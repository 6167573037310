export const cs = {
  processPlugins: {
    activeFrom: 'Aktivní od',
    activeTo: 'Aktivní do',
    activeTotal: 'Celkem aktivní',
    taskTerm: 'Termín tasku',
    reminder: 'Připomenutí',
    console: 'Konzole',
    scheduler: 'Scheduler',
    selectTaskStatus: 'Vybrat status tasku',
    endTask: 'Uzavřít task',
    progressBar: 'Progress bar',
    incident: 'Incident',
    incidentMessage: 'Incident - zpráva',
    decisionName: 'Název rozhodnutí',
    decisionKey: 'Klíč rozhodnutí',
    decisionInputs: 'Vstupy rozhodnutí',
    decisionOutputs: 'Výstupy rozhodnutí',
    filters: {
      tsmDeployStateFilter: 'tSM Deploy State Filter',
      tsmDeployStateFilterDescription:
        'Definuje filtr pro výběr stavů nasazení. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek a poskytuje ' +
        'rozhraní pro vícenásobný výběr. Dynamicky načítá data stavů nasazení ' +
        'ze služby procesů. Implementuje překryvný panel s možnostmi výběru a potvrzení.',
      tsmProcessGroupActionFilter: 'tSM Process Group Action Filter',
      tsmProcessGroupActionFilterDescription:
        'Definuje filtr pro výběr akcí skupiny procesů. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek a poskytuje ' +
        'rozhraní pro vícenásobný výběr. Dynamicky načítá data akcí skupiny ' +
        'procesů ze služby. Implementuje překryvný panel s možnostmi výběru a potvrzení.',
      tsmProcessGroupFilter: 'tSM Process Group Filter',
      tsmProcessGroupFilterDescription:
        'Implementuje filtr skupin procesů s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek a umožňuje ' +
        'filtrování a výběr skupin procesů z dynamicky načteného seznamu. ' +
        'Poskytuje možnosti pro odstranění a potvrzení, s využitím překryvného panelu.',
      tsmProcessStateFilter: 'tSM Process State Filter',
      tsmProcessStateFilterDescription:
        'Implementuje filtr stavu procesu s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, umožňuje ' +
        'filtrování stavů procesů z dynamicky načteného seznamu. ' +
        'Poskytuje možnosti pro odstranění a potvrzení, s využitím překryvného panelu.',
      tsmProcessTypeFilter: 'tSM Process Type Filter',
      tsmProcessTypeFilterDescription:
        'Implementuje filtr typu procesu s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, umožňuje uživatelům ' +
        'filtrovat typy procesů načtené z API. Poskytuje možnosti výběru, odstranění ' +
        'a potvrzení, s využitím překryvného panelu pro interakci.',
      tsmTaskFilter: 'tSM Task Filter',
      tsmTaskFilterDescription:
        'Implementuje filtr úkolů s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, ' +
        'umožňuje uživatelům filtrovat definice úkolů načtené z API. ' +
        'Poskytuje možnosti výběru, odstranění a potvrzení, s využitím ' +
        'překryvného panelu pro interakci.',
      tsmTaskStatusFilter: 'tSM Task Status Filter',
      tsmTaskStatusFilterDescription:
        'Implementuje filtr stavu úkolu s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, ' +
        'umožňuje uživatelům filtrovat stavy úkolů načtené z API. ' +
        'Poskytuje možnosti výběru, odstranění a potvrzení, s využitím ' +
        'překryvného panelu pro interakci.',
      tsmTaskStatusGroupFilter: 'tSM Task Status Group Filter',
      tsmTaskStatusGroupFilterDescription:
        'Implementuje filtr skupiny stavů úkolů s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, umožňuje ' +
        'uživatelům filtrovat skupiny stavů úkolů načtené z API. ' +
        'Poskytuje možnosti výběru, odstranění a potvrzení, s využitím ' +
        'překryvného panelu pro interakci.',
      tsmTaskTypeFilter: 'tSM Task Type Filter',
      tsmTaskTypeFilterDescription:
        'Implementuje filtr typu úkolu s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, umožňuje uživatelům ' +
        'filtrovat typy úkolů načtené z API. Poskytuje možnosti výběru, odstranění ' +
        'a potvrzení, s využitím překryvného panelu pro interakci.',
    },
    pipes: {
      dmnDefinitionCode: 'Dmn Definition Code',
      dmnDefinitionCodeDescription:
        'Nahrává a získává data definice DMN podle jejího kódu a stavu nasazení. Pokud jsou data k dispozici, ' +
        'vrací zadané pole nebo kompletní data; jinak vrací poskytnuté ID. Pokud data nejsou ve store, ' +
        'inicializuje jejich načtení pomocí dispatch.',
      priorityIcon: 'Priority Icon',
      priorityIconDescription:
        'Generuje HTML reprezentaci ikony priority s jejím názvem. Získává detaily priority ' +
        'z registrační služby na základě poskytnuté hodnoty a volitelného argumentu. Pokud je dostupný ' +
        'styl, zahrnuje ikonu; jinak zobrazuje pouze název.',
      processDefinitionCodeDeployed: 'Process Definition Code Deployed',
      processDefinitionCodeDeployedDescription:
        'Získává a spravuje data pro nasazenou definici procesu podle kódu. Používá poskytnuté ID k ' +
        'výběru nebo načtení detailů definice procesu a volitelně extrahuje specifické pole, pokud je ' +
        'definováno. Zajišťuje efektivní načítání a ukládání dat pro opakované použití.',
      processDefinitionCodeVersion: 'Process Definition Code Version',
      processDefinitionCodeVersionDescription:
        'Zajišťuje načítání a ukládání detailů definice procesu podle kódu a verze. Používá ' +
        'poskytnutý kód a verzi pro získání nebo načtení relevantních dat, s možností vybrat ' +
        'konkrétní pole. Efektivně spravuje získávání a opětovné využití informací o definici procesu.',
      processDefinitionCode: 'Process Definition Code',
      processDefinitionCodeDescription:
        'Umožňuje načítání a ukládání definic procesů podle kódu. Filtruje a vybírá nasazené ' +
        'procesy nebo nejnovější verzi, pokud žádný proces není nasazen. Nabízí přístup k ' +
        'konkrétním polím na základě vstupních parametrů a zajišťuje efektivní správu stavu.',
      processDefinitionId: 'Process Definition Id',
      processDefinitionIdDescription:
        'Získává podrobnosti definice procesu podle ID ze store. Podporuje přístup ke konkrétním ' +
        'polím na základě vstupních parametrů nebo vrací celý objekt dat. Spouští akci pro načtení ' +
        'definice procesu, pokud již není dostupná ve store.',
      processGroupCode: 'Process Group Code',
      processGroupCodeDescription:
        'Načítá a získává podrobnosti skupiny procesů podle kódu ze store. Vrací konkrétní pole ' +
        'dat skupiny procesů, pokud je určeno, nebo celý objekt dat. Spouští akci pro načtení ' +
        'skupiny procesů, pokud není přítomna ve store.',
      processGroupId: 'Process Group Id',
      processGroupIdDescription:
        'Získává podrobnosti skupiny procesů podle ID ze store. Poskytuje konkrétní pole dat skupiny ' +
        'procesů, pokud je definováno, nebo celý objekt dat. Pokud skupina procesů není ve store, ' +
        'spouští akci pro její načtení.',
      processTypeCode: 'Process Type Code',
      processTypeCodeDescription:
        'Načítá podrobnosti typu procesu ze store pomocí jedinečného kódu. Umožňuje vrátit konkrétní ' +
        'pole dat typu procesu, pokud je určeno, nebo celý datový objekt. Spouští akci pro načtení ' +
        'typu procesu, pokud není ve store.',
      processTypeId: 'Process Type Id',
      processTypeIdDescription:
        'Načítá podrobnosti typu procesu ze store pomocí jedinečného ID. Vrací hodnoty konkrétního pole ' +
        'nebo celý datový objekt podle zadaného argumentu. Spouští akci pro načtení podrobností typu ' +
        'procesu, pokud nejsou ve store.',
      taskActionTemplateCode: 'Task Action Template Code',
      taskActionTemplateCodeDescription:
        'Načítá podrobnosti šablony akce úkolu pomocí jedinečného kódu. Získává hodnoty konkrétního pole ' +
        'nebo celý datový objekt podle zadaného argumentu. Spouští akci pro načtení šablony akce úkolu, ' +
        'pokud není již dostupná ve store.',
      taskActionTemplateId: 'Task Action Template Id',
      taskActionTemplateIdDescription:
        'Načítá podrobnosti šablony akce úkolu pomocí jedinečného identifikátoru. Získává hodnoty konkrétního ' +
        'pole nebo celý datový objekt podle zadaného argumentu. Spouští akci pro načtení šablony akce úkolu, ' +
        'pokud není již dostupná ve store.',
      taskDefinitionCode: 'Task Definition Code',
      taskDefinitionCodeDescription:
        'Načítá data definice úkolu pomocí jedinečného kódu. Vrací hodnoty konkrétního pole nebo celý datový ' +
        'objekt podle zadaného argumentu. Automaticky spouští akci pro načtení definice úkolu, pokud není ' +
        'již uložená.',
      taskDefinitionId: 'Task Definition Id',
      taskDefinitionIdDescription:
        'Načítá data definice úkolu pomocí jedinečného identifikátoru. Umožňuje získat konkrétní pole nebo ' +
        'celá data na základě vstupního argumentu. Spouští akci pro načtení definice úkolu, pokud není ' +
        'přítomna v úložišti.',
      taskLink: 'Task Link',
      taskLinkDescription:
        'Vytváří hypertextový odkaz na úkol v rámci konkrétní instance procesu. Sestavuje URL pomocí ' +
        'zadaného modulu a referenčního ID úkolu z poskytnutých dat řádku. Zobrazuje zadanou hodnotu ' +
        'jako viditelný text odkazu.',
      taskStatusCodeColor: 'Task Status Code Color',
      taskStatusCodeColorDescription:
        'Vytváří stylizovaný štítek stavu úkolů na základě kódu stavu a dat. Načítá detaily stavu úkolu ' +
        'ze store a sestavuje barevný štítek s přeloženým textem. Pokud není poskytnut kód stavu, ale ' +
        'data řádku naznačují aktivní úkoly, zobrazí "aktivní" stav. Jinak se výchozí nastavení ' +
        'změní na "dokončeno" s šedým štítkem.',
      taskStatusCode: 'Task Status Code',
      taskStatusCodeDescription:
        'Načítá informace o stavu úkolu na základě zadaného kódu a vrací specifická pole dat nebo celý ' +
        'objekt. Pokud nejsou dostupná data, vrací zadaný kód. Používá store pro správu stavu a spouští ' +
        'načítání dat podle potřeby.',
      taskStatusGroupCode: 'Task Status Group Code',
      taskStatusGroupCodeDescription:
        'Načítá podrobnosti o skupině stavů úkolů na základě zadaného kódu a poskytuje specifické pole ' +
        'nebo celý objekt. Pokud data neexistují, vrací zadaný kód. Spravuje stav pomocí store a ' +
        'spouští načítání dat podle potřeby.',
      taskStatusGroupId: 'Task Status Group Id',
      taskStatusGroupIdDescription:
        'Načítá podrobnosti o skupině stavů úkolů podle ID a vrací specifické pole nebo celý objekt. ' +
        'Pokud data nejsou dostupná, vrací zadané ID. Automaticky spravuje stav a spouští načítání dat ' +
        'podle potřeby.',
      taskStatusIcon: 'Task Status Icon',
      taskStatusIconDescription:
        'Vytváří ikonu stavu úkolu pomocí třídy stylu a názvu ze seznamu stavů. Načítá data podle kódu ' +
        'a vytváří stylovaný element span s názvem stavu úkolu. Pokud hodnota není zadána, vrací ' +
        'prázdný řetězec.',
      taskStatusId: 'Task Status Id',
      taskStatusIdDescription:
        'Načte podrobnosti o stavu úkolu podle jeho ID a vrátí konkrétní pole nebo všechna data ' +
        'podle zadaných argumentů. Používá selektor k načtení stavu úkolu ze store a spustí akci ' +
        'pro načtení stavu, pokud není dostupný.',
      taskUnassignedSubtask: 'Task Unassigned Subtask',
      taskUnassignedSubtaskDescription:
        'Ověřuje, zda úkol obsahuje nějaké aktivní podúkoly, které nejsou přiřazeny a jsou ' +
        'označeny jako podúkoly schvalující osoby. Používá BooleanTranslatePipe k převodu ' +
        'logického výsledku na lokalizovaný řetězec.',
      ticketTaskById: 'Ticket Task By Id',
      ticketTaskByIdDescription:
        'Zajišťuje data úkolu na základě kombinovaného ID aktivity a ID provádění. Rozděluje složené ID ' +
        'úkolu k získání ID aktivity a ID provádění a poté získává odpovídající data úkolu z úložiště. ' +
        'Umožňuje přístup k vnořeným vlastnostem pomocí cesty oddělené tečkami.',
    },
    controls: {
      header: 'Hlavička',
      assigned: 'Přiřazeno',
      location: 'Adresa úkolu',
      time: 'Časy úkolu',
      worker: 'Pracovník',
      divider: 'Dělící čára',
      skillAndRegion: 'Dovednosti a region',
      description: 'Popis úkolu',
      cost: 'Náklady',
      footer: 'Patička',
    },
  },
};
