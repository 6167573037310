import {SelectItem} from 'primeng/api/selectitem';

export enum FormatterInputType {
  FILE = 'FILE',
  HTML = 'HTML',
}

export function getAllFormatterInputTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(FormatterInputType).forEach((val) =>
    items.push({label: FormatterInputType[val], value: val}),
  );
  return items;
}
