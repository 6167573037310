<div class="p-inputgroup">
  <input
    [ngStyle]="{display: readonly ? 'none' : ''}"
    [placeholder]="
      localizationData
        | localizationDataTranslate: 'widget.placeholder' : placeholder
    "
    pPassword
    type="password"
    [attr.id]="inputId ? inputId : null"
    #inputElement
    [(ngModel)]="val"
    [tabindex]="tabindex"
    [showPassword]="isShowPassword"
    class="p-inputtext p-component p-element"
    autocomplete="new-password"
  />
  <span
    class="p-inputgroup-addon"
    [ngStyle]="{display: readonly ? 'none' : ''}"
  >
    <i
      [ngClass]="
        !isShowPassword
          ? 'p-icon-visibility pass-showed'
          : 'p-icon-visibility-off pass-hidden'
      "
      (click)="showPassword()"
    ></i>
  </span>
</div>
<span
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
  [ngClass]="val ? 'readonly-text' : 'readonly-text empty'"
>
  {{ val ? '********' : 'none' }}
</span>
