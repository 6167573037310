import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BASE_URL, SharedRequest, SharedRequestValidUntil} from '@tsm/framework/root';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';

@Injectable()
export class CommonApiService<ServerResponseType, ToClientType> {
  public readonly BASE_URL: string;

  constructor(
    @Inject(BASE_URL) public baseUrl,
    public apiService: ApiService,
    public config: ConfigService<Config>,
  ) {
    if (baseUrl === undefined) {
      console.error('CommonApiService nema nastavenou BASE_URL!!!');
    }
    if (baseUrl.indexOf('http') < 0) {
      this.BASE_URL = config.value.apiUrls.base + baseUrl;
    } else {
      this.BASE_URL = baseUrl;
    }
  }

  @SharedRequestValidUntil()
  getEntitySharedRequest(
    entityType: string,
    id: string,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/${id}`,
    );
  }

  getEntity(
    entityType: string,
    id: string,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/${id}`,
    );
  }

  getEntityPublic(
    entityType: string,
    id: string,
    expands: string[] = [],
  ): Observable<Envelope<ToClientType>> {
    const expandsString =
      expands.length > 0 ? `?expand=${expands.join(',')}` : '';
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/v1/${entityType}/${id}${expandsString}`,
    );
  }

  getEntities(entityType: string, ids: string[]): Observable<any> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/all/${ids.join(',')}`,
    );
  }

  getEntitiesByIds(entityType: string, ids: string[]): Observable<any> {
    return this.apiService.post<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/allByIds`,
      ids,
    );
  }

  @SharedRequestValidUntil(120)
  getEntitiesByCodes(entityType: string, codes: string[]): Observable<any> {
    return this.apiService.post<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/allByCodes`,
      codes,
    );
  }

  getEntitiesByNames(entityType: string, names: string[]): Observable<any> {
    return this.apiService.post<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/allByNames`,
      names,
    );
  }

  @SharedRequest
  getEntitiesByIdsSharedRequest(
    entityType: string,
    ids: string[],
  ): Observable<any> {
    return this.apiService.post<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/allByIds`,
      ids,
    );
  }

  @SharedRequest
  getEntitiesByCodesSharedRequest(
    entityType: string,
    codes: string[],
  ): Observable<any> {
    return this.apiService.post<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/allByCodes`,
      codes,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  @SharedRequestValidUntil(120)
  getEntityByCodeSharedRequest(
    entityType: string,
    code: string,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/code/${code}`,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  getEntityByCode(
    entityType: string,
    code: string,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/code/${code}`,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  @SharedRequestValidUntil(120)
  getEntityByCodePublicSharedRequest(
    entityType: string,
    code: string,
    expands: string[] = [],
  ): Observable<Envelope<ToClientType>> {
    const expandsString =
      expands.length > 0 ? `?expand=${expands.join(',')}` : '';
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/v1/${entityType}/${code}${expandsString}`,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  getEntityByCodePublic(
    entityType: string,
    code: string,
    expands: string[] = [],
  ): Observable<Envelope<ToClientType>> {
    const expandsString =
      expands.length > 0 ? `?expand=${expands.join(',')}` : '';
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/v1/${entityType}/${code}${expandsString}`,
    );
  }

  getEntityByKey(
    entityType: string,
    key: string,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/${entityType}/key/${key}`,
    );
  }

  deleteEntity(
    entityType: string,
    id: string,
    immediatelyRefresh?: boolean,
  ): Observable<Envelope<ToClientType>> {
    let url = this.BASE_URL + `/${entityType}/${id}`;
    if (immediatelyRefresh) {
      url += '?immediatelyRefresh=true';
    }
    return this.apiService.delete<ServerResponseType, ToClientType>(url);
  }

  deleteAllEntities(entityType: string, ids: string[]): Observable<any> {
    return this.apiService.post(
      `${this.BASE_URL}/${entityType}/deleteAll`,
      ids,
    );
  }

  postEntity(
    entityType: string,
    entity: ServerResponseType,
    immediatelyRefresh?: boolean,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.post<ServerResponseType, ToClientType>(
      this.BASE_URL +
        `/${entityType}${
          immediatelyRefresh == true ? '?immediatelyRefresh=true' : ''
        }`,
      entity,
    );
  }

  postAllEntities(
    entityType: string,
    entities: ServerResponseType[],
    otherQuery?: string,
  ): Observable<Envelope<ToClientType[]>> {
    return this.apiService.post<ServerResponseType[], ToClientType[]>(
      `${this.BASE_URL}/${entityType}/saveAll${
        otherQuery ? '/' + otherQuery : ''
      }`,
      entities,
    );
  }

  patchAllEntities(
    entityType: string,
    entities: ServerResponseType[],
    otherQuery?: string,
  ): Observable<Envelope<ToClientType[]>> {
    return this.apiService.patch<ServerResponseType[], ToClientType[]>(
      `${this.BASE_URL}/${entityType}/saveAll${
        otherQuery ? '/' + otherQuery : ''
      }`,
      entities,
    );
  }

  putEntity(
    entityType: string,
    id: string,
    entity: ServerResponseType,
    immediatelyRefresh?: boolean,
  ): Observable<Envelope<ToClientType>> {
    return this.apiService.put<ServerResponseType, ToClientType>(
      this.BASE_URL +
        `/${entityType}/${id}${
          immediatelyRefresh == true ? '?immediatelyRefresh=true' : ''
        }`,
      entity,
    );
  }

  patchEntity(
    entityType: string,
    id: string,
    entity: ServerResponseType | Partial<ServerResponseType>,
    immediatelyRefresh?: boolean,
  ) {
    return this.apiService.patch<ServerResponseType, ToClientType>(
      this.BASE_URL +
        `/${entityType}/${id}${
          immediatelyRefresh == true ? '?immediatelyRefresh=true' : ''
        }`,
      entity,
    );
  }

  putOrPostEntity(
    entityType: string,
    entity: ServerResponseType,
    id: string,
    immediatelyRefresh?: boolean,
  ) {
    const entityVar = entity as any;
    if (
      entityVar?.auditInfo?.version != null ||
      entityVar.version != null ||
      entityVar.id != null
    ) {
      return this.putEntity(entityType, id, entity, immediatelyRefresh);
    } else {
      return this.postEntity(entityType, entity, immediatelyRefresh);
    }
  }

  upsertEntity(
    entityType: string,
    entity: ServerResponseType,
    id: string,
    immediatelyRefresh?: boolean,
  ) {
    if (
      (entity as any)?.auditInfo?.version != null ||
      (entity as any)?.version != null
    ) {
      return this.putEntity(entityType, id, entity, immediatelyRefresh);
    } else {
      return this.postEntity(entityType, entity, immediatelyRefresh);
    }
  }

  upsertEntityPatch(
    entityType: string,
    entity: ServerResponseType,
    id: string,
    immediatelyRefresh?: boolean,
  ) {
    if (
      (entity as any).version != null ||
      (entity as any)?.auditInfo?.version != null
    ) {
      return this.patchEntity(entityType, id, entity, immediatelyRefresh);
    } else {
      return this.postEntity(entityType, entity, immediatelyRefresh);
    }
  }

  // nove se muze na klasicky endpoint poslat jak filtrace tak i razeni nebo pocet zaznamu(default je 100)
  getAll(
    entityType: string,
    pageSortFilter?: string,
  ): Observable<Envelope<ToClientType[]>> {
    return this.apiService.get<ServerResponseType[], ToClientType[]>(
      this.BASE_URL +
        `/${entityType}${!!pageSortFilter ? '?' + pageSortFilter : ''}`,
    );
  }

  getAllFilterable(
    entityType: string,
    pageSortFilter: string,
  ): Observable<Envelope<ToClientType[]>> {
    return this.apiService.get<ServerResponseType[], ToClientType[]>(
      this.BASE_URL + `/${entityType}/filtering-list?${pageSortFilter}`,
    );
  }

  getAllFilterablePublic(
    entityType: string,
    pageSortFilter: string,
  ): Observable<Envelope<ToClientType[]>> {
    return this.apiService.get<ServerResponseType[], ToClientType[]>(
      this.BASE_URL + `/v1/${entityType}/page?${pageSortFilter}`,
    );
  }

  getAllFilterableElastic(
    entityType: string,
    pageSortFilter: string,
  ): Observable<Envelope<object>> {
    return this.apiService.get<object[], object[]>(
      this.BASE_URL + `/${entityType}/filtering?${pageSortFilter}`,
    );
  }

  getItemsById(
    entityType: string,
    entityId: string,
    itemType: string,
    module?: string,
  ): Observable<any> {
    const url =
      module === undefined
        ? this.BASE_URL + `/${itemType}/${entityType}/${entityId}`
        : this.BASE_URL + `/${itemType}/${entityType}/${module}/${entityId}`;
    return this.apiService.get<ServerResponseType, ToClientType>(url);
  }

  diffEntities(
    entityType: string,
    diffEntities: {
      id: string;
      [k: string]: any;
    }[],
    compareField: 'id' | 'code' = 'id',
  ): Observable<Envelope<ToClientType[]>> {
    let url =
      this.BASE_URL +
      `/${entityType}/save-diff${
        compareField === 'code' ? '?compareField=' + compareField : ''
      }`;
    const entities = diffEntities.map((e) => {
      const {id, ...rest} = e;
      return rest;
    });
    return this.apiService.post<ServerResponseType[], ToClientType[]>(
      url,
      entities,
    );
  }

  dbbridge(): Observable<Envelope> {
    return this.apiService.get<any, any>(
      this.config.value.apiUrls.base + `tps-custom/api/audit-log/execute`,
    );
  }
}
