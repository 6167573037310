import {SelectItem} from 'primeng/api';

export enum TimePeriod {
  NO_PERIOD = 'NO_PERIOD',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export function getAllTimePeriods(): SelectItem[] {
  const actions: SelectItem[] = [];
  Object.keys(TimePeriod).forEach((key) =>
    actions.push({label: TimePeriod[key], value: key}),
  );
  return actions;
}

export function getTimePeriodByLabel(label: string): string {
  const item = Object.entries(TimePeriod).find((value) => value[1] === label);
  return item ? item[0] : null;
}

export function getTimePeriodByKey(key: string): string {
  const item = Object.entries(TimePeriod).find((value) => value[0] === key);
  return item ? item[1] : null;
}
