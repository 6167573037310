import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'linkHrefComponent',
  pure: true,
})
export class LinkHrefComponentPipe implements PipeTransform {
  public requiresRowData = true;

  public transform(value: any, args: any, row: any): any {
    return '';
  }
}
