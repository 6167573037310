import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from 'primeng/api';
import {RippleModule} from 'primeng/ripple';
import {OverlayPanel} from './overlay-panel/overlay-panel.component';

@NgModule({
    imports: [CommonModule, RippleModule, SharedModule, OverlayPanel],
    exports: [OverlayPanel, SharedModule],
})
export class OverlayPanelModule {}
