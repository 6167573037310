import {createReducer, on} from '@ngrx/store';
import {BackupSetDiffTable, BackupSetImport} from '@tsm/listing-lib/service';
import {IdentifiedPack} from '@tsm/framework/root';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  DeleteBackupSetImportSuccess,
  DiffBackupSetImport,
  DiffBackupSetImportError,
  DiffBackupSetImportSuccess,
  LoadBackupSetImportById,
  LoadBackupSetImportByIdError,
  LoadBackupSetImportByIdSuccess,
  UpsertBackupSetImport,
  UpsertBackupSetImportError,
  UpsertBackupSetImportSuccess,
} from '../actions';

export interface IBackupSetImportState extends BackupSetImport {
  diffTables?: {[key: string]: BackupSetDiffTable};
}

export type BackupSetImportState = EntityState<
  IdentifiedPack<IBackupSetImportState>
>;
export const backupSetImportAdapter: EntityAdapter<
  IdentifiedPack<IBackupSetImportState>
> = createEntityAdapter<IdentifiedPack<IBackupSetImportState>>({});

export const backupSetImportInitialState: BackupSetImportState =
  backupSetImportAdapter.getInitialState({});

export const backupSetImportReducer = createReducer(
  backupSetImportInitialState,
  on(LoadBackupSetImportById, (state, {id}) =>
    backupSetImportAdapter.upsertOne(
      {
        ...state.entities[id],
        id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadBackupSetImportByIdSuccess, (state, {backupSetImport}) =>
    backupSetImportAdapter.upsertOne(
      {
        id: backupSetImport.id,
        data: {
          ...backupSetImport,
          importLogKeys: (() => {
            if (backupSetImport?.importLog) {
              var keys: string[] = [];
              const fileMapKeys = Object.keys(
                backupSetImport?.importSummary?.fileMap,
              );
              fileMapKeys.forEach((key, index) => {
                keys[index] = fileMapKeys.find(
                  (key) =>
                    +backupSetImport?.importSummary?.fileMap[key].split(
                      '_',
                    )[0] ===
                    index + 1,
                );
              });
              return keys;
            }
            return null;
          })(),
        },
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadBackupSetImportByIdError, (state, {id, error}) =>
    backupSetImportAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertBackupSetImport, (state, {backupSetImport}) =>
    backupSetImportAdapter.updateOne(
      {
        id: backupSetImport.id,
        changes: {
          ...state.entities[backupSetImport.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertBackupSetImportSuccess, (state, {backupSetImport}) =>
    backupSetImportAdapter.upsertOne(
      {
        id: backupSetImport.id,
        data: {
          ...backupSetImport,
          importLogKeys: backupSetImport?.importLog
            ? Object.keys(backupSetImport?.importLog)
            : null,
        },
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(UpsertBackupSetImportError, (state, {id, error}) =>
    backupSetImportAdapter.upsertOne(
      {
        id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DeleteBackupSetImportSuccess, (state, {id}) =>
    backupSetImportAdapter.removeOne(id, state),
  ),

  on(DiffBackupSetImport, (state, {backupSetImportId, options}) =>
    backupSetImportAdapter.upsertOne(
      {
        id: backupSetImportId,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(DiffBackupSetImportSuccess, (state, {backupSetDiffs, backupSetImportId}) =>
    backupSetImportAdapter.upsertOne(
      {
        id: backupSetImportId,
        data: {
          ...state.entities[backupSetImportId].data,
          diffTables: backupSetDiffs.reduce(
            (acc: {[key: string]: BackupSetDiffTable}, item) => {
              acc = {
                ...acc,
                [item.data.diffName]: item,
              };
              return acc;
            },
            state.entities[backupSetImportId]?.data?.diffTables || {},
          ),
        },
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DiffBackupSetImportError, (state, {backupSetImportId, error}) =>
    backupSetImportAdapter.upsertOne(
      {
        id: backupSetImportId,
        data: state.entities[backupSetImportId].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),
);
