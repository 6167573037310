import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteListingType,
  DeleteListingTypeError,
  DeleteListingTypeSuccess,
  DiffListingType,
  DiffListingTypeError,
  DiffListingTypeSuccess,
  LoadListingType,
  LoadListingTypeByCode,
  LoadListingTypeByCodeError,
  LoadListingTypeByCodeSuccess,
  LoadListingTypeById,
  LoadListingTypeByIdError,
  LoadListingTypeByIdSuccess,
  LoadListingTypeError,
  LoadListingTypeSuccess,
  RefreshDataAndClearSelected,
  UpsertListingType,
  UpsertListingTypeError,
  UpsertListingTypeSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {CommonConfigFormApiService} from '../services';
import {ListingType} from '../models';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class ListingTypeEffects {
  private readonly LISTING_API_PATH = 'v1/listing';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingType),
      exhaustMap(() =>
        this.commonApiService
          .getAll('listing')
          .pipe(
            map((env) =>
              env.success
                ? LoadListingTypeSuccess({listingTypes: env.data})
                : LoadListingTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingTypeById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.LISTING_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadListingTypeByIdSuccess({listingType: env.data})
                : LoadListingTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingTypeByCode),
      concatMap(({code}) =>
        this.commonApiService
          .getEntityByCodeSharedRequest(this.LISTING_API_PATH, code)
          .pipe(
            map((env) => {
              if (env.success) {
                if (env.data == null) {
                  console.warn(
                    'For code [' +
                      code +
                      '] does not exist listing type definition!',
                  );
                  return LoadListingTypeByCodeError({
                    code,
                    error: {
                      status: '404',
                      timestamp: new Date().toISOString(),
                      message:
                        'For code [' +
                        code +
                        '] does not exist listing type definition!',
                    },
                  });
                }
                return LoadListingTypeByCodeSuccess({listingType: env.data});
              }
              return LoadListingTypeByCodeError({code, error: env.error});
            }),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertListingType),
      concatMap(({listingType, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(this.LISTING_API_PATH, listingType, listingType.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.listingTypeService.effects
                    .messageListingTypeChangeSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertListingTypeSuccess({
                  listingType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.listingTypeService.effects
                    .messageListingTypeSaveUnSuccessful,
                );
                return UpsertListingTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteListingType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.LISTING_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.listingTypeService.effects.listingTypeDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/listing-type']);
              }
              return DeleteListingTypeSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.listingTypeService.effects.listingTypeDeleteError,
              );
              return DeleteListingTypeError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffListingType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.LISTING_API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffListingTypeSuccess({
                  listingTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffListingTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertListingTypeSuccess),
      tap(({listingType, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/config/listing-type', listingType.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteListingTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private commonApiService: CommonConfigFormApiService<
      ListingType,
      ListingType
    >,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
