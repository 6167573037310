export const en = {
  billingPlugins: {
    headerDescription: 'Header docs',
    billingDocument: {
      invoiceNumberMnemonic: 'Invoice number mnemonic',
      invoiceNumberMnemonicTooltip:
        'Mnemonic for custom invoice number generation. If value is left empty, billing documents code will be used as invoice number instead.',
      dueDate: 'Due date',
      invoiceDate: 'Invoice date',
      invoiceText: 'Invoice text',
      priceWithVat: 'Price with VAT',
      invoiceNumber: 'Invoice Number',
      billingCurrency: 'Currency',
      account: 'Account',
      catalogLines: 'Catalog products',
      products: 'Products',
      invoicedProductsAndServices: 'Invoice products and services',
      generateInvoice: 'Generate Invoice',
      billingCycle: 'Billing cycle',
      variableSymbol: 'Variable symbol',
      dateOfRealization: 'Date of realization',
      priceWithoutVat: 'Price without VAT',
      billingDocuments: 'Billing documents',
      generate: 'Generate Billing document',
      approve: 'Approve',
      storno: 'Storno',
      downloadCSV: 'Download CSV',
      showDocument: 'Show document',
      regenerateDocument: 'Regenerate invoice',
      resendDocument: 'Resend invoice',
      invoiceDocumentNotFound: 'Invoice not found',
    },
    billingDocumentLine: {
      quantity: 'quantity',
      entityInstanceConfiguration: 'Product',
      entityInstanceConfigurationName: 'Product Name',
      entityInstanceConfigurationCode: 'Product Code',
      entityInstanceConfigurationValidFor: {
        from: 'Product valid from',
        to: 'product valid to',
      },
      deleteConfirmation: 'Do you really want to delete this item?',
      billingDocumentLines: 'Accounting items',
      new: 'Create New',
      edit: 'Edit line',
      goToProductDetail: 'Go to product detail',
      entityCatalogSpecification: 'Catalog specification',
      vat: 'VAT',
      sortOrder: 'Sort Order',
      priceWithoutVat: 'Price without VAT',
      addNewConfiguration: 'Add product from catalog',
      addExistingConfiguration: 'Add existing product',
      amount: 'Amount',
      vatRate: 'VAT (%)',
      parentDocumentLine: 'Parent line',
      addChild: 'Add child line',
      priceType: 'Price type',
      unitPrice: 'Unit price',
      regionName: 'Region Name',
      regionCode: 'Region Code',
      SapConsumerNumber: 'SAP consumer number',
      costCenter: 'Cost Center',
      dueDateType: 'Due Date Type',
      materialNumber: 'Material Number',
      address: 'Address',
      plugId: 'Plug ID',
      crossId: 'CROSS ID',
      contractNumberOtherParty: 'Contract number of other party',
      productName: 'Product name',
      billingDocument: 'Billing document',
    },
    billingCycle: {
      billingStart: 'Start date',
      status: 'Status',
      billingEnd: 'End date',
      billingDate: 'Billing date',
      billingPeriod: 'Billing period',
      dataTags: 'Tags',
      type: 'Billing Type',
      description: 'Description',
      edit: 'Edit billing cycle',
      new: 'Create new billing cycle',
      periodFrom: 'Billing period start',
      periodTo: 'Billing period end',
      cycleType: 'Billing Cycle type',
      billingCycle: 'Billing cycle',
      periodError: '"Period From" must be before "Period After"',
      billingDocuments: 'Billing documents',
      billing: 'Billing',
      toAccount: 'To account',
    },
    plugin: {
      billingCycleTypeLOV: 'Billing cycle type',
      loveboxSAPStatus: 'Billing SAP document status lovbox',
      billingSAPDocumentStatus: 'Billing - Billing SAP document status lov',
      billingCycleLov: 'Billing cycle',
      billingDocumentLov: 'Billing document type',
      billingDocumentWidget: 'Billing document list',
    },
    filters: {
      tsmBillingCycleFilter: 'tSM Billing Cycle Filter',
      tsmBillingCycleFilterDescription:
        'Provides an interface for filtering billing cycles with customizable options. ' +
        'It supports filtering by cycle type, validity period, and code. ' +
        'Integrates with services to retrieve and manage selected cycles dynamically.',
      tsmBillingCycleStatusFilter: 'tSM Billing Cycle Status Filter',
      tsmBillingCycleStatusFilterDescription:
        'Provides an interface for filtering billing cycle statuses by code and name. ' +
        'It integrates with services to retrieve statuses dynamically and manage selections.',
      tsmBillingCycleTypeFilter: 'tSM Billing Cycle Type Filter',
      tsmBillingCycleTypeFilterDescription:
        'Provides an interface for filtering billing cycle types by code and name. ' +
        'It retrieves data dynamically and supports multi-selection for flexibility.',
      tsmBillingCycleTypeFrequencyFilter:
        'tSM Billing Cycle Type Frequency Filter',
      tsmBillingCycleTypeFrequencyFilterDescription:
        'Provides an interface for filtering billing frequencies using a table view. ' +
        'Retrieves available frequencies dynamically and supports multi-selection.',
      tsmBillingDocumentStatusFilter: 'tSM Billing Document Status Filter',
      tsmBillingDocumentStatusFilterDescription:
        'Provides an interface for filtering billing document statuses by code and name. ' +
        'Retrieves and manages statuses dynamically, supporting multi-selection.',
      tsmBillingDocumentTypeFilter: 'tSM Billing Document Type Filter',
      tsmBillingDocumentTypeFilterDescription:
        'Provides an interface for filtering billing document types by code and name. ' +
        'Retrieves types dynamically and supports multi-selection for flexibility.',
      tsmBillingSapDocumentStatusFilter:
        'tSM Billing Sap Document Status Filter',
      tsmBillingSapDocumentStatusFilterDescription:
        'Provides an interface for filtering SAP billing document statuses by code and name. ' +
        'Retrieves statuses dynamically and supports multi-selection for flexibility.',
    },
    pipes: {
      billingCurrencyLoader: 'Billing Currency Loader',
      billingCurrencyLoaderDescription:
        'Loads billing currency data by ID from the store, retrieves specific fields like the code, ' +
        'or nested properties using dot-separated strings, and ensures the data is fetched if not ' +
        'already available.',
      billingCurrencyLoaderCode: 'Billing Currency Loader Code',
      billingCurrencyLoaderCodeDescription:
        'Loads billing currency data by Code from the store, retrieves specific fields like the code, ' +
        'or nested properties using dot-separated strings, and ensures the data is fetched if not ' +
        'already available.',
      billingCycleLoader: 'Billing Cycle Loader',
      billingCycleLoaderDescription:
        'Retrieves billing cycle data by ID from the store, fetching specific fields like the code, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingCycleLoaderCode: 'Billing Cycle Loader Code',
      billingCycleLoaderCodeDescription:
        'Retrieves billing cycle data by Code from the store, fetching specific fields like the code, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingCycleStatusLoader: 'Billing Cycle Status Loader',
      billingCycleStatusLoaderDescription:
        'Retrieves billing cycle status data by ID from the store, fetching specific fields like the code, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingCycleStatusLoaderCode: 'Billing Cycle Status Loader Code',
      billingCycleStatusLoaderCodeDescription:
        'Retrieves billing cycle status data by Code from the store, fetching specific fields like the code, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingCycleTypeLoader: 'Billing Cycle Type Loader',
      billingCycleTypeLoaderDescription:
        'Retrieves billing cycle type data by ID from the store, fetching specific fields like the name, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingDocumentLineLoader: 'Billing Document Line Loader',
      billingDocumentLineLoaderDescription:
        'Retrieves billing document line data by ID from the store, fetching specific fields like the name, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingDocumentLoader: 'Billing Document Loader',
      billingDocumentLoaderDescription:
        'Retrieves billing document data by ID from the store, fetching specific fields like the name, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingDocumentLoaderKey: 'Billing Document Loader Key',
      billingDocumentLoaderKeyDescription:
        'Retrieves billing document data by Key from the store, fetching specific fields like the name, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingDocumentStatusLoader: 'Billing Document Status Loader',
      billingDocumentStatusLoaderDescription:
        'Retrieves billing document status by ID from the store, fetching specific fields like the code, ' +
        'or the entire object, with support for nested properties using dot-separated strings. ' +
        'Ensures data is fetched if not already available.',
      billingDocumentTypeLoader: 'Billing Document Type Loader',
      billingDocumentTypeLoaderDescription:
        'Loads billing document type by ID from the store, retrieving specific fields such as the name ' +
        'or the entire object. Supports flexible data fetching with field selection and ensures data ' +
        'is fetched if not already available.',
      billingPriceTypeLoader: 'Billing Price Type',
      billingPriceTypeLoaderDescription:
        'Transforms billing price type codes into their respective labels. Converts "mesicni" or "MESICNI" ' +
        'to "Měsíční" and "jednorazovy" or "JEDNORAZOVY" to "Jednorázový". Returns an empty string for ' +
        'unrecognized values.',
      billingSapDocumentStatusLoader: 'SAP Document Status Loader',
      billingSapDocumentStatusLoaderDescription:
        'Loads SAP document status by its ID from the store, retrieves specific fields such as the name ' +
        'or the complete object, and ensures the data is fetched if not already available.',
      billingSapDocumentStatusLoaderCode: 'SAP Document Status Loader Code',
      billingSapDocumentStatusLoaderCodeDescription:
        'Loads SAP document status by its Code from the store, retrieves specific fields such as the name ' +
        'or the complete object, and ensures the data is fetched if not already available.',
      billingVatRateLoader: 'Billing Vat Rate Loader',
      billingVatRateLoaderDescription:
        'Fetches billing VAT rate by its ID from the store, retrieves specific fields like the code ' +
        'or the complete object, and ensures the data is fetched if not already available.',
    },
  },
};
