import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {PrivHelperService} from '@tsm/framework/security';

@Directive({
  selector: '[tsmHasPriv]',
})
export class HasPrivDirective implements OnChanges {
  @Input()
  tsmHasPriv: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private privHelperService: PrivHelperService,
  ) {}

  private checkPriv() {
    /**
     * Returns true, if any authority is a prefix of required claim.
     */
    if (
      this.privHelperService.hasPrivilege(
        this.tsmHasPriv,
        this.privHelperService.getPrivileges(),
      )
    ) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const priv = changes['tsmHasPriv'];
    if (priv.currentValue !== priv.previousValue) {
      this.checkPriv();
    }
  }
}
