import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Optional,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  TOOLTIP_DATA,
  TOOLTIP_MAX_WIDTH,
  TOOLTIP_STATIC_POSITION,
  TooltipData,
} from '../tooltip/tsm-tooltip.component';
import {DialogRef} from '@angular/cdk/dialog';
import {translation} from '../../i18n';

@Component({
  selector: 'tsm-tooltip-dialog-component',
  templateUrl: './tsm-tooltip-dialog.component.html',
  styleUrls: ['./tsm-tooltip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'[id]': 'tooltipStaticPosition'},
})
export class TooltipDialogComponent {
  translation = translation;
  maxWidthRem = 45;

  @Output()
  closeTooltip = new EventEmitter();

  get asString(): string | false {
    return typeof this.tooltipData === 'string' ? this.tooltipData : false;
  }

  get asTemplate(): TemplateRef<any> | false {
    return this.tooltipData instanceof TemplateRef ? this.tooltipData : false;
  }

  constructor(
    @Inject(TOOLTIP_DATA) public tooltipData: TooltipData,
    @Optional()
    @Inject(TOOLTIP_STATIC_POSITION)
    public tooltipStaticPosition: string,
    @Optional() @Inject(TOOLTIP_MAX_WIDTH) public tooltipMaxWidthRem: number,
    private dialogRef: DialogRef<string>,
  ) {
    if (tooltipMaxWidthRem) {
      this.maxWidthRem = tooltipMaxWidthRem;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
