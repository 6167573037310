import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {distinctArrays, isUuid} from '@tsm/framework/functions';
import {selectUserImageById, selectUserImageByIds} from '../selectors';
import {LoadBatchUserImageById, LoadUserImageByIds} from '../actions';
import {UserImage} from '../model';
import {SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'userImage',
  pure: true,
})
export class UserImagePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  setObservable(id: any, onlyImage = false) {
    this.destroy();

    if (Array.isArray(id)) {
      const distinctArray = distinctArrays(null, id);
      this._id = distinctArray.filter((x) => !!x);
      this._obs$ = this.store.pipe(
        select(selectUserImageByIds(this._id)),
        filter(
          (it) =>
            it.some((x) => x != null) && !it.some((x) => x.loading == true),
        ),
        map((it) => {
          let avatars = '';
          let moreAvatars = 0;
          if (it.length === 1) {
            if (onlyImage) {
              return `<div class="p-chip p-component p-chip-image">
                          <img src="${this.getImage(
                            it[0].data,
                          )}" class="-mr-3" alt="${it[0].data?.name}">
                      </div>`;
            }
            return `<div class="p-chip p-component p-chip-image">
                        ${this.getImage(it[0].data)}
                        <div class="p-chip-text">${it[0].data?.name}</div>
                    </div>`;
          } else {
            it.forEach((x, index) => {
              if (index <= 4) {
                avatars =
                  avatars +
                  `<div class="p-avatar p-component p-avatar-image p-avatar-circle">
                            ${this.getImage(x.data, null, 'ng-star-inserted')}
                                       </div>`;
              } else {
                moreAvatars = moreAvatars + 1;
              }
            });
            if (moreAvatars != 0) {
              return `<div class="p-avatar-group p-component">${avatars}<div class="p-avatar p-component p-avatar-circle p-more"><span class="p-avatar-text">+${moreAvatars}</span></div></div>`;
            } else {
              return `<div class="p-avatar-group p-component">${avatars}</div>`;
            }
          }
        }),
      );
    } else {
      this._id = id;
      this._obs$ = this.store.pipe(
        select(selectUserImageById(this._id)),
        filter((it) => it != null && it.loading == false),
        map((it) => {
          if (!it.data || it.error != null) {
            return this._id;
          } else {
            if (onlyImage) {
              return `<div class="p-chip p-component p-chip-image">
                          ${this.getImage(it.data)}
                      </div>`;
            }
            return `<div class="p-chip p-component p-chip-image">
                        ${this.getImage(it.data)}
                        <div class="p-chip-text">${it.data['name']}</div>
                    </div>`;
          }
        }),
      );
    }
  }

  getImage(
    user: UserImage,
    style: string = null,
    classes: string = null,
  ): SafeUrl {
    if (user && user.profileImage) {
      return `<img src="${
        'data:' + user.mimeType + ';base64,' + user.profileImage
      }"
                    alt="${user.name}"
                    ${style != null ? 'style=' + style : ''} ${
                      classes != null ? 'class=' + classes : ''
                    }
                    title="${user?.name}">`;
    }
    const initials = user?.name.split(' ')[0][0] + user?.name.split(' ')[1][0];
    return `<div class="p-avatar p-component p-avatar-circle label-circle char-${initials
      ?.charAt(0)
      ?.toLowerCase()}">
                <span class="p-avatar-text">${initials}</span>
            </div>`;
  }

  checkIfDispatchNeeded() {
    if (Array.isArray(this._id)) {
      this._checkSubscription = this.store
        .pipe(select(selectUserImageByIds(this._id)))
        .subscribe((x) => {
          if (x?.length == 0 || this._id?.length != x?.length) {
            untracked(() =>
              this.store.dispatch(LoadUserImageByIds({ids: this._id})),
            );
          }
        });
    } else {
      this._checkSubscription = this.store
        .pipe(select(selectUserImageById(this._id)))
        .subscribe((x) => {
          if (!x && isUuid(this._id)) {
            untracked(() =>
              this.store.dispatch(LoadBatchUserImageById({id: this._id})),
            );
          }
        });
    }
  }
}
