import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {scriptAdapter} from '../reducers/script.reducer';
import {ScriptingState} from '../reducers';

const getScriptingState = createFeatureSelector<ScriptingState>('scripting');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
export const getScriptState = createSelector(
  getScriptingState,
  (scriptingState) => scriptingState.script,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  scriptAdapter.getSelectors(getScriptState);

export const selectScriptByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectAllScripts = createSelector(selectAll, (entities) =>
  entities.map((pack) => pack.data),
);

export const selectScriptById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectScriptByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((entity) => entity && entity.data.code === code),
  );

export const selectScriptsByCodes = (codes: string[]) =>
  createSelector(selectAllScripts, (entities) =>
    entities.filter((entity) => entity && codes.indexOf(entity.code) > -1),
  );
