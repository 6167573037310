export const en = {
  characteristicsService: {
    effects: {
      characteristicSaveSuccess: 'Saving the feature was successful',
      characteristicSaveError: 'An error occurred while saving the feature',
      characteristicValueSaveSuccess:
        'Saving characteristic value was successful',
      characteristicValueSaveError:
        'An error occurred while saving the characteristic value',
      diffCharacteristicsSuccess:
        'Saving characteristic diff finished with success',
      diffCharacteristicsError:
        'Error occurred during saving characteristic diff.',
    },
  },
};
