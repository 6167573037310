<div class="icon-picker-header sticky z-2 mb-5 py-4 px-3 xl:px-0">
  <div class="flex gap-4">
    <div class="flex flex-grow-1 filter gap-4">
      <div class="flex flex-grow-1">
        <div class="flex flex-auto align-items-center">
          <div class="flex flex-none">
            <i class="pi pi-search text-lg mx-2"></i>
          </div>
          <div class="flex-grow-1">
            <!--search bar-->
            <dtl-autoComplete
              (onClear)="onModelChanged()"
              [(ngModel)]="selectedIcon"
              (completeMethod)="filterIcons($event)"
              [dropdown]="false"
              [style]="{width: '100%'}"
              [placeholder]="translation.shared.searchIcon | transloco"
              appendTo="body"
              [type]="'search'"
              [ariaLabel]="translation.shared.searchIcon | transloco"
            >
            </dtl-autoComplete>
          </div>
        </div>
      </div>

      <div class="flex flex-none border-left-2 border-300">&nbsp;</div>

      <div class="flex-none align-content-end w-13rem p-fluid">
        <!--option-listing-->
        <p-dropdown
          [options]="pickerBundles"
          [(ngModel)]="selectedIconBundle"
          optionLabel="name"
          optionValue="code"
          (onChange)="onModelChanged(); clearSearchBar()"
          appendTo="body"
          placeholder="Vyberte skupinu"
          ariaLabel="Vyberte skupinu"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="flex flex-none border-right-2 border-300">&nbsp;</div>

    <dtl-grid class="grid-nogutter align-items-center gap-2">
      <dtl-col fixed>
        <div class="font-thin">
          {{ translationShared.shared.color | transloco }}
        </div>
      </dtl-col>
      <dtl-col class="p-fluid">
        <p-dropdown
          [options]="groupedColors"
          [filter]="true"
          [(ngModel)]="selectedColor"
          placeholder="{{ translationShared.shared.selectColor | transloco }}"
          [group]="true"
          [showClear]="true"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="flex flex-1 align-items-center">
              <ng-container *ngIf="colorType == 'bg'">
                <span
                  class="inline-block"
                  [ngClass]="'bg-' + item.value"
                  style="height: 16px; width: 100%"
                  >&nbsp;</span
                >
              </ng-container>
              <ng-container *ngIf="colorType == 'text'">
                <span class="font-medium" [ngClass]="'text-' + item.value">{{
                  item.label
                }}</span>
              </ng-container>
            </div>
          </ng-template>
        </p-dropdown>
      </dtl-col>
    </dtl-grid>

    <div class="flex flex-none border-right-2 border-300">&nbsp;</div>

    <dtl-grid class="grid-nogutter align-items-center gap-2">
      <dtl-col fixed>
        <div class="font-thin">
          {{ translationShared.shared.size | transloco }}
        </div>
      </dtl-col>
      <dtl-col class="p-fluid">
        <p-dropdown
          [options]="textSizes"
          [(ngModel)]="selectedIconSize"
          optionLabel="name"
          placeholder="{{ translationShared.shared.selectSize | transloco }}"
          [showClear]="true"
        >
          <ng-template let-textSizes pTemplate="item">
            <span [class]="textSizes.code">Aa</span>
          </ng-template>
        </p-dropdown>
      </dtl-col>
    </dtl-grid>
  </div>
</div>

<ng-container *ngIf="selectedIconBundle !== 'all'">
  <!--    ICONS   -->
  <tsm-icon-picker-icon
    *ngIf="isIconSelection"
    [filteredIcons]="filteredIcons"
    [prefix]="prefix"
    (onIconSelected)="onIconSelection($event)"
    [mixed]="false"
  >
  </tsm-icon-picker-icon>

  <!--IMAGES    -->
  <tsm-icon-picker-image
    *ngIf="!isIconSelection"
    [filteredImages]="filteredImages"
    [mixed]="false"
    [imagePathPrefix]="imagePathPrefix"
    (onImageSelected)="copyPathPassPath($event)"
    (onImageBase64Selected)="copyBase64PassNothing($event)"
  >
  </tsm-icon-picker-image>
</ng-container>

<!--ICONS x IMAGES - MIXED-->
<ng-container *ngIf="selectedIconBundle === 'all'">
  <tsm-icon-picker-icon
    [filteredIcons]="filteredIcons"
    [prefix]="prefix"
    (onIconSelected)="onIconSelection($event)"
    [mixed]="true"
  >
  </tsm-icon-picker-icon>

  <tsm-icon-picker-image
    [filteredImages]="filteredImages"
    [mixed]="true"
    [imagePathPrefix]="imagePathPrefix"
    (onImageSelected)="copyPathPassPath($event)"
    (onImageBase64Selected)="copyBase64PassNothing($event)"
  >
  </tsm-icon-picker-image>
</ng-container>
