import {forwardRef, Provider, Type} from '@angular/core';
import {FLUENT_FORMS_INPUT_COMPONENT} from '@tsm/framework/root/di';

export const useParentWidgetProvidersFor = (type: Type<any>) => {
  return [
    {
      provide: FLUENT_FORMS_INPUT_COMPONENT,
      useExisting: forwardRef(() => type),
    },
  ] as Provider[];
};
