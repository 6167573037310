import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';

const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getId = createSelector(
  getRouterState,
  (rs) => (rs as any).state.params['id'],
);

export const isDetail = createSelector(
  getRouterState,
  (rs) => !!(rs as any).state.params['id'],
);

export const isPopupDetail = createSelector(
  getRouterState,
  (rs) =>
    !!(rs as any).state.params['id'] &&
    (rs as any).state.url.includes('/popup/'),
);

export const getUrlSegments = createSelector(
  getRouterState,
  (rs) => (rs as any).state.segments,
);

export const getUrlQueryParams = createSelector(
  getRouterState,
  (rs) => (rs as any).state.queryParams,
);

const selectLayoutState = createFeatureSelector<any>('layout');

export const previousUrlCore = createSelector(
  selectLayoutState,
  (layout) => layout.previousUrl,
);
