import {Inject, Injectable, NgZone, Optional} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {
  MONACO_AFTER_LOAD,
  MONACO_PATH,
  MonacoNamespace,
  WindowWithMonaco,
} from '../models';

declare let window: WindowWithMonaco;

@Injectable({providedIn: 'root'})
export class MonacoEditorLoaderService {
  nodeRequire: any;
  isMonacoLoaded$: BehaviorSubject<MonacoNamespace | null> =
    new BehaviorSubject<MonacoNamespace | null>(null);
  private _monacoPath = 'assets/monaco/vs';
  set monacoPath(value: string) {
    if (value) {
      this._monacoPath = value;
    }
  }

  // TODO: accepting MONACO_PATH might not be necessary ?
  constructor(
    private ngZone: NgZone,
    @Optional() @Inject(MONACO_PATH) public monacoPathConfig: string,
    @Optional()
    @Inject(MONACO_AFTER_LOAD)
    public monacoAfterLoad: ((monaco: MonacoNamespace) => void)[],
  ) {
    if (window.monacoEditorAlreadyInitialized) {
      ngZone.run(() => this.isMonacoLoaded$.next(window.monaco));
      return;
    }

    window.monacoEditorAlreadyInitialized = true;

    if (this.monacoPathConfig) {
      this.monacoPath = this.monacoPathConfig;
    }
    this.loadMonaco();
  }

  loadMonaco() {
    const onGotAmdLoader = () => {
      // Load monaco
      (<any>window).amdRequire = (<any>window).require;
      if (this.nodeRequire) {
        (<any>window).require = this.nodeRequire;
      }
      (<any>window).amdRequire.config({paths: {vs: this._monacoPath}});
      (<any>window).amdRequire(['vs/editor/editor.main'], () => {
        // apply global common extensions
        this.monacoAfterLoadGlobal(window.monaco);

        // apply custom extensions, if defined
        if (this.monacoAfterLoad && Array.isArray(this.monacoAfterLoad)) {
          this.monacoAfterLoad.forEach((afterLoadFunc) =>
            afterLoadFunc(window.monaco),
          );
        }

        this.ngZone.run(() => this.isMonacoLoaded$.next(window.monaco));
      });
    };

    let loaderScript: any = null;
    // Load AMD loader if necessary
    if (!(<any>window).require && !(<any>window).amdRequire) {
      loaderScript = document.createElement('script');
      loaderScript.type = 'text/javascript';
      loaderScript.src = `${this._monacoPath}/loader.js`;
      loaderScript.addEventListener('load', onGotAmdLoader);
      document.body.appendChild(loaderScript);
    } else if (!(<any>window).amdRequire) {
      this.addElectronFixScripts();

      this.nodeRequire = (<any>window).require;
      loaderScript = document.createElement('script');
      loaderScript.type = 'text/javascript';
      loaderScript.src = `${this._monacoPath}/loader.js`;
      loaderScript.addEventListener('load', onGotAmdLoader);
      document.body.appendChild(loaderScript);
    } else {
      onGotAmdLoader();
    }
  }

  addElectronFixScripts() {
    const electronFixScript = document.createElement('script');
    // workaround monaco-css not understanding the environment
    const inlineScript = document.createTextNode('self.module = undefined;');
    // workaround monaco-typescript not understanding the environment
    const inlineScript2 = document.createTextNode(
      'self.process.browser = true;',
    );
    electronFixScript.appendChild(inlineScript);
    electronFixScript.appendChild(inlineScript2);
    document.body.appendChild(electronFixScript);
  }

  /**
   * This is a place for common/global monaco setup
   */
  monacoAfterLoadGlobal(monaco: MonacoNamespace): void {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
    });
  }
}
