import {Pipe, PipeTransform} from '@angular/core';
import {FluentExpressionService} from '../services';
import {of} from 'rxjs';

@Pipe({
  name: 'evalLazyExpression',
  pure: true,
})
export class EvalLazyExpressionPipe implements PipeTransform {
  constructor(private fluentExpresssionService: FluentExpressionService) {}

  transform(value: string, rootFormId: string, row = 0) {
    if (typeof value === 'string' && !value.includes('LAZY{')) {
      return of(value);
    }
    return this.fluentExpresssionService.eval(
      value.replace('LAZY', '$'),
      rootFormId,
      row,
    );
  }
}
