import {Injectable} from '@angular/core';
import {exhaustBy, withLatestCached} from '@tsm/framework/root';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, mergeMap, tap} from 'rxjs/operators';
import {
  ChangeSelectedProfile,
  ChangeSelectedProfileDuringSetup,
  DeleteListingProfile,
  DeleteListingProfileError,
  DeleteListingProfileSuccess,
  DiffListingProfile,
  DiffListingProfileError,
  DiffListingProfileSuccess,
  DisableRefresh,
  InsertListingProfile,
  InsertListingProfileError,
  InsertListingProfileSuccess,
  LoadListingProfile,
  LoadListingProfileByCode,
  LoadListingProfileByCodeAndListingCode,
  LoadListingProfileByCodeAndListingCodeError,
  LoadListingProfileByCodeAndListingCodeSuccess,
  LoadListingProfileByCodeError,
  LoadListingProfileByCodes,
  LoadListingProfileByCodesError,
  LoadListingProfileByCodesSuccess,
  LoadListingProfileByCodeSuccess,
  LoadListingProfileById,
  LoadListingProfileByIdError,
  LoadListingProfileByIdSuccess,
  LoadListingProfileError,
  LoadListingProfileSuccess,
  RefreshDataAndClearSelected,
  RefreshProfilesFor,
  SaveProfileSuccess,
  SelectedProfileChangedSuccess,
  SelectedProfileDuringSetupChangedSuccess,
  StartRefresh,
  UpdateListingProfile,
  UpdateListingProfileError,
  UpdateListingProfileSuccess,
} from '../actions';
import {
  CommonConfigFormApiService,
  ListingProfileService,
  ListingService,
  PageSortFilterService,
} from '../services';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Store} from '@ngrx/store';
import {FilterModel, FilterOperator, ListingProfile} from '../models';
import {Router} from '@angular/router';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {DeleteMenuItemsByIdInUrl} from '@tsm/layout/service';
import {
  selectListingProfileByCodeAndListingCode,
  selectListingProfileById,
} from '../selectors';
import {of} from 'rxjs';

@Injectable()
export class ListingProfileEffects {
  private readonly LISTING_PROFILE_API_PATH = 'listing-profile';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingProfile),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.LISTING_PROFILE_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadListingProfileSuccess({listingProfiles: env.data})
                : LoadListingProfileError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingProfileById),
      withLatestCached((action) =>
        this.store.select(selectListingProfileById(action?.id)),
      ),
      concatMap(([action, {data}]) => {
        if (data && !action?.forcedReload) {
          return of(LoadListingProfileByIdSuccess({listingProfile: data}));
        } else {
          return this.commonApiService
            .getEntity(this.LISTING_PROFILE_API_PATH, action?.id)
            .pipe(
              map((env) =>
                env.success
                  ? LoadListingProfileByIdSuccess({listingProfile: env.data})
                  : LoadListingProfileByIdError({
                      id: action?.id,
                      error: env.error,
                    }),
              ),
            );
        }
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingProfileByCode),
      exhaustBy(
        ({code}) => code,
        ({code}) => {
          return this.commonApiService
            .getEntityByCode(this.LISTING_PROFILE_API_PATH, code)
            .pipe(
              map((env) =>
                env.success && env.data != null
                  ? LoadListingProfileByCodeSuccess({listingProfile: env.data})
                  : LoadListingProfileByCodeError({
                      code: code,
                      error: env.error,
                    }),
              ),
            );
        },
      ),
    ),
  );

  loadByCodeAndListingCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingProfileByCodeAndListingCode),
      withLatestCached(({code, listingCode}) =>
        this.store.select(
          selectListingProfileByCodeAndListingCode(code, listingCode),
        ),
      ),
      concatMap(([{code, listingCode}, state]) => {
        const filters: FilterModel[] = [
          {
            field: 'code',
            value: code,
            operator: FilterOperator.eq,
          },
          {
            field: 'listing.code',
            value: listingCode,
            operator: FilterOperator.eq,
          },
        ];
        return state?.data
          ? of(
              LoadListingProfileByCodeAndListingCodeSuccess({
                listingProfile: state.data as any,
              }),
            )
          : this.commonApiService
              .getAllFilterable(
                this.LISTING_PROFILE_API_PATH,
                this.pageSortFilterService.getUrlFilterFromFilterModels(
                  filters,
                ),
              )
              .pipe(
                map((env) =>
                  env.success
                    ? LoadListingProfileByCodeAndListingCodeSuccess({
                        listingProfile: env.data[0],
                      })
                    : LoadListingProfileByCodeAndListingCodeError({
                        code,
                        listingCode: null,
                        error: env.error,
                      }),
                ),
              );
      }),
    ),
  );

  insert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsertListingProfile),
      concatMap(({listingProfile, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.LISTING_PROFILE_API_PATH,
            listingProfile,
            listingProfile.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.listingProfileService.effects
                    .messageListingProfileChangeSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return InsertListingProfileSuccess({
                  listingProfile: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.listingProfileService.effects
                    .messageListingProfileSaveUnSuccessful,
                );
                return InsertListingProfileError({
                  id: listingId,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateListingProfile),
      concatMap(({listingProfile, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.LISTING_PROFILE_API_PATH,
            listingProfile,
            listingProfile.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.listingProfileService.effects
                    .messageListingProfileChangeSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpdateListingProfileSuccess({
                  listingProfile: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.listingProfileService.effects
                    .messageListingProfileSaveUnSuccessful,
                );
                return UpdateListingProfileError({
                  id: listingId,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  updateListingProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateListingProfileSuccess),
        tap(({listingProfile}) =>
          this.listingService.setRefreshIfPresent(
            null,
            listingProfile.id,
            listingProfile.config?.refresh,
            listingProfile.listing.code,
            true,
          ),
        ),
      ),
    {dispatch: false},
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteListingProfile),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService
          .deleteEntity(this.LISTING_PROFILE_API_PATH, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.listingProfileService.effects
                    .listingProfileDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                if (shouldRedirectToListing) {
                  this.router.navigate(['/config/listing-profile']);
                }
                return DeleteListingProfileSuccess({
                  id: id,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.listingProfileService.effects
                    .listingProfileDeleteError,
                );
                return DeleteListingProfileError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffListingProfile),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/listings-profiles', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffListingProfileSuccess({
                  listingProfiles: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffListingProfileError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAndRedirectAndDeleteMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteListingProfileSuccess),
      mergeMap(({id, listingId}) => [
        RefreshDataAndClearSelected({id: listingId}),
        DeleteMenuItemsByIdInUrl({id: id}),
      ]),
    ),
  );

  refreshDataAfterDiff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffListingProfileSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateListingProfileSuccess, InsertListingProfileSuccess),
      tap(({listingProfile, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/config/listing-profile', listingProfile.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  loadByCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadListingProfileByCodes),
      concatMap(({codes}) =>
        this.listingProfileService
          .getListingProfilesByCodes(codes)
          .pipe(
            map((env) =>
              env.success
                ? LoadListingProfileByCodesSuccess({listingProfiles: env.data})
                : LoadListingProfileByCodesError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  changeSelectedProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeSelectedProfile),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      mergeMap(([action, state]) => {
        return [
          SelectedProfileChangedSuccess({...action}),
          ...(state.selectedProfileId != null
            ? [
                DisableRefresh({
                  id: action.id,
                  profileId: state.selectedProfileId,
                }),
              ]
            : []),
          StartRefresh({id: action.id, profileId: action.selectedProfileId}),
        ];
      }),
    ),
  );

  changeSelectedProfileDuringSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeSelectedProfileDuringSetup),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      mergeMap(([action, state]) => [
        SelectedProfileDuringSetupChangedSuccess({...action}),
        ...(state.selectedProfileId != null
          ? [
              DisableRefresh({
                id: action.id,
                profileId: state.selectedProfileId,
              }),
            ]
          : []),
        StartRefresh({id: action.id, profileId: action.selectedProfileId}),
      ]),
    ),
  );

  refreshListAfterSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveProfileSuccess),
      map(({payload}) =>
        RefreshProfilesFor({id: payload.id, reloadData: payload.reloadData}),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private listingProfileService: ListingProfileService,
    private listingService: ListingService,
    private store: Store,
    private pageSortFilterService: PageSortFilterService,
    private commonApiService: CommonConfigFormApiService<
      ListingProfile,
      ListingProfile
    >,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
