export const colors: Color[] = [
  {name: 'WHITE', flag: 'background', color: '#FFFFFF'},
  {name: 'BLUE', flag: 'background', color: '#b9dcf5'},
  {name: 'PURPLE', flag: 'background', color: '#adaaed'},
  {name: 'GREEN', flag: 'background', color: '#ceeed6'},
  {name: 'YELLOW', flag: 'background', color: '#f6f4e0'},
  {name: 'RED', flag: 'background', color: '#f3b6bf'},
  {name: 'PINK', flag: 'background', color: '#eed0e2'},
  {name: 'ORANGE', flag: 'background', color: '#ecc9bc'},
  {name: 'GRAY', flag: 'background', color: '#eeeeee'},
  {name: 'BLACK', flag: 'background', color: '#1c1c1c'},
  {name: 'DARK_WHITE', flag: 'background', color: '#000000'},
  {name: 'DARK_BLUE', flag: 'background', color: '#4285f4'},
  {name: 'DARK_PURPLE', flag: 'background', color: '#6a0dad'},
  {name: 'DARK_GREEN', flag: 'background', color: '#228B22'},
  {name: 'DARK_YELLOW', flag: 'background', color: '#e3bc08'},
  {name: 'DARK_RED', flag: 'background', color: '#ad2121'},
  {name: 'DARK_PINK', flag: 'background', color: '#e75480'},
  {name: 'DARK_ORANGE', flag: 'background', color: '#ff8c00'},
  {name: 'DARK_GRAY', flag: 'background', color: '#6f6f6f'},
  {name: 'DARK_BLACK', flag: 'background', color: '#000000'},
];

export function findColor(color: string): Color {
  return colors.find(
    (x) =>
      x.color.toLowerCase() === color.toLowerCase() ||
      x.name.toLowerCase() === color.toLowerCase(),
  );
}

/**
 * Pokud je dark rezim, vrati to light barvu a naopak
 * @param color
 */
export function findInvertColor(color: string): Color {
  const originalColor = findColor(color);
  const newColorName = originalColor.name.startsWith('DARK_')
    ? originalColor.name.replace('DARK_', '')
    : 'DARK_' + originalColor.name;
  return colors.find((x) => x.name === newColorName);
}

export interface Color {
  name: string;
  flag: string;
  color: string;
}
