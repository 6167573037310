import {createFeatureSelector, createSelector} from '@ngrx/store';
import {characteristicsValuesAdapter, CharacteristicsState} from '../reducers';

const getConfigFormState =
  createFeatureSelector<CharacteristicsState>('characteristics');

export const getCharacteristicsValuesState = createSelector(
  getConfigFormState,
  (characteristicsValuesState) =>
    characteristicsValuesState.characteristicsValues,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  characteristicsValuesAdapter.getSelectors(getCharacteristicsValuesState);

export const selectCharacteristicsValues = selectAll;

export const selectCharacteristicsValuesById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectCharacteristicsValuesByCharId = (charId: string) =>
  createSelector(selectAll, (entities) =>
    entities
      .map((pack) => pack.data)
      .filter(
        (characteristicsValues) =>
          characteristicsValues.characteristics.id === charId,
      ),
  );

export const selectCharacteristicsValuesNotAsIdentifiedPack = () =>
  createSelector(selectAll, (entities) => entities.map((pack) => pack.data));
