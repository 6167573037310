import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {NotificationRelatedEntity} from '../models';
import {NotificationService} from '../services';

@Pipe({
  name: 'notificationRelatedEntityById',
  pure: true,
})
export class NotificationRelatedEntityByIdPipe implements PipeTransform {
  constructor(private notificationService: NotificationService) {}

  transform(id: string): Observable<NotificationRelatedEntity[]> {
    if (id == null) {
      return of();
    }
    return this.notificationService
      .getNotificationRelatedEntityByIdAndResend(id)
      .pipe(
        map((x) => {
          return x.data;
        }),
      );
  }
}
