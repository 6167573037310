// manual imports
import type * as monaco from 'monaco-editor';

export const MONACO_PATH = 'MONACO_PATH';

export const MONACO_AFTER_LOAD = 'MONACO_AFTER_LOAD';

export type MonacoNamespace = typeof monaco;

export type MonacoEditorConstructionOptions =
  monaco.editor.IEditorConstructionOptions;
export type MonacoStandaloneEditorConstructionOptions =
  monaco.editor.IStandaloneEditorConstructionOptions;
export type MonacoDiffEditorConstructionOptions =
  monaco.editor.IDiffEditorConstructionOptions;
export type MonacoStandaloneCodeEditor = monaco.editor.IStandaloneCodeEditor;
export type MonacoStandaloneDiffEditor = monaco.editor.IStandaloneDiffEditor;
export type MonacoEditorMarker = monaco.editor.IMarker;
export type MonacoEditorModel = monaco.editor.ITextModel;

export interface WindowWithMonaco extends Window {
  monaco: MonacoNamespace;
  monacoEditorAlreadyInitialized?: boolean;
}
