export class MonacoEditorSupportedLanguages {
  getAll(): string[] {
    const languages = [
      'abap',
      'aes',
      'apex',
      'azcli',
      'bat',
      'bicep',
      'c',
      'cameligo',
      'clojure',
      'coffeescript',
      'cpp',
      'csharp',
      'csp',
      'css',
      'dart',
      'dockerfile',
      'ecl',
      'elixir',
      'fsharp',
      'go',
      'graphql',
      'handlebars',
      'hcl',
      'html',
      'ini',
      'java',
      'javascript',
      'json',
      'julia',
      'kotlin',
      'less',
      'lexon',
      'liquid',
      'lua',
      'm3',
      'markdown',
      'mips',
      'msdax',
      'mysql',
      'objective-c',
      'pascal',
      'pascaligo',
      'perl',
      'pgsql',
      'php',
      'plaintext',
      'postiats',
      'powerquery',
      'powershell',
      'pug',
      'python',
      'r',
      'razor',
      'redis',
      'redshift',
      'restructuredtext',
      'ruby',
      'rust',
      'sb',
      'scala',
      'scheme',
      'scss',
      'shell',
      'sol',
      'sql',
      'st',
      'swift',
      'systemverilog',
      'tcl',
      'twig',
      'typescript',
      'vb',
      'verilog',
      'xml',
      'yaml',
    ];

    return languages;
  }
}
