import {merge, Observable} from 'rxjs';
import {distinctUntilChanged, map, scan} from 'rxjs/operators';
import * as objectPath from 'object-path';

/**
 * Postupne nezavisle na case sesbira obserable a sestavi jeden vysledny
 * @param observables - {key: string, observable: Observable<any>, alias?: string (pokud potrebuju stejnou entitu pod vice identifikatorama)}
 */
export function mergeAndScan<ResultType>(
  ...observables: Array<{
    key: string;
    observable: Observable<any>;
    alias?: string;
  }>
): Observable<ResultType> {
  return merge(
    ...observables
      .filter((entity) => entity.key && entity.observable)
      .map((entity) =>
        entity.observable.pipe(
          distinctUntilChanged(),
          map((obs) => ({
            key: entity.key,
            value: obs,
            alias: entity.alias,
          })),
        ),
      ),
  ).pipe(
    scan((acc, curr) => {
      const result = {...acc};
      objectPath.set(result, curr.key, curr.value);
      if (curr.alias) {
        objectPath.set(result, curr.alias, curr.value);
      }
      return result;
    }, {} as ResultType),
  );
}
