export const en = {
  slaPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmSlaTypeFilter: 'tSM Sla Type Filter',
      tsmSlaTypeFilterDescription:
        'Implements an SLA type filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to filter ' +
        'SLA types retrieved from translations. Provides options for selection, ' +
        'removal, and confirmation, using an overlay panel for interaction.',
    },
    pipes: {
      slaLoader: 'Sla Loader',
      slaLoaderDescription:
        'Retrieves an SLA by its ID and extracts its name. If the data is unavailable, ' +
        'returns the provided ID instead.',
      slaMetricCondition: 'Sla Metric Condition',
      slaMetricConditionDescription:
        'Groups SLA metric conditions by type and formats them into a readable string. ' +
        'Conditions are categorized into START, PAUSE, and END, listing their event types. ' +
        'Returns a formatted text representation of these conditions.',
    },
  },
};
