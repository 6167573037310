export const cs = {
  slaPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmSlaTypeFilter: 'tSM Sla Type Filter',
      tsmSlaTypeFilterDescription:
        'Implementuje filtr typu SLA s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, umožňuje uživatelům ' +
        'filtrovat typy SLA načtené z překladů. Poskytuje možnosti výběru, odstranění ' +
        'a potvrzení, s využitím překryvného panelu pro interakci.',
    },
    pipes: {
      slaLoader: 'Sla Loader',
      slaLoaderDescription:
        'Načte SLA podle jeho ID a vrátí jeho název. Pokud data nejsou dostupná, ' +
        'vrátí zadané ID.',
      slaMetricCondition: 'Sla Metric Condition',
      slaMetricConditionDescription:
        'Seskupuje podmínky SLA metrik podle typu a formátuje je do čitelného řetězce. ' +
        'Podmínky jsou rozděleny na START, PAUSE a END, přičemž zobrazují jejich typy událostí. ' +
        'Vrací formátovaný textový výstup těchto podmínek.',
    },
  },
};
