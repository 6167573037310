import {
  isAfter,
  isBefore,
  isEqual,
  min,
  max,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

export class DateHelper {
  static isSameOrBefore = (first, second) => {
    return isBefore(first, second) || isEqual(first, second);
  };

  static differenceTwoDates(
    to: string | Date,
    from: string | Date,
    ignoreZeros?: boolean,
  ): string {
    if (!to || !from) {
      return '';
    }
    let tmpTo: Date = !(to instanceof Date) ? DateHelper.toDate(to) : to;
    let tmpFrom: Date = !(from instanceof Date)
      ? DateHelper.toDate(from)
      : from;

    const start = tmpFrom < tmpTo ? tmpFrom : tmpTo;
    const end = tmpFrom < tmpTo ? tmpTo : tmpFrom;

    const years = differenceInYears(end, start);
    // Subtract the years to get the remaining months
    const months = differenceInMonths(end, start) - years * 12;
    // Subtract the years and months to get the remaining days
    const days = differenceInDays(end, start) - years * 365 - months * 30; // Approximation, might need adjustment based on calendar specifics
    // Calculate hours, minutes, and seconds from the absolute difference in milliseconds
    const totalHours = differenceInHours(end, start);
    const hours = totalHours % 24;
    const totalMinutes = differenceInMinutes(end, start);
    const minutes = totalMinutes % 60;

    if (ignoreZeros) {
      const result =
        (tmpFrom > tmpTo ? '- ' : '') +
        (years > 0 ? years + 'y ' : '') +
        (months > 0 ? months + 'm ' : '') +
        (days > 0 ? days + 'd ' : '') +
        (hours > 0 ? hours + 'h ' : '') +
        (minutes > 0 ? minutes + 'min' : '');
      return result === '- ' || result === '' ? '0min' : result;
    } else {
      return (
        (tmpFrom > tmpTo ? '- ' : '') +
        (years > 0 ? years + 'y ' : '') +
        (months > 0 ? months + 'm ' : '') +
        (days > 0 ? days + 'd ' : '0d ') +
        (hours > 0 ? hours + 'h ' : '0h ') +
        (minutes > 0 ? minutes + 'min' : 0 + 'min')
      );
    }
  }

  static progressTwoDates(end: string | Date, start: string | Date): number {
    if (!end || !start) {
      return 0;
    }
    let tmpEnd = end;
    let tmpStart = start;
    const tmpNow = new Date();
    if (!(end instanceof Date)) {
      tmpEnd = DateHelper.toDate(end);
    }
    if (!(start instanceof Date)) {
      tmpStart = DateHelper.toDate(start);
    }

    const y = 100 / ((tmpEnd as Date).getTime() - (tmpStart as Date).getTime());
    const z = tmpNow.getTime() - (tmpStart as Date).getTime();
    const percent = Math.floor(y * z);
    return percent > 100 ? 100 : percent;
  }

  static toDate(str: string): any {
    return str ? new Date(str) : '';
  }

  static compareDates(a: string | Date, b: string | Date): number {
    let tmpA = a;
    let tmpB = b;
    if (!(a instanceof Date)) {
      tmpA = DateHelper.toDate(a);
    }
    if (!(b instanceof Date)) {
      tmpB = DateHelper.toDate(b);
    }
    return +tmpA - +tmpB > 0 ? -1 : 1;
  }

  static compareDateTimes(a: string | Date, b: string | Date): number {
    const aDate = typeof a === 'string' ? new Date(a) : a;
    const bDate = typeof b === 'string' ? new Date(b) : b;

    if (isBefore(aDate, bDate)) {
      return -1;
    } else if (isAfter(aDate, bDate)) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * Porovna datum az na uroven sekund
   */
  static isSameTime(a: string | Date, b: string | Date): boolean {
    let result = false;

    if (a == null || b == null) {
      return result;
    }
    let tmpA = a;
    let tmpB = b;
    if (!(a instanceof Date)) {
      tmpA = DateHelper.toDate(a);
    }
    if (!(b instanceof Date)) {
      tmpB = DateHelper.toDate(b);
    }

    if (
      parseInt(((tmpA as Date).getTime() / 1000).toString()) ===
      parseInt(((tmpB as Date).getTime() / 1000).toString())
    ) {
      result = true;
    }

    // if ((tmpA as Date).toDateString() == (tmpB as Date).toDateString()) {
    //   if ((tmpA as Date).getHours() == (tmpB as Date).getHours()) {
    //     if ((tmpA as Date).getMinutes() == (tmpB as Date).getMinutes()) {
    //       if ((tmpA as Date).getSeconds() == (tmpB as Date).getSeconds()) {
    //         result = true;
    //       }
    //     }
    //   }
    // }
    return result;
  }

  static minDate(array: string[] | Date[]): Date {
    if (array == null || array.length === 0) {
      return null;
    }
    const convertedValues: Date[] = [];
    array.forEach((v) => {
      if (!(v instanceof Date)) {
        convertedValues.push(DateHelper.toDate(v));
      } else {
        convertedValues.push(v);
      }
    });
    return min(convertedValues);
  }

  static maxDate(array: string[] | Date[]): Date {
    if (array == null || array.length === 0) {
      return null;
    }
    const convertedValues: Date[] = [];
    array.forEach((v) => {
      if (!(v instanceof Date)) {
        convertedValues.push(DateHelper.toDate(v));
      } else {
        convertedValues.push(v);
      }
    });
    return max(convertedValues);
  }

  /**
   * Formát HH:mm:ss, př. 3735 => 01:02:15
   */
  // fixme prepis po vzoru ConvertMinutesToHoursMinutesPipe
  static convertSecondsToHourMinuteSecond(seconds: number): string {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds - hour * 3600) / 60);
    const sec = Math.floor(seconds - (hour * 3600 + min * 60));
    return `${hour.toString().padStart(2, '0')}:${min
      .toString()
      .padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  }

  // fixme prepis po vzoru ConvertMinutesToHoursMinutesPipe
  static convertMinutesToDayHourMinute(
    minutes: number,
    ignoreZeros?: boolean,
  ): string {
    const d = Math.floor(minutes / 1440);
    const minusDays = d > 0 ? d * 1440 : 0;
    const h = Math.floor((minutes - minusDays) / 60);
    const min = minutes % 60;
    if (ignoreZeros) {
      if (minutes < 0) {
        return (
          '-' +
          (d < 0 ? (d + '').replace('-', '') + 'd ' : '') +
          (h < 0 ? (h + '').replace('-', '') + 'h ' : '') +
          (min < 0 ? (min + '').replace('-', '') + 'min' : '')
        );
      }
      return (
        (d > 0 ? d + 'd ' : '') +
        (h > 0 ? h + 'h ' : '') +
        (min > 0 ? min + 'min' : '')
      );
    } else {
      if (minutes < 0) {
        return (
          '-' +
          (d < 0 ? (d + '').replace('-', '') + 'd ' : '0d ') +
          (h < 0 ? (h + '').replace('-', '') + 'h ' : '0h ') +
          (min < 0 ? (min + '').replace('-', '') + 'min' : 0 + 'min')
        );
      }
      return (
        (d > 0 ? d + 'd ' : '0d ') +
        (h > 0 ? h + 'h ' : '0h ') +
        (min > 0 ? min + 'min' : 0 + 'min')
      );
    }
  }

  // fixme prepis po vzoru ConvertMinutesToHoursMinutesPipe
  static convertMinutesToWorkdayHourMinute(
    minutes: number,
    ignoreZeros?: boolean,
  ): string {
    const d = Math.floor(minutes / 480); // 8 * 60
    const minusDays = d > 0 ? d * 480 : 0;
    const h = Math.floor((minutes - minusDays) / 60);
    const min = minutes % 60;
    if (ignoreZeros) {
      if (minutes < 0) {
        return (
          '-' +
          (d < 0 ? (d + '').replace('-', '') + 'd ' : '') +
          (h < 0 ? (h + '').replace('-', '') + 'h ' : '') +
          (min < 0 ? (min + '').replace('-', '') + 'min' : '')
        );
      }
      return (
        (d > 0 ? d + 'd ' : '') +
        (h > 0 ? h + 'h ' : '') +
        (min > 0 ? min + 'min' : '')
      );
    } else {
      if (minutes < 0) {
        return (
          '-' +
          (d < 0 ? (d + '').replace('-', '') + 'd ' : '0d ') +
          (h < 0 ? (h + '').replace('-', '') + 'h ' : '0h ') +
          (min < 0 ? (min + '').replace('-', '') + 'min' : 0 + 'min')
        );
      }
      return (
        (d > 0 ? d + 'd ' : '0d ') +
        (h > 0 ? h + 'h ' : '0h ') +
        (min > 0 ? min + 'min' : 0 + 'min')
      );
    }
  }
}
