export const en = {
  catalogPlugins: {
    headerDescription: 'Header docs',
    instantiable: 'Enable instantiation',
    priceDetail: 'Price list',
    catalogRelations: 'Catalog relations',
    catalogPrivs: 'Catalog privileges',
    orderLine: {
      entitySpecificationInstance: 'Order line configuration profile',
    },
    filters: {
      tsmCatalogCategoryFilter: 'tSM Catalog Category Filter',
      tsmCatalogCategoryFilterDescription:
        'Provides an interface for filtering catalog categories with dynamic options. ' +
        'Supports filtering by name, code, and entity type, allowing multi-selection.',
      tsmCatalogCodeFilter: 'tSM Catalog Code Filter',
      tsmCatalogCodeFilterDescription:
        'Provides an interface for filtering catalogs by code and name dynamically. ' +
        'Supports multi-selection and allows filtering based on catalog type.',
      tsmCatalogEntityTypeFilter: 'tSM Catalog Entity Type Filter',
      tsmCatalogEntityTypeFilterDescription:
        'Provides an interface for filtering catalog entity types dynamically. ' +
        'Retrieves available types and supports multi-selection for flexibility.',
      tsmCatalogSpecificationFilter: 'tSM Catalog Specification Filter',
      tsmCatalogSpecificationFilterDescription:
        'Provides an interface for filtering catalog specifications dynamically. ' +
        'Supports filtering by name, code, and entity type, allowing multi-selection.',
      tsmCatalogSpecificationStatusFilter:
        'tSM Catalog Specification Status Filter',
      tsmCatalogSpecificationStatusFilterDescription:
        'Provides an interface for filtering catalog specification statuses dynamically. ' +
        'Supports filtering by code and name, allowing multi-selection.',
      tsmCatalogSpecificationTypeFilter:
        'tSM Catalog Specification Type Filter',
      tsmCatalogSpecificationTypeFilterDescription:
        'Provides an interface for filtering catalog specification types dynamically. ' +
        'Supports filtering by name and code, allowing multi-selection.',
      tsmCatalogStatusFilter: 'tSM Catalog Status Filter',
      tsmCatalogStatusFilterDescription:
        'Enables filtering of catalog statuses using a multi-selection interface. ' +
        'Filters entries by code and name dynamically.',
      tsmCatalogTypeFilter: 'tSM Catalog Type Filter',
      tsmCatalogTypeFilterDescription:
        'Provides a multi-selection filter for catalog types. ' +
        'Fetches available catalog types and applies filtering ' +
        'based on user selection.',
    },
    pipes: {
      catalogCategoryCode: 'Catalog Category Code',
      catalogCategoryCodeDescription:
        'Fetches catalog category data by its code and associated catalog code from the store, retrieves ' +
        'specific fields like the name or the complete object, and ensures the data is fetched if not already present.',
      catalogCategory: 'Catalog Category',
      catalogCategoryDescription:
        'Fetches catalog category data by its ID from the store, retrieves specific fields like the name ' +
        'or the complete object, and ensures the data is fetched if not already present.',
      catalogCode: 'Catalog Code',
      catalogCodeDescription:
        'Fetches catalog data by its code from the store, retrieves specific fields like the name ' +
        'or the complete object, and ensures the data is fetched if not already present.',
      catalogSpecificationLivecycle: 'Catalog Specification Livecycle',
      catalogSpecificationLivecycleDescription:
        'Formats catalog specification data based on its lifecycle status, applying visual styles ' +
        'to indicate whether the status is "ACTIVE", "INACTIVE", or another state.',
      catalogSpecificationStatusCode: 'Catalog Specification Status Code',
      catalogSpecificationStatusCodeDescription:
        'Fetches catalog specification status by code from the store, retrieves specific fields such as the ' +
        'name or the complete object, and ensures the data is fetched if not already present.',
      catalogSpecificationStatus: 'Catalog Specification Status',
      catalogSpecificationStatusDescription:
        'Fetches catalog specification status by ID from the store, retrieves specific fields such as the name ' +
        'or the complete object, and ensures the data is fetched if not already present.',
      catalogStatus: 'Catalog Status',
      catalogStatusDescription:
        'Fetches catalog status by ID from the store, retrieves specific fields like the name or the full object, ' +
        'and ensures data is loaded if not already available.',
      catalog: 'Catalog',
      catalogDescription:
        'Fetches catalog data by ID from the store, retrieves specific fields such as the name or the entire object, ' +
        'and ensures the data is loaded if not already present in the store.',
      entityCatalogSpecCode: 'Entity Catalog Spec Code',
      entityCatalogSpecCodeDescription:
        'Fetches catalog specification data by code from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is loaded if not already present in the store.',
      entityCatalogSpec: 'Entity Catalog Spec',
      entityCatalogSpecDescription:
        'Retrieves catalog specification data by ID from the store, fetches specific fields such as the name ' +
        'or the entire object, and ensures the data is loaded if missing from the store.',
      entityCatalogSpecTypeCode: 'Entity Catalog Spec Type Code',
      entityCatalogSpecTypeCodeDescription:
        'Retrieves catalog specification type data by code from the store, fetches specific fields such as ' +
        'the name or the entire object, and ensures the data is loaded if missing from the store.',
      entityCatalogSpecType: 'Entity Catalog Spec Type',
      entityCatalogSpecTypeDescription:
        'Retrieves catalog specification type data by ID from the store, fetches specific fields such as the ' +
        'name or the entire object, and ensures the data is loaded if missing from the store.',
      priceBookItem: 'Price Book Item',
      priceBookItemDescription:
        'Processes a list of price book items, filters them by a specific price book ID, and formats the ' +
        'result with translated labels for recurring charge prices and periods. Returns an empty string ' +
        'if the value or arguments are invalid.',
      relationshipArrow: 'Relationship Arrow',
      relationshipArrowDescription:
        'Generates an HTML representation of an arrow icon based on the relationship direction in the ' +
        'provided row data. Adds a forward arrow if the relationship is outgoing, a backward arrow if ' +
        'it is incoming, and no arrow if no relationship is present.',
      relationshipCategorySpecificationIcon:
        'Relationship Category Specification Icon',
      relationshipCategorySpecificationIconDescription:
        'Displays an HTML representation for a relationship icon. If the target entity is categorized, ' +
        'it uses a blue background. Otherwise, it uses a green background to indicate a non-categorized ' +
        'entity.',
      RelationshipCodeLink: 'Relationship Code Link',
      RelationshipCodeLinkDescription:
        'Generates an HTML anchor tag for the relationship code. If the target entity is not categorized, ' +
        'it creates a link to the catalog specification. If the target entity is categorized, it creates ' +
        'a link to the catalog category. If the target entity does not match these conditions, it returns ' +
        'the plain ID.',
      RelationshipSpecificationState: 'Relationship Specification State',
      RelationshipSpecificationStateDescription:
        'Loads the lifecycle status of a catalog specification by ID. If the status is active, it displays ' +
        'the status name with a green background. If inactive, it uses a red background. For other statuses, ' +
        'it uses a gray background. Returns an empty string if no data is available.',
    },
  },
};
