import {Plugin} from '@tsm/framework/plugin';
import {ConfigUrlDataSource} from '@tsm/shared';
import {translation as translationPlugin} from '@tsm/shared/plugins-i18n';

export const sharedPlugin: Plugin = {
  menus: [
    {
      key: 'settings',
      priority: 400,
      label: 'menu.settings.settings',
      icon: 'tsm-icon-tools',
      privilege: [
        'Settings.View', // smazat jednou?
        'Config.BackupSet.ViewList',
        'Config.BackupSet.Import.ViewList',
        'Config.Mnemonics.ViewList',
        'Config.ConfigType.ViewList',
        'Config.EntityType.ViewList',
        'Config.Microservice.ViewList',
        'Config.TsmModule.ViewList',
        'Config.Swagger',
        'Config.Listing.ListingProfile.ViewList',
        'Config.Listing.ListingColumn.ViewList',
        'Config.Listing.ListingType.ViewList',
        'Config.Listing.ValueConverterConfig.ViewList',
        'Cal.Calendar.ViewList',
        '!Settings.Script.Groovy.Console',
        '!Settings.Script.TQL.Console',
        'Cal.Registers.EventType.ViewList',
        'Settings.Reporting',
        'Am.Region',
        'Am.Layer',
      ],
      items: [
        {
          key: 'sla-list',
          label: 'menu.settings.sla',
          icon: 'camera_front',
          routerLink: ['/sla-list'],
          privilege: ['Tick.Sla.View'],
        },
        {
          key: 'listing',
          label: 'menu.listing.listing',
          icon: 'tsm-icon-table',
          privilege: [
            'Config.Listing.ListingProfile.ViewList',
            'Config.Listing.ListingColumn.ViewList',
            'Config.Listing.ListingType.ViewList',
            'Config.Listing.ValueConverterConfig.ViewList',
          ],
          items: [
            {
              key: 'tsm-listing-column-list',
              label: 'menu.settings.tableColumnConfiguration',
              icon: 'tsm-icon-edit-columns-1',
              routerLink: ['/config/listing-column'],
              listingType: 'tsm-table-column-config-list',
              privilege: ['Config.Listing.ListingColumn.ViewList'],
            },
            {
              key: 'tsm-listing-type-list',
              label: 'menu.settings.listingType',
              icon: 'tsm-icon-edit-columns-1',
              routerLink: ['/config/listing-type'],
              listingType: 'tsm-table-type-config-list',
              privilege: ['Config.Listing.ListingType.ViewList'],
            },
            {
              key: 'tsm-listing-profile-list',
              label: 'menu.settings.listingProfile',
              icon: 'tsm-icon-edit-columns-1',
              routerLink: ['/config/listing-profile'],
              listingType: 'tsm-table-profile-config-list',
              privilege: ['Config.Listing.ListingProfile.ViewList'],
            },
            {
              key: 'tsm-listing-value-converter-config-list',
              label: 'menu.settings.valueConverterConfig',
              icon: 'tsm-icon-edit-columns-1',
              routerLink: ['/config/value-converter-config'],
              listingType: 'tsm-value-converter-config-list',
              privilege: ['Config.Listing.ValueConverterConfig.ViewList'],
            },
          ],
        },
        {
          key: 'calendar-system',
          label: 'menu.settings.calendarManagement',
          icon: 'tsm-icon-calendar',
          privilege: [
            'Cal.Calendar.ViewList',
            'Cal.Registers.EventType.ViewList',
            'Cal.CalendarTemplate.ViewList',
          ],
          items: [
            {
              key: 'calendar-list',
              label: 'menu.settings.calendars',
              icon: 'p-icon-today',
              routerLink: ['/calendar/calendar'],
              listingType: 'tsm-calendar-list',
              privilege: ['Cal.Calendar.ViewList'],
            },
            {
              key: 'EVENT_TYPE',
              label: 'menu.settings.calendarEventType',
              icon: 'p-icon-event',
              routerLink: ['/calendar/event-type'],
              privilege: ['Cal.Registers.EventType.ViewList'],
            },
            {
              key: 'calendar-template',
              label: 'menu.settings.calendarTemplate',
              icon: 'p-icon-event',
              routerLink: ['/calendar/calendar-template'],
              privilege: ['Cal.CalendarTemplate.ViewList'],
            },
          ],
        },
        {
          key: 'address-management-system',
          label: 'menu.settings.addressManagement',
          icon: 'tsm-icon-address',
          privilege: ['Am.Region.ViewList', 'Am.Layer.ViewList'],
          items: [
            {
              key: 'address-management-regions',
              label: 'menu.addressManagement.regions',
              icon: 'tsm-icon-regions',
              routerLink: ['/address-management/regions'],
              privilege: ['Am.Region.ViewList'],
              priority: 0,
            },
            {
              key: 'address-management-layers',
              label: 'menu.addressManagement.layers',
              icon: 'tsm-icon-regions',
              routerLink: ['/address-management/layers'],
              privilege: ['Am.Layer.ViewList'],
              priority: 1000,
            },
          ],
        },
        {
          key: 'dms',
          label: 'menu.notifications.notifications',
          icon: 'tsm-icon-notification',
          privilege: [
            'Dms.Notification.View',
            'Dms.NotificationTemplate.View',
            'Dms.NotificationRule.View',
          ],
          items: [
            {
              key: 'notification',
              label: 'menu.notifications.notifications',
              icon: 'tsm-icon-notification',
              routerLink: ['/dms/notification'],
              privilege: ['Dms.Notification.View'],
              priority: 0,
            },
            {
              key: 'notificationTemplate',
              label: 'menu.notifications.notificationTemplates',
              icon: 'tsm-icon-design',
              routerLink: ['/dms/notification-template'],
              privilege: ['Dms.NotificationTemplate.View'],
              priority: 0,
            },

            {
              key: 'NOTIFICATION_REGISTERS',
              label: 'menu.notifications.registers',
              icon: 'tsm-icon-registers-1',
              privilege: ['true'],
              items: [
                {
                  key: 'DELIVERY_TIME',
                  label: 'menu.notifications.deliveryTime',
                  icon: 'tsm-icon-menu-item',
                  listingType: 'tsm-delivery-time',
                  routerLink: ['/dms/delivery-time'],
                  privilege: ['true'],
                },
                {
                  key: 'notificationRule',
                  label: 'menu.notifications.notificationRules',
                  icon: 'tsm-icon-menu-item',
                  routerLink: ['/dms/notification-rule'],
                  privilege: ['Dms.NotificationRule.View'],
                  priority: 0,
                },
              ],
            },
          ],
        },
        {
          key: 'om',
          label: 'menu.outputManagement.outputManagement',
          icon: 'tsm-icon-email',
          privilege: [
            'Om.formatterTemplate.View',
            'Om.formatterDocumentTemplate.View',
          ],
          items: [
            {
              key: 'formatterTemplate',
              label: 'menu.outputManagement.templates',
              icon: 'tsm-icon-design',
              routerLink: ['/dms/formatter-template'],
              privilege: ['Om.formatterTemplate.View'],
              priority: 0,
            },
            {
              key: 'formatterDocumentTemplate',
              label: 'menu.outputManagement.documentTemplates',
              icon: 'tsm-icon-layout-1',
              routerLink: ['/dms/formatter-document-template'],
              privilege: ['Om.formatterDocumentTemplate.View'],
              priority: 0,
            },
          ],
        },
        {
          key: 'log',
          priority: 1200,
          label: 'menu.logging.logging',
          icon: 'tsm-icon-logging',
          privilege: ['Log.ViewList'],
          items: [
            {
              key: 'ticketing',
              label: 'menu.logging.logging',
              icon: 'tsm-icon-logging',
              routerLink: ['/logging/listing'],
              listingType: 'tsm-logging',
              privilege: ['Log.ViewList'],
            },
            {
              key: 'LOG_REGISTERS',
              label: 'menu.logging.registers',
              icon: 'tsm-icon-registers-1',
              privilege: ['Log.Registers'],
              items: [
                {
                  key: 'LOG_TYPE',
                  label: 'menu.logging.logType',
                  icon: 'tsm-icon-menu-item',
                  routerLink: ['/logging/log-type'],
                  privilege: ['true'],
                },
              ],
            },
          ],
        },
        {
          key: 'TQL_REPORTING',
          priority: 1200,
          label: 'menu.settings.reporting',
          icon: 'tsm-icon-megaphone',
          routerLink: ['/reporting/designer'],
          privilege: ['Settings.Reporting'],
        },
        {
          key: 'scripting',
          priority: 1200,
          label: 'menu.settings.scripting.scripting',
          icon: 'tsm-icon-command-line',
          privilege: [
            '!Settings.Script.Groovy.Console',
            '!Settings.Script.TQL.Console',
            '!Settings.Script.Python.Console',
            '!Settings.Script.Spel.Console',
            '!Settings.Script.Spel.ViewList',
          ],
          items: [
            {
              key: 'GROOVY_CONSOLE',
              label: 'menu.settings.scripting.groovyConsole',
              icon: 'tsm-icon-script-3',
              routerLink: ['/scripting/groovy-console'],
              privilege: ['!Settings.Script.Groovy.Console'],
              priority: 0,
            },
            {
              key: 'SPEL_CONSOLE',
              label: 'menu.settings.scripting.spelConsole',
              icon: 'tsm-icon-script-3',
              routerLink: ['/scripting/spel-console'],
              privilege: ['!Settings.Script.Spel.Console'],
              priority: 0,
            },
            {
              key: 'SPEL',
              label: 'menu.settings.scripting.spelScript',
              icon: 'tsm-icon-script-3',
              routerLink: ['/scripting/spel'],
              privilege: [
                '!Settings.Script.Spel.ViewList',
                '!Settings.Script.Spel.Console',
              ],
              priority: 0,
            },
            {
              key: 'PYTHON_SCRIPT_CONSOLE',
              label: 'menu.settings.scripting.pythonScriptConsole',
              icon: 'tsm-icon-script-3',
              routerLink: ['/scripting/python-script-console'],
              privilege: ['!Settings.Script.Python.Console'],
              priority: 0,
            },
            {
              key: 'TQL_CONSOLE',
              label: 'menu.settings.scripting.tqlConsole',
              icon: 'tsm-icon-data-analysis',
              routerLink: ['/scripting/tql-console'],
              privilege: ['!Settings.Script.TQL.Console'],
              priority: 0,
            },
          ],
        },
        {
          key: 'KAFKA_DLQ',
          label: 'menu.dms.kafkaDlq',
          icon: 'tsm-icon-mobility',
          routerLink: ['dms/kafka-dlq'],
          privilege: ['!Settings.KafkaDlq.ViewList'],
          priority: 0,
        },
        {
          key: 'CONFIG_TYPE',
          label: 'menu.configForm.configType',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/config-type'],
          privilege: ['Config.ConfigType.ViewList'],
        },
        {
          key: 'TSM_MODULE',
          label: 'menu.configForm.tsmModule',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/tsmmodule'],
          privilege: ['Config.TsmModule.ViewList'],
        },
        {
          key: 'MICROSERVICE',
          label: 'menu.configForm.microservice',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/microservice'],
          privilege: ['Config.Microservice.ViewList'],
        },
        {
          key: 'ENTITY_TYPE',
          label: 'menu.configForm.entityType',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/entity-type/entity-type'],
          privilege: ['Config.EntityType.ViewList'],
        },
        {
          key: 'ATTACHMENT_TYPE',
          label: 'menu.dms.attachmentType',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/dms/attachment-types'],
          privilege: ['Settings.AttachType.View'],
        },
        {
          key: 'COMMENT_TYPE',
          label: 'menu.dms.commentType',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/dms/comment-types'],
          privilege: ['Settings.CommentType.View'],
        },
        {
          key: 'WORKLOG',
          label: 'menu.dms.worklog',
          icon: 'tsm-icon-work-time-2',
          routerLink: ['/dms/worklog-types'],
          privilege: ['Dms.Worklogs.View'],
        },
        {
          key: 'WORKLOG_TYPE',
          label: 'menu.dms.worklogType',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/dms/worklog-types'],
          privilege: ['Settings.WorklogType.View'],
        },
        {
          key: 'MNEMONIC_CONFIG',
          label: 'menu.configForm.mnemonics',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/mnemonic-config'],
          privilege: ['Config.Mnemonics.ViewList'],
        },
        {
          key: 'SWAGGER',
          label: 'menu.configForm.tsmPublicApi',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/swagger'],
          privilege: ['Config.Swagger'],
        },
        {
          key: 'CSS_UTILS',
          label: 'menu.configForm.cssUtils',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/css-utils'],
          privilege: ['true'],
        },
        {
          key: 'DBBRIDGE',
          label: 'menu.configForm.dbbridge',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/config/dbbridge'],
          privilege: ['If.dbBridge.reload'],
        },
        {
          key: 'backup-set',
          label: 'menu.configForm.backupSet',
          routerLink: ['/config/backup-set'],
          privilege: ['Config.BackupSet.ViewList'],
        },
        {
          key: 'backup-set-imports',
          label: 'menu.configForm.backupSetImports',
          routerLink: ['/config/backup-set/import'],
          privilege: ['Config.BackupSet.Import.ViewList'],
        },
      ],
    },
  ],
  routes: [],
  widgets: [
    {
      loadChildren: () => import('@tsm/shared').then((x) => x.SharedModule),
      definitions: [
        {
          selector: 'tsm-register-default-card',
          loadComponent: () =>
            import('@tsm/shared/register-default-card').then(
              (x) => x.RegisterDefaultCardComponent,
            ),
        },
        {
          selector: 'tsm-preview-button',
        },
        {
          selector: 'tsm-preview-dropdown',
        },
        {
          selector: 'tsm-data-tags',
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () => import('@tsm/shared').then((x) => x.SharedModule),
      definitions: [
        {
          selector: 'booleanIcon',
          name: translationPlugin.sharedPlugins.pipes.booleanIcon,
          description:
            translationPlugin.sharedPlugins.pipes.booleanIconDescription,
        },
        {
          selector: 'booleanTranslate',
          name: translationPlugin.sharedPlugins.pipes.booleanTranslate,
          description:
            translationPlugin.sharedPlugins.pipes.booleanTranslateDescription,
        },
        {
          selector: 'clipboard',
          name: translationPlugin.sharedPlugins.pipes.clipboard,
          description:
            translationPlugin.sharedPlugins.pipes.clipboardDescription,
        },
        {
          selector: 'colorPipe',
          name: translationPlugin.sharedPlugins.pipes.colorPipe,
          description:
            translationPlugin.sharedPlugins.pipes.colorPipeDescription,
        },
        {
          selector: 'constructCharsForNew',
          name: translationPlugin.sharedPlugins.pipes.constructCharsForNew,
          description:
            translationPlugin.sharedPlugins.pipes
              .constructCharsForNewDescription,
        },
        {
          selector: 'dataTagsArray',
          name: translationPlugin.sharedPlugins.pipes.dataTagsArray,
          description:
            translationPlugin.sharedPlugins.pipes.dataTagsArrayDescription,
        },
        {
          selector: 'dateFormatPipe',
          name: translationPlugin.sharedPlugins.pipes.dateFormatPipe,
          description:
            translationPlugin.sharedPlugins.pipes.dateFormatPipeDescription,
        },
        {
          selector: 'datesDiff',
          name: translationPlugin.sharedPlugins.pipes.datesDiff,
          description:
            translationPlugin.sharedPlugins.pipes.datesDiffDescription,
        },
        {
          selector: 'decimalNumberFormat',
          name: translationPlugin.sharedPlugins.pipes.decimalNumberFormat,
          description:
            translationPlugin.sharedPlugins.pipes
              .decimalNumberFormatDescription,
        },
        {
          selector: 'number',
          name: translationPlugin.sharedPlugins.pipes.number,
          description: translationPlugin.sharedPlugins.pipes.numberDescription,
        },
        {
          selector: 'description',
          name: translationPlugin.sharedPlugins.pipes.description,
          description:
            translationPlugin.sharedPlugins.pipes.descriptionDescription,
        },
        {
          selector: 'domPurify',
          name: translationPlugin.sharedPlugins.pipes.domPurify,
          description:
            translationPlugin.sharedPlugins.pipes.domPurifyDescription,
        },
        {
          selector: 'emptyOrNotEmptyTranslate',
          name: translationPlugin.sharedPlugins.pipes.emptyOrNotEmptyTranslate,
          description:
            translationPlugin.sharedPlugins.pipes
              .emptyOrNotEmptyTranslateDescription,
        },
        {
          selector: 'enumToValue',
          name: translationPlugin.sharedPlugins.pipes.enumToValue,
          description:
            translationPlugin.sharedPlugins.pipes.enumToValueDescription,
        },
        {
          selector: 'externalLink',
          name: translationPlugin.sharedPlugins.pipes.externalLink,
          description:
            translationPlugin.sharedPlugins.pipes.externalLinkDescription,
        },
        {
          selector: 'filter',
          name: translationPlugin.sharedPlugins.pipes.filter,
          description: translationPlugin.sharedPlugins.pipes.filterDescription,
        },
        {
          selector: 'find',
          name: translationPlugin.sharedPlugins.pipes.find,
          description: translationPlugin.sharedPlugins.pipes.findDescription,
        },
        {
          selector: 'flattenExceptKeys',
          name: translationPlugin.sharedPlugins.pipes.flattenExceptKeys,
          description:
            translationPlugin.sharedPlugins.pipes.flattenExceptKeysDescription,
        },
        {
          selector: 'iconImg',
          name: translationPlugin.sharedPlugins.pipes.iconImg,
          description: translationPlugin.sharedPlugins.pipes.iconImgDescription,
        },
        {
          selector: 'join',
          name: translationPlugin.sharedPlugins.pipes.join,
          description: translationPlugin.sharedPlugins.pipes.joinDescription,
        },
        {
          selector: 'map',
          name: translationPlugin.sharedPlugins.pipes.map,
          description: translationPlugin.sharedPlugins.pipes.mapDescription,
        },
        {
          selector: 'moduleIcon',
          name: translationPlugin.sharedPlugins.pipes.moduleIcon,
          description:
            translationPlugin.sharedPlugins.pipes.moduleIconDescription,
        },
        {
          selector: 'numberToFixed',
          name: translationPlugin.sharedPlugins.pipes.numberToFixed,
          description:
            translationPlugin.sharedPlugins.pipes.numberToFixedDescription,
        },
        {
          selector: 'objectPathGet',
          name: translationPlugin.sharedPlugins.pipes.objectPathGet,
          description:
            translationPlugin.sharedPlugins.pipes.objectPathGetDescription,
        },
        {
          selector: 'removeHtmlElements',
          name: translationPlugin.sharedPlugins.pipes.removeHtmlElements,
          description:
            translationPlugin.sharedPlugins.pipes.removeHtmlElementsDescription,
        },
        {
          selector: 'safeEval',
          name: translationPlugin.sharedPlugins.pipes.safeEval,
          description:
            translationPlugin.sharedPlugins.pipes.safeEvalDescription,
        },
        {
          selector: 'safeHtml',
          name: translationPlugin.sharedPlugins.pipes.safeHtml,
          description:
            translationPlugin.sharedPlugins.pipes.safeHtmlDescription,
        },
        {
          selector: 'safe',
          name: translationPlugin.sharedPlugins.pipes.safe,
          description: translationPlugin.sharedPlugins.pipes.safeDescription,
        },
        {
          selector: 'sanitizeNull',
          name: translationPlugin.sharedPlugins.pipes.sanitizeNull,
          description:
            translationPlugin.sharedPlugins.pipes.sanitizeNullDescription,
        },
        {
          selector: 'shallowMerge',
          name: translationPlugin.sharedPlugins.pipes.shallowMerge,
          description:
            translationPlugin.sharedPlugins.pipes.shallowMergeDescription,
        },
        {
          selector: 'translatePipe',
          name: translationPlugin.sharedPlugins.pipes.translatePipe,
          description:
            translationPlugin.sharedPlugins.pipes.translatePipeDescription,
        },
        {
          selector: 'trimTextPipe',
          name: translationPlugin.sharedPlugins.pipes.trimTextPipe,
          description:
            translationPlugin.sharedPlugins.pipes.trimTextPipeDescription,
        },
        {
          selector: 'formatPhone',
          name: translationPlugin.sharedPlugins.pipes.formatPhone,
          description:
            translationPlugin.sharedPlugins.pipes.formatPhoneDescription,
        },
        {
          selector: 'currency',
          name: translationPlugin.sharedPlugins.pipes.currency,
          description:
            translationPlugin.sharedPlugins.pipes.currencyDescription,
        },
      ],
    },
  ],
  dataSources: [
    {
      dataSource: ConfigUrlDataSource,
      name: 'configUi',
      initialValue: null,
    },
  ],
};
