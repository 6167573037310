export function returnNewer(newValues, oldValues) {
  if (oldValues === null || oldValues === undefined) {
    return oldValues === newValues ? oldValues : newValues;
  }
  if (Array.isArray(newValues)) {
    return newValues.every(
      (newValue, newValueIndex) => newValue === oldValues[newValueIndex],
    )
      ? oldValues
      : newValues;
  } else if (typeof newValues === 'object' && !(newValues instanceof Date)) {
    const aProperties = Object.getOwnPropertyNames(newValues);
    const bProperties = Object.getOwnPropertyNames(oldValues);
    if (aProperties.length !== bProperties.length) {
      return newValues;
    }
    for (let i = 0; i < aProperties.length; i++) {
      const propName = aProperties[i];
      if (newValues[propName] !== oldValues[propName]) {
        return newValues;
      }
    }
    return oldValues;
  } else {
    return newValues === oldValues ? oldValues : newValues;
  }
}
