import {Pipe, untracked} from '@angular/core';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {LoadBatchCharacteristicsByCodeFilter} from '../actions';
import {selectCharacteristicsByCode} from '../selectors';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'characteristicsCode',
  pure: true,
})
export class CharacteristicsCodePipe extends AbstractCachedLoaderPipe {
  constructor(store: Store<any>) {
    super(store);
  }

  setObservable(id: any, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectCharacteristicsByCode(this._id)),
      map((it) => {
        if (!it || !it) {
          return id;
        } else {
          return field !== 'all' ? (it.length > 0 ? it[0][field] : id) : it[0];
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectCharacteristicsByCode(this._id)))
      .subscribe((x) => {
        if (x?.length === 0) {
          untracked(() =>
            this.store.dispatch(
              LoadBatchCharacteristicsByCodeFilter({code: this._id}),
            ),
          );
        }
      });
  }
}
