import {SelectItem} from 'primeng/api';

export enum RestartPeriodType {
  PERPETUAL = 'PERPETUAL',
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
}

export function getAllRestartPeriodTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(RestartPeriodType).forEach((val) =>
    items.push({
      label: 'shared.restartPeriodType.' + RestartPeriodType[val],
      value: val,
    }),
  );
  return items;
}

export function getRestartPeriodTypeKeyByLabel(label: string): string {
  const item = Object.entries(RestartPeriodType).find(
    (value) => value[1] === label,
  );
  return item ? item[0] : null;
}

export function getRestartPeriodTypeLabelByKey(key: string): string {
  const item = Object.entries(RestartPeriodType).find(
    (value) => value[0] === key,
  );
  return item ? item[1] : null;
}
