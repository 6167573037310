import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteAllRoleAccessRules,
  DeleteAllRoleAccessRulesError,
  DeleteAllRoleAccessRulesSuccess,
  DeleteRoleAccessRule,
  DeleteRoleAccessRuleError,
  DeleteRoleAccessRuleSuccess,
  LoadRoleAccessRulesByAccessRuleId,
  LoadRoleAccessRulesByAccessRuleIdError,
  LoadRoleAccessRulesByAccessRuleIdSuccess,
  LoadRoleAccessRulesByRoleId,
  LoadRoleAccessRulesByRoleIdError,
  LoadRoleAccessRulesByRoleIdSuccess,
  SaveAllRoleAccessRules,
  SaveAllRoleAccessRulesError,
  SaveAllRoleAccessRulesSuccess,
  UpsertRoleAccessRule,
  UpsertRoleAccessRuleError,
  UpsertRoleAccessRuleSuccess,
} from '../actions';
import {EntityIdentif, RoleAccessRule} from '../model';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';

@Injectable()
export class RoleAccessRuleEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<RoleAccessRule, RoleAccessRule>,
    private store: Store<any>,
    private ts: ToastService,
  ) {}

  loadByRoleId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoleAccessRulesByRoleId),
      exhaustMap(({roleId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.ROLE_ACCESS_RULE,
            'role.id__eq=' + roleId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadRoleAccessRulesByRoleIdSuccess({entities: env.data})
                : LoadRoleAccessRulesByRoleIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByAccessRuleId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoleAccessRulesByAccessRuleId),
      exhaustMap(({accessRuleId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.ROLE_ACCESS_RULE,
            'accessRule.id__eq=' + accessRuleId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadRoleAccessRulesByAccessRuleIdSuccess({entities: env.data})
                : LoadRoleAccessRulesByAccessRuleIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRoleAccessRule),
      concatMap(({roleAccessRule}) =>
        this.commonApiService
          .upsertEntity(
            EntityIdentif.ROLE_ACCESS_RULE,
            roleAccessRule,
            roleAccessRule.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .roleAccessRuleSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertRoleAccessRuleSuccess({roleAccessRule: env.data});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .roleAccessRuleSaveFailure,
                );
                return UpsertRoleAccessRuleError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAllRoleAccessRules),
      concatMap(({roleAccessRules}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.ROLE_ACCESS_RULE, roleAccessRules)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .roleAccessRuleSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return SaveAllRoleAccessRulesSuccess({
                  roleAccessRules: env.data,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .roleAccessRuleSaveFailure,
                );
                return SaveAllRoleAccessRulesError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRoleAccessRule),
      concatMap(({id}) =>
        this.commonApiService
          .deleteEntity(EntityIdentif.ROLE_ACCESS_RULE, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .roleAccessRuleDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteRoleAccessRuleSuccess({id: id});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .roleAccessRuleDeleteFailure,
                );
                return DeleteRoleAccessRuleError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllRoleAccessRules),
      concatMap(({ids}) =>
        this.commonApiService
          .deleteAllEntities(EntityIdentif.ROLE_ACCESS_RULE, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .roleAccessRuleDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteAllRoleAccessRulesSuccess({ids: ids});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .roleAccessRuleSaveFailure,
                );
                return DeleteAllRoleAccessRulesError(env.error);
              }
            }),
          ),
      ),
    ),
  );
}
