import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {Priv} from '../model';

export const LoadPrivs = createAction('[Priv] Load Privs');
export const LoadPrivsSuccess = createAction(
  '[Priv] Load Privs Success',
  props<{entities: Priv[]}>(),
);
export const LoadPrivsError = createAction(
  '[Priv] Load Privs Error',
  props<{error: TsmError}>(),
);

export const LoadPrivById = createAction(
  '[Priv] Load LoadPrivById',
  props<{id: string}>(),
);
export const LoadPrivByIdSuccess = createAction(
  '[Priv] Load LoadPrivById Success',
  props<{priv: Priv}>(),
);
export const LoadPrivByIdError = createAction(
  '[Priv] Load LoadPrivById Error',
  props<{error: any}>(),
);

export const UpsertPriv = createAction(
  '[Priv] Upsert Priv',
  props<{
    priv: Priv;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertPrivSuccess = createAction(
  '[Priv] Upsert Priv Success',
  props<{
    priv: Priv;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertPrivError = createAction(
  '[Priv] Upsert Priv Error',
  props<TsmError>(),
);

export const DeletePriv = createAction(
  '[Priv] Delete Priv',
  props<{id: string; listingId: string}>(),
);
export const DeletePrivSuccess = createAction(
  '[Priv] Delete Priv Success',
  props<{id: string; listingId: string}>(),
);
export const DeletePrivError = createAction(
  '[Priv] Delete Priv Error',
  props<TsmError>(),
);

export const LoadPrivByCode = createAction(
  '[Priv] Load LoadPrivByCode',
  props<{code: string}>(),
);
export const LoadPrivByCodeSuccess = createAction(
  '[Priv] Load LoadPrivByCode Success',
  props<{priv: Priv}>(),
);
export const LoadPrivByCodeError = createAction(
  '[Priv] Load LoadPrivByCode Error',
  props<{error: any}>(),
);

export const DiffPrivs = createAction(
  '[Priv] Diff Privs',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffPrivsSuccess = createAction(
  '[Priv] Diff Privs Success',
  props<{
    privs: Priv[];
    listingId: string;
  }>(),
);
export const DiffPrivsError = createAction(
  '[Priv] Diff Privs Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
