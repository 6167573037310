import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {TranslocoService} from '@tsm/framework/translate';
import {NgIf, NgFor} from '@angular/common';
import {FrameworkTooltipModule} from '@tsm/framework/tooltip';
import {TranslocoPipe} from '@jsverse/transloco';

@Component({
  selector: 'dtl-form-field-errors',
  templateUrl: './form-field-errors.component.html',
  styleUrls: ['./form-field-errors.component.scss'],
  host: {
    class: 'dtl-form-field-errors',
    '[class.empty]': '!(convertErrors.length > 0)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, FrameworkTooltipModule, TranslocoPipe],
})
export class FormFieldErrorsComponent {
  @HostBinding('class.not-in-view-port')
  notInViewPort = false;

  @HostBinding('class.in-view-port')
  inViewPort = false;

  @ViewChild('errorTooltip') errorTooltip: ElementRef;

  @Input() readonly: boolean;

  @Input() errors: ValidationErrors | null;

  @Input() touched = false;

  @Input() excelStyleErrors = false;

  // Styl chybových hlášek ala Excel: proměnná pro rozlišení vybraného zobrazení
  carretClicked = true;

  private readonly defaultMessageKeys = {
    required: 'validation.messages.requiredValue',
    minlength: 'validation.messages.valueMinLength',
    maxlength: 'validation.messages.valueMaxLength',
    email: 'validation.messages.email',
    min: 'validation.messages.min',
    max: 'validation.messages.max',
    pattern: 'validation.messages.pattern',
    //hack pro json-schema-form
    minLength: 'validation.messages.valueMinLengthForms',
    maxLength: 'validation.messages.valueMaxLengthForms',
    minimum: 'validation.messages.minimum',
    maximum: 'validation.messages.maximum',
  };

  constructor(
    public cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {}

  @HostBinding('class.excel-error-showing')
  get excelErrorsShowing() {
    return this.displayExcelStyleErrorsByType;
  }

  get errorsHtml() {
    return this.convertErrors
      .map(
        (error) =>
          `${this.translocoService.translate(error.messageKey, error.value)}`,
      )
      .join('');
  }

  get displayExcelStyleErrorsByType() {
    if (this.excelStyleErrors) {
      return this.touched;
    } else {
      return false;
    }
  }

  get convertErrors(): {key: string; value: any; messageKey: string}[] {
    if (!this.errors) {
      return [];
    }
    return Object.keys(this.errors).reduce(
      (acc, key) => [
        ...acc,
        {
          key: key,
          value: this.errors[key],
          messageKey: this.getErrorMessageKey(this.errors[key], key),
        },
      ],
      [],
    );
  }

  inViewPortChanged(inViewPort: boolean) {
    this.inViewPort = inViewPort;
    this.notInViewPort = !inViewPort;
  }

  private getErrorMessageKey(error: any, key) {
    // hack pro json-schema-form ($customErrorCode-)
    return key === 'customErrorCode' || key.startsWith('$customErrorCode-')
      ? error
      : this.defaultMessageKeys[key];
  }
}
