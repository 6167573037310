import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/region-assignment.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getRegionAssignmentState = createSelector(
  getUserManagementState,
  (regionAssignmentState) => regionAssignmentState.regionAssignment,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getRegionAssignmentState);

export const selectRegionAssignmentByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectRegionAssignmentsByUserId = (userId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.user.id === userId),
  );

export const selectRegionAssignmentById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectRegionAssignmentsByUserIds = (userIds: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((ent) => ent.data && userIds.includes(ent.data.user.id)),
  );

export const getAllRegionAssignmentss = selectAll;

export const selectRegionAssignmentsByRegionId = (regionId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.regionId === regionId),
  );
