import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {translation} from '../../i18n';
import {OverlayPanel} from '@tsm/framework/override-primeng/overlay-panel';
import {TsmFormControl} from '@tsm/framework/forms';
import {translation as translationShared} from '@tsm/shared-i18n';

@Component({
  selector: 'dtl-table-paginator',
  templateUrl: './dtl-table-paginator.component.html',
  styleUrls: ['./dtl-table-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DtlTablePaginatorComponent {
  translation = translation;
  translationShared = translationShared;

  @Input() closePrevious = true;

  private _page = 1;
  @Input() set page(page: number) {
    this._page = page;
    this.pageControl.setValue(this.page);
  }

  get page() {
    return this._page;
  }

  private _pageSize = 1;
  @Input() set pageSize(pageSize: number) {
    this._pageSize = pageSize;
    this.pageSizeControl.setValue(this.pageSize);
  }

  get pageSize() {
    return this._pageSize;
  }

  @Input() total = 1;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() pageSizeChanges: EventEmitter<number> = new EventEmitter();
  @Output() pageAndPageSizeChanges: EventEmitter<{
    page: number;
    pageSize: number;
  }> = new EventEmitter();

  @ViewChild('oPpaginator')
  oPpaginator: OverlayPanel;

  pageControl = new TsmFormControl(this.page);
  pageSizeControl = new TsmFormControl(this.pageSize);
  maxLimitItems = false;

  constructor(public cdr: ChangeDetectorRef) {}

  openPaginator(event) {
    this.oPpaginator.toggle(event);
  }

  changeFocusPageSize(focus) {
    if (focus == false && this.pageSizeControl.value == null) {
      this.pageSizeControl.setValue(this.pageSize);
    }
  }

  changeFocusPage(focus) {
    if (focus == false && this.pageControl.value == null) {
      this.pageControl.setValue(this.page);
    }
  }

  setPage() {
    const resultPage =
      this.pageControl.value <= this.lastPage
        ? this.pageControl.value
        : this.lastPage;
    this.pageAndPageSizeChanges.emit({
      page: resultPage,
      pageSize: this.pageSizeControl.value,
    });
    this.oPpaginator.hide();
  }

  get firstItemIndex() {
    return (this.page - 1) * this.pageSize + 1;
  }

  get lastItemIndex() {
    return this.page * this.pageSize > this.total
      ? this.total
      : this.page * this.pageSize;
  }

  get lastPage() {
    const pageSize = this.pageSizeControl.value ?? this.pageSize;
    const maxRealPage = Math.max(Math.ceil(this.total / pageSize), 1);
    if (maxRealPage * pageSize > 100000) {
      this.maxLimitItems = true;
      return Math.max(Math.floor(100000 / pageSize), 1);
    }
    this.maxLimitItems = false;
    return maxRealPage;
  }

  get isFirstPage() {
    return this.page === 1;
  }

  get isLastPage() {
    return this.page === this.lastPage;
  }

  changePageToFirst($event) {
    $event.stopPropagation();
    if (!this.isFirstPage) {
      this.pageChanged.emit(1);
    }
  }

  changePageToPrev($event) {
    $event.stopPropagation();
    if (!this.isFirstPage) {
      this.pageChanged.emit(this.page - 1);
    }
  }

  changePageToNext($event) {
    $event.stopPropagation();
    if (!this.isLastPage) {
      this.pageChanged.emit(this.page + 1);
    }
  }

  changePageToLast($event) {
    $event.stopPropagation();
    if (!this.isLastPage) {
      this.pageChanged.emit(this.lastPage);
    }
  }
}
