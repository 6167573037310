import {SelectItem} from 'primeng/api';

export enum DataTypeEnum {
  // keyword
  VARCHAR = 'VARCHAR',
  // integer
  INTEGER = 'INTEGER',
  // long
  BIGINT = 'BIGINT',
  // double
  DOUBLE = 'DOUBLE',
  // boolean
  BOOLEAN = 'BOOLEAN',
  // datetime
  TIMESTAMP = 'TIMESTAMP',
}

export function getAllDataTypes(): SelectItem[] {
  const dataTypes: SelectItem[] = [];
  Object.keys(DataTypeEnum).forEach((key) =>
    dataTypes.push({label: DataTypeEnum[key], value: key}),
  );
  return dataTypes;
}
