import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'booleanIcon',
  pure: true,
})
export class BooleanIconPipe implements PipeTransform {
  constructor() {}

  transform(value: any, ...classConfig: {value: any; class: string}[]): any {
    let trueClass = 'pi pi-check-circle pi-fw p-color-green';
    let falseClass = '';
    let nullClass = '';
    classConfig.forEach((item) => {
      if (item.value === true || item.value === 'true') {
        trueClass = item.class;
      } else if (item.value === false || item.value === 'false') {
        falseClass = item.class;
      } else if (item.value === null || item.value === 'null') {
        nullClass = item.class;
      }
    });
    if (Array.isArray(value)) {
      if (value?.length == 0 || !value.some((x) => !!x)) {
        if (!value.some((x) => !!x)) {
          return nullClass === '' ? '' : `<i class="${nullClass}"></i>`;
        }
        return falseClass === '' ? '' : `<i class="${falseClass}"></i>`;
      }
      return trueClass === '' ? '' : `<i class="${trueClass}"></i>`;
    }

    if (!value) {
      if (value == null) {
        return nullClass === '' ? '' : `<i class="${nullClass}"></i>`;
      }
      return falseClass === '' ? '' : `<i class="${falseClass}"></i>`;
    }
    return trueClass === '' ? '' : `<i class="${trueClass}"></i>`;
  }
}
