<span
  #container
  [ngClass]="{
    'p-calendar': true,
    'p-calendar-w-btn': showIcon,
    'p-calendar-timeonly': timeOnly,
    'p-calendar-disabled': disabled,
    'p-focus': focus,
    'only-icon': onlyIcon,
  }"
  [ngStyle]="style"
  [class]="styleClass"
>
  <ng-template [ngIf]="!inline">
    <input
      *ngIf="!onlyIcon"
      #inputfield
      type="text"
      [attr.id]="inputId"
      [attr.name]="name"
      [attr.required]="required"
      [attr.aria-required]="required"
      [value]="inputFieldValue"
      (focus)="onInputFocus($event)"
      (keydown)="onInputKeydown($event)"
      (click)="onInputClick()"
      (blur)="onInputBlur($event)"
      [readonly]="readonlyInput"
      (input)="onUserInput($event)"
      [ngStyle]="inputStyle"
      [class]="inputStyleClass"
      [placeholder]="placeholder ? placeholder : ''"
      [disabled]="disabled"
      [attr.tabindex]="tabindex"
      [attr.inputmode]="touchUI ? 'off' : null"
      [ngClass]="'p-inputtext p-component'"
      autocomplete="off"
      [attr.aria-labelledby]="ariaLabelledBy"
    />
    <i
      *ngIf="showClear && !disabled && value != null"
      class="p-calendar-clear-icon pi pi-times"
      (click)="clear()"
    ></i>
    <button
      *ngIf="showIcon"
      #buttonField
      pButton
      pRipple
      type="button"
      (click)="onButtonClick($event, inputfield)"
      class="p-datepicker-trigger"
      [icon]="icon"
      [disabled]="disabled"
      tabindex="0"
      [attr.aria-label]="getTranslation('chooseDate')"
    ></button>
  </ng-template>
  <div
    #contentWrapper
    [class]="panelStyleClass"
    [ngStyle]="panelStyle"
    [ngClass]="{
      'p-datepicker p-component': true,
      'p-datepicker-inline': inline,
      'p-disabled': disabled,
      'p-datepicker-timeonly': timeOnly,
      'p-datepicker-multiple-month': this.numberOfMonths > 1,
      'p-datepicker-monthpicker': view === 'month',
      'p-datepicker-touch-ui': touchUI,
    }"
    [@overlayAnimation]="
      touchUI
        ? {
            value: 'visibleTouchUI',
            params: {
              showTransitionParams: showTransitionOptions,
              hideTransitionParams: hideTransitionOptions,
            },
          }
        : {
            value: 'visible',
            params: {
              showTransitionParams: showTransitionOptions,
              hideTransitionParams: hideTransitionOptions,
            },
          }
    "
    [@.disabled]="inline === true"
    (@overlayAnimation.start)="onOverlayAnimationStart($event)"
    (@overlayAnimation.done)="onOverlayAnimationDone($event)"
    *ngIf="inline || overlayVisible"
  >
    <ng-content select="p-header"></ng-content>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <ng-container *ngIf="!timeOnly">
      <div class="p-datepicker-group-container">
        <div
          class="p-datepicker-group"
          *ngFor="let month of months; let i = index; trackBy: trackBy"
        >
          <div class="p-datepicker-header">
            <button
              (keydown)="onContainerButtonKeydown($event)"
              class="p-datepicker-prev p-link"
              (click)="onPrevButtonClick($event)"
              *ngIf="i === 0"
              type="button"
              pRipple
            >
              <span class="p-datepicker-prev-icon pi pi-chevron-left"></span>
            </button>
            <div class="p-datepicker-title">
              <span
                class="p-datepicker-month"
                *ngIf="!monthNavigator && view !== 'month'"
                >{{ getTranslation('monthNames')[month.month] }}</span
              >
              <p-dropdown
                *ngIf="
                  monthNavigator && view !== 'month' && numberOfMonths === 1
                "
                tabindex="0"
                class="p-datepicker-month"
                [ngModel]="currentMonth"
                [options]="monthsOptions"
                (ngModelChange)="onMonthDropdownChange($event)"
              >
              </p-dropdown>
              <p-dropdown
                *ngIf="yearNavigator && numberOfMonths === 1"
                tabindex="0"
                class="p-datepicker-year"
                [ngModel]="currentYear"
                [options]="yearOptions"
                (ngModelChange)="onYearDropdownChange($event)"
              >
              </p-dropdown>
              <span class="p-datepicker-year" *ngIf="!yearNavigator">{{
                view === 'month' ? currentYear : month.year
              }}</span>
            </div>
            <button
              (keydown)="onContainerButtonKeydown($event)"
              class="p-datepicker-next p-link"
              (click)="onNextButtonClick($event)"
              *ngIf="numberOfMonths === 1 ? true : i === numberOfMonths - 1"
              type="button"
              pRipple
            >
              <span class="p-datepicker-next-icon pi pi-chevron-right"></span>
            </button>
          </div>
          <div class="p-datepicker-calendar-container" *ngIf="view === 'date'">
            <table class="p-datepicker-calendar">
              <thead>
                <tr>
                  <th
                    *ngIf="showWeek"
                    class="p-datepicker-weekheader p-disabled"
                  >
                    <span>{{ getTranslation('weekHeader') }}</span>
                  </th>
                  <th
                    scope="col"
                    *ngFor="
                      let weekDay of weekDays;
                      let begin = first;
                      let end = last
                    "
                  >
                    <span>{{ weekDay }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let week of month.dates;
                    let j = index;
                    trackBy: trackBy
                  "
                >
                  <td *ngIf="showWeek" class="p-datepicker-weeknumber">
                    <span class="p-disabled">
                      {{ month.weekNumbers[j] }}
                    </span>
                  </td>
                  <td
                    *ngFor="let date of week; trackBy: trackBy"
                    [ngClass]="{
                      'p-datepicker-other-month': date.otherMonth,
                      'p-datepicker-today': date.today,
                    }"
                  >
                    <ng-container
                      *ngIf="date.otherMonth ? showOtherMonths : true"
                    >
                      <span
                        [ngClass]="{
                          'p-highlight': isSelected(date),
                          'p-disabled': !date.selectable,
                        }"
                        (click)="onDateSelect($event, date)"
                        draggable="false"
                        (keydown)="onDateCellKeydown($event, date, i)"
                        pRipple
                      >
                        <ng-container *ngIf="!dateTemplate">{{
                          date.day
                        }}</ng-container>
                        <ng-container
                          *ngTemplateOutlet="
                            dateTemplate;
                            context: {$implicit: date}
                          "
                        ></ng-container>
                      </span>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="p-monthpicker" *ngIf="view === 'month'">
        <span
          *ngFor="let m of monthPickerValues; let i = index"
          (click)="onMonthSelect($event, i)"
          (keydown)="onMonthCellKeydown($event, i)"
          class="p-monthpicker-month"
          [ngClass]="{
            'p-highlight': isMonthSelected(i),
            'p-disabled': !isSelectable(1, i, this.currentYear, false),
          }"
          pRipple
        >
          {{ m }}
        </span>
      </div>
    </ng-container>
    <div class="p-timepicker" *ngIf="showTime || timeOnly">
      <div class="p-hour-picker">
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (keydown.enter)="incrementHour($event)"
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          (mouseleave)="onTimePickerElementMouseLeave()"
          pRipple
          data-cy="calendar-component-button-hour-picker-add"
        >
          <span class="pi pi-chevron-up"></span>
        </button>
        <span
          class="time-fake-input"
          [class.p-state-focus]="hourInputFocus"
          [class.fake-number]="currentHour < 10"
        >
          <span *ngIf="currentHour < 10" (click)="hourInput.focus()">0</span>
          <input
            type="number"
            [ngModel]="currentHour"
            (ngModelChange)="changeHour($event, false)"
            (focus)="hourInputFocus = true"
            (blur)="
              changeHour($any($event.target).value, true);
              hourInputFocus = false
            "
            #hourInput
            [style]="currentHour < 10 ? 'width: 9px' : 'width: 18px'"
          />
        </span>
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (keydown.enter)="decrementHour($event)"
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          (mouseleave)="onTimePickerElementMouseLeave()"
          pRipple
        >
          <span class="pi pi-chevron-down"></span>
        </button>
      </div>
      <div class="p-separator">
        <span>{{ timeSeparator }}</span>
      </div>
      <div class="p-minute-picker">
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (keydown.enter)="incrementMinute($event)"
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          (mouseleave)="onTimePickerElementMouseLeave()"
          pRipple
          data-cy="calendar-component-button-minute-picker-add"
        >
          <span class="pi pi-chevron-up"></span>
        </button>
        <span
          class="time-fake-input"
          [class.p-state-focus]="minuteInputFocus"
          [class.fake-number]="currentMinute < 10"
        >
          <span *ngIf="currentMinute < 10" (click)="minuteInput.focus()"
            >0</span
          >
          <input
            type="number"
            [ngModel]="currentMinute"
            (ngModelChange)="changeMinute($event, false)"
            (focus)="minuteInputFocus = true"
            (blur)="
              changeMinute($any($event.target).value, true);
              minuteInputFocus = false
            "
            #minuteInput
            [style]="currentMinute < 10 ? 'width: 9px' : 'width: 18px'"
          />
        </span>
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (keydown.enter)="decrementMinute($event)"
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          (mouseleave)="onTimePickerElementMouseLeave()"
          pRipple
        >
          <span class="pi pi-chevron-down"></span>
        </button>
      </div>
      <div class="p-separator" *ngIf="showSeconds">
        <span>{{ timeSeparator }}</span>
      </div>
      <div class="p-second-picker" *ngIf="showSeconds">
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (keydown.enter)="incrementSecond($event)"
          (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          (mouseleave)="onTimePickerElementMouseLeave()"
          pRipple
        >
          <span class="pi pi-chevron-up"></span>
        </button>
        <span
          ><ng-container *ngIf="currentSecond < 10">0</ng-container>
          {{ currentSecond }}</span
        >
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (keydown.enter)="decrementSecond($event)"
          (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          (mouseleave)="onTimePickerElementMouseLeave()"
          pRipple
        >
          <span class="pi pi-chevron-down"></span>
        </button>
      </div>
      <div class="p-ampm-picker" *ngIf="hourFormat == '12'">
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (click)="toggleAMPM($event)"
          (keydown.enter)="toggleAMPM($event)"
          pRipple
        >
          <span class="pi pi-chevron-up"></span>
        </button>
        <span>{{ pm ? 'PM' : 'AM' }}</span>
        <button
          class="p-link"
          type="button"
          (keydown)="onContainerButtonKeydown($event)"
          (click)="toggleAMPM($event)"
          (keydown.enter)="toggleAMPM($event)"
          pRipple
        >
          <span class="pi pi-chevron-down"></span>
        </button>
      </div>
    </div>

    <!-- MESSAGE pro minimalni datum -->
    <div
      *ngIf="showTime && minDate && errorTimeMessage"
      style="color: red; text-align: center"
    >
      {{ timeMessage }}
    </div>

    <div class="p-datepicker-buttonbar" *ngIf="showButtonBar">
      <ng-container *ngIf="!timeOnly">
        <button
          pButton
          pRipple
          type="button"
          [label]="getTranslation('today')"
          (keydown)="onContainerButtonKeydown($event)"
          (click)="onTodayButtonClick($event)"
          [ngClass]="[todayButtonStyleClass]"
          data-cy="calendar-component-button-today"
        ></button>
      </ng-container>
      <div>
        <button
          pButton
          pRipple
          *ngIf="showPickDateTimeButton && (showTime || timeOnly)"
          type="button"
          [label]="pickDateTimeButtonLabel | transloco"
          (click)="applyClicked()"
          [disabled]="!filled"
          class="p-button-success"
          data-cy="calendar-component-button-pick"
        ></button>
        <button
          pButton
          pRipple
          *ngIf="showClear"
          type="button"
          [label]="getTranslation('clear')"
          (keydown)="onContainerButtonKeydown($event)"
          (click)="onClearButtonClick($event)"
          class="p-button-plain p-button-text ml-1"
        ></button>
      </div>
    </div>
    <ng-content select="p-footer"></ng-content>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</span>
