import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {CommonApiService as SharedApiService} from '@tsm/shared';
import {Config} from '@tsm/framework/config';

@Injectable()
export class CommonApiService<
  ServerResponseType,
  ToClientType,
> extends SharedApiService<ServerResponseType, ToClientType> {
  constructor(config: ConfigService<Config>, apiService: ApiService) {
    super('tsm-dms/api', apiService, config);
  }
}
