<div class="config-bar left-0 right-auto bottom-0">
  <div class="flex flex-nowrap gap-2">
    <div class="flex align-items-center justify-content-center">
      <i
        class="menu-mode-icon tsm-icon-pushpin-3 cursor-pointer"
        [class.p-state-active]="panelMode"
        (click)="onPanelPushPinClick()"
        [tsmTooltip]="sidebarOverlappingTooltip"
        data-cy="layout-tsm-overlapping-menu-button"
      ></i>
      <ng-template #sidebarOverlappingTooltip>
        <ng-container>
          <!-- Headline -->
          <div
            class="border-blue-600 font-medium text-2xl px-3 py-2 mb-2 text-900 sticky top-0 surface-overlay tsm-tooltip-header"
            style="border-left: 8px solid"
          >
            Režim panelu
          </div>

          <!-- Text Body -->
          <div class="line-height-3 text-700 mb-3">
            <h4>Překrytí (default)</h4>
            <p>
              Základní nastavení. Panel při zobrazení překrývá obsah, po
              kliknutí mimo panel dojde k jeho automatickému skrytí.
            </p>

            <h4>Statický</h4>
            <p>
              Při aktivaci této volby panel při zobrazení nepřekrývá (odsouvá)
              obsah, po kliknutí mimo panel se NEskryje.
            </p>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
