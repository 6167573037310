import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/config-form/components-i18n';

export const controlsRegisterValueNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-register-value-new',
    name: 'Register value new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'RegisterValue',
    tsmControls: {
      name: {
        name: translationShared.shared.title,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      code: {
        name: translationShared.shared.code,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.code,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      order: {
        name: translationShared.shared.itemOrdering,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label: translationShared.shared.itemOrdering,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-number',
        },
      },
      parent: {
        name: translationShared.shared.parent,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.parent,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-register-value-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      icon: {
        name: translationShared.shared.icon,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'icon',
            label: translationShared.shared.icon,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-icon',
        },
      },
      validFrom: {
        name: translationShared.shared.validFrom,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label: translationShared.shared.validFrom,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      validTo: {
        name: translationShared.shared.validTo,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label: translationShared.shared.validTo,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsRegisterValueDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-register-value-detail',
    name: 'Register value detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'RegisterValue',
    tsmControls: {
      'RegisterValue.Header': {
        name: translationShared.shared.header,
        description: translationShared.shared.settingsButton,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      // 'RegisterValue.Toolbar.Settings': {
      //     name: translationShared.shared.settingsButton,
      //     description: null,
      //     icon: 'pi pi-ellipsis-v'
      // },
      // 'RegisterValue.Toolbar.Refresh': {
      //     name: translationShared.shared.refreshButton,
      //     description: null,
      //     icon: 'pi pi-sync'
      // },
      'RegisterValue.Details.Name': {
        name: translationShared.shared.title,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.title,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'RegisterValue.Details.Code': {
        name: translationShared.shared.code,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.code,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'RegisterValue.Details.Order': {
        name: translationShared.shared.itemOrdering,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.itemOrdering,
            labelPosition: 'left',
            type: 'number',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-number',
        },
      },
      'RegisterValue.Details.Parent': {
        name: translationShared.shared.parent,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.parent,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-register-value-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'RegisterValue.Details.Icon': {
        name: translationShared.shared.icon,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.icon,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon-picker',
        },
      },
      'RegisterValue.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'RegisterValue.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'RegisterValue.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'RegisterValue.Dates.ValidFrom': {
        name: translationShared.shared.validFrom,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validFrom,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'RegisterValue.Dates.ValidTo': {
        name: translationShared.shared.validTo,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validTo,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'RegisterValue.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'RegisterValue.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
    },
  };

export const controlSpecificationCharacteristicsConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-entity-specification-characteristics',
    name: 'EntitySpecificationCharacteristic config',
    description: '',
    useType: 'FORM_CONFIG',
    entityType: null,
    tsmControls: {
      regexp: {
        name: translation.configFormComponents.components
          .entitySpecificationCharacteristic.regexForValidation,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translation.configFormComponents.components
                .entitySpecificationCharacteristic.regexForValidation,
            labelPosition: 'top',
          },
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
      },
      defaultValue: {
        name: translationShared.shared.defaultValue,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.defaultValue,
            labelPosition: 'top',
          },
        },
      },
      whenInserted: {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'top',
            type: 'date',
          },
        },
      },
      whenEdited: {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'top',
            type: 'date',
          },
        },
      },
      whoInserted: {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'top',
            type: 'text',
          },
        },
      },
      whoEdited: {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'top',
            type: 'text',
          },
        },
      },
    },
  };
