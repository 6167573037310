import {inject} from '@angular/core';
import {TranslocoService} from '@jsverse/transloco';

function AnyClass<Props>(): new () => Props {
  return class {} as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

function setPathsToObjectValues<T extends object | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = '',
): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce(
        (objectToTransform, [key, value]) => {
          // @ts-ignore
          objectToTransform[key] = setPathsToObjectValues(
            key,
            value,
            concatIfExistsPath(path, suffix),
          );

          return objectToTransform;
        },
        {} as T,
      )
    : (concatIfExistsPath(path, suffix) as T);
}

export function getLocalization<T = any>(translations: T): T {
  const temp = new (AnyClass<T>())();
  Object.assign(
    temp,
    setPathsToObjectValues('', translations as unknown as object),
  );
  return temp;
}

/**
 * Pouzivat jen ve vyjimecnych pripadech, idealne nikdy
 * @param key
 * @param params
 */
export function injectAndTranslate(key: string, params?: any): string {
  const translocoService = inject(TranslocoService);
  return translocoService.translate(key, params);
}

/**
 * Pouzivat jen ve vyjimecnych pripadech, idealne nikdy
 * @param key
 * @param params
 */
export function injectAndTranslateDefault(key: string, params?: any): string {
  const translocoService = inject(TranslocoService);
  return translocoService.translate(
    key,
    params,
    translocoService.getDefaultLang(),
  );
}
