import {Injectable} from '@angular/core';
import {IdentifiedPack, SharedRequestValidUntil} from '@tsm/framework/root';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, finalize, switchMap} from 'rxjs/operators';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {translation} from '../i18n';
import {ScriptDataService} from '../services';

@Docs({
  detail:
    'evalScriptById(scriptId: string, scriptData?: any, disableCache = false, disableToast = false): IdentifiedPack<any>',
  description: {
    about: translation.dataSource.evalScriptById,
    params: [
      {
        name: translation.dataSource.scriptIdName,
        about: translation.dataSource.scriptIdDescription,
      },
      {
        name: translation.dataSource.scriptDataName,
        about: translation.dataSource.scriptDataDescription,
      },
      {
        name: translation.dataSource.scriptDisableCache,
        about: translation.dataSource.scriptDisableCacheDescription,
      },
      {
        name: translation.dataSource.scriptDisableToast,
        about: translation.dataSource.scriptDisableToastDescription,
      },
    ],
    example:
      "evalScriptById('9d1548e7-26df-4ec2-8931-b86581b21672', {'parametr': $value.text})",
  },
})
@Injectable()
export class EvalScriptByIdDataSource
  implements DtlDataSource<IdentifiedPack<any>>
{
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private scriptDataService: ScriptDataService) {}

  pushParams(params) {
    this.value.next(params);
  }

  getRemoteDataOuter(
    scriptId: string,
    scriptData = {},
    disableCache = false,
    disableToast = false,
  ): Observable<IdentifiedPack<any>> {
    return disableCache
      ? this.getRemoteData(scriptId, scriptData, disableCache, disableToast)
      : this.getRemoteDataCached(
          scriptId,
          scriptData,
          disableCache,
          disableToast,
        );
  }

  /**
   * Experimentalni funkcnost pro "lokalni stav" po zavolani create
   * Pokud je formular s hodne datasourcy evalScriptById tak vsechny sdilely stream
   * Tohle resi ze oddeli stream a kazdy kdo zavola create() ma svuj vlastni
   */
  create(): Observable<IdentifiedPack<any>> {
    const localSubject = new BehaviorSubject<any[]>(this.value.getValue());
    // subscribe localSubject to global changes
    const globalSubscription = this.value
      .asObservable()
      .subscribe(localSubject);

    return localSubject.asObservable().pipe(
      filter((params: any[]) => params && params[0] != null),
      switchMap((params: any[]) =>
        this.getRemoteDataOuter(params[0], params[1], params[2], params[3]),
      ),
      finalize(() => {
        globalSubscription.unsubscribe();
      }),
    );
  }

  private getRemoteData(
    scriptId: string,
    scriptData = {},
    disableCache = false,
    disableToast = false,
  ): Observable<IdentifiedPack<any>> {
    return this.scriptDataService.evalScriptById(
      scriptId,
      scriptData,
      disableCache,
      disableToast,
    );
  }

  @SharedRequestValidUntil()
  private getRemoteDataCached(
    scriptId: string,
    scriptData = {},
    disableCache = false,
    disableToast = false,
  ): Observable<IdentifiedPack<any>> {
    return this.scriptDataService.evalScriptById(
      scriptId,
      scriptData,
      disableCache,
      disableToast,
    );
  }
}
