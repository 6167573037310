import {ChangeDetectorRef, Directive, Optional} from '@angular/core';
import {
  LayoutedComponent,
  LayoutIdDirective,
} from '@tsm/framework/root/layout-id';
import {FluentFormsInputOptions} from '@tsm/framework/root/di';

@Directive()
export abstract class ParentWidgetComponent implements FluentFormsInputOptions {
  protected constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
  ) {}

  setInput(key: string, value: any) {
    this[key] = value;
    this.cdr.markForCheck();
  }
}
