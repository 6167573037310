/**
 * Vykradená a upravená knihovna https://www.npmjs.com/package/parse-duration.ts - je špatně ubalená (verze 0.1.3), nelze v angular použít.
 */
export class ParseDuration {
  static parse(str: string): number {
    const duration = /(-?\d*\.?\d+(?:e[-+]?\d+)?)\s*([a-zμ]*)/gi;

    /**
     * conversion ratios
     */
    const units = {};

    units['ns'] = units['nanosecond'] = 1 / 1e6;

    // noinspection JSNonASCIINames
    units['μs'] = units['microsecond'] = 1 / 1e3;

    const millisecond = (units['millisecond'] = 1);
    units['ms'] = millisecond;

    const second = (units['second'] = millisecond * 1000);
    units['sec'] = second;
    units['s'] = second;

    const minute = (units['minute'] = second * 60);
    units['min'] = minute;
    units['m'] = minute;

    const hour = (units['hour'] = minute * 60);
    units['hr'] = hour;
    units['h'] = hour;

    const day = (units['day'] = hour * 24);
    units['d'] = day;

    const week = (units['week'] = day * 7);
    units['wk'] = week;
    units['w'] = week;

    units['month'] = day * (365.25 / 12);

    const year = (units['year'] = day * 365.25);
    units['yr'] = year;
    units['y'] = year;

    let result = 0;
    // ignore commas
    str = str.replace(/(\d),(\d)/g, '$1$2');
    str.replace(duration, (substring: string, n: string, unit: string) => {
      const ms =
        units[unit] || units[unit.toLowerCase().replace(/s$/, '')] || 1;
      result += parseFloat(n) * ms;
      return '';
    });
    return result;
  }
}
