import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AttachmentDataService, AttachmentService} from '../services';
import {
  DeleteAllDataAttachment,
  DeleteAllDataAttachmentError,
  DeleteAllDataAttachmentSuccess,
  DeleteDataAttachment,
  DeleteDataAttachmentError,
  DeleteDataAttachmentSuccess,
  DownloadDataAttachment,
  DownloadDataAttachmentError,
  DownloadDataAttachmentSuccess,
  LoadDataAttachmentError,
  LoadDataAttachmentOld,
  LoadDataAttachmentSuccess,
  UploadDataAndDeleteAllAttachment,
  UploadDataAndDeleteAllAttachmentError,
  UploadDataAndDeleteAllAttachmentSuccess,
  UploadDataAttachment,
  UploadDataAttachmentError,
  UploadDataAttachmentSuccess,
} from '../actions';
import {exhaustMap, map, mergeMap} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';

@Injectable()
export class AttachmentEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDataAttachmentOld),
      mergeMap((action) => {
        return this.attachmentDataService
          .getAttachmentByOwnerIdAndOwnerType(
            action.ownerId,
            action.ownerType,
            action.onlyWithThumbnail,
          )
          .pipe(
            map((att) =>
              att.success
                ? LoadDataAttachmentSuccess({
                    ownerType: action.ownerType,
                    ownerId: action.ownerId,
                    readonly: action.readonly,
                    attachments: att.data,
                  })
                : LoadDataAttachmentError({
                    ownerType: action.ownerType,
                    ownerId: action.ownerId,
                    error: att.error,
                  }),
            ),
          );
      }),
    ),
  );

  uploadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadDataAttachment),
      exhaustMap(({ownerId, ownerType, attachments}) =>
        this.attachmentDataService
          .uploadFiles(ownerId, ownerType, attachments)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toast.showToast(
                  translation.attachments.save.success,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UploadDataAttachmentSuccess({
                  ownerId: ownerId,
                  ownerType: ownerType,
                  attachments: env.data,
                });
              } else {
                this.toast.showError(
                  env.error,
                  translation.attachments.save.error,
                );
                return UploadDataAttachmentError({
                  ownerId: ownerId,
                  ownerType: ownerType,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  uploadDataAndDeleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadDataAndDeleteAllAttachment),
      exhaustMap(({ownerId, ownerType, attachments}) =>
        this.attachmentDataService
          .uploadAndDeleteAll(ownerId, ownerType, attachments)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toast.showToast(
                  translation.attachments.saveAndDeleteAll,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UploadDataAndDeleteAllAttachmentSuccess({
                  ownerId: ownerId,
                  ownerType: ownerType,
                  attachments: attachments,
                });
              } else {
                this.toast.showError(
                  env.error,
                  translation.attachments.save.error,
                );
                return UploadDataAndDeleteAllAttachmentError({
                  ownerId: ownerId,
                  ownerType: ownerType,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  downloadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DownloadDataAttachment),
      exhaustMap(({ownerId, ownerType, attachment, showData}) =>
        this.attachmentDataService
          .downloadData(ownerId, ownerType, attachment, showData)
          .pipe(
            map((env) => {
              if (env.success) {
                return DownloadDataAttachmentSuccess({
                  ownerId: ownerId,
                  ownerType: ownerType,
                });
              } else {
                this.toast.showError(
                  env.error,
                  translation.attachments.download.error,
                );
                return DownloadDataAttachmentError({
                  ownerId: ownerId,
                  ownerType: ownerType,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  deleteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteDataAttachment),
      exhaustMap(({ownerId, ownerType, attachment}) =>
        this.attachmentDataService.deleteFile(attachment.id).pipe(
          map((env) => {
            if (env.success) {
              this.toast.showToast(
                translation.attachments.delete.success,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteDataAttachmentSuccess({
                ownerId: ownerId,
                ownerType: ownerType,
                attachmentId: attachment.id,
              });
            } else {
              this.toast.showError(
                env.error,
                translation.attachments.delete.error,
              );
              return DeleteDataAttachmentError({
                ownerId: ownerId,
                ownerType: ownerType,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  deleteAllData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllDataAttachment),
      exhaustMap(({ownerId, ownerType}) =>
        this.attachmentDataService.deleteAllFile(ownerId, ownerType).pipe(
          map((env) =>
            env.success
              ? DeleteAllDataAttachmentSuccess({
                  ownerId: ownerId,
                  ownerType: ownerType,
                })
              : DeleteAllDataAttachmentError({
                  ownerId: ownerId,
                  ownerType: ownerType,
                  error: env.error,
                }),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private attachmentsService: AttachmentService,
    private attachmentDataService: AttachmentDataService,
    private toast: ToastService,
  ) {}
}
