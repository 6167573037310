<p-divider align="center" class="block my-3">
  <span
    class="font-semibold border-1 border-500 px-4 py-2 surface-ground border-round-sm"
    >Images</span
  >
</p-divider>

<dtl-grid *ngIf="!mixed" class="icons-grid gap-3 m-0 px-3 xl:px-0">
  <dtl-col *ngFor="let image of filteredImages">
    <div
      (click)="
        onImageSelected.emit('assets/icons/' + imagePathPrefix + image + '.svg')
      "
    >
      <img
        [attr.id]="'preview-' + image"
        [src]="'assets/icons/' + imagePathPrefix + image + '.svg'"
        [alt]="'ICON REPRESENTING' + image"
        style="height: 64px"
      />
      <span>{{ image }}</span>
      <div
        class="code-block cursor-pointer"
        pTooltip="Po kliknutí na tuto ikonu, získáte kód obrázku v base64 formátu, bude vložen do clipboardu"
      >
        <i
          (click)="
            onImageBase64Selected.emit('preview-' + image);
            $event.stopPropagation()
          "
          class="tsm-icon-command-line"
        ></i>
      </div>
    </div>
  </dtl-col>
</dtl-grid>

<dtl-grid *ngIf="mixed" class="icons-grid gap-3 m-0 px-3 xl:px-0">
  <dtl-col *ngFor="let image of filteredImages">
    <div (click)="onImageSelected.emit('assets/icons/' + image + '.svg')">
      <img
        [attr.id]="'preview-' + image"
        [src]="'assets/icons/' + image + '.svg'"
        [alt]="'ICON REPRESENTING' + image"
        style="height: 64px"
      />
      <span>{{ image }}</span>
      <div
        class="code-block cursor-pointer"
        pTooltip="Po kliknutí na tuto ikonu, získáte kód obrázku v base64 formátu, bude vložen do clipboardu"
      >
        <i
          (click)="
            onImageBase64Selected.emit('preview-' + image);
            $event.stopPropagation()
          "
          class="tsm-icon-command-line"
        ></i>
      </div>
    </div>
  </dtl-col>
</dtl-grid>
