export const cs = {
  userManagementService: {
    effects: {
      regionSaveSuccess: 'Ukládání regionu uživatele proběhlo úspěšně',
      regionAlreadyAssigned: 'Uživatel má daný region již přiřazený',
      regionSaveError: 'Během ukládání regionu uživatele došlo k chybě',
      regionAssignSuccess: 'Přiřazení regionů proběhlo úspěšně',
      regionAssignError: 'Během přiřazení regionu došlo k chybě',
      regionDeleteSuccess: 'Mazání regionu uživatele proběhlo úspěšně',
      regionDeleteFailure: 'Během mazání regionu uživatele došlo k chybě',
      regionRemoveSuccess: 'Odebrání regionů proběhlo úspěšně',
      regionRemoveFailure: 'Během odebírání regionů došlo k chybě',
      roleRemoveSuccess: 'Odebrání rolí proběhlo úspěšně',
      roleRemoveFailure: 'Během odebírání rolí došlo k chybě',
      roleSaveSuccess: 'Ukládání role proběhlo úspěšně',
      roleSaveFailure: 'Během ukládání role došlo k chybě',
      roleDeleteSuccess: 'Mazání role proběhlo úspěšně',
      roleDeleteFailure:
        'Během mazání role došlo k chybě, zkontrolujte, že k roli nejsou přiřazeni žádní uživatelé nebo oprávnění.',
      roleDiffSuccess: 'Ukládání rozdílů role skončilo úspěšně.',
      roleDiffError: 'Při ukládání rozdílů role došlo k chybě.',
      rolePrivilegeSaveSuccess: 'Ukládání oprávnění role proběhlo úspěšně',
      rolePrivilegeSaveFailure: 'Během ukládání oprávnění role došlo k chybě',
      rolePrivilegeDeleteSuccess: 'Mazání oprávnění role proběhlo úspěšně',
      rolePrivilegeDeleteFailure: 'Během mazání oprávnění role došlo k chybě',
      roleAccessRuleSaveSuccess:
        'Ukládání přístupových pravidel role proběhlo úspěšně',
      roleAccessRuleSaveFailure:
        'Během ukládání přístupových pravidel role došlo k chybě',
      roleAccessRuleDeleteSuccess:
        'Mazání přístupových pravidel role proběhlo úspěšně',
      roleAccessRuleDeleteFailure:
        'Během mazání přístupových pravidel role došlo k chybě',
      diffAccessRuleSuccess:
        'Ukládání rozdílů přístupových pravidel skončilo úspěšně.',
      diffAccessRuleError:
        'Při ukládání rozdílů přístupových pravidel došlo k chybě.',
      skillSaveSuccess: 'Ukládání dovednosti uživatele proběhlo úspěšně',
      skillDeleteSuccess: 'Mazání dovednosti uživatele proběhlo úspěšně',
      skillDeleteFailure: 'Během mazání dovednosti uživatele došlo k chybě',
      skillAlreadyAssigned: 'Uživatel má danou dovednost již přiřazenou',
      groupSaveSuccess: 'Ukládání skupiny proběhlo úspěšně',
      groupSaveFailure: 'Během ukládání skupiny došlo k chybě',
      groupDeleteSuccess: 'Mazání skupiny proběhlo úspěšně',
      groupDeleteFailure:
        'Během mazání skupiny došlo k chybě, zkontrolujte, že ke skupině nejsou přiřazeni žádní uživatelé.',
      groupRegionSaveSuccess: 'Ukládání regionu skupiny proběhlo úspěšně',
      groupRegionSaveFailure: 'Během ukládání regionu skupiny došlo k chybě',
      groupRegionAssignSuccess: 'Přiřazení regionů proběhlo úspěšně',
      groupRegionAssignFailure: 'Během přiřazení regionů došlo k chybě',
      groupRegionDeleteSuccess: 'Mazání regionu skupiny proběhlo úspěšně',
      groupRegionDeleteFailure: 'Během mazání regionu skupiny došlo k chybě',
      userSaveSuccess: 'Ukládání uživatele proběhlo úspěšně',
      userSaveFailure: 'Během ukládání uživatele došlo k chybě',
      userDeactivationSuccess: 'Deaktivace uživatele proběhla úspěšně',
      userActivationSuccess: 'Aktivace uživatele proběhla úspěšně',
      userDeactivationFailure: 'Během deaktivace uživatele došlo k chybě',
      userRoleSaveSuccess: 'Ukládání role uživatele proběhlo úspěšně',
      userRoleSaveFailure: 'Během ukládání role uživatele došlo k chybě',
      userRoleAssignSuccess: 'Udělení rolí proběhlo úspěšně',
      userRoleAssignFailure: 'Během udílení rolí došlo k chybě',
      userRoleDeleteSuccess: 'Mazání role uživatele proběhlo úspěšně',
      userRoleDeleteFailure: 'Během mazání role uživatele došlo k chybě',
      userRoleRemoveSuccess: 'Odebrání rolí proběhlo úspěšně',
      userRoleRemoveFailure: 'Během odebírání rolí došlo k chybě',
      userUserGroupSaveSuccess: 'Ukládání role uživatele proběhlo úspěšně',
      userUserGroupSaveFailure: 'Během ukládání role uživatele došlo k chybě',
      diffUserGroupSuccess: 'Ukládání rozdílů rolí uživatele skončilo úspěšně.',
      diffUserGroupError: 'Při ukládání rozdílů rolí uživatele došlo k chybě.',
      userUserGroupAddSuccess: 'Přidání členů do skupiny proběhlo úspěšně.',
      userUserGroupAddFailure:
        'Během přidávání členů do skupiny došlo k chybě.',
      userUserGroupDeleteSuccess:
        'Mazání uživatele ze skupiny proběhlo úspěšně',
      userUserGroupDeleteFailure:
        'Během mazání uživatele ze skupiny došlo k chybě',
      accessRuleRemoveSuccess: 'Odebrání pravidla přístupu proběhlo úspěšně',
      accessRuleRemoveFailure:
        'Během odebírání pravidla přístupu došlo k chybě',
      accessRuleSaveSuccess: 'Ukládání pravidla přístupu proběhlo úspěšně',
      accessRuleSaveFailure: 'Během ukládání pravidla přístupu došlo k chybě',
      accessRuleDeleteSuccess: 'Mazání pravidla přístupu proběhlo úspěšně',
      accessRuleDeleteFailure:
        'Během mazání pravidla přístupu došlo k chybě. Zkontrolujte zda pravidlo není přiřazeno roli',
      privSaveSuccess: 'Ukládání oprávnění proběhlo úspěšně',
      privSaveFailure: 'Během ukládání oprávnění došlo k chybě',
      privDeleteSuccess: 'Mazání oprávnění proběhlo úspěšně',
      privDeleteFailure: 'Během mazání oprávnění došlo k chybě',
      saveUserNotificationConfigError:
        'Během ukládání konfigurace došlo k chybě.',
      saveUserTypeSuccesMsg: 'Typ uživatele byl úspěšně uložen.',
      saveUserTypeErrorMsg: 'Během ukládání typu uživatele došlo k chybě',
      deleteUserTypeSuccessMsg: 'Mazání typu uživatele proběhlo úspěšně',
      deleteUserTypeErrorMsg:
        'Během odstranění typu uživatele došlo k chybě, zkontrolujte, zda na něm nejsou závislé jiné záznamy',
      diffUserTypeSuccess: 'Ukládání rozdílů typu uživatele skončilo úspěšně.',
      diffUserTypeError: 'Při ukládání rozdílů typu uživatele došlo k chybě.',
      saveUserGroupTypeSuccesMsg: 'Typ skupiny uživatele byl úspěšně uložen.',
      saveUserGroupTypeErrorMsg:
        'Během ukládání typu skupiny uživatele došlo k chybě',
      deleteUserGroupTypeSuccessMsg:
        'Mazání typu skupiny uživatele proběhlo úspěšně',
      deleteUserGroupTypeErrorMsg:
        'Během odstranění typu skupiny uživatele došlo k chybě, zkontrolujte, zda na něm nejsou závislé jiné záznamy',
      diffUserGroupTypeSuccess:
        'Ukládání rozdílů typu skupiny skončilo úspěšně.',
      diffUserGroupTypeError:
        'Při ukládání rozdílů typu skupiny došlo k chybě.',
    },
    registry: {
      hasPriv: 'Zkontroluje, zda má aktuální uživatel oprávnění',
      hasPolicy:
        'Zkontroluje, zda má aktuální uživatel přístup ke zdrojům pro danou policy s parametry',
      hasRole: 'Zkontroluje, zda má aktuální uživatel roli',
    },
    userParameter: {
      language: 'Jazyk (language)',
      darkTheme: 'Tmavé téma (darkTheme)',
      rememberTabviewState:
        'Pamatovat si poslední otevřenou záložku (rememberTabviewState)',
      lastLocation: 'WFM - Poslední lokace (lastLocation)',
      confirmTaskStatusChange:
        'Potvrzování změny statusu na tasku (confirmTaskStatusChange)',
      commentViewType: 'Typ zobrazení komentářů (commentViewType)',
      confirmCloseWindow:
        'Upozornit před opuštěním stránky (confirmCloseWindow)',
      confirmCloseButton:
        'Upozornění na zavření dialogového okna (confirmCloseButton)',
      floatingDetail: 'Pohyblivý detail (floatingDetail)',
      rememberLockedMenu: 'Pamatovat si zamčené menu (rememberLockedMenu)',
      rememberPinnedMenu: 'Pamatovat si připnuté menu (rememberPinnedMenu)',
      activeAccount: 'Vracet pouze aktivní accounty (activeAccount)',
      allowNotificationApi: 'Allow notification API (allowNotificationApi)',
      defaultExcelStyleErrors:
        'Make excel style errors as default (defaultExcelStyleErrors)',
      openInNewTabWithoutCtrl: 'Otevřít do nového tabu bez "Ctrl"',
      emailSender: 'Odesílatel emailu',
    },
  },
};
