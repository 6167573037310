export interface UpdateModel {
  showUpdateMessage: boolean;
  showUpdateOverlay: boolean;
  updateOverlayStep: UpdateOverlayStepEnum;
}

export enum UpdateOverlayStepEnum {
  DOWNLOADING,
  INSTALLED,
}
