export class NotificationImagesSvgBundle {
  getAll(): string[] {
    return [
      'user-gray',
      'user-group-gray',
      'user-group-important',
      'user-group-important-lock',
      'user-important',
    ];
  }
}
