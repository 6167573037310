import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {SharedRequestValidUntil} from '@tsm/framework/root';

@Injectable({
  providedIn: 'root',
})
export class MicroserviceDataService {
  private readonly BASE_URL: string;
  private plainTextHeader = new HttpHeaders().set('Accept', 'text/plain');

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.base;
  }

  @SharedRequestValidUntil()
  getActuatorData(backendUrl: string, endPoint: string): Observable<any> {
    return this.apiService
      .get(
        `${this.BASE_URL}${backendUrl?.split('/api')[0]}/actuator/${endPoint}`,
      )
      .pipe(map((x) => x.data));
  }

  @SharedRequestValidUntil()
  getActuatorDataAsPlainText(
    backendUrl: string,
    endPoint: string,
  ): Observable<any> {
    return this.apiService
      .getRaw(
        `${this.BASE_URL}${backendUrl?.split('/api')[0]}/actuator/${endPoint}`,
        null,
        {
          headers: this.plainTextHeader,
          observe: 'response',
          responseType: 'text' as 'json',
        },
      )
      .pipe(map((data) => data.body));
  }
}
