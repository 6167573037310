export const en = {
  layoutTsm: {
    addAnyItem: 'Add any item from the main menu (drag / drop)',
    clickToSave: 'Please click here to save your edits',
    loading: 'Loading',
    logOut: 'Log out',
    noMessages: 'You have no messages',
    deleteMsg: 'Delete message',
    notifications: 'Notifications',
    notificationsHotkeyText: 'Show notifications',
    notification: 'Notification',
    history: 'History',
    historyHotkeyText: 'Show the history panel',
    unreadOnly: 'Unread only',
    noAlerts: 'No alerts',
    deleteNotification: 'Delete notification',
    language: 'Language',
    czech: 'CZ',
    english: 'EN',
    all: 'All',
    mode: 'Mode',
    darkTheme: 'Dark',
    lightTheme: 'Light',
  },
};
