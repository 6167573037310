import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@tsm/framework/translate';
import {SharedModule} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {KeyFilterModule} from 'primeng/keyfilter';
import {RippleModule} from 'primeng/ripple';
import {DtlCalendar} from './calendar/calendar.component';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        RippleModule,
        FormsModule,
        KeyFilterModule,
        TranslateModule,
        DropdownModule,
        DtlCalendar,
    ],
    exports: [DtlCalendar, ButtonModule, SharedModule],
})
export class DtlCalendarModule {}
