import {Pipe, PipeTransform} from '@angular/core';
import {distinctArrays} from '@tsm/framework/functions';

@Pipe({
  name: 'userImageComponent',
  pure: true,
})
export class UserImageComponentPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return distinctArrays(null, value);
  }
}
