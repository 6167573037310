import {Injectable} from '@angular/core';
import {
  FilterModel,
  FilterOperator,
  SortModel,
  SortType,
  Table,
  TableColumn,
  TreeTableParams,
} from '../models';
import {DtlUtils} from '../utils';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PageSortFilterService {
  public getUrlFilterFromFilterModels(
    filter: FilterModel[],
    columns?: TableColumn[],
  ): string {
    if (filter == null || filter.length === 0) {
      return '';
    }
    let urlFilter = '';
    filter.forEach((x) => {
      urlFilter = urlFilter.concat(this.getUrlFilter(x, columns));
    });
    // odstranim "&" na zacatku a vrati filter url
    return urlFilter.substr(1);
  }

  public getPagingSortFilterUrl<T>(
    table: Table<T>,
    tree?: TreeTableParams,
    options?: string,
  ): string {
    let paging = 'page=0&size=20&sort=whenInserted,desc';
    if (table) {
      if (tree.isActive === true && tree.parentValue) {
        paging = `page=${0}&size=${1000}`; // pokud nejsem na prvni strance s rootama a otviram potomky
      } else {
        paging = `page=${table.page - 1}&size=${table.pageSize}`;
      }
      paging = paging.concat(this.getSortFilterUrl(table));
    }
    if (tree.isActive === true) {
      if (tree.parentValue) {
        paging = `${paging}&${tree.parentField}__eq=${tree.parentValue}`;
      } else {
        if (tree.multipleParents) {
          paging = `${paging}&${tree.parentField}__eq=00000000-0000-0000-0000-000000000000`;
        } else {
          paging = `${paging}&${tree.parentField}__empty`;
        }
      }
    }

    paging = paging + this.scriptedFields(table.columns);
    paging = paging + this.sortType(table.columns);
    paging = paging + this.addUrlParam(table.columns, table.filters);
    if (options) {
      paging = `${paging}&options=${options}`;
    }
    return paging;
  }

  public getPagingSortFilterUrlForHistory<T>(
    columns: TableColumn[],
    ids: string[],
    options?: string,
  ): string {
    let paging = 'page=0&size=2000';

    const urlFilter = '&'.concat(
      'id',
      '__',
      FilterOperator.in.toString(),
      '=',
      ids.join(','),
    );
    paging = paging.concat(urlFilter);

    // paging = paging + this.scriptedFields(columns);
    // paging = paging + this.sortType(columns);
    if (options) {
      paging = `${paging}&options=${options}`;
    }
    return paging;
  }

  private getUrlFilter(filter: FilterModel, columns?: TableColumn[]): string {
    if (filter) {
      const convertedFilter = this.convertRelativeOrLeaveBe(filter, columns);
      if (convertedFilter.field.length !== 0) {
        let field = convertedFilter.field;

        // specialni pripad vyhledani podle vsech dostupnych sloupcu
        if (columns && field === 'ALL') {
          field = columns
            .filter(
              (col) => col.filterWidget != null && col.filterFulltextSearch,
            )
            .map((col) => col.filterField || col.field)
            .join(',');
        }

        return '&'.concat(
          field,
          '__',
          convertedFilter.operator.toString().includes('FilterOperator.')
            ? convertedFilter.operator.toString().split('.')[1]
            : convertedFilter.operator.toString(),
          '=',
          this.includeValue(
            convertedFilter.operator as unknown as FilterOperator,
          )
            ? encodeURIComponent(
                // na rozdil od encodeURI() koduje i napr. "+"
                Array.isArray(convertedFilter.value)
                  ? convertedFilter.value.join(',')
                  : convertedFilter.value,
              )
            : '',
        );
      }
    }
    return null;
  }

  private includeValue(operation: FilterOperator) {
    // operatory pro ktere se nepredava hodnota
    const exceptions: FilterOperator[] = [
      FilterOperator.empty,
      FilterOperator.notempty,
    ];
    return !exceptions.includes(operation);
  }

  private getUrlSort(sort: SortModel): string {
    return '&sort='.concat(sort.field, ',', sort.sortType);
  }

  private getSortFilterUrl<T>(table: Table<T>): string {
    let urlSort = '';
    let urlFilter = '';

    if (table.sorts && table.sorts.length > 0) {
      table.sorts.forEach((sort) => {
        urlSort = urlSort.concat(this.getUrlSort(sort));
      });
    } else {
      urlSort = urlSort.concat(
        this.getUrlSort({field: 'whenInserted', sortType: SortType.desc}),
      );
    }

    if (Array.isArray(table.filters)) {
      // @ts-ignore
      const filteredColumns = table.filters.filter((filter) => {
        if (filter.operator && filter.value) {
          if (
            Array.isArray(filter.value) &&
            filter.value.filter((val) => val).length
          ) {
            return true;
          } else if (!Array.isArray(filter.value)) {
            return true;
          }
        }
      });
      filteredColumns.forEach((filter) => {
        urlFilter = urlFilter.concat(this.getUrlFilter(filter, table.columns));
      });
    }
    return urlSort.concat(urlFilter);
  }

  /** odeslat i skriptované sloupce na backend */
  private scriptedFields(columns: TableColumn[]): string {
    if (columns) {
      const tmpColumns = columns.filter(
        (col) => col.visible && col.scriptField,
      );
      if (tmpColumns.length > 0) {
        return (
          '&' +
          tmpColumns
            .map(
              (col) =>
                // název pole a skript
                col.field + '=' + encodeURIComponent(col.scriptField),
            )
            .join('&')
        );
      }
    }
    return '';
  }

  /** Typ řazení */
  private sortType(columns: TableColumn[]): string {
    if (columns) {
      const tmpColumns = columns.filter(
        (col) => col.visible && col.sortType && col.sortDisabled != true,
      );
      if (tmpColumns.length > 0) {
        return (
          '&' +
          tmpColumns
            .map(
              (col) =>
                // typ řazení pro skriptované pole, pokud je uvedeno.
                'sortType:' +
                col.field +
                '=' +
                encodeURIComponent(col.sortType),
            )
            .join('&')
        );
      }
    }
    return '';
  }

  /** Typ řazení */
  private addUrlParam(columns: TableColumn[], filters: FilterModel[]): string {
    if (columns) {
      const tmpColumns = columns.filter((col) => col.addUrlParam != null);
      if (tmpColumns.length > 0) {
        return (
          '&' +
          tmpColumns
            .filter((c) =>
              filters.some(
                (f) => f.field === c.field || f.field === c.filterField,
              ),
            )
            .map((col) => col.addUrlParam)
            .join('&')
        );
      }
    }
    return '';
  }

  /**
   * relativni filter prelozi na absolutni podle aktualniho data, ostatni filtery vrati beze zmeny
   * */
  private convertRelativeOrLeaveBe(
    filter: FilterModel,
    columns?: TableColumn[],
  ): FilterModel {
    //HACK pro datumy, EQ posilat jako BTW, protoze DB to neumi zpracovat
    if (this.isRelative(filter)) {
      return this.convertToNonRelative(filter);
    } else {
      const foundColumn = columns?.find(
        (c) => filter.field === c.field || filter.field === c.filterField,
      );
      if ((foundColumn?.filterWidget as any)?.isCalendarRangeFilter == true) {
        if (filter.operator == 'eq') {
          return {
            ...filter,
            operator: FilterOperator.btw,
          };
        }
      }
      return filter;
    }
  }

  private isRelative(filter: FilterModel): boolean {
    return (
      filter.operator === FilterOperator.gtr ||
      filter.operator === FilterOperator.ltr ||
      filter.operator === FilterOperator.btwr
    );
  }

  private convertToNonRelative(filter: FilterModel): FilterModel {
    let newFilter = null;
    const datePipe = new DatePipe('cs');

    if (filter.operator === FilterOperator.btwr) {
      const periodFirst = filter.value[0];
      const periodSecond = filter.value[1];
      const dateRound = filter.value[2];

      const boundaryDateFirst = DtlUtils.getDateFromPeriodAndToday(
        periodFirst,
        dateRound,
      );
      const boundaryDateSecond = DtlUtils.getDateFromPeriodAndToday(
        periodSecond,
        dateRound,
      );
      newFilter = {
        ...filter,
        operator: FilterOperator.btw,
        value: [
          datePipe.transform(boundaryDateFirst, "yyyy-MM-dd'T'HH:mm:ss.SSSZ"),
          datePipe.transform(boundaryDateSecond, "yyyy-MM-dd'T'HH:mm:ss.SSSZ"),
        ],
      };
    } else {
      const operator =
        filter.operator === FilterOperator.gtr
          ? FilterOperator.gte
          : FilterOperator.lte;
      const period = filter.value[0];
      const dateRound = filter.value[1];

      const boundaryDate = DtlUtils.getDateFromPeriodAndToday(
        period,
        dateRound,
      );

      newFilter = {
        ...filter,
        operator: operator,
        value: datePipe.transform(boundaryDate, "yyyy-MM-dd'T'HH:mm:ss.SSSZ"),
      };
    }

    return newFilter;
  }
}
