import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {ListingProfile} from '../models';

export const LoadListingProfile = createAction(
  '[ListingProfile] Load ListingProfile',
);
export const LoadListingProfileSuccess = createAction(
  '[ListingProfile] Load ListingProfile Success',
  props<{
    listingProfiles: ListingProfile[];
  }>(),
);
export const LoadListingProfileError = createAction(
  '[ListingProfile] Load ListingProfile Error',
  props<TsmError>(),
);

export const LoadListingProfileById = createAction(
  '[ListingProfile] Load LoadListingProfileById',
  props<{
    id: string;
    forcedReload?: boolean;
  }>(),
);
export const LoadListingProfileByIdSuccess = createAction(
  '[ListingProfile] Load LoadListingProfileById Success',
  props<{
    listingProfile: ListingProfile;
  }>(),
);
export const LoadListingProfileByIdError = createAction(
  '[ListingProfile] Load LoadListingProfileById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadListingProfileByCode = createAction(
  '[ListingProfile] Load LoadListingProfileByCode',
  props<{
    code: string;
  }>(),
);
export const LoadListingProfileByCodeSuccess = createAction(
  '[ListingProfile] Load LoadListingProfileByCode Success',
  props<{
    listingProfile: ListingProfile;
  }>(),
);
export const LoadListingProfileByCodeError = createAction(
  '[ListingProfile] Load LoadListingProfileByCode Error',
  props<{
    code: string;
    error: TsmError;
  }>(),
);

export const LoadListingProfileByCodeAndListingCode = createAction(
  '[ListingProfile] Load LoadListingProfileByCodeAndListingCode',
  props<{
    code: string;
    listingCode: string;
  }>(),
);
export const LoadListingProfileByCodeAndListingCodeSuccess = createAction(
  '[ListingProfile] Load LoadListingProfileByCodeAndListingCode Success',
  props<{
    listingProfile: ListingProfile;
  }>(),
);
export const LoadListingProfileByCodeAndListingCodeError = createAction(
  '[ListingProfile] Load LoadListingProfileByCodeAndListingCode Error',
  props<{
    code: string;
    listingCode: string;
    error: TsmError;
  }>(),
);

export const LoadListingProfileByCodes = createAction(
  '[ListingProfile] Load LoadListingProfileByCodes',
  props<{
    codes: string[];
  }>(),
);
export const LoadListingProfileByCodesSuccess = createAction(
  '[ListingProfile] Load LoadListingProfileByCodes Success',
  props<{
    listingProfiles: ListingProfile[];
  }>(),
);
export const LoadListingProfileByCodesError = createAction(
  '[ListingProfile] Load LoadListingProfileByCodes Error',
  props<{
    error: TsmError;
  }>(),
);

export const InsertListingProfile = createAction(
  '[ListingProfile] Insert ListingProfile',
  props<{
    listingProfile: ListingProfile;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const InsertListingProfileSuccess = createAction(
  '[ListingProfile] Insert ListingProfile Success',
  props<{
    listingProfile: ListingProfile;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const InsertListingProfileError = createAction(
  '[ListingProfile] Insert ListingProfile Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const UpdateListingProfile = createAction(
  '[ListingProfile] Update ListingProfile',
  props<{
    listingProfile: ListingProfile;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpdateListingProfileSuccess = createAction(
  '[ListingProfile] Update ListingProfile Success',
  props<{
    listingProfile: ListingProfile;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpdateListingProfileError = createAction(
  '[ListingProfile] Update ListingProfile Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const DeleteListingProfile = createAction(
  '[ListingProfile] Delete ListingProfile',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing: boolean;
  }>(),
);
export const DeleteListingProfileSuccess = createAction(
  '[ListingProfile] Delete ListingProfile Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteListingProfileError = createAction(
  '[ListingProfile] Delete ListingProfile Error',
  props<TsmError>(),
);

export const DiffListingProfile = createAction(
  '[ListingProfile] Diff ListingProfile',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffListingProfileSuccess = createAction(
  '[ListingProfile] Diff ListingProfile Success',
  props<{
    listingProfiles: ListingProfile[];
    listingId: string;
  }>(),
);
export const DiffListingProfileError = createAction(
  '[ListingProfile] Diff ListingProfile Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
