import {DesignerCategoryEnum, Plugin} from '@tsm/framework/plugin';

import {translation as translationPlugin} from '@tsm/sla/plugins-i18n';

export const slaPlugin: Plugin = {
  menus: [
    // {
    //   key: 'settings', priority: 400, label: 'menu.settings.settings',
    //   icon: 'tsm-icon-settings', privilege: ['true'], items: [
    //     {
    //       key: 'sla-list', label: 'menu.settings.sla',
    //       icon: 'camera_front', routerLink: ['/sla-list'], privilege: ['Settings.ViewList']
    //     },
    //     {
    //       key: 'tsm-table-column-config-list',
    //       label: 'menu.settings.tableColumnConfiguration',
    //       icon: 'tsm-icon-edit-columns-1',
    //       routerLink: ['/user-management/table-column-config'],
    //       listingType: 'tsm-table-column-config-list',
    //       privilege: ['true']
    //     },
    //     {
    //       key: 'dms',
    //       priority: 1100,
    //       label: 'menu.notifications.notifications',
    //       icon: 'tsm-icon-notification',
    //       privilege: ['Dms.Notifications'],
    //       items: [{
    //         key: 'notification',
    //         label: 'menu.notifications.notifications',
    //         icon: 'tsm-icon-notification',
    //         routerLink: ['/dms/notification'],
    //         privilege: ['true'],
    //         priority: 0
    //       },
    //         {
    //           key: 'notificationTemplate',
    //           label: 'menu.notifications.notificationTemplates',
    //           icon: 'tsm-icon-design',
    //           routerLink: ['/dms/notification-template'],
    //           privilege: ['true'],
    //           priority: 0
    //         },
    //       ]
    //     },
    //     {
    //       key: 'om',
    //       priority: 1100,
    //       label: 'menu.outputManagement.outputManagement',
    //       icon: 'tsm-icon-settings',
    //       privilege: ['true'],
    //       items: [
    //         {
    //         key: 'formatterTemplate',
    //         label: 'menu.outputManagement.templates',
    //         icon: 'tsm-icon-design',
    //         routerLink: ['/dms/formatter-template'],
    //         privilege: ['true'],
    //         priority: 0
    //       },
    //         {
    //           key: 'formatterDocumentTemplate',
    //           label: 'menu.outputManagement.documentTemplates',
    //           icon: 'tsm-icon-design',
    //           routerLink: ['/dms/formatter-document-template'],
    //           privilege: ['true'],
    //           priority: 0
    //         },
    //       ]
    //     }
    //   ]
    // }
  ],
  routes: [
    {
      path: 'sla-list',
      loadChildren: () =>
        import('@tsm/sla/components').then((x) => x.SlaModule),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/sla/widgets').then((x) => x.SlaWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/sla/widgets/filters/sla-type-filer').then(
              (x) => x.SlaTypeFilterComponent,
            ),
          selector: 'tsm-sla-type-filter',
          name: translationPlugin.slaPlugins.filters.tsmSlaTypeFilter,
          description:
            translationPlugin.slaPlugins.filters.tsmSlaTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/sla/widgets').then((x) => x.SlaWidgetsModule),
      definitions: [
        {
          selector: 'slaLoader',
          name: translationPlugin.slaPlugins.pipes.slaLoader,
          description: translationPlugin.slaPlugins.pipes.slaLoaderDescription,
        },
        {
          selector: 'slaMetricCondition',
          name: translationPlugin.slaPlugins.pipes.slaMetricCondition,
          description:
            translationPlugin.slaPlugins.pipes.slaMetricConditionDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/sla/widgets').then((x) => x.SlaWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/sla/widgets/sla-lov').then((x) => x.SlaLovComponent),
          selector: 'tsm-sla-lov',
        },
        {
          loadComponent: () =>
            import('@tsm/sla/widgets/sla-card/sla-card').then(
              (x) => x.SlaCardComponent,
            ),
          selector: 'tsm-sla-card',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import('@tsm/sla/widgets/sla-card/sla-card-editor').then(
                  (x) => x.SlaCardEditorComponent,
                ),
              editorSelector: 'tsm-sla-card-editor',
              widgetOrLayout: 'layout',
              name: 'Sla card',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: 'Sla card',
              icon: 'p-icon-person-add',
              value: {
                type: 'layout',
                title: 'Sla card',
                widget: {
                  type: 'tsm-sla-card',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
