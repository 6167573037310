import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {Worklog, WorklogTimer} from '../models';
import {
  FilterModel,
  GridDataHttpModel,
  PageSortFilterService,
} from '@tsm/listing-lib/service';

@Injectable({
  providedIn: 'root',
})
export class WorklogDataService {
  private readonly WORKLOG = `worklog`;
  private readonly STOPWATCH = 'stopwatch';
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private pageSortFilterService: PageSortFilterService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getWorklogByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<Worklog[]>> {
    return this.apiService.get<Worklog[], Worklog[]>(
      `${this.BASE_URL}/${this.WORKLOG}/${ownerType}/${ownerId}`,
    );
  }

  getWorklogByFilters(
    userId: string,
    page: number,
    size: number,
    filters?: FilterModel[],
  ): Observable<Envelope<GridDataHttpModel<Worklog>>> {
    let url = `${this.BASE_URL}/${this.WORKLOG}/filtering?page=${page}&size=${size}&sort=whenInserted,desc`;
    if (filters?.length > 0) {
      url += `&${this.pageSortFilterService.getUrlFilterFromFilterModels(
        filters,
      )}`;
    }
    return this.apiService.get(url);
  }

  saveWorklog(
    ownerId: string,
    ownerType: string,
    worklog: Worklog,
  ): Observable<Envelope<Worklog>> {
    return this.apiService.post<Worklog, Worklog>(
      `${this.BASE_URL}/${this.WORKLOG}/${ownerType}/${ownerId}`,
      worklog,
    );
  }

  startWorklogStopwatch(worklogTimer: WorklogTimer) {
    return this.apiService.post<WorklogTimer, WorklogTimer>(
      `${this.BASE_URL}/${this.WORKLOG}/${this.STOPWATCH}/${worklogTimer.ownerType}/${worklogTimer.ownerId}`,
      null,
    );
  }

  stopWorklogTimer() {
    return this.apiService.post<WorklogTimer, WorklogTimer>(
      `${this.BASE_URL}/${this.WORKLOG}/${this.STOPWATCH}`,
      null,
    );
  }

  deleteWorklogById(id: string): Observable<Envelope<Worklog>> {
    return this.apiService.delete<Worklog, Worklog>(
      `${this.BASE_URL}/${this.WORKLOG}/${id}`,
    );
  }

  getTimeSpentFor(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<number>> {
    return this.apiService.get<number, number>(
      `${this.BASE_URL}/v1/worklogs/${ownerType}/${ownerId}/spent`,
    );
  }
}
