export const en = {
  userManagementService: {
    effects: {
      regionSaveSuccess: "The user's region was successfully saved",
      regionAlreadyAssigned: 'The user has already got this region assigned',
      regionSaveError: "There was an error while saving the user's region",
      regionAssignSuccess: 'The region assignment completed successfully',
      regionAssignError: 'There was an error while assigning the region',
      regionDeleteSuccess: 'The region deletion completed successfully',
      regionDeleteFailure:
        "There was an error while deleting the user's region",
      regionRemoveSuccess: 'The region removal completed successfully',
      regionRemoveFailure: 'There was an error while removing the regions',
      roleRemoveSuccess: 'The Role removal completed successfully',
      roleRemoveFailure: 'There was an error while removing the roles',
      roleSaveSuccess: 'The role was successfully saved',
      roleSaveFailure: 'There was an error while saving the role',
      roleDeleteSuccess: 'The role deletion completed successfully',
      roleDeleteFailure:
        'There was an error while deleting the role, check that there are no users or permissions assigned to this role.',
      roleDiffSuccess: 'Saving role diff finished with success',
      roleDiffError: 'Error occurred during saving role diff.',
      rolePrivilegeSaveSuccess: 'The role privilege was successfully saved',
      rolePrivilegeSaveFailure:
        'There was an error while saving the role permission',
      rolePrivilegeDeleteSuccess: 'The role deletion completed successfully',
      rolePrivilegeDeleteFailure:
        "There was an error while deleting the role's permission",
      roleAccessRuleSaveSuccess: 'The role access rule was successfully saved',
      roleAccessRuleSaveFailure:
        'There was an error while saving the role access rule',
      roleAccessRuleDeleteSuccess:
        'The role access rule deletion completed successfully',
      roleAccessRuleDeleteFailure:
        "There was an error while deleting the role access rule's permission",
      skillSaveSuccess: "The user's skill was successfully saved",
      skillDeleteSuccess: "The skill's deletion completed successfully",
      skillDeleteFailure: "There was an error while deleting the user's skill",
      skillAlreadyAssigned: 'The user has already got this skill',
      groupSaveSuccess: 'The group was successfully saved',
      groupSaveFailure: 'There was an error while saving the group',
      groupDeleteSuccess: 'There group deletion completed successfully',
      groupDeleteFailure:
        'There was an error when deleting the group, check that there are no users assigned to this group.',
      groupRegionSaveSuccess: "The group's region was successfully saved",
      groupRegionSaveFailure:
        "There was an error while saving the group's region",
      groupRegionAssignSuccess: 'The region was successfully assigned',
      groupRegionAssignFailure:
        'There was an error while assigning the regions',
      groupRegionDeleteSuccess: "The group's region was successfully deleted",
      groupRegionDeleteFailure:
        "There was an error while deleting the group's region",
      userSaveSuccess: 'The user was successfully saved',
      userSaveFailure: 'There was an error while saving the user',
      userDeactivationSuccess: 'The user was successfully deactivated',
      userActivationSuccess: 'The user was successfully activated',
      userDeactivationFailure: 'There was an error while deactivating the user',
      userRoleSaveSuccess: 'The user role was successfully saved',
      userRoleSaveFailure: "There was an error while saving the user's role",
      userRoleAssignSuccess: 'The roles were successfully assigned',
      userRoleAssignFailure: 'There was an error while assigning the roles',
      userRoleDeleteSuccess: "The user's role was successfully deleted",
      userRoleDeleteFailure:
        "There was an error while deleting the user's role",
      userRoleRemoveSuccess: 'The roles were successfully removed',
      userRoleRemoveFailure: 'There was an error while removing the roles',
      userUserGroupSaveSuccess: "The user's role was successfully saved",
      userUserGroupSaveFailure:
        "There was an error while saving the user's role",
      diffUserGroupSuccess: "Saving user's role diff finished with success",
      diffUserGroupError: "Error occurred during saving user's role diff.",
      userUserGroupAddSuccess: 'The users were successfully added to the group',
      userUserGroupAddFailure:
        'There was an error while adding the users to the group',
      userUserGroupDeleteSuccess:
        'The user was removed from the group successfully',
      userUserGroupDeleteFailure:
        'There was an error while deleting the user from the group',
      accessRuleRemoveSuccess: 'The access rule removal completed successfully',
      accessRuleRemoveFailure:
        'There was an error while removing the access ruls',
      accessRuleSaveSuccess: 'The access rule was successfully saved',
      accessRuleSaveFailure: 'There was an error while saving the access rule',
      accessRuleDeleteSuccess:
        'The access rule deletion completed successfully',
      accessRuleDeleteForeignKeyFailure:
        'The access rule deletion completed successfully',
      accessRuleDeleteFailure:
        'There was an error while deleting the access rule, check that there are no users or permissions assigned to this access rule.',
      diffAccessRuleSuccess: 'Saving Access rule diff finished with success',
      diffAccessRuleError: 'Error occurred during saving access rule diff.',
      privSaveSuccess: 'The priv was successfully saved',
      privSaveFailure: 'There was an error while saving the priv',
      privDeleteSuccess: 'The priv deletion completed successfully',
      privDeleteFailure: 'There was an error while deleting the priv',
      saveUserNotificationConfigError:
        'There was an error while saving configuration.',
      diffPrivsSuccess: 'Saving privs diff finished with success',
      diffPrivsError: 'Error occurred during saving privs diff.',
      saveUserTypeSuccesMsg: 'Saving user type was successful.',
      saveUserTypeErrorMsg: 'An error occurred while saving user type',
      deleteUserTypeSuccessMsg: 'Deleting user type finished with success',
      deleteUserTypeErrorMsg:
        "Error occurred during deleting user type, please check if there aren't any record dependent on this one",
      diffUserTypeSuccess: 'Saving user type diff finished with success',
      diffUserTypeError: 'Error occurred during saving user type diff.',
      saveUserGroupTypeSuccesMsg: 'Saving user group type was successful.',
      saveUserGroupTypeErrorMsg:
        'An error occurred while saving user group type',
      deleteUserGroupTypeSuccessMsg:
        'Deleting user group type finished with success',
      deleteUserGroupTypeErrorMsg:
        "Error occurred during deleting user group type, please check if there aren't any record dependent on this one",
      diffUserGroupTypeSuccess: 'Saving group type diff finished with success',
      diffUserGroupTypeError: 'Error occurred during saving group type diff.',
    },
    registry: {
      hasPriv: 'Checks if current user has a privilege',
      hasPolicy:
        'Checks if current user has access for resources for a given policy with parameters',
      hasRole: 'Checks if current user has role',
    },
    userParameter: {
      language: 'Language (language)',
      darkTheme: 'Dark theme (darkTheme)',
      rememberTabviewState: 'Remember tabview state (rememberTabviewState)',
      lastLocation: 'Last location (lastLocation)',
      confirmTaskStatusChange:
        'Confirm task status change (confirmTaskStatusChange)',
      commentViewType: 'Comment view type (commentViewType)',
      confirmCloseWindow:
        'Confirm before leaving the page (confirmCloseWindow)',
      confirmCloseButton:
        'Confirm before leaving dialog window (confirmCloseButton)',
      floatingDetail: 'Floating detail (floatingDetail)',
      rememberLockedMenu: 'Remember locked menu (rememberLockedMenu)',
      rememberPinnedMenu: 'Remember pinned menu (rememberPinnedMenu)',
      activeAccount: 'Active account (activeAccount)',
      allowNotificationApi: 'Allow notification API (allowNotificationApi)',
      defaultExcelStyleErrors:
        'Make excel style errors as default (defaultExcelStyleErrors)',
      openInNewTabWithoutCtrl: 'Open in new tab without "Ctrl"',
      emailSender: 'Email sender',
    },
  },
};
