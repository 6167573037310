import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {RoleAccessRule} from '../model';

export const LoadRoleAccessRulesByRoleId = createAction(
  '[RoleAccessRule] Load RoleAccessRules ByRoleId',
  props<{
    roleId: string;
  }>(),
);
export const LoadRoleAccessRulesByRoleIdSuccess = createAction(
  '[RoleAccessRule] Load RoleAccessRules ByRoleId Success',
  props<{
    entities: RoleAccessRule[];
  }>(),
);
export const LoadRoleAccessRulesByRoleIdError = createAction(
  '[RoleAccessRule] Load RoleAccessRules ByRoleId Error',
  props<{
    error: TsmError;
  }>(),
);

export const LoadRoleAccessRulesByAccessRuleId = createAction(
  '[RoleAccessRule] Load RoleAccessRules ByAccessRuleId',
  props<{
    accessRuleId: string;
  }>(),
);
export const LoadRoleAccessRulesByAccessRuleIdSuccess = createAction(
  '[RoleAccessRule] Load RoleAccessRules ByAccessRuleId Success',
  props<{
    entities: RoleAccessRule[];
  }>(),
);
export const LoadRoleAccessRulesByAccessRuleIdError = createAction(
  '[RoleAccessRule] Load RoleAccessRules ByAccessRuleId Error',
  props<{
    error: TsmError;
  }>(),
);

export const UpsertRoleAccessRule = createAction(
  '[RoleAccessRule] Upsert RoleAccessRule',
  props<{
    roleAccessRule: RoleAccessRule;
  }>(),
);
export const UpsertRoleAccessRuleSuccess = createAction(
  '[RoleAccessRule] Upsert RoleAccessRule Success',
  props<{
    roleAccessRule: RoleAccessRule;
  }>(),
);
export const UpsertRoleAccessRuleError = createAction(
  '[RoleAccessRule] Upsert RoleAccessRule Error',
  props<{
    error: TsmError;
  }>(),
);

export const SaveAllRoleAccessRules = createAction(
  '[RoleAccessRule] Save RoleAccessRules',
  props<{
    roleAccessRules: RoleAccessRule[];
  }>(),
);
export const SaveAllRoleAccessRulesSuccess = createAction(
  '[RoleAccessRule] Save All RoleAccessRules Success',
  props<{
    roleAccessRules: RoleAccessRule[];
  }>(),
);
export const SaveAllRoleAccessRulesError = createAction(
  '[RoleAccessRule] Save All RoleAccessRules Error',
  props<{
    error: TsmError;
  }>(),
);

export const DeleteRoleAccessRule = createAction(
  '[RoleAccessRule] Delete RoleAccessRule',
  props<{id: string}>(),
);
export const DeleteRoleAccessRuleSuccess = createAction(
  '[RoleAccessRule] Delete RoleAccessRule Success',
  props<{
    id: string;
  }>(),
);
export const DeleteRoleAccessRuleError = createAction(
  '[RoleAccessRule] Delete RoleAccessRule Error',
  props<{
    error: TsmError;
  }>(),
);

export const DeleteAllRoleAccessRules = createAction(
  '[RoleAccessRule] Delete All RoleAccessRules',
  props<{
    ids: string[];
  }>(),
);
export const DeleteAllRoleAccessRulesSuccess = createAction(
  '[RoleAccessRule] Delete All RoleAccessRules Success',
  props<{
    ids: string[];
  }>(),
);
export const DeleteAllRoleAccessRulesError = createAction(
  '[RoleAccessRule] Delete All RoleAccessRules Error',
  props<{
    error: TsmError;
  }>(),
);
