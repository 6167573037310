import {Pipe, untracked} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {LoadScriptByCode} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectScriptByCode} from '../selectors';

@Pipe({
  name: 'scriptCodeLoader',
  pure: true,
})
export class ScriptCodeLoaderPipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(id: string, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectScriptByCode(id)),
      filter((it) => it != null && it.loading == false),
      map((it) => {
        if (!it.data || it.error != null) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectScriptByCode(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadScriptByCode({code: this._id})),
          );
        }
      });
  }
}
