export const cs = {
  tablePaginator: {
    paginatorSettings: 'Nastavení stránkování',
    to: 'až',
    of: 'z',
    pageSize: 'Poč. záznamů na stránku',
    pageSettings: 'Nastavení stránky',
    minPage: 'Min. stránka je',
    maxPage: 'Max. stránka je',
  },
};
