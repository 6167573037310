export const en = {
  dmsPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmCommentTypeFilter: 'tSM Comment Type Filter',
      tsmCommentTypeFilterDescription:
        'Filters comment types using a multi-selection overlay panel. ' +
        'Retrieves valid comment types from the store and filters them ' +
        'based on the provided context. Displays selected types and ' +
        'allows users to confirm or cancel selections.',
      tsmCommentUserTypeFilter: 'tSM Comment User Type Filter',
      tsmCommentUserTypeFilterDescription:
        'Filters user comment types using a dropdown selection. ' +
        'Allows users to choose between individual and organization types. ' +
        'Applies equality filtering and updates the selection display value.',
      tsmDeliveryItemTypeFilter: 'tSM Delivery Item Type Filter',
      tsmDeliveryItemTypeFilterDescription:
        'Filters delivery item types using a selectable list. ' +
        'Displays available types in a table format with multiple selection enabled. ' +
        'Retrieves options from the service and allows users to select multiple items. ' +
        'Applies the selected values and updates the display representation accordingly. ' +
        'Supports selecting all or individual items and dynamically updates the selection.',
      tsmFormatterDocumentSizeFilter: 'tSM Formatter Document Size Filter',
      tsmFormatterDocumentSizeFilterDescription:
        'Filters formatter document sizes using a selectable list. ' +
        'Displays available sizes in a table format with multiple selection enabled. ' +
        'Retrieves options from the service and allows users to select multiple items. ' +
        'Applies the selected values and updates the display representation accordingly. ' +
        'Supports selecting all or individual items and dynamically updates the selection.',
      tsmFormatterDocumentTemplateFilter:
        'tSM Formatter Document Template Filter',
      tsmFormatterDocumentTemplateFilterDescription:
        'Filters formatter document templates using a selectable list. ' +
        'Displays available templates in a table format with multiple selection enabled. ' +
        'Retrieves options dynamically from the service and allows users to filter and select ' +
        'items. Applies the selected values and updates the display representation accordingly. ' +
        'Supports sorting, searching, and efficient data retrieval.',
      tsmFormatterDocumentTypeFilter: 'tSM Formatter Document Type Filter',
      tsmFormatterDocumentTypeFilterDescription:
        'Filters formatter document types using a selectable list. ' +
        'Displays available document types in a table format with multiple selection enabled. ' +
        'Retrieves options dynamically from the service and allows users to filter and select items. ' +
        'Applies the selected values and updates the display representation accordingly. ' +
        'Supports sorting, searching, and efficient data retrieval.',
      tsmFormatterInputTypeFilter: 'tSM Formatter Input Type Filter',
      tsmFormatterInputTypeFilterDescription:
        'Filters formatter input types using a selectable list. ' +
        'Displays available input types in a table format with multiple selection enabled. ' +
        'Retrieves options dynamically from the service and allows users to filter and select items. ' +
        'Applies the selected values and updates the display representation accordingly. ' +
        'Supports sorting, searching, and efficient data retrieval.',
      tsmFormatterOutputTypeFilter: 'tSM Formatter Output Type Filter',
      tsmFormatterOutputTypeFilterDescription:
        'Filters formatter output types using a selectable list. ' +
        'Displays available output types in a table format with multiple selection enabled. ' +
        'Retrieves options dynamically from the service and allows users to filter and select items. ' +
        'Applies the selected values and updates the display representation accordingly. ' +
        'Supports sorting, searching, and efficient data retrieval.',
      tsmKafkaDlqStatusFilter: 'tSM Kafka Dlq Status Filter',
      tsmKafkaDlqStatusFilterDescription:
        'Filters Kafka DLQ log statuses using a table-based selection interface. ' +
        'Displays available statuses with multiple selection support. ' +
        'Retrieves status options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports sorting, searching, and efficient data handling.',
      tsmNotificationRuleEventTypeFilter:
        'tSM Notification Rule Event Type Filter',
      tsmNotificationRuleEventTypeFilterDescription:
        'Filters notification rule event types using a table-based selection interface. ' +
        'Displays available event types with multiple selection support. ' +
        'Retrieves event type options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports sorting, searching, and efficient data handling.',
      tsmNotificationRuleFilter: 'tSM Notification Rule Filter',
      tsmNotificationRuleFilterDescription:
        'Filters notification rules using a table-based selection interface. ' +
        'Displays available notification rules with multiple selection support. ' +
        'Retrieves rule options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports searching and filtering by rule name and code.',
      tsmNotificationStatusFilter: 'tSM Notification Status Filter',
      tsmNotificationStatusFilterDescription:
        'Filters notification statuses using a table-based selection interface. ' +
        'Displays available statuses with multiple selection support. ' +
        'Retrieves status options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports searching and filtering by status name.',
      tsmNotificationTemplateCategoryFilter:
        'tSM Notification Template Category Filter',
      tsmNotificationTemplateCategoryFilterDescription:
        'Filters notification template categories using a table-based selection interface. ' +
        'Displays available categories with multiple selection support. ' +
        'Retrieves category options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports searching and filtering by category name.',
      tsmNotificationTemplateStatusFilter:
        'tSM Notification Template Status Filter',
      tsmNotificationTemplateStatusFilterDescription:
        'Filters notification template statuses using a table-based selection interface. ' +
        'Displays available statuses with multiple selection support. ' +
        'Retrieves status options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports searching and filtering by status name.',
      tsmNotificationTypeFilter: 'tSM Notification Type Filter',
      tsmNotificationTypeFilterDescription:
        'Filters notification types using a table-based selection interface. ' +
        'Displays available types with multiple selection support. ' +
        'Retrieves type options dynamically from the service. ' +
        'Applies selected values and updates the displayed filter criteria. ' +
        'Supports searching and filtering by type name.',
      tsmUserUsergroupRefFilter: 'tSM User Usergroup Ref Filter',
      tsmUserUsergroupRefFilterDescription:
        'Filters user groups using a reference-based selection component. ' +
        'Displays available groups and allows multiple selections. ' +
        'Stores selected values and applies them to the filter criteria. ' +
        'Uses an overlay panel for selection with confirmation and cancellation options. ' +
        'Supports filtering by group reference name.',
      tsmUserUsergroupRefMultiselectFilter:
        'tSM User Usergroup Ref Multiselect Filter',
      tsmUserUsergroupRefMultiselectFilterDescription:
        'Filters users and user groups with a multi-select table. ' +
        'Fetches data from the store and combines user and group entries. ' +
        'Supports pagination, sorting, and full-text search filtering. ' +
        'Uses an overlay panel with selection confirmation and cancellation. ' +
        'Applies selected values as filter criteria using an "in" operator.',
    },
    pipes: {
      containsKeyValue: 'Contains Key Value',
      containsKeyValueDescription:
        'Determines if a given structure includes a specific key-value pair through deep traversal.',
      countAttachments: 'Count Attachments',
      countAttachmentsDescription:
        'Returns the number of attachments for a specific owner ID and type.',
      entityNameLoader: 'Entity Name Loader',
      entityNameLoaderDescription:
        'Retrieves entity data, such as User or UserGroup names, by dynamically selecting and loading data from the store.',
      formatterDocumentTemplateLoader: 'Formatter Document TemplateLoader',
      formatterDocumentTemplateLoaderDescription:
        'Loads and manages formatter document template data from the store, ensuring access to specific fields or full content.',
      formatterTemplateLoader: 'Formatter Template Loader',
      formatterTemplateLoaderDescription:
        'Loads and manages formatter template data from the store, allowing access to specific fields or the full template.',
      minutesToText: 'Minutes To Text',
      minutesToTextDescription:
        'Converts a duration in minutes into a human-readable text format, displaying hours and minutes.',
      notificationAddress: 'Notification Address',
      notificationAddressDescription:
        'Formats address data into a readable string, prioritizing the formatted address if available, or combining individual address components such as street, city, and postal code into a concise output.',
      notificationCustomerById: 'Notification Customer by ID',
      notificationCustomerByIdDescription:
        'Resolves and returns the customer key associated with a notification based on its owner type, integrating with various APIs to fetch customer data for types such as Customer, Account, OCS_INVOICE, or Ticket and Order.',
      notificationRelatedEntityById: 'Notification Related Entity By Id',
      notificationRelatedEntityByIdDescription:
        'Retrieves related entities for a given notification ID.',
      notificationRuleCode: 'Notification Rule Code',
      notificationRuleCodeDescription:
        'Fetches and observes notification rule data by code from a store, providing specific fields or full data and triggers actions to load the data, if it is not already available.',
      notificationTemplateByCode: 'Notification Template By Code',
      notificationTemplateByCodeDescription:
        'Fetches a notification template by its code from the store, allowing access to specific fields or full template data, and ensures the data is loaded if not already available.',
      notificationTemplateLoader: 'Notification Template Loader',
      notificationTemplateLoaderDescription:
        'Loads and provides notification template data from the store by ID, supporting specific field selection and ensuring data is fetched if unavailable.',
      safeHtml: 'Safe Html',
      safeHtmlDescription:
        'Sanitizes HTML content to ensure it is safe for use in the DOM, preventing potential security risks from malicious code injection.',
      notificationTo: 'Notification Recipients',
      notificationToDescription:
        'Based on the owner type, determines and translates the recipients of a notification.',
    },
  },
};
