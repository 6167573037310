import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as effects from './effects';
import * as reducers from './reducers';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('uiConfig', reducers.reducers),
    EffectsModule.forFeature([effects.UiConfigEffects]),
  ],
  providers: [],
  exports: [],
})
export class UiConfigModule {}
