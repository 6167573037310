import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'tsm-icon-picker-icon',
  templateUrl: 'tsm-icon-picker-icon.component.html',
  styleUrls: ['../tsm-icon-picker/tsm-icon-picker.component.scss'],
})
export class TsmIconPickerIconComponent {
  @Input() filteredIcons: string[];

  @Input() prefix: string;

  @Input() mixed = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onIconSelected: EventEmitter<any> = new EventEmitter();
}
