import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {UserParameter} from '../model';

@Injectable({
  providedIn: 'root',
})
export class UserParametersService {
  private readonly BASE_URL: string;
  private USER_PARAMETERS: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.userManagement;
    this.USER_PARAMETERS = 'user-parameter';
  }

  deleteUserParam(
    userId: string,
    userParamName: string,
  ): Observable<Envelope<any>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/${userId}/${userParamName}`;
    return this.apiService.delete(url);
  }

  getUserParam(
    userId: string,
    userParamName: string,
  ): Observable<Envelope<UserParameter>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/${userId}/${userParamName}`;
    return this.apiService.get(url, (x) => this.convertUserParamFromDB(x));
  }

  getUserParamsByUserId(userId: string): Observable<Envelope<UserParameter[]>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/${userId}/all`;
    return this.apiService.get<any, UserParameter[]>(url, (x) =>
      x.map((y) => this.convertUserParamFromDB(y)),
    );
  }

  getUserIdsByFilter(
    userIds: string[],
    userGroupIds: string[],
    skillIds: string[],
  ): Observable<Envelope<string[]>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/userIdByFilter`;
    return this.apiService.post(url, {
      userIds: userIds,
      userGroupIds: userGroupIds,
      skillIds: skillIds,
    });
  }

  setUserParam(
    userId: string,
    userParamName: string,
    paramValue: string,
  ): Observable<Envelope<UserParameter>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/${userId}/${userParamName}`;
    return this.apiService.post(url, paramValue, (x) =>
      this.convertUserParamFromDB(x),
    );
  }

  getUserParams(
    userIds: string[],
    userParamName: string,
  ): Observable<Envelope<UserParameter[]>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/for-all/${userIds}/${userParamName}`;
    return this.apiService.get<any, UserParameter[]>(url, (x) =>
      x.map((y) => this.convertUserParamFromDB(y)),
    );
  }

  private convertUserParamFromDB(itemDb: any): UserParameter {
    const {user, ...userParam} = itemDb;
    return {...userParam, userId: user.id};
  }
}
