import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '../i18n';

@Pipe({
  name: 'backupRestoreIcon',
  pure: true,
})
export class BackupRestoreIconPipe implements PipeTransform {
  translation = translation;

  constructor(private translocoService: TranslocoService) {}

  transform(value: string): string {
    if (value.toLowerCase().includes('new')) {
      return `<i style="height: 24px" class="pi pi-plus" title="${this.translocoService.translate(
        translation.listingLib.components.backupRestore.NEW,
      )}"></i>`;
    } else if (value.toLowerCase().includes('change')) {
      return `<i style="height: 24px" class="pi pi-pencil" title="${this.translocoService.translate(
        translation.listingLib.components.backupRestore.CHANGE,
      )}"></i>`;
    } else {
      return `<i style="height: 24px" class="pi pi-trash" title="${this.translocoService.translate(
        translation.listingLib.components.backupRestore.REMOVE,
      )}"></i>`;
    }
  }
}
