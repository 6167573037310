import {SelectItem} from 'primeng/api/selectitem';

export enum FormatterDocumentSize {
  AUTO = 'AUTO',
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
  A4 = 'A4',
  B5 = 'B5',
  LETTER = 'LETTER',
  // size: JIS-B4;
  // size: A4 portrait;
}

export function getAllFormatterDocumentSizes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(FormatterDocumentSize).forEach((val) =>
    items.push({label: FormatterDocumentSize[val], value: val}),
  );
  return items;
}
