import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {UpsertDialogPopup} from '../actions';

export type PopupState = EntityState<any>;

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: PopupState = adapter.getInitialState({
  id: 'user',
  parentComponentName: 'user',
  saving: false,
  successSaving: true,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(
    UpsertDialogPopup,
    (state, {parentComponentName, saving, successSaving, error}) =>
      adapter.upsertOne(
        {
          id: parentComponentName,
          parentComponentName: parentComponentName,
          saving: saving,
          successSaving: successSaving,
          error: error,
        },
        state,
      ),
  ),
);
