/**
 * Definice filtru
 * - field urcuje sloupec
 * - sortType vzestupne/sestupne
 */
export interface SortModel {
  field: string;
  sortType: SortType;
}

export enum SortType {
  /**
   * sestupne
   */
  desc = 'desc',

  /**
   * vzestupne
   */
  asc = 'asc',
}
