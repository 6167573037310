<div
  #container
  (click)="onMouseclick($event)"
  [ngStyle]="style"
  [class]="styleClass"
  [ngClass]="{
    'p-dropdown p-component': true,
    'p-disabled': disabled,
    'p-dropdown-open': overlayVisible,
    'p-focus': focused,
    'p-dropdown-clearable': showClear && !disabled,
    'p-dropdown-link': filled && iconHref,
  }"
>
  <div class="p-hidden-accessible">
    <input
      #in
      [attr.id]="inputId"
      type="text"
      readonly
      (focus)="onInputFocus($event)"
      aria-haspopup="listbox"
      [attr.placeholder]="placeholder"
      aria-haspopup="listbox"
      [attr.aria-label]="ariaLabel"
      [attr.aria-expanded]="false"
      [attr.aria-labelledby]="ariaLabelledBy"
      (blur)="onInputBlur($event)"
      (keydown)="onKeydown($event, true)"
      [disabled]="disabled"
      [attr.tabindex]="tabindex"
      pAutoFocus
      [autofocus]="autofocus"
      [attr.aria-activedescendant]="overlayVisible ? labelId : null"
      role="combobox"
    />
  </div>
  <span
    [attr.id]="labelId"
    [ngClass]="{
      'p-dropdown-label p-inputtext': true,
      'p-dropdown-label-empty': label == null || label.length === 0,
      'p-dropdown-placeholder-empty': !!placeholder && !value,
    }"
    *ngIf="!editable && label != null"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
    [positionStyle]="tooltipPositionStyle"
    [tooltipStyleClass]="tooltipStyleClass"
  >
    <ng-container *ngIf="!selectedItemTemplate">{{
      label || 'empty'
    }}</ng-container>
    <ng-container
      *ngTemplateOutlet="
        selectedItemTemplate;
        context: {$implicit: selectedOption}
      "
    ></ng-container>
    <ng-container *ngIf="!!placeholder && !value">{{
      placeholder
    }}</ng-container>
  </span>
  <span
    *ngIf="!editable && label == null"
    [ngClass]="{
      'p-dropdown-label p-inputtext p-placeholder': true,
      'p-dropdown-label-empty': placeholder == null || placeholder.length === 0,
    }"
  >
    {{ placeholder || 'empty' }}
  </span>
  <input
    #editableInput
    type="text"
    [attr.maxlength]="maxlength"
    class="p-dropdown-label p-inputtext"
    *ngIf="editable"
    [disabled]="disabled"
    [attr.placeholder]="placeholder"
    aria-haspopup="listbox"
    [attr.aria-expanded]="overlayVisible"
    (input)="onEditableInputChange($event)"
    (focus)="onEditableInputFocus($event)"
    (blur)="onInputBlur($event)"
  />
  <a
    *ngIf="filled && iconHref"
    href="{{ iconHref }}"
    target="_blank"
    rel="noopener noreferrer"
    class="p-dropdown-link-icon"
  >
    <i class="tsm-icon-link-external link-icon"></i>
  </a>
  <i
    class="p-dropdown-clear-icon pi pi-times"
    (click)="clear($event)"
    *ngIf="isVisibleClearIcon"
  ></i>
  <div
    class="p-dropdown-trigger"
    role="button"
    aria-label="dropdown trigger"
    aria-haspopup="listbox"
    [attr.aria-expanded]="overlayVisible"
  >
    <span class="p-dropdown-trigger-icon" [ngClass]="dropdownIcon"></span>
  </div>
  <p-overlay
    #overlay
    [(visible)]="overlayVisible"
    [options]="overlayOptions"
    [target]="'@parent'"
    [appendTo]="appendTo"
    [autoZIndex]="autoZIndex"
    [baseZIndex]="baseZIndex"
    [showTransitionOptions]="showTransitionOptions"
    [hideTransitionOptions]="hideTransitionOptions"
    (onAnimationStart)="onOverlayAnimationStart($event)"
    (onHide)="hide()"
  >
    <ng-template pTemplate="content">
      <div
        [ngClass]="'p-dropdown-panel p-component'"
        [ngStyle]="panelStyle"
        [class]="panelStyleClass"
      >
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        <div
          class="p-dropdown-header"
          *ngIf="filter"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="filterTemplate; else builtInFilterElement">
            <ng-container
              *ngTemplateOutlet="
                filterTemplate;
                context: {options: filterOptions}
              "
            ></ng-container>
          </ng-container>
          <ng-template #builtInFilterElement>
            <div class="p-dropdown-filter-container">
              <input
                #filter
                type="text"
                autocomplete="off"
                [value]="filterValue || ''"
                class="p-dropdown-filter p-inputtext p-component"
                [attr.placeholder]="filterPlaceholder"
                (keydown.enter)="$event.preventDefault()"
                (keydown)="onKeydown($event, false)"
                (input)="onFilterInputChange($event)"
                [attr.aria-label]="ariaFilterLabel"
                [attr.aria-activedescendant]="
                  overlayVisible ? 'p-highlighted-option' : labelId
                "
              />
              <!--              custom code - icon-->
              <span class="p-dropdown-filter-icon tsm-icon-funnel"></span>
            </div>
          </ng-template>
        </div>
        <div
          class="p-dropdown-items-wrapper"
          [style.max-height]="virtualScroll ? 'auto' : scrollHeight || 'auto'"
        >
          <p-scroller
            *ngIf="virtualScroll"
            #scroller
            [items]="optionsToDisplay"
            [style]="{height: scrollHeight}"
            [itemSize]="virtualScrollItemSize || _itemSize"
            [autoSize]="true"
            [lazy]="lazy"
            (onLazyLoad)="onLazyLoad.emit($event)"
            [options]="virtualScrollOptions"
          >
            <ng-template
              pTemplate="content"
              let-items
              let-scrollerOptions="options"
            >
              <ng-container
                *ngTemplateOutlet="
                  buildInItems;
                  context: {$implicit: items, options: scrollerOptions}
                "
              ></ng-container>
            </ng-template>
            <ng-container *ngIf="loaderTemplate">
              <ng-template pTemplate="loader" let-scrollerOptions="options">
                <ng-container
                  *ngTemplateOutlet="
                    loaderTemplate;
                    context: {options: scrollerOptions}
                  "
                ></ng-container>
              </ng-template>
            </ng-container>
          </p-scroller>
          <ng-container *ngIf="!virtualScroll">
            <ng-container
              *ngTemplateOutlet="
                buildInItems;
                context: {$implicit: optionsToDisplay, options: {}}
              "
            ></ng-container>
          </ng-container>

          <ng-template #buildInItems let-items let-scrollerOptions="options">
            <ul
              #items
              [attr.id]="listId"
              class="p-dropdown-items"
              [ngClass]="scrollerOptions.contentStyleClass"
              [style]="scrollerOptions.contentStyle"
              role="listbox"
            >
              <ng-container *ngIf="group">
                <ng-template ngFor let-optgroup [ngForOf]="items">
                  <li
                    class="p-dropdown-item-group"
                    [ngStyle]="{height: scrollerOptions.itemSize + 'px'}"
                  >
                    <span *ngIf="!groupTemplate">{{
                      getOptionGroupLabel(optgroup) || 'empty'
                    }}</span>
                    <ng-container
                      *ngTemplateOutlet="
                        groupTemplate;
                        context: {$implicit: optgroup}
                      "
                    ></ng-container>
                  </li>
                  <ng-container
                    *ngTemplateOutlet="
                      itemslist;
                      context: {
                        $implicit: getOptionGroupChildren(optgroup),
                        selectedOption: selectedOption,
                      }
                    "
                  ></ng-container>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="!group">
                <ng-container
                  *ngTemplateOutlet="
                    itemslist;
                    context: {$implicit: items, selectedOption: selectedOption}
                  "
                ></ng-container>
              </ng-container>
              <ng-template
                #itemslist
                let-options
                let-selectedOption="selectedOption"
              >
                <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                  <p-dropdownItem
                    [class]="dropdownItemStyleClass"
                    [option]="option"
                    [selected]="selectedOption == option"
                    [label]="getOptionLabel(option)"
                    [disabled]="isOptionDisabled(option)"
                    (onClick)="onItemClick($event)"
                    [template]="itemTemplate"
                  ></p-dropdownItem>
                </ng-template>
              </ng-template>
              <li
                *ngIf="filterValue && isEmpty()"
                class="p-dropdown-empty-message"
                [ngStyle]="{height: scrollerOptions.itemSize + 'px'}"
              >
                <ng-container
                  *ngIf="
                    !emptyFilterTemplate && !emptyTemplate;
                    else emptyFilter
                  "
                >
                  {{ emptyFilterMessageLabel }}
                </ng-container>
                <ng-container
                  #emptyFilter
                  *ngTemplateOutlet="emptyFilterTemplate || emptyTemplate"
                ></ng-container>
              </li>
              <li
                *ngIf="!filterValue && isEmpty()"
                class="p-dropdown-empty-message"
                [ngStyle]="{height: scrollerOptions.itemSize + 'px'}"
              >
                <ng-container *ngIf="!emptyTemplate; else empty">
                  {{ emptyMessageLabel }}
                </ng-container>
                <ng-container
                  #empty
                  *ngTemplateOutlet="emptyTemplate"
                ></ng-container>
              </li>
            </ul>
          </ng-template>
        </div>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </div>
    </ng-template>
  </p-overlay>
</div>
