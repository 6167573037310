import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgZone,
  Optional,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {AutoSizeTooltipDirectiveModule} from '@tsm/framework/root/directives';
import {InputGroupModule} from 'primeng/inputgroup';
import {InputGroupAddonModule} from 'primeng/inputgroupaddon';
import {TranslocoPipe} from '@jsverse/transloco';

@Component({
  selector: 'dtl-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'], // NOTE: Styles from different component
  host: {
    class: 'input-group-component',
  },
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputTextComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputTextComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputTextComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    AutoSizeTooltipDirectiveModule,
    NgTemplateOutlet,
    InputGroupModule,
    InputGroupAddonModule,
    TranslocoPipe,
  ],
})
export class FormInputTextComponent extends ParentWidgetAccessorComponent {
  @Input() noneText = 'none';

  @Input() color = '';

  @Input() style: any;

  @Input() inputId: string;

  @Input() overflowValueHidden = false;

  @Input() leftIcon: string;

  @Input() rightIcon: string;

  private _inputElement: ElementRef;
  private _setAutofocus = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
    private ngZone: NgZone,
  ) {
    super(cdr, layoutIdDirective);
  }

  get inputElement() {
    return this._inputElement;
  }

  @ViewChild('inputElement') set inputElement(inputElement: ElementRef) {
    this._inputElement = inputElement;
    if (this.inputElement) {
      // add focus listeners
      this.inputElement.nativeElement.addEventListener('focus', () => {
        this.onFocus(true);
      });

      this.inputElement.nativeElement.addEventListener('blur', () => {
        this.onFocus(false);
      });

      // handle autofocus
      if (this._setAutofocus) {
        this.autofocus();
      }
    }
  }

  @Input()
  set setAutofocus(value: boolean) {
    this._setAutofocus = value;
    if (value) {
      this.autofocus();
    }
  }

  getVal(val: string) {
    return val;
  }

  private autofocus() {
    if (this.inputElement) {
      this.ngZone.runOutsideAngular(() => {
        this.inputElement.nativeElement.focus();
        this.focused = true;
      });
    }
  }
}
