export const en = {
  scriptingPlugins: {
    headerDescription: 'Header docs',
    pipes: {
      scriptCodeLoader: 'Script Code Loader',
      scriptCodeLoaderDescription:
        'Retrieves script data using a given code. Fetches the script details from the store, ' +
        'allowing access to specific properties through a field parameter. Ensures data is loaded ' +
        'if not available in the store.',
      scriptLoader: 'Script Loader',
      scriptLoaderDescription:
        'Loads script details by a given ID. Retrieves the script data from the store and allows ' +
        'field-based access to specific attributes. Ensures data is fetched if not already present.',
    },
  },
};
