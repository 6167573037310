import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {TsmModule} from '@tsm/config-form/service'; // nevypada ze by melo vliv nebo vadilo
import {Config, ConfigService} from '@tsm/framework/config';
import {map} from 'rxjs/operators';
import {ApiService} from '@tsm/framework/http';
import {of} from 'rxjs';
import {
  controlsDetailConfiguration,
  controlsNewConfiguration,
  controlsNewWizardConfiguration,
  controlsRelatedEntityDetailRefDataConfiguration,
  controlsRelatedEntityTypeDetailConfiguration,
  controlsTemplateNewWizardConfiguration,
  controlsTypeDetailConfiguration,
} from './tsm-controls-configuration';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/tickets/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const ticketsPlugin: Plugin = {
  menus: [
    {
      key: 'tickets',
      priority: 1400,
      label: 'menu.ticket.ticketing',
      icon: 'tsm-icon-ticketing',
      privilege: [
        'Tick.Ticket.ViewList',
        'Tick.TicketTemplate.ViewList',
        'Tick.Registers.Channel.ViewList',
        'Tick.Registers.Category.ViewList',
        'Tick.Registers.Priority.ViewList',
        'Tick.Registers.Problem.ViewList',
        'Tick.Registers.Resolution.ViewList',
        'Tick.Registers.Status.ViewList',
        'Tick.Registers.Type.ViewList',
        'Tick.Registers.Advice.ViewList',
        'Tick.Registers.LinkType.ViewList',
        'Tick.Registers.TicketSeverity.ViewList',
        'Tick.Registers.RelatedPartyRole.ViewList',
        'Tick.Registers.RelatedPartyType.ViewList',
        'Tick.Registers.RelatedEntityType.ViewList',
      ],
      items: [
        {
          key: 'tsm-ticket-list',
          label: 'menu.ticket.ticket',
          icon: 'tsm-icon-ticket',
          routerLink: ['/tickets/ticket'],
          listingType: 'tsm-ticket-list',
          privilege: ['Tick.Ticket.ViewList'],
        },
        {
          key: 'configuration',
          label: 'menu.ticket.configuration',
          icon: 'tsm-icon-configuration',
          privilege: [
            'Tick.Registers.Channel.ViewList',
            'Tick.Registers.Category.ViewList',
            'Tick.Registers.Priority.ViewList',
            'Tick.Registers.Problem.ViewList',
            'Tick.Registers.Resolution.ViewList',
            'Tick.Registers.Status.ViewList',
            'Tick.Registers.Type.ViewList',
            'Tick.Registers.Advice.ViewList',
            'Tick.Registers.LinkType.ViewList',
            'Tick.Registers.TicketSeverity.ViewList',
            'Tick.Registers.RelatedPartyRole.ViewList',
            'Tick.Registers.RelatedPartyType.ViewList',
            'Tick.Registers.RelatedEntityType.ViewList',
            'Tick.TicketTemplate.ViewList',
          ],
          items: [
            {
              key: 'tsm-ticket-type-list',
              label: 'menu.ticket.types',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/type/Ticketing'],
              privilege: ['Tick.Registers.Type.ViewList'],
            },
            {
              key: 'tsm-link-type-list',
              label: 'menu.ticket.ticketLink',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/link-type'],
              privilege: ['Tick.Registers.LinkType.ViewList'],
            },

            {
              key: 'tsm-ticket-status-list',
              label: 'menu.ticket.statuses',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/status'],
              privilege: ['Tick.Registers.Status.ViewList'],
            },
            {
              key: 'tsm-ticket-category-list',
              label: 'menu.ticket.category',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/category'],
              privilege: ['Tick.Registers.Category.ViewList'],
            },
            {
              key: 'CHANNEL',
              label: 'menu.ticket.channels',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/registers/CHANNEL'],
              privilege: ['Tick.Registers.Channel.ViewList'],
            },
            {
              key: 'tsm-ticket-priority-list',
              label: 'menu.ticket.priority',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/priority'],
              privilege: ['Tick.Registers.Priority.ViewList'],
            },
            {
              key: 'tsm-ticket-severity-list',
              label: 'menu.ticket.severity',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/severity'],
              privilege: ['Tick.Registers.TicketSeverity.ViewList'],
            },
            {
              key: 'PROBLEM',
              label: 'menu.ticket.problems',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/problem'],
              privilege: ['Tick.Registers.Problem.ViewList'],
            },
            {
              key: 'RESOLUTION',
              label: 'menu.ticket.resolution',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/registers/RESOLUTION'],
              privilege: ['Tick.Registers.Resolution.ViewList'],
            },
            {
              key: 'tsm-advice-type-list',
              label: 'menu.ticket.adviceTypes',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/advice-type'],
              privilege: ['Tick.Registers.Advice.ViewList'],
            },
            {
              key: 'tsm-related-entity-type',
              label: 'menu.ticket.relatedEntityType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/related-entity-type'],
              privilege: ['Tick.Registers.RelatedEntityType.ViewList'],
            },
            {
              key: 'tsm-related-party-role',
              label: 'menu.ticket.relatedPartyRole',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/related-party-role'],
              privilege: ['Tick.Registers.RelatedPartyRole.ViewList'],
            },
            {
              key: 'tsm-related-party-type',
              label: 'menu.ticket.relatedPartyType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/tickets/related-party-type'],
              privilege: ['Tick.Registers.RelatedPartyType.ViewList'],
            },
            {
              key: 'tsm-ticket-template',
              label: 'menu.ticket.ticketTemplate',
              icon: 'tsm-icon-ticket',
              routerLink: ['/tickets/ticket-template'],
              listingType: 'tsm-ticket-list',
              privilege: ['Tick.TicketTemplate.ViewList'],
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'tickets',
      loadChildren: () =>
        import('@tsm/tickets/components').then(
          (x) => x.TicketsComponentsModule,
        ),
    },
    {
      // hack pro cetin, protoze znasilnuje ciselniky z ticketingu
      path: 'change-management',
      loadChildren: () =>
        import('@tsm/tickets/components').then(
          (x) => x.TicketsComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/tickets/widgets').then((x) => x.TicketsWidgetsModule),
      definitions: [
        {selector: 'tsm-ticket-relationship-list'},
        {selector: 'tsm-ticket-list-poznamka-column'},
        {selector: 'tsm-relationship-tab'},
        {selector: 'tsm-relationship-columns'},
        {selector: 'tsm-advice-column'},
        {selector: 'tsm-ticket-entity-instance-configuration-detail-widget'},
        {selector: 'tsm-ticket-service-char'},
        {selector: 'tsm-ticket-related-tasks-widget'},
        {selector: 'tsm-ticket-new-container'},
        {selector: 'tsm-ticket-task-detail'},

        {
          selector: 'tsm-ticket-link-type-lov',
          privilege: ['true'],
          designers: [
            {
              name: 'Typ vazby ticketů',
              icon: 'p-icon-assignment',
              description: 'Lovbox typů vazeb ticketů',
              editorSelector: 'tsm-ticket-link-type-lov-editor',
              formField: 'linkType',
              value: {
                title: 'Typ vazby ticketů',
                widget: {
                  type: 'tsm-ticket-link-type-lov',
                  readonly: false,
                },
                type: 'string',
              },
              categoryId: DesignerCategoryEnum.Ticketing,
              widgetOrLayout: 'widget',
            },
          ],
        },

        {
          selector: 'tsm-ticket-relationship-list',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ticket-relationship-list-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketRelationshipList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketRelationshipList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.ticketRelationshipList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-relationship-list',
                },
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },

        {
          selector: 'tsm-advice-tab',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-advice-tab-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.adviceList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.adviceList.widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.adviceList.inWidgetTitle,
                widget: {
                  type: 'tsm-advice-tab',
                },
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-related-entity-tab',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-related-entity-tab-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.relatedEntityList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.relatedEntityList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title: translationShared.shared.relatedEntityList,
                widget: {
                  type: 'tsm-related-entity-tab',
                },
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-task-list',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ticket-task-list-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketTaskList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketTaskList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.ticketTaskList.inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-task-list',
                },
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ticket-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketList.widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.ticketList.inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-list-related-entity-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ticket-list-related-entity-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketRelatedEntityList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketRelatedEntityList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.ticketRelatedEntityList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-list-related-entity-widget',
                },
                type: 'layout',
                config: {},
              },
            },
          ],
        },

        {
          selector: 'tsm-ticket-history',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ticket-history-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketAuditList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketAuditList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.history,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.ticketAuditList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-history',
                },
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-relationship-tab',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-relationship-tab-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.relationshipBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.relationshipBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 8,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-relationship-tab',
                },
                items: [],
              },
            },
          ],
        },

        {selector: 'tsm-ticket-card'},
        {
          selector: 'tsm-ticket-category-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          privilege: ['!Tick.Widget.tsm-ticket-category-lov'],
          designers: [
            {
              editorSelector: 'tsm-ticket-category-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'ticketCategory',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketCategorySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketCategorySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketCategorySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-category-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-advice-type-lov',
          privilege: ['!Tick.Widget.tsm-advice-type-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-advice-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'adviceType',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.adviceTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.adviceTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 10,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.adviceTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-advice-type-lov',
                },
                type: 'object',
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-problem-lov',
          privilege: ['!Tick.Widget.tsm-ticket-problem-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-problem-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'ticketProblem',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketProblemSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketProblemSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 11,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketProblemSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-problem-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-problem-tree-lov',
          privilege: ['!Tick.Widget.tsm-ticket-problem-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-problem-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'ticketProblem',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketProblemTreeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketProblemTreeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 12,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketProblemTreeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-problem-tree-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-resolution-lov',
          privilege: ['!Tick.Widget.tsm-ticket-resolution-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-resolution-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'ticketResolution',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketResolutionSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketResolutionSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 13,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketResolutionSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-resolution-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-lov',
          privilege: ['!Tick.Widget.tsm-ticket-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'ticket',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 14,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-lov',
                },
                type: 'string',
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-status-lov',
          privilege: ['!Tick.Widget.tsm-ticket-status-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-status-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'status',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketStatusSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketStatusSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 15,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketStatusSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-status-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-related-party-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-related-party-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'relatedParty',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketRelatedPartySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketRelatedPartySelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 16,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketRelatedPartySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-related-party-lov',
                },
                type: 'string',
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-related-party-role-lov',
          privilege: ['!Tick.Widget.tsm-ticket-related-party-role-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-related-party-role-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'role',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets
                .ticketRelatedPartyRoleSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketRelatedPartyRoleSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 17,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketRelatedPartyRoleSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-related-party-role-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-priority-lov',
          privilege: ['!Tick.Widget.tsm-ticket-priority-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-priority-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'priority',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketPrioritySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketPrioritySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 18,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketPrioritySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-priority-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-severity-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-severity-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'severity',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketSeveritySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketSeveritySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 19,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketSeveritySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-severity-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-related-entity-lov',
          privilege: ['!Tick.Widget.tsm-ticket-related-entity-of-ticket-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-related-entity-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'relatedEntity',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketRelatedEntitySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketRelatedEntitySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 20,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketRelatedEntitySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-related-entity-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'type',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 21,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-type-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-template-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-template-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'type',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketTemplateTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketTemplateTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 22,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketTemplateTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-template-type-lov',
                },
                type: 'string',
                config: {
                  module: 'ChangeManagement',
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-category-registry-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-category-registry-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'type',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              name: translationShared.shared.widgets
                .ticketCategoryRegistrySelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketCategoryRegistrySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 23,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketCategoryRegistrySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-category-registry-lov',
                },
                type: 'string',
                config: {
                  module: 'ChangeManagement',
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-related-entity-type-lov',
          privilege: ['!Tick.Widget.tsm-ticket-related-entity-type-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-related-entity-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'relatedEntityType',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets
                .ticketRelatedEntityTypeSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketRelatedEntityTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 24,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketRelatedEntityTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-related-entity-type-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-channel-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-ticket-channel-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'channel',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.ticketChannelSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ticketChannelSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 25,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.ticketChannelSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-ticket-channel-lov',
                },
                type: 'string',
                config: {
                  dataKey: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-category-tree-lov',
          privilege: ['true'],
          designers: [
            {
              name: 'kategorie ticketů - strom',
              icon: DesignerIconsEnum.select,
              editorSelector: 'tsm-ticket-category-tree-lov-editor',
              description: 'kategorie ticketů - stromečkové zobrazení',
              formField: 'category',
              order: 26,
              value: {
                title: 'kategorie ticketů - strom',
                widget: {
                  type: 'tsm-ticket-category-tree-lov',
                  readonly: false,
                },
                type: 'object',
              },
              categoryId: DesignerCategoryEnum.Ticketing,
              widgetOrLayout: 'widget',
            },
          ],
        },

        {
          selector: 'tsm-ticket-related-entity-name-icon-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector:
                'tsm-ticket-related-entity-name-icon-widget-editor',
              widgetOrLayout: 'widget',
              name: translationShared.shared.nameRelatedEntityIcon,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              categoryId: DesignerCategoryEnum.Ticketing,
              description: translationShared.shared.nameRelatedEntityIcon,
              icon: 'tsm-icon-registers',
              formField: 'type',
              group: GroupPosition.Secondary,
              order: 27,
              value: {
                title: translationShared.shared.nameRelatedEntityIcon,
                widget: {
                  type: 'tsm-ticket-related-entity-name-icon-widget',
                },
                type: 'string',
                config: {
                  module: 'ChangeManagement',
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-advice-active-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-advice-active-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ticketing,
              name: translationShared.shared.widgets.adviceActivePanel
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.adviceActivePanel
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 28,
              icon: DesignerIconsEnum.panel,
              value: {
                widget: {
                  type: 'tsm-advice-active-widget',
                },
                config: {
                  ticketId: null,
                },
                type: 'layout',
              },
            },
          ],
        },
        {
          selector: 'tsm-related-entity-detail-refdata',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-related-entity-detail-refdata-editor',
              widgetOrLayout: 'layout',
              name: translationShared.shared
                .descriptionRelatedEntityDetailRefData,
              categoryId: DesignerCategoryEnum.Ticketing,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.descriptionRelatedEntityDetailRefData,
              icon: 'p-icon-assignment',
              group: GroupPosition.Primary,
              order: 29,
              value: {
                title:
                  translationShared.shared
                    .descriptionRelatedEntityDetailRefData,
                widget: {
                  type: 'tsm-related-entity-detail-refdata',
                },
                type: 'layout',
              },
            },
          ],
        },
        {
          selector: 'tsm-ticket-release-button',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ticket-release-button-editor',
              widgetOrLayout: 'widget',
              name: translationShared.shared.nameReleaseButton,
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description:
                translationShared.shared.descriptionTicketReleaseButton,
              icon: 'tsm-icon-change-request',
              formField: 'type',
              order: 30,
              value: {
                title: translationShared.shared.descriptionTicketReleaseButton,
                widget: {
                  type: 'tsm-ticket-release-button',
                },
                type: 'string',
                config: {
                  module: 'ChangeManagement',
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/tickets/widgets').then((x) => x.TicketsWidgetsModule),
      definitions: [
        {
          selector: 'tsm-ticket-category-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketCategoryFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketCategoryFilterDescription,
        },
        {
          selector: 'tsm-ticket-channel-filter',
          name: translationPlugin.ticketsPlugins.filters.tsmTicketChannelFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketChannelFilterDescription,
        },
        {
          selector: 'tsm-ticket-link-type-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketLinkTypeFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketLinkTypeFilterDescription,
        },
        {
          selector: 'tsm-ticket-priority-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketPriorityFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketPriorityFilterDescription,
        },
        {
          selector: 'tsm-ticket-problem-tree-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketProblemTreeFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketProblemTreeFilterDescription,
        },
        {
          selector: 'tsm-ticket-related-party-role-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketRelatedPartyRoleFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketRelatedPartyRoleFilterDescription,
        },
        {
          selector: 'tsm-ticket-related-party-type-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketRelatedPartyTypeFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketRelatedPartyTypeFilterDescription,
        },
        {
          selector: 'tsm-ticket-severity-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketSeverityFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketSeverityFilterDescription,
        },
        {
          selector: 'tsm-ticket-status-filter',
          name: translationPlugin.ticketsPlugins.filters.tsmTicketStatusFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketStatusFilterDescription,
        },
        {
          selector: 'tsm-ticket-type-code-filter',
          name: translationPlugin.ticketsPlugins.filters
            .tsmTicketTypeCodeFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketTypeCodeFilterDescription,
        },
        {
          selector: 'tsm-ticket-type-filter',
          name: translationPlugin.ticketsPlugins.filters.tsmTicketTypeFilter,
          description:
            translationPlugin.ticketsPlugins.filters
              .tsmTicketTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/tickets/widgets').then((x) => x.TicketsWidgetsModule),
      definitions: [
        {
          selector: 'adviceIcon',
          name: translationPlugin.ticketsPlugins.pipes.adviceIcon,
          description:
            translationPlugin.ticketsPlugins.pipes.adviceIconDescription,
        },
        {
          selector: 'channelByCode',
          name: translationPlugin.ticketsPlugins.pipes.channelByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.channelByCodeDescription,
        },
        {
          selector: 'channelById',
          name: translationPlugin.ticketsPlugins.pipes.channelById,
          description:
            translationPlugin.ticketsPlugins.pipes.channelByIdDescription,
        },
        {
          selector: 'jsonActiveTasks',
          name: translationPlugin.ticketsPlugins.pipes.jsonActiveTasks,
          description:
            translationPlugin.ticketsPlugins.pipes.jsonActiveTasksDescription,
        },
        {
          selector: 'localization',
          name: translationPlugin.ticketsPlugins.pipes.localization,
          description:
            translationPlugin.ticketsPlugins.pipes.localizationDescription,
        },
        {
          selector: 'priorityByCode',
          name: translationPlugin.ticketsPlugins.pipes.priorityByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.priorityByCodeDescription,
        },
        {
          selector: 'priorityById',
          name: translationPlugin.ticketsPlugins.pipes.priorityById,
          description:
            translationPlugin.ticketsPlugins.pipes.priorityByIdDescription,
        },
        {
          selector: 'problemTypeByCode',
          name: translationPlugin.ticketsPlugins.pipes.problemTypeByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.problemTypeByCodeDescription,
        },
        {
          selector: 'problemTypeLoader',
          name: translationPlugin.ticketsPlugins.pipes.problemTypeLoader,
          description:
            translationPlugin.ticketsPlugins.pipes.problemTypeLoaderDescription,
        },
        {
          selector: 'relatedEntityByIdNameIcon',
          name: translationPlugin.ticketsPlugins.pipes
            .relatedEntityByIdNameIcon,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityByIdNameIconDescription,
        },
        {
          selector: 'relatedEntityById',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityById,
          description:
            translationPlugin.ticketsPlugins.pipes.relatedEntityByIdDescription,
        },
        {
          selector: 'relatedEntityNameIcon',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityNameIcon,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityNameIconDescription,
        },
        {
          selector: 'relatedEntityOfTicket',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityOfTicket,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityOfTicketDescription,
        },
        {
          selector: 'relatedEntityParentName',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityParentName,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityParentNameDescription,
        },
        {
          selector: 'relatedEntityShortName',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityShortName,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityShortNameDescription,
        },
        {
          selector: 'relatedEntityTypeByCode',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityTypeByCode,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityTypeByCodeDescription,
        },
        {
          selector: 'relatedEntityTypeIcon',
          name: translationPlugin.ticketsPlugins.pipes.relatedEntityTypeIcon,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedEntityTypeIconDescription,
        },
        {
          selector: 'relatedPartyOwner',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyOwner,
          description:
            translationPlugin.ticketsPlugins.pipes.relatedPartyOwnerDescription,
        },
        {
          selector: 'relatedPartyParticipants',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyParticipants,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedPartyParticipantsDescription,
        },
        {
          selector: 'relatedPartyRef',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyRef,
          description:
            translationPlugin.ticketsPlugins.pipes.relatedPartyRefDescription,
        },
        {
          selector: 'relatedPartyRoleByCodePipe',
          name: translationPlugin.ticketsPlugins.pipes
            .relatedPartyRoleByCodePipe,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedPartyRoleByCodePipeDescription,
        },
        {
          selector: 'relatedPartyRoleByIdPipe',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyRoleByIdPipe,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedPartyRoleByIdPipeDescription,
        },
        {
          selector: 'relatedPartyTypeCode',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyTypeCode,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedPartyTypeCodeDescription,
        },
        {
          selector: 'relatedPartyTypeId',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyTypeId,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedPartyTypeIdDescription,
        },
        {
          selector: 'relatedPartyWithRef',
          name: translationPlugin.ticketsPlugins.pipes.relatedPartyWithRef,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relatedPartyWithRefDescription,
        },
        {
          selector: 'relatedParty',
          name: translationPlugin.ticketsPlugins.pipes.relatedParty,
          description:
            translationPlugin.ticketsPlugins.pipes.relatedPartyDescription,
        },
        {
          selector: 'relationshipDirection',
          name: translationPlugin.ticketsPlugins.pipes.relationshipDirection,
          description:
            translationPlugin.ticketsPlugins.pipes
              .relationshipDirectionDescription,
        },
        {
          selector: 'resolutionTypeByCode',
          name: translationPlugin.ticketsPlugins.pipes.resolutionTypeByCode,
          description:
            translationPlugin.ticketsPlugins.pipes
              .resolutionTypeByCodeDescription,
        },
        {
          selector: 'resolutionTypeLoader',
          name: translationPlugin.ticketsPlugins.pipes.resolutionTypeLoader,
          description:
            translationPlugin.ticketsPlugins.pipes
              .resolutionTypeLoaderDescription,
        },
        {
          selector: 'ropAddress',
          name: translationPlugin.ticketsPlugins.pipes.ropAddress,
          description:
            translationPlugin.ticketsPlugins.pipes.ropAddressDescription,
        },
        {
          selector: 'severityByCode',
          name: translationPlugin.ticketsPlugins.pipes.severityByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.severityByCodeDescription,
        },
        {
          selector: 'severityIcon',
          name: translationPlugin.ticketsPlugins.pipes.severityIcon,
          description:
            translationPlugin.ticketsPlugins.pipes.severityIconDescription,
        },
        {
          selector: 'statusByCode',
          name: translationPlugin.ticketsPlugins.pipes.statusByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.statusByCodeDescription,
        },
        {
          selector: 'statusIconByCode',
          name: translationPlugin.ticketsPlugins.pipes.statusIconByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.statusIconByCodeDescription,
        },
        {
          selector: 'statusIcon',
          name: translationPlugin.ticketsPlugins.pipes.statusIcon,
          description:
            translationPlugin.ticketsPlugins.pipes.statusIconDescription,
        },
        {
          selector: 'tabs',
          name: translationPlugin.ticketsPlugins.pipes.tabs,
          description: translationPlugin.ticketsPlugins.pipes.tabsDescription,
        },
        {
          selector: 'ticketCategoryCode',
          name: translationPlugin.ticketsPlugins.pipes.ticketCategoryCode,
          description:
            translationPlugin.ticketsPlugins.pipes
              .ticketCategoryCodeDescription,
        },
        {
          selector: 'ticketCategory',
          name: translationPlugin.ticketsPlugins.pipes.ticketCategory,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketCategoryDescription,
        },
        {
          selector: 'ticketId',
          name: translationPlugin.ticketsPlugins.pipes.ticketId,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketIdDescription,
        },
        {
          selector: 'ticketKey',
          name: translationPlugin.ticketsPlugins.pipes.ticketKey,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketKeyDescription,
        },
        {
          selector: 'ticketLink',
          name: translationPlugin.ticketsPlugins.pipes.ticketLink,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketLinkDescription,
        },
        {
          selector: 'ticketRefIdPipe',
          name: translationPlugin.ticketsPlugins.pipes.ticketRefIdPipe,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketRefIdPipeDescription,
        },
        {
          selector: 'ticketStatusId',
          name: translationPlugin.ticketsPlugins.pipes.ticketStatusId,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketStatusIdDescription,
        },
        {
          selector: 'ticketTaskLink',
          name: translationPlugin.ticketsPlugins.pipes.ticketTaskLink,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketTaskLinkDescription,
        },
        {
          selector: 'ticketTypeByCode',
          name: translationPlugin.ticketsPlugins.pipes.ticketTypeByCode,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketTypeByCodeDescription,
        },
        {
          selector: 'ticketTypeById',
          name: translationPlugin.ticketsPlugins.pipes.ticketTypeById,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketTypeByIdDescription,
        },
        {
          selector: 'ticketTypeSla',
          name: translationPlugin.ticketsPlugins.pipes.ticketTypeSla,
          description:
            translationPlugin.ticketsPlugins.pipes.ticketTypeSlaDescription,
        },
        {
          selector: 'typeIcon',
          name: translationPlugin.ticketsPlugins.pipes.typeIcon,
          description:
            translationPlugin.ticketsPlugins.pipes.typeIconDescription,
        },
        {
          selector: 'userUserGroupRef',
          name: translationPlugin.ticketsPlugins.pipes.userUserGroupRef,
          description:
            translationPlugin.ticketsPlugins.pipes.userUserGroupRefDescription,
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'Ticket',
      url: (
        entityId,
        apiService: ApiService,
        configService: ConfigService<Config>,
      ) => {
        return apiService
          .get<
            TsmModule,
            TsmModule
          >(configService.value.apiUrls.ticket + '/ticket/' + entityId + '/module')
          .pipe(
            map((x) => {
              if (x.success) {
                if (x.data.code === 'Ticketing') {
                  return '/tickets/ticket/' + entityId;
                } else if (x.data.code === 'ChangeManagement') {
                  return '/change-management/change-request/' + entityId;
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }),
          );
      },
      priority: 2,
    },
    {
      entityType: 'Ticket',
      url: (entityId) => {
        return of('/tickets/ticket/' + entityId);
      },
      priority: 1,
    },
    {
      entityType: 'TicketCategory',
      url: (entityId) => {
        return of('/tickets/category');
      },
    },
    {
      entityType: 'TicketSeverity',
      url: (entityId) => {
        return of('/tickets/severity');
      },
    },
    {
      entityType: 'LinkType',
      url: (entityId) => {
        return of('/tickets/link-type');
      },
    },
    {
      entityType: 'TicketStatus',
      url: (entityId) => {
        return of('/tickets/status');
      },
    },
    {
      entityType: 'TicketProblem',
      url: (entityId) => {
        return of('/tickets/problem');
      },
    },
    {
      entityType: 'TicketType',
      url: (entityId) => {
        return of('/tickets/type/Ticketing/' + entityId);
      },
    },
    {
      entityType: 'TicketPriority',
      url: (entityId) => {
        return of('/tickets/priority');
      },
    },
    {
      entityType: 'TicketResolution',
      url: (entityId) => {
        return of('/tickets/registers/RESOLUTION');
      },
    },
    {
      entityType: 'AdviceType',
      url: (entityId) => {
        return of('/tickets/advice-type');
      },
    },
    {
      entityType: 'TicketCategory',
      url: (entityId) => {
        return of('/tickets/category');
      },
    },
    {
      entityType: 'RelatedPartyRole',
      url: (entityId) => {
        return of('/tickets/related-party-role');
      },
    },
    {
      entityType: 'RelatedPartyType',
      url: (entityId) => {
        return of('/tickets/related-party-type');
      },
    },
    {
      entityType: 'RelatedEntityType',
      url: (entityId) => {
        return of('/tickets/related-entity-type');
      },
    },
    {
      entityType: 'ImpactedEntityType',
      url: (entityId) => {
        return of('/tickets/impacted-entity-type/' + entityId);
      },
    },
  ],
  layoutControls: [
    controlsDetailConfiguration,
    controlsNewConfiguration,
    controlsTypeDetailConfiguration,
    controlsRelatedEntityTypeDetailConfiguration,
    controlsRelatedEntityDetailRefDataConfiguration,
    controlsNewWizardConfiguration,
    controlsTemplateNewWizardConfiguration,
  ],
};
