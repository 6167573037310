import * as fromCharacteristicsReducer from './characteristics.reducer';
import * as fromCharacteristicsValuesReducer from './characteristics-values.reducer';
import {Action, combineReducers} from '@ngrx/store';

export * from './characteristics.reducer';
export * from './characteristics-values.reducer';

export interface CharacteristicsState {
  characteristics: fromCharacteristicsReducer.CharacteristicsState;
  characteristicsValues: fromCharacteristicsValuesReducer.CharacteristicsValuesState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(
  state: CharacteristicsState | undefined,
  action: Action,
) {
  return combineReducers({
    characteristics: fromCharacteristicsReducer.characteristicsReducer,
    characteristicsValues:
      fromCharacteristicsValuesReducer.characteristicsValuesReducer,
  })(state, action);
}
