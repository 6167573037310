import {SelectItem} from 'primeng/api';

export enum RoleInGroupEnum {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER',
  CALENDAR_ADMIN = 'CALENDAR_ADMIN',
}

export function getAllRolesInGroup(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(RoleInGroupEnum).forEach((val) =>
    items.push({label: RoleInGroupEnum[val], value: val}),
  );
  return items;
}
