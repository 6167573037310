import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {TsmAbstractControl} from '@tsm/framework/forms';
import {Injectable} from '@angular/core';
import {DateHelper} from './date-helper';

// @dynamic
@Injectable()
export class ValidationUtils {
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static alphabetWithoutNumericPattern = Validators.pattern(
    '^[abcdefghijklmnopqrstuvxyzABCDEFGHIJKLMNOPQRSTUVWXYZáčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚÝŽ]+$',
  );
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static phoneNumberPattern = Validators.pattern('[+]?[0-9 ]{9,15}');
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static webAddressPattern = Validators.pattern(
    '^(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*))',
  );
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static emailRegex =
    /^[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z]{2,}$/;
  private static emailTitleRegex =
    /^".{1,}"<[a-z][a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z]{2,}>$/;
  private static emailPattern = Validators.pattern(ValidationUtils.emailRegex);
  private static emailTitlePattern = Validators.pattern(
    ValidationUtils.emailTitleRegex,
  );
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static emailGroupRFCRegex =
    /^((\".*?\"\s)|([a-zA-Z0-9]([a-zA-Z]|\s)*[a-zA-Z0-9]\s?))*?<[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z]{2,}>$/;
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static floatNumberPattern = Validators.pattern(
    '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$',
  );
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static colsValueNumberPattern =
    Validators.pattern('^([1-9]|1[0-2])$');
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static goalTimePattern = Validators.pattern(
    '^([0-9]+h)$|(^(0|[1-5]|[1-5][0-9])(m|min)$)|([0-9]+h(0|[1-5]|[1-5][0-9])(m|min))$',
  );

  public static ipAddress =
    /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])$/;
  public static ipAddressMask =
    /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\/(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
  public static ipAddressCidr =
    /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\/((?:[0-9])|(?:[1-2][0-9])|(?:3[0-2]))$/;
  public static ipCidr = /^((?:[0-9])|(?:[1-2][0-9])|(?:3[0-2]))$/;
  private static ipAddressPattern = Validators.pattern(
    ValidationUtils.ipAddress,
  );
  private static ipAddressMaskPattern = Validators.pattern(
    ValidationUtils.ipAddressMask,
  );
  private static ipAddressCidrPattern = Validators.pattern(
    ValidationUtils.ipAddressCidr,
  );
  private static ipCidrPattern = Validators.pattern(ValidationUtils.ipCidr);
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private;
  static phoneNumberErrorMessage = 'shared.phoneNumberErrorMessage';
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static webAddressErrorMessage = 'shared.webAddressErrorMessage';
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static emailErrorMessage = 'shared.emailErrorMessage';
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static floatErrorMessage = 'shared.floatErrorMessage';
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static colsValueErrorMessage = 'shared.colsValueErrorMessage';
  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  private static goalTimeValueErrorMessage = 'shared.goalTimeValueErrorMessage';
  private static ipAddressMaskErrorMessage = 'shared.ipAddressMaskErrorMessage';
  private static ipAddressCidrErrorMessage = 'shared.ipAddressCidrErrorMessage';
  private static ipAddressErrorMessage = 'shared.ipAddressErrorMessage';
  private static ipCidrErrorMessage = 'shared.ipCidrErrorMessage';
  private static mustBeAboveZeroTranslation = 'shared.valueMustBeAboveZero';
  private static allowedAlphanumericErrorMessage =
    'shared.allowedAlphanumericErrorMessage';
  private static firstLetterUppercaseErrorMessage =
    'shared.firstLetterUppercaseErrorMessage';
  private static endsWithDotErrorMessage = 'shared.endsWithDotErrorMessage';
  private static startsWithDotErrorMessage = 'shared.startsWithDotErrorMessage';
  private static uppercaseAfterDotErrorMessage =
    'shared.uppercaseAfterDotErrorMessage';

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static alphabetWithoutNumericValidator(
    errorMessage: string,
  ): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.alphabetWithoutNumericPattern(control)) {
        return null;
      }
      return {customErrorCode: errorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */ public static phoneNumberValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.phoneNumberPattern(control)) {
        return null;
      }
      return {customErrorCode: this.phoneNumberErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static webAddressValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.webAddressPattern(control)) {
        return null;
      }
      return {customErrorCode: this.webAddressErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static emailValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.emailPattern(control) || !this.emailTitlePattern(control)) {
        return null;
      }
      return {customErrorCode: this.emailErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static emailGroupValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      const emailResult = this.emailPattern(control);
      if (emailResult) {
        const controlValue: string = control.value;
        const emailValues = controlValue.replace(/\".*?\"/g, '');
        let delimiter = null;
        if (emailValues.includes(';') && emailValues.includes(',')) {
          return {customErrorCode: this.emailErrorMessage};
        } else if (emailValues.includes(';')) {
          delimiter = ';';
        } else if (emailValues.includes(',')) {
          delimiter = ',';
        }
        if (
          delimiter != null &&
          (controlValue.startsWith(delimiter) ||
            controlValue.endsWith(delimiter))
        ) {
          return {customErrorCode: this.emailErrorMessage};
        }

        if (delimiter == null) {
          const valid = this.emailGroupRFCRegex.test(control.value);
          if (valid) {
            return null;
          }
        } else {
          const emailGroupResult = control.value
            .split(delimiter)
            .map(
              (x) => this.emailRegex.test(x) || this.emailGroupRFCRegex.test(x),
            );
          if (!emailGroupResult.some((x) => !x)) {
            return null;
          }
        }
      } else {
        return null;
      }

      return {customErrorCode: this.emailErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static floatNumberValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.floatNumberPattern(control)) {
        return null;
      }
      return {customErrorCode: this.floatErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static colsValueValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.colsValueNumberPattern(control)) {
        return null;
      }
      return {customErrorCode: this.colsValueErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static compareValuesValidator(
    firstControl: string,
    secondControl: string,
    errorMessage: string,
  ): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      const firstCtrl = control.get(firstControl);
      const secondCtrl = control.get(secondControl);
      if (firstCtrl.invalid || secondCtrl.invalid) {
        return null;
      }
      const first = firstCtrl.value;
      const second = secondCtrl.value;
      if (!(!first || !second || first === second)) {
        control.get(secondControl).setErrors({customErrorCode: errorMessage});
      }
      return null;
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static goalTimeValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.goalTimePattern(control)) {
        return null;
      }
      return {customErrorCode: this.goalTimeValueErrorMessage};
    };
  }

  /**
   * ...
   * Please do not USE statci in angular
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  public static compareDates(
    firstControl: string,
    secondControl: string,
  ): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      const firstCtrl = control.get(firstControl);
      const secondCtrl = control.get(secondControl);

      const first = firstCtrl.value;
      const second = secondCtrl.value;
      const changedOne = control.get(firstControl).dirty
        ? control.get(firstControl)
        : control.get(secondControl);

      if (!(!first || !second || DateHelper.isSameOrBefore(first, second))) {
        changedOne.setErrors({
          customErrorCode: 'validation.messages.compareDates',
        });
      } else {
        changedOne.setErrors(null);
      }
      return null;
    };
  }

  public static ipAddressMaskValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.ipAddressMaskPattern(control)) {
        return null;
      }
      return {customErrorCode: this.ipAddressMaskErrorMessage};
    };
  }

  public static ipAddressCidrValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.ipAddressCidrPattern(control)) {
        return null;
      }
      return {customErrorCode: this.ipAddressCidrErrorMessage};
    };
  }

  public static ipAddressValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.ipAddressPattern(control)) {
        return null;
      }
      return {customErrorCode: this.ipAddressErrorMessage};
    };
  }

  public static ipCidrValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      if (!this.ipCidrPattern(control)) {
        return null;
      }
      return {customErrorCode: this.ipCidrErrorMessage};
    };
  }

  public static onlyPositiveNumberValidator(): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value < 0) {
        return {customErrorCode: this.mustBeAboveZeroTranslation};
      }
      return null;
    };
  }

  public static dependentOnValidator(
    dependentOnControl: string,
    setErrorControl: string,
    errorMessage: string,
  ): ValidatorFn {
    return (control: TsmAbstractControl): ValidationErrors | null => {
      const firstCtrl = control.get(dependentOnControl);
      const secondCtrl = control.get(setErrorControl);

      const first = firstCtrl.value;
      if (!first && secondCtrl.value) {
        secondCtrl.setErrors({
          customErrorCode: errorMessage,
        });
        secondCtrl.markAsTouched();
      } else {
        secondCtrl.setErrors(null);
      }
      return null;
    };
  }

  public static normalizedNameToCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      // Kontrola na prázdnou hodnotu - povolíme prázdnou hodnotu, pokud je přípustné ji ignorovat
      if (!value) {
        return null;
      }

      // Regulární výraz pro povolené znaky (alfanumerické, tečka, podtržítko, pomlčka)
      const validPattern = /^[a-zA-Z0-9._\-]*$/;

      // Pokud hodnota neodpovídá povoleným znakům, vrátíme chybu
      if (!validPattern.test(value)) {
        return {customErrorCode: this.allowedAlphanumericErrorMessage};
      }

      // Poslední znak nesmí být , podtržítko, pomlčka
      if (value.endsWith('.') || value.endsWith('_') || value.endsWith('-')) {
        return {customErrorCode: this.endsWithDotErrorMessage};
      }

      // První znak nesmí být , podtržítko, pomlčka
      if (
        value.startsWith('.') ||
        value.startsWith('_') ||
        value.startsWith('-')
      ) {
        return {customErrorCode: this.startsWithDotErrorMessage};
      }

      // Pokud vše vyhovuje, vracíme null (žádná chyba)
      return null;
    };
  }
}
