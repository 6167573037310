export class CommentImagesSvgBundle {
  getAll(): string[] {
    return [
      '001-metrics',
      '002-owners',
      '003-map',
      '004-vision',
      '005-team',
      '006-bulb',
      '007-file',
      '008-portfolio',
      '009-user',
      '010-product',
      '011-programmer',
      '012-team',
      '013-owner',
      '014-team',
      '015-kanban',
      '016-backlog',
      '017-document',
      '018-pie chart',
      '019-enterprise',
      '020-scrum',
      '021-globe',
      '022-user',
      '023-system',
      '024-roadmap',
      '025-cloud computing',
      '026-document',
      '027-line graph',
      '028-folder',
      '029-portfolio',
      '030-developer',
      '031-idea',
      '032-computer',
      '033-coordination',
      '034-case study',
      '035-document',
      '036-bulb',
      '037-training',
      '038-webinar',
      '039-team work',
      '040-organization',
      '041-settings',
      '042-customer support',
      '043-analytics',
      '044-help',
      '045-presentation',
      '046-framework',
      '047-structure',
      '048-solution',
      '049-planned-outage',
      'chat',
      'email-1',
      'email-2',
      'email-3',
      'email_yellow',
      'headset',
      'inbox',
      'incoming-call',
      'incoming-call_red',
      'megaphone',
      'megaphone-1',
      'message-1',
      'message-type-b2b',
      'message-type-biga',
      'message-type-netcool',
      'microphone-1',
      'phone-book',
      'phone-call-2',
      'phone-call_green',
      'smartphone',
      'smartphone-3',
      'smartphone-4',
      'telemarketer',
      'television',
      'transfer',
      'transfer-1',
      'transmitter-1',
      'transmitter-2',
      'web',
    ];
  }
}
