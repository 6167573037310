import {Pipe, untracked} from '@angular/core';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {LoadRoleByCode} from '../actions';
import {selectRoleByCode} from '../selectors';

@Pipe({
  name: 'roleCode',
  pure: true,
})
export class RoleCodePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  setObservable(code: string, field = 'name') {
    this.destroy();
    this._id = code;

    this._obs$ = this.store.pipe(
      select(selectRoleByCode(this._id)),
      map((it) => {
        if (!it) {
          return code;
        } else {
          return field !== 'all' ? it[field] : it;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectRoleByCode(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadRoleByCode({code: this._id})),
          );
        }
      });
  }
}
