import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {filter, take, takeUntil} from 'rxjs/operators';

import {MonacoEditorLoaderService} from '../../services';
import {
  MonacoDiffEditorConstructionOptions,
  MonacoEditorMarker,
  MonacoEditorModel,
  MonacoNamespace,
  MonacoStandaloneDiffEditor,
} from '../../models';
import {Terminator} from '@tsm/framework/terminator';
import {FrameworkResizeObserverModule} from '@tsm/framework/resize-observer';
import {CommonModule} from '@angular/common';
import {ResizableModule, ResizeEvent} from 'angular-resizable-element';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {type editor} from 'monaco-editor';

@Component({
  selector: 'tsm-monaco-diff-editor',
  templateUrl: 'monaco-diff-editor.component.html',
  styleUrls: ['monaco-diff-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonacoDiffEditorComponent),
      multi: true,
    },
    Terminator,
  ],
})
export class MonacoDiffEditorComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  container: HTMLDivElement;
  editor: MonacoStandaloneDiffEditor;
  originalModel: MonacoEditorModel;
  modifiedModel: MonacoEditorModel;

  private monaco: MonacoNamespace;

  @HostBinding('class.swap')
  @Input()
  swap = false;
  @Input() updateIfDirty = false;
  @Input() language: string;
  @Input() original: string;
  @Input() modified: string;
  @Input() titleOriginal: string;
  @Input() titleModified: string;
  @Input() options: MonacoDiffEditorConstructionOptions;
  @Input() resizable = false;
  @Input() defaultHeight: string | null = null;
  @Output() init: EventEmitter<editor.IStandaloneCodeEditor> =
    new EventEmitter();
  @Output() isDirtyChange: EventEmitter<boolean> = new EventEmitter();
  @Output() markersChange: EventEmitter<MonacoEditorMarker[]> =
    new EventEmitter();
  @Output() touchedChange: EventEmitter<boolean> = new EventEmitter();
  value: string;

  @ViewChild('diffEditor', {static: true}) editorContent: ElementRef;

  @HostBinding('style.height')
  hostHeight: string;

  private onTouched: () => void;
  private propagateChange: (_: any) => any = (_: any) => {};
  private lastIncomingVersionId = 1;
  private isDirty = false;
  readonly resizeHandleHeightPx = 10;
  private pendingChanges: SimpleChanges;

  constructor(
    private monacoLoader: MonacoEditorLoaderService,
    private terminator: Terminator,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.container = this.editorContent.nativeElement;
    this.monacoLoader.isMonacoLoaded$
      .pipe(
        filter((monaco) => monaco !== null),
        take(1),
        takeUntil(this.terminator),
      )
      .subscribe((monaco) => {
        this.initMonaco(monaco);
      });

    this.hostHeight = this.defaultHeight;
  }

  ngOnChanges(changes: SimpleChanges) {
    // handle value changes after load
    if (
      this.monaco &&
      this.editor &&
      (changes.original || changes.modified || changes.language)
    ) {
      this.handleChanges();
    } else {
      this.pendingChanges = changes;
    }
    // handle options change after load
    if (this.editor && changes.options && !changes.options.firstChange) {
      this.editor.updateOptions(changes.options.currentValue);
    }

    // adjust monaco height to accommodate resize handle element
    if (changes.resizable) {
      if (this.editor) {
        this.editor.layout({
          width: this.editorContent.nativeElement.offsetWidth,
          height:
            this.editorContent.nativeElement.offsetHeight -
            (this.resizable ? this.resizeHandleHeightPx : 0),
        });
      }
    }
  }

  writeValue(value: string): void {
    // Do not reflect incoming changes of input if dirty
    if (!this.updateIfDirty && this.isDirty) {
      return;
    }
    this.value = value;
    if (this.editor) {
      // increment incoming version counter BEFORE saving the value to the editor
      // jasny, pricti +1, protoze to je to nejcitelnejsi mily debile/autore
      this.lastIncomingVersionId =
        this.editor.getModifiedEditor().getModel().getAlternativeVersionId() +
        1;
      // string spadne
      if (value != null && typeof value === 'object') {
        console.warn('Passed object should be string');
      }
      this.editor.getModifiedEditor().setValue(value ? value : '');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsDirty() {
    this.isDirty = true;
    this.isDirtyChange.emit(true);
  }

  private initMonaco(monaco: MonacoNamespace) {
    this.monaco = monaco;

    let opts: MonacoDiffEditorConstructionOptions = {
      // update angular14 theme doesn´t exist in this interface
      // theme: 'vc'
    };
    if (this.options) {
      opts = Object.assign({}, opts, this.options);
    }
    this.editor = monaco.editor.createDiffEditor(this.container, opts);

    this.originalModel = monaco.editor.createModel(this.original);
    this.modifiedModel = monaco.editor.createModel(this.modified);

    this.editor.setModel({
      original: this.originalModel,
      modified: this.modifiedModel,
    });
    this.editor.layout();
    this.init.emit(this.editor.getModifiedEditor());

    if (this.pendingChanges) {
      this.handleChanges();
    }
  }

  onObserverResized(event: ResizeObserverEntry) {
    if (this.editor) {
      this.editor.layout({
        width: event.contentRect.width,
        // take resize handle height into account, if present
        height:
          event.contentRect.height -
          (this.resizable ? this.resizeHandleHeightPx : 0),
      });
    }
  }

  destroyModels() {
    if (this.originalModel) {
      this.originalModel.dispose();
      this.originalModel = null;
    }
    if (this.modifiedModel) {
      this.modifiedModel.dispose();
      this.modifiedModel = null;
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.dispose();
      this.editor = null;
    }
    this.destroyModels();
  }

  onMwlResizing(event: ResizeEvent): void {
    this.hostHeight = event.rectangle.height + 'px';
  }

  private handleChanges() {
    this.destroyModels();

    this.originalModel = this.monaco.editor.createModel(
      this.original,
      this.language ? this.language : 'text',
    );
    this.modifiedModel = this.monaco.editor.createModel(
      this.modified,
      this.language ? this.language : 'text',
    );
    this.editor.setModel({
      original: this.originalModel,
      modified: this.modifiedModel,
    });

    // change events
    this.editor.getModifiedEditor().onDidChangeModelContent(() => {
      const alternativeVersionId = this.editor
        .getModifiedEditor()
        .getModel()
        .getAlternativeVersionId();

      // compare versions to determine if the model change was done using editor input (TRUE) or externally (FALSE)
      if (this.lastIncomingVersionId !== alternativeVersionId) {
        this.markAsDirty();
        this.propagateChange(this.editor.getModifiedEditor().getValue());
      }
      this.cdr.markForCheck();
    });
  }
}

@NgModule({
  imports: [CommonModule, FrameworkResizeObserverModule, ResizableModule],
  declarations: [MonacoDiffEditorComponent],
  exports: [MonacoDiffEditorComponent],
})
export class DtlMonacoDiffEditorModule {}
