import {createAction, props} from '@ngrx/store';
import {UserImage} from '../model';

export const LoadUserImageById = createAction(
  '[User] Load LoadUserImageById',
  props<{id: string}>(),
);
export const LoadUserImageByIdSuccess = createAction(
  '[User] Load LoadUserImageById Success',
  props<{
    user: UserImage;
  }>(),
);
export const LoadUserImageByIdError = createAction(
  '[User] Load LoadUserImageById Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const LoadUserImageByIds = createAction(
  '[User] Load LoadUserImageByIds',
  props<{ids: string[]}>(),
);
export const LoadUserImageByIdsSuccess = createAction(
  '[User] Load LoadUserImageByIds Success',
  props<{
    entities: UserImage[];
  }>(),
);
export const LoadUserImageByIdsError = createAction(
  '[User] Load LoadUserImageByIds Error',
  props<{
    ids: string[];
    error: any;
  }>(),
);

export const LoadBatchUserImageById = createAction(
  '[User] Load LoadBatchUserImageById',
  props<{id: string}>(),
);
export const LoadBatchUserImageByIdSuccess = createAction(
  '[User] Load LoadBatchUserImageById Success',
  props<{
    entities: UserImage[];
  }>(),
);
export const LoadBatchUserImageByIdError = createAction(
  '[User] Load LoadBatchUserImageById Error',
  props<{
    ids: string[];
    error: any;
  }>(),
);
