import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {isUuid} from '@tsm/framework/functions';

@Directive({
  selector: '[pageTitle]',
})
export class PageTitleDirective implements OnDestroy {
  private _observer: MutationObserver;
  private _pageTitle: string;

  private _subject$ = new Subject<void>();
  private _subscription: Subscription;

  get pageTitle(): string {
    return this._pageTitle;
  }

  @Input()
  set pageTitle(value: string) {
    this._pageTitle = value;
    if (this._pageTitle != null && this._pageTitle != '') {
      this.titleService.setTitle(this._pageTitle);
    }
  }

  constructor(
    private el: ElementRef,
    private ngZone: NgZone,
    private titleService: Title,
  ) {
    this._observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation, index) => {
        ngZone.runOutsideAngular(() => this._subject$.next());
      });
    });

    this._observer.observe(this.el.nativeElement, {
      childList: false,
      attributes: false,
      characterData: true,
      subtree: true,
      // attributeFilter:true,
      attributeOldValue: false,
      characterDataOldValue: true,
    });

    this._subscription = this._subject$
      .pipe(debounceTime(100))
      .subscribe((_) => this.setTitle());
  }

  ngOnDestroy() {
    if (this._observer) {
      this._observer.disconnect();
      this._observer = null;
    }
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  private setTitle() {
    if (this._pageTitle != null && this._pageTitle != '') {
      this.titleService.setTitle(this._pageTitle);
    } else if (
      this.el.nativeElement.getElementsByClassName('details') != null &&
      this.el.nativeElement.getElementsByClassName('details').length > 0
    ) {
      let resultTitle = '';
      for (
        let i =
          this.el.nativeElement.getElementsByClassName('details')[0].children
            .length - 1;
        i >= 0;
        i--
      ) {
        const value = this.el.nativeElement
          .getElementsByClassName('details')[0]
          .children[i].textContent?.trim();
        if (isUuid(value) || value == 'none' || value == '') {
          continue;
        }
        resultTitle = resultTitle + value;
        if (i != 0) {
          resultTitle = resultTitle + ' - ';
        }
      }
      resultTitle = resultTitle + ' | tSM';
      this.titleService.setTitle(resultTitle);
    } else if (
      this.el.nativeElement.children != null &&
      this.el.nativeElement.children.length > 0
    ) {
      let isOk = true;
      for (let i = this.el.nativeElement.children.length - 1; i >= 0; i--) {
        if (
          this.el.nativeElement.children[i].className !== 'item' &&
          this.el.nativeElement.children[i].className !==
            'item ng-star-inserted'
        ) {
          isOk = false;
        }
      }
      if (isOk == false) {
        return;
      }
      let resultTitle = '';
      for (let i = this.el.nativeElement.children.length - 1; i >= 0; i--) {
        resultTitle =
          resultTitle +
          this.el.nativeElement.children.item(i).textContent?.trim();
        if (i != 0) {
          resultTitle = resultTitle + ' - ';
        }
      }
      resultTitle = resultTitle + ' | tSM';
      this.titleService.setTitle(resultTitle);
    } else {
      this.titleService.setTitle('tSM - APP');
    }
  }
}

@NgModule({
  declarations: [PageTitleDirective],
  exports: [PageTitleDirective],
})
export class PageTitleDirectiveModule {}
