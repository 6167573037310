import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'tsm-icon-picker-image',
  templateUrl: 'tsm-icon-picker-image.component.html',
  styleUrls: ['../tsm-icon-picker/tsm-icon-picker.component.scss'],
})
export class TsmIconPickerImageComponent {
  @Input() filteredImages: string[];

  @Input() mixed = false;

  // null is essential, cuz if you haven't provided any imagePathPrefix, then you shouldn't add anything to the output string
  @Input() imagePathPrefix = '';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onImageSelected: EventEmitter<any> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onImageBase64Selected: EventEmitter<any> = new EventEmitter();
}
