import {createReducer, on} from '@ngrx/store';
import {LayoutState} from '../models';
import {
  AddMenuItemAction,
  DeleteMenuItemAction,
  LoadMenuItemsAction,
  LoadMenuItemsActionError,
  LoadMenuItemsActionSuccess,
  ReorderBookmarkMenuItemsAction,
  SetInitState,
  SetLayoutMenuAction,
  SetMenuAction,
  SetTopbarAction,
  ToggleBookmarkMenuItemAction,
  UpdateMenuItemAction,
  UpsertMenuItemsAction,
  UpsertPreviousUrl,
} from '../actions';

export const initialState: LayoutState = {
  opened: false,
  activeTopBarItem: null,
  previousUrl: {url: null, queryParams: null},
  layoutMenu: {
    error: null,
    data: [],
    loading: false,
  },
};

export const reducer = createReducer(
  initialState,

  on(SetInitState, (state) => ({
    ...initialState,
  })),
  on(SetTopbarAction, (state, {topbar}) => ({
    ...state,
    activeTopBarItem: state.activeTopBarItem === topbar ? null : topbar,
  })),
  on(SetMenuAction, (state, {opened}) => {
    return {
      ...state,
      opened,
    };
  }),

  // CUSTOM BUTTON + PROFILY
  on(SetLayoutMenuAction, (state, {layoutMenu}) => ({
    ...state,
    layoutMenu: {
      ...state.layoutMenu,
      data: layoutMenu,
      loading: false,
      error: null,
    },
  })),

  // MENU

  on(
    LoadMenuItemsAction,
    UpsertMenuItemsAction,
    ReorderBookmarkMenuItemsAction,
    AddMenuItemAction,
    UpdateMenuItemAction,
    DeleteMenuItemAction,
    (state, {}) => {
      return {
        ...state,
        layoutMenu: {
          ...state.layoutMenu,
          loading: true,
          error: null,
        },
      };
    },
  ),

  on(LoadMenuItemsActionSuccess, (state, {menus}) => {
    return {
      ...state,
      layoutMenu: {
        ...state.layoutMenu,
        data: menus,
        loading: false,
        error: null,
      },
    };
  }),

  on(LoadMenuItemsActionError, (state, {error}) => {
    return {
      ...state,
      layoutMenu: {
        ...state.layoutMenu,
        loading: false,
        error: error,
      },
    };
  }),

  on(UpsertPreviousUrl, (state, {url, queryParams}) => ({
    ...state,
    previousUrl: {url: url, queryParams: queryParams},
  })),
);
