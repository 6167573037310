import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {AttachmentTypeModel} from '../models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttachmentTypeDataService {
  private readonly ATTACHMENT = `v1/attachment-types`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getEntity(id: string): Observable<Envelope<AttachmentTypeModel>> {
    return this.apiService.get<AttachmentTypeModel, AttachmentTypeModel>(
      this.BASE_URL + `/${this.ATTACHMENT}/${id}`,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  getEntityByCode(code: string): Observable<Envelope<AttachmentTypeModel>> {
    return this.getEntity(code);
  }

  deleteEntity(id: string): Observable<Envelope<AttachmentTypeModel>> {
    return this.apiService.delete<AttachmentTypeModel, AttachmentTypeModel>(
      this.BASE_URL + `/${this.ATTACHMENT}/${id}`,
    );
  }

  postEntity(
    entity: AttachmentTypeModel,
  ): Observable<Envelope<AttachmentTypeModel>> {
    return this.apiService.post<AttachmentTypeModel, AttachmentTypeModel>(
      this.BASE_URL + `/${this.ATTACHMENT}`,
      entity,
    );
  }

  putEntity(id: string, entity: AttachmentTypeModel): Observable<any> {
    return this.apiService.put<AttachmentTypeModel, AttachmentTypeModel>(
      this.BASE_URL + `/${this.ATTACHMENT}/${id}`,
      entity,
    );
  }

  upsertEntity(entity: AttachmentTypeModel, id: string) {
    if (id) {
      return this.putEntity(id, entity);
    } else {
      return this.postEntity(entity);
    }
  }

  getAll(): Observable<any> {
    return this.apiService.get<AttachmentTypeModel[], AttachmentTypeModel[]>(
      this.BASE_URL + `/${this.ATTACHMENT}/all`,
    );
  }
}
