import {createFeatureSelector, createSelector} from '@ngrx/store';
import {worklogTimerAdapter} from '../reducers/worklog-timer.reducer';
import {DmsState} from '../reducers';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getWorklogTimerState = createSelector(
  getDmsState,
  (x) => x.worklogTimer,
);

const {selectEntities} = worklogTimerAdapter.getSelectors(getWorklogTimerState);

export const selectCurrentWorklogTimer = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);
