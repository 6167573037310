import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {translation} from '@tsm/shared-i18n';

@Component({
  selector: 'dtl-hide-overflow',
  template: `
    <dtl-overlayPanel styleClass="dropdown" #op appendTo="body">
      <ng-template pTemplate>
        <ng-container *ngFor="let template of overflownTemplates">
          <ng-container *ngTemplateOutlet="template"></ng-container>
        </ng-container>
      </ng-template>
    </dtl-overlayPanel>

    <div
      #wrapper
      *ngIf="templates"
      class="wrapper"
      [class.is-overflowing]="isOverflowing"
      tsmResizeObserver
      (resize)="resized($event)"
      [style.height]="height"
    >
      <ng-container *ngFor="let template of templates">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
    <button
      *ngIf="overflownTemplates.length > 0"
      pButton
      pRipple
      type="button"
      [pTooltip]="translation.shared.showAll | transloco"
      class="p-button-plain p-button-text show-all"
      icon="p-icon-more-vert"
      (click)="op.toggle($event)"
    ></button>
  `,
  styleUrls: ['./hide-overflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HideOverflowComponent {
  @ViewChild('wrapper', {read: ElementRef}) wrapperEl: ElementRef;
  translation = translation;
  @Input() height: string;

  @HostBinding('style.height')
  get styleHeightString() {
    return this.height;
  }

  @Input() templates: TemplateRef<any>[];
  overflownTemplates: TemplateRef<any>[] = [];

  isOverflowing = false;
  showDropdown = false;
  observer: MutationObserver;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
  ) {}

  ngAfterViewInit() {
    this.observer = new MutationObserver(() => {
      this.resized({
        target: this.wrapperEl.nativeElement,
      } as ResizeObserverEntry);
    });
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  resized({target}: ResizeObserverEntry) {
    const children = target.children;
    this.overflownTemplates = Array.from(children)
      .map((x, i) => ({isOverflown: this.isOverflown(x as HTMLElement), i}))
      .filter((x) => x.isOverflown)
      .map((x) => this.templates[x.i]);
    this.cdr.detectChanges();
  }

  private isOverflown(el: HTMLElement) {
    const elTop = el.offsetTop;
    const intHeight = parseInt(this.height);
    const magicConstant = 8;
    return elTop >= intHeight - magicConstant;
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    this.observer = null;
  }
}
