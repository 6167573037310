import {ControlValueAccessor} from '@angular/forms';
import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'abstractControlValueAccessor',
  host: {
    class: 'dtl-form-field-input',
    '[class.p-state-filled]': 'filled',
    '[class.p-state-focus]': 'focused && readonly !== true',
    '[class.p-state-readonly]':
      'readonly !== undefined && readonly !== null && readonly !== false',
  },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AbstractControlValueAccessor implements ControlValueAccessor {
  /**
   * Slouzi pro definici atributu pro porovnavani objektu (viz. dokumentace PrimeNg)
   * Zaroven urcuje atribut z objektu, ktery se predava do FormControl
   */
  @Input()
  dataKey: string = null;

  @Input()
  tabindex: number;

  @Input()
  readonly = false;

  /**
   * Pokud true, dojde k otevreni noveho okna s listem a nastavenym filtrem
   */
  @Input()
  openFilteredList = false;

  @Output()
  valueChanged = new EventEmitter();

  filled = false;
  focused = false;
  @Output()
  changeFocus = new EventEmitter();

  protected _val: any;

  protected customValueConverter: (value: any) => any = null;

  get val(): any {
    return this._val;
  }

  // Neprejmenovavat a nechat "cdr" nutne kvuli chybe angularu!
  constructor(protected cdr: ChangeDetectorRef) {}

  set val(value: any) {
    const val = this.dataKey && value ? value[this.dataKey] : value;
    this._val = val;
    this.onChange(val);
    this.valueChanged.emit(val);
    this.setFilled(val);
    this.cdr.markForCheck();
  }

  /**
   * Alternative for overriding writeValue function
   */
  writeVal = signal(this.val);

  @Input()
  set value(value: any) {
    this.writeValue(value);
  }

  get value() {
    return this._val;
  }

  onChange: (val) => void = (val) => {};
  onTouched: (val) => void = (val) => {};
  onReadonly: (val) => void = (val) => {};

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }

  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // We implement this method to keep a reference to the onReadonly
  //callback function passed by the forms API
  registerOnReadonly(fn) {
    this.onReadonly = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.onFocus(false);
  }

  setReadonlyState(readonly: boolean): void {
    this.readonly = readonly;
    this.cdr.markForCheck();
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (this.customValueConverter != null) {
      this._val = this.customValueConverter(value);
    } else if (this.dataKey && value) {
      this._val = typeof value !== 'object' ? value : value[this.dataKey];
    } else {
      this._val = value;
    }
    this.setFilled(value);
    this.writeVal.set(value);
    this.cdr.markForCheck();
  }

  setFilled(value: any) {
    if (typeof value === 'number') {
      this.filled = !(value === undefined || value === null);
    } else if (Array.isArray(value)) {
      this.filled = value.length > 0;
    } else if (typeof value === 'object') {
      this.filled = !!value;
    } else {
      this.filled = !!(value && value.length > 0);
    }
  }

  onFocus(isFocus: boolean) {
    this.focused = isFocus;
    if (!isFocus) {
      this.onTouched(true);
    }
    this.changeFocus.emit(this.focused);
    this.cdr.markForCheck();
  }
}
