import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteAllUserGroupRegions,
  DeleteAllUserGroupRegionsError,
  DeleteAllUserGroupRegionsSuccess,
  DeleteUserGroupRegion,
  DeleteUserGroupRegionError,
  DeleteUserGroupRegionSuccess,
  LoadUserGroupRegionsByUserGroupId,
  LoadUserGroupRegionsByUserGroupIdError,
  LoadUserGroupRegionsByUserGroupIdSuccess,
  LoadUserGroupRegionsByUserGroupRegionId,
  LoadUserGroupRegionsByUserGroupRegionIdError,
  LoadUserGroupRegionsByUserGroupRegionIdSuccess,
  PatchUserGroupRegion,
  PatchUserGroupRegionError,
  PatchUserGroupRegionSuccess,
  SaveAllUserGroupRegions,
  SaveAllUserGroupRegionsError,
  SaveAllUserGroupRegionsSuccess,
  UpsertUserGroupRegion,
  UpsertUserGroupRegionError,
  UpsertUserGroupRegionSuccess,
} from '../actions';
import {EntityIdentif, UserGroupRegion} from '../model';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {translation} from '../i18n';

@Injectable()
export class UserGroupRegionEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      UserGroupRegion,
      UserGroupRegion
    >,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupRegionsByUserGroupId),
      exhaustMap(({userGroupId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_GROUP_REGION,
            'userGroup.id__eq=' + userGroupId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupRegionsByUserGroupIdSuccess({entities: env.data})
                : LoadUserGroupRegionsByUserGroupIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByUserGroupRegionId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupRegionsByUserGroupRegionId),
      exhaustMap(({userGroupRegionId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_GROUP_REGION,
            'regionId__eq=' + userGroupRegionId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupRegionsByUserGroupRegionIdSuccess({
                    entities: env.data,
                  })
                : LoadUserGroupRegionsByUserGroupRegionIdError({
                    error: env.error,
                  });
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroupRegion),
      concatMap(({userGroupRegion}) =>
        this.commonApiService
          .upsertEntity(
            EntityIdentif.USER_GROUP_REGION,
            userGroupRegion,
            userGroupRegion.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .groupRegionSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserGroupRegionSuccess({
                  userGroupRegion: env.data,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .groupRegionSaveFailure,
                );
                return UpsertUserGroupRegionError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatchUserGroupRegion),
      concatMap(({userGroupRegion}) =>
        this.commonApiService
          .patchEntity(
            EntityIdentif.USER_GROUP_REGION,
            userGroupRegion.id,
            userGroupRegion,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .groupRegionSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return PatchUserGroupRegionSuccess({userGroupRegion: env.data});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .groupRegionSaveFailure,
                );
                return PatchUserGroupRegionError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAllUserGroupRegions),
      concatMap(({entities, listingId}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.USER_GROUP_REGION, entities)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .groupRegionAssignSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return SaveAllUserGroupRegionsSuccess({
                  entities: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .groupRegionAssignFailure,
                );
                return SaveAllUserGroupRegionsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserGroupRegion),
      concatMap(({id}) =>
        this.commonApiService
          .deleteEntity(EntityIdentif.USER_GROUP_REGION, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .groupRegionDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteUserGroupRegionSuccess({id: id});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .groupRegionDeleteFailure,
                );
                return DeleteUserGroupRegionError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllUserGroupRegions),
      concatMap(({ids, listingId}) =>
        this.commonApiService
          .deleteAllEntities(EntityIdentif.USER_GROUP_REGION, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.regionRemoveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteAllUserGroupRegionsSuccess({
                  ids: ids,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.regionRemoveFailure,
                );
                return DeleteAllUserGroupRegionsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeleteUserGroupRegionSuccess,
        UpsertUserGroupRegionSuccess,
        SaveAllUserGroupRegionsSuccess,
        DeleteAllUserGroupRegionsSuccess,
        PatchUserGroupRegionSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
