import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import {
  addEntity,
  removeEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import {FluentFormState} from '../models';

const collection = 'fluentForms';
const entity = type<FluentFormState>();

export const withFluentForms = () =>
  signalStoreFeature(
    withEntities({entity, collection}),
    withComputed((store) => ({
      entityMap: store.fluentFormsEntityMap,
    })),
    withMethods((store) => ({
      selectValue(id: string) {
        return store.fluentFormsEntityMap()[id]?.value;
      },
      selectContext(id: string) {
        return store.fluentFormsEntityMap()[id]?.context;
      },
      selectOutputContext(id: string) {
        return store.fluentFormsEntityMap()[id]?.outputContext;
      },
      selectRootControl(id: string) {
        return store.fluentFormsEntityMap()[id]?.rootControl;
      },
      selectRootSchema(id: string) {
        return store.fluentFormsEntityMap()[id]?.schema;
      },
      selectLayoutingType(id: string) {
        return store.fluentFormsEntityMap()[id]?.layoutingType;
      },
      selectMarkForCheckDataPointer(id: string) {
        return store.fluentFormsEntityMap()[id]?.markForCheckDataPointer;
      },
      upsert(newState: FluentFormState): void {
        const found = store.fluentFormsEntityMap()[newState.id];
        if (found != null) {
          patchState(
            store,
            updateEntity(
              {
                id: found.id,
                changes: newState,
              },
              {collection},
            ),
          );
        } else {
          patchState(store, addEntity(newState, {collection}));
        }
      },
      destroy(id: string): void {
        patchState(store, removeEntity(id, {collection}));
      },
    })),
  );
