import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnInit,
} from '@angular/core';
import {translation} from '@tsm/shared-i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {PickerBundle, PickerBundleList} from '../resources';
import {SelectItemGroup} from 'primeng/api';
import {TranslocoService} from '@tsm/framework/translate';
import {ToastService} from '@tsm/framework/toast';

interface TextSize {
  name: string;
  code: string;
}

@Component({
  selector: 'tsm-icon-picker-content',
  templateUrl: 'tsm-icon-picker-content.component.html',
  styleUrls: ['./tsm-icon-picker-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsmIconPickerContentComponent implements OnInit {
  @Input() label: string;

  @Input() showSuccessDialog = true;

  @Input() class: string;

  @Input() isMenuReadonly: boolean;

  @Input() allowedCategory: 'icon' | 'image' = null;

  @Output() onIconClick = new EventEmitter<string>();

  @Output() hideDialog = new EventEmitter();

  translation = translation;
  translationShared = translationShared;

  icons: string[];
  images: string[];

  prefix: string = null;
  imagePathPrefix: string = null;

  pickerBundles: PickerBundle[] = PickerBundleList.getList();

  selectedIconBundle = 'all';
  selectedIcon: string;

  filteredIcons: string[];
  filteredImages: string[];

  displayDialog = false;

  isIconSelection = true;

  closeDialogAfterSelection = true;

  // Color Dropdown
  groupedColors: SelectItemGroup[];
  selectedColor: string | undefined;
  colorType: 'bg' | 'text' = 'text';
  colorPalette = [
    'blue',
    'green',
    'yellow',
    'cyan',
    'pink',
    'indigo',
    'teal',
    'orange',
    'bluegray',
    'purple',
    'gray',
    'red',
    'primary',
  ];
  colorTints = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];
  colorPaletteWithTints = this.colorPalette
    .map((color) => this.colorTints.map((tint) => `${color}-${tint}`))
    .flat();

  // Size Dropdown
  textSizes: TextSize[] | undefined;
  selectedIconSize: TextSize | undefined;

  constructor(
    private messageService: ToastService,
    private translocoService: TranslocoService,
  ) {
    if (this.allowedCategory != null) {
      this.pickerBundles.filter(
        (bundles) => bundles.type == this.allowedCategory,
      );
    }

    const colorVariables = this.getColorVariables();
    this.groupedColors = this.colorPalette.map((color) => ({
      label: color.charAt(0).toUpperCase() + color.slice(1),
      value: `${color}-500`,
      items: colorVariables
        .filter((x) => x.includes(`${color}-`))
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((x) => {
          const res = {label: x.replace('-', ' '), value: x};
          res.label = res.label.charAt(0).toUpperCase() + res.label.slice(1);
          return res;
        }),
    }));

    this.textSizes = [
      {name: 'text-xs', code: 'text-xs'},
      {name: 'text-sm', code: 'text-sm'},
      {name: 'text-base', code: 'text-base'},
      {name: 'text-lg', code: 'text-lg'},
      {name: 'text-xl', code: 'text-xl'},
      {name: 'text-2xl', code: 'text-2xl'},
      {name: 'text-3xl', code: 'text-3xl'},
      {name: 'text-4xl', code: 'text-4xl'},
      {name: 'text-5xl', code: 'text-5xl'},
      {name: 'text-6xl', code: 'text-6xl'},
      {name: 'text-7xl', code: 'text-7xl'},
      {name: 'text-8xl', code: 'text-8xl'},
    ];
  }

  ngOnInit(): void {
    this.filteredIcons = this.icons = this.getSelectedContentMixed('icon');
    this.filteredImages = this.images = this.getSelectedContentMixed('image');
  }

  onIconSelection(value: string, image?: boolean) {
    if (value) {
      if (this.selectedColor) {
        value = value + ' ' + this.colorType + '-' + this.selectedColor;
      }
      if (this.selectedIconSize) {
        value = value + ' ' + this.selectedIconSize.code;
      }
      this.passIntoClipboard(value);
      //navigator.clipboard.writeText(value).then().catch(e => console.error(e));
      this.onIconClick.emit(value);

      if (this.closeDialogAfterSelection) {
        // this.clearSearchBar();
        // this.onModelChanged();
        this.hideDialog.emit();
      }

      if (this.showSuccessDialog) {
        this.messageService.showToast(
          image
            ? this.translocoService.translate(
                this.translation.shared.imageCopiedSummary,
              ) +
                ' (' +
                value +
                ')'
            : this.translocoService.translate(
                this.translation.shared.iconCopiedSummary,
              ) +
                ' (' +
                value +
                ')',
        );
      }
    }
  }

  onModelChanged() {
    if (this.selectedIconBundle === 'all') {
      this.filteredIcons = this.icons = this.getSelectedContentMixed('icon');
      this.filteredImages = this.images = this.getSelectedContentMixed('image');
    } else {
      const bundle: PickerBundle = this.pickerBundles.find(
        (x) => x.code == this.selectedIconBundle,
      );

      if (bundle.type == 'icon') {
        this.prefix = bundle.prefix;
        this.filteredIcons = this.icons = bundle.content;
        this.isIconSelection = true;
      } else if (bundle.type == 'image') {
        this.imagePathPrefix = bundle.prefix;
        this.filteredImages = this.images = bundle.content;
        this.isIconSelection = false;
      }
    }
  }

  filterIcons(event) {
    this.filteredIcons = this.icons.filter((x) =>
      x.includes(event.query.toLowerCase()),
    );
    this.filteredImages = this.images.filter((x) =>
      x.includes(event.query.toLowerCase()),
    );
  }

  clearSearchBar() {
    this.selectedIcon = null;
  }

  copyPathPassPath(iconPath: string) {
    this.passIntoClipboard(iconPath);
    //navigator.clipboard.writeText(iconPath).then().catch(e => console.error(e));
    this.onIconSelection(iconPath, true);
  }

  copyBase64PassNothing(imgId: string) {
    const canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
    const canvasContext = canvas.getContext('2d');
    const img = document.getElementById(imgId) as CanvasImageSource;

    canvasContext.clearRect(
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height,
    );
    canvasContext.beginPath();
    canvasContext.drawImage(img, 10, 10);
    //navigator.clipboard.writeText(canvas.toDataURL()).then().catch(e => console.error(e));
    this.passIntoClipboard(canvas.toDataURL());
  }

  passIntoClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getSelectedContentMixed(content: 'icon' | 'image'): string[] {
    const finalContent = [];

    const bundles: PickerBundle[] = this.pickerBundles.filter(
      (x) => x.type == content,
    );
    bundles.forEach((bundle) => {
      bundle.content.forEach((bundleContent) => {
        finalContent.push(bundle.prefix + bundleContent);
      });
    });

    return finalContent;
  }

  private getColorVariables() {
    const allVariables = Array.from(document.styleSheets)
      .filter(
        (sheet) =>
          sheet.href === null || sheet.href.startsWith(window.location.origin),
      )
      .reduce(
        (acc, sheet) =>
          (acc = [
            ...acc,
            ...Array.from(sheet.cssRules).reduce(
              (def, rule: any) =>
                (def =
                  rule.selectorText === ':root'
                    ? [
                        ...def,
                        ...Array.from(rule.style).filter((name: string) =>
                          name.startsWith('--'),
                        ),
                      ]
                    : def),
              [],
            ),
          ]),
        [],
      );
    return allVariables
      .filter((x) => this.colorPaletteWithTints.some((y) => x.includes(y)))
      .map((x) => x.replace('--', ''));
  }
}
