import {Plugin} from '@tsm/framework/plugin';
import {translation as translationPlugin} from '@tsm/service-management/plugins-i18n';

export const serviceManagementPlugin: Plugin = {
  menus: [
    {
      key: 'serviceManagement',
      priority: 800,
      label: 'menu.serviceManagement.serviceManagement',
      icon: 'tsm-icon-service-management',
      privilege: ['true'],
      items: [
        {
          key: 'serviceManagementList',
          label: 'menu.serviceManagement.managedMicroservices',
          icon: 'tsm-icon-entity-specifications',
          routerLink: ['/service-management'],
          privilege: ['true'],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'service-management',
      loadChildren: () =>
        import('@tsm/service-management/components').then(
          (x) => x.TsmServiceManagementComponentsModule,
        ),
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/service-management/widgets').then(
          (x) => x.ServiceManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'consumerStatusIcon',
          name: translationPlugin.serviceManagementPlugins.pipes
            .consumerStatusIcon,
          description:
            translationPlugin.serviceManagementPlugins.pipes
              .consumerStatusIconDescription,
        },
        {
          selector: 'serviceStatusIconPipe',
          name: translationPlugin.serviceManagementPlugins.pipes
            .serviceStatusIconPipe,
          description:
            translationPlugin.serviceManagementPlugins.pipes
              .serviceStatusIconPipeDescription,
        },
      ],
    },
  ],
  filters: [],
  widgets: [
    {
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/managed-metrics-environment'
            ).then((x) => x.ManagedMetricsEnvironmentComponent),
          selector: 'tsm-managed-metrics-environment',
        },
        {
          loadComponent: () =>
            import('@tsm/service-management/widgets/actuator').then(
              (x) => x.ActuatorComponent,
            ),
          selector: 'tsm-actuator-component',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/assigned-partition-widget'
            ).then((x) => x.AssignedPartitionWidgetComponent),
          selector: 'tsm-assigned-partition-widget',
        },
        {
          loadComponent: () =>
            import('@tsm/service-management/widgets/disk-space-widget').then(
              (x) => x.DiskSpaceWidgetComponent,
            ),
          selector: 'tsm-disk-space-widget',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/consumer-status-tag-widget'
            ).then((x) => x.ComsumerStatusTagWidgetComponent),
          selector: 'tsm-consumer-status-tag-widget',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/service-status-tag-widget'
            ).then((x) => x.ServiceStatusTagWidgetComponent),
          selector: 'tsm-service-status-tag-widget',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/kafka-topic-partition-widget'
            ).then((x) => x.KafkaTopicPartitionWidgetComponent),
          selector: 'tsm-topic-partition-widget',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/kafka-admin-member-description-widget'
            ).then((x) => x.KafkaAdminMemberDescriptionWidgetComponent),
          selector: 'tsm-kafka-admin-member-description-widget',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/service-management/widgets/kafka-admin-assigned-partition-widget'
            ).then((x) => x.KafkaAdminAssignedPartitionWidgetComponent),
          selector: 'tsm-kafka-admin-assigned-partition-widget',
        },
        {
          loadComponent: () =>
            import('@tsm/service-management/widgets/consumer-status-lov').then(
              (x) => x.ConsumerStatusLovComponent,
            ),
          selector: 'tsm-consumer-status-lov',
        },
        {
          loadComponent: () =>
            import('@tsm/service-management/widgets/kafka-group-widget').then(
              (x) => x.KafkaGroupWidgetComponent,
            ),
          selector: 'tsm-kafka-group-widget',
        },
      ],
    },
  ],
};
