import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteValueConverterConfig,
  DeleteValueConverterConfigError,
  DeleteValueConverterConfigSuccess,
  DiffValueConverterConfig,
  DiffValueConverterConfigError,
  DiffValueConverterConfigSuccess,
  InsertValueConverterConfig,
  InsertValueConverterConfigError,
  InsertValueConverterConfigSuccess,
  LoadValueConverterConfig,
  LoadValueConverterConfigById,
  LoadValueConverterConfigByIdError,
  LoadValueConverterConfigByIdSuccess,
  LoadValueConverterConfigError,
  LoadValueConverterConfigSuccess,
  RefreshDataAndClearSelected,
  UpdateValueConverterConfig,
  UpdateValueConverterConfigError,
  UpdateValueConverterConfigSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {CommonConfigFormApiService} from '../services';
import {Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {ValueConverterConfig} from '../models';

@Injectable()
export class ValueConverterConfigEffects {
  private readonly CONFIG_TYPE_API_PATH = 'value-convertor-config';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadValueConverterConfig),
      exhaustMap(() =>
        this.commonApiService.getAll(this.CONFIG_TYPE_API_PATH).pipe(
          map((env) =>
            env.success
              ? LoadValueConverterConfigSuccess({
                  valueConverterConfigs: env.data,
                })
              : LoadValueConverterConfigError(env.error),
          ),
        ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadValueConverterConfigById),
      concatMap(({id}) =>
        this.commonApiService.getEntity(this.CONFIG_TYPE_API_PATH, id).pipe(
          map((env) =>
            env.success
              ? LoadValueConverterConfigByIdSuccess({
                  valueConverterConfig: env.data,
                })
              : LoadValueConverterConfigByIdError({id, error: env.error}),
          ),
        ),
      ),
    ),
  );

  insert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsertValueConverterConfig),
      concatMap(({valueConverterConfig, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.CONFIG_TYPE_API_PATH,
            valueConverterConfig,
            valueConverterConfig.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.valueConverterConfigService.effects
                    .messageValueConverterConfigChangeSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return InsertValueConverterConfigSuccess({
                  valueConverterConfig: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.valueConverterConfigService.effects
                    .messageValueConverterConfigSaveUnSuccessful,
                );
                return InsertValueConverterConfigError({
                  id: listingId,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateValueConverterConfig),
      concatMap(({valueConverterConfig, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.CONFIG_TYPE_API_PATH,
            valueConverterConfig,
            valueConverterConfig.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.valueConverterConfigService.effects
                    .messageValueConverterConfigChangeSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpdateValueConverterConfigSuccess({
                  valueConverterConfig: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.valueConverterConfigService.effects
                    .messageValueConverterConfigSaveUnSuccessful,
                );
                return UpdateValueConverterConfigError({
                  id: listingId,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteValueConverterConfig),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.CONFIG_TYPE_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.valueConverterConfigService.effects
                  .valueConverterConfigDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/value-converter-config']);
              }
              return DeleteValueConverterConfigSuccess({
                id: id,
                listingId: listingId,
              });
            } else {
              this.toastService.showError(
                env.error,
                translation.valueConverterConfigService.effects
                  .valueConverterConfigDeleteError,
              );
              return DeleteValueConverterConfigError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffValueConverterConfig),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(
            `v1/${this.CONFIG_TYPE_API_PATH}`,
            diffEntities,
            compareField,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffValueConverterConfigSuccess({
                  valueConverterConfigs: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffValueConverterConfigError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterInsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsertValueConverterConfigSuccess),
      tap(({valueConverterConfig, redirect}) => {
        if (redirect === true) {
          this.router.navigate([
            '/config/value-converter-config',
            valueConverterConfig.id,
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeleteValueConverterConfigSuccess,
        DiffValueConverterConfigSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private commonApiService: CommonConfigFormApiService<
      ValueConverterConfig,
      ValueConverterConfig
    >,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
