import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserParametersService} from '../service';
import {concatMap, map} from 'rxjs/operators';
import {
  DeleteUserParameterByUserIdAndName,
  DeleteUserParameterByUserIdAndNameError,
  DeleteUserParameterByUserIdAndNameSuccess,
  LoadUserParameterByUserIdAndParameterName,
  LoadUserParameterByUserIdAndParameterNameError,
  LoadUserParameterByUserIdAndParameterNameSuccess,
  LoadUserParameterByUserIdsAndParameterName,
  LoadUserParameterByUserIdsAndParameterNameError,
  LoadUserParameterByUserIdsAndParameterNameSuccess,
  LoadUserParametersByUserId,
  LoadUserParametersByUserIdError,
  LoadUserParametersByUserIdSuccess,
  UpsertUserParameterByUserIdAndName,
  UpsertUserParameterByUserIdAndNameError,
  UpsertUserParameterByUserIdAndNameSuccess,
} from '../actions';

@Injectable()
export class UserParameterEffects {
  constructor(
    private actions$: Actions,
    private userParametersService: UserParametersService,
  ) {}

  deleteByUserIdAndName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserParameterByUserIdAndName),
      concatMap(({userId, name, listingId}) => this.userParametersService.deleteUserParam(userId, name).pipe(
        map(env => {
          if (env.success) {
            return DeleteUserParameterByUserIdAndNameSuccess({
              entity: env.data,
              name: name,
              userId: userId,
              listingId: listingId
            });
          } else {
            return DeleteUserParameterByUserIdAndNameError({name: name, userId: userId, error: env.error});
          }
        })
      ))
    )
  );

  upsertByUserIdAndName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserParameterByUserIdAndName),
      concatMap(({userId, name, value, listingId}) =>
        this.userParametersService.setUserParam(userId, name, value).pipe(
          map((env) => {
            if (env.success) {
              return UpsertUserParameterByUserIdAndNameSuccess({
                entity: env.data,
                name: name,
                userId: userId,
                listingId: listingId,
              });
            } else {
              return UpsertUserParameterByUserIdAndNameError({
                name: name,
                userId: userId,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  loadByUserIdAndName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserParameterByUserIdAndParameterName),
      concatMap(({userId, name}) =>
        this.userParametersService.getUserParam(userId, name).pipe(
          map((env) => {
            if (env.success) {
              return LoadUserParameterByUserIdAndParameterNameSuccess({
                userId: userId,
                entity: env.data,
                name: name,
              });
            } else {
              return LoadUserParameterByUserIdAndParameterNameError({
                userId: userId,
                name: name,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  loadByUserIdsAndName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserParameterByUserIdsAndParameterName),
      concatMap(({userIds, name}) =>
        this.userParametersService.getUserParams(userIds, name).pipe(
          map((env) => {
            if (env.success) {
              return LoadUserParameterByUserIdsAndParameterNameSuccess({
                userIds: userIds,
                entities: env.data,
                name: name,
              });
            } else {
              return LoadUserParameterByUserIdsAndParameterNameError({
                userIds: userIds,
                name: name,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserParametersByUserId),
      concatMap(({userId}) => {
        return this.userParametersService.getUserParamsByUserId(userId).pipe(
          map((env) =>
            env.success
              ? LoadUserParametersByUserIdSuccess({
                  entities: env.data ?? [],
                  userId: userId,
                })
              : LoadUserParametersByUserIdError({
                  userId: userId,
                  error: env.error,
                }),
          ),
        );
      }),
    ),
  );
}
