import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shallowMerge',
  pure: true,
})
export class ShallowMergePipe implements PipeTransform {
  transform(obj1: any, obj2: any): any {
    return {
      ...obj1,
      ...obj2,
    };
  }
}
