import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Comment} from '../models';
import {Location} from '@tsm/wfm/service';

import {EditNewCommentText} from '../actions';

export interface NewCommentForm extends Comment {
  isWindow?: boolean;
  commentTypeCodes?: string[];
  defaultCommentType?: string;
  title?: string;
  url?: string;
  ownerLocation?: Location;
  hidePrivateSwitch?: boolean;
}

export const newCommentAdapter: EntityAdapter<NewCommentForm> =
  createEntityAdapter<Comment>();
export const initialState: EntityState<NewCommentForm> =
  newCommentAdapter.getInitialState({});

const getEntityId = (ownerId: string, ownerType: string) => ownerId + ownerType;

export const newCommentReducer = createReducer(
  initialState,

  on(EditNewCommentText, (state, {ownerId, ownerType, comment}) => {
    if (comment === null) {
      return newCommentAdapter.removeOne(
        getEntityId(ownerId, ownerType),
        state,
      );
    }
    return newCommentAdapter.upsertOne(
      {...comment, id: getEntityId(ownerId, ownerType)},
      state,
    );
  }),
);
