import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'colorPipe',
  pure: true,
})
export class ColorPipe implements PipeTransform {
  transform(color: any): any {
    return color == null || color == ''
      ? ''
      : '<div class="color-icon" style="background-color: ' +
          color +
          '">&nbsp;</div>';
  }
}
