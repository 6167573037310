export interface IdentifiedPack<T> {
  id: any;
  data?: T;
  loading?: boolean;
  validUntil?: Date;
  error?: any;
}

export class IdentifiedPackUtils {
  /**
   * Overi byly-li nactene vsechna data
   * @param packs pole IdentifiedPacku
   *
   * @return true pokud nacitani bylo dokoncene pro vsechny prvky pole, jinak false
   */
  private static areAllLoaded(packs: IdentifiedPack<any>[]): boolean {
    for (let i = 0; i < packs.length; i++) {
      if (packs[i].loading === true) {
        return false;
      }
    }
    return true;
  }

  /**
   * Funkce vraci prvni chybu, ktera nastala behem nacteni dat
   * @param packs pole IdentifiedPacku
   *
   * @return chybu pokud byla nalezena, v opacnem pripade bude vracen null
   */
  private static checkErrors(packs: IdentifiedPack<any>[]) {
    for (let i = 0; i < packs.length; i++) {
      if (packs[i].error) {
        return packs[i].error;
      }
    }
    return null;
  }

  /**
   * @link IdentifiedPackUtils#checkErrors
   * Dela to samy, ale umi pracovat i s ne [], lisi se jen vstupni parameter.
   * @param args pole identified packu nebo identified pack samotny,
   * taky muze byt polem premichanych mezi sebou [] a samotnych instanci
   */
  public static anyError(...args) {
    let res = null;
    for (let i = 0; i < args.length; i++) {
      if (!Array.isArray(args[i])) {
        if (args[i] == null) {
          return null;
        } else if (args[i].error) {
          return args[i].error;
        }
      } else {
        if ((res = this.checkErrors(args[i])) != null) {
          return res;
        }
      }
    }
    return null;
  }

  /**
   * @link IdentifiedPackUtils#areAllLoaded
   * Dela to samy, ale umi pracovat i s ne [], lisi se jen vstupni parameter.
   * @param args pole identified packu nebo identified pack samotny,
   * taky muze bt polem premichanych mezi sebou [] a samotnych instanci
   */
  public static allLoadsSucceed(...args) {
    for (let i = 0; i < args.length; i++) {
      if (args[i] == null) {
        return false;
      } else if (!Array.isArray(args[i])) {
        if (args[i].loading === true) {
          return false;
        }
      } else {
        if (this.areAllLoaded(args[i]) === false) {
          return false;
        }
      }
    }
    return true;
  }
}
