import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {Script} from '../models';
import {
  DeleteScriptSuccess,
  DiffScript,
  DiffScriptError,
  DiffScriptSuccess,
  LoadScriptByCodeSuccess,
  LoadScriptById,
  LoadScriptByIdError,
  LoadScriptByIdSuccess,
  UpsertScript,
  UpsertScriptError,
  UpsertScriptSuccess,
} from '../actions';

export interface ScriptState extends EntityState<IdentifiedPack<Script>> {}

export const scriptAdapter: EntityAdapter<IdentifiedPack<Script>> =
  createEntityAdapter<IdentifiedPack<Script>>({});
const scriptInitialState: ScriptState = scriptAdapter.getInitialState({});

export const reducer = createReducer(
  scriptInitialState,

  on(LoadScriptById, (state, {id}) =>
    scriptAdapter.upsertOne(
      {
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadScriptByIdSuccess, LoadScriptByCodeSuccess, (state, {script}) =>
    scriptAdapter.upsertOne(
      {
        ...state.entities[script.id],
        id: script.id,
        data: script,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadScriptByIdError, (state, {id, error}) =>
    scriptAdapter.upsertOne(
      {
        id: id,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertScript, (state, {script}) =>
    scriptAdapter.upsertOne(
      {
        ...state.entities[script.id],
        id: script.id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(UpsertScriptSuccess, (state, {script}) =>
    scriptAdapter.upsertOne(
      {
        id: script.id,
        data: script,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(UpsertScriptError, (state, {script, error}) =>
    scriptAdapter.upsertOne(
      {
        ...state.entities[script.id],
        id: script.id,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DeleteScriptSuccess, (state, script) => ({
    ...scriptAdapter.removeOne(script.id, state),
  })),

  on(DiffScript, (state, {diffEntities}) =>
    scriptAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffScriptSuccess, (state, {scripts}) =>
    scriptAdapter.upsertMany(
      scripts.map((script) => {
        return {
          id: script.id,
          changes: {
            id: script.id,
            data: script,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffScriptError, (state, {diffEntities, error}) =>
    scriptAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
