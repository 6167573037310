import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {HotkeyAction} from '../models/hotkey-action.model';
import {DisableSelector, HotkeysService} from '../services';

@Directive({
  selector: '[dtlAllowedHotkeys]',
})
export class AllowedHotkeysDirective {
  @Input() dtlAllowedHotkeys: string[];
}

@Directive({
  selector: '[dtlHotkey]',
})
export class HotkeyDirective implements OnChanges, OnDestroy {
  @Input() dtlHotkey: string | string[];
  @Input() dtlHoteyAction: HotkeyAction = HotkeyAction.Click;
  @Input() dtlHotkeyLocal = true;
  @Input() dtlHotkeyText = null;
  @Input() dtlCustomHotkeyText = null;
  @Input() dtlHotkeyGroup = null;
  @Input() dtlHotkeyDisabledIn: DisableSelector[] = ['input', 'textarea'];
  @Input() dtlHotkeyPreventDefault = true;
  @Input() dtlHotkeyStopProgation = true;
  @Output() dtlHotkeyCustomAction: EventEmitter<any> = new EventEmitter<any>();

  _sub: Subscription;

  constructor(
    private hotkeyService: HotkeysService,
    private el: ElementRef,
    @Optional() private allowedHotkeysDirective?: AllowedHotkeysDirective,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dtlHotkey?.currentValue != null) {
      if (
        Array.isArray(changes.dtlHotkey.currentValue) &&
        changes.dtlHotkey.currentValue.length == 0
      ) {
        return;
      }
      this.rebind(changes.dtlHotkey.currentValue);
    }
  }

  private rebind(hotkey) {
    if (this._sub) {
      this._sub.unsubscribe();
    }
    this._sub = this.hotkeyService
      .when(hotkey, {
        preventDefault: this.dtlHotkeyPreventDefault,
        stopPropagation: this.dtlHotkeyStopProgation,
        element: this.dtlHotkeyLocal ? this.el.nativeElement : undefined,
        customHotkeyText: this.dtlCustomHotkeyText,
        disableIn: this.dtlHotkeyDisabledIn,
        text: this.dtlHotkeyText,
        group: this.dtlHotkeyGroup,
        allowedHotkeys: this.allowedHotkeysDirective?.dtlAllowedHotkeys ?? [],
      })
      .subscribe((x) => {
        if (this.el?.nativeElement?.disabled === true) {
          return;
        }
        return this.getCallback()(x);
      });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  private getCallback() {
    if (this.dtlHotkeyCustomAction.observers.length > 0) {
      return (e: Mousetrap.ExtendedKeyboardEvent) => {
        this.dtlHotkeyCustomAction.emit(e);
      };
    } else {
      switch (this.dtlHoteyAction) {
        case HotkeyAction.Focus:
          return this.doFocus.bind(this);
        case HotkeyAction.Click:
          return this.doClick.bind(this);
        case HotkeyAction.Submit:
          return this.doSubmit.bind(this);
        default:
          return this.doClick.bind(this);
      }
    }
  }

  private doFocus(_: Mousetrap.ExtendedKeyboardEvent): void {
    this.el.nativeElement.focus();
  }

  private doClick(_: Mousetrap.ExtendedKeyboardEvent): void {
    this.el.nativeElement.click();
  }

  private doSubmit(_: Mousetrap.ExtendedKeyboardEvent): void {
    (this.el.nativeElement as HTMLFormElement).submit();
  }
}

@NgModule({
  declarations: [HotkeyDirective, AllowedHotkeysDirective],
  exports: [HotkeyDirective, AllowedHotkeysDirective],
})
export class HotkeyDirectiveModule {}
