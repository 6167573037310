import {of} from 'rxjs';
import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/dms/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';
import {controlsCommentNewConfiguration} from './tsm-controls-configuration';

export const dmsPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'dms',
      loadChildren: () =>
        import('@tsm/dms/components').then((x) => x.DmsComponentsModule),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/dms/widgets').then((x) => x.DmsWidgetsModule),
      definitions: [
        {
          selector: 'tsm-comment-type-filter',
          name: translationPlugin.dmsPlugins.filters.tsmCommentTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmCommentTypeFilterDescription,
        },
        {
          selector: 'tsm-comment-user-type-filter',
          name: translationPlugin.dmsPlugins.filters.tsmCommentUserTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmCommentUserTypeFilterDescription,
        },
        {
          selector: 'tsm-delivery-item-type-filter',
          name: translationPlugin.dmsPlugins.filters.tsmDeliveryItemTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmDeliveryItemTypeFilterDescription,
        },
        {
          selector: 'tsm-formatter-document-size-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmFormatterDocumentSizeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmFormatterDocumentSizeFilterDescription,
        },
        {
          selector: 'tsm-formatter-document-template-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmFormatterDocumentTemplateFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmFormatterDocumentTemplateFilterDescription,
        },
        {
          selector: 'tsm-formatter-document-type-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmFormatterDocumentTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmFormatterDocumentTypeFilterDescription,
        },
        {
          selector: 'tsm-formatter-input-type-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmFormatterInputTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmFormatterInputTypeFilterDescription,
        },
        {
          selector: 'tsm-formatter-output-type-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmFormatterOutputTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmFormatterOutputTypeFilterDescription,
        },
        {
          selector: 'tsm-kafka-dlq-status-filter',
          name: translationPlugin.dmsPlugins.filters.tsmKafkaDlqStatusFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmKafkaDlqStatusFilterDescription,
        },
        {
          selector: 'tsm-notification-rule-event-type-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmNotificationRuleEventTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmNotificationRuleEventTypeFilterDescription,
        },
        {
          selector: 'tsm-notification-rule-filter',
          name: translationPlugin.dmsPlugins.filters.tsmNotificationRuleFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmNotificationRuleFilterDescription,
        },
        {
          selector: 'tsm-notification-status-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmNotificationStatusFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmNotificationStatusFilterDescription,
        },
        {
          selector: 'tsm-notification-template-category-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmNotificationTemplateCategoryFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmNotificationTemplateCategoryFilterDescription,
        },
        {
          selector: 'tsm-notification-template-status-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmNotificationTemplateStatusFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmNotificationTemplateStatusFilterDescription,
        },
        {
          selector: 'tsm-notification-type-filter',
          name: translationPlugin.dmsPlugins.filters.tsmNotificationTypeFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmNotificationTypeFilterDescription,
        },
        {
          selector: 'tsm-user-usergroup-ref-filter',
          name: translationPlugin.dmsPlugins.filters.tsmUserUsergroupRefFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmUserUsergroupRefFilterDescription,
        },
        {
          selector: 'tsm-user-usergroup-ref-multiselect-filter',
          name: translationPlugin.dmsPlugins.filters
            .tsmUserUsergroupRefMultiselectFilter,
          description:
            translationPlugin.dmsPlugins.filters
              .tsmUserUsergroupRefMultiselectFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/dms/widgets').then((x) => x.DmsWidgetsModule),
      definitions: [
        {
          selector: 'containsKeyValue',
          name: translationPlugin.dmsPlugins.pipes.containsKeyValue,
          description:
            translationPlugin.dmsPlugins.pipes.containsKeyValueDescription,
        },
        {
          selector: 'countAttachments',
          name: translationPlugin.dmsPlugins.pipes.countAttachments,
          description:
            translationPlugin.dmsPlugins.pipes.countAttachmentsDescription,
        },
        {
          selector: 'entityNameLoader',
          name: translationPlugin.dmsPlugins.pipes.entityNameLoader,
          description:
            translationPlugin.dmsPlugins.pipes.entityNameLoaderDescription,
        },
        {
          selector: 'formatterDocumentTemplateLoader',
          name: translationPlugin.dmsPlugins.pipes
            .formatterDocumentTemplateLoader,
          description:
            translationPlugin.dmsPlugins.pipes
              .formatterDocumentTemplateLoaderDescription,
        },
        {
          selector: 'formatterTemplateLoader',
          name: translationPlugin.dmsPlugins.pipes.formatterTemplateLoader,
          description:
            translationPlugin.dmsPlugins.pipes
              .formatterTemplateLoaderDescription,
        },
        {
          selector: 'minutesToText',
          name: translationPlugin.dmsPlugins.pipes.minutesToText,
          description:
            translationPlugin.dmsPlugins.pipes.minutesToTextDescription,
        },
        {
          selector: 'notificationAddress',
          name: translationPlugin.dmsPlugins.pipes.notificationAddress,
          description:
            translationPlugin.dmsPlugins.pipes.notificationAddressDescription,
        },
        {
          selector: 'notificationCustomerById',
          name: translationPlugin.dmsPlugins.pipes.notificationCustomerById,
          description:
            translationPlugin.dmsPlugins.pipes
              .notificationCustomerByIdDescription,
        },
        {
          selector: 'notificationRelatedEntityById',
          name: translationPlugin.dmsPlugins.pipes
            .notificationRelatedEntityById,
          description:
            translationPlugin.dmsPlugins.pipes
              .notificationRelatedEntityByIdDescription,
        },
        {
          selector: 'notificationRuleCode',
          name: translationPlugin.dmsPlugins.pipes.notificationRuleCode,
          description:
            translationPlugin.dmsPlugins.pipes.notificationRuleCodeDescription,
        },
        {
          selector: 'notificationTemplateByCode',
          name: translationPlugin.dmsPlugins.pipes.notificationTemplateByCode,
          description:
            translationPlugin.dmsPlugins.pipes
              .notificationTemplateByCodeDescription,
        },
        {
          selector: 'notificationTemplateLoader',
          name: translationPlugin.dmsPlugins.pipes.notificationTemplateLoader,
          description:
            translationPlugin.dmsPlugins.pipes
              .notificationTemplateLoaderDescription,
        },
        {
          selector: 'safeHtml',
          name: translationPlugin.dmsPlugins.pipes.safeHtml,
          description: translationPlugin.dmsPlugins.pipes.safeHtmlDescription,
        },
        {
          selector: 'notificationToOwnerType',
          name: translationPlugin.dmsPlugins.pipes.notificationTo,
          description:
            translationPlugin.dmsPlugins.pipes.notificationToDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/dms/widgets').then((x) => x.DmsWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/dms/widgets/new-comment').then(
              (x) => x.NewCommentComponent,
            ),
          selector: 'dtl-new-comment',
        },
        {
          loadComponent: () =>
            import('@tsm/dms/widgets/new-comment').then(
              (x) => x.NewCommentWindowsComponent,
            ),
          selector: 'dtl-new-comment-windows',
        },
        {
          loadComponent: () =>
            import('@tsm/dms/widgets/communication-sla').then(
              (x) => x.CommunicationSlaComponent,
            ),
          selector: 'tsm-communication-sla',
          designers: [
            {
              loadComponent: () =>
                import('@tsm/dms/widgets/communication-sla-editor').then(
                  (x) => x.CommunicationSlaEditorComponent,
                ),
              editorSelector: 'tsm-communication-sla-editor',
              widgetOrLayout: 'layout',
              name: translationShared.shared.emailsSla,
              categoryId: DesignerCategoryEnum.Dms,
              description:
                translationShared.shared.emailsNotificationsDescription,
              icon: 'p-icon-assignment',
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-communication-sla',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/dms/widgets/communication-session').then(
              (x) => x.CommunicationSessionComponent,
            ),
          selector: 'tsm-communication-session',
          designers: [
            {
              loadComponent: () =>
                import('@tsm/dms/widgets/communication-session-editor').then(
                  (x) => x.CommunicationSessionEditorComponent,
                ),
              editorSelector: 'tsm-communication-session-editor',
              widgetOrLayout: 'widget',
              formField: 'id',
              name: translationShared.shared.session,
              categoryId: DesignerCategoryEnum.Dms,
              description: translationShared.shared.session,
              icon: 'p-icon-assignment',
              value: {
                type: 'string',
                widget: {
                  type: 'tsm-communication-session',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/dms/widgets/communication-widget').then(
              (x) => x.CommunicationWidgetComponent,
            ),
          selector: 'tsm-communication-widget',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import('@tsm/dms/widgets/communication-widget-editor').then(
                  (x) => x.CommunicationWidgetEditorComponent,
                ),
              editorSelector: 'tsm-communication-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.emailCommunicationPanel
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.emailCommunicationPanel
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.panel,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-communication-widget',
                },
              },
            },
          ],
        },
        {selector: 'tsm-worklog-add-dialog'},
        {
          selector: 'tsm-worklog-duration',
          designers: [
            {
              editorSelector: 'tsm-worklog-duration-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              name: translationShared.shared.worklogDurationInput,
              description: '',
              icon: 'p-icon-timer',
              value: {
                type: 'layout',
                title: translationShared.shared.worklogDuration,
                widget: {
                  type: 'tsm-worklog-duration',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-sharing-detail',
        },
        {
          selector: 'tsm-notification-tab',
          designers: [
            {
              editorSelector: 'tsm-notification-tab-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.notificationList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.notificationList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.notificationList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-notification-tab',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-comments-widget',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema?.widget?.readonly,
            };
          },
          designers: [
            {
              editorSelector: 'dtl-comments-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.commentsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.commentsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.commentsBookmark
                    .inWidgetTitle,
                widget: {
                  type: 'dtl-comments-widget',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-worklog-estimate',
          designers: [
            {
              editorSelector: 'tsm-worklog-estimate-editor',
              widgetOrLayout: 'widget',
              formField: 'worklogEstimate',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.shared.workEstimate,
              icon: 'tsm-icon-work-time-2',
              name: translationShared.shared.workEstimate,
              value: {
                type: 'layout',
                title: translationShared.shared.worklog,
                widget: {
                  type: 'tsm-worklog-estimate',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-worklogs-widget',
          designers: [
            {
              editorSelector: 'dtl-worklogs-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.worklogsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.worklogsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.worklogsBookmark
                    .inWidgetTitle,
                widget: {
                  type: 'dtl-worklogs-widget',
                },
              },
            },
          ],
        },
        {selector: 'tsm-attachment-galleria'},
        {
          selector: 'dtl-attachments-widget',
          designers: [
            {
              editorSelector: 'dtl-attachments-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.attachmentsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.attachmentsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.attachmentsBookmark
                    .inWidgetTitle,
                widget: {
                  type: 'dtl-attachments-widget',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-signature-pad',
          loadComponent: () =>
            import('@tsm/dms/widgets/signature-pad').then(
              (x) => x.SignaturePadComponent,
            ),
        },
        {
          selector: 'tsm-signature-pad-input',
          loadComponent: () =>
            import(
              '@tsm/dms/widgets/signature-pad-input/signature-pad-input'
            ).then((x) => x.SignaturePadInputComponent),
          designers: [
            {
              editorSelector: 'tsm-signature-pad-input-editor',
              loadComponent: () =>
                import(
                  '@tsm/dms/widgets/signature-pad-input/signature-pad-input-editor'
                ).then((x) => x.SignaturePadInputEditorComponent),
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description: translationShared.shared.signatureDescription,
              icon: 'tsm-icon-signature',
              formField: 'signaturePad',
              name: translationShared.shared.signature,
              group: GroupPosition.Tertiary,
              order: 3,
              value: {
                type: 'string',
                title: translationShared.shared.signature,
                widget: {
                  type: 'tsm-signature-pad-input',
                  persistent: 'Never',
                  notitle: true,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-sharing-ref-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-sharing-ref-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.shared.participantRef,
              icon: 'tsm-icon-user-group',
              formField: 'sharingRef',
              name: translationShared.shared.participantRef,
              value: {
                type: 'object',
                title: translationShared.shared.participantRef,
                widget: {
                  type: 'tsm-sharing-ref-lov',
                },
                config: {
                  validatorRequired: true,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-comment-type-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              title: fluentSchema.title,
              readonly: fluentSchema.widget?.readonly,
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-comment-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'commentType',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.commentTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.commentTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.commentTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-comment-type-lov',
                },
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-notification-template-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-notification-template-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'notificationTemplate',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.notificationTemplateSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.notificationTemplateSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.notificationTemplateSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-notification-template-lov',
                },
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-formatter-document-template-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-formatter-document-template-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'formatterDocumentTemplate',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets
                .formaterDocumentTemplateSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.formaterDocumentTemplateSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 8,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets
                    .formaterDocumentTemplateSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-formatter-document-template-lov',
                },
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-worklog-list',
          designers: [
            {
              editorSelector: 'tsm-worklog-list-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              name: translationShared.shared.widgets.worklogList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.worklogList.widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.worklogList.inWidgetTitle,
                widget: {
                  type: 'tsm-worklog-list',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-worklog-stopwatch-start-button',
          designers: [
            {
              editorSelector: 'tsm-worklog-stopwatch-start-button-editor',
              widgetOrLayout: 'layout',
              name: translationShared.shared.worklogStopwatchStartButton,
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.shared.worklogStopwatchStartButton,
              icon: 'tsm-icon-work-time-2',
              value: {
                title: translationShared.shared.worklogStopwatchStartButton,
                widget: {
                  type: 'tsm-worklog-stopwatch-start-button',
                },
                type: 'layout',
                config: {
                  ownerId: '',
                  ownerType: '',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-gallery',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-gallery-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Advanced,
              description: translationShared.shared.galleryTooltip,
              icon: 'p-icon-image',
              name: translationShared.shared.gallery,
              backgroundColorOverride: DesignerDataColorOverride.blue,
              group: GroupPosition.Tertiary,
              order: 1,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-gallery',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-file-upload-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'dtl-file-upload-widget-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description: translationShared.shared.fileUploadTooltip,
              icon: 'p-icon-image',
              formField: 'file',
              name: translationShared.shared.fileUpload,
              backgroundColorOverride: DesignerDataColorOverride.blue,
              group: GroupPosition.Tertiary,
              order: 1,
              value: {
                type: 'object',
                widget: {
                  type: 'dtl-file-upload-widget',
                },
                config: {
                  attachmentTypeAllowEmpty: true,
                  allowDefaultDialog: true,
                  canDownloadFile: true,
                  acceptDocumentTypes: '*',
                  maxFileSize: 5120,
                },
              },
            },
          ],
        },
      ],
    },
  ],
  layoutControls: [controlsCommentNewConfiguration],
  entityTypes: [
    {
      entityType: 'AttachmentType',
      url: (entityId) => {
        return of('/dms/attachment-types/' + entityId);
      },
    },
    {
      entityType: 'CommentType',
      url: (entityId) => {
        return of('/dms/comment-types/' + entityId);
      },
    },
    ,
    {
      entityType: 'DeliveryTime',
      url: (entityId) => {
        return of('/dms/delivery-time');
      },
    },
  ],
};
