import {ModuleWithProviders, NgModule} from '@angular/core';
import {COMPONENTS, EXPORTS, IMPORTS, PIPES} from './index';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {
  BooleanIconPipe,
  BooleanTranslatePipe,
  ClipboardPipe,
  DataTagsArrayPipe,
  DateFormatPipe,
  DatesDiffPipe,
  DescriptionPipe,
  EmptyOrNotEmptyTranslatePipe,
  EnumToValuePipe,
  ExternalLinkPipe,
  FilterPipe,
  IconImgPipe,
  ModuleIconPipe,
  NumberToFixedPipe,
  ObjectPathGetPipe,
  RemoveHtmlElementsPipe,
  SafeHtmlPipe,
  SafePipe,
  TranslatePipe,
  TrimTextPipe,
  ColorPipe,
  ConstructCharsForNewPipe,
  DecimalNumberFormatPipe,
  DomPurifyPipe,
  FindPipe,
  FlattenExceptKeysPipe,
  JoinPipe,
  MapPipe,
  SafeEvalPipe,
  SanitizeNullPipe,
  ShallowMergePipe,
} from './pipes';
import {HasWidgets} from '@tsm/framework/plugin';
import {FormatPhonePipe} from '@tsm/framework/root';
import {
  DataTagsComponent,
  PreviewButtonComponent,
  PreviewDropdownComponent,
} from './components';

@NgModule({
  imports: [...IMPORTS],
  declarations: [...PIPES, ...COMPONENTS],
  exports: [...EXPORTS, ...PIPES, ...COMPONENTS],
  providers: [...PIPES],
})
export class SharedModule implements HasWidgets {
  typeMap = {
    booleanIcon: BooleanIconPipe,
    booleanTranslate: BooleanTranslatePipe,
    clipboard: ClipboardPipe,
    colorPipe: ColorPipe,
    constructCharsForNew: ConstructCharsForNewPipe,
    dataTagsArray: DataTagsArrayPipe,
    dateFormatPipe: DateFormatPipe,
    datesDiff: DatesDiffPipe,
    decimalNumberFormat: DecimalNumberFormatPipe,
    description: DescriptionPipe,
    domPurify: DomPurifyPipe,
    emptyOrNotEmptyTranslate: EmptyOrNotEmptyTranslatePipe,
    enumToValue: EnumToValuePipe,
    externalLink: ExternalLinkPipe,
    filter: FilterPipe,
    find: FindPipe,
    flattenExceptKeys: FlattenExceptKeysPipe,
    iconImg: IconImgPipe,
    join: JoinPipe,
    map: MapPipe,
    moduleIcon: ModuleIconPipe,
    formatPhone: FormatPhonePipe,
    numberToFixed: NumberToFixedPipe,
    objectPathGet: ObjectPathGetPipe,
    removeHtmlElements: RemoveHtmlElementsPipe,
    safeEval: SafeEvalPipe,
    safeHtml: SafeHtmlPipe,
    safe: SafePipe,
    sanitizeNull: SanitizeNullPipe,
    shallowMerge: ShallowMergePipe,
    translatePipe: TranslatePipe,
    trimTextPipe: TrimTextPipe,
    'tsm-data-tags': DataTagsComponent,
    'tsm-preview-dropdown': PreviewDropdownComponent,
    'tsm-preview-button': PreviewButtonComponent,
    currency: CurrencyPipe,
    number: DecimalPipe,
  };

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
        DateFormatPipe,
        CurrencyPipe,
        DataTagsArrayPipe,
        SafeHtmlPipe,
        SafePipe,
        ModuleIconPipe,
        DatesDiffPipe,
        FilterPipe,
        ObjectPathGetPipe,
        DescriptionPipe,
        FormatPhonePipe,
        NumberToFixedPipe,
        RemoveHtmlElementsPipe,
        TranslatePipe,
        TrimTextPipe,
      ],
    };
  }
}
