import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'translatePipe',
  pure: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(localization: string): any {
    return this.translocoService.langChanges$.pipe(
      map((lang: string) =>
        this.translocoService.translate(localization, {lang}),
      ),
    );
  }
}
