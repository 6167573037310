export const cs = {
  userManagementPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmPrivTypeFilter: 'tSM Priv Type Filter',
      tsmPrivTypeFilterDescription:
        'Poskytuje komponentu filtru pro výběr typů oprávnění. ' +
        'Zobrazuje pohled s možností vícenásobného výběru, filtrování a řazení. ' +
        'Dynamicky načítá typy oprávnění ze serveru a mapuje je na volitelné možnosti. ' +
        'Používá strukturované rozvržení tabulky s konfigurovatelnými sloupci a ' +
        'nastaveními zobrazení. Aplikuje a odesílá vybrané hodnoty při potvrzení.',
      tsmRoleFilter: 'tSM Role Filter',
      tsmRoleFilterDescription:
        'Implementuje filtr pro výběr rolí s přehledným datovým zobrazením. ' +
        'Podporuje vícenásobný výběr a dynamické filtrování uživatelských rolí. ' +
        'Načítá data rolí ze serveru a mapuje je na volitelné možnosti. ' +
        'Umožňuje konfiguraci identifikátoru pro filtrování. ' +
        'Odesílá vybrané role a jejich zobrazované hodnoty při potvrzení.',
      tsmUserFilter: 'tSM User Filter',
      tsmUserFilterDescription:
        'Implementuje filtr pro výběr uživatelů s podporou filtrování podle aktuálního ' +
        'a nedefinovaných uživatelů. Zobrazuje strukturovaný datový pohled s možností ' +
        'vícenásobného výběru. Umožňuje uživatelům vyhledávat a vybírat konkrétní uživatele. ' +
        'Obsahuje zaškrtávací pole pro výběr aktuálního nebo nedefinovaných uživatelů. ' +
        'Automaticky aktualizuje vybrané hodnoty na základě uživatelských akcí. ' +
        'Odesílá vybraná uživatelská data a zobrazované hodnoty při potvrzení.',
      tsmUserGroupFilter: 'tSM User Group Filter',
      tsmUserGroupFilterDescription:
        'Implementuje filtr pro výběr uživatelských skupin s možností vícenásobného výběru. ' +
        'Umožňuje filtrování podle aktuálních uživatelských skupin a nedefinovaných skupin. ' +
        'Zobrazuje strukturovaný datový pohled s možností vyhledávání a výběru. Automaticky ' +
        'aktualizuje vybrané hodnoty na základě interakcí s formulářem. Podporuje ' +
        'filtrování podle konkrétních vlastností uživatelských skupin, pokud je poskytnut ' +
        'kontext. Odesílá vybrané uživatelské skupiny a zobrazované hodnoty při potvrzení.',
      tsmUserGroupTypeFilter: 'tSM User Group Type Filter',
      tsmUserGroupTypeFilterDescription:
        'Implementuje filtr pro výběr typu uživatelské skupiny s podporou vícenásobného ' +
        'výběru. Zobrazuje strukturovaný datový pohled s možností vyhledávání a filtrování. ' +
        'Umožňuje filtrování podle kódů a názvů typů uživatelských skupin s fulltextovým ' +
        'vyhledáváním. Řadí výsledky vzestupně podle kódu. ' +
        'Odesílá vybrané typy uživatelských skupin a zobrazované hodnoty při potvrzení.',
      tsmUserStatusFilter: 'tSM User Status Filter',
      tsmUserStatusFilterDescription:
        'Implementuje filtr pro výběr uživatelského stavu s podporou vícenásobného výběru. ' +
        'Zobrazuje strukturovaný datový pohled s volitelnými uživatelskými stavy. ' +
        'Dynamicky načítá možnosti stavů a překládá jejich názvy. ' +
        'Umožňuje výběr všech stavů nebo jednotlivých pomocí zaškrtávacích políček. ' +
        'Odesílá vybrané stavy a jejich zobrazované hodnoty při potvrzení.',
      tsmUserTypeEnumFilter: 'tSM User Type Enum Filter',
      tsmUserTypeEnumFilterDescription:
        'Implementuje filtr s možností vícenásobného výběru pro výčet typů uživatelů. ' +
        'Zobrazuje volitelné typy uživatelů v strukturovaném datovém pohledu. ' +
        'Dynamicky načítá možnosti uživatelských typů a překládá jejich názvy. ' +
        'Podporuje výběr všech nebo jednotlivých typů uživatelů pomocí zaškrtávacích políček. ' +
        'Odesílá vybrané typy uživatelů a jejich zobrazované hodnoty při potvrzení.',
      tsmUserTypeFilter: 'tSM User Type Filter',
      tsmUserTypeFilterDescription:
        'Implementuje filtr s možností vícenásobného výběru pro typy uživatelů. ' +
        'Zobrazuje dostupné typy uživatelů ve strukturovaném datovém pohledu. ' +
        'Umožňuje filtrování podle kódu nebo názvu s podporou fulltextového vyhledávání. ' +
        'Uživatelé mohou vybrat více typů uživatelů a dynamicky spravovat výběry. ' +
        'Načítá data o typech uživatelů z API a aktualizuje stav filtru při výběru.',
    },
    pipes: {
      checkOnline: 'Check Online',
      checkOnlineDescription:
        'Ověřuje, zda je uživatel nebo systém online na základě poslední aktualizace. ' +
        'Načítá online stav a určuje, zda byla poslední aktualizace v rámci pěti minut.',
      privLoaderByCode: 'Priv Loader By Code',
      privLoaderByCodeDescription:
        'Načítá a získává podrobnosti oprávnění podle zadaného kódu. ' +
        'Načítá oprávnění z úložiště a poskytuje konkrétní pole dat.',
      privLoader: 'Priv Loader',
      privLoaderDescription:
        'Získává podrobnosti oprávnění podle zadaného ID. ' +
        'Načítá oprávnění z úložiště a poskytuje konkrétní pole dat.',
      roleCode: 'Role Code',
      roleCodeDescription:
        'Získává podrobnosti role podle zadaného kódu. ' +
        'Načítá roli z úložiště a poskytuje konkrétní pole dat.',
      role: 'Role',
      roleDescription:
        'Získává podrobnosti role podle zadaného ID. ' +
        'Načítá roli z úložiště a poskytuje konkrétní pole dat.',
      userByCode: 'User By Code',
      userByCodeDescription:
        'Získává podrobnosti uživatele na základě zadaného kódu. ' +
        'Načítá uživatele z úložiště a vrací specifikované pole dat.',
      userGroupInfoCode: 'User Group Info Code',
      userGroupInfoCodeDescription:
        'Získává informace o uživatelské skupině na základě zadaného kódu. ' +
        'Načítá podrobnosti skupiny z úložiště a vrací specifikované pole dat.',
      userGroupInfo: 'User Group Info',
      userGroupInfoDescription:
        'Získává podrobnosti o uživatelské skupině podle zadaného ID. ' +
        'Načítá data skupiny z úložiště a vrací konkrétní hodnotu pole.',
      userGroupTypeCode: 'User Group Type Code',
      userGroupTypeCodeDescription:
        'Získává podrobnosti o typu uživatelské skupiny podle zadaného kódu. ' +
        'Načítá data typu z úložiště a vrací konkrétní hodnotu pole.',
      userGroupType: 'User Group Type',
      userGroupTypeDescription:
        'Získává podrobnosti o typu uživatelské skupiny podle zadaného ID. ' +
        'Načítá data typu z úložiště a vrací konkrétní hodnotu pole.',
      userGroupsInfoCode: 'User Groups Info Code',
      userGroupsInfoCodeDescription:
        'Získává podrobnosti o více uživatelských skupinách pomocí pole kódů. ' +
        'Načítá data uživatelských skupin z úložiště a vrací vybrané hodnoty polí.',
      userGroupsInfo: 'User Groups Info',
      userGroupsInfoDescription:
        'Získává informace o více uživatelských skupinách pomocí pole ID. ' +
        'Načítá data uživatelských skupin z úložiště a vrací zadané hodnoty polí.',
      userImageComponent: 'User Image Component',
      userImageComponentDescription:
        'Filtruje unikátní hodnoty ze vstupního pole a vrací odlišné komponenty obrázků ' +
        'uživatele. Zajišťuje, že duplicitní položky jsou odstraněny z výsledných dat.',
      userImage: 'User Image',
      userImageDescription:
        'Načte a zobrazí profilový obrázek uživatele z úložiště. Podporuje načítání ' +
        'více uživatelských obrázků a jejich zobrazení jako avatarů. Pokud obrázek ' +
        'není k dispozici, vygeneruje zástupné znaky na základě iniciál uživatele.',
      userParameter: 'User Parameter',
      userParameterDescription:
        'Převede klíč uživatelského parametru na přeložený popis. Získává ' +
        'lokalizovanou hodnotu pomocí TranslocoService na základě zadaného klíče.',
      userTypeIcon: 'User Type Icon',
      userTypeIconDescription:
        'Načte a zobrazí typ uživatele s příslušnou ikonou. Pokud je ikona ' +
        'k dispozici, zobrazí se vedle názvu typu uživatele.',
      userType: 'User Type',
      userTypeDescription:
        'Načte a zobrazí informace o typu uživatele z úložiště. Vrací ' +
        'konkrétní pole nebo celý objekt dat podle požadavku.',
      userUserGroupRole: 'User User Group Role',
      userUserGroupRoleDescription:
        'Převádí pole rolí uživatele ve skupině na přeložené popisky. ' +
        'Využívá překlady ze sdíleného i18n modulu a uživatelského slovníku.',
      userUserGroup: 'User User Group',
      userUserGroupDescription:
        'Získává a zobrazuje název uživatele nebo uživatelské skupiny. ' +
        'Dynamicky určuje typ a vybírá odpovídající data ze store.',
      user: 'User',
      userDescription:
        'Načítá a zobrazuje detaily uživatele podle zadaného ID. ' +
        'Používá store k načtení dat a vrací specifikovanou hodnotu.',
    },
  },
};
