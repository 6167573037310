import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {Sharing} from '../models';

export const LoadSharing = createAction(
  '[Sharing] Load data',
  props<{ownerId: string; ownerType: string}>(),
);
export const LoadSharingSuccess = createAction(
  '[Sharing] Load data Success',
  props<{sharing: Sharing[]}>(),
);
export const LoadSharingError = createAction(
  '[Sharing] Load data ERROR',
  props<{error: any}>(),
);

export const UpsertSharing = createAction(
  '[Sharing] Upsert Sharing',
  props<{sharing: Sharing}>(),
);
export const UpsertSharingSuccess = createAction(
  '[Sharing] Upsert Sharing Success',
  props<{sharing: Sharing}>(),
);
export const UpsertSharingError = createAction(
  '[Sharing] Upsert Sharing Error',
  props<TsmError>(),
);

export const DeleteSharing = createAction(
  '[Sharing] Delete Sharing',
  props<{id: string}>(),
);
export const DeleteSharingSuccess = createAction(
  '[Sharing] Delete Sharing Success',
  props<{id: string}>(),
);
export const DeleteSharingError = createAction(
  '[Sharing] Delete Sharing Error',
  props<TsmError>(),
);
