import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {ListingType} from '../models';

export const LoadListingType = createAction('[ListingType] Load ListingType');
export const LoadListingTypeSuccess = createAction(
  '[ListingType] Load ListingType Success',
  props<{
    listingTypes: ListingType[];
  }>(),
);
export const LoadListingTypeError = createAction(
  '[ListingType] Load ListingType Error',
  props<TsmError>(),
);

export const LoadListingTypeById = createAction(
  '[ListingType] Load LoadListingTypeById',
  props<{id: string}>(),
);
export const LoadListingTypeByIdSuccess = createAction(
  '[ListingType] Load LoadListingTypeById Success',
  props<{
    listingType: ListingType;
  }>(),
);
export const LoadListingTypeByIdError = createAction(
  '[ListingType] Load LoadListingTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadListingTypeByCode = createAction(
  '[ListingType] Load LoadListingTypeByCode',
  props<{
    code: string;
  }>(),
);
export const LoadListingTypeByCodeSuccess = createAction(
  '[ListingType] Load LoadListingTypeByCode Success',
  props<{
    listingType: ListingType;
  }>(),
);
export const LoadListingTypeByCodeError = createAction(
  '[ListingType] Load LoadListingTypeByCode Error',
  props<{
    code: string;
    error: TsmError;
  }>(),
);

export const UpsertListingType = createAction(
  '[ListingType] Upsert ListingType',
  props<{
    listingType: ListingType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertListingTypeSuccess = createAction(
  '[ListingType] Upsert ListingType Success',
  props<{
    listingType: ListingType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertListingTypeError = createAction(
  '[ListingType] Upsert ListingType Error',
  props<TsmError>(),
);

export const DeleteListingType = createAction(
  '[ListingType] Delete ListingType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteListingTypeSuccess = createAction(
  '[ListingType] Delete ListingType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteListingTypeError = createAction(
  '[ListingType] Delete ListingType Error',
  props<TsmError>(),
);

export const DiffListingType = createAction(
  '[ListingType] Diff ListingType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffListingTypeSuccess = createAction(
  '[ListingType] Diff ListingType Success',
  props<{
    listingTypes: ListingType[];
    listingId: string;
  }>(),
);
export const DiffListingTypeError = createAction(
  '[ListingType] Diff ListingType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
