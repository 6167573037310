import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {TsmModule} from '../models';
import {
  DeleteTsmModuleSuccess,
  DiffTsmModule,
  DiffTsmModuleError,
  DiffTsmModuleSuccess,
  LoadTsmModuleByCode,
  LoadTsmModuleByCodeError,
  LoadTsmModuleByCodeSuccess,
  LoadTsmModuleById,
  LoadTsmModuleByIdError,
  LoadTsmModuleByIdSuccess,
  LoadTsmModulesByTypeSuccess,
  LoadTsmModuleSuccess,
  UpsertTsmModule,
  UpsertTsmModuleSuccess,
} from '../actions/tsm-module.actions';

export type TsmModuleState = EntityState<IdentifiedPack<TsmModule>>;

export const tsmModuleAdapter: EntityAdapter<IdentifiedPack<TsmModule>> =
  createEntityAdapter<IdentifiedPack<TsmModule>>({});

const initialState: TsmModuleState = tsmModuleAdapter.getInitialState({});

export const tsmModuleReducer = createReducer(
  initialState,

  on(LoadTsmModuleSuccess, (state, {tsmModules}) => ({
    ...tsmModuleAdapter.addMany(
      tsmModules.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadTsmModulesByTypeSuccess, (state, {tsmModules}) => ({
    ...tsmModuleAdapter.addMany(
      tsmModules.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadTsmModuleById, (state, {id}) =>
    tsmModuleAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadTsmModuleByIdSuccess, (state, {tsmModule}) =>
    tsmModuleAdapter.upsertOne(
      {
        id: tsmModule.id,
        data: tsmModule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadTsmModuleByIdError, (state, {id, error}) =>
    tsmModuleAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadTsmModuleByCode, (state, {code}) => {
    const found = Object.keys(state.entities)
      .map((k) => state.entities[k])
      .find((x) => x.data?.code === code);
    return tsmModuleAdapter.upsertOne(
      {
        ...(found ?? {}),
        id: found?.id,
        loading: true,
        error: null,
      },
      state,
    );
  }),

  on(LoadTsmModuleByCodeSuccess, (state, {tsmModule}) => {
    const newState = tsmModuleAdapter.removeOne(tsmModule.code, state);
    const found = Object.keys(state.entities)
      .map((k) => state.entities[k])
      .find((x) => x.data?.code === tsmModule.code);

    return tsmModuleAdapter.upsertOne(
      {
        ...(found ?? {}),
        id: tsmModule.id,
        data: tsmModule,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadTsmModuleByCodeError, (state, {code, error}) =>
    tsmModuleAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertTsmModule, (state, {tsmModule}) =>
    tsmModuleAdapter.updateOne(
      {
        id: tsmModule.id,
        changes: {
          ...state.entities[tsmModule.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertTsmModuleSuccess, (state, {tsmModule}) =>
    tsmModuleAdapter.upsertOne(
      {
        id: tsmModule.id,
        data: tsmModule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteTsmModuleSuccess, (state, tsmModule) =>
    tsmModuleAdapter.removeOne(tsmModule.id, state),
  ),

  on(DiffTsmModule, (state, {diffEntities}) =>
    tsmModuleAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffTsmModuleSuccess, (state, {tsmModules}) =>
    tsmModuleAdapter.upsertMany(
      tsmModules.map((tsmModule) => {
        return {
          id: tsmModule.id,
          data: tsmModule,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffTsmModuleError, (state, {diffEntities, error}) =>
    tsmModuleAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
