import {Observable} from 'rxjs';
import {exhaustMap, startWith} from 'rxjs/operators';

export function exhaustMapWith<TSource, TSecond>(
  second: (input: TSource) => Observable<TSecond>,
  startWithValue: string,
) {
  return function efficiencyAtItsFinest(source: Observable<TSource>) {
    return Observable.create((subscriber) => {
      const subscription = source
        .pipe(exhaustMap(second), startWith(startWithValue))
        .subscribe(
          (value) => {
            try {
              subscriber.next(value);
            } catch (err) {
              subscriber.error(err);
            }
          },
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        );
      return subscription;
    }) as Observable<TSecond>;
  };
}
