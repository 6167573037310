/**
 * Pomocne funkce pro usnadneni
 * praci s uzivatelskymi privilegii
 */
import {Inject, Injectable} from '@angular/core';
import {distinctArrays} from '@tsm/framework/functions';
import {SecurityService} from '../services';
import {hasPriv, hasPrivStrict, SecurityModuleOptions} from '../models';
import {SECURITY_MODULE_OPTIONS} from '../di';

@Injectable({
  providedIn: 'root',
})
export class PrivHelperService {
  constructor(
    private securityService: SecurityService,
    @Inject(SECURITY_MODULE_OPTIONS)
    private moduleOptions: SecurityModuleOptions,
  ) {}

  /**
   * Returns true, if any authority is a prefix of required claim.
   */
  public isCurrentUserHasRole(tokenPrivileges: string): boolean {
    return this.hasPrivilege(tokenPrivileges, this.getPrivileges());
  }

  public isCurrentUserHasRoleStrict(tokenPrivileges: string): boolean {
    return this.hasPrivilegeStrict(tokenPrivileges, this.getPrivileges());
  }

  /**
   * Returns true, if any authority is a prefix of required claim.
   */
  public isCurrentUserHasAnyOfRoles(tokenPrivileges: string[]): boolean {
    return (
      tokenPrivileges.filter((rn) =>
        this.hasPrivilege(rn, this.getPrivileges()),
      ).length !== 0
    );
  }

  public isCurrentUserHasAnyRoleStrict(tokenPrivileges: string[]): boolean {
    return (
      tokenPrivileges.filter((rn) =>
        this.hasPrivilegeStrict(rn, this.getPrivileges()),
      ).length !== 0
    );
  }

  public getPrivileges(): string[] {
    return this.securityService.getClaims();
  }

  public hasPrivilege(tokenPriv: string, privileges: string[]): boolean {
    const newPrivileges = distinctArrays(
      null,
      privileges,
      this.moduleOptions.overridePrivileges,
    );
    return hasPriv(tokenPriv, newPrivileges);
  }

  /**
   * Striktne hlidam opravneni (nepouziova se "startsWith", ale "===")
   */
  public hasPrivilegeStrict(tokenPriv: string, privileges: string[]): boolean {
    const newPrivileges = distinctArrays(
      null,
      privileges,
      this.moduleOptions.overridePrivileges,
    );
    return hasPrivStrict(tokenPriv, newPrivileges);
  }
}
