import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Host,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  NG_VALUE_ACCESSOR,
  type ControlValueAccessor,
} from '@angular/forms';
import {of, Subscription} from 'rxjs';
import {TsmFormControl, TypedTsmFormControl} from '@tsm/framework/forms';
import {FormInplaceComponent} from '../form-inplace/form-inplace.component';
import {FormComponent} from '../form.component';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {v4 as getUuid} from 'uuid';
import {
  LayoutedComponent,
  LayoutIdDirective,
} from '@tsm/framework/root/layout-id';
import {
  FLUENT_FORMS_INPUT_COMPONENT,
  FluentFormsInputOptions,
} from '@tsm/framework/root/di';

@Component({
  selector: 'tsm-form-field',
  templateUrl: './form-field.component.html',
  // styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldComponent),
      multi: true,
    },
    {
      provide: LayoutedComponent,
      useExisting: forwardRef(() => FormFieldComponent),
    },
    {
      provide: FLUENT_FORMS_INPUT_COMPONENT,
      useExisting: forwardRef(() => FormFieldComponent),
    },
  ],
})
export class FormFieldComponent
  extends LayoutedComponent
  implements
    FluentFormsInputOptions<FormFieldComponent>,
    ControlValueAccessor,
    OnInit,
    OnDestroy
{
  @ViewChild('defaultInputTemplate', {static: true}) defaultInputTemplate;

  @ViewChild('defaultTextareaTemplate', {static: true}) defaultTextareaTemplate;

  @ViewChild('defaultDateTemplate', {static: true}) defaultDateTemplate;

  @ViewChild('defaultDatetimeTemplate', {static: true}) defaultDatetimeTemplate;

  @ViewChild('defaultTimeTemplate', {static: true}) defaultTimeTemplate;

  @ViewChild('defaultMonthYearTemplate', {static: true})
  defaultMonthYearTemplate;

  @ViewChild('defaultCheckboxTemplate', {static: true}) defaultCheckboxTemplate;

  @ViewChild('defaultTriStateCheckboxTemplate', {static: true})
  defaultTriStateCheckboxTemplate;

  @ViewChild('defaultTiptapTemplate', {static: true}) defaultTiptapTemplate;

  @ViewChild('defaultTiptapSmallTemplate', {static: true})
  defaultTiptapSmallTemplate;

  @ViewChild('defaultTiptapLightTemplate', {static: true})
  defaultTiptapLightTemplate;

  @ViewChild('defaultNumberTemplate', {static: true}) defaultNumberTemplate;

  @ViewChild('defaultNumberSortTemplate', {static: true})
  defaultNumberSortTemplate;

  @ViewChild('defaultPintTemplate', {static: true}) defaultPintTemplate;

  @ViewChild('defaultIntTemplate', {static: true}) defaultIntTemplate;

  @ViewChild('defaultNumTemplate', {static: true}) defaultNumTemplate;

  @ViewChild('defaultRegexTemplate', {static: true}) defaultRegexTemplate;

  @ViewChild('defaultRatingTemplate', {static: true}) defaultRatingTemplate;

  @ViewChild('defaultPasswordTemplate', {static: true}) defaultPasswordTemplate;

  @ViewChild('defaultLinkTemplate', {static: true}) defaultLinkTemplate;

  @ViewChild('defaultMaskTemplate', {static: true}) defaultMaskTemplate;

  @ViewChild('defaultPhoneTemplate', {static: true}) defaultPhoneTemplate;

  @ViewChild('defaultSwitchTemplate', {static: true}) defaultSwitchTemplate;

  @ViewChild('defaultXmlTemplate', {static: true}) defaultXmlTemplate;

  @ViewChild('defaultMarkdownTemplate', {static: true}) defaultMarkdownTemplate;

  @ViewChild('defaultJsonTemplate', {static: true}) defaultJsonTemplate;

  @ViewChild('defaultJsonObjectTemplate', {static: true})
  defaultJsonObjectTemplate;

  @ViewChild('defaultJavaTemplate', {static: true}) defaultJavaTemplate;

  @ViewChild('defaultSqlTemplate', {static: true}) defaultSqlTemplate;

  @ViewChild('defaultHtmlTemplate', {static: true}) defaultHtmlTemplate;

  @ViewChild('defaultRadioButtonTemplate', {static: true})
  defaultRadioButtonTemplate;

  @ViewChild('defaultPreviewDropdownTemplate', {static: true})
  defaultPreviewDropdownTemplate;

  @ViewChild('defaultIconPickerTemplate', {static: true})
  defaultIconPickerTemplate;

  @ContentChild(TemplateRef, {read: TemplateRef}) contentedTemplate;

  @ContentChild(FLUENT_FORMS_INPUT_COMPONENT, {
    read: FLUENT_FORMS_INPUT_COMPONENT,
  })
  childFluentFormsInputComponent: FluentFormsInputOptions;

  @Input() generatedLabelId: string = getUuid();

  @Input() ownerId: string;

  @Input() ownerType: string;

  // tiptap
  @Input() hashtagOption: 'tickets' | 'script' = 'tickets';

  @Input() suggestionScriptCode: string;

  @Input() suggestionScriptData: any;

  @Input() clickScriptCode: string;

  @Input() clickScriptData: any;

  @Input() moreSelector: string;

  @Input() moreInputs: any = {};

  @Input() disableTableStyle = false;

  @Input() hideMenuItemIframe = false;

  @Input() hideMenuItemDiagram = false;

  @Input() hideMenuItemImage = false;

  @Input() hideMenuItemAttachments = false;

  @Input() hideMenuItemInsertCode = false;

  @Input() hideMenuItemSourceCode = false;

  @Input() pastePlainImages = false;

  /**
   * Funguje pro type JSON ( monaco-editor )
   */
  @Input() wrapLines = false;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, jakou část layoutu má prvek zabírat
   */
  @Input() cols: number;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, o jakou část layoutu má být prvek odsunut (zleva)
   */
  @Input() offset: number;

  /**
   * Atribut pro připojení rozšiřující CSS třídy
   */
  @Input() class = '';

  /**
   * Input pro připojení rozšiřujících CSS tříd, výhradně pro zanořenou komponentu samozneho inputu, napr: 'dtl-form-input-text'
   */
  @Input() formFieldTemplateExtendClass = '';

  /**
   * Input pro připojení rozšiřujících CSS tříd, výhradně pro zanořenou komponentu 'dtl-form-field-container'
   * @see libs/shared/src/lib/components/form/form-field/form-field.component.html:76
   * @example Any existing CSS classes, separated by a space
   */
  @Input() formFieldContainerExtendClass = '';

  /**
   * Input pro připojení rozšiřujících CSS tříd, výhradně pro zanořenou komponentu 'dtl-form-field-label'
   * @see libs/shared/src/lib/components/form/form-field/form-field.component.html
   * @example Any existing CSS classes, separated by a space
   */
  @Input() formFieldLabelExtendClass = '';

  /**
   * Input pro připojení rozšiřujících CSS stylů, výhradně pro zanořenou komponentu 'dtl-form-field-container'
   * @see libs/shared/src/lib/components/form/form-field/form-field.component.html:76
   * @example {'any-property': 'value', 'padding-left': '.325em'}
   */
  @Input() formFieldContainerExtendStyle = {};

  /**
   * Přepíná styl a pozici zobrazení labelu a inputu
   */
  @Input() labelPosition: 'left' | 'top' | 'right' = 'left';

  @Input() label: string;

  @Input() leftIcon: string;

  @Input() rightIcon: string;

  @Input() noneText: string;

  /**
   * default: input
   * more types: password/date/datetime/time/monthYear/textarea/checkbox/richtext/richtext-inline/number/pint/num/sort/rating
   * number - možné zadat desetinná i záporná čísla, čísla s exponentem e, hodnota 0 se po uložení zobrazuje
   * pint - možné zadat pouze nezáporná celá čísla, hodnota 0 se po uložení nezobrazuje
   * num - možné zadat desetinná čísla, hodnota 0 se po uložení nezobrazuje
   * int - možné zadat pouze nezáporná celá čísla, hodnota 0 se po uložení zobrazuje, číslo je omezeno na 10 míst.
   */

  @Input() type:
    | 'text'
    | 'link'
    | 'password'
    | 'date'
    | 'datetime'
    | 'time'
    | 'monthYear'
    | 'textarea'
    | 'checkbox'
    | 'triStateCheckbox'
    | 'radiobutton'
    | 'richtext'
    | 'richtext-inline'
    | 'tiptap'
    | 'tiptap-large'
    | 'tiptap-light'
    | 'number'
    | 'pint'
    | 'num'
    | 'sort'
    | 'rating'
    | 'int'
    | 'mask'
    | 'phone'
    | 'switch'
    | 'xml'
    | 'markdown'
    | 'json'
    | 'json-object'
    | 'java'
    | 'html'
    | 'sql'
    | 'previewDropdown'
    | 'regex'
    | 'icon-picker'
    | 'tags';

  @Input() formControl: TsmFormControl | TypedTsmFormControl;

  @Input() placeholder: string;

  @Input() formControlName: string;

  @Input() helpMessage: any;

  @Input() helpMessageStatic: string;

  @Input() maxWidthRemTooltip = 28;

  @Input() tooltip: any;

  @Input() textareaHeight = 75;

  @Input() minDate: Date;

  @Input() maxDate: Date;

  @Input() min: number;

  @Input() max: number;

  @Input() minlength = 0;

  @Input() maxlength = 10;

  @Input() fractionDigits = 0;

  @Input() step: number;

  @Input() stepShowButtons = true;

  @Input() mask: string;

  @Input() maskPlaceholder: string;

  @Input() leftSwitchLabel: string;

  @Input() rightSwitchLabel: string;

  @Input() required: boolean = null;

  @Input() roundEndDate: boolean;

  @Input() codeEditorHeight: string = null;

  @Input() codeEditorResizable = true;

  @Input() tabindex: number;

  @Input() showPickDateTimeButton = true;

  @Input() pickDateTimeButtonLabel = 'shared.calendar.pick';

  @Input() showMilliseconds?: boolean = false;

  @Input() showTime?: boolean = true;

  @Input() showSeconds?: boolean = false;

  @Input() yearRange: string = '1900:' + (new Date().getFullYear() + 30);

  @Input() calendarShowIcon = true;

  @Input() calendarShowCloseIcon = true;

  @Input() setAutofocus: boolean;

  @Input() excelStyleErrors = null;

  @Input() radioButtonValue: string = null;

  @Input() radioButtonGroup: string = null;

  @Input() updateIfDirty = false;

  @Input() regex: RegExp = null;

  @Input() showRemoveButton = false;

  @Input() showLangButton = false;

  @Input() customButtonText: string;

  @Input() customButtonIcon = 'tsm-icon-management-1';

  @Output() iconSelected: EventEmitter<any> = new EventEmitter();

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @Output() changeFocus: EventEmitter<boolean> = new EventEmitter();

  @Output() hashtagAttachmentIds = new EventEmitter<string[]>();

  @Output() hashtagSelectionChanged = new EventEmitter<{
    value: any;
    index: number;
  }>();

  @Output() removeClick = new EventEmitter<void>();

  @Output() showLangClick = new EventEmitter<void>();

  useFormControl = true;

  context = {
    $implicit: null,
    readonly: false,
    uuid: this.generatedLabelId,
  };

  subscriptions: Subscription[] = [];
  control: TsmFormControl | TypedTsmFormControl;

  excelStyleErrorsClicked = false;
  excelStyleErrorsAppParams = (window as any)?.app?.params?.excelStyleErrors;

  constructor(
    @Host() @Optional() private formInplaceComponent: FormInplaceComponent,
    @Host() @Optional() private formComponent: FormComponent,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    protected cdr: ChangeDetectorRef,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {
    super(layoutIdDirective);
    if (formInplaceComponent) {
      this.labelPosition = formInplaceComponent.labelPosition;
    }

    if (formComponent) {
      this.labelPosition = formComponent.labelPosition;
    }
  }

  @HostBinding('class')
  get styleClass(): string {
    let result = 'field col-12' + ' ' + this.class;
    if (this.cols) {
      result = this.offset
        ? 'field col-12 md:col-' +
          this.cols +
          ' md:col-offset-' +
          this.offset +
          ' ' +
          this.class
        : 'field col-12 md:col-' + this.cols + ' ' + this.class;
    }
    return result;
  }

  get template(): TemplateRef<any> {
    if (this.contentedTemplate) {
      return this.contentedTemplate;
    } else {
      let component: TemplateRef<any>;
      switch (this.type) {
        case 'textarea': {
          component = this.defaultTextareaTemplate;
          break;
        }
        case 'date': {
          component = this.defaultDateTemplate;
          break;
        }
        case 'datetime': {
          component = this.defaultDatetimeTemplate;
          break;
        }
        case 'time': {
          component = this.defaultTimeTemplate;
          break;
        }
        case 'monthYear': {
          component = this.defaultMonthYearTemplate;
          break;
        }
        case 'checkbox': {
          component = this.defaultCheckboxTemplate;
          break;
        }
        case 'triStateCheckbox': {
          component = this.defaultTriStateCheckboxTemplate;
          break;
        }
        case 'radiobutton': {
          component = this.defaultRadioButtonTemplate;
          break;
        }
        case 'richtext': {
          component = this.defaultTiptapSmallTemplate;
          break;
        }
        case 'richtext-inline': {
          component = this.defaultTiptapSmallTemplate;
          break;
        }
        case 'tiptap': {
          component = this.defaultTiptapSmallTemplate;
          break;
        }
        case 'tiptap-light': {
          component = this.defaultTiptapLightTemplate;
          break;
        }
        case 'tiptap-large': {
          component = this.defaultTiptapTemplate;
          break;
        }
        case 'number': {
          component = this.defaultNumberTemplate;
          break;
        }
        case 'sort': {
          component = this.defaultNumberSortTemplate;
          break;
        }
        case 'pint': {
          component = this.defaultPintTemplate;
          break;
        }
        case 'int': {
          component = this.defaultIntTemplate;
          break;
        }
        case 'num': {
          component = this.defaultNumTemplate;
          break;
        }
        case 'regex': {
          component = this.defaultRegexTemplate;
          break;
        }
        case 'rating': {
          component = this.defaultRatingTemplate;
          break;
        }
        case 'password': {
          component = this.defaultPasswordTemplate;
          break;
        }
        case 'link': {
          component = this.defaultLinkTemplate;
          break;
        }
        case 'mask': {
          component = this.defaultMaskTemplate;
          break;
        }
        case 'phone': {
          component = this.defaultPhoneTemplate;
          break;
        }
        case 'switch': {
          component = this.defaultSwitchTemplate;
          break;
        }
        case 'xml': {
          component = this.defaultXmlTemplate;
          break;
        }
        case 'markdown': {
          component = this.defaultMarkdownTemplate;
          break;
        }
        case 'json': {
          component = this.defaultJsonTemplate;
          break;
        }
        case 'json-object': {
          component = this.defaultJsonObjectTemplate;
          break;
        }
        case 'java': {
          component = this.defaultJavaTemplate;
          break;
        }
        case 'sql': {
          component = this.defaultSqlTemplate;
          break;
        }
        case 'html': {
          component = this.defaultHtmlTemplate;
          break;
        }
        case 'previewDropdown': {
          component = this.defaultPreviewDropdownTemplate;
          break;
        }
        case 'icon-picker': {
          component = this.defaultIconPickerTemplate;
          break;
        }
        default: {
          component = this.defaultInputTemplate;
          break;
        }
      }
      return component;
    }
  }

  // magic?
  @Input() set tsmFormControl(
    tsmFormControl: TsmFormControl | TypedTsmFormControl,
  ) {
    this.useFormControl = false;
    this.formControl = tsmFormControl;
  }

  @Input()
  set value(value: any) {
    // slouzi primarne pro Forms (komponentu tsm-control) k nastaveni defaultni hodnoty
    if (
      this.control &&
      (this.control.value === null || this.control.value === undefined)
    ) {
      this.control.setValue(value);
    }
  }

  @Input()
  set readonly(value: boolean) {
    this.context = {...this.context, readonly: value};
  }

  onChange: (any) => any;
  onTouched: (any) => any;

  setInput(key: string, value: any) {
    if (this.childFluentFormsInputComponent) {
      this.childFluentFormsInputComponent.setInput(key, value);
    }
  }

  containerClicked() {
    this.excelStyleErrorsClicked = !this.excelStyleErrorsClicked;
  }

  ngOnInit() {
    if (this.formControl) {
      this.control = this.formControl;
    } else {
      if (this.controlContainer) {
        if (this.formControlName) {
          this.control = this.controlContainer.control.get(
            this.formControlName,
          ) as TsmFormControl;
        } else {
          //console.warn('Missing FormControlName directive from host element of the component');
        }
      } else {
        // console.warn('Can\'t find parent FormGroup directive');
      }
    }

    if (this.control) {
      this.subscriptions.push(
        (this.control.touchedChanges || of(this.control.touched)).subscribe(
          (_) => {
            this.cdr.markForCheck();
            if (['number', 'pint', 'sort', 'num', 'int'].includes(this.type)) {
              this.subscriptions.push(
                this.control.touchedChanges.subscribe(() => {
                  if (
                    this.control.value !== null &&
                    this.control.value !== undefined &&
                    this.control.value !== ''
                  ) {
                    switch (this.type) {
                      case 'number':
                        this.control.patchValue(
                          parseFloat(this.control.value),
                          {emitEvent: false},
                        );
                        break;
                      case 'pint':
                        this.control.patchValue(parseInt(this.control.value), {
                          emitEvent: false,
                        });
                        break;
                      case 'int':
                        this.control.patchValue(parseInt(this.control.value), {
                          emitEvent: false,
                        });
                        break;
                      case 'num':
                        this.control.patchValue(
                          parseFloat(this.control.value),
                          {emitEvent: false},
                        );
                        break;
                      case 'sort':
                        this.control.patchValue(parseInt(this.control.value), {
                          emitEvent: false,
                        });
                        break;
                      default:
                        break;
                    }
                  } else {
                    this.control.patchValue(null, {emitEvent: false});
                  }
                }),
              );
            }
          },
        ),
      );
      this.subscriptions.push(
        this.control.valueChanges
          .pipe(debounceTime(250), distinctUntilChanged())
          .subscribe((value) => {
            this.valueChange.emit(value);
          }),
      );
    } else {
      // console.warn('Missing declaration FormControl in host element of the component');
    }
  }

  changeValue(value) {
    this.context = {...this.context, $implicit: value};
    this.onChange(value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.context = {...this.context, $implicit: value};
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
