import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteAllUserUserGroups,
  DeleteAllUserUserGroupsError,
  DeleteAllUserUserGroupsSuccess,
  DeleteUserUserGroup,
  DeleteUserUserGroupError,
  DeleteUserUserGroupSuccess,
  LoadUserUserGroupById,
  LoadUserUserGroupByIdError,
  LoadUserUserGroupByIdSuccess,
  LoadUserUserGroups,
  LoadUserUserGroupsByGroupCode,
  LoadUserUserGroupsByGroupCodeError,
  LoadUserUserGroupsByGroupCodeSuccess,
  LoadUserUserGroupsByGroupId,
  LoadUserUserGroupsByGroupIds,
  LoadUserUserGroupsByGroupIdSuccess,
  LoadUserUserGroupsByUserId,
  LoadUserUserGroupsByUserIdError,
  LoadUserUserGroupsByUserIdSuccess,
  LoadUserUserGroupsError,
  LoadUserUserGroupsSuccess,
  SaveAllUserUserGroups,
  SaveAllUserUserGroupsError,
  SaveAllUserUserGroupsSuccess,
  UpsertUserUserGroup,
  UpsertUserUserGroupError,
  UpsertUserUserGroupSuccess,
} from '../actions';
import {EntityIdentif, UserUserGroup} from '../model';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {translation} from '../i18n';
import {exhaustBy} from '@tsm/framework/root';

@Injectable()
export class UserUserGroupEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<UserUserGroup, UserUserGroup>,
    private store: Store<any>,
    private ts: ToastService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserUserGroups),
      exhaustMap(() =>
        this.commonApiService
          .getAll(EntityIdentif.USER_USER_GROUP)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserUserGroupsSuccess({entities: env.data})
                : LoadUserUserGroupsError(env.error),
            ),
          ),
      ),
    ),
  );

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserUserGroupsByUserId),
      exhaustBy(
        ({userId}) => userId,
        ({userId}) => {
          return this.commonApiService
            .getAllFilterable(
              EntityIdentif.USER_USER_GROUP,
              'user.id__eq=' + userId,
            )
            .pipe(
              map((env) => {
                return env.success
                  ? LoadUserUserGroupsByUserIdSuccess({entities: env.data})
                  : LoadUserUserGroupsByUserIdError({error: env.error});
              }),
            );
        },
      ),
    ),
  );

  loadByGroupId = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserUserGroupsByGroupId),
      exhaustMap(({groupId}) =>
        this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_USER_GROUP,
            'userGroup.id__eq=' + groupId,
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadUserUserGroupsByGroupIdSuccess({entities: env.data})
                : LoadUserUserGroupsByUserIdError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByGroupIds = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserUserGroupsByGroupIds),
      exhaustMap(({groupIds}) =>
        this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_USER_GROUP,
            'userGroup.id__in=' + groupIds.join(','),
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadUserUserGroupsByGroupIdSuccess({entities: env.data})
                : LoadUserUserGroupsByUserIdError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserUserGroupById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(EntityIdentif.USER_USER_GROUP, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserUserGroupByIdSuccess({userUserGroup: env.data})
                : LoadUserUserGroupByIdError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByGroupCode = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserUserGroupsByGroupCode),
      exhaustMap(({code}) =>
        this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_USER_GROUP,
            'userGroup.code__eq=' + code,
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadUserUserGroupsByGroupCodeSuccess({entities: env.data})
                : LoadUserUserGroupsByGroupCodeError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserUserGroup),
      concatMap(({userUserGroup, listingId}) =>
        this.commonApiService
          .upsertEntity(
            EntityIdentif.USER_USER_GROUP,
            userUserGroup,
            userUserGroup.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                return UpsertUserUserGroupSuccess({
                  userUserGroup: env.data,
                  listingId: listingId,
                });
              } else {
                return UpsertUserUserGroupError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAllUserUserGroups),
      concatMap(({entities, listingId}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.USER_USER_GROUP, entities)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .userUserGroupAddSuccess,
                  ToastSeverity.SUCCESS,
                );
                return SaveAllUserUserGroupsSuccess({
                  entities: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .userUserGroupAddFailure,
                );
                return SaveAllUserUserGroupsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserUserGroup),
      concatMap(({id, listingId}) =>
        this.commonApiService
          .deleteEntity(EntityIdentif.USER_USER_GROUP, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .userUserGroupDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteUserUserGroupSuccess({
                  id: id,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .userUserGroupDeleteFailure,
                );
                return DeleteUserUserGroupError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllUserUserGroups),
      exhaustMap(({ids, listingId}) =>
        this.commonApiService
          .deleteAllEntities(EntityIdentif.USER_USER_GROUP, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .userUserGroupDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteAllUserUserGroupsSuccess({
                  ids: ids,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .userUserGroupDeleteFailure,
                );
                return DeleteAllUserUserGroupsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeleteUserUserGroupSuccess,
        UpsertUserUserGroupSuccess,
        SaveAllUserUserGroupsSuccess,
        DeleteAllUserUserGroupsSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
