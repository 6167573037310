import {Pipe, untracked} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectRegisterValueById} from '../selectors';
import {LoadRegisterValueById} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'registerValueById',
  pure: true,
})
export class RegisterValueByIdPipe extends AbstractCachedLoaderPipe {
  private _cache = new Map<string, any>();

  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(id: string, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectRegisterValueById(id)),
      filter((item) => {
        return item && !!item.data;
      }),
      map((it) => {
        if (!it || !it.data) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .select(selectRegisterValueById(this._id))
      .subscribe((x) => {
        if (!x && !this._cache.has(this._id)) {
          untracked(() =>
            this.store.dispatch(LoadRegisterValueById({id: this._id})),
          );
          // resi problem cyklickym volam za predpokladu, ze backend nevrati data (vyprsela platnost, smazana hodnota, atd.)
          // muze to zpusobovat mozna problem s refreshem u spravnych hodnot ve storu (spravne by se to melo cele prepsat ve storu)
          this._cache.set(this._id, x);
        }
      });
  }
}
