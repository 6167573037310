export const cs = {
  wfmPlannerPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmTaskCurrentStateFilter: 'tSM Task Current State Filter',
      tsmTaskCurrentStateFilterDescription:
        'Implementuje filtr s možností vícenásobného výběru pro stavy úkolů. ' +
        'Zobrazuje dostupné stavy úkolů v rozbalovacím seznamu s překlady. ' +
        'Umožňuje uživatelům vybrat více stavů a dynamicky aktualizovat hodnotu filtru. ' +
        'Načítá předdefinované stavy úkolů a mapuje je na volitelné možnosti. ' +
        'Formátuje vybrané stavy pro zobrazení pomocí překladů před aplikací filtru.',
      tsmTaskHardRuleFilter: 'tSM Task Hard Rule Filter',
      tsmTaskHardRuleFilterDescription:
        'Implementuje filtr s možností vícenásobného výběru pro omezení úkolů. ' +
        'Zobrazuje dostupná tvrdá pravidla pomocí rozbalovacího seznamu s překlady. ' +
        'Umožňuje uživatelům vybrat více omezení a dynamicky aktualizovat hodnotu filtru. ' +
        'Načítá předdefinovaná omezení úkolů a mapuje je na volitelné možnosti. ' +
        'Formátuje vybraná omezení pro zobrazení pomocí překladů před aplikací filtru.',
      tsmTaskPartitionFilter: 'tSM Task Partition Filter',
      tsmTaskPartitionFilterDescription:
        'Implementuje filtr s možností vícenásobného výběru pro rozdělení úkolů. ' +
        'Zobrazuje dostupné možnosti rozdělení pomocí rozbalovacího seznamu. ' +
        'Umožňuje uživatelům vybrat více rozdělení a dynamicky aktualizovat hodnotu filtru. ' +
        'Načítá předdefinovaná rozdělení úkolů a mapuje je na volitelné možnosti. ' +
        'Formátuje vybraná rozdělení jako řetězec oddělený čárkami před aplikací filtru.',
    },
    pipes: {
      formatTimeType: 'Format Time Type',
      formatTimeTypeDescription:
        'Formátuje časové intervaly na základě zadaného typu času, jako je den, týden, pracovní týden, ' +
        'měsíc nebo vlastní interval, pomocí lokalizovaných formátů dat. Zajišťuje správné formátování ' +
        'podle aktuálního jazykového nastavení a příslušného časového rozsahu.',
    },
  },
};
