import * as objectPath from 'object-path';

/**
 * Funce slouzi pro razeni dle vice atributu
 * @param array - pole pro serazeni
 * @param sortMeta - pole pro zadani razeni 1 (ascending-vzestupné) / -1 (descending-sestupny)
 */
export function multiSort<T = any>(
  array: Array<T>,
  sortMeta: Array<{field: string; order: 1 | -1}>,
): Array<T> {
  return array.sort((data1, data2) => {
    return multiSortField(data1, data2, sortMeta, 0);
  });
}

const multiSortField = (data1, data2, sortMeta, index) => {
  const value1 = objectPath.get(data1, sortMeta[index].field);
  const value2 = objectPath.get(data2, sortMeta[index].field);
  let result = null;

  if (value1 == null && value2 != null) result = -1;
  else if (value1 != null && value2 == null) result = 1;
  else if (value1 == null && value2 == null) result = 0;
  else if (typeof value1 == 'string' || value1 instanceof String) {
    if (value1.localeCompare && value1 != value2) {
      return sortMeta[index].order * value1.localeCompare(value2);
    }
  } else {
    result = value1 < value2 ? -1 : 1;
  }

  if (value1 == value2) {
    return sortMeta.length - 1 > index
      ? multiSortField(data1, data2, sortMeta, index + 1)
      : 0;
  }

  return sortMeta[index].order * result;
};
