import {InjectionToken} from '@angular/core';
import {DtlDataSource} from '@tsm/framework/datasource';
import {DtlFilters, DtlPipes, DtlWidgets, TsmEntityTypes} from './models';
import {DtlMenuItem} from './menu-item';

export const WIDGET: InjectionToken<DtlWidgets[]> = new InjectionToken<
  DtlWidgets[]
>('@DTL/WIDGET');

export const DTL_DATASOURCES: InjectionToken<
  {
    dataSource: DtlDataSource;
    initialValue: any;
    name: string;
  }[]
> = new InjectionToken<
  {dataSource: DtlDataSource; initialValue: any; name: string}[]
>('@DTL/DATASOURCE');

export const FILTER: InjectionToken<DtlFilters[]> = new InjectionToken<
  DtlFilters[]
>('@DTL/FILTER');

export const PIPE: InjectionToken<DtlPipes[]> = new InjectionToken<DtlPipes[]>(
  '@DTL/PIPE',
);

export const MENU: InjectionToken<DtlMenuItem[]> = new InjectionToken<
  DtlMenuItem[]
>('@DTL/TSM/MENU');

export const LAYOUT_CONTROLS: InjectionToken<DtlMenuItem[]> =
  new InjectionToken<DtlMenuItem[]>('@DTL/TSM/LAYOUT_CONTROLS');

export const ENTITY_TYPE: InjectionToken<TsmEntityTypes[]> = new InjectionToken<
  TsmEntityTypes[]
>('@DTL/TSM/ENTITY_TYPE');
