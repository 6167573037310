import {createAction, props} from '@ngrx/store';
import {UserParameter, UserParameterName} from '../model';
import {TsmError} from '@tsm/framework/http';

export const LoadUserParametersByUserId = createAction(
  '[UserParameter] Load ParametersByUserId',
  props<{
    userId: string;
  }>(),
);
export const LoadUserParametersByUserIdSuccess = createAction(
  '[UserParameter] Load ParametersByUserId Success',
  props<{
    entities: UserParameter[];
    userId?: string;
  }>(),
);
export const LoadUserParametersByUserIdError = createAction(
  '[UserParameter] Load ParametersByUserId Error',
  props<{
    userId: string;
    error: TsmError;
  }>(),
);

export const LoadUserParameterByUserIdAndParameterName = createAction(
  '[UserParameter] Load ParameterByUserIdAndName',
  props<{
    userId: string;
    name: UserParameterName;
  }>(),
);
export const LoadUserParameterByUserIdAndParameterNameSuccess = createAction(
  '[UserParameter] Load ParameterByUserIdAndName Success',
  props<{
    entity: UserParameter;
    userId: string;
    name: UserParameterName;
  }>(),
);
export const LoadUserParameterByUserIdAndParameterNameError = createAction(
  '[UserParameter] Load ParameterByUserIdAndName Error',
  props<{
    userId: string;
    name: UserParameterName;
    error: TsmError;
  }>(),
);

export const LoadUserParameterByUserIdsAndParameterName = createAction(
  '[UserParameter] Load ParameterByUserIdsAndName',
  props<{
    userIds: string[];
    name: UserParameterName;
  }>(),
);
export const LoadUserParameterByUserIdsAndParameterNameSuccess = createAction(
  '[UserParameter] Load ParameterByUserIdsAndName Success',
  props<{
    entities: UserParameter[];
    userIds: string[];
    name: UserParameterName;
  }>(),
);
export const LoadUserParameterByUserIdsAndParameterNameError = createAction(
  '[UserParameter] Load ParameterByUserIdsAndName Error',
  props<{
    userIds: string[];
    name: UserParameterName;
    error: TsmError;
  }>(),
);

export const UpsertUserParameterByUserIdAndName = createAction(
  '[UserParameter] Upsert User',
  props<{
    userId: string;
    name: UserParameterName;
    value: any;
    listingId?: string;
  }>(),
);
export const UpsertUserParameterByUserIdAndNameSuccess = createAction(
  '[UserParameter] Upsert ParameterByUserIdAndName Success',
  props<{
    entity: UserParameter;
    userId: string;
    name: UserParameterName;
    listingId?: string;
  }>(),
);
export const UpsertUserParameterByUserIdAndNameError = createAction(
  '[UserParameter] Upsert ParameterByUserIdAndName Error',
  props<{
    userId: string;
    name: UserParameterName;
    error: TsmError;
  }>(),
);

export const DeleteUserParameterByUserIdAndName = createAction(
  '[UserParameter] Delete User',
  props<{
    userId: string;
    name: UserParameterName;
    listingId?: string;
  }>(),
);
export const DeleteUserParameterByUserIdAndNameSuccess = createAction(
  '[UserParameter] Delete ParameterByUserIdAndName Success',
  props<{
    entity: UserParameter;
    userId: string;
    name: UserParameterName;
    listingId?: string;
  }>(),
);
export const DeleteUserParameterByUserIdAndNameError = createAction(
  '[UserParameter] Delete ParameterByUserIdAndName Error',
  props<{
    userId: string;
    name: UserParameterName;
    error: TsmError;
  }>(),
);
