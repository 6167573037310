export const cs = {
  characteristicsPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmSchemaLocationFilter: 'tSM Schema Location Filter',
      tsmSchemaLocationFilterDescription:
        'Filtruje lokality na základě schématických definic. ' +
        'Podporuje vícenásobný výběr a nabízí možnosti filtrování ' +
        'pro hierarchické struktury lokalit.',
    },
    pipes: {
      characteristicsCode: 'Characteristics Code',
      characteristicsCodeDescription:
        'Načítá a zpracovává data charakteristik na základě konkrétního kódu. Odesílá akce pro načtení ' +
        'charakteristik, pokud již nejsou dostupné ve store. Extrahuje a vrací specifické pole první ' +
        'charakteristiky nebo celý objekt podle určeného pole. Zajišťuje správné zacházení s případy, kdy ' +
        'nejsou nalezena žádná data.',
      characteristicsByCodes: 'Characteristics By Codes',
      characteristicsByCodesDescription:
        'Načítá a zpracovává data charakteristik na základě více kódů. Odesílá akci pro načtení ' +
        'charakteristik, pokud data nejsou již dostupná ve store. Vrací specifická pole nebo celý soubor ' +
        'charakteristik pro zadané kódy. Zajišťuje hladké zpracování případů s chybějícími daty a integraci ' +
        'se store.',
      characteristics: 'Characteristics',
      characteristicsDescription:
        'Načítá a zpracovává data charakteristik podle zadaného ID. Odesílá akci pro načtení ' +
        'charakteristik, pokud data nejsou již dostupná ve store. Vrací specifická pole nebo celý objekt dat ' +
        'na základě zadaného parametru pole. Zajišťuje hladkou integraci se store a zpracování případů, kdy ' +
        'data nejsou dostupná.',
    },
  },
};
