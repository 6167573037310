import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {type MonacoEditorConstructionOptions} from '@tsm/framework/monaco-editor';

@Component({
  selector: 'tsm-code-editor-dialog',
  template: `
    <tsm-dialog
      [header]="header"
      [style]="{width: '75vw'}"
      (close)="cancel.emit()"
    >
      <dtl-grid>
        <dtl-col [cols]="content.children.length === 0 ? 12 : 8">
          <tsm-monaco-editor
            [options]="editorOptionsSchema"
            [(ngModel)]="value"
            style="height: 70vh;"
          >
          </tsm-monaco-editor>
        </dtl-col>
        <dtl-col [cols]="content.children.length === 0 ? 0 : 4" class="p-l-0">
          <div #content class="content">
            <ng-content></ng-content>
          </div>
        </dtl-col>
      </dtl-grid>
      <p-footer>
        <tsm-button-success (click)="save.emit(value)"></tsm-button-success>
        <tsm-button-close (click)="cancel.emit()"></tsm-button-close>
      </p-footer>
    </tsm-dialog>
  `,
  styles: [
    `
      .content {
        height: 70vh; /*stejna vyska jako monaco editor*/
        overflow: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeEditorDialogComponent {
  @Input()
  header: string;

  @Input()
  value: string;

  @Output()
  valueChanged: EventEmitter<string>;

  @Input()
  editorOptionsSchema: MonacoEditorConstructionOptions;

  @Output()
  save = new EventEmitter<string>();

  @Output()
  cancel = new EventEmitter();
}
