import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dataTagsArray',
  pure: true,
})
export class DataTagsArrayPipe implements PipeTransform {
  constructor() {}

  transform(values: any[]): any {
    return values
      .map((value) => '<span class="tsm-data-tag">' + value + '</span>')
      .join('\n');
  }
}
