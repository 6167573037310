import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/user-group.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserGroupState = createSelector(
  getUserManagementState,
  (userManagementState) => userManagementState.userGroup,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserGroupState);

export const selectUserGroupByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectUserGroupById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectUserGroupByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectUserGroupByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data && x.data.code === code),
  );

export const selectUserGroupByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x?.data?.code)),
  );

export const getAllUserGroups = selectAll;
