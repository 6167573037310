import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LayoutTsmMenuService} from '@tsm/layout/service';
import {DtlMenuItem} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '../i18n';

@Component({
  selector: '[tsm-layout-menu-item]',
  templateUrl: './layout-menu-item.component.html',
  styleUrls: ['./layout-menu-item.component.scss'],
  animations: [
    trigger('children', [
      state(
        'collapsed',
        style({
          height: '0',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
        }),
      ),
      state(
        'hidden',
        style({
          display: 'none',
        }),
      ),
      state(
        'visible',
        style({
          display: 'block',
        }),
      ),
      transition(
        'collapsed <=> expanded',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutMenuItemComponent implements OnInit, OnDestroy {
  translation = translation;

  translationShared = translationShared;

  @Input() item: any;

  @Input() index!: number;

  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

  @Input() parentKey!: string;

  @Input() menuPinned!: boolean;

  @Input() searchCharCount: number;

  @Input() bookmarkMenuItems: DtlMenuItem[];

  @Output()
  toggleBookmark = new EventEmitter();

  active = signal(false);
  isActiveRouterLink = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string = '';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private menuService: LayoutTsmMenuService,
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
      (value) => {
        if (value.routeEvent) {
          this.active.set(
            value.key === this.key || value.key.startsWith(this.key + '-'),
          );
        } else {
          if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
            this.active.set(false);
          }
        }
      },
    );

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active.set(false);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((params) => {
        if (this.isSlim) {
          this.active.set(false);
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          }
        }
      });
  }

  ngOnInit() {
    this.key = this.parentKey
      ? this.parentKey + '-' + this.index
      : String(this.index);

    if (!this.isSlim && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }
  }

  updateActiveStateFromRoute() {
    let activeRoute = this.router.isActive(this.item.routerLink[0], {
      paths: 'exact',
      queryParams: 'ignored',
      matrixParams: 'ignored',
      fragment: 'ignored',
    });
    if (activeRoute) {
      const hasProfile = new RegExp(
        'profile=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
        'gi',
      ).test(this.router.url);
      const hasListing = new RegExp('listing=(\\S+)', 'gi').test(
        this.router.url,
      );
      if (hasProfile && hasListing) {
        // pokud aktualni url obsahuje parametr s profilem, tak se musi porovnat s polozkama v menu (zda obsahuje take profile v query parametru)
        const queryParamProfile = this.item?.queryParams?.['profile'];
        const queryParamListing = this.item?.queryParams?.['listing'];
        if (!queryParamProfile || !queryParamListing) {
          // jde o polozku v menu, ktera nema definovany profil a listing (/crm/customer)
          activeRoute = false;
        } else if (queryParamProfile && queryParamListing) {
          // jde o polozku v menu, ktera ma definovany profil (/crm/customer?profile=dd4b773a-5256-4fbc-9106-6f8216f2931d)
          if (
            queryParamProfile != this.route.snapshot.queryParams?.profile ||
            queryParamListing != this.route.snapshot.queryParams?.listing
          ) {
            // pokud profil nebo listing na polozce v menu neodpovida profilu nebo listingu v url, je nutne vypnout activeRoute
            activeRoute = false;
          }
        }
      }
      // aktualni url byla vyhodnocena jako aktivni routa bez ohledu na to, zda obsahuje profile nebo ne (/crm/customer?profile=dd4b773a-5256-4fbc-9106-6f8216f2931d nebo pouze /crm/customer)
      else if (hasProfile) {
        // pokud aktualni url obsahuje parametr s profilem, tak se musi porovnat s polozkama v menu (zda obsahuje take profile v query parametru)
        const queryParamProfile = this.item?.queryParams?.['profile'];
        if (!queryParamProfile) {
          // jde o polozku v menu, ktera nema definovany profil (/crm/customer)
          activeRoute = false;
        } else if (queryParamProfile) {
          // jde o polozku v menu, ktera ma definovany profil (/crm/customer?profile=dd4b773a-5256-4fbc-9106-6f8216f2931d)
          if (!queryParamProfile != this.route.snapshot.queryParams?.profile) {
            // pokud profil na polozce v menu neodpovida profilu v url, je nutne vypnout activeRoute
            activeRoute = false;
          }
        }
      } else if (hasListing) {
        // pokud aktualni url obsahuje parametr s profilem, tak se musi porovnat s polozkama v menu (zda obsahuje take profile v query parametru)
        const queryParamListing = this.item?.queryParams?.['listing'];
        if (!queryParamListing) {
          // jde o polozku v menu, ktera nema definovany listing (/crm/customer)
          activeRoute = false;
        } else if (queryParamListing) {
          // jde o polozku v menu, ktera ma definovany profil (/crm/customer?listing=tsm-customer_muj)
          if (queryParamListing != this.route.snapshot.queryParams?.listing) {
            // pokud listing na polozce v menu neodpovida listing v url, je nutne vypnout activeRoute
            activeRoute = false;
          }
        }
      }
      if (activeRoute) {
        this.menuService.onMenuStateChange({key: this.key, routeEvent: true});
      }
    }
    this.isActiveRouterLink = activeRoute;
  }

  itemClick(event: MouseEvent, isParent: boolean, item?: DtlMenuItem) {
    if (isParent == false) {
      if (!!item.url) {
        window.open(item.url, '_blank', 'noopener noreferrer');
      } else {
        if (event?.ctrlKey || event?.metaKey) {
          const url = this.router
            .createUrlTree(item.routerLink, {
              // fragment: item.fragment,
              // queryParamsHandling: item.queryParamsHandling,
              // preserveFragment: item.preserveFragment,
              // skipLocationChange: item.skipLocationChange,
              // replaceUrl: item.replaceUrl,
              // state: item.state,
              queryParams: item.queryParams,
            })
            .toString();
          window.open(url, '_blank', 'noopener noreferrer');
        } else {
          this.router.navigate(item.routerLink, {
            // fragment: item.fragment,
            // queryParamsHandling: item.queryParamsHandling,
            // preserveFragment: item.preserveFragment,
            // skipLocationChange: item.skipLocationChange,
            // replaceUrl: item.replaceUrl,
            // state: item.state,
            queryParams: item.queryParams,
          });
        }
      }
    }
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // execute command
    if (this.item.command) {
      this.item.command({originalEvent: event, item: this.item});
    }

    // toggle active state
    if (this.item.items) {
      this.active.set(!this.active());
    } else {
      if (this.isMobile) {
        // this.layoutService.state.staticMenuMobileActive = false;
      }
    }

    this.menuService.onMenuStateChange({key: this.key});

    if (
      isParent === false &&
      ((!this.menuPinned && event) || this.isMobile || window.innerWidth < 991)
    ) {
      this.menuService.closeMenu();
    }
  }

  onToggleBookmark(event, item: DtlMenuItem) {
    event.stopImmediatePropagation();
    this.toggleBookmark.emit(item);
  }

  get submenuAnimation() {
    // pokud mam ve vyhledavani 3 a vice znaku, tak vzdy rozbaluj menu
    if (this.searchCharCount >= 3) {
      return 'expanded';
    }
    return this.root ? 'expanded' : this.active() ? 'expanded' : 'collapsed';
  }

  get isSlim() {
    return false; //this.config.menuMode === 'slim';
  }

  get isDesktop() {
    return window.innerWidth > 991;
  }

  get isMobile() {
    return !this.isDesktop;
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active() && !this.root;
  }

  getElementUrl(routerLink: string[]) {
    if (routerLink) {
      return this.router.createUrlTree(routerLink);
    }
    return null;
  }

  isMark(item): boolean {
    return this.bookmarkMenuItems?.some((x) => x.key === item.key);
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
