import {
  ArrayExpressionAstNode,
  AstNode,
  CallExpressionAstNode,
  ConditionalExpressionAsNode,
  IdentifierAstNode,
  isArrayExpression,
  isBinaryExpressionAsNode,
  isCallExpressionAstNode,
  isConditionalExpression,
  isExpresstionStatementAstnode,
  isIdentifierAstNode,
  isLiteralAstnode,
  isLogicalExpressionAstNode,
  isMemberExpressionAstNode,
  isObjectExpressionAstNode,
  isUnaryExpressionAstNode,
  LiteralAstNode,
  MemberExpressionAstNode,
  ObjectExpressionAstNode,
  UnaryExpressionAstNode,
  ArrowFunctionExpressionAstNode,
  isArrowFunctionExpressionAstNode,
} from './utils';
import jsPlumb from 'jsplumb';
import uuid = jsPlumb.jsPlumbUtil.uuid;
import {DtlDataSource} from '@tsm/framework/datasource';
import {Injector} from '@angular/core';

export interface DatasourcesContainer {
  library: {[key: string]: any};
  store: {[key: string]: any};
}

export interface FunctionsContainer {
  [key: string]: any;
}

export interface GeneratorOutput {
  value: string;
  functions: string[];
  transforms: string[];
}

export class Generators {
  static generateExpressionFunctionString(
    node: AstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let paramsRequired = false;
    let output: GeneratorOutput = null;

    if (isConditionalExpression(node)) {
      paramsRequired = true;
      output = Generators.generateConditionalFunctionString(
        node,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isBinaryExpressionAsNode(node)) {
      paramsRequired = false;
      output = Generators.generateBinaryFunctionString(
        node.left,
        node.right,
        node.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isLogicalExpressionAstNode(node)) {
      paramsRequired = false;
      output = Generators.generateLogicalFunctionString(
        node.left,
        node.right,
        node.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isIdentifierAstNode(node)) {
      paramsRequired = true;
      output = Generators.generateIdentifierFunctionString(node.name, false);
    }

    if (isMemberExpressionAstNode(node)) {
      paramsRequired = false;
      output = Generators.generateMemberFunctionString(
        node,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isCallExpressionAstNode(node)) {
      paramsRequired = false;
      output = Generators.generateCallExpressionString(
        node,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isLiteralAstnode(node)) {
      paramsRequired = false;
      output = Generators.generateLiteralFunctionString(node);
    }

    if (isArrayExpression(node)) {
      paramsRequired = false;
      output = Generators.generateArrayFunctionString(
        node,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isUnaryExpressionAstNode(node)) {
      paramsRequired = false;
      output = Generators.generateUnaryFunctionString(
        node,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    return {
      value: output?.value,
      functions: [...(output?.functions ?? [])],
      transforms: [...(output?.transforms ?? [])],
    };
  }

  static getDataSourceInstance(
    dataSource: any,
    injector: Injector,
  ): DtlDataSource {
    const datasourcesInjector = Injector.create({
      parent: injector,
      providers: [dataSource],
    });
    const dataSourceInstance: DtlDataSource<any> =
      datasourcesInjector.get<any>(dataSource);
    return dataSourceInstance;
  }

  static generateLiteralFunctionString(node: LiteralAstNode): GeneratorOutput {
    let value = node.value;
    if (node?.value?.includes && node.value.includes('"')) {
      value = node.value.split('"').join('\\"');
    }
    const source =
      typeof value === 'string' || value instanceof String
        ? '"' + value + '"'
        : value;

    return {
      value: source,
      functions: [],
      transforms: [],
    };
  }

  static generateObjectFunctionString(
    node: ObjectExpressionAstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let source = '{';
    const innerFunctions = [];
    const innerTransforms = [];

    node.properties.forEach((property, i) => {
      let tempKey = '';
      let tempValue = '';
      if (isIdentifierAstNode(property.key)) {
        const output = Generators.generateIdentifierFunctionString(
          property.key.name,
          false,
        );
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isIdentifierAstNode(property.value)) {
        const output = Generators.generateIdentifierFunctionString(
          property.value.name,
          false,
        );
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isMemberExpressionAstNode(property.key)) {
        const output = Generators.generateMemberFunctionString(
          property.key,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isMemberExpressionAstNode(property.value)) {
        const output = Generators.generateMemberFunctionString(
          property.value,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isBinaryExpressionAsNode(property.key)) {
        const output = Generators.generateBinaryFunctionString(
          property.key.left,
          property.key.right,
          property.key.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isBinaryExpressionAsNode(property.value)) {
        const output = Generators.generateBinaryFunctionString(
          property.value.left,
          property.value.right,
          property.value.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isLogicalExpressionAstNode(property.key)) {
        const output = Generators.generateLogicalFunctionString(
          property.key.left,
          property.key.right,
          property.key.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isLogicalExpressionAstNode(property.value)) {
        const output = Generators.generateLogicalFunctionString(
          property.value.left,
          property.value.right,
          property.value.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isCallExpressionAstNode(property.key)) {
        const output = Generators.generateCallExpressionString(
          property.key,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isCallExpressionAstNode(property.value)) {
        const output = Generators.generateCallExpressionString(
          property.value,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isArrayExpression(property.key)) {
        const output = Generators.generateArrayFunctionString(
          property.key,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isArrayExpression(property.value)) {
        const output = Generators.generateArrayFunctionString(
          property.value,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isLiteralAstnode(property.key)) {
        const output = Generators.generateLiteralFunctionString(property.key);
        tempKey = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
      if (isLiteralAstnode(property.value)) {
        const output = Generators.generateLiteralFunctionString(property.value);
        tempValue = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      source +=
        `${tempKey}: ${tempValue}` +
        (node.properties.length - 1 === i ? '' : ', ');
    });
    // Odebrani carky
    source += '}';

    return {
      value: source,
      functions: [],
      transforms: [],
    };
  }

  static generateUnaryFunctionString(
    node: UnaryExpressionAstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let output: GeneratorOutput = null;
    const innerFunctions = [];
    const innerTransforms = [];

    if (isConditionalExpression(node.argument)) {
      output = Generators.generateConditionalFunctionString(
        node.argument,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isBinaryExpressionAsNode(node.argument)) {
      output = Generators.generateBinaryFunctionString(
        node.argument.left,
        node.argument.right,
        node.argument.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isLogicalExpressionAstNode(node.argument)) {
      output = Generators.generateLogicalFunctionString(
        node.argument.left,
        node.argument.right,
        node.argument.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isIdentifierAstNode(node.argument)) {
      output = Generators.generateIdentifierFunctionString(
        node.argument.name,
        false,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isMemberExpressionAstNode(node.argument)) {
      output = Generators.generateMemberFunctionString(
        node.argument,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isCallExpressionAstNode(node.argument)) {
      output = Generators.generateCallExpressionString(
        node.argument,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isLiteralAstnode(node.argument)) {
      output = Generators.generateLiteralFunctionString(node.argument);
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isArrayExpression(node.argument)) {
      output = Generators.generateArrayFunctionString(
        node.argument,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    if (isObjectExpressionAstNode(node.argument)) {
      output = Generators.generateObjectFunctionString(
        node.argument,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }

    const source = `!(${output.value})`;
    return {
      value: source,
      functions: innerFunctions,
      transforms: innerTransforms,
    };
  }

  static generateIdentifierFunctionString(
    identifier: string,
    computed: boolean,
    isFunction: boolean = false,
  ): GeneratorOutput {
    return {
      value: identifier,
      functions: [],
      transforms: [],
    };
  }

  static generateArrowFunctionString(
    arrowFunctionNode: ArrowFunctionExpressionAstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    // Process the arrow function body and parameters
    const params = arrowFunctionNode.params
      .map((param) => param.name)
      .join(', ');
    const bodyOutput = Generators.generateExpressionFunctionString(
      arrowFunctionNode.body,
      functions,
      transforms,
      dataSourcesDefinitions,
      injector,
    );

    return {
      value: `(${params}) => ${bodyOutput.value}`,
      functions: [...bodyOutput.functions],
      transforms: [...bodyOutput.transforms],
    };
  }

  static generateCallExpressionString(
    callNode: CallExpressionAstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let output: GeneratorOutput = null;
    let argumentsArgsListString = '';
    const innerFunctions = [];
    const innerTransforms = [];
    if (isIdentifierAstNode(callNode.callee)) {
      output = Generators.generateIdentifierFunctionString(
        callNode.callee.name,
        false,
        true,
      );
      innerFunctions.push(...output.functions);
      innerTransforms.push(...output.transforms);
    }
    if (isMemberExpressionAstNode(callNode.callee)) {
      const memberExpression = callNode.callee as MemberExpressionAstNode;

      if (isIdentifierAstNode(memberExpression.property)) {
        const objectOutput = Generators.generateExpressionFunctionString(
          memberExpression.object,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );

        argumentsArgsListString += `${objectOutput.value},`;
        callNode.arguments.forEach((arg, argIndex) => {
          let temp = '';
          if (isIdentifierAstNode(arg)) {
            const argOutput = Generators.generateIdentifierFunctionString(
              arg.name,
              false,
            );
            temp = argOutput.value;
            innerFunctions.push(...argOutput.functions);
            innerTransforms.push(...argOutput.transforms);
          }

          if (isLiteralAstnode(arg)) {
            const argOutput = Generators.generateLiteralFunctionString(arg);
            temp = argOutput.value;
            innerFunctions.push(...argOutput.functions);
            innerTransforms.push(...argOutput.transforms);
          }

          if (isArrowFunctionExpressionAstNode(arg)) {
            const argOutput = Generators.generateArrowFunctionString(
              arg,
              functions,
              transforms,
              dataSourcesDefinitions,
              injector,
            );
            temp = argOutput.value;
            innerFunctions.push(...argOutput.functions);
            innerTransforms.push(...argOutput.transforms);
          }

          // Add more checks for different argument types as needed
          argumentsArgsListString += `${temp},`;
        });

        argumentsArgsListString = argumentsArgsListString.slice(0, -1); // Remove trailing comma

        const source = `functions["${memberExpression.property.name}"](${argumentsArgsListString})`;
        return {
          value: source,
          functions: innerFunctions,
          transforms: innerTransforms,
        };
      } else {
        output = Generators.generateMemberFunctionString(
          callNode.callee,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }
    }

    callNode.arguments.forEach((arg, argIndex) => {
      let temp = '';
      if (isIdentifierAstNode(arg)) {
        const output = Generators.generateIdentifierFunctionString(
          arg.name,
          false,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isMemberExpressionAstNode(arg)) {
        const output = Generators.generateMemberFunctionString(
          arg,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isBinaryExpressionAsNode(arg)) {
        const output = Generators.generateBinaryFunctionString(
          arg.left,
          arg.right,
          arg.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isLogicalExpressionAstNode(arg)) {
        const output = Generators.generateLogicalFunctionString(
          arg.left,
          arg.right,
          arg.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isCallExpressionAstNode(arg)) {
        const output = Generators.generateCallExpressionString(
          arg,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isLiteralAstnode(arg)) {
        const output = Generators.generateLiteralFunctionString(arg);
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isArrayExpression(arg)) {
        const output = Generators.generateArrayFunctionString(
          arg,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isObjectExpressionAstNode(arg)) {
        const output = Generators.generateObjectFunctionString(
          arg,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      argumentsArgsListString += `${temp},`;
    });

    // Odebrani carky
    argumentsArgsListString = argumentsArgsListString.slice(0, -1);

    const foundDataSource = dataSourcesDefinitions.library[output.value];
    if (foundDataSource) {
      const guid = uuid();
      const instance = Generators.getDataSourceInstance(
        foundDataSource,
        injector,
      );
      dataSourcesDefinitions.store[guid] = instance;
      dataSourcesDefinitions.store[guid]._compiledString =
        argumentsArgsListString;

      const source = `dataSources['${guid}'](${argumentsArgsListString}) && dataSourcesValues['${guid}']`;
      return {
        value: source,
        functions: innerFunctions,
        transforms: innerTransforms,
      };
    }

    let source = '';
    if (output.value !== 'sumMany') {
      source = `functions["${output.value}"](${argumentsArgsListString})`;
    } else {
      source = `parseFloat(functions["${
        output.value
      }"](${argumentsArgsListString}).toFixed(${8}))`;
    }
    return {
      functions: innerFunctions,
      transforms: innerTransforms,
      value: source,
    };
  }

  static generateBinaryFunctionString(
    leftNode: AstNode,
    rightNode: AstNode,
    operator,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let leftOutput: GeneratorOutput = null;
    let rightOutput: GeneratorOutput = null;
    const innerFunctions = [];
    const innerTransforms = [];
    // Literal
    if (isLiteralAstnode(leftNode)) {
      leftOutput = Generators.generateLiteralFunctionString(leftNode);
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isLiteralAstnode(rightNode)) {
      rightOutput = Generators.generateLiteralFunctionString(rightNode);
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // conditional
    if (isConditionalExpression(leftNode)) {
      leftOutput = Generators.generateConditionalFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isConditionalExpression(rightNode)) {
      rightOutput = Generators.generateConditionalFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // identifier expression
    if (isIdentifierAstNode(leftNode)) {
      leftOutput = Generators.generateIdentifierFunctionString(
        leftNode.name,
        false,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }

    if (isIdentifierAstNode(rightNode)) {
      rightOutput = Generators.generateIdentifierFunctionString(
        rightNode.name,
        false,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // binary expression
    if (isBinaryExpressionAsNode(leftNode)) {
      leftOutput = Generators.generateBinaryFunctionString(
        leftNode.left,
        leftNode.right,
        leftNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }

    if (isBinaryExpressionAsNode(rightNode)) {
      rightOutput = Generators.generateBinaryFunctionString(
        rightNode.left,
        rightNode.right,
        rightNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // binary expression
    if (isLogicalExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateLogicalFunctionString(
        leftNode.left,
        leftNode.right,
        leftNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }

    if (isLogicalExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateLogicalFunctionString(
        rightNode.left,
        rightNode.right,
        rightNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // member expression
    if (isMemberExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateMemberFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isMemberExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateMemberFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // call expression
    if (isCallExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateCallExpressionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isCallExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateCallExpressionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // array
    if (isArrayExpression(leftNode)) {
      rightOutput = Generators.generateArrayFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }
    if (isArrayExpression(rightNode)) {
      rightOutput = Generators.generateArrayFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // decimal.js
    const isDecimal = (v) => v != null && v % 1 != 0;
    if (
      operator === '+' ||
      (operator === '-' &&
        (isDecimal(leftOutput.value) || isDecimal(rightOutput.value)))
    ) {
      let source;
      if (operator === '+') {
        source = `functions['safePlus'](${leftOutput.value}, ${rightOutput.value})`;
      } else {
        source = `functions['safeMinus'](${leftOutput.value}, ${rightOutput.value})`;
      }

      return {
        functions: innerFunctions,
        transforms: innerTransforms,
        value: source,
      };
    }

    const source =
      operator !== '|'
        ? `(${leftOutput.value} ${operator} ${rightOutput.value})`
        : `transforms["${rightOutput.value}"](${leftOutput.value})`;
    return {
      functions: innerFunctions,
      transforms: innerTransforms,
      value: source,
    };
  }

  static generateLogicalFunctionString(
    leftNode: AstNode,
    rightNode: AstNode,
    operator,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let leftOutput: GeneratorOutput = null;
    let rightOutput: GeneratorOutput = null;
    const innerFunctions = [];
    const innerTransforms = [];
    // Literal
    if (isLiteralAstnode(leftNode)) {
      leftOutput = Generators.generateLiteralFunctionString(leftNode);
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isLiteralAstnode(rightNode)) {
      rightOutput = Generators.generateLiteralFunctionString(rightNode);
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // Conditional
    if (isConditionalExpression(leftNode)) {
      leftOutput = Generators.generateConditionalFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isConditionalExpression(rightNode)) {
      rightOutput = Generators.generateConditionalFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // identifier expression
    if (isIdentifierAstNode(leftNode)) {
      leftOutput = Generators.generateIdentifierFunctionString(
        leftNode.name,
        false,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }

    if (isIdentifierAstNode(rightNode)) {
      rightOutput = Generators.generateIdentifierFunctionString(
        rightNode.name,
        false,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // binary expression
    if (isBinaryExpressionAsNode(leftNode)) {
      leftOutput = Generators.generateBinaryFunctionString(
        leftNode.left,
        leftNode.right,
        leftNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }

    if (isBinaryExpressionAsNode(rightNode)) {
      rightOutput = Generators.generateBinaryFunctionString(
        rightNode.left,
        rightNode.right,
        rightNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // binary expression
    if (isLogicalExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateLogicalFunctionString(
        leftNode.left,
        leftNode.right,
        leftNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }

    if (isLogicalExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateLogicalFunctionString(
        rightNode.left,
        rightNode.right,
        rightNode.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // member expression
    if (isMemberExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateMemberFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isMemberExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateMemberFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // call expression
    if (isCallExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateCallExpressionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isCallExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateCallExpressionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // array expression
    if (isArrayExpression(leftNode)) {
      leftOutput = Generators.generateArrayFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isArrayExpression(rightNode)) {
      rightOutput = Generators.generateArrayFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    // unary expression
    if (isUnaryExpressionAstNode(leftNode)) {
      leftOutput = Generators.generateUnaryFunctionString(
        leftNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...leftOutput.functions);
      innerTransforms.push(...leftOutput.transforms);
    }
    if (isUnaryExpressionAstNode(rightNode)) {
      rightOutput = Generators.generateUnaryFunctionString(
        rightNode,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
      innerFunctions.push(...rightOutput.functions);
      innerTransforms.push(...rightOutput.transforms);
    }

    const source = `(${leftOutput.value} ${operator} ${rightOutput.value})`;
    return {
      value: source,
      functions: innerFunctions,
      transforms: innerTransforms,
    };
  }

  static generateMemberFunctionString(
    node: MemberExpressionAstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let simpleProperty = true;
    let objectOutput: GeneratorOutput = null;
    let propertyOutput: GeneratorOutput = null;
    const innerFunctions = [];
    const innerTransforms = [];

    if (isBinaryExpressionAsNode(node.property)) {
      simpleProperty = false;
      propertyOutput = Generators.generateBinaryFunctionString(
        node.property.left,
        node.property.right,
        node.property.operator,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isCallExpressionAstNode(node.object)) {
      simpleProperty = false;
      objectOutput = Generators.generateCallExpressionString(
        node.object,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isCallExpressionAstNode(node.property)) {
      simpleProperty = false;
      propertyOutput = Generators.generateCallExpressionString(
        node.property,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    if (isIdentifierAstNode(node.object)) {
      simpleProperty = false;
      objectOutput = Generators.generateIdentifierFunctionString(
        node.object.name,
        node.computed,
      );
    }
    if (isIdentifierAstNode(node.property)) {
      propertyOutput = Generators.generateIdentifierFunctionString(
        node.property.name,
        node.computed,
      );
    }

    if (isLiteralAstnode(node.property)) {
      propertyOutput = Generators.generateLiteralFunctionString(node.property);
    }

    if (isMemberExpressionAstNode(node.object)) {
      simpleProperty = false;
      objectOutput = Generators.generateMemberFunctionString(
        node.object,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }
    if (isMemberExpressionAstNode(node.property)) {
      simpleProperty = false;
      propertyOutput = Generators.generateMemberFunctionString(
        node.property,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }
    if (isArrayExpression(node.property)) {
      simpleProperty = false;
      propertyOutput = Generators.generateArrayFunctionString(
        node.property,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    }

    const source = simpleProperty
      ? propertyOutput.value
      : node.computed
        ? `${objectOutput.value}[${propertyOutput.value}]`
        : `${objectOutput.value}?.${propertyOutput.value}`;
    return {
      value: source,
      functions: innerFunctions,
      transforms: innerTransforms,
    };
  }

  static getDebugString(node): string {
    if (isExpresstionStatementAstnode(node)) {
      return '${ ' + this.getDebugString(node) + '}';
    }
    if (isBinaryExpressionAsNode(node)) {
      return `${this.getDebugString(node.left)} ${
        node.operator
      } ${this.getDebugString(node.right)}`;
    }
    if (isMemberExpressionAstNode(node)) {
      if (node.computed) {
        return `${this.getDebugString(node.object)}[${this.getDebugString(
          node.property,
        )}]`;
      } else {
        return `${this.getDebugString(node.object)}.${this.getDebugString(
          node.property,
        )}`;
      }
    }

    if (isCallExpressionAstNode(node)) {
      const temp =
        (node.callee as IdentifierAstNode).name +
        '(' +
        node.arguments
          .reduce((acc, it) => acc + this.getDebugString(it) + ', ', '')
          .slice(0, -2) +
        ')';
      return temp;
    }

    if (isIdentifierAstNode(node)) {
      return node.name;
    }

    if (isLiteralAstnode(node)) {
      return node.value;
    }

    if (isLogicalExpressionAstNode(node)) {
      return `${this.getDebugString(node.left)} ${
        node.operator
      } ${this.getDebugString(node.right)}`;
    }

    return '// MUHEHEEEE - ' + node.type;
  }

  private static generateConditionalFunctionString(
    expression: ConditionalExpressionAsNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    const testOutput: GeneratorOutput =
      Generators.generateExpressionFunctionString(
        expression.test,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    const consequentOutput: GeneratorOutput =
      Generators.generateExpressionFunctionString(
        expression.consequent,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    const alternateOutput: GeneratorOutput =
      Generators.generateExpressionFunctionString(
        expression.alternate,
        functions,
        transforms,
        dataSourcesDefinitions,
        injector,
      );
    const source = `(${testOutput.value} ? (${consequentOutput.value}) : (${alternateOutput.value}))`;
    return {
      value: source,
      functions: [
        ...testOutput.functions,
        ...consequentOutput.functions,
        ...alternateOutput.functions,
      ],
      transforms: [
        ...testOutput.transforms,
        ...consequentOutput.transforms,
        ...alternateOutput.transforms,
      ],
    };
  }

  private static generateArrayFunctionString(
    expression: ArrayExpressionAstNode,
    functions: FunctionsContainer,
    transforms: FunctionsContainer,
    dataSourcesDefinitions: DatasourcesContainer,
    injector: Injector,
  ): GeneratorOutput {
    let elementsString = '';
    const innerFunctions = [];
    const innerTransforms = [];

    expression.elements.forEach((elNode) => {
      let temp = '';
      if (isIdentifierAstNode(elNode)) {
        const output = Generators.generateIdentifierFunctionString(
          elNode.name,
          false,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isMemberExpressionAstNode(elNode)) {
        const output = Generators.generateMemberFunctionString(
          elNode,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isBinaryExpressionAsNode(elNode)) {
        const output = Generators.generateBinaryFunctionString(
          elNode.left,
          elNode.right,
          elNode.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isLogicalExpressionAstNode(elNode)) {
        const output = Generators.generateLogicalFunctionString(
          elNode.left,
          elNode.right,
          elNode.operator,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isCallExpressionAstNode(elNode)) {
        const output = Generators.generateCallExpressionString(
          elNode,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isArrayExpression(elNode)) {
        const output = Generators.generateArrayFunctionString(
          elNode,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isLiteralAstnode(elNode)) {
        const output = Generators.generateLiteralFunctionString(elNode);
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      if (isObjectExpressionAstNode(elNode)) {
        const output = Generators.generateObjectFunctionString(
          elNode,
          functions,
          transforms,
          dataSourcesDefinitions,
          injector,
        );
        temp = output.value;
        innerFunctions.push(...output.functions);
        innerTransforms.push(...output.transforms);
      }

      elementsString += `${temp},`;
    });
    // Odebrani carky
    elementsString = elementsString.slice(0, -1);

    const source = `[${elementsString}]`;
    return {
      value: source,
      functions: innerFunctions,
      transforms: innerTransforms,
    };
  }
}
