import {createAction, props} from '@ngrx/store';
import {JwtResponseHttpModel} from '@tsm/runtime-info';
import {NavigationExtras, UrlTree} from '@angular/router';
import {ToastSeverity} from '@tsm/framework/toast';
import {createActionWithMetadata} from '@tsm/framework/root/actions-metadata';
import {translation as translationShared} from '@tsm/shared-i18n';

export const LoginFinishedSuccess = createAction(
  '[Core] Login finished success',
  props<{response: JwtResponseHttpModel}>(),
);

export const Logout = createActionWithMetadata('[Core] Logout', {
  title: translationShared.shared.actionsMetadata.logout,
});

export const LogoutClearState = createAction('[Core] Logout Clear State');

export const Navigate = createActionWithMetadata(
  '[Core] Navigate',
  {
    title: translationShared.shared.actionsMetadata.navigate,
    props: {
      url: translationShared.shared.url,
    },
  },
  props<{url: string | UrlTree; extras?: NavigationExtras; newTab?: boolean}>(),
);

export const ShowMessage = createActionWithMetadata(
  '[Core] ShowMessage',
  {
    title: translationShared.shared.actionsMetadata.showMessage,
    props: {
      text: translationShared.shared.text,
    },
  },
  props<{text: string; type?: ToastSeverity; title?: string}>(),
);
