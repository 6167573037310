import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {ValueConverterConfig} from '../models';

export const LoadValueConverterConfig = createAction(
  '[ValueConverterConfig] Load ValueConverterConfig',
);
export const LoadValueConverterConfigSuccess = createAction(
  '[ValueConverterConfig] Load ValueConverterConfig Success',
  props<{
    valueConverterConfigs: ValueConverterConfig[];
  }>(),
);
export const LoadValueConverterConfigError = createAction(
  '[ValueConverterConfig] Load ValueConverterConfig Error',
  props<TsmError>(),
);

export const LoadValueConverterConfigById = createAction(
  '[ValueConverterConfig] Load LoadValueConverterConfigById',
  props<{
    id: string;
  }>(),
);
export const LoadValueConverterConfigByIdSuccess = createAction(
  '[ValueConverterConfig] Load LoadValueConverterConfigById Success',
  props<{
    valueConverterConfig: ValueConverterConfig;
  }>(),
);
export const LoadValueConverterConfigByIdError = createAction(
  '[ValueConverterConfig] Load LoadValueConverterConfigById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const InsertValueConverterConfig = createAction(
  '[ValueConverterConfig] Insert ValueConverterConfig',
  props<{
    valueConverterConfig: ValueConverterConfig;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const InsertValueConverterConfigSuccess = createAction(
  '[ValueConverterConfig] Insert ValueConverterConfig Success',
  props<{
    valueConverterConfig: ValueConverterConfig;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const InsertValueConverterConfigError = createAction(
  '[ValueConverterConfig] Insert ValueConverterConfig Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const UpdateValueConverterConfig = createAction(
  '[ValueConverterConfig] Update ValueConverterConfig',
  props<{
    valueConverterConfig: ValueConverterConfig;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpdateValueConverterConfigSuccess = createAction(
  '[ValueConverterConfig] Update ValueConverterConfig Success',
  props<{
    valueConverterConfig: ValueConverterConfig;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpdateValueConverterConfigError = createAction(
  '[ValueConverterConfig] Update ValueConverterConfig Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const DeleteValueConverterConfig = createAction(
  '[ValueConverterConfig] Delete ValueConverterConfig',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteValueConverterConfigSuccess = createAction(
  '[ValueConverterConfig] Delete ValueConverterConfig Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteValueConverterConfigError = createAction(
  '[ValueConverterConfig] Delete ValueConverterConfig Error',
  props<TsmError>(),
);

export const DiffValueConverterConfig = createAction(
  '[ValueConverterConfig] Diff ValueConverterConfig',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffValueConverterConfigSuccess = createAction(
  '[ValueConverterConfig] Diff ValueConverterConfig Success',
  props<{
    valueConverterConfigs: ValueConverterConfig[];
    listingId: string;
  }>(),
);
export const DiffValueConverterConfigError = createAction(
  '[ValueConverterConfig] Diff ValueConverterConfig Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
