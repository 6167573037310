import {ConfigType} from '../models';
import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';

export const LoadConfigType = createAction('[ConfigType] Load ConfigType');
export const LoadConfigTypeSuccess = createAction(
  '[ConfigType] Load ConfigType Success',
  props<{
    configTypes: ConfigType[];
  }>(),
);
export const LoadConfigTypeError = createAction(
  '[ConfigType] Load ConfigType Error',
  props<TsmError>(),
);

export const LoadConfigTypeById = createAction(
  '[ConfigType] Load LoadConfigTypeById',
  props<{id: string}>(),
);
export const LoadConfigTypeByIdSuccess = createAction(
  '[ConfigType] Load LoadConfigTypeById Success',
  props<{
    entity: ConfigType;
  }>(),
);
export const LoadConfigTypeByIdError = createAction(
  '[ConfigType] Load LoadConfigTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadConfigTypeByCode = createAction(
  '[ConfigType] Load ConfigTypeByCode',
  props<{code: string}>(),
);
export const LoadConfigTypeByCodeSuccess = createAction(
  '[ConfigType] Load ConfigTypeByCode Success',
  props<{
    entity: ConfigType;
  }>(),
);
export const LoadConfigTypeByCodeError = createAction(
  '[ConfigType] Load ConfigTypeByCode Error',
  props<{
    code: string;
    error: TsmError;
  }>(),
);

export const UpsertConfigType = createAction(
  '[ConfigType] Upsert ConfigType',
  props<{
    configType: ConfigType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertConfigTypeSuccess = createAction(
  '[ConfigType] Upsert ConfigType Success',
  props<{
    configType: ConfigType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertConfigTypeError = createAction(
  '[ConfigType] Upsert ConfigType Error',
  props<TsmError>(),
);

export const DeleteConfigType = createAction(
  '[ConfigType] Delete ConfigType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteConfigTypeSuccess = createAction(
  '[ConfigType] Delete ConfigType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteConfigTypeError = createAction(
  '[ConfigType] Delete ConfigType Error',
  props<TsmError>(),
);

export const PatchConfigType = createAction(
  '[Customer] Patch ConfigType',
  props<{
    id: string;
    configType: ConfigType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const PatchConfigTypeSuccess = createAction(
  '[Customer] Patch ConfigType Success',
  props<{
    id: string;
    configType: ConfigType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const PatchConfigTypeError = createAction(
  '[Customer] Patch ConfigType Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const DiffConfigType = createAction(
  '[ConfigType] Diff ConfigType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffConfigTypeSuccess = createAction(
  '[ConfigType] Diff ConfigType Success',
  props<{
    configTypes: ConfigType[];
    listingId: string;
  }>(),
);
export const DiffConfigTypeError = createAction(
  '[ConfigType] Diff ConfigType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
