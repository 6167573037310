import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserManagementState} from '../reducers';
import {RouterStateUrl} from '@tsm/framework/root';
import {userTypeAdapter} from '../reducers/user-type.reducer';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserTypeState = createSelector(
  getUserManagementState,
  (userState) => userState.userType,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  userTypeAdapter.getSelectors(getUserTypeState);

export const allUserTypes = selectAll;

export const userTypeEntities = selectEntities;

export const selectUserTypeById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectUserTypeByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data?.code === code),
  );

export const selectUserTypesByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );
