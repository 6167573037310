import {SelectItem} from 'primeng/api';

export enum UserParameterName {
  LANGUAGE = 'language',
  SCALE = 'scale',
  DARK_THEME = 'darkTheme',
  REMEMBER_TAB_VIEW_STATE = 'rememberTabviewState',
  LAST_LOCATION = 'lastLocation',
  CONFIRM_TASK_STATUS_CHANGE = 'confirmTaskStatusChange',
  COMMENT_VIEW_TYPE = 'commentViewType',
  CONFIRM_CLOSE_WINDOW = 'confirmCloseWindow',
  CONFIRM_CLOSE_BUTTON = 'confirmCloseButton',
  FLOATING_DETAIL = 'floatingDetail',
  REMEMBER_LOCKED_MENU = 'rememberLockedMenu',
  REMEMBER_PINNED_MENU = 'rememberPinnedMenu',
  ACTIVE_ACCOUNT = 'activeAccount',
  ALLOW_NOTIFICATION_API = 'allowNotificationApi',
  OPEN_IN_NEW_TAB_WITHOUT_CTRL = 'openInNewTabWithoutCtrl',
  MODULE_TYPE = 'moduleType',
  WORKLOG_STOPWATCH = 'worklogStopwatch',
  PANEL_MODE = 'panelMode',
  DEBUG_MODE = 'debugMode',
  FAVORITE_IDS = 'favoriteIds',
  EMAIL_SENDER = 'emailSender',
  FAVORITE_WIDGETS = 'favorite-widgets',
}

export function getAllUserParameter(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(UserParameterName).forEach((val) =>
    items.push({
      label: 'userManagementService.userParameter.' + val,
      value: val,
    }),
  );
  return items;
}

export function getAllAllowedUserParameter(): SelectItem[] {
  const items: SelectItem[] = [];
  items.push({
    label:
      'userManagementService.userParameter.' +
      UserParameterName.REMEMBER_TAB_VIEW_STATE,
    value: UserParameterName.REMEMBER_TAB_VIEW_STATE,
  });
  items.push({
    label:
      'userManagementService.userParameter.' +
      UserParameterName.CONFIRM_TASK_STATUS_CHANGE,
    value: UserParameterName.CONFIRM_TASK_STATUS_CHANGE,
  });
  items.push({
    label:
      'userManagementService.userParameter.' +
      UserParameterName.CONFIRM_CLOSE_WINDOW,
    value: UserParameterName.CONFIRM_CLOSE_WINDOW,
  });
  items.push({
    label:
      'userManagementService.userParameter.' +
      UserParameterName.CONFIRM_CLOSE_BUTTON,
    value: UserParameterName.CONFIRM_CLOSE_BUTTON,
  });
  items.push({
    label:
      'userManagementService.userParameter.' + UserParameterName.ACTIVE_ACCOUNT,
    value: UserParameterName.ACTIVE_ACCOUNT,
  });
  items.push({
    label:
      'userManagementService.userParameter.' +
      UserParameterName.ALLOW_NOTIFICATION_API,
    value: UserParameterName.ALLOW_NOTIFICATION_API,
  });
  items.push({
    label:
      'userManagementService.userParameter.' +
      UserParameterName.OPEN_IN_NEW_TAB_WITHOUT_CTRL,
    value: UserParameterName.OPEN_IN_NEW_TAB_WITHOUT_CTRL,
  });
  items.push({
    label:
      'userManagementService.userParameter.' + UserParameterName.EMAIL_SENDER,
    value: UserParameterName.EMAIL_SENDER,
  });
  return items;
}
