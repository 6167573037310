import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteAllRegionAssignments,
  DeleteAllRegionAssignmentsError,
  DeleteAllRegionAssignmentsSuccess,
  DeleteRegionAssignmentSuccess,
  LoadRegionAssignmentsByRegionAssignmentId,
  LoadRegionAssignmentsByRegionAssignmentIdError,
  LoadRegionAssignmentsByRegionAssignmentIdSuccess,
  LoadRegionAssignmentsByUserId,
  LoadRegionAssignmentsByUserIdError,
  LoadRegionAssignmentsByUserIds,
  LoadRegionAssignmentsByUserIdsError,
  LoadRegionAssignmentsByUserIdsSuccess,
  LoadRegionAssignmentsByUserIdSuccess,
  SaveAllRegionAssignments,
  SaveAllRegionAssignmentsError,
  SaveAllRegionAssignmentsSuccess,
  UpsertRegionAssignmentSuccess,
} from '../actions';
import {RegionAssignment} from '../model';

export type RegionAssignmentState = EntityState<
  IdentifiedPack<RegionAssignment>
>;

export const adapter: EntityAdapter<IdentifiedPack<RegionAssignment>> =
  createEntityAdapter<IdentifiedPack<RegionAssignment>>({});

export const initialState: RegionAssignmentState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(
    LoadRegionAssignmentsByUserIdSuccess,
    LoadRegionAssignmentsByUserIdsSuccess,
    LoadRegionAssignmentsByRegionAssignmentIdSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((regionAssignment) => ({
          id: regionAssignment.id,
          data: regionAssignment,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadRegionAssignmentsByUserId,
    LoadRegionAssignmentsByUserIds,
    LoadRegionAssignmentsByRegionAssignmentId,
    DeleteAllRegionAssignments,
    SaveAllRegionAssignments,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadRegionAssignmentsByUserIdError,
    LoadRegionAssignmentsByUserIdsError,
    LoadRegionAssignmentsByRegionAssignmentIdError,
    DeleteAllRegionAssignmentsError,
    SaveAllRegionAssignmentsError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertRegionAssignmentSuccess, (state, {regionAssignment}) =>
    adapter.upsertOne(
      {
        id: regionAssignment.id,
        data: regionAssignment,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteRegionAssignmentSuccess, (state, regionAssignment) =>
    adapter.removeOne(regionAssignment.id, state),
  ),

  on(SaveAllRegionAssignmentsSuccess, (state, {entities}) => ({
    ...adapter.upsertMany(
      entities.map((entity) => ({
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(DeleteAllRegionAssignmentsSuccess, (state, {ids}) => ({
    ...adapter.removeMany(ids, state),
    error: null,
    loading: false,
  })),
);
