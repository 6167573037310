import {SelectItem} from 'primeng/api/selectitem';

export enum NotificationType {
  USER_COMMENT = 'USER_COMMENT',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
  CUSTOMER_MESSAGE = 'CUSTOMER_MESSAGE',
}

export function getAllNotificationTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(NotificationType).forEach((val) =>
    items.push({label: NotificationType[val], value: val}),
  );
  return items;
}
