import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {map, tap} from 'rxjs/operators';
import {previousUrlCore} from '../selectors';

@Injectable()
export class TeamsGuard implements CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    return this.store.select(previousUrlCore).pipe(
      tap((x) => {
        if (x.url?.includes('teams') && !state.url.includes('teams')) {
          this.router.navigate(['teams' + state.url]);
        }
      }),
      map((x) => true),
    );
  }
}
