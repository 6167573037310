export const cs = {
  layoutTsm: {
    addAnyItem: 'Přidejte libovolnou položku z hlavního menu (drag/drop)',
    clickToSave: 'Pro uložení úprav, prosím potvrďte kliknutím zde',
    loading: 'Načítám',
    logOut: 'Odhlásit se',
    noMessages: 'Nemáte žádné zprávy',
    deleteMsg: 'Smazat zprávu',
    notifications: 'Notifikace',
    notificationsHotkeyText: 'Zobrazit notifikace',
    notification: 'Oznámení',
    history: 'Historie',
    historyHotkeyText: 'Zobrazit panel s historií',
    unreadOnly: 'Jen nepřečtené',
    noAlerts: 'Žádná upozornění',
    deleteNotification: 'Smazat oznámení',
    language: 'Jazyk',
    czech: 'CS',
    english: 'EN',
    all: 'Vše',
    mode: 'Režim',
    darkTheme: 'Tmavý',
    lightTheme: 'Světlý',
  },
};
