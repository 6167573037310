import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  Action,
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import * as actions from '../actions';
import {IdPackage} from '../models';

export type LocalState = EntityState<IdPackage>;

export const adapter: EntityAdapter<IdPackage> =
  createEntityAdapter<IdPackage>();

export const initialState: LocalState = adapter.getInitialState({});

const localStateReducer = createReducer(
  initialState,
  on(actions.SetLocalState, (state, {key, payload}) => {
    return adapter.upsertOne(
      {
        id: key,
        data: payload,
      },
      state,
    );
  }),
  on(actions.DeleteLocalState, (state, {key}) => {
    return adapter.removeOne(key, state);
  }),
);

export function reducer(state: LocalState | undefined, action: Action) {
  return localStateReducer(state, action);
}

export interface State {
  localState: LocalState;
}

export const reducers: ActionReducerMap<State> = {
  localState: reducer,
};

export const getLocalState = createFeatureSelector<LocalState>('localState');

const {selectAll} = adapter.getSelectors();

export const getLocalStateByKey = (key: string) =>
  createSelector(getLocalState, (state) => state.entities[key]);
