import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteCharacteristics,
  DeleteCharacteristicsError,
  DeleteCharacteristicsSuccess,
  DiffCharacteristics,
  DiffCharacteristicsError,
  DiffCharacteristicsSuccess,
  LoadCharacteristics,
  LoadCharacteristicsByEntityTypeSuccess,
  LoadCharacteristicsByFilterError,
  LoadCharacteristicsByFilterSuccess,
  LoadCharacteristicsByFormId,
  LoadCharacteristicsByFormIdError,
  LoadCharacteristicsByFormIdSuccess,
  LoadCharacteristicsByIdError,
  LoadCharacteristicsByIdSuccess,
  LoadCharacteristicsError,
  LoadCharacteristicsPacksSuccess,
  LoadCharacteristicsSuccess,
  UpsertCharacteristics,
  UpsertCharacteristicsError,
  UpsertCharacteristicsSuccess,
} from '../actions';
import {Characteristics} from '../models';
import {addDays} from 'date-fns';

export type CharacteristicsState = EntityState<IdentifiedPack<Characteristics>>;

export const characteristicsAdapter: EntityAdapter<
  IdentifiedPack<Characteristics>
> = createEntityAdapter<IdentifiedPack<Characteristics>>({});
export const characteristicsInitialState: CharacteristicsState =
  characteristicsAdapter.getInitialState({});

export const characteristicsReducer = createReducer(
  characteristicsInitialState,

  on(
    LoadCharacteristics,
    DeleteCharacteristics,
    LoadCharacteristicsByFormId,
    (state, {}) => ({...state, loading: true}),
  ),

  on(LoadCharacteristicsByIdSuccess, (state, {entity}) =>
    characteristicsAdapter.upsertOne(
      {
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadCharacteristicsByIdSuccess, (state, {entity}) =>
    characteristicsAdapter.upsertOne(
      {
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadCharacteristicsSuccess,
    LoadCharacteristicsByFormIdSuccess,
    LoadCharacteristicsByFilterSuccess,
    LoadCharacteristicsByEntityTypeSuccess,
    (state, {entities}) => ({
      ...characteristicsAdapter.upsertMany(
        entities.map((statusOnly) => ({
          id: statusOnly.id,
          data: statusOnly,
          loading: false,
          error: null,
          validUntil: addDays(new Date(), 1),
        })),
        state,
      ),
      error: null,
    }),
  ),

  on(LoadCharacteristicsPacksSuccess, (state, {packs}) =>
    characteristicsAdapter.upsertMany(packs, state),
  ),

  on(DeleteCharacteristicsSuccess, (state, {id}) =>
    characteristicsAdapter.removeOne(id, state),
  ),

  on(UpsertCharacteristics, (state, {characteristics}) =>
    characteristicsAdapter.upsertOne(
      {
        id: characteristics.id,
        data: null,
        loading: true,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(UpsertCharacteristicsSuccess, (state, {characteristics}) =>
    characteristicsAdapter.upsertOne(
      {
        id: characteristics.id,
        data: characteristics,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(UpsertCharacteristicsError, (state, {id, error}) =>
    characteristicsAdapter.upsertOne(
      {
        id: id,
        loading: false,
        error: error,
        validUntil: null,
      },
      state,
    ),
  ),

  on(
    LoadCharacteristicsError,
    LoadCharacteristicsByIdError,
    LoadCharacteristicsByFormIdError,
    DeleteCharacteristicsError,
    LoadCharacteristicsByFilterError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(DiffCharacteristics, (state, {diffEntities}) =>
    characteristicsAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffCharacteristicsSuccess, (state, {characteristics}) =>
    characteristicsAdapter.upsertMany(
      characteristics.map((characteristic) => {
        return {
          id: characteristic.id,
          changes: {
            id: characteristic.id,
            data: characteristic,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffCharacteristicsError, (state, {diffEntities, error}) =>
    characteristicsAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
