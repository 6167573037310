import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'clipboard',
  pure: true,
})
export class ClipboardPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    return this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.sanitizer.bypassSecurityTrustHtml(
        '<i class="tsm-icon-checklist cursor-pointer" style="margin-right: 2px; font-size: 1.2em;" onclick="getValueOnClipboardPipe(this)" title="Copy to clipboard"></i>' +
          '<span>' +
          value +
          '</span>',
      ),
    );
  }
}
