import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {RegisterValue} from '../models';
import {
  DeleteRegisterValueError,
  DeleteRegisterValuesError,
  DeleteRegisterValuesSuccess,
  DeleteRegisterValueSuccess,
  DiffRegisterValue,
  DiffRegisterValueError,
  DiffRegisterValueSuccess,
  LoadBatchRegisterValueByIds,
  LoadBatchRegisterValueByIdsError,
  LoadBatchRegisterValueByIdsSuccess,
  LoadBatchRegisterValueByRegisterCodeAndCodeSuccess,
  LoadBatchRegisterValueByRegisterCodeAndNameSuccess,
  LoadRegisterValueById,
  LoadRegisterValueByIdError,
  LoadRegisterValueByIdSuccess,
  LoadRegisterValuesByFilter,
  LoadRegisterValuesByFilterError,
  LoadRegisterValuesByFilterSuccess,
  LoadRegisterValuesByRegisterId,
  LoadRegisterValuesByRegisterIdError,
  LoadRegisterValuesByRegisterIdSuccess,
  UpsertRegisterValue,
  UpsertRegisterValueError,
  UpsertRegisterValueSuccess,
} from '../actions';
import {addDays} from 'date-fns';

export interface RegisterValuesState
  extends EntityState<IdentifiedPack<RegisterValue>> {
  filters?: any[];
}

export const registerValuesAdapter: EntityAdapter<
  IdentifiedPack<RegisterValue>
> = createEntityAdapter<IdentifiedPack<RegisterValue>>({});
export const registerValuesInitialState: RegisterValuesState =
  registerValuesAdapter.getInitialState({});

export const registerValuesReducer = createReducer(
  registerValuesInitialState,

  on(LoadRegisterValuesByRegisterId, LoadRegisterValuesByFilter, (state) => ({
    ...state,
    loading: true,
  })),

  on(LoadRegisterValuesByRegisterIdSuccess, (state, {entities, registerId}) => {
    if (state.entities) {
      // odstraneni starych registerValues ze store podle registerId
      const entArray = Object.keys(state.entities).map(
        (it) => state.entities[it],
      );
      const registerRelatedValIds = entArray
        .filter(
          (ent) =>
            ent.data &&
            ent.data.register &&
            ent.data.register.id === registerId,
        )
        .map((ent) => ent.data.id);
      state = {
        ...registerValuesAdapter.removeMany(registerRelatedValIds, state),
      };
    }

    return {
      ...registerValuesAdapter.addMany(
        entities.map((val) => ({
          id: val.id,
          data: val,
          loading: false,
          error: null,
          validUntil: addDays(new Date(), 1),
        })),
        state,
      ),
      error: null,
      loading: false,
    };
  }),

  on(DeleteRegisterValueSuccess, (state, {id}) =>
    registerValuesAdapter.removeOne(id, state),
  ),

  on(LoadRegisterValueById, (state, {id}) =>
    registerValuesAdapter.upsertOne(
      {
        id: id,
        data: null,
        loading: true,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(UpsertRegisterValue, (state, {registerValue}) =>
    registerValuesAdapter.upsertOne(
      {
        id: registerValue.id,
        data: null,
        loading: true,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(
    LoadRegisterValueByIdSuccess,
    UpsertRegisterValueSuccess,
    (state, {registerValue}) =>
      registerValuesAdapter.upsertOne(
        {
          id: registerValue.id,
          data: registerValue,
          loading: false,
          error: null,
          validUntil: null,
        },
        state,
      ),
  ),

  on(
    LoadRegisterValueByIdError,
    UpsertRegisterValueError,
    DeleteRegisterValueError,
    (state, {id, error}) =>
      registerValuesAdapter.upsertOne(
        {
          id: id,
          loading: false,
          error: error,
          validUntil: null,
        },
        state,
      ),
  ),

  on(
    LoadRegisterValuesByFilterSuccess,
    LoadBatchRegisterValueByRegisterCodeAndCodeSuccess,
    LoadBatchRegisterValueByRegisterCodeAndNameSuccess,
    (state, {entities}) => ({
      ...registerValuesAdapter.upsertMany(
        entities.map((statusOnly) => ({
          id: statusOnly.id,
          data: statusOnly,
          loading: false,
          error: null,
          validUntil: addDays(new Date(), 1),
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadRegisterValuesByRegisterIdError,
    LoadRegisterValuesByFilterError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(LoadBatchRegisterValueByIds, (state, {ids}) => ({
    ...registerValuesAdapter.upsertMany(
      ids.map((id) => ({
        id: id,
        data: null,
        loading: true,
        error: null,
      })),
      state,
    ),
  })),

  on(LoadBatchRegisterValueByIdsSuccess, (state, {entities}) => ({
    ...registerValuesAdapter.upsertMany(
      entities.map((ent) => ({
        id: ent.id,
        data: ent,
        loading: false,
        error: null,
      })),
      state,
    ),
  })),

  on(LoadBatchRegisterValueByIdsError, (state, {ids, error}) => ({
    ...registerValuesAdapter.upsertMany(
      ids.map((id) => ({
        id: id,
        error: error,
        loading: false,
      })),
      state,
    ),
  })),

  on(DeleteRegisterValuesSuccess, (state, {ids}) =>
    registerValuesAdapter.removeMany(ids, state),
  ),

  on(DeleteRegisterValuesError, (state, {ids, error}) => ({
    ...registerValuesAdapter.upsertMany(
      ids.map((id) => ({
        id: id,
        loading: false,
        error: error,
        validUntil: null,
      })),
      state,
    ),
  })),

  on(DiffRegisterValue, (state, {diffEntities}) =>
    registerValuesAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffRegisterValueSuccess, (state, {registerValues}) =>
    registerValuesAdapter.upsertMany(
      registerValues.map((registerValue) => {
        return {
          id: registerValue.id,
          data: registerValue,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffRegisterValueError, (state, {diffEntities, error}) =>
    registerValuesAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
