export const en = {
  history: {
    deleteAll: 'Smazat historii',
    historyTooltip: 'Change history settings',
    history: 'history',
  },
  layoutTsm: {
    searchPlaceholder: 'Hledat v menu, zkratky, kontakty a další..',
    changeLanguage: 'Change app language',
    debugMessage: 'On/Off of application debugging',
    changeGraphics: 'Change graphic mode of App',
    addAnyItem: 'Add any item from the main menu (drag / drop)',
    clickToSave: 'Please click here to save your edits',
    loading: 'Loading',
    logOut: 'Log out',
    endYourSession: 'End your session',
    noMessages: 'You have no messages',
    deleteMsg: 'Delete message',
    notifications: 'Notifications',
    notificationsHotkeyText: 'Show notifications',
    notification: 'Notification',
    history: 'History',
    historyHotkeyText: 'Show the history panel',
    unreadOnly: 'Unread only',
    noAlerts: 'No alerts',
    deleteNotification: 'Delete notification',
    language: 'Language',
    debug: 'Debug',
    on: 'On',
    off: 'Off',
    czech: 'CZ',
    english: 'EN',
    all: 'All',
    colorMode: 'Color mode',
    mode: 'Mode',
    darkTheme: 'Dark',
    lightTheme: 'Light',
    switchToDark: 'Switch to Dark Mode',
    switchToDarkTip: 'Switch to dark mode save your eyes',
    switchToLight: 'Switch to Light Mode',
    switchToLightTip: 'Switch to light mode for optimal user experience',
    density: {
      density: 'Density',
      default: 'Default',
      comfortable: 'Comfortable',
      compact: 'Compact',
    },
    logoTooltip: "Go to the application's main page",
    logoAccessibilityText: 'Icon - App Logo',
    showHideMenu: 'Show/Hide Menu',
    myProfile: 'My Profile',
    goToYourProfile: 'Go to your profile',
    recent: 'Recent',
  },
  menuDesigner: {
    addMenuItem: 'Add menu item',
    editMenuItem: 'Edit menu item',
    saveAllMenu: 'Save the entire menu structure',
    menuSettings: 'Menu settings',
    resultMenu: 'The resulting menu that will be displayed',
    tmpMenu:
      'A storage area for menu items that will not be visible in the resulting menu.',
    emptyMenu:
      'Drag items from the left column here that you want to hide or temporarily set aside',
    uniqueCode:
      'Please enter a different code. The code must be unique across the entire menu!',
    spaceMenu:
      'The menu item must be moved to the gap between individual buttons.',
    newUserRegister: 'Create a menu item for the user register',
    listingType: 'Listing type',
    routerLink: 'Router link',
    url: 'URL',
    queryParams: 'Query parameters',
  },
  menuPinned: {
    header: 'Menu mode',
    overlayMode: 'Automatically hide menu (default)',
    overlayModeDescription:
      'The menu is automatically hidden when the user clicks outside the menu.',
    staticMode: 'Static menu',
    staticModeDescription:
      'The menu is always visible and does not hide when the user clicks outside the menu.',
  },
};
