import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/address-management/plugins-i18n';

export const addressManagementPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'address-management',
      loadChildren: () =>
        import('@tsm/address-management/components').then(
          (x) => x.AddressManagementComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/address-management/widgets').then(
          (x) => x.AddressManagementWidgetsModule,
        ),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/address-management/widgets/address-input/address-input'
            ).then((x) => x.AddressInputComponent),
          selector: 'tsm-address-input',
          privilege: ['!Am.Widget.tsm-address-input'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/address-management/widgets/address-input/address-input-editor'
                ).then((x) => x.AddressInputEditorComponent),
              editorSelector: 'tsm-address-input-editor',
              widgetOrLayout: 'widget',
              formField: 'address',
              categoryId: DesignerCategoryEnum.AddressManagement,
              name: translationShared.shared.widgets.addressSearch.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.addressSearch
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1, // Adjust according to the order rules
              icon: DesignerIconsEnum.search,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.addressSearch.inWidgetTitle,
                widget: {
                  type: 'tsm-address-input',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/address-management/widgets/region-layer-lov/region-layer-lov'
            ).then((x) => x.RegionLayerLovComponent),
          selector: 'tsm-region-layer-lov',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/address-management/widgets/region-layer-lov/region-layer-lov-editor'
                ).then((x) => x.RegionLayerLovEditorComponent),
              editorSelector: 'tsm-region-layer-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'regionLayer',
              categoryId: DesignerCategoryEnum.AddressManagement,
              name: translationShared.shared.widgets.regionLayerSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.regionLayerSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2, // Adjust according to the order rules
              icon: DesignerIconsEnum.select,
              value: {
                type: 'widget',
                title:
                  translationShared.shared.widgets.regionLayerSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-region-layer-lov',
                },
                config: {},
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/address-management/widgets').then(
          (x) => x.AddressManagementWidgetsModule,
        ),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/address-management/widgets/filters/region-filter'
            ).then((x) => x.RegionFilterComponent),
          selector: 'tsm-region-filter',
          name: translationPlugin.addressManagementPlugins.filters
            .tsmRegionFilter,
          description:
            translationPlugin.addressManagementPlugins.filters
              .tsmRegionFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/address-management/widgets/filters/region-layer-filter'
            ).then((x) => x.RegionLayerFilterComponent),
          selector: 'tsm-region-layer-filter',
          name: translationPlugin.addressManagementPlugins.filters
            .tsmRegionLayerFilter,
          description:
            translationPlugin.addressManagementPlugins.filters
              .tsmRegionLayerFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/address-management/widgets/filters/region-type-filter'
            ).then((x) => x.RegionTypeFilterComponent),
          selector: 'tsm-region-type-filter',
          name: translationPlugin.addressManagementPlugins.filters
            .tsmRegionTypeFilter,
          description:
            translationPlugin.addressManagementPlugins.filters
              .tsmRegionTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/address-management/widgets').then(
          (x) => x.AddressManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'addressLoader',
          name: translationPlugin.addressManagementPlugins.pipes.addressLoader,
          description:
            translationPlugin.addressManagementPlugins.pipes
              .addressLoaderDescription,
        },
        {
          selector: 'regionCode',
          name: translationPlugin.addressManagementPlugins.pipes.regionCode,
          description:
            translationPlugin.addressManagementPlugins.pipes
              .regionCodeDescription,
        },
        {
          selector: 'regionLayer',
          name: translationPlugin.addressManagementPlugins.pipes.regionLayer,
          description:
            translationPlugin.addressManagementPlugins.pipes
              .regionLayerDescription,
        },
        {
          selector: 'regionLayerCode',
          name: translationPlugin.addressManagementPlugins.pipes.regionLayer,
          description:
            translationPlugin.addressManagementPlugins.pipes
              .regionLayerDescription,
        },
        {
          selector: 'regionObjectLoader',
          name: translationPlugin.addressManagementPlugins.pipes
            .regionObjectLoader,
          description:
            translationPlugin.addressManagementPlugins.pipes
              .regionObjectLoaderDescription,
        },
      ],
    },
  ],
};
