import {Injectable} from '@angular/core';
import * as jexl from 'jexl';
import {memoizeFn} from '../utils/memoize';
import {Docs} from '@tsm/framework/datasource';

interface RegistryItem {
  fce: (...args: any[]) => any;
  docs: Docs;
  pure: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RegistryService {
  readonly transformsRegistry: {[key: string]: RegistryItem} = {};
  readonly functionsRegistry: {[key: string]: RegistryItem} = {};

  get transformsArray() {
    return Object.keys(this.transformsRegistry).map((key) => ({
      key,
      ...this.transformsRegistry[key],
    }));
  }

  get functionsArray() {
    return Object.keys(this.functionsRegistry).map((key) => ({
      key,
      ...this.functionsRegistry[key],
    }));
  }

  addTransform(
    key: string,
    fce: (...args: any[]) => any,
    docs: Docs,
    pure = true,
  ) {
    this.transformsRegistry[key] = {
      fce: pure ? memoizeFn(key, fce) : fce,
      docs,
      pure,
    };
  }

  addFunction(
    key: string,
    fce: (...args: any[]) => any,
    docs: Docs,
    pure = true,
  ) {
    this.functionsRegistry[key] = {
      fce: pure ? memoizeFn(key, fce) : fce,
      docs,
      pure,
    };
  }

  /**
   * Pokud neni nejaka funkce zaregistrovana v jexlu tak ji prida
   */
  addToJexl() {
    this.functionsArray
      .filter((x) => jexl._grammar.functions[x.key] == null)
      .forEach((x) => {
        jexl.addFunction(x.key, x.fce);
      });
    this.transformsArray
      .filter((x) => jexl._grammar.transforms[x.key] == null)
      .forEach((x) => {
        jexl.addTransform(x.key, x.fce);
      });
  }
}
