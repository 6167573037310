import {Injectable} from '@angular/core';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {ConfigService} from '@tsm/framework/config';
import {getUserId} from '@tsm/framework/functions';
import {
  FilterModel,
  GridDataHttpModel,
  PageSortFilterService,
} from '@tsm/listing-lib/service';
import {HistoryModel} from '../models';

@Injectable({
  providedIn: 'root',
})
export class HistoryDataService {
  constructor(
    private apiService: ApiService,
    private config: ConfigService,
    private pageSortFilterService: PageSortFilterService,
  ) {}

  getHistoryForCurrentUser(): Observable<
    Envelope<GridDataHttpModel<HistoryModel>>
  > {
    const url = `${
      this.config.value.apiUrls.dms
    }/v1/recently-used-entities/${getUserId()}`;
    return this.apiService.get(url);
  }

  getHistoryForCurrentUserAndEntityType(
    entityType: string,
  ): Observable<Envelope<GridDataHttpModel<HistoryModel>>> {
    const url = `${
      this.config.value.apiUrls.dms
    }/v1/recently-used-entities/${getUserId()}/${entityType}`;
    return this.apiService.get(url);
  }

  getHistoryFilterable(
    filters: FilterModel[],
    page: number,
    size: number,
  ): Observable<Envelope<GridDataHttpModel<HistoryModel>>> {
    const filtersStr =
      this.pageSortFilterService.getUrlFilterFromFilterModels(filters);
    const url = `${this.config.value.apiUrls.dms}/v1/recently-used-entities/filterable?${filtersStr}&page=${page}&size=${size}&sort=created,desc`;
    return this.apiService.get(url);
  }

  deleteHistoryForCurrentUser(): Observable<
    Envelope<GridDataHttpModel<HistoryModel>>
  > {
    const url = `${
      this.config.value.apiUrls.dms
    }/v1/recently-used-entities/delete/by-user-id/${getUserId()}`;
    return this.apiService.delete(url);
  }
}
