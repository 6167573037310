import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {NotificationTemplate} from '../models';
import {
  FilterModel,
  GridDataHttpModel,
  PageSortFilterService,
} from '@tsm/listing-lib/service';

@Injectable({
  providedIn: 'root',
})
export class NotificationTemplateService {
  private readonly NOTIFICATION_TEMPLATE = `notificationTemplate`;
  private readonly NOTIFICATION_TEMPLATE_BY_CODE = `templateByCode`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private pageSortFilterService: PageSortFilterService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  saveNotificationTemplate(
    notificationTemplate: NotificationTemplate,
  ): Observable<Envelope<NotificationTemplate>> {
    if (notificationTemplate.id) {
      return this.apiService.put<NotificationTemplate, NotificationTemplate>(
        `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}/${notificationTemplate.id}`,
        notificationTemplate,
      );
    } else {
      return this.apiService.post<NotificationTemplate, NotificationTemplate>(
        `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}`,
        notificationTemplate,
      );
    }
  }

  getNotificationTemplateByCode(
    code: string,
  ): Observable<Envelope<NotificationTemplate>> {
    return this.apiService.get<NotificationTemplate, NotificationTemplate>(
      `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}/${this.NOTIFICATION_TEMPLATE_BY_CODE}/${code}`,
    );
  }

  getNotificationTemplateById(
    id: string,
  ): Observable<Envelope<NotificationTemplate>> {
    return this.apiService.get<NotificationTemplate, NotificationTemplate>(
      `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}/${id}`,
    );
  }

  deleteNotificationTemplateById(
    id: string,
  ): Observable<Envelope<NotificationTemplate>> {
    return this.apiService.delete<NotificationTemplate, NotificationTemplate>(
      `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}/${id}`,
    );
  }

  getNotificationTemplatesByFilter(
    filters: FilterModel[],
  ): Observable<Envelope<NotificationTemplate[]>> {
    const pageSortFilter =
      this.pageSortFilterService.getUrlFilterFromFilterModels(filters);
    return this.apiService.get<NotificationTemplate[], NotificationTemplate[]>(
      `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}/filtering-list?${pageSortFilter}`,
    );
  }

  getNotificationIgnored(
    userId: string,
  ): Observable<Envelope<GridDataHttpModel<NotificationTemplate>>> {
    return this.apiService.get<
      GridDataHttpModel<NotificationTemplate>,
      GridDataHttpModel<NotificationTemplate>
    >(
      `${this.BASE_URL}/${this.NOTIFICATION_TEMPLATE}/user/${userId}/filtering-ignored`,
    );
  }
}
