import {NgModule, Optional, SkipSelf} from '@angular/core';
import {IMPORTS, PIPES, PROVIDERS} from './index';
import {PrivHelperService, SecurityService} from '@tsm/framework/security';
import {RegistryService} from '@tsm/framework/root';
import {translation} from './i18n';
import {RuntimeService} from '@tsm/runtime-info';

@NgModule({
  imports: [...IMPORTS],
  declarations: [...PIPES],
  providers: [...PROVIDERS, ...PIPES],
  exports: [...PIPES],
})
export class UserManagementServiceModule {
  constructor(
    privHelperService: PrivHelperService,
    securityService: SecurityService,
    registryService: RegistryService,
    runtimeService: RuntimeService,
    @Optional() @SkipSelf() parent: UserManagementServiceModule,
  ) {
    if (!parent) {
      registryService.addFunction(
        'hasPriv',
        (priv: string) => {
          return privHelperService.isCurrentUserHasRole(priv);
        },
        {
          detail: 'hasPriv(priv: string)',
          description: {
            about: translation.userManagementService.registry.hasPriv,
            params: [{name: 'priv', about: 'Privilege name'}],
            example: "hasPriv('Tick.Ticket.View')",
          },
        },
      );
      registryService.addFunction(
        'hasPolicy',
        (policyName: string, params: []) => {
          return securityService.hasAccess(policyName, params);
        },
        {
          detail: 'hasPolicy(policyName: string, params: []))',
          description: {
            about: translation.userManagementService.registry.hasPolicy,
            params: [
              {name: 'policyName', about: 'Policy name'},
              {name: 'params', about: 'Policy params'},
            ],
            example: "hasPolicy('somePolicy', [$context.ticket])",
          },
        },
      );
      registryService.addFunction(
        'hasRole',
        (roleCode: string) => {
          return runtimeService.hasRole(roleCode);
        },
        {
          detail: 'hasRole(roleCode: string))',
          description: {
            about: translation.userManagementService.registry.hasRole,
            params: [{name: 'roleCode', about: 'Role code'}],
            example: "hasRole('someRole', [$context.ticket])",
          },
        },
      );
      registryService.addToJexl();
    }
  }
}
