import {Pipe, PipeTransform} from '@angular/core';
import * as jexl from 'jexl';
import {getUserId} from '@tsm/framework/functions';

/**
 * Pipe slouzi pro vyhodnocovani vyrazu
 * https://www.npmjs.com/package/jexl
 */
@Pipe({
  name: 'safeEval',
  pure: true,
})
export class SafeEvalPipe implements PipeTransform {
  constructor() {}

  transform(value: any, expression: string): any {
    if (!expression) {
      console.error('Expression was not set!');
      return value;
    }

    try {
      let tmpExpression = expression;
      let copyValue = {
        currentUserId: getUserId(),
      };
      const convertKeys = expression
        .match(/#([\w.]+)/g)
        ?.filter((x) => !!x)
        .map((x) => x.replace('#', ''));
      Object.keys(value).forEach((k) => {
        if (convertKeys?.includes(k)) {
          const newKey = k.split('.').join('__');
          tmpExpression = tmpExpression.split('#' + k).join(newKey);
          copyValue = {
            ...copyValue,
            [newKey]: value[k],
          };
        } else {
          copyValue = {
            ...copyValue,
            [k]: value[k],
          };
        }
      });
      return jexl.evalSync(tmpExpression, copyValue);
    } catch (ex) {
      console.error('Error parsing:' + ex);
      return value;
    }
  }
}
