import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Config} from '@tsm/framework/config';
import {UserGroupSkill, User} from '../model';

@Injectable({
  providedIn: 'root',
})
export class UserGroupSkillService {
  private readonly BASE_URL: string;
  private USER_PARAMETERS: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.userManagement;
    this.USER_PARAMETERS = 'user-group-skill';
  }

  upsertMoreUserGroupSkill(
    rows: {
      user: User & {[key: string]: number}[];
    }[],
  ): Observable<Envelope<UserGroupSkill[]>> {
    const url = `${this.BASE_URL}/${this.USER_PARAMETERS}/update-many`;
    return this.apiService.post(url, rows);
  }
}
