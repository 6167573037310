import {Directive, Input, NgModule} from '@angular/core';

@Directive({
  selector: 'img[defaultImage]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src',
  },
})
export class ImageDefaultDirective {
  @Input() src: string;
  @Input() defaultImage: string;

  updateUrl() {
    this.src = this.defaultImage;
  }
}

@NgModule({
  declarations: [ImageDefaultDirective],
  exports: [ImageDefaultDirective],
})
export class ImageDefaultDirectiveModule {}
