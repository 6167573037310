import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';

import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';
import * as configurations from './tsm-controls-configuration';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/inventory/plugins-i18n';

export const inventoryPlugin: Plugin = {
  menus: [
    {
      key: 'inventory',
      priority: 1400,
      label: 'menu.inventory.inventory',
      icon: 'tsm-icon-briefcase',
      privilege: [
        'Inv.Product.ViewList',
        'Inv.Service.ViewList',
        'Inv.Resource.ViewList',
      ],
      items: [
        {
          key: 'products',
          label: 'menu.inventory.products',
          icon: 'subject',
          routerLink: ['/inventory/entity-instance-configuration/product'],
          listingType: 'tsm-entity-instance-configuration-product',
          privilege: ['Inv.Product.ViewList'],
        },
        {
          key: 'services',
          label: 'menu.inventory.services',
          icon: 'subject',
          routerLink: ['/inventory/entity-instance-configuration/service'],
          listingType: 'tsm-entity-instance-configuration-service',
          privilege: ['Inv.Service.ViewList'],
        },
        {
          key: 'resources',
          label: 'menu.inventory.resources',
          icon: 'subject',
          routerLink: ['/inventory/entity-instance-configuration/resource'],
          listingType: 'tsm-entity-instance-configuration-resource',
          privilege: ['Inv.Resource.ViewList'],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'inventory',
      loadChildren: () =>
        import('@tsm/inventory/components').then(
          (x) => x.InventoryComponentsModule,
        ),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/inventory/widgets').then((x) => x.InventoryWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/inventory/widgets/filters/entity-active-filter').then(
              (x) => x.EntityActiveFilterComponent,
            ),
          selector: 'tsm-entity-active-filter',
          name: translationPlugin.inventoryPlugins.filters
            .tsmEntityActiveFilter,
          description:
            translationPlugin.inventoryPlugins.filters
              .tsmEntityActiveFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/filters/entity-instance-configuration-filter'
            ).then((x) => x.EntityInstanceConfigurationFilterComponent),
          selector: 'tsm-entity-instance-configuration-filter',
          name: translationPlugin.inventoryPlugins.filters
            .tsmEntityInstanceConfigurationFilter,
          description:
            translationPlugin.inventoryPlugins.filters
              .tsmEntityInstanceConfigurationFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/filters/entity-instance-configuration-status-filter'
            ).then((x) => x.EntityInstanceConfigurationStatusFilterComponent),
          selector: 'tsm-entity-instance-configuration-status-filter',
          name: translationPlugin.inventoryPlugins.filters
            .tsmEntityInstanceConfigurationStatusFilter,
          description:
            translationPlugin.inventoryPlugins.filters
              .tsmEntityInstanceConfigurationStatusFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/inventory/widgets/filters/ip-address-filter').then(
              (x) => x.IpAddressFilterComponent,
            ),

          selector: 'tsm-ip-address-filter',
          name: translationPlugin.inventoryPlugins.filters.tsmIpAddressFilter,
          description:
            translationPlugin.inventoryPlugins.filters
              .tsmIpAddressFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/inventory/widgets').then((x) => x.InventoryWidgetsModule),
      definitions: [
        {
          selector: 'entityInstanceConfigurationKey',
          name: translationPlugin.inventoryPlugins.pipes
            .entityInstanceConfigurationKey,
          description:
            translationPlugin.inventoryPlugins.pipes
              .entityInstanceConfigurationKeyDescription,
        },
        {
          selector: 'entityInstanceConfigurationStatusCode',
          name: translationPlugin.inventoryPlugins.pipes
            .entityInstanceConfigurationStatusCode,
          description:
            translationPlugin.inventoryPlugins.pipes
              .entityInstanceConfigurationStatusCodeDescription,
        },
        {
          selector: 'entityInstanceConfigurationStatus',
          name: translationPlugin.inventoryPlugins.pipes
            .entityInstanceConfigurationStatus,
          description:
            translationPlugin.inventoryPlugins.pipes
              .entityInstanceConfigurationStatusDescription,
        },
        {
          selector: 'entityInstanceConfiguration',
          name: translationPlugin.inventoryPlugins.pipes
            .entityInstanceConfiguration,
          description:
            translationPlugin.inventoryPlugins.pipes
              .entityInstanceConfigurationDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/inventory/widgets').then((x) => x.InventoryWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-create-dependent/entity-instance-create-dependent'
            ).then((x) => x.EntityInstanceCreateDependentComponent),
          selector: 'tsm-entity-instance-create-dependent',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
              title: fluentSchema.title,
              tooltip: fluentSchema.widget?.tooltip,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-create-dependent/entity-instance-create-dependent-editor'
                ).then((x) => x.EntityInstanceCreateDependentEditorComponent),
              editorSelector: 'tsm-entity-instance-create-dependent-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets
                .createDependentInstanceButton.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.createDependentInstanceButton
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.button,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.createDependentInstanceButton
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-create-dependent',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-new/entity-instance-configuration-new'
            ).then((x) => x.EntityInstanceNewConfigurationContainerComponent),
          selector: 'tsm-entity-instance-new-container',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-relationship/entity-instance-relationship'
            ).then((x) => x.EntityInstanceRelationshipComponent),
          selector: 'tsm-entity-instance-relationship',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-relationship/entity-instance-relationship-editor'
                ).then((x) => x.EntityInstanceRelationshipEditorComponent),
              editorSelector: 'tsm-entity-instance-relationship-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets
                .instanceConfRelationshipBookmark.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets
                  .instanceConfRelationshipBookmark.widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets
                    .instanceConfRelationshipBookmark.inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-relationship',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-list-panel/entity-instance-configuration-list-panel'
            ).then((x) => x.EntityInstanceConfigurationListPanelComponent),
          selector: 'tsm-entity-instance-configuration-list-panel',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-configuration-list-panel/entity-instance-configuration-list-panel-editor'
                ).then(
                  (x) => x.EntityInstanceConfigurationListPanelEditorComponent,
                ),
              editorSelector:
                'tsm-entity-instance-configuration-list-panel-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets.instanceConfBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.instanceConfBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.instanceConfBookmark
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-configuration-list-panel',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-tree/entity-instance-tree'
            ).then((x) => x.EntityInstanceTreeComponent),
          selector: 'tsm-entity-instance-tree',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-tree/entity-instance-tree-editor'
                ).then((x) => x.EntityInstanceTreeEditorComponent),
              editorSelector: 'tsm-entity-instance-tree-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets.instanceConfTreeList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.instanceConfTreeList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.instanceConfTreeList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-tree',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-steps/entity-instance-steps'
            ).then((x) => x.EntityInstanceStepsComponent),
          selector: 'tsm-entity-instance-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-steps/entity-instance-steps-editor'
                ).then((x) => x.EntityInstanceStepsEditorComponent),
              editorSelector: 'tsm-entity-instance-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets.instanceConfStatusBar
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.instanceConfStatusBar
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.instanceConfStatusBar
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-steps',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-cost/entity-instance-configuration-cost'
            ).then((x) => x.EntityInstanceConfigurationCostComponent),
          selector: 'tsm-entity-instance-configuration-cost',
          privilege: ['!Inv.Widget.tsm-entity-instance-configuration-cost'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-configuration-cost/entity-instance-configuration-cost-editor'
                ).then((x) => x.EntityInstanceConfigurationCostEditorComponent),
              editorSelector: 'tsm-entity-instance-configuration-cost-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets.instanceConfCostCard
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.instanceConfCostCard
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.card,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.instanceConfCostCard
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-configuration-cost',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-lov/entity-instance-configuration-lov'
            ).then((x) => x.EntityInstanceConfigurationLovComponent),
          selector: 'tsm-entity-instance-configuration-lov',
          privilege: ['!Inv.Widget.tsm-entity-instance-configuration-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-configuration-lov/entity-instance-configuration-lov-editor'
                ).then((x) => x.EntityInstanceConfigurationLovEditorComponent),
              editorSelector: 'tsm-entity-instance-configuration-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Inventory,
              formField: 'entityInstanceConfiguration',
              name: translationShared.shared.widgets.instanceConfSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.instanceConfSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.instanceConfSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-configuration-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-list-widget/entity-instance-configuration-list-widget'
            ).then((x) => x.EntityInstanceConfigurationListWidgetComponent),
          selector: 'tsm-entity-instance-configuration-list-widget',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-configuration-list-widget/entity-instance-configuration-list-widget-editor'
                ).then(
                  (x) => x.EntityInstanceConfigurationListWidgetEditorComponent,
                ),
              editorSelector:
                'tsm-entity-instance-configuration-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets.instanceConfList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.instanceConfList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.instanceConfList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-configuration-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: '20',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-list-widget-new/entity-instance-configuration-list-widget-new'
            ).then((x) => x.EntityInstanceConfigurationListWidgetNewComponent),
          selector: 'tsm-entity-instance-configuration-list-widget-new',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/entity-instance-configuration-list-widget-new/entity-instance-configuration-list-widget-new-editor'
                ).then(
                  (x) =>
                    x.EntityInstanceConfigurationListWidgetNewEditorComponent,
                ),
              editorSelector:
                'tsm-entity-instance-configuration-list-widget-new-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Inventory,
              name: translationShared.shared.widgets.productConfList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.productConfList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.productConfList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-instance-configuration-list-widget-new',
                },
                type: 'layout',
                config: {},
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/inventory/widgets/ip-address/ip-address').then(
              (x) => x.IpAddressComponent,
            ),
          selector: 'tsm-ip-address',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/inventory/widgets/ip-address/ip-address-editor'
                ).then((x) => x.IpAddressEditorComponent),
              editorSelector: 'tsm-ip-address-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Inventory,
              formField: 'ipAddress',
              name: translationShared.shared.widgets.ipAddressInput.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ipAddressInput
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 11,
              icon: DesignerIconsEnum.input,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.ipAddressInput.inWidgetTitle,
                widget: {
                  type: 'tsm-ip-address',
                  notitle: true,
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-detail-widget'
            ).then((x) => x.EntityInstanceConfigurationDetailWidgetComponent),
          selector: 'tsm-entity-instance-configuration-detail-widget',
        },
        {
          selector: 'tsm-entity-instance-configuration-card',
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-card'
            ).then((x) => x.EntityInstanceConfigurationCardComponent),
        },
        {
          selector: 'tsm-entity-instance-configuration-select-dialog',
          loadComponent: () =>
            import(
              '@tsm/inventory/widgets/entity-instance-configuration-select-dialog'
            ).then((x) => x.EntityInstanceConfigurationSelectDialogComponent),
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'EntityInstance',
      url: (
        entityId,
        apiService: ApiService,
        configService: ConfigService<Config>,
      ) => {
        return apiService
          .get<
            {type: string},
            {
              type: string;
            }
          >(
            configService.value.apiUrls.inventory +
              '/v1/entity-instance-configurations/entity-instance/' +
              entityId +
              '/latest',
          )
          .pipe(
            map((x) => {
              if (x.success) {
                if (x.data.type == 'PRODUCT') {
                  return (
                    '/inventory/entity-instance-configurations/product/' +
                    entityId
                  );
                } else if (x.data.type == 'SERVICE') {
                  return (
                    '/inventory/entity-instance-configurations/service/' +
                    entityId
                  );
                } else if (x.data.type == 'RESOURCE') {
                  return (
                    '/inventory/entity-instance-configurations/resource/' +
                    entityId
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }),
          );
      },
      priority: 2,
    },
    {
      entityType: 'EntityInstance',
      url: (entityId) => {
        return of('/inventory/entity-instance-configurations/' + entityId);
      },
      priority: 1,
    },
  ],
  layoutControls: [
    configurations.controlsProductNewConfiguration,
    configurations.controlsServiceNewConfiguration,
    configurations.controlsResourceNewConfiguration,
    configurations.controlsSkillNewConfiguration,
    configurations.controlsWorkNewConfiguration,
    configurations.controlsOthersNewConfiguration,
    configurations.controlsProductDetailConfiguration,
    configurations.controlsServiceDetailConfiguration,
    configurations.controlsResourceDetailConfiguration,
    configurations.controlsSkillDetailConfiguration,
    configurations.controlsWorkDetailConfiguration,
    configurations.controlsOthersDetailConfiguration,
    configurations.controlsEntityInstanceConfigurationTopbarConfiguration,
    configurations.controlsEntityInstanceConfigurationBulkTopbarConfiguration,
  ],
};
