<div
  [ngClass]="
    'p-paginator p-component p-widget-header p-unselectable-text p-helper-clearfix'
  "
  (click)="openPaginator($event)"
>
  <a
    [attr.tabindex]="isFirstPage ? null : '0'"
    class="p-paginator-first p-paginator-element p-state-default p-corner-all pi pi-angle-double-left"
    (click)="changePageToFirst($event)"
    (keydown.enter)="changePageToFirst($event)"
    [ngClass]="{'p-disabled': isFirstPage}"
    [tabindex]="isFirstPage ? -1 : null"
  >
    <!-- <span class="p-paginator-icon pi pi-step-backward"></span> -->
  </a>
  <a
    tabindex="0"
    [attr.tabindex]="isFirstPage ? null : '0'"
    class="p-paginator-prev p-paginator-element p-state-default p-corner-all pi pi-angle-left"
    (click)="changePageToPrev($event)"
    (keydown.enter)="changePageToPrev($event)"
    [ngClass]="{'p-disabled': isFirstPage}"
    [tabindex]="isFirstPage ? -1 : null"
  >
    <!-- <span class="p-paginator-icon pi pi-caret-left"></span> -->
  </a>
  <span
    class="p-paginator-pages cursor-pointer"
    pTooltip="{{ translation.tablePaginator.paginatorSettings | transloco }}"
    tooltipPosition="top"
    [showDelay]="300"
  >
    {{ firstItemIndex }} {{ translation.tablePaginator.to | transloco }}
    {{ lastItemIndex }} {{ translation.tablePaginator.of | transloco }}
    <span [attr.data-cy]="'dtl-table-paginator-total-count'">{{ total }}</span>
  </span>
  <a
    [attr.tabindex]="isLastPage ? null : '0'"
    class="p-paginator-next p-paginator-element p-state-default p-corner-all pi pi-angle-right"
    (click)="changePageToNext($event)"
    (keydown.enter)="changePageToNext($event)"
    [ngClass]="{'p-disabled': isLastPage}"
    [tabindex]="isLastPage ? -1 : null"
  >
    <!-- <span class="p-paginator-icon pi pi-caret-right"></span> -->
  </a>
  <a
    [attr.tabindex]="isLastPage ? null : '0'"
    class="p-paginator-last p-paginator-element p-state-default p-corner-all pi pi-angle-double-right"
    (click)="changePageToLast($event)"
    (keydown.enter)="changePageToLast($event)"
    [ngClass]="{'p-disabled': isLastPage}"
    [tabindex]="isLastPage ? -1 : null"
  >
    <!-- <span class="p-paginator-icon pi pi-step-forward"></span> -->
  </a>
</div>
<dtl-overlayPanel
  #oPpaginator
  [showCloseIcon]="true"
  [closePrevious]="closePrevious"
  appendTo="body"
  styleClass="paginator-overlay-panel"
>
  <ng-template pTemplate>
    <dtl-form-field-container [labelPosition]="'top'">
      <dtl-form-field-label>
        {{ translation.tablePaginator.pageSize | transloco }}
      </dtl-form-field-label>
      <dtl-form-input-number
        [class.ng-required]="true"
        [formControl]="pageSizeControl"
        (changeFocus)="changeFocusPageSize($event)"
        [readonly]="false"
        [min]="1"
        [max]="200"
        [fractionDigits]="0"
      >
      </dtl-form-input-number>
      <dtl-form-field-errors
        [errors]="pageSizeControl?.errors"
        [touched]="pageSizeControl?.touched"
      ></dtl-form-field-errors>
    </dtl-form-field-container>

    <dtl-form-field-container [labelPosition]="'top'">
      <dtl-form-field-label>
        {{ translation.tablePaginator.pageSettings | transloco }}
      </dtl-form-field-label>
      <dtl-form-input-number
        [class.ng-required]="true"
        [formControl]="pageControl"
        (changeFocus)="changeFocusPage($event)"
        [readonly]="false"
        [min]="1"
        [max]="lastPage"
        [fractionDigits]="0"
      >
      </dtl-form-input-number>
      <dtl-form-field-errors
        [errors]="pageControl?.errors"
        [touched]="pageControl?.touched"
      ></dtl-form-field-errors>
    </dtl-form-field-container>
    <div class="italic thin px-1 pb-2">
      {{ translation.tablePaginator.minPage | transloco }} 1.
      {{ translation.tablePaginator.maxPage | transloco }} {{ lastPage }}.
    </div>
    <p-footer>
      <div class="text-right">
        <button
          pButton
          pRipple
          icon="p-icon-done"
          class="p-button-success p-button-rounded"
          pTooltip="{{ translationShared.shared.save | transloco }}"
          (click)="setPage(); $event.stopPropagation()"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-times"
          class="p-button-plain p-button-rounded p-button-text"
          pTooltip="{{ translationShared.shared.cancel | transloco }}"
          (click)="oPpaginator.hide(); $event.stopPropagation()"
        ></button>
      </div>
    </p-footer>
  </ng-template>
</dtl-overlayPanel>
