import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {PrimeNGConfig, PrimeTemplate, SelectItem} from 'primeng/api';
import {DomHandler} from 'primeng/dom';
import {Observable, of, Subject} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {ApiService} from '@tsm/framework/http';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '@tsm/shared-i18n';
import {PrivHelperService} from '@tsm/framework/security';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {ScrollerItemOptions} from 'primeng/scroller';
import {Overlay} from 'primeng/overlay';
import {distinctArrays} from '@tsm/framework/functions';

export const LABEL_TAG_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DataTagsComponent),
  multi: true,
};

@Component({
  selector: 'tsm-data-tags',
  templateUrl: './data-tags.component.html',
  styleUrls: ['./data-tags.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        }),
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
  host: {
    class: 'dtl-form-field-input',
    '[class.p-state-filled]': 'filled',
    '[class.p-state-focus]': 'focus && !disabled',
    '[class.p-state-readonly]': 'disabled === true',
  },
  providers: [LABEL_TAG_VALUE_ACCESSOR],
})
export class DataTagsComponent
  extends AbstractControlValueAccessor
  implements OnChanges, AfterViewChecked, AfterContentInit
{
  @Input() optionsSelectProperty: string;
  @Input() suggestions: SelectItem[] = [];
  @Input() url: string; // adresa, na kterou se mam dotazovat
  @Input() urlRedirect: string; // adresa, pro otevreni noveho okna s filtrem
  @Input() parentPriv: string;
  @Input() canShowFilteredPage = true;
  @Input() upperCase = true;

  @HostBinding('class.hide-dropdown-button')
  @Input()
  hideDropdownButton = false;

  searchString = new Subject<string>();
  suggestions$: Observable<{label: string; value: string}[]>;
  translation = translation;

  /*********** PRIMENG *************/
  @Input() minLength = 1;

  @Input() delay = 300;

  @Input() virtualScroll = false;
  @Input() group: boolean;
  @Input() virtualScrollOptions: ScrollerItemOptions;

  @Input() style: any;

  @Input() panelStyle: any;

  @Input() styleClass: string;

  @Input() panelStyleClass: string;

  @Input() inputStyle: any;

  @Input() inputId: string;

  @Input() inputStyleClass: string;

  @Input() placeholder: string;

  @Input() readonly: boolean;

  _disabled: boolean;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(val: boolean) {
    if (val == false) {
      if (
        this.parentPriv == null ||
        this.privHelperService.isCurrentUserHasRole(
          this.parentPriv + '.Edit#DataTags',
        )
      ) {
        this._disabled = val;
      } else {
        this._disabled = true;
      }
    } else {
      this._disabled = val;
    }
  }

  @Input() maxlength: number;

  @Input() name: string;

  @Input() required: boolean;

  @Input() appendTo: any; // = 'body';

  @Input() autoHighlight: boolean;

  @Input() type = 'text';

  @Input() autoZIndex = true;

  @Input() baseZIndex = 0;

  @Input() ariaLabel: string;

  @Input() ariaLabelledBy: string;

  @Input() dropdownIcon = 'pi pi-chevron-down';

  @Input() unique = true;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @Output() onUnselect: EventEmitter<any> = new EventEmitter();

  @Output() onFocusChange: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Output() onDropdownClick: EventEmitter<any> = new EventEmitter();

  @Output() onClear: EventEmitter<any> = new EventEmitter();

  @Output() onKeyUp: EventEmitter<any> = new EventEmitter();

  @Output() onShow: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  @Input() scrollHeight = '200px';

  @Input() dropdown = true;

  @Input() dropdownMode = 'blank';

  @Input() tabindex: number;

  @Input() dataKey: string;

  @Input() emptyMessage: string;

  @Input() showTransitionOptions = '225ms ease-out';

  @Input() hideTransitionOptions = '195ms ease-in';

  @Input() autofocus: boolean;

  @Input() autocomplete = 'off';

  @Input() allowAddingNewTag = true;

  @Input() newTagText = this.translation.shared.newTag;

  @ViewChild('multiIn') multiInputEL: ElementRef;

  @ViewChild('multiContainer') multiContainerEL: ElementRef;

  @ViewChild('ddBtn') dropdownButton: ElementRef;

  @ViewChild('overlay') overlayViewChild: Overlay;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  // pokud animuju (probiha otevirani - nez se otevre nebo probiha zavirani nez se plne zavre), tak return;
  isAnimating = false;

  itemsWrapper: HTMLDivElement;

  itemTemplate: TemplateRef<any>;

  selectedItemTemplate: TemplateRef<any>;

  timeout: any;

  overlayVisible = false;

  documentClickListener: any;

  suggestionsUpdated: boolean;

  highlightOption: {label: string; value: string};

  highlightOptionChanged: boolean;

  focus = false;

  inputClick: boolean;

  inputKeyDown: boolean;

  noResults: boolean;

  loading: boolean;

  forceSelectionUpdateModelTimeout: any;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    private translocoService: TranslocoService,
    public cd: ChangeDetectorRef,
    private apiService: ApiService,
    public config: PrimeNGConfig,
    private zone: NgZone,
    public privHelperService: PrivHelperService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(cd);
  }

  ngOnInit(): void {
    let suggestions$: Observable<{label: string; value: any}[]>;
    if (this.url) {
      suggestions$ = this.searchString.asObservable().pipe(
        switchMap((searchString) => {
          return this.apiService
            .get<
              Array<string>,
              Array<string>
            >(this.url + (searchString ? '?search=' + searchString : ''))
            .pipe(
              filter((x) => !!x && x.success),
              map((x) => {
                if (x.data) {
                  const data = this.optionsSelectProperty
                    ? x.data.map((x) => x[this.optionsSelectProperty])
                    : x.data;
                  const resultArray = data
                    .filter((y) => !(this.value ?? []).includes(y))
                    .sort((a, b) => a.localeCompare(b))
                    .map((z) => ({label: z, value: z}));
                  if (
                    this.allowAddingNewTag &&
                    searchString !== '' &&
                    !resultArray.some((j) => j.value === searchString) &&
                    !this.value.some((j) => j === searchString) &&
                    (this.parentPriv == null ||
                      this.privHelperService.isCurrentUserHasRole(
                        this.parentPriv + '.Edit#DataTags.Add',
                      ))
                  ) {
                    resultArray.unshift({
                      label:
                        searchString +
                        (this.newTagText == null || this.newTagText === ''
                          ? ''
                          : ' (' +
                            this.translocoService.translate(this.newTagText) +
                            ')'),
                      value: searchString,
                    });
                  }
                  return resultArray;
                }
                return [];
              }),
            );
        }),
      );
    } else {
      suggestions$ = this.searchString.asObservable().pipe(
        switchMap((searchString) => {
          const resultArray = this.suggestions
            ?.map((x) => ({label: x.label, value: x.value}))
            .filter((x) =>
              x.label.toLowerCase().includes(searchString.toLowerCase()),
            )
            .filter((x) => !this.valOptions.some((y) => y.value === x.value))
            .sort((a, b) => a.label.localeCompare(b.label));

          if (
            this.allowAddingNewTag &&
            searchString !== '' &&
            !resultArray.find((j) => j.value === searchString) &&
            (this.parentPriv == null ||
              this.privHelperService.isCurrentUserHasRole(
                this.parentPriv + '.Edit#DataTags.Add',
              ))
          ) {
            resultArray.unshift({
              label:
                searchString +
                (this.newTagText == null || this.newTagText === ''
                  ? ''
                  : ' (' +
                    this.translocoService.translate(this.newTagText) +
                    ')'),
              value: searchString,
            });
          }
          return of(resultArray);
        }),
      );
    }
    this.suggestions$ = suggestions$.pipe(
      tap((y) => {
        if (y != null && this.loading) {
          this.highlightOption = null;
          if (y.length) {
            this.noResults = false;
            this.show();
            this.suggestionsUpdated = true;
            if (this.autoHighlight) {
              this.highlightOption = y[0];
            }
          } else {
            this.noResults = true;
            if (this.emptyMessage) {
              this.show();
              this.suggestionsUpdated = true;
            } else {
              this.hide();
            }
          }
          this.loading = false;
        }
      }),
    );
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.url && simpleChanges.url.currentValue) {
      this.suggestions$ = this.searchString.asObservable().pipe(
        switchMap((searchString) => {
          return this.apiService
            .get<
              Array<string>,
              Array<string>
            >(this.url + (searchString ? '?search=' + searchString : ''))
            .pipe(
              filter((x) => !!x && x.success),
              map((x) => {
                if (x.data) {
                  const resultArray = x.data
                    .filter((y) => !(this.value ?? []).includes(y))
                    .sort((a, b) => a.localeCompare(b))
                    .map((z) => ({label: z, value: z}));
                  if (
                    this.allowAddingNewTag &&
                    searchString !== '' &&
                    !resultArray.find((j) => j.value === searchString) &&
                    (this.parentPriv == null ||
                      this.privHelperService.isCurrentUserHasRole(
                        this.parentPriv + '.Edit#DataTags.Add',
                      ))
                  ) {
                    resultArray.unshift({
                      label:
                        searchString +
                        (this.newTagText == null || this.newTagText === ''
                          ? ''
                          : ' (' +
                            this.translocoService.translate(this.newTagText) +
                            ')'),
                      value: searchString,
                    });
                  }
                  return resultArray;
                }
                return [];
              }),
              tap((y) => {
                if (y != null && this.loading) {
                  this.highlightOption = null;
                  if (y.length) {
                    this.noResults = false;
                    this.show();
                    this.suggestionsUpdated = true;
                    if (this.autoHighlight) {
                      this.highlightOption = y[0];
                    }
                  } else {
                    this.noResults = true;
                    if (this.emptyMessage) {
                      this.show();
                      this.suggestionsUpdated = true;
                    } else {
                      this.hide();
                    }
                  }
                  this.loading = false;
                }
              }),
            );
        }),
      );
    } else if (
      simpleChanges.suggestions &&
      Array.isArray(simpleChanges.suggestions.currentValue)
    ) {
      this.suggestions$ = this.searchString.asObservable().pipe(
        switchMap((searchString) => {
          let resultArray = this.suggestions
            ?.map((x) => ({label: x.label, value: x.value}))
            .filter((x) =>
              x.label.toLowerCase().includes(searchString.toLowerCase()),
            )
            .filter((x) => !this.valOptions.some((y) => y.value === x.value))
            .sort((a, b) => a.label.localeCompare(b.label));

          // Allow adding a new tag if it's not found in the suggestions
          if (
            this.allowAddingNewTag &&
            searchString !== '' &&
            !resultArray.find((j) => j.value === searchString) &&
            (this.parentPriv == null ||
              this.privHelperService.isCurrentUserHasRole(
                this.parentPriv + '.Edit#DataTags.Add',
              ))
          ) {
            resultArray.unshift({
              label:
                searchString +
                (this.newTagText == null || this.newTagText === ''
                  ? ''
                  : ' (' +
                    this.translocoService.translate(this.newTagText) +
                    ')'),
              value: searchString,
            });
          }

          return of(resultArray);
        }),
      );
    }
  }

  ngAfterViewChecked() {
    //Use timeouts as since Angular 4.2, AfterViewChecked is broken and not called after panel is updated
    if (this.suggestionsUpdated && this.overlayViewChild) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.overlayViewChild) {
            this.overlayViewChild.alignOverlay();
          }
        }, 1);
        this.suggestionsUpdated = false;
      });
    }

    if (this.highlightOptionChanged) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.overlayViewChild && this.itemsWrapper) {
            let listItem = DomHandler.findSingle(
              this.overlayViewChild.overlayViewChild.nativeElement,
              'li.p-highlight',
            );

            if (listItem) {
              DomHandler.scrollInView(this.itemsWrapper, listItem);
            }
          }
        }, 1);
        this.highlightOptionChanged = false;
      });
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItem':
          this.selectedItemTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  setReadonlyState(readonly: boolean) {
    this.disabled = readonly;
    super.setReadonlyState(readonly);
  }

  writeValue(value: string[]): void {
    if (this.url) {
      this.valOptions = Array.isArray(value)
        ? value.map((x) => ({label: x, value: x}))
        : [];
    } else {
      this.valOptions = Array.isArray(value)
        ? value.map((x) => ({
            label: this.suggestions?.find((y) => y.value === x)?.label ?? x,
            value: x,
          }))
        : [];
    }
    super.writeValue(value);
  }

  onInput(event: Event) {
    // When an input element with a placeholder is clicked, the onInput event is invoked in IE.
    if (!this.inputKeyDown && DomHandler.isIE()) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const value = (<HTMLInputElement>event.target).value;

    if (value.length >= this.minLength) {
      this.timeout = setTimeout(() => {
        this.search(event, value);
      }, this.delay);
    } else {
      this.hide();
    }
    this.updateFilledState();
    this.inputKeyDown = false;
  }

  onInputClick(event: MouseEvent) {
    if (this.isAnimating) {
      return;
    }
    if (this.documentClickListener) {
      this.inputClick = true;
    }
  }

  search(event: any, query: string) {
    //allow empty string but not undefined or null
    if (query === undefined || query === null) {
      return;
    }
    this.loading = true;
    this.searchString.next(this.upperCase ? query.toUpperCase() : query);
  }

  valOptions = [];

  selectItem(option: any, focus: boolean = true) {
    if (this.forceSelectionUpdateModelTimeout) {
      clearTimeout(this.forceSelectionUpdateModelTimeout);
      this.forceSelectionUpdateModelTimeout = null;
    }

    this.multiInputEL.nativeElement.value = '';

    this.valOptions = distinctArrays('value', [option], this.valOptions);
    this.val = this.valOptions.map((x) =>
      typeof x === 'string' ? x : x.value,
    );

    this.onSelect.emit(option);
    this.updateFilledState();
    this.hide();
    if (focus) {
      this.focusInput();
    }
  }

  show() {
    if (this.multiInputEL) {
      const hasFocus =
        document.activeElement == this.multiInputEL.nativeElement;

      if (!this.overlayVisible && hasFocus) {
        this.overlayVisible = true;
      }
    }
  }

  onOverlayAnimationDone(event: AnimationEvent) {
    this.isAnimating = false;
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    this.isAnimating = true;
    if (event.toState === 'visible') {
      this.itemsWrapper = DomHandler.findSingle(
        this.overlayViewChild.overlayViewChild.nativeElement,
        this.virtualScroll ? '.p-scroller' : '.p-autocomplete-panel',
      );
      // this.virtualScroll && this.scroller?.setContentEl(this.itemsViewChild.nativeElement);

      this.itemsWrapper.addEventListener('mousedown', (e) => {
        e.preventDefault();
      });
      this.onShow.emit(event);
    }
  }

  hide() {
    this.overlayVisible = false;
    this.onHide.emit(event);
    this.cdr.markForCheck();
  }

  handleDropdownClick(event) {
    if (this.isAnimating) {
      return;
    }
    if (!this.overlayVisible) {
      this.focusInput();
      const queryValue = this.multiInputEL.nativeElement.value;

      if (this.dropdownMode === 'blank') {
        this.search(event, '');
      } else if (this.dropdownMode === 'current') {
        this.search(event, queryValue);
      }

      this.onDropdownClick.emit({
        originalEvent: event,
        query: queryValue,
      });
    } else {
      this.hide();
    }
  }

  focusInput() {
    this.multiInputEL.nativeElement.focus();
  }

  removeItem(item: any) {
    const itemIndex = DomHandler.index(item);
    const removedValue = this.value[itemIndex];
    this.valOptions = this.valOptions.filter((val, i) => i != itemIndex);
    this.val = this.value.filter((val, i) => i != itemIndex);
    this.updateFilledState();
    this.onUnselect.emit(removedValue);
  }

  showFilteredPage(item) {
    if (this.canShowFilteredPage) {
      const lastParamKey = this.route.routeConfig.path.split('/:')[0];
      const lastIndex =
        window.location.href.indexOf(lastParamKey + '/') +
        (lastParamKey.includes('detail') ? 0 : lastParamKey.length);
      const filter = `[{%22field%22:%22dataTags%22,%22operator%22:%22in%22,%22value%22:[%22${item}%22]}]`;
      let url = `${window.location.href.slice(
        0,
        lastIndex,
      )}?queryFilters=${filter}`;
      if (this.urlRedirect) {
        url = `${this.urlRedirect}?queryFilters=${filter}`;
      }
      window.open(url, '_blank', 'noopener noreferrer');
    }
  }

  onKeydown(event, suggestions: {label: string; value: string}[]) {
    if (this.overlayVisible) {
      const highlightItemIndex = this.findOptionIndex(
        this.highlightOption,
        suggestions,
      );

      switch (event.which) {
        //down
        case 40:
          if (highlightItemIndex != -1) {
            const nextItemIndex = highlightItemIndex + 1;
            if (nextItemIndex != suggestions.length) {
              this.highlightOption = suggestions[nextItemIndex];
              this.highlightOptionChanged = true;
            }
          } else {
            this.highlightOption = suggestions[0];
          }

          event.preventDefault();
          break;

        //up
        case 38:
          if (highlightItemIndex > 0) {
            const prevItemIndex = highlightItemIndex - 1;
            this.highlightOption = suggestions[prevItemIndex];
            this.highlightOptionChanged = true;
          }

          event.preventDefault();
          break;

        //enter
        case 13:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
            this.hide();
          }
          event.preventDefault();
          break;

        //escape
        case 27:
          this.hide();
          event.preventDefault();
          event.stopPropagation();
          break;

        //tab
        case 9:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
          }
          this.hide();
          break;

        //backspace
        case 8:
          if (
            this.value &&
            this.value.length &&
            !this.multiInputEL.nativeElement.value
          ) {
            // this.value = [...this.value];
            // const removedValue = this.value.pop();
            // this.onModelChange(distinctArrays(null, this.value.map(x => x.value)));
            // this.updateFilledState();
            // this.onUnselect.emit(removedValue);
          }
          break;
      }
    } else {
      if (event.which === 40 && suggestions) {
        this.search(event, event.target.value);
      } else if (event.which === 40 && this.overlayVisible === false) {
        this.handleDropdownClick(event);
      }
    }

    this.inputKeyDown = true;
  }

  onKeyup(event) {
    this.onKeyUp.emit(event);
  }

  onInputFocus(event) {
    this.focus = true;
    this.onFocusChange.emit(event);
  }

  onInputBlur(event) {
    this.focus = false;
    this.onBlur.emit(event);
  }

  onInputChange(event, suggestions: {label: string; value: string}[]) {
    if (suggestions) {
      let valid = false;
      const inputValue = event.target.value.trim();

      if (suggestions) {
        for (const suggestion of suggestions) {
          const itemValue = suggestion.label;
          if (itemValue && inputValue === itemValue.trim()) {
            valid = true;
            this.forceSelectionUpdateModelTimeout = setTimeout(() => {
              this.selectItem(suggestion, false);
            }, 250);
            break;
          }
        }
      }

      if (!valid) {
        this.multiInputEL.nativeElement.value = '';
        this.onClear.emit(event);
      }
    }
  }

  onInputPaste(
    event: ClipboardEvent,
    suggestions: {label: string; value: string}[],
  ) {
    this.onKeydown(event, suggestions);
  }

  findOptionIndex(
    option,
    suggestions: {label: string; value: string}[],
  ): number {
    let index = -1;
    if (suggestions) {
      for (let i = 0; i < suggestions.length; i++) {
        if (!!option && option.label === suggestions[i].label) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  updateFilledState() {
    this.filled =
      !!(this.value && this.value.length) ||
      (this.multiInputEL &&
        this.multiInputEL.nativeElement &&
        this.multiInputEL.nativeElement.value != '');
    this.cd.markForCheck();
  }

  getAriaLabel(): string {
    return this.value?.length == 0
      ? 'Data Tags are empty, use dropdown menu to choose from list'
      : 'Current Data Tags: ' +
          this.valOptions
            .map((x) => (typeof x === 'string' ? x : x.label))
            .join(',') +
          '. Use dropdown menu to choose from list';
  }

  ngOnDestroy() {
    if (this.forceSelectionUpdateModelTimeout) {
      clearTimeout(this.forceSelectionUpdateModelTimeout);
      this.forceSelectionUpdateModelTimeout = null;
    }
  }
}
