export const en = {
  comment: 'Comment',
  worklog: 'Worklog',
  dlqService: {
    massUpdateSuccess: 'Mass update has been successful',
    massUpdateError: 'An error occurred during mass update',
  },
  dmsService: {
    succNotifTmplSave: 'Saving template finished successfully',
    unSuccNotifTmplSave: 'Error occurred during saving template',
    succNotifTmplDelete: 'Delete template finished successfully',
    unSuccNotifTmplDelete: 'Error occurred during delete template',
    succNotifTmplDiff: 'Saving template diff finished with success',
    unsuccNotifTmplDiff: 'Error occurred during saving template diff.',
    succNotifRuleSave: 'Saving rule finished successfully',
    unSuccNotifRuleSave: 'Error occurred during saving rule',
    succNotifRuleDelete: 'Delete rule finished successfully',
    unSuccNotifRuleDelete: 'Error occurred during delete rule',
    succNotifRuleDiff: 'Saving rule diff finished with success',
    unsuccNotifRuleDiff: 'Error occurred during saving rule diff.',
  },
  attachments: {
    type: 'Type',
    fileName: 'File name',
    fileUploadDate: 'File upload date',
    fileUploaderName: 'Uploaded by',
    save: {
      success: 'Attachment(s) were successfully saved.',
      error: 'An Error has occurred while uploading attachment(s).',
    },
    delete: {
      success: 'Attachment(s) were successfully deleted.',
      error: 'An error has occurred while deleting attachment(s).',
    },
    download: {
      error: 'An error has occurred while download attachment.',
    },
    saveAndDeleteAll: 'File(s) were successfully saved.',
  },
  comments: {
    save: {
      success: 'Comment(s) were successfully saved.',
      error: 'An Error has occurred while uploading comment(s).',
    },
    delete: {
      success: 'comment(s) were successfully deleted.',
      error: 'An error has occurred while deleting comment(s).',
    },
  },
  worklogs: {
    started: 'Started',
    ended: 'Ended',
    save: {
      success: 'Worklog(s) were successfully saved.',
      error: 'An Error has occurred while uploading worklog(s).',
    },
    delete: {
      success: 'worklog(s) were successfully deleted.',
      error: 'An error has occurred while deleting worklog(s).',
    },
  },
  worklogTimer: {
    effects: {
      worklogTimerStartSuccess: 'Worklog was started successfully',
      worklogTimerEndSuccess: 'Worklog was saved successfuly',
      worklogTimerEndError: 'An Error has occurred while saving worklog.',
    },
  },
  attachmentType: {
    effects: {
      attachmentTypeSaveSuccess: 'Saving attachment type was successful.',
      attachmentTypeSaveError: 'An error occurred while saving attachment type',
      attachmentTypeDeleteSuccess:
        'Deleting attachment type finished with success',
      attachmentTypeDeleteError:
        'Error occurred while deleting attachment type.',
      attachmentTypeDiffSuccess:
        'Saving attachment type diff finished with success',
      attachmentTypeDiffError:
        'Error occurred during saving attachment type diff.',
    },
  },
  commentType: {
    title: 'Comment type',
    effects: {
      commentTypeSaveSuccess: 'Saving comment type was successful.',
      commentTypeSaveError: 'An error occurred while saving comment type',
      commentTypeDeleteSuccess: 'Deleting comment type finished with success',
      commentTypeDeleteError: 'Error occurred while deleting comment type.',
      commentTypeDiffSuccess: 'Saving comment type diff finished with success',
      commentTypeDiffError: 'Error occurred during saving comment type diff.',
    },
  },
  worklogType: {
    effects: {
      worklogTypeSaveSuccess: 'Saving worklog type was successful.',
      worklogTypeSaveError: 'An error occurred while saving worklog type',
      worklogTypeDeleteSuccess: 'Deleting worklog type finished with success',
      worklogTypeDeleteError: 'Error occurred while deleting comment type.',
      worklogTypeDiffSuccess: 'Saving worklog type diff finished with success',
      worklogTypeDiffError: 'Error occurred during saving worklog type diff.',
    },
  },
  formatterTemplate: {
    save: {
      success: 'Saving layout finished successfully',
      error: 'Error occurred during saving layout',
    },
    delete: {
      success: 'Delete layout finished successfully',
      error: 'Error occurred during delete layout',
    },
    diff: {
      success: 'Saving Layout diff finished successfully.',
      error: 'Error occurred during saving Layout diff.',
    },
  },
  formatterDocumentTemplate: {
    diff: {
      success: 'Saving formatter document template diff finished successfully.',
      error: 'Error occurred during saving formatter document template diff.',
    },
  },
  sharing: {
    save: {
      success: 'Saving sharing finished successfully',
      error: 'Error occurred during saving sharing',
    },
    delete: {
      success: 'Delete sharing finished successfully',
      error: 'Error occurred during delete sharing',
    },
  },
  deliveryTime: {
    diff: {
      success: 'Saving delivery time diff finished successfully.',
      error: 'Error occurred during saving delivery time diff.',
    },
  },
};
