<input
  [ngStyle]="{display: readonly ? 'none' : ''}"
  type="text"
  #inputElement
  [(ngModel)]="val"
  [placeholder]="placeholder ? (placeholder | transloco) : ''"
  [tabindex]="tabindex"
  [pKeyFilter]="'pint'"
  [maxlength]="maxlength"
  [minlength]="minlength"
  class="p-inputtext p-corner-all p-state-default p-component"
  data-cy="dtl-form-input-integer"
/>
<span
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
  [ngClass]="
    val !== null && val !== undefined ? 'readonly-text' : 'readonly-text empty'
  "
>
  {{ val !== null && val !== undefined ? val : 'none' }}
</span>
