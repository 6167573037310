import {createAction, props} from '@ngrx/store';
import {DtlMenuItem, DtlMenuItemApi} from '@tsm/framework/plugin';

export const SetInitState = createAction('[Layout] Set init state');

export const SetTopbarAction = createAction(
  '[Layout] Set topbar',
  props<{
    topbar:
      | 'notifications'
      | 'profile'
      | 'messages'
      | 'worklogs'
      | 'debug'
      | null;
  }>(),
);

export const SetData = createAction(
  '[DataConsole] Set dataConsole',
  props<{value: any; context: any}>(),
);

export const OpenMenuAction = createAction('[Layout] Open menu');

export const CloseMenuAction = createAction('[Layout] Close menu');

export const SetMenuAction = createAction(
  '[Layout] Set menu',
  props<{opened: boolean}>(),
);

export const SetLayoutMenuAction = createAction(
  '[Layout] Set layout menu',
  props<{layoutMenu: DtlMenuItem[]}>(),
);

// Cela definice menu
export const ChangeLanguageMenuItemsAction = createAction(
  '[Layout] Change language menu items',
  props<{lang: string}>(),
);

export const LoadMenuItemsAction = createAction('[Layout] Load menu items');

export const LoadMenuItemsActionSuccess = createAction(
  '[Layout] Load menu items Success',
  props<{menus: DtlMenuItem[]}>(),
);

export const LoadMenuItemsActionError = createAction(
  '[Layout] Load menu items Error',
  props<{error: any}>(),
);

export const UpsertMenuItemsAction = createAction(
  '[Layout] Upsert menu items',
  props<{menuItems: DtlMenuItemApi[]}>(),
);

export const AddMenuItemAction = createAction(
  '[Layout] Add menu item',
  props<{menuItem: DtlMenuItem}>(),
);

export const UpdateMenuItemAction = createAction(
  '[Layout] Upsert menu item',
  props<{menuItem: DtlMenuItem}>(),
);

export const DeleteMenuItemAction = createAction(
  '[Layout] Delete menu item',
  props<{menuItem: DtlMenuItem}>(),
);

export const ToggleBookmarkMenuItemAction = createAction(
  '[Layout] ToggleBookmark menu item',
  props<{menuItem: DtlMenuItem}>(),
);

export const ReorderBookmarkMenuItemsAction = createAction(
  '[Layout] ReorderBookmark menu items',
  props<{menuItems: DtlMenuItem[]}>(),
);

export const UpdateMenuItemsByIdInUrl = createAction(
  '[Layout] Update MenuItems by id in url',
  props<{id: string}>(),
);

export const DeleteMenuItemsByIdInUrl = createAction(
  '[Layout] Delete MenuItems by id in url',
  props<{id: string}>(),
);

export const UpsertPreviousUrl = createAction(
  '[Layout] Upsert previous url',
  props<{url: string; queryParams: any}>(),
);
