import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {WorklogType} from '../models';

export const LoadWorklogType = createAction('[WorklogType] Load WorklogType');
export const LoadWorklogTypeSuccess = createAction(
  '[WorklogType] Load WorklogType Success',
  props<{
    worklogTypes: WorklogType[];
  }>(),
);
export const LoadWorklogTypeError = createAction(
  '[WorklogType] Load WorklogType Error',
  props<TsmError>(),
);

export const LoadWorklogTypeById = createAction(
  '[WorklogType] Load LoadWorklogTypeById',
  props<{id: string}>(),
);
export const LoadWorklogTypeByIdSuccess = createAction(
  '[WorklogType] Load LoadWorklogTypeById Success',
  props<{
    worklogType: WorklogType;
  }>(),
);
export const LoadWorklogTypeByIdError = createAction(
  '[WorklogType] Load LoadWorklogTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadWorklogTypeByCode = createAction(
  '[WorklogType] Load WorklogTypeByCode',
  props<{code: string}>(),
);
export const LoadWorklogTypeByCodeSuccess = createAction(
  '[WorklogType] Load WorklogTypeByCode Success',
  props<{
    worklogType: WorklogType;
  }>(),
);
export const LoadWorklogTypeByCodeError = createAction(
  '[WorklogType] Load WorklogTypeByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertWorklogType = createAction(
  '[WorklogType] Upsert WorklogType',
  props<{
    worklogType: WorklogType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertWorklogTypeSuccess = createAction(
  '[WorklogType] Upsert WorklogType Success',
  props<{
    worklogType: WorklogType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertWorklogTypeError = createAction(
  '[WorklogType] Upsert WorklogType Error',
  props<TsmError>(),
);

export const DeleteWorklogType = createAction(
  '[WorklogType] Delete WorklogType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteWorklogTypeSuccess = createAction(
  '[WorklogType] Delete WorklogType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteWorklogTypeError = createAction(
  '[WorklogType] Delete WorklogType Error',
  props<TsmError>(),
);

export const DiffWorklogType = createAction(
  '[WorklogType] Diff WorklogType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffWorklogTypeSuccess = createAction(
  '[WorklogType] Diff WorklogType Success',
  props<{
    worklogTypes: WorklogType[];
    listingId: string;
  }>(),
);
export const DiffWorklogTypeError = createAction(
  '[WorklogType] Diff WorklogType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
