import {
  ChangeDetectorRef,
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
} from '@angular/core';
import {NgControl, NgModel} from '@angular/forms';

@Directive({
  selector: '[tsmInputText]',
  host: {
    '[class.p-inputtext]': 'true',
    '[class.p-component]': 'true',
    '[class.p-state-filled]': 'filled',
  },
})
export class InputTextDirective implements DoCheck, OnInit {
  filled: boolean;

  constructor(
    public el: ElementRef,
    @Optional() public ngModel: NgModel,
    protected cdr: ChangeDetectorRef,
    private formControl: NgControl,
  ) {}

  ngOnInit() {
    if (this.formControl) {
      this.formControl.valueChanges.subscribe((x) => this.cdr.markForCheck());
    }
  }

  ngDoCheck() {
    this.updateFilledState();
  }

  //To trigger change detection to manage p-state-filled for material labels when there is no value binding
  @HostListener('input', ['$event'])
  onInput(e) {
    this.updateFilledState();
  }

  updateFilledState() {
    this.filled =
      (this.el.nativeElement.value && this.el.nativeElement.value.length) ||
      (this.ngModel && this.ngModel.model);
  }
}
