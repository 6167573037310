import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/process/plugins-i18n';

export const controlsDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-task-detail',
  name: 'Task detail',
  description: '',
  useType: 'FORM_DETAIL',
  entityType: 'Task',
  tsmControls: {
    'Task.Header': {
      name: translationShared.shared.header,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.header,
          labelPosition: 'left',
          type: 'previewHeader',
        },
      },
    },
    'Task.Toolbar.Settings': {
      name: translationShared.shared.settingsButton,
      description: null,
      icon: 'pi pi-ellipsis-v',
    },
    'Task.Toolbar.Refresh': {
      name: translationShared.shared.refreshButton,
      description: null,
      icon: 'pi pi-sync',
    },
    'Task.Toolbar.Scheduler': {
      name: translation.processPlugins.scheduler,
      description: null,
      imageUrl: 'assets/preview/prew_scheduler.png',
    },
    'Task.Toolbar.SelectTaskStatus': {
      name: translation.processPlugins.selectTaskStatus,
      description: null,
      imageUrl: 'assets/preview/prew_select_task_status.png',
    },
    'Task.Toolbar.EndTask': {
      name: translation.processPlugins.endTask,
      description: null,
      imageUrl: 'assets/preview/prew_endTask.png',
    },
    'Task.Incident.Message': {
      name: translation.processPlugins.incidentMessage,
      description: null,
      imageUrl: 'assets/preview/prew_incident.png',
    },
    'Task.Incident.Editor': {
      name: translation.processPlugins.incident,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.incident,
          labelPosition: 'left',
          type: 'previewHeader',
        },
      },
      defaultComponent: {
        selector: 'tsm-monaco-editor',
        inputs: {
          language: 'json',
          defaultHeight: '300px',
          ngModelOptions: {standalone: true},
          options: {
            fontSize: 12,
            automaticLayout: true,
            readOnly: true,
          },
        },
      },
    },
    'Task.Decision.Name': {
      name: translation.processPlugins.decisionName,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.decisionName,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Task.Decision.Key': {
      name: translation.processPlugins.decisionKey,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.decisionKey,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Task.Decision.Inputs': {
      name: translation.processPlugins.decisionInputs,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.decisionInputs,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Task.Decision.Outputs': {
      name: translation.processPlugins.decisionOutputs,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.decisionOutputs,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Task.Status': {
      name: translationShared.shared.status,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.status,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'tsm-task-status-lov',
      },
    },
    'Task.Details.Description': {
      name: translationShared.shared.description,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.description,
          labelPosition: 'left',
          type: 'tiptap',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
    'Task.Dates.ProgressBar': {
      name: translation.processPlugins.progressBar,
      description: null,
      imageUrl: 'assets/preview/prew_progress_bar.png',
    },
    'Task.Dates.ActiveFrom': {
      name: translation.processPlugins.activeFrom,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.activeFrom,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    'Task.Dates.ActiveTo': {
      name: translation.processPlugins.activeTo,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.activeTo,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    'Task.Dates.ActiveTotal': {
      name: translation.processPlugins.activeTotal,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.activeTotal,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Task.Dates.Term': {
      name: translation.processPlugins.taskTerm,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.taskTerm,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Task.Dates.Reminder': {
      name: translation.processPlugins.reminder,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translation.processPlugins.reminder,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Task.People.Department': {
      name: translationShared.shared.department,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.department,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Task.People.User': {
      name: translationShared.shared.user,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.user,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'tsm-user-lov',
        inputs: {
          moduleCodes: ['WFM'],
        },
      },
    },
    'Task.Tabs.Process': {
      name: translationShared.shared.process,
      description: null,
      imageUrl: 'assets/preview/prew_process.png',
    },
    'Task.Tabs.Console': {
      name: translation.processPlugins.console,
      description: null,
      imageUrl: 'assets/preview/prew_console.png',
    },
  },
};

export const controlsWfmPopupDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-popup-task-detail',
    name: 'Task WFM dialog detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Task',
    tsmControls: {
      'Task.Header': {
        name: translation.processPlugins.controls.header,
        description: null,
      },
      'Task.Assigned': {
        name: translation.processPlugins.controls.assigned,
        description: null,
      },
      'Task.Location': {
        name: translation.processPlugins.controls.location,
        description: null,
      },
      'Task.Times': {
        name: translation.processPlugins.controls.time,
        description: null,
      },
      'Task.Workers': {
        name: translation.processPlugins.controls.worker,
        description: null,
      },
      'Task.Divider': {
        name: translation.processPlugins.controls.divider,
        description: null,
      },
      'Task.SkillAndRegion': {
        name: translation.processPlugins.controls.skillAndRegion,
        description: null,
      },
      'Task.Cost': {
        name: translation.processPlugins.controls.cost,
        description: null,
      },
      'Task.Description': {
        name: translation.processPlugins.controls.description,
        description: null,
      },
      'Task.Footer': {
        name: translation.processPlugins.controls.footer,
        description: null,
      },
    },
  };

export const controlsWfmDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-wfm-task-detail',
  name: 'Task WFM detail',
  description: '',
  useType: 'FORM_DETAIL',
  entityType: 'Task',
  tsmControls: {
    'Task.Header': {
      name: translation.processPlugins.controls.header,
      description: null,
    },
    'Task.Assigned': {
      name: translation.processPlugins.controls.assigned,
      description: null,
    },
    'Task.Location': {
      name: translation.processPlugins.controls.location,
      description: null,
    },
    'Task.Times': {
      name: translation.processPlugins.controls.time,
      description: null,
    },
    'Task.Description': {
      name: translation.processPlugins.controls.description,
      description: null,
    },
  },
};
