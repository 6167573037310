import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {Config} from '@tsm/framework/config';
import {ConsoleResponseModel} from '../models';

@Injectable({
  providedIn: 'root',
})
export class GroovyConsoleDataService {
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.base;
  }

  runConsole(endpoint: string, query: any) {
    return this.apiService.post<ConsoleResponseModel, ConsoleResponseModel>(
      `${this.BASE_URL}${endpoint}`,
      query,
    );
  }
}
