import {inject, Injectable, Injector} from '@angular/core';
import {RuntimeService} from '@tsm/runtime-info';
import {Store} from '@ngrx/store';
import {
  Observable,
  combineLatest,
  map,
  merge,
  of,
  scan,
  startWith,
  distinctUntilChanged,
} from 'rxjs';
import {Config, ConfigService} from '@tsm/framework/config';
import {FluentFormsStore} from '../stores';
import {extractExpressionContent, RegistryService} from '@tsm/framework/root';
import * as meriyah from 'meriyah';
import {returnNewer} from '../utils';
import {FrameworkPluginService} from '@tsm/framework/plugin';
import {ExpresstionStatementAstNode, Generators} from '../ast';
import {toObservable} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class FluentExpressionService {
  private runtimeInfo$ = this.runtimeService.getCurrentUserObservable();
  private version$ = of((window as any)?.app?.version);
  private config$ = of(this.configService.value);
  private configUi$ = this.getConfigUi$();
  private build$ = of((window as any)?.app?.build);

  private entityMap$ = toObservable(this.fluentFormsStore.entityMap);

  constructor(
    private runtimeService: RuntimeService,
    private store$: Store,
    private configService: ConfigService<Config>,
    private fluentFormsStore: FluentFormsStore,
    private registryService: RegistryService,
    private frameworkPluginService: FrameworkPluginService,
    private injector: Injector,
  ) {}

  eval(expresion: string, rootFormId: string, row = 0) {
    // fces, transforms, so on
    const fcesFinal = {};
    const transformsFinal = {};
    // fces
    this.registryService.functionsArray.forEach(({key, fce}) => {
      fcesFinal[key] = fce;
    });
    fcesFinal['returnNewer'] = returnNewer;

    // transforms
    this.registryService.transformsArray.forEach(({key, fce}) => {
      transformsFinal[key] = fce;
    });

    // datasources
    const datasouercesLib = this.frameworkPluginService.getAllDatasources();
    const library = {};
    const store = {};
    datasouercesLib.forEach((dts) => {
      library[dts.name] = dts.dataSource;
    });
    const datasources = {
      library: library,
      store: store,
    };
    // other
    const context$ = this.entityMap$.pipe(
      map((x) => x[rootFormId]?.context),
      distinctUntilChanged(),
    );
    const outputContext$ = this.entityMap$.pipe(
      map((x) => x[rootFormId]?.outputContext),
      distinctUntilChanged(),
    );
    const rootControl = this.fluentFormsStore.selectRootControl(rootFormId);
    const value$ = rootControl.valueChanges.pipe(startWith(rootControl.value));

    const unknown = expresion;
    const expression = extractExpressionContent(unknown).trim();
    let parsed = null;
    try {
      parsed = meriyah.parse(expression);
    } catch (ex) {
      console.warn('Cannot parse:' + expression);
      return of(null);
    }

    if (parsed == null) {
      console.warn('Wrong expression: ' + unknown);
      return of(null);
    }
    const root = parsed.body[0] as ExpresstionStatementAstNode;
    if (root == null) {
      console.warn('Wrong expression: ' + unknown);
      return of(null);
    }

    const fceResult = Generators.generateExpressionFunctionString(
      root.expression,
      fcesFinal,
      transformsFinal,
      datasources,
      this.injector,
    );
    const fceString = `
             ${fceResult.functions.reduce((acc, it) => acc + '\n' + it, '')}
             return ${fceResult.value};
            `;
    // this.compiled = fceString.trim();
    const fce = new Function(
      '$value',
      'unknown',
      'functions',
      'transforms',
      '$row',
      '$context',
      '$outputContext',
      '$config',
      '$configUi',
      '$runtimeInfo',
      '$version',
      '$build',
      'dataSources',
      'dataSourcesValues',
      fceString,
    );

    const transformedDts = {};
    const datasourceValuesObs$ = [];

    Object.keys(datasources.store).forEach((dtsKey) => {
      const obs$ = datasources.store[dtsKey].create();
      datasourceValuesObs$.push(
        obs$.pipe(
          map((value) => ({
            [dtsKey]: value,
          })),
        ),
      );
      const previousArgs = [];
      transformedDts[dtsKey] = (...args) => {
        if (args === undefined) {
          return true;
        }
        let shouldPushParams = false;
        args.forEach((arg, argIndex) => {
          if (previousArgs[argIndex] != arg) {
            shouldPushParams = true;
            previousArgs[argIndex] = arg;
          }
        });
        if (shouldPushParams) {
          datasources.store[dtsKey].pushParams(args);
        }

        return true;
      };
    });

    const scannedObs$ = merge(...datasourceValuesObs$).pipe(
      scan(
        ((acc, value) => {
          const temp = {
            ...acc,
            [Object.keys(value)[0]]: Object.values(value)[0],
          };
          return temp;
        }) as any,
        {},
      ),
      startWith({}),
    );

    return combineLatest([
      value$,
      context$,
      outputContext$,
      this.config$,
      this.configUi$,
      this.runtimeInfo$,
      this.version$,
      this.build$,
      scannedObs$,
    ]).pipe(
      map(
        ([
          _$value,
          _context$,
          _config$,
          _configUi$,
          _runtimeInfo$,
          _version$,
          _build$,
          _scannedObs$,
        ]) => {
          try {
            const newValue = fce(
              _$value,
              unknown,
              fcesFinal,
              transformsFinal,
              row,
              _context$,
              _config$,
              _configUi$,
              _runtimeInfo$,
              _version$,
              _build$,
              transformedDts,
              _scannedObs$,
            );
            return newValue;
          } catch (ex) {
            console.warn(ex.toString());
          }
        },
      ),
    );
  }

  private getConfigUi$(): Observable<any> {
    return this.store$.select((x) => (x as any)?.uiConfig?.uiConfig?.data);
  }
}
