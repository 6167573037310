import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/calendar/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';
import {of} from 'rxjs';

export const calendarPlugin: Plugin = {
  menus: [
    {
      key: 'calendar',
      priority: 1900,
      label: 'menu.calendar.calendar',
      icon: 'tsm-icon-calendar',
      privilege: ['Calendar'],
      items: [
        {
          key: 'my-calendar',
          priority: 1900,
          label: 'menu.calendar.calendar',
          icon: 'tsm-icon-calendar',
          privilege: ['Calendar'],
          routerLink: ['/calendar/my-calendar'],
        },
        {
          key: 'full-calendar',
          priority: 2000,
          label: 'menu.calendar.calendar',
          icon: 'tsm-icon-calendar',
          privilege: ['Calendar'],
          routerLink: ['/calendar/full-calendar'],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'calendar',
      loadChildren: () =>
        import('@tsm/calendar/components').then(
          (x) => x.CalendarComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/calendar/widgets').then((x) => x.CalendarWidgetsModule),
      definitions: [
        {
          selector: 'tsm-full-calendar',
          loadComponent: () =>
            import('@tsm/calendar/widgets/full-calendar').then(
              (x) => x.FullCalendarComponent,
            ),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-full-calendar-editor',
              loadComponent: () =>
                import('@tsm/calendar/widgets/full-calendar').then(
                  (x) => x.FullCalendarEditorComponent,
                ),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.fullCalendarPanel
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.fullCalendarPanel
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.panel,
              value: {
                title:
                  translationShared.shared.widgets.fullCalendarPanel
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-full-calendar',
                },
                type: 'layout',
                config: {
                  filterOnlyByOwner: true,
                  canAddEvent: true,
                  canEditEvent: true,
                  hideLeftPanel: true,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar-event',
          loadComponent: () =>
            import('@tsm/calendar/widgets/calendar-event/calendar-event').then(
              (x) => x.CalendarEventComponent,
            ),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-event-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-event/calendar-event-editor'
                ).then((x) => x.CalendarEventEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              name: translationShared.menu.settings.calendarEventList,
              description: translationShared.menu.settings.calendarEventList,
              icon: 'p-icon-person-add',
              value: {
                title: translationShared.menu.settings.calendarEventList,
                widget: {
                  type: 'tsm-calendar-event',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar-event-list-widget',
          loadComponent: () =>
            import(
              '@tsm/calendar/widgets/calendar-event-list-widget/calendar-event-list-widget'
            ).then((x) => x.CalendarEventListWidgetComponent),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-event-list-widget-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-event-list-widget/calendar-event-list-widget-editor'
                ).then((x) => x.CalendarEventListWidgetEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.calendarEventList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.calendarEventList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.calendarEventList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-calendar-event-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar',
          loadComponent: () =>
            import('@tsm/calendar/widgets/calendar/calendar').then(
              (x) => x.CalendarComponent,
            ),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-editor',
              loadComponent: () =>
                import('@tsm/calendar/widgets/calendar/calendar-editor').then(
                  (x) => x.CalendarEditorComponent,
                ),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.calendarPanel.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.calendarPanel
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.panel,
              value: {
                title:
                  translationShared.shared.widgets.calendarPanel.inWidgetTitle,
                widget: {
                  type: 'tsm-calendar',
                },
                type: 'layout',
                config: {
                  filterOnlyByOwner: true,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar-create-button',
          loadComponent: () =>
            import(
              '@tsm/calendar/widgets/calendar-create-button/calendar-create-button'
            ).then((x) => x.CalendarCreateButtonComponent),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-create-button-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-create-button/calendar-create-button-editor'
                ).then((x) => x.CalendarCreateButtonEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.newCalendarButton
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.newCalendarButton
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.button,
              value: {
                title:
                  translationShared.shared.widgets.newCalendarButton
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-calendar-create-button',
                },
                type: 'layout',
                config: {
                  ownerId: '',
                  ownerType: '',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-colliding-pew-button',
          loadComponent: () =>
            import('@tsm/calendar/widgets/colliding-pew-button').then(
              (x) => x.CollidingPewButtonComponent,
            ),
        },
        {
          selector: 'tsm-calendar-subtype-lov',
          loadComponent: () =>
            import(
              '@tsm/calendar/widgets/calendar-subtype-lov/calendar-subtype-lov'
            ).then((x) => x.CalendarSubtypeLovComponent),
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-calendar-subtype-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-subtype-lov/calendar-subtype-lov-editor'
                ).then((x) => x.CalendarSubtypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'calendarSubtype',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.calendarSubypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.calendarSubypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.calendarSubypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-calendar-subtype-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar-event-type-lov',
          loadComponent: () =>
            import('@tsm/calendar/widgets/calendar-event-type-filter-lov').then(
              (x) => x.CalendarEventTypeFilterLovComponent,
            ),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-event-type-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-event-type-lov/calendar-event-type-lov-editor'
                ).then((x) => x.CalendarEventTypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'calendarEventType',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.calendarEventTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.calendarEventTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.calendarEventTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-calendar-event-type-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar-template-lov',
          loadComponent: () =>
            import(
              '@tsm/calendar/widgets/calendar-template-lov/calendar-template-lov'
            ).then((x) => x.CalendarTemplateLovComponent),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-template-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-template-lov/calendar-template-lov-editor'
                ).then((x) => x.CalendarTemplateLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'calendarTemplate',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.calendarTemplateSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.calendarTemplateSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.calendarTemplateSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-calendar-template-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-calendar-event-configuration-data',
          loadComponent: () =>
            import(
              '@tsm/calendar/widgets/calendar-event-configuration-data/calendar-event-configuration-data'
            ).then((x) => x.CalendarEventConfigurationDataComponent),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-calendar-event-configuration-data-editor',
              loadComponent: () =>
                import(
                  '@tsm/calendar/widgets/calendar-event-configuration-data/calendar-event-configuration-data-editor'
                ).then((x) => x.CalendarEventConfigurationDataEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'data',
              categoryId: DesignerCategoryEnum.Calendar,
              name: translationShared.shared.widgets.calendarEventConfCard
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.calendarEventConfCard
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.card,
              value: {
                title:
                  translationShared.shared.widgets.calendarEventConfCard
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-calendar-event-configuration-data',
                },
                type: 'object',
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/calendar/widgets').then((x) => x.CalendarWidgetsModule),
      definitions: [
        {
          selector: 'tsm-calendar-event-type-filter',
          loadComponent: () =>
            import(
              '@tsm/calendar/widgets/filters/calendar-event-type-filter'
            ).then((x) => x.CalendarEventTypeFilterComponent),
          name: translationPlugin.calendarPlugins.filters
            .tsmCalendarEventTypeFilter,
          description:
            translationPlugin.calendarPlugins.filters
              .tsmCalendarEventTypeFilterDescription,
        },
        {
          selector: 'tsm-calendar-type-filter',
          loadComponent: () =>
            import('@tsm/calendar/widgets/filters/calendar-type-filter').then(
              (x) => x.CalendarTypeFilterComponent,
            ),
          name: translationPlugin.calendarPlugins.filters.tsmCalendarTypeFilter,
          description:
            translationPlugin.calendarPlugins.filters
              .tsmCalendarTypeFilterDescription,
        },
        {
          selector: 'tsm-full-calendar-event-type-filter',
          loadComponent: () =>
            import('@tsm/calendar/widgets/full-calendar').then(
              (x) => x.FullCalendarEventTypeFilterComponent,
            ),
          name: translationPlugin.calendarPlugins.filters
            .tsmFullCalendarEventTypeFilter,
          description:
            translationPlugin.calendarPlugins.filters
              .tsmFullCalendarEventTypeFilterDescription,
        },
        {
          selector: 'tsm-full-calendar-filter',
          loadComponent: () =>
            import('@tsm/calendar/widgets/full-calendar').then(
              (x) => x.FullCalendarFilterComponent,
            ),
          name: translationPlugin.calendarPlugins.filters.tsmFullCalendarFilter,
          description:
            translationPlugin.calendarPlugins.filters
              .tsmFullCalendarFilterDescription,
        },
        {
          selector: 'tsm-full-calendar-user-group-filter',
          loadComponent: () =>
            import('@tsm/calendar/widgets/full-calendar').then(
              (x) => x.FullCalendarUserGroupFilterComponent,
            ),
          name: translationPlugin.calendarPlugins.filters
            .tsmFullCalendarUserGroupFilter,
          description:
            translationPlugin.calendarPlugins.filters
              .tsmFullCalendarUserGroupFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/calendar/widgets').then((x) => x.CalendarWidgetsModule),
      definitions: [
        {
          selector: 'calendarEventTypeByCode',
          name: translationPlugin.calendarPlugins.pipes.calendarEventTypeByCode,
          description:
            translationPlugin.calendarPlugins.pipes
              .calendarEventTypeByCodeDescription,
        },
        {
          selector: 'calendarEventTypeById',
          name: translationPlugin.calendarPlugins.pipes.calendarEventTypeById,
          description:
            translationPlugin.calendarPlugins.pipes
              .calendarEventTypeByIdDescription,
        },
        {
          selector: 'calendarTemplateByCode',
          name: translationPlugin.calendarPlugins.pipes.calendarTemplateByCode,
          description:
            translationPlugin.calendarPlugins.pipes
              .calendarTemplateByCodeDescription,
        },
        {
          selector: 'calendarTemplateById',
          name: translationPlugin.calendarPlugins.pipes.calendarTemplateById,
          description:
            translationPlugin.calendarPlugins.pipes
              .calendarTemplateByIdDescription,
        },
        {
          selector: 'hasCollidingPew',
          name: translationPlugin.calendarPlugins.pipes.hasCollidingPew,
          description:
            translationPlugin.calendarPlugins.pipes.hasCollidingPewDescription,
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'CalendarEventType',
      url: (entityId) => {
        return of('/calendar-event-type');
      },
    },
  ],
};
