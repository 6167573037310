import {parseISO, isValid} from 'date-fns';

/**
 * Definice filtru
 * - field urcuje sloupec
 * - value hodnota filtru
 * - displayValue hodnota pro zobrazení
 * - operator definice oprace (eq, like, atd...)
 */
export interface FilterModel {
  field: string;
  value?: any;
  displayValue?: any;
  operator: FilterOperator;
  readonly?: boolean;
  editReadonly?: boolean;
  visible?: boolean;
}

export enum FilterOperator {
  /**
   * Retrieves object with property containing provided value )case-insensitive) - {@code ?property__contains=foo}
   */
  contains = 'contains',

  /**
   * Retrieves object with property not containing provided value (case-insensitive) - {@code ?property__notcontains=foo}
   */
  notcontains = 'notcontains',

  /**
   * Retrieves object with property starting with provided value (case-insensitive) - {@code ?property__startswith=foo}
   */
  startswith = 'startswith',

  /**
   * Retrieves object with property ending with provided value (case-insensitive) - {@code ?property__endswith=foo}
   */
  endswith = 'endswith',

  /**
   * Retrieves object if property is contained in provided list of values - {@code ?property__in=["AAA","AA"]}
   */
  in = 'in',

  /**
   * Retrieves object if property isn´t contained in provided list of values - {@code ?property__notin=["AAA","AA"]}
   */
  notin = 'notin',

  /**
   * Retrieves object if property equals to provided value - {@code ?property__eq=A}
   */
  eq = 'eq',

  /**
   * Retrieves object if property not equals to provided value - {@code ?property__noteq=A}
   */
  noteq = 'noteq',

  /**
   * Retrieves object if property is greater than provided value - {@code ?property__gt=24}
   */
  gt = 'gt',

  /**
   * Retrieves object if property is greater or equal to provided value - {@code ?property__gte=24}
   */
  gte = 'gte',

  /**
   * Retrieves object if property is less than provided value - {@code ?property__lt=24}
   */
  lt = 'lt',

  /**
   * Retrieves object if property is less or equal to provided value - {@code ?property__lte=24}
   */
  lte = 'lte',

  /**
   * Retrieves object if property is emoty - {@code ?property__empty}
   */
  empty = 'empty',

  /**
   * Retrieves object if property isn´t empty - {@code ?property__notempty}
   */
  notempty = 'notempty',

  /**
   * Retrieves object if property is between to provided values - {@code ?property__btw=10-15}
   */
  btw = 'btw',

  /**
   * Greater than current time, relative - {@code ?property__gtr=-1d}
   */
  gtr = 'gtr',

  /**
   * Less than current time, relative - {@code ?property__ltr=+1d}
   */
  ltr = 'ltr',

  /**
   * Between time, relative
   * */
  btwr = 'btwr',

  /**
   * Don't touch value - specialni operator, ktery rika, nesahej vubec na value
   * pouziva se napriklad pro definovavni vlastni funkce ve filtru
   * {
   *       field: 'uzemi',
   *       operator: FilterOperator.dontTouch,
   *       value: 'uzemi()',
   *       visible: false
   *     }
   */
  dontTouch = 'dontTouch',
}

export function filterOperatorConverter(operator: string) {
  switch (operator) {
    case FilterOperator.contains:
    case FilterOperator.startswith:
    case FilterOperator.endswith:
      return 'like';
    case FilterOperator.notcontains:
      return 'not like';
    case FilterOperator.in:
      return 'in';
    case FilterOperator.notin:
      return 'not in';
    case FilterOperator.eq:
      return '=';
    case FilterOperator.noteq:
      return '!=';
    case FilterOperator.gt:
    case FilterOperator.gtr:
      return '>';
    case FilterOperator.gte:
      return '>=';
    case FilterOperator.lt:
    case FilterOperator.ltr:
      return '<';
    case FilterOperator.lte:
      return '<=';
    case FilterOperator.empty:
      return 'is null';
    case FilterOperator.notempty:
      return 'is not null';
    case FilterOperator.btw:
    case FilterOperator.btwr:
      return 'between';
    default:
      return '=';
  }
}

export function filterValueAndOperatorConverter(
  operator: string,
  value: any | any[],
  onlyValues = false,
  customTqlExpression = false,
) {
  switch (operator) {
    case FilterOperator.contains:
      return onlyValues ? `'%${value}%'` : `like '%${value}%'`;
    case FilterOperator.notcontains:
      return onlyValues ? `'%${value}%'` : `not like '%${value}%'`;
    case FilterOperator.startswith:
      return onlyValues ? `'${value}%'` : `like '${value}%'`;
    case FilterOperator.endswith:
      return onlyValues ? `'%${value}'` : `like '%${value}'`;
    case FilterOperator.in:
      return onlyValues
        ? `${convertorValueIn(value, customTqlExpression)}`
        : `in (${convertorValueIn(value, customTqlExpression)})`;
    case FilterOperator.notin:
      return onlyValues
        ? `${convertorValueIn(value, customTqlExpression)}`
        : `not in (${convertorValueIn(value, customTqlExpression)})`;
    case FilterOperator.eq:
      return onlyValues
        ? `${convertorValueEq(value, customTqlExpression)}`
        : `= ${convertorValueEq(value, customTqlExpression)}`;
    case FilterOperator.noteq:
      return onlyValues
        ? `${convertorValueEq(value, customTqlExpression)}`
        : `!= ${convertorValueEq(value, customTqlExpression)}`;
    case FilterOperator.gt:
    case FilterOperator.gtr:
      return onlyValues
        ? `${convertorNumberValue(value)}`
        : `> ${convertorNumberValue(value)}`;
    case FilterOperator.gte:
      return onlyValues
        ? `${convertorNumberValue(value)}`
        : `>= ${convertorNumberValue(value)}`;
    case FilterOperator.lt:
    case FilterOperator.ltr:
      return onlyValues
        ? `${convertorNumberValue(value)}`
        : `< ${convertorNumberValue(value)}`;
    case FilterOperator.lte:
      return onlyValues
        ? `${convertorNumberValue(value)}`
        : `<= ${convertorNumberValue(value)}`;
    case FilterOperator.empty:
      return onlyValues ? 'null' : `is null`;
    case FilterOperator.notempty:
      return onlyValues ? 'null' : `is not null`;
    case FilterOperator.btw:
    case FilterOperator.btwr:
      return onlyValues
        ? `'${value[0]}' and '${value[1]}'`
        : `between '${value[0]}' and '${value[1]}'`;
    case FilterOperator.dontTouch:
      return onlyValues ? `${value}` : `= ${value}`;
    default:
      return onlyValues
        ? `${convertorValueEq(value, customTqlExpression)}`
        : `= ${convertorValueEq(value, customTqlExpression)}`;
  }
}

function convertorValueIn(
  value: string | string[],
  customTqlExpression = false,
): string {
  if (Array.isArray(value)) {
    return value
      .filter((x) => !!x)
      .map((v) => {
        return v.includes('()')
          ? v
          : `${convertorValueEq(v, customTqlExpression)}`;
      })
      .join(',');
  } else {
    return value.includes('()') ? value : `'${value}'`;
  }
}

function convertorValueEq(value: any, customTqlExpression = false): any {
  if (typeof value == 'string') {
    // je to string
    // jedna se o vlastni sestaveni filtru nebo o hodnoty (viz. pole)
    if (
      ['currentUser()' || 'myUserGroup()'].includes(value) ||
      customTqlExpression === true
    ) {
      // pokud hodnota se netvari jako funkce
      if (
        value.match(/[a-zA-Z]+\(.*?\)/g) == null &&
        value.match(/\(.*?\)/g) == null
      ) {
        return value == 'true' || value == 'false'
          ? `${value == 'true'}`
          : `'${value}'`;
      }
      // jedna se nejspise o funkci, tak na to nesahat
      return value;
    } else {
      // pokud se jedna o boolean ve forme stringu, tak to preved
      return value == 'true' || value == 'false'
        ? `${value == 'true'}`
        : `'${value}'`;
    }
  } else if (typeof value == 'number') {
    // je to cislo
    return value;
  } else {
    // else { // neni to boolean
    if (value == true || value == false) {
      return value == true;
    } else {
      return `'${value}'`;
    }
  }
}

function convertorNumberValue(value: string | string[]): any {
  let val;
  if (Array.isArray(value)) {
    val = value.filter((x) => !!x)[0];
  } else {
    val = value;
  }
  if (
    !!val &&
    (val as unknown as string).length > 4 &&
    isValid(parseISO(val + ''))
  ) {
    const date = new Date(val as unknown as string);
    if (!(date.toString() === 'Invalid Date' || date.getFullYear() > 9999)) {
      return `'${val}'`;
    }
  }
  try {
    return parseFloat(val);
  } catch (e) {
    return `'${val}'`;
  }
}
