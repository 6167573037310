import {NgModule} from '@angular/core';
import {DIRECTIVES, IMPORTS, PIPES, PROVIDERS} from './index';

@NgModule({
  imports: [...IMPORTS],
  declarations: [...PIPES, ...DIRECTIVES],
  exports: [...PIPES, ...DIRECTIVES],
  providers: [...PROVIDERS, ...PIPES, ...DIRECTIVES],
})
export class ConfigFormServiceModule {}
