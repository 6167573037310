import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DataState, LayoutState, LayoutVM} from '../models';
import {
  selectUserParameterByCurrentUserAndName,
  UserParameterName,
} from '@tsm/user-parameters';
import {isMobile} from '@tsm/framework/functions';
import {getRuntimeInfo} from '@tsm/runtime-info';
import {getUiConfig} from '@tsm/ui-config';
import {DtlMenuItemType} from '@tsm/framework/plugin';

export const selectLayoutState = createFeatureSelector<LayoutState>('layout');

export const selectAllLayoutMenuState = createSelector(
  selectLayoutState,
  (layout) => {
    if (
      layout?.layoutMenu?.loading == false &&
      layout?.layoutMenu?.data?.length === 0
    ) {
      return [];
    }
    return layout?.layoutMenu?.data;
  },
);

export const selectLayoutMenuState = createSelector(
  selectLayoutState,
  (layout) => {
    if (
      layout?.layoutMenu?.loading == false &&
      layout?.layoutMenu?.data?.length === 0
    ) {
      return [];
    }
    const selfcare = (window as any)?.app?.params?.selfcare ?? false;

    return layout?.layoutMenu?.data.filter(
      (x) =>
        x.type ===
          (selfcare ? DtlMenuItemType.SELFCARE : DtlMenuItemType.MENU) ||
        x.type == null,
    );
  },
);

export const selectLayoutBookmarkMenuState = createSelector(
  selectLayoutState,
  (layout) => {
    if (
      layout?.layoutMenu?.loading == false &&
      layout?.layoutMenu?.data?.length === 0
    ) {
      return [];
    }
    return layout?.layoutMenu?.data
      .filter((x) => x.type === DtlMenuItemType.BOOKMARK)
      .sort((a, b) => a.priority - b.priority);
  },
);

export const loadingLayoutMenuState = createSelector(
  selectLayoutState,
  (layout) => {
    return layout?.layoutMenu?.loading;
  },
);

export const getPreviousUrl = createSelector(
  selectLayoutState,
  (layout) => layout.previousUrl,
);

export interface Value {
  value: Value;
}

const getValueState = createFeatureSelector<Value>('value');
export const getValue = createSelector(getValueState, (x) => x.value);

export interface Context {
  context: Context;
}

const getContextState = createFeatureSelector<Context>('context');
export const getContext = createSelector(getContextState, (x) => x.context);

export const selectDataState = createFeatureSelector<DataState>('dataConsole');

export const getDataAndContext = createSelector(
  getContextState,
  getValueState,
  (context, value) => {
    const result = {
      context: context,
      value: value,
    };
    return result;
  },
);

export const getLayoutVM = () =>
  createSelector(
    getRuntimeInfo,
    selectLayoutState,
    getUiConfig,
    selectUserParameterByCurrentUserAndName(
      UserParameterName.REMEMBER_PINNED_MENU,
    ),
    selectUserParameterByCurrentUserAndName(
      UserParameterName.REMEMBER_LOCKED_MENU,
    ),
    (runtime, layout, uiConfig, pinnedParam, lockedParam) => {
      const isMobileMode = isMobile() || window.innerWidth < 991;
      const pinned = isMobileMode
        ? false
        : pinnedParam?.parameterValue === 'true';
      const locked = isMobileMode
        ? false
        : lockedParam?.parameterValue === 'true';
      const result: LayoutVM = {
        topbar: {
          activeTopBarItem: layout.activeTopBarItem,
          //  logika class v memoized selectoru
          opened: pinned ? locked : layout.opened,
          pinned: pinned,
          staticMenuMobileActive: layout.opened || locked,
          staticMenuDesktopInactive: pinned ? !locked : !layout.opened,
          name: runtime != null ? runtime.name : '',
          env: uiConfig.data?.env,
        },
      };
      return result;
    },
  );
