<input
  [ngStyle]="{display: readonly ? 'none' : ''}"
  [style]="style"
  type="text"
  #inputElement
  [(ngModel)]="val"
  [tabindex]="tabindex"
  [pKeyFilter]="keyFilter"
  class="p-inputtext p-corner-all p-state-default p-component"
  data-cy="dtl-form-input-key-filter-regex"
  [placeholder]="placeholder ? (placeholder | transloco) : ''"
/>
<span
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
  [ngClass]="val ? 'readonly-text' : 'readonly-text empty'"
>
  {{ val ? val : 'none' }}
</span>
