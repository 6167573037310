<div class="flex w-12 flex-column wrapper">
  <div class="flex w-12">
    @if (form.get('localizationData')) {
      <div class="flex flex-1">
        <div
          class="flex flex-1"
          *ngLet="
            form.get('localizationData').value
              | localizationDataTranslate
                : field
                : form.get(field).value as value
          "
        >
          @if (readonly) {
            @if (
              form
                .get('localizationData')
                ?.get(translocoService.getActiveLang())
                ?.get(field).value === value
            ) {
              <tsm-form-field
                [formControl]="
                  form.get('localizationData')?.get('en')?.get(field)
                "
                [type]="type"
                label="{{ readonlyShowLabel ? label : null }}"
                [readonly]="true"
              >
              </tsm-form-field>
            } @else {
              <tsm-form-field
                [formControl]="form.get(field)"
                [type]="type"
                label="{{ readonlyShowLabel ? label : null }}"
                [readonly]="true"
              >
              </tsm-form-field>
            }
          }
          @if (!readonly) {
            <ng-content></ng-content>
          }
        </div>
      </div>
    }
    <div class="flex align-items-center" *ngIf="!readonly">
      <button
        pButton
        pRipple
        class="p-button-text p-button-rounded ml-1"
        icon="pi pi-language"
        (click)="showLangs = !showLangs"
      ></button>
    </div>
  </div>
  <div
    *ngIf="showLangs && !readonly"
    class="flex flex-column w-full shadow-1 border-1 border-300 border-round relative z-1 mx-2 mt-3 mb-3 p-3 arrow arrow--top"
  >
    <ng-container *ngFor="let lang of langs">
      <tsm-form-field
        [formControl]="getLocalizedField(field, lang)"
        [type]="type"
        label="{{ label }} [{{ lang }}]"
      >
      </tsm-form-field>
    </ng-container>
  </div>
</div>
