import * as layoutStateReducer from './layout-tsm-state.reducer';
import {ActionReducer, MetaReducer} from '@ngrx/store';
import {LayoutState} from '../models';

export function layoutReducer(state, action) {
  return layoutStateReducer.reducer(state, action);
}

export function storeResetMetaReducer(
  reducer: ActionReducer<LayoutState>,
): ActionReducer<LayoutState> {
  return storeReset('[LoginScreen] Logout')(reducer);
}

export const metaReducers: MetaReducer<LayoutState>[] = [storeResetMetaReducer];

export function storeReset(options: string) {
  const {action: actionType} = Object.assign({}, {action: options} || {});
  return (reducer) => (state, action) => {
    if (action.type === actionType) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
