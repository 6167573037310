import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/tickets/plugins-i18n';
import type {Config} from '@tsm/framework/config';
import {getUserId} from '@tsm/framework/functions';

export const controlsNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-ticket-new',
  name: 'Ticket new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'Ticket',
  tsmControls: {
    name: {
      name: translationShared.shared.name,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.name,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    severity: {
      name: translationShared.shared.severity,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.severity,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-severity-lov',
      },
    },
    priority: {
      name: translationShared.shared.priority,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.priority,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-priority-lov',
      },
    },
    channel: {
      name: translationShared.shared.channel,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.channel,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-channel-lov',
      },
    },
    ticketCategory: {
      name: translationShared.shared.category,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.category,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-category-lov',
      },
    },
    externalId: {
      name: translationShared.shared.externalId,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.externalId,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    creationGroup: {
      name: translationShared.shared.group,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.group,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-user-user-group-lov',
        inputs: {
          userId: getUserId(),
        },
      },
    },
    description: {
      name: translationShared.shared.description,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'tiptap',
          label: translationShared.shared.description,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
  },
};

export const controlsDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-ticket-detail',
  name: 'Ticket detail',
  description: '',
  useType: 'FORM_DETAIL',
  entityType: 'Ticket',
  tsmControls: {
    'Ticket.Submit': {
      name: translationShared.shared.submitButton,
      previewComponent: {
        selector: 'tsm-preview-button',
        inputs: {
          title: translationShared.shared.submitButton,
        },
      },
      inputs: {
        defaultValue: {
          disableValidations: true,
        },
        helpMessage: translation.ticketsPlugins.ticketsSubmitHelp,
        selector: 'tsm-form-button-inputs',
      },
    },
    'Ticket.Details.Name': {
      name: translationShared.shared.name,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.name,
          labelPosition: 'left',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Ticket.Details.Key': {
      name: translationShared.shared.key,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.key,
          labelPosition: 'left',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Ticket.Header': {
      name: translationShared.shared.header,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.header,
          labelPosition: 'left',
          type: 'previewHeader',
        },
      },
    },
    'Ticket.Toolbar.Settings': {
      name: translationShared.shared.settingsButton,
      description: null,
      icon: 'pi pi-ellipsis-v',
    },
    'Ticket.Toolbar.Refresh': {
      name: translationShared.shared.refreshButton,
      description: null,
      icon: 'pi pi-sync',
    },
    'Ticket.Details.Type': {
      name: translationShared.shared.type,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.type,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Ticket.Details.State': {
      name: translationShared.shared.state,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.state,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-status-lov',
      },
    },
    'Ticket.Details.Category': {
      name: translationShared.shared.category,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.category,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-category-lov',
      },
    },
    'Ticket.Details.Priority': {
      name: translationShared.shared.priority,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.priority,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-priority-lov',
      },
    },
    'Ticket.Details.Severity': {
      name: translationShared.shared.severity,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.severity,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-severity-lov',
      },
    },
    'Ticket.Details.Channel': {
      name: translationShared.shared.channel,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.channel,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-channel-lov',
      },
    },
    'Ticket.Details.ExternalId': {
      name: translationShared.shared.externalId,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.externalId,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Ticket.Details.Labels': {
      name: translationShared.shared.labels,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.labels,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'tsm-data-tags',
        inputs: {
          url: (config: Config) => config.apiUrls.ticket + '/ticket/dataTags',
          parentPriv: 'Tick.Ticket',
        },
      },
    },
    'Ticket.Dates.Created': {
      name: translationShared.shared.created,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.created,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Ticket.Dates.Closed': {
      name: translationShared.shared.closed,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.closed,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    'Ticket.People.Tasks': {
      name: translationShared.shared.tasks,
      description: null,
      imageUrl: 'assets/preview/prew_task-card.png',
    },
    'Ticket.People.Created': {
      name: translationShared.shared.createdBy,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.createdBy,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Ticket.People.Closed': {
      name: translationShared.shared.closedBy,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.closedBy,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Ticket.Problem.Problem': {
      name: translationShared.shared.problem,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.problem,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-problem-lov',
      },
    },
    'Ticket.Solution.Solution': {
      name: 'Resolution',
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.resolution,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-resolution-lov',
      },
    },
    'Ticket.Accordions.Description': {
      name: translationShared.shared.description,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.description,
          labelPosition: 'left',
          type: 'tiptap',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
    'Ticket.Accordions.Relations': {
      name: translationShared.shared.relation,
      description: null,
      previewComponent: {
        selector: 'tsm-relationship-tab',
        inputs: {
          label: translationShared.shared.relation,
        },
      },
    },
    'Ticket.Tabs.Process': {
      name: translationShared.shared.process,
      description: null,
      imageUrl: 'assets/preview/prew_process.png',
    },
  },
};
export const controlsTypeDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-ticket-type-detail',
  name: 'Ticket type detail',
  description: '',
  useType: 'CONFIG',
  entityType: 'TicketType',
  tsmControls: {},
};

export const controlsRelatedEntityTypeDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-related-entity-type-detail',
    name: 'Ticket related entity type detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'TicketRelatedEntityType',
    tsmControls: {},
  };

export const controlsRelatedEntityDetailRefDataConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-related-entity-detail-refdata.detail',
    name: 'Ticket related entity detail refdata',
    description: '',
    useType: 'CONFIG',
    entityType: 'TicketRelatedEntityDetail',
    tsmControls: {},
  };

export const controlsNewWizardConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-ticket-new.wizard',
  name: 'New ticket wizard',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'TicketTemplate',
  tsmControls: {},
};

export const controlsTemplateNewWizardConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-ticket-template-new.wizard',
    name: 'New ticket template wizard',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'TicketTemplate',
    tsmControls: {},
  };
