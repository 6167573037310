export class UiIconsBundle {
  getAll(): string[] {
    return [
      '360-view',
      '360-view-1',
      'account',
      'add-image',
      'address',
      'address-book',
      'address-book-1',
      'archive',
      'attachment',
      'audit',
      'audit-1',
      'bars-progress',
      'bars-progress-1',
      'basket',
      'book-bookmark',
      'book-bookmark-2',
      'booking',
      'booking-1',
      'border-all',
      'cards-blank',
      'catalog',
      'catalog-1',
      'category-alt',
      'chart-pie-alt',
      'chart-pie-alt-1',
      'cli',
      'comment',
      'company-location',
      'contact',
      'crm',
      'custom-actions',
      'customer',
      'dashboard',
      'data-service',
      'detail',
      'discount',
      'delivery',
      'delivery-1',
      'description-alt',
      'description-alt-1',
      'description-alt-2',
      'dropdown',
      'dropdown-1',
      'envelope',
      'envelope-1',
      'envelope-open',
      'envelope-open-1',
      'error-404',
      'event',
      'features',
      'features-1',
      'file-excel',
      'file-pdf',
      'file-powerpoint',
      'file-word',
      'filter',
      'financial-info',
      'frog',
      'grid-alt',
      'history',
      'information',
      'input-numeric',
      'input-numeric-1',
      'internet-service',
      'invoice',
      'kpi',
      'lead',
      'logo',
      'network',
      'new-order',
      'new-user',
      'notes',
      'notification',
      'order',
      'payment',
      'person',
      'place',
      'process',
      'product',
      'restricted',
      'router',
      'satellite',
      'sd-card',
      'search',
      'segment',
      'service',
      'service-1',
      'settings',
      'sim-card',
      'sms-service',
      'stats',
      'stats-1',
      'status',
      'stock-item',
      'subscription-alt',
      'table-list',
      'table-list-1',
      'table-tree',
      'table-tree-1',
      'table-tree-2',
      'task',
      'tasks',
      'ticket',
      'tickets',
      'user',
      'user-group',
      'user-settings',
      'voice-service',
      'warning',
      'window-maximize',
      'window-maximize-1',
      'window-restore',
      'window-restore-1',
      'www',
    ];
  }
}
