import {Action, select, Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';

import {IdentifiedPack} from '../models';
import {isIdentifiedPack, isPack} from './load-and-wait.operator';

export function loadAndWaitForStore<StoreType, DataType>(
  store: Store<StoreType>,
  loadingAction: Action,
  selector: (state: StoreType) => DataType,
) {
  store.dispatch(loadingAction);
  return store.pipe(
    select(selector),
    filter((t: IdentifiedPack<DataType> | IdentifiedPack<any> | any) => {
      if (t instanceof Array) {
        return t && t.find((x) => !x.loading);
      }
      if (isIdentifiedPack(t)) {
        return t && !t.loading && !!t.data;
      }
      if (isPack(t)) {
        return t && !t.loading && !!t.data;
      }
      return t !== undefined && t !== null;
    }),
    take(1),
  );
}
