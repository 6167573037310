import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'constructCharsForNew',
})
export class ConstructCharsForNewPipe implements PipeTransform {
  transform(entity: any): any {
    const chars = entity?.chars ?? {};
    const result = {
      ...chars,
      chars,
    };
    return result;
  }
}
