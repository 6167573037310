import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CommentDataService, CommentService} from '../services';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteDataComment,
  DeleteDataCommentError,
  DeleteDataCommentSuccess,
  SaveDataComment,
  SaveDataCommentError,
  SaveDataCommentSuccess,
} from '../actions';
import {translation} from '../i18n';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {ListingService} from '@tsm/listing-lib/service';
import {ConfigService} from '@tsm/framework/config';
import {Config} from '@tsm/framework/config';
import {Store} from '@ngrx/store';

@Injectable()
export class CommentEffects {
  saveData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveDataComment),
      concatMap(({ownerId, ownerType, comment, noSuccessToast}) =>
        this.commentDataService.saveComment(ownerId, ownerType, comment).pipe(
          map((env) => {
            if (env.success) {
              if (!noSuccessToast) {
                this.toast.showToast(
                  translation.comments.save.success,
                  ToastSeverity.SUCCESS,
                  3000,
                );
              }
              return SaveDataCommentSuccess({
                ownerId: ownerId,
                ownerType: ownerType,
                comment: env.data,
              });
            } else {
              this.toast.showError(env.error, translation.comments.save.error);
              return SaveDataCommentError({
                ownerId: ownerId,
                ownerType: ownerType,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  deleteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteDataComment),
      exhaustMap(({ownerId, ownerType, comment}) =>
        this.commentDataService.deleteCommentById(comment.id).pipe(
          map((env) => {
            if (env.success) {
              this.toast.showToast(
                translation.comments.delete.success,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteDataCommentSuccess({
                ownerId: ownerId,
                ownerType: ownerType,
                commentId: comment.id,
              });
            } else {
              this.toast.showError(
                env.error,
                translation.comments.delete.error,
              );
              return DeleteDataCommentError({
                ownerId: ownerId,
                ownerType: ownerType,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commentService: CommentService,
    private listingService: ListingService,
    private commentDataService: CommentDataService,
    private config: ConfigService<Config>,
    private store: Store,
    private toast: ToastService,
  ) {}
}
