import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import {filter} from 'rxjs/operators';
import {HistoryModel, isHistoryRouteData} from '../models';
import {translate} from '@jsverse/transloco';
import {getUserId} from '@tsm/framework/functions';
import {RuntimeService} from '@tsm/runtime-info';
import {RxStompService} from '@tsm/framework/websocket';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private static TO_REPLACE = ['entityId', 'entityType'];
  private lastHistoryItem: HistoryModel;

  constructor(
    private rxStompService: RxStompService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private runtimeService: RuntimeService,
  ) {
    router.events
      .pipe(
        filter((e: any): e is RouterEvent => e instanceof NavigationEnd),
        filter(() => this.runtimeService.isLoggedIn()),
      )
      .subscribe((navigationEnd) => {
        const data = this.buildDataObject(this.activatedRoute.snapshot, 'data');
        if (isHistoryRouteData(data as any)) {
          const params = this.buildDataObject(
            this.activatedRoute.snapshot,
            'params',
          );
          const temp: HistoryModel = {
            created: new Date(),
            entityId: null,
            entityType: data.logHistory.entityType,
            icon: data.logHistory.icon,
            url: navigationEnd.url,
            name: translate(data.logHistory.title), // zapis vcetne prekladu, protoze pro zobrazeni nemusi byt modul k dispozici
            userId: getUserId(),
          };
          if (data.logHistory.paramsMap) {
            HistoryService.TO_REPLACE.forEach((toReplace) => {
              if (data.logHistory.paramsMap[toReplace]) {
                temp[toReplace] = params[data.logHistory.paramsMap[toReplace]];
              }
            });
          }
          this.appendToHistory(temp);
        }
      });
  }

  public appendToHistory(historyModel: HistoryModel) {
    historyModel.userId = getUserId();

    if (this.runtimeService.isLoggedIn()) {
      this.lastHistoryItem = historyModel;
      this.rxStompService.publish({
        destination: `/app/access-log`,
        body: JSON.stringify(historyModel),
      });
    }
  }

  public appendManyToHistory(entityIds: string[], entityType: string) {
    const temp: HistoryModel = {
      created: new Date(),
      entityIds: entityIds,
      entityType: entityType,
      userId: getUserId(),
    };
    if (this.runtimeService.isLoggedIn()) {
      this.rxStompService.publish({
        destination: `/app/access-log`,
        body: JSON.stringify(temp),
      });
    }
  }

  private buildDataObject(
    tree: {data: any; firstChild?: any; params: any},
    whatToGet: 'data' | 'params',
  ): any {
    let obj = {};
    let pointer = tree;
    while (pointer.firstChild != null) {
      obj = {...obj, ...pointer[whatToGet]};
      pointer = pointer.firstChild;
    }
    obj = {...obj, ...pointer[whatToGet]};
    return obj;
  }
}
