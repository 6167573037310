import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Optional,
  Output,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgControl, FormsModule} from '@angular/forms';
import {FormFieldInput, TsmAbstractControl} from '@tsm/framework/forms';
import {
  MonacoEditorConstructionOptions,
  MonacoEditorMarker,
  MonacoStandaloneCodeEditor,
} from '@tsm/framework/monaco-editor';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {DtlMonacoEditorModule} from '@tsm/framework/monaco-editor';

@Component({
  selector: 'dtl-form-input-json-object',
  templateUrl: './form-input-json-object.component.html',
  host: {
    class: 'p-state-filled-fix p-inputtext',
  },
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputJsonObjectComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputJsonObjectComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputJsonObjectComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DtlMonacoEditorModule, FormsModule],
})
export class FormInputJsonObjectComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @Input() updateIfDirty = false;

  @Input() widgetHeight: any;

  @Input() inline = false;

  @Input() resizable = true;

  @Output() init: EventEmitter<MonacoStandaloneCodeEditor> = new EventEmitter();

  ngControl: TsmAbstractControl;

  private _editorOptions: MonacoEditorConstructionOptions;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  get stringifiedVal(): string {
    // do not serialize empty input
    if (this.val === null || this.val === undefined) {
      return '';
    }

    try {
      return JSON.stringify(this.val, null, 4);
    } catch (e) {
      if (e instanceof TypeError) {
        // pass
      } else {
        throw e;
      }
      return '';
    }
  }

  set stringifiedVal(value: string) {
    try {
      // do not attempt to parse empty string
      if (!value || value.trim() === '') {
        this.val = null;
      } else {
        this.val = JSON.parse(value);
      }
      this.clearFormError('$customErrorCode-parser');
    } catch (e) {
      // TODO: Localize ?
      this.setFormError(
        '$customErrorCode-parser',
        'Unable to parse object from given JSON: ' + e.message,
      );
    }
  }

  get editorOptions(): MonacoEditorConstructionOptions {
    return {
      // manual settings
      fontSize: 11,
      minimap: {
        enabled: false,
      },
      // taken from inputs
      readOnly: this.readonly,
      tabIndex: this.tabindex,
      ...this._editorOptions,
    };
  }

  @Input()
  set editorOptions(value: MonacoEditorConstructionOptions) {
    this._editorOptions = value;
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.ngControl = ngControl.control as TsmAbstractControl;
      });
    }
  }

  onEditorInitialized(editor: MonacoStandaloneCodeEditor) {
    editor.onDidFocusEditorWidget(() => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    editor.onDidBlurEditorWidget(() => {
      this.focused = false;
      this.cdr.markForCheck();
    });

    this.init.emit(editor);
  }

  onMarkersChanged(errors: MonacoEditorMarker[]) {
    const errorMessage = errors
      .filter((m) => m.severity >= 8)
      .map((m) => `Line ${m.startLineNumber}: ${m.message}`)
      .join(', ');

    if (!errorMessage) {
      this.clearFormError('$customErrorCode-markers');
    } else {
      // TODO: Localize ?
      this.setFormError(
        '$customErrorCode-markers',
        'Syntax errors: ' + errorMessage,
      );
    }
  }

  private clearFormError(key: string): void {
    if (!this.ngControl) {
      return;
    }

    const errorsCopy = {...this.ngControl.errors};

    if (errorsCopy.hasOwnProperty(key)) {
      delete errorsCopy[key];
    }

    if (Object.keys(errorsCopy).length === 0) {
      this.ngControl.setErrors(null);
    } else {
      this.ngControl.setErrors(errorsCopy);
    }
  }

  private setFormError(key: string, value: string): void {
    if (!this.ngControl) {
      return;
    }

    const errorsCopy = {...this.ngControl.errors};
    errorsCopy[key] = value;
    this.ngControl.setErrors(errorsCopy);
  }
}
