import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {concatMap, exhaustMap, filter, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {animationBuffer, withLatestCached} from '@tsm/framework/root';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {
  DeleteUserGroup,
  DeleteUserGroupError,
  DeleteUserGroupSuccess,
  DiffUserGroup,
  DiffUserGroupError,
  DiffUserGroupSuccess,
  LoadBatchUserGroupByCode,
  LoadBatchUserGroupByCodeError,
  LoadBatchUserGroupByCodeSuccess,
  LoadUserGroupByCode,
  LoadUserGroupByCodeError,
  LoadUserGroupByCodeSuccess,
  LoadUserGroupById,
  LoadUserGroupByIdError,
  LoadUserGroupByIds,
  LoadUserGroupByIdsError,
  LoadUserGroupByIdsSuccess,
  LoadUserGroupByIdSuccess,
  LoadUserGroups,
  LoadUserGroupsError,
  LoadUserGroupsSuccess,
  SaveUserGroupNotificationConfig,
  SaveUserGroupNotificationConfigError,
  SaveUserGroupNotificationConfigSuccess,
  UpsertUserGroup,
  UpsertUserGroupError,
  UpsertUserGroupSuccess,
} from '../actions';
import {EntityIdentif, UserGroup} from '../model';
import {selectUserGroupByCode, selectUserGroupById} from '../selectors';
import {CommonApiService, UserGroupService} from '../service';
import {Router} from '@angular/router';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {LoginFinishedSuccess, Logout} from '@tsm/core';

@Injectable()
export class UserGroupEffects {
  private readonly API_PATH = 'v1/user-groups';
  translation = translation;
  translationShared = translationShared;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<UserGroup, UserGroup>,
    private store: Store,
    private userGroupService: UserGroupService,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupById),
      withLatestCached((action) =>
        this.store.select(selectUserGroupById(action.id)),
      ),
      concatMap(([action, userGroup]) => {
        if (action?.forcedReload || !userGroup.data) {
          return this.commonApiService
            .getEntity(EntityIdentif.USER_GROUP, action.id)
            .pipe(
              map((env) =>
                env.success
                  ? LoadUserGroupByIdSuccess({userGroup: env.data})
                  : LoadUserGroupByIdError({id: action.id, error: env.error}),
              ),
            );
        } else {
          return of(LoadUserGroupByIdSuccess({userGroup: userGroup.data}));
        }
      }),
    ),
  );

  loadByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupByIds),
      animationBuffer(
        this.actions$,
        LoadUserGroupByIds,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((ids) => {
        return ids.map((x) => x.ids);
      }),
      map((ids) => Array.from(new Set(ids))),
      concatMap((ids) => {
        const flattend = ids.reduce((acc, arr) => acc.concat(arr), []);
        return this.commonApiService
          .getEntitiesByIds(
            EntityIdentif.USER_GROUP,
            Array.from(new Set(flattend)),
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadUserGroupByIdsSuccess({userGroups: env.data})
                : LoadUserGroupByIdsError({ids, error: env.error}),
            ),
          );
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupByCode),
      withLatestCached((action) =>
        this.store.select(selectUserGroupByCode(action.code)),
      ),
      concatMap(([action, userGroup]) => {
        return userGroup && userGroup.data
          ? of(LoadUserGroupByCodeSuccess({userGroup: userGroup.data}))
          : this.commonApiService
              .getEntityByCode(EntityIdentif.USER_GROUP, action.code)
              .pipe(
                map((env) =>
                  env.success
                    ? LoadUserGroupByCodeSuccess({userGroup: env.data})
                    : LoadUserGroupByCodeError({
                        code: action.code,
                        error: env.error,
                      }),
                ),
              );
      }),
    ),
  );

  loadBatchByCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBatchUserGroupByCode),
      animationBuffer(
        this.actions$,
        LoadBatchUserGroupByCode,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((ids) => ids.map((x) => x.code)),
      map((codes) => Array.from(new Set(codes))),
      concatMap((codes) => {
        return this.commonApiService
          .getEntitiesByCodesSharedRequest(EntityIdentif.USER_GROUP, codes)
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchUserGroupByCodeSuccess({userGroups: response.data})
                  : LoadBatchUserGroupByCodeError({
                      codes: codes,
                      error: response.error,
                    });
              return temp;
            }),
          );
      }),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroups),
      exhaustMap(() =>
        this.commonApiService
          .getAll(EntityIdentif.USER_GROUP)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserGroupsSuccess({entities: env.data})
                : LoadUserGroupsError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroup),
      concatMap(({userGroup, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(EntityIdentif.USER_GROUP, userGroup, userGroup.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.groupSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserGroupSuccess({
                  userGroup: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.groupSaveFailure,
                );
                return UpsertUserGroupError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserGroup),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(EntityIdentif.USER_GROUP, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.groupDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/user-management/user-group']);
              }
              return DeleteUserGroupSuccess({id: id, listingId: listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects.groupDeleteFailure,
              );
              return DeleteUserGroupError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffUserGroup),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffUserGroupSuccess({
                  userGroups: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffUserGroupError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  redirectToDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroupSuccess),
      tap(({userGroup, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            '/user-management/user-group/'.concat(userGroup.id),
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UpsertUserGroupSuccess,
        DeleteUserGroupSuccess,
        DiffUserGroupSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  saveNotifConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveUserGroupNotificationConfig),
      // groupBy(action => action.id),
      // mergeMap(group =>
      //   group.pipe(debounceTime(500), switchMap(latestValue => of(latestValue)))
      // ),
      concatMap((action) =>
        this.userGroupService
          .saveNotificationConfiguration(action.id, action.config)
          .pipe(
            map((envelope) => {
              if (envelope.success) {
                return SaveUserGroupNotificationConfigSuccess({
                  id: action.id,
                  config: action.config,
                });
              } else {
                this.ts.showError(
                  envelope.error,
                  translation.userManagementService.effects
                    .saveUserNotificationConfigError,
                );
                return SaveUserGroupNotificationConfigError({
                  id: action.id,
                  error: envelope.error,
                });
              }
            }),
          ),
      ),
    ),
  );
}
