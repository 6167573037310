import {FormArray, FormControl, FormGroup} from '@angular/forms';

type FormWithControls = FormGroup | FormArray;

function collectInvalidControls(
  form: FormWithControls,
  invalidControls: string[],
  parentKey: string = '',
): void {
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    const controlPath = parentKey ? `${parentKey}.${key}` : key;

    if (control instanceof FormGroup || control instanceof FormArray) {
      collectInvalidControls(control, invalidControls, controlPath);
    } else if (control instanceof FormControl && control.invalid) {
      invalidControls.push(controlPath);
    }
  });
}

export function getInvalidControls(...forms: FormWithControls[]) {
  const invalidControls: string[] = [];
  forms.forEach((form) => collectInvalidControls(form, invalidControls));
  return invalidControls;
}

export function logInvalidControls(...forms: FormWithControls[]): void {
  const invalidControls: string[] = getInvalidControls(...forms);

  if (invalidControls.length > 0) {
    console.warn(`Invalid controls: ${invalidControls.join(', ')}`);
  }
}
