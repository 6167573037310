import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatPhone',
    standalone: true,
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string): string {
    if (/^\d{9}$/g.test(value)) {
      return value.replace(/(\d{3})/g, '$1 ');
    } else if (/^\+\d{12}$/.test(value)) {
      return value.replace(/^(\+420)(\d{3})(\d{3})(\d{3})$/, '$1 $2 $3 $4');
    } else if (/^\d{15}$/g.test(value)) {
      return value.replace(/^(\d{5})(\d{3})(\d{3})(\d{3})$/, '$1 $2 $3 $4');
    }
    return value;
  }

  transformBack(value: string): string {
    return [
      /(\d{3} \d{3} \d{3})/g,
      /^(\+420)(\d{3} \d{3} \d{3})/g,
      /(\d{5} \d{3} \d{3})/g,
    ].some((x) => x.test(value))
      ? value.replace(/ /g, '')
      : value;
  }
}
