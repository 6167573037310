import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {Characteristics} from '../models';
import {characteristicsAdapter, CharacteristicsState} from '../reducers';

const getConfigFormState =
  createFeatureSelector<CharacteristicsState>('characteristics');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getCharacteristicsState = createSelector(
  getConfigFormState,
  (characteristicsState) => characteristicsState.characteristics,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  characteristicsAdapter.getSelectors(getCharacteristicsState);

export const selectCharacteristics = selectAll;

export const selectCharacteristicsById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectCharacteristicsByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectCharacteristicsByFormId = (id: string) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter((e) => e.data && e.data.form && e.data.form.id === id)
      .map((e) => e.data),
  );

//
// export const selectCharacteristicsBySpecificationId = (filter: (((characteristics: Characteristics) => boolean))) =>
//   createSelector(
//     selectAll,
//     entities => {
//       return entities
//         .map(pack => pack.data)
//         .filter((entity) => filter(entity));
//     });

export const selectCharacteristicsByCode = (code: string) =>
  createSelector(selectAll, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter((entity) => entity.code === code);
  });

export const selectCharacteristicsWithFormIdentifiersByCode = (code: string) =>
  createSelector(selectAll, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter((entity) => entity.code === code)
      .map((entity) => ({
        ...entity,
        form: {
          ...entity.form,
          schema: {
            ...entity.form.schema,
            id: entity.form.id,
            code: entity.form.code,
          },
        },
      }));
  });

export const selectCharacteristicsByCodes = (code: string[]) =>
  createSelector(selectAll, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter((entity) => code.includes(entity.code));
  });

export const selectCharacteristicsByFilter = (
  filter: (char: Characteristics) => boolean,
) =>
  createSelector(selectAll, (entities) => {
    return entities.map((pack) => pack.data).filter((entity) => filter(entity));
  });

export const selectCharacteristicsByEntityType = (entityType: string) =>
  createSelector(selectAll, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter((entity) => entity.entityType === entityType);
  });

export const selectCharacteristicsCountByFilter = (
  filter: (char: Characteristics) => boolean,
) =>
  createSelector(selectCharacteristicsByFilter(filter), (data) => {
    return data ? data.length : 0;
  });

export const selectCharacteristicsCountWithoutFilter = () =>
  createSelector(selectAll, (data) => {
    return data ? data.length : 0;
  });
