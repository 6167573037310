export const en = {
  configFormComponents: {
    components: {
      remainingReqTsmControls:
        'Form was not saved. These placeholders are required: {{data}}',
      invalidTsmControl:
        'Form was not saved. These placeholders are not valid: {{data}}',
      checklist: {
        // presunout do plugins-i18n
        title: 'Checklist',
        tooltip: 'To-do list with the option to upload items from a register',
      },
      inCharacteristics: 'In characteristics',
      selectMicroservice: 'Select Microservice',
      placeholders: 'Core attributes',
      dragToReorder: 'Drag to reorder',
      editFavoriteName: 'Rename',
      addToFavorite: 'Add to favorites',
      deleteFavorite: 'Remove from favorites',
      noFavoriteItemYet: 'You have no favorite items yet',
      dropToAddFavorite: 'Drag the Widget to favorites',
      favoriteAdded: 'Widget Added',
      favoriteAlreadyExists: 'The Widget is already in favorites',
      favoriteAddedDetail: "Widget '{{name}}' has been added to favorites",
      entityType: {
        title: 'Entity type',
      },
      swagger: 'Swagger',
      swaggerRefreshToken:
        'The access token has been refreshed. Please invoke the requested query again.',
      tsmPublicApi: 'tSM Public API',
      defaultProfileTooltip: 'Default profile for all users',
      uniqueIdentifier: 'Unique identification within configuration type.',
      characteristicsSorting: 'Logical grouping of characteristics.',
      configTypeLabel: 'Configuration type',
      mnemonicConfigLabel: 'Mnemonic configuration',
      sequenceName: 'Sequence name',
      createSequence: 'Create sequence',
      startValue: 'Start value',
      increment: 'Increment',
      sequence: 'Sequence',
      dbbridge: 'dbBridge - reload',
      config: 'Config',
      configAdvanced: 'Advanced configuration',
      configAdvancedInfo:
        'Custom configuration stored in JSON format. It can be used for customization, ' +
        'such as storing configuration properties for a new microservice, ' +
        'which is created outside the core of tSM and tailored to specific needs.',
      formSelectHint:
        'Used render form. If not specified, an automatic layout is performed based on the current data.',
      baseForm: 'Extension of the base form',
      schemaLocationHint: `Which section of the target form should be displayed:
* Details - among the main items of the introduction form. Use only forms of type 'custom fields'
* Main sections - among the main drop-down sections on the left side of the form
* Bookmark - among bookmarks
* People, Dates, ... - in the appropriate section (if the target module contains it)
* Comments - works as one of the possible forms for adding comments
* Create record - location on screen to create`,
      characteristics: {
        subtitleHint:
          'The title is displayed in the target module as a custom field or section / tab name.',
        readPermission: 'Read permission',
        editPermission: 'Edit permission',
        displayOrder: 'Display Order',
        positionHint:
          'Relative position to other characteristics of the same type',
        attributeNameHint:
          'Name which will be representing current characteristic in JSON format.',
        isUnique: 'Is unique',
        configurable: 'Configurable',
        required: 'Required',
        readonly: 'Read only',
        saveDraft: 'Button for saving as a draft',
        extensible: 'Extendable',
        usedInCharacteristics: 'Used in configuration profiles',
        characteristicsAdministration: 'Characteristic management',
        newCharacteristic: 'New Characteristic',
        attributeName: 'JSON attribute name',
        characteristicsList: 'Characteristics list',
        indexMapping: 'ES mapping type',
        indexMappingHint:
          'Not indexed - the characteristics will not be index in elastic and thus will not be available in listing for viewing / searching\n' +
          'Basic (default) - all fields of the characteristics will be indexed as basic text. Searching on this field is limited to prefix query only. Please note that sorting on numeric and date fields will be only lexicographic.\n' +
          'Typed - everything will be indexed with type information. Although it gives best result for searching, type change on any field is not allowed in the future. You must either create new field or recreate elastic index (administrative task).',
      },
      characteristicsValue: {
        valueAdministration: 'Manage characteristic value',
        valueName: 'Value name',
        unit: 'Unit',
        range: 'Range',
        isDefault: 'Is default',
        characteristicValues: 'Characteristic values',
      },
      configType: {
        editConfigType: 'Edit configuration type',
        createConfigType: 'Create configuration type',
        sortingByModule: 'Logical partitioning by TSM.',
        configTypeList: 'Config type list',
        syncMethod: 'Synchronization method between environments',
      },
      microservice: {
        title: 'Microservice',
        editMicroservice: 'Edit microservice',
        createMicroservice: 'Create microservice',
        backendUrl: 'Microservice URL',
        microserviceList: 'Microservice list',
        deleteMicroserviceConfirmation:
          'Do you really want to delete this record?',
        tooltip:
          'Select the microservice that is responsible for query execution and data transformation of this listing.',
        environmentalVariables: 'Environment variables',
        flyway: 'Flyway',
        threadDump: 'Thread dump',
        scheduledTasks: 'Scheduled tasks',
        installedBy: 'Installed by',
        installedOn: 'Installed on',
        installedRank: 'Installed rank',
        executionTime: 'Execution time',
        checksum: 'Checksum',
      },
      listingProfile: {
        editValueConverterConfig: 'Edit value converter config',
        createValueConverterConfig: 'Create value converter config',
        title: 'Listing Profile',
        editListingProfile: 'Edit listing profile',
        createListingProfile: 'Create listing profile',
        listingProfileList: 'Listing profile list',
        customError: 'Sort Order must be -1 or 0 or 1',
        config: 'Config',
        tqlName: 'TQL name',
        tqlNameTooltip:
          'TQL name of the listing, it is used to identify the listing in the TQL language.',
        exportStatus: 'Export status',
        nextStart: 'Next start',
        profileCategory: 'Profile category',
      },
      myListingProfiles: {
        title: 'My listing profiles',
      },
      backupSet: {
        title: 'Backup sets',
        editBackupSet: 'Edit backup set',
        createBackupSet: 'Create backup set',
        saveDiffSuccess: 'Backup set successfully restored.',
        saveDiffError: 'Restoring backup set failed.',
        diffResult: 'Backup set diff result',
        backupSetName: 'Backup set name',
        backupSetCode: 'Backup set code',
        exportedBy: 'Exported by',
        whenExported: 'Exported',
      },
      listingType: {
        title: 'Listing Type',
        configType: 'Config type',
        editListingType: 'Edit listing type',
        createListingType: 'Create listing type',
        listingTypeList: 'Listing type list',
        elasticSearchIndex: 'Elasticsearch index name',
        elasticSearchIndexTooltip:
          'Existing Elasticsearch index or alias. Each listing query is translated to the Elasticseach DSL Query using this index name.',
        backendUrl: 'Backend URL',
        backendUrlTooltip:
          'Backend URL of this listing (REST source of the data).',
        frontendUrl: 'Frontend URL',
        frontendUrlTooltip:
          'URL address for displaying the listing on the UI. Additionally, it is used for constructing the address to display data from reporting.',
        tqlName: 'TQL name',
        tqlNameTooltip:
          'TQL name of the listing, it is used to identify the listing in the TQL language.',
        customTitle: 'Listing title',
        customTitleTooltip:
          'The title of the listing that will be displayed in its header',
        defaultFilter: 'Default condition',
        defaultFilterTooltip:
          'Ability to specify a default condition that applies to all records (just like access rules).',
        parentPrivilege: 'Permission to edit input widget ',
        parentPrivilegeTooltip:
          'This is a privilege used for direct editing in a table cell (eg: Crm.Customer.Edit => Crm.Customer.Edit#name)',
        massEditing: 'Enable mass editing',
        massEditingTooltip:
          'This is to enable mass editing of multiple selected records.',
        massEditingConfiguration: 'Mass editing configuration',
        massEditingConfigurationInfo:
          'Advanced mass editing settings, where you can set the appearance of buttons, SPEL script and form, which is then applied to selected records.',
        defaultListingType: 'Default listing type',
        defaultListingTypeTooltip:
          'Exactly one type of listing must be set as default within the entity type. If not set, some special features for displaying record details are not available (e.g., displaying changes in backup/restore).',
      },
      valueConverterConfig: {
        title: 'Value converter config',
        edit: 'Edit value converter config',
        create: 'Create value converter config',
        backendBeanName: 'Background bean name',
        paramsFormCode: 'Params form code',
        cacheType: 'Cache type',
      },
      tsmmodule: {
        title: 'Tsm Module',
        editTsmmodule: 'Edit tsm module',
        createTsmmodule: 'Create tsm module',
        tsmModuleList: 'TSM module list',
        deleteTsmModuleDialog:
          'Do you really want to delete this record? It could be referenced from another part of the application, such references will stop working.',
        jsonParserError:
          'Unable to parse JSON. Please check that the provided value is a correct JSON object.',
      },
      entitySpecifications: {
        newCharacteristicsGroup: 'New configuration profile',
        specificationsList: 'Specifications list',
        allowedRelationships: 'Allowed relationships',
        allowedRelationshipsInfo:
          'This configuration allows you to restrict the types of relationships that can be created for this record. ' +
          'If left blank, the record can be created without a type of  comment / attachment / worklog / notification can be attached to the record. You can enforce ' +
          'a specific type, set a default type or set list of allowed types. ' +
          'To display comments / attachments / worklogs / notifications on the detaile form, use the corresponding widgets in the tSM Form Designer.',
      },
      mnemonicConfig: {
        editMnemonicConfig: 'Edit mnemonic',
        createMnemonicConfig: 'Create mnemonic',
        mnemonicConfigList: 'Mnemonic config list',
        deleteMnemonicConfigConfirmation:
          'Do you really want to delete this record? It could be referenced from another part of the application, such references will stop working.',
      },
      listingColumn: {
        listingColumnList: 'Listing column list',
      },
      editgrid: {
        footer: 'Footer',
        header: 'Header',
        addHeaderRow: 'Add header row',
        addHeaderRowTooltip: 'You can add header row by clicking on the button',
        addFooterRow: 'Add footer row',
        addFooterRowTooltip: 'You can add footer row by clicking on the button',
        addCell: 'Add cell',
        colspan: 'colspan',
        rowspan: 'rowspan',
        width: 'width',
        fractionDigits: 'fraction digits',
      },
      entitySpecificationCharacteristic: {
        transformation: 'Transformation',
        settingTransformation: 'Setting transformation',
        regexForValidation: 'Regex for validation',
      },
      form: {
        applicableUnits: 'Applicable units',
        key: 'Key',
        variable: 'Variable',
        toolbar: 'Toolbar',
        pageHeader: 'Page header',
        header: 'Header',
        back: 'Navigate back',
        undo: 'Undo',
        undoTooltip2: 'Undo changes',
        redo: 'Redo',
        reset: 'Reset form content',
        redoTooltip2: 'Redo changes',
        resetTooltip: 'Reset form to the initial state',
        formDesigner: 'Form designer',
        formPreview: 'Preview Form',
        savedData: 'Saved Data (JSON)',
        formType: 'Form Type',
        newForm: 'New Form',
        schemaGenerator: 'Schema generator',
        sourceCodeJson: 'Data source code (json)',
        schema: 'Schema',
        fullscreenInTooltip: 'View the form in full screen',
        fullscreenOutTooltip: 'Exit full screen mode (Esc)',
        undoTooltip: 'Undo the last action',
        undoShortcut: 'Ctrl+z',
        formSorting: 'Logical grouping of forms.',
        listing: 'Show field in listing',
        innerForm: 'Inner form',
        formCode: 'Form code',
        formCategoryHint: `Specifying a form:
* Form - standard view as main section or tab.
* Dialog - display in modal window and labels are displayed above the text.
* Custom field - typically display one item without a label for insertion into a detailed section (the title is taken from the characteristic)
* Dashboard - the form is designed to be displayed as a dashboard`,
        favoriteComponents: 'Favorite Widgets',
        addToFavorites: 'Add to Favorites',
        pinItem:
          'Keeps the category open even if other categories are expanded.',
        removeFromFavorites: 'Remove from Favorites',
        basicComponents: 'Basic Components',
        basicInfo: 'Basic info',
        customClass: 'Custom class',
        pageSize: 'Page size',
        rowsPerPageOptions: 'Rows per page options',
        textField: 'Text Item',
        displayTextField: 'Text to display',
        maskField: 'Mask Field',
        mask: 'Mask',
        phoneField: 'Phone',
        placeholder: 'Placeholder',
        numberField: 'Numeric field',
        inputIconPicker: 'Icon picker',
        textArea: 'Text Area',
        longText: 'Long text',
        checkbox: 'Check Box',
        checkboxLabel: 'Checkbox',
        triStateCheckboxLabel: 'Tri state checkbox',
        leftLabel: 'Left label',
        rightLabel: 'Right label',
        switchLabel: 'Switch',
        dateLabel: 'Date',
        dateTimeLabel: 'Date & Time',
        minDate: 'Min date',
        maxDate: 'Max date',
        select: 'Select',
        submitButton: 'Submit button',
        submitRequestButton: 'Submit request button',
        mergeRemoteAndLocalData: 'Merge manually entered data with remote data',
        remoteData: 'Expression for remote data',
        remoteFieldTitle: 'Attribute name for value name',
        remoteFieldConst: 'Attribute name for value',
        dataComponents: 'Data Components',
        register: 'Register',
        userRegister: 'User register',
        userRegisterListing: 'Advanced - User register listing',
        userChoice: 'User selection',
        personChoice: 'Person selection',
        userLink: 'Customer link',
        contractLink: 'Contract link',
        contract: 'Contract',
        orderLink: 'Order link',
        leadLink: 'Link to lead',
        leadsByOrder: 'Leads by Order',
        assignPreventiveActions: 'Assign Preventive Actions to Device Type',
        dataView: 'Data view component',
        tsmComponents: 'TSM Components',
        tasks: 'Tasks',
        taskList: 'Task List',
        ticketList: 'Ticket List',
        ticketsBySeverityGraphs: 'Tickets by severity (charts)',
        ticketsBySeverityCards: 'Tickets by severity (card)',
        wfmByWorkResourceGraphs: 'WFM by work resource (charts)',
        wfmByTaskGraphs: 'WFM by task (charts)',
        solvedTickets: 'Received/resolved tickets',
        layout: 'Layout',
        page: 'Page',
        toColumns: 'To Columns',
        htmlContent: 'HTML Content',
        html: 'Html',
        help: 'Help',
        textHint: 'Text hint',
        content: 'Content',
        sourceCode: 'Source code',
        iframe: 'External content',
        iframeTooltip: 'External content - this is an IFRAME',
        saveForm: 'Save form',
        deleteFormData: 'Delete form preview data',
        componentDeletion: 'Delete layout component',
        favoriteWidgetDeletion: 'Delete favorite widget?',
        favoriteWidgetDeletionMessage: 'This action cannot be undone.',
        duplicateVariable:
          'The specified variable name already exists in the form.',
        deleteLayoutWidget:
          'The layout component will be removed, including the components it contains. Do you want to continue?',
        requiredValue: 'This value is required',
        valueMinLength: 'The value does not have required minimum length',
        valueMaxLength: 'The value does not have required maximum length',
        hideTitle: 'Hide title',
        titlePosition: 'Title position',
        inputType: 'Input type',
        viewTime: 'View Time',
        monthYear: 'Month and year',
        isoFormat: 'ISO format',
        htmlCode: 'Html Code',
        shownAttribute: 'Displayed attribute (key, name, subject, ...)',
        frame: 'Frame',
        background: 'Background',
        columnWidth: 'Column width',
        offset: 'Offset',
        addColumn: 'Add next column',
        tabName: 'Tab name',
        deleteTab: 'Delete tab',
        addTab: 'Add next tab',
        optionalInputClass: 'Opt. CSS class for input',
        optionalDescriptionClass: 'Opt. CSS class for description',
        moreValues: 'More values',
        readOnly: 'Read only',
        persistent: 'Persistent',
        disabled: 'Disabled',
        addButtons: 'Add buttons',
        contextButton: 'Context button',
        localeFormatting: 'Locale formatting',
        localeFormattingEnabled: 'Enabled',
        localeFormattingDisabled: 'Disabled',
        hidden: 'Hidden',
        listingSettings: 'Listing Settings',
        defaultRowsCount: 'Default number of rows',
        tableProfile: 'Table Profile',
        viewExport: 'Show export',
        viewFilter: 'Show filter',
        showColumnManager: 'Show column manager',
        showProfileSelection: 'Show profile selection',
        showResetButton: 'Show reset button',
        defaultValue: 'Default value',
        selectionItems: 'Selection items',
        const: 'Const',
        addItem: 'Add another item',
        ownerId: 'Owner ID',
        ownerType: 'Owner type',
        ticketId: 'Ticket ID',
        selectedUsers: 'Selected users',
        remove: 'Remove',
        selectedGroups: 'Selected groups',
        validation: 'Validation',
        errorMessageRequired: 'Custom error message (required field)',
        requiredValidating: 'Expression for validation on / off',
        errorMessage: 'Custom error message regex',
        patternValidating: 'Expression for pattern validation on / off',
        customValidation: 'Expresssion for custom validation',
        customValidations: 'Custom validations',
        customValidationValidating:
          'Expresssion for disabling/enabling custom validation',
        customValidationMessage: 'Custom errror message (custom validation)',
        minValue: 'Minimum value (incl.)',
        maxValue: 'Maximum value (incl.)',
        fractionDigits: 'Fraction digits',
        minLength: 'Min. length',
        minLengthValidating:
          'Expression for disabling/enabling minimum value validation',
        minLengthErrorMessage:
          'Min. length must be less than or equal to the maximum length.',
        minLengthError:
          'Custom min. length must be less than or equal to the maximum length.',
        maxLength: 'Max. length',
        maxLengthValidating:
          'Expression for disabling/enabling maximum value validation',
        maxLengthErrorMessage:
          'Max. length must be greater than or equal to min. length.',
        maxLengthError:
          'Custom max. length must be greater than or equal to min. length.',
        svg: 'SVG',
        bar: 'Button bar',
        buttonMenu: 'Button menu',
        diagramCreation: 'Diagram creation',
        drawIo: 'Draw IO',
        heightPx: 'Height (px)',
        widthPx: 'Width (px)',
        popupHeight: 'Popup window height (px)',
        popupWidth: 'Popup window width (px)',
        src: 'Source URL',
        listingType: 'Listing type',
        topEdge: 'Top edge',
        rightEdge: 'Right edge',
        bottomEdge: 'Bottom edge',
        leftEdge: 'Left edge',
        incorrectFormat:
          'Wrong format, enter valid css attribute (eg 10px, .5em, 5%)',
        fieldEdit: 'Edit field',
        variableName: 'Variable name',
        customLinkTitle: 'Custom link name',
        buttonType: 'Button type',
        customCssClass: 'Optional CSS class style',
        customCssClassHelpMessage: 'Custom CSS class to add to this component.',
        customIconCssClass: 'Optional CSS class icons',
        customIconCssClassHelpMessage:
          "This is the full icon class string to show the icon. Example: 'p-icon-check'",
        linkTarget: 'Link target',
        queryParams: 'Query params',
        linkUrl: 'Link url',
        postUrl: 'Post url',
        getActionDownloadFile: 'Get request for download file',
        getUrl: 'Get url',
        postAction: 'Post request',
        postActionDownloadFile: 'Post request for download file',
        getAction: 'Get request',
        ngrxAction: 'Ngrx action',
        ngrxActionAfterPost: 'Ngrx action with response data',
        closeDialog: 'Close dialog after submit',
        formTitle: 'Form title',
        formContext: 'Form context',
        messageType: 'Message type',
        messageText: 'Message text',
        successMessage: 'Message after successful execution',
        materialMovements: 'Material movements',
        multimediaDsl: 'Mutimeda DSL',
        internetDsl: 'Internet DSL',
        access: 'Access',
        activationDetail: 'Activation detail',
        kzList: 'KZ list',
        formSettings: 'Form settings',
        formWidth: 'Form width (px)',
        formHeight: 'Form height (px)',
        advancedComponents: 'Advanced components',
        tsmControl: 'Tsm Control',
        tsmControlTooltip:
          'Tsm Control - used for positioning static form-field components.',
        tsmCharacteristic: 'Tsm Characteristic',
        tsmCharacteristicTooltip:
          'Tsm Characteristic - used to insert a characteristic.',
        chart: 'Layout - Chart',
        array: 'Array',
        accordionArray: 'Accordion array',
        palette: 'Palette',
        visibleDropAreas: 'Visible drop areas',
        tooltip: {
          listingComponents: {
            userRegisterListing: 'Displays a list of user values.',
            basicInfo:
              'The component allows you to define additional attributes, your own form layout, allowed types of comments, etc.' +
              'Configuration profiles can be shared between multiple types for simplicity',
            listingProfileLov:
              'The component allows selection of the list profile type, it is possible to filter using input for a more convenient selection of the desired value.',
            listingColumns:
              'The component allows selection of the list column type, it is possible to filter using input for a more convenient selection of the desired value.',
            configFormType:
              'The component allows the selection of the type of configuration form, it is possible to filter using the input for a more convenient selection of the desired value.',
            userRegister:
              'The component allows selection from an enum, it is possible to filter using an input for a more convenient selection of the desired value.' +
              'The enum can be selected in the settings.',
            innerForm:
              'A component that allows you to insert another form that becomes part of the content of this form.',
          },
          basicComponents: {
            monacoDiffEditor:
              'Monaco Diff Editor is an equivalent of Monaco Editor, which offers the ability to compare the content of two editors displayed side by side.',
            favoriteComponents: `<p>Favorite widgets provide quick access to frequently used components, including nested elements. This makes your work easier and speeds up form design.</p><h5 class="m-0 mt-3 mb-2">How to add an item to Favorites?</h5><ul><li><strong>By dragging</strong> – simply drag the component from the designer workspace into the Favorites section.</li><li><strong>Using the action icon</strong> – click the icon next to the component name in the component palette.</li></ul><h5 class="m-0 mt-3 mb-2">How to manage Favorites items?</h5><p>You can rearrange saved widgets at any time to suit your needs. Simply drag them to the desired position in the Favorites list.</p><ul><li><strong>Renaming</strong> – click the pencil icon next to the name to rename any item.</li><li><strong>Deletion</strong> – click the cross icon to remove an item from Favorites.</li></ul>`,
            basicComponents:
              'Basic components are a collection of commonly used components used to allow gathering and displaying of data. ' +
              ' The user directly interacts with the components and the resulting data is saved to the database. The properties of the ' +
              'components can be set using the editor widget, such as the maximum length of input in a text field. ' +
              'They include components such as a numeric field, checkbox or a button.\n',
            textField:
              "The basic component for general text input. Optionally you can configure validations, masks and visual behaviour. The validation ensures that the data entered meets certain criteria, such as being a certain length or having a specific format. The mask is used to format the text as it's being entered, such as adding dashes to a phone number or hyphens to a credit card number. This helps to improve the user experience by providing clear expectations for the type of data that should be entered and ensuring that the data is entered in a consistent format.",
            variable:
              'Variable is a component that allows you to insert a variable into the form. The variable is a value that is stored in the $value object and can be used in the form.',
            numberField:
              'The numeric field is an input field in a form that is used to collect numerical data from the user. The field is typically formatted to only accept numbers and can be further customized to restrict the type of numbers that are accepted. For example, the field could be set up to only accept positive numbers, whole numbers, or numbers within a certain range. Validation can also be applied to the numeric field to ensure that the entered data meets specific criteria, such as being within a certain range or having a specific number of decimal places. The use of a numeric field helps to ensure that the data collected is accurate and consistent, and also makes it easier to process the data in downstream applications.',
            inputIconPicker:
              'The icon picker is an input field on a form that is used to select an icon displayed next to the form input field. The icon will be placed on the left or right side of the form field.',
            textArea:
              'The text area is a multi-line text input field in a form that is used to collect large amounts of text data from the user. Unlike a regular text field, a text area can accommodate multiple lines of text, making it well-suited for collecting longer pieces of information such as descriptions, comments, or feedback. The use of a text area helps to make it easy for users to enter and read large amounts of text data, while also providing a way to ensure that the data collected is accurate and consistent. If you want the user to enter Rich text (such as title, colors, font size, font weight etc., use Rich Text',
            richTextEditor:
              'The rich text field, also known as a WYSIWYG (What You See Is What You Get) editor, is a type of text input field in a form that allows users to enter and format text with various styles, such as bold, italic, and underline. This type of text field is well-suited for collecting information that requires visual formatting, such as descriptions or comments. Rich text fields can also include validation to ensure that the entered data meets specific criteria, such as having a minimum or maximum length. The use of a rich text field helps to improve the user experience by allowing users to create visually appealing and well-formatted text, while also providing a way to ensure that the data collected is accurate and consistent.',
            checkbox:
              'The checkbox is a type of input field that allows users to select only checked or unchecked values. Checkboxes is represented as small square that can be checked or unchecked by clicking on them. Checkbox is associated with a label that describes the option it represents. The use of checkboxes helps to simplify the process of collecting information by presenting the user with a clear list of options to choose from, while also allowing for flexible and multi-faceted data collection.',
            triStateCheckbox:
              "A tri-state checkbox is a GUI element with three possible states: checked, unchecked, and indeterminate. The indeterminate state is used when the checkbox's status depends on its child checkboxes or specific criteria. It's commonly employed in hierarchical data or groups of related checkboxes. The behavior of a tri-state checkbox can be customized by developers to suit their application's needs.",
            switch:
              'The switch is a type of input field in a form that allows users to toggle between two states, such as on/off, yes/no, or true/false. Switch is represented as a small rectangular toggle that can be moved left or right to change its state. It is often used as a more compact alternative to checkboxes for binary decisions, and can also be used in place of radio buttons for single-choice selection. The use of switches helps to simplify the user interface by providing a clear and intuitive way to make binary selections, while also allowing for more compact and aesthetically pleasing forms.',
            dateTime:
              'The date time field is an input field in a form that is used to collect date and time information from the user. Use configuration to select if the user should input Date and / Or Time parts. The field presents the user with a calendar-style interface, making it easy to select a specific date and time. Date fields may include validation to ensure that the entered data meets specific criteria, such as being within a certain range or being a valid date format.',
            rating:
              'The rating field is an input field in a form that allows users to rate or score something along a numerical scale. The field represented by a row of stars, each of which can be clicked or selected to indicate a specific rating. Rating fields can be used to collect customer feedback, product reviews, or other forms of evaluation. They can also be used to collect information about personal preferences, such as movie ratings or book reviews. The use of a rating field helps to simplify the process of collecting numerical data by providing a clear and intuitive way to make selections, while also allowing for flexible and multi-faceted data collection.',
            link: 'The link field is an input field in a form that allows users to enter a hyperlink, such as a URL or email address. The field is represented by a text box, into which the user can type or paste a link. The use of a link field is particularly useful for collecting information about online resources, such as websites, online profiles, or articles.',
            selectField:
              'The select field, also known as a drop-down menu, is an input field in a form that allows users to select an option from a list of pre-defined options. The field is typically represented by a drop-down list, from which the user can select a single option. The use of a select field helps to simplify the process of collecting data, by providing a clear and concise way for users to make selections. The field can also help to ensure the accuracy and consistency of the data collected, by limiting the available options to a predefined set of values. Select fields can be used to collect a wide range of information, such as geographical locations, job titles, product categories, or any other information that can be represented by a set of predefined options. If you need selecting from TSM based data, use an appropriate component such as "Register value", "Customer segment" or "Account.',
            autocompleteField:
              'The autocomplete field, also known as the "autocomplete" feature, is an interactive input element in a form that provides users with instant suggestions to complete their entries based on previous inputs or a predefined set of options. As soon as the user starts typing in the field, the system dynamically generates and displays a list of relevant suggestions, from which the user can choose, allowing them to quickly and efficiently complete their entry without the need to type out the entire text.',
            autocomplete:
              'An HTML autocomplete input is a form element that predicts and displays a list of suggestions as the user types, facilitating quicker and more accurate entries.\n',
            userRegister:
              'Select a value from a register configured in tSM module Config & Form. Unlike "Select Field", the available values are configured in a reusable custom Register (sometimes called also a lookup table). The field is represented by a drop-down list of values, from which the user can select the desired value. Values can be searched and selected value detail can be viewed or edited in a new window.',
            codeEditor:
              'The Code editor field is an input field in a form that is used to collect and display code, typically for the purpose of application log display, enhanced configuration or 3rd party system communication settings. The field is represented by an advanced code editor, witch provides additional functionality, such as syntax highlighting, code completion, and folding.',
            password:
              'The password field is an input field in a form that is used to collect sensitive information, such as a password or passphrase. The field is represented by a text box, but the characters entered into the field are obscured and replaced with asterisks or other symbols to protect the confidentiality of the information. Password field also includes additional security features, such as password strength meter.',
            maskField:
              'The mask field is an input field that uses a predefined format or pattern to guide users in entering data. The format can include symbols or placeholders to indicate the required format, such as a date, phone number, or credit card number. The field automatically applies the formatting as users enter the data, making it easier to enter accurate and consistent data. This helps to reduce errors and improve the quality of the data collected in the form.',
            expressionEditor:
              'The expression editor is used to input a SPEL or JEXL expression and add it to the form.',
            object:
              'The object is a data structure that contains a set of named properties, each of which has a value. The object field type is typically used in forms to collect data that is organized into a set of related properties. The field can be represented by a collection of input fields, such as text fields, checkboxes, or drop-down menus, each of which is used to collect a specific property of the object. The use of an object field type helps to simplify the process of data entry, by providing a clear and organized way to collect related data elements. The field can also be used to enforce data constraints or to validate the data entered into the form, by using built-in validation rules or custom validation logic. tSM Forms Designer creates both - visual UI for the user and underlying data structure. You may need to use Object type to create complex JSON Schema to communicate with 3rd party systems. See ' +
              '<a href="https://json-schema.org/understanding-json-schema/reference/object.html#object" target="_blank" rel="noreferrer">https://json-schema.org/understanding-json-schema/reference/object.html#object</a> for more details.',
            array:
              'The array field type is a type of input field used to collect and store data in the form of an array. An array is a data structure that contains a ordered collection of elements, where each element can be of any data type. The field can be represented by a collection of input fields, such as text fields, checkboxes, or drop-down menus, each of which is used to collect a single element in the array. Array element can be also a complex Object, designed using Object field type. You may need to use Object type to create complex JSON Schema to communicate with 3rd party systems. See ' +
              '<a href="https://json-schema.org/understanding-json-schema/reference/array.html" target="_blank" rel="noreferrer">https://json-schema.org/understanding-json-schema/reference/array.html</a> for more details.',
            accordionArray:
              'The accordion array field type is a type of input field used to collect and store data in the form of an array. An array is a data structure that contains a ordered collection of elements, where each element can be of any data type. The field can be represented by a collection of input fields, such as text fields, checkboxes, or drop-down menus, each of which is used to collect a single element in the array. Array element can be also a complex Object, designed using Object field type. You may need to use Object type to create complex JSON Schema to communicate with 3rd party systems. Every array item is wrapped inside accordion tab. See ' +
              '<a href="https://json-schema.org/understanding-json-schema/reference/array.html" target="_blank" rel="noreferrer">https://json-schema.org/understanding-json-schema/reference/array.html</a> for more details.',
            monacoEditor:
              'The Monaco Editor is a powerful code editor offering advanced editing features, including syntax highlighting, code completion, and code folding, to help users write code more efficiently. The editor also provides support for multiple programming languages.',
            button:
              'The button element is an interactive UI component that is used to trigger an action, such as submitting a form or initiating a specific task. In tSM there are several button types with extensive configuration capability. They can also be styled and labeled.',
            submitButton:
              "The submit button is an interactive user interface component used to trigger a form submission event. The button contains the form value that can be accessed by other components. After pressing the button, the specified value is stored in the button's state, which can be accessed through an expression ($value.requestButton).",
            submitRequestButton:
              "The submit button is an interactive user interface component used to trigger a form submission event. The button contains the form value that can be accessed by other components. The button always sends a request (Action - GET/POST + URL). The response returned by the request is stored in the button's state, which can be accessed through an expression (e.g., $value.requestButton).",
            content:
              'The content component is used to embed text created with the Editor component(see Editor description)',
            clipboardButton:
              'Button for copying the selected value to the clipboard.',
          },
          advancedComponents: {
            advancedComponents:
              'Advanced components contain components that provide more functionality than basic components.',
            displayText: 'Any text to display',
          },
          layoutComponents: {
            anchor:
              'The widget inserts an HTML <div> with a specified ID into the DOM and automatically scrolls to it when the page loads. This is useful for directing users to a specific section of a webpage upon loading.',
            layoutComponents: `Layout components are used to define the layout and structure of the form. Unlike Data components,
              no data are stored to the application. However, even layout components can access form data and context using
              scripting (for example to hide a section based on a field value).
              These components play a crucial role in determining how the form is presented to the user,
              and how the different elements of the application are organized on the screen. Some common types of layout components include:
              <ul>
              <li>Containers: These are components that define a section of the user interface and
              are used to group other components together. Examples include 12-column layout,
              In-Place and panels. Special type is an Inplace layout component for in place editing of the form.</li>
              <li>Cards: These are components that present information in a compact, organized manner,
              and are commonly used to display information such as product details or profiles.</li>
              <li>Navigational elements: These are components that help users navigate through the screen, such as accordions and tabs.</li>
              <li>Buttons and links: Used to execute an action or navigate to a different screen.</li>
              <li>Content: Used for adding additional content represented to the end user, such as help, static text information or a complex HTML UI.</li>
              </ul>`,
            splitter:
              'The splitter element is used to visually separate and organize form fields into distinct sections or columns. It is represented by a horizontal or vertical line that can be resized by dragging its handle to adjust the amount of space allocated to each section.  It is commonly used in forms with multiple columns or sections to provide users with a clear visual separation between different parts of the form, improving its readability and usability. It can also be customized with different styles and colors using CSS classes to match the overall design of the form.',
            clone:
              'The special component to allow duplication of a form section to a different place. Use "Clone key" attribute of an object to mark the object with custom key (for example "productDetail") and then use the key in this component configuration. This is intended to position parts of the form in different sections based on the form usage. Typically this is the case of Detail form within listings or on a seperate page.',
            card: 'The card component presents information in a compact, organized manner, and is commonly used to display information such as product detail or profiles.',
            tsmControl:
              'The Special component to position basic tSM attributes which are not configurable on the form. Usually you do not need to use this component directly, but first select the tSM Component to which this form belongs to (e.g. "Customer detail") and then select available controls from the custom menu (e.g. "Name", "Description", "Segment" etc.)',
            backButton:
              'The back button element is used for returning to the previous page upon clicking.',
            inplace:
              'The form inplace component is a UI element that allows for in-place editing of data within a form. At first the data is in read-only mode intended for viewing, but edit mode can be entered by clicking on the edit icon. Afterwards changes can be made to the data and modifications saved or discarded by buttons.',
            panel:
              "The panel component is a container component that can be used to group and organize other components within a section that has it's own header. It can be manually or programatically collapsed and it's properties can be changed such as the header.",
            layout:
              "The 12-column layout is a popular layout design pattern that is used to divide a user interface into 12 equal-width columns. The 12-column layout is a flexible design pattern that can be used to create a wide range of layouts, from simple one-column designs to more complex multi-column layouts. It provides a simple and effective way to divide a user interface into manageable sections, and helps to ensure that the layout is consistent and scalable across different screen sizes and devices. The number 12 is the most easily divisible among reasonably small numbers; it's possible to have 12, 6, 4, 3, 2 or 1 evenly spaced columns. This gives designers tremendous flexibility over a layout.",
            externalContent:
              'External content(iframe) is to embed external content into the current page. This makes it possible to view content from another source without leaving the current page. For example, an iframe can be used to display a map or a video from another website. The content can be interacted with within the iframe just as if it was being viewed on its own page.',
            accordion:
              'The accordion is a UI component used to display and organize content in a collapsible manner, allowing users to expand or collapse sections of content as needed. It consists of multiple sections or panels, with a title for each section and its associated content displayed in the body of the panel. When a user clicks on the title of a panel, the content of that panel is expanded, upon clicking again, it is collapsed.',
            tabs: 'The tabs component allows for organizing content into separate tabs or pages. Each tab typically has a label or title, and when a user clicks on a tab, the content associated with that tab is displayed. The tab component is useful for breaking up large amounts of content into smaller, more manageable chunks, and it provides a way for users to easily navigate between different sections of the content.',
            steps:
              'The steps component functions as a versatile wizard that guides users through a multi-step process. It organizes the steps with clear labels, allowing users to easily navigate through the process. With its intuitive interface, users can track their progress, move forward or backward, and complete complex tasks in a manageable manner. The component adjusts dynamically to accommodate varying numbers of steps, ensuring a seamless user experience.',
            charts:
              'The Charts component provides a set of customizable charting options that can be used to display data in a visually appealing way. It supports a variety of chart types, such as line, bar, pie, doughnut, radar, polar area, and scatter charts.',
            svg: 'A SVG component is used for displaying scalable vector graphics. It allows for the creation of vector-based graphics that can be scaled up or down without losing quality or clarity. The SVG component is particularly useful for creating interactive and dynamic visualizations, such as charts and graphs, which can be customized and animated based on user interaction or data changes.',
            buttonBar:
              'The button bar component creates a section which you can open by clicking on it. Inside the section you can put additional content such as a Text item, Password field or a Checkbox. These items are only visible when the section is opened. You can add a custom text in the button bar label - for example "More", or "Click to view". There are other customizable options such as a tooltip or a CSS class.',
            section:
              "The section component is similiar to the panel component(see it's description) with the main difference that it does not have a header.",
            fieldset:
              'The fieldset component is used to group related form controls or content into a single visually identifiable unit. It can be used to provide context and improve the overall organization of a form.',
            html: 'The Html component is used to embed plain html in a section which is then rendered.',
            content:
              'The content component is used to embed text created with the Editor component(see Editor description).',
            help: 'The help component is used to embed text inside a form serving as a hint or documentation to the user. It can be programatically shown or hidden.',
            textHint:
              'The Text hint component is used to embed text inside a form using an Rich text editor serving as a hint or documentation to the user. An icon can be placed infront of the text symbolizing the type of the hint - ex. informational or warning.',
            // nezařazené
            containers:
              'These are components that define a section of the user interface and are used to group other components together. Examples include 12-column layout, In-Place and panels. Special type is an Inplace layout component for in place editing.',
            navigationalElements:
              'These are components that help users navigate through the screen, such as accordions and tabs.',
          },
          technicalPreview: {
            technicalPreview:
              'The features in this section are experimental and may be changed or removed completely in future releases. tSM team will make a best effort to fix any issues, but experimental features are not supported to the same level as generally available (GA) features.',
          },
          scheme: {
            missingInLayout:
              'Error: this element was not found in the source code [layout/item]',
          },
        },
        unsavedFormHeading: 'Unsaved changes',
        unsavedFormMessage:
          'You are about leave this form without saving. All changes will be lost. Do you really want to leave without saving?',
      },
      register: {
        attributeSpec:
          'Specifying additional attributes to the register value. This form will be displayed for value edit. ',
        registerSorting: 'Logical grouping of code lists',
        values: 'Values',
        portrayalInMenu: 'Menu view',
        addRegister: 'Add this register as a new menu item.',
        locationInMenu: 'Location in the menu ',
        menuKey:
          'The menu key (whole location) where the item should be placed (eg tickets + registers)',
        menuItemTitle: 'Menu item name',
        itemIcon: "Item icon (see manual) - eg. 'tsm-icon-form' ",
        newRegister: 'New register',
        editRecord: 'Edit entry',
        addValue: 'Add value',
        registerValues: 'Register values',
        registerList: 'Register list',
        deleteConfirmation:
          'Are you sure you want to delete all selected values?',
        order: 'Order',
      },
      buttonTypes: {
        button_download_file: 'Button for download file',
        button_dialog: 'Button with dialog',
        message_button: 'Show message',
        link: 'Link',
        internal_link: 'Internal link in application',
        component_selector: 'Button with component',
      },
      linkTargets: {
        _blank: 'New window',
        _top: 'Actual window',
        popup: 'Popup window',
      },
      buttonMessageTypes: {
        success: 'Success',
        error: 'Error',
      },
      tableColumn: {
        filters: 'Filters',
        editColumnConfiguration: 'Edit column configuration',
        createColumnConfiguration: 'Create column configuration',
        listingType: 'Listing type',
        main: 'Main',
        field: 'Field',
        visible: 'Visible',
        visibleTable: 'Visible in table',
        visibleCard: 'Visible in card',
        displayAllowed: 'View the column in the column manager',
        position: 'Position',
        favoritesOrder: 'Order in the filters',
        nested: 'Nested',
        alwaysInQuery: 'Always add this column to the query',
        applyDefaultValueIfFilterMissing:
          'Apply default value if filter missing',
        defaultValue: 'Default value',
        dataType: 'Data type',
        scriptField: 'Elasticsearch script field',
        sortType: 'Sort type',
        design: 'Design',
        editWidget: 'Edit widget',
        inputWidget: 'Input widget',
        inputWidgetConfig: 'Input widget config',
        inputWidgetPrivilege: 'Privilege to edit cell',
        inputWidgetSpelCode: 'Spel script',
        inputWidgetField: 'Editing field',
        displayedField: 'Displayed field',
        cssClass: 'CSS class',
        width: 'Width in pixels',
        widget: 'Widget',
        context: 'Context',
        fulltext: 'Fulltext',
        customTqlExpression:
          'Attribute for custom filter build definition ["$field $operator treeParent(\'$value\')]"',
        forbid: 'Disabled',
        converter: 'Converter',
        convertOnBackend: 'Converter value on server',
        convertOnBackendTooltip:
          'If true, the value is converted on the server, otherwise the GUI does it',
        listingCode: 'Listing code',
        columnConfiguration: 'Column configuration',
        header: 'Header',
        tooltip: 'Tooltip',
        repeatedExportSetting: 'Repeated export setup',
        metrics: 'Metrics aggregation',
        metricsTooltip:
          'Specify aggregation method according to Elasticsearch metrics aggregation - e.g. sum, count, max, avg or including field name: sum(field), ...',
        buckets: 'Bucket aggregation',
        bucketsTooltip:
          'Specify aggregation method according to Elasticsearch bucket aggregation. Default is term (if not specified and Metrics aggregation is not specified).',
        addUrlParam: 'Additional filter parameters',
        dynamicComponent: 'Dynamic component',
        localization: 'Localization',
      },
    },
  },
};
