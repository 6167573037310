export const en = {
  calendarPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmCalendarEventTypeFilter: 'tSM Calendar Event Type Filter',
      tsmCalendarEventTypeFilterDescription:
        'Provides an interface for filtering calendar event types with dynamic data. ' +
        'Retrieves event types from the store and supports multi-selection.',
      tsmCalendarTypeFilter: 'tSM Calendar Type Filter',
      tsmCalendarTypeFilterDescription:
        'Provides an interface for filtering calendar types with a table view. ' +
        'Retrieves available types dynamically and supports multi-selection.',
      tsmFullCalendarEventTypeFilter: 'tSM Full Calendar Event Type Filter',
      tsmFullCalendarEventTypeFilterDescription:
        'Provides an interface for filtering full calendar event types dynamically. ' +
        'Supports filtering by event type codes or IDs and allows multi-selection.',
      tsmFullCalendarFilter: 'tSM Full Calendar Filter',
      tsmFullCalendarFilterDescription:
        'Provides a filtering interface for full calendar views with configurable options. ' +
        'Supports dynamic event type filtering and manages table state efficiently.',
      tsmFullCalendarUserGroupFilter: 'tSM Full Calendar User Group Filter',
      tsmFullCalendarUserGroupFilterDescription:
        'Provides a filtering interface for user group calendars with subtype selection. ' +
        'Retrieves available subtypes dynamically and updates the calendar configuration.',
    },
    pipes: {
      calendarEventTypeByCode: 'Calendar Event Type By Code',
      calendarEventTypeByCodeDescription:
        'Fetches calendar event type by its code from the store, retrieves specific fields like the name ' +
        'or the entire object, and ensures the data is fetched if not already available.',
      calendarEventTypeById: 'Calendar Event Type By Id',
      calendarEventTypeByIdDescription:
        'Fetches calendar event type by its ID from the store, retrieves specific fields like the name ' +
        'or the entire object, and ensures the data is fetched if not already available.',
      calendarTemplateByCode: 'Calendar Template By Code',
      calendarTemplateByCodeDescription:
        'Retrieves calendar template data by its code from the store, extracts specific fields like ' +
        'the name or the complete object, and ensures the data is loaded if not already available.',
      calendarTemplateById: 'Calendar Template By Id',
      calendarTemplateByIdDescription:
        'Retrieves calendar template data by its ID from the store, extracts specific fields like the name ' +
        'or the entire object, and ensures the data is loaded if not already available.',
      hasCollidingPew: 'Has Colliding Pew',
      hasCollidingPewDescription:
        'Checks for colliding PEW data by its ID from the store, retrieves the "hasCollidingPew" property ' +
        'if available, and ensures the data is fetched if not already present in the store.',
    },
  },
};
