export function createClickableBackdropElement(
  clickCallback: (ev: MouseEvent) => void,
  options?: {
    height?: string;
    zIndex?: number;
    stopPropagation?: boolean;
  },
) {
  const backdrop = document.createElement('div');
  const finalOptions = {
    zIndex: options?.zIndex ?? 997,
    stopPropagation:
      options?.stopPropagation !== undefined ? options.stopPropagation : true,
    height: options?.height ?? '100%',
  };
  backdrop.style.top = 0 + 'px';
  backdrop.style.left = 0 + 'px';
  backdrop.style.zIndex = finalOptions.zIndex.toString();
  backdrop.style.position = 'fixed';
  // backdrop.style.backgroundColor = 'rgba(0,0,0,0.5)';
  backdrop.style.width = '100%';
  backdrop.style.height = finalOptions.height;
  backdrop.addEventListener('click', (ev) => {
    if (finalOptions.stopPropagation) {
      ev.stopPropagation();
    }
    clickCallback(ev);
  });
  return backdrop;
}
