import {Component, HostBinding, Input} from '@angular/core';
import {translation} from '@tsm/shared-i18n';

@Component({
  selector: 'tsm-button',
  template: `
    <button
      pButton
      pRipple
      type="button"
      [class.spinner]="progress"
      [icon]="icon"
      [label]="label"
      [disabled]="disabled"
    ></button>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `,
  ],
})
export class ButtonComponent {
  translationShared = translation;

  /**
   * Label pro změnu názvu tlačítka
   */
  @Input() label: string;

  @Input() icon: string;

  @Input() progress = false;

  @Input() disabled = false;

  @HostBinding('style.pointer-events')
  get disableClicking() {
    return this.disabled || this.progress ? 'none' : null;
  }

  constructor() {}
}
