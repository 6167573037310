import {Injectable} from '@angular/core';
import {DtlLayoutControlsConfiguration} from './models';
import {sortBy} from 'lodash-es';

interface PipeOrFilter {
  selector: string;
  name?: string;
  description?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FrameworkPluginService {
  private _filters: PipeOrFilter[] = [];
  private _pipes: PipeOrFilter[] = [];
  private _layoutControlsConfigurations: DtlLayoutControlsConfiguration[] = [];

  private _datasourcesLibrary = [];
  private _entityTypes = [];

  // pouziva se jenom ve fluent forms
  private _widgetsLibrary = [];

  addWidgets(widget) {
    this._widgetsLibrary.push(...widget);
  }

  getAllWidgets() {
    // widgety řadíme:
    // na základě skupin :: group === 1 - nejvyšší prio.; group === undefined - nejnižší prio.
    // na základě pořadí :: order === 1 - nejvyšší prio.; order === undefined - nejnižší prio.
    // řazení na zákledě property order se děje samostatně v každé skupině určené property group
    return this.sortWidgetsByGroupAndOrder(this._widgetsLibrary);
  }

  findWidgetEditorSelectorByTypeAndWidgetType(type, widgetType) {
    if (widgetType === 'select') {
      // muze mit type string i number
      return this._widgetsLibrary.find(
        (x) => x.value.widget.type === widgetType,
      ).editorSelector;
    }
    return this._widgetsLibrary.find(
      (x) => x.value.type === type && x.value.widget.type === widgetType,
    ).editorSelector;
  }

  findWidgetByTypeAndWidgetType(type, widgetType) {
    if (widgetType === 'tsm-characteristic' && type === 'object') {
      return this._widgetsLibrary.find(
        (x) => x.value.type === 'layout' && x.value.widget.type === widgetType,
      );
    } else if (widgetType === 'select') {
      // muze mit type string i number
      return this._widgetsLibrary.find(
        (x) => x.value.widget.type === widgetType,
      );
    }
    return this._widgetsLibrary.find(
      (x) =>
        this.normalizeTypeText(x.value.type) === this.normalizeTypeText(type) &&
        x.value.widget.type === widgetType,
    );
  }

  // nekde je historicky string
  private normalizeTypeText(t: string) {
    return t === 'string' ? 'text' : t;
  }

  addDatasources(datasources) {
    this._datasourcesLibrary.push(...datasources);
  }

  getAllDatasources() {
    return this._datasourcesLibrary;
  }

  addFilter(filter: PipeOrFilter) {
    this._filters.push(filter);
  }

  getAllFilters(): ReadonlyArray<PipeOrFilter> {
    return this._filters;
  }

  addPipe(pipe: PipeOrFilter) {
    this._pipes.push(pipe);
  }

  getAllPipes(): ReadonlyArray<PipeOrFilter> {
    return this._pipes;
  }

  addLayoutControlConfiguration(conf) {
    this._layoutControlsConfigurations.push(conf);
  }

  getAllLayoutControlsConfigurations() {
    return this
      ._layoutControlsConfigurations as ReadonlyArray<DtlLayoutControlsConfiguration>;
  }

  addEntityType(entityTypes: any[]) {
    entityTypes.forEach((x) => {
      if (!this._entityTypes.some((y) => y.entityType === x.entityType)) {
        this._entityTypes.push(x);
      }
    });
  }

  getAllEntityType() {
    return this._entityTypes;
  }

  private sortWidgetsByGroupAndOrder(widgets: any[]): any[] {
    return sortBy(widgets, [
      (widget) => widget.group ?? 0,
      (widget) => widget.order ?? 0,
    ]);
  }
}
