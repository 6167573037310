import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import {Terminator} from '@tsm/framework/terminator';
import {fromEvent} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {FrameworkTooltipModule, TooltipDirective} from '@tsm/framework/tooltip';
import {Overlay} from '@angular/cdk/overlay';

@Directive({
  selector: '[tsmAutoSizeTooltip]',
  providers: [Terminator],
})
export class AutoSizeTooltipDirective implements OnDestroy {
  private _text: string;

  @Input('tsmAutoSizeTooltip') set text(text: string) {
    this._text = text;
  }

  @Input() textAllTime: string;

  @Input() useTargetElement = false;

  @Input() targetElement?: HTMLElement;

  tsmTooltip: TooltipDirective;

  constructor(
    public el: ElementRef,
    public zone: NgZone,
    protected terminator: Terminator,
    public cdr: ChangeDetectorRef,
    public vcRef: ViewContainerRef,
    protected overlay: Overlay,
  ) {
    this.tsmTooltip = new TooltipDirective(
      el,
      overlay,
      vcRef,
      terminator,
      zone,
    );
    fromEvent(el.nativeElement, 'mouseenter')
      .pipe(
        filter((el: any) => el.fromElement != null),
        map((el) => {
          const finalElement =
            this.targetElement != null
              ? this.targetElement
              : !this.useTargetElement
                ? el.fromElement
                : el.target;
          return (
            finalElement.scrollWidth > finalElement.clientWidth ||
            finalElement.scrollHeight > finalElement.offsetHeight
          );
        }),
        takeUntil(terminator),
      )
      .subscribe((shouldDisable) => {
        if (shouldDisable) {
          // nevejde se to vykreslit
          this.tsmTooltip.tsmTooltip =
            this._text +
            (this.textAllTime ? ' (' + this.textAllTime + ')' : '');
          this.tsmTooltip.showTooltip(null);
        } else if (this.textAllTime) {
          // vejde se to vykreslit, tak pokud mam textAllTime, tak ho zobraz jinak ne
          this.tsmTooltip.tsmTooltip = this.textAllTime;
          this.tsmTooltip.showTooltip(null);
        }
      });

    fromEvent(el.nativeElement, 'mouseleave')
      .pipe(takeUntil(terminator))
      .subscribe(() => {
        this.tsmTooltip?.hideTooltipEvent({type: 'mouseleave'} as any);
      });
  }

  ngOnDestroy() {
    this.tsmTooltip.ngOnDestroy();
    this.tsmTooltip = undefined;
  }
}

@NgModule({
  imports: [FrameworkTooltipModule],
  declarations: [AutoSizeTooltipDirective],
  exports: [AutoSizeTooltipDirective],
})
export class AutoSizeTooltipDirectiveModule {}
