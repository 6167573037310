import {TableColumn} from '../models';

export function buildObjectFromPaths(
  pathsValues: {
    newValue: any;
    oldValue: any;
    field: string;
    column: TableColumn;
  }[],
  ignoreFields: string[] = [],
): any {
  const result = {};

  pathsValues.forEach(({field, newValue}) => {
    if (ignoreFields.includes(field)) {
      return;
    }

    const parts = field.split('.'); // Rozdělení cesty na části
    let current = result;

    // Projít všechny části cesty kromě poslední
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];

      // Pokud daná část cesty ještě neexistuje, vytvoříme ji jako prázdný objekt
      if (!current[part]) current[part] = {};

      // Posuneme se hlouběji do struktury objektu
      current = current[part];
    }

    // Přiřadíme hodnotu k poslední části cesty
    current[parts[parts.length - 1]] = newValue;
  });

  return result;
}
