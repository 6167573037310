export const cs = {
  sharedPlugins: {
    headerDescription: 'Hlavička dokumentace',
    pipes: {
      number: 'Number format',
      numberDescription: 'Transformuje na číslo',
      currency: 'Currency',
      currencyDescription: 'Displaying currency',
      formatPhone: 'Formát telefonního čísla',
      formatPhoneDescription: 'Formát telefonního čísla',
      booleanIcon: 'Boolean Icon',
      booleanIconDescription:
        'Generuje HTML ikonu na základě hodnoty boolean nebo pole. Aplikuje specifikované CSS třídy pro ' +
        'hodnoty true, false nebo null. Pipe podporuje vlastní konfigurace tříd a zajišťuje správné ' +
        'zobrazení ikon pro jednotlivé hodnoty nebo pole.',
      booleanTranslate: 'Boolean Translate',
      booleanTranslateDescription:
        'Převádí hodnoty boolean nebo pole na přeložené řetězce. Vrací "Ano" nebo "Ne" na základě hodnoty ' +
        'a aplikuje překlad pomocí TranslocoService. Pokud je parametr "nullable" true, jsou hodnoty null ' +
        'považovány za prázdnou hodnotu.',
      clipboard: 'Clipboard',
      clipboardDescription:
        'Generuje sanitizovaný HTML řetězec obsahující ikonu pro kopírování hodnot do schránky. Ikona je ' +
        'stylována a obsahuje událost "onclick", která spustí JavaScriptovou funkci pro kopírování zadané ' +
        'hodnoty do schránky.',
      colorPipe: 'Color Pipe',
      colorPipeDescription:
        'Převádí hodnotu barvy na HTML řetězec, který zobrazuje malý čtvercový div prvek stylovaný ' +
        'zadanou barvou pozadí. Pokud je hodnota barvy null nebo prázdná, vrátí prázdný řetězec.',
      constructCharsForNew: 'Construct Chars For New',
      constructCharsForNewDescription:
        'Transformuje objekt entity extrahováním vlastnosti "chars" a vložením do nového objektu. ' +
        'Výsledný objekt obsahuje původní vlastnost "chars" a také vnořené pole "chars". ' +
        'Pokud je entita nebo její vlastnost "chars" null, použije se výchozí prázdný objekt.',
      dataTagsArray: 'Data Tags Array',
      dataTagsArrayDescription:
        'Transformuje pole hodnot na řetězec HTML prvků. ' +
        'Každá hodnota v poli je obalena do prvku span s třídou "tsm-data-tag". ' +
        'Výsledné HTML prvky jsou spojeny s koncovými znaky řádků mezi nimi.',
      dateFormatPipe: 'Date Format Pipe',
      dateFormatPipeDescription:
        'Formátuje zadanou hodnotu data na řetězec podle specifikovaného typu formátu. ' +
        'Podporované formáty zahrnují: "dateTime" pro "dd.MM.yyyy HH:mm", "dateTimeS" pro "dd.MM.yyyy HH:mm:ss", ' +
        '"dateTimeMs" pro "dd.MM.yyyy HH:mm:ss.SSS", "date" pro "dd.MM.yyyy" a "time" pro "HH:mm". ' +
        'Pokud není uveden typ formátu nebo je hodnota null, původní hodnota se vrací beze změn.',
      datesDiff: 'Dates Diff',
      datesDiffDescription:
        'Vypočítá rozdíl mezi zadaným datem a aktuálním datem ve specifikované časové jednotce. ' +
        'Podporuje časové jednotky jako "roky", "měsíce", "dny", "hodiny", "minuty" a "sekundy". ' +
        'Dodatečné možnosti zahrnují: vracení pouze kladných hodnot, zaokrouhlení dolů (floor) nebo nahoru (ceil). ' +
        'Pokud je datum null nebo neplatná hodnota, vrací se prázdný řetězec.',
      decimalNumberFormat: 'Decimal Number Format',
      decimalNumberFormatDescription:
        'Formátuje číselnou hodnotu do řetězce podle místních zvyklostí. ' +
        'Pipe převádí vstupní číslo na formát řetězce, který zajišťuje správné ' +
        'oddělovače pro desetinná místa a tisíce pro lepší čitelnost. Pokud je vstup prázdný, ' +
        'vrací se prázdný řetězec.',
      description: 'Description',
      descriptionDescription:
        'Předává vstupní data beze změny, zachovává HTML formátování, což je na GUI přijatelné. ' +
        'Na backendu však konvertor odstraní HTML značky, aby se předešlo možným problémům.',
      domPurify: 'Dom Purify',
      domPurifyDescription:
        'Zajišťuje bezpečnost HTML řetězce jeho sanitací pomocí DOMPurify s vlastní konfigurací. ' +
        'Filtruje atributy jako "style", aby povolil pouze předem definovanou sadu CSS vlastností, jako jsou ' +
        'barva, velikost písma a zarovnání textu. Po sanitizaci je transformovaný kód HTML vyňat z ' +
        'bezpečnostních kontrol Angularu, což zajišťuje bezpečné vykreslení obsahu v prohlížeči.',
      emptyOrNotEmptyTranslate: 'Empty Or Not Empty Translate',
      emptyOrNotEmptyTranslateDescription:
        'Překládá pole nebo hodnotu na "prázdné" nebo "neprázdné" podle jejího obsahu s využitím lokalizace. ' +
        'Pokud je hodnota prázdné pole nebo neobsahuje žádné pravdivé prvky, překládá se na "prázdné". ' +
        'Pokud pole obsahuje alespoň jeden pravdivý prvek, překládá se na "neprázdné". Zpracovává nullable ' +
        'hodnoty tím, že vrací prázdný řetězec, pokud je nullable specifikováno a hodnota je null.',
      enumToValue: 'Enum To Value',
      enumToValueDescription:
        'Převádí číselnou hodnotu na přeložený textový popis. Pokud hodnota existuje v zadané mapě předpon, ' +
        'získá a přeloží odpovídající popisek. Jinak připojí číselnou hodnotu k předponě a přeloží výsledek. ' +
        'Pokud je vstup nulový nebo prázdný, vrátí prázdný řetězec.',
      externalLink: 'External Link',
      externalLinkDescription:
        'Převádí URL adresu na HTML prvek odkazu s atributem "rel" nastaveným na "noopener noreferrer". ' +
        'Pokud je vstupní hodnota platná, vrátí kliknutelný odkaz. Jinak vrátí prázdný řetězec.',
      filter: 'Filter',
      filterDescription:
        'Filtrování pole objektů na základě zadaného pole a hodnoty. ' +
        'Vrací nové pole obsahující pouze objekty, kde zadané pole odpovídá zadané hodnotě.',
      find: 'Find',
      findDescription:
        'Najde první objekt v poli, kde zadané pole odpovídá zadané hodnotě. ' +
        'Používá cestu k poli pro získání hodnoty v každém objektu a vrací odpovídající objekt, ' +
        'nebo undefined, pokud není nalezena shoda.',
      flattenExceptKeys: 'Flatten Except Keys',
      flattenExceptKeysDescription:
        'Zplošťuje vlastnosti objektu do pole, s výjimkou zadaných klíčů. ' +
        'Odstraní uvedené klíče z objektu a poté mapuje zbývající vlastnosti ' +
        'do pole jejich hodnot.',
      iconImg: 'Icon Img',
      iconImgDescription:
        'Generuje HTML reprezentaci pro ikonu nebo obrázek na základě zadané hodnoty a řádkových dat. ' +
        'Pokud je v řádkových datech nalezeno pole "icon", určí, zda vykreslit obrázek nebo ' +
        'třídu ikony. Obrázky s cestami začínajícími "assets" jsou předponou doplněny základním URL. ' +
        'Podporuje jak absolutní, tak relativní URL pro obrázky.',
      join: 'Join',
      joinDescription:
        'Spojí pole řetězců do jediného řetězce a k oddělení prvků použije zadaný oddělovač. Pokud ' +
        'je pole prázdné nebo nedefinované, vrátí prázdný řetězec.',
      map: 'Map',
      mapDescription:
        'Transformuje pole objektů na pole obsahující hodnoty zadaného atributu z každého objektu. ' +
        'Pokud je pole prázdné nebo nedefinované, vrátí prázdné pole.',
      moduleIcon: 'Module Icon',
      moduleIconDescription:
        'Převádí identifikátory modulů na odpovídající ikony s přeloženými tooltipy. ' +
        'Například "ticket" se transformuje na ikonu ticketingu a "ordering" na ikonu objednávání. ' +
        'Pokud identifikátor modulu neodpovídá žádné předdefinované ikoně, vrátí původní hodnotu. ' +
        'Pokud je vstupní hodnota null nebo nedefinovaná, vrátí prázdný řetězec.',
      numberToFixed: 'To Fixed',
      numberToFixedDescription:
        'Formátuje číslo na pevný počet desetinných míst s výchozí hodnotou 2. ' +
        'Pokud je vstup null nebo nedefinovaný, vrátí prázdný řetězec. Pokud je vstupní hodnota řetězec, ' +
        'převede ji na číslo před formátováním. Desetinná tečka je nahrazena čárkou pro lokalizaci.',
      objectPathGet: 'Object Path Get',
      objectPathGetDescription:
        'Získává hodnotu na zadané cestě v objektu pomocí knihovny ObjectPath. ' +
        'Cestu lze zadat jako řetězec nebo pole řetězců. Pokud cesta neexistuje, ' +
        'vrací undefined.',
      removeHtmlElements: 'Remove Html Elements',
      removeHtmlElementsDescription:
        'Odstraňuje HTML značky z daného řetězce. Pokud je zadána délka, text se zkrátí na ' +
        'zadanou délku a přidá se vlastní přípona (výchozí je "..."). Pokud délka není zadána, ' +
        'vrátí celý text bez HTML značek.',
      safeEval: 'Safe Eval',
      safeEvalDescription:
        'Vyhodnocuje JEXL výrazy vůči poskytnutým datům, což umožňuje dynamické výpočty na základě ' +
        'zadaného výrazu. Podporuje transformaci klíčů objektů s tečkami na dvojité podtržítko pro ' +
        'zajištění kompatibility. Vrací výsledek vyhodnocení nebo původní hodnotu v případě chyby. ' +
        'Zapíše chybu, pokud není zadán výraz nebo dojde k chybě při parsování.',
      safeHtml: 'Safe Html',
      safeHtmlDescription:
        'Sanitizuje HTML obsah, aby bylo bezpečné jej použít v šablonách Angularu, odstraní potenciálně ' +
        'nebezpečné prvky nebo atributy. Používá DomSanitizer z Angularu pro zpracování vstupu a vrací ' +
        'sanitizovanou verzi zadaného HTML řetězce.',
      safe: 'Safe',
      safeDescription:
        'Zpracovává vstupní řetězce na základě zadaného typu, aby bylo zajištěno bezpečné použití v ' +
        'aplikacích Angular. Sanitizuje nebo důvěřuje obsahu podle daného kontextu, například HTML, styl, ' +
        'skript, URL nebo URL zdroje. Umožňuje obcházet zabezpečení pro předem sanitizovaný obsah, pokud je ' +
        'explicitně označen jako důvěryhodný.',
      sanitizeNull: 'Sanitize Null',
      sanitizeNullDescription:
        'Nahrazuje hodnoty null nebo undefined prázdným řetězcem, čímž zajišťuje bezpečný a konzistentní ' +
        'výstup pro použití v aplikacích Angular. Hodnoty, které nejsou null nebo undefined, ponechá nezměněné.',
      shallowMerge: 'Shallow Merge',
      shallowMergeDescription:
        'Slučuje dva objekty do jednoho pomocí mělkého slučování, kde vlastnosti z druhého objektu přepisují ' +
        'ty v prvním objektu, pokud mají stejné klíče. Užitečné pro kombinování nebo aktualizaci datových ' +
        'struktur s minimální složitostí.',
      translatePipe: 'Translate Pipe',
      translatePipeDescription:
        'Zajišťuje překládání daného lokalizačního klíče v reálném čase sledováním změn jazyka. ' +
        'Automaticky aktualizuje překlad při změně jazyka, což zajišťuje dynamické a kontextově ' +
        'citlivé překlady.',
      trimTextPipe: 'Trim Text Pipe',
      trimTextPipeDescription:
        'Zkracuje zadaný text na stanovený limit znaků a přidává "..." jako indikaci, že text byl ' +
        'zkrácen. Pokud vstup není text, vrátí původní hodnotu beze změny.',
    },
  },
};
