import {createAction, props} from '@ngrx/store';
import {KafkaTopic} from '../models';

export const LoadKafkaTopic = createAction('[KafkaTopic] Load data');

export const LoadKafkaTopicSuccess = createAction(
  '[KafkaTopic] Load data Success',
  props<{topics: KafkaTopic[]}>(),
);

export const LoadKafkaTopicError = createAction(
  '[KafkaTopic] Load data ERROR',
  props<{error: any}>(),
);

export const SelectKafkaTopic = createAction(
  '[KafkaTopic] Select',
  props<{key: string}>(),
);
