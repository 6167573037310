import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';
import {ToastParamsDataModel, ToastSeverity} from '../models';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private static readonly DEFAULT_DURATION = 4000;
  public readonly MAIN_TOAST = 'mainToast';

  private _subject = new BehaviorSubject(true);
  onToast$: Observable<any>;

  constructor(private messageService: MessageService) {
    this.onToast$ = this._subject.asObservable();
  }

  public showToast(
    message: string,
    severity: ToastSeverity = ToastSeverity.SUCCESS,
    duration?: number,
    data?: ToastParamsDataModel,
    title?: string,
  ) {
    let sticky = false;
    if (!duration) {
      duration = ToastService.DEFAULT_DURATION;
    }
    if (severity === ToastSeverity.WARN || severity === ToastSeverity.ERROR) {
      sticky = true;
    }
    this.messageService.add({
      key: this.MAIN_TOAST,
      severity: severity,
      life: duration,
      summary: title,
      detail: message,
      sticky: sticky,
      closable: false,
      data: data,
    });
    this._subject.next(true);
  }

  public showError(
    data: ToastParamsDataModel,
    title?: string,
    duration?: number,
  ) {
    this.messageService.add({
      key: this.MAIN_TOAST,
      severity:
        data?.businessCode === 'constraintViolation'
          ? ToastSeverity.WARN
          : ToastSeverity.ERROR,
      life: duration ?? ToastService.DEFAULT_DURATION,
      detail: title,
      sticky: duration == null,
      closable: false,
      data: data,
    });
    this._subject.next(true);
  }

  public showWarning(message: string) {
    this.messageService.add({
      key: this.MAIN_TOAST,
      severity: ToastSeverity.WARN,
      life: ToastService.DEFAULT_DURATION,
      detail: message,
      sticky: false,
      closable: true,
    });
  }

  closeToast() {
    this.messageService.clear(this.MAIN_TOAST);
  }
}
