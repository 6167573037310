import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {translation as translationShared} from '@tsm/shared-i18n';

@Component({
  selector: 'tsm-preview-dropdown',
  template: `
    <tsm-dropdown
      *ngIf="!readonly"
      (onFocus)="onFocus(true)"
      (onHide)="onFocus(false)"
      [options]="[]"
      [(ngModel)]="val"
      [showClear]="showClear"
      [style]="{width: '100%'}"
      [autoDisplayFirst]="false"
      [filter]="true"
      [dataKey]="selectProperty === 'all' ? 'id' : null"
    >
    </tsm-dropdown>

    <span *ngIf="readonly && !val" class="readonly-text empty">
      {{ translationShared.shared.none | transloco }}
    </span>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PreviewDropdownComponent),
      multi: true,
    },
  ],
})
export class PreviewDropdownComponent extends AbstractControlValueAccessor {
  translationShared = translationShared;

  @Input() showClear = true;

  @Input() selectProperty: 'id' | 'code' | 'all' = 'all';

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
