import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/change-management/plugins-i18n';
import {
  controlsRelatedEntityPlaceLocalitiesDetailConfiguration,
  controlsRelatedEntityPlaceObjectsDetailConfiguration,
  controlsWorklogLocalityTopbarConfiguration,
} from './tsm-controls-configuration';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const changeManagementPlugin: Plugin = {
  menus: [
    {
      key: 'change-management',
      priority: 1200,
      label: 'menu.changeManagement.changeManagement',
      icon: 'tsm-icon-change-management',
      privilege: [
        'ChM.ChangeRequest.ViewList',
        'ChM.ChangeRequestTemplate.ViewList',
        'ChM.Registers.OperationImpact.ViewList',
        'ChM.Registers.Technology.ViewList',
        'ChM.Registers.ImpactOnCustomer.ViewList',
        'ChM.Registers.ReasonForWork.ViewList',
        'ChM.Registers.Category.ViewList',
        'ChM.Registers.Type.ViewList',
        'ChM.Registers.Status.ViewList',
        'ChM.Registers.Priority.ViewList',
        'ChM.Registers.Resolution.ViewList',
        'ChM.Registers.Channel.ViewList',
        'ChM.Registers.RelatedEntityType.ViewList',
        'ChM.Registers.RelatedPartyType.ViewList',
        'ChM.Registers.RelatedPartyRole.ViewList',
        'ChM.Registers.LinkType.ViewList',
        'ChM.Registers.ScheduleType.ViewList',
        'ChM.WorkLogLocality.ViewList',
      ],
      items: [
        {
          key: 'change-request-list',
          label: 'menu.changeManagement.changeRequest',
          icon: 'tsm-icon-change-request',
          listingType: 'tsm-change-request-list',
          routerLink: ['/change-management/change-request/'],
          privilege: ['ChM.ChangeRequest.ViewList'],
        },
        {
          key: 'change-request-list-templates',
          label: 'menu.changeManagement.changeRequestTemplates',
          icon: 'tsm-icon-change-request',
          listingType: 'tsm-change-request-templates-list',
          routerLink: ['/change-management/change-request-template'],
          privilege: ['ChM.ChangeRequestTemplate.ViewList'],
        },
        {
          key: 'change-request-list-template-groups',
          label: 'menu.changeManagement.changeRequestTemplateGroups',
          icon: 'tsm-icon-change-request',
          listingType: 'tsm-change-reques-template-groups-list',
          routerLink: ['/change-management/change-request-template-group'],
          privilege: ['ChM.ChangeRequestTemplate.ViewList'],
        },
        {
          key: 'tsm-change-registers',
          label: 'menu.changeManagement.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'ChM.Registers.OperationImpact.ViewList',
            'ChM.Registers.Technology.ViewList',
            'ChM.Registers.ImpactOnCustomer.ViewList',
            'ChM.Registers.ReasonForWork.ViewList',
            'ChM.Registers.Category.ViewList',
            'ChM.Registers.Priority.ViewList',
            'ChM.Registers.Status.ViewList',
            'ChM.Registers.Resolution.ViewList',
            'ChM.Registers.Channel.ViewList',
            'ChM.Registers.Type.ViewList',
            'ChM.Registers.RelatedEntityType.ViewList',
            'ChM.Registers.RelatedPartyType.ViewList',
            'ChM.Registers.RelatedPartyRole.ViewList',
            'ChM.Registers.LinkType.ViewList',
            'ChM.Registers.ScheduleType.ViewList',
          ],
          items: [
            {
              key: 'PEW_CHANNEL',
              label: 'menu.changeManagement.operationImpacts',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/registers/OPERATION_IMPACT'],
              privilege: ['ChM.Registers.OperationImpact.ViewList'],
            },
            // {
            //   key: 'tsm-ticket-category-list',
            //   label: 'menu.ticket.category',
            //   icon: 'tsm-icon-menu-item',
            //   routerLink: ['/change-management/category'],
            //   privilege: ['ChM.Registers.Category.ViewList']
            // },
            {
              key: 'pew_technology-list',
              label: 'menu.changeManagement.technology',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/technology'],
              privilege: ['ChM.Registers.Technology.ViewList'],
            },
            {
              key: 'pew_impact-on-customer-list',
              label: 'menu.changeManagement.impactOnCustomer',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/impact-on-customer'],
              privilege: ['ChM.Registers.ImpactOnCustomer.ViewList'],
            },
            {
              key: 'pew_reason-for-work-list',
              label: 'menu.changeManagement.reasonForWork',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/reason-for-work'],
              privilege: ['ChM.Registers.ReasonForWork.ViewList'],
            },
            {
              key: 'pew_tsm-priority-list',
              label: 'menu.changeManagement.priority',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/priority'],
              privilege: ['ChM.Registers.Priority.ViewList'],
            },
            {
              key: 'pew_tsm-status-list',
              label: 'menu.changeManagement.statuses',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/status'],
              privilege: ['ChM.Registers.Status.ViewList'],
            },
            {
              key: 'PEW_RESOLUTION-list',
              label: 'menu.changeManagement.resolution',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/registers/RESOLUTION'],
              privilege: ['ChM.Registers.Resolution.ViewList'],
            },
            {
              key: 'PEW_CHANNEL-list',
              label: 'menu.changeManagement.channels',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/registers/CHANNEL'],
              privilege: ['ChM.Registers.Channel.ViewList'],
            },
            {
              key: 'pew_ticket-type-list',
              label: 'menu.changeManagement.types',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/type/ChangeManagement'],
              privilege: ['ChM.Registers.Type.ViewList'],
            },
            {
              key: 'pew_related-entity-type-list',
              label: 'menu.changeManagement.relatedEntityType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/related-entity-type'],
              privilege: ['ChM.Registers.RelatedEntityType.ViewList'],
            },
            {
              key: 'pew_related-party-type-list',
              label: 'menu.changeManagement.relatedPartyType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/related-party-type'],
              privilege: ['ChM.Registers.RelatedPartyType.ViewList'],
            },
            {
              key: 'pew_related-party-role-list',
              label: 'menu.changeManagement.relatedPartyRole',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/related-party-role'],
              privilege: ['ChM.Registers.RelatedPartyRole.ViewList'],
            },
            {
              key: 'pew_link-type-list',
              label: 'menu.changeManagement.linkType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/link-type'],
              privilege: ['ChM.Registers.LinkType.ViewList'],
            },
            {
              key: 'pew_schedule-type',
              label: 'menu.changeManagement.scheduleType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/change-management/schedule-types'],
              privilege: ['ChM.Registers.ScheduleType.ViewList'],
            },
          ],
        },
        {
          key: 'change-request-worklog-list',
          label: 'menu.changeManagement.workOnLocalities',
          icon: 'tsm-icon-change-request',
          listingType: 'tsm-worklog-list',
          routerLink: ['/change-management/worklog-locality'],
          privilege: ['ChM.WorkLogLocality.ViewList'],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'change-management',
      loadChildren: () =>
        import('@tsm/change-management/components').then(
          (x) => x.ChangeManagementComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/change-management/widgets').then(
          (x) => x.ChangeManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'tsm-change-request-list-actions-button',
        },
        {
          selector: 'tsm-change-request-poznamka-cednik',
        },
        {
          selector: 'tsm-schedule-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-schedule-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'schedule',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.scheduleActivitiesSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.scheduleActivitiesSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.scheduleActivitiesSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-schedule-type-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-technology-lov',
          privilege: ['!ChM.Widget.tsm-technology-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-technology-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'technology',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.technologySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.technologySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.technologySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-technology-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {selector: 'tsm-change-request-card'},
        {selector: 'tsm-worklog-locality-list-row-button-widget'},
        {selector: 'tsm-change-request-task-detail'},
        {
          selector: 'tsm-change-request-schedule-widget',
          privilege: ['!ChM.Widget.tsm-schedule-widget'],
          designers: [
            {
              editorSelector: 'tsm-change-request-schedule-widget',
              widgetOrLayout: 'widget',
              formField: 'scheduleIds',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.scheduleBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.scheduleBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.bookmark,
              value: {
                title:
                  translationShared.shared.widgets.scheduleBookmark
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-change-request-schedule-widget',
                },
                type: 'array',
              },
            },
          ],
        },
        {
          selector: 'tsm-change-request-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-change-request-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.changeRequestList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.changeRequestList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.changeRequestList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-change-request-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-worklog-locality-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-worklog-locality-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.workLogLocationsList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.workLogLocationsList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.workLogLocationsList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-worklog-locality-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-change-request-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-change-request-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'changeRequest',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.changeRequestSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.changeRequestSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.changeRequestSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-change-request-lov',
                },
                type: 'string',
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-operation-impact-lov',
          privilege: ['!ChM.Widget.tsm-operation-impact-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-operation-impact-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'operationImpact',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.operationImpactSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.operationImpactSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.operationImpactSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-operation-impact-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-impact-on-customer-lov',
          privilege: ['!ChM.Widget.tsm-impact-on-customer-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-impact-on-customer-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'impactOnCustomer',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.customerImpactSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerImpactSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 8,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.customerImpactSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-impact-on-customer-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-region-lov',
          privilege: ['!ChM.Widget.tsm-region-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-region-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'region',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.regionSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.regionSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.regionSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-region-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-reason-for-work-lov',
          privilege: ['!ChM.Widget.tsm-reason-for-work-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-reason-for-work-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'reasonForWork',
              categoryId: DesignerCategoryEnum.ChangeManagement,
              name: translationShared.shared.widgets.workReasonSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.workReasonSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 10,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.workReasonSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-reason-for-work-lov',
                },
                type: 'string',
                config: {
                  selectProperty: 'code',
                },
              },
            },
          ],
        },
      ],
    },
    {
      loadChildren: () =>
        import('@tsm/change-management/components').then(
          (x) => x.ChangeManagementComponentsModule,
        ),
      definitions: [{selector: 'tsm-change-request-new-wizard'}],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/change-management/widgets').then(
          (x) => x.ChangeManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'tsm-change-management-filter',
          name: translationPlugin.changeManagementPlugins.filters
            .tsmChangeManagementFilter,
          description:
            translationPlugin.changeManagementPlugins.filters
              .tsmChangeManagementFilterDescription,
        },
        {
          selector: 'tsm-impact-on-customer-filter',
          name: translationPlugin.changeManagementPlugins.filters
            .tsmImpactOnCustomerFilter,
          description:
            translationPlugin.changeManagementPlugins.filters
              .tsmImpactOnCustomerFilterDescription,
        },
        {
          selector: 'tsm-operation-impact-filter',
          name: translationPlugin.changeManagementPlugins.filters
            .tsmOperationImpactFilter,
          description:
            translationPlugin.changeManagementPlugins.filters
              .tsmOperationImpactFilterDescription,
        },
        {
          selector: 'tsm-technology-filter',
          name: translationPlugin.changeManagementPlugins.filters
            .tsmTechnologyFilter,
          description:
            translationPlugin.changeManagementPlugins.filters
              .tsmTechnologyFilterDescription,
        },
        {
          selector: 'tsm-worklog-locality-user-groups-filter',
          name: translationPlugin.changeManagementPlugins.filters
            .tsmWorklogLocalityUserGroupsFilter,
          description:
            translationPlugin.changeManagementPlugins.filters
              .tsmWorklogLocalityUserGroupsFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/change-management/widgets').then(
          (x) => x.ChangeManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'changeRequestLink',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestLink,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestLinkDescription,
        },
        {
          selector: 'changeRequestLoader',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestLoader,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestLoaderDescription,
        },
        {
          selector: 'changeRequestOperationImpactByCode',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestOperationImpactByCode,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestOperationImpactByCodeDescription,
        },
        {
          selector: 'changeRequestOperationImpact',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestOperationImpact,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestOperationImpactDescription,
        },
        {
          selector: 'changeRequestSchedule',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestSchedule,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestScheduleDescription,
        },
        {
          selector: 'changeRequestTechnologyCode',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestTechnologyCode,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestTechnologyCodeDescription,
        },
        {
          selector: 'changeRequestTechnology',
          name: translationPlugin.changeManagementPlugins.pipes
            .changeRequestTechnology,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .changeRequestTechnologyDescription,
        },
        {
          selector: 'relatedEntitiesToPlaces',
          name: translationPlugin.changeManagementPlugins.pipes
            .relatedEntitiesToPlaces,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .relatedEntitiesToPlacesDescription,
        },
        {
          selector: 'scheduleTypeLoader',
          name: translationPlugin.changeManagementPlugins.pipes
            .scheduleTypeLoader,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .scheduleTypeLoaderDescription,
        },
        {
          selector: 'scheduleTypeLoaderCode',
          name: translationPlugin.changeManagementPlugins.pipes
            .scheduleTypeLoaderCode,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .scheduleTypeLoaderCodeDescription,
        },
        {
          selector: 'worklogLocalityUserGroups',
          name: translationPlugin.changeManagementPlugins.pipes
            .worklogLocalityUserGroups,
          description:
            translationPlugin.changeManagementPlugins.pipes
              .worklogLocalityUserGroupsDescription,
        },
      ],
    },
  ],
  layoutControls: [
    controlsRelatedEntityPlaceObjectsDetailConfiguration,
    controlsRelatedEntityPlaceLocalitiesDetailConfiguration,
    controlsWorklogLocalityTopbarConfiguration,
  ],
};
