import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DeliveryTime} from '../models';
import {concatMap, map} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Injectable} from '@angular/core';
import {CommonApiService} from '../services';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  DiffDeliveryTime,
  DiffDeliveryTimeError,
  DiffDeliveryTimeSuccess,
} from '../actions/delivery-time.actions';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';

@Injectable()
export class DeliveryTimeEffects {
  translationShared = translationShared;

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffDeliveryTime),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/delivery-times', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffDeliveryTimeSuccess({
                  deliveryTimes: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffDeliveryTimeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffDeliveryTimeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private ts: ToastService,
    private commonApiService: CommonApiService<DeliveryTime, DeliveryTime>,
  ) {}
}
