import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {map, Observable} from 'rxjs';
import {PrivHelperService} from '@tsm/framework/security';
import {Store} from '@ngrx/store';
import {selectUpdateMessage} from '@tsm/shared';

@Injectable()
export class PrivilegeGuard implements CanActivateChild {
  constructor(
    private privHelperService: PrivHelperService,
    private router: Router,
    private store$: Store,
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    // pokud je k dispozici nova verze reloadni
    return this.store$.select(selectUpdateMessage).pipe(
      map((isVisible) => {
        const hasPriv =
          route.data.privilege === undefined
            ? true
            : this.privHelperService.isCurrentUserHasAnyOfRoles(
                route.data.privilege,
              );
        if (!hasPriv) {
          if (isVisible) {
            window.location.href = '/403';
            return false;
          }
          this.router.navigate(['/403']);
          console.error(
            'Na pozadovanou URL [' +
              state.url +
              '] nemate prirazene privilegium!',
          );
        }
        if (isVisible) {
          window.location.href = state.url;
          return false;
        }
        return hasPriv;
      }),
    );
  }
}
