export const cs = {
  shared: {
    detailUrl: 'Detail url',
    lineType: 'Typ order line',
    rowIdField: 'ID záznamu',
    emptyMessage: 'Nenalezeny žádné záznamy',
    actionsMetadata: {
      navigate: 'Navigovat',
      showMessage: 'Zobrazit zprávu',
      logout: 'Odhlásit',
      loadTicketById: 'Načíst tiket podle ID',
      loadTicketByKey: 'Načíst tiket podle klíče',
      loadCustomerById: 'Načíst zákazníka podle ID',
      loadCustomerByKey: 'Načíst zákazníka podle klíče',
      loadLeadById: 'Načíst lead podle ID',
      loadLeadByKey: 'Načíst lead podle klíče',
      loadPersonById: 'Načíst osobu podle ID',
      loadPersonByKey: 'Načíst osobu podle klíče',
      loadAccountById: 'Načíst účet podle ID',
      loadAccountByKey: 'Načíst účet podle klíče',
      loadCampaignById: 'Načíst kampaň podle ID',
      loadCampaignByCode: 'Načíst kampaň podle kódu',
      loadOrderById: 'Načíst objednávku podle ID',
      loadOrderByKey: 'Načíst objednávku podle klíče',
      loadTaskByExecutionIdAndActivityId:
        'Načíst úkol podle executionId a activityId',
      loadTasksByBusinessKey: 'Načíst úkoly podle businessKey',
      loadCatalogById: 'Načíst katalog podle ID',
      loadCatalogByCode: 'Načíst katalog podle kódu',
      loadCatalogCategoryById: 'Načíst kategorii katalogu podle ID',
      loadCatalogCategoryByCodeAndCatalogCode:
        'Načíst kategorii katalogu podle kódu a kódu katalogu',
      loadCatalogSpecificationByCategoryId:
        'Načíst specifikaci katalogu podle ID kategorie',
      loadCatalogSpecificationByCatalogId:
        'Načíst specifikaci katalogu podle ID katalogu',
      loadByIdRegisterValue: 'Načíst hodnotu číselníku podle ID',
      loadByCodeRegisterValue:
        'Načíst hodnotu číselníku podle kódu hodnoty číselníku a kódu číselníku',
      loadCatalogSpecificationByCodeAndCatalogType:
        'Načíst specifikaci katalogu podle kódu a typu katalogu',
      loadCatalogSpecificationById: 'Načíst specifikaci katalogu podle ID',
      loadCatalogSpecificationByCode: 'Načíst specifikaci katalogu podle kódu',
      loadEntityInstanceConfigurationById:
        'Načíst konfiguraci instance entity podle ID',
      loadEntityInstanceConfigurationByKey:
        'Načíst konfiguraci instance entity podle kliče',
      loadEntityInstanceConfigurationsByEntityInstanceId:
        'Načíst konfigurace instance entity podle ID instance',
      loadStockById: 'Načíst sklad podle ID',
      loadStockByKey: 'Načíst sklad podle klíče',
      loadMaintenanceActivityById: 'Načíst údržbovou aktivitu podle ID',
      loadMaintenancePlanById: 'Načíst plán údržby podle ID',
      loadMaintenancePlanByKey: 'Načíst plán údržby podle klíče',
      loadOrderLinesByOrderId: 'Načíst řádky objednávky podle ID objednávky',
      loadOrderLineById: 'Načíst řádek objednávky podle ID',
      loadOrderLinesByIds: 'Načíst řádky objednávky podle více ID',
      loadRegisterById: 'Načíst číselník podle ID',
      loadRegisterByCode: 'Načíst číselník podle kódu',
      loadStockItemById: 'Načíst položku skladu podle ID',
      loadStockItemByKey: 'Načíst položku skladu podle klíče',
      loadStockItemBySerialNumber:
        'Načíst položku skladu podle sériového čísla',
    },
    activityId: 'Activity id',
    cutionId: 'Execution id',
    templates: 'Šablony',
    print: 'Tisk',
    reloadAgain: 'Znovu načíst',
    openInNewTab: 'Otevřít v nové záložce',
    continue: 'Pokračovat',
    removeValueOnChange: 'Při změně smazat hodnotu',
    hideCode: 'Skrýt kód',
    listingWidgets: {
      sections: {
        commonAttributes: 'Společné atributy',
        commonProperties: 'Společné vlastnosti',
        tqlExtensions: 'Rozšíření TQL',
        widgetSpecificSettings: 'Specifické nastavení widgetu',
        newFormSettings: 'Nastavení nového formuláře',
        filterSettings: 'Nastavení filtrů',
      },
    },
    widgets: {
      // CRM
      customerList: {
        widgetDescription:
          'Seznam zákazníků v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam zákazníků',
        inWidgetTitle: 'Seznam zákazníků',
      },
      campaignWaveList: {
        widgetDescription:
          'Seznam vln kampaně v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam vln kampaně',
        inWidgetTitle: 'Seznam vln kampaně',
      },
      leadList: {
        widgetDescription:
          'Seznam leadů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam leadů',
        inWidgetTitle: 'Seznam leadů',
      },
      accountsBookmark: {
        widgetDescription: 'Jednoduchý seznam účtů v tabulkovém zobrazení.',
        widgetName: 'Přehled účtů',
        inWidgetTitle: '',
      },
      personsBookmark: {
        widgetDescription: 'Jednoduchý seznam osob v tabulkovém zobrazení.',
        widgetName: 'Přehled osob',
        inWidgetTitle: '',
      },
      addressesBookmark: {
        widgetDescription: 'Jednoduchý seznam adres v tabulkovém zobrazení.',
        widgetName: 'Přehled adres',
        inWidgetTitle: '',
      },
      addressRolesBookmark: {
        widgetDescription:
          'Jednoduchý seznam rolí adres v tabulkovém zobrazení.',
        widgetName: 'Přehled rolí adres',
        inWidgetTitle: '',
      },
      responsiblePersonsBookmark: {
        widgetDescription:
          'Jednoduchý seznam odpovědných osob v tabulkovém zobrazení.',
        widgetName: 'Přehled odpovědných osob',
        inWidgetTitle: '',
      },
      contactsBookmark: {
        widgetDescription: 'Jednoduchý seznam kontaktů v tabulkovém zobrazení.',
        widgetName: 'Přehled kontaktů',
        inWidgetTitle: '',
      },
      accountTypeSelect: {
        widgetDescription: 'Výběr typu účtu z rozbalovací nabídky..',
        widgetName: 'Nabídka typu účtu',
        inWidgetTitle: 'Vyberte typ účtu',
      },
      campaignSelect: {
        widgetDescription: 'Výběr kampaně z rozbalovací nabídky.',
        widgetName: 'Nabídka kampaní',
        inWidgetTitle: 'Vyberte kampaň',
      },
      campaignWaveDefinition: {
        widgetDescription: 'Výběr vlny kampaně z rozbalovací nabídky.',
        widgetName: 'Nabídka vln kampaní',
        inWidgetTitle: 'Vyberte vlnu kampaně',
      },
      campaignTypeSelect: {
        widgetDescription: 'Výběr typu kampaně z rozbalovací nabídky.',
        widgetName: 'Nabídka typů kampaní',
        inWidgetTitle: 'Vyberte typ kampaně',
      },
      customerSelect: {
        widgetDescription: 'Výběr zákazníka z rozbalovací nabídky.',
        widgetName: 'Nabídka zákazníků',
        inWidgetTitle: 'Vyberte zákazníka',
      },
      accountSelect: {
        widgetDescription: 'Výběr účtu z rozbalovací nabídky.',
        widgetName: 'Nabídka účtů',
        inWidgetTitle: 'Vyberte účet',
      },
      leadSelect: {
        widgetDescription: 'Výběr leadu z rozbalovací nabídky.',
        widgetName: 'Nabídka leadů',
        inWidgetTitle: 'Vyberte lead',
      },
      leadTypeSelect: {
        widgetDescription: 'Výběr typu leada z rozbalovací nabídky.',
        widgetName: 'Nabídka typů leada',
        inWidgetTitle: 'Vyberte typ leada',
      },
      personSelect: {
        widgetDescription: 'Výběr osoby z rozbalovací nabídky.',
        widgetName: 'Nabídka osob',
        inWidgetTitle: 'Vyberte osobu',
      },
      personTypeSelect: {
        widgetDescription: 'Výběr typu osoby z rozbalovací nabídky.',
        widgetName: 'Nabídka typů osob',
        inWidgetTitle: 'Vyberte typ soby',
      },
      addressSelect: {
        widgetDescription: 'Výběr adresy z rozbalovací nabídky.',
        widgetName: 'Nabídka adres',
        inWidgetTitle: 'Vyberte adresu',
      },
      addressRoleTypeSelect: {
        widgetDescription: 'Výběr typu role adresy z rozbalovací nabídky.',
        widgetName: 'Nabídka typů rol adres',
        inWidgetTitle: 'Vyberte typ role adresy',
      },
      legalFormSelect: {
        widgetDescription: 'Výběr právní formy z rozbalovací nabídky.',
        widgetName: 'Nabídka právních forem',
        inWidgetTitle: 'Vyberte právní formu',
      },
      customerSegmentSelect: {
        widgetDescription: 'Výběr segmentu z rozbalovací nabídky.',
        widgetName: 'Nabídka segmentů zákazníků',
        inWidgetTitle: 'Vyberte segmentu zákazníka',
      },
      customerTypeSelect: {
        widgetDescription: 'Výběr typu zákazníka rozbalovací nabídky.',
        widgetName: 'Nabídka typů zákazníků',
        inWidgetTitle: 'Vyberte typ zákazníka',
      },
      campaignStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu kampaně s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů kampaně',
        inWidgetTitle: '',
      },
      accountStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu účtu s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů účtu',
        inWidgetTitle: '',
      },
      leadStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu leadu s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů leadu',
        inWidgetTitle: '',
      },
      customerStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu zákazníka s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů zákazníka',
        inWidgetTitle: '',
      },
      personStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu osoby s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů osoby',
        inWidgetTitle: '',
      },
      plannedTaskCard: {
        widgetDescription:
          'Karta zobrazující podrobnosti o plánovaném úkolu z Work Force Managementu.',
        widgetName: 'Karta plánovaného úkolu',
        inWidgetTitle: '',
      },
      productCard: {
        widgetDescription: 'Informace o produktu zobrazené ve formě karty.',
        widgetName: 'Karta produktu',
        inWidgetTitle: '',
      },
      createFromAresButton: {
        widgetDescription: 'Tlačítko pro vytvoření zákazníka z registru ARES.',
        widgetName: 'Tlačítko vytvořit z Aresu',
        inWidgetTitle: '',
      },
      customerAuditList: {
        widgetDescription:
          'Historie akcí a změn provedených na zákazníkovi v přehledném zobrazení.',
        widgetName: 'Historie zákazníka',
        inWidgetTitle: '',
      },
      accountAuditList: {
        widgetDescription:
          'Historie akcí a změn provedených na účtu v přehledném zobrazení.',
        widgetName: 'Historie účtu',
        inWidgetTitle: '',
      },
      leadAuditList: {
        widgetDescription:
          'Historie akcí a změn provedených na leadu v přehledném zobrazení.',
        widgetName: 'Historie leadu',
        inWidgetTitle: '',
      },
      personAuditList: {
        widgetDescription:
          'Historie akcí a změn provedených na osobe v přehledném zobrazení.',
        widgetName: 'Historie osoby',
        inWidgetTitle: '',
      },

      // BILLING
      billingCycleTypeSelect: {
        widgetDescription:
          'Výběr typu fakturačního cyklu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů fakt. cyklů',
        inWidgetTitle: 'Vyberte typ fakturačního cyklu',
      },
      billingCurrencySelect: {
        widgetDescription: 'Výběr měny fakturace z rozbalovací nabídky.',
        widgetName: 'Nabídka měn fakturace',
        inWidgetTitle: 'Vyberte měnu fakturace',
      },
      billingCycleSelect: {
        widgetDescription: 'Výběr fakturačního cyklu z rozbalovací nabídky.',
        widgetName: 'Nabídka fakturačních cyklů',
        inWidgetTitle: 'Vyberte fakturační cyklus',
      },
      billingCycleStatusSelect: {
        widgetDescription:
          'Výběr stavu fakturačního cyklu z rozbalovací nabídky.',
        widgetName: 'Nabídka stavů fakturačního cyklu',
        inWidgetTitle: 'Vyberte stav fakturačního cyklu',
      },
      billingDocumentTypeSelect: {
        widgetDescription:
          'Výběr typu fakturačního dokumentu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů fakt. dokumentů',
        inWidgetTitle: 'Vyberte typ fakturačního dokumentu',
      },
      billingDocumentLineSelect: {
        widgetDescription:
          'Výběr linky fakturačního dokumentu z rozbalovací nabídky.',
        widgetName: 'Nabídka linek fakt. dokumentů',
        inWidgetTitle: 'Vyberte linku fakturačního dokumentu',
      },
      billingDocumentStatusSelect: {
        widgetDescription:
          'Výběr stavu fakturačního dokumentu z rozbalovací nabídky.',
        widgetName: 'Nabídka stavů fakt. dokumentů',
        inWidgetTitle: 'Vyberte stav fakturačního dokumentu',
      },
      billingSapDocumentStatusSelect: {
        widgetDescription:
          'Výběr stavu fakturačního SAP dokumentu z rozbalovací nabídky.',
        widgetName: 'Nabídka stavů SAP fakt. dokumentů',
        inWidgetTitle: 'Vyberte stav SAP fakturačního dokumentu',
      },
      billingDocumentSelect: {
        widgetDescription:
          'Výběr fakturačního dokumentu z rozbalovací nabídky.',
        widgetName: 'Nabídka fakt. dokumentů',
        inWidgetTitle: 'Vyberte fakturační dokument',
      },
      vatRateSelect: {
        widgetDescription: 'Výběr sazby DPH z rozbalovací nabídky.',
        widgetName: 'Nabídka sazeb DPH',
        inWidgetTitle: 'Vyberte sazbu DPH',
      },
      billingDocumentList: {
        widgetDescription:
          'Seznam fakturačních dokumentů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam fakturačních dokumentů',
        inWidgetTitle: 'Vyberte fakturační dokument',
      },

      // ORDERING
      orderLinesBookmark: {
        widgetDescription:
          'Jednoduchý seznam položek objednávky v tabulkovém zobrazení.',
        widgetName: 'Přehled položek objednávky',
        inWidgetTitle: 'Přehled položek objednávky',
      },
      orderStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu objednávky s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů objednávky',
        inWidgetTitle: 'Lišta stavů objednávky',
      },
      quotesBookmark: {
        widgetDescription: 'Jednoduchý seznam nabídek v tabulkovém zobrazení.',
        widgetName: 'Přehled nabídek',
        inWidgetTitle: 'Přehled nabídek',
      },
      ordersBookmark: {
        widgetDescription:
          'Jednoduchý seznam objednávek v tabulkovém zobrazení.',
        widgetName: 'Přehled objednávek',
        inWidgetTitle: 'Přehled objednávek',
      },
      orderSelect: {
        widgetDescription: 'Výběr objednávky z rozbalovací nabídky.',
        widgetName: 'Nabídka objednávek',
        inWidgetTitle: 'Vyberte objednávku',
      },
      orderStatusSelect: {
        widgetDescription: 'Výběr stavu objednávky z rozbalovací nabídky.',
        widgetName: 'Nabídka stavů objednávek',
        inWidgetTitle: 'Vyberte stav objednávky',
      },
      orderTypeSelect: {
        widgetDescription: 'Výběr typu objednávky z rozbalovací nabídky.',
        widgetName: 'Nabídka typů objednávek',
        inWidgetTitle: 'Vyberte typ objednávky',
      },
      orderTaskList: {
        widgetDescription:
          'Seznam úkolů objednávky v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam úkolů objednávky',
        inWidgetTitle: 'Seznam úkolů objednávky',
      },
      orderList: {
        widgetDescription:
          'Seznam objednávek v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam objednávek',
        inWidgetTitle: 'Seznam objednávek',
      },
      orderLine: {
        widgetDescription:
          'Seznam položek objednávek v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam položek objednávek',
        inWidgetTitle: 'Seznam položek objednávek',
      },
      relatedEntityOrderList: {
        widgetDescription:
          'Seznam objednávek souvisejících entit v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam souvisejících objednávek',
        inWidgetTitle: 'Seznam souvisejících objednávek',
      },

      // TICKETING
      ticketRelationshipList: {
        widgetDescription:
          'Seznam vztahů tiketů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam vztahů tiketů',
        inWidgetTitle: 'Seznam vztahů tiketů',
      },
      ticketAuditList: {
        widgetDescription:
          'Historie akcí a změn souvisejících s tiketem v přehledném zobrazení.',
        widgetName: 'Historie tiketu',
        inWidgetTitle: 'Historie tiketu',
      },
      adviceList: {
        widgetDescription:
          'Seznam avíz v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam avíz',
        inWidgetTitle: 'Seznam avíz',
      },
      relatedEntityList: {
        widgetDescription:
          'Seznam souvisejících entit v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam souvisejících entit',
        inWidgetTitle: 'Seznam souvisejících entit',
      },
      ticketTaskList: {
        widgetDescription:
          'Seznam úkolů souvisejících s tikety v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam úkolů tiketů',
        inWidgetTitle: 'Seznam úkolů tiketů',
      },
      ticketList: {
        widgetDescription:
          'Seznam tiketů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam tiketů',
        inWidgetTitle: 'Seznam tiketů',
      },
      ticketRelatedEntityList: {
        widgetDescription:
          'Seznam souvisejících entit tiketů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam souvisejících entit tiketů',
        inWidgetTitle: 'Seznam souvisejících entit tiketů',
      },
      ticketCategorySelect: {
        widgetDescription: 'Výběr kategorie tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka kategorií tiketů',
        inWidgetTitle: 'Vyberte kategorii tiketu',
      },
      adviceTypeSelect: {
        widgetDescription: 'Výběr typu avíza z rozbalovací nabídky.',
        widgetName: 'Nabídka typů avíz',
        inWidgetTitle: 'Vyberte typ avíza',
      },
      ticketProblemSelect: {
        widgetDescription: 'Výběr problému tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka problémů tiketů',
        inWidgetTitle: 'Vyberte problém tiketu',
      },
      ticketProblemTreeSelect: {
        widgetDescription:
          'Výběr problému tiketu ze stromové rozbalovací nabídky.',
        widgetName: 'Nabídka (stromová) problémů tiketů',
        inWidgetTitle: 'Vyberte problém tiketu',
      },
      ticketResolutionSelect: {
        widgetDescription: 'Výběr řešení tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka řešení tiketů',
        inWidgetTitle: 'Vyberte řešení tiketu',
      },
      ticketSelect: {
        widgetDescription: 'Výběr tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka tiketů',
        inWidgetTitle: 'Vyberte tiket',
      },
      ticketRelatedPartySelect: {
        widgetDescription:
          'Výběr související strany tiketu z rozbalovacího seznamu.',
        widgetName: 'Výběr související strany tiketu',
        inWidgetTitle: 'Vyberte související stranu tiketu',
      },
      ticketStatusSelect: {
        widgetDescription: 'Výběr stavu tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka stavů tiketů',
        inWidgetTitle: 'Vyberte stav tiketu',
      },
      ticketRelatedPartyRoleSelect: {
        widgetDescription:
          'Výběr role související strany tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka rolí souvisejících stran tiketů',
        inWidgetTitle: 'Vyberte roli související strany tiketu',
      },
      ticketPrioritySelect: {
        widgetDescription: 'Výběr priority tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka priorit tiketů',
        inWidgetTitle: 'Vyberte prioritu tiketu',
      },
      ticketSeveritySelect: {
        widgetDescription: 'Výběr závažnosti tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka závažnosti tiketů',
        inWidgetTitle: 'Vyberte závažnost tiketu',
      },
      ticketRelatedEntitySelect: {
        widgetDescription:
          'Výběr související entity pro tikety z rozbalovací nabídky.',
        widgetName: 'Nabídka souvisejících entit tiketů',
        inWidgetTitle: 'Vyberte související entitu',
      },
      ticketTypeSelect: {
        widgetDescription: 'Výběr typu tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů tiketů',
        inWidgetTitle: 'Vyberte typ tiketu',
      },
      ticketTemplateTypeSelect: {
        widgetDescription: 'Výběr typu šablony tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů šablon tiketů',
        inWidgetTitle: 'Vyberte typ šablony tiketu',
      },
      adviceActivePanel: {
        widgetDescription:
          'Panel zobrazující podrobnosti aktivních avíz na tiketu.',
        widgetName: 'Panel aktivních avíz',
        inWidgetTitle: 'Panel aktivních avíz',
      },
      ticketCategoryRegistrySelect: {
        widgetDescription:
          'Výběr registru kategorií tiketů z rozbalovací nabídky.',
        widgetName: 'Nabídka registrů kategorií tiketů',
        inWidgetTitle: 'Vyberte registr kategorií tiketů',
      },
      ticketRelatedEntityTypeSelect: {
        widgetDescription:
          'Výběr typu související entity tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů souvisejících entit tiketů',
        inWidgetTitle: 'Vyberte typ související entity tiketu',
      },
      ticketChannelSelect: {
        widgetDescription: 'Výběr kanálu tiketu z rozbalovací nabídky.',
        widgetName: 'Nabídka kanálů tiketů',
        inWidgetTitle: 'Vyberte kanál tiketu',
      },
      relationshipBookmark: {
        widgetDescription: 'Přehled zobrazující informace o vztazích.',
        widgetName: 'Přehled vztahů',
        inWidgetTitle: 'Přehled vztahů',
      },
      stockItemList: {
        widgetDescription:
          'Seznam skladových položek v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam skladových položek',
        inWidgetTitle: 'Seznam skladových položek',
      },
      stockSelect: {
        widgetDescription: 'Výběr skladu z rozbalovací nabídky.',
        widgetName: 'Nabídka skladů',
        inWidgetTitle: 'Vyberte sklad',
      },
      stockTypeSelect: {
        widgetDescription: 'Výběr typu skladu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů skladů',
        inWidgetTitle: 'Vyberte typ skladu',
      },
      stockItemSelect: {
        widgetDescription: 'Výběr skladové položky z rozbalovací nabídky.',
        widgetName: 'Nabídka skladových položek',
        inWidgetTitle: 'Vyberte skladovou položku',
      },
      stockItemTypeSelect: {
        widgetDescription: 'Výběr typu skladové položky z rozbalovací nabídky.',
        widgetName: 'Nabídka typů skladových položek',
        inWidgetTitle: 'Vyberte typ skladové položky',
      },
      stockMovementTypeSelect: {
        widgetDescription:
          'Výběr typu pohybu skladové položky z rozbalovací nabídky.',
        widgetName: 'Nabídka typů pohybů skladových položek',
        inWidgetTitle: 'Vyberte typ pohybu skladové položky',
      },
      reportTable: {
        widgetDescription:
          'Tabulkové zobrazení dat reportu s možností filtrování.',
        widgetName: 'Tabulka reportu',
        inWidgetTitle: 'Tabulka reportu',
      },
      reportChart: {
        widgetDescription: 'Grafické zobrazení dat reportu.',
        widgetName: 'Graf reportu',
        inWidgetTitle: 'Graf reportu',
      },
      userSelect: {
        widgetDescription: 'Výběr uživatele z rozbalovací nabídky.',
        widgetName: 'Nabídka uživatelů',
        inWidgetTitle: 'Vyberte uživatele',
      },
      userRoleSelect: {
        widgetDescription: 'Výběr role uživatele z rozbalovací nabídky.',
        widgetName: 'Nabídka rolí uživatelů',
        inWidgetTitle: 'Vyberte roli uživatele',
      },
      userGroupSelect: {
        widgetDescription: 'Výběr skupiny uživatelů z rozbalovací nabídky.',
        widgetName: 'Nabídka skupin uživatelů',
        inWidgetTitle: 'Vyberte skupinu uživatelů',
      },
      userGroupTypeSelect: {
        widgetDescription:
          'Výběr typu skupiny uživatelů z rozbalovací nabídky.',
        widgetName: 'Nabídka typů skupin uživatelů',
        inWidgetTitle: 'Vyberte typ skupiny uživatelů',
      },
      userPrivilegeSelect: {
        widgetDescription: 'Výběr práv uživatele z rozbalovací nabídky.',
        widgetName: 'Nabídka práv uživatele',
        inWidgetTitle: 'Vyberte práva uživatele',
      },
      userTypeSelect: {
        widgetDescription: 'Výběr typu uživatele z rozbalovací nabídky.',
        widgetName: 'Nabídka typů uživatel',
        inWidgetTitle: 'Vyberte typ uživatele',
      },
      logList: {
        widgetDescription:
          'Seznam logů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam logů',
        inWidgetTitle: 'Seznam logů',
      },
      addressSearch: {
        widgetDescription:
          'Komponenta pro vyhledávání a výběr adres s pokročilými filtry.',
        widgetName: 'Vyhledávání adres',
        inWidgetTitle: 'Vyhledávání adres',
      },
      scriptSelect: {
        widgetDescription: 'Výběr skriptu z rozbalovací nabídky.',
        widgetName: 'Nabídka skriptů',
        inWidgetTitle: 'Vyberte skript',
      },
      scheduleActivitiesSelect: {
        widgetDescription: 'Výběr činností harmonogramu z rozbalovací nabídky.',
        widgetName: 'Nabídka činností harmonogramu',
        inWidgetTitle: 'Vyberte činnosti harmonogramu',
      },
      technologySelect: {
        widgetDescription: 'Výběr technologií z rozbalovací nabídky.',
        widgetName: 'Nabídka technologií',
        inWidgetTitle: 'Vyberte technologii',
      },
      scheduleBookmark: {
        widgetDescription:
          'Jednoduchý seznam harmonogramů v tabulkovém zobrazení.',
        widgetName: 'Přehled harmonogramů',
        inWidgetTitle: 'Harmonogram',
      },
      changeRequestList: {
        widgetDescription:
          'Seznam žádostí o změnu v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam žádostí o změnu',
        inWidgetTitle: 'Seznam žádostí o změnu',
      },
      workLogLocationsList: {
        widgetDescription:
          'Seznam lokalit logů práce v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam lokalit logů práce',
        inWidgetTitle: 'Seznam lokalit logů práce',
      },
      changeRequestSelect: {
        widgetDescription: 'Výběr žádostí o změnu z rozbalovací nabídky.',
        widgetName: 'Nabídka žádostí o změnu',
        inWidgetTitle: 'Vyberte žádost o změnu',
      },
      operationImpactSelect: {
        widgetDescription: 'Výběr vlivu na provoz z rozbalovací nabídky.',
        widgetName: 'Nabídka vlivů na provoz',
        inWidgetTitle: 'Vyberte vliv na provoz',
      },
      customerImpactSelect: {
        widgetDescription: 'Výběr dopadu na zákazníka z rozbalovací nabídky.',
        widgetName: 'Nabídka dopadů na zákazníka',
        inWidgetTitle: 'Vyberte dopad na zákazníka',
      },
      regionSelect: {
        widgetDescription: 'Výběr regionů z rozbalovací nabídky.',
        widgetName: 'Nabídka regionů',
        inWidgetTitle: 'Vyberte region',
      },
      workReasonSelect: {
        widgetDescription: 'Výběr důvodů práce z rozbalovací nabídky.',
        widgetName: 'Nabídka důvodů práce',
        inWidgetTitle: 'Vyberte důvod práce',
      },

      // INVENTORY
      createDependentInstanceButton: {
        widgetDescription: 'Tlačítko pro vytvoření závislé instance.',
        widgetName: 'Tlačítko vytvořit závislou instanci',
        inWidgetTitle: '',
      },
      createNewInstanceConfDialog: {
        widgetDescription: 'Dialog pro vytvoření nové konfigurace instance.',
        widgetName: 'Dialog nové konf. instance',
        inWidgetTitle: 'Nová konfigurace instance',
      },
      instanceConfRelationshipBookmark: {
        widgetDescription:
          'Jednoduchý přehled vztahů konfigurací instance v tabulkovém zobrazení.',
        widgetName: 'Přehled vztahů konf. instance',
        inWidgetTitle: '',
      },
      instanceConfBookmark: {
        widgetDescription:
          'Jednoduchý přehled konfigurací instance v tabulkovém zobrazení.',
        widgetName: 'Přehled konf. instance',
        inWidgetTitle: '',
      },
      instanceConfList: {
        widgetDescription:
          'Seznam konfigurací instance v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam konf. instance',
        inWidgetTitle: 'Seznam konfigurací instance',
      },
      instanceConfTreeList: {
        widgetDescription: 'Hierarchický seznam konfigurací instance.',
        widgetName: 'Seznam (stromový) konf. instance',
        inWidgetTitle: 'Hierarchický seznam konfigurací instance',
      },
      instanceConfStatusBar: {
        widgetDescription:
          'Přehled aktuálního stavu konfigurace instance s možností rychlého přepnutí.',
        widgetName: 'Lišta stavů konf. instance',
        inWidgetTitle: '',
      },
      instanceConfCostCard: {
        widgetDescription: 'Karta zobrazující náklady konfigurace instance.',
        widgetName: 'Karta nákladů konf. instance',
        inWidgetTitle: 'Náklady konfigurace instance',
      },
      instanceConfSelect: {
        widgetDescription: 'Výběr konfigurace instance z rozbalovací nabídky.',
        widgetName: 'Nabídka konf. instance',
        inWidgetTitle: 'Vyberte konfiguraci instance',
      },
      productConfList: {
        widgetDescription:
          'Seznam konfigurací produktů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam konf. produktů',
        inWidgetTitle: 'Seznam konfigurací produktů',
      },
      ipAddressInput: {
        widgetDescription: 'Vstupní pole pro zadání IP adresy.',
        widgetName: 'Pole pro zadání IP adresy',
        inWidgetTitle: 'Zadejte IP adresu',
      },

      // CATALOG
      catalogSpecificationSelect: {
        widgetDescription: 'Výběr specifikace katalogu z rozbalovací nabídky.',
        widgetName: 'Nabídka specifikací katalogu',
        inWidgetTitle: 'Vyberte specifikaci katalogu',
      },
      catalogCategorySelect: {
        widgetDescription: 'Výběr kategorie katalogu z rozbalovací nabídky.',
        widgetName: 'Nabídka kategorií katalogu',
        inWidgetTitle: 'Vyberte kategorii katalogu',
      },
      catalogSelect: {
        widgetDescription: 'Výběr katalogu z rozbalovací nabídky.',
        widgetName: 'Nabídka katalogů',
        inWidgetTitle: 'Vyberte katalog',
      },
      catalogPrivilegeList: {
        widgetDescription:
          'Seznam oprávnění katalogu v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam oprávnění katalogu',
        inWidgetTitle: 'Seznam oprávnění katalogu',
      },
      catalogRelationList: {
        widgetDescription:
          'Seznam vztahů katalogu v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam vztahů katalogu',
        inWidgetTitle: 'Seznam vztahů katalogu',
      },
      priceCard: {
        widgetDescription: 'Karta zobrazující cenové detaily.',
        widgetName: 'Karta cen',
        inWidgetTitle: 'Cenové detaily',
      },

      // PROCESS
      processInstanceTasksBookmark: {
        widgetDescription:
          'Jednoduchý seznam úkolů instance procesu v tabulkovém zobrazení.',
        widgetName: 'Přehled úkolů instance procesu',
        inWidgetTitle: '',
      },
      taskHistoryBookmark: {
        widgetDescription:
          'Jednoduchý seznam historie úkolů v tabulkovém zobrazení.',
        widgetName: 'Přehled historie úkolů',
        inWidgetTitle: '',
      },
      taskLogsBookmark: {
        widgetDescription:
          'Jednoduchý seznam logů úkolů v tabulkovém zobrazení.',
        widgetName: 'Přehled logů úkolů',
        inWidgetTitle: '',
      },
      taskVariablesBookmark: {
        widgetDescription:
          'Jednoduchý seznam proměnných úkolů v tabulkovém zobrazení.',
        widgetName: 'Přehled proměnných úkolů',
        inWidgetTitle: '',
      },
      taskDetailsCard: {
        widgetDescription: 'Karta zobrazující podrobnosti o konkrétním úkolu.',
        widgetName: 'Karta podrobností úkolu',
        inWidgetTitle: 'Podrobnosti úkolu',
      },
      taskDefinitionSelect: {
        widgetDescription: 'Výběr definice úkolu z rozbalovací nabídky.',
        widgetName: 'Nabídka definic úkolů',
        inWidgetTitle: 'Vyberte definici úkolu',
      },
      processDefinitionSelect: {
        widgetDescription: 'Výběr definice procesu z rozbalovací nabídky.',
        widgetName: 'Nabídka definic procesů',
        inWidgetTitle: 'Vyberte definici procesů',
      },
      taskStatusSelect: {
        widgetDescription: 'Výběr stavu úkolu z rozbalovací nabídky.',
        widgetName: 'Nabídka stavů úkolů',
        inWidgetTitle: 'Vyberte stav úkolu',
      },
      taskStatusGroupSelect: {
        widgetDescription: 'Výběr skupiny stavů úkolu z rozbalovací nabídky.',
        widgetName: 'Nabídka skupin stavů úkolů',
        inWidgetTitle: 'Vyberte skupinu stavů úkolu',
      },
      taskActionTemplateSelect: {
        widgetDescription: 'Výběr šablony akce úkolu z rozbalovací nabídky.',
        widgetName: 'Nabídka šablon akcí úkolů',
        inWidgetTitle: 'Vyberte šablonu akce úkolu',
      },
      processGroupSelect: {
        widgetDescription: 'Výběr skupiny procesu z rozbalovací nabídky.',
        widgetName: 'Nabídka skupin procesu',
        inWidgetTitle: 'Vyberte skupinu procesu',
      },
      processTypeSelect: {
        widgetDescription: 'Výběr typu procesu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů procesu',
        inWidgetTitle: 'Vyberte typ procesu',
      },

      // DMS
      emailCommunicationPanel: {
        widgetDescription: 'Panel pro správu a zobrazení emailové komunikace.',
        widgetName: 'Panel emailové komunikace',
        inWidgetTitle: 'Emailová komunikace',
      },
      notificationList: {
        widgetDescription:
          'Seznam upozornění v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam upozornění',
        inWidgetTitle: 'Seznam upozornění',
      },
      commentsBookmark: {
        widgetDescription:
          'Jednoduchý přehled komentářů v tabulkovém zobrazení.',
        widgetName: 'Přehled komentářů',
        inWidgetTitle: 'Přehled komentářů',
      },
      worklogsBookmark: {
        widgetDescription:
          'Jednoduchý přehled pracovních záznamů v tabulkovém zobrazení.',
        widgetName: 'Přehled pracovních záznamů',
        inWidgetTitle: 'Přehled pracovních záznamů',
      },
      attachmentsBookmark: {
        widgetDescription: 'Jednoduchý přehled příloh v tabulkovém zobrazení.',
        widgetName: 'Přehled příloh',
        inWidgetTitle: 'Přehled příloh',
      },
      commentTypeSelect: {
        widgetDescription: 'Výběr typu komentáře z rozbalovací nabídky.',
        widgetName: 'Nabídka typů komentářů',
        inWidgetTitle: 'Vyberte typ komentáře',
      },
      notificationTemplateSelect: {
        widgetDescription: 'Výběr šablony upozornění z rozbalovací nabídky.',
        widgetName: 'Nabídka šablon upozornění',
        inWidgetTitle: 'Vyberte šablonu upozornění',
      },
      formaterDocumentTemplateSelect: {
        widgetDescription:
          'Výběr šablony dokumentu pro formátování z rozbalovací nabídky.',
        widgetName: 'Nabídka šablon dokumentů',
        inWidgetTitle: 'Vyberte šablonu dokumentu',
      },
      worklogList: {
        widgetDescription:
          'Seznam pracovních záznamů v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam pracovních záznamů',
        inWidgetTitle: 'Seznam pracovních záznamů',
      },

      // LISTING
      listingProfileSelect: {
        widgetDescription: 'Výběr profilu seznamu z rozbalovací nabídky.',
        widgetName: 'Nabídka profilů seznamu',
        inWidgetTitle: 'Vyberte profil seznamu',
      },
      listingColumnSelect: {
        widgetDescription: 'Výběr sloupce seznamu z rozbalovací nabídky.',
        widgetName: 'Nabídka sloupců seznamu',
        inWidgetTitle: 'Vyberte sloupec seznamu',
      },
      formSelect: {
        widgetDescription: 'Výběr formuláře z rozbalovací nabídky.',
        widgetName: 'Nabídka formulářů',
        inWidgetTitle: 'Vyberte formulář',
      },
      entityTypeSelect: {
        widgetDescription: 'Výběr typu entity z rozbalovací nabídky.',
        widgetName: 'Nabídka typů entit',
        inWidgetTitle: 'Vyberte typ entity',
      },
      listingTypeSelect: {
        widgetDescription: 'Výběr typu seznamu z rozbalovací nabídky.',
        widgetName: 'Nabídka typů seznamu',
        inWidgetTitle: 'Vyberte typ seznamu',
      },
      configurationProfilePanelSelect: {
        widgetDescription: 'Výběr profilu konfigurace v panelu.',
        widgetName: 'Panel nabídky konf. profilu',
        inWidgetTitle: 'Vyberte konf. profil ',
      },
      characteristicSelect: {
        widgetDescription: 'Výběr charakteristiky z rozbalovací nabídky.',
        widgetName: 'Nabídka charakteristik',
        inWidgetTitle: 'Vyberte charakteristiku',
      },

      // CALENDAR
      fullCalendarPanel: {
        widgetDescription:
          'Panel úplného kalendáře se všemi zobrazenými událostmi.',
        widgetName: 'Panel úplného kalendáře',
        inWidgetTitle: 'Úplný kalendář',
      },
      calendarEventList: {
        widgetDescription:
          'Seznam událostí kalendáře v tabulkovém zobrazení s možností filtrování a úprav.',
        widgetName: 'Seznam událostí kalendáře',
        inWidgetTitle: 'Události kalendáře',
      },
      calendarPanel: {
        widgetDescription: 'Panel zobrazující přehled kalendáře.',
        widgetName: 'Panel kalendáře',
        inWidgetTitle: 'Kalendář',
      },
      newCalendarButton: {
        widgetDescription: 'Tlačítko pro vytvoření nového kalendáře.',
        widgetName: 'Tlačítko nového kalendáře',
        inWidgetTitle: '',
      },
      calendarSubypeSelect: {
        widgetDescription: 'Výběr podtypu kalendáře z rozbalovací nabídky.',
        widgetName: 'Nabídka podtypů kalendáře',
        inWidgetTitle: 'Vyberte podtyp kalendáře',
      },
      calendarEventTypeSelect: {
        widgetDescription:
          'Výběr typu události kalendáře z rozbalovací nabídky.',
        widgetName: 'Nabídka typů událostí kalendáře',
        inWidgetTitle: 'Vyberte typ události',
      },
      calendarTemplateSelect: {
        widgetDescription: 'Výběr šablonu kalendáře z rozbalovací nabídky.',
        widgetName: 'Nabídka šablon kalendáře',
        inWidgetTitle: 'Vyberte šablonu kalendáře',
      },
      calendarEventConfCard: {
        widgetDescription:
          'Karta zobrazující podrobnosti konfigurace událostí kalendáře.',
        widgetName: 'Karta konfigurace událostí kalendáře',
        inWidgetTitle: 'Konfigurace události',
      },

      //ADDRESS MANAGEMENT

      regionLayerSelect: {
        widgetDescription: 'Výběr vrstvy regionu z rozbalovací nabídky.',
        widgetName: 'Nabídka vrstev regionu',
        inWidgetTitle: 'Vyberte vrstvu regionu',
      },
    },
    formSelection: 'Výběr formuláře',
    spelParserError: 'Chyba SPEL parseru',
    versionConflict: 'Upozornění: Formulář byl editován jiným uživatelem.',
    versionConflictMessage:
      'Vaše změny nelze uložit, protože by došlo k přepsání úprav provedených tímto uživatelem. ' +
      'Pro zachování vašich změn si otevřete formulář v novém okně a ručně zkopírujte své úpravy. ' +
      'Dbejte zvýšené opatrnosti, abyste nepřepsali změny provedené jiným uživatelem.',
    hashtagOption: 'Hash nápověda',
    hashtagOptionDescription: 'Zobrazí tikety nebo zavolá spel skript',
    suggestionScriptData: 'Skript pro našeptávání',
    clickScriptData: 'SKript po kliku',
    scriptData: 'Dodatečné parametry pro vyhodnocení skriptu',
    moreSelector: 'Selektor komponenty pro zobrazení více dat',
    templateCode: 'Kódy šablony',
    emailsNotificationsDescription:
      'Komponenta slouží k zobrazení emailového vlákna komunikace',
    successMessage: 'Success message',
    successMessageTooltip:
      'The message that appears after a successful button action',
    lazy: 'Load lazy - not loaded when hidden true.',
    loadNext: 'Load next',
    loadNextButton:
      'A "Load Form" button is displayed, and the form is not loaded until the button is clicked. (The inner form is not validated until it is loaded.)',
    disableValidations: 'Vypnout validace',
    newRecordConfig: {
      newRecordConfig: 'Objekt typu dialogu',
      title: 'Titulek k zobrazení',
      types: 'Typy',
      defaultType: 'Defaultní typ',
      createPrivilege: 'Oprávnění k vytvoření',
      asJson: 'Objekt jako JSON',
    },
    deleteValue: 'Smazat hodnotu',
    anchor: 'Kotva',
    submit: 'Uložit',
    submitButton: 'Tlačítko uložit',
    chars: 'Charakteristiky',
    sharingType: 'Typ sdílení',
    cancelService: 'Výběr služby',
    monacoDiffEditor: 'Monaco diff editor', // TODO dopln si sam
    modified: 'Nový',
    original: 'Původní',
    urlAbout: 'Vrací konfigurační uživatelské rozhraní',
    urlAttributeName: 'atribut',
    urlAttributeThe: 'The atribut',
    billingDataSourceAbout: 'Vrací fakturu podle účtu a kódu typu dokumentu',
    accountId: 'účetId',
    accountIdAbout: 'ID účtu',
    documentTypeName: 'kódTypuDokumentu',
    documentTypeNameAbout: 'Kód typu dokumentu',
    billingDate: 'Vrací datum plus pracovní dny',
    theDate: 'Datum',
    ticketEntityOne: 'Získává entitu tiketu podle ID',
    ticketEntityTwo: 'Získává související entitu podle ID tiketu',
    userEntityOne: 'Získává entitu uživatele podle ID',
    registerEntity: 'Získává entitu hodnoty registru podle ID',
    registerValidValue: 'Získává entitu hodnoty registru podle ID',
    filtersEntity: 'Filtry pro související entitu',
    codeRegister: 'Kód registru',
    codeRegisterValue: 'Kód hodnoty registru',
    codeRegisterValidValue: 'Kód platné hodnoty registru',
    userEntityId: 'Získává entitu uživatele podle ID',
    relatedEntityId: 'Získává související entitu podle ID',
    relatedEntityIdOne: 'relatedEntityId',
    relatedEntityIdAbout: 'ID související entity',
    ticketRelationship: 'Získává entitu vztahu tiketu podle ID',
    ticketIdOne: 'ticketId',
    theTicketIdOne: 'ID tiketu',
    registerCodeCam: 'registerCode',
    customerId: 'customerId',
    customerIdOfThe: 'ID zákazníka',
    entityIdAbout: 'Vrací konfiguraci instance entity podle ID',
    entityIdAboutThe: 'ID konfigurace instance entity',
    entityInstanceIdAboutThe: 'Vrací objednávku podle ID instance entity',
    entityInstanceId: 'ID instance entity',
    entityInstanceIdThe: 'ID instance entity',
    orderIdAbout: 'Vrací řádek objednávky podle ID',
    orderId: 'orderId',
    orderIdThe: 'ID objednávky',
    userGroupEntityById: 'Získává entitu uživatelské skupiny podle ID',
    codeGroupThe: 'Kód uživatelské skupiny',
    userGroupAbout: 'Získává entitu uživatelské skupiny podle kódu',
    idOfUserThe: 'ID uživatele',
    nonAlphanumeric: 'Pouze alfanumerické znaky jsou povoleny',
    chooseFilter: 'Potvrdit filtr - vybrat',
    componentPanel: 'Zobrazit/skrýt panel s komponentami',
    userDescription: 'Uživatel',
    userImage: 'Obrázek uživatele',
    userImageDescription: 'Obrázek uživatele',
    userImageComponent: 'Komponenta obrázku uživatele',
    userImageComponentDescription: 'Komponenta obrázku uživatele',
    userTypeNew: 'Typ uživatele',
    userTypeDescription: 'Typ uživatele',
    userTypeIcon: 'Ikona typu uživatele',
    userTypeIconDescription: 'Ikona typu uživatele',
    userGroupInfo: 'Informace o skupině uživatelů',
    userGroupInfoDescription: 'Informace o skupině uživatelů',
    privLoader: 'Načítání oprávnění',
    privLoaderDescription: 'Načítání oprávnění',
    userGroupInfoCode: 'Kód informace o skupině uživatelů',
    userGroupInfoCodeDescription: 'Kód informace o skupině uživatelů',
    privLoaderByCode: 'Načítání oprávnění podle kódu',
    privLoaderByCodeDescription: 'Načítání oprávnění podle kódu',
    statusIcon: 'Ikona stavu',
    statusIconDescription: 'Ikona stavu',
    statusIconByCode: 'Ikona stavu podle kódu',
    statusIconByCodeDescription: 'Ikona stavu podle kódu',
    severityIcon: 'Ikona závažnosti',
    severityIconDescription: 'Ikona závažnosti',
    typeIcon: 'Ikona typu',
    typeIconDescription: 'Ikona typu',
    ticketTypeByCode: 'Typ tiketu podle kódu',
    ticketTypeByCodeDescription: 'Typ tiketu podle kódu',
    ticketTypeById: 'Typ tiketu podle ID',
    ticketTypeByIdDescription: 'Typ tiketu podle ID',
    ropAddress: 'Adresa ROP',
    ropAddressDescription: 'Adresa ROP',
    ticketIdNew: 'ID tiketu',
    ticketIdDescription: 'ID tiketu',
    tabsDescription: 'Záložky',
    relatedEntityByIdNameIcon: 'Související entita podle ID, jména a ikony',
    relatedEntityByIdNameIconDescription:
      'Související entita podle ID, jména a ikony',
    relatedPartyParticipants: 'Účastníci související strany',
    relatedPartyParticipantsDescription: 'Účastníci související strany',
    ticketLink: 'Odkaz na tiket',
    ticketLinkDescription: 'Odkaz na tiket',
    adviceIcon: 'Ikona rady',
    adviceIconDescription: 'Ikona rady',
    ticketTaskLink: 'Odkaz na úkol tiketu',
    ticketTaskLinkDescription: 'Odkaz na úkol tiketu',
    ticketCategoryDescription: 'Kategorie tiketu',
    ticketCategoryCode: 'Kód kategorie tiketu',
    ticketCategoryCodeDescription: 'Kód kategorie tiketu',
    relatedEntityTypeByCode: 'Typ související entity podle kódu',
    relatedEntityTypeByCodeDescription: 'Typ související entity podle kódu',
    stockTaskLoader: 'Načítání skladových úloh',
    stockTaskLoaderDescription: 'Načítání skladových úloh',
    stockMovementStatus: 'Stav skladového pohybu',
    stockMovementStatusDescription: 'Stav skladového pohybu',
    slaLoader: 'Načítání SLA',
    slaLoaderDescription: 'Načítání SLA',
    externalLink: 'Externí odkaz',
    externalLinkDescription: 'Externí odkaz',
    filter: 'Filtr',
    filterDescription: 'Filtr',
    filterChars: 'Filter Chars',
    dataTagsArray: 'Pole datových štítků',
    dataTagsArrayDescription: 'Pole datových štítků',
    booleanTranslate: 'Překlad logické hodnoty',
    booleanTranslateDescription: 'Překlad logické hodnoty',
    booleanIcon: 'Ikona logické hodnoty',
    booleanIconDescription: 'Ikona logické hodnoty',
    enumToValue: 'Enum na hodnotu',
    enumToValueDescription: 'Enum na hodnotu',
    iconImg: 'Ikona obrázku',
    iconImgDescription: 'Ikona obrázku',
    clipboardName: 'Schránka',
    clipboardDescription: 'Schránka',
    datesDiff: 'Rozdíl mezi daty',
    datesDiffDescription: 'Rozdíl mezi daty',
    objectPathGet: 'Získání hodnoty cesty v objektu',
    objectPathGetDescription: 'Získání hodnoty cesty v objektu',
    descriptionDescription: 'Popis',
    numberDescription: 'Číslo',
    toFixed: 'Omezený počet desetinných míst',
    toFixedDescription: 'Omezený počet desetinných míst',
    configType: 'Typ konfigurace',
    configTypeDescription: 'Typ konfigurace',
    configTypeCode: 'Kód typu konfigurace',
    configTypeCodeDescription: 'Kód typu konfigurace',
    maintenanceActivityRelatedDirection: 'Směr související s údržbou',
    maintenanceActivityRelatedDirectionDescription:
      'Směr související s údržbou',
    selectedColumnsToString: 'Vybrané sloupce na řetězce',
    selectedColumnsToStringDescription: 'Vybrané sloupce na řetězce',
    linkHrefComponent: 'Odkaz Href Komponenta',
    linkHrefComponentDescription: 'Odkaz Href Komponenta',
    entityInstanceConfiguration: 'Konfigurace instance entity',
    entityInstanceConfigurationDescription: 'Konfigurace instance entity',
    entityInstConfStatusCode: 'Kód stavu konfigurace instance entity',
    entityInstConfStatusCodeDescription:
      'Kód stavu konfigurace instance entity',
    operationImpactByCode: 'Dopad operace podle kódu',
    operationImpactByCodeDescription: 'Dopad operace podle kódu',
    arrayToString: 'Pole na řetězec',
    arrayToStringDescription: 'Pole na řetězec',
    userFromSystem: 'Výběr uživatele ze systému',
    userRoleFromSystem: 'Výběr uživatelské role ze systému',
    userGroupList: 'Správa uživatelů - Výběr uživatelské skupiny',
    userGroupType: 'Typ uživatelské skupiny',
    userGroupTypeList: 'Výběr typu uživatelské skupiny',
    ticketRelationshipList: 'Seznam vztahů tiketů',
    ticketHistory: 'Historie tiketu',
    adviceList: 'Seznam avíz',
    relatedEntityList: 'Seznam souvisejících entit',
    ticketTaskList: 'Seznam úkolů tiketu',
    ticketList: 'Seznam tiketů',
    ticketListRelated: 'Seznam tiketů podle související entity',
    ticketCategory: 'Kategorie tiketu',
    nameUserSelection: 'Výběr uživatele ze systému',
    noticeType: 'Typ avíza',
    nameProblemTicket: 'Problém',
    nameProblemTicketTree: 'Problém - stromeček',
    nameTicketSolution: 'Řešení',
    nameTicketLov: 'Ticket',
    descriptionTicketStatus: 'Výběr stavu z ticketingu / change managementu',
    descriptionTicketRole: 'Výběr role z ticketingu / change managementu',
    descriptionTicketPriority:
      'Výběr priority z ticketingu / change managementu',
    descriptionTicketSeverity:
      'Výběr severity z ticketingu / change managementu',
    nameRelatedRequestEntity: 'Související entita požadavku',
    descriptionRelatedRequestEntity:
      'Výběr související entity konkrétního požadavku z change managementu',
    titleRelatedEntity: 'Související entita',
    nameTicketType: 'Typ tiketu',
    descriptionTicketType: 'Typ ticketu/change requestu',
    nameTemplateType: 'Typ šablony tiketu',
    descriptionTemplateType: 'Typ šablony tiketu',
    nameRelatedEntityIcon: 'Ikona související entity',
    nameActiveAdvice: 'Aktivní avíza',
    nameRegistryTicketCategory: 'Ticketing - Kategorie tiketu z registru',
    descriptionRelatedEntityDetailRefData:
      'Detailní reference související entity',
    descriptionTicketReleaseButton: 'Ticketing - Ticket release tlačítko',
    nameReleaseButton: 'Ticketing - Release tlačítko',
    nameRelatedEntityType: 'Typ související entity',
    descriptionRelatedEntityType:
      'Výběr typu související entity ze change managementu',
    nameSamplingChannel: 'Kanál',
    descriptionSamplingChannel: 'Náběrový kanál change managementu',
    descriptionTicketBindings: 'Seznam vazeb tohoto tiketu',
    nameStatus: 'Status ticketu',
    priority: 'Priorita',
    severity: 'Závažnost',
    registerType: 'Typ registry kategorie ticketu/change requestu',
    pivotReporting: 'Pivot reporting',
    chartReporting: 'Chart reporting',
    openDevTerminal: 'Otevření vývojářské konzole',
    scriptLov: 'Výběr skriptu',
    stockItem: 'Skladová položka',
    stockIndex: 'Sklad - obsah',
    taskDefinitionLOV: 'Definice tasku',
    taskDefinition: 'Definice úlohy',
    bookmarkQuotes: 'Bookmark nabídky',
    orderSteps: 'Lišta stavů objednávky',
    campaignStatusSteps: 'Lišta stavů kampaně',
    logListing: 'Bookmark řádků objednávky',
    logList: 'Seznam logů',
    viewComp: 'Komponenta pro zobrazení dat',
    filterWidget: 'Listing - Filtr',
    lovListing: 'Listing type',
    lovListingDescription: 'Komponenta sloužící pro výběr typu seznamu.',
    processInstanceTasks: 'Tasky instance procesu',
    taskHistory: 'Historie tasku',
    taskLogs: 'Logy tasku',
    taskVariables: 'Proměnné tasku',
    taskDetails: 'Detail tasku',
    ipAddress: 'IP adresa',
    createButtonEntity: 'Tlačítko pro vytvoření závislé instance entity',
    newEntity: 'Konfigurace nové instance entity',
    relationshipEntity: 'Konfigurace vztahů instance entity',
    redirectToDetail: 'Přesměrovat na detail',
    instanceEntity: 'Konfigurace seznamového panelu instance entity',
    instanceEntityTree: 'Konfigurace stromové struktury instance entity',
    instanceEntitySteps: 'Konfigurace kroků instance entity',
    instanceEntityCost: 'Náklady na konfiguraci instance entity',
    instanceEntityConf: 'Konfigurace instance entity',
    instanceEntityList: 'Seznam konfigurací instance entity',
    instanceEntityListNew: 'Seznam konfigurací instance entity',
    aboutApp: 'O Aplikaci',
    addToBookmarks: 'Přidat do záložek',
    nextActions: 'Další akce',
    toDetailForm: 'Konfigurace formuláře',
    toSpelConsole: 'Spel konzole',
    toPythonConsole: 'Python konzole',
    toAnsibleConsole: 'Ansible konzole',
    lastActive: 'Naposledy aktivní',
    quickMessage: 'Poslat rychlou zprávu v rámci aplikace tSM',
    teamsAppCall: 'Zavolat přes externí aplikaci Microsoft Teams',
    header: 'Header',
    size: 'Velikost',
    height: 'Výška',
    width: 'Šířka',
    offset: 'Odsazení',
    compact: 'Compact',
    layout: 'Layout',
    json: 'Json',
    monacoEditor: 'Monaco editor',
    sum: 'Celkem',
    budget: 'Rozpočet',
    relationshipParentId: 'Relationship parent id',
    relationshipParentCode: 'Relationship parent code',
    relationshipTypeCodes: 'Relationship type code',
    relationshipAddWithParent: 'Relationship add with parent',
    entityType: 'Typ entity',
    entityTypeDescription: 'Komponenta sloužící pro výběr typu entity.',
    excludeIds: 'ID k vynechání',
    excludeCodes: 'Kódy k vynechání',
    lifecycleStatusCode: 'Lifecycle status code',
    lifeCycleInfo:
      'Toto pole označuje aktuální fázi v životním cyklu položky nebo entity.',
    productCodes: 'Kódy produktů',
    productTypeCodes: 'Typ kódu produktů',
    catalogLifecycleStatusCode: 'Catalog lifecycle status code',
    catalogChars: 'Charakteristiky katalogu',
    excludeCategoryCodes: 'Vynechat kódy kategorií',
    categoryCodes: 'Kódy kategorií',
    displayField: 'Atribut(y) k zobrazení',
    defaultPolicy: 'Defaultní policy',
    policy: 'Policy',
    context: 'Kontext',
    savePng: 'Uložit jako PNG',
    saveSvg: 'Uložit jako SVG',
    contextMenu: 'Kontextové menu',
    table: 'Tabulka',
    advanced: 'Pokročilé',
    days: 'Dny',
    hours: 'Hodiny',
    minutes: 'Minuty',
    seconds: 'Sekundy',
    miliseconds: 'Milisekundy',
    more: 'Více',
    massEvents: 'Hromadné akce',
    relation: 'Relace',
    operationalRelationUsage: 'Provozní využití relace',
    significantRelation: 'Významná relace',
    threads: 'Vlákna',
    importData: 'Importovat data',
    parentUserGroup: 'Nadřazená skupina',
    privilege: 'Oprávnění',
    hidden: 'Skrýt',
    associatedRole: 'Přiřazená role',
    exportPrivilege: 'Právo pro export',
    exportedRecords: 'Exportované záznamy',
    specificationCode: 'Kód specifikace',
    whenEdited: 'Upraveno',
    whenInserted: 'Vytvořeno',
    whenUploaded: 'Nahráno',
    whenImported: 'Importováno',
    whoUploaded: 'Nahrál',
    whoEdited: 'Upravil',
    whoInserted: 'Vytvořil',
    public: 'Veřejný',
    shareBy: 'Sdíleno podle',
    billing: 'Fakturace',
    expandAll: 'Rozbalit vše',
    publicTooltip: 'Sdílet se všemi uživateli',
    sharing: 'Sdílet s uživateli / skupinami uživatelů',
    listingId: 'Listing ID',
    showAll: 'Zobrazit vše',
    showLess: 'Zobrazit méně',
    refreshData: 'Obnovit data',
    redrawData: 'Překreslit data',
    redrawDataTooltip:
      'Aplikuje se nová konfigurace a dojde k novému vykreslení',
    noRecords: 'Žádné záznamy',
    dataBoxId: 'Identifikátor datové schránky',
    isValid: 'Validní',
    validation: 'Validace',
    validationMessage: 'Validační zpráva',
    validationExpression: 'Validační výraz',
    campaign: 'Kampaň',
    campaignWaveDefinition: 'Definice vlny kampaně',
    campaignWaveList: 'Seznam vln kampaně',
    campaignType: 'Typ kampaně',
    tickets: 'Tikety',
    download: 'Stáhnout',
    responseDownload: 'Stáhnout odpověď',
    requestDownload: 'Stáhnout požadavek',
    operation: 'Operace',
    arDocuments: 'Doklady',
    clipboard: 'Hodnota byla vložena do schránky.',
    log: 'Log',
    gallery: 'Galerie',
    galleryTooltip:
      'Galerie umožňuje nahrávat a zobrazovat grafické soubory, je také možné měnit téma zobrazení.',
    fileUpload: 'Nahrání souboru',
    fileUploadFailed: 'Nepovedlo se nahrát soubor',
    fileUploadTooltip: 'Komponenta umožňuje nahrát soubor k entitě.',
    dropFile:
      'Přetáhněte soubory, které chcete připojit, nebo je vyberte z počítače',
    directoryStructure: 'Adresářová struktura',
    showSameLocation: 'Pouze se stejnou lokací',
    newTag: 'Nový tag',
    coefficient: 'Koeficient',
    workEstimate: 'Odhad pracnosti',
    worklog: 'Záznam práce',
    expression: 'Výraz',
    expressionEditor: 'Editor výrazů',
    scriptFormat: 'Formát skriptu',
    script: 'Skript',
    clickScript: 'Skript po kliknutí',
    suggestionsScript: 'Skript pro našeptávání',
    scriptResult: 'Výsledek skriptu',
    collection: 'Vstupní kolekce',
    elementVariable: 'Proměnná instance',
    events: 'Události',
    action: 'Akce',
    actions: 'Akce',
    actionsAfterSuccess: 'Akce po úspěchu',
    actionsAfterError: 'Akce po chybě',
    done: 'Hotovo',
    supervisor: 'Nadřízený',
    configEntityTypeHeader: 'Typ Entity',
    indexMappingHeader: 'ES typ mapování',
    indexName: 'Název indexu',
    formTypeHeader: 'Typ Formuláře',
    finished: 'Ukončen',
    history: 'Historie',
    signature: 'Podpis',
    signatureDescription:
      'Widget typu Podpis nabízí možnost vytvoření podpisu podepsáním přímo v zařízení.',
    awareness: 'Awareness',
    attachments: 'Přílohy',
    attachmentsTypes: 'Povolené typy příloh',
    attachmentsTypesDescription:
      'Výčet kódů povolených typů příloh, pod kterými lze přílohu uložit (formát: ["kodtypu1", "kodtypu2"])',
    attachmentTypeAllowEmpty: 'Povolit prázdný typ přílohy',
    attachmentTypeAllowEmptyDescription:
      'V případě, že jsou povolené pouze vybrané typy příloh, umožní zaškrtnutí této volby, uložit přílohu také BEZ typu.',
    acceptDocumentTypes: 'Povolené typy souborů',
    acceptDocumentTypesDescription: {
      option1: 'Povoleny všechny typy souborů: nevyplněno, *, /*',
      option2: 'Povoleny pouze obrázky: image/*',
      option3: 'Povoleny vyjmenované typy souborů: .jpg, .xlsx',
    },
    aplication: 'Aplikace',
    condition: 'Podmínka',
    abbreviation: 'Zkratka',
    code: 'Kód',
    codeInfo:
      'Kód představuje identifikaci tohoto záznamu a musí být unikátní. ' +
      'Kód je dále používán v dalších konfiguracích a je vystaven na API, proto může jeho ' +
      'změna vyvolat potřebu změn dalších konfiguracích nebo dokonce i návazných systémů.' +
      'Pro kód doporučujeme využívat "dot notation" (např. "Finance.Vymahani") bez českých znaků.',
    mnemonicCode: 'Kód mnemoniky',
    mnemonicsInfo:
      'Mnemoniky umožňují automatické generování hlavního identifikátoru záznamu - např. ' +
      '“OUT-45531” (Outage tiket) “FIN-5135” (Finance tiket), “SUP-5131” (Support tiket) nebo ' +
      'složitější “INV 2023/155” (Invoice 155 v roce 2023). Definice mnemoniky typicky obsahuje ' +
      'prefix a číslo přiřazené ze sekvence, může ale obsahovat i komplexní výraz pro přidělení ' +
      'identifikátoru na základě dat z vytvářeného záznamu.',
    comment: 'Komentář',
    comments: 'Komentáře',
    commentTypes: 'Typ komentáře',
    commentsTypes1: 'Typy komentářů',
    commentTemplate: 'Šablona komentáře',
    commentsTypes: 'Povolené typy komentářů',
    commentTypeAllowEmpty: 'Povolit prázdný typ komentáře',
    defaultCommentType: 'Výchozí typ komentáře',
    filterByCodes: 'Filtrovat podle kódů',
    worklogs: 'Logy práce',
    worklogList: 'Seznam logů práce',
    worklogLocalityList: 'Seznam lokalit logů práce',
    showWorkLogs: 'Zobrazit logy práce',
    worklogTypes: 'Povolené typy logů práce',
    worklogTypeAllowEmpty: 'Povolit prázdný typ logu práce',
    defaultWorklogType: 'Výchozí typ logu práce',
    worklogStopwatchStartButton: 'Tlačítko spuštění stopek',
    worker: 'Pracovník',
    ticketDetail: 'Detail tiketu',
    entitySpecification: 'Konfigurační profil',
    entitySpecificationType: 'Typ konfiguračního profilu',
    entitySpecificationInfo:
      'Konfigurační profil umožňuje definovat dodatečné atributy, vlastní layout formuláře, povolené typy komentářů apod. ' +
      'Konfigurační profily mohou být pro zjednodušení sdílené mezi více typy.',
    createAutomatically: 'Vytvořit automaticky',
    createAutomaticallyTooltip:
      'Automaticky bude vytvořen nový záznam. Pokud chcete použít již existující záznam, zvolte možnost "Vybrat ze stávajících"',
    createAutomaticallyWithPrefix: 'Vytvořit automaticky s prefixem',
    createAutomaticallyWithPrefixTooltip:
      'Automaticky bude vytvořen nový záznam se zadaným prefixem. Pokud chcete použít již existující záznam, zvolte možnost "Vybrat ze stávajících"',
    chooseFromExisting: 'Vybrat ze stávajících',
    activityGenerationTime: 'Doba generování aktivity',
    cancelPlannedActivityAfter: 'Zrušit plánovanou aktivitu po počtu dní',
    createMaintenancePlanTemplate: 'Vytvořit šablonu plánu údržby',
    planningFromElementInventoryPermitted:
      'Povolit plánování z Inventory Prvku',
    nothingFound: 'Nic nenalezeno.',
    date: 'Datum',
    dates: 'Datumy',
    dateFrom: 'Datum od',
    dateTo: 'Datum do',
    createDate: 'Datum vytvoření',
    lastChangeDate: 'Datum poslední změny',
    validity: 'Platnost',
    validityFrom: 'Platnost od',
    validityTo: 'Platnost do',
    primaryMicroservice: 'Primární Microservice',
    microservice: 'Microservice',
    invalidate: 'Ukončit platnost',
    invalidated: 'Zneplatněn',
    find: 'Vyhledat',
    send: 'Odeslat',
    create: 'Vytvořit',
    createCopy: 'Vytvořit kopii',
    createTooltip: 'Vytvořit (Shift+n nebo n)',
    createCopyTooltip: 'Vytvořit kopii (Shift+c)',
    createTemplate: 'Vytvořit šablonu',
    createTemplateTooltip: 'Vytvořit šablonu (Shift+n nebo n)',
    createWizard: 'Založení PEW',
    createWizardTooltip: 'Založení PEW (Shift+n nebo n)',
    createFromAres: 'Vytvořit z Aresu',
    createFromAresTooltip: 'Vytvořit z Aresu (Shift+a nebo a)',
    createdBy: 'Vytvořil',
    createNewCustomer: 'Vytvořit nového zákazníka',
    approvedUser: 'Schválil',
    created: 'Vytvořeno',
    createdByGroup: 'Vytvořeno skupinou',
    close: 'Zavřít',
    closeBulkEdit: 'Zavřít hromadnou editaci',
    delete: 'Smazat',
    deleteAll: 'Smazat vše',
    remove: 'Odebrat',
    pinTopbar: 'Připnout do topbaru',
    unpinTopbar: 'Odepnout z topbaru',
    migrate: 'Zmigrovat',
    deleteTooltip: 'Smazat vybraný záznam',
    deleteEvent: 'Smazat událost',
    copy: 'Kopie',
    paste: 'Vložit',
    dropComponent: 'Vložte komponentu',
    reverseRel: 'Otočení vazby',
    confirmation: 'Potvrzeni',
    deleteConfirmation: 'Potvrdit smazání',
    deleteRecordConfirm: 'Opravdu chcete smazat tento záznam?',
    deleteConfirmationMessage:
      'Opravdu chcete záznam smazat? Může dojít ke smazání některých podřízených záznamů napříč aplikací.',
    decompose: 'Dekomponovat',
    discardAllChanges: 'Zahodit všechny změny',
    save: 'Uložit',
    saveDraft: 'Uložit jako náhled',
    savedDraft: 'Uloženo jako náhled',
    saveOther: 'Uložit a další',
    saveDetail: 'Uložit a přejít na detail',
    saveForm: 'Uložit formulář',
    testData: 'Testovací data',
    storno: 'Storno',
    succSave: 'Ukládání proběhlo úspěšně',
    unSuccSave: 'Během ukládání došlo k chybě',
    unSuccClone: 'Během klonování došlo k chybě',
    succDiff: 'Ukládání rozdílů proběhlo úspěšně',
    unSuccDiff: 'Během ukládání rozdílů došlo k chybě',
    performChanges: 'Provést změny',
    resetChanges: 'Vrátit změny',
    choose: 'Vybrat',
    confirm: 'Potvrdit',
    confirmGeneric: 'Potvrdit akci',
    confirmGenericMessage: 'Opravdu chcete provést vybranou akci?',
    confirmCloseWindow: 'Opravdu chcete zavřít okno?',
    selection: 'Výběr',
    selected: 'Vybraných',
    beginning: 'Začátek',
    startOfFailure: 'Začátek poruchy',
    end: 'Konec',
    pause: 'Pauza',
    object: 'Předmět',
    objectLayout: 'Rozložení objektu',
    objectAsObject: 'Objekt',
    entity: 'Entita',
    persIdentNum: 'IČO',
    taxIdentNum: 'DIČ',
    legalForm: 'Právní forma',
    legalForms: 'Právní formy',
    segment: 'Segment',
    leadSource: 'Zdroj leada',
    leadType: 'Typ leada',
    inputting: 'Vstupní',
    inputParameters: 'Vstupní parametry',
    outputting: 'Výstupní',
    outputParameters: 'Výstupní parametry',
    department: 'Oddělení',
    selectAction: 'Vybrat akci',
    businessKey: 'Business key',
    module: 'Modul',
    tsmModuleInfo:
      'Pomocí modulů můžete dále rozdělit tikety do skupin dle chování. ' +
      'Typické moduly jsou např. "Tiketing", "Change management", "L2 Support" apod. ' +
      'K modulu se často váže speciální customizace tvořená na míru. Pokud nemáte speciální požadavek, ' +
      'ponechte defaultní modul "Tiketing".',
    moduleType: 'Typ modulu',
    description: 'Popis',
    documentation: 'Dokumentace',
    documents: 'Dokumenty',
    link: 'Vazba',
    links: 'Vazby',
    skills: 'Dovednosti',
    privs: 'Oprávnění',
    parentPriv: 'Prefix oprávnění',
    parentPrivTooltip:
      'Nadřazené oprávnění použité pro ostatní oprávnění widgetu.',
    edit: 'Upravit',
    editTooltip: 'Přejít na detail vybraného záznamu',
    edited: 'Upraveno',
    massEdit: 'Hromadná editace',
    takeOver: 'Převzít',
    passOn: 'Předat',
    process: 'Proces',
    defaultProcess: 'Defaultní proces',
    defaultProcessInfo:
      'Proces určuje jakým způsobem bude tiket řešen. V rámci procesu můžete přidělovat úkoly, ' +
      'automatizovat řešení, přidávat komentáře a mnoho dalšího. Pro každý typ tiketu můžete mít jiný proces, ' +
      'proces lze také vybrat dynamicky pomocí SPEL výrazu na základě obsahu tiketu.',
    defaultProcessGenericInfo:
      'Proces určuje jakým způsobem bude entita řešena. V rámci procesu můžete přidělovat úkoly, ' +
      'automatizovat řešení, přidávat komentáře a mnoho dalšího. Pro každou entitu můžete mít jiný proces, ' +
      'proces lze také vybrat dynamicky pomocí SPEL výrazu na základě obsahu entity.',
    dmn: 'DMN',
    tasks: 'Tasky',
    processType: 'Typ procesu',
    processCreation: 'Vytvoření procesu',
    processSelection: 'Výběr procesu',
    companyType: 'Typ společnosti',
    message: 'Zpráva',
    resolved: 'Vyřešeno',
    messages: 'Zprávy',
    messagesHotkeyText: 'Zobrazit zprávy',
    error: 'Chyba',
    compiled: 'Kompilace',
    run: 'Spustit',
    runSelected: 'Spustit vybrané',
    errorOccured: 'Chyba při načítání dat',
    errors: 'Chyby',
    signals: 'Signály',
    escalations: 'Eskalace',
    editedBy: 'Upravil',
    name: 'Jméno',
    fullName: 'Celé jméno',
    title: 'Název',
    titleGenericInfo:
      'Zvolte krátký název tohoto záznamu. Zobrazuje se v seznamech, v záhlaví formulářů, dá se podle něj také vyhledávat a reportovat.',
    titleForTypeInfo:
      'Zvolte krátký název tohoto typu. Zobrazuje se v seznamech, v záhlaví formulářů, ' +
      'dá se podle něj také vyhledávat a reportovat. ',
    createPrivilege: 'Privilegium pro vytvoření',
    sectionTitle: 'Nadpis sekce',
    fieldsetLegend: 'Legenda (Nadpis)',
    panelTitle: 'Nadpis panelu',
    parent: 'Nadřazený',
    readPrivilege: 'Read privilege',
    writePrivilege: 'Write privilege',
    note: 'Poznámka',
    slaHeader: 'SLA',
    impactOnSla: 'Dopad na SLA',
    problem: 'Problém',
    partyName: 'Potenciální zákazník',
    nextInformation: 'Další informace',
    additionalInformation: 'Dodatečné informace',
    additionalServices: 'Doplňkové produkty',
    layer: 'Vrstva',
    type: 'Typ',
    selectType: 'Typ selectu',
    radioButtonsDirection: 'Směr radio buttonů',
    typeInfo:
      'Vyberte, zda se bude jednat o Osobu, Organizaci nebo Pracovní skupinu.',
    change: 'Změna',
    subtype: 'Podtyp',
    state: 'Stav',
    key: 'ID',
    stateCode: 'Kód stavu',
    showCalendar: 'Ukaž kalendář',
    filters: 'Filtry',
    selectItemFilters: 'Filtry pro výběr položek',
    selectItemFiltersTooltip:
      'Jedná se o filtr, který hlídá, zda má být vybraná hodnota povolena vybrat. Pokud je filtr prázdný, mohou se vybrat všechny hodnoty.',
    messageFromInvalidSelectItem: 'Vlastní zprava pro neplatný výběr položky',
    messageFromInvalidSelectItemTooltip:
      'Tato zpráva se zobrazí, pokud uživatel vybere položku, která nesplňuje podmínky uvedené v atributu "Filtry pro výběr položek".',
    showConfig: 'Konfigurace zobrazení listingu',
    listing: 'Listing',
    checkSelectedItemByFilters:
      'Není možné vybrat tuto položku "{{name}}", protože nesplňuje podmínky uvedené v atributu "Filtry pro výběr položek".',
    rowsCount: 'Počet řádků',
    pageSize: 'Počet řádků na stránku',
    tableHeightEnableMin: 'Zapnout výšku tabulky jako minimální výšku',
    tableHeightEnableMinDescription:
      'S žádnými daty se výška tabulky také aplikuje',
    tableHeight: 'Výška tabulky',
    tableHeightTooltip:
      'Změnu výšky tabulky provedete zadáním číselné hodnoty včetně požadovaných jednotek (px, em, rem, %, vh)',
    selectedColumns: 'Vybrané sloupce',
    sortOrder: 'Pořadí řazení',
    sortField: 'Řadící sloupec',
    userId: 'Id uživatele',
    isDefault: 'Defaultní',
    exports: 'Exporty',
    cancelExport: 'Zrušit export',
    exportSettings: 'Nastavení exportu',
    showExport: 'Zobrazit tlačítko pro export',
    showFilters: 'Zobrazit filtr',
    showRefresh: 'Zobrazit tlačítko pro refresh',
    showPaginator: 'Zobrazit stránkování',
    showManagerColumns: 'Zobrazit správce sloupců',
    showProfiles: 'Zobrazit profily',
    showManagerSort: 'Zobrazit správce řazení',
    showManagerColor: 'Zobrazit správce barev',
    showListingConfig: 'Zobrazit konfiguraci listingu',
    hiddenDataViewMode: 'Skrýt možnosti zobrazení dat',
    hideButtonGroup: 'Schovat sekci tlačítek',
    tableDataViewMode: 'Režim zobrazení dat tabulky',
    scrollable: 'Zapnout posuvníky',
    anchorHeader: 'Ukotvit záhlaví tabulky',
    anchorHeaderHelpMessage:
      'Záhlaví sloupců seznamu bude při posouvání vždy vidět',
    extendTqlWhere: 'Rozšíření podmínky v TQL',
    externalTqlSort: 'Vlastní řazení v TQL',
    externalTqlSortForNested: 'Vlastní řazení v TQL pro NESTED atributy',
    colors: 'Barvy',
    colorCode: 'Kód barvy',
    url: 'URL adresa',
    target: 'Target',
    local: 'Local',
    localization: 'Lokalizace',
    specificationState: 'Stav specifikace',
    paymentBehaviour: 'Platební morálka',
    orderDate: 'Datum objednávky',
    assignedTo: 'Přiřazeno na',
    deliveryDateRequested: 'Požadované datum realizace',
    deliveryDate: 'Skutečné datum realizace',
    deliveredDate: 'Skutečné datum realizace',
    requestedDate: 'Požadované datum',
    plannedEndDate: 'Plánováno do',
    plannedEnd: 'Plánovaný konec',
    plannedStartDate: 'Plánováno od',
    stateGroup: 'Skupina stavu',
    stateManagement: 'Správa stavu',
    channel: 'Kanál',
    form: 'Formulář',
    forms: 'Formuláře',
    formSelectHint:
      'Použitý formulář pro vykreslení. Pokud není zadán, provede se vykreslení s automatickým layoutem na základě aktuálních dat.',
    term: 'Termín',
    lead: 'Lead',
    userName: 'Uživatelské jméno',
    selectLanguage: 'Vyberte jazyk',
    czechLanguage: 'Čeština',
    englishLanguage: 'Angličtina',
    deutschLanguage: 'Němčina',
    costCoefficient: 'Cenový koeficient',
    user: 'Uživatel',
    users: 'Uživatelé',
    userUserGroup: 'Uživatel / Skupina',
    customer: 'Zákazník',
    customerReport: 'Zákazník - report',
    customerList: 'Seznam zákazníků',
    productCards: 'Karty výrobků',
    customerType: 'Typ zákazníka',
    company: 'Společnost',
    firm: 'Firma',
    details: 'Detaily',
    emergency: 'Emergency',
    detail: 'Detail',
    rating: 'Hodnocení',
    button: 'Tlačítko',
    clipboardButton: 'Tlačítko kopírování do schránky',
    backButton: 'Tlačítko zpět',
    nextButton: 'Tlačítko další',
    version: 'Verze',
    valuesSel: 'Výběr hodnot',
    none: 'none', // nechat anglicky
    color: 'Barva',
    consents: 'Souhlasy',
    telephone: 'Tel. číslo',
    mobilePhone: 'Mobilní telefon',
    partyRole: 'Typ účastníka',
    responsiblePersons: 'Odpovědné osoby',
    contactPerson: 'Kontaktní osoba',
    person: 'Osoba',
    givenName: 'Křestní jméno',
    salutation: 'Oslovení',
    taskStatusGroup: 'Skupina stavu tasku',
    taskPlannedChar: 'Typ plánovaného úkolu',
    changeTaskStatus: 'Změna stavu tasku: {{from}} -> {{to}}',
    completionTask: 'Ukončení tasku',
    taskDetail: 'Detail tasku',
    taskName: 'Název úkolu',
    service: 'Služba',
    class: 'Třída',
    serviceName: 'Název služby',
    catalogItemName: 'Název v katalogu',
    group: 'Skupina',
    people: 'Lidé',
    gender: 'Pohlaví',
    deployJust: 'Právě nasazeno',
    parameters: 'Parametry',
    variables: 'Proměnné',
    jobConfiguration: 'Konfigurace jobu',
    contract: 'Account',
    ticket: 'Tiket',
    ticketing: 'Tiketing',
    emails: 'Emaily',
    session: 'Session emailů',
    incident: 'Incident',
    massAssume: 'Hromadné převzetí',
    massHandover: 'Hromadné předání',
    processDefinition: 'Definice procesu',
    massMigrationProcess: 'Hromadná migrace instance procesu',
    massModificationProcess: 'Hromadná modifikace procesního tasku',
    category: 'Kategorie',
    categoryType: 'Typ kategorie',
    vrf: 'VFR',
    designation: 'Označení',
    labels: 'Štítky',
    label: 'Štítek',
    labelDesc: 'Popisek',
    uiConfig: 'UI Konfigurace',
    config: 'Typ konfigurace',
    configLabel: 'Konfigurace',
    tqlScript: 'TQL skript',
    spelScript: 'SPEL skript',
    usage: 'Použití',
    price: 'Cena',
    pricelist: 'Sazebník',
    catalogPrice: 'Katalogová cena',
    iconPicker: 'Nabídka ikon',
    searchIcon: 'Vyhledat ikonu',
    search: 'Vyhledat',
    iconCopiedSummary: 'Ikona zkopírována do schránky!',
    imageCopiedSummary: 'Obrázek zkopírován do schránky!',
    closeDialogAfterSelection: 'Zavřít dialog ihned po výběru položky',
    stacktrace: 'stacktrace',
    tqlName: 'TQL název',
    routerLink: 'Odkaz na router v aplikaci',
    worklogDuration: 'Doba práce',
    worklogDurationInput: 'Vstup doby práce',
    currency: {
      currency: 'Měna',
      CZK: 'Kč',
    },
    vatAmount: 'Sazba DPH',
    vatRange: 'Pásmo DPH',
    withVAT: 's DPH',
    withoutVAT: 'bez DPH',
    recurrences: 'Účt. období',
    startingRecurrence: 'První účt. perioda',
    listPriceLimit: 'Limit od',
    listPriceLimitMax: 'Limit do',
    chargingClass: 'Charging class',
    ares: 'Dotáhnout data',
    saveTemplate: 'Uložit jako šablonu',
    share: 'Sdílení',
    createEmptyTemplate: 'Vytvořit šablonu',
    createEmptyTemplateTooltip: 'Vytvořit šablonu (Shift+t nebo t)',
    restartPeriod: 'Interval restartu',
    mustBeZeroToHundred: 'Toto pole musí obsahovat číslo v rozsahu 0...100',
    valueMustBeAboveZero: 'Hodnota musí být větší než 0',
    privateComment: 'Interní',
    commentUserType: {
      INDIVIDUAL: 'Uživatelský',
      ORGANIZATION: 'Systémový',
    },
    userCommentType: 'Typ',
    publicComment: 'Veřejný',
    relatedParties: 'Účastníci',
    relatedEntity: 'Related entity',
    relatedPartyTypes: 'Typy účastníků',
    relatedEntityTypeChars: 'Konfigurační profil',
    relatedEntityTargetType: 'Typ cílové entity',
    chooseType: 'Zvolte typ',
    isPlaceOfEntity: 'Je umístěním entity',
    individualPrice: 'Individuální cena',
    participant: 'Účastník',
    participantRef: 'Dms - Účastník (sharing-ref)',
    diagramSource: 'Zdroj diagramu',
    protocol: 'Protokol',
    previewProtocol: 'Náhled protokolu',
    sendProtocol: 'Odeslat protokol',
    saveProtocol: 'Uložit protokol(y)',
    settings: 'Nastavení',
    settingsButton: 'Tlačítko nastavení',
    refreshButton: 'Tlačítko obnovení',
    vatRangeCode: {
      EXE: 'Osvobozeno',
      STD: 'Základní',
      '1RED': 'První snížená',
      '2RED': 'Druhá snížená',
      REVERSE: 'Reverse',
      EXE_3RD_PARTY: 'Osvobozeno třetí stranou',
      STDH: 'Základní - Historie',
    },
    sharedComp: {
      editBlock: 'Upravit blok',
      templateRequired: 'Tato komponenta musí obsahovat template',
      hidePassword: 'Skrýt heslo',
      showPassword: 'Zobrazit heslo',
    },
    processGroupAction: {
      ADD: 'Přidat',
      CHANGE: 'Změnit',
      REMOVE: 'Odstranit',
      SUSPEND: 'Přerušit',
      TAKEOVER: 'Převzít',
      RESUME: 'Pokračovat',
      LINK: 'Připojit',
      RESTRICT: 'Omezit',
      CUSTOM: 'Custom',
    },
    processDefinitionState: {
      DRAFT: 'Návrh',
      CURRENT: 'Aktuální',
      HISTORY: 'Historický',
    },
    emailsSla: 'SLA emailů',
    sla: {
      MODULE: {
        CRM: 'CRM',
        TICKETING: 'Tiketing',
      },
    },
    processDefinitionDeploymentStatus: {
      ERROR: 'Chyba',
      REQUESTED: 'Požadováno',
      COMPLETED: 'Hotovo',
    },
    dmnDefinitionDeploymentStatus: {
      ERROR: 'Chyba',
      REQUESTED: 'Požadováno',
      COMPLETED: 'Hotovo',
    },
    processColor: {
      RED: 'červená',
      BLUE: 'modrá',
      YELLOW: 'žlutá',
      GREEN: 'zelená',
      WHITE: 'bílá',
    },
    orderLineType: {
      PRODUCT: 'Produkt',
      SERVICE: 'Služba',
      PRICELIST: 'Sazebník',
      MATERIAL: 'Materiál',
      WFM: 'Wfm',
    },
    catalogType: {
      PRODUCT: 'Produkt',
      RESOURCE: 'Zdroj',
      SERVICE: 'Služba',
      PRICELIST: 'Sazebník',
      SKILL: 'Dovednost',
      WORK: 'Práce',
      MATERIAL: 'Materiál',
      OTHERS: 'Ostatní',
    },
    scriptType: {
      ADMIN: 'Admin',
      TQL: 'Tql',
      REPORTING: 'Reporting',
      SPEL: 'Spel',
      GROOVY: 'Groovy',
      ANSIBLE: 'Ansible',
      NETMICO: 'Netmico',
    },
    priceListType: {
      label: 'Typ ceny',
      PRICE: 'Cena',
      DISCOUNT_ABSOLUTE: 'Sleva',
      DISCOUNT_PERCENT: 'Procentní sleva',
    },
    recurringChargePeriodTypeEnums: {
      label: 'Typ časové jednoty poplatku',
      DAY: 'Den',
      WEEK: 'Týden',
      MONTH: 'Měsíc',
      YEAR: 'Rok',
    },
    recurringChargePeriodType: 'Časová jednotka',
    recurringChargePeriodLength: 'Počet časových jednotek',
    catalogEntityType: {
      CATALOG: 'Katalog',
      CATEGORY: 'Kategorie',
      SPECIFICATION: 'Specifikace',
    },
    catalogSpecificationTypeLabel: 'Typ specifikace',
    catalogSpecificationType: {
      TARIFF: 'Tarif',
      VAS_ACCOUNT: 'Doplňková služba na plátce',
      VAS: 'Doplňková služba',
      HW: 'HW',
      DISCOUNT: 'Sleva',
      CATEGORY: 'Kategorie',
      NA: 'Kategorie',
      INSTALL: 'Instalační poplatek',
      PAYMENT: 'Poplatek',
      SUSPEND: 'Pozastavení/Blokace',
      DISCOUNT_ACCOUNT: 'Sleva na plátce',
      NONRECURRING: 'Jednorázový poplatek',
    },
    entityInstanceLocationRequired: {
      none: 'Není vyžadováno',
      place: 'Místo z registru',
      crmAddress: 'Adresa',
    },
    processTaskGroup: {
      TASK_GROUP_TYPE_COMPLETE: 'Hotovo',
      TASK_GROUP_TYPE_RETURN: 'Vráceno',
      TASK_GROUP_TYPE_ERROR: 'V chybě',
      TASK_GROUP_TYPE_DELETE: 'Smazaný',
      TASK_GROUP_TYPE_REALIZATION: 'V realizaci',
      TASK_GROUP_TYPE_SKIPPED: 'SKIPPED',
      TASK_GROUP_TYPE_RESOLVED: 'RESOLVED',
      TASK_GROUP_TYPE_WAITING_INTERNAL: 'Čekající interní',
      TASK_GROUP_TYPE_WAITING_CUSTOMER: 'Čekající zákazník',
    },
    parameterType: {
      EXPRESSION: 'Expression',
      SCRIPT_GROOVY: 'Groovy skript',
    },
    taskActionTemplateType: {
      EXPRESSION: 'Expression',
      GROOVY: 'Groovy skript',
    },
    variableType: {
      SOURCE: 'Zdroj',
      SOURCE_EXPRESSION: 'Zdrojový výraz',
      ALL: 'Všechny',
    },
    email: {
      dashedLabel: 'E-mail',
      label: 'Email',
    },
    emailType: {
      BUSINESS: 'Pracovní',
      PRIVATE: 'Soukromý',
    },
    customers: {
      status: {
        ACTIVE: 'Aktivní',
        INACTIVE: 'Neaktivní',
        CEASED: 'Ukončen',
      },
    },
    accountState: {
      PREPARING: 'Připravuje se',
      ACTIVE: 'Aktivní',
      SUSPENDED: 'Přerušena účinnost',
      CEASED: ' Ukončen',
    },
    leadPriority: {
      HIGH: 'Vysoká',
      MEDIUM: 'Střední',
      LOW: 'Nízká',
      LEAD_STATUS_NEW: 'Nově založený',
      LEAD_STATUS_IN_PROGRESS: 'Ve zpracování',
      LEAD_STATUS_LATER: 'Oslovit později',
      LEAD_STATUS_CONVERTED: 'Konvertován na zákazníka',
      LEAD_STATUS_CONVERTED_EXTERNAL: 'Konvertován do jiného CRM',
      LEAD_STATUS_REJECTED: 'Zrušen',
      LEAD_STATUS_UNSUCCESSFUL: 'Neúspěšný',
    },
    crmAddressState: {
      INACTIVE: 'Neaktivní',
      ACTIVE: 'Aktivní',
    },
    address: 'Adresa',
    addressManagement: 'Adresy',
    billingAddress: 'Fakturační adresa',
    urlAdress: 'Url',
    changePassword: 'Změnit heslo',
    installPWA: 'Nainstalovat jako webovou aplikaci',
    openPWA: 'Otevřít jako webovou aplikaci',
    keyboardShortcuts: 'Klávesové zkratky',
    appVersion: 'Verze aplikace',
    role: 'Role',
    typeOfCooperation: 'Typ spolupráce',
    personRole: 'Role',
    task: 'Task',
    requiredResponseDate: 'Požadované datum odpovědi',
    responseDate: 'Datum odpovědi',
    reqSolutionDate: 'Požadované datum vyřešení',
    termSolution: 'Termín vyřešení',
    termResponse: 'Termín odezvy',
    solved: 'Vyřešeno',
    closed: 'Uzavřeno',
    closedBy: 'Uzavřel',
    externalId: 'External ID',
    serviceID: 'Service ID',
    caseID: 'Case ID',
    tsID: 'TS ID',
    resourceID: 'Resource ID',
    plannedFrom: 'Plánováno od',
    plannedTo: 'Plánováno do',
    advice: 'Avíza',
    oneAdvice: 'Avízo',
    userGroup: 'Uživatelská skupina',
    realUserGroup: 'Skutečné oddělení',
    showClear: 'Zobrazit ikonu pro mazání',
    disableLink: 'Vypnout odkaz',
    keyboardOnMobile: 'Klávesnice na mobilním zařízení',
    showPlaceholder: 'Show placeholder',
    filterCodes: 'Kódy pro filtrování',
    showIcon: 'Zobrazit ikonu',
    iconClass: 'Třída ikony',
    showNoneText: 'Zobrazení textu v případě nulové hodnoty',
    defaultTreeViewMode: 'Výchozí režim stromového zobrazení',
    style: 'Styl',
    isLinkValue: 'Hodnota je odkaz',
    userGroups: 'Uživatelské skupiny',
    userParameters: 'Uživatelské parametry',
    audit: 'Audit',
    reports: 'Výkazy',
    regions: 'Rajony',
    basicInfo: 'Základní informace',
    info: 'Info',
    active: 'Aktivní',
    inactive: 'Neaktivní',
    makeInactive: 'Zneaktivnit',
    currentActivity: 'Provozní aktivita',
    activity: 'Aktivita',
    editRecord: 'Upravit záznam',
    wholeSiteFailure: 'Výpadek celé lokality',
    typeItPew: 'Typ IT PEW',
    deleteRecord: 'Smazat záznam',
    goToDetail: 'Přejít na detail',
    ldapMember: 'LDAP člen',
    confirmPassword: 'Ověření hesla',
    language: 'Jazyk',
    timezone: 'Časová zóna',
    password: 'Heslo',
    familyName: 'Příjmení',
    profileName: 'Název profilu',
    profileId: 'ID profilu',
    profileCategory: 'Kategorie profilu',
    fileName: 'Název souboru',
    cancel: 'Zrušit',
    for: 'Pro',
    activate: 'Aktivovat',
    deactivate: 'Deaktivovat',
    template: 'Šablona',
    templateOwner: 'Vlastník šablony',
    newCharacteristic: 'Nová charakteristika',
    characteristic: 'Charakteristika',
    readPermission: 'Oprávnění pro čtení',
    editPermission: 'Oprávnění pro editaci',
    regionType: {
      OBEC: 'Obec',
      OKRES: 'Okres',
      ZSJ: 'Základní sídelní jednotka',
      KRAJ: 'Kraj',
      RAJ: 'Rajon',
      RAJCEL: 'Rajonizační celek',
      MISTO: 'Umístění',
      PSC: 'PSČ',
    },
    add: 'Přidat',
    replace: 'Nahradit',
    genderEnum: {
      MALE: 'Muž',
      FEMALE: 'Žena',
    },
    status: 'Stav',
    formType: {
      DASHBOARD: 'Dashboard',
      DIALOG: 'Dialog',
      FORM: 'Formulář',
      CUSTOM_FIELD: 'Vlastní pole',
      PAGE: 'Stránka',
    },
    taskType: {
      TASK: 'Task',
      USER_TASK: 'User task',
      SERVICE_TASK: 'Service task',
      SEND_TASK: 'Send task',
      RECEIVE_TASK: 'Receive task',
      MANUAL_TASK: 'Manual task',
      BUSINESS_RULE_TASK: 'Business rule task',
      SCRIPT_TASK: 'Script task',
      CALL_ACTIVITY: 'Call activity',
      START_TASK: 'Start task',
      START_TASK_COMPENSATION: 'Start task compensation',
      START_TASK_ERROR: 'Start task error',
      START_TASK_ESCALATION: 'Start task escalation',
      START_TASK_MESSAGE: 'Start task message',
      START_TASK_SIGNAL: 'Start task signal',
      START_TASK_CONDITIONAL: 'Start task conditional',
      START_TASK_TIMER: 'Start task timer',
      END_TASK: 'End task',
      END_TASK_CANCEL: 'End task cancel',
      END_TASK_COMPENSATION: 'End task compensation',
      END_TASK_ERROR: 'End task error',
      END_TASK_ESCALATION: 'End task escalation',
      END_TASK_MESSAGE: 'End task message',
      END_TASK_NONE: 'End task none',
      END_TASK_SIGNAL: 'End task signal',
      END_TASK_TERMINATE: 'End task terminate',
      TIMER_EVENT: 'Timer event',
      GATEWAY: 'Gateway',
      MESSAGE_EVENT: 'Message event',
      BOUNDARY_TIMER: 'Boundary timer',
      BOUNDARY_MESSAGE: 'Boundary message',
      BOUNDARY_SIGNAL: 'Boundary signal',
      BOUNDARY_COMPENSATION: 'Boundary compensation',
      BOUNDARY_ERROR: 'Boundary error',
      BOUNDARY_ESCALATION: 'Boundary escalation',
      BOUNDARY_CANCEL: 'Boundary cancel',
      BOUNDARY_CONDITIONAL: 'Boundary conditional',
      SUBPROCESS: 'Subprocess',
    },
    syncMethodType: {
      MANUAL: 'Manuální synchronizace v odpovědnosti uživatele.',
      NONE: 'Synchronizace se neprovádí, každé prostředí má vlastní data.',
      COMPARE:
        'Změna se provádí primárně na testovacím prostředí a pomocí dalších nástrojů se zjistí rozdíly a proběhne nasazení na provoz.',
      SYSTEM:
        'Systémové konfigurace ovlivňující běh základních služeb. Hodnoty se mění typicky v rámci instalace nové verze aplikace.',
    },
    indexMapping: {
      NONE: 'Bez indexu',
      BASIC: 'Základní',
      TYPED: 'Včetně datových typů',
    },
    userType: {
      INDIVIDUAL: 'Osoba',
      WORK_GROUP: 'Pracovní skupina',
      ORGANIZATION: 'Organizace',
      PROFILE: 'Profil',
    },
    configForms: {
      details: 'Detaily',
      inplace: 'Inplace',
      taskDetail: 'Task detail',
      completedPage: 'Ukončení stránky',
      info: 'Info sekce',
      people: 'Lidé',
      dates: 'Datumy',
      section: 'Hlavní sekce',
      sectionDefaultOpened: 'Hlavní sekce - defaultně rozbaleno',
      tabs: 'Záložka',
      create: 'Vytvoření záznamu',
      comments: 'Komentáře',
      attachments: 'Přílohy',
      callScript: 'Call script',
      topbar: 'Topbar',
      topbarMenu: 'Topbar menu',
      taskPlanner: 'Detail tasku plánovače',
      taskProtocol: 'Protokol na detail tasku plánovače',
      resolution: 'Řešení',
      problem: 'Problém',
    },
    statusGroup: {
      ENABLE: 'Aktivní',
      DISABLE: 'Neaktivní',
    },
    roleInGroupEnum: {
      MEMBER: 'Člen',
      CALENDAR_ADMIN: 'Správce kalendářů',
      MANAGER: 'Vedoucí',
    },
    quoteStatus: {
      DRAFT: 'V přípravě',
      CONVERSATIONS: 'Vyjednává se',
      DECLINE: 'Odmítnuta',
      SUSPENDED: 'Přerušena',
      ORDERED: 'Objednána',
    },
    activeStatus: {
      ACTIVE: 'Aktivní',
      INACTIVE: 'Neaktivní',
      ENABLE: 'Aktivní',
      DISABLE: 'Neaktivní',
    },
    stockType: {
      PROJECT: 'Projektový',
      PERSONAL: 'Osobní',
      RESERVE: 'Rezervovaný',
      SUPER: 'Super',
      TEAM: 'Týmový',
    },
    ticketEntity: {
      CHANGE_MANAGEMENT: 'Change management',
      TICKETING: 'Tiketing',
    },
    accessRule: {
      READ: 'Čtení',
      READ_WRITE: 'Čtení/Zápis',
    },
    privType: {
      SYSTEM: 'Systémové',
      USER: 'Uživatelské',
    },
    yes: 'Ano',
    no: 'Ne',
    leaveButton: 'Odejít bez uložení',
    saveChangesButton: 'Uložit změny',
    yesAndNo: 'Ano a Ne',
    ticketTabs: {
      ADVICE: 'Avízo',
      ATTACHMENTS: 'Přílohy',
      COMMENTS: 'Komentáře',
      PROCESS: 'Proces',
      INCIDENT: 'Incidenty',
      RELATED_ENTITY: 'Související služby',
      RELATED_PARTY: 'Účastníci',
      TICKET_TEMPLATES: 'Šablony tiketů',
      TICKET_TEMPLATE_GROUPS: 'Šablony skupin tiketů',
      IMPACTED_ENTITY: 'Dotčené služby',
      RELATIONSHIPS: 'Vazby',
      PLACE: 'Místa',
      LOGS: 'Logy',
    },
    descending: 'Descending',
    ascending: 'Ascending',
    notOrder: 'Neřadit',
    from: 'Od',
    of: 'Z',
    to: 'Do',
    until: 'Až',
    previous: 'Předchozí',
    next: 'Další',
    indeterminate: 'Neurčitý',
    recordsCount: 'Počet záznamů',
    deleteFromSelection: 'Smazat z výběru',
    removeSelected: 'Odebrat vybrané',
    deselect: 'Zrušit vybrané',
    editSelected: 'Upravit vybrané',
    selectAll: 'Vybrat vše',
    invertSelected: 'Invertovat výběr',
    pin: 'pin',
    positionInForm: 'Pozice ve formuláři',
    validFrom: 'Platí od',
    validTo: 'Platí do',
    data: 'Data',
    subtitle: 'Titulek',
    subject: 'Předmět',
    tsmModule: 'TSM modul',
    primaryTsmModule: 'Primární TSM modul',
    extensibility: 'Rozšiřitelnost',
    uniqueness: 'Unikátnost',
    required: 'Povinná',
    readonly: 'Pouze pro čtení',
    configurable: 'Konfigurovatelná',
    value: 'Hodnota',
    displayValue: 'Hodnota k zobrazení',
    values: 'Hodnoty',
    moreValues: 'Více hodnot',
    selectionListingOneOrMore: 'Výběr pomocí listingu (jedné nebo více hodnot)',
    selectFirstValue: 'Vybrat první záznam',
    multiselect: 'Vybrat více záznamů',
    selectionMode: 'Mód výběru hodnot',
    singleMode: 'Výběr jednoho záznamu',
    multipleMode: 'Výběr více záznamů',
    multipleModeGallery: 'Dovolit uložit více obrázků',
    multipleModeGalleryDescription:
      'Po odškrtnutí této volby půjde do galerie uložit pouze jeden obrázek.',
    returnTypeMode: 'Mód uložení více hodnot',
    returnTypeString: 'Serializovaný řetězec',
    returnTypeArray: 'Pole hodnot',
    characteristics: 'Charakteristiky',
    characteristicsFilters: 'Filtry charakteristik',
    characteristicsInfo:
      'Charakteristiky (zvané také "atributy") rozšiřují datový model této entity o další vlastnosti. ' +
      'Charakteristiky mají svůj název pod kterým jsou v datech uložené (attributeName) a datový typ ("string", "number", nebo komplexní objekt s vlastními atributy). ' +
      'Charakteristiky jsou modelovány v tSM Form Designeru. Každý formulář tvoří vedle "vizuální reprezentace" také JSON Schema datový model. Core atributy společně ' +
      'se všemi charakteristikami tak tvoří formální datový model této entity, který je mimo jiné k dispozici i jako součást Public API.',
    formSpecificationInfo:
      'Formuláře pro založení nového záznamu, definice pohledů na detail záznamu nebo přechodové formuláře pro různé akce. Pro definici se používá ' +
      'standardní tSM Form Designer, musí však být správně nastaven "Core formulář", který rozšiřuje. Poté je možné z palety vybírat core atributy a výše definované ' +
      'charakteristiky a rozmisťovat je na formuláři podle potřeby. Dále je možné na formulář přidat tlačítka a další komponenty typu komentáře, přílohy, logy apod. podle potřeby.',
    measureUnit: 'Měrná jednotka',
    unitPrice: 'Jednotková cena',
    text: 'Text',
    string: 'String',
    mask: 'Maska',
    number: 'Číslo',
    editor: 'Editor', // nechat anglicky
    designer: 'Designer', // nechat anglicky
    order: 'Objednávka',
    orderType: 'Typ objednávky',
    changeManagement: 'Change Management', // nechat anglicky
    section: 'Sekce',
    fieldset: 'Fieldset',
    panel: 'Panel',
    splitter: 'Splitter',
    card: 'Karta',
    clone: 'Klon',
    editgrid: 'Grid',
    editTable: 'Tabulka',
    editTableDescription:
      'Tato komponenta slouží pro vygenerování tabulky. Je možné upravovat schéma a vkládat sloupce, nastavit defaultní řazení i výchozí počty záznamů.',
    tabs: 'Záložky',
    steps: 'Wizard',
    accordion: 'Accordion',
    content: 'Obsah',
    file: 'Soubor',
    resend: 'Přeposlat',
    top: 'Nahoře',
    left: 'Vlevo',
    right: 'Vpravo',
    bottom: 'Dole',
    ipAddressMask: 'Maska',
    ipAddressCidr: 'CIDR',
    ordering: 'Ordering', // nechat anglicky
    placeholder: 'Placeholder', // nechat anglicky
    columns: 'Sloupce',
    addColumn: 'Přidat sloupec',
    addRow: 'Přidat řádek',
    tooltip: 'Tooltip', // nechat naglicky
    visibleColumns: 'Viditelné sloupce',
    deleteItem: 'Smazat položku',
    groups: 'Skupiny',
    isRequired: 'Povinné',
    specification: 'Konfigurační profil',
    customErrorCode:
      'Hodnota může obsahovat pouze malá a velká písmena bez diakritiky, číslice, tečku, podtržítko a pomlčku. Začínat musí písmenem!',
    customErrorQName: 'Hodnota musí být validní podle QName!',
    phoneNumberErrorMessage: 'Neplatný formát telefonního čísla',
    webAddressErrorMessage: 'Neplatná url adresa',
    emailErrorMessage: 'Neplatný email',
    floatErrorMessage: 'Číselná hodnota musí být ve formátu XX.XX',
    integerErrorMessage: 'Neplatná celočíselná hodnota',
    positiveIntegerErrorMessage: 'Neplatná kladná celočíselná hodnota',
    colsValueErrorMessage: 'Hodnota musí být v rozmezí 1 až 12',
    goalTimeValueErrorMessage:
      'Hodnota musí být ve formátu XXhXXm, kde x je nějakým číslem',
    registerParentErrorMessage:
      'Číselníková hodnota nemůže mít jako nadřízenou položku sama sebe.',
    validityPeriodErrorMessage:
      'Datum začátku platnosti musí předcházet datu konce platnosti.',
    ipAddressMaskErrorMessage:
      'Zadaná hodnota neodpovídá formátu Ip adresy/masky',
    ipAddressCidrErrorMessage:
      'Zadaná hodnota neodpovídá formátu Ip adresy/cidr',
    ipAddressErrorMessage: 'Zadaná hodnota neodpovídá formátu Ip adresy',
    ipCidrErrorMessage: 'Zadaná hodnota neodpovídá formátu CIDR u Ip adresy',
    allowedAlphanumericErrorMessage:
      'Povoleny jsou pouze alfanumerické znaky, tečky, podtržítka a pomlčky.',
    firstLetterUppercaseErrorMessage: 'První písmeno musí být velké.',
    endsWithDotErrorMessage:
      'Hodnota nesmí končit tečkou, podtržítkem a pomlčkou.',
    startsWithDotErrorMessage:
      'Hodnota nesmí začínat tečkou, podtržítkem a pomlčkou.',
    uppercaseAfterDotErrorMessage: 'Písmena po tečce musí být velká.',
    calendar: {
      title: 'Kalendář',
      dayOfWeek: {
        shortcut: {
          MONDAY: 'Po',
          TUESDAY: 'Út',
          WEDNESDAY: 'St',
          THURSDAY: 'Čt',
          FRIDAY: 'Pá',
          SATURDAY: 'So',
          SUNDAY: 'Ne',
        },
        MONDAY: 'pondělí',
        TUESDAY: 'úterý',
        WEDNESDAY: 'středa',
        THURSDAY: 'čtvrtek',
        FRIDAY: 'pátek',
        SATURDAY: 'sobota',
        SUNDAY: 'neděle',
        WORK_DAY: 'pracovní den',
        DAY: 'den',
      },
      month: {
        JANUARY: 'leden',
        FEBRUARY: 'únor',
        MARCH: 'březen',
        APRIL: 'duben',
        MAY: 'květen',
        JUNE: 'červen',
        JULY: 'červenec',
        AUGUST: 'srpen',
        SEPTEMBER: 'září',
        OCTOBER: 'říjen',
        NOVEMBER: 'listopad',
        DECEMBER: 'prosinec',
      },
      TODAY: 'Dnes',
      CLEAR: 'Smazat',
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      weekHeader: 'Wk',
      message: 'Nelze zadat menší datum než: {{minDate}}',
      pick: 'Vybrat',
      chooseDate: 'Vybrat datum',
    },
    dataLoading: 'Načítám data, vydržte prosím...',
    shared: 'Sdílený',
    sharedWith: 'Sdíleno s ',
    id: 'ID',
    emptyFieldErrorMessage: 'Toto pole musí být vyplněno!',
    endDateErrorMessage: 'Datum od musí být menší než datum do!',
    datesMustNotBeInPast: 'Dates must not be in the past.',
    sortFieldErrorMessage: 'Hodnota musí být v rozmezí -1 až 1',
    checkDateFormatHHMM: 'Zkontrolujte prosím správnost zadaného času HH:MM!',
    show: 'Zobrazit',
    fromTo: 'Od / Do',
    help: 'Nápověda',
    icon: 'Ikona',
    iconColor: 'Barva Ikony',
    iconInfo:
      'Vybraná ikona se váže k danému záznamu a slouží k jeho snazší vizuální identifikaci. Zobrazuje se vedle záznamu všude tam, kde je použit. Například modul Tikety - u jeho typu se zobrazuje jeho přiřazená ikona',
    opened: 'Otevřeno',
    image: 'Obrázek',
    itemOrdering: 'Pořadí',
    // TODO JB - revidovat
    itemOrderingInfo: 'Pořadí ve kterém se tento záznam zobrazí',
    products: 'Produkty',
    product: 'Produkt',
    allProducts: 'Všechny produkty',
    userRegister: 'Uživatelský číselník',
    userRegisterCode: 'Kód uživatelského číselníku',
    userRegisterName: 'Název uživatelského číselníku',
    userRegisterConfigType: 'Typ konfigurace uživatelského číselníku',
    userRegisterTags: 'Štítky uživatelského číselníku',
    register: 'Číselník',
    registers: 'Číselníky',
    registerId: 'Register ID',
    registerName: 'Název registru',
    registerCode: 'Kód registru',
    registerTags: 'Tagy registru',
    registerSettingsInfo:
      'Pokročilé nastavení typu listingu. Pro jeho zobrazení je defaultně použit formulář s kódem "Listing.Type.Detail.Config". Pokud tento formulář neexistuje, je nastavení zobrazeno a editovatelné jako text v json formátu.',
    display: 'Zobrazení',
    displayFieldTooltip:
      'Je možné přidat více hodnot pomocí automatického doplňování nebo pomocí zdrojového kódu ["klíč", "název"]',
    displayed: 'Zobrazeno',
    new: 'Nový',
    old: 'Starý',
    profile: 'Profil',
    profiles: 'Profily',
    profileForDisplayColumns: 'Profil, který definuje zobrazení sloupců',
    openDetailType: 'Skriptování - Typ otevření detailu',
    source: 'Zdroj',
    sourceCode: 'Zdrojový kód',
    schema: 'Schéma',
    taxExemption: 'Přenesená daňová povinnost',
    actual: 'Aktuální',
    actualValue: 'Skutečná hodnota',
    actualValueInPercent: 'Skutečná hodnota[%]',
    actuatorHealth: 'Zdraví aktuátoru',
    preview: 'Náhled',
    previewMode: 'Režim náhledu',
    previewModeDescription:
      'Režim náhledu zaškrtněte pokud chcete aby komponenta zobrazovala pouze počet záznamů s jejich názvy. ' +
      'Umístěte před ní nadpis dle toho co komponenta zobrazuje.',
    displayOnlyPreviewModeCount: 'Režim náhledu - zobrazit pouze počet.',
    diplayPreviewModeCountDescription:
      'Pokud používáte režim náhledu, tak zaškrtněte pokud chcete aby se vypisoval pouze počet záznamů bez jejich názvů.',
    chart: 'Graf',
    chartConfiguration: 'Konfigurace grafu',
    pivotConfiguration: 'Konfigurace pivotu',
    totalCost: 'Celkové náklady',
    cargo: 'Náklad',
    timeToArrive: 'Doba příjezdu',
    location: 'Místo',
    technology: 'Technologie',
    technologyDescription: 'Výběr technologie z change managementu',
    chooseRegionFromChM: 'Výběr regionu z change managementu',
    autoSelectFirstRow: 'Automaticky označit první řádek',
    trafficImpact: 'Vliv na provoz',
    customerImpact: 'Dopad na zákazníka',
    reasonForWork: 'Důvod práce',
    region: 'Region',
    impactOnCustomer: 'Dopad na zákazníka',
    operationImpact: 'Dopad na provoz',
    executor: 'Vykonavatel',
    bundle: 'Bundle', // nechat anglicky
    serviceDefinition: 'Definice služby',
    locationOne: 'Umístění 1',
    locationTwo: 'Umístění 2',
    configurations: 'Historie Produktů/Služeb',
    current: 'Současný',
    pending: 'Čekající',
    catalog: 'Katalog',
    catalogInfo: 'Katalog pro kategorii',
    superordinateCategory: 'Nadřízená kategorie',
    catalogSpecification: 'Katalogová specifikace',
    taskLabel: 'Úkol',
    back: 'Zpět',
    backTooltip: 'Vrátit zpět na předchozí obrazovku',
    backup: 'Záloha',
    backupSet: 'Soubor záloh',
    doBackup: 'Zálohovat',
    restore: 'Obnovit',
    restoreSuccess: 'Obnovení proběhlo úspěšně.',
    restoreFailed: 'Obnovení dat selhalo.',
    importStatus: 'Stav nahrávání',
    backupSetImportStatus: {
      NOT_PROCESSED: 'Neprocesováno',
      OK: 'Nahráno',
      FAILED: 'Selhalo',
    },
    time: 'Čas',
    primary: 'Primární',
    daysOfWeek: [
      'shared.calendar.dayOfWeek.SUNDAY',
      'shared.calendar.dayOfWeek.MONDAY',
      'shared.calendar.dayOfWeek.TUESDAY',
      'shared.calendar.dayOfWeek.WEDNESDAY',
      'shared.calendar.dayOfWeek.THURSDAY',
      'shared.calendar.dayOfWeek.FRIDAY',
      'shared.calendar.dayOfWeek.SATURDAY',
    ],
    // daysOfWeek: ['Neděle', 'Ponděli', 'Útery', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
    daysInNormalOrder: [
      'Ponděli',
      'Útery',
      'Středa',
      'Čtvrtek',
      'Pátek',
      'Sobota',
      'Neděle',
    ],
    monthNames: [
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec',
    ],
    cargoByTechnician: 'Náklad dle výhodnosti technika',
    cargoByRegion: 'Náklad dle příslušnosti k regionu',
    termArrangement: 'Sjednání termínu',
    today: 'Dnes',
    productRelations: 'Vazby na produkty',
    categoryRelations: 'Vazby na kategorie',
    relations: 'Vazby',
    catalogTypeHeader: 'Typ katalogu',
    catalogEntityTypeHeader: 'Typ entity',
    entityInstanceLocation1RequiredHeader: 'Požadované umístění 1',
    entityInstanceLocation2RequiredHeader: 'Požadované umístění 2',
    catalogCode: 'Kód katalogu',
    catalogId: 'ID Katalogu',
    catalogCodes: 'Kódy katalogu',
    recurringChargePeriod: {
      MONTHLY: 'Měsíčně',
      QUARTERLY: 'Čtvrtletně',
      YEARLY: 'Ročně',
    },
    notificationTypes: 'Povolené typy notifikací',
    notificationTypeAllowEmpty: 'Povolit prázdný typ notifikace',
    notificationType: {
      USER_COMMENT: 'Uživatelský komentář',
      SYSTEM_NOTIFICATION: 'Systémová notifikace',
      CUSTOMER_MESSAGE: 'Zákaznická notifikace',
    },
    notificationRuleEventType: {
      SYSTEM: 'System',
      PROCESS: 'Process',
      KAFKA: 'Kafka',
      REST: 'REST',
      DIRECT: 'Direct',
    },
    deliveryItemType: {
      SMS: 'SMS',
      EMAIL: 'Email',
      LETTER: 'Dopis',
      APPLICATION: 'Aplikace',
      USER_COMMENT: 'Uživatelský komentář',
      INCOMING_EMAIL: 'Příchozí email',
    },
    notificationTemplateStatus: {
      ACTIVE: 'Aktivní',
      INACTIVE: 'Neaktivní',
    },
    notificationTemplateCategory: {
      CAT_IMPORTANT: 'Důležité',
      CAT_LOW_PRIORITY: 'Nízká priorita',
    },
    notificationStatus: {
      ALL: 'Všechny',
      PLAN: 'Čeká na odeslání',
      PROCESSING: 'Probíhá zpracování',
      SENT: 'Odeslána',
      FAILED: 'Chyba v odeslání',
      PROCESSED: 'Zpracována',
      DELETED: 'Smazaná',
      TEMPLATE_INACTIVE: 'Neaktivní šablona - neodesláno',
      CAT_LOW_PRIORITY: 'Omezené notifikace - neodesláno',
      DISABLED: 'Vypnuté notifikace - neodesláno',
      CONFIRMED: 'Potvrzena zákazníkem',
      DELIVERED: 'Doručeno',
      DRAFT: 'Koncept',
    },
    senderType: {
      MANUAL: 'Manuální',
      PROCESS: 'Proces',
    },
    notificationSendingToTypes: {
      primary: 'Primární kontakt',
      related: 'Dle ovlivněných služeb',
      linked: 'Dle souvisejících tiketů',
      custom: '-',
    },
    filterOperator: {
      contains: 'Obsahuje',
      notcontains: 'Neobsahuje',
      startswith: 'Začíná na',
      endswith: 'Končí na',
      in: 'Je v seznamu',
      notin: 'Není v seznamu',
      eq: 'Rovná se',
      noteq: 'Nerovná se',
      gt: 'Více než',
      gte: 'Více nebo rovno než',
      lt: 'Méně než',
      lte: 'Méně nebo rovno než',
      empty: 'Nevyplněn',
      notempty: 'Vyplněn',
      btw: 'Mezi',
      gtr: 'Více než (relativně)',
      ltr: 'Méně než (relativně)',
      btwr: 'Mezi (relativně)',
      dontTouch: 'Nedotýkat se (dontTouch)',
      invertSelected: 'Invertovat výběr',
    },
    allDay: 'Celý den',
    month: 'Měsíc',
    custom: 'Vlastní',
    week: 'Týden',
    workweek: 'Pracovní týden',
    day: 'Den',
    timeline: 'Agenda',
    confirmBothFromToMsg: 'Doplňte obě hodnoty od, do.',
    valuePlaceholder: 'Zadejte hodnotu',
    owner: 'Vlastník',
    ownerType: 'Typ vlastníka',
    ownerId: 'Id vlastníka',
    defaultProfile: 'Výchozí profil',
    emailHeader: 'Hlavička emailu',
    emailText: 'Text emailu',
    attachmentName: 'Název přílohy',
    emailRecipientListTo: 'Seznam adresátů To',
    emailRecipientListCc: 'Seznam adresátů Cc',
    recipientList: 'Seznam příjemců',
    sendTime: 'Čas odeslání',
    emailContent: 'Obsah emailu',
    dynamicForms: 'Konfigurační profil',
    allowedValues: 'Povolené hodnoty',
    selectProperty: 'Atribut',
    ok: 'Ok',
    export: 'Export',
    aggregation: 'Agregace',
    mailSendPeriod: {
      DAILY: 'denně',
      WEEKLY: 'týdně',
      MONTHLY: 'měsíčně',
    },
    default: 'Výchozí',
    defaultValue: 'Výchozí hodnota',
    defaultComponentValue: 'Výchozí hodnota této komponenty.',
    defaultLanguage: 'Výchozí jazyk',
    disableKey: 'Disable key',
    serialNumber: 'Sériové číslo',
    amount: 'Množství',
    stock: 'Sklad',
    batchNo: 'Šarže',
    writeOff: 'Odepsat',
    confirmSelection: 'Potvrdit výběr',
    plannedBeginning: 'Plánovaný začátek',
    daysToPlannedBeginning: 'Počet dní do plánovaného začátku',
    domainConcurrency: 'Doménový souběh',
    openTemplate: 'Náhled PEW',
    openPreview: 'Uložit náhled',
    plannedEnding: 'Plánovaný konec',
    approvedDate: 'Datum schválení',
    outageType: {
      real: 'Skutečný',
      plan: 'Plánovaný',
    },
    resolution: 'Řešení',
    termination: 'Ukončení',
    changeRequest: 'Change request', // nechat anglicky
    deletionSuccess: 'Smazání proběhlo úspěšně',
    deletionFailure: 'Během mazání došlo k chybě',
    ticketId: 'Tiket ID',
    services: 'Služby',
    orders: 'Objednávky',
    offers: 'Nabídky',
    addresses: 'Adresy',
    deleteColumn: 'Smazat sloupec',
    saveAs: 'Uložit jako',
    recurringPeriod: {
      NONE: 'neopakuje se',
      DAILY: 'denně',
      WEEKLY: 'týdně',
      MONTHLY: 'měsíčně',
      YEARLY: 'ročně',
      WORK_DAILY: 'každý pracovní den',
      CUSTOM: 'vlastní',
    },
    itemOrderEnum: {
      FIRST: 'první',
      SECOND: 'druhý',
      THIRD: 'třetí',
      FOURTH: 'čtvrtý',
      LAST: 'poslední',
    },
    priorityEnum: {
      LOW: 'Nízká',
      NORMAL: 'Normální',
      CRITICAL: 'Kritická',
    },
    maintenanceActivityStateEnum: {
      NEW: 'Nová',
      PLANNED: 'Plánovaná',
      DECLINED: 'Odmítnutá',
      REALIZED: 'Realizovaná',
      IMPOSSIBLE: 'Nelze provést',
    },
    maintenancePlanStateEnum: {
      PROPOSED: 'Návrh',
      IN_PREPARATION: 'Ke schválení',
      ACCEPTED: 'Schválen',
      IN_REALIZATION: 'Realizace',
      REALIZED: 'Realizován kompletně',
      REALIZED_PARTIALLY: 'Realizován částečně',
      DECLINED: 'Zamítnut',
    },
    constraintTypeEnum: {
      SOON: 'Co nejdříve',
      LATE: 'Co nejpozději',
      MUST_START: 'Musí být zahájen',
      MUST_FINISH: 'Musí být dokončen',
    },
    calendarType: {
      USER: 'Uživatelský kalendář',
      LISTING: 'Listing kalendář',
      SYSTEM: 'Systémový kalendář',
      GOOGLE: 'Google kalendář',
      RESOURCE: 'Zdrojový kalendář',
    },
    calendarSubtype: {
      TEMPLATE: 'Šablona',
      INDIVIDUAL: 'Jednotlivec',
      WORKGROUP: 'Pracovní skupina',
    },
    periodicInterval: {
      WEEK: 'Týdně',
      MONTH_1: 'Jednou za měsíc',
      MONTH_3: 'Jednou za 3 měsíc',
      MONTH_6: 'Jednou za 6 měsíců',
      YEAR_1: 'Jednou za rok',
      YEAR_2: 'Jednou za 2 roky',
      YEAR_3: 'Jednou za 3 roky',
      YEAR_4: 'Jednou za 4 roky',
      YEAR_5: 'Jednou za 5 let',
      YEAR_10: 'Jednou za 10 let',
    },
    restartPeriodType: {
      PERPETUAL: 'Perpetuálně',
      YEARLY: 'Ročně',
      MONTHLY: 'Měsíčně',
      DAILY: 'Denně',
    },
    orderLineOperation: {
      ADD: 'Vytvoření',
      REMOVE: 'Ukončení',
      UPDATE: 'Změna',
      TAKEOVER: 'Převod',
      MOVE: 'Přeložení',
      LINK: 'Beze změny',
    },
    duration: 'Délka',
    durationMin: 'Délka v min.',
    durationTime: 'Doba trvání',
    selectedColor: 'Vybraná barva',
    selectColor: 'Vyberte barvu',
    selectSize: 'Vyberte velikost',
    dataLoadFailed: 'Nepodařilo se načíst data! Popis chyby:',
    noResultsFound: 'Žádné výsledky nenalezeny',
    requiredDeliveryDate: 'Skutečné datum realizace',
    requiredDeliveryDateShort: 'Pož. dat. realizace',
    account: 'Account',
    acc: 'Account',
    accounts: 'Accounty',
    quote: 'Nabídka',
    productState: 'Stav produktu',
    orderStatus: {
      CURRENT: 'Aktuálně',
      PENDING: 'Naplánováno',
      HISTORY: 'Historický',
      QUOTE: 'Nabídka',
    },
    selectors: {
      orderCreated: 'Objednávka byla vytvořena',
      orderEdited: 'Objednávka byla upravena',
      orderTypeCreated: 'Typ objednávky byl vytvořen',
      orderTypeEdited: 'Typ objednávky byl upraven',
      quoteCreated: 'Nabídka byla vytvořena',
      quoteEdited: 'Nabídka byla upravena',
    },
    characteristicsTitle: 'Název',
    characteristicsSubtitle: 'Titulek',
    processInstance: {
      task: {
        name: 'Task název',
        user: 'Task uživatel',
        userGroup: 'Task oddělení',
        status: 'Task stav',
        startTime: 'Task od',
        endTime: 'Task do',
        plannedStartTime: 'Task plán od',
        plannedEndTime: 'Task plán do',
        dueDate: 'Task termín',
        followupDate: 'Task upomínka',
        dateWorkStart: 'Zahájení PP',
        dateWorkEnd: 'Dokončení PP',
        zahajeniPrace: 'Zahájení práce',
        dokonceniPrace: 'Dokončení práce',
      },
    },
    documentExType: {
      DEPOSIT: 'Vratná kauce',
      REPAIR_TAX_DOC: 'Opravný daňový doklad',
      GENERAL: 'Obecná faktura',
      PROFORMA: 'Zálohová faktura',
      RECIPROCAL: 'Reciproční závazek',
    },
    sharingRefType: {
      USER: 'Uživatel',
      USER_GROUP: 'Skupina',
    },
    logging: {
      logs: 'Logy',
      ownerType: 'Modul',
      type: 'Typ',
      duration: 'Doba trvání (ms)',
      startDate: 'Začátek',
      module: 'Modul',
      callerInfo: 'Info o volajícím',
      request: 'Request',
      response: 'Response',
      resultCode: 'Result code',
      resultMessage: 'Popis výsledku',
      relatedData: 'Doplňující informace',
      ownerId: 'Owner ID',
      correlationId: 'Correlation ID',
      logType: {
        code: 'Kód',
        name: 'Název',
        protocol: 'Protokol',
        language: 'Jazyk/Formát',
        validityFrom: 'Platnost od',
        validityTo: 'Platnost do',
        appFrom: 'Zdroj',
        appTo: 'Cíl',
        formCode: 'Kód formuláře',
        form: 'Formulář',
        requestFormCode: 'Kód formuláře pro request',
        requestForm: 'Formulář pro request',
        responseFormCode: 'Kód formuláře pro response',
        responseForm: 'Formulář pro response',
        relatedDataFormCode: 'Kód formuláře pro související data',
        relatedDataForm: 'Formulář pro související data',
      },
    },
    logMess: {
      showSource: 'Zobrazit zdroj',
      showForm: 'Zobrazit formulář',
      cmpOrgMsg: 'Porovnat s původní zprávou',
      downloadMsg: 'Stáhnout zprávu',
      resendToInterface: 'Znovu odeslat do interface',
    },
    notifications: {
      notificationTemplates: 'Šablony notifikací',
      notificationTemplate: 'Šablona notifikací',
      notifications: 'Notifikace',
      sendNotification: 'Poslat notifikaci',
      formatterDocumentTemplate: 'Šablony out managementu',
    },
    formatterInputType: {
      FILE: 'Soubor',
      HTML: 'HTML',
    },
    formatterOutputType: {
      PDF: 'PDF',
      DOC: 'DOC',
      DOCX: 'DOCX',
    },
    formatterDocumentType: {
      PDF: 'PDF',
      EMAIL: 'EMAIL',
      SOURCE: 'Zdrojový kód',
      EXCEL: 'Excel',
    },
    formatterDocumentSize: {
      AUTO: 'AUTO',
      PORTRAIT: 'PORTRAIT',
      LANDSCAPE: 'LANDSCAPE',
      A4: 'A4',
      B5: 'B5',
      LETTER: 'LETTER',
    },
    campaignHeaders: {
      customerMnemoID: 'ID Zákazníka',
      campaign: 'Kampaň',
      followUp: 'Kontaktovat',
      campaignLines: 'Produktÿ',
    },
    billingDocument: {
      billingNew: 'Účtování - nový dokument',
      status: {
        VALID: 'Validní',
        DELETED: 'Nevalidní',
      },
    },
    billingCurrency: {
      position: {
        PREFIX: 'Před',
        SUFFIX: 'Za',
      },
    },
    billingCycle: {
      status: {
        ACTIVE: 'Aktivní',
        BILLED: 'Zaúčtováno',
        TO_BE_BILLED: 'Před zaúčtováním',
      },
    },
    billingCycleType: {
      billingFrequency: {
        YEARLY: 'Ročně',
        QUARTERLY: 'Čtvrtletně',
        MONTHLY: 'Měsíčně',
      },
    },
    billingDocumentType: {
      sapConfig: {
        HOUSEHOLD: 'House Hold Connected',
        HOME_PAST: 'Home Past',
        DARK_FIBER: 'Dark Fiber',
      },
    },
    formTooltip: {
      basicComponents: {
        expressionEditor:
          'Editor výrazů je vstupní pole, které se používá k zadání výrazu SPEL nebo JEXL.',
      },
    },
    stockItemStatus: {
      ACTIVE: 'Aktivní',
      ARCHIVE: 'Archivovaný',
    },
    technicalPreview: 'Technical preview',
    availability: 'Dostupnost',
    crmAddressListingDescription:
      'Komponenta zobrazující adresy např. na detailu Leada a Zákazníka',
    addressRolesListingDescription:
      'Komponenta zobrazující adresy např. na detailu Accounta a Osoby',
    responsiblePersonListingDescription:
      'Komponentu použitou např. na detailu Accounta',
    showInScheduler: 'Zobrazit v plánovači',
    resending: 'Poslat Znovu',
    resendingMessage: 'Provést opakované poslání?',
  },
  config: {
    autocomplete: 'Výběr s našeptáváním',
    variable: 'Proměnná',
    basicInfo: 'Základní informace konfiguračního profilu',
    text: 'Text',
    number: 'Číslo',
    icon: 'Ikona',
    textarea: 'Textarea',
    richtext: 'Richtext',
    checkbox: 'Checkbox',
    triStateCheckbox: 'Třístavový checkbox',
    switch: 'Spínač',
    date: 'Datum',
    rating: 'Hodnocení',
    link: 'Odkaz',
    select: 'Výběr',
    multiselect: 'Výběr více záznamů',
    radio: 'Radio button',
    password: 'Heslo',
    mask: 'Maska',
    phone: 'Telefon',
    button: 'Tlačítko',
    submitButton: 'Odeslat tlačítko',
    submitRequestButton: 'Odeslat tlačítko pro žádost',
    helpvalue: 'Pomocná hodnota',
    html: 'HTML zdroj',
    codeList: 'Číselník',
    javaExe: 'Java příklad',
    formCode: 'Kód formuláře',
    horizontal: 'Horizontální',
    vertical: 'Vertikální',
    optionalClass: 'Volitelná CSS třída',
  },
  menu: {
    dashboard: {
      createNew: 'Vytvořit nový dashboard',
      dashboardManage: 'Správa dashboardů',
    },
    calendar: {
      calendar: 'Kalendář',
      fullCalendar: 'Nový kalendář',
      calendarManage: 'Správa kalendářů',
      calendarCreateButton: 'Tlačítko vytvoření kalendáře',
      calendarSubtype: 'Podtyp kalendáře',
      calendarEventType: 'Typ události kalendáře',
      calendarEventConfigurationData: 'Konfigurační data události kalendáře',
      calendarTemplate: 'List šablon kalendáře',
    },
    crm: {
      crm: 'CRM',
      crmAddressListing: 'Přehled adres',
      crmAddressLOV: 'Adresa',
      crmAddress: 'Adresy CRM',
      customers: 'Zákazníci',
      customerHistory: 'Historie zákazníka',
      contracts: 'Accounty',
      leads: 'Leady',
      persons: 'Osoby',
      personSteps: 'Lišta stavů osoba',
      dialsManagement: 'Číselníky',
      personRoles: 'Role osob',
      legalForm: 'Právní forma',
      customerType: 'Typ zákazníka',
      leadType: 'Typ leada',
      leadList: 'Seznam leadů',
      leadSteps: 'Lišta stavů lead',
      personType: 'Typ osoby',
      personHistory: 'Historie osoby',
      personListing: 'Přehled osob',
      responsiblePersonListing: 'Přehled odpovědných osob',
      accountType: 'Typ accountu',
      accountSteps: 'Lišta stavů account',
      accountHistory: 'Historie accountu',
      accountListing: 'Přehled účtů',
      accountListingDescription:
        'Komponenta použitá například na detailu zákazníka',
      customerSegment: 'Segment zákazníka',
      customerSteps: 'Lišta stavů zákazníka',
      rating: 'Hodnocení',
      leadSource: 'Zdroj leada',
      leadHistory: 'Historie leada',
      address: 'Adresa',
      addressRolesListing: 'Přehled rolí adres',
      registers: 'Číselníky',
      contactManager: 'Přehled kontaktů',
      campaign: 'Kampaně',
      campaignWaveDefinition: 'Definice vln kampaní',
      campaignWave: 'Vlny kampaní',
      campaignType: 'Nabídka typů kampaní',
      campaignStatus: 'Status kampaně',
      partyCampaignStatus: 'Status party kampaně',
      sales: 'Prodej',
    },
    ordering: {
      ordering: 'Ordering',
      order: 'Objednávky',
      orderListing: 'Seznam objednávek',
      orderList: 'Seznam objednávek',
      orderBookmark: 'Bookmark objednávky',
      orderListRelated: 'Seznam objednávek podle související entity',
      orderTaskList: 'Seznam úkolů objednávky',
      orderHardwareLine: 'Ordering - Hardware řádek objednávky',
      quote: 'Nabídky',
      registers: 'Číselníky',
      orderType: 'Typ objednávky',
      orderStatus: 'Status objednávky',
    },
    provisioning: {
      provisioning: 'Service provisioning',
      order: 'Objednávky služeb',
    },
    wfm: {
      wfm: 'WFM',
      workOrders: 'Objednávky práce',
      dispatcher: 'Dispečer',
      scheduler: 'Plánovač',
      routeMap: 'Mapa tras',
      myTasks: 'Moje úkoly',
      registers: 'Číselníky',
      worksCatalog: 'Katalog prací',
      materialKatalog: 'Materiálový katalog',
    },
    addressManagement: {
      regions: 'Správa rajonů',
      layers: 'Správa vrstev',
    },
    inventory: {
      inventory: 'Inventory',
      products: 'Produkty',
      services: 'Služby',
      resources: 'Zdroje',
    },
    ticket: {
      ticketing: 'Tiketing',
      ticket: 'Tikety',
      registers: 'Číselníky',
      configuration: 'Konfigurace',
      channels: 'Kanály',
      category: 'Kategorie',
      priority: 'Priority',
      problems: 'Problémy',
      resolution: 'Řešení',
      statuses: 'Statusy',
      technology: 'Technologie',
      types: 'Typy tiketů',
      adviceTypes: 'Typy avíza',
      ticketLink: 'Vazby tiketů',
      severity: 'Závažnosti',
      relatedPartyRole: 'Role účastníka',
      relatedPartyType: 'Typy skupin v řešení',
      relatedEntityType: 'Typy souvisejících entit',
      impactedEntityType: 'Typy ovlivněných entit',
      ticketTemplate: 'Šablony tiketů',
    },
    serviceManagement: {
      serviceManagement: 'Řízení služeb',
      managedMicroservices: 'Spravované mikroslužby',
    },
    stock: {
      stock: 'Sklad',
      nullableItems: 'Zobrazit také položky s nulovým množstvím',
      stocks: 'Sklady',
      items: 'Položky',
      itemList: 'Položky - detail',
      moves: 'Pohyby',
      movementCreation: 'Vytvoření pohybů',
      stockType: 'Typy skladů',
      stockMovementType: 'Typ pohybu',
      stockItemType: 'Typ položky',
      registerManagement: 'Číselníky',
    },
    changeManagement: {
      changeManagement: 'Plánované práce',
      changeRequest: 'PEW',
      changeRequestList: 'Seznam žádostí o změnu',
      changeRequestListLOV: 'Výběr typu žádostí o změnu',
      changeRequestTemplates: 'Šablony PEW',
      changeRequestTemplateGroups: 'Šablony skupin PEW',
      workOnLocalities: 'Historie práce na lokalitách',
      registers: 'Číselníky',
      types: 'Typy změn. požadavků',
      dialsManagement: 'Číselníky',
      operationImpacts: 'Dopad na provoz',
      technology: 'Technologie',
      impactOnCustomer: 'Dopad na zákazníka',
      reasonForWork: 'Důvod práce',
      priority: 'Priorita',
      statuses: 'Stavy tiketu',
      resolution: 'Řešení',
      channels: 'Kanály',
      relatedEntityType: 'Typ související entity',
      impactedEntityType: 'Typ ovlivněné entity',
      relatedPartyType: 'Typy skupin v řešení',
      relatedPartyRole: 'Role účastníka',
      linkType: 'Typ vazby',
      region: 'Region',
      scheduleType: 'Činnosti harmonogramu',
      schedule: 'Harmonogram',
      scheduleDescription: 'Harmonogram související služby',
      scheduleTypeDescription:
        'Výběr činnosti harmonogramu z change managementu',
    },
    billing: {
      billing: 'Billing',
      dialsManagement: 'Číselníky',
      billingDocument: 'Dokument billingu',
      billingDocumentLine: 'Účetní položky',
      billingDocumentType: 'Typ billing dokumentu',
      paymentOverview: 'Přehled plateb',
      documentOverview: 'Přehled dokladů',
      controlGroups: 'Kontrolní skupiny',
      registers: 'Číselníky',
      priceItemOverview: 'Přehled ceníkových položek',
      billingCurrency: 'Měny',
      billingCycle: 'Účtovací období',
      billingCycleType: 'Typy účtovacích období',
      billingDocumentStatus: 'Stavy billing dokumentů',
      sapDocumentStatus: 'stav SAP dokumentu',
      billingCycleStatus: 'Stavy účtovacích období',
      vatRate: 'Sazba DPH',
    },
    userManagement: {
      userManagement: 'Správa uživatelů',
      users: 'Uživatelé',
      profiles: 'Profily',
      profile: 'Profil',
      userGroups: 'Uživatelské skupiny',
      roles: 'Role',
      privs: 'Oprávnění',
      accessRules: 'Přístupová pravidla',
      bulkEdits: 'Hromadné úpravy',
      usersSkills: 'Dovednosti uživatelů',
      registers: 'Číselníky',
      skillsCatalog: 'Katalog dovedností',
      userGroupType: 'Typ uživatelské skupiny',
      userType: 'Typ uživatele',
    },
    catalogs: {
      catalog: 'Katalog',
      catalogManagement: 'Katalogy',
      catalogItem: 'Položka z katalogu',
      catalogs: 'Katalogy',
      category: 'Kategorie',
      specifications: 'Specifikace',
      registers: 'Číselníky',
      catalogStates: 'Stavy katalogu',
      specificationStates: 'Stavy specifikací',
      specificationLinkType: 'Typ vazby specifikací',
      specificationType: 'Typ specifikace',
      vatRange: 'Pásmo DPH',
      entityCatalogSpecDescription: 'Entitní katalogová specifikace',
      entityCatalogSpecCodeDescription:
        'Entitní katalogová specifikace podle kódu',
      catalogCategoryDescription: 'Katalogová kategorie',
      catalogCategoryCodeDescription: 'Katalogová kategorie podle kódu',
      entityCatalogSpecTypeDescription: 'Typ entitní katalogové specifikace',
      catalogRelationshipCategorySpecIconDescription:
        'Ikona kategorie vztahu katalogové specifikace',
      relationshipCodeLinkDescription: 'Odkaz kódu vztahu',
    },
    process: {
      processManag: 'Procesy',
      processDesigner: 'Proces designer',
      taskDesigner: 'Task designer',
      taskActionTemplate: 'Šablona akce',
      registers: 'Číselníky',
      processTypesManage: 'Typy procesů',
      processGroupManag: 'Skupiny procesů',
      statusManagement: 'Stavy',
      stateMenagGrooups: 'Skupiny stavů',
      dmnDesigner: 'Dmn designer',
      dmnManag: 'Dmn',
      processTasks: 'Tasky procesů',
    },
    configForm: {
      configForm: 'Konfigurace & Formuláře',
      entitySpecifications: 'Konfigurační profily',
      characteristics: 'Charakteristiky',
      characteristicsSelect: 'Výběr charakteristiky',
      formManagement: 'Formuláře',
      registers: 'Uživatelské číselníky',
      dialsManagement: 'Číselníky',
      configType: 'Typ konfigurace',
      configFormType: 'Formulář',
      formField: 'Pole formuláře',
      tsmModule: 'Moduly TSM',
      microservice: 'Microservice',
      entityType: 'Typ entity',
      mnemonics: 'Mnemoniky',
      swagger: 'Swagger',
      tsmPublicApi: 'tSM Public API',
      dbbridge: 'dbBridge - reload',
      fluentFormDesigner: 'Fluent form designer',
      fluentForm: 'Fluent form',
      fluentFormMigrations: 'Fluent form migrations',
      treeView: 'Tree view',
      registerValues: 'Hodnoty uživatelských číselníků',
    },
    dms: {
      dms: 'DMS',
      attachmentType: 'Typ přílohy',
      commentType: 'Typ komentářů',
      worklog: 'Logy práce',
      worklogType: 'Typ logů práce',
      kafkaTopic: 'Kafka topic',
      kafkaDlq: 'Kafka DLQ',
      helloWorld: 'Hello World!',
      fraud: 'Výpověď fraud NT',
      bill: 'Vzor účastnické smlouvy NT (fyzická osoba)',
      service: 'Před. serv. prot. (fyzická osoba)',
      serviceB2b: 'Před. serv. prot. (podnikatel)',
      serviceB2business: 'Před. serv. prot. (právnická osoba)',
      oneTimeBill: 'Jednorázová faktura',
      pewReports: 'Pew reporty',
      mail: 'Pošta',
      serviceOffer: 'Nabídka služby',
      mobileTree: 'Mobilní strom',
      customWidgets: 'Vlastní Widgety',
      iconPicker: 'TSM VÝBĚR IKON',
      tiptapEditor: 'TSM TIPTAP EDITOR',
      form: 'Vstupní formulář',
      audit: 'Auditní sandbox',
      ntr: 'NTR Faktura',
    },
    settings: {
      settings: 'Nastavení',
      sla: 'SLA',
      tableColumnConfiguration: 'Konfigurace sloupců tabulek',
      listingType: 'Listing Type',
      listing: 'Listing',
      listingColumns: 'Sloupečky listingu',
      listingProfile: 'Profil seznamu',
      myListingProfiles: 'Moje profily seznamů',
      backupSets: 'Soubory záloh',
      backupSetImports: 'Importy souborů záloh',
      listingProfileLov: 'Profil',
      valueConverterConfig: 'Konfigurace konvertorů hodnot',
      dialsManagement: 'Číselníky',
      deliveryTime: 'Čas doručení',
      calendars: 'Kalendáře',
      calendarManagement: 'Správa kalendářů',
      calendarEventType: 'Typ události kalendáře',
      calendarEventList: 'List událostí kalendáře',
      calendarTemplate: 'List šablon kalendáře',
      addressManagement: 'Správa adres',
      kaflaDlq: 'Kafka - neúspěšné zprávy',
      reporting: 'Reporting',
      scripting: {
        scripting: 'Skriptování',
        groovyConsole: 'Groovy konzole',
        tqlConsole: 'TQL konzole',
        spelConsole: 'Spel konzole',
        pythonScriptConsole: 'Python skript konzole',
        ansibleConsole: 'Ansible konzole',
        spelScript: 'Spel Scripty',
      },
    },
    listing: {
      listing: 'Listing',
    },
    preventiveMaintenance: {
      preventiveMaintenance: 'Preventivní údržba',
      maintenancePlan: 'Plán údržby',
      maintenancePlans: 'Plány údržby',
      netInventoryPU: 'Network Inventory PU',
      registers: 'Číselníky',
      activityCatalogPu: 'Katalog činností PU',
      netInventCatalogPu: 'Katalog Network Inventory PU',
      maintenanceActivityTypeGroup: 'Skupina typu aktivit údržby',
      maintenanceActivityTypeGroups: 'Skupiny typů aktivit',
      maintenanceActivityType: 'Typ aktivity údržby',
      maintenanceActivityTypes: 'Typy aktivit',
      maintenanceActivityRelationTypes: 'Typy vazeb aktivit',
      territorialException: 'Územní výjimka',
      territorialExceptions: 'Územní výjimky',
      elementTypeActivity: 'Aktivity na typu prvku',
      excludedPeriod: 'Vyloučené období',
      excludedPeriods: 'Vyloučená období',
      material: 'Materiál',
      means: 'Prostředky',
      maintenancePlanTemplate: 'Šablona plánu údržby',
      maintenancePlanTemplates: 'Šablony plánů údržby',
      maintenanceTemplateRegion: 'Rajon šablony údržby',
      maintenanceActivityInventory: 'Inventář údržbových činností',
      maintenanceActivityRelatedList:
        'Seznam souvisejících údržbových činností',
      maintenancePlanActivity: 'Varianta plánu preventivní údržby',
      maintenancePreventive: 'Preventivní údržbová činnost',
      maintenancePreventiveTaskList: 'Seznam úkolů preventivní údržby',
      maintenancePlanTaskList: 'Seznam úkolů plánu preventivní údržby',
      maintenancePreventiveImplementation:
        'Stav implementace plánu preventivní údržby',
      maintenanceActivitySteps: 'Kroky údržbových činností',
      maintenancePlanSteps: 'Kroky plánu údržby',
      elementTypeRegion: 'Typy prvků v rajonu',
      maintenancePlanType: 'Typ plánu údržby',
      maintenanceActivity: 'Aktivita údržby',
      maintenanceActivities: 'Aktivity údržby',
      maintenancePlanVariant: 'Varianty plánu údržby',
      config: 'Konfigurace',
      catalogElement: 'Katalog prvků pro PÚ',
      catalogMaterial: 'Katalog materiálu',
      catalogMeans: 'Katalog prostředků',
      maintenanceCostCategory: 'Kategorie nákladů údržby',
      maintenanceCostCategoryShort: 'Kategorie nákladů',
    },
    positionSelector: {
      portrayalInMenu: 'Zobrazení v menu',
      addRegister: 'Přidat tento číselník jako novou položku do menu.',
      locationInMenu: 'Umístění v menu',
      menuKey:
        'Klíč menu (celá cesta), kam se má položka umístit (např. tickets + registers)',
      menuItemTitle: 'Název položky v menu',
      itemIcon: "Ikona položky (viz. manuál) - např. 'tsm-icon-form'",
      menuPrivileges: 'Oprávnění nutné pro zobrazení položky v menu',
    },
    logging: {
      registers: 'Číselníky',
      logType: 'Typ logu',
      logging: 'Logy',
      ticketing: 'Tiketing',
      ordering: 'Ordering',
    },
    notifications: {
      notificationTemplates: 'Šablony notifikací',
      notificationRules: 'Typy notifikací',
      notifications: 'Notifikace',
      registers: 'Číselníky',
      deliveryTime: 'Čas doručení',
    },
    outputManagement: {
      outputManagement: 'Output management',
      templates: 'Layout',
      documentTemplates: 'Šablony',
    },
  },
  validation: {
    title: 'Validace',
    messages: {
      requiredValue: 'Tato hodnota je povinná',
      valueMinLength:
        'Hodnota ({{actualLength}}) nemá minimální požadovanou délku {{requiredLength}}',
      valueMaxLength:
        'Hodnota ({{actualLength}}) překračuje maximální povolenou délku {{requiredLength}}',
      valueMinLengthForms:
        'Hodnota ({{currentLength}}) nemá minimální požadovanou délku {{minimumLength}}',
      valueMaxLengthForms:
        'Hodnota ({{currentLength}}) překračuje maximální povolenou délku {{maximumLength}}',
      min: 'Zadaná hodnota ({{actual}}) je menší než {{min}}',
      max: 'Zadaná hodnota ({{actual}}) je větší než {{max}}',
      email: 'Zadaná hodnota neodpovídá formátu emailu',
      pattern:
        'Zadaná hodnota neodpovídá regulárnímu výrazu [{{requiredPattern}}]',
      compareDates:
        'Datum "Platí od" musí být menší nebo rovno datumu "Platí do"',
      valueUsed: 'Zadaná hodnota je již použita',
      minimum: 'Musí být větší nebo rovno {{minimumValue}}',
      maximum: 'Musí být menší nebo rovno {{maximumValue}}',
      profileNameUsed: 'Jméno profilu musí být unikátní',
      profileCodeUsed: 'Kód profilu musí být unikátní',
    },
  },
  restoreInfo: {
    title: 'Výsledek porovnání aktuálního stavu oproti záloze',
    titlePast: 'Výsledek porovnání předcházejícího stavu oproti záloze',
    total: 'Počet záznamů v importované záloze',
    created1: 'záznam vytvořen',
    created234: 'záznamy vyvtořeny',
    created5: 'záznamů vytvořeno',
    edited1: 'záznam upraven',
    edited234: 'záznamy upraveny',
    edited5: 'záznamů upraveno',
    deleted1: 'záznam smazán',
    deleted234: 'záznamy smazány',
    deleted5: 'záznamů smazáno',
    compared1: 'záznam porovnán',
    compared234: 'záznamy porovnány',
    compared5: 'záznamů porovnáno',
    filterMismatch1: 'záznam neodpovídá filtrům použitým v záloze',
    filterMismatch234: 'záznamy neodpovídá filtrům použitým v záloze',
    filterMismatch5: 'záznamů neodpovídá filtrům použitým v záloze',
    willCreated1: 'záznam bude vytvořen',
    willCreated234: 'záznamy budou vyvtořeny',
    willCreated5: 'záznamů bude vytvořeno',
    willEdited1: 'záznam bude upraven',
    willEdited234: 'záznamy budou upraveny',
    willEdited5: 'záznamů bude upraveno',
    willDeleted1: 'záznam bude smazán',
    willDeleted234: 'záznamy budou smazány',
    willDeleted5: 'záznamů bude smazáno',
    willFilterMismatch1: 'záznam aktuálně neodpovídá filtrům použitým v záloze',
    willFilterMismatch234:
      'záznamy aktuálně neodpovídá filtrům použitým v záloze',
    willFilterMismatch5:
      'záznamů aktuálně neodpovídá filtrům použitým v záloze',
    noChanges: 'Mezi aktuálním stavem a zálohou nebyly nalezeny žádné změny.',
    tooltip:
      'Uvedené počty záznamů platí při zpracování všech záznamů z tabulky níže.',
    selectedRecords: 'Počet záznamů vybraných pro zpracování',
  },
};
