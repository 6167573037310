import {merge, Observable, switchMap, timer} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';

export function debounceLoading(
  obs: Observable<boolean>,
  options: {
    minLoadingTime: number;
  },
): Observable<boolean> {
  return new Observable<boolean>((sub) => {
    const trueValues$ = obs.pipe(
      filter((value) => value === true),
      switchMap(() =>
        timer(options.minLoadingTime).pipe(
          takeUntil(obs.pipe(filter((value) => value === false))),
          map(() => true),
        ),
      ),
    );

    const falseValues$ = obs.pipe(filter((value) => value === false));

    const subscription = merge(trueValues$, falseValues$).subscribe({
      next: (s) => sub.next(s),
      error: (s) => sub.error(s),
    });

    return () => subscription.unsubscribe();
  });
}
