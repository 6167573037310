import {NgModule, Optional, ModuleWithProviders, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {COMPONENTS} from './index';
import {DynamicComponentConfigurationOptionsNext} from './models';
import {DynamicComponentService} from './services/dynamic-component.service';
import {DYNAMIC_COMPONENT_CONFIGURATION_NEXT} from './di/tokens';

@NgModule({
  imports: [CommonModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class DynamicComponentModule {
  constructor(
    service: DynamicComponentService,
    @Optional()
    @Inject(DYNAMIC_COMPONENT_CONFIGURATION_NEXT)
    options_next: DynamicComponentConfigurationOptionsNext[],
  ) {
    if (options_next) {
      options_next.forEach((option) => service.addConfigurationNext(option));
    }
  }

  static withComponents(
    options: DynamicComponentConfigurationOptionsNext,
  ): ModuleWithProviders<DynamicComponentModule> {
    return {
      ngModule: DynamicComponentModule,
      providers: [
        {
          provide: DYNAMIC_COMPONENT_CONFIGURATION_NEXT,
          useValue: options,
          multi: true,
        },
      ],
    };
  }
}
