import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {Comment} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommentDataService {
  private readonly COMMENT = `comment`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getCommentByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<Comment[]>> {
    return this.apiService.get<Comment[], Comment[]>(
      `${this.BASE_URL}/${this.COMMENT}/${ownerType}/${ownerId}`,
    );
  }

  saveComment(
    ownerId: string,
    ownerType: string,
    comment: Comment,
  ): Observable<Envelope<Comment>> {
    return this.apiService.post<Comment, Comment>(
      `${this.BASE_URL}/${this.COMMENT}/${ownerType}/${ownerId}`,
      comment,
    );
  }

  deleteCommentById(id: string): Observable<Envelope<Comment>> {
    return this.apiService.delete<Comment, Comment>(
      `${this.BASE_URL}/${this.COMMENT}/${id}`,
    );
  }

  countComment(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<number>> {
    return this.apiService.get<number, number>(
      `${this.BASE_URL}/${this.COMMENT}/count/${ownerType}/${ownerId}`,
    );
  }
}
