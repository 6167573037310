import {createAction, props} from '@ngrx/store';
import {FormatterDocumentTemplate} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadFormatterDocumentTemplateById = createAction(
  '[FormatterDocumentTemplate] Load data',
  props<{
    id: string;
  }>(),
);
export const LoadFormatterDocumentTemplateByIdSuccess = createAction(
  '[FormatterDocumentTemplate] Load data Success',
  props<{
    formatterDocumentTemplate: FormatterDocumentTemplate;
  }>(),
);
export const LoadFormatterDocumentTemplateByIdError = createAction(
  '[FormatterDocumentTemplate] Load data ERROR',
  props<{
    id: string;
    error: any;
  }>(),
);

export const UpsertFormatterDocumentTemplate = createAction(
  '[FormatterDocumentTemplate] Upsert data',
  props<{
    formatterDocumentTemplate: FormatterDocumentTemplate;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertFormatterDocumentTemplateSuccess = createAction(
  '[FormatterDocumentTemplate] Upsert data Success',
  props<{
    formatterDocumentTemplate: FormatterDocumentTemplate;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertFormatterDocumentTemplateError = createAction(
  '[FormatterDocumentTemplate] Upsert data Error',
  props<{
    error: any;
  }>(),
);

export const DeleteFormatterDocumentTemplate = createAction(
  '[FormatterDocumentTemplate] Delete data',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteFormatterDocumentTemplateSuccess = createAction(
  '[FormatterDocumentTemplate] Delete data Success',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteFormatterDocumentTemplateError = createAction(
  '[FormatterDocumentTemplate] Delete data Error',
  props<TsmError>(),
);

export const DiffFormatterDocumentTemplate = createAction(
  '[FormatterDocumentTemplate] Diff FormatterDocumentTemplate',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffFormatterDocumentTemplateSuccess = createAction(
  '[FormatterDocumentTemplate] Diff FormatterDocumentTemplate Success',
  props<{
    formatterDocumentTemplates: FormatterDocumentTemplate[];
    listingId: string;
  }>(),
);
export const DiffFormatterDocumentTemplateError = createAction(
  '[FormatterDocumentTemplate] Diff FormatterDocumentTemplate Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
