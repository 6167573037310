export const cs = {
  history: {
    historyTooltip: 'Upravit zobrazení historie',
    deleteAll: 'Smazat historii',
    history: 'Historie',
  },
  layoutTsm: {
    searchPlaceholder: '"Search menus, shortcuts, contact and more..',
    changeLanguage: 'Změnit jazyk aplikace',
    debugMessage: 'On/Off debuggingu aplikace',
    changeGraphics: 'Změnit grafický mód aplikace',
    addAnyItem: 'Přidejte libovolnou položku z hlavního menu (drag/drop)',
    clickToSave: 'Pro uložení úprav, prosím potvrďte kliknutím zde',
    loading: 'Načítám',
    logOut: 'Odhlásit se',
    endYourSession: 'Ukončete relaci',
    noMessages: 'Nemáte žádné zprávy',
    deleteMsg: 'Smazat zprávu',
    notifications: 'Notifikace',
    notificationsHotkeyText: 'Zobrazit notifikace',
    notification: 'Oznámení',
    history: 'Historie',
    historyHotkeyText: 'Zobrazit panel s historií',
    unreadOnly: 'Jen nepřečtené',
    noAlerts: 'Žádná upozornění',
    deleteNotification: 'Smazat oznámení',
    language: 'Jazyk',
    debug: 'Debug',
    on: 'On',
    off: 'Off',
    czech: 'CS',
    english: 'EN',
    all: 'Vše',
    colorMode: 'Barevný režim',
    mode: 'Režim',
    darkTheme: 'Tmavý',
    lightTheme: 'Světlý',
    switchToDark: 'Přepněte do tmavého režimu',
    switchToDarkTip: 'Přepněte do tmavého režimu a ušetřete si oči',
    switchToLight: 'Přepněte do světlého režimu',
    switchToLightTip:
      'Přepněte do světelného režimu pro optimální uživatelský zážitek',
    density: {
      density: 'Kompaktnost',
      default: 'Výchozí',
      comfortable: 'Nízká',
      compact: 'Vysoká',
    },
    logoTooltip: 'Přejít na hlavní stránku aplikace',
    logoAccessibilityText: 'Ikona - Logo aplikace',
    showHideMenu: 'Zobrazit/skrýt menu',
    myProfile: 'Můj profil',
    goToYourProfile: 'Přejděte na svůj profil',
    recent: 'Naposledy zobrazené',
  },
  menuDesigner: {
    addMenuItem: 'Nová položka menu',
    editMenuItem: 'Editace položky menu',
    saveAllMenu: 'Uložit celou strukturu menu',
    menuSettings: 'Nastavení menu',
    resultMenu: 'Výsledné menu, které se bude zobrazovat',
    tmpMenu:
      'Odkládací plocha pro položky menu, které nebudou ve výsledném menu vidět.',
    emptyMenu:
      'Přetáhněte sem položky z levého sloupce, které chcete skrýt nebo dočasně odložit',
    uniqueCode: 'Zadejte prosim jiný kód. Kód musí být v celém menu unikátní!',
    spaceMenu:
      'Položka menu musí být přesunuta do mezery mezi jednotlivé tlačítka.',
    newUserRegister: 'Vytvořit položku menu pro uživatelský číselník',
    listingType: 'Typ listingu',
    routerLink: 'Router link',
    url: 'URL',
    queryParams: 'Parametry dotazu',
  },
  menuPinned: {
    header: 'Režim menu',
    overlayMode: 'Automaticky skrývat menu (výchozí)',
    overlayModeDescription:
      'Základní nastavení. Při aktivaci této volby menu při zobrazení překrývá obsah, po kliknutí na položku dojde k jeho automatickému skrytí.',
    staticMode: 'Statický',
    staticModeDescription:
      'Menu při zobrazení nepřekrývá (odsouvá) obsah, po kliknutí na položku se NEskryje.',
  },
};
