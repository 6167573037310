import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {NotificationRule} from '../models';

export const LoadNotificationRuleById = createAction(
  '[NotificationRule] Load data',
  props<{id: string}>(),
);
export const LoadNotificationRuleByIdSuccess = createAction(
  '[NotificationRule] Load data Success',
  props<{
    notificationRule: NotificationRule;
  }>(),
);
export const LoadNotificationRuleByIdError = createAction(
  '[NotificationRule] Load data ERROR',
  props<{
    id: string;
    error: any;
  }>(),
);

export const UpsertNotificationRule = createAction(
  '[NotificationRule] Upsert data',
  props<{
    notificationRule: NotificationRule;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertNotificationRuleSuccess = createAction(
  '[NotificationRule] Upsert data Success',
  props<{
    notificationRule: NotificationRule;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertNotificationRuleError = createAction(
  '[NotificationRule] Upsert data Error',
  props<{
    error: any;
  }>(),
);

export const DeleteNotificationRule = createAction(
  '[NotificationRule] Delete data',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteNotificationRuleSuccess = createAction(
  '[NotificationRule] Delete data Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteNotificationRuleError = createAction(
  '[NotificationRule] Delete data Error',
  props<TsmError>(),
);

export const DiffNotificationRule = createAction(
  '[NotificationRule] Diff NotificationRule',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffNotificationRuleSuccess = createAction(
  '[NotificationRule] Diff NotificationRule Success',
  props<{
    notificationRules: NotificationRule[];
    listingId: string;
  }>(),
);
export const DiffNotificationRuleError = createAction(
  '[NotificationRule] Diff NotificationRule Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);

export const LoadNotificationRuleByCode = createAction(
  '[NotificationRule] Load NotificationRuleByCode',
  props<{
    code: string;
  }>(),
);
export const LoadNotificationRuleByCodeSuccess = createAction(
  '[NotificationRule] Load NotificationRuleByCode Success',
  props<{
    notificationRule: NotificationRule;
  }>(),
);
export const LoadNotificationRuleByCodeError = createAction(
  '[NotificationRule] Load NotificationRuleByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);
