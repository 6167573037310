import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map, mergeMap} from 'rxjs/operators';
import {
  DeleteUserGroupSkill,
  DeleteUserGroupSkillError,
  DeleteUserGroupSkillSuccess,
  LoadUserGroupSkillsBySkillId,
  LoadUserGroupSkillsBySkillIdError,
  LoadUserGroupSkillsBySkillIds,
  LoadUserGroupSkillsBySkillIdSuccess,
  LoadUserGroupSkillsByUserGroupId,
  LoadUserGroupSkillsByUserGroupIdError,
  LoadUserGroupSkillsByUserGroupIds,
  LoadUserGroupSkillsByUserGroupIdSuccess,
  LoadUserGroupSkillsByUserId,
  LoadUserGroupSkillsByUserIdError,
  LoadUserGroupSkillsByUserIds,
  LoadUserGroupSkillsByUserIdSuccess,
  UpsertMoreUserGroupSkill,
  UpsertMoreUserGroupSkillError,
  UpsertMoreUserGroupSkillSuccess,
  UpsertUserGroupSkill,
  UpsertUserGroupSkillError,
  UpsertUserGroupSkills,
  UpsertUserGroupSkillsError,
  UpsertUserGroupSkillsSuccess,
  UpsertUserGroupSkillSuccess,
} from '../actions';
import {EntityIdentif, UserGroupSkill} from '../model';
import {CommonApiService, UserGroupSkillService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {translation} from '../i18n';

@Injectable()
export class UserGroupSkillEffects {
  translation = translation;

  loadMultipleByUserIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupSkillsByUserIds),
      mergeMap((action) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_GROUP_SKILL,
            'userGroup.id__in=' + action.userIds.join(','),
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupSkillsByUserIdSuccess({entities: env.data})
                : LoadUserGroupSkillsByUserIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadMultipleByUserGroupIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupSkillsByUserGroupIds),
      mergeMap((action) => {
        return this.commonApiService
          .getAllFilterable(
            'v1/' + EntityIdentif.USER_GROUP_SKILL,
            'userGroup.id__in=' + action.userIds.join(','),
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupSkillsByUserGroupIdSuccess({entities: env.data})
                : LoadUserGroupSkillsByUserGroupIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupSkillsByUserId),
      exhaustMap(({userId}) => {
        return this.commonApiService
          .getAllFilterable(
            'v1/' + EntityIdentif.USER_GROUP_SKILL,
            'userGroup.id__eq=' + userId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupSkillsByUserIdSuccess({entities: env.data})
                : LoadUserGroupSkillsByUserIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByUserGroupId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupSkillsByUserGroupId),
      exhaustMap(({userId}) => {
        return this.commonApiService
          .getAllFilterable(
            'v1/' + EntityIdentif.USER_GROUP_SKILL,
            'userGroup.id__eq=' + userId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupSkillsByUserGroupIdSuccess({entities: env.data})
                : LoadUserGroupSkillsByUserGroupIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadBySkillId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupSkillsBySkillId),
      exhaustMap(({skillId}) => {
        return this.commonApiService
          .getAllFilterable(
            'v1/' + EntityIdentif.USER_GROUP_SKILL,
            'entityCatalogSpecificationId__eq=' + skillId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupSkillsBySkillIdSuccess({entities: env.data})
                : LoadUserGroupSkillsBySkillIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadMultipleBySkillIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupSkillsBySkillIds),
      mergeMap((action) => {
        return this.commonApiService
          .getAllFilterable(
            'v1/' + EntityIdentif.USER_GROUP_SKILL,
            'entityCatalogSpecificationId.id__in=' + action.skillIds.join(','),
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserGroupSkillsBySkillIdSuccess({entities: env.data})
                : LoadUserGroupSkillsBySkillIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroupSkill),
      concatMap(({userGroupSkill}) => {
        return this.commonApiService
          .upsertEntity(
            'v1/' + EntityIdentif.USER_GROUP_SKILL,
            userGroupSkill,
            userGroupSkill.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.skillSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserGroupSkillSuccess({userGroupSkill: env.data});
              } else {
                const message =
                  env.error.serverError === 'PrintableError'
                    ? translation.userManagementService.effects
                        .skillAlreadyAssigned
                    : translation.userManagementService.effects.regionSaveError;
                this.ts.showError(env.error, message);
                return UpsertUserGroupSkillError(env.error);
              }
            }),
          );
      }),
    ),
  );

  upserts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroupSkills),
      concatMap(({entities}) => {
        return this.commonApiService
          .postAllEntities('v1/' + EntityIdentif.USER_GROUP_SKILL, entities)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.skillSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserGroupSkillsSuccess({entities: env.data});
              } else {
                const message =
                  env.error.serverError === 'PrintableError'
                    ? translation.userManagementService.effects
                        .skillAlreadyAssigned
                    : translation.userManagementService.effects.regionSaveError;
                this.ts.showError(env.error, message);
                return UpsertUserGroupSkillsError(env.error);
              }
            }),
          );
      }),
    ),
  );

  upsertMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMoreUserGroupSkill),
      concatMap(({rows}) =>
        this.userGroupSkillService.upsertMoreUserGroupSkill(rows).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.skillSaveSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return UpsertMoreUserGroupSkillSuccess({entities: env.data});
            } else {
              const message =
                env.error.serverError === 'PrintableError'
                  ? translation.userManagementService.effects
                      .skillAlreadyAssigned
                  : translation.userManagementService.effects.regionSaveError;

              this.ts.showError(env.error, message);
              return UpsertMoreUserGroupSkillError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserGroupSkill),
      concatMap(({id}) =>
        this.commonApiService
          .deleteEntity('v1/' + EntityIdentif.USER_GROUP_SKILL, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.skillDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteUserGroupSkillSuccess({id: id});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.skillDeleteFailure,
                );
                return DeleteUserGroupSkillError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<UserGroupSkill, UserGroupSkill>,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
    private userGroupSkillService: UserGroupSkillService,
  ) {}
}
