export const cs = {
  dmsPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmCommentTypeFilter: 'tSM Comment Type Filter',
      tsmCommentTypeFilterDescription:
        'Filtruje typy komentářů pomocí výběrového panelu. ' +
        'Načítá platné typy komentářů ze store a filtruje je ' +
        'na základě zadaného kontextu. Zobrazuje vybrané typy ' +
        'a umožňuje jejich potvrzení nebo zrušení.',
      tsmCommentUserTypeFilter: 'tSM Comment User Type Filter',
      tsmCommentUserTypeFilterDescription:
        'Filtruje typy uživatelských komentářů pomocí výběrového seznamu. ' +
        'Umožňuje výběr mezi typy individuální a organizace. ' +
        'Aplikuje filtr rovnosti a aktualizuje zobrazovanou hodnotu.',
      tsmDeliveryItemTypeFilter: 'tSM Delivery Item Type Filter',
      tsmDeliveryItemTypeFilterDescription:
        'Filtruje typy doručovacích položek pomocí výběrového seznamu. ' +
        'Zobrazuje dostupné typy ve formátu tabulky s možností více výběrů. ' +
        'Načítá možnosti ze služby a umožňuje uživatelům vybrat více položek. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazovanou reprezentaci. ' +
        'Podporuje výběr všech nebo jednotlivých položek a dynamicky aktualizuje výběr.',
      tsmFormatterDocumentSizeFilter: 'tSM Formatter Document Size Filter',
      tsmFormatterDocumentSizeFilterDescription:
        'Filtruje velikosti dokumentů formátovacího nástroje pomocí volitelného seznamu. ' +
        'Zobrazuje dostupné velikosti ve formátu tabulky s možností více výběrů. ' +
        'Načítá možnosti ze služby a umožňuje uživatelům vybrat více položek. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazovanou reprezentaci. ' +
        'Podporuje výběr všech nebo jednotlivých položek a dynamicky aktualizuje výběr.',
      tsmFormatterDocumentTemplateFilter:
        'tSM Formatter Document Template Filter',
      tsmFormatterDocumentTemplateFilterDescription:
        'Filtruje šablony dokumentů formátovacího nástroje pomocí výběrového seznamu. ' +
        'Zobrazuje dostupné šablony ve formátu tabulky s možností více výběrů. ' +
        'Dynamicky načítá možnosti ze služby a umožňuje uživatelům filtrovat a vybírat položky. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazovanou reprezentaci. ' +
        'Podporuje řazení, vyhledávání a efektivní načítání dat.',
      tsmFormatterDocumentTypeFilter: 'tSM Formatter Document Type Filter',
      tsmFormatterDocumentTypeFilterDescription:
        'Filtruje typy dokumentů formátovacího nástroje pomocí výběrového seznamu. ' +
        'Zobrazuje dostupné typy dokumentů ve formátu tabulky s možností více výběrů. ' +
        'Dynamicky načítá možnosti ze služby a umožňuje uživatelům filtrovat a vybírat položky. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazovanou reprezentaci. ' +
        'Podporuje řazení, vyhledávání a efektivní načítání dat.',
      tsmFormatterInputTypeFilter: 'tSM Formatter Input Type Filter',
      tsmFormatterInputTypeFilterDescription:
        'Filtruje typy vstupů formátovacího nástroje pomocí výběrového seznamu. ' +
        'Zobrazuje dostupné vstupní typy ve formátu tabulky s možností více výběrů. ' +
        'Dynamicky načítá možnosti ze služby a umožňuje uživatelům filtrovat a vybírat položky. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazovanou reprezentaci. ' +
        'Podporuje řazení, vyhledávání a efektivní načítání dat.',
      tsmFormatterOutputTypeFilter: 'tSM Formatter Output Type Filter',
      tsmFormatterOutputTypeFilterDescription:
        'Filtruje typy výstupů formátovacího nástroje pomocí výběrového seznamu. ' +
        'Zobrazuje dostupné výstupní typy ve formátu tabulky s možností více výběrů. ' +
        'Dynamicky načítá možnosti ze služby a umožňuje uživatelům filtrovat a vybírat položky. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazovanou reprezentaci. ' +
        'Podporuje řazení, vyhledávání a efektivní načítání dat.',
      tsmKafkaDlqStatusFilter: 'tSM Kafka Dlq Status Filter',
      tsmKafkaDlqStatusFilterDescription:
        'Filtruje stavy Kafka DLQ logů pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupné stavy s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti stavů ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje řazení, vyhledávání a efektivní práci s daty.',
      tsmNotificationRuleEventTypeFilter:
        'tSM Notification Rule Event Type Filter',
      tsmNotificationRuleEventTypeFilterDescription:
        'Filtruje typy událostí pravidel notifikací pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupné typy událostí s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti typů událostí ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje řazení, vyhledávání a efektivní práci s daty.',
      tsmNotificationRuleFilter: 'tSM Notification Rule Filter',
      tsmNotificationRuleFilterDescription:
        'Filtruje pravidla notifikací pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupná pravidla notifikací s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti pravidel ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje vyhledávání a filtrování podle názvu a kódu pravidla.',
      tsmNotificationStatusFilter: 'tSM Notification Status Filter',
      tsmNotificationStatusFilterDescription:
        'Filtruje stavy notifikací pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupné stavy s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti stavů ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje vyhledávání a filtrování podle názvu stavu.',
      tsmNotificationTemplateCategoryFilter:
        'tSM Notification Template Category Filter',
      tsmNotificationTemplateCategoryFilterDescription:
        'Filtruje kategorie šablon notifikací pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupné kategorie s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti kategorií ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje vyhledávání a filtrování podle názvu kategorie.',
      tsmNotificationTemplateStatusFilter:
        'tSM Notification Template Status Filter',
      tsmNotificationTemplateStatusFilterDescription:
        'Filtruje stavy šablon notifikací pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupné stavy s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti stavů ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje vyhledávání a filtrování podle názvu stavu.',
      tsmNotificationTypeFilter: 'tSM Notification Type Filter',
      tsmNotificationTypeFilterDescription:
        'Filtruje typy notifikací pomocí rozhraní pro výběr v tabulce. ' +
        'Zobrazuje dostupné typy s podporou vícenásobného výběru. ' +
        'Dynamicky načítá možnosti typů ze služby. ' +
        'Aplikuje vybrané hodnoty a aktualizuje zobrazená kritéria filtru. ' +
        'Podporuje vyhledávání a filtrování podle názvu typu.',
      tsmUserUsergroupRefFilter: 'tSM User Usergroup Ref Filter',
      tsmUserUsergroupRefFilterDescription:
        'Filtruje uživatelské skupiny pomocí komponenty výběru na základě reference. ' +
        'Zobrazuje dostupné skupiny a umožňuje vícenásobný výběr. ' +
        'Ukládá vybrané hodnoty a aplikuje je na kritéria filtru. ' +
        'Používá překryvný panel pro výběr s možnostmi potvrzení a zrušení. ' +
        'Podporuje filtrování podle referenčního názvu skupiny.',
      tsmUserUsergroupRefMultiselectFilter:
        'tSM User Usergroup Ref Multiselect Filter',
      tsmUserUsergroupRefMultiselectFilterDescription:
        'Filtruje uživatele a uživatelské skupiny pomocí tabulky s multi-výběrem. ' +
        'Načítá data ze store a kombinuje záznamy uživatelů a skupin. ' +
        'Podporuje stránkování, řazení a fulltextové vyhledávání. ' +
        'Používá překryvný panel s potvrzením a zrušením výběru. ' +
        'Aplikuje vybrané hodnoty jako filtrační kritéria s operátorem "in".',
    },
    pipes: {
      containsKeyValue: 'Obsahuje klíč a hodnotu',
      containsKeyValueDescription:
        'Určuje, zda struktura obsahuje konkrétní klíč a jeho hodnotu pomocí hloubkového procházení.',
      countAttachments: 'Počet příloh',
      countAttachmentsDescription:
        'Vrací počet příloh přiřazených konkrétnímu vlastníku podle ID a typu.',
      entityNameLoader: 'Načítání názvu entity',
      entityNameLoaderDescription:
        'Načítá data entity, například názvy uživatelů nebo uživatelských skupin z úložiště.',
      formatterDocumentTemplateLoader: 'Načítání šablony dokumentu',
      formatterDocumentTemplateLoaderDescription:
        'Načítá a spravuje data šablon dokumentů z úložiště, zajišťuje přístup ke specifickým polím nebo celému obsahu.',
      formatterTemplateLoader: 'Načítání šablony',
      formatterTemplateLoaderDescription:
        'Načítá a spravuje data šablony z úložiště, umožňuje přístup ke specifickým polím nebo k celé šabloně.',
      minutesToText: 'Minuty na text',
      minutesToTextDescription:
        'Převádí dobu v minutách na čitelný textový formát zobrazený jako hodiny a minuty.',
      notificationAddress: 'Notifikační adresa',
      notificationAddressDescription:
        'Formátuje adresu do čitelného textového řetězce, přednostně využívá formátovanou adresu, ' +
        'nebo kombinuje části, jako jsou ulice, město a PSČ, do stručného výstupu.',
      notificationCustomerById: 'Notifikace zákazníka podle ID',
      notificationCustomerByIdDescription:
        'Získává a vrací klíč zákazníka spojeného s notifikací na základě typu vlastníka.',
      notificationRelatedEntityById: 'Související entita notifikace podle ID',
      notificationRelatedEntityByIdDescription:
        'Načítá související entity pro dané ID notifikace.',
      notificationRuleCode: 'Kód pravidla notifikace',
      notificationRuleCodeDescription:
        'Načítá pravidlo notifikace podle kódu z úložiště, poskytuje specifická pole nebo celý obsah pravidla ' +
        'a zajišťuje načtení dat, pokud nejsou dostupná.',
      notificationTemplateByCode: 'Šablona notifikace podle kódu',
      notificationTemplateByCodeDescription:
        'Načítá šablonu notifikace podle jejího kódu z úložiště a umožňuje přístup k určitým polím nebo celému obsahu.',
      notificationTemplateLoader: 'Načítání šablony notifikace',
      notificationTemplateLoaderDescription:
        'Načítá a poskytuje data šablony notifikace z úložiště podle ID, podporuje výběr specifických polí a zajišťuje ' +
        'načtení dat, pokud nejsou dostupná.',
      safeHtml: 'Bezpečný HTML',
      safeHtmlDescription:
        'Sanitizuje HTML obsah pro bezpečné použití v DOM a předchází bezpečnostním rizikům způsobeným škodlivým kódem.',
      notificationTo: 'Příjemci notifikace',
      notificationToDescription:
        'Na základě ownerType dojde k určení a přeložení, komu byla notifikace zaslána.',
    },
  },
};
