import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, tap} from 'rxjs/operators';
import {
  DeleteScript,
  DeleteScriptError,
  DeleteScriptSuccess,
  DiffScript,
  DiffScriptError,
  DiffScriptSuccess,
  LoadScriptByCode,
  LoadScriptByCodeError,
  LoadScriptByCodeSuccess,
  LoadScriptById,
  LoadScriptByIdError,
  LoadScriptByIdSuccess,
  PatchScript,
  UpsertScript,
  UpsertScriptError,
  UpsertScriptSuccess,
} from '../actions';
import {Router} from '@angular/router';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {CommonApiService, ScriptDataService} from '../services';
import {Script} from '../models';

@Injectable()
export class ScriptEffects {
  translation = translation;
  private readonly API_PATH = 'v1/scripts';

  constructor(
    private actions$: Actions,
    private scriptDataService: ScriptDataService,
    private router: Router,
    private toastService: ToastService,
    private commonApiService: CommonApiService<Script, Script>,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadScriptById),
      concatMap(({id}) =>
        this.scriptDataService
          .get(id)
          .pipe(
            map((env) =>
              env.success && env.data
                ? LoadScriptByIdSuccess({script: env.data})
                : LoadScriptByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadScriptByCode),
      concatMap(({code}) =>
        this.scriptDataService
          .getByCode(code)
          .pipe(
            map((env) =>
              env.success && env.data
                ? LoadScriptByCodeSuccess({script: env.data})
                : LoadScriptByCodeError({code, error: env.error}),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertScript),
      concatMap(({script, listingId, redirect, redirectUrl}) =>
        this.scriptDataService.upsert(script).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.script.upsertSuccess,
                ToastSeverity.SUCCESS,
                5000,
              );
              return UpsertScriptSuccess({
                script: env.data,
                listingId: listingId,
                redirect: redirect,
                redirectUrl,
              });
            } else {
              this.toastService.showError(env.error);
              return UpsertScriptError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatchScript),
      concatMap(({script, listingId, redirect}) =>
        this.scriptDataService.patch(script).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.script.patchSuccess,
                ToastSeverity.SUCCESS,
                5000,
              );
              return UpsertScriptSuccess({
                script: env.data,
                listingId: listingId,
                redirect: redirect,
              });
            } else {
              this.toastService.showError(env.error);
              return UpsertScriptError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteScript),
      concatMap(({id, listingId}) =>
        this.scriptDataService.delete(id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.script.deleteSuccess,
                ToastSeverity.SUCCESS,
                5000,
              );
              return DeleteScriptSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(env.error);
              return DeleteScriptError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffScript),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.script.diffSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffScriptSuccess({
                  scripts: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.script.diffError,
                );
                return DiffScriptError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertScriptSuccess),
      tap(({script, redirect, redirectUrl}) => {
        if (redirect) {
          this.router.navigate(['/scripts/script', script.id]);
        }
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteScriptSuccess, DiffScriptSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
