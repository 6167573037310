import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeHtmlElements',
})
export class RemoveHtmlElementsPipe implements PipeTransform {
  constructor() {}

  transform(value, length = 0, end = '...') {
    if (length === 0) {
      return value?.replace(/<[^>]+>/g, '');
    } else {
      const cleanText = value?.replace(/<[^>]+>/g, '');
      return cleanText?.length > length
        ? cleanText.substring(0, length) + end
        : cleanText;
    }
  }
}
