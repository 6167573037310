import * as attachmentReducer from './attachment.reducer';
import * as commentReducer from './comment.reducer';
import * as newCommentReducer from './new-comment.reducer';
import * as notificationReducer from './notification.reducer';
import * as notificationMyReducer from './notification-my.reducer';
import * as notificationCountReducer from './notification-count.reducer';
import {Action, combineReducers} from '@ngrx/store';
import {EntityState} from '@ngrx/entity';
import * as notificationTemplateReducer from './notification-template.reducer';
import * as formatterTemplateReducer from './formatter-template.reducer';
import * as formatterDocumentTemplateReducer from './formatter-document-template.reducer';
import * as sharingReducer from './sharing.reducer';
import * as fromAttachmentTypeReducer from './attachment-type.reducer';
import * as fromCommentTypeReducer from './comment-type.reducer';
import * as notificationRuleReducer from './notification-rule.reducer';
import * as fromKafkaTopicReducer from './kafka-topic.reducer';
import * as worklogReducer from './worklog.reducer';
import * as worklogTypeReducer from './worklog-type.reducer';
import * as countAttachmentsReducer from './count-attachments.reducer';
import * as worklogTimerReducer from './worklog-timer.reducer';
import * as worklogMyReducer from './worklog-my.reducer';
import * as deliveryTimeReducer from './delivery-time.reducer';
import {IdentifiedPack} from '@tsm/framework/root';
import {NotificationCountState} from '../models';

export {CommentState} from './comment.reducer';
export {NewCommentForm} from './new-comment.reducer';
export {WorklogState} from './worklog.reducer';
export {AttachmentState} from './attachment.reducer';

export interface DmsState {
  attachment: EntityState<attachmentReducer.AttachmentState>;
  sharing: sharingReducer.SharingState;
  comments: EntityState<commentReducer.CommentState>;
  newComment: EntityState<newCommentReducer.NewCommentForm>;
  notification: EntityState<notificationReducer.NotificationState>;
  notificationMy: EntityState<notificationMyReducer.NotificationMyState>;
  notificationCount: EntityState<IdentifiedPack<NotificationCountState>>;
  notificationTemplate: notificationTemplateReducer.NotificationTemplateState;
  notificationRules: notificationRuleReducer.NotificationRuleState;
  formatterTemplate: formatterTemplateReducer.FormatterTemplateState;
  formatterDocumentTemplate: formatterDocumentTemplateReducer.FormatterDocumentTemplateState;
  attachmentType: fromAttachmentTypeReducer.AttachmentTypeState;
  commentType: fromCommentTypeReducer.CommentTypeState;
  worklog: EntityState<worklogReducer.WorklogState>;
  worklogTimer: EntityState<worklogTimerReducer.WorklogTimerState>;
  worklogType: worklogTypeReducer.WorklogTypeState;
  worklogMy: EntityState<worklogMyReducer.WorklogMyState>;
  kafkaTopic: fromKafkaTopicReducer.KafkaTopicState;
  countAttachments: countAttachmentsReducer.CountAttachmentsState;
  deliveryTime: deliveryTimeReducer.DeliveryTimeState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: DmsState | undefined, action: Action) {
  return combineReducers({
    attachment: attachmentReducer.attachmentsReducer,
    comments: commentReducer.commentsReducer,
    newComment: newCommentReducer.newCommentReducer,
    sharing: sharingReducer.sharingReducer,
    notification: notificationReducer.notificationReducer,
    notificationMy: notificationMyReducer.notificationMyReducer,
    notificationCount: notificationCountReducer.notificationCountReducer,
    notificationTemplate:
      notificationTemplateReducer.notificationTemplateReducer,
    notificationRules: notificationRuleReducer.notificationRuleReducer,
    formatterTemplate: formatterTemplateReducer.formatterTemplateReducer,
    formatterDocumentTemplate:
      formatterDocumentTemplateReducer.formatterDocumentTemplateReducer,
    attachmentType: fromAttachmentTypeReducer.attachmentTypeReducer,
    commentType: fromCommentTypeReducer.commentTypeReducer,
    worklog: worklogReducer.worklogsReducer,
    worklogTimer: worklogTimerReducer.worklogTimerReducer,
    worklogType: worklogTypeReducer.worklogTypeReducer,
    worklogMy: worklogMyReducer.worklogMyReducer,
    kafkaTopic: fromKafkaTopicReducer.kafkaTopicReducer,
    countAttachments: countAttachmentsReducer.countAttachmentsReducer,
    deliveryTime: deliveryTimeReducer.deliveryTimeReducer,
  })(state, action);
}
