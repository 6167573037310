import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@tsm/framework/translate';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {TooltipModule} from 'primeng/tooltip';
import {Menu, MenuItemContent} from './menu/menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        ConfirmPopupModule,
        TooltipModule,
        Menu, MenuItemContent,
    ],
    exports: [Menu, RouterModule],
})
export class MenuModule {}
