import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/access-rule.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getAccessRuleState = createSelector(
  getUserManagementState,
  (userManagementState) => userManagementState.accessRule,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getAccessRuleState);

export const selectAccessRuleByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectAccessRuleById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectAllAccessRules = selectAll;
