import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {EntityIdentif, Priv} from '../model';
import {CommonApiService} from '../service';
import {
  DeletePriv,
  DeletePrivError,
  DeletePrivSuccess,
  DiffPrivs,
  DiffPrivsError,
  DiffPrivsSuccess,
  LoadPrivByCode,
  LoadPrivByCodeError,
  LoadPrivByCodeSuccess,
  LoadPrivById,
  LoadPrivByIdError,
  LoadPrivByIdSuccess,
  LoadPrivs,
  LoadPrivsError,
  LoadPrivsSuccess,
  UpsertPriv,
  UpsertPrivError,
  UpsertPrivSuccess,
} from '../actions';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {translation} from '../i18n';
import {withLatestCached} from '@tsm/framework/root';
import {selectPrivByCode} from '../selectors';
import {of} from 'rxjs';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class PrivEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<Priv, Priv>,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadByPrivId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPrivById),
      exhaustMap(({id}) => {
        return this.commonApiService.getEntity(EntityIdentif.PRIV, id).pipe(
          map((env) => {
            return env.success && env.data
              ? LoadPrivByIdSuccess({priv: env.data})
              : LoadPrivByIdError({error: env.error});
          }),
        );
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPrivByCode),
      withLatestCached((action) =>
        this.store.select(selectPrivByCode(action.code)),
      ),
      concatMap(([action, priv]) => {
        return priv && priv.data
          ? of(LoadPrivByCodeSuccess({priv: priv.data}))
          : this.commonApiService
              .getEntityByCode(EntityIdentif.PRIV, action.code)
              .pipe(
                map((env) =>
                  env.success && env.data
                    ? LoadPrivByCodeSuccess({priv: env.data})
                    : LoadPrivByCodeError({error: env.error}),
                ),
              );
      }),
    ),
  );

  loadByPriv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPrivs),
      exhaustMap(() => {
        return this.commonApiService.getAll(EntityIdentif.PRIV).pipe(
          map((env) => {
            return env.success
              ? LoadPrivsSuccess({entities: env.data})
              : LoadPrivsError({error: env.error});
          }),
        );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertPriv),
      concatMap(({priv, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(EntityIdentif.PRIV, priv, priv.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.privSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertPrivSuccess({
                  priv: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.privSaveFailure,
                );
                return UpsertPrivError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeletePriv),
      concatMap(({id, listingId}) =>
        this.commonApiService.deleteEntity(EntityIdentif.PRIV, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.privDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeletePrivSuccess({id: id, listingId: listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects.privDeleteFailure,
              );
              return DeletePrivError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffPrivs),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/' + EntityIdentif.PRIV, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffPrivsSuccess({
                  privs: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffPrivsError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertPrivSuccess, DeletePrivSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
