import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgZone,
  Optional,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NgClass } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'dtl-form-input-textarea',
    templateUrl: './form-input-textarea.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputTextareaComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FormInputTextareaComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputTextareaComponent),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        InputTextareaModule,
        NgClass,
        TranslocoPipe,
    ],
})
export class FormInputTextareaComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: ElementRef;

  @Input() inputId: string;

  @Input() noneText: string;

  @Input() widgetHeight: any;

  @Input() placeholder: string;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    private ngZone: NgZone,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  @Input()
  set setAutofocus(value: boolean) {
    if (value) {
      this.ngZone.runOutsideAngular(() => {
        this.inputElement.nativeElement.focus();
        this.focused = true;
      });
    }
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.addEventListener('focus', () => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    this.inputElement.nativeElement.addEventListener('blur', () => {
      this.focused = false;
      this.onTouched(true);
      this.cdr.markForCheck();
    });
  }
}
