import {SelectItem} from 'primeng/api/selectitem';

export enum DeliveryItemType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  LETTER = 'LETTER',
  APPLICATION = 'APPLICATION',
  USER_COMMENT = 'USER_COMMENT',
}

export function getAllDeliveryItemTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(DeliveryItemType).forEach((val) =>
    items.push({label: DeliveryItemType[val], value: val}),
  );
  return items;
}
