export const en = {
  serviceManagementPlugins: {
    headerDescription: 'Header docs',
    pipes: {
      consumerStatusIcon: 'Consumer Status Icon',
      consumerStatusIconDescription:
        'Returns an icon representation of the consumer status. Maps each status to a corresponding ' +
        'icon and optional CSS class. Can return either the full HTML element or just the class.',
      serviceStatusIconPipe: 'Service Status Icon Pipe',
      serviceStatusIconPipeDescription:
        'Maps service status to an appropriate icon and label. Returns either the full HTML ' +
        'element or only the class, depending on the provided parameter.',
    },
  },
};
