import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {withLatestCached} from '@tsm/framework/root';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {
  DeleteAccessRule,
  DeleteAccessRuleError,
  DeleteAccessRuleSuccess,
  DiffAccessRule,
  DiffAccessRuleError,
  DiffAccessRuleSuccess,
  LoadAccessRuleById,
  LoadAccessRuleByIdError,
  LoadAccessRuleByIdSuccess,
  LoadAccessRules,
  LoadAccessRulesError,
  LoadAccessRulesSuccess,
  UpsertAccessRule,
  UpsertAccessRuleError,
  UpsertAccessRuleSuccess,
} from '../actions';
import {AccessRule, EntityIdentif} from '../model';
import {selectAccessRuleById} from '../selectors';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class AccessRuleEffects {
  translation = translation;
  translationShared = translationShared;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<AccessRule, AccessRule>,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAccessRuleById),
      withLatestCached((action) =>
        this.store.select(selectAccessRuleById(action.id)),
      ),
      concatMap(([action, accessRule]) => {
        if (action?.forcedReload || !accessRule.data) {
          return this.commonApiService
            .getEntity(EntityIdentif.ACCESS_RULES, action.id)
            .pipe(
              map((env) =>
                env.success
                  ? LoadAccessRuleByIdSuccess({accessRule: env.data})
                  : LoadAccessRuleByIdError({id: action.id, error: env.error}),
              ),
            );
        } else {
          return of(LoadAccessRuleByIdSuccess({accessRule: accessRule.data}));
        }
      }),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAccessRules),
      exhaustMap(() =>
        this.commonApiService
          .getAll(EntityIdentif.ACCESS_RULES)
          .pipe(
            map((env) =>
              env.success
                ? LoadAccessRulesSuccess({entities: env.data})
                : LoadAccessRulesError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertAccessRule),
      concatMap(({accessRule, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(EntityIdentif.ACCESS_RULES, accessRule, accessRule.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .accessRuleSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertAccessRuleSuccess({
                  accessRule: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .accessRuleSaveFailure,
                );
                return UpsertAccessRuleError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  redirectToDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertAccessRuleSuccess),
      tap(({accessRule, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            '/user-management/access-rule/'.concat(accessRule.id),
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAccessRule),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(EntityIdentif.ACCESS_RULES, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects
                  .accessRuleDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/user-management/access-rule']);
              }
              return DeleteAccessRuleSuccess({id: id, listingId: listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects
                  .accessRuleDeleteFailure,
              );
              return DeleteAccessRuleError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffAccessRule),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(
            `v1/${EntityIdentif.ACCESS_RULES}`,
            diffEntities,
            compareField,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffAccessRuleSuccess({
                  accessRules: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffAccessRuleError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UpsertAccessRuleSuccess,
        DeleteAccessRuleSuccess,
        DiffAccessRuleSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
