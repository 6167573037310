import {DatePipe} from '@angular/common';
import {ParseDuration} from './parse-duration';
import {FilterOperator} from '../models';

export interface EnumItem<E> {
  id: E;
  name: keyof E;
  label: string;
}

export enum DateRound {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
}

// @dynamic
export class DtlUtils {
  public static readonly dateFormat = '/d{4}-d{2}-d{2}Td{2}:d{2}:d{2}.d{3}Z?$/'; // 2018-03-21T10:25:34.000Z
  public static readonly locale_cs = {
    firstDayOfWeek: 1,
    dayNames: [
      'Neděle',
      'Pondělí',
      'Úterý',
      'Středa',
      'Čtvrtek',
      'Pátek',
      'Sobota',
    ],
    dayNamesShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pa', 'So'],
    dayNamesMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pa', 'So'],
    monthNames: [
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec',
    ],
    monthNamesShort: [
      'Led',
      'Úno',
      'Bře',
      'Dub',
      'Kvě',
      'Čvn',
      'Čvc',
      'Srp',
      'Zář',
      'Říj',
      'Lis',
      'Pro',
    ],
    today: 'Dnes',
    clear: 'Smazat',
  };

  /**
   * Funkce vrati hodnotu na zaklade fieldu, ktery muze byt poskladan pres teckovou notaci.
   * Pokud funkce nenajde hodnotu na zaklade fieldu, vrati prazdny retezec.
   * @param data - vstupni data
   * @param field - napr. automobilka.auto.barva
   * @return napr. vrati to barvu auta
   */
  public static getData(data: any, field: string): string {
    if (data && field) {
      if (field.indexOf('.') === -1) {
        return data[field] !== undefined ? data[field] : data;
      } else {
        const fields: string[] = field.split('.');
        const len: number = fields.length;
        let subElementData = data;
        for (let i = 0; i < len - 1; ++i) {
          if (subElementData === null || subElementData === undefined) {
            return '';
          }
          subElementData = subElementData[fields[i]];
        }
        if (subElementData === null || subElementData === undefined) {
          return '';
        }
        return subElementData[fields[len - 1]] !== undefined
          ? subElementData[fields[len - 1]]
          : '';
      }
    }
    return null;
  }

  public static convertDate(date: Date, time: string, format?: string): string {
    const datePipe = new DatePipe('cs');
    if (time === 'from') {
      return datePipe.transform(date, format || 'yyyy-MM-ddT00:00:00.000Z');
    }
    return datePipe.transform(date, format || 'yyyy-MM-ddT23:59:59.000Z');
  }

  public static enumToArray<E>(Enum: any): EnumItem<E>[] {
    return Object.keys(Enum).map((key) => {
      const result = {
        id: Enum[key],
        name: key,
        label: Enum[key],
      } as EnumItem<E>;
      return result;
    });
  }

  /**
   * Naformátuje relativní čas pro zobrazení.
   * Je využíváno jak na UI komponentě, tak i při přepočtu po načtení profilu.
   * @param filterOperator před, po, nebo mezi?
   * @param values posun času (např. -7d, nebo 1h 30m)
   * @param long zda použít dlouhý zápis (včetně časového posunu), nebo jen výsledné datum
   * @param round zaokroulování času
   */
  public static formatRelative(
    filterOperator: FilterOperator,
    values: string[],
    long: boolean,
    round: DateRound,
    searchAsISOFormat?: boolean,
  ): string {
    if (filterOperator === FilterOperator.btwr) {
      return this.formatRelativeBetween(filterOperator, values, long, round);
    }

    let period = values[0];
    if (searchAsISOFormat && filterOperator === FilterOperator.ltr) {
      period = values[1];
    }

    const sign = filterOperator === FilterOperator.gtr ? '≥ ' : '≤ ';
    const datePipe = new DatePipe('cs');
    const date = this.getDateFromPeriodAndToday(period, round);
    const result = sign + datePipe.transform(date, 'dd.MM.yyyy HH:mm');
    if (long) {
      return (period || '') + ' (' + result + ')';
    }
    return result;
  }

  public static formatRelativeBetween(
    fo: FilterOperator,
    [period1, period2]: string[],
    long: boolean,
    round: DateRound,
  ): string {
    const datePipe = new DatePipe('cs');
    const date1 = this.getDateFromPeriodAndToday(period1, round);
    const date2 = this.getDateFromPeriodAndToday(period2, round);
    const result1 = datePipe.transform(date1, 'dd.MM.yyyy HH:mm');
    const result2 = datePipe.transform(date2, 'dd.MM.yyyy HH:mm');
    if (long) {
      return `${result1} (${period1}) - ${result2} (${period2})`;
    }
    return `${result1} - ${result2}`;
  }

  public static getDateFromPeriodAndToday(period, round?: DateRound): Date {
    let date = new Date();
    if (period) {
      const millis = ParseDuration.parse(period);
      date = new Date(Date.now() + millis);
    }
    if (round !== null) {
      this.round(date, round);
    }
    return date;
  }

  private static round(date: Date, round: DateRound) {
    if (round === DateRound.HOUR) {
      date.setMinutes(0, 0, 0);
    } else if (round === DateRound.DAY) {
      date.setMinutes(0, 0, 0);
      date.setHours(0);
    } else if (round === DateRound.WEEK) {
      date.setMinutes(0, 0, 0);
      date.setHours(0);
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      date.setDate(diff);
    } else if (round === DateRound.MONTH) {
      date.setMinutes(0, 0, 0);
      date.setHours(0);
      date.setDate(1);
    }
  }

  /**
   * Vraci kopii pole 'data' serazeneho podle poradi polozek v poli 'pattern' ekvivalentnich podle atributu 'key'
   * @param key
   * @param pattern
   * @param data
   */
  public static copyItemOrdering(key: string, pattern: any[], data: any[]) {
    const result = [...data];
    return result.sort(
      (a, b) =>
        pattern.findIndex((a_pat) => a_pat[key] === a[key]) -
        pattern.findIndex((b_pat) => b_pat[key] === b[key]),
    );
  }
}
