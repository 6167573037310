import {Injectable} from '@angular/core';
import {
  IdentifiedPack,
  loadAndWait,
  SharedRequestValidUntil,
} from '@tsm/framework/root';
import {BehaviorSubject, Observable} from 'rxjs';
import {Register, TsmModule} from '../models';
import {filter, switchMap} from 'rxjs/operators';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {Store} from '@ngrx/store';
import {LoadRegisterByCode} from '../actions';
import {selectRegisterByCode} from '../selectors';
import {translation} from '@tsm/shared-i18n';

@Docs({
  detail: 'register(registerCode: string): IdentifiedPack<RegisterValue>',
  description: {
    about: translation.shared.register,
    params: [
      {
        name: translation.shared.registerCode,
        about: translation.shared.registerCodeCam,
      },
    ],
    example: "register('9d1548e7-26df-4ec2-8931-b86581b21672')",
  },
})
@Injectable()
export class RegisterDataSource
  implements DtlDataSource<IdentifiedPack<Register>>
{
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private store: Store) {}

  @SharedRequestValidUntil()
  getRemoteData(code: string): Observable<IdentifiedPack<Register>> {
    return this.store.pipe(
      loadAndWait(LoadRegisterByCode({code}), selectRegisterByCode(code)),
    );
  }

  pushParams(params: any[]) {
    this.value.next(params);
  }

  create(): Observable<IdentifiedPack<Register>> {
    return this.value.asObservable().pipe(
      filter((x: string[]) => x[0] != null),
      switchMap((x: string[]) => {
        return this.getRemoteData(x[0]);
      }),
    );
  }
}
