import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeletePrivSuccess,
  DiffPrivs,
  DiffPrivsError,
  DiffPrivsSuccess,
  LoadPrivByCodeSuccess,
  LoadPrivByIdSuccess,
  LoadPrivs,
  LoadPrivsError,
  LoadPrivsSuccess,
  UpsertPrivSuccess,
} from '../actions';
import {Priv} from '../model';

export type PrivState = EntityState<IdentifiedPack<Priv>>;

export const adapter: EntityAdapter<IdentifiedPack<Priv>> = createEntityAdapter<
  IdentifiedPack<Priv>
>({});

export const initialState: PrivState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadPrivByIdSuccess, LoadPrivByCodeSuccess, (state, {priv}) =>
    adapter.upsertOne(
      {
        id: priv.id,
        data: priv,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadPrivsSuccess, (state, {entities}) => ({
    ...adapter.addMany(
      entities.map((priv) => ({
        id: priv.id,
        data: priv,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(LoadPrivs, (state, {}) => ({...state, loading: true})),

  on(LoadPrivsError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(UpsertPrivSuccess, (state, {priv}) =>
    adapter.upsertOne(
      {
        id: priv.id,
        data: priv,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeletePrivSuccess, (state, priv) => adapter.removeOne(priv.id, state)),

  on(DiffPrivs, (state, {diffEntities}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffPrivsSuccess, (state, {privs}) =>
    adapter.upsertMany(
      privs.map((priv) => {
        return {
          id: priv.id,
          changes: {
            id: priv.id,
            data: priv,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffPrivsError, (state, {diffEntities, error}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
