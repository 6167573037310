import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  BehaviorSubject,
  finalize,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import {catchError, filter, take} from 'rxjs/operators';
import {TranslocoService} from '@tsm/framework/translate';
import {AppLoggerService} from '@tsm/framework/logger';
import {ToastService} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {RuntimeService} from '@tsm/runtime-info';
import {AccessRulesGuard} from '@tsm/access-rules/service';
import {Config, ConfigService} from '@tsm/framework/config';

declare const ngDevMode: boolean;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  ignore500ErrorForMessageStrings =
    (window as any)?.app?.params?.ignore500ErrorForMessageStrings ?? false;
  isRefreshingToken = false;
  private tokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null,
  );

  constructor(
    private runtimeService: RuntimeService,
    private translocoService: TranslocoService,
    private appLogger: AppLoggerService<any>,
    private toastService: ToastService,
    private configService: ConfigService<Config>,
    private acessRulesGuard: AccessRulesGuard,
  ) {}

  writeError(info) {
    const serverErrors = (window as any).serverErrors;
    if (serverErrors == null) {
      (window as any).serverErrors = [];
    }
    (window as any).serverErrors.push(info);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(this.addToken(request, this.runtimeService.getAccessToken()))
      .pipe(
        // @ts-ignore
        catchError((event: any) => {
          // eh
          let tokenUrl = ngDevMode
            ? this.configService.value.apiUrls.userManagement
            : this.configService.value.apiUrls.userManagement.includes('http')
              ? this.configService.value.apiUrls.userManagement // pokud ma GUI jinou URL nez backend, tak je nutne mit v konfiguracich plnou URL (ne relativni)
              : this.runtimeService.getBaseUrl() +
                this.configService.value.apiUrls.userManagement;
          tokenUrl = tokenUrl + '/token';
          if (event.url === tokenUrl) {
            return throwError(() => event);
          }
          if (event instanceof HttpErrorResponse) {
            const info = {
              date: new Date().toUTCString(),
              requestUrl: request.url,
              error: event.error,
              errorText: JSON.stringify(event.error),
            };
            this.writeError(info);
            if (event.error) {
              this.appLogger
                .createErrorLog(
                  `Response from calling url ${info.requestUrl} contains error - `,
                )
                .withScope(
                  `${JwtInterceptor.name} TRACE ID: ${event.error.traceId}`,
                )
                .execute();
              this.appLogger
                .createDebugLog(
                  `Response from calling url ${info.requestUrl} contains error`,
                )
                .withScope(
                  `${JwtInterceptor.name} TRACE ID: ${event.error.traceId}`,
                )
                .withPayload(info)
                .execute();
            } else {
              // inform about error without details
              console.error(
                `Response from calling url ${info.requestUrl} contains error, response:`,
                event,
              );
            }

            switch (event.status) {
              case 401: {
                this.acessRulesGuard.cleanState();
                return this.handle401Error$(request, next);
              }
              case 500: {
                if (
                  (event as any).error?.businessCode === 'ApiRemoteException'
                ) {
                  if (
                    Array.isArray(this.ignore500ErrorForMessageStrings) &&
                    this.ignore500ErrorForMessageStrings.length > 0 &&
                    this.ignore500ErrorForMessageStrings.some((x) =>
                      event.error?.message?.includes(x),
                    )
                  ) {
                    return throwError(() => event);
                  }
                  this.toastService.showError(
                    event.error,
                    translation.toast.thirdPartySystemsNotAvailable,
                  );
                }
                return throwError(() => event);
              }
              default: {
                return throwError(() => event);
              }
            }
          }
        }),
      );
  }

  private handle401Error$(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject$.next(null);

      if (this.runtimeService.getRefreshToken() == null) {
        this.runtimeService.signOut(true);
        return of(null);
      }

      return this.runtimeService.tryRefreshToken().pipe(
        switchMap(() => {
          this.tokenSubject$.next(this.runtimeService.getAccessToken());
          return next.handle(
            this.addToken(req, this.runtimeService.getAccessToken()),
          );
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.error?.status === 'UNAUTHORIZED') {
            this.runtimeService.signOut(true);
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      return this.tokenSubject$.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(req, token));
        }),
      );
    }
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const isConfigJson: boolean =
      req.url.startsWith(window.location.origin + '/config.json') ||
      req.url.startsWith(window.location.origin + '/configs/config.');

    if (!isConfigJson) {
      const baseUrl = this.configService.value.apiUrls.base;
      if (req.url.startsWith(baseUrl)) {
        if (token) {
          const language = this.translocoService.getActiveLang();
          return req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              ['App-Language']: `${language}`,
            },
          });
        }
      }
    }

    return req;
  }
}
