import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  viewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';

import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { NgStyle, NgClass } from '@angular/common';
import { LocalizationDataTranslatePipe } from '@tsm/framework/root';

@Component({
    selector: 'dtl-form-input-mask',
    templateUrl: './form-input-mask.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
    styles: [
        `
      :host > *:first-child {
        display: flex;
      }
    `,
    ],
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputMaskComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormInputMaskComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputMaskComponent),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        InputMaskModule,
        FormsModule,
        NgStyle,
        NgClass,
        LocalizationDataTranslatePipe,
    ],
})
export class FormInputMaskComponent
  extends ParentWidgetAccessorComponent
  implements OnChanges
{
  @Input() mask = '';

  @Input() maskPlaceholder: string;

  @Input() inputId: string;

  inputMask = viewChild(InputMask);

  private canUpdateValue = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const mask = changes.mask;
    if (!mask) {
      this.canUpdateValue = true;
    } else if (mask?.isFirstChange() && this.inputMask()) {
      // moc skaredy hack, ale problem je v tom, ze kdyz se zmeni maska (fluentForm => clovek nevim kdy se to stane), tak p-inputMask promaze hodnotu
      // => do te doby, nez se nastavi maska, tak neprepisuj hodnoty, ale musim pockat, nez probehne magie v settru mask v p-inputMask
      const proto = Object.getPrototypeOf(this.inputMask());
      const descriptor = Object.getOwnPropertyDescriptor(proto, 'mask');

      if (descriptor && descriptor.set) {
        Object.defineProperty(this.inputMask(), 'mask', {
          set: (newMask: string) => {
            descriptor.set.call(this.inputMask(), newMask);
            this.canUpdateValue = true;
          },
          get: descriptor.get,
        });
      }
    }
  }

  setValue(value) {
    if (this.canUpdateValue) {
      this.val = value;
    }
  }

  writeValue(value) {
    super.writeValue(value);
  }

  setFilled(value) {
    super.setFilled(value);
  }

  maskFocused() {
    this.focused = true;
    this.cdr.markForCheck();
  }

  maskBlured() {
    this.focused = false;
    this.onTouched(true);
    this.cdr.markForCheck();
  }
}
