import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';

import {
  AppComponent,
  CoreModule,
  JwtInterceptor,
  RuntimeInfoResolver,
  TeamsGuard,
  UserIsLoggedInGuard,
} from '@tsm/core';
import {AccessRulesGuard} from '@tsm/access-rules/service';
import {accessRulesPlugin} from '@tsm/access-rules/plugins';
import {frameworkPlugin, FrameworkPluginModule} from '@tsm/framework/plugin';
import {SharedModule} from '@tsm/shared';
import {sharedPlugin} from '@tsm/shared/plugins';

import {
  LayoutMenuDesignerComponent,
  LayoutTsmComponent,
  LayoutWidgetsModule,
  TeamsLayoutComponent,
} from '@tsm/layout/widgets';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {dashboardPlugin} from '@tsm/dashboard/plugins';
import {
  processDefinitionPlugin,
  processInstancePlugin,
} from '@tsm/process/plugins';
import {userManagementPlugin} from '@tsm/user-management/plugins';
import {addressManagementPlugin} from '@tsm/address-management/plugins';
import {exampleHelloworldPlugin} from '@tsm/example/plugins';
import {configFormPlugin} from '@tsm/config-form/plugins';
import {entityTypePlugin} from '@tsm/entity-type/plugins';
import {registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {slaPlugin} from '@tsm/sla/plugins';
import {orderingPlugin} from '@tsm/ordering/plugins';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {dmsPlugin} from '@tsm/dms/plugins';
import {listingLibPlugin} from '@tsm/listing-lib/plugins';
import {customerPlugin} from '@tsm/customer/plugins';
import {calendarPlugin} from '@tsm/calendar/plugins';
import {ticketsPlugin} from '@tsm/tickets/plugins';

// kvuli selfcare konfiguracni detail+new formulare, pokud aplikace nema selfcare, tak nepouzivat (pravdepodobne bude mit jen slovanet prozatim)
import {requirementsPlugin} from '@tsm/selfcare/requirements/plugins';

import {TranslateModule} from '@tsm/framework/translate';
import {inventoryPlugin} from '@tsm/inventory/plugins';
import {catalogPlugin} from '@tsm/catalog/plugins';
import {stockPlugin} from '@tsm/stock/plugins';
import {accountComponentsPlugin} from '@tsm/account/plugins';
import {changeManagementPlugin} from '@tsm/change-management/plugins';
import {loggingPlugin} from '@tsm/logging/plugins';
import {FrameworkShortcutsModule} from '@tsm/framework/shortcuts';
import {accessDeniedPlugin} from '@tsm/access-denied/plugins';
import {scriptingPlugin} from '@tsm/scripting/plugins';
import {reportingPlugin} from '@tsm/reporting/plugins';
import {
  defaultTokenGetter,
  FrameworkSecurityModule,
} from '@tsm/framework/security';
import {PrivilegeGuard} from '@tsm/privilege';
import {billingPlugin} from '@tsm/billing/plugins';
import {policies} from './policies';
import {expressionEditorPlugin} from '@tsm/framework/expression-editor-plugin';
import {auditPlugin} from '@tsm/audit/plugins';
import {frameworkAwarenessPlugin} from '@tsm/framework/awareness/plugins';
import {characteristicsPlugin} from '@tsm/characteristics/plugins';
import {serviceManagementPlugin} from '@tsm/service-management/plugins';
import {wfmPlannerPlugin} from '@tsm/wfm-planner/plugins';
import {wfmPlugin} from '@tsm/wfm/plugins';

registerLocaleData(localeCs, 'cs');

// TODO, pokud mozno umoznit registrovat pres plugin module primo
export const appRoutes: Routes = [
  ...accountComponentsPlugin.routes,
  {
    path: 'teams',
    component: TeamsLayoutComponent,
    children: [
      {pathMatch: 'full', path: '', redirectTo: 'dashboard'},
      ...dashboardPlugin.routes,
      ...accessDeniedPlugin.routes,
      ...ticketsPlugin.routes,
      ...catalogPlugin.routes,
      ...exampleHelloworldPlugin.routes,
      ...userManagementPlugin.routes,
      ...addressManagementPlugin.routes,
      ...customerPlugin.routes,
      ...configFormPlugin.routes,
      ...entityTypePlugin.routes,
      ...processDefinitionPlugin.routes,
      ...processInstancePlugin.routes,
      ...changeManagementPlugin.routes,
      ...calendarPlugin.routes,
      ...slaPlugin.routes,
      ...inventoryPlugin.routes,
      ...orderingPlugin.routes,
      ...stockPlugin.routes,
      ...dmsPlugin.routes,
      ...listingLibPlugin.routes,
      ...loggingPlugin.routes,
      ...scriptingPlugin.routes,
      ...reportingPlugin.routes,
      ...billingPlugin.routes,
      ...characteristicsPlugin.routes,
      ...serviceManagementPlugin.routes,
    ],
    canActivate: [UserIsLoggedInGuard],
    canActivateChild: [PrivilegeGuard, AccessRulesGuard, TeamsGuard],
    resolve: {runtimeInfo: RuntimeInfoResolver},
  },
  {
    path: '',
    component: LayoutTsmComponent,
    children: [
      {pathMatch: 'full', path: '', redirectTo: 'dashboard'},
      {
        path: 'layout-menu-designer',
        component: LayoutMenuDesignerComponent,
        data: {
          privilege: ['!Special.MenuConfig'],
        },
      },
      ...dashboardPlugin.routes,
      ...accessDeniedPlugin.routes,
      ...ticketsPlugin.routes,
      ...catalogPlugin.routes,
      ...exampleHelloworldPlugin.routes,
      ...userManagementPlugin.routes,
      ...addressManagementPlugin.routes,
      ...customerPlugin.routes,
      ...configFormPlugin.routes,
      ...entityTypePlugin.routes,
      ...processDefinitionPlugin.routes,
      ...processInstancePlugin.routes,
      ...changeManagementPlugin.routes,
      ...calendarPlugin.routes,
      ...slaPlugin.routes,
      ...inventoryPlugin.routes,
      ...orderingPlugin.routes,
      ...stockPlugin.routes,
      ...dmsPlugin.routes,
      ...listingLibPlugin.routes,
      ...loggingPlugin.routes,
      ...scriptingPlugin.routes,
      ...reportingPlugin.routes,
      ...billingPlugin.routes,
      ...characteristicsPlugin.routes,
      ...serviceManagementPlugin.routes,
      ...wfmPlannerPlugin.routes,
      ...wfmPlugin.routes,
    ],
    canActivate: [UserIsLoggedInGuard],
    canActivateChild: [PrivilegeGuard, AccessRulesGuard, TeamsGuard],
    resolve: {runtimeInfo: RuntimeInfoResolver},
  },
  {path: '**', redirectTo: '/404', pathMatch: 'full'},
  // {
  //   path: 'dev', component: EmptyLayoutComponent, children: [
  //     ...dashboardPlugin.routes,
  //     ...ticketsComponentsPlugin.routes,
  //     ...catalogComponentsPlugin.routes,
  //     ...exampleHelloworldPlugin.routes,
  //     ...userManagementPlugin.routes,
  //     ...customerPlugin.routes,
  //     ...configFormComponentsPlugin.routes,
  //     ...processDefinitionPlugin.routes,
  //     ...changeManagementPlugin.routes,
  //     ...calendarComponentsPlugin.routes,
  //     ...slaPlugin.routes
  //   ], canActivate: [UserIsLoggedInGuard],
  //   resolve: {runtimeInfo: RuntimeInfoResolver}
  // }
];

@NgModule({
  declarations: [],
  imports: [
    FrameworkShortcutsModule.forRoot({
      enabled: true,
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    LayoutWidgetsModule,
    RouterModule.forRoot(appRoutes, {
      // z duvodu resolveru
      paramsInheritanceStrategy: 'always',
    }),
    // TODO: podporovat pole
    FrameworkPluginModule.registerPlugin(frameworkPlugin),
    FrameworkPluginModule.registerPlugin(sharedPlugin),
    FrameworkPluginModule.registerPlugin(dashboardPlugin),
    FrameworkPluginModule.registerPlugin(accessDeniedPlugin),
    FrameworkPluginModule.registerPlugin(ticketsPlugin),
    FrameworkPluginModule.registerPlugin(requirementsPlugin),
    FrameworkPluginModule.registerPlugin(catalogPlugin),
    FrameworkPluginModule.registerPlugin(exampleHelloworldPlugin),
    FrameworkPluginModule.registerPlugin(userManagementPlugin),
    FrameworkPluginModule.registerPlugin(addressManagementPlugin),
    FrameworkPluginModule.registerPlugin(customerPlugin),
    FrameworkPluginModule.registerPlugin(configFormPlugin),
    FrameworkPluginModule.registerPlugin(entityTypePlugin),
    FrameworkPluginModule.registerPlugin(processDefinitionPlugin),
    FrameworkPluginModule.registerPlugin(processInstancePlugin),
    FrameworkPluginModule.registerPlugin(changeManagementPlugin),
    FrameworkPluginModule.registerPlugin(calendarPlugin),
    FrameworkPluginModule.registerPlugin(slaPlugin),
    FrameworkPluginModule.registerPlugin(inventoryPlugin),
    FrameworkPluginModule.registerPlugin(orderingPlugin),
    FrameworkPluginModule.registerPlugin(stockPlugin),
    FrameworkPluginModule.registerPlugin(dmsPlugin),
    FrameworkPluginModule.registerPlugin(listingLibPlugin),
    FrameworkPluginModule.registerPlugin(loggingPlugin),
    FrameworkPluginModule.registerPlugin(scriptingPlugin),
    FrameworkPluginModule.registerPlugin(billingPlugin),
    FrameworkPluginModule.registerPlugin(reportingPlugin),
    FrameworkPluginModule.registerPlugin(expressionEditorPlugin),
    FrameworkPluginModule.registerPlugin(auditPlugin),
    FrameworkPluginModule.registerPlugin(frameworkAwarenessPlugin),
    FrameworkPluginModule.registerPlugin(characteristicsPlugin),
    FrameworkPluginModule.registerPlugin(accessRulesPlugin),
    FrameworkPluginModule.registerPlugin(serviceManagementPlugin),
    FrameworkPluginModule.registerPlugin(wfmPlannerPlugin),
    FrameworkPluginModule.registerPlugin(wfmPlugin),
    ServiceWorkerModule.register('custom-worker.js', {enabled: environment.sw}),
    TranslateModule.forRoot({
      localizations: [],
      translocoConfig: {
        availableLangs: ['cs', 'en'],
        defaultLang:
          (window as any)?.app?.params?.defaultLanguage != null
            ? (window as any)?.app?.params?.defaultLanguage
            : 'cs',
        prodMode: environment.production,
        reRenderOnLangChange: true,
        failedRetries: 2,
        flatten: {
          aot: false,
        },
        scopes: {
          keepCasing: false,
        },
        missingHandler: {
          logMissingKey: false,
          useFallbackTranslation: true,
          allowEmpty: true,
        },
        interpolation: ['{{', '}}'],
      },
    }),
    FrameworkSecurityModule.forRoot(policies, {
      policyConflictResolution: 'ignore',
      tokenGetter: defaultTokenGetter,
      whenDuplicit: 'warning',
      overridePrivileges: [],
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
