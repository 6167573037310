import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {NotificationTemplate} from '../models';
import {
  DiffNotificationTemplate,
  DiffNotificationTemplateError,
  DiffNotificationTemplateSuccess,
  LoadNotificationTemplateByCode,
  LoadNotificationTemplateByCodeError,
  LoadNotificationTemplateByCodeSuccess,
  LoadNotificationTemplateById,
  LoadNotificationTemplateByIdError,
  LoadNotificationTemplateByIdSuccess,
  LoadNotificationTemplatesByFilter,
  LoadNotificationTemplatesByFilterError,
  LoadNotificationTemplatesByFilterSuccess,
  UpsertNotificationTemplate,
  UpsertNotificationTemplateSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';
import {addDays} from 'date-fns';

export type NotificationTemplateState = EntityState<
  IdentifiedPack<NotificationTemplate>
>;

export const notificationTemplateAdapter: EntityAdapter<
  IdentifiedPack<NotificationTemplate>
> = createEntityAdapter<IdentifiedPack<NotificationTemplate>>({});
export const initialState: NotificationTemplateState =
  notificationTemplateAdapter.getInitialState({});

export const notificationTemplateReducer = createReducer(
  initialState,

  on(UpsertNotificationTemplate, (state, {notificationTemplate}) =>
    notificationTemplateAdapter.updateOne(
      {
        id: notificationTemplate.id,
        changes: {
          ...state.entities[notificationTemplate.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertNotificationTemplateSuccess, (state, {notificationTemplate}) =>
    notificationTemplateAdapter.upsertOne(
      {
        id: notificationTemplate.id,
        data: notificationTemplate,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationTemplateById, (state, {id}) =>
    notificationTemplateAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationTemplateByIdSuccess, (state, {notificationTemplate}) =>
    notificationTemplateAdapter.upsertOne(
      {
        id: notificationTemplate.id,
        data: notificationTemplate,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationTemplateByIdError, (state, {id, error}) =>
    notificationTemplateAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadNotificationTemplatesByFilter, (state, {}) => ({
    ...state,
    loading: true,
  })),

  on(LoadNotificationTemplatesByFilterSuccess, (state, {entities}) => {
    return {
      ...notificationTemplateAdapter.upsertMany(
        entities.map((item) => ({
          id: item.id,
          data: item,
          loading: false,
          error: null,
          validUntil: addDays(new Date(), 1),
        })),
        state,
      ),
      error: null,
      loading: false,
    };
  }),

  on(LoadNotificationTemplatesByFilterError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(LoadNotificationTemplateByCode, (state, {}) => ({
    ...state,
    loading: true,
  })),

  on(LoadNotificationTemplateByCodeSuccess, (state, {notificationTemplate}) =>
    notificationTemplateAdapter.upsertOne(
      {
        id: notificationTemplate.id,
        data: notificationTemplate,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationTemplateByCodeError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(DiffNotificationTemplate, (state, {diffEntities}) =>
    notificationTemplateAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffNotificationTemplateSuccess, (state, {notificationTemplates}) =>
    notificationTemplateAdapter.upsertMany(
      notificationTemplates.map((notificationTemplate) => {
        return {
          id: notificationTemplate.id,
          changes: {
            id: notificationTemplate.id,
            data: notificationTemplate,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffNotificationTemplateError, (state, {diffEntities, error}) =>
    notificationTemplateAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
