import {createReducer, on} from '@ngrx/store';
import {BackupSet} from '@tsm/listing-lib/service';
import {IdentifiedPack} from '@tsm/framework/root';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  DeleteBackupSetSuccess,
  LoadBackupSetById,
  LoadBackupSetByIdError,
  LoadBackupSetByIdSuccess,
  UpsertBackupSet,
  UpsertBackupSetError,
  UpsertBackupSetSuccess,
} from '../actions';

export type BackupSetState = EntityState<IdentifiedPack<BackupSet>>;
export const backupSetAdapter: EntityAdapter<IdentifiedPack<BackupSet>> =
  createEntityAdapter<IdentifiedPack<BackupSet>>({});

export const backupSetInitialState: BackupSetState =
  backupSetAdapter.getInitialState({});

export const backupSetReducer = createReducer(
  backupSetInitialState,
  on(LoadBackupSetById, (state, {id}) =>
    backupSetAdapter.upsertOne(
      {
        ...state.entities[id],
        id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadBackupSetByIdSuccess, (state, backupSet) =>
    backupSetAdapter.upsertOne(
      {
        id: backupSet.id,
        data: backupSet,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadBackupSetByIdError, (state, {id, error}) =>
    backupSetAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertBackupSet, (state, {backupSet}) =>
    backupSetAdapter.updateOne(
      {
        id: backupSet.id,
        changes: {
          ...state.entities[backupSet.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertBackupSetSuccess, (state, {backupSet}) =>
    backupSetAdapter.upsertOne(
      {
        id: backupSet.id,
        data: backupSet,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(UpsertBackupSetError, (state, {id, error}) =>
    backupSetAdapter.upsertOne(
      {
        id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DeleteBackupSetSuccess, (state, {id}) =>
    backupSetAdapter.removeOne(id, state),
  ),
);
