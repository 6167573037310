import {
  patchState,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {FluentDebuggerModel} from '../models';
import {computed} from '@angular/core';

const initialState: FluentDebuggerModel = {
  id: null,
  code: null,
  rootControl: null,
};

export const withFluentDebugger = () =>
  signalStoreFeature(
    withState<FluentDebuggerModel>(initialState),
    withComputed((store) => ({
      isDebuggerOpened: computed(() => store.id() != null),
      debuggerState: computed(() => ({
        id: store.id(),
        code: store.code(),
        rootControl: store.rootControl(),
      })),
    })),
    withMethods((store) => ({
      setDebugger(newState: FluentDebuggerModel) {
        patchState(store, (state) => ({
          ...state,
          ...newState,
        }));
      },
      toggle(newState: FluentDebuggerModel) {
        if (store.id() !== newState.id) {
          patchState(store, (state) => newState);
        } else {
          patchState(store, () => initialState);
        }
      },
      destroyDebugger() {
        patchState(store, () => initialState);
      },
    })),
  );
