export const en = {
  configFormPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmConfigTypeFilter: 'tSM Config Type Filter',
      tsmConfigTypeFilterDescription:
        'Filters configuration types based on predefined attributes. ' +
        'Supports multiple selections and allows filtering ' +
        'by code and name for precise results.',
      tsmEntitySpecificationIdFilter: 'tSM Entity Specification Id Filter',
      tsmEntitySpecificationIdFilterDescription:
        'Filters entity specifications based on predefined attributes. ' +
        'Supports multiple selections and allows filtering ' +
        'by code and name for efficient entity management.',
      tsmEntitySpecificationTypeFilter: 'tSM Entity Specification Type Filter',
      tsmEntitySpecificationTypeFilterDescription:
        'Filters entity specification types based on predefined criteria. ' +
        'Supports multiple selections and enables filtering ' +
        'by code and name for streamlined entity type management.',
      tsmFormFilter: 'tSM Form Filter',
      tsmFormFilterDescription:
        'Filters forms by predefined criteria, allowing multiple selections. ' +
        'Supports filtering by code and name to enhance form selection ' +
        'and management efficiency.',
      tsmFormIdFilter: 'tSM Form Id Filter',
      tsmFormIdFilterDescription:
        'Filters forms based on unique identifiers, allowing multiple selections. ' +
        'Enables precise form filtering by ID and name, optimizing form ' +
        'management and selection.',
      tsmFormTypeFilter: 'tSM Form Type Filter',
      tsmFormTypeFilterDescription:
        'Filters forms based on their type, allowing multiple selections. ' +
        'Provides a structured way to categorize and select forms, enhancing ' +
        'efficiency in form management.',
      tsmIndexMappingFilter: 'tSM Index Mapping Filter',
      tsmIndexMappingFilterDescription:
        'Filters index mappings with multiple selection support. ' +
        'Displays available mappings in a structured list, allowing users ' +
        'to efficiently refine their selections.',
      tsmMicroserviceFilter: 'tSM Microservice Filter',
      tsmMicroserviceFilterDescription:
        'Filters microservices by allowing multiple selections. ' +
        'Displays available microservices in a structured list, enabling ' +
        'users to refine their selection efficiently.',
      tsmRegisterValueFilter: 'tSM Register Value Filter',
      tsmRegisterValueFilterDescription:
        'Filters register values based on a specified context. ' +
        'Supports selection by ID or code, allowing users to ' +
        'refine their filter criteria dynamically.',
      tsmSchemaLocationFilter: 'tSM Schema Location Filter',
      tsmSchemaLocationFilterDescription:
        'Filters schema locations based on available options. ' +
        'Supports multiple selection and dynamic translation ' +
        'of displayed values.',
      tsmSyncMethodTypeFilter: 'tSM Sync Method Type Filter',
      tsmSyncMethodTypeFilterDescription:
        'Filters synchronization methods from available options. ' +
        'Supports multiple selection and translates displayed ' +
        'values dynamically.',
      tsmTsmModuleFilter: 'tSM Tsm Module Filter',
      tsmTsmModuleFilterDescription:
        'Filters TSM modules based on their code or name. ' +
        'Supports multiple selection and retrieves selected ' +
        'values dynamically.',
    },
    pipes: {
      arrayToString: 'Array To String',
      arrayToStringDescription:
        'Transforms an array of objects into a string where objects are separated by a given separator. ' +
        'Supports displaying specific fields, handling unique values, and applying a transformation pipe ' +
        'on individual elements.',
      tsmCharsFormTypePipe: 'TSM Chars Form Type Pipe',
      tsmCharsFormTypePipeDescription:
        'Filters and sorts a list of characteristics based on the form type, schema location, ' +
        'and user privileges. Ensures only accessible items are displayed and orders them by default.',
      tsmColorExpressionPipe: 'TSM Color Expression Pipe',
      tsmColorExpressionPipeDescription:
        'Transforms a value into its JSON string representation using a JSON pipe. ' +
        'If the value is null, it returns an empty string.',
      configTypeCode: 'Config Type Code',
      configTypeCodeDescription:
        'Loads configuration type data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      configType: 'Config Type',
      configTypeDescription:
        'Loads configuration type data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      entitySpecificationCode: 'Entity Specificatio nCode',
      entitySpecificationCodeDescription:
        'Loads entity specification data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      entitySpecificationType: 'Entity Specification Type',
      entitySpecificationTypeDescription:
        'Loads entity specification type data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      entitySpecification: 'Entity Specification',
      entitySpecificationDescription:
        'Loads entity specification data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      formatText: 'Format Text',
      formatTextDescription:
        'Formats a given text string by replacing newline and tab characters with HTML elements. ' +
        'Newlines are converted to <br> tags, and tabs are replaced with non-breaking spaces.',
      tsmFormsCode: 'TSM Forms Code',
      tsmFormsCodeDescription:
        'Loads form data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      tsmForms: 'TSM Forms',
      tsmFormsDescription:
        'Loads form data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      microserviceCode: 'Microservice Code',
      microserviceCodeDescription:
        'Loads microservice data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available',
      microservice: 'Microservice',
      microserviceDescription:
        'Loads microservice data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      mnemonicConfigCode: 'Mnemonic Config Code',
      mnemonicConfigCodeDescription:
        'Loads mnemonic configuration data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      mnemonicConfig: 'Mnemonic Config',
      mnemonicConfigDescription:
        'Loads mnemonic configuration data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      pipeInPipe: 'Pipe In Pipe',
      pipeInPipeDescription:
        'Transforms an input value by applying a sequence of two pipes, one after the other, ' +
        'allowing customization of arguments and ensuring the data flows through both transformations.',
      registerByCode: 'Register By Code',
      registerByCodeDescription:
        'Fetches register data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is loaded if it is not already available.',
      registerById: 'Register By Id',
      registerByIdDescription:
        'Fetches register data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is loaded if it is not already available.',
      registerValueArrayById: 'Register Value Array By Id',
      registerValueArrayByIdDescription:
        'Fetches an array of register values by IDs from the store, retrieves specific fields, ' +
        'such as the name or all data, and ensures the values are loaded if they are not already available.',
      registerValueById: 'Register Value By Id',
      registerValueByIdDescription:
        'Fetches a register value by ID from the store, retrieves specific fields such as the name ' +
        'or all data, and ensures the value is loaded if it is not already available.',
      registerValueIcon: 'Register Value Icon',
      registerValueIconDescription:
        'Fetches a register value icon by register code and code from the store, retrieves specific ' +
        'fields such as the name, applies an icon transformation, and ensures the data is loaded ' +
        'if it is not already available.',
      registerValueName: 'Register Value Name',
      registerValueNameDescription:
        'Fetches a register value by register code and name from the store, retrieves specific ' +
        'fields such as the name or the entire object, and ensures the data is loaded if it ' +
        'is not already available.',
      registerValue: 'Register Value',
      registerValueDescription:
        'Fetches a register value by its register code and code from the store, retrieves specific ' +
        'fields such as the name or the entire object, and ensures the data is loaded if it is ' +
        'not already available.',
      tsmModulesCode: 'TSM Modules Code',
      tsmModulesCodeDescription:
        'Fetches TSM module data by its code from the store, retrieves specific fields such as ' +
        'the name or the entire object, and ensures the data is loaded if it is not already available.',
      tsmModulesId: 'TSM Modules Id',
      tsmModulesIdDescription:
        'Fetches TSM module data by its ID from the store, retrieves specific fields such as ' +
        'the name or the entire object, and ensures the data is loaded if it is not already available.',
    },
    directives: {
      viewDefer: 'View Defer',
      viewDeferDescription:
        'Postpones loading content until it becomes visible in the viewport during scrolling. ' +
        'Useful for optimizing performance by deferring rendering of initially hidden content. ' +
        'Emits an "onLoad" event when content is loaded. Uses scroll events and viewport dimensions ' +
        'to determine when to load deferred content.',
    },
  },
};
