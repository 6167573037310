import {defaultPolicy, type Policy} from '@tsm/framework/security';
import {type Register} from '@tsm/register';
import {type IdentifiedPack} from '@tsm/framework/root';

export const ticketDefaultPolicy: Policy = (
  authorities: string[],
  params: any[],
  securityData: IdentifiedPack<Register[]>[],
) => {
  const priv: string = params[1];

  return defaultPolicy(authorities, [priv]);
};
