import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {CommonApiService as SharedApiService} from '@tsm/shared';
import {Observable} from 'rxjs';
import {
  FilterModel,
  FilterOperator,
  PageSortFilterService,
} from '@tsm/listing-lib/service';
import {SharedRequestValidUntil} from '@tsm/framework/root';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService<
  ServerResponseType,
  ToClientType,
> extends SharedApiService<ServerResponseType, ToClientType> {
  private readonly CHARS_API_PATH = 'characteristics';

  constructor(
    config: ConfigService<Config>,
    apiService: ApiService,
    private pageSortFilterService: PageSortFilterService,
  ) {
    super('tsm-config-form/api', apiService, config);
  }

  @SharedRequestValidUntil(300)
  getTsmModuleByType(type: string): Observable<any> {
    return this.apiService.get<ServerResponseType, ToClientType>(
      this.BASE_URL + `/tsm-module/type/${type}`,
    );
  }

  getCharacteristicsByEntityType(entityType: string) {
    return this.apiService.get<ServerResponseType[], ToClientType[]>(
      this.BASE_URL + `/v1/characteristics/entityType/${entityType}`,
    );
  }

  @SharedRequestValidUntil(300)
  getCharacteristicsByCodes(codes: string[]): Observable<any> {
    const filters: FilterModel[] = [
      {
        field: 'code',
        operator: FilterOperator.in,
        value: codes,
      },
    ];
    return this.getAllFilterable(
      this.CHARS_API_PATH,
      this.pageSortFilterService.getUrlFilterFromFilterModels(filters),
    );
  }
}
