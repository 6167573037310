export const en = {
  orderingPlugins: {
    orderLine: {
      count: 'Count',
      operation: 'Operation',
      work: 'Work',
      productId: 'Product ID',
      productCode: 'Product Code',
      serviceId: 'Service ID',
      serviceCode: 'Service Code',
      workCode: 'Work code',
      price: 'Price',
      workOnService: 'Work on service',
      prieForUnit: 'Price per unit',
      endOfProviding: 'End of providing',
      priceForUnitWithoutTax: 'Price per unit (without VAT)',
      totalPriceForUnit: 'Total price per unit',
      paymentPeriod: 'Period of payments',
      regularPriceValue: 'Price of regular payment',
      regularPriceValueWithoutTax: 'Price of regular payment (without VAT)',
      totalPriceForUsing: 'Total price per use',
      totalPriceForRegularPayment: 'Total price of regular payment',
      priceConfig: 'Pricing',
      orderRowChars: 'Order line configuration profile',
      orderTypeDetail: 'Order type detail',
      newOrderType: 'New order type',
      orderTypes: 'Types of orders',
      goToProductDetail: 'Go to the product detail',
      goToServiceDetail: 'Go to the service detail',
      goToDetailLine: 'Go to the line detail',
      addRelationshipOnSpecification: 'Add child product',
      addRelationshipOnInventory: 'Add child inventory',
      addServiceRelationshipOnSpecification: 'Add child service',
      deleteProduct: 'Delete product',
      deleteService: 'Delete service',
      deleteConfirmation: 'Are you sure you want to remove selected product?',
      selectProductWarn: 'Select product instead of category.',
    },
    filters: {
      tsmOrderStatusFilter: 'tSM Order Status Filter',
      tsmOrderStatusFilterDescription:
        'Defines an order status filter component that extends a parent filter listing ' +
        'component. Provides a user interface for selecting multiple order statuses ' +
        'using a data view. Supports filtering, sorting, and displaying order statuses ' +
        'retrieved from an external API. Implements an overlay panel with options to ' +
        'confirm or cancel the selection. Updates the filter values upon selection and ' +
        'emits changes.',
      tsmOrderTypeFilter: 'tSM Order Type Filter',
      tsmOrderTypeFilterDescription:
        'Defines an order type filter component that extends a parent listing filter ' +
        'component. Provides an interface for selecting multiple order types using ' +
        'a data view. Supports filtering, sorting, and fetching order types from an ' +
        'external API. Implements an overlay panel with selection confirmation and ' +
        'cancellation options. Updates the filter values and emits changes upon ' +
        'selection. Dynamically updates the listing based on the provided context.',
      tsmQuoteStatusFilter: 'tSM Quote Status Filter',
      tsmQuoteStatusFilterDescription:
        'Defines a quote status filter component that extends a table filter ' +
        'parent component. Provides a selection interface for filtering quotes ' +
        'by status using a data view. Supports multiple selection, displaying ' +
        'statuses in a table, and updating filter values upon selection. ' +
        'Implements an overlay panel with confirmation and cancellation ' +
        'options. Dynamically translates status names based on localization.',
    },
    pipes: {
      orderLoader: 'Order Loader',
      orderLoaderDescription:
        'Loads order data by ID from the store, retrieves specific fields like the subject, or the ' +
        'complete object, and ensures the data is fetched if it is not already available.',
      orderLoaderKey: 'Order Loader by Key',
      orderLoaderKeyDescription:
        'Loads order data by Key from the store, retrieves specific fields like the subject, or the ' +
        'complete object, and ensures the data is fetched if it is not already available.',
      orderStatusByCode: 'Order Status By Code',
      orderStatusByCodeDescription:
        'Loads order status data by code from the store, retrieves specific fields like the name, ' +
        'or the complete object, and ensures data is fetched if not already available.',
      orderStatusById: 'Order Status By Id',
      orderStatusByIdDescription:
        'Loads order status data by ID from the store, retrieves specific fields like the name, ' +
        'or the complete object, and ensures data is fetched if not already available.',
      orderTypeByCode: 'Order Type By Code',
      orderTypeByCodeDescription:
        'Loads order type data by code from the store, retrieves specific fields like the name, ' +
        'or the complete object, and ensures data is fetched if not already available.',
      orderTypeById: 'Order Type By Id',
      orderTypeByIdDescription:
        'Loads order type data by ID from the store, retrieves specific fields like the name, ' +
        'or the complete object, and ensures data is fetched if not already available.',
      productOrderLines: 'Product Order Lines',
      productOrderLinesDescription:
        'Loads order line data associated with a specific entity instance configuration ID from ' +
        'the store, retrieves either all fields or specific ones, and dispatches the action to ' +
        'fetch the data if not already available.',
      quoteLoader: 'Quote Loader',
      quoteLoaderDescription:
        'Loads quote data by ID from the store, retrieves specific fields like the subject, or the ' +
        'complete object, and ensures the data is fetched if it is not already available.',
    },
  },
};
