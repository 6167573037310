import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';

import {BehaviorSubject, Observable} from 'rxjs';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {map, take, tap} from 'rxjs/operators';
import {AccessRules} from '../models';
import {toSignal} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AccessRulesGuard implements CanActivateChild {
  constructor(
    private configService: ConfigService,
    public apiService: ApiService,
  ) {}

  private _accessRules: BehaviorSubject<AccessRules> =
    new BehaviorSubject<AccessRules>(null);
  accessRules$: Observable<AccessRules> = this._accessRules.asObservable();
  accessRules = toSignal(this.accessRules$);
  _lastUrl = '';
  _lastId = '';

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    const routeData: any = route.data;
    if (routeData.accessRulesUrl === undefined) {
      this._accessRules.next({
        writeAccess: true,
        readAccess: true,
        entity: null,
      });
      if (route.data != null && Object.keys(route.data).length != 0) {
        this._lastId = '';
        this._lastUrl = '';
      }
      return true;
    }
    if (route.params['id']) {
      const newUrl =
        this.configService.value.apiUrls.base +
        routeData.accessRulesUrl +
        '/' +
        route.params['id'];
      if (newUrl !== this._lastUrl) {
        return this.apiService
          .get<
            AccessRules,
            AccessRules
          >(this.configService.value.apiUrls.base + routeData.accessRulesUrl + '/' + route.params['id'])
          .pipe(
            tap((x) => {
              if (x.success && x.data.readAccess) {
                this._lastUrl = newUrl;
                this._lastId = route.params['id'];
                this._accessRules.next(x.data);
              }
            }),
            map((x) => {
              return x.success && x.data.readAccess;
            }),
          );
      } else {
        return true;
      }
    } else if (route.params['key']) {
      const newUrl =
        this.configService.value.apiUrls.base +
        routeData.accessRulesUrl +
        '/key/' +
        route.params['key'];
      if (newUrl !== this._lastUrl) {
        return this.apiService
          .get<
            AccessRules,
            AccessRules
          >(this.configService.value.apiUrls.base + routeData.accessRulesUrl + '/key/' + route.params['key'])
          .pipe(
            tap((x) => {
              if (x.success && x.data.readAccess) {
                this._lastUrl = newUrl;
                this._lastId = route.params['key'];
                this._accessRules.next(x.data);
              }
            }),
            map((x) => {
              return x.success && x.data.readAccess;
            }),
          );
      } else {
        return true;
      }
    } else {
      this._accessRules.next({
        writeAccess: false,
        readAccess: false,
        entity: null,
      });
      return false;
    }
  }

  /**
   * Refreshne accessRules na zaklade URL
   * Data se pripadne berou z accessRules$
   * @param url - napr: 'tsm-ticket/api/ticket/elastic/check-accessrules/dd288131-836d-4253-b5f6-0240b625b297'
   * @param id
   */
  refreshAccessRules(url: string, id: string) {
    if (!id) {
      this._accessRules.next({
        writeAccess: false,
        readAccess: false,
        entity: null,
      });
      return;
    }
    const newUrl = this.configService.value.apiUrls.base + url + '/' + id;
    this.apiService
      .get<AccessRules, AccessRules>(newUrl)
      .pipe(
        map((x) => {
          if (x.success && x.data.readAccess) {
            this._accessRules.next(x.data);
          }
        }),
        take(1),
        // eslint-disable-next-line rxjs/no-ignored-subscribe
      )
      .subscribe();
  }

  cleanState() {
    this._lastUrl = null;
    this._lastId = null;
  }
}
