import {SelectItem} from 'primeng/api/selectitem';

export enum NotificationRuleEventType {
  SYSTEM = 'SYSTEM',
  PROCESS = 'PROCESS',
  KAFKA = 'KAFKA',
  REST = 'REST',
  DIRECT = 'DIRECT',
}

export const getAllNotificationRuleEventTypes = (): SelectItem[] =>
  Object.keys(NotificationRuleEventType).map(
    (val) =>
      ({label: NotificationRuleEventType[val], value: val}) as SelectItem,
  );
