
import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '../i18n';

@Pipe({
  name: 'userParameter',
  pure: true,
})
export class UserParameterPipe implements PipeTransform {
  translation = translation;

  constructor(private ts: TranslocoService) {}

  transform(value: any): any {
    if (value) {
      return this.ts.translate('userManagementService.userParameter.' + value);
    } else {
      return '';
    }
  }
}
