<ng-container *ngLet="filteredMenuItems$ | async as layoutMenu">
  <div
    class="filter-wrapper flex sticky z-1 top-0 px-2 py-3"
    style="backdrop-filter: blur(1px)"
  >
    <div
      class="p-input-icon-left w-full relative inline-flex align-items-center"
    >
      <i class="pi pi-search" style="top: initial; margin-top: 2px"></i>
      <input
        #searchMenuInput
        pInputText
        [formControl]="searchControl"
        type="text"
        [placeholder]="translationShared.shared.search | transloco"
        [attr.aria-label]="translationShared.shared.search | transloco"
        class="p-inputtext-sm w-full pr-5"
      />
      <i
        *ngIf="searchControl.value?.length > 0"
        class="pi pi-times p-color-red absolute right cursor-pointer border-circle p-1 hover:bg-red-500 hover:text-white text-xs transition-all transition-duration-200"
        style="top: initial; margin-top: 2px; right: 0.5rem"
        (click)="searchControl.reset(); searchMenuInput.focus()"
      ></i>
    </div>
  </div>
  <ng-container *ngLet="loading$ | async as loading">
    <div *ngIf="loading" class="p-3">
      <div>
        <p-skeleton styleClass="mb-3" width="30%"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div class="mt-5">
        <p-skeleton styleClass="mb-3" width="30%"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div class="mt-5">
        <p-skeleton styleClass="mb-3" width="30%"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton styleClass="mb-3"></p-skeleton>
      </div>
    </div>

    <ul class="layout-menu" *ngIf="!loading">
      <ng-container *ngLet="bookmarkMenuState$ | async as bookmarkMenu">
        <ng-container *ngFor="let item of layoutMenu; let i = index">
          <li
            tsm-layout-menu-item
            *ngIf="!item.separator"
            [item]="item"
            [index]="i"
            [root]="true"
            [menuPinned]="menuPinned"
            [bookmarkMenuItems]="bookmarkMenu"
            (toggleBookmark)="onToggleBookmark($event)"
            [searchCharCount]="searchControl.value?.length"
          ></li>
          <li *ngIf="item.separator" class="menu-separator"></li>
        </ng-container>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>
