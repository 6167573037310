import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/role-priv.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getRolePrivState = createSelector(
  getUserManagementState,
  (rolePrivState) => rolePrivState.rolePriv,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getRolePrivState);

export const selectRolePrivByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectRolePrivsByRoleId = (roleId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((p) => p.data.role.id === roleId),
  );

export const selectRolePrivById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllRolePrivs = selectAll;
