import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';

export const reportingPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'reporting',
      loadChildren: () =>
        import('@tsm/reporting/components').then(
          (x) => x.ReportingComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/reporting/widgets').then((x) => x.ReportingWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/reporting/widgets/pivot-reporting-widget/pivot-reporting-widget'
            ).then((x) => x.PivotReportingWidgetComponent),
          selector: 'tsm-pivot-reporting-widget',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/reporting/widgets/pivot-reporting-widget/pivot-reporting-widget-editor'
                ).then((x) => x.PivotReportingWidgetEditorComponent),
              editorSelector: 'tsm-pivot-reporting-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Reporting,
              name: translationShared.shared.widgets.reportTable.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.reportTable.widgetDescription,
              group: GroupPosition.Primary,
              order: 1, // Adjust order according to the sequence
              icon: DesignerIconsEnum.table,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.reportTable.inWidgetTitle,
                widget: {
                  type: 'tsm-pivot-reporting-widget',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/reporting/widgets/chart-reporting-widget/chart-reporting-widget'
            ).then((x) => x.ChartReportingWidgetComponent),
          selector: 'tsm-chart-reporting-widget',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/reporting/widgets/chart-reporting-widget/chart-reporting-widget-editor'
                ).then((x) => x.ChartReportingWidgetEditorComponent),
              editorSelector: 'tsm-chart-reporting-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Reporting,
              name: translationShared.shared.widgets.reportChart.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.reportChart.widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.chart,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.reportChart.inWidgetTitle,
                widget: {
                  type: 'tsm-chart-reporting-widget',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/reporting/widgets').then((x) => x.ReportingWidgetsModule),
      definitions: [
        // {selector: 'dtl-filter-input'},
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/reporting/widgets').then((x) => x.ReportingWidgetsModule),
      definitions: [
        // {selector: 'filtersToString'},
      ],
    },
  ],
};
