import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'description',
  pure: true,
})
export class DescriptionPipe implements PipeTransform {
  constructor() {}

  /**
   *  Na GUI nedela vubec nic, ve sloupeckach nam nevadi naformatovany HTML,
   *  kazdopadne na backendu je to problem.
   *  Na backemdu ten konvertor odstrani HTML znaky
   */
  transform(value: any): any {
    return value;
  }
}
