import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import * as fromRuntimeInfoReducer from './runtime-info.reducer';
import {RuntimeInfo} from '../models';

export interface RuntimeInfoState {
  runtimeInfo: RuntimeInfo;
}

export function reducers(state: RuntimeInfoState | undefined, action: Action) {
  return combineReducers({
    runtimeInfo: fromRuntimeInfoReducer.runtimeInfoReducer,
  })(state, action);
}

export const metaReducers: MetaReducer<RuntimeInfoState>[] = [];

const getRuntimeInfoState =
  createFeatureSelector<RuntimeInfoState>('runtimeInfo');
export const getRuntimeInfo = createSelector(
  getRuntimeInfoState,
  (x) => x.runtimeInfo,
);
