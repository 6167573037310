import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {
  DeleteFormatterTemplate,
  DeleteFormatterTemplateError,
  DeleteFormatterTemplateSuccess,
  DiffFormatterTemplate,
  DiffFormatterTemplateError,
  DiffFormatterTemplateSuccess,
  LoadFormatterTemplateById,
  LoadFormatterTemplateByIdError,
  LoadFormatterTemplateByIdSuccess,
  UpsertFormatterTemplate,
  UpsertFormatterTemplateError,
  UpsertFormatterTemplateSuccess,
} from '../actions';
import {CommonApiService, FormatterTemplateService} from '../services';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {getUrlSegments} from '@tsm/core';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {exhaustBy, withLatestCached} from '@tsm/framework/root';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {FormatterTemplate} from '../models';

@Injectable()
export class FormatterTemplateEffect {
  translation = translation;
  translationShared = translationShared;
  private readonly API_PATH = 'v1/formatter-templates';

  constructor(
    private actions$: Actions,
    private formatterTemplateService: FormatterTemplateService,
    private ts: ToastService,
    private translocoService: TranslocoService,
    private router: Router,
    private state: Store<any>,
    private commonApiService: CommonApiService<
      FormatterTemplate,
      FormatterTemplate
    >,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFormatterTemplateById),
      mergeMap(({id}) =>
        this.formatterTemplateService.getTemplateById(id).pipe(
          map((env) =>
            env.success
              ? LoadFormatterTemplateByIdSuccess({
                  formatterTemplate: env.data,
                })
              : LoadFormatterTemplateByIdError({id, error: env.error}),
          ),
        ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertFormatterTemplate),
      exhaustBy(
        (x) => x.formatterTemplate,
        ({formatterTemplate, listingId, redirectToDetail}) => {
          return this.formatterTemplateService
            .saveTemplate(formatterTemplate)
            .pipe(
              tap((env) => {
                if (env.success) {
                  this.ts.showToast(
                    this.translocoService.translate(
                      translation.formatterTemplate.save.success,
                    ),
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                } else {
                  this.ts.showError(
                    env.error,
                    translation.formatterTemplate.save.error,
                  );
                }
              }),
              map((env) =>
                env.success
                  ? UpsertFormatterTemplateSuccess({
                      formatterTemplate: env.data,
                      listingId: listingId,
                      redirectToDetail: redirectToDetail,
                    })
                  : UpsertFormatterTemplateError(env.error),
              ),
            );
        },
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteFormatterTemplate),
      exhaustBy(
        (x) => x.id,
        ({id, listingId, shouldRedirectToListing}) => {
          return this.formatterTemplateService.deleteTemplateById(id).pipe(
            tap((env) => {
              if (env.success) {
                this.ts.showToast(
                  this.translocoService.translate(
                    translation.formatterTemplate.delete.success,
                  ),
                  ToastSeverity.SUCCESS,
                  3000,
                );
                if (shouldRedirectToListing) {
                  this.router.navigate(['/dms/formatter-template']);
                }
              } else {
                this.ts.showError(
                  env.error,
                  translation.formatterTemplate.delete.error,
                );
              }
            }),
            map((env) =>
              env.success
                ? DeleteFormatterTemplateSuccess({id: id, listingId: listingId})
                : DeleteFormatterTemplateError(env.error),
            ),
          );
        },
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffFormatterTemplate),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffFormatterTemplateSuccess({
                  formatterTemplates: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffFormatterTemplateError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertFormatterTemplateSuccess),
      withLatestCached((action) => this.state.select(getUrlSegments)),
      tap(([action, segments]) => {
        const index = segments.findIndex((s) => s === 'popup');
        const url =
          index === -1
            ? segments.join('/')
            : segments.slice(0, index).join('/');
        this.router.navigate([url]);
      }),
      map(([action]) => RefreshDataAndClearSelected({id: action.listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteFormatterTemplateSuccess, DiffFormatterTemplateSuccess),
      map((action) => RefreshDataAndClearSelected({id: action.listingId})),
    ),
  );

  redirectToDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertFormatterTemplateSuccess),
      tap(({formatterTemplate, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            '/dms/formatter-template/'.concat(formatterTemplate.id),
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
