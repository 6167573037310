export const en = {
  shared: {
    detailUrl: 'Detail url',
    lineType: 'Order line type',
    rowIdField: 'ID item field',
    emptyMessage: 'No records found',
    actionsMetadata: {
      navigate: 'Navigate',
      showMessage: 'Show Message',
      logout: 'Logout',
      loadTicketById: 'Load Ticket by ID',
      loadTicketByKey: 'Load Ticket by Key',
      loadCustomerById: 'Load Customer by ID',
      loadCustomerByKey: 'Load Customer by Key',
      loadLeadById: 'Load Lead by ID',
      loadLeadByKey: 'Load Lead by Key',
      loadPersonById: 'Load Person by ID',
      loadPersonByKey: 'Load Person by Key',
      loadAccountById: 'Load Account by ID',
      loadAccountByKey: 'Load Account by Key',
      loadCampaignById: 'Load Campaign by ID',
      loadCampaignByCode: 'Load Campaign by Code',
      loadOrderById: 'Load Order by ID',
      loadOrderByKey: 'Load Order by Key',
      loadTaskByExecutionIdAndActivityId:
        'Load Task by executionId and activityId',
      loadTasksByBusinessKey: 'Load Tasks by businessKey',
      loadCatalogById: 'Load Catalog by ID',
      loadCatalogByCode: 'Load Catalog by Code',
      loadCatalogCategoryById: 'Load Catalog Category by ID',
      loadCatalogCategoryByCodeAndCatalogCode:
        'Load Catalog Category by Code and Catalog Code',
      loadCatalogSpecificationByCategoryId:
        'Load Catalog Specification by Category ID',
      loadCatalogSpecificationByCatalogId:
        'Load Catalog Specification by Catalog ID',
      loadCatalogSpecificationByCodeAndCatalogType:
        'Load Catalog Specification by Code and Catalog Type',
      loadCatalogSpecificationById: 'Load Catalog Specification by ID',
      loadCatalogSpecificationByCode: 'Load Catalog Specification by Code',
      loadByIdRegisterValue: 'Load Register Value by ID',
      loadByCodeRegisterValue:
        'Load Register Value by Code register value Code and register Code',
      loadEntityInstanceConfigurationById:
        'Load Entity Instance Configuration by ID',
      loadEntityInstanceConfigurationByKey:
        'Load Entity Instance Configuration by key',
      loadEntityInstanceConfigurationsByEntityInstanceId:
        'Load Entity Instance Configurations by Entity Instance ID',
      loadStockById: 'Load Stock by ID',
      loadStockByKey: 'Load Stock by Key',
      loadMaintenanceActivityById: 'Load Maintenance Activity by ID',
      loadMaintenancePlanById: 'Load Maintenance Plan by ID',
      loadMaintenancePlanByKey: 'Load Maintenance Plan by Key',
      loadOrderLinesByOrderId: 'Load Order Lines by Order ID',
      loadOrderLineById: 'Load Order Line by ID',
      loadOrderLinesByIds: 'Load Order Lines by IDs',
      loadRegisterById: 'Load Register by ID',
      loadRegisterByCode: 'Load Register by Code',
      loadStockItemById: 'Load Stock Item by ID',
      loadStockItemByKey: 'Load Stock Item by Key',
      loadStockItemBySerialNumber: 'Load Stock Item by Serial Number',
    },
    activityId: 'Activity id',
    executionId: 'Execution id',
    templates: 'Templates',
    print: 'Print',
    reloadAgain: 'Reload again',
    openInNewTab: 'Open in new tab',
    continue: 'Continue',
    removeValueOnChange: 'Remove value on change',
    hideCode: 'Hide code',
    listingWidgets: {
      sections: {
        commonAttributes: 'Common Attributes',
        commonProperties: 'Common Properties',
        tqlExtensions: 'TQL Extensions',
        widgetSpecificSettings: 'Widget Specific Settings',
        newFormSettings: 'New Form Settings',
        filterSettings: 'Filter Settings',
      },
    },
    widgets: {
      customerList: {
        widgetDescription:
          'Customer list in a tabular view with filtering and editing options.',
        widgetName: 'Customer List',
        inWidgetTitle: 'Customer List',
      },
      campaignWaveList: {
        widgetDescription:
          'Campaign wave list in a tabular view with filtering and editing options.',
        widgetName: 'Campaign Wave List',
        inWidgetTitle: 'Campaign Wave List',
      },
      leadList: {
        widgetDescription:
          'Lead list in a tabular view with filtering and editing options.',
        widgetName: 'Lead List',
        inWidgetTitle: 'Lead List',
      },
      accountsBookmark: {
        widgetDescription: 'Simple list of accounts in a tabular view.',
        widgetName: 'Accounts Bookmark',
        inWidgetTitle: '',
      },
      personsBookmark: {
        widgetDescription: 'Simple list of persons in a tabular view.',
        widgetName: 'Persons Bookmark',
        inWidgetTitle: '',
      },
      addressesBookmark: {
        widgetDescription: 'Simple list of addresses in a tabular view.',
        widgetName: 'Addresses Bookmark',
        inWidgetTitle: '',
      },
      addressRolesBookmark: {
        widgetDescription: 'Simple list of address roles in a tabular view.',
        widgetName: 'Address Roles Bookmark',
        inWidgetTitle: '',
      },
      responsiblePersonsBookmark: {
        widgetDescription:
          'Simple list of responsible persons in a tabular view.',
        widgetName: 'Responsible Persons Bookmark',
        inWidgetTitle: '',
      },
      contactsBookmark: {
        widgetDescription: 'Simple list of contacts in a tabular view.',
        widgetName: 'Contacts Bookmark',
        inWidgetTitle: '',
      },
      accountTypeSelect: {
        widgetDescription: 'Selection of an account type from a dropdown list.',
        widgetName: 'Account type select',
        inWidgetTitle: 'Select an account type',
      },
      campaignSelect: {
        widgetDescription: 'Selection of a campaign from a dropdown list.',
        widgetName: 'Campaign Select',
        inWidgetTitle: 'Select a campaign',
      },
      campaignTypeSelect: {
        widgetDescription: 'Selection of a campaign type from a dropdown list.',
        widgetName: 'Campaign Type Select',
        inWidgetTitle: 'Select a campaign type',
      },
      customerSelect: {
        widgetDescription: 'Selection of a customer from a dropdown list.',
        widgetName: 'Customer Select',
        inWidgetTitle: 'Select a customer',
      },
      accountSelect: {
        widgetDescription: 'Selection of an account from a dropdown list.',
        widgetName: 'Account Select',
        inWidgetTitle: 'Select an account',
      },
      leadSelect: {
        widgetDescription: 'Selection of a lead from a dropdown list.',
        widgetName: 'Lead Select',
        inWidgetTitle: 'Select a lead',
      },
      leadTypeSelect: {
        widgetDescription: 'Selection of a lead type from a dropdown list.',
        widgetName: 'Lead type Select',
        inWidgetTitle: 'Select a lead type',
      },
      personSelect: {
        widgetDescription: 'Selection of a person from a dropdown list.',
        widgetName: 'Person Select',
        inWidgetTitle: 'Select a person',
      },
      personTypeSelect: {
        widgetDescription: 'Selection of a person type from a dropdown list.',
        widgetName: 'Person Type Select',
        inWidgetTitle: 'Select a person type',
      },
      addressSelect: {
        widgetDescription: 'Selection of an address from a dropdown list.',
        widgetName: 'Address Select',
        inWidgetTitle: 'Select an address',
      },
      addressRoleTypeSelect: {
        widgetDescription:
          'Selection of an address role type from a dropdown list.',
        widgetName: 'Address Role Type Select',
        inWidgetTitle: 'Select an address role type',
      },
      legalFormSelect: {
        widgetDescription: 'Selection of a legal form from a dropdown list.',
        widgetName: 'Legal Form Select',
        inWidgetTitle: 'Select a legal form',
      },
      customerSegmentSelect: {
        widgetDescription:
          'Selection of a customer segment from a dropdown list.',
        widgetName: 'Customer Segment Select',
        inWidgetTitle: 'Select a customer segment',
      },
      customerTypeSelect: {
        widgetDescription: 'Selection of a customer type from a dropdown list.',
        widgetName: 'Customer Type Select',
        inWidgetTitle: 'Select a customer type',
      },
      campaignStatusBar: {
        widgetDescription:
          'Overview of the current status of the campaign with quick switching options.',
        widgetName: 'Campaign Status Bar',
        inWidgetTitle: 'Select a campaign status bar',
      },
      campaignWaveDefinition: {
        widgetDescription:
          'Overview of the current campaign wave definition with quick switching options.',
        widgetName: 'Caimpaign Wave Definition',
        inWidgetTitle: 'Select a campaign wave definition',
      },
      customerStatusBar: {
        widgetDescription:
          'Overview of the current status of the customer with quick switching options.',
        widgetName: 'Customer Status Bar',
        inWidgetTitle: '',
      },
      accountStatusBar: {
        widgetDescription:
          'Overview of the current status of the account with quick switching options.',
        widgetName: 'Account Status Bar',
        inWidgetTitle: '',
      },
      leadStatusBar: {
        widgetDescription:
          'Overview of the current status of the lead with quick switching options.',
        widgetName: 'Lead Status Bar',
        inWidgetTitle: '',
      },
      personStatusBar: {
        widgetDescription:
          'Overview of the current status of the person with quick switching options.',
        widgetName: 'Person Status Bar',
        inWidgetTitle: '',
      },
      plannedTaskCard: {
        widgetDescription:
          'Card displaying details of a scheduled task from work force management.',
        widgetName: 'Planned Task Card',
        inWidgetTitle: '',
      },
      productCard: {
        widgetDescription: 'Card displaying information about the product.',
        widgetName: 'Product Card',
        inWidgetTitle: '',
      },
      createFromAresButton: {
        widgetDescription:
          'Button to create a customer from the ares register.',
        widgetName: 'Create from Ares Button',
        inWidgetTitle: '',
      },
      customerAuditList: {
        widgetDescription:
          'History of actions and changes made to the customer in a clear view.',
        widgetName: 'Customer History',
        inWidgetTitle: '',
      },
      accountAuditList: {
        widgetDescription:
          'History of actions and changes made to the account in a clear view.',
        widgetName: 'Account History',
        inWidgetTitle: '',
      },
      leadAuditList: {
        widgetDescription:
          'History of actions and changes made to the lead in a clear view.',
        widgetName: 'Lead History',
        inWidgetTitle: '',
      },
      personAuditList: {
        widgetDescription:
          'History of actions and changes made to the person in a clear view.',
        widgetName: 'Person History',
        inWidgetTitle: '',
      },
      billingCycleTypeSelect: {
        widgetDescription:
          'Selection of a Billing Cycle Type from a dropdown list.',
        widgetName: 'Billing Cycle Type Select',
        inWidgetTitle: 'Select Billing Cycle Type',
      },
      billingCurrencySelect: {
        widgetDescription:
          'Selection of a Billing Currency from a dropdown list.',
        widgetName: 'Billing Currency Select',
        inWidgetTitle: 'Select Billing Currency',
      },
      billingCycleSelect: {
        widgetDescription: 'Selection of a Billing Cycle from a dropdown list.',
        widgetName: 'Billing Cycle Select',
        inWidgetTitle: 'Select Billing Cycle',
      },
      billingCycleStatusSelect: {
        widgetDescription:
          'Selection of a Billing Cycle Status from a dropdown list.',
        widgetName: 'Billing Cycle Status Select',
        inWidgetTitle: 'Select Billing Cycle Status',
      },
      billingDocumentTypeSelect: {
        widgetDescription:
          'Selection of a Billing Document Type from a dropdown list.',
        widgetName: 'Billing Document Type Select',
        inWidgetTitle: 'Select Billing Document Type',
      },
      billingDocumentLineSelect: {
        widgetDescription:
          'Selection of a Billing Document Line from a dropdown list.',
        widgetName: 'Billing Document Line Select',
        inWidgetTitle: 'Select Billing Document Line',
      },
      billingDocumentStatusSelect: {
        widgetDescription:
          'Selection of a Billing Document Status from a dropdown list.',
        widgetName: 'Billing Document Status Select',
        inWidgetTitle: 'Select Billing Document Status',
      },
      billingSapDocumentStatusSelect: {
        widgetDescription:
          'Selection of a  Billing SAP Document Status from a dropdown list.',
        widgetName: 'Billing SAP Document Status Select',
        inWidgetTitle: 'Select Billing SAP Document Status',
      },
      billingDocumentSelect: {
        widgetDescription:
          'Selection of a Billing Document from a dropdown list.',
        widgetName: 'Billing Document Select',
        inWidgetTitle: 'Select Billing Document',
      },
      vatRateSelect: {
        widgetDescription: 'Selection of a VAT rate from a dropdown list.',
        widgetName: 'VAT Rate Select',
        inWidgetTitle: 'Select VAT rate',
      },
      billingDocumentList: {
        widgetDescription:
          'Billing Document list in a tabular view with filtering and editing options.',
        widgetName: 'Billing Document List',
        inWidgetTitle: '',
      },
      orderLinesBookmark: {
        widgetDescription: 'Simple list of order lines in a tabular view.',
        widgetName: 'Order Lines Bookmark',
        inWidgetTitle: 'Order Lines Bookmark',
      },
      orderStatusBar: {
        widgetDescription:
          'Overview of the current status of the order with quick switching options.',
        widgetName: 'Order Status Bar',
        inWidgetTitle: 'Order Status Bar',
      },
      quotesBookmark: {
        widgetDescription: 'Simple list of quotes in a tabular view.',
        widgetName: 'Quotes Bookmark',
        inWidgetTitle: 'Quotes Bookmark',
      },
      ordersBookmark: {
        widgetDescription: 'Simple list of orders in a tabular view.',
        widgetName: 'Orders Bookmark',
        inWidgetTitle: 'Orders Bookmark',
      },
      orderSelect: {
        widgetDescription: 'Selection of an order from a dropdown list.',
        widgetName: 'Order Select',
        inWidgetTitle: 'Select an Order',
      },
      orderStatusSelect: {
        widgetDescription: 'Selection of an order status from a dropdown list.',
        widgetName: 'Order Status Select',
        inWidgetTitle: 'Select an order status',
      },
      orderTypeSelect: {
        widgetDescription: 'Selection of an order type from a dropdown list.',
        widgetName: 'Order Type Select',
        inWidgetTitle: 'Select an order type',
      },
      orderTaskList: {
        widgetDescription:
          'Order task list in a tabular view with filtering and editing options.',
        widgetName: 'Order Task List',
        inWidgetTitle: 'Order Task List',
      },
      orderList: {
        widgetDescription:
          'Order list in a tabular view with filtering and editing options.',
        widgetName: 'Order List',
        inWidgetTitle: 'Order List',
      },
      orderLine: {
        widgetDescription:
          'Order line in a tabular view with filtering and editing options.',
        widgetName: 'Order line list',
        inWidgetTitle: 'Order line list',
      },
      relatedEntityOrderList: {
        widgetDescription:
          'Related entity order list in a tabular view with filtering and editing options.',
        widgetName: 'Related Entity Order List',
        inWidgetTitle: 'Related Entity Order List',
      },
      ticketRelationshipList: {
        widgetDescription:
          'Relationship list of tickets in a tabular view with filtering and editing options.',
        widgetName: 'Ticket Relationship List',
        inWidgetTitle: 'Ticket Relationship List',
      },
      ticketAuditList: {
        widgetDescription:
          'History of actions and changes related to the ticket in a clear view.',
        widgetName: 'Ticket History',
        inWidgetTitle: 'Ticket History',
      },
      adviceList: {
        widgetDescription:
          'Advice list in a tabular view with filtering and editing options.',
        widgetName: 'Advice List',
        inWidgetTitle: 'Advice List',
      },
      relatedEntityList: {
        widgetDescription:
          'Related entity information list in a tabular view with filtering and editing options..',
        widgetName: 'Related Entity List',
        inWidgetTitle: 'Related Entity List',
      },
      ticketTaskList: {
        widgetDescription:
          'Task list related to tickets in a tabular view with filtering and editing options.',
        widgetName: 'Ticket Task List',
        inWidgetTitle: 'Ticket Task List',
      },
      ticketList: {
        widgetDescription:
          'Ticket list in a tabular view with filtering and editing options.',
        widgetName: 'Ticket List',
        inWidgetTitle: 'Ticket List',
      },
      ticketRelatedEntityList: {
        widgetDescription:
          'Related entity ticket list in a tabular view with filtering and editing options.',
        widgetName: 'Ticket Related Entity List',
        inWidgetTitle: 'Ticket Related Entity List',
      },
      ticketCategorySelect: {
        widgetDescription:
          'Selection of a ticket category from a dropdown list.',
        widgetName: 'Ticket Category Select',
        inWidgetTitle: 'Select a Ticket Category',
      },
      adviceTypeSelect: {
        widgetDescription: 'Selection of an advice type from a dropdown list.',
        widgetName: 'Advice Type Select',
        inWidgetTitle: 'Select an Advice Type',
      },
      ticketProblemSelect: {
        widgetDescription:
          'Selection of a ticket problem from a dropdown list.',
        widgetName: 'Ticket Problem Select',
        inWidgetTitle: 'Select a Ticket Problem',
      },
      ticketProblemTreeSelect: {
        widgetDescription:
          'Selection of a ticket problem from a tree-structured dropdown list.',
        widgetName: 'Ticket Problem Tree Select',
        inWidgetTitle: 'Select a Ticket Problem',
      },
      ticketResolutionSelect: {
        widgetDescription:
          'Selection of a ticket resolution from a dropdown list.',
        widgetName: 'Ticket Resolution Select',
        inWidgetTitle: 'Select a Ticket Resolution',
      },
      ticketSelect: {
        widgetDescription: 'Selection of a ticket from a dropdown list.',
        widgetName: 'Ticket Select',
        inWidgetTitle: 'Select a Ticket',
      },
      ticketRelatedPartySelect: {
        widgetDescription:
          'Selection of a ticket related party from a dropdown list.',
        widgetName: 'Ticket Related Party Select',
        inWidgetTitle: 'Select a Ticket related party',
      },
      ticketStatusSelect: {
        widgetDescription: 'Selection of a ticket status from a dropdown list.',
        widgetName: 'Ticket Status Select',
        inWidgetTitle: 'Select a Ticket Status',
      },
      ticketRelatedPartyRoleSelect: {
        widgetDescription:
          'Selection of a ticket related party role from a dropdown list.',
        widgetName: 'Ticket Related Party Role Select',
        inWidgetTitle: 'Select a Ticket Related Party Role',
      },
      ticketPrioritySelect: {
        widgetDescription:
          'Selection of a ticket priority from a dropdown list.',
        widgetName: 'Ticket Priority Select',
        inWidgetTitle: 'Select a Ticket Priority',
      },
      ticketSeveritySelect: {
        widgetDescription:
          'Selection of a ticket severity from a dropdown list.',
        widgetName: 'Ticket Severity Select',
        inWidgetTitle: 'Select a Ticket Severity',
      },
      ticketRelatedEntitySelect: {
        widgetDescription:
          'Selection of a related entity for tickets from a dropdown list.',
        widgetName: 'Ticket Related Entity Select',
        inWidgetTitle: 'Select a Related Entity',
      },
      ticketTypeSelect: {
        widgetDescription: 'Selection of a ticket type from a dropdown list.',
        widgetName: 'Ticket Type Select',
        inWidgetTitle: 'Select a Ticket Type',
      },
      ticketTemplateTypeSelect: {
        widgetDescription:
          'Selection of a ticket template type from a dropdown list.',
        widgetName: 'Ticket Template Type Select',
        inWidgetTitle: 'Select a Ticket Template Type',
      },
      adviceActivePanel: {
        widgetDescription: 'Panel displaying details of ticket active advices.',
        widgetName: 'Active Advices Panel',
        inWidgetTitle: 'Active Advices Panel',
      },
      ticketCategoryRegistrySelect: {
        widgetDescription:
          'Selection of a ticket category registry from a dropdown list.',
        widgetName: 'Ticket Category Registry Select',
        inWidgetTitle: 'Select a Ticket Category Registry',
      },
      ticketRelatedEntityTypeSelect: {
        widgetDescription:
          'Selection of a ticket related entity type from a dropdown list.',
        widgetName: 'Ticket Related Entity Type Select',
        inWidgetTitle: 'Select a Ticket Related Entity Type',
      },
      ticketChannelSelect: {
        widgetDescription:
          'Selection of a ticket channel from a dropdown list.',
        widgetName: 'Ticket Channel Select',
        inWidgetTitle: 'Select a Ticket Channel',
      },
      relationshipBookmark: {
        widgetDescription: 'Bookmark displaying relationship information.',
        widgetName: 'Relationship Bookmark',
        inWidgetTitle: 'Relationship Bookmark',
      },
      stockItemList: {
        widgetDescription:
          'Stock item list in a tabular view with filtering and editing options.',
        widgetName: 'Stock Item List',
        inWidgetTitle: 'Stock Item List',
      },
      stockSelect: {
        widgetDescription: 'Selection of stock from a dropdown list.',
        widgetName: 'Stock Select',
        inWidgetTitle: 'Select Stock',
      },
      stockTypeSelect: {
        widgetDescription: 'Selection of a stock type from a dropdown list.',
        widgetName: 'Stock Type Select',
        inWidgetTitle: 'Select Stock type',
      },
      stockItemSelect: {
        widgetDescription: 'Selection of a stock item from a dropdown list.',
        widgetName: 'Stock Item Select',
        inWidgetTitle: 'Select Stock Item',
      },
      stockItemTypeSelect: {
        widgetDescription:
          'Selection of a stock item type from a dropdown list.',
        widgetName: 'Stock Item Type Select',
        inWidgetTitle: 'Select Stock item type',
      },
      stockMovementTypeSelect: {
        widgetDescription:
          'Selection of a stock movement type from a dropdown list.',
        widgetName: 'Stock Movement Type Select',
        inWidgetTitle: 'Select Stock movement type',
      },
      reportTable: {
        widgetDescription:
          'Tabular representation of report data with filtering options.',
        widgetName: 'Report Table',
        inWidgetTitle: 'Report Table',
      },
      reportChart: {
        widgetDescription: 'Graphical representation of report data.',
        widgetName: 'Report Chart',
        inWidgetTitle: 'Report Chart',
      },
      userSelect: {
        widgetDescription: 'Selection of a user from a dropdown list.',
        widgetName: 'User Select',
        inWidgetTitle: 'Select User',
      },
      userRoleSelect: {
        widgetDescription: 'Selection of a user role from a dropdown list.',
        widgetName: 'User Role Select',
        inWidgetTitle: 'Select User Role',
      },
      userGroupSelect: {
        widgetDescription: 'Selection of a user group from a dropdown list.',
        widgetName: 'User Group Select',
        inWidgetTitle: 'Select User Group',
      },
      userGroupTypeSelect: {
        widgetDescription:
          'Selection of a user group type from a dropdown list.',
        widgetName: 'User Group Type Select',
        inWidgetTitle: 'Select User Group Type',
      },
      userPrivilegeSelect: {
        widgetDescription:
          'Selection of a user privilege from a dropdown list.',
        widgetName: 'User Privilege Select',
        inWidgetTitle: 'Select User privilege',
      },
      userTypeSelect: {
        widgetDescription: 'Selection of a user type from a dropdown list.',
        widgetName: 'User Type Select',
        inWidgetTitle: 'Select User type',
      },
      logList: {
        widgetDescription:
          'Log list in a tabular view with filtering and editing options.',
        widgetName: 'Log List',
        inWidgetTitle: 'Log List',
      },
      addressSearch: {
        widgetDescription:
          'Component for searching and selecting addresses with advanced filtering.',
        widgetName: 'Address Search',
        inWidgetTitle: 'Address Search',
      },
      scriptSelect: {
        widgetDescription: 'Selection of a script from a dropdown list.',
        widgetName: 'Script Select',
        inWidgetTitle: 'Select a Script',
      },
      scheduleActivitiesSelect: {
        widgetDescription:
          'Selection of schedule activities from a dropdown list.',
        widgetName: 'Schedule Activities Select',
        inWidgetTitle: 'Select Schedule Activities',
      },
      technologySelect: {
        widgetDescription: 'Selection of technologies from a dropdown list.',
        widgetName: 'Technology Select',
        inWidgetTitle: 'Select Technology',
      },
      scheduleBookmark: {
        widgetDescription:
          'Overview of the schedule with quick access options.',
        widgetName: 'Schedule Bookmark',
        inWidgetTitle: 'Schedule',
      },
      changeRequestList: {
        widgetDescription:
          'List of change requests in a tabular view with filtering and editing options.',
        widgetName: 'Change Request List',
        inWidgetTitle: 'Change Request List',
      },
      workLogLocationsList: {
        widgetDescription:
          'List of work log locations in a tabular view with filtering and editing options.',
        widgetName: 'Work Log Locations List',
        inWidgetTitle: 'Work Log Locations List',
      },
      changeRequestSelect: {
        widgetDescription: 'Selection of change requests from a dropdown list.',
        widgetName: 'Change Request Select',
        inWidgetTitle: 'Select Change Request',
      },
      operationImpactSelect: {
        widgetDescription:
          'Selection of operation impact from a dropdown list.',
        widgetName: 'Operation Impact Select',
        inWidgetTitle: 'Select Operation Impact',
      },
      customerImpactSelect: {
        widgetDescription: 'Selection of customer impact from a dropdown list.',
        widgetName: 'Customer Impact Select',
        inWidgetTitle: 'Select Customer Impact',
      },
      regionSelect: {
        widgetDescription: 'Selection of regions from a dropdown list.',
        widgetName: 'Region Select',
        inWidgetTitle: 'Select Region',
      },
      workReasonSelect: {
        widgetDescription: 'Selection of work reasons from a dropdown list.',
        widgetName: 'Work Reason Select',
        inWidgetTitle: 'Select Work Reason',
      },

      // INVENTORY
      createDependentInstanceButton: {
        widgetDescription: 'Button to create a dependent instance.',
        widgetName: 'New Dependent Instance Button',
        inWidgetTitle: 'New Dependent Entity Instance Button',
      },
      createNewInstanceConfDialog: {
        widgetDescription: 'Dialog for creating a new instance configuration.',
        widgetName: 'New Instance Conf. Dialog',
        inWidgetTitle: 'New Instance Configuration',
      },
      instanceConfRelationshipBookmark: {
        widgetDescription:
          'Simple list of relationships for instance configurations in a tabular view.',
        widgetName: 'Instance Conf. Relation Bookmark',
        inWidgetTitle: '',
      },
      instanceConfBookmark: {
        widgetDescription:
          'Simple list of instance configurations in a tabular view.',
        widgetName: 'Instance Conf. Bookmark',
        inWidgetTitle: '',
      },
      instanceConfList: {
        widgetDescription:
          'List of instance configurations in a tabular view with filtering and editing options.',
        widgetName: 'Instance Conf. List',
        inWidgetTitle: 'Instance Configuration List',
      },
      instanceConfTreeList: {
        widgetDescription: 'Tree-structured list of instance configurations.',
        widgetName: 'Instance Conf. Tree List',
        inWidgetTitle: 'Instance Configuration Tree',
      },
      instanceConfStatusBar: {
        widgetDescription:
          'Overview of the current status of the instance configuration with quick switching options.',
        widgetName: 'Instance Conf. Status Bar',
        inWidgetTitle: '',
      },
      instanceConfCostCard: {
        widgetDescription:
          'Card displaying cost details for instance configurations.',
        widgetName: 'Instance Conf. Cost Card',
        inWidgetTitle: 'Instance Configuration Costs',
      },
      instanceConfSelect: {
        widgetDescription:
          'Selection of an instance configuration from a dropdown list.',
        widgetName: 'Instance Conf. Select',
        inWidgetTitle: 'Select Instance Configuration',
      },
      productConfList: {
        widgetDescription:
          'List of product configurations in a tabular view with filtering and editing options.',
        widgetName: 'Product Conf. List',
        inWidgetTitle: 'Product Configuration List',
      },
      ipAddressInput: {
        widgetDescription: 'Input field for entering an IP address.',
        widgetName: 'IP Address Input',
        inWidgetTitle: 'Enter IP Address',
      },

      // CATALOG
      catalogSpecificationSelect: {
        widgetDescription:
          'Selection of a catalog specification from a dropdown list.',
        widgetName: 'Catalog Specification Select',
        inWidgetTitle: 'Select Catalog Specification',
      },
      catalogCategorySelect: {
        widgetDescription:
          'Selection of a catalog category from a dropdown list.',
        widgetName: 'Catalog Category Select',
        inWidgetTitle: 'Select Catalog Category',
      },
      catalogSelect: {
        widgetDescription: 'Selection of a catalog from a dropdown list.',
        widgetName: 'Catalog Select',
        inWidgetTitle: 'Select Catalog',
      },
      catalogPrivilegeList: {
        widgetDescription:
          'List of catalog privileges in a tabular view with filtering and editing options.',
        widgetName: 'Catalog Privilege List',
        inWidgetTitle: 'Catalog Privilege List',
      },
      catalogRelationList: {
        widgetDescription:
          'List of catalog relations in a tabular view with filtering and editing options.',
        widgetName: 'Catalog Relation List',
        inWidgetTitle: 'Catalog Relation List',
      },
      priceCard: {
        widgetDescription: 'Card displaying pricing details.',
        widgetName: 'Price Card',
        inWidgetTitle: 'Price Details',
      },

      // PROCESS
      processInstanceTasksBookmark: {
        widgetDescription:
          'Simple list of process instance tasks in a tabular view.',
        widgetName: 'Process Inst. Tasks Bookmark',
        inWidgetTitle: '',
      },
      taskHistoryBookmark: {
        widgetDescription:
          'Simple list of task history entries in a tabular view.',
        widgetName: 'Task History Bookmark',
        inWidgetTitle: '',
      },
      taskLogsBookmark: {
        widgetDescription: 'Simple list of task logs in a tabular view.',
        widgetName: 'Task Logs Bookmark',
        inWidgetTitle: '',
      },
      taskVariablesBookmark: {
        widgetDescription: 'Simple list of task variables in a tabular view.',
        widgetName: 'Task Variables Bookmark',
        inWidgetTitle: '',
      },
      taskDetailsCard: {
        widgetDescription: 'Card displaying details of a specific task.',
        widgetName: 'Task Details Card',
        inWidgetTitle: 'Task Details',
      },
      taskDefinitionSelect: {
        widgetDescription:
          'Selection of a task definition from a dropdown list.',
        widgetName: 'Task Definition Select',
        inWidgetTitle: 'Select Task Definition',
      },
      processDefinitionSelect: {
        widgetDescription:
          'Selection of a process definition from a dropdown list.',
        widgetName: 'Process Definition Select',
        inWidgetTitle: 'Select Process Definition',
      },
      taskStatusSelect: {
        widgetDescription: 'Selection of a task status from a dropdown list.',
        widgetName: 'Task Status Select',
        inWidgetTitle: 'Select Task status',
      },
      taskStatusGroupSelect: {
        widgetDescription:
          'Selection of a task status group from a dropdown list.',
        widgetName: 'Task Status Group Select',
        inWidgetTitle: 'Select Task status group',
      },
      taskActionTemplateSelect: {
        widgetDescription:
          'Selection of a task action template from a dropdown list.',
        widgetName: 'Task Action Template Select',
        inWidgetTitle: 'Select Task action template',
      },
      processGroupSelect: {
        widgetDescription: 'Selection of a process group from a dropdown list.',
        widgetName: 'Process Group Select',
        inWidgetTitle: 'Select Process Group',
      },
      processTypeSelect: {
        widgetDescription: 'Selection of a process type from a dropdown list.',
        widgetName: 'Process Type Select',
        inWidgetTitle: 'Select Process Type',
      },

      // DMS
      emailCommunicationPanel: {
        widgetDescription:
          'Panel for managing and displaying email communications.',
        widgetName: 'Email Communication Panel',
        inWidgetTitle: 'Email Communication',
      },
      notificationList: {
        widgetDescription:
          'List of notifications in a tabular view with filtering and editing options.',
        widgetName: 'Notification List',
        inWidgetTitle: 'Notification List',
      },
      commentsBookmark: {
        widgetDescription: 'Simple list of comments in a tabular view.',
        widgetName: 'Comments Bookmark',
        inWidgetTitle: 'Comments Bookmark',
      },
      worklogsBookmark: {
        widgetDescription: 'Simple list of worklogs in a tabular view.',
        widgetName: 'Worklogs Bookmark',
        inWidgetTitle: '',
      },
      attachmentsBookmark: {
        widgetDescription: 'Simple list of attachments in a tabular view.',
        widgetName: 'Attachments Bookmark',
        inWidgetTitle: 'Attachments Bookmark',
      },
      commentTypeSelect: {
        widgetDescription: 'Selection of a comment type from a dropdown list.',
        widgetName: 'Comment Type Select',
        inWidgetTitle: 'Select a Comment Type',
      },
      notificationTemplateSelect: {
        widgetDescription:
          'Selection of a notification template from a dropdown list.',
        widgetName: 'Notification Template Select',
        inWidgetTitle: 'Select a Notification Template',
      },
      formaterDocumentTemplateSelect: {
        widgetDescription:
          'Selection of a document template for formatting from a dropdown list.',
        widgetName: 'Formatter Doc. Template Select',
        inWidgetTitle: 'Select a Document Template',
      },
      worklogList: {
        widgetDescription:
          'List of worklogs in a tabular view with filtering and editing options.',
        widgetName: 'Worklog List',
        inWidgetTitle: 'Worklog List',
      },

      // LISTING
      listingProfileSelect: {
        widgetDescription:
          'Selection of a listing profile from a dropdown list.',
        widgetName: 'Listing Profile Select',
        inWidgetTitle: 'Select a Listing Profile',
      },
      listingColumnSelect: {
        widgetDescription:
          'Selection of a listing column from a dropdown list.',
        widgetName: 'Listing Column Select',
        inWidgetTitle: 'Select a Listing Column',
      },
      formSelect: {
        widgetDescription: 'Selection of a form from a dropdown list.',
        widgetName: 'Form Select',
        inWidgetTitle: 'Select a Form',
      },
      entityTypeSelect: {
        widgetDescription: 'Selection of an entity type from a dropdown list.',
        widgetName: 'Entity Type Select',
        inWidgetTitle: 'Select an Entity Type',
      },
      listingTypeSelect: {
        widgetDescription: 'Selection of a listing type from a dropdown list.',
        widgetName: 'Listing Type Select',
        inWidgetTitle: 'Select a Listing Type',
      },
      configurationProfilePanelSelect: {
        widgetDescription: 'Selection of a configuration profile on the panel.',
        widgetName: 'Configuration Profile Select Panel',
        inWidgetTitle: 'Select a Configuration Profile ',
      },
      characteristicSelect: {
        widgetDescription:
          'Selection of a characteristic from a dropdown list.',
        widgetName: 'Characteristic Select',
        inWidgetTitle: 'Select Characteristic',
      },

      // CALENDAR
      fullCalendarPanel: {
        widgetDescription: 'Full calendar panel with all events displayed.',
        widgetName: 'Full Calendar Panel',
        inWidgetTitle: 'Full Calendar',
      },
      calendarEventList: {
        widgetDescription:
          'List of calendar events in a tabular view with filtering and editing options.',
        widgetName: 'Calendar Event List',
        inWidgetTitle: 'Calendar Events',
      },
      calendarPanel: {
        widgetDescription: 'Panel displaying an overview of the calendar.',
        widgetName: 'Calendar Panel',
        inWidgetTitle: 'Calendar',
      },
      newCalendarButton: {
        widgetDescription: 'Button to create a new calendar.',
        widgetName: 'New Calendar Button',
        inWidgetTitle: '',
      },
      calendarSubypeSelect: {
        widgetDescription:
          'Selection of a calendar subtype from a dropdown list.',
        widgetName: 'Calendar Subtype Select',
        inWidgetTitle: 'Select Calendar Subtype',
      },
      calendarEventTypeSelect: {
        widgetDescription:
          'Selection of a calendar event type from a dropdown list.',
        widgetName: 'Calendar Event Type Select',
        inWidgetTitle: 'Select Event Type',
      },
      calendarTemplateSelect: {
        widgetDescription:
          'Selection of a calendar template from a dropdown list.',
        widgetName: 'Calendar Template Select',
        inWidgetTitle: 'Select Calendar Template',
      },
      calendarEventConfCard: {
        widgetDescription:
          'Card displaying configuration details for calendar events.',
        widgetName: 'Calendar Event Conf. Card',
        inWidgetTitle: 'Event Configuration',
      },

      //ADDRESS MANAGEMENT

      regionLayerSelect: {
        widgetDescription: 'Selection of a region layer from a dropdown list.',
        widgetName: 'Region Layer Select',
        inWidgetTitle: 'Select a Region Layer',
      },
    },
    formSelection: 'Form selection',
    spelParserError: 'Spel parser error',
    versionConflict: 'Warning: Form has been edited by another user.',
    versionConflictMessage:
      'Your changes cannot be saved because they would overwrite edits made by this user. To preserve your changes, open the form in a new window and manually copy your edits. Be careful not to overwrite edits made by another user.',
    hashtagOption: 'Hash suggestions',
    hashtagOptionDescription: 'Displays tickets or calls spel script',
    suggestionScriptData: 'Script data for suggestions',
    clickScriptData: 'Script data after click',
    scriptData: 'Additional params for script evaluation',
    moreSelector: 'Component selector to display more data',
    templateCode: 'Template code',
    emailsNotificationsDescription:
      'The component is used to display the email communication thread',
    successMessage: 'Success message',
    successMessageTooltip:
      'The message that appears after a successful button action',
    lazy: 'Delayed loading - does not load if the form is hidden. (The inner form is not validated until it is loaded.)',
    loadNext: 'Load more',
    loadNextButton:
      'Shows a button to load the form, the form does not load until the button is clicked. (The inner form is not validated until it is loaded.)',
    disableValidations: 'Disable validations',
    newRecordConfig: {
      newRecordConfig: 'Object dialog type',
      title: 'Title to display',
      types: 'Types',
      defaultType: 'Default type',
      createPrivilege: 'Create privilege',
      asJson: 'Object as a JSON',
    },
    deleteValue: 'Delete value',
    anchor: 'Anchor',
    submit: 'Submit',
    submitButton: 'Submit button',
    chars: 'Characteristics',
    sharingType: 'Sharing type',
    cancelService: 'Service selection',
    monacoDiffEditor: 'Monaco diff editor', // TODO dopln si sam
    modified: 'Modified',
    original: 'Original',
    urlAbout: 'Returns the config ui',
    urlAttributeName: 'attribute',
    urlAttributeThe: 'The attribute',
    billingDataSourceAbout:
      'Returns the billingDocument by accountId and documentTypeCode',
    accountId: 'accountId',
    accountIdAbout: 'The id of the account',
    documentTypeName: 'documentTypeCode',
    documentTypeNameAbout: 'The code of the documentType',
    billingDate: 'Returns the date plus workdays',
    theDate: 'The date',
    ticketEntityOne: 'Gets ticket entity by id',
    ticketEntityTwo: 'Gets related entity by ticket id',
    userEntityOne: 'Gets user entity by id',
    registerEntity: 'Gets register value entity by id',
    registerValidValue: 'Gets register value entity by id',
    filtersEntity: 'Filters for the related entity',
    codeRegister: 'Code of the register',
    codeRegisterValue: 'Code of the register value',
    codeRegisterValidValue: 'Code of the register valid value',
    userEntityId: 'Gets user entity by id',
    relatedEntityId: 'Gets related entity by id',
    relatedEntityIdOne: 'relatedEntityId',
    relatedEntityIdAbout: 'Id of the related entity',
    ticketRelationship: 'Gets ticket relationship entity by id',
    ticketIdOne: 'ticketId',
    theTicketIdOne: 'Id of the ticket',
    registerCodeCam: 'registerCode',
    customerId: 'customerId',
    customerIdOfThe: 'Id of the customer',
    entityIdAbout: 'Returns the entityInstanceConfigurationId by id',
    entityIdAboutThe: 'The id of the entityInstanceConfigurationId',
    entityIdInstanceAboutThe: 'Returns the order by entityInstanceId',
    entityInstanceId: 'entityInstanceId',
    entityInstanceIdThe: 'The entityInstanceId',
    orderIdAbout: 'Returns the order line by orderId',
    orderId: 'orderId',
    orderIdThe: 'The orderId',
    userGroupEntityById: 'Gets user user group entity by id',
    codeGroupThe: 'Code of the user group',
    userGroupAbout: 'Gets user group entity by code',
    idOfUserThe: 'Id of the user',
    nonAlphanumeric: 'Only alphanumeric characters are allowed',
    chooseFilter: 'Filter - choose',
    componentPanel: 'Show/Hide component panel',
    userDescription: 'User',
    userImage: 'User Image',
    userImageDescription: 'User Image',
    userImageComponent: 'User Image Component',
    userImageComponentDescription: 'User Image Component',
    userTypeNew: 'User Type',
    userTypeDescription: 'User Type',
    userTypeIcon: 'User Type Icon',
    userTypeIconDescription: 'User Type Icon',
    userGroupInfo: 'User Group Info',
    userGroupInfoDescription: 'User Group Info',
    privLoader: 'Privilege Loader',
    privLoaderDescription: 'Privilege Loader',
    userGroupInfoCode: 'User Group Info Code',
    userGroupInfoCodeDescription: 'User Group Info Code',
    privLoaderByCode: 'Privilege Loader By Code',
    privLoaderByCodeDescription: 'Privilege Loader By Code',
    statusIcon: 'Status Icon',
    statusIconDescription: 'Status Icon',
    statusIconByCode: 'Status Icon By Code',
    statusIconByCodeDescription: 'Status Icon By Code',
    severityIcon: 'Severity Icon',
    severityIconDescription: 'Severity Icon',
    typeIcon: 'Type Icon',
    typeIconDescription: 'Type Icon',
    ticketTypeByCode: 'Ticket Type By Code',
    ticketTypeByCodeDescription: 'Ticket Type By Code',
    ticketTypeById: 'Ticket Type By ID',
    ticketTypeByIdDescription: 'Ticket Type By ID',
    ropAddress: 'ROP Address',
    ropAddressDescription: 'ROP Address',
    ticketIdNew: 'Ticket ID',
    ticketIdDescription: 'Ticket ID',
    tabsDescription: 'Tabs',
    relatedEntityByIdNameIcon: 'Related Entity By ID Name Icon',
    relatedEntityByIdNameIconDescription: 'Related Entity By ID Name Icon',
    relatedPartyParticipants: 'Related Party Participants',
    relatedPartyParticipantsDescription: 'Related Party Participants',
    ticketLink: 'Ticket Link',
    ticketLinkDescription: 'Ticket Link',
    adviceIcon: 'Advice Icon',
    adviceIconDescription: 'Advice Icon',
    ticketTaskLink: 'Ticket Task Link',
    ticketTaskLinkDescription: 'Ticket Task Link',
    ticketCategoryDescription: 'Ticket Category',
    ticketCategoryCode: 'Ticket Category Code',
    ticketCategoryCodeDescription: 'Ticket Category Code',
    relatedEntityTypeByCode: 'Related Entity Type By Code',
    relatedEntityTypeByCodeDescription: 'Related Entity Type By Code',
    stockTaskLoader: 'Stock Task Loader',
    stockTaskLoaderDescription: 'Stock Task Loader',
    stockMovementStatus: 'Stock Movement Status',
    stockMovementStatusDescription: 'Stock Movement Status',
    slaLoader: 'SLA Loader',
    slaLoaderDescription: 'SLA Loader',
    externalLink: 'External Link',
    externalLinkDescription: 'External Link',
    filter: 'Filter',
    filterDescription: 'Filter',
    filterChars: 'Filter Chars',
    dataTagsArray: 'Data Tags Array',
    dataTagsArrayDescription: 'Data Tags Array',
    booleanTranslate: 'Boolean Translate',
    booleanTranslateDescription: 'Boolean Translate',
    booleanIcon: 'Boolean Icon',
    booleanIconDescription: 'Boolean Icon',
    enumToValue: 'Enum to Value',
    enumToValueDescription: 'Enum to Value',
    iconImg: 'Icon Image',
    iconImgDescription: 'Icon Image',
    clipboardName: 'Clipboard',
    clipboardDescription: 'Clipboard',
    datesDiff: 'Dates Difference',
    datesDiffDescription: 'Dates Difference',
    objectPathGet: 'Object Path Get',
    objectPathGetDescription: 'Object Path Get',
    descriptionDescription: 'Description',
    numberDescription: 'Number',
    toFixed: 'To Fixed Decimal Places',
    toFixedDescription: 'To Fixed Decimal Places',
    configType: 'Configuration Type',
    configTypeDescription: 'Configuration Type',
    configTypeCode: 'Configuration Type by code',
    configTypeCodeDescription: 'Configuration Type by code',
    maintenanceActivityRelatedDirection:
      'Maintenance Activity Related Direction',
    maintenanceActivityRelatedDirectionDescription:
      'Maintenance Activity Related Direction',
    selectedColumnsToString: 'Selected Columns to String',
    selectedColumnsToStringDescription: 'Selected Columns to String',
    linkHrefComponent: 'Link Href Component',
    linkHrefComponentDescription: 'Link Href Component',
    entityInstanceConfiguration: 'Entity Instance Configuration',
    entityInstanceConfigurationDescription: 'Entity Instance Configuration',
    entityInstConfStatusCode: 'Entity Instance Configuration Status Code',
    entityInstConfStatusCodeDescription:
      'Entity Instance Configuration Status Code',
    arrayToString: 'Array to String',
    arrayToStringDescription: 'Array to String',
    operationImpactByCode: 'Operation Impact by Code',
    operationImpactByCodeDescription: 'Operation Impact by Code',
    userFromSystem: 'User Selection from the System',
    userRoleFromSystem: 'User Role Selection from the System',
    userGroupList: 'User management - User Group Selection',
    userGroupType: 'User Group Type',
    userGroupTypeList: 'User Group Type Selection',
    ticketRelationshipList: 'Ticket relationship list',
    ticketHistory: 'Ticket history',
    adviceList: 'Advice list',
    relatedEntityList: 'Related entity list',
    ticketTaskList: 'Ticket task list',
    ticketList: 'Ticket list',
    ticketListRelated: 'Ticket list by related entity',
    ticketCategory: 'Ticket Category',
    nameUserSelection: 'User Selection from System',
    noticeType: 'Notices type',
    nameProblemTicket: 'Problem',
    nameProblemTicketTree: 'Problem - Tree',
    nameTicketSolution: 'Solution',
    nameTicketLov: 'Ticket',
    descriptionTicketStatus:
      'Selection of Status from Ticketing / Change Management',
    descriptionTicketRole:
      'Selection of Role from Ticketing / Change Management',
    descriptionTicketPriority:
      'Selection of Priority from Ticketing / Change Management',
    descriptionTicketSeverity:
      'Selection of Severity from Ticketing / Change Management',
    nameRelatedRequestEntity: 'Related Request Entity',
    descriptionRelatedRequestEntity:
      'Selection of Specific Request Entity from Change Management',
    titleRelatedEntity: 'Related Entity',
    nameTicketType: 'Ticket Type',
    descriptionTicketType: 'Ticket / Change Request Type',
    nameTemplateType: 'Ticket Template Type',
    descriptionTemplateType: 'Ticket Template Type',
    nameRelatedEntityIcon: 'Related Entity Icon',
    nameActiveAdvice: 'Active Advice',
    nameRegistryTicketCategory: 'Ticketing - Ticket Category from Registry',
    descriptionRelatedEntityDetailRefData:
      'Related Entity Detail Reference Data',
    descriptionTicketReleaseButton: 'Ticketing - Ticket Release Button',
    nameReleaseButton: 'Release Button',
    nameRelatedEntityType: 'Related Entity Type',
    descriptionRelatedEntityType:
      'Selection of Related Entity Type from Change Management',
    nameSamplingChannel: 'Channel',
    descriptionSamplingChannel: 'Change Management Sampling Channel',
    descriptionTicketBindings: 'List of Bindings for this Ticket',
    nameStatus: 'Ticket status',
    priority: 'Priority',
    severity: 'Severity',
    registerType: 'Type of registry for ticket/change request category',
    stockItem: 'stock item',
    stockIndex: 'Stock - index',
    openDevTerminal: 'Open developer console',
    scriptLov: 'Script lov',
    pivotReporting: 'Pivot reporting',
    chartReporting: 'Chart reporting',
    taskDefinitionLOV: 'Task definition lov',
    taskDefinition: 'Task definition',
    bookmarkQuotes: 'Quotes bookmark',
    orderSteps: 'Order status bar',
    campaignStatusSteps: 'Campaign status bar',
    logListing: 'Order line bookmark',
    logList: 'Log list',
    viewComp: 'Data view component',
    filterWidget: 'Listing - Filter',
    lovListing: 'Listing type',
    lovListingDescription: 'Component used to select the list type.',
    processInstanceTasks: 'Process instance tasks',
    taskHistory: 'Task history',
    taskLogs: 'Task logs',
    taskVariables: 'Task variables',
    taskDetails: 'Task details',
    ipAddress: 'IP address',
    createButtonEntity: 'Entity Instance Create Dependent Button',
    newEntity: 'Entity Instance Configuration New',
    relationshipEntity: 'Entity Instance Configuration Relationship',
    redirectToDetail: 'Redirect to detail',
    instanceEntity: 'Entity Instance Configuration List Panel',
    instanceEntityTree: 'Entity Instance Configuration Tree',
    instanceEntitySteps: 'Entity Instance Configuration Steps',
    instanceEntityCost: 'Entity Instance Configuration cost',
    instanceEntityConf: 'Entity Instance Configuration',
    instanceEntityList: 'Entity instance configuration list',
    instanceEntityListNew: 'Entity instance configuration list',
    aboutApp: 'About App',
    addToBookmarks: 'Add to bookmarks',
    nextActions: 'Next actions',
    toDetailForm: 'Form configuration',
    toSpelConsole: 'Spel console',
    toPythonConsole: 'Python console',
    toAnsibleConsole: 'Ansible console',
    lastActive: 'Last active',
    quickMessage: 'Send a quick message within the tSM application',
    teamsAppCall: 'Make a call through an external Microsoft Teams application',
    header: 'Header',
    size: 'Size',
    height: 'Height',
    width: 'Width',
    offset: 'Offset',
    compact: 'Compact',
    layout: 'Layout',
    json: 'Json',
    monacoEditor: 'Monaco editor',
    sum: 'Sum',
    budget: 'Budget',
    relationshipParentId: 'Relationship parent id',
    relationshipParentCode: 'Relationship parent code',
    relationshipTypeCodes: 'Relationship type code',
    relationshipAddWithParent: 'Relationship add with parent',
    entityType: 'Entity type',
    excludeIds: 'Exlude ids',
    excludeCodes: 'Exclude codes',
    lifecycleStatusCode: 'Lifecycle status code',
    lifeCycleInfo:
      "This field denotes the current phase in an item or entity's lifecycle.",
    productCodes: 'Product codes',
    productTypeCodes: 'Product type codes',
    catalogLifecycleStatusCode: 'Catalog lifecycle status code',
    catalogChars: 'Catalog chars',
    excludeCategoryCodes: 'Exclude category codes',
    categoryCodes: 'Category codes',
    displayField: 'Display field',
    defaultPolicy: 'Default policy',
    policy: 'Policy',
    context: 'Context',
    savePng: 'Save to PNG',
    saveSvg: 'Save to SVG',
    contextMenu: 'Context menu',
    advanced: 'Advanced',
    table: 'Table',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    miliseconds: 'Miliseconds',
    more: 'More',
    massEvents: 'Bulk actions',
    relation: 'Relation',
    operationalRelationUsage: 'Operational relation usage',
    significantRelation: 'Significant relation',
    threads: 'Threads',
    importData: 'Import data',
    privilege: 'Privilege',
    hidden: 'Hidden',
    parentUserGroup: 'Parent user group',
    associatedRole: 'Associated role',
    exportPrivilege: 'Export privilege',
    exportedRecords: 'Exported records',
    specificationCode: 'Specification code',
    whenEdited: 'Last edited',
    whenInserted: 'Created',
    whenUploaded: 'Upload date',
    whenImported: 'Import date',
    whoUploaded: 'Uploaded by',
    whoEdited: 'Edited by',
    whoInserted: 'Created by',
    public: 'Public',
    shareBy: 'Share by',
    billing: 'Billing',
    expandAll: 'Expand all',
    publicTooltip: 'Share with every user',
    listingId: 'Listing ID',
    showAll: 'Show all',
    showLess: 'Show less',
    refreshData: 'Refresh data',
    redrawData: 'Redraw data',
    redrawDataTooltip:
      'A new configuration is applied and a new rendering occurs',
    noRecords: 'No records',
    dataBoxId: 'Data box identifier',
    tickets: 'Tickets',
    isValid: 'Is Valid',
    validation: 'Validation',
    validationMessage: 'Validation message',
    validationExpression: 'Validation expression',
    campaign: 'Campaign',
    campaignWaveDefinition: 'Campaign wave definition',
    campaignWaveList: 'Campaign wave list',
    campaignType: 'Campaign type',
    arDocuments: 'Documents',
    download: 'Download',
    responseDownload: 'Download response',
    requestDownload: 'Download request',
    operation: 'Operation',
    clipboard: 'The value has been pasted into the clipboard.',
    log: 'Log',
    gallery: 'Gallery',
    galleryTooltip:
      'The gallery allows you to upload and display graphic files, it is also possible to change the display theme.',
    fileUpload: 'File upload',
    fileUploadFailed: 'File upload failed',
    fileUploadTooltip:
      'The component allows you to upload a file to an entity.',
    dropFile:
      'Drag the files you want to attach or select them from your computer',
    directoryStructure: 'Directory structure',
    showSameLocation: 'Same location only',
    newTag: 'New tag',
    coefficient: 'Coefficient',
    expression: 'Expression',
    workEstimate: 'Work estimate',
    worklog: 'Worklog',
    expressionEditor: 'Expression editor',
    scriptFormat: 'Script format',
    script: 'Script',
    clickScript: 'Script after click',
    suggestionsScript: 'Script for suggestions',
    scriptResult: 'Script result',
    collection: 'Collection',
    elementVariable: 'Element variable',
    events: 'Events',
    action: 'Action',
    actions: 'Actions',
    actionsAfterSuccess: 'Äctions after success',
    actionsAfterError: 'Actions after error',
    done: 'Done',
    supervisor: 'Supervisor',
    configEntityTypeHeader: 'Entity Type',
    indexMappingHeader: 'ES mapping type',
    indexName: 'Index name',
    formTypeHeader: 'Form Type',
    finished: 'Finished',
    history: 'History',
    signature: 'Signature',
    signatureDescription:
      'Signature offers the option to create a signature by signing directly on the device.',
    awareness: 'Awareness',
    attachments: 'Attachments',
    attachmentsTypes: 'Allowed attachment types',
    attachmentsTypesDescription:
      'List of codes of allowed attachment types under which the attachment can be saved (format: ["codetype1", "codetype2"])',
    attachmentTypeAllowEmpty: 'Allow empty attachment type',
    attachmentTypeAllowEmptyDescription:
      'If the attachment type is not selected, the attachment will be saved without a type.',
    acceptDocumentTypes: 'Allowed attachment file types',
    acceptDocumentTypesDescription: {
      option1: 'All file types allowed: blank, *, */',
      option2: 'Only images allowed: image/*',
      option3: 'Enumerated file types allowed: .jpg, .xlsx',
    },
    aplication: 'Aplication',
    condition: 'Condition',
    abbreviation: 'Abbreviation',
    code: 'Code',
    codeInfo:
      'The code represents the identification of this record and must be unique. ' +
      'The code is also used in other configurations and is exposed to the API, so changing it may require ' +
      'changes to other configurations or even related systems. ' +
      'We recommend using "dot notation" (e.g. "Finance.Collection").',
    mnemonicCode: 'Mnemonic code',
    mnemonicsInfo:
      'Mnemonics enable automatic generation of main the main identificatior of a record - ex. "OUT-45531 (Outage ticket), "FIN-5135" (Finance ticket), "SUP-5131" (Support ticket) or more complex "INV 2023/155" (Invoice 155 in the year 2023). The definition of mnemonic typically contains a prefix and a number assigned from a sequence. It can also contain a complex expression for assigning an identificator based on data from the created record',
    comment: 'Comment',
    commentTypes: 'Comment type',
    commentsTypes1: 'Comment types',
    comments: 'Comments',
    commentTemplate: 'Comment template',
    commentsTypes: 'Allowed comment types',
    commentTypeAllowEmpty: 'Allow empty comment type',
    defaultCommentType: 'Default comment type',
    filterByCodes: 'Filter by codes',
    worklogs: 'Worklogs',
    worklogList: 'Worklog list',
    worklogLocalityList: 'Worklog locality list',
    showWorkLogs: 'Show worklogs',
    worklogTypes: 'Allowed worklog types',
    worklogTypeAllowEmpty: 'Allow empty worklog type',
    defaultWorklogType: 'Default worklog type',
    worklogStopwatchStartButton: 'Worklog stopwatch start button',
    worker: 'Worker',
    ticketDetail: 'Ticket detail',
    entitySpecification: 'Configuration profile',
    entitySpecificationType: 'Configuration profile type',
    entitySpecificationInfo:
      'The configuration profile allows defining additional characteristics, custom forms, types of comments, etc. ' +
      'Configuration profiles can be shared among multiple types for simplification.',
    createAutomatically: 'Create automatically',
    createAutomaticallyTooltip:
      'A new record will be created automatically. If you want to use an already existing record, select the option "Choose from existing"',
    createAutomaticallyWithPrefix: 'Create automatically with prefix',
    createAutomaticallyWithPrefixTooltip:
      'A new record will be created automatically with the chosen prefix. If you want to use an already existing record, select the option "Choose from existing"',
    chooseFromExisting: 'Choose from existing',
    activityGenerationTime: 'Activity generation time',
    cancelPlannedActivityAfter: 'Cancel planned activity after days',
    createMaintenancePlanTemplate: 'Create maintenance plan template',
    planningFromElementInventoryPermitted:
      'Permit planning from Element Inventory',
    nothingFound: 'Nothing was found.',
    date: 'Date',
    dates: 'Dates',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    createDate: 'Creation date',
    lastChangeDate: 'Last modified date',
    validity: 'Valid',
    validityFrom: 'Valid from',
    validityTo: 'Valid to',
    primaryMicroservice: 'Primary Microservice',
    microservice: 'Microservice',
    invalidate: 'Invalidate',
    invalidated: 'Invalidated',
    find: 'Find',
    send: 'Send',
    create: 'Create',
    createCopy: 'Create copy',
    createTooltip: 'Create (Shift+n or n)',
    createCopyTooltip: 'Create copy (Shift+c)',
    createTemplate: 'Create template',
    createTemplateTooltip: 'Create template (Shift+n or n)',
    createWizard: 'Create - PEW',
    createWizardTooltip: 'Create - PEW (Shift+n or n)',
    createFromAres: 'Create from Ares',
    createFromAresTooltip: 'Create from Ares (Shift+a or a)',
    createdBy: 'Created by',
    createNewCustomer: 'Create new customer',
    approvedUser: 'Approved by',
    created: 'Created',
    createdByGroup: 'Created by group',
    close: 'Close',
    closeBulkEdit: 'Close bulk editing',
    delete: 'Delete',
    deleteAll: 'Delete all',
    remove: 'Remove',
    pinTopbar: 'Pin to topbar',
    unpinTopbar: 'Unpin from topbar',
    migrate: 'Migrate',
    deleteTooltip: 'Delete the selected entry',
    deleteEvent: 'Delete event',
    copy: 'Copy',
    paste: 'Paste',
    dropComponent: 'Drop a component',
    reverseRel: 'Reverse relationship',
    confirmation: 'Confirmation',
    deleteConfirmation: 'Confirm deletion',
    deleteRecordConfirm: 'Are you sure you want to delete this record?',
    deleteConfirmationMessage:
      'Are you sure you want to delete the config type? All child records will be deleted across the whole application as well.',
    decompose: 'Decompose',
    discardAllChanges: 'Discard all changes',
    storno: 'Storno',
    save: 'Save',
    saveDraft: 'Save as draft',
    savedDraft: 'Saved as draft',
    saveOther: 'Save and other',
    saveDetail: 'Save and go to detail',
    saveForm: 'Save form',
    testData: 'Test data',
    succSave: 'Saving succeeded',
    unSuccSave: 'An error occurred while saving',
    unSuccClone: 'An error occurred while cloning',
    succDiff: 'Saving diff succeeded',
    unSuccDiff: 'An error occured while saving diff',
    performChanges: 'Perform changes',
    resetChanges: 'Reset changes',
    choose: 'Choose',
    confirm: 'Confirm',
    confirmGeneric: 'Confirm action',
    confirmGenericMessage: 'Do you want to proceed with the action?',
    confirmCloseWindow: 'Do you want to close window?',
    selection: 'Selection',
    selected: 'Selected',
    beginning: 'Beginning',
    startOfFailure: 'Start of failure',
    end: 'End',
    pause: 'Pause',
    object: 'Object',
    objectLayout: 'Object',
    objectAsObject: 'Object layout',
    entity: 'Entity',
    persIdentNum: 'Company number',
    taxIdentNum: 'VAT ID',
    legalForm: 'Legal form',
    legalForms: 'Legal forms',
    leadSource: 'Lead source',
    leadType: 'Lead type',
    segment: 'Segment',
    inputting: 'Input',
    inputParameters: 'Input Parameters',
    outputting: 'Output',
    outputParameters: 'Output Parameters',
    department: 'Department',
    selectAction: 'Select action',
    businessKey: 'Business key',
    module: 'Module',
    tsmModuleInfo:
      'Using modules, you can further divide tickets into groups according to their behavior. ' +
      'Typical modules include "Ticketing", "Change management", "L2 Support", and others. ' +
      'A module often has a special customization tailored to it. If you do not have a specific requirement, ' +
      'leave the default "Ticketing" module.',
    moduleType: 'Module type',
    description: 'Description',
    documentation: 'Documentation',
    documents: 'Documents',
    link: 'Link',
    links: 'Links',
    skills: 'Skills',
    privs: 'Privileges',
    parentPriv: 'Parent privilege',
    parentPrivTooltip: 'Parent privilege used for privileges of the widget.',
    edit: 'Edit',
    editTooltip: 'Go to the detail of the selected record',
    edited: 'Edited',
    massEdit: 'Mass edit',
    takeOver: 'Take over',
    passOn: 'Pass on',
    process: 'Process',
    defaultProcess: 'Default process',
    defaultProcessInfo:
      'The process determines how the ticket will be handled. Within the process, you can assign ' +
      'tasks, automate solutions, add comments, and much more. You can have a different process for each type of ticket, ' +
      'and the process can also be dynamically selected using a SPEL expression based on the content of the ticket.',
    defaultProcessGenericInfo:
      'The process determines how the entity will be handled. Within the process, you can assign ' +
      'tasks, automate solutions, add comments, and much more. You can have a different process for each type of entity, ' +
      'and the process can also be dynamically selected using a SPEL expression based on the content of the entity.',
    dmn: 'DMN',
    tasks: 'Tasks',
    processType: 'Process type',
    processCreation: 'Process creation',
    processSelection: 'Process selection',
    companyType: 'Type of company',
    message: 'Message',
    resolved: 'Resolved',
    messages: 'Messages',
    messagesHotkeyText: 'Show messages',
    error: 'Error',
    compiled: 'Compiled',
    run: 'Run',
    runSelected: 'Run selected',
    errorOccured: 'Error occured during data loading',
    errors: 'Errors',
    signals: 'Signals',
    escalations: 'Escalations',
    editedBy: 'Edited by',
    name: 'Name',
    fullName: 'Full Name',
    title: 'Title',
    titleGenericInfo:
      'Choose a short name for this type. It is displayed in data lists and form headers, it can be also used for searching and reporting.',
    titleForTypeInfo:
      'Choose a short name for this type. ' +
      'It is displayed in data lists and form headers, it can be also used for searching and reporting. ',
    createPrivilege: 'Create privilege',
    sectionTitle: 'Section title',
    fieldsetLegend: 'Legend (title)',
    panelTitle: 'Panel title',
    parent: 'Parent',
    readPrivilege: 'Read privilege',
    writePrivilege: 'Write privilege',
    note: 'Note',
    slaHeader: 'SLA',
    impactOnSla: 'Impact on SLA',
    problem: 'Problem',
    partyName: 'Potential customer',
    nextInformation: 'More information',
    additionalInformation: 'Additional information',
    additionalServices: 'Additional services',
    layer: 'Layer',
    type: 'Type',
    selectType: 'Select type',
    radioButtonsDirection: 'Radio buttons direction',
    typeInfo:
      'Choose whether it will be a person, an organization or a workgroup.',
    change: 'Change',
    subtype: 'Subtype',
    state: 'Status',
    key: 'Key',
    stateCode: 'Status code',
    showCalendar: 'Show calendar',
    filters: 'Filters',
    selectItemFilters: 'Select item filters',
    selectItemFiltersTooltip:
      'This is a filter that checks whether the selected value should be allowed to be selected. If the filter is empty, all values can be selected.',
    messageFromInvalidSelectItem: 'Custom message for invalid item selection',
    messageFromInvalidSelectItemTooltip:
      'This message will be displayed if the user selects an item that does not meet the conditions specified in the "Select item filters" attribute.',
    showConfig: 'Show config',
    listing: 'Listing',
    checkSelectedItemByFilters:
      'This "{{name}}" item cannot be selected because it does not meet the conditions specified in the "Select item filters" attribute.',
    rowsCount: 'Rows count',
    pageSize: 'Page size',
    tableHeightEnableMin: 'Enable table height as minimal height',
    tableHeightEnableMinDescription:
      'With no data the height of the content will apply also.',
    tableHeight: 'Table Height',
    tableHeightTooltip:
      'You can change the height of the table by entering a numerical value, including the desired units (px, em, rem, %, vh)',
    selectedColumns: 'Selected columns',
    sortOrder: 'Sort order',
    sortField: 'Sort field',
    userId: 'User id',
    isDefault: 'Default',
    exports: 'Exports',
    cancelExport: 'Cancel export',
    exportSettings: 'Export settings',
    showExport: 'Show export button',
    showFilters: 'Show filter',
    showRefresh: 'Show refresh button',
    showPaginator: 'Show paginator',
    showManagerColumns: 'Show manager columns button',
    showProfiles: 'Show profile',
    showManagerSort: 'Show manager sort',
    showManagerColor: 'Show manager color',
    showListingConfig: 'Show listing config',
    hiddenDataViewMode: 'Hide data view mode',
    hideButtonGroup: 'Hide button section',
    tableDataViewMode: 'Table data view mode',
    scrollable: 'Enable scrollbars',
    anchorHeader: 'Anchor table header',
    anchorHeaderHelpMessage:
      'Keep the column headers of the list visible while scrolling',
    extendTqlWhere: 'Extend TQL where',
    externalTqlSort: 'External TQL sort',
    externalTqlSortForNested: 'External TQL sort for nested',
    colors: 'colors',
    colorCode: 'Color code',
    url: 'URL address',
    target: 'Target',
    local: 'Local',
    localization: 'Localization',
    specificationState: 'Specification status',
    paymentBehaviour: 'Payment behaviour',
    orderDate: 'Order date',
    assignedTo: 'Assigned to',
    deliveryDateRequested: 'Requested delivery date',
    deliveryDate: 'Delivery date',
    deliveredDate: 'Delivery date',
    plannedEndDate: 'Planned end date',
    plannedEnd: 'Planned end',
    plannedStartDate: 'Planned start date',
    stateGroup: 'Status group',
    stateManagement: 'Status management',
    channel: 'Channel',
    form: 'Form',
    forms: 'Forms',
    formSelectHint:
      'Used render form. If not specified, an automatic layout is performed based on the current data.',
    term: 'Date',
    lead: 'Lead',
    userName: 'Username',
    selectLanguage: 'Select a Language',
    czechLanguage: 'Czech',
    englishLanguage: 'English',
    deutschLanguage: 'Deutsch',
    costCoefficient: 'Cost coefficient',
    user: 'User',
    users: 'Users',
    userUserGroup: 'User / User group',
    customer: 'Customer',
    customerReport: 'Customer - report',
    customerList: 'Customer list',
    productCards: 'Product cards',
    customerType: 'Customer type',
    company: 'Company',
    firm: 'Firm',
    details: 'Details',
    emergency: 'Emergency',
    detail: 'Detail',
    rating: 'Rating',
    button: 'Button',
    clipboardButton: 'Clipboard button',
    backButton: 'Back button',
    nextButton: 'Next button',
    version: 'version',
    valuesSel: 'Value selection',
    none: 'none',
    color: 'Color',
    consents: 'Consents',
    telephone: 'Telephone',
    mobilePhone: 'Mobile phone',
    partyRole: 'Party role',
    responsiblePersons: 'Responsible persons',
    person: 'Person',
    contactPerson: 'Contact person',
    givenName: 'First name',
    salutation: 'Salutation',
    taskStatusGroup: 'Task status group',
    taskPlannedChar: 'Planned task char',
    changeTaskStatus: 'Change task status: {{from}} -> {{to}}',
    completionTask: 'Task completion',
    taskDetail: 'Task detail',
    taskName: 'Task name',
    service: 'Service',
    class: 'Class',
    serviceName: 'Service name',
    catalogItemName: 'Name in catalog',
    group: 'Group',
    people: 'People',
    gender: 'Gender',
    deployJust: 'Just deployed',
    parameters: 'Parameters',
    variables: 'Variables',
    jobConfiguration: 'Job configuration',
    contract: 'Contract',
    ticket: 'Ticket',
    ticketing: 'Ticketing',
    emails: 'E-mails',
    session: 'Session emails',
    incident: 'Incident',
    massAssume: 'Bulk assume',
    massHandover: 'Bulk handover',
    processDefinition: 'Process definition',
    massMigrationProcess: 'Bulk migration of process instance',
    massModificationProcess: 'Bulk modification of a process task',
    category: 'Category',
    categoryType: 'Category type',
    vrf: 'VFR',
    designation: 'Designation',
    labels: 'Labels',
    label: 'Label',
    labelDesc: 'Label',
    uiConfig: 'UI Configuration',
    config: 'Config type',
    configLabel: 'Configuration',
    tqlScript: 'TQL script',
    spelScript: 'SPEL script',
    usage: 'Used by',
    price: 'Price',
    pricelist: 'Pricelist',
    catalogPrice: 'Catalog price',
    iconPicker: 'Icon Picker',
    searchIcon: 'Search icon',
    search: 'Search',
    iconCopiedSummary:
      'Icon was successfully copied and saved in the clipboard!',
    imageCopiedSummary:
      'Image was successfully copied and saved in the clipboard!',
    closeDialogAfterSelection: 'Close dialog right after selecting an item',
    stacktrace: 'stacktrace',
    tqlName: 'TQL name',
    routerLink: 'Router link within the application',
    worklogDuration: 'Worklog duration',
    worklogDurationInput: 'worklog duration input',
    currency: {
      currency: 'Currency',
      CZK: 'CZK',
    },
    vatAmount: 'VAT amount',
    vatRange: 'VAT range',
    withVAT: 'With VAT',
    withoutVAT: 'Without VAT',
    recurrences: 'Recurrences',
    startingRecurrence: 'Starting recurrence',
    listPriceLimit: 'List price limit',
    listPriceLimitMax: 'Upper limit for individual price',
    chargingClass: 'Charging class',
    ares: 'Pull data',
    saveTemplate: 'Save template',
    share: 'Share',
    createEmptyTemplate: 'Create template',
    createEmptyTemplateTooltip: 'Create template (Shift+t or t)',
    restartPeriod: 'Restart period',
    mustBeZeroToHundred: 'This field must contain number in range 0...100',
    valueMustBeAboveZero: 'The value must be greater than 0',
    privateComment: 'Private',
    commentUserType: {
      INDIVIDUAL: 'User Comment',
      ORGANIZATION: 'System Comment',
    },
    userCommentType: 'Comment type',
    publicComment: 'Public',
    relatedParties: 'Related parties',
    relatedEntity: 'Related entity',
    relatedPartyTypes: 'Related party types',
    relatedEntityTypeChars: 'Configuration profile',
    relatedEntityTargetType: 'Target entity type',
    chooseType: 'Choose type',
    isPlaceOfEntity: 'Location of the entity',
    individualPrice: 'Individual price',
    participant: 'Participant',
    participantRef: 'Dms - Participant (sharing-ref)',
    diagramSource: 'Diagram Source',
    protocol: 'Protocol',
    previewProtocol: 'Preview protocol',
    sendProtocol: 'Send protocol',
    saveProtocol: 'Save protocol(s)',
    settings: 'Settings',
    settingsButton: 'Settings button',
    refreshButton: 'Refresh button',
    vatRangeCode: {
      EXE: 'Exempt from VAT',
      STD: 'Standard',
      '1RED': 'First reduced',
      '2RED': 'Second reduced',
      REVERSE: 'Reverse',
      EXE_3RD_PARTY: 'Third party exempt from VAT',
      STDH: 'Standard - History',
    },
    sharedComp: {
      editBlock: 'Edit block',
      templateRequired: 'This component must contain a template',
      hidePassword: 'Hide password',
      showPassword: 'Show password',
    },
    processGroupAction: {
      ADD: 'Add',
      CHANGE: 'Change',
      REMOVE: 'Remove',
      SUSPEND: 'Suspend',
      TAKEOVER: 'Take over',
      RESUME: 'Resume',
      LINK: 'Link',
      RESTRICT: 'Restrict',
      CUSTOM: 'Custom',
    },
    processDefinitionState: {
      DRAFT: 'Draft',
      CURRENT: 'Current',
      HISTORY: 'History',
    },
    emailsSla: 'Emails SLA',
    sla: {
      MODULE: {
        CRM: 'CRM',
        TICKETING: 'Ticketing',
      },
    },
    processDefinitionDeploymentStatus: {
      ERROR: 'Error',
      REQUESTED: 'Requested',
      COMPLETED: 'Completed',
    },
    dmnDefinitionDeploymentStatus: {
      ERROR: 'Error',
      REQUESTED: 'Requested',
      COMPLETED: 'Completed',
    },
    processColor: {
      RED: 'Red',
      BLUE: 'Blue',
      YELLOW: 'Yellow',
      GREEN: 'Green',
      WHITE: 'Whye',
    },
    orderLineType: {
      PRODUCT: 'Product',
      SERVICE: 'Service',
      PRICELIST: 'Price list',
      MATERIAL: 'Material',
      WFM: 'Wfm',
    },
    catalogType: {
      PRODUCT: 'Product',
      RESOURCE: 'Resource',
      SERVICE: 'Service',
      PRICELIST: 'Price list',
      SKILL: 'Skill',
      WORK: 'Work',
      MATERIAL: 'Material',
      OTHERS: 'Others',
    },
    scriptType: {
      ADMIN: 'Admin',
      TQL: 'Tql',
      REPORTING: 'Reporting',
      SPEL: 'Spel',
      GROOVY: 'Groovy',
      ANSIBLE: 'Ansible',
      NETMICO: 'Netmico',
    },
    priceListType: {
      label: 'Price type',
      PRICE: 'Price',
      DISCOUNT_ABSOLUTE: 'Discount',
      DISCOUNT_PERCENT: 'Discount percent',
    },
    recurringChargePeriodTypeEnums: {
      label: 'Recurring charge period type',
      DAY: 'Day',
      WEEK: 'Week',
      MONTH: 'Month',
      YEAR: 'Year',
    },
    recurringChargePeriodType: 'Recurring charge period type',
    recurringChargePeriodLength: 'Recurring charge period length',
    catalogEntityType: {
      CATALOG: 'Catalog',
      CATEGORY: 'Catalog',
      SPECIFICATION: 'Specification',
    },
    catalogSpecificationTypeLabel: 'Specification type',
    catalogSpecificationType: {
      TARIFF: 'Tariff',
      VAS_ACCOUNT: 'VAS account',
      VAS: 'VAS',
      HW: 'HW',
      DISCOUNT: 'Discount',
      CATEGORY: 'Category',
      NA: 'Category',
      INSTALL: 'Installation fee',
      PAYMENT: 'Fee',
      SUSPEND: 'Suspend/Blocking',
      DISCOUNT_ACCOUNT: 'Payer discount',
      NONRECURRING: 'One-time fee',
    },
    entityInstanceLocationRequired: {
      none: 'Not required',
      place: 'Location from register',
      crmAddress: 'Address',
    },
    processTaskGroup: {
      TASK_GROUP_TYPE_COMPLETE: 'Complete',
      TASK_GROUP_TYPE_RETURN: 'Return',
      TASK_GROUP_TYPE_ERROR: 'Error',
      TASK_GROUP_TYPE_DELETE: 'Deleted',
      TASK_GROUP_TYPE_REALIZATION: 'In progress',
      TASK_GROUP_TYPE_SKIPPED: 'Skipped',
      TASK_GROUP_TYPE_RESOLVED: 'Resolved',
      TASK_GROUP_TYPE_WAITING_INTERNAL: 'Waiting internal',
      TASK_GROUP_TYPE_WAITING_CUSTOMER: 'Waiting customer',
    },
    parameterType: {
      EXPRESSION: 'Expression',
      SCRIPT_GROOVY: 'Groovy script',
    },
    taskActionTemplateType: {
      EXPRESSION: 'Expression',
      GROOVY: 'Groovy script',
    },
    variableType: {
      SOURCE: 'Source',
      SOURCE_EXPRESSION: 'Source expression',
      ALL: 'All',
    },
    email: {
      dashedLabel: 'E-mail',
      label: 'E-mail',
    },
    emailType: {
      BUSINESS: 'Business',
      PRIVATE: 'Private',
    },
    customers: {
      status: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        CEASED: 'Ceased',
      },
    },
    accountState: {
      PREPARING: 'Preparing',
      ACTIVE: 'Active',
      SUSPENDED: 'Suspended',
      CEASED: ' Ceased',
    },
    leadPriority: {
      HIGH: 'High',
      MEDIUM: 'Medium',
      LOW: 'Low',
      LEAD_STATUS_NEW: 'New',
      LEAD_STATUS_IN_PROGRESS: 'In progress',
      LEAD_STATUS_LATER: 'Call later',
      LEAD_STATUS_CONVERTED: 'Converted to customer',
      LEAD_STATUS_CONVERTED_EXTERNAL: 'Converted to external CRM',
      LEAD_STATUS_REJECTED: 'Rejected',
      LEAD_STATUS_UNSUCCESSFUL: 'Unsuccessful',
    },
    crmAddressState: {
      INACTIVE: 'Inactive',
      ACTIVE: 'Active',
    },
    address: 'Address',
    addressManagement: 'Adress management',
    billingAddress: 'Billing address',
    urlAdress: 'Url',
    changePassword: 'Change password',
    installPWA: 'Install as web application',
    openPWA: 'Open as web application',
    keyboardShortcuts: 'Keyboard shortcuts',
    appVersion: 'APP version',
    role: 'Role',
    typeOfCooperation: 'Type of cooperation',
    personRole: 'Role',
    task: 'Task',
    requiredResponseDate: 'Required response date',
    responseDate: 'Response date',
    reqSolutionDate: 'Required solution date',
    termSolution: 'Term solution',
    termResponse: 'Term response',
    solved: 'Solved',
    closed: 'Closed',
    closedBy: 'Closed by',
    externalId: 'External ID',
    serviceID: 'Service ID',
    caseID: 'Case ID',
    tsID: 'TS ID',
    resourceID: 'Resource ID',
    plannedFrom: 'Planned from',
    plannedTo: 'Planned to',
    advice: 'Advice',
    oneAdvice: 'Advice',
    userGroup: 'User group',
    realUserGroup: 'Real user group',
    showClear: 'Show clear',
    disableLink: 'Disable link',
    keyboardOnMobile: 'Keyboard on mobile',
    showPlaceholder: 'Show placeholder',
    filterCodes: 'Filter codes',
    showIcon: 'Show icon',
    iconClass: 'Icon class',
    showNoneText: 'Show none text',
    defaultTreeViewMode: 'Default tree view mode',
    style: 'Style',
    isLinkValue: 'Is link value',
    userGroups: 'User groups',
    userParameters: 'User parameters',
    audit: 'Audit',
    reports: 'Reports',
    regions: 'Regions',
    basicInfo: 'Basic information',
    info: 'Info',
    active: 'Active',
    inactive: 'Inactive',
    makeInactive: 'Make inactive',
    currentActivity: 'Current activity',
    activity: 'Activity',
    editRecord: 'Edit record',
    wholeSiteFailure: 'Whole site failure',
    typeItPew: 'Type IT PEW',
    deleteRecord: 'Delete record',
    goToDetail: 'Go to detail',
    ldapMember: 'LDAP member',
    confirmPassword: 'Confirm password',
    language: 'Language',
    timezone: 'Timezone',
    password: 'Password',
    familyName: 'Surname',
    profileName: 'Profile name',
    profileId: 'Profile id',
    profileCategory: 'Profile category',
    fileName: 'File name',
    cancel: 'Cancel',
    for: 'for',
    activate: 'Activate',
    deactivate: 'Deactivate',
    template: 'Template',
    templateOwner: 'Template owner',
    newCharacteristic: 'New Characteristic',
    characteristic: 'Characteristic',
    readPermission: 'Read permission',
    editPermission: 'Edit permission',
    regionType: {
      OBEC: 'City',
      OKRES: 'District',
      ZSJ: 'Basic settlement unit',
      KRAJ: 'Region',
      RAJ: 'Zone',
      RAJCEL: 'Zone unit',
      MISTO: 'Location',
      PSC: 'Zip code',
    },
    add: 'Add',
    replace: 'Replace',
    genderEnum: {
      MALE: 'Male',
      FEMALE: 'Female',
    },
    status: 'Status',
    formType: {
      DASHBOARD: 'Dashboard',
      DIALOG: 'Dialog',
      FORM: 'Form',
      CUSTOM_FIELD: 'Custom field',
      PAGE: 'Page',
    },
    taskType: {
      TASK: 'Task',
      USER_TASK: 'User task',
      SERVICE_TASK: 'Service task',
      SEND_TASK: 'Send task',
      RECEIVE_TASK: 'Receive task',
      MANUAL_TASK: 'Manual task',
      BUSINESS_RULE_TASK: 'Business rule task',
      SCRIPT_TASK: 'Script task',
      CALL_ACTIVITY: 'Call activity',
      START_TASK: 'Start task',
      START_TASK_COMPENSATION: 'Start task compensation',
      START_TASK_ERROR: 'Start task error',
      START_TASK_ESCALATION: 'Start task escalation',
      START_TASK_MESSAGE: 'Start task message',
      START_TASK_SIGNAL: 'Start task signal',
      START_TASK_CONDITIONAL: 'Start task conditional',
      START_TASK_TIMER: 'Start task timer',
      END_TASK: 'End task',
      END_TASK_CANCEL: 'End task cancel',
      END_TASK_COMPENSATION: 'End task compensation',
      END_TASK_ERROR: 'End task error',
      END_TASK_ESCALATION: 'End task escalation',
      END_TASK_MESSAGE: 'End task message',
      END_TASK_NONE: 'End task none',
      END_TASK_SIGNAL: 'End task signal',
      END_TASK_TERMINATE: 'End task terminate',
      TIMER_EVENT: 'Timer event',
      GATEWAY: 'Gateway',
      MESSAGE_EVENT: 'Message event',
      BOUNDARY_TIMER: 'Boundary timer',
      BOUNDARY_MESSAGE: 'Boundary message',
      BOUNDARY_SIGNAL: 'Boundary signal',
      BOUNDARY_COMPENSATION: 'Boundary compensation',
      BOUNDARY_ERROR: 'Boundary error',
      BOUNDARY_ESCALATION: 'Boundary escalation',
      BOUNDARY_CANCEL: 'Boundary cancel',
      BOUNDARY_CONDITIONAL: 'Boundary conditional',
      SUBPROCESS: 'Subprocess',
    },
    syncMethodType: {
      MANUAL:
        'The user / configurator is responsible for manual synchronization.',
      NONE: 'No synchronization is expected, each environment has different data.',
      COMPARE:
        'A change is created in dev / staging environment and later is synchronized using tSM diff tools to production.',
      SYSTEM:
        'System cofiguration with impact to basic tSM Services. These settings are changed usually with new application version automatically.',
    },
    indexMapping: {
      NONE: 'No index',
      BASIC: 'Basic',
      TYPED: 'With data types',
    },
    userType: {
      INDIVIDUAL: 'Person',
      WORK_GROUP: 'Working group',
      ORGANIZATION: 'Organization',
      PROFILE: 'Profil',
    },
    configForms: {
      details: 'Details',
      inplace: 'Inplace',
      cards: 'Cards (section below detail)',
      taskDetail: 'Task detail',
      completedPage: 'Page end',
      info: 'Info section',
      people: 'People',
      dates: 'Dates',
      section: 'Main sections',
      sectionDefaultOpened: 'Main sections - default opened',
      tabs: 'Bookmark',
      create: 'Create a record',
      comments: 'Comments',
      callScript: 'Call script',
      topbar: 'Topbar',
      topbarMenu: 'Topbar menu',
      attachments: 'Attachments',
      taskPlanner: 'Detail of a scheduler task',
      taskProtocol: 'Protocol on the scheduler task detail',
      resolution: 'Resolution',
      problem: 'Problem',
    },
    statusGroup: {
      ENABLE: 'Active',
      DISABLE: 'Inactive',
    },
    roleInGroupEnum: {
      MEMBER: 'Member',
      CALENDAR_ADMIN: 'Calendar admin',
      MANAGER: 'Manager',
    },
    quoteStatus: {
      DRAFT: 'In preparation',
      CONVERSATIONS: 'Negotiating',
      DECLINE: 'Rejected',
      SUSPENDED: 'Aborted',
      ORDERED: 'Ordered',
    },
    activeStatus: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      ENABLE: 'Enabled',
      DISABLE: 'Disabled',
    },
    stockType: {
      PROJECT: 'Project',
      PERSONAL: 'Personal',
      RESERVE: 'Reserved',
      SUPER: 'Super',
      TEAM: 'Teamwork',
    },
    ticketEntity: {
      CHANGE_MANAGEMENT: 'Change management',
      TICKETING: 'Ticketing',
    },
    accessRule: {
      READ: 'Read',
      READ_WRITE: 'Read/Write',
    },
    privType: {
      SYSTEM: 'System',
      USER: 'User',
    },
    yes: 'Yes',
    no: 'No',
    leaveButton: 'Leave without saving',
    saveChangesButton: 'Save changes',
    yesAndNo: 'Yes and No',
    ticketTabs: {
      ADVICE: 'Advice',
      ATTACHMENTS: 'Attachments',
      COMMENTS: 'Comments',
      PROCESS: 'Proces',
      INCIDENT: 'Incidents',
      RELATED_ENTITY: 'Related entity',
      RELATED_PARTY: 'Related party',
      TICKET_TEMPLATES: 'Ticket templates',
      TICKET_TEMPLATE_GROUPS: 'Ticket template groups',
      IMPACTED_ENTITY: 'Impacted entity',
      RELATIONSHIPS: 'Relations',
      Place: 'Place',
      LOGS: 'Logs',
    },
    descending: 'Descending',
    ascending: 'Ascending',
    notOrder: 'Do not order',
    from: 'From',
    of: 'Of',
    to: 'To',
    until: 'Until',
    previous: 'Previous',
    next: 'Next',
    indeterminate: 'Indeterminate',
    recordsCount: 'The number of records',
    deleteFromSelection: 'Delete from selection',
    removeSelected: 'Remove selected',
    deselect: 'Deselect',
    editSelected: 'Edit selected',
    selectAll: 'Select all',
    invertSelected: 'Invert selection',
    pin: 'pin',
    positionInForm: 'Position in the form',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    data: 'Data',
    subtitle: 'Subtitle',
    subject: 'Subject',
    tsmModule: 'TSM module',
    primaryTsmModule: 'Primary TSM module',
    extensibility: 'Extensibility',
    uniqueness: 'Uniqueness',
    required: 'Required',
    readonly: 'Read only',
    configurable: 'Configurable',
    value: 'Value',
    displayValue: 'Display value',
    values: 'Values',
    moreValues: 'More values',
    selectionListingOneOrMore: 'Selection using a listing (one or more values)',
    selectFirstValue: 'Select first value',
    multiselect: 'Multiselect',
    selectionMode: 'Selection mode',
    singleMode: 'Single mode',
    multipleMode: 'Multiple mode',
    multipleModeGallery: 'Allows you to save multiple images',
    multipleModeGalleryDescription:
      'After unchecking this option, only one image can be saved to the gallery.',
    returnTypeMode: 'Multiple value storage mode',
    returnTypeString: 'Serialized value',
    returnTypeArray: 'Array of values',
    characteristics: 'Characteristics',
    characteristicsFilters: 'Characteristics filters',
    characteristicsInfo:
      'Characteristics (also called "attributes") expand the data model of this entity with additional properties. ' +
      'Characteristics have their own name under which they are stored in the data (attributeName) and a data type ("string", "number", or a complex object with its own attributes). ' +
      'Characteristics are modeled in the tSM Form Designer. Each form consists of a "visual representation" as well as a JSON Schema data model. ' +
      'Core attributes together with all characteristics thus form the formal data model of this entity, which is available as part of the Public API among other things.',
    formSpecificationInfo:
      'Forms for creating a new record, defining views of the record details, or custom transition forms for various actions. ' +
      'Use standard tSM Form Designer with "Core Form" set to correct form type. ' +
      'Core attributes and the above-defined characteristics can be selected from the palette and placed on the form as needed. ' +
      'Furthermore, buttons and other components such as comments, attachments, logs, etc. can be added to the form as needed.',
    measureUnit: 'Measure unit',
    unitPrice: 'Unit price',
    text: 'Text',
    string: 'String',
    mask: 'Mask',
    number: 'Number',
    editor: 'Editor',
    designer: 'Designer',
    order: 'Order',
    orderType: 'Order type',
    changeManagement: 'Planned work',
    section: 'Section',
    fieldset: 'Fieldset',
    panel: 'Panel',
    splitter: 'Splitter',
    card: 'Card',
    clone: 'Clone',
    editgrid: 'Grid',
    editTable: 'Table',
    editTableDescription:
      'This component is used to generate a table. It is possible to edit the scheme and insert columns, set the default sorting and set the default number of displayed records.',
    tabs: 'Tabs',
    steps: 'Wizard',
    accordion: 'Accordions',
    content: 'Content',
    file: 'File',
    resend: 'Resend',
    top: 'Up',
    left: 'Left',
    right: 'Right',
    bottom: 'Bottom',
    ipAddressMask: 'Mask',
    ipAddressCidr: 'CIDR',
    ordering: 'Ordering',
    placeholder: 'Placeholder',
    columns: 'Columns',
    addColumn: 'Add column',
    addRow: 'Add row',
    tooltip: 'Tooltip',
    visibleColumns: 'Visible columns',
    deleteItem: 'Delete item',
    groups: 'Groups',
    isRequired: 'Required',
    specification: 'Configuration profile',
    customErrorCode:
      'The value can only contain lowercase and uppercase letters without diacritics, numbers, a period, an underscore, and a hyphen. It must start with a letter!',
    customErrorQName: 'Value must be a valid QName!',
    phoneNumberErrorMessage: 'Invalid phone number format',
    webAddressErrorMessage: 'Invalid web address',
    emailErrorMessage: 'Invalid email',
    floatErrorMessage: 'The numeric value must be in the format XX.XX',
    integerErrorMessage: 'Invalid integer value',
    positiveIntegerErrorMessage: 'Invalid positive integer value',
    colsValueErrorMessage: 'The value must be between 1 and 12',
    goalTimeValueErrorMessage:
      'The value must be in the format XXhXXm, where x is a number',
    registerParentErrorMessage:
      'Register value cannot have itself as a parent item.',
    validityPeriodErrorMessage:
      'Validity beginning must pre-date its end date.',
    ipAddressMaskErrorMessage:
      'The entered value does not match the format of the IP address/mask',
    ipAddressCidrErrorMessage:
      'The value entered does not match the format of the IP address/cidr',
    ipAddressErrorMessage:
      'The entered value does not match the format of the IP address',
    ipCidrErrorMessage:
      'The value entered does not match the format of the CIDR',
    allowedAlphanumericErrorMessage:
      'The value can only contain uppercase and lowercase letters without diacritics, numbers, dots, underscores and hyphens.',
    firstLetterUppercaseErrorMessage: 'The first letter must be uppercase.',
    endsWithDotErrorMessage:
      'The value must not end with a period, underscore, or hyphen.',
    startsWithDotErrorMessage:
      'The value must not start with a period, underscore, or hyphen.',
    uppercaseAfterDotErrorMessage:
      'Each segment after a dot must start with an uppercase letter.',
    calendar: {
      title: 'Calendar',
      dayOfWeek: {
        shortcut: {
          MONDAY: 'Mo',
          TUESDAY: 'Tu',
          WEDNESDAY: 'We',
          THURSDAY: 'Th',
          FRIDAY: 'Fr',
          SATURDAY: 'Sa',
          SUNDAY: 'Su',
        },
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        WORK_DAY: 'working day',
        DAY: 'day',
      },
      month: {
        JANUARY: 'january',
        FEBRUARY: 'february',
        MARCH: 'march',
        APRIL: 'april',
        MAY: 'may',
        JUNE: 'june',
        JULY: 'july',
        AUGUST: 'August',
        SEPTEMBER: 'september',
        OCTOBER: 'october',
        NOVEMBER: 'november',
        DECEMBER: 'december',
      },
      TODAY: 'Today',
      CLEAR: 'Delete',
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      weekHeader: 'Wk',
      message: 'Cannot enter a date less than: {{minDate}}',
      pick: 'Pick',
      chooseDate: 'Choose Date',
    },
    dataLoading: 'Loading data, please hold on...',
    shared: 'Shared',
    sharedWith: 'Shared with',
    id: 'ID',
    emptyFieldErrorMessage: 'This field must be filled!',
    endDateErrorMessage: 'The date from must be less than the date to!',
    datesMustNotBeInPast: 'Dates must not be in the past.',
    sortFieldErrorMessage: 'The value must be between -1 and 1',
    checkDateFormatHHMM:
      'Please make sure the time you entered is correct HH:MM!',
    show: 'Show',
    fromTo: 'From / To',
    help: 'Help',
    icon: 'Icon',
    iconColor: 'Icon Color',
    iconInfo:
      "The selected icon is tied to the record and it's purpose is to ease it's visual identification. " +
      'It displayed next to the record. For example in module Ticketing - in the field type the corresponding icon is displayed',
    opened: 'Opened',
    image: 'Image',
    itemOrdering: 'Order',
    // TODO JB - revidovat
    itemOrderingInfo: 'The order in which this record will appear',
    products: 'Products',
    product: 'Product',
    allProducts: 'All products',
    userRegister: 'User register',
    userRegisterCode: 'User register code',
    userRegisterName: 'User register name',
    userRegisterConfigType: 'User register configuration type',
    userRegisterTags: 'User register tags',
    register: 'Register',
    registers: 'Registers',
    registerId: 'Register ID',
    registerName: 'Register Name',
    registerCode: 'Register Code',
    registerTags: 'Register tags',
    registerSettingsInfo:
      'Advanced listing type settings. By default, the form with the code "Listing.Type.Detail.Config" ' +
      'is used to display it. If this form does not exist, the settings are displayed and editable as text in json format.',
    display: 'Display',
    displayFieldTooltip:
      'It is possible to add multiple values using autocomplete or with source code ["key", "name"]',
    displayed: 'Displayed',
    new: 'New',
    old: 'Old',
    profile: 'Profile',
    profiles: 'Profiles',
    profileForDisplayColumns: 'A profile that defines the display of columns',
    openDetailType: 'Scripting - Open detail type',
    source: 'Source',
    sourceCode: 'Source code',
    schema: 'Schema',
    taxExemption: 'Reverse charge',
    actual: 'Actual',
    actualValue: 'Actual Value',
    actualValueInPercent: 'Actual Value[%]',
    actuatorHealth: 'Actuator health',
    preview: 'Preview',
    previewMode: 'Preview mode',
    previewModeDescription:
      'Tick the preview mode if you wish the component to display only the count of records with their names.' +
      'Place a heading infront of it according to the data displayed by the component.',
    displayOnlyPreviewModeCount: 'Preview mode - display only count.',
    diplayPreviewModeCountDescription:
      'If you are using preview mode, then tick the checkbox if you wish to display only the count of records without their names.',
    chart: 'Chart',
    chartConfiguration: 'Chart configuration',
    pivotConfiguration: 'Pivot configuration',
    totalCost: 'Total cost',
    cargo: 'Cost',
    timeToArrive: 'Driving time',
    location: 'Location',
    autoSelectFirstRow: 'Select first row',
    technology: 'Technology',
    technologyDescription: 'Choosing technology from change management',
    chooseRegionFromChM: 'Selection of region from change management',
    trafficImpact: 'Traffic impact',
    customerImpact: 'Customer impact',
    reasonForWork: 'Reason for work',
    operationImpact: 'Operation impact',
    region: 'Region',
    impactOnCustomer: 'Impact on customer',
    executor: 'Executor',
    bundle: 'Bundle',
    serviceDefinition: 'Service definition',
    locationOne: 'Location 1',
    locationTwo: 'Location 2',
    configurations: 'Product/Service history',
    current: 'Current',
    pending: 'Pending',
    catalog: 'Catalog',
    catalogInfo: 'Catalog for the category',
    superordinateCategory: 'Superordinate category',
    catalogSpecification: 'Catalog specification',
    taskLabel: 'Task',
    back: 'Back',
    backTooltip: 'Go back to the previous screen',
    backup: 'Backup',
    doBackup: 'Backup',
    restore: 'Restore',
    backupSet: 'Backup set',
    restoreSuccess: 'Data successfully restored.',
    restoreFailed: 'Restoring data failed',
    importStatus: 'Import status',
    backupSetImportStatus: {
      NOT_PROCESSED: 'Not processed',
      OK: 'Success',
      FAILED: 'Failed',
    },
    time: 'Time',
    primary: 'Primary',
    // daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    daysOfWeek: [
      'shared.calendar.dayOfWeek.SUNDAY',
      'shared.calendar.dayOfWeek.MONDAY',
      'shared.calendar.dayOfWeek.TUESDAY',
      'shared.calendar.dayOfWeek.WEDNESDAY',
      'shared.calendar.dayOfWeek.THURSDAY',
      'shared.calendar.dayOfWeek.FRIDAY',
      'shared.calendar.dayOfWeek.SATURDAY',
    ],
    daysInNormalOrder: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    cargoByTechnician: 'Cost by technician performance',
    cargoByRegion: 'Cost by region',
    termArrangement: 'Book Appointment',
    today: 'Today',
    productRelations: 'Product relations',
    categoryRelations: 'Category relations',
    relations: 'Relations',
    catalogTypeHeader: 'Catalog type',
    catalogEntityTypeHeader: 'Entity type',
    entityInstanceLocation1RequiredHeader: 'Required location 1',
    entityInstanceLocation2RequiredHeader: 'Required location 2',
    catalogCode: 'Catalog code',
    catalogId: 'Catalog ID',
    catalogCodes: 'Catalog codes',
    recurringChargePeriod: {
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly',
    },
    notificationTypes: 'Allowed notification types',
    notificationTypeAllowEmpty: 'Allow empty notification type',
    notificationType: {
      USER_COMMENT: 'User comment',
      SYSTEM_NOTIFICATION: 'System notification',
      CUSTOMER_MESSAGE: 'Customer message',
    },
    notificationRuleEventType: {
      SYSTEM: 'System',
      PROCESS: 'Process',
      KAFKA: 'Kafka',
      REST: 'REST',
      DIRECT: 'Direct',
    },
    deliveryItemType: {
      SMS: 'SMS',
      EMAIL: 'Email',
      LETTER: 'Letter',
      APPLICATION: 'Application',
      CUSTOMER_COMMENT: 'User comment',
      USER_COMMENT: 'User comment',
      INCOMING_EMAIL: 'Incoming email',
    },
    notificationTemplateStatus: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
    notificationTemplateCategory: {
      CAT_IMPORTANT: 'Important',
      CAT_LOW_PRIORITY: 'Low priority',
    },
    notificationStatus: {
      ALL: 'All',
      PLAN: 'Plan',
      PROCESSING: 'Processing',
      SENT: 'Sent',
      FAILED: 'Failed',
      PROCESSED: 'Processed',
      DELETED: 'Deleted',
      TEMPLATE_INACTIVE: 'Template inactive',
      CAT_LOW_PRIORITY: 'Cat low priority',
      DISABLED: 'Disabled',
      CONFIRMED: 'Confirmed',
      DELIVERED: 'Delivered',
      DRAFT: 'Draft',
    },
    senderType: {
      MANUAL: 'Manual',
      PROCESS: 'Process',
    },
    notificationSendingToTypes: {
      primary: 'Primary contact',
      related: 'By affected services',
      linked: 'By linked tickets',
      custom: '-',
    },
    filterOperator: {
      contains: 'Contains',
      notcontains: 'Not contains',
      startswith: 'Starts with',
      endswith: 'Ends with',
      in: 'Is in the list',
      notin: 'Is not in the list',
      eq: 'Equals',
      noteq: 'Not equals',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      lt: 'Less than',
      lte: 'Less than or equal to',
      empty: 'Empty',
      notempty: 'Not empty',
      btw: 'Between',
      gtr: 'Greater than (relative)',
      ltr: 'Less than (relative)',
      btwr: 'Between (relative)',
      dontTouch: 'Don´t touch (dontTouch)',
      invertSelected: 'Invert selected',
    },
    allDay: 'All day',
    month: 'Month',
    custom: 'Custom',
    week: 'Week',
    workweek: 'Workweek',
    day: 'Day',
    timeline: 'Timeline',
    confirmBothFromToMsg: 'Fill both values from, to.',
    operator: 'operator',
    valuePlaceholder: 'Enter a value',
    owner: 'Owner',
    ownerType: 'Owner type',
    ownerId: 'Owner Id',
    defaultProfile: 'Default profile',
    emailHeader: 'Email header',
    emailText: 'Email text',
    attachmentName: 'Attachment name',
    emailRecipientListTo: 'Mailing List To',
    emailRecipientListCc: 'List of recipients CC',
    recipientList: 'Recipient list',
    sendTime: 'Send time',
    emailContent: 'Email content',
    dynamicForms: 'Configuration profile',
    allowedValues: 'Allowed values',
    selectProperty: 'Select property',
    ok: 'Ok',
    export: 'Export',
    aggregation: 'Aggregation',
    mailSendPeriod: {
      DAILY: 'daily',
      WEEKLY: 'weekly',
      MONTHLY: 'monthly',
    },
    default: 'Default',
    defaultValue: 'Default value',
    defaultComponentValue: 'Default value of this component.',
    defaultLanguage: 'Default language',
    disableKey: 'Disable key',
    serialNumber: 'Serial number',
    amount: 'Amount',
    stock: 'Stock',
    batchNo: 'Batch',
    writeOff: 'Write off',
    confirmSelection: 'Confirm selection',
    plannedBeginning: 'Planned start',
    daysToPlannedBeginning: 'Days to planned start',
    domainConcurrency: 'Domain concurrency',
    openTemplate: 'Open template',
    openPreview: 'Open preview',
    plannedEnding: 'Planned end',
    approvedDate: 'Approved date',
    changeManagementOutageType: {
      real: 'Real',
      plan: 'Planned',
    },
    resolution: 'Solution',
    termination: 'Termination',
    changeRequest: 'PEW',
    deletionSuccess: 'Deletion succeeded',
    deletionFailure: 'An error occurred while deleting',
    ticketId: 'Ticket ID',
    services: 'Services',
    orders: 'Orders',
    offers: 'Offers',
    addresses: 'Addresses',
    deleteColumn: 'Delete column',
    saveAs: 'Save as',
    recurringPeriod: {
      NONE: 'does not repeat',
      DAILY: 'daily',
      WEEKLY: 'weekly',
      MONTHLY: 'monthly',
      YEARLY: 'yearly',
      WORK_DAILY: 'every work day',
      CUSTOM: 'custom',
    },
    itemOrderEnum: {
      FIRST: 'first',
      SECOND: 'second',
      THIRD: 'third',
      FOURTH: 'fourth',
      LAST: 'last',
    },
    priorityEnum: {
      LOW: 'Low',
      NORMAL: 'Normal',
      CRITICAL: 'Critical',
    },
    maintenanceActivityStateEnum: {
      NEW: 'New',
      PLANNED: 'Planned',
      DECLINED: 'Declined',
      REALIZED: 'Realized',
      IMPOSSIBLE: 'Impossible',
    },
    maintenancePlanStateEnum: {
      PROPOSED: 'Draft Plan',
      IN_PREPARATION: 'Plan to be approved',
      ACCEPTED: 'Approved',
      IN_REALIZATION: 'Realization',
      REALIZED: 'Implemented completely',
      REALIZED_PARTIALLY: 'Partially Realized',
      DECLINED: 'Rejected',
    },
    constraintTypeEnum: {
      SOON: 'Earliest possible',
      LATE: 'Latest possible',
      MUST_START: 'Must be started',
      MUST_FINISH: 'Must be finished',
    },
    calendarType: {
      USER: 'User calendar',
      LISTING: 'Listing calendar',
      SYSTEM: 'System calendar',
      GOOGLE: 'Google calendar',
      RESOURCE: 'Resource calendar',
    },
    calendarSubtype: {
      TEMPLATE: 'Template',
      INDIVIDUAL: 'Individual',
      WORKGROUP: 'Workgroup',
    },
    periodicInterval: {
      WEEK: 'Weekly',
      MONTH_1: 'Once per month',
      MONTH_3: 'Once per 3 months',
      MONTH_6: 'Once per 6 months',
      YEAR_1: 'Once per year',
      YEAR_2: 'Twice per 2 year',
      YEAR_3: 'Once per 3 years',
      YEAR_4: 'Once per 4 years',
      YEAR_5: 'Once per 5 years',
      YEAR_10: 'Once per 10 years',
    },
    restartPeriodType: {
      PERPETUAL: 'Perpetual',
      YEARLY: 'Yearly',
      MONTHLY: 'Monthly',
      DAILY: 'Daily',
    },
    orderLineOperation: {
      ADD: 'Add',
      REMOVE: 'Remove',
      UPDATE: 'Update',
      TAKEOVER: 'Takeover',
      MOVE: 'Move',
      LINK: 'Link',
    },
    duration: 'Duration',
    durationMin: 'Duration in min.',
    durationTime: 'Duration',
    selectedColor: 'Selected color',
    selectColor: 'Select a Color',
    selectSize: 'Select a Size',
    dataLoadFailed: 'Failed to load data! Error:',
    noResultsFound: 'No results found',
    requiredDeliveryDate: 'Requested delivery date',
    requiredDeliveryDateShort: 'Req. delivery date',
    requestedDate: 'Requested date',
    account: 'Account',
    acc: 'Account',
    accounts: 'Accounts',
    quote: 'Quote',
    productState: 'Product state',
    orderStatus: {
      CURRENT: 'Current',
      PENDING: 'Pending',
      HISTORY: 'History',
      QUOTE: 'Quote',
    },
    selectors: {
      orderCreated: 'The order was created',
      orderEdited: 'The order was edited',
      orderTypeCreated: 'The order type was created',
      orderTypeEdited: 'The order type was edited',
      quoteCreated: 'The quote was created',
      quoteEdited: 'The quote was edited',
    },
    characteristicsTitle: 'Name',
    characteristicsSubtitle: 'Title',
    processInstance: {
      task: {
        name: 'Task name',
        user: 'Task user',
        userGroup: 'Task group',
        status: 'Task status',
        startTime: 'Task from',
        endTime: 'Task to',
        plannedStartTime: 'Task plan from',
        plannedEndTime: 'Task plan to',
        dueDate: 'Task due date',
        followupDate: 'Task followup date',
        dateWorkStart: 'WFM work start',
        dateWorkEnd: 'WFM work end',
        zahajeniPrace: 'Task work start',
        dokonceniPrace: 'Task work end',
      },
    },
    documentExType: {
      DEPOSIT: 'Refundable deposit',
      REPAIR_TAX_DOC: 'Corrective tax document',
      GENERAL: 'General invoice',
      PROFORMA: 'Advance invoice',
      RECIPROCAL: 'Reciprocal commitment',
    },
    sharingRefType: {
      USER: 'User',
      USER_GROUP: 'User group',
    },
    logging: {
      logs: 'Logs',
      ownerType: 'Module',
      type: 'Type',
      module: 'Module',
      duration: 'Duration (ms)',
      request: 'Request',
      startDate: 'Start date',
      response: 'Response',
      resultCode: 'Result Code',
      resultMessage: 'Result description',
      relatedData: 'Related data',
      ownerId: 'Owner ID',
      correlationId: 'Correlation ID',
      logType: {
        code: 'Code',
        name: 'Name',
        protocol: 'Protocol',
        language: 'Language',
        validityFrom: 'Valid from',
        validityTo: 'Valid to',
        appFrom: 'Source Application',
        appTo: 'Target Application',
        formCode: 'Form code',
        form: 'Form',
        requestFormCode: 'Request form code',
        requestForm: 'Request form',
        responseFormCode: 'Response form code',
        responseForm: 'Response form',
        relatedDataFormCode: 'Related data form code',
        relatedDataForm: 'Related data form',
      },
    },
    logMess: {
      showSource: 'View source',
      showForm: 'View form',
      cmpOrgMsg: 'Compare with the original message',
      downloadMsg: 'Download message',
      resendToInterface: 'Resend to interface',
    },
    notifications: {
      notificationTemplates: 'Notification templates',
      notificationTemplate: 'Notification template',
      notifications: 'Notifications',
      sendNotification: 'Send notification',
      formatterDocumentTemplate: 'Formatter document template',
    },
    formatterInputType: {
      FILE: 'File',
      HTML: 'HTML',
    },
    formatterOutputType: {
      PDF: 'PDF',
      DOC: 'DOC',
      DOCX: 'DOCX',
    },
    formatterDocumentType: {
      PDF: 'PDF',
      EMAIL: 'EMAIL',
      SOURCE: 'SOURCE',
      EXCEL: 'EXCEL',
    },
    formatterDocumentSize: {
      AUTO: 'AUTO',
      PORTRAIT: 'PORTRAIT',
      LANDSCAPE: 'LANDSCAPE',
      A4: 'A4',
      B5: 'B5',
      LETTER: 'LETTER',
    },
    campaignHeaders: {
      customerMnemoID: 'Customer ID',
      campaign: 'Campaign',
      followUp: 'Follow up',
      campaignLines: 'Products',
    },
    billingDocument: {
      billingNew: 'Billing document new',
      status: {
        VALID: 'Valid',
        DELETED: 'Invalid',
      },
    },
    billingCurrency: {
      position: {
        PREFIX: 'Prefix',
        SUFFIX: 'Suffix',
      },
    },
    billingCycle: {
      status: {
        ACTIVE: 'Active',
        BILLED: 'Billed',
        TO_BE_BILLED: 'To be billed',
      },
    },
    billingCycleType: {
      billingFrequency: {
        YEARLY: 'Yearly',
        QUARTERLY: ' Quarterly',
        MONTHLY: 'Monthly',
      },
    },
    billingDocumentType: {
      sapConfig: {
        HOUSEHOLD: 'House Hold Connected',
        HOME_PAST: 'Home Past',
        DARK_FIBER: 'Dark Fiber',
      },
    },
    formTooltip: {
      basicComponents: {
        expressionEditor:
          'An expression editor is an input field used to input a SPEL or JEXL expression.',
      },
    },
    stockItemStatus: {
      ACTIVE: 'Active',
      ARCHIVE: 'Archived',
    },
    technicalPreview: 'Technical preview',
    crmAddressListingDescription: '',
    addressRolesListingDescription: '',
    responsiblePersonListingDescription: '',
    showInScheduler: 'Show in scheduler',
    resending: 'Resend',
    resendingMessage: 'Send selected messages again?',
    availability: 'Availability',
  },
  config: {
    autocomplete: 'Autocomplete',
    variable: 'Variable',
    basicInfo: 'Basic information of the configuration profile',
    text: 'Text',
    number: 'Number',
    icon: 'Icon',
    textarea: 'Textarea',
    richtext: 'Richtext',
    checkbox: 'Checkbox',
    triStateCheckbox: 'Tri state checkbox',
    switch: 'Switch',
    date: 'Date',
    rating: 'Rating',
    link: 'Link',
    select: 'Select',
    multiselect: 'Multiselect',
    radio: 'Radio',
    password: 'Password',
    mask: 'Mask',
    phone: 'Phone',
    button: 'Button',
    submitButton: 'Submit button',
    submitRequestButton: 'Submit request button',
    helpvalue: 'Help value',
    html: 'HTML source',
    codeList: 'Code List',
    javaExe: 'Java example',
    formCode: 'Form code',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    optionalClass: 'Optional CSS class',
  },
  menu: {
    dashboard: {
      createNew: 'Create new dashboard',
      dashboardManage: 'Manage dashboard',
    },
    calendar: {
      calendar: 'Calendar',
      fullCalendar: 'Full calendar',
      calendarManage: 'Calendar manage',
      calendarCreateButton: 'Calendar create button',
      calendarSubtype: 'Calendar subtype',
      calendarEventType: 'Calendar event type',
      calendarEventConfigurationData: 'Calendar event configuration data',
      calendarTemplate: 'Template calendar list',
    },
    crm: {
      crm: 'CRM',
      crmAddressListing: 'Addresses bookmark',
      crmAddressLOV: 'Addresses',
      crmAddress: 'CRM addresses',
      customers: 'Customers',
      customerHistory: 'Customer history',
      contracts: 'Accounts',
      leads: 'Leads',
      persons: 'Persons',
      personSteps: 'Person status bar',
      dialsManagement: 'Registers',
      personRoles: 'Persons roles',
      legalForm: 'Legal form',
      customerType: 'Customer type',
      leadType: 'Lead type',
      leadList: 'Lead list',
      leadSteps: 'Leads status bar',
      personType: 'Person type',
      personHistory: 'Person history',
      personListing: 'Person listing',
      responsiblePersonListing: 'Responsible person bookmark',
      accountType: 'Account type',
      accountSteps: 'Account status bar',
      accountHistory: 'Account history',
      accountListing: 'Account bookmark',
      accountListingDescription:
        'Component used, for example, on the customer detail',
      customerSegment: 'Customer segment',
      customerSteps: 'Customer status bar',
      rating: 'Rating',
      leadSource: 'Lead source',
      leadHistory: 'Lead history',
      address: 'Address',
      addressRolesListing: 'Bookmark by using address',
      registers: 'Registers',
      contactManager: 'Contacts manager',
      campaign: 'Campaigns',
      campaignWaveDefinition: 'Campaign wave definitions',
      campaignWave: 'Campaign waves',
      campaignType: 'Campaign type',
      campaignStatus: 'Campaign status',
      partyCampaignStatus: 'Party campaign status',
      sales: 'Sales',
    },
    ordering: {
      ordering: 'Ordering',
      order: 'Order',
      orderListing: 'Order Listing',
      orderBookmark: 'Order bookmark',
      orderList: 'Order List',
      orderListRelated: 'Order list by related entity',
      orderTaskList: 'Order task list',
      orderHardwareLine: 'Ordering - Order line hardware',
      quote: 'Quote',
      registers: 'Registers',
      orderType: 'Order type',
      orderStatus: 'Order status',
    },
    provisioning: {
      provisioning: 'Service Provisioning',
      order: 'Service Order',
    },
    wfm: {
      wfm: 'WFM',
      workOrders: 'Work orders',
      dispatcher: 'Dispatcher',
      scheduler: 'Scheduler',
      routeMap: 'Route map',
      myTasks: 'My tasks',
      registers: 'Registers',
      worksCatalog: 'Catalog of works',
      materialKatalog: 'Material catalog',
    },
    addressManagement: {
      regions: 'Regions',
      layers: 'Layers',
    },
    inventory: {
      inventory: 'Inventory',
      products: 'Products',
      services: 'Services',
      resources: 'Resources',
    },
    ticket: {
      ticketing: 'Ticketing',
      ticket: 'Tickets',
      configuration: 'Configuration',
      registers: 'Registers',
      channels: 'Channels',
      category: 'Categories',
      priority: 'Priorities',
      problems: 'Problems',
      resolution: 'Resolutions',
      statuses: 'Statuses',
      technology: 'Technologies',
      types: 'Ticket types',
      adviceTypes: 'Advice types',
      ticketLink: 'Ticket links',
      severity: 'Severities',
      relatedPartyRole: 'Related party roles',
      relatedPartyType: 'Related party types',
      relatedEntityType: 'Related entity types',
      impactedEntityType: 'Impacted entity types',
      ticketTemplate: 'Ticket templates',
    },
    serviceManagement: {
      serviceManagement: 'Service management',
      managedMicroservices: 'Managed microservices',
    },
    stock: {
      stock: 'Stock',
      nullableItems: 'Show zero quantity items',
      stocks: 'Stocks',
      items: 'Items',
      itemList: 'Items - detail',
      moves: 'Moves',
      movementCreation: 'Movement creation',
      stockType: 'Stock types',
      stockMovementType: 'Stock movement types',
      stockItemType: 'Stock item types',
      registerManagement: 'Registers',
    },
    changeManagement: {
      changeManagement: 'Change Management',
      changeRequest: 'Change Request',
      changeRequestList: 'Change request list',
      changeRequestLOV: 'Change request type',
      changeRequestTemplates: 'Change request templates',
      changeRequestTemplateGroups: 'Change request template groups',
      workOnLocalities: 'History of work on localities',
      registers: 'Registers',
      types: 'Change request types',
      dialsManagement: 'Registers',
      operationImpacts: 'Operation Impact',
      technology: 'Technology',
      impactOnCustomer: 'Impact on customer',
      reasonForWork: 'Reason for work',
      priority: 'Priority',
      statuses: 'Ticket status',
      resolution: 'Resolution',
      channels: 'Channel',
      relatedEntityType: 'Related entity type',
      impactedEntityType: 'Impacted entity type',
      relatedPartyType: 'Related party type',
      relatedPartyRole: 'Related party role',
      linkType: 'Link type',
      region: 'Region',
      scheduleType: 'Schedule activity type',
      schedule: 'Schedule',
      scheduleDescription: 'Schedule of related services',
      scheduleTypeDescription: 'Schedule activity type change management',
    },
    billing: {
      billing: 'Billing',
      dialsManagement: 'Registers',
      billingDocument: 'Billing document',
      billingDocumentLine: 'Billing document lines',
      billingDocumentType: 'Billing document type',
      paymentOverview: 'Payment overview',
      documentOverview: 'Document overview',
      controlGroups: 'Control groups',
      registers: 'Registers',
      priceItemOverview: 'Price Item overview',
      billingCurrency: 'Currency',
      billingCycle: 'Billing cycle',
      billingCycleType: 'Billing cycle type',
      billingDocumentStatus: 'Billing document status',
      billingCycleStatus: 'Billing cycle status',
      sapDocumentStatus: 'SAP document status',
      vatRate: 'VAT rate',
    },
    userManagement: {
      userManagement: 'User management',
      users: 'Users',
      profiles: 'Profiles',
      profile: 'Profile',
      userGroups: 'Users group',
      roles: 'Roles',
      privs: 'Privs',
      accessRules: 'Access rules',
      bulkEdits: 'Bulk edits',
      usersSkills: "Users' skills",
      registers: 'Registers',
      skillsCatalog: 'Skills catalog',
      userGroupType: 'User group type',
      userType: 'User type',
    },
    catalogs: {
      catalog: 'Catalog',
      catalogManagement: 'Catalogs',
      catalogItem: 'Item from catalog',
      catalogs: 'Catalogs',
      category: 'Category',
      specifications: 'Specifications',
      registers: 'Registers',
      catalogStates: 'Catalog states',
      specificationStates: 'Specification states',
      specificationLinkType: 'Specification link type',
      specificationType: 'Specification type',
      vatRange: 'Vat Range',
      entityCatalogSpecDescription: 'Entity Catalog Spec',
      entityCatalogSpecCodeDescription: 'Entity Catalog Spec Code',
      catalogCategoryDescription: 'Catalog Category',
      catalogCategoryCodeDescription: 'Entity Catalog Spec Code',
      catalogRelationshipCategorySpecIconDescription:
        'Catalog Relationship Category Spec Icon',
      relationshipCodeLinkDescription: 'Relationship Code Link',
    },
    process: {
      processManag: 'Process management',
      processDesigner: 'Process designer',
      taskDesigner: 'Task designer',
      taskActionTemplate: 'Task action template',
      registers: 'Registers',
      processTypesManage: 'Process type management',
      processGroupManag: 'Process group management',
      statusManagement: 'State management',
      stateMenagGrooups: 'Group state management',
      dmnDesigner: 'Dmn designer',
      dmnManag: 'Dmn management',
      processTasks: 'Process tasks',
    },
    configForm: {
      configForm: 'Config & Form',
      entitySpecifications: 'Configuration profiles',
      characteristics: 'Characteristics',
      characteristicsSelect: 'Selection of characteristic',
      formManagement: 'Form management',
      registers: 'User registers',
      dialsManagement: 'Registers',
      configType: 'Config type',
      configFormType: 'Form',
      formField: 'Form field',
      tsmModule: 'TSM modules',
      microservice: 'Microservice',
      entityType: 'Entity type',
      entityTypeDescription: 'Component used to select an entity type.',
      mnemonics: 'Mnemonics',
      mnemonicsInfo:
        'Mnemoniky umožňují automatické generování hlavního identifikátoru záznamu - např. ' +
        '“OUT-45531” (Outage ticket) “FIN-5135” (Finance ticket), “SUP-5131” (Support ticket) or ' +
        'more complex “INV 2023/155” (Invoice 155 in the year 2023). Definice mnemoniky typicky ' +
        'obsahuje prefix a číslo přiřazené ze sekvence, můžou ale obsahovat i komplexní výraz pro ' +
        'přidělení identifikátoru na základě dat z vytvářeného záznamu.',
      swagger: 'Swagger',
      tsmPublicApi: 'tSM Public API',
      dbbridge: 'dbBridge - reload',
      fluentFormDesigner: 'Fluent form designer',
      fluentForm: 'Fluent form',
      fluentFormMigrations: 'Fluent form migrace',
      treeView: 'Tree view',
      registerValues: 'Values of user registers',
    },
    dms: {
      dms: 'DMS',
      attachmentType: 'AttachmentType',
      commentType: 'CommentType',
      worklog: 'Worklog',
      worklogType: 'WorklogType',
      kafkaTopic: 'Kafka topic',
      kafkaDlq: 'Kafka DLQ',
      helloWorld: 'Hello World!',
      fraud: 'Notice of Fraud (NT)',
      bill: 'Sample Customer Contract (Individual)',
      service: 'Handover service protocol (Individual)',
      serviceB2b: 'Handover service protocol (Contractor)',
      serviceB2business: 'Handover service protocol (Business)',
      oneTimeBill: 'One-Time Invoice',
      pewReports: 'Pew reports',
      mail: 'Mail',
      serviceOffer: 'Service Offer',
      mobileTree: 'Mobile Tree',
      customWidgets: 'Custom Widgets',
      iconPicker: 'TSM ICON PICKER',
      tiptapEditor: 'TSM TIPTAP EDITOR',
      form: 'Input Form',
      audit: 'Audit Sandbox',
      ntr: 'NTR Invoice',
    },
    settings: {
      settings: 'Settings',
      sla: 'SLA',
      tableColumnConfiguration: 'Table column configuration',
      listingType: 'Listing Type',
      listing: 'Listing',
      listingColumns: 'Listing columns',
      listingProfile: 'Listing Profile',
      myListingProfiles: 'My listing profiles',
      backupSets: 'Backup sets',
      backupSetImports: 'Backup set imports',
      listingProfileLov: 'Profile',
      valueConverterConfig: 'Value converter config',
      deliveryTime: 'Delivery time',
      calendars: 'Calendars',
      calendarManagement: 'Calendar management',
      calendarEventType: 'Calendar event type',
      calendarEventList: 'Calendar event list',
      calendarTemplate: 'Template calendar list',
      addressManagement: 'Address management',
      dialsManagement: 'Registers',
      kaflaDlq: 'Kafka - failed messages',
      reporting: 'Reporting',
      scripting: {
        scripting: 'Scripting',
        groovyConsole: 'Groovy Console',
        tqlConsole: 'TQL console',
        spelConsole: 'Spel Console',
        pythonScriptConsole: 'Python script console',
        ansibleConsole: 'Ansible console',
        spelScript: 'Spel Scripts',
      },
    },
    listing: {
      listing: 'Listing',
    },
    preventiveMaintenance: {
      preventiveMaintenance: 'Preventive maintenance',
      maintenancePlan: 'Maintenance plan',
      maintenancePlans: 'Maintenance plans',
      netInventoryPU: 'Network Inventory PU',
      registers: 'Registers',
      activityCatalogPu: 'PU activity catalog',
      netInventCatalogPu: 'Catalog Network Inventory PU',
      maintenanceActivityTypeGroup: 'Maintenance Activity Type Group',
      maintenanceActivityTypeGroups: 'Activity Type Groups',
      maintenanceActivityType: 'Maintenance Activity Type',
      maintenanceActivityInventory: 'Maintenance Activity Inventory',
      maintenanceActivityRelatedList: 'Maintenance activity related list',
      maintenancePlanActivity: 'Preventive Maintenance Plan Variant',
      maintenancePreventive: 'Preventive Maintenance Activity',
      maintenancePreventiveTaskList:
        'Preventive Maintenance Activity Task List',
      maintenancePlanTaskList: 'Preventive Maintenance Plan Task List',
      maintenancePreventiveImplementation:
        'Preventive Maintenance Plan implementation status',
      maintenanceActivityTypes: 'Activity Types',
      maintenanceActivitySteps: 'Maintenance activity steps',
      maintenancePlanSteps: 'Maintenance plan steps',
      maintenanceActivityRelationTypes: 'Activity Relation Types',
      territorialException: 'Territorial exception',
      territorialExceptions: 'Territorial exceptions',
      elementTypeActivity: 'Element type activity',
      excludedPeriod: 'Excluded period',
      excludedPeriods: 'Excluded periods',
      material: 'Material',
      means: 'Means',
      maintenancePlanTemplate: 'Maintenance plan template',
      maintenancePlanTemplates: 'Maintenance plan templates',
      maintenanceTemplateRegion: 'Maintenance template region',
      elementTypeRegion: 'Element types in region',
      maintenancePlanType: 'Maintenance plan type',
      maintenanceActivity: 'Maintenance activity',
      maintenanceActivities: 'Maintenance activities',
      maintenancePlanVariant: 'Maintenance plan variant',
      config: 'Configuration',
      catalogElement: 'Elements catalog for PM',
      catalogMaterial: 'Material catalog',
      catalogMeans: 'Means catalog',
      maintenanceCostCategory: 'Maintenance cost category',
      maintenanceCostCategoryShort: 'Cost category',
    },
    positionSelector: {
      portrayalInMenu: 'Menu view',
      addRegister: 'Add this register as a new menu item.',
      locationInMenu: 'Location in the menu ',
      menuKey:
        'The menu key (whole location) where the item should be placed (eg tickets + registers)',
      menuItemTitle: 'Menu item name',
      itemIcon: "Item icon (see manual) - eg. 'tsm-icon-form' ",
      menuPrivileges: 'Privileges needed to display menu item',
    },
    logging: {
      registers: 'Registers',
      logType: 'Log type',
      logging: 'Logging',
      ticketing: 'Ticketing',
      ordering: 'Ordering',
    },
    notifications: {
      notificationTemplates: 'Notification templates',
      notificationRules: 'Notification types',
      notifications: 'Notifications',
      registers: 'Registers',
      deliveryTime: 'Delivery time',
    },
    outputManagement: {
      outputManagement: 'Output management',
      templates: 'Layout',
      documentTemplates: 'Templates',
    },
  },
  validation: {
    title: 'Validation',
    messages: {
      requiredValue: 'This value is required',
      valueMinLength:
        'The value ({{actualLength}}) does not have required minimum length {{requiredLength}}',
      valueMaxLength:
        'The value ({{actualLength}}) does not have required maximum length {{requiredLength}}',
      valueMinLengthForms:
        'The value ({{currentLength}}) does not have required minimum length {{minimumLength}}',
      valueMaxLengthForms:
        'The value ({{currentLength}}) does not have required maximum length {{maximumLength}}',
      min: 'The value entered ({{actual}}) is less than {{min}}',
      max: 'Entered value ({{actual}}) is greater than {{max}}',
      email: 'The value entered does not match the email format',
      pattern:
        'The value entered does not match the regex [{{requiredPattern}}]',
      compareDates: 'The "Valid From" value must precede the "Valid To" value.',
      valueUsed: 'The value is already used',
      minimum: 'Must be {{minimumValue}} or more',
      maximum: 'Must be {{maximumValue}} or less',
      profileNameUsed: 'Profile name must be unique',
      profileCodeUsed: 'Profile code must be unique',
    },
  },
  restoreInfo: {
    title: 'The result of comparing the current state against the backup',
    titlePast: 'The result of comparing the previous state against the backup',
    total: 'The number of records in the imported backup',
    created1: 'record created',
    created234: 'records created',
    created5: 'records created',
    edited1: 'record edited',
    edited234: 'records edited',
    edited5: 'records edited',
    deleted1: 'record deleted',
    deleted234: 'records deleted',
    deleted5: 'records deleted',
    compared1: 'record compared',
    compared234: 'records compared',
    compared5: 'records compared',
    filterMismatch1:
      'record not currently matching the filters and it will be edited',
    filterMismatch234:
      'records not currently matching the filters and will be edited',
    filterMismatch5:
      'records not currently matching the filters and will be edited',
    willCreated1: 'record will be created',
    willCreated234: 'records will be created',
    willCreated5: 'records will be created',
    willEdited1: 'record will be edited',
    willEdited234: 'records will be edited',
    willEdited5: 'records will be edited',
    willDeleted1: 'record will be deleted',
    willDeleted234: 'record will be deleted',
    willDeleted5: 'records will be deleted',
    willFilterMismatch1:
      'record not currently matching the filters and it will be edited',
    willFilterMismatch234:
      'records not currently matching the filters and will be edited',
    willFilterMismatch5:
      'records not currently matching the filters and will be edited',
    noChanges:
      'No changes were found between the current state and the backup.',
    tooltip:
      'The indicated numbers of records apply when processing all records from the table below.',
    selectedRecords: 'Number of records selected for processing',
  },
};
