import {UserGroupType} from '../model';
import {TsmError} from '@tsm/framework/http';
import {createAction, props} from '@ngrx/store';

export const LoadUserGroupType = createAction(
  '[UserGroupType] Load UserGroupType',
);
export const LoadUserGroupTypeSuccess = createAction(
  '[UserGroupType] Load UserGroupType Success',
  props<{
    userGroupTypes: UserGroupType[];
  }>(),
);
export const LoadUserGroupTypeError = createAction(
  '[UserGroupType] Load UserGroupType Error',
  props<TsmError>(),
);

export const LoadUserGroupTypeById = createAction(
  '[UserGroupType] Load LoadUserGroupTypeById',
  props<{
    id: string;
  }>(),
);
export const LoadUserGroupTypeByIdSuccess = createAction(
  '[UserGroupType] Load LoadUserGroupTypeById Success',
  props<{
    userGroupType: UserGroupType;
  }>(),
);
export const LoadUserGroupTypeByIdError = createAction(
  '[UserGroupType] Load LoadUserGroupTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadUserGroupTypeByCode = createAction(
  '[UserGroupType] Load LoadUserGroupTypeByCode',
  props<{
    code: string;
  }>(),
);
export const LoadUserGroupTypeByCodeSuccess = createAction(
  '[UserGroupType] Load LoadUserGroupTypeByCode Success',
  props<{
    userGroupType: UserGroupType;
  }>(),
);
export const LoadUserGroupTypeByCodeError = createAction(
  '[UserGroupType] Load LoadUserGroupTypeByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertUserGroupType = createAction(
  '[UserGroupType] Upsert UserGroupType',
  props<{
    userGroupType: UserGroupType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertUserGroupTypeSuccess = createAction(
  '[UserGroupType] Upsert UserGroupType Success',
  props<{
    userGroupType: UserGroupType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertUserGroupTypeError = createAction(
  '[UserGroupType] Upsert UserGroupType Error',
  props<TsmError>(),
);

export const DeleteUserGroupType = createAction(
  '[UserGroupType] Delete UserGroupType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteUserGroupTypeSuccess = createAction(
  '[UserGroupType] Delete UserGroupType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteUserGroupTypeError = createAction(
  '[UserGroupType] Delete UserGroupType Error',
  props<TsmError>(),
);

export const DiffUserGroupType = createAction(
  '[UserGroupType] Diff UserGroupType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffUserGroupTypeSuccess = createAction(
  '[UserGroupType] Diff UserGroupType Success',
  props<{
    userGroupTypes: UserGroupType[];
    listingId: string;
  }>(),
);
export const DiffUserGroupTypeError = createAction(
  '[UserGroupType] Diff UserGroupType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
