import {Injectable} from '@angular/core';

interface Section {
  id: string;
  title: string;
  fields: {key: string; label: string; helpMessage: string}[];
}

@Injectable({
  providedIn: 'root',
})
export class FieldsDescriptionService {
  private sections: Section[] = [];

  setSections(sections: Section[]): void {
    this.sections = sections;
  }

  getSections(): Section[] {
    return this.sections;
  }
}
