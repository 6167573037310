import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectUserByCode} from '../selectors';
import {LoadUserByCode} from '../actions';
import {of} from 'rxjs';

@Pipe({
  name: 'userByCode',
  pure: true,
})
export class UserByCodePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  get obser(): any {
    return this._obs$;
  }

  setObservable(code: string, field = 'name') {
    this.destroy();
    this._id = code;

    if (code != null) {
      this._obs$ = this.store.pipe(
        select(selectUserByCode(code)),
        filter((it) => it != null && it.loading == false),
        map((it) => {
          if (!it.data || it.error != null) {
            return code;
          } else {
            return field !== 'all' ? it.data[field] : it.data;
          }
        }),
      );
    } else {
      this._obs$ = of(null);
    }
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectUserByCode(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadUserByCode({code: this._id})),
          );
        }
      });
  }
}
