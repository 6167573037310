import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DtlMenuItem,
  DtlMenuItemApi,
  DtlMenuItemType,
} from '@tsm/framework/plugin';
import {Observable} from 'rxjs';
import {LayoutTsmService} from '@tsm/layout/service';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '../i18n';
import {Terminator} from '@tsm/framework/terminator';
import {TreeDragDropService, TreeNode} from 'primeng/api';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {v4 as getUuid} from 'uuid';

interface MenuItemTreeNode<T = any> extends TreeNode {
  originalLabel: string;
  parent?: MenuItemTreeNode<T>;
  children?: MenuItemTreeNode<T>[];
  localizationData?: any;
}

@Component({
  selector: 'tsm-layout-menu-designer',
  templateUrl: './layout-menu-designer.component.html',
  styleUrls: ['./layout-menu-designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Terminator, TreeDragDropService],
})
export class LayoutMenuDesignerComponent {
  translation = translation;
  translationShared = translationShared;

  originalMenuList: MenuItemTreeNode[] = [];
  resultMenuList: MenuItemTreeNode[] = [];
  tmpMenuList: MenuItemTreeNode[] = [];
  showEditMenuItem = false;
  selectedTreeNode: MenuItemTreeNode = null;
  selfcare = (window as any)?.app?.params?.selfcare ?? false;

  private layoutMenuState$: Observable<DtlMenuItem[]>;
  bookmarkMenuState$: Observable<DtlMenuItem[]>;
  loading$: Observable<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private terminator$: Terminator,
    private cdr: ChangeDetectorRef,
    private ts: ToastService,
    public layoutTsmService: LayoutTsmService,
  ) {
    this.layoutMenuState$ = layoutTsmService.getLayoutMenu();
    this.bookmarkMenuState$ = layoutTsmService.getLayoutBookmarkMenu();
    this.loading$ = this.layoutTsmService.loadingLayoutMenu();

    this.layoutMenuState$.subscribe((x) => {
      this.originalMenuList = this.copyDtlMenuItemsToTreeNodes(x);
      // console.log(this.originalMenuList);
      this.resultMenuList = this.filterTreesByAttribute(
        this.originalMenuList,
        true,
      );
      this.tmpMenuList = this.filterTreesByAttribute(
        this.originalMenuList,
        false,
      );
      this.cdr.markForCheck();
    });
  }

  saveMenu() {
    const visibleTable = this.treeNodesToDtlMenuItemApis(
      this.resultMenuList,
      true,
    );
    const invisibleTable = this.treeNodesToDtlMenuItemApis(
      this.tmpMenuList,
      false,
    );
    this.layoutTsmService.saveAllMenuItems([
      ...visibleTable,
      ...invisibleTable,
    ]);
  }

  discardAllChanges() {
    this.resultMenuList = this.filterTreesByAttribute(
      this.originalMenuList,
      true,
    );
    this.tmpMenuList = this.filterTreesByAttribute(
      this.originalMenuList,
      false,
    );
    this.cdr.markForCheck();
  }

  onEdit(item: MenuItemTreeNode, visible: boolean) {
    this.showEditMenuItem = true;
    this.selectedTreeNode = {...item, data: {...item.data, visible: visible}};
  }

  onDelete(item: MenuItemTreeNode) {
    this.deleteNodeFromForest(this.tmpMenuList, item.key);
  }

  onSaveEditMenuItem(item: DtlMenuItem) {
    if (this.selectedTreeNode != null) {
      // jedna se o editaci polozky v menu
      if (item.visible === false) {
        //prava strana (pomocny strom)
        this.tmpMenuList = this.findAndEditTreeNodeInForest(
          this.tmpMenuList,
          this.selectedTreeNode.key,
          item,
        );
      } else {
        //leva strana (vysledny strom)
        this.resultMenuList = this.findAndEditTreeNodeInForest(
          this.resultMenuList,
          this.selectedTreeNode.key,
          item,
        );
      }
    } else {
      // jedna se o novou poolozku do menu
      if (
        this.findNodeInForest(this.resultMenuList, item.key) != null ||
        this.findNodeInForest(this.tmpMenuList, item.key) != null
      ) {
        this.ts.showToast(
          translation.menuDesigner.uniqueCode,
          ToastSeverity.INFO,
        );
        return;
      }
      this.tmpMenuList.push(
        this.convertDtlMenuItemToTreeNode({...item, id: item.id || getUuid()}),
      );
    }
    this.showEditMenuItem = false;
    this.selectedTreeNode = null;
  }

  onCloseEditMenuItem() {
    this.showEditMenuItem = false;
    this.selectedTreeNode = null;
  }

  onDrop(event, type: 'result' | 'tmp') {
    const dragItem: MenuItemTreeNode<DtlMenuItem> = event.dragNode;
    const dropItem: MenuItemTreeNode<DtlMenuItem> = event.dropNode;
    const originalEvent = event.originalEvent;
    if (dropItem == null) {
      // pokud z leva pretahuju do prava
      event.accept();
      return;
    }
    if (originalEvent.target.className.includes('p-treenode-droppoint')) {
      // jedna se o mezeru
      const parentDrop = dropItem.parent;
      if (
        !parentDrop &&
        dragItem.styleClass === 'menu-item' &&
        type === 'result'
      ) {
        // console.log("drop do ROOTU");
        //nemuzu z routovaciho potomka udelat ROOTa
        this.ts.showToast(
          translation.menuDesigner.spaceMenu,
          ToastSeverity.INFO,
        );
        return;
      }
      // else {
      // console.log("drop do parenta", parentDrop);
      // }
    } else {
      if (dropItem.styleClass === 'menu-item') {
        //nemuzu z routovaciho potomka udelat ROOTa
        this.ts.showToast(
          translation.menuDesigner.spaceMenu,
          ToastSeverity.INFO,
        );
        return;
      }
      // console.log("drop dovnitr item", dropItem);
    }
    event.accept();
  }

  // prevede DtlMenuItems na TreeNodes
  private copyDtlMenuItemsToTreeNodes(
    forest: DtlMenuItem[],
  ): MenuItemTreeNode[] {
    const newForest: MenuItemTreeNode[] = [];
    for (const tree of forest) {
      newForest.push(this.convertDtlMenuItemToTreeNode(tree));
    }
    return newForest;
  }

  private convertDtlMenuItemToTreeNode(node: DtlMenuItem): MenuItemTreeNode {
    if (!node) {
      return null;
    }
    const newNode: MenuItemTreeNode<DtlMenuItem> = {
      label: node.translateLabel,
      originalLabel: node.label,
      data: {...node, id: node.id || getUuid()},
      icon: node.icon,
      key: node.key,
      styleClass:
        !!node?.routerLink || !!node?.url ? 'menu-item' : 'folder-item',
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      children: [],
    };
    if (node.items) {
      for (const child of node.items) {
        newNode.children.push(this.convertDtlMenuItemToTreeNode(child));
      }
    }
    return newNode;
  }

  // prevod TreeNode[] na DtlMenuItem[]
  private treeNodesToDtlMenuItemApis(
    forest: MenuItemTreeNode[],
    visibleSection: boolean,
  ): DtlMenuItemApi[] {
    const result: DtlMenuItemApi[] = [];
    let index = 10000;
    const myRouter = this.router;

    const that = this;

    function flatten(node: MenuItemTreeNode, parent?: MenuItemTreeNode) {
      index = index - 10;
      const routerLink = !!node?.data?.routerLink
        ? myRouter
            .createUrlTree(
              Array.isArray(node?.data?.routerLink)
                ? node?.data?.routerLink
                : [node?.data?.routerLink],
              {queryParams: node.data.queryParams || {}},
            )
            .toString()
        : null;
      result.push({
        // listingType?
        id: node.data.id,
        code: node.key,
        name: node.originalLabel,
        icon: node.data.icon,
        url: node.data.url,
        routerLink: routerLink,
        parent: parent?.data
          ? {
              id: parent.data.id,
              code: parent.key,
              name: parent.label,
              icon: parent.data.icon,
              url: null,
              routerLink: null,
              type: that.selfcare
                ? DtlMenuItemType.SELFCARE
                : DtlMenuItemType.MENU,
            }
          : null,
        useDefaultSetting: false,
        visible: visibleSection,
        privilege:
          node?.data?.privilege?.length > 0
            ? node?.data?.privilege.join(',')
            : 'true',
        config: {},
        userId: node.data.userId,
        sortOrder: index,
        type: that.selfcare ? DtlMenuItemType.SELFCARE : DtlMenuItemType.MENU,
        localizationData: node.data?.localizationData ?? {},
      });

      for (const child of node.children) {
        flatten(child, node);
      }
    }

    for (const tree of forest) {
      flatten(tree);
    }
    return result;
  }

  /**
   * Najde prvek v lese a aktualizuje ho
   */
  private findAndEditTreeNodeInForest(
    forest: MenuItemTreeNode[],
    targetValue: string,
    menuItem: DtlMenuItem,
  ): MenuItemTreeNode[] {
    function findAndEditNodeInTree(
      tree: MenuItemTreeNode,
      targetValue: string,
      menuItem: DtlMenuItem,
    ): MenuItemTreeNode {
      if (tree.key === targetValue) {
        return {
          ...tree,
          label: menuItem.translateLabel,
          originalLabel: menuItem.label,
          styleClass:
            !!menuItem?.routerLink || !!menuItem?.url
              ? 'menu-item'
              : 'folder-item',
          data: menuItem,
          icon: menuItem.icon,
          key: menuItem.key,
          children: tree.children.map((child) =>
            findAndEditNodeInTree(child, targetValue, menuItem),
          ),
        };
      }
      return {
        ...tree,
        children: tree.children.map((child) =>
          findAndEditNodeInTree(child, targetValue, menuItem),
        ),
      };
    }

    return forest.map((tree) =>
      findAndEditNodeInTree(tree, targetValue, menuItem),
    );
  }

  /**
   * Zkusi najit prvek v lese dle key
   */
  private findNodeInForest(
    forest: MenuItemTreeNode[],
    targetKey: string,
  ): MenuItemTreeNode | null {
    function findNodeInTree(
      tree: MenuItemTreeNode,
      targetKey: string,
    ): MenuItemTreeNode | null {
      if (tree.key === targetKey) {
        return tree;
      }
      for (const child of tree.children) {
        const result = findNodeInTree(child, targetKey);
        if (result !== null) {
          return result;
        }
      }
      return null;
    }

    for (const tree of forest) {
      const result = findNodeInTree(tree, targetKey);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }

  /**
   * Smazani prvku z lesa na zaklade key
   */
  private deleteNodeFromForest(forest: MenuItemTreeNode[], key: string): void {
    function deleteNode(root: MenuItemTreeNode, key: string): void {
      root.children = root.children.filter((child) => child.key !== key);
      root.children.forEach((child) => deleteNode(child, key));
    }

    forest.forEach((tree, index) => {
      if (tree.key === key) {
        forest.splice(index, 1);
      } else {
        tree.children = tree.children.filter((child) => child.key !== key);
        for (const child of tree.children) {
          deleteNode(child, key);
        }
      }
    });
  }

  /**
   * Profiltrovani lesa na viditelne a neviditelne polozky dle visible
   */
  private filterTreesByAttribute(
    trees: MenuItemTreeNode[],
    visibleSection: boolean,
  ): MenuItemTreeNode[] {
    const visibleTrees: MenuItemTreeNode[] = [];

    // Funkce pro získání potomků, kteří jsou viditelní
    function getVisibleChildren(node: MenuItemTreeNode): MenuItemTreeNode[] {
      return node.children.reduce(
        (acc: MenuItemTreeNode[], child: MenuItemTreeNode) => {
          if (
            visibleSection
              ? child?.data?.visible != false
              : child?.data?.visible == false
          ) {
            const visibleChild: MenuItemTreeNode = {
              ...child,
              children: getVisibleChildren(child),
            };
            acc.push(visibleChild);
          } else {
            acc.push(...getVisibleChildren(child));
          }
          return acc;
        },
        [],
      );
    }

    // Procházení stromů a přidávání viditelných stromů do pole
    trees.forEach((tree: MenuItemTreeNode) => {
      if (
        visibleSection
          ? tree?.data?.visible != false
          : tree?.data?.visible == false
      ) {
        const visibleTree: MenuItemTreeNode = {
          ...tree,
          children: getVisibleChildren(tree),
        };
        // Kontrola, zda kořen již není v poli visibleTrees
        const existingRoot = visibleTrees.find((t) => t.key === tree.key);
        if (!existingRoot) {
          visibleTrees.push(visibleTree);
        } else {
          // Pokud kořen již byl do pole přidán, přidáme pouze jeho viditelné potomky
          existingRoot.children.push(...visibleTree.children);
        }
      }
    });

    return visibleTrees;
  }

  // NEMAZAT, MOZNA SE BUDE JESTE HODIT!!!
  // private filterTreesByAttribute(trees: TreeNode[], visibleSection: boolean): TreeNode[] {
  //   const visibleTrees: TreeNode[] = [];
  //
  //   function hasVisibleNode(node: TreeNode): boolean {
  //     // Pokud má aktuální uzel nastavenou vlastnost visible na true, vrať true
  //     if (node?.data?.visible === false) {
  //       return true;
  //     }
  //     // Pokud uzel nemá žádné potomky, vrátíme false
  //     if (!node?.children?.length) {
  //       return false;
  //     }
  //     // Procházíme potomky a rekurzivně voláme funkci hasVisibleNode pro každého z nich
  //     for (let i = 0; i < node.children.length; i++) {
  //       if (hasVisibleNode(node.children[i])) {
  //         return true;
  //       }
  //     }
  //     // Pokud se nepodařilo najít viditelný prvek v potomcích, vrátíme false
  //     return false;
  //   }
  //
  //   // Funkce pro získání potomků, kteří jsou viditelní
  //   function getVisibleChildren(node: TreeNode): TreeNode[] {
  //     return node.children.reduce((acc: TreeNode[], child: TreeNode) => {
  //       if (visibleSection
  //         ? child?.data?.visible != false // leva strana
  //         : (node?.data?.visible == false ? true : hasVisibleNode(child)) // prava strana
  //       ) {
  //         acc.push({
  //           ...child,
  //           children: getVisibleChildren(child),
  //         });
  //       } else {
  //         acc.push(...(visibleSection
  //               ? []
  //               : getVisibleChildren({
  //                 ...child,
  //                 styleClass: !visibleSection && hasVisibleNode(child) ? 'folder-item-fake' : child.styleClass
  //               })
  //           )
  //         );
  //       }
  //       return acc;
  //     }, []);
  //   }
  //
  //   // Procházení stromů a přidávání viditelných stromů do pole
  //   trees.forEach((tree: TreeNode) => {
  //     if (visibleSection
  //       ? tree?.data?.visible != false // leva strana
  //       : (tree?.data?.visible == false || (tree?.data?.visible != false && hasVisibleNode(tree))) // prava strana
  //     ) {
  //       // tree?.children?.some(ch => ch?.data?.visible == false)
  //       const visibleTree: TreeNode = {
  //         ...tree,
  //         styleClass: (!visibleSection && tree?.data?.visible != false && hasVisibleNode(tree)) ? 'folder-item-fake' : tree.styleClass,
  //         children: getVisibleChildren(tree),
  //       };
  //       // Kontrola, zda kořen již není v poli visibleTrees
  //       const existingRoot = visibleTrees.find((t) => t.key === tree.key);
  //       if (!existingRoot) {
  //         visibleTrees.push(visibleTree);
  //       } else {
  //         // Pokud kořen již byl do pole přidán, přidáme pouze jeho viditelné potomky
  //         existingRoot.children.push(...visibleTree.children);
  //       }
  //     }
  //   });
  //
  //   return visibleTrees;
  // }
}
