<div class="flex relative">
  <dtl-form-input-text
    #inputElement
    [class.ng-required]="required == true"
    [class.p-state-filled]="true"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [setAutofocus]="setAutofocus"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="inputId"
    [(ngModel)]="val"
  >
  </dtl-form-input-text>
  <!-- TODO je to pomaly -->
  <div
    *ngIf="!inputElement.readonly"
    class="absolute"
    style="right: 0.225rem; bottom: 0.225rem"
  >
    <tsm-icon-picker
      (onIconClick)="selectIcon($event)"
      label="empty"
      class="p-button-plain p-button-rounded p-button-text"
    ></tsm-icon-picker>
  </div>
</div>
