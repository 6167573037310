import {Injector, Pipe, PipeTransform, Type} from '@angular/core';

import * as objectPath from 'object-path';
import {combineLatest, from, isObservable, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {TableColumn, TableType} from '../models';
import {DynamicComponentService} from '@tsm/framework/dynamic-component';
import {LocalizationDataTranslatePipe} from '@tsm/framework/root';

@Pipe({
  name: 'dataForView',
})
export class DataForViewPipe implements PipeTransform {
  _prevValue: any = null;
  _prevResult: any = null;
  private tableType: TableType;

  constructor(
    private injector: Injector,
    private localizationDataTranslatePipe: LocalizationDataTranslatePipe,
    private dynamicComponentService: DynamicComponentService,
  ) {}

  transform(
    row: any,
    columns: TableColumn[],
    tableType: TableType,
    lang: string,
  ): any {
    this.tableType = tableType;
    if (this._prevValue == null) {
      this._prevValue = {row: row, columns: columns};
      this._prevResult = this.createData(columns, row);
      return this._prevResult;
    }

    const unchanged = this.discriminator(this._prevValue, {
      row: row,
      columns: columns,
      lang: lang,
    });
    if (unchanged) {
      return this._prevResult;
    }
    this._prevValue = {row: row, columns: columns};
    this._prevResult = this.createData(columns, row);
    return this._prevResult;
  }

  discriminator(
    oldValue: {row: any; columns: any; lang: string},
    newValue: {
      row: any;
      columns: any;
      lang: string;
    },
  ) {
    if (!oldValue) {
      return false;
    }
    return (
      oldValue.row == newValue.row &&
      oldValue.columns.length == newValue.columns.length &&
      !oldValue.columns.some(
        (x) => newValue.columns.find((y) => y.field === x.field) == null,
      ) &&
      oldValue.lang == newValue.lang
    );
  }

  createData(columns: TableColumn[], row) {
    return columns.reduce((acc, col) => {
      const val = this.getColumnValue(col, row);
      acc[col.field] = {
        ...col,
        value: this.convertWithColumnConvertor(col, row),
        originalValue: this.getColumnValue(col, row),
        hasValue: !!val && (!Array.isArray(val) || val.length),
      };
      return acc;
    }, {});
  }

  convertWithColumnConvertor(column: TableColumn, rowData) {
    const data = this.getColumnValue(column, rowData);

    if (column.converter && column.convertOnBackend != true) {
      return from(Promise.resolve(column.converter)).pipe(
        switchMap((converterWidget) => {
          return this.getConvertedValueFromPipe(
            column,
            converterWidget,
            rowData,
            data,
          );
        }),
      );
    }
    return of(data);
  }

  getColumnValue(column: TableColumn, rowData: any): any {
    let field: string =
      this.tableType?.queryLanguage === 'TQL'
        ? column.field
        : column.displayField || column.field;

    // strip vse za znakem # - pouziva se jako suffix pro unikatni field
    if (field.indexOf('#') !== -1) {
      field = field.substr(0, field.indexOf('#'));
    }
    // vyhodnoceni dat v ceste. Specialni znak * pro vraceni celeho zaznamu - zracovani pak bude v konvertoru
    if (field === '*') {
      return rowData;
    } else if (this.tableType?.queryLanguage === 'TQL') {
      return this.localizationDataTranslatePipe.transform(
        rowData.localizationData,
        field,
        objectPath.get(rowData, [field]),
        true,
      );
    } else {
      return this.localizationDataTranslatePipe.transform(
        rowData.localizationData,
        field,
        objectPath.get(rowData, field),
        true,
      );
    }
  }

  private getConvertedValueFromPipe(
    column: TableColumn,
    converter: Type<PipeTransform>,
    rowData: any,
    data: any,
  ): Observable<any> {
    const pipe = this.injector.get<PipeTransform>(converter, null, {
      optional: true,
    });
    const paramObs: Observable<any> = column.converterParams
      ? combineLatest(
          column.converterParams.map((param) =>
            isObservable(param) ? param : of(param),
          ),
        )
      : of(null);
    if (!pipe) {
      if (!(converter as any)?.ɵpipe?.name) {
        throw new Error('ɵpipe does not exist!!');
      }
      // pokud nedohledam pipu v injectoru (bere se z roota a napriklad v sobe nema UserPipe), tak se kouknu do registrovabych pipe v pluginu
      return from(
        this.dynamicComponentService.resolvePipeFactoryAndModule(
          (converter as any).ɵpipe.name,
        ),
      ).pipe(
        switchMap((x) => {
          const newInjector =
            x[1] == null
              ? Injector.create({
                  providers: [x[0]],
                  parent: this.injector,
                })
              : x[1].injector;

          const pipe1 = newInjector.get(converter);
          let result;
          if ((pipe1 as any).requiresRowData) {
            result = paramObs.pipe(
              map((params) =>
                pipe1.transform(
                  data,
                  ...(params || ['emptyConverterParams']),
                  rowData,
                ),
              ),
              switchMap((x) => this.returnAsObsIfNotAlready(x)),
            );
          } else {
            result = paramObs.pipe(
              map((params) => pipe1.transform(data, ...(params || []))),
              switchMap((x) => this.returnAsObsIfNotAlready(x)),
            );
          }
          if (isObservable(result)) {
            return result;
          } else {
            return of(result);
          }
        }),
      );
    } else {
      let result: Observable<any> = of('');
      if ((pipe as any).requiresRowData) {
        result = paramObs.pipe(
          map((params) =>
            pipe.transform(
              data,
              ...(params || ['emptyConverterParams']),
              rowData,
            ),
          ),
          switchMap((x) => this.returnAsObsIfNotAlready(x)),
        );
      } else {
        result = paramObs.pipe(
          map((params) => pipe.transform(data, ...(params || []))),
          switchMap((x) => this.returnAsObsIfNotAlready(x)),
        );
      }
      return result;
    }
  }

  private returnAsObsIfNotAlready(value: any): Observable<any> {
    return isObservable(value) ? value : of(value);
  }
}
