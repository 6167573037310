import {translation as translationShared} from '@tsm/shared-i18n';
import {Component} from '@angular/core';
import {WidgetEditorComponent} from '@tsm/framework/root';
import {TsmFormBuilder} from '@tsm/framework/forms';

@Component({
  selector: 'tsm-preview-dropdown-editor',
  template: `
    <tsm-form [formGroup]="widgetForm">
      <tsm-accordion>
        <tsm-accordionTab [selected]="true">
          <p-header>{{
            translationShared.shared.basicInfo | transloco
          }}</p-header>

          <dtl-dynamic-component
            [selector]="'dtl-basic-editor'"
            [inputs]="{
              formGroup: widgetForm,
              showTitleLocalizations: showTitleLocalizations,
              showTooltipLocalizations: showTooltipLocalizations,
            }"
          ></dtl-dynamic-component>

          <dtl-form-group>
            <ng-container formGroupName="config">
              <tsm-form-field
                label="{{ translationShared.shared.value | transloco }}"
                formControlName="selectProperty"
              >
                <ng-template>
                  <tsm-dropdown
                    class="dtl-form-field-input"
                    [showClear]="false"
                    [options]="selectProperties"
                    [autoDisplayFirst]="false"
                    formControlName="selectProperty"
                  >
                  </tsm-dropdown>
                </ng-template>
              </tsm-form-field>
            </ng-container>

            <tsm-form-field
              label="{{ translationShared.shared.defaultValue | transloco }}"
              [cols]="12"
              formControlName="default"
            >
              <ng-template>
                <dtl-dynamic-component
                  [selector]="'tsm-ticket-priority-lov'"
                  [inputs]="{
                    value: widgetForm.value.default,
                    dataKey: widgetForm.get('config.selectProperty').value,
                    readonly: false,
                    formControl: widgetForm.get('default'),
                  }"
                ></dtl-dynamic-component>
              </ng-template>
            </tsm-form-field>
          </dtl-form-group>
        </tsm-accordionTab>

        <tsm-accordionTab>
          <p-header>{{
            translationShared.validation.title | transloco
          }}</p-header>
          <ng-template pTemplate="content">
            <dtl-dynamic-component
              [selector]="'dtl-basic-validation-editor'"
              [inputs]="{
                formGroup: widgetForm,
                config: config,
              }"
            ></dtl-dynamic-component>
          </ng-template>
        </tsm-accordionTab>
      </tsm-accordion>
    </tsm-form>
  `,
})
export class PreviewDropdownEditorComponent extends WidgetEditorComponent {
  translationShared = translationShared;

  selectProperties = [
    {label: 'ID', value: 'id'},
    {label: 'Code', value: 'code'},
    {label: 'All', value: 'all'},
  ];

  constructor(fb: TsmFormBuilder) {
    super(
      fb.tsmGroup<any>({
        title: fb.tsmControl(''),
        default: fb.tsmControl(''),
        widget: fb.tsmGroup({
          listing: fb.tsmControl(false),
          readonly: fb.tsmControl(false),
          excelStyleErrors: fb.tsmControl(false),
          persistent: fb.tsmControl('ReadonlyBased'),
          hidden: fb.tsmControl(false),
          disabled: fb.tsmControl(false),
          notitle: fb.tsmControl(false),
          tooltip: fb.tsmControl(''),
          labelPosition: fb.tsmControl('left'),
          fieldHtmlClass: fb.tsmControl(''),
          labelHtmlClass: fb.tsmControl(''),
          requiredInput: fb.tsmControl(false),
          requiredValidating: fb.tsmControl(''),
          customValidations: fb.tsmArray([]),
          customValidationsValidating: fb.tsmArray([]),
          validationMessages: fb.tsmGroup({
            required: fb.tsmControl(''),
            customValidations: fb.tsmArray([]),
          }),
        }),
        config: fb.tsmGroup({
          selectProperty: fb.tsmControl(''),
        }),
      }),
    );
  }
}
