import {NgModule, Optional, SkipSelf} from '@angular/core';
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  endOfDay,
  format,
  formatISO,
  isBefore,
  isEqual,
  isValid,
  parse,
  parseISO,
} from 'date-fns';

import {
  COMPONENTS,
  EXPORTS,
  GUARDS,
  IMPORTS,
  PIPES,
  PROVIDERS,
  RESOLVERS,
} from './index';
import {TranslocoService} from '@tsm/framework/translate';
import {DatePipe, DecimalPipe, JsonPipe} from '@angular/common';
import {banValid, RegistryService} from '@tsm/framework/root';
import {getOrganizationId, getUserId} from '@tsm/framework/functions';
import {v4 as getUuid, validate as uuidValidate} from 'uuid';
import * as objectPath from 'object-path';
import {roundDateTo, ValidationUtils} from '@tsm/shared';
import {JsonPointer} from 'tsm-json-schema-form-functions';

@NgModule({
  imports: [...IMPORTS],
  declarations: [...COMPONENTS, ...PIPES],
  exports: [...EXPORTS],
  providers: [...GUARDS, ...RESOLVERS, ...PROVIDERS],
})
export class CoreModule {
  private convertDate(date: Date, time: string, format?: string): string {
    const datePipe = new DatePipe('cs');
    if (time === 'from') {
      return datePipe.transform(date, format || 'yyyy-MM-ddT00:00:00.000Z');
    }
    return datePipe.transform(date, format || 'yyyy-MM-ddT23:59:59.000Z');
  }

  constructor(
    @Optional() @SkipSelf() parent: CoreModule,
    private translocoService: TranslocoService,
    private json: JsonPipe,
    private registryService: RegistryService,
    private decimalPipe: DecimalPipe,
  ) {
    if (parent) {
      throw new Error(
        'CoreModule byl uz nacten. Mel by byt importovan pouze jednou!',
      );
    }

    registryService.addTransform(
      'translate',
      (val) => translocoService.translate(val),
      {
        detail: 'translate',
        description: {
          about: 'translate',
          params: [{name: 'val', about: 'Text to translate'}],
          example: "'someText' | translate",
        },
      },
    );
    registryService.addTransform('json', (val) => json.transform(val), {
      detail: 'json',
      description: {
        about: 'Converts any object into json string',
        params: [{name: 'obj', about: 'Object to parse'}],
        example: '{"some": "json"} | json',
      },
    });
    registryService.addTransform('jsonParse', (val) => JSON.parse(val), {
      detail: 'jsonParse',
      description: {
        about: 'Parses json string into object',
        params: [{name: 'str', about: 'Json string'}],
        example: '\'{"some": "json"}\' | jsonParse',
      },
    });
    registryService.addTransform(
      'seconds',
      (val) => new Date(val).getTime() / 1000,
      {
        detail: 'seconds',
        description: {
          about: 'Converts date to seconds',
          params: [{name: 'date', about: 'Date to convert'}],
          example: 'now() | seconds',
        },
      },
    );

    // validace rodneho cisla
    const nidValid = (x: string) => {
      const monthRanges = [
        [1, 12],
        [21, 32],
        [51, 62],
        [71, 82],
      ];
      try {
        if (x.length == 0) {
          return true;
        }
        if (x[6] == '/') {
          x = x.replace('/', '');
        }
        if (x.split('').some((y) => y === '_')) {
          x = x.replace(/_/g, '');
        }
        if (x.length < 9) {
          throw 1;
        }
        let year = parseInt(x.substr(0, 2), 10);
        let month = parseInt(x.substr(2, 2), 10);

        const foundRange = monthRanges.find(
          (range) => range[0] <= month && range[1] >= month,
        );
        if (foundRange == null) {
          return false;
        }

        const day = parseInt(x.substr(4, 2), 10);
        if (day < 1 || day > 31) {
          return false;
        }
        const ext = parseInt(x.substr(6, 3), 10);

        if (x.length === 9 && month > 70 && 2000 + year > 2003) {
          throw 1;
        }
        if (x.length === 9 && year < 54) {
          return true;
        }

        let c = 0;
        if (x.length === 10) {
          c = parseInt(x.substr(9, 1));
        }
        let m = parseInt(x.substr(0, 9)) % 11;
        if (m == 10) {
          m = 0;
        }
        if (m != c) {
          throw 1;
        }
        year += year < 54 ? 2000 : 1900;
        if (month > 70 && year > 2003) {
          month -= 70;
        } else if (month > 50) {
          month -= 50;
        } else if (month > 20 && year > 2003) {
          month -= 20;
        }
        const d = new Date();
        if (year > d.getFullYear()) {
          throw 1;
        }
        if (month == 0) {
          throw 1;
        }
        if (month > 12) {
          throw 1;
        }
        if (day == 0) {
          throw 1;
        }
        if (day > 31) {
          throw 1;
        }
      } catch (e) {
        return false;
      }
      return true;
    };
    registryService.addFunction('nidValid', (x: string) => nidValid(x), {
      detail: 'nidValid(val: string)',
      description: {
        about: 'nidValid',
        params: [{name: 'val', about: 'National identification number'}],
        example: "nidValid('123456/7890')",
      },
    });
    registryService.addFunction('currentUserId', () => getUserId(), {
      detail: 'currentUserId()',
      description: {
        about: 'currentUserId',
        example: 'currentUserId()',
      },
    });
    registryService.addFunction(
      'currentOrganizationId',
      () => getOrganizationId(),
      {
        detail: 'currentOrganizationId()',
        description: {
          about: 'currentOrganizationId',
          example: 'currentOrganizationId()',
        },
      },
    );
    // validace bankovniho uctu
    registryService.addFunction(
      'banValid',
      (accountNumber: string) => banValid(accountNumber),
      {
        detail: 'banValid(val: string)',
        description: {
          about: 'banValid',
          params: [{name: 'val', about: 'Bank account number'}],
          example: "banValid('1234567890')",
        },
      },
    );
    // prevod rodneho cisla na datum
    registryService.addFunction(
      'nidToDate',
      (val: string) => {
        if (!!val && nidValid(val)) {
          const rcDate = val.split('/')[0];
          const rcControl = val.split('/')[1];
          const year = rcDate.slice(0, 2);
          const month = +rcDate.slice(2, 4);
          const day = rcDate.slice(4, 6);

          let beforeDate;
          let afterDate;

          const adjustDate = (monthAdjustment) => {
            const adjustedMonth = month - monthAdjustment;
            const baseYear = adjustedMonth > 0 ? `19${year}` : `20${year}`;
            return parse(
              `${baseYear}-${adjustedMonth > 0 ? adjustedMonth : month}-${day}`,
              'yyyy-M-d',
              new Date(),
            );
          };

          if (month - 70 > 0) {
            // It's a woman
            beforeDate = adjustDate(70);
            afterDate = adjustDate(0);
          } else if (month - 50 > 0) {
            // It's a woman
            beforeDate = adjustDate(50);
            afterDate = adjustDate(0);
          } else if (month - 20 > 0) {
            // It's a man
            beforeDate = adjustDate(20);
            afterDate = adjustDate(0);
          } else {
            // It's a man
            beforeDate = adjustDate(0);
            afterDate = adjustDate(0);
          }

          if (
            isBefore(afterDate, new Date()) ||
            isEqual(afterDate, new Date())
          ) {
            if (
              (rcControl.includes('_') || rcControl.length === 3) &&
              +year < 54
            ) {
              return formatISO(beforeDate, {representation: 'date'});
            }
            return formatISO(afterDate, {representation: 'date'});
          }
          return formatISO(beforeDate, {representation: 'date'});
        } else {
          return null;
        }
      },
      {
        detail: 'nidToDate(val: string)',
        description: {
          about: 'nidToDate',
          params: [{name: 'val', about: 'National identification number'}],
          example: "nidToDate('123456/7890')",
        },
      },
    );
    registryService.addTransform(
      'minutes',
      (val) => new Date(val).getTime() / 60000,
      {
        detail: 'minutes',
        description: {
          about: 'Calculates minutes for a given date',
          params: [{name: 'val', about: 'Date'}],
          example: "'2020-01-01T00:00:00.000Z' | minutes",
        },
      },
    );
    registryService.addTransform(
      'hours',
      (val) => new Date(val).getTime() / 360000,
      {
        detail: 'hours',
        description: {
          about: 'hours',
          params: [{name: 'val', about: 'Date'}],
          example: "'2020-01-01T00:00:00.000Z' | hours",
        },
      },
    );
    // prace s datumem, odpovida funkci ADD v Momentu
    registryService.addFunction(
      'addTime',
      (val, count, type) => {
        if (isValid(parseISO(val)) && count != null) {
          const date = new Date(val);
          let resultDate;

          // Based on the type, add the corresponding amount of time
          switch (type) {
            case 'years':
              resultDate = addYears(date, count);
              break;
            case 'months':
              resultDate = addMonths(date, count);
              break;
            case 'days':
              resultDate = addDays(date, count);
              break;
            case 'hours':
              resultDate = addHours(date, count);
              break;
            case 'minutes':
              resultDate = addMinutes(date, count);
              break;
            case 'seconds':
              resultDate = addSeconds(date, count);
              break;
            default:
              return null; // or handle the error as you see fit
          }

          // Return the modified date in ISO 8601 format
          return formatISO(resultDate);
        } else {
          // If the date is not valid, return null or handle accordingly
          return null;
        }
      },
      {
        detail: 'addTime',
        description: {
          about: 'addTime',
          params: [
            {name: 'val', about: 'Date'},
            {name: 'count', about: 'Count'},
            {name: 'type', about: 'Type'},
          ],
          example: "addTime('2020-01-01T00:00:00.000Z', 1, 'days')",
        },
      },
    );
    registryService.addFunction(
      'length',
      (val) => {
        if (!val) {
          return 0;
        }
        return val.length;
      },
      {
        detail: 'length(val: string)',
        description: {
          about: 'length',
          params: [{name: 'val', about: 'String'}],
          example: "length('test')",
        },
      },
    );
    registryService.addFunction('round', (val) => Math.round(val), {
      detail: 'round(val: number)',
      description: {
        about: 'round',
        params: [{name: 'val', about: 'Number'}],
        example: 'round(1.5)',
      },
    });
    registryService.addFunction(
      'sum',
      (...args) => args.reduce((total, num) => total + num, 0),
      {
        detail: 'sum(...args: number[])',
        description: {
          about: 'sum',
          params: [{name: 'args', about: 'Numbers'}],
          example: 'sum(1, 2, 3)',
        },
      },
    );
    // @returns number
    registryService.addFunction(
      'sumMany',
      (array, field, fractionDigits = null) => {
        const sum = (array ?? []).reduce(
          (total, item) => total + (parseFloat(item?.[field] ?? 0) || 0),
          0,
        );
        if (fractionDigits != null) {
          const normalized = +sum.toFixed(fractionDigits);
          return normalized;
        }
        return sum;
      },
      {
        detail: 'sumMany(array: any[], field: string, fractionDigits: number)',
        description: {
          about: 'sumMany',
          params: [
            {name: 'array', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'fractionDigits', about: 'Fraction digits'},
          ],
          example: "sumMany([{a: 1}, {a: 2}], 'a')",
        },
      },
    );
    // @returns string
    registryService.addFunction(
      'transformNumber',
      (val, fractionDigits) => {
        const digitsInfo =
          fractionDigits == null ? '' : `1.${fractionDigits}-${fractionDigits}`;
        const normalized = this.decimalPipe.transform(val, digitsInfo);
        return normalized;
      },
      {
        detail: 'transformNumber(val: number, fractionDigits: number)',
        description: {
          about: 'transformNumber',
          params: [
            {name: 'val', about: 'Number'},
            {name: 'fractionDigits', about: 'Fraction digits'},
          ],
          example: 'transformNumber(1.5, 2)',
        },
      },
    );
    registryService.addFunction(
      'now',
      (withoutTime = false) => {
        if (withoutTime) {
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          return date.toISOString();
        }
        return new Date().toISOString();
      },
      {
        detail: 'now(withoutTime: boolean)',
        description: {
          about: 'now',
          params: [{name: 'withoutTime', about: 'Without time'}],
          example: 'now()',
        },
      },
      false,
    );
    registryService.addTransform(
      'toISOString',
      (val) => {
        if (!val) {
          return null;
        }
        if (typeof val == 'string') {
          return val;
        }
        return val.toISOString();
      },
      {
        detail: 'toISOString',
        description: {
          about: 'Convert date to iso string',
          params: [{name: 'val', about: 'Date'}],
          example: 'now() | toISOString',
        },
      },
    );
    registryService.addFunction(
      'nowWithHours',
      (
        hours: number,
        minutes: number = 0,
        seconds: number = 0,
        formatIso = false,
      ) => {
        const date = new Date();
        date.setHours(hours, minutes, seconds, 0);
        if (formatIso) {
          return date.toISOString();
        }
        return date;
      },
      {
        detail:
          'nowWithHours(hours: number, minutes: number = 0, seconds: number = 0, formatIso = false)',
        description: {
          about: 'nowWithHours',
          params: [
            {name: 'hours', about: 'Hours'},
            {name: 'minutes', about: 'Minutes'},
            {name: 'seconds', about: 'Seconds'},
            {name: 'formatIso', about: 'Format iso'},
          ],
          example: 'nowWithHours(1, 2, 3)',
        },
      },
      false,
    );
    registryService.addFunction(
      'isToday',
      (date: string) => {
        const today = new Date();
        const dateToCheck = new Date(date);
        return today.toDateString() === dateToCheck.toDateString();
      },
      {
        detail: 'isToday(date: string)',
        description: {
          about: 'isToday',
          params: [{name: 'date', about: 'Date'}],
          example: 'isToday(whenInserted)',
        },
      },
    );
    registryService.addFunction(
      'isTomorrow',
      (date: string) => {
        let today = new Date();
        today.setDate(today.getDate() + 1);
        const dateToCheck = new Date(date);
        return today.toDateString() === dateToCheck.toDateString();
      },
      {
        detail: 'isTomorrow(date: string)',
        description: {
          about: 'isTomorrow',
          params: [{name: 'date', about: 'Date'}],
          example: 'isTomorrow(whenInserted)',
        },
      },
    );
    registryService.addFunction(
      'isYesterday',
      (date: string) => {
        let today = new Date();
        today.setDate(today.getDate() - 1);
        const dateToCheck = new Date(date);
        return today.toDateString() === dateToCheck.toDateString();
      },
      {
        detail: 'isYesterday(date: string)',
        description: {
          about: 'isYesterday',
          params: [{name: 'date', about: 'Date'}],
          example: 'isYesterday(whenInserted)',
        },
      },
    );
    registryService.addFunction(
      'isToday',
      (date: string | Date) => {
        const today = new Date();
        const dateToCheck = new Date(date);
        return today.toDateString() === dateToCheck.toDateString();
      },
      {
        detail: 'isToday(date: string | Date)',
        description: {
          about: 'isToday',
          params: [
            {
              name: 'date',
              about: 'Date',
            },
          ],
        },
      },
    );
    registryService.addFunction(
      'isTomorrow',
      (date: string | Date) => {
        let today = new Date();
        today.setDate(today.getDate() + 1);
        const dateToCheck = new Date(date);
        return today.toDateString() === dateToCheck.toDateString();
      },
      {
        detail: 'isTomorrow(date: string | Date)',
        description: {
          about: 'isTomorrow',
          params: [
            {
              name: 'date',
              about: 'Date',
            },
          ],
        },
      },
    );
    registryService.addFunction(
      'isYesterday',
      (date: string | Date) => {
        let today = new Date();
        today.setDate(today.getDate() - 1);
        const dateToCheck = new Date(date);
        return today.toDateString() === dateToCheck.toDateString();
      },
      {
        detail: 'isYesterday(date: string | Date)',
        description: {
          about: 'isYesterday',
          params: [
            {
              name: 'date',
              about: 'Date',
            },
          ],
        },
      },
    );
    // orez stringu
    registryService.addFunction(
      'substring',
      (value, start, end) => {
        return (value as string)?.substring(start, end);
      },
      {
        detail: 'substring(value: string, start: number, end: number)',
        description: {
          about: 'substring',
          params: [
            {name: 'value', about: 'String'},
            {name: 'start', about: 'Start'},
            {name: 'end', about: 'End'},
          ],
          example: "substring('test', 1, 2)",
        },
      },
    );
    // split stringu
    registryService.addFunction(
      'split',
      (value, char = '/') => {
        return !!value ? value.split(char) : [];
      },
      {
        detail: "split(value: string, char: string = '/')",
        description: {
          about: 'split',
          params: [
            {name: 'value', about: 'String'},
            {name: 'char', about: 'Char'},
          ],
          example: "split('test.text', '.')",
        },
      },
    );
    // replace all quotes
    registryService.addFunction(
      'replaceDoubleQuotesToSingle',
      (value: string) => {
        return value.split('"').join("'");
      },
      {
        detail: 'replaceDoubleQuotesToSingle(value: string)',
        description: {
          about: 'replaceDoubleQuotesToSingle',
          params: [{name: 'value', about: 'String'}],
          example: 'replaceDoubleQuotesToSingle(\'"test"\')',
        },
      },
    );
    // replace all targets
    registryService.addFunction(
      'replace',
      (value: string, char: string, join: string) => {
        if (!!value) {
          return value.split(char).join(join);
        }
        return null;
      },
      {
        detail: 'replace(value: string, char: string, join: string)',
        description: {
          about: 'replace',
          params: [
            {name: 'value', about: 'String'},
            {name: 'char', about: 'Char'},
            {name: 'join', about: 'Join'},
          ],
          example: "replace('test.text', '.', '-')",
        },
      },
    );
    // premapuje pole obkejtu na nove posle dle fieldu
    registryService.addFunction(
      'mapObject',
      (value: Array<any>, field: string) => {
        if (!value || value.length === 0) {
          return [];
        }
        const fieldArray = field.split('.');
        let tmpValue = value;
        for (let i = 0; i < fieldArray.length; i++) {
          tmpValue = tmpValue.map((x) => x[fieldArray[i]]);
        }
        return tmpValue;
      },
      {
        detail: 'mapObject(value: Array<any>, field: string)',
        description: {
          about: 'Maps an array of objects to a new array based on field',
          params: [
            {name: 'value', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
          ],
          example: "mapObject([{a: {b: 1}}, {a: {b: 2}}], 'a.b')",
        },
      },
    );
    // join pole stringu
    registryService.addFunction(
      'join',
      (value: Array<string>, separator = ', ') => {
        if (!value || value.length === 0) {
          return '';
        }
        return value.join(separator);
      },
      {
        detail: "join(value: Array<string>, separator = ', ')",
        description: {
          about: 'join',
          params: [
            {name: 'value', about: 'Array of strings'},
            {name: 'separator', about: 'Separator'},
          ],
          example: "join(['test', 'text'], '.')",
        },
      },
    );
    // pridani prvku do pole
    registryService.addFunction(
      'addItems',
      (value: Array<string>, addArray: Array<any> = []) => {
        if (!addArray || addArray.length === 0) {
          return value;
        }
        return [...value, ...addArray];
      },
      {
        detail: 'addItems',
        description: {
          about: 'addItems',
          params: [
            {name: 'value', about: 'Array'},
            {name: 'addArray', about: 'Array to add'},
          ],
          example: "addItems(['test'], ['text'])",
        },
      },
    );
    // prunik pole stringu
    registryService.addFunction(
      'intersection',
      (arr1: Array<string>, arr2: Array<string>) => {
        if (!arr1 || !arr2) {
          return [];
        }
        const set2 = new Set(arr2);
        return arr1.filter((item) => set2.has(item));
      },
      {
        detail: 'intersection',
        description: {
          about: 'Intersection items to an array',
          params: [
            {name: 'arr1', about: 'Array'},
            {name: 'arr2', about: 'Array'},
          ],
          example: "intersection(['test'], ['text'])",
        },
      },
    );
    // zjisti, zda promenna/pole je prazdna
    registryService.addFunction(
      'isNullOrEmpty',
      (value: any | Array<any>) => {
        let result = false;
        if (Array.isArray(value)) {
          result = value.filter((x) => !!x).length === 0;
        } else if (value == null || value === '') {
          result = true;
        }
        return result;
      },
      {
        detail: 'isNullOrEmpty(value: any | Array<any>)',
        description: {
          about: 'isNullOrEmpty',
          params: [{name: 'value', about: 'Variable'}],
          example: "isNullOrEmpty('test')",
        },
      },
    );
    registryService.addFunction(
      'firstOrNull',
      (arr: any, index?: number) => {
        let isNull = false;
        if (Array.isArray(arr)) {
          isNull = arr.length === 0;
        } else if (arr == null || arr === '') {
          isNull = true;
        }
        if (isNull) {
          return null;
        }
        return index == null ? arr[0] : arr[index];
      },
      {
        detail: 'firstOrNull(arr: any, index?: number)',
        description: {
          about: 'firstOrNull',
          params: [
            {name: 'arr', about: 'Array'},
            {name: 'index', about: 'Index'},
          ],
          example: "firstOrNull(['test'])",
        },
      },
    );
    registryService.addFunction(
      'hashMap',
      (value: Array<Array<string>>) => {
        const mapObj = value.reduce(
          (map, obj) => ((map[obj[0]] = obj[1]), map),
          {},
        );
        return mapObj;
      },
      {
        detail: 'hashMap(value: Array<Array<string>>)',
        description: {
          about: 'hashMap',
          params: [{name: 'value', about: 'Array of arrays'}],
          example: 'hashMap([["test", "text"]])',
        },
      },
    );
    registryService.addFunction(
      'map',
      (
        val: any[],
        attr: string | ((value: any, index: number, array: any[]) => unknown),
      ) => {
        if (Array.isArray(val)) {
          if (typeof attr === 'function') {
            return val?.map(attr);
          } else {
            return val?.map((x) => objectPath.get(x, attr));
          }
        }
        return [];
      },
      {
        detail: 'map(val: any[], attr: string)',
        description: {
          about: 'map',
          params: [
            {name: 'val', about: 'Array of objects'},
            {name: 'attr', about: 'Field'},
          ],
          example: "map([{a: 1}, {a: 2}], 'a')",
        },
      },
    );
    registryService.addFunction(
      'flatMap',
      (val: any[], attr: string) => val?.flatMap((x) => x?.[attr]),
      {
        detail: 'flatMap(val: any[], attr: string)',
        description: {
          about: 'flatMap',
          params: [
            {name: 'val', about: 'Array of objects'},
            {name: 'attr', about: 'Field'},
          ],
          example: "flatMap([{a: [1]}, {a: [2]}], 'a')",
        },
      },
    );
    registryService.addFunction(
      'mapToObject',
      (val: any[], attrs: string[]) => {
        return val.map((x) => {
          const res = {};
          attrs.forEach((a) => (res[a] = x[a]));
          return res;
        });
      },
      {
        detail: 'mapToObject(val: any[], attrs: string[])',
        description: {
          about: 'mapToObject',
          params: [
            {name: 'val', about: 'Array of objects'},
            {name: 'attrs', about: 'Fields'},
          ],
          example: "mapToObject([{a: 1, b: 2}, {a: 3, b: 4}], ['a', 'b'])",
        },
      },
    );
    registryService.addFunction(
      'mapToArray',
      (val: any[], attrs: string[]) => {
        return val.map((x) => {
          const res = [];
          attrs.forEach((a) => res.push(x[a]));
          return res;
        });
      },
      {
        detail: 'mapToArray(val: any[], attrs: string[])',
        description: {
          about: 'mapToArray',
          params: [
            {name: 'val', about: 'Array of objects'},
            {name: 'attrs', about: 'Fields'},
          ],
          example: "mapToArray([{a: 1, b: 2}, {a: 3, b: 4}], ['a', 'b'])",
        },
      },
    );
    registryService.addFunction(
      'toFixed',
      (val: number, param: number) => {
        if (val != null && typeof val === 'number') {
          return val?.toFixed(param);
        }
        return val;
      },
      {
        detail: 'toFixed(val: number, param: number)',
        description: {
          about: 'toFixed',
          params: [
            {name: 'val', about: 'Number'},
            {name: 'param', about: 'Decimal places'},
          ],
          example: 'toFixed(1.2345, 2)',
        },
      },
    );
    registryService.addFunction(
      'percentToValue',
      (val: string) => parseFloat(val) / 100,
      {
        detail: 'percentToValue(val: string)',
        description: {
          about: 'percentToValue',
          params: [{name: 'val', about: 'Percent'}],
          example: "percentToValue('10%')",
        },
      },
    );
    registryService.addFunction('isNaN', (val: any) => isNaN(val), {
      detail: 'isNaN(val: any)',
      description: {
        about: 'isNaN',
        params: [{name: 'val', about: 'Value'}],
        example: "isNaN('test')",
      },
    });
    registryService.addFunction(
      'dateFormat',
      (val: Date | string, forma: string) => {
        if (val == null) {
          return null;
        }
        if (typeof val === 'string') {
          val = parseISO(val);
        }
        const adjustedFormatStr = forma
          .replace('YYYY', 'yyyy')
          .replace('DD', 'dd');
        return format(val, adjustedFormatStr);
      },
      {
        detail: 'dateFormat(val: Date | string, format: string)',
        description: {
          about: 'dateFormat',
          params: [
            {name: 'val', about: 'Date | string'},
            {name: 'format', about: 'Format'},
          ],
          example: "dateFormat(new Date(), 'YYYY-MM-DD')",
        },
      },
    );
    registryService.addFunction(
      'dateFormatFrom',
      (val: Date | string, fromFormat: string, toFormat: string) => {
        if (val == null) {
          return null;
        }
        if (typeof val === 'string') {
          val = parseISO(val);
        }
        fromFormat = fromFormat.replace('YYYY', 'yyyy').replace('DD', 'dd');
        const fromFormatDate = new Date(fromFormat);
        return format(val, toFormat);
      },
      {
        detail:
          'dateFormatFrom(val: Date | string, fromFormat: string, toFormat: string)',
        description: {
          about: 'dateFormatFrom',
          params: [
            {name: 'val', about: 'Date | string'},
            {name: 'fromFormat', about: 'Format'},
            {name: 'toFormat', about: 'Format'},
          ],
          example: "dateFormatFrom(new Date(), 'YYYY-MM-DD', 'DD-MM-YYYY')",
        },
      },
    );
    registryService.addFunction(
      'dateServerFormat',
      (val: Date, time: string, format: string) =>
        this.convertDate(val, time, format),
      {
        detail: 'dateServerFormat(val: Date, time: string, format: string)',
        deprecated: true,
        description: {
          about: 'dateServerFormat',
          params: [
            {name: 'val', about: 'Date'},
            {name: 'time', about: 'Time'},
            {name: 'format', about: 'Format'},
          ],
          example: "dateServerFormat(new Date(), '12:00', 'YYYY-MM-DD')",
        },
      },
    );
    registryService.addFunction(
      'includes',
      (arr: string[], value: string) => arr?.includes(value) ?? false, // bulletproof pro osliky co delaji undefined.includes('a')
      {
        detail: 'includes(arr: string[], value: string)',
        description: {
          about: 'includes',
          params: [
            {name: 'arr', about: 'Array of strings'},
            {name: 'value', about: 'String'},
          ],
          example: "includes(['test', 'test2'], 'test')",
        },
      },
    );
    registryService.addFunction(
      'includesArray',
      (
        arr: (string | number | boolean)[],
        values: (string | number | boolean)[],
        onlySome = false,
      ) => {
        if (!Array.isArray(arr) || !Array.isArray(values)) {
          return false; // Return false if either argument is not an array
        }
        if (onlySome) {
          return values.some((value) => arr.includes(value));
        }
        return values.every((value) => arr.includes(value)); // Check if every value from values array is in arr
      },
      {
        detail:
          'includesArray(arr: (string | number | boolean)[], values: (string | number | boolean)[], onlySome = false)',
        description: {
          about: 'includesArray',
          params: [
            {
              name: 'arr',
              about: 'Array of primitives (string, number, boolean)',
            },
            {
              name: 'values',
              about:
                'Array of primitives (string, number, boolean) to check against',
            },
            {
              name: 'onlySome',
              about:
                'Checks only if some values are present in array, not every',
            },
          ],
          example: "includesArray(['test', 1, true], ['test', 1]) // true",
        },
      },
    );
    registryService.addFunction(
      'like',
      (arr: string[], value: string) =>
        arr.some((x) => x?.includes(value) ?? false),
      {
        detail: 'like(arr: string[], value: string)',
        description: {
          about: 'like',
          params: [
            {name: 'arr', about: 'Array of strings'},
            {name: 'value', about: 'String'},
          ],
          example: "like(['test', 'test2'], 'test')",
        },
      },
    );
    registryService.addFunction(
      'every',
      (
        arr: any[],
        field: string,
        operator: '!=' | '==' | '===' | '!==' | '>=' | '<=' | '>' | '<',
        val: any,
      ) => {
        const compare = (val1, val2) => {
          switch (operator) {
            case '!=':
              return val1 != val2;
            case '==':
              return val1 == val2;
            case '===':
              return val1 === val2;
            case '!==':
              return val1 !== val2;
            case '>=':
              return val1 >= val2;
            case '<=':
              return val1 <= val2;
            case '>':
              return val1 >= val2;
            case '<':
              return val1 <= val2;
            default:
              console.warn('Unsupported operator');
              return false;
          }
        };
        const result = arr.every((x) => compare(objectPath.get(x, field), val));
        return result;
      },
      {
        detail:
          "every(arr: string[], field: string, operator: '!=' | '==' | '===' | '!==' | '>=' | '<=' | '>' | '<', val: any)",
        description: {
          about: 'every',
          params: [
            {name: 'arr', about: 'Array'},
            {name: 'field', about: 'String'},
            {
              name: 'operator',
              about: "'!=' | '==' | '===' | '!==' | '>=' | '<=' | '>' | '<'",
            },
            {name: 'val', about: 'Value'},
          ],
          example: "every($value.array, 'text', '!=', null)",
        },
      },
    );
    registryService.addFunction(
      'isUuid',
      (uuid: string) => uuidValidate(uuid),
      {
        detail: 'isUuid(uuid: string)',
        description: {
          about: 'isUuid',
          params: [{name: 'uuid', about: 'String'}],
          example: "isUuid('test')",
        },
      },
    );
    registryService.addFunction(
      'getUuid',
      () => getUuid(),
      {
        detail: 'getUuid()',
        description: {
          about: 'getUuid',
          params: [],
          example: 'getUuid()',
        },
      },
      false,
    );
    registryService.addFunction(
      'getFieldByObject',
      (obj: string, field: string) => {
        return objectPath.get(obj, field);
      },
      {
        detail: 'getFieldByObject(obj: string, field: string)',
        description: {
          about: 'getFieldByObject',
          params: [
            {name: 'obj', about: 'Object'},
            {name: 'field', about: 'Field'},
          ],
          example: 'getFieldByObject(\'{"test": "test"}\', \'test\')',
        },
      },
    );
    registryService.addFunction(
      'createObject',
      (field: string | string[], value: any | any[]) => {
        let emptyObj = {};
        if (Array.isArray(field)) {
          field.forEach((f, index) => {
            objectPath.set(emptyObj, f, value[index]);
          });
        } else {
          objectPath.set(emptyObj, field, value);
        }
        return emptyObj;
      },
      {
        detail: 'createObject(field: string | string[], value: any | any[])',
        description: {
          about: 'createObject',
          params: [
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: "createObject('test', 'test')",
        },
      },
    );
    registryService.addFunction(
      'find',
      (arr: any[], field: string, value: any) => {
        if (arr == null) {
          return null;
        }
        return arr.find((x) => objectPath.get(x, field) === value);
      },
      {
        detail: 'find(arr: any[], field: string, value: any)',
        description: {
          about: 'find',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'find([{"test": "test"}], \'test\', \'test\')',
        },
      },
    );
    registryService.addFunction(
      'findAnd',
      (arr: any[], field: string[], value: any[]) => {
        if (arr == null) {
          return null;
        }
        return arr.find((x) =>
          field.every((f, i) => objectPath.get(x, f) === value[i]),
        );
      },
      {
        detail: 'findAnd(arr: any[], field: string[], value: any[])',
        description: {
          about: 'findAnd',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'findAnd([{"test": "test"}], [\'test\'], [\'test\'])',
        },
      },
    );
    registryService.addFunction(
      'findOr',
      (arr: any[], field: string[], value: any[]) => {
        if (arr == null) {
          return null;
        }
        return arr.find((x) =>
          field.some((f, i) => objectPath.get(x, f) === value[i]),
        );
      },
      {
        detail: 'findOr(arr: any[], field: string[], value: any[])',
        description: {
          about: 'findOr',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'findOr([{"test": "test"}], [\'test\'], [\'test\'])',
        },
      },
    );
    registryService.addFunction(
      'some',
      (arr: any[], field: string, value: any, strongly = false) => {
        if (arr == null) {
          return false;
        }
        if (field == null) {
          return arr.some((x) => x === value);
        }
        return arr.some((x) => objectPath.get(x, field) === value);
      },
      {
        detail: 'some(arr: any[], field: string, value: any)',
        description: {
          about: 'some',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'some([{"test": "test"}], \'test\', \'test\')',
        },
      },
    );
    registryService.addFunction(
      'someEmpty',
      (arr: any[], field: string) => {
        if (arr == null) {
          return false;
        }
        if (field == null) {
          return arr.some((x) => x === field);
        }
        return arr.some(
          (x) =>
            objectPath.get(x, field) == null || objectPath.get(x, field) === '',
        );
      },
      {
        detail: 'someEmpty(arr: any[], field: string)',
        description: {
          about: 'someEmpty',
          params: [
            {
              name: 'arr',
              about: 'Array',
            },
            {
              name: 'field',
              about: 'Attribute',
            },
          ],
        },
      },
    );
    registryService.addFunction(
      'someAnd',
      (arr: any[], field: string[], value: any[]) => {
        if (arr == null) {
          return false;
        }
        return arr.some((x) =>
          field.every((f, i) => objectPath.get(x, f) === value[i]),
        );
      },
      {
        detail: 'someAnd(arr: any[], field: string[], value: any[])',
        description: {
          about: 'someAnd',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'someAnd([{"test": "test"}], [\'test\'], [\'test\'])',
        },
      },
    );
    registryService.addFunction(
      'someOr',
      (arr: any[], field: string[], value: any[]) => {
        if (arr == null) {
          return false;
        }
        return arr.some((x) =>
          field.some((f, i) => objectPath.get(x, f) === value[i]),
        );
      },
      {
        detail: 'someOr(arr: any[], field: string[], value: any[])',
        description: {
          about: 'someOr',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'someOr([{"test": "test"}], [\'test\'], [\'test\'])',
        },
      },
    );
    registryService.addFunction(
      'filter',
      (arr: any[], field: string, value: any) => {
        if (arr == null) {
          return [];
        }
        return arr.filter((x) => objectPath.get(x, field) === value);
      },
      {
        detail: 'filter(arr: any[], field: string, value: any)',
        description: {
          about: 'filter',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'filter([{"test": "test"}], \'test\', \'test\')',
        },
      },
    );
    registryService.addFunction(
      'filterAnd',
      (arr: any[], field: string[], value: any[]) => {
        if (arr == null) {
          return [];
        }
        return arr.filter((x) =>
          field.every((f, i) => objectPath.get(x, f) === value[i]),
        );
      },
      {
        detail: 'filterAnd(arr: any[], field: string[], value: any[])',
        description: {
          about: 'filterAnd',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'filterAnd([{"test": "test"}], [\'test\'], [\'test\'])',
        },
      },
    );
    registryService.addFunction(
      'filterOr',
      (arr: any[], field: string[], value: any[]) => {
        if (arr == null) {
          return [];
        }
        return arr.filter((x) =>
          field.some((f, i) => objectPath.get(x, f) === value[i]),
        );
      },
      {
        detail: 'filterOr(arr: any[], field: string[], value: any[])',
        description: {
          about: 'filterOr',
          params: [
            {name: 'arr', about: 'Array of objects'},
            {name: 'field', about: 'Field'},
            {name: 'value', about: 'Value'},
          ],
          example: 'filterOr([{"test": "test"}], [\'test\'], [\'test\'])',
        },
      },
    );
    registryService.addFunction(
      'ceil',
      (val: number) => {
        return typeof val === 'number' ? Math.ceil(val) : val;
      },
      {
        detail: 'ceil(val: number)',
        description: {
          about: 'ceil',
          params: [
            {
              name: 'number',
              about: 'Number to ceil',
            },
          ],
          example: 'ceil(5.4)',
        },
      },
    );
    registryService.addFunction(
      'floor',
      (val: number) => {
        return typeof val === 'number' ? Math.floor(val) : val;
      },
      {
        detail: 'floor(val: number)',
        description: {
          about: 'floor',
          params: [
            {
              name: 'number',
              about: 'Number to floor',
            },
          ],
          example: 'floor(5.6)',
        },
      },
    );
    registryService.addFunction(
      'dateDiff',
      (
        firstDate: Date,
        secondDate: Date,
        unitOfTime?: string,
        precision = false,
      ) => {
        if (firstDate == null || secondDate == null) {
          return 0;
        }

        if (typeof firstDate === 'string') {
          firstDate = parseISO(firstDate);
        }
        if (typeof secondDate === 'string') {
          secondDate = parseISO(secondDate);
        }

        const difference = (unit: string) => {
          switch (unit) {
            case 'years':
              return differenceInYears(firstDate, secondDate);
            case 'months':
              return differenceInMonths(firstDate, secondDate);
            case 'days':
              return differenceInDays(firstDate, secondDate);
            case 'hours':
              return differenceInHours(firstDate, secondDate);
            case 'minutes':
              return differenceInMinutes(firstDate, secondDate);
            case 'seconds':
              return differenceInSeconds(firstDate, secondDate);
            default:
              // Default case could be days or any unit you consider as default
              return differenceInDays(firstDate, secondDate);
          }
        };

        const preciseDifference = (unit: string) => {
          switch (unit) {
            case 'years':
              return (
                differenceInMilliseconds(firstDate, secondDate) /
                (1000 * 60 * 60 * 24 * 365.25)
              );
            case 'months':
              return (
                differenceInMilliseconds(firstDate, secondDate) /
                (1000 * 60 * 60 * 24 * 30)
              );
            case 'days':
              return (
                differenceInMilliseconds(firstDate, secondDate) /
                (1000 * 60 * 60 * 24)
              );
            case 'hours':
              return (
                differenceInMilliseconds(firstDate, secondDate) /
                (1000 * 60 * 60)
              );
            case 'minutes':
              return (
                differenceInMilliseconds(firstDate, secondDate) / (1000 * 60)
              );
            case 'seconds':
              return differenceInMilliseconds(firstDate, secondDate) / 1000;
            default:
              return (
                differenceInMilliseconds(firstDate, secondDate) /
                (1000 * 60 * 60 * 24)
              );
          }
        };

        if (precision) {
          return preciseDifference(unitOfTime || 'days');
        }

        return difference(unitOfTime || 'days');
      },
      {
        detail:
          'dateDiff(firstDate: Date, secondDate: Date, unitOfTime?: moment.unitOfTime.Diff, precision = false)',
        description: {
          about: 'dateDiff',
          params: [
            {name: 'firstDate', about: 'First date'},
            {name: 'secondDate', about: 'Second date'},
            {name: 'unitOfTime', about: 'Unit of time'},
            {name: 'precision', about: 'Precision'},
          ],
          example: "dateDiff(new Date(), new Date(), 'days')",
        },
      },
    );
    registryService.addFunction(
      'roundDateTo',
      (date: Date, unitOfTime: string) => {
        return roundDateTo(date, unitOfTime);
      },
      {
        detail: 'roundDateTo(date: Date, unitOfTime: moment.unitOfTime.Diff)',
        description: {
          about: 'roundDateTo',
          params: [
            {name: 'date', about: 'Date'},
            {name: 'unitOfTime', about: 'Unit of time'},
          ],
          example: "roundDateTo(new Date(), 'days')",
        },
      },
    );
    registryService.addFunction(
      'compareDates',
      (
        firstDate: Date | string,
        secondDate: Date | string,
        operator: string,
        unitOfTime: string,
      ) => {
        if (firstDate == null || secondDate == null || unitOfTime == null) {
          return false;
        }
        const operators = {
          '>': (x, y) => x > y,
          '>=': (x, y) => x >= y,
          '<': (x, y) => x < y,
          '<=': (x, y) => x <= y,
        };

        const first = roundDateTo(firstDate, unitOfTime);
        const second = roundDateTo(secondDate, unitOfTime);

        return operators[operator](first.getTime(), second.getTime());
      },
      {
        detail:
          'compareDates(firstDate: Date, secondDate: Date, operator: string, unitOfTime: moment.unitOfTime.Diff)',
        description: {
          about: 'compareDates',
          params: [
            {name: 'firstDate', about: 'First date'},
            {name: 'secondDate', about: 'Second date'},
            {name: 'operator', about: 'Operator'},
            {name: 'unitOfTime', about: 'Unit of time'},
          ],
          example: "compareDates(new Date(), new Date(), '>', 'days')",
        },
      },
    );
    registryService.addFunction(
      'decimalToHoursMinutesSeconds',
      (decimalHours) => {
        if (decimalHours == null || decimalHours === 0) {
          return null;
        }
        let hours: any = Math.floor(decimalHours);
        let minutes: any = Math.round((decimalHours - hours) * 60);
        if (decimalHours < 10) {
          hours = '0' + hours;
        }
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        return `${hours}:${minutes}`;
      },
      {
        detail: 'decimalToHoursMinutesSeconds(decimalHours: number)',
        description: {
          about: 'decimalToHoursMinutesSeconds',
          params: [{name: 'decimalHours', about: 'Decimal hours'}],
          example: 'decimalToHoursMinutesSeconds(1.5)',
        },
      },
    );
    registryService.addFunction(
      'replacement',
      (value: any, replacements: string[]) => {
        const result = {...value};
        replacements.forEach((repl) => {
          JsonPointer.forEachDeep(result, (v, p) => {
            if (v === '__REPLACEMENT__') {
              JsonPointer.set(result, p, repl);
            }
          });
        });
        return result;
      },
      {
        detail: 'replacement(value: any, replacements: string[])',
        description: {
          about: 'replacement',
          params: [
            {name: 'value', about: 'Value'},
            {name: 'replacements', about: 'Replacements'},
          ],
          example: "replacement({test: '__REPLACEMENT__'}, ['test'])",
        },
      },
    );
    registryService.addFunction(
      'createHashMap',
      (...values) => {
        const hashMap = {};
        values.forEach((value, index) => {
          if ((index + 1) % 2 === 0) {
            hashMap[values[index - 1]] = value;
          } else {
            hashMap[value] = null;
          }
        });
        return hashMap;
      },
      {
        detail: 'createHashMap(...values: any[])',
        description: {
          about: 'createHashMap',
          params: [{name: 'values', about: 'Sequence primitive values'}],
          example: 'createHashMap("a", "b", "c", "d")',
        },
      },
    );
    registryService.addFunction(
      'midnight',
      (days: number = 0) => {
        const date = endOfDay(addDays(new Date(), days));
        return date.toISOString();
      },
      {
        detail: 'midnight(days: number = 0)',
        description: {
          about: 'midnight',
          params: [
            {name: 'days', about: '0 - today, 1 - tomorrow, -1 - yesterday'},
          ],
          example: 'midnight()',
        },
      },
      false,
    );
    registryService.addFunction(
      'isValidEmail',
      (email: string) => {
        if (typeof email === 'string') {
          return ValidationUtils.emailRegex.test(email);
        }
        return email;
      },
      {
        detail: 'isValidEmail(email: string)',
        description: {
          about: 'isValidEmail',
          params: [{name: 'email', about: 'Email'}],
          example: 'isValidEmail("about@me.com")',
        },
      },
    );
    // tuhle funkci idealne nepouzivat nikde prosim
    registryService.addFunction(
      'spread',
      (obj1: any, obj2: any) => {
        // omg, spread jako takovy dosere uplne cely formulare, vraci pokazde novou isntanci, tak jsem to prepsal na tohle
        const result = obj1;
        Object.keys(obj2).forEach((k) => {
          result[k] = obj2[k];
        });
        return result;
      },
      {
        detail: 'spread(obj1, obj2)',
        description: {
          about: 'spread',
          params: [
            {name: 'obj1', about: 'First object'},
            {name: 'obj2', about: 'Second object'},
          ],
          example: 'spread(obj1, obj2)',
        },
      },
    );
    // tuhle funkci idealne nepouzivat nikde prosim
    registryService.addFunction(
      'getLocationOrigin',
      () => window.location.origin,
      {
        detail: 'getLocationOrigin()',
        description: {
          about: 'getLocationOrigin',
          params: [],
          example: 'getLocationOrigin()',
        },
      },
    );

    registryService.addToJexl();
  }
}
