import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {Config} from '@tsm/framework/config';
import {AnsibleRequestModel, AnsibleResultModel} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AnsibleConsoleDataService {
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.networkAutomation;
  }

  runConsole(request: AnsibleRequestModel) {
    return this.apiService.post<AnsibleResultModel, AnsibleResultModel>(
      `${this.BASE_URL}/ansible`,
      request,
    );
  }
}
