import {createAction, props} from '@ngrx/store';
import {NotificationTemplate} from '../models';
import {TsmError} from '@tsm/framework/http';
import {FilterModel} from '@tsm/listing-lib/service';

export const LoadNotificationTemplateById = createAction(
  '[NotificationTemplate] Load data',
  props<{id: string}>(),
);
export const LoadNotificationTemplateByIdSuccess = createAction(
  '[NotificationTemplate] Load data Success',
  props<{
    notificationTemplate: NotificationTemplate;
  }>(),
);
export const LoadNotificationTemplateByIdError = createAction(
  '[NotificationTemplate] Load data ERROR',
  props<{
    id: string;
    error: any;
  }>(),
);

export const UpsertNotificationTemplate = createAction(
  '[NotificationTemplate] Upsert data',
  props<{
    notificationTemplate: NotificationTemplate;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertNotificationTemplateSuccess = createAction(
  '[NotificationTemplate] Upsert data Success',
  props<{
    notificationTemplate: NotificationTemplate;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertNotificationTemplateError = createAction(
  '[NotificationTemplate] Upsert data Error',
  props<{
    error: any;
  }>(),
);

export const DeleteNotificationTemplate = createAction(
  '[NotificationTemplate] Delete data',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteNotificationTemplateSuccess = createAction(
  '[NotificationTemplate] Delete data Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteNotificationTemplateError = createAction(
  '[NotificationTemplate] Delete data Error',
  props<TsmError>(),
);

export const LoadNotificationTemplatesByFilter = createAction(
  '[NotificationTemplate] Load data by filters',
  props<{
    filters: FilterModel[];
  }>(),
);
export const LoadNotificationTemplatesByFilterSuccess = createAction(
  '[NotificationTemplate] Load data by filters Success',
  props<{
    entities: NotificationTemplate[];
  }>(),
);
export const LoadNotificationTemplatesByFilterError = createAction(
  '[NotificationTemplate] Load data by filters ERROR',
  props<{
    error: TsmError;
  }>(),
);

export const LoadNotificationTemplateByCode = createAction(
  '[NotificationTemplate] Load data by code',
  props<{
    code: string;
  }>(),
);
export const LoadNotificationTemplateByCodeSuccess = createAction(
  '[NotificationTemplate] Load data by code Success',
  props<{
    notificationTemplate: NotificationTemplate;
  }>(),
);
export const LoadNotificationTemplateByCodeError = createAction(
  '[NotificationTemplate] Load data by code ERROR',
  props<{
    error: any;
  }>(),
);

export const DiffNotificationTemplate = createAction(
  '[NotificationTemplate] Diff NotificationTemplate',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffNotificationTemplateSuccess = createAction(
  '[NotificationTemplate] Diff NotificationTemplate Success',
  props<{
    notificationTemplates: NotificationTemplate[];
    listingId: string;
  }>(),
);
export const DiffNotificationTemplateError = createAction(
  '[NotificationTemplate] Diff NotificationTemplate Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
