import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectConfigTypeByCode} from '../selectors';
import {LoadConfigTypeByCode} from '../actions';

@Pipe({
  name: 'configTypeCode',
  pure: true,
})
export class ConfigTypeCodePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  setObservable(code: string, field = 'name') {
    this.destroy();
    this._id = code;

    this._obs$ = this.store.pipe(
      select(selectConfigTypeByCode(code)),
      filter((it) => it != null && it.loading == false),
      map((it) => {
        if (!it.data || it.error != null) {
          return code;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectConfigTypeByCode(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadConfigTypeByCode({code: this._id})),
          );
        }
      });
  }
}
