import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/user-group-skill.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserGroupSkillState = createSelector(
  getUserManagementState,
  (state) => state.userGroupSkill,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserGroupSkillState);

export const selectUserGroupSkillByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectUserGroupSkillsByUserId = (userGroupId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.userGroup.id === userGroupId),
  );

export const selectUserGroupSkillsByUserIds = (userGroupIds: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => userGroupIds.includes(r.data.userGroup.id)),
  );

export const selectUserGroupSkillsBySkillIds = (skillIds: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) =>
      skillIds.includes(r.data.entityCatalogSpecificationId),
    ),
  );

export const selectUserGroupSkillById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllUserGroupSkills = selectAll;
