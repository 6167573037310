import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KafkaTopicService {
  private readonly KAFKA_TOPIC = 'kafka-maintenance';
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getAllKafkaTopic(): Observable<Envelope<Array<string>>> {
    return this.apiService.get<Array<string>, Array<string>>(
      `${this.BASE_URL}/${this.KAFKA_TOPIC}/topics`,
    );
  }
}
