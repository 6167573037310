import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {AccessRule} from '../model';

export const LoadAccessRules = createAction('[AccessRule] Load AccessRules');
export const LoadAccessRulesSuccess = createAction(
  '[AccessRule] Load AccessRules Success',
  props<{
    entities: AccessRule[];
  }>(),
);
export const LoadAccessRulesError = createAction(
  '[AccessRule] Load AccessRules Error',
  props<{error: any}>(),
);

export const LoadAccessRuleById = createAction(
  '[AccessRule] Load LoadAccessRuleById',
  props<{
    id: string;
    forcedReload?: boolean;
  }>(),
);
export const LoadAccessRuleByIdSuccess = createAction(
  '[AccessRule] Load LoadAccessRuleById Success',
  props<{
    accessRule: AccessRule;
  }>(),
);
export const LoadAccessRuleByIdError = createAction(
  '[AccessRule] Load LoadAccessRuleById Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const UpsertAccessRule = createAction(
  '[AccessRule] Upsert AccessRule',
  props<{
    accessRule: AccessRule;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertAccessRuleSuccess = createAction(
  '[AccessRule] Upsert AccessRule Success',
  props<{
    accessRule: AccessRule;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertAccessRuleError = createAction(
  '[AccessRule] Upsert AccessRule Error',
  props<TsmError>(),
);

export const DeleteAccessRule = createAction(
  '[AccessRule] Delete AccessRule',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteAccessRuleSuccess = createAction(
  '[AccessRule] Delete AccessRule Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteAccessRuleError = createAction(
  '[AccessRule] Delete AccessRule Error',
  props<TsmError>(),
);

export const DiffAccessRule = createAction(
  '[AccessRule] Diff AccessRule',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffAccessRuleSuccess = createAction(
  '[AccessRule] Diff AccessRule Success',
  props<{
    accessRules: AccessRule[];
    listingId: string;
  }>(),
);
export const DiffAccessRuleError = createAction(
  '[AccessRule] Diff AccessRule Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
