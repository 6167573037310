import {
  ApplicationRef,
  ChangeDetectorRef,
  Compiler,
  ComponentFactory,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  NgModuleFactory,
  NgModuleRef,
  Optional,
  reflectComponentType,
  Type,
  ViewContainerRef,
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  DynamicComponentConfigurationNext,
  DynamicComponentConfigurationOptionsNext,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentService {
  _cache: {
    [selector: string]: {
      type: Type<any>;
      factory: ComponentFactory<any>;
      module: NgModuleRef<any>;
    };
  } = {};

  constructor(
    private injector: Injector,
    @Optional() private compiler: Compiler,
    @Optional() private applicationRef: ApplicationRef,
  ) {}

  private _configMapNext: {
    [alias: string]: DynamicComponentConfigurationNext;
  } = {};

  getWidgetMapper(selector: string) {
    const config = this._configMapNext[selector];
    return config ? config.widgetMapper : undefined;
  }

  addConfigurationNext(options: DynamicComponentConfigurationOptionsNext) {
    Array.from(Object.keys(options)).forEach((o) => {
      if (this._configMapNext[o]) {
        return;
      }
      this._configMapNext = {...this._configMapNext, [o]: options[o]};
    });
  }

  /**
   * Pouziva se u
   * entityCardService - karticky v notifikacich (nevime jestli karticka je jako standalone komponenta nebo je registovana v modulu)
   * tableColumnConfigService - premapovani filtru sloupecku ze selektoru na type (nevime jestli filtr (FullCalendarEventTypeFilterComponent) je jako standalone komponenta nebo je registrovany v modulu)
   * dynamicComponent - pres funkci useRootDynamicComponent$
   * dynamicComponent - pres funkci useDynamicComponent$ a funkci resolveComponent
   * @param selector
   */
  resolveComponentFactoryAndModule(
    selector: string,
  ): Promise<[ComponentFactory<any>, NgModuleRef<any>, Type<any>]> {
    const cached = this._cache[selector];
    const foundConfiguration = this._configMapNext[selector];
    if (!foundConfiguration) {
      return new Promise((_, r) =>
        r('configuration was not found - ' + selector),
      );
    }
    if (foundConfiguration.loadComponent) {
      if (cached) {
        return new Promise<
          [ComponentFactory<any>, NgModuleRef<any>, Type<any>]
        >((resolve, reject) => {
          resolve([cached.factory, cached.module, cached.type]);
        });
      }
      return foundConfiguration.loadComponent().then((type: Type<any>) => {
        this._cache[selector] = {
          factory: {componentType: type} as any,
          module: null,
          type: type,
        };
        return [{componentType: type}, null, type] as any;
      });
    }
    return new Promise<[ComponentFactory<any>, NgModuleRef<any>, Type<any>]>(
      (resolve, reject) => {
        if (cached) {
          resolve([cached.factory, cached.module, cached.type]);
        } else {
          foundConfiguration
            .loadChildren()
            .then(async (x: NgModuleFactory<any>) => {
              let moduleFactory: NgModuleFactory<any>;
              if (x instanceof NgModuleFactory) {
                moduleFactory = x;
              } else {
                moduleFactory = await this.compiler.compileModuleAsync(x);
              }
              const module = moduleFactory.create(this.injector);

              const componentFactoryResolver = module.componentFactoryResolver;

              if (!module.instance.typeMap) {
                console.log(
                  '%cTypeMap property has not been found in loaded Module, have you forgotten to implement hasWidgets interface? Please, see address-management-widgets.module for example!',
                  'background: red; color: yellow; font-size: x-large',
                );
              }
              const factoryClass = module.instance.typeMap[selector];

              if (!factoryClass) {
                throw new Error(`Unrecognized component name: ${selector}`);
              }
              const componentFactory =
                componentFactoryResolver.resolveComponentFactory(factoryClass);
              this._cache[selector] = {
                factory: componentFactory,
                module: module,
                type: factoryClass,
              };
              resolve([componentFactory, module, factoryClass]);
            });
        }
      },
    );
  }

  anyWithSelector(selector: string) {
    return this._configMapNext[selector] !== undefined;
  }

  resolvePipeFactoryAndModule(selector: string) {
    const cached = this._cache[selector];
    const foundConfiguration = this._configMapNext[selector];
    if (!foundConfiguration) {
      return new Promise((_, r) =>
        r('configuration was not found - ' + selector),
      );
    }
    if (foundConfiguration.loadComponent) {
      if (cached) {
        return new Promise<[Type<any>, NgModuleRef<any>]>((resolve, reject) => {
          resolve([cached.type, cached.module]);
        });
      }
      return foundConfiguration.loadComponent().then((type: Type<any>) => {
        this._cache[selector] = {
          factory: null,
          module: null,
          type: type,
        };
        return [type, null] as any;
      });
    }
    return new Promise<[Type<any>, NgModuleRef<any>]>((resolve, reject) => {
      if (cached) {
        resolve([cached.type, cached.module]);
      } else {
        foundConfiguration
          .loadChildren()
          .then(async (x: NgModuleFactory<any>) => {
            let moduleFactory: NgModuleFactory<any>;
            if (x instanceof NgModuleFactory) {
              moduleFactory = x;
            } else {
              moduleFactory = await this.compiler.compileModuleAsync(x);
            }
            const module = moduleFactory.create(this.injector);

            if (!module.instance.typeMap) {
              console.log(
                '%cTypeMap property has not been found in loaded Module, have you forgotten to implement hasWidgets interface? Please, see address-management-widgets.module for example!',
                'background: red; color: yellow; font-size: x-large',
              );
            }
            const type = module.instance.typeMap[selector];
            if (!type) {
              throw new Error(`Unrecognized component name: ${selector}`);
            }
            this._cache[selector] = {
              factory: null,
              module: module,
              type: type,
            };
            resolve([type, module]);
          });
      }
    });
  }

  updateInputs<TComponent>(
    inputs: {[key: string]: any},
    bundle: DynamicComponentBundle<TComponent>,
  ) {
    if (inputs) {
      const keys = Object.keys(inputs);
      let anythingChanged = false;
      keys.forEach((key) => {
        if (bundle.inputNames.includes(key)) {
          // Zajimaji nas jen opravdove inputy
          if (bundle.__inputContainers[key] !== inputs[key]) {
            // a jen pokud je rozdilny
            anythingChanged = true;
            bundle.__inputContainers[key] = inputs[key];
            bundle.componentRef.setInput(key, inputs[key]);
          }
        }
      });

      // aby se volal ngOnChanges pro setInput - jinak se nevola
      if (anythingChanged) {
        bundle.componentChangeDetector.markForCheck();
      }
    }
  }

  resolveStandaloneComponent<TComponent = any>(
    type: Type<TComponent>,
    viewContainerRef: ViewContainerRef,
    inputs: any,
    detectChanges: boolean = false,
  ): Promise<DynamicComponentBundle<TComponent>> {
    return new Promise<DynamicComponentBundle<TComponent>>(
      (resolve, reject) => {
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(type);

        const bundle: DynamicComponentBundle<TComponent> = {
          componentInstance: null,
          __inputContainers: {},
          componentChangeDetector: null,
          setInputs: null,
          setOutputs: null,
          componentRef: null,
          inputNames: [],
        };

        const componentInputNames = reflectComponentType(type).inputs.map(
          (x) => x.templateName,
        );
        bundle.inputNames = componentInputNames;
        bundle.componentRef = componentRef;
        bundle.componentInstance = componentRef.instance;
        bundle.componentChangeDetector =
          componentRef.injector.get(ChangeDetectorRef);
        this.updateInputs(inputs, bundle);
        if (detectChanges) {
          componentRef.changeDetectorRef.detectChanges();
        }
        resolve(bundle);
      },
    );
  }

  /**
   * Pouziva se u
   * dynamicComponent - pres funkci useDynamicComponent$
   * @param selector
   * @param viewContainerRef
   * @param inputs
   * @param detectChanges
   */
  private resolveComponent<TComponent = any>(
    selector: string,
    viewContainerRef: ViewContainerRef,
    inputs: any,
    detectChanges: boolean = false,
  ): Promise<DynamicComponentBundle<TComponent>> {
    return new Promise<DynamicComponentBundle<TComponent>>(
      (resolve, reject) => {
        this.resolveComponentFactoryAndModule(selector)
          .then(([factory, module, type]) => {
            viewContainerRef.clear();
            let componentRef: ComponentRef<any>;
            if (Object.keys(factory)?.length === 1 && !!factory.componentType) {
              // skaredy hack z duvodu toho, ze nevim, zda se jedna o standalone komponentu nebo widget registrovany v modulu
              // ma to spojistos s DynamicComponentService.resolveComponentFactoryAndModule (if (foundConfiguration.loadComponent) {)
              // vyuziva se to napriklad u tsm-register-default-card nebo u definice filtru (FullCalendarEventTypeFilterComponent) do listingu, kde filtr je jako standalone komponenta
              componentRef = viewContainerRef.createComponent(
                factory.componentType,
              );
            } else {
              componentRef = viewContainerRef.createComponent(
                factory,
                0,
                module.injector,
              );
            }

            const bundle: DynamicComponentBundle<TComponent> = {
              componentInstance: null,
              __inputContainers: {},
              componentChangeDetector: null,
              setInputs: null,
              setOutputs: null,
              componentRef: null,
              inputNames: [],
            };

            const componentInputNames = reflectComponentType(type).inputs.map(
              (x) => x.templateName,
            );
            bundle.inputNames = componentInputNames;
            bundle.componentRef = componentRef;
            bundle.componentInstance = componentRef.instance;
            bundle.componentChangeDetector =
              componentRef.injector.get(ChangeDetectorRef);
            this.updateInputs(inputs, bundle);
            if (detectChanges) {
              componentRef.changeDetectorRef.detectChanges();
            }
            resolve(bundle);
          })
          .catch((e) => console.error(e, selector));
      },
    );
  }

  /**
   * Pouziva se u
   * dynamicComponent
   * @param selector
   * @param inputs
   * @param outputs
   * @param detectChanges
   */
  useRootDynamicComponent$<TComponent>(
    selector,
    inputs,
    outputs,
    detectChanges = false,
  ): Observable<DynamicComponentBundle<TComponent>> {
    return new Observable((observer) => {
      let componentRef: ComponentRef<unknown> | undefined;
      const resetOutputs$ = new Subject<void>();
      this.resolveComponentFactoryAndModule(selector).then(
        ([factory, module, type]) => {
          const componentRef = factory.create(module.injector);
          const bundle: DynamicComponentBundle<TComponent> = {
            componentInstance: null,
            __inputContainers: {},
            componentChangeDetector: null,
            setInputs: null,
            setOutputs: null,
            componentRef: null,
            inputNames: [],
          };

          this.applicationRef.detachView(componentRef.hostView);

          const domElem = (componentRef.hostView as EmbeddedViewRef<unknown>)
            .rootNodes[0] as HTMLElement;
          document.body.appendChild(domElem);
          const componentInputNames = reflectComponentType(type).inputs.map(
            (x) => x.templateName,
          );
          bundle.inputNames = componentInputNames;
          bundle.componentRef = componentRef;
          bundle.componentInstance = componentRef.instance;
          bundle.componentChangeDetector =
            componentRef.injector.get(ChangeDetectorRef);
          this.updateInputs(inputs, bundle);
          if (detectChanges) {
            componentRef.changeDetectorRef.detectChanges();
          }

          bundle.setInputs = (inputs) => {
            this.updateInputs(inputs, bundle);
          };

          bundle.setOutputs = (outputs, previousOutputs) => {
            resetOutputs$.next(null);
            if (outputs) {
              Object.keys(outputs).forEach((key) => {
                if (bundle.componentInstance[key]) {
                  bundle.componentInstance[key]
                    .pipe(takeUntil(resetOutputs$))
                    .subscribe((data) => outputs[key](data));
                }
              });
            }
          };

          observer.next(bundle);
        },
      ),
        (rejected) => {
          observer.error(rejected);
        };
      return () => {
        resetOutputs$.next();
        if (componentRef) {
          this.applicationRef.detachView(componentRef.hostView);
          componentRef.destroy();
        }
      };
    });
  }

  /**
   * Pouziva se u
   * dynamicComponent - pokud vstupme je rovnou type(standalone komeponenty)
   * @param type
   * @param hosting
   * @param inputs
   * @param outputs
   * @param detectChanges
   */
  useDynamicStandaloneComponent$<TComponent>(
    type: Type<any>,
    hosting: ViewContainerRef,
    inputs,
    outputs,
    detectChanges = false,
  ): Observable<DynamicComponentBundle<TComponent>> {
    return new Observable((observer) => {
      const resetOutputs$ = new Subject<void>();

      this.resolveStandaloneComponent<TComponent>(
        type,
        hosting,
        inputs,
        detectChanges,
      ).then(
        (bundle) => {
          bundle.setInputs = (inputs) => {
            this.updateInputs(inputs, bundle);
          };

          bundle.setOutputs = (outputs, previousOutputs) => {
            resetOutputs$.next(null);
            if (outputs) {
              Object.keys(outputs).forEach((key) => {
                if (bundle.componentInstance[key]) {
                  bundle.componentInstance[key]
                    .pipe(takeUntil(resetOutputs$))
                    .subscribe((data) => outputs[key](data));
                }
              });
            }
          };

          if (outputs) {
            Object.keys(outputs).forEach((key) => {
              if (bundle.componentInstance[key]) {
                bundle.componentInstance[key]
                  .pipe(takeUntil(resetOutputs$))
                  .subscribe((data) => outputs[key](data));
              }
            });
          }

          observer.next(bundle);
        },
        (rejected) => {
          observer.error(rejected);
        },
      );

      return () => {
        resetOutputs$.next();
        hosting.remove(0);
      };
    });
  }

  /**
   * Pouziva se u
   * dynamicComponent - pokud vstupme je selector a nevim, zda se jedna o standalone komponentu nebo widget registrovany v modulu (vyresim mi to funkce resolveComponent)
   * fluentNodeComponent - pokud vstupme je selector a nevim, zda se jedna o standalone komponentu nebo widget registrovany v modulu (vyresim mi to funkce resolveComponent)
   * fluentNodeRuntimeComponent - pokud vstupme je selector a nevim, zda se jedna o standalone komponentu nebo widget registrovany v modulu (vyresim mi to funkce resolveComponent)
   * @param selector
   * @param hosting
   * @param inputs
   * @param outputs
   * @param detectChanges
   */
  useDynamicComponent$<TComponent>(
    selector,
    hosting: ViewContainerRef,
    inputs,
    outputs,
    detectChanges = false,
  ): Observable<DynamicComponentBundle<TComponent>> {
    return new Observable((observer) => {
      const resetOutputs$ = new Subject<void>();

      this.resolveComponent<TComponent>(
        selector,
        hosting,
        inputs,
        detectChanges,
      ).then(
        (bundle) => {
          bundle.setInputs = (inputs) => {
            this.updateInputs(inputs, bundle);
          };

          bundle.setOutputs = (outputs, previousOutputs) => {
            resetOutputs$.next(null);
            if (outputs) {
              Object.keys(outputs).forEach((key) => {
                if (bundle.componentInstance[key]) {
                  bundle.componentInstance[key]
                    .pipe(takeUntil(resetOutputs$))
                    .subscribe((data) => outputs[key](data));
                }
              });
            }
          };

          if (outputs) {
            Object.keys(outputs).forEach((key) => {
              if (bundle.componentInstance[key]) {
                bundle.componentInstance[key]
                  .pipe(takeUntil(resetOutputs$))
                  .subscribe((data) => outputs[key](data));
              }
            });
          }

          observer.next(bundle);
        },
        (rejected) => {
          observer.error(rejected);
        },
      );

      return () => {
        resetOutputs$.next();
        hosting.remove(0);
      };
    });
  }
}

export interface DynamicComponentBundle<TComponent = any> {
  componentInstance: TComponent;
  componentChangeDetector: ChangeDetectorRef;
  componentRef: ComponentRef<TComponent>;
  setInputs: (inputs: any) => void;
  setOutputs: (outputs, previousOutputs) => void;
  __inputContainers: {[key: string]: any};
  inputNames: string[];
}
