import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/customer/plugins-i18n';
import {type Config} from '@tsm/framework/config';

export const controlsCustomerNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-customer-new',
    name: 'Customer new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'Customer',
    tsmControls: {
      customerName: {
        name: translationShared.shared.title,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      legalForm: {
        name: translationShared.shared.legalForm,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.legalForm,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-legal-form-lov',
        },
      },
      customerSegment: {
        name: translationShared.shared.segment,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.segment,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-segment-lov',
        },
      },
      persIdentNum: {
        name: translationShared.shared.persIdentNum,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.persIdentNum,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      taxIdentNum: {
        name: translationShared.shared.taxIdentNum,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.taxIdentNum,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsCustomerDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-customer-detail',
    name: 'Customer detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Customer',
    tsmControls: {
      'Customer.Header': {
        name: translationShared.shared.header,
        description: translation.customerPlugins.headerDescription,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'Customer.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'Customer.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'Customer.Toolbar.Delete': {
        name: translationShared.shared.remove,
        description: null,
        icon: 'p-icon-clear',
      },
      'Customer.Details.LegalForm': {
        name: translationShared.shared.legalForm,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.legalForm,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-legal-form-lov',
        },
      },
      'Customer.Details.CustomerSegment': {
        name: translationShared.shared.segment,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.segment,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-segment-lov',
        },
      },
      'Customer.Details.IdentificationNumber': {
        name: translationShared.shared.persIdentNum,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.persIdentNum,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Customer.Details.Vatin': {
        name: translationShared.shared.taxIdentNum,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.taxIdentNum,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Customer.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) =>
              config.apiUrls.tsmCustomer + '/customers/dataTags',
          },
        },
      },
      'Customer.Details.Status': {
        name: translationShared.shared.status,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.status,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-status-lov',
        },
      },
      'Customer.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Customer.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Customer.Dates.ValidFrom': {
        name: translationShared.shared.validFrom,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validFrom,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Customer.Dates.ValidTo': {
        name: translationShared.shared.validTo,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validTo,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Customer.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Customer.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Customer.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
    },
  };

// export const controlsLeadNewConfiguration: DtlLayoutControlsConfiguration = {
//   code: 'tsm-lead-new',
//   name: 'Lead new',
//   description: '',
//   useType: 'FORM_NEW',
//   entityType: 'Lead',
//   tsmControls: {
//     partyName: {
//       name: translationShared.shared.partyName,
//       previewComponent: {
//         selector: 'tsm-form-field',
//         inputs: {
//           type: 'text',
//           label: translationShared.shared.partyName,
//           labelPosition: 'top'
//         }
//       }
//     },
//     leadRating: {
//       name: translationShared.shared.rating,
//       previewComponent: {
//         selector: 'tsm-form-field',
//         inputs: {
//           type: 'previewDropdown',
//           label: translationShared.shared.rating,
//           labelPosition: 'top'
//         }
//       }
//     },
//     leadType: {
//       name: translationShared.shared.leadType,
//       previewComponent: {
//         selector: 'tsm-form-field',
//         inputs: {
//           type: 'previewDropdown',
//           label: translationShared.shared.leadType,
//           labelPosition: 'top'
//         }
//       }
//     },
//     leadSource: {
//       name: translationShared.shared.leadSource,
//       previewComponent: {
//         selector: 'tsm-form-field',
//         inputs: {
//           type: 'previewDropdown',
//           label: translationShared.shared.leadSource,
//           labelPosition: 'top'
//         }
//       }
//     },
//     priority: {
//       name: translationShared.shared.priority,
//       previewComponent: {
//         selector: 'tsm-form-field',
//         inputs: {
//           type: 'previewDropdown',
//           label: translationShared.shared.priority,
//           labelPosition: 'top'
//         }
//       }
//     },
//     description: {
//       name: translationShared.shared.description,
//       previewComponent: {
//         selector: 'tsm-form-field',
//         inputs: {
//           type: 'text',
//           label: translationShared.shared.description,
//           labelPosition: 'top'
//         }
//       }
//     }
//   }
// };

export const controlsLeadDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-lead-detail',
  name: 'Lead detail',
  description: '',
  useType: 'FORM_DETAIL',
  entityType: 'Lead',
  tsmControls: {
    'Lead.Header': {
      name: translationShared.shared.header,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.header,
          labelPosition: 'left',
          type: 'previewHeader',
        },
      },
    },
    'Lead.Toolbar.Settings': {
      name: translationShared.shared.settingsButton,
      description: null,
      icon: 'pi pi-ellipsis-v',
    },
    'Lead.Toolbar.Refresh': {
      name: translationShared.shared.refreshButton,
      description: null,
      icon: 'pi pi-sync',
    },
    'Lead.Details.Key': {
      name: translationShared.shared.lead,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.lead,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Lead.Details.LeadSource': {
      name: translationShared.shared.leadSource,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.leadSource,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-lead-source-lov',
      },
    },
    'Lead.Details.PartyName': {
      name: translationShared.shared.partyName,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.partyName,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Lead.Details.LeadRating': {
      name: translationShared.shared.rating,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.rating,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-lead-rating-lov',
      },
    },
    'Lead.Details.Priority': {
      name: translationShared.shared.priority,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.priority,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-lead-priority-lov',
      },
    },
    'Lead.Details.Labels': {
      name: translationShared.shared.labels,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.labels,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'tsm-data-tags',
        inputs: {
          url: (config: Config) =>
            config.apiUrls.tsmCustomer + '/leads/dataTags',
          parentPriv: 'Crm.Lead',
        },
      },
    },
    'Lead.Details.Description': {
      name: translationShared.shared.description,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.description,
          labelPosition: 'left',
          type: 'tiptap',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
    'Lead.Dates.WhenCreated': {
      name: translationShared.shared.whenInserted,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whenInserted,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Lead.Dates.WhenEdited': {
      name: translationShared.shared.whenEdited,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whenEdited,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Lead.People.WhoInserted': {
      name: translationShared.shared.whoInserted,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whoInserted,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Lead.People.WhoEdited': {
      name: translationShared.shared.whoEdited,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whoEdited,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
  },
};

export const controlsPersonNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-person-new',
  name: 'Person new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'Person',
  tsmControls: {
    firstName: {
      name: translationShared.shared.name,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.name,
          labelPosition: 'top',
        },
      },
    },
    lastName: {
      name: translationShared.shared.familyName,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.familyName,
          labelPosition: 'top',
        },
      },
      required: true,
    },
    phones: {
      name: translationShared.shared.mobilePhone,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.mobilePhone,
          labelPosition: 'top',
        },
      },
    },
    emails: {
      name: translationShared.shared.email.label,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.email.label,
          labelPosition: 'top',
        },
      },
    },
    customer: {
      name: translationShared.shared.customer,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.customer,
          labelPosition: 'top',
        },
      },
    },
    lead: {
      name: translationShared.shared.lead,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.lead,
          labelPosition: 'top',
        },
      },
    },
    personType: {
      name: translationShared.shared.type,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.type,
          labelPosition: 'top',
        },
      },
    },
    salutation: {
      name: translationShared.shared.salutation,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.salutation,
          labelPosition: 'top',
        },
      },
    },
    personRoleTypes: {
      name: translationShared.shared.personRole,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.personRole,
          labelPosition: 'top',
        },
      },
    },
    description: {
      name: translationShared.shared.description,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.description,
          labelPosition: 'top',
        },
      },
    },
  },
};

export const controlsPersonDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-person-detail',
    name: 'Person detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Person',
    tsmControls: {
      'Person.Header': {
        name: translationShared.shared.header,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'Person.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'Person.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'Person.Details.FirstName': {
        name: translationShared.shared.name,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.name,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Person.Details.LastName': {
        name: translationShared.shared.familyName,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.familyName,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Person.Details.PersonType': {
        name: translationShared.shared.type,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.type,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-person-type-lov',
        },
      },
      'Person.Details.Salutation': {
        name: translationShared.shared.salutation,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.salutation,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Person.Details.Customer': {
        name: translationShared.shared.customer,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.customer,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'Person.Details.Status': {
        name: translationShared.shared.status,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.status,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-person-status-lov',
        },
      },
      'Person.Details.PersonRoleTypes': {
        name: translationShared.shared.personRole,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.personRole,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-person-role-type-lov',
        },
      },
      'Person.Details.Lead': {
        name: translationShared.shared.lead,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.lead,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-lead-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'Person.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            parentPriv: 'Crm.Person',
            url: (config: Config) =>
              config.apiUrls.tsmCustomer + '/persons/dataTags',
          },
        },
      },
      'Person.Details.UserId': {
        name: translationShared.shared.userId,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.userId,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-user-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'Person.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'Person.Dates.WhenCreated': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Person.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Person.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Person.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
    },
  };

export const controlsAccountNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-account-new',
  name: 'Account new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'Account',
  tsmControls: {
    accountName: {
      name: translationShared.shared.title,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.title,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    customer: {
      name: translationShared.shared.customer,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.customer,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-customer-lov',
        inputs: {
          selectProperty: 'all',
        },
      },
    },
    status: {
      name: translationShared.shared.status,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.status,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-account-status-lov',
      },
    },
    accountStartDate: {
      name: translationShared.shared.validityFrom,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'date',
          label: translationShared.shared.title,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    accountEndDate: {
      name: translationShared.shared.validityTo,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'date',
          label: translationShared.shared.validityTo,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    paymentBehaviour: {
      name: translationShared.shared.paymentBehaviour,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.paymentBehaviour,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    description: {
      name: translationShared.shared.description,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'tiptap',
          label: translationShared.shared.description,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
  },
};

export const controlsAccountDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-account-detail',
    name: 'Account detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Account',
    tsmControls: {
      'Account.Header': {
        name: translationShared.shared.header,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'Account.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'Account.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'Account.Details.Customer': {
        name: translationShared.shared.customer,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.customer,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'Account.Details.Status': {
        name: translationShared.shared.status,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.status,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-account-status-lov',
        },
      },
      'Account.Details.PaymentBehaviour': {
        name: translationShared.shared.paymentBehaviour,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.paymentBehaviour,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Account.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) =>
              config.apiUrls.tsmCustomer + '/accounts/dataTags',
            parentPriv: 'Crm.Account',
          },
        },
      },
      'Account.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'Account.Dates.WhenCreated': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Account.Dates.ValidFrom': {
        name: translationShared.shared.validFrom,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validFrom,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Account.Dates.ValidTo': {
        name: translationShared.shared.validTo,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validTo,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Account.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Account.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'Account.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
    },
  };

export const controlsCampaignNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-campaign-new',
    name: 'Campaign new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'Campaign',
    tsmControls: {
      name: {
        name: translationShared.shared.title,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      code: {
        name: translationShared.shared.code,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.code,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      validFrom: {
        name: translationShared.shared.validFrom,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'datetime',
            label: translationShared.shared.validFrom,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      validTo: {
        name: translationShared.shared.validTo,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'datetime',
            label: translationShared.shared.validTo,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
    },
  };

export const controlsCampaignDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-campaign-detail',
    name: 'Campaign detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Campaign',
    tsmControls: {
      'Campaign.Header': {
        name: translationShared.shared.header,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'Campaign.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'Campaign.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      // 'Campaign.Details.Name': {
      //   name: translationShared.shared.title,
      //   previewComponent: {
      //     selector: 'tsm-form-field',
      //     inputs: {
      //       type: 'text',
      //       label: translationShared.shared.title,
      //       labelPosition: 'left'
      //     }
      //   }
      // },
      // 'Campaign.Details.Code': {
      //   name: translationShared.shared.code,
      //   previewComponent: {
      //     selector: 'tsm-form-field',
      //     inputs: {
      //       type: 'text',
      //       label: translationShared.shared.code,
      //       labelPosition: 'left'
      //     }
      //   }
      // },
      // 'Campaign.Details.Type': {
      //   name: translationShared.shared.type,
      //   description: null,
      //   previewComponent: {
      //     selector: 'tsm-form-field',
      //     inputs: {
      //       label: translationShared.shared.type,
      //       labelPosition: 'left',
      //       type: 'previewDropdown'
      //     }
      //   },
      // },
      'Campaign.Dates.ValidFrom': {
        name: translationShared.shared.validFrom,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'datetime',
            label: translationShared.shared.validFrom,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Campaign.Dates.ValidTo': {
        name: translationShared.shared.validTo,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'datetime',
            label: translationShared.shared.validTo,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Campaign.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'Campaign.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Campaign.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Campaign.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'Campaign.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
    },
  };

export const controlsTypeDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-customer-type-detail',
  name: 'Customer type detail',
  description: '',
  useType: 'CONFIG',
  entityType: 'CustomerType',
  tsmControls: {},
};

export const controlsCampaignTypeDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-campaign-type-detail',
    name: 'Campaign type detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'CampaignType',
    tsmControls: {},
  };

export const controlsPartyCampaignStatusDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-party-campaign-status-detail',
    name: 'Party campaign status detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'PartyCampaignStatus',
    tsmControls: {},
  };

export const controlsCampaignStatusDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-campaign-status-detail',
    name: 'Campaign status detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'CampaignStatus',
    tsmControls: {},
  };

export const controlsTopbarConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-customer.topbar',
  name: 'Customer topbar',
  description: '',
  useType: 'CONFIG',
  entityType: 'Customer',
  tsmControls: {},
};

export const controlsListTopbarConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-customer-list.topbar',
  name: 'Customer list topbar',
  description: '',
  useType: 'CONFIG',
  entityType: 'Customer',
  tsmControls: {},
};

export const controlsCampaignTopbarConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-campaign.topbar',
    name: 'Campaign topbar',
    description: '',
    useType: 'CONFIG',
    entityType: 'Campaign',
    tsmControls: {},
  };
