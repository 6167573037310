import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {RegionAssignment} from '../model';

export const LoadRegionAssignmentsByUserId = createAction(
  '[RegionAssignment] Load RegionAssignments ByUserId',
  props<{
    userId: string;
  }>(),
);
export const LoadRegionAssignmentsByUserIdSuccess = createAction(
  '[RegionAssignment] Load RegionAssignments ByUserId Success',
  props<{
    entities: RegionAssignment[];
  }>(),
);
export const LoadRegionAssignmentsByUserIdError = createAction(
  '[RegionAssignment] Load RegionAssignments ByUserId Error',
  props<{
    error: any;
  }>(),
);

export const UpsertRegionAssignment = createAction(
  '[RegionAssignment] Upsert RegionAssignment',
  props<{
    regionAssignment: RegionAssignment;
  }>(),
);
export const UpsertRegionAssignmentSuccess = createAction(
  '[RegionAssignment] Upsert RegionAssignment Success',
  props<{
    regionAssignment: RegionAssignment;
    listingId?: string;
  }>(),
);
export const UpsertRegionAssignmentError = createAction(
  '[RegionAssignment] Upsert RegionAssignment Error',
  props<TsmError>(),
);

export const PatchRegionAssignment = createAction(
  '[RegionAssignment] Patch RegionAssignment',
  props<{
    regionAssignment: RegionAssignment;
  }>(),
);
export const PatchRegionAssignmentSuccess = createAction(
  '[RegionAssignment] Patch RegionAssignment Success',
  props<{
    regionAssignment: RegionAssignment;
    listingId?: string;
  }>(),
);
export const PatchRegionAssignmentError = createAction(
  '[RegionAssignment] Patch RegionAssignment Error',
  props<TsmError>(),
);

export const SaveAllRegionAssignments = createAction(
  '[RegionAssignment] Save All RegionAssignments',
  props<{
    entities: RegionAssignment[];
    listingId?: string;
  }>(),
);
export const SaveAllRegionAssignmentsSuccess = createAction(
  '[RegionAssignment] Save All RegionAssignments Success',
  props<{
    entities: RegionAssignment[];
    listingId?: string;
  }>(),
);
export const SaveAllRegionAssignmentsError = createAction(
  '[RegionAssignment] Save All RegionAssignments Error',
  props<{
    error: any;
  }>(),
);

export const DeleteRegionAssignment = createAction(
  '[RegionAssignment] Delete RegionAssignment',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteRegionAssignmentSuccess = createAction(
  '[RegionAssignment] Delete RegionAssignment Success',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteRegionAssignmentError = createAction(
  '[RegionAssignment] Delete RegionAssignment Error',
  props<TsmError>(),
);

export const DeleteAllRegionAssignments = createAction(
  '[RegionAssignment] Delete All RegionAssignments',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllRegionAssignmentsSuccess = createAction(
  '[RegionAssignment] Delete All RegionAssignments Success',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllRegionAssignmentsError = createAction(
  '[RegionAssignment] Delete All RegionAssignments Error',
  props<{
    error: any;
  }>(),
);

export const LoadRegionAssignmentsByRegionAssignmentId = createAction(
  '[RegionAssignment] Load RegionAssignments By RegionAssignmentId',
  props<{
    regionAssignmentId: string;
  }>(),
);
export const LoadRegionAssignmentsByRegionAssignmentIdSuccess = createAction(
  '[RegionAssignment] Load RegionAssignments By RegionAssignmentId Success',
  props<{
    entities: RegionAssignment[];
  }>(),
);
export const LoadRegionAssignmentsByRegionAssignmentIdError = createAction(
  '[RegionAssignment] Load RegionAssignments By RegionAssignmentId Error',
  props<{
    error: any;
  }>(),
);

export const LoadRegionAssignmentsByUserIds = createAction(
  '[RegionAssignment] Load RegionAssignments ByUserIds',
  props<{
    userIds: string[];
  }>(),
);
export const LoadRegionAssignmentsByUserIdsSuccess = createAction(
  '[RegionAssignment] Load RegionAssignments ByUserIds Success',
  props<{
    entities: RegionAssignment[];
  }>(),
);
export const LoadRegionAssignmentsByUserIdsError = createAction(
  '[RegionAssignment] Load RegionAssignments ByUserIds Error',
  props<{
    error: any;
  }>(),
);
