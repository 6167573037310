import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {of} from 'rxjs';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/entity-type/plugins-i18n';

import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const entityTypePlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'entity-type',
      loadChildren: () =>
        import('@tsm/entity-type/components').then(
          (x) => x.EntityTypeComponentsModule,
        ),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/entity-type/widgets').then(
          (x) => x.EntityTypeWidgetsModule,
        ),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/entity-type/widgets/filters/entity-type-filter').then(
              (x) => x.EntityTypeFilterComponent,
            ),
          selector: 'tsm-entity-type-filter',
          name: translationPlugin.entityTypePlugins.filters.tsmEntityTypeFilter,
          description:
            translationPlugin.entityTypePlugins.filters
              .tsmEntityTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/entity-type/widgets').then(
          (x) => x.EntityTypeWidgetsModule,
        ),
      definitions: [
        {
          selector: 'entityTypeCode',
          name: translationPlugin.entityTypePlugins.pipes.entityTypeCode,
          description:
            translationPlugin.entityTypePlugins.pipes.entityTypeCodeDescription,
        },
        {
          selector: 'entityType',
          name: translationPlugin.entityTypePlugins.pipes.entityType,
          description:
            translationPlugin.entityTypePlugins.pipes.entityTypeDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/entity-type/widgets').then(
          (x) => x.EntityTypeWidgetsModule,
        ),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/entity-type/widgets/entity-type-lov/entity-type-lov'
            ).then((x) => x.EntityTypeLovComponent),
          selector: 'tsm-entity-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/entity-type/widgets/entity-type-lov/entity-type-lov-editor'
                ).then((x) => x.EntityTypeLovEditorComponent),
              editorSelector: 'tsm-entity-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'entityType',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets.entityTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.entityTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.entityTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-entity-type-lov',
                },
                config: {
                  selectProperty: 'code',
                },
                type: 'string',
              },
            },
          ],
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'EntityType',
      url: (entityId) => {
        return of('/entity-type/entity-type/' + entityId);
      },
    },
  ],
};
