import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope, ServerHttpOptions} from '@tsm/framework/http';
import {Store} from '@ngrx/store';
import {catchError, switchMap, take} from 'rxjs/operators';
import {Params, Router} from '@angular/router';
import {
  JwtRequestHttpModel,
  JwtResponseHttpModel,
  RuntimeInfo,
} from '../models';
import {getRuntimeInfo} from '../reducers';
import {
  getOrganizationId,
  removeOrganizationId,
  removeUserId,
  setOrganizationId,
  setUserId,
} from '@tsm/framework/functions';
import {JwtService, SecurityService} from '@tsm/framework/security';

@Injectable({
  providedIn: 'root',
})
export class RuntimeService {
  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
    private store: Store,
    private router: Router,
    private jwtService: JwtService,
    private securityService: SecurityService,
  ) {}

  // pokud se bude nekdy upravovat, upravit i ve swagger.component
  readonly ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
  readonly REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
  readonly LOGIN_KEY = 'LOGIN';
  readonly WEB_WORKER_LISTING = 'WEB_WORKER_LISTING';

  setAccessToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
  }

  setRefreshToken(token: string) {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, token);
  }

  setClaims(claims: string[]) {
    this.securityService.setClaims(claims);
  }

  getAccessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  getClaims() {
    return this.securityService.getClaims();
  }

  isLoggedIn() {
    const jwtToken = this.getAccessToken();
    if (jwtToken == null) {
      return false;
    }
    try {
      if (this.jwtService.decodeToken(jwtToken) == null) {
        return false;
      }
    } catch {
      return false;
    }
    return !this.jwtService.isTokenExpired(jwtToken);
  }

  tryRefreshToken(): Observable<JwtResponseHttpModel> {
    const refresh_token = this.getRefreshToken();
    const organizationId = getOrganizationId();
    const config = this.config.value;
    const client_id = config.clientId;

    return this.token({
      client_id,
      refresh_token,
      grant_type: 'refresh_token',
      audience: 'auth',
      organizationId: organizationId,
    }).pipe(
      switchMap((response: Envelope<JwtResponseHttpModel>) => {
        if (response.success) {
          this.setAccessToken(response.data.access_token);
          this.setRefreshToken(response.data.refresh_token);
          this.setClaims(response.data.claims);
          return of(response.data);
        } else {
          return throwError(() => response);
        }
      }),
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      }),
    );
  }

  changeOrganization(organizationId: string): Observable<JwtResponseHttpModel> {
    const refresh_token = this.getRefreshToken();
    const config = this.config.value;
    const client_id = config.clientId;

    return this.token({
      client_id,
      refresh_token,
      grant_type: 'refresh_token',
      audience: 'auth',
      organizationId: organizationId,
    }).pipe(
      switchMap((response: Envelope<JwtResponseHttpModel>) => {
        if (response.success) {
          this.setAccessToken(response.data.access_token);
          this.setRefreshToken(response.data.refresh_token);
          this.setClaims(response.data.claims);
          setOrganizationId(response.data.organizationId);
          return of(response.data);
        } else {
          return throwError(() => response);
        }
      }),
      catchError((error) => {
        console.log(error);
        return throwError(() => error);
      }),
    );
  }

  getLogin() {
    return localStorage.getItem(this.LOGIN_KEY);
  }

  setLogin(login: string) {
    localStorage.setItem(this.LOGIN_KEY, login);
  }

  setUserId(userId: string) {
    setUserId(userId);
  }

  signOut(redirect = false) {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    removeOrganizationId();
    this.securityService.removeClaims();
    localStorage.removeItem(this.WEB_WORKER_LISTING);
    localStorage.removeItem(this.LOGIN_KEY);
    removeUserId();

    if (redirect) {
      if (!this.router.url.includes('/account/login')) {
        const cleanUrl = window.location.href.replace(
          window.location.origin,
          '',
        );
        const splitUrlAndQueryParams = cleanUrl.split('?');
        const returnUrl = splitUrlAndQueryParams[0];
        let queryParams: Params = {return_url: returnUrl};
        const queryParamsUrl = splitUrlAndQueryParams[1];
        queryParamsUrl?.split('&')?.forEach((param) => {
          const splitParam = param.split('=');
          queryParams = {
            ...queryParams,
            [splitParam[0]]: splitParam[1],
          };
        });
        this.router.navigate(['/account/login'], {queryParams: queryParams});
      }
    }
  }

  signOutSSO(url: string) {
    this.apiService.get(url).pipe(take(1)).subscribe();
  }

  /**
   * Vrátí aktuální informace, zejména přihlášeného uživatele.
   *
   * Přihlášený uživatel se do store dává před načtením stránky, tedy pokud je přihlášený, je zaručeno, že
   * vrací hodnotu ihned.
   *
   * Upozornení: Jde o immutable hodnotu - jednorázovou, takže se nezmění žádná součást,
   * pokud chcete být notifikování o změnách, použijte observable variantu
   * a metodu [getCurrentUserObservable]
   */
  getCurrent(): RuntimeInfo {
    let runtimeInfo: RuntimeInfo = null;
    this.store
      .select(getRuntimeInfo)
      .pipe(take(1))
      .subscribe((r) => (runtimeInfo = r));
    return runtimeInfo;
  }

  hasRole(roleCode: string | string[]): boolean {
    let runtimeInfo: RuntimeInfo = this.getCurrent();
    if (runtimeInfo == null) {
      return false;
    }
    return runtimeInfo.urList.some((ur) =>
      Array.isArray(roleCode)
        ? roleCode.includes(ur.roleCode)
        : ur.roleCode === roleCode,
    );
  }

  /**
   * Vrátí aktuální informace, zejména přihlášeného uživatele.
   *
   * Přihlášený uživatel se do store dává před načtením stránky, tedy pokud je přihlášený, je zaručeno, že
   * vrací hodnotu ihned.
   */
  getCurrentUserObservable(): Observable<RuntimeInfo> {
    return this.store.select(getRuntimeInfo);
  }

  token(
    data: JwtRequestHttpModel,
    withCredentials = false,
  ): Observable<Envelope<JwtResponseHttpModel>> {
    const url = this.config.value.apiUrls.userManagement + '/token';
    if (withCredentials === true) {
      return this.apiService.post<JwtResponseHttpModel, JwtResponseHttpModel>(
        url,
        data,
        null,
        {withCredentials: true} as ServerHttpOptions,
      );
    }
    return this.apiService.post<JwtResponseHttpModel, JwtResponseHttpModel>(
      url,
      data,
    );
  }

  getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      (window.location.hash != null && window.location.hash !== '' ? '#' : '')
    );
  }
}
