import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, mergeMap, map} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {
  DeleteSharing,
  DeleteSharingError,
  DeleteSharingSuccess,
  LoadSharing,
  LoadSharingError,
  LoadSharingSuccess,
  UpsertSharing,
  UpsertSharingError,
  UpsertSharingSuccess,
} from '../actions';
import {SharingService} from '../services';

@Injectable()
export class SharingEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSharing),
      mergeMap((action) => {
        return this.sharingsService
          .getSharingByOwnerIdAndOwnerType(action.ownerId, action.ownerType)
          .pipe(
            map((att) =>
              att.success
                ? LoadSharingSuccess({
                    sharing: att.data,
                  })
                : LoadSharingError({
                    error: att.error,
                  }),
            ),
          );
      }),
    ),
  );

  deleteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteSharing),
      exhaustMap(({id}) =>
        this.sharingsService.deleteSharing(id).pipe(
          map((env) => {
            if (env.success) {
              this.toast.showToast(
                translation.sharing.delete.success,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteSharingSuccess({id: id});
            } else {
              this.toast.showError(env.error, translation.sharing.delete.error);
              return DeleteSharingError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertSharing),
      concatMap(({sharing}) =>
        this.sharingsService.upsertSharing(sharing).pipe(
          map((env) => {
            if (env.success) {
              this.toast.showToast(
                translation.sharing.save.success,
                ToastSeverity.SUCCESS,
                3000,
              );
              return UpsertSharingSuccess({sharing: env.data});
            } else {
              this.toast.showError(env.error, translation.sharing.save.error);
              return UpsertSharingError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private sharingsService: SharingService,
    private toast: ToastService,
  ) {}
}
