<p-triStateCheckbox
  [(ngModel)]="val"
  [tabindex]="tabindex"
  [disabled]="readonly"
  (onChange)="onTouched(true)"
  [inputId]="labelForId"
  data-cy="dtl-form-input-tri-state-checkbox"
  [tsmTooltip]="triStateCheckboxTooltip"
>
</p-triStateCheckbox>

<ng-template #triStateCheckboxTooltip>
  <!-- Headline -->
  <div
    class="border-blue-600 font-medium text-2xl px-3 py-2 mb-2 text-900 sticky top-0 surface-overlay tsm-tooltip-header"
    style="border-left: 8px solid"
  >
    {{ translation.formModule.triStateCheckbox | transloco }}
  </div>
  <!-- Text Body -->
  <div class="line-height-3 text-700">
    <h6 class="mb-1">
      <p-triStateCheckbox
        [ngModel]="true"
        [readonly]="true"
      ></p-triStateCheckbox>
      {{ translation.formModule.triStateCheckboxTrue | transloco }}
    </h6>

    <h6 class="mb-1">
      <p-triStateCheckbox
        [ngModel]="false"
        [readonly]="true"
      ></p-triStateCheckbox>
      {{ translation.formModule.triStateCheckboxFalse | transloco }}
    </h6>

    <h6 class="mb-1">
      <p-triStateCheckbox
        [ngModel]="null"
        [readonly]="true"
      ></p-triStateCheckbox>
      {{ translation.formModule.triStateCheckboxNull | transloco }}
    </h6>
  </div>
</ng-template>
