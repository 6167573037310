import {
  ChangeDetectorRef,
  Directive,
  Inject,
  Input,
  NgModule,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[scriptLoaded]',
})
export class ScriptLoadedShieldDirective {
  private loaded = false;

  @Input() set scriptLoaded(path: string) {
    this.loadScript(path).subscribe(
      (loaded) => {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.detectChanges();
      },
      (error) => {
        this.viewContainer.clear();
        const div = this.document.createElement('div');

        div.innerText = error;
        this.viewContainer.element.nativeElement.appendChild(div);
      },
    );
  }

  loadScript(path: string): Observable<any> {
    return new Observable((observer) => {
      if (this.loaded) {
        observer.next();
        observer.complete();
        return;
      }
      const found = document.getElementById(path);
      if (found) {
        observer.next();
        observer.complete();
        this.loaded = true;
        return;
      }
      const script = document.createElement('script');
      script.id = path;
      script.src = path;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => {
        observer.next();
        observer.complete();
        this.loaded = true;
      };
      script.onerror = (x) => {
        observer.error(x);
        observer.complete();
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    }).pipe(shareReplay(1));
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
  ) {}
}

@NgModule({
  declarations: [ScriptLoadedShieldDirective],
  exports: [ScriptLoadedShieldDirective],
})
export class ScriptLoadedShieldModule {}
