export const cs = {
  customerPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmAccountFilter: 'tSM Account Filter',
      tsmAccountFilterDescription:
        'Filtruje účty podle klíče nebo názvu s podporou vícenásobného výběru. ' +
        'Dynamicky načítá vybrané účty a umožňuje jejich filtrování ' +
        'prostřednictvím překryvného panelu. Obsahuje klávesové zkratky ' +
        'pro rychlý výběr a odstranění vybraných účtů.',
      tsmAccountStatusFilter: 'tSM Account Status Filter',
      tsmAccountStatusFilterDescription:
        'Filtruje účty podle jejich stavu s podporou vícenásobného výběru. ' +
        'Zobrazuje dostupné stavy v překryvném panelu a umožňuje uživatelům ' +
        'je vybírat nebo odstraňovat. Používá překlady pro dynamické ' +
        'zobrazování popisků a umožňuje hromadný výběr a odebrání stavů.',
      tsmAccountTypeFilter: 'tSM Account Type Filter',
      tsmAccountTypeFilterDescription:
        'Filtruje účty podle jejich typu s podporou vícenásobného výběru. ' +
        'Zobrazuje typy účtů v překryvném panelu a umožňuje uživatelům ' +
        'je vybírat nebo odstraňovat. Používá službu pro načtení dostupných ' +
        'typů účtů a překlady pro dynamické zobrazení popisků.',
      tsmCampaignFilter: 'tSM Campaign Filter',
      tsmCampaignFilterDescription:
        'Filtruje kampaně podle jejich unikátních identifikátorů s podporou vícenásobného ' +
        'výběru. Zobrazuje kampaně v překryvném panelu a umožňuje uživatelům ' +
        'je efektivně vybírat a filtrovat. Používá službu pro načtení dat kampaní ' +
        'a překlady pro dynamické zobrazení popisků.',
      tsmCampaignStatusFilter: 'tSM Campaign Status Filter',
      tsmCampaignStatusFilterDescription:
        'Filtruje stavy kampaní podle jejich unikátních identifikátorů nebo kódů. ' +
        'Podporuje vícenásobný výběr a zobrazuje stavy kampaní ' +
        'v překryvném panelu pro efektivní výběr. Používá službu pro načítání ' +
        'dat o stavech kampaní a dynamicky aplikuje překlady pro popisky.',
      tsmCampaignTypeFilter: 'tSM Campaign Type Filter',
      tsmCampaignTypeFilterDescription:
        'Filtruje typy kampaní podle jejich unikátních identifikátorů nebo kódů. ' +
        'Podporuje vícenásobný výběr a zobrazuje typy kampaní ' +
        'v překryvném panelu pro efektivní výběr. Používá službu pro načítání ' +
        'dat o typech kampaní a dynamicky aplikuje překlady pro popisky.',
      tsmCampaignWaveDefinitionFilter: 'tSM Campaign Wave Definition Filter',
      tsmCampaignWaveDefinitionFilterDescription:
        'Filtruje definice vln kampaní podle jejich unikátních identifikátorů. ' +
        'Podporuje vícenásobný výběr a zobrazuje dostupné možnosti ' +
        'v překryvném panelu pro efektivní výběr. Používá službu pro načítání ' +
        'dat o definicích vln kampaní a dynamicky aplikuje překlady ' +
        'pro popisky a zobrazované hodnoty.',
      tsmCrmAddressRoleTypeFilter: 'tSM Crm Address Role Type Filter',
      tsmCrmAddressRoleTypeFilterDescription:
        'Filtruje role adres CRM pomocí vícenásobného výběru ' +
        'v překryvném panelu. Dynamicky načítá dostupné typy rolí ' +
        'ze služby registru a převádí je na volitelné možnosti. ' +
        'Zobrazuje vybrané hodnoty s jejich přeloženými názvy ' +
        'a umožňuje uživatelům potvrdit nebo zrušit výběr.',
      tsmCustomerFilter: 'tSM Customer Filter',
      tsmCustomerFilterDescription:
        'Filtruje zákazníky pomocí výběrového panelu s podporou více výběrů. ' +
        'Dynamicky načítá data zákazníků a převádí je na volitelné možnosti. ' +
        'Umožňuje filtrování podle klíčů nebo ID zákazníků na základě kontextu. ' +
        'Získává data zákazníků z úložiště a aktualizuje hodnotu filtru ' +
        'vybranými položkami. Poskytuje možnost potvrdit nebo zrušit výběr.',
      tsmCustomerLegalFormFilter: 'tSM Customer Legal Form Filter',
      tsmCustomerLegalFormFilterDescription:
        'Filtruje právní formy zákazníků pomocí výběrového panelu s podporou více výběrů. ' +
        'Dynamicky načítá data právních forem a převádí je na volitelné možnosti. ' +
        'Získává data právních forem z úložiště a aktualizuje hodnotu filtru ' +
        'vybranými položkami. Poskytuje možnost potvrdit nebo zrušit výběr.',
      tsmCustomerSegmentTypeFilter: 'tSM Customer Segment Type Filter',
      tsmCustomerSegmentTypeFilterDescription:
        'Filtruje segmenty zákazníků pomocí výběrového panelu s možností více výběrů. ' +
        'Dynamicky načítá data segmentů a převádí je na volitelné možnosti. ' +
        'Získává segmenty zákazníků z úložiště a aktualizuje hodnotu filtru ' +
        'vybranými položkami. Poskytuje možnost potvrdit nebo zrušit výběr.',
      tsmCustomerStatusFilter: 'tSM Customer Status Filter',
      tsmCustomerStatusFilterDescription:
        'Filtruje stavy zákazníků pomocí výběrového panelu s možností více výběrů. ' +
        'Dynamicky načítá data stavů a zobrazuje je jako volitelné možnosti. ' +
        'Načítá stavy zákazníků a aktualizuje filtr vybranými hodnotami. ' +
        'Poskytuje možnost potvrdit nebo zrušit výběr.',
      tsmCustomerTypeFilter: 'tSM Customer Type Filter',
      tsmCustomerTypeFilterDescription:
        'Filtruje typy zákazníků pomocí výběrového panelu s možností více výběrů. ' +
        'Dynamicky načítá data typů zákazníků a umožňuje filtrování podle kódu nebo názvu. ' +
        'Načítá vybrané typy zákazníků a aktualizuje filtr zvolenými hodnotami. ' +
        'Podporuje potvrzení nebo zrušení výběru.',
      tsmLeadFilter: 'tSM Lead Filter',
      tsmLeadFilterDescription:
        'Filtruje záznamy potenciálních zákazníků pomocí výběrového panelu. ' +
        'Dynamicky načítá záznamy a umožňuje filtrování podle ID nebo názvu subjektu. ' +
        'Načítá vybrané záznamy a aktualizuje filtr zvolenými hodnotami. ' +
        'Podporuje potvrzení nebo zrušení výběru.',
      tsmLeadPriorityFilter: 'tSM Lead Priority Filter',
      tsmLeadPriorityFilterDescription:
        'Filtruje priority potenciálních zákazníků pomocí výběrového panelu. ' +
        'Dynamicky načítá možnosti a umožňuje filtrování podle názvu priority. ' +
        'Načítá vybrané priority a aktualizuje filtr zvolenými hodnotami. ' +
        'Podporuje potvrzení nebo zrušení výběru.',
      tsmLeadSourceFilter: 'tSM Lead Source Filter',
      tsmLeadSourceFilterDescription:
        'Filtruje zdroje potenciálních zákazníků pomocí výběrového panelu. ' +
        'Umožňuje uživatelům dynamicky vybírat zdroje a aktualizuje filtr. ' +
        'Načítá data zdrojů potenciálních zákazníků a formátuje vybrané hodnoty. ' +
        'Podporuje řazení, filtrování a potvrzení nebo zrušení výběru.',
      tsmLeadStatusFilter: 'tSM Lead Status Filter',
      tsmLeadStatusFilterDescription:
        'Filtruje stavy potenciálních zákazníků pomocí výběrového panelu. ' +
        'Umožňuje uživatelům dynamicky vybírat stavy a aktualizuje filtr. ' +
        'Načítá data stavů potenciálních zákazníků a formátuje vybrané hodnoty. ' +
        'Podporuje řazení, filtrování a potvrzení nebo zrušení výběru.',
      tsmLeadTypeFilter: 'tSM Lead Type Filter',
      tsmLeadTypeFilterDescription:
        'Filtruje typy potenciálních zákazníků pomocí výběrového panelu. ' +
        'Umožňuje uživatelům dynamicky vybírat typy a aktualizuje filtr. ' +
        'Načítá data typů potenciálních zákazníků a formátuje vybrané hodnoty. ' +
        'Podporuje řazení, filtrování a potvrzení nebo zrušení výběru.',
      tsmPartyCampaignStatusFilter: 'tSM Party Campaign Status Filter',
      tsmPartyCampaignStatusFilterDescription:
        'Filtruje stavy kampaní strany pomocí výběrového panelu. ' +
        'Umožňuje uživatelům dynamicky vybírat stavy kampaní a aktualizuje filtr. ' +
        'Načítá data stavů kampaní a formátuje vybrané hodnoty. ' +
        'Podporuje řazení, filtrování a potvrzení nebo zrušení výběru.',
      tsmPersonFilter: 'tSM Person Filter',
      tsmPersonFilterDescription:
        'Filtruje osoby pomocí výběrového panelu. ' +
        'Umožňuje uživatelům dynamicky vybírat jednotlivce a aktualizuje filtr. ' +
        'Načítá data osob a formátuje vybrané hodnoty pro zobrazení. ' +
        'Podporuje řazení, filtrování a potvrzení nebo zrušení výběru.',
      tsmPersonStatusFilter: 'tSM Person Status Filter',
      tsmPersonStatusFilterDescription:
        'Filtruje osoby podle stavu pomocí výběrového panelu. ' +
        'Zobrazuje dostupné stavy a umožňuje uživatelům vybrat více možností. ' +
        'Načítá data o stavech osob a formátuje vybrané hodnoty pro zobrazení. ' +
        'Podporuje potvrzení nebo zrušení výběru a aktualizaci filtru.',
      tsmPersonTypeFilter: 'tSM Person Type Filter',
      tsmPersonTypeFilterDescription:
        'Filtruje osoby podle typu pomocí výběrového panelu. ' +
        'Zobrazuje seznam typů osob a umožňuje vícenásobný výběr. ' +
        'Načítá dostupné typy a formátuje vybrané hodnoty pro zobrazení. ' +
        'Podporuje potvrzení nebo zrušení výběru a aktualizaci filtru.',
      tsmRoleInGroupFilter: 'tSM Role In Group Filter',
      tsmRoleInGroupFilterDescription:
        'Filtruje role v rámci skupiny pomocí výběrového panelu. ' +
        'Zobrazuje seznam dostupných rolí a umožňuje vícenásobný výběr. ' +
        'Načítá data rolí a formátuje vybrané hodnoty pro zobrazení. ' +
        'Podporuje potvrzení nebo zrušení výběru a aktualizaci filtru.',
    },
    pipes: {
      customerLoader: 'Konvertor zákazníka',
      customerLoaderDescription:
        'Načítá data zákazníka podle ID z úložiště, získává specifická pole včetně ' +
        'vnořených vlastností použitím tečkové notace, nebo vrací celý objekt, pokud ' +
        'je požadován. Zajišťuje načtení dat, pokud nejsou dostupná.',
      accountLoader: 'Konvertor accountu',
      accountLoaderDescription:
        'Načítá data účtu podle ID z úložiště, získává specifická pole pomocí tečkové notace, ' +
        'nebo vrací celý objekt, pokud je požadován. Zajišťuje načtení dat, pokud nejsou dostupná.',
      accountTypeLoaderCode: 'Account Type Loader Code',
      accountTypeLoaderCodeDescription:
        'Načítá data typu účtu podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      campaignId: 'Konvertor kampaně',
      campaignIdDescription:
        'Načítá data kampaně podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignStatusId: 'Konvertor stavu kampaně',
      campaignStatusIdDescription:
        'Načítá data stavu kampaně podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignCode: 'Konvertor kampaně',
      campaignCodeDescription:
        'Načítá data kampaně podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignStatusCode: 'Konvertor stavu kampaně',
      campaignStatusCodeDescription:
        'Načítá data stavu kampaně podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignTypeId: 'Konvertor typu kampaně',
      campaignTypeIdDescription:
        'Načítá data typu kampaně podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignTypeCode: 'Konvertor typu kampaně',
      campaignTypeCodeDescription:
        'Načítá data typu kampaně podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      partyCampaignStatusId: 'Konvertor stavu kampaně zákazníka',
      partyCampaignStatusIdDescription:
        'Načítá data stavu kampaně strany podle ID z úložiště, získává specifická pole, jako ' +
        'je název nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      partyCampaignStatusCode: 'Konvertor stavu kampaně zákazníka',
      partyCampaignStatusCodeDescription:
        'Načítá data stavu kampaně strany podle kódu z úložiště, získává specifická pole ' +
        'jako je název nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignWaveDefinitionId: 'Konvertor definice vlny kampaně',
      campaignWaveDefinitionIdDescription:
        'Načítá data definice vlny kampaně podle ID z úložiště, získává specifická pole ' +
        'jako je název nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      campaignWaveDefinitionCode: 'Konvertor definice vlny kampaně',
      campaignWaveDefinitionCodeDescription:
        'Načítá data definice vlny kampaně podle kódu z úložiště, získává specifická pole ' +
        'jako je název nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      personObjectLoader: 'Konvertor osoby',
      personObjectLoaderDescription:
        'Načítá data osoby podle ID z úložiště, získává specifická pole, jako je celé jméno ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      crmAddressRoleTypeCodeLoader: 'Konvertor typu role crm adresy',
      crmAddressRoleTypeCodeLoaderDescription:
        'Načítá data typu role adresy v CRM podle kódu z úložiště, získává pole názvu ' +
        'a zajišťuje načtení dat, pokud nejsou dostupná.',
      crmAddressObjectLoader: 'Konvertor crm adres',
      crmAddressObjectLoaderDescription:
        'Načítá data adresy v CRM podle ID z úložiště, získává specifická pole nebo celý objekt ' +
        'a zajišťuje načtení dat, pokud nejsou dostupná.',
      accountLoaderKey: 'Account Loader Key',
      accountLoaderKeyDescription:
        'Načítá a ukládá data účtu podle klíče, umožňuje získat konkrétní pole nebo všechna data ' +
        'a spouští akce, pokud data ještě nejsou uložená v paměti.',
      primaryContactElastic: 'Primary Contact Elastic',
      primaryContactElasticDescription:
        'Vyhledá a vrátí hodnotu primárního kontaktu ze seznamu, nebo null, pokud žádný primární ' +
        'kontakt není nalezen.',
      tsmPersonUsage: 'Tsm Person Usage',
      tsmPersonUsageDescription:
        'Načítá a kombinuje data účtů a objednávek podle ID role osoby, získává ' +
        'zadaná pole a spouští akce pro načtení chybějících dat, pokud je to nutné.',
      personTypeLoaderCode: 'Person Type Loader Code',
      personTypeLoaderCodeDescription:
        'Načítá data typu osoby podle kódu z úložiště, získává specifická pole ' +
        'nebo všechna data a spouští akce pro načtení dat, pokud nejsou dostupná.',
      personTypeLoader: 'Person Type Loader',
      personTypeLoaderDescription:
        'Načítá data typu osoby podle ID z úložiště, získává specifická pole ' +
        'nebo všechna data a spouští akce pro načtení chybějících dat, pokud nejsou dostupná.',
      personRoleTypeLoader: 'Person Role Type Loader',
      personRoleTypeLoaderDescription:
        'Načítá typ role osoby podle ID z úložiště, získává data, jako je kód nebo název ' +
        'a zajišťuje načtení dat, pokud nejsou dostupná.',
      personRoleObjectLoader: 'Person Role Object Loader Description',
      personRoleObjectLoaderDescription:
        'Načítá data role osoby podle ID z úložiště, získává celý objekt a spouští ' +
        'akce pro načtení dat, pokud nejsou dostupná.',
      personRoleByPersonIdLoader: 'Person Role By Person Id Loader',
      personRoleByPersonIdLoaderDescription:
        'Načítá aktivní role osoby podle ID osoby z úložiště, získává jejich data a spouští ' +
        'akce pro načtení rolí, pokud nejsou dostupné nebo chybí v cache.',
      personObjectKeyLoader: 'Person Object Key Loader',
      personObjectKeyLoaderDescription:
        'Načítá data osoby podle unikátního klíče z úložiště, získává specifická pole ' +
        'nebo celý objekt a spouští akce pro načtení dat, pokud nejsou dostupná.',
      leadTypeLoader: 'Lead Type Loader',
      leadTypeLoaderDescription:
        'Načítá data typu lead podle ID z úložiště, získává specifická pole ' +
        'nebo celý objekt a spouští akce pro načtení dat, pokud nejsou dostupná.',
      leadSourceLoader: 'Lead Source Loader',
      leadSourceLoaderDescription:
        'Načítá data zdroje lead podle ID z úložiště, získává specifická pole ' +
        'nebo celý objekt a spouští akce pro načtení dat, pokud nejsou dostupná.',
      leadTypeLoaderCode: 'Lead Type Loader Code',
      leadTypeLoaderCodeDescription:
        'Načítá data typu zájemce podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      leadLoader: 'Lead Loader',
      leadLoaderDescription:
        'Načítá data leadu podle ID z úložiště, získává specifická pole, jako je "název ' +
        'strany", nebo celý objekt a spouští akce pro načtení dat, pokud nejsou dostupná.',
      customerTypeLoader: 'Customer Type Loader',
      customerTypeLoaderDescription:
        'Načítá data typu zákazníka podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      leadLoaderKey: 'Lead Loader Key',
      leadLoaderKeyDescription:
        'Načítá data zájemce podle klíče z úložiště, získává specifická pole, jako je "název strany" ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      customerTypeLoaderCode: 'Customer Type Loader Code',
      customerTypeLoaderCodeDescription:
        'Načítá data typu zákazníka podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      customerSegmentId: 'Customer Segment Id',
      customerSegmentIdDescription:
        'Načítá data segmentu zákazníka podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná',
      customerLoaderKey: 'Customer Loader Key',
      customerLoaderKeyDescription:
        'Načítá data zákazníka podle klíče z úložiště, získává specifická pole včetně vnořených ' +
        'vlastností pomocí tečkové notace, nebo vrací celý objekt. Zajišťuje načtení dat, pokud ' +
        'nejsou dostupná.',
      customerLegalFormId: 'Customer Legal Form Id',
      customerLegalFormIdDescription:
        'Načítá data právního označení zákazníka podle ID z úložiště, získává specifická pole ' +
        'jako je název nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      customerLegalFormCode: 'Customer Legal Form Code',
      customerLegalFormCodeDescription:
        'Načítá data právního označení zákazníka podle kódu z úložiště, získává specifická pole ' +
        'jako je název nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná',
      customerJsonActiveTasks: 'Customer Json Active Tasks',
      customerJsonActiveTasksDescription:
        'Zpracovává a formátuje informace o aktivních úkolech z JSON řetězce, převádí data ' +
        'do čitelného formátu a vrací jedinečné hodnoty jako CSV řetězec.',
      crmAddressRoleObjectLoader: 'CRM Address Role Object Loader',
      crmAddressRoleObjectLoaderDescription:
        'Načítá data role adresy v CRM podle ID z úložiště, získává celý objekt ' +
        'a provádí akce pro načtení dat, pokud nejsou dostupná.',
      tsmCrmAddressRoleUsage: 'Tsm CRM Address Role Usage',
      tsmCrmAddressRoleUsageDescription:
        'Kombinuje a zpracovává data týkající se rolí adres v CRM podle ID, včetně účtů ' +
        'objednávek a dalších objektů. Získává specifická pole nebo všechna data a spouští ' +
        'akce pro načtení chybějících informací, pokud je to nutné.',
      crmAddressRoleTypeLoader: 'CRM Address Role Type Loader',
      crmAddressRoleTypeLoaderDescription:
        'Načítá data typu role adresy v CRM podle ID z úložiště, získává pole name ' +
        'a spouští akce pro načtení dat, pokud nejsou dostupná.',
      accountTypeLoader: 'Account Type Loader',
      accountTypeLoaderDescription:
        'Načítá data typu účtu podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a spouští akce pro načtení dat, pokud nejsou dostupná.',
    },
  },
};
