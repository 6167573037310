import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@tsm/framework/translate';
import * as components from './components';
import * as directives from './directives';
import {localizations} from './i18n';
import {DialogModule} from '@angular/cdk/dialog';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {TooltipModule} from 'primeng/tooltip';

const COMPONENTS = [
  components.TooltipComponent,
  components.TooltipDialogComponent,
  directives.TooltipDirective,
];

const IMPORTS = [
  TranslateModule.forFeature(localizations),
  CommonModule,
  DialogModule,
  CdkDrag,
  CdkDragHandle,
  ButtonModule,
  RippleModule,
  TooltipModule,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...IMPORTS],
  exports: [...COMPONENTS],
})
export class FrameworkTooltipModule {}
