import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import {
  Confirmation,
  ConfirmationService,
  MenuItem,
  PrimeNGConfig,
} from 'primeng/api';
import {DomHandler} from 'primeng/dom';
import {TranslocoService} from '@tsm/framework/translate';

import {v4 as getUuid} from 'uuid';
import {ZIndexUtils} from 'primeng/utils';
import { NgIf, NgClass, NgStyle, NgFor } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink, RouterLinkActive } from '@angular/router';

/**
 * Extension of primeNG MenuItem, with props controlling displaying of confirmation
 * popup for item.command.
 * Confirmation Popup can be fully configured by `item.confirmation object`.
 */
export interface ConfirmationMenuItem extends MenuItem {
  // Controls if confirmation popup should be displayed
  showConfirmation?:
    | boolean
    | ((event: Event, item: ConfirmationMenuItem) => boolean);
  // Overrides default confirmation object for MenuItem
  confirmation?: Confirmation;
}

/**
 * Context menu component, use to show custom menu.
 *
 * Warning: Menu item `showConfirmation` prop works only with `command` prop and disables correct
 * handling for `item.url` links.
 *
 * Usage:
 * ```
 * <dtl-menu
 *    [popup]="true"
 *    [model]="items"
 *    [disabledPreventDocumentDefault]="disabledPreventDocumentDefault"
 *    appendTo="body">
 * </dtl-menu>
 * ```
 * Possible context menu items configurations:
 * ```
 * let items = [
 *  {
 *    label: 'Basic Action',
 *    command: (event) => {...custom code}
 *  },
 *  {
 *    label: 'Action with generic confirmation',
 *    command: (event) => {...custom code},
 *    showConfirmation: true
 *  },
 *  {
 *    label: 'Action with custom confirmation',
 *    command: (event) => {...custom code},
 *    showConfirmation: (event, item) => { return item.hasProperty; },
 *    confirmation: {
 *      icon: 'p-icon-warning p-color-warning',
 *      acceptLabel: 'YES',
 *      rejectLabel: 'NOPE',
 *      message: 'Are you sure?'
 *    }
 *  },
 * ];
 * ```
 */
@Component({
    selector: 'dtl-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [
        trigger('overlayAnimation', [
            state('void', style({
                transform: 'translateY(5%)',
                opacity: 0,
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1,
            })),
            transition('void => visible', animate('{{showTransitionParams}}')),
            transition('visible => void', animate('{{hideTransitionParams}}')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'p-element',
    },
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgStyle,
        NgFor,
        TooltipModule,
        forwardRef(() => MenuItemContent),
        ConfirmPopupModule,
        TranslocoPipe,
    ],
})
export class Menu implements OnDestroy {
  // resi problem se zaviranim menu
  @Input() disabledPreventDocumentDefault = false;

  @Input() header: string;

  @Input() model: MenuItem[] | ConfirmationMenuItem[];
  // zobrazovat jako popup menu
  @Input() popup: boolean;

  @Input() style: any;

  @Input() styleClass: string;

  @Input() appendTo: any;

  @Input() autoZIndex = true;

  @Input() baseZIndex = 0;

  @Input() showTransitionOptions = '225ms ease-out';

  @Input() hideTransitionOptions = '195ms ease-in';

  @ViewChild('container', {static: true}) containerViewChild: ElementRef;

  @Output() onShow: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  container: HTMLDivElement;

  documentClickListener: any;

  documentResizeListener: any;

  preventDocumentDefault: boolean;

  target: any;

  visible: boolean;
  confirmPopupKey = 'menuConfirmation';

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    protected cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    public config: PrimeNGConfig,
  ) {
    // prevent collisions for multple menu instances
    this.confirmPopupKey = getUuid();
  }

  toggle(event) {
    if (this.visible) {
      this.hide();
    } else {
      this.show(event);
    }

    this.preventDocumentDefault = true;
  }

  show(event) {
    this.target = event.currentTarget;
    this.visible = true;
    this.preventDocumentDefault = true;
    this.cdr.markForCheck();
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.onShow.emit({});
          this.appendOverlay();
          DomHandler.absolutePosition(this.container, this.target);
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
        }
        break;

      case 'void':
        this.onOverlayHide();
        this.onHide.emit({});
        break;
    }
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.container);
      } else {
        DomHandler.appendChild(this.container, this.appendTo);
      }
    }
  }

  restoreOverlayAppend() {
    if (this.container && this.appendTo) {
      this.el.nativeElement.appendChild(this.container);
    }
  }

  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set(
        'menu',
        this.container,
        this.baseZIndex + this.config.zIndex.menu,
      );
    }
  }

  hide() {
    this.visible = false;
    this.cdr.markForCheck();
  }

  onWindowResize() {
    this.hide();
  }

  itemClick(event, item: ConfirmationMenuItem) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      // command with confirmation
      const showConfirmation =
        typeof item.showConfirmation === 'function'
          ? item.showConfirmation(event, item)
          : item.showConfirmation;

      if (showConfirmation) {
        this.confirmItemClick(event, item);
        return;
      }

      item.command({
        originalEvent: event,
        item: item,
      });
    }

    if (this.popup) {
      this.hide();
    }
  }

  /**
   * Show confirmation popup. Default configuration is overridden by `item.configuration` object.
   * This disables handling item.url as standard link.
   *
   * @param event
   * @param item
   */
  confirmItemClick(event: Event, item: ConfirmationMenuItem) {
    // stop propagation to prevent closing menu via bindClickListener
    event.preventDefault();
    event.stopPropagation();

    // default confirmation configuration
    const confirmation: Confirmation = {
      key: this.confirmPopupKey,
      target: event.target,
      icon: 'p-icon-warning p-color-warning',
      message: `${this.translocoService.translate(
        'shared.confirmGenericMessage',
      )}`,
      acceptLabel: this.translocoService.translate('shared.yes'),
      rejectLabel: this.translocoService.translate('shared.no'),
      accept: () => {
        item.command({
          originalEvent: event,
          item: item,
        });
        if (this.popup) {
          this.hide();
        }
      },
      reject: () => {
        if (this.popup) {
          this.hide();
        }
      },
    };
    // create confirmation, merge default and item confirmation
    this.confirmationService.confirm({
      ...confirmation,
      ...item.confirmation,
    });
  }

  /**
   * Setup listeners for menu closing on click event.
   */
  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen(
        'document',
        'click',
        () => {
          if (this.disabledPreventDocumentDefault) {
            if (this.preventDocumentDefault) {
              this.hide();
            }
          } else {
            if (!this.preventDocumentDefault) {
              this.hide();
            }
          }

          this.preventDocumentDefault = false;
        },
      );
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.preventDocumentDefault = false;
    this.target = null;
  }

  ngOnDestroy() {
    if (this.popup) {
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
  }

  hasSubMenu(): boolean {
    if (this.model) {
      for (const item of this.model) {
        if (item.items) {
          return true;
        }
      }
    }
    return false;
  }
}

@Component({
    selector: '[pMenuItemContent]',
    template: `
    <div class="p-menuitem-content">
      <a
        *ngIf="!item.routerLink"
        [attr.href]="item.url || null"
        class="p-menuitem-link p-corner-all"
        [attr.data-automationid]="item.automationId"
        [attr.target]="item.target"
        [attr.title]="item.title"
        [attr.id]="item.id"
        [ngClass]="{'p-state-disabled': item.disabled}"
        (click)="menu.itemClick($event, item)"
      >
        <span
          class="p-menuitem-icon"
          *ngIf="item.icon"
          [ngClass]="item.icon"
        ></span>
        <span class="p-menuitem-text">{{ item.label | transloco }}</span>
      </a>
      <a
        *ngIf="item.routerLink"
        [routerLink]="item.routerLink"
        [attr.data-automationid]="item.automationId"
        [queryParams]="item.queryParams"
        [routerLinkActive]="'p-state-active'"
        [routerLinkActiveOptions]="
          item.routerLinkActiveOptions || {exact: false}
        "
        class="p-menuitem-link p-corner-all"
        [attr.target]="item.target"
        [attr.id]="item.id"
        [attr.title]="item.title"
        [ngClass]="{'p-state-disabled': item.disabled}"
        (click)="menu.itemClick($event, item)"
      >
        <span
          class="p-menuitem-icon"
          *ngIf="item.icon"
          [ngClass]="item.icon"
        ></span>
        <span class="p-menuitem-text">{{ item.label | transloco }}</span>
      </a>
    </div>
  `,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        RouterLink,
        RouterLinkActive,
        TranslocoPipe,
    ],
})
export class MenuItemContent {
  @Input('pMenuItemContent') item: MenuItem;

  constructor(@Inject(forwardRef(() => Menu)) public menu: Menu) {}
}
