import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {UserGroupRegion} from '../model';

export const LoadUserGroupRegionsByUserGroupId = createAction(
  '[UserGroupRegion] Load UserGroupRegions ByUserGroupId',
  props<{
    userGroupId: string;
  }>(),
);
export const LoadUserGroupRegionsByUserGroupIdSuccess = createAction(
  '[UserGroupRegion] Load UserGroupRegions ByUserGroupId Success',
  props<{
    entities: UserGroupRegion[];
  }>(),
);
export const LoadUserGroupRegionsByUserGroupIdError = createAction(
  '[UserGroupRegion] Load UserGroupRegions ByUserGroupId Error',
  props<{
    error: any;
  }>(),
);

export const UpsertUserGroupRegion = createAction(
  '[UserGroupRegion] Upsert UserGroupRegion',
  props<{
    userGroupRegion: UserGroupRegion;
  }>(),
);
export const UpsertUserGroupRegionSuccess = createAction(
  '[UserGroupRegion] Upsert UserGroupRegion Success',
  props<{
    userGroupRegion: UserGroupRegion;
    listingId?: string;
  }>(),
);
export const UpsertUserGroupRegionError = createAction(
  '[UserGroupRegion] Upsert UserGroupRegion Error',
  props<TsmError>(),
);

export const PatchUserGroupRegion = createAction(
  '[UserGroupRegion] Patch UserGroupRegion',
  props<{
    userGroupRegion: UserGroupRegion;
  }>(),
);
export const PatchUserGroupRegionSuccess = createAction(
  '[UserGroupRegion] Patch UserGroupRegion Success',
  props<{
    userGroupRegion: UserGroupRegion;
    listingId?: string;
  }>(),
);
export const PatchUserGroupRegionError = createAction(
  '[UserGroupRegion] Patch UserGroupRegion Error',
  props<TsmError>(),
);

export const SaveAllUserGroupRegions = createAction(
  '[UserGroupRegion] Save All UserGroupRegions',
  props<{
    entities: UserGroupRegion[];
    listingId?: string;
  }>(),
);
export const SaveAllUserGroupRegionsSuccess = createAction(
  '[UserGroupRegion] Save All UserGroupRegions Success',
  props<{
    entities: UserGroupRegion[];
    listingId?: string;
  }>(),
);
export const SaveAllUserGroupRegionsError = createAction(
  '[UserGroupRegion] Save All UserGroupRegions Error',
  props<{
    error: any;
  }>(),
);

export const DeleteUserGroupRegion = createAction(
  '[UserGroupRegion] Delete UserGroupRegion',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteUserGroupRegionSuccess = createAction(
  '[UserGroupRegion] Delete UserGroupRegion Success',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteUserGroupRegionError = createAction(
  '[UserGroupRegion] Delete UserGroupRegion Error',
  props<TsmError>(),
);

export const DeleteAllUserGroupRegions = createAction(
  '[UserGroupRegion] Delete All UserGroupRegions',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllUserGroupRegionsSuccess = createAction(
  '[UserGroupRegion] Delete All UserGroupRegions Success',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllUserGroupRegionsError = createAction(
  '[UserGroupRegion] Delete All UserGroupRegions Error',
  props<{
    error: any;
  }>(),
);

export const LoadUserGroupRegionsByUserGroupRegionId = createAction(
  '[UserGroupRegion] Load UserGroupRegions By UserGroupRegionId',
  props<{
    userGroupRegionId: string;
  }>(),
);
export const LoadUserGroupRegionsByUserGroupRegionIdSuccess = createAction(
  '[UserGroupRegion] Load UserGroupRegions By UserGroupRegionId Success',
  props<{
    entities: UserGroupRegion[];
  }>(),
);
export const LoadUserGroupRegionsByUserGroupRegionIdError = createAction(
  '[UserGroupRegion] Load UserGroupRegions By UserGroupRegionId Error',
  props<{
    error: any;
  }>(),
);
