import {Inject, Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {SECURITY_MODULE_OPTIONS} from '../di';
import {SecurityModuleOptions} from '../models';
import {ApiService, Envelope} from '@tsm/framework/http';
import {combineLatest, map, Observable, of, switchMap, take} from 'rxjs';
import {Store} from '@ngrx/store';
import {LoadSecurityData} from '../actions';

@Injectable()
export class SecurityDataService {
  private getRegisterValueUrl = (code: string) =>
    `${this.config.value.apiUrls.tsmFormConfig}/registers/filtering?page=0&size=20&sort=whenInserted,desc&code__eq=${code}`;
  private getUserRegisterUrl = (registerId: string) =>
    `${this.config.value.apiUrls.tsmFormConfig}/registerValues/filtering?page=0&size=200000&sort=order,asc&register.id__eq=${registerId}`;

  constructor(
    private config: ConfigService,
    @Inject(SECURITY_MODULE_OPTIONS)
    private moduleOptions: SecurityModuleOptions,
    private apiService: ApiService,
    private store$: Store,
  ) {}

  getUserRegisters$(): Observable<any> {
    const requests: Observable<any>[] =
      this.moduleOptions.userRegisterCodes.map((code) =>
        this.apiService.get(this.getRegisterValueUrl(code)),
      );
    const userRegister$ = combineLatest(requests).pipe(take(1));
    return userRegister$.pipe(
      map((x: Envelope[]) => x.map((y) => y.data?.content[0]?.id)),
      switchMap((ids: string[]) =>
        combineLatest(
          ids.map((id, i) =>
            id != null
              ? this.apiService.get(this.getUserRegisterUrl(id)).pipe(
                  map((x) => ({
                    id: this.moduleOptions.userRegisterCodes[i],
                    ...x,
                  })),
                )
              : of({id: this.moduleOptions.userRegisterCodes[i]}),
          ),
        ),
      ),
    );
  }

  loadSecurityData() {
    if (this.moduleOptions.userRegisterCodes?.length > 0) {
      this.store$.dispatch(LoadSecurityData());
    }
  }
}
