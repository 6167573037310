import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import type {Config} from '@tsm/framework/config';
import {getUserId} from '@tsm/framework/functions';

export const controlsTicketNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-selfcare-ticket-new',
  name: 'Selfcare Ticket new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'Ticket',
  tsmControls: {
    'Selfcare.Ticket.New.Name': {
      name: translationShared.shared.name,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.name,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Selfcare.Ticket.New.Severity': {
      name: translationShared.shared.severity,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.severity,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-severity-lov',
      },
    },
    'Selfcare.Ticket.New.Priority': {
      name: translationShared.shared.priority,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.priority,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-priority-lov',
      },
    },
    'Selfcare.Ticket.New.Channel': {
      name: translationShared.shared.channel,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.channel,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-channel-lov',
      },
    },
    'Selfcare.Ticket.New.Category': {
      name: translationShared.shared.category,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.category,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-ticket-category-lov',
      },
    },
    'Selfcare.Ticket.New.ExternalId': {
      name: translationShared.shared.externalId,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.externalId,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Selfcare.Ticket.New.CreationGroup': {
      name: translationShared.shared.group,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.group,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-user-user-group-lov',
        inputs: {
          userId: getUserId(),
        },
      },
    },
    'Selfcare.Ticket.New.Description': {
      name: translationShared.shared.description,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'tiptap',
          label: translationShared.shared.description,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
  },
};

export const controlsTicketDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-selfcare-ticket-detail',
    name: 'Selfcare Ticket detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Ticket',
    tsmControls: {
      'Selfcare.Ticket.Detail.Name': {
        name: translationShared.shared.name,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.name,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Ticket.Detail.Key': {
        name: translationShared.shared.key,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.key,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Ticket.Detail.Header': {
        name: translationShared.shared.header,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'Selfcare.Ticket.Detail.State': {
        name: translationShared.shared.state,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.state,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-status-lov',
        },
      },
      'Selfcare.Ticket.Detail.Category': {
        name: translationShared.shared.category,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.category,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-category-lov',
        },
      },
      'Selfcare.Ticket.Detail.Priority': {
        name: translationShared.shared.priority,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.priority,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-priority-lov',
        },
      },
      'Selfcare.Ticket.Detail.Severity': {
        name: translationShared.shared.severity,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.severity,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-severity-lov',
        },
      },
      'Selfcare.Ticket.Detail.Channel': {
        name: translationShared.shared.channel,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.channel,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-channel-lov',
        },
      },
      'Selfcare.Ticket.Detail.ExternalId': {
        name: translationShared.shared.externalId,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.externalId,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Ticket.Detail.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) => config.apiUrls.ticket + '/ticket/dataTags',
            parentPriv: 'Tick.Ticket',
          },
        },
      },
      'Selfcare.Ticket.Detail.Created': {
        name: translationShared.shared.created,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.created,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Selfcare.Ticket.Detail.Closed': {
        name: translationShared.shared.closed,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.closed,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'Selfcare.Ticket.Detail.CreatedBy': {
        name: translationShared.shared.createdBy,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.createdBy,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Ticket.People.ClosedBy': {
        name: translationShared.shared.closedBy,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.closedBy,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Ticket.Detail.Problem': {
        name: translationShared.shared.problem,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.problem,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-problem-lov',
        },
      },
      'Selfcare.Ticket.Detail.Solution': {
        name: 'Resolution',
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.resolution,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-ticket-resolution-lov',
        },
      },
      'Selfcare.Ticket.Detail.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsOrderingNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-selfcare-order-new',
    name: 'Selfcare Order new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'Order',
    tsmControls: {
      'Selfcare.Order.New.CustomerId': {
        name: translationShared.shared.customer,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.customer,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-lov',
        },
      },
      'Selfcare.Order.New.AccountId': {
        name: translationShared.shared.acc,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.acc,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-account-lov',
        },
      },
      'Selfcare.Order.New.EntityInstanceConfiguration': {
        name: translationShared.shared.cancelService,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.cancelService,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-instance-configuration-lov',
          inputs: {
            deleteValueOnCustomerAccountIdChanged: true,
            status: 'ACTIVE',
            actives: ['CURRENT'],
            showEntityInstanceKey: true,
            displayField: ['name', 'entityInstance.key'],
          },
        },
      },
      'Selfcare.Order.New.DeliveryDateRequested': {
        name: translationShared.shared.requiredDeliveryDate,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label: translationShared.shared.requiredDeliveryDate,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Selfcare.Order.New.Subject': {
        name: translationShared.shared.subject,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.subject,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Order.New.Description': {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsOrderingDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-selfcare-order-detail',
    name: 'Selfcare Order detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Order',
    tsmControls: {
      'Selfcare.Order.Detail.Name': {
        name: translationShared.shared.name,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.name,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Order.Detail.Subject': {
        name: translationShared.shared.subject,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.subject,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Order.Detail.Key': {
        name: translationShared.shared.key,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.key,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Order.Detail.Type': {
        name: translationShared.shared.type,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.type,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-order-type-lov',
        },
      },
      'Selfcare.Order.Detail.Account': {
        name: translationShared.shared.account,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.account,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-account-lov',
        },
      },
      'Selfcare.Order.Detail.Quote': {
        name: translationShared.shared.quote,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.quote,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-quote-lov',
          inputs: {
            returnObject: true,
          },
        },
      },
      'Selfcare.Order.Detail.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            parentPriv: 'Ord.Order',
            url: (config: Config) =>
              config.apiUrls.ordering + '/orders/dataTags',
            urlRedirect: '/tsm-ordering/api/ordering/order',
          },
        },
      },
      'Selfcare.Order.Detail.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'Selfcare.Order.Detail.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Selfcare.Order.Detail.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Selfcare.Order.Detail.OrderDate': {
        name: translationShared.shared.orderDate,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.orderDate,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'Selfcare.Order.Detail.DeliveryDateRequested': {
        name: translationShared.shared.deliveryDateRequested,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.deliveryDateRequested,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Selfcare.Order.Detail.DeliveryDate': {
        name: translationShared.shared.deliveredDate,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.deliveredDate,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'Selfcare.Order.Detail.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Selfcare.Order.Detail.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
      },
    },
  };
