import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadSecurityData, LoadSecurityDataSuccess} from '../actions';
import {SecurityDataService} from '../services';
import {map} from 'rxjs';
import {Envelope} from '@tsm/framework/http';
import {exhaustMap} from 'rxjs/operators';

@Injectable()
export class SecurityDataEffects {
  loadSecurityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSecurityData),
      exhaustMap(() =>
        this.securityDataService.getUserRegisters$().pipe(
          map((data: Envelope[]) => {
            return LoadSecurityDataSuccess({
              data: data.filter((x) => x.success),
            });
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private securityDataService: SecurityDataService,
  ) {}
}
