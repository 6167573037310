import {inject, ModuleWithProviders, NgModule} from '@angular/core';

import {
  DefaultTranspiler,
  TRANSLOCO_CONFIG,
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_TRANSPILER,
  TranslocoConfig,
  TranslocoModule,
  TranslocoService,
  DefaultMissingHandler,
  TRANSLOCO_INTERCEPTOR,
  DefaultInterceptor,
  TRANSLOCO_FALLBACK_STRATEGY,
  DefaultFallbackStrategy,
} from '@jsverse/transloco';
import {LOCALIZATIONS, ROOT_LOCALIZATIONS} from './di/tokens';
import {Localization} from './models';

export const initLocalizations = () => {
  const translocoService = inject(TranslocoService);
  const localizations = inject(LOCALIZATIONS);
  const rootLocalizations = inject(ROOT_LOCALIZATIONS);

  localizations
    .filter((x) => !x.added)
    .forEach((x) => {
      x.added = true;

      x.localizations.forEach((localization) => {
        translocoService.setTranslation(
          localization.value,
          localization.language,
          {merge: true, emitChange: false},
        );
      });
    });

  ([] as Localization[]).concat
    .apply([], rootLocalizations)
    .forEach((localization) => {
      translocoService.setTranslation(
        localization.value,
        localization.language,
        {merge: true, emitChange: false},
      );
    });
};

export function provideLocalization(localizations: Localization[]) {
  return [
    {
      provide: LOCALIZATIONS,
      useValue: {
        added: false,
        localizations: localizations,
      },
      multi: true,
    },
  ];
}

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule],
})
export class TranslateModule {
  constructor() {
    initLocalizations();
  }

  public static forFeature(
    localizations: Localization[],
  ): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [
        {
          provide: LOCALIZATIONS,
          useValue: {
            added: false,
            localizations: localizations,
          },
          multi: true,
        },
      ],
    };
  }

  public static forRoot(options: {
    localizations: Localization[];
    translocoConfig: TranslocoConfig;
  }): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [
        {
          provide: ROOT_LOCALIZATIONS,
          useValue: options.localizations,
          multi: true,
        },
        {
          provide: TRANSLOCO_CONFIG,
          useValue: {
            availableLangs: ['cs'],
            defaultLang: navigator.language === 'cs' ? 'cs' : 'en',
            prodMode: false,
            reRenderOnLangChange: false,
            ...options.translocoConfig,
          },
        },
        {
          provide: TRANSLOCO_CONFIG,
          useValue: options.translocoConfig,
        },
        {
          provide: TRANSLOCO_TRANSPILER,
          useClass: DefaultTranspiler,
        },
        {
          provide: TRANSLOCO_MISSING_HANDLER,
          useClass: DefaultMissingHandler,
        },
        {
          provide: TRANSLOCO_INTERCEPTOR,
          useClass: DefaultInterceptor,
        },
        {
          provide: TRANSLOCO_FALLBACK_STRATEGY,
          useClass: DefaultFallbackStrategy,
        },
      ],
    };
  }
}
