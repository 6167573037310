import {createFeatureSelector, createSelector} from '@ngrx/store';
import {userGroupTypeAdapter} from '../reducers/user-group-type.reducer';
import {UserManagementState} from '../reducers';

const getCrmState =
  createFeatureSelector<UserManagementState>('userManagement');
export const pathToEntities = createSelector(
  getCrmState,
  (x) => x.userGroupType,
);

const {selectAll, selectEntities} =
  userGroupTypeAdapter.getSelectors(pathToEntities);

export const selectUserGroupTypeById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectUserGroupTypeByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x?.data?.code === code),
  );

export const selectUserGroupTypeByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );
