import * as fromListingReducer from './listings.reducer';
import * as fromTableColumnConfigReducer from './table-column-config.reducer';
import * as fromListingProfileReducer from './listing-profile.reducer';
import * as fromListingTypeReducer from './listing-type.reducer';
import * as fromValueConverterConfigReducer from './value-converter-config.reducer';
import {Action, combineReducers, MetaReducer} from '@ngrx/store';
import {Table} from '../models';
import {EntityState} from '@ngrx/entity';

export * from './listings.reducer';

export interface ListingContainerState {
  listing: EntityState<Table>;
  columnConfig: fromTableColumnConfigReducer.TableColumnConfigState;
  listingProfile: fromListingProfileReducer.ListingProfileModelState;
  valueConverterConfig: fromValueConverterConfigReducer.ValueConverterConfigState;
  listingType: fromListingTypeReducer.ListingTypeState;
}

export function reducers(
  state: ListingContainerState | undefined,
  action: Action,
) {
  return combineReducers({
    listing: fromListingReducer.reducer,
    listingProfile: fromListingProfileReducer.reducer,
    listingType: fromListingTypeReducer.listingTypeReducer,
    valueConverterConfig:
      fromValueConverterConfigReducer.valueConverterConfigReducer,
    columnConfig: fromTableColumnConfigReducer.reducer,
  })(state, action);
}

export const metaReducers: MetaReducer<ListingContainerState>[] = [];
