import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteAllRegionAssignments,
  DeleteAllRegionAssignmentsError,
  DeleteAllRegionAssignmentsSuccess,
  DeleteRegionAssignment,
  DeleteRegionAssignmentError,
  DeleteRegionAssignmentSuccess,
  LoadRegionAssignmentsByRegionAssignmentId,
  LoadRegionAssignmentsByRegionAssignmentIdError,
  LoadRegionAssignmentsByRegionAssignmentIdSuccess,
  LoadRegionAssignmentsByUserId,
  LoadRegionAssignmentsByUserIdError,
  LoadRegionAssignmentsByUserIds,
  LoadRegionAssignmentsByUserIdsError,
  LoadRegionAssignmentsByUserIdsSuccess,
  LoadRegionAssignmentsByUserIdSuccess,
  PatchRegionAssignment,
  PatchRegionAssignmentError,
  PatchRegionAssignmentSuccess,
  SaveAllRegionAssignments,
  SaveAllRegionAssignmentsError,
  SaveAllRegionAssignmentsSuccess,
  UpsertRegionAssignment,
  UpsertRegionAssignmentError,
  UpsertRegionAssignmentSuccess,
} from '../actions';
import {EntityIdentif, RegionAssignment} from '../model';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {translation} from '../i18n';

@Injectable()
export class RegionAssignmentEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      RegionAssignment,
      RegionAssignment
    >,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegionAssignmentsByUserId),
      exhaustMap(({userId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.REGION_ASSIGNMENT,
            'user.id__eq=' + userId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadRegionAssignmentsByUserIdSuccess({entities: env.data})
                : LoadRegionAssignmentsByUserIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByUserIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegionAssignmentsByUserIds),
      exhaustMap(({userIds}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.REGION_ASSIGNMENT,
            'user.id__in=' + userIds,
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadRegionAssignmentsByUserIdsSuccess({entities: env.data})
                : LoadRegionAssignmentsByUserIdsError({error: env.error}),
            ),
          );
      }),
    ),
  );

  loadByRegionAssignmentId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegionAssignmentsByRegionAssignmentId),
      exhaustMap(({regionAssignmentId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.REGION_ASSIGNMENT,
            'entitySpecificationId__eq=' + regionAssignmentId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadRegionAssignmentsByRegionAssignmentIdSuccess({
                    entities: env.data,
                  })
                : LoadRegionAssignmentsByRegionAssignmentIdError({
                    error: env.error,
                  });
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRegionAssignment),
      concatMap(({regionAssignment}) =>
        this.commonApiService
          .upsertEntity(
            EntityIdentif.REGION_ASSIGNMENT,
            regionAssignment,
            regionAssignment.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.regionSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertRegionAssignmentSuccess({
                  regionAssignment: env.data,
                });
              } else {
                const message =
                  env.error.serverError === 'PrintableError'
                    ? translation.userManagementService.effects
                        .regionAlreadyAssigned
                    : translation.userManagementService.effects.regionSaveError;
                this.ts.showError(env.error, message);
                return UpsertRegionAssignmentError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatchRegionAssignment),
      concatMap(({regionAssignment}) =>
        this.commonApiService
          .patchEntity(
            EntityIdentif.REGION_ASSIGNMENT,
            regionAssignment.id,
            regionAssignment,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.regionSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return PatchRegionAssignmentSuccess({
                  regionAssignment: env.data,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.regionSaveError,
                );
                return PatchRegionAssignmentError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAllRegionAssignments),
      concatMap(({entities, listingId}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.REGION_ASSIGNMENT, entities)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.regionAssignSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return SaveAllRegionAssignmentsSuccess({
                  entities: env.data,
                  listingId: listingId,
                });
              } else {
                const message =
                  env.error.serverError === 'PrintableError'
                    ? translation.userManagementService.effects
                        .regionAlreadyAssigned
                    : translation.userManagementService.effects
                        .regionAssignError;
                this.ts.showError(env.error, message);
                return SaveAllRegionAssignmentsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRegionAssignment),
      concatMap(({id}) =>
        this.commonApiService
          .deleteEntity(EntityIdentif.REGION_ASSIGNMENT, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.regionAssignSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteRegionAssignmentSuccess({id: id});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.regionDeleteFailure,
                );
                return DeleteRegionAssignmentError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllRegionAssignments),
      concatMap(({ids, listingId}) =>
        this.commonApiService
          .deleteAllEntities(EntityIdentif.REGION_ASSIGNMENT, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.roleRemoveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteAllRegionAssignmentsSuccess({
                  ids: ids,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.roleRemoveFailure,
                );
                return DeleteAllRegionAssignmentsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeleteRegionAssignmentSuccess,
        UpsertRegionAssignmentSuccess,
        SaveAllRegionAssignmentsSuccess,
        DeleteAllRegionAssignmentsSuccess,
        PatchRegionAssignmentSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
