import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trimTextPipe',
})
export class TrimTextPipe implements PipeTransform {
  transform(value: any, limit = 256): string {
    if (typeof value === 'string') {
      return value.substring(0, limit) + '...';
    }
    return value;
  }
}
