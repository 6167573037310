import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/stock/plugins-i18n';
import type {Config} from '@tsm/framework/config';

export const controlsStockNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-stock-new',
  name: 'Stock new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'Stock',
  tsmControls: {
    name: {
      name: translationShared.shared.title,
      required: true,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.title,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    ownerUserId: {
      name: translationShared.shared.owner,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'previewDropdown',
          label: translationShared.shared.owner,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'tsm-user-lov',
      },
    },
    def: {
      name: translationShared.shared.default,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'checkbox',
          label: translationShared.shared.default,
          labelPosition: 'left',
        },
      },
    },
    status: {
      name: translationShared.shared.status,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.status,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-stock-status-lov',
      },
    },
    description: {
      name: translationShared.shared.description,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'tiptap',
          label: translationShared.shared.description,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
  },
};

export const controlsStockDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-stock-detail',
    name: 'Stock detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'Stock',
    tsmControls: {
      'Stock.Header': {
        name: translationShared.shared.header,
        description: translationShared.shared.header,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'Stock.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'Stock.Toolbar.Action': {
        name: translationShared.shared.action,
        description: null,
        icon: 'tsm-icon-move',
      },
      'Stock.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'Stock.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) =>
              config.apiUrls.tsmStock + '/stocks/dataTags',
          },
        },
      },
      'Stock.Details.OwnerUserId': {
        name: translationShared.shared.owner,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.owner,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-user-lov',
        },
      },
      'Stock.Details.Def': {
        name: translationShared.shared.default,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'checkbox',
            label: translationShared.shared.default,
            labelPosition: 'left',
          },
        },
      },
      'Stock.Details.Status': {
        name: translationShared.shared.status,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.status,
            labelPosition: 'left',
            type: 'previewDropdown',
          },
        },
        defaultComponent: {
          selector: 'tsm-stock-status-lov',
        },
      },
      'Stock.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Stock.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'Stock.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Stock.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Stock.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'Stock.Tab.StockItemViews': {
        name: translation.stockPlugins.stockItemViews,
        description: null,
      },
      'Stock.Tab.StockSharedResponsibilities': {
        name: translation.stockPlugins.stockSharedResponsibilities,
        description: null,
      },
    },
  };

export const controlsStockItemNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-stock-item-new',
    name: 'Stock item new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'StockItem',
    tsmControls: {
      stock: {
        name: translationShared.shared.stock,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.stock,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-stock-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
        required: true,
      },
      serialNumber: {
        name: translationShared.shared.serialNumber,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.serialNumber,
            labelPosition: 'top',
          },
        },
      },
      entCatSpecRef: {
        name: translation.stockPlugins.material,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translation.stockPlugins.material,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-specification-lov',
        },
        required: true,
      },
      batchNo: {
        name: translationShared.shared.batchNo,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.batchNo,
            labelPosition: 'top',
          },
        },
      },
      amount: {
        name: translationShared.shared.amount,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.amount,
            labelPosition: 'top',
          },
        },
        required: true,
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
      },
    },
  };

export const controlsStockItemDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-stock-item-detail',
    name: 'Stock item detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'StockItem',
    tsmControls: {
      'StockItem.Header': {
        name: translationShared.shared.header,
        description: translationShared.shared.header,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'StockItem.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'StockItem.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'StockItem.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) =>
              config.apiUrls.tsmStock + '/stocks/dataTags',
          },
        },
      },
      'StockItem.Details.Stock': {
        name: translationShared.shared.stock,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.stock,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-stock-lov',
        },
      },
      'StockItem.Details.SerialNumber': {
        name: translationShared.shared.serialNumber,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.serialNumber,
            labelPosition: 'left',
          },
        },
      },
      'StockItem.Details.EntCatSpecRef': {
        name: translation.stockPlugins.material,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.stockPlugins.material,
            labelPosition: 'left',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-specification-lov',
        },
      },
      'StockItem.Details.BatchNo': {
        name: translationShared.shared.batchNo,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.batchNo,
            labelPosition: 'left',
          },
        },
      },
      'StockItem.Details.Amount': {
        name: translationShared.shared.amount,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label: translationShared.shared.amount,
            labelPosition: 'left',
          },
        },
      },
      'StockItem.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'StockItem.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'StockItem.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'StockItem.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'StockItem.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
    },
  };
