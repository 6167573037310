import {createReducer, on} from '@ngrx/store';
import {LoadSecurityDataSuccess} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export type SecurityDataState = EntityState<IdentifiedPack<any>>;

export const adapter: EntityAdapter<IdentifiedPack<any>> = createEntityAdapter<
  IdentifiedPack<any>
>({});

export const initialState: SecurityDataState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadSecurityDataSuccess, (state, {data}) => ({
    ...adapter.addMany(
      data.map((item) => ({
        id: item.id,
        data: item.data?.content,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),
);
