import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {RegisterValue} from '../models';
import {FilterModel} from '@tsm/listing-lib/service';
import {ScriptData} from '@tsm/framework/fluent-forms';
import {createActionWithMetadata} from '@tsm/framework/root/actions-metadata';
import {translation as translationShared} from '@tsm/shared-i18n';

export const LoadRegisterValueById = createActionWithMetadata(
  '[RegisterValue] LoadById RegisterValue',
  {
    title: translationShared.shared.actionsMetadata.loadByIdRegisterValue,
    props: {
      id: translationShared.shared.id,
    },
  },
  props<{id: string}>(),
);
export const LoadRegisterValueByIdSuccess = createAction(
  '[RegisterValue] LoadById RegisterValue Success',
  props<{
    registerValue: RegisterValue;
  }>(),
);
export const LoadRegisterValueByIdError = createAction(
  '[RegisterValue] LoadById RegisterValue Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadBatchRegisterValueByIds = createAction(
  '[RegisterValue] LoadBatchRegisterValueByIds',
  props<{
    ids: string[];
  }>(),
);
export const LoadBatchRegisterValueByIdsSuccess = createAction(
  '[RegisterValue] LoadBatchRegisterValueByIds Success',
  props<{
    entities: RegisterValue[];
  }>(),
);
export const LoadBatchRegisterValueByIdsError = createAction(
  '[RegisterValue] LoadBatchRegisterValueByIds Error',
  props<{
    ids: string[];
    error: TsmError;
  }>(),
);

export const LoadBatchRegisterValueByRegisterCodeAndCode =
  createActionWithMetadata(
    '[RegisterValue] LoadBatchByRegisterVaRegisterCodeAndCode',
    {
      title: translationShared.shared.actionsMetadata.loadByCodeRegisterValue,
      props: {
        registerCode: translationShared.shared.registerCode,
        code: translationShared.shared.code,
      },
    },
    props<{
      registerCode: string;
      code: string;
    }>(),
  );
export const LoadBatchRegisterValueByRegisterCodeAndCodeSuccess = createAction(
  '[RegisterValue] LoadBatchByRegisterVaRegisterCodeAndCode Success',
  props<{
    entities: RegisterValue[];
  }>(),
);
export const LoadBatchRegisterValueByRegisterCodeAndCodeError = createAction(
  '[RegisterValue] LoadBatchByRegisterVaRegisterCodeAndCode Error',
  props<{
    entities: {registerCode: string; value: string}[];
    error: TsmError;
  }>(),
);

export const LoadBatchRegisterValueByRegisterCodeAndName = createAction(
  '[RegisterValue] LoadBatchByRegisterVaRegisterCodeAndName',
  props<{
    registerCode: string;
    name: string;
  }>(),
);
export const LoadBatchRegisterValueByRegisterCodeAndNameSuccess = createAction(
  '[RegisterValue] LoadBatchByRegisterVaRegisterCodeAndName Success',
  props<{
    entities: RegisterValue[];
  }>(),
);
export const LoadBatchRegisterValueByRegisterCodeAndNameError = createAction(
  '[RegisterValue] LoadBatchByRegisterVaRegisterCodeAndName Error',
  props<{
    entities: {registerCode: string; value: string}[];
    error: TsmError;
  }>(),
);

export const UpsertRegisterValue = createAction(
  '[RegisterValue] Upsert RegisterValue',
  props<{
    registerValue: RegisterValue;
    listingId: string;
    scriptData?: ScriptData;
  }>(),
);
export const UpsertRegisterValueSuccess = createAction(
  '[RegisterValue] Upsert RegisterValue Success',
  props<{
    registerValue: RegisterValue;
    listingId: string;
    scriptData?: ScriptData;
  }>(),
);
export const UpsertRegisterValueError = createAction(
  '[RegisterValue] Upsert RegisterValue Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const DeleteRegisterValue = createAction(
  '[RegisterValue] Delete RegisterValue',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteRegisterValueSuccess = createAction(
  '[RegisterValue] Delete RegisterValue Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteRegisterValueError = createAction(
  '[RegisterValue] Delete RegisterValue Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const DeleteRegisterValues = createAction(
  '[RegisterValue] Delete RegisterValues',
  props<{
    ids: string[];
    listingId: string;
    registerId?: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteRegisterValuesSuccess = createAction(
  '[RegisterValue] Delete RegisterValues Success',
  props<{
    ids: string[];
    listingId: string;
  }>(),
);
export const DeleteRegisterValuesError = createAction(
  '[RegisterValue] Delete RegisterValues Error',
  props<{
    ids: string[];
    error: TsmError;
  }>(),
);

export const LoadRegisterValuesByRegisterId = createAction(
  '[RegisterValues] Load RegisterValues by RegisterId',
  props<{
    registerId: string;
  }>(),
);
export const LoadRegisterValuesByRegisterIdSuccess = createAction(
  '[RegisterValues] Load RegisterValues by RegisterId Success',
  props<{
    registerId: string;
    entities: RegisterValue[];
  }>(),
);
export const LoadRegisterValuesByRegisterIdError = createAction(
  '[RegisterValues] Load RegisterValues by RegisterId Error',
  props<{
    error: TsmError;
  }>(),
);

export const LoadRegisterValuesByFilter = createAction(
  '[RegisterValues] Load RegisterValues by Filter',
  props<{
    registerFilters: FilterModel[];
  }>(),
);
export const LoadRegisterValuesByFilterSuccess = createAction(
  '[RegisterValues] Load RegisterValues by Filter Success',
  props<{
    entities: RegisterValue[];
  }>(),
);
export const LoadRegisterValuesByFilterError = createAction(
  '[RegisterValues] Load RegisterValues by Filter Error',
  props<{
    error: TsmError;
  }>(),
);

export const DiffRegisterValue = createAction(
  '[RegisterValue] Diff RegisterValues',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffRegisterValueSuccess = createAction(
  '[RegisterValue] Diff RegisterValues Success',
  props<{
    registerValues: RegisterValue[];
    listingId: string;
  }>(),
);
export const DiffRegisterValueError = createAction(
  '[RegisterValue] Diff RegisterValues Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
