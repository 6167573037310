<!-- Styl chybových hlášek ala Excel css třída: excel-style-errors -->
<div
  class="dtl-form-field-wrapper"
  [class.excel-style-errors]="excelStyleErrors"
  data-cy="dtl-form-field-container"
  [tsmTooltip]="tooltip ? tooltip : null"
>
  <div
    class="dtl-form-field-flex dtl-form-field-infix"
    data-cy="dtl-form-field-flex"
  >
    <ng-content></ng-content>
    <ng-content select="dtl-form-field-label"></ng-content>
  </div>
  <ng-content select="dtl-form-field-errors"></ng-content>
</div>
