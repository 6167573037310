import {Directive, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';

@Directive({
    selector: '[dtlFormFieldInput]',
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormFieldInputDirective,
        },
    ],
    standalone: true,
})
export class FormFieldInputDirective {
  constructor(@Optional() @Self() public ngControl: NgControl) {}
}
