import {
  Directive,
  HostListener,
  Inject,
  NgZone,
  ViewContainerRef,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[tsmDragAndScroll]',
})
export class DragAndScrollDirective {
  private mouseIsDown: boolean;

  @HostListener('mousedown', ['$event'])
  onMouseDown($event: any) {
    if ($event.button === 2) {
      this.zone.runOutsideAngular(() => {
        this.document.addEventListener(
          'mousemove',
          this.onMouseMove.bind(this),
        );
      });
      this.mouseIsDown = true;
      this.viewContainerRef.element.nativeElement.style.cursor = 'grabbing';
    }
  }

  onMouseMove($event: any) {
    if (this.mouseIsDown) {
      this.zone.runOutsideAngular(() => {
        requestAnimationFrame((_) => {
          this.viewContainerRef.element.nativeElement.scrollLeft -=
            3 * $event.movementX;
          this.viewContainerRef.element.nativeElement.scrollTop -=
            3 * $event.movementY;
        });
      });
      $event.stopPropagation();
    }
  }

  @HostListener('document:click')
  onDocClick() {
    this.mouseIsDown = false;
    this.setGrab();
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.document.removeEventListener('mousemove', this.onMouseDown.bind(this));
    this.mouseIsDown = false;
    this.setGrab();
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
  ) {
    this.setGrab();
  }

  private setGrab() {
    this.viewContainerRef.element.nativeElement.style.cursor = 'default';
  }
}
