export function roundDateTo(date: string | Date, unitOfTime: string) {
  if (date == null) {
    return null;
  }
  const result = typeof date === 'string' ? new Date(date) : date;
  const times = {
    months: (date: Date) =>
      new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth())),
    days: (date: Date) =>
      new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
      ),
    hours: (date: Date) =>
      new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
        ),
      ),
    minutes: (date: Date) =>
      new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
        ),
      ),
    seconds: (date: Date) =>
      new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds(),
        ),
      ),
    milliseconds: (date: Date) =>
      new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCSeconds(),
          date.getUTCMilliseconds(),
        ),
      ),
  };
  if (result == null) {
    console.log('');
  }
  return times[unitOfTime](result);
}
