import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {NotificationRule} from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationRuleService {
  private readonly NOTIFICATION_RULE = 'notification-rules';
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  saveNotificationRule(
    notificationRule: NotificationRule,
  ): Observable<Envelope<NotificationRule>> {
    if (notificationRule.version != null) {
      return this.apiService.put<NotificationRule, NotificationRule>(
        `${this.BASE_URL}/${this.NOTIFICATION_RULE}/${notificationRule.id}`,
        notificationRule,
      );
    } else {
      return this.apiService.post<NotificationRule, NotificationRule>(
        `${this.BASE_URL}/${this.NOTIFICATION_RULE}`,
        notificationRule,
      );
    }
  }

  getNotificationRuleById(id: string): Observable<Envelope<NotificationRule>> {
    return this.apiService.get<NotificationRule, NotificationRule>(
      `${this.BASE_URL}/${this.NOTIFICATION_RULE}/${id}`,
    );
  }

  deleteNotificationRuleById(
    id: string,
  ): Observable<Envelope<NotificationRule>> {
    return this.apiService.delete<NotificationRule, NotificationRule>(
      `${this.BASE_URL}/${this.NOTIFICATION_RULE}/${id}`,
    );
  }
}
