import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {EditNewCommentText} from '../actions';
import {EntityState} from '@ngrx/entity';
import {NewCommentForm} from '../reducers/new-comment.reducer';

@Injectable({
  providedIn: 'root',
})
export class NewCommentService {
  constructor(private store$: Store<EntityState<NewCommentForm>>) {}

  editNewComment(ownerId, ownerType, comment) {
    this.store$.dispatch(
      EditNewCommentText({
        ownerId,
        ownerType,
        comment,
      }),
    );
  }
}
