import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {ListingProfile} from '../models';
import {
  DeleteListingProfileSuccess,
  DiffListingProfile,
  DiffListingProfileError,
  DiffListingProfileSuccess,
  InsertListingProfile,
  InsertListingProfileError,
  InsertListingProfileSuccess,
  LoadListingProfileByCode,
  LoadListingProfileByCodeAndListingCodeSuccess,
  LoadListingProfileByCodeError,
  LoadListingProfileByCodesSuccess,
  LoadListingProfileByCodeSuccess,
  LoadListingProfileById,
  LoadListingProfileByIdError,
  LoadListingProfileByIdSuccess,
  LoadListingProfileSuccess,
  UpdateListingProfile,
  UpdateListingProfileError,
  UpdateListingProfileSuccess,
} from '../actions';

export type ListingProfileModelState = EntityState<
  IdentifiedPack<ListingProfile>
>;

export const listingProfileAdapter: EntityAdapter<
  IdentifiedPack<ListingProfile>
> = createEntityAdapter<IdentifiedPack<ListingProfile>>({});

export const listingProfileInitialState: ListingProfileModelState =
  listingProfileAdapter.getInitialState({});

export const reducer = createReducer(
  listingProfileInitialState,

  on(LoadListingProfileSuccess, (state, {listingProfiles}) => ({
    ...listingProfileAdapter.upsertMany(
      listingProfiles.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
  })),

  on(LoadListingProfileById, (state, {id}) =>
    listingProfileAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadListingProfileByIdSuccess, (state, {listingProfile}) =>
    listingProfileAdapter.upsertOne(
      {
        id: listingProfile.id,
        data: listingProfile,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadListingProfileByIdError, (state, {id, error}) =>
    listingProfileAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadListingProfileByCode, (state, {code}) =>
    listingProfileAdapter.upsertOne(
      {
        id: code,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadListingProfileByCodeSuccess, (state, {listingProfile}) => {
    const newState = listingProfileAdapter.removeOne(
      listingProfile.code,
      state,
    );
    return listingProfileAdapter.upsertOne(
      {
        id: listingProfile.id,
        data: listingProfile,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadListingProfileByCodeError, (state, {code, error}) =>
    listingProfileAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpdateListingProfile, InsertListingProfile, (state, {listingProfile}) =>
    listingProfileAdapter.updateOne(
      {
        id: listingProfile.id,
        changes: {
          ...state.entities[listingProfile.id],
          loading: false,
        },
      },
      state,
    ),
  ),

  on(
    UpdateListingProfileSuccess,
    InsertListingProfileSuccess,
    LoadListingProfileByCodeAndListingCodeSuccess,
    (state, {listingProfile}) =>
      listingProfileAdapter.upsertOne(
        {
          id: listingProfile.id,
          data: listingProfile,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(
    UpdateListingProfileError,
    InsertListingProfileError,
    (state, {id, error}) =>
      listingProfileAdapter.upsertOne(
        {
          ...state.entities[id],
          loading: false,
          error,
        },
        state,
      ),
  ),

  on(DeleteListingProfileSuccess, (state, listingType) =>
    listingProfileAdapter.removeOne(listingType.id, state),
  ),

  on(LoadListingProfileByCodesSuccess, (state, {listingProfiles}) => ({
    ...listingProfileAdapter.upsertMany(
      listingProfiles.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
  })),

  on(DiffListingProfile, (state, {diffEntities}) =>
    listingProfileAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffListingProfileSuccess, (state, {listingProfiles}) =>
    listingProfileAdapter.upsertMany(
      listingProfiles.map((listingProfile) => {
        return {
          id: listingProfile.id,
          changes: {
            id: listingProfile.id,
            data: listingProfile,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffListingProfileError, (state, {diffEntities, error}) =>
    listingProfileAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
