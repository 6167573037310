import {Pipe, PipeTransform} from '@angular/core';
import {translation as translation} from '@tsm/shared-i18n';
import {TranslocoService} from '@tsm/framework/translate';

@Pipe({
  name: 'moduleIcon',
  pure: false,
})
export class ModuleIconPipe implements PipeTransform {
  translation = translation;

  constructor(private translocoService: TranslocoService) {}

  transform(value: any, args?: any): string {
    if (value) {
      switch (value) {
        case 'ticket':
          return `<i class="tsm-icon-ticketing" title="${this.translocoService.translate(
            translation.shared.ticketing,
          )}"></i>`;
        case 'ordering':
          return `<i class="tsm-icon-ordering" title="${this.translocoService.translate(
            translation.shared.ordering,
          )}"></i>`;

        default:
          return value;
      }
    }
    return '';
  }
}
