import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';

export const MassUpdateDlq = createAction(
  '[DLQ] Mass update',
  props<{request: any; listingId: string}>(),
);
export const MassUpdateDlqSuccess = createAction(
  '[DLQ] Mass update Success',
  props<{listingId: string}>(),
);
export const MassUpdateDlqError = createAction(
  '[DLQ] Mass update Error',
  props<{error: TsmError}>(),
);
