import {Component, Input} from '@angular/core';
import {ValidationBadgeStatus} from '../../models/enums';

@Component({
  template: ` <div
    class="flex gap-2 align-items-center font-medium border-round-sm"
    [ngClass]="'status-' + status"
    [tsmTooltip]="tooltip"
  >
    <div class="flex-none">
      <tsm-validation-icon [status]="status"></tsm-validation-icon>
    </div>
    <div class="flex-1">
      <span class="right-part-wrapper text-sm">
        <ng-content></ng-content>
      </span>
    </div>
  </div>`,
  selector: 'tsm-validation-badge',
  styleUrls: ['./validation-badge.component.scss'],
})
export class ValidationBadgeComponent {
  public statusValues = ValidationBadgeStatus;

  @Input() status: ValidationBadgeStatus = ValidationBadgeStatus.Ok;

  @Input() tooltip: string;
}
