import {Pipe, untracked} from '@angular/core';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectUserGroupByCodes} from '../selectors';
import {LoadUserGroupByCodes} from '../actions';

@Pipe({
  name: 'userGroupsInfoCode',
  pure: true,
})
export class UserGroupsInfoCodePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(codes: string[], args: string = 'name') {
    this.destroy();
    this._id = codes;
    this._args = args;
    this._obs$ = this.store.pipe(
      select(selectUserGroupByCodes(codes)),
      map((x) => {
        return x.map((y) => {
          if (!y.loading && !y.error) {
            return args == 'all' ? y.data : y.data[args];
          } else {
            return y.id;
          }
        });
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectUserGroupByCodes(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadUserGroupByCodes({codes: this._id})),
          );
        }
      });
  }
}
