import {Action, ActionReducerMap, createSelector} from '@ngrx/store';
import {routerReducer} from '@ngrx/router-store';

import {SharedState} from '../models';
import {InjectionToken} from '@angular/core';
import {updateReducer} from './update.reducer';
import {hotkeysReducer} from './hotkeys.reducer';

export const shareReducer = new InjectionToken<
  ActionReducerMap<SharedState, Action>
>('Root state', {
  factory: () => ({
    router: routerReducer,
    update: updateReducer,
    hotkeys: hotkeysReducer,
  }),
});

export const getSharedState = createSelector<
  SharedState,
  [SharedState],
  SharedState
>(
  (x) => x,
  (x) => x,
);

const getUpdate = createSelector(getSharedState, (x) => x.update);

export const selectUpdateMessage = createSelector(
  getUpdate,
  (update) => update.showUpdateMessage,
);

export const selectUpdateOverlay = createSelector(
  getUpdate,
  (update) => update.showUpdateOverlay,
);

export const selectUpdateOverlayStep = createSelector(
  getUpdate,
  (update) => update.updateOverlayStep,
);

export const getHotkeysOverlay = createSelector(
  getSharedState,
  (x) => x.hotkeys,
);
