import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {Script} from '../models';

export const LoadScriptById = createAction(
  '[Script] Load LoadScriptById',
  props<{id: string}>(),
);
export const LoadScriptByIdSuccess = createAction(
  '[Script] Load LoadScriptById Success',
  props<{script: Script}>(),
);
export const LoadScriptByIdError = createAction(
  '[Script] Load LoadScriptById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadScriptByCode = createAction(
  '[Script] Load LoadScriptByCode',
  props<{code: string}>(),
);
export const LoadScriptByCodeSuccess = createAction(
  '[Script] Load LoadScriptByCode Success',
  props<{
    script: Script;
  }>(),
);
export const LoadScriptByCodeError = createAction(
  '[Script] Load LoadScriptByCode Error',
  props<{
    code: string;
    error: TsmError;
  }>(),
);

export const UpsertScript = createAction(
  '[Script] Upsert Script',
  props<{
    script: Script;
    listingId: string;
    redirect: boolean;
    redirectUrl?: string;
  }>(),
);
export const UpsertScriptSuccess = createAction(
  '[Script] Upsert Script Success',
  props<{
    script: Script;
    listingId: string;
    redirect: boolean;
    redirectUrl?: string;
  }>(),
);
export const UpsertScriptError = createAction(
  '[Script] Upsert Script Error',
  props<{script: Script; error: any}>(),
);

export const DeleteScript = createAction(
  '[Script] Delete Script',
  props<{
    id: string;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const DeleteScriptSuccess = createAction(
  '[Script] Delete Script Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteScriptError = createAction(
  '[Script] Delete Script Error',
  props<{error: any}>(),
);

export const PatchScript = createAction(
  '[Script] Patch Script',
  props<{
    id: string;
    script: Script | Partial<Script>;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const PatchScriptSuccess = createAction(
  '[Script] Patch Script Success',
  props<{
    id: string;
    script: Script;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const PatchScriptError = createAction(
  '[Script] Patch Script Error',
  props<{error: any}>(),
);

export const DiffScript = createAction(
  '[Script] Diff Script',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffScriptSuccess = createAction(
  '[Script] Diff Script Success',
  props<{
    scripts: Script[];
    listingId: string;
  }>(),
);
export const DiffScriptError = createAction(
  '[Script] Diff Script Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
