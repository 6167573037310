import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {ConfigFormState, mnemonicSequenceAdapter} from '../reducers';
import {IdentifiedPack} from '@tsm/framework/root';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getMnemonicSequenceState = createSelector(
  getConfigFormState,
  (configFormState) => configFormState.mnemonicSequence,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  mnemonicSequenceAdapter.getSelectors(getMnemonicSequenceState);

export const selectMnemonicSequences = selectAll;

/*export const selectMnemonicSequences = () =>
  createSelector(
    selectAll,
    entities => entities.filter(pack => !!pack && !pack.loading)
  );*/
