import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import {
  FormFieldInput,
  HasExternalTouch,
  TsmFormControl,
  TsmFormGroup,
} from '@tsm/framework/forms';
import {FormFieldErrorsComponent} from '../form-field-errors/form-field-errors.component';
import {Terminator} from '@tsm/framework/terminator';
import {takeUntil} from 'rxjs/operators';
import {concat, merge, of, Subject} from 'rxjs';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {NgControl, Validator} from '@angular/forms';
import {FrameworkTooltipModule} from '@tsm/framework/tooltip';

@Component({
  selector: 'dtl-form-field-container',
  templateUrl: './form-field-container.component.html',
  // styleUrls: ['./form-field-container.component.scss'],
  host: {
    '[class.dtl-form-field]': 'disableStyleClass === false',
    '[class.layout-orientation-top]': 'labelPosition === "top"',
    '[class.layout-orientation-left]':
      'labelPosition === "left" || !labelPosition',
    '[class.layout-orientation-right]': 'labelPosition === "right"',
  },
  providers: [Terminator],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FrameworkTooltipModule],
})
export class FormFieldContainerComponent implements AfterContentInit {
  @ContentChild(FormFieldInput, {static: false})
  formFieldInput: FormFieldInput;

  @ContentChild(FormFieldInput, {static: false, read: ElementRef})
  formFieldInputElementRef: ElementRef;

  @ContentChild(FormFieldErrorsComponent, {static: false})
  formFieldErrors: FormFieldErrorsComponent;

  @Input() excelStyleErrors = false;

  @Input() disableStyleClass = false;

  @Input() labelPosition: 'left' | 'top' | 'right' = 'left';

  @Input() tooltip: any;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, jakou část layoutu má prvek zabírat
   */
  @Input() cols: number;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, o jakou část layoutu má být prvek odsunut (zleva)
   */
  @Input() offset: number;

  /**
   * Atribut pro připojení rozšiřující CSS třídy
   */
  @Input() class = '';

  @Input() leftIcon: string;

  @Input() rightIcon: string;

  __reactOnEvent = true;
  _required = false;

  private formControl: TsmFormControl;
  private _validationChanged = new Subject();

  constructor(private terminator: Terminator) {}

  @HostBinding('class')
  get styleClass(): string {
    if (this.disableStyleClass) {
      return this.class;
    }
    let result = 'field col-12 md:col-12' + ' ' + this.class;
    if (this.cols) {
      result = this.offset
        ? 'field col-12 md:col-' +
          this.cols +
          ' md:col-offset-' +
          this.offset +
          ' ' +
          this.class
        : 'field col-12 md:col-' + this.cols + ' ' + this.class;
    }
    return result;
  }

  // slouzi ciste k zobrazeni / vypnuti hvezdicky
  setRequired(value) {
    this._required = value;
    this.formFieldInputElementRef?.nativeElement.classList[
      this._required ? 'add' : 'remove'
    ]('ng-required');
  }

  instanceValidate(instance) {
    if (
      instance.registerOnValidatorChange != null &&
      typeof instance.registerOnValidatorChange === 'function' &&
      instance.validate != null &&
      typeof instance.validate === 'function'
    ) {
      const errors = instance.validate(this.formControl);
      this.formControl.setErrors(errors);
    }
  }

  setControl(
    control,
    instance: AbstractControlValueAccessor & Validator & HasExternalTouch,
    formFieldElementRef,
    layoutingType: 'design' | 'runtime',
  ) {
    this.formFieldInputElementRef = formFieldElementRef;
    // nechci expression stringy v runtime v controlu, ve schematu staci
    if (
      !(typeof control.value === 'string' && control.value.startsWith('${'))
    ) {
      if (instance.writeValue) {
        instance.writeValue(control.value);
      } else {
        console.warn(
          'function writeValue is not defined for instance',
          instance,
        );
      }
    }

    this.formControl = control;
    if (
      instance.externalTouch != null &&
      typeof instance.externalTouch === 'function'
    ) {
      this.formControl.touchedChanges.subscribe((touched) => {
        instance.externalTouch(touched);
      });
    }
    // this.instanceValidate(instance);

    this.formControl.valueChanges.subscribe((value) => {
      if (this.__reactOnEvent && instance.val != value) {
        instance.writeValue(value);
      } else {
        this.__reactOnEvent = true;
      }
    });

    // registrace validatoru
    instance.registerOnChange((val) => {
      this.__reactOnEvent = false;
      this.formControl.markAsDirty();
      if (val == null && this.formControl instanceof TsmFormGroup) {
        (
          this.formControl as unknown as unknown as TsmFormGroup<any>
        ).resetWithDefaults();
      } else {
        if (this.formControl.value != val) {
          this.formControl.patchValue(val);
        } else {
          this.__reactOnEvent = true;
        }
      }
      this.instanceValidate(instance);
    });
    if (instance.registerOnTouched) {
      instance.registerOnTouched((val) => {
        if (val) {
          this.formControl.markAsTouched();
        } else {
          this.formControl.markAsUntouched();
        }
      });
    }

    merge(
      of(this.formControl.status),
      this.formControl.statusChanges,
      this.formControl.touchedChanges,
      this._validationChanged,
    )
      .pipe(takeUntil(this.terminator))
      .subscribe((_) => {
        // je tu "hack" z duvodu toho, ze vracime cely objekt a ne pri. datovy typ + chybi udelat rekurzi
        if (this.formControl instanceof TsmFormGroup) {
          const controlsErrors = Object.keys(
            (this.formControl as unknown as TsmFormGroup).controls,
          ).reduce((acc, item) => {
            const tmpFormControl = this.formControl.get(item);
            if (
              tmpFormControl.errors &&
              Object.keys(tmpFormControl.errors).length > 0
            ) {
              const errorKey = Object.keys(tmpFormControl.errors);
              errorKey.forEach((key) => {
                acc = {...acc, [key]: tmpFormControl.errors[key]};
              });
            }
            return acc;
          }, {});
          this.formFieldErrors.errors = {
            ...controlsErrors,
            ...(this.formControl as unknown as TsmFormGroup).errors,
          };
        } else {
          this.formFieldErrors.errors = this.formControl.errors;
        }

        if (this.formFieldInputElementRef) {
          if (this.formControl.dirty) {
            this.formFieldInputElementRef.nativeElement.classList.add(
              'ng-dirty',
            );
            this.formFieldInputElementRef.nativeElement.classList.remove(
              'ng-pristine',
            );
          } else {
            this.formFieldInputElementRef.nativeElement.classList.remove(
              'ng-dirty',
            );
            this.formFieldInputElementRef.nativeElement.classList.add(
              'ng-pristine',
            );
          }

          if (this.formControl.valid || this.formControl.disabled) {
            this.formFieldInputElementRef.nativeElement.classList.add(
              'ng-valid',
            );
            this.formFieldInputElementRef.nativeElement.classList.remove(
              'ng-invalid',
            );
          } else {
            this.formFieldInputElementRef.nativeElement.classList.add(
              'ng-invalid',
            );
            this.formFieldInputElementRef.nativeElement.classList.remove(
              'ng-valid',
            );
          }

          if (this.formControl.touched) {
            this.formFieldInputElementRef.nativeElement.classList.add(
              'ng-touched',
            );
            this.formFieldInputElementRef.nativeElement.classList.remove(
              'ng-untouched',
            );
          } else {
            this.formFieldInputElementRef.nativeElement.classList.add(
              'ng-untouched',
            );
            this.formFieldInputElementRef.nativeElement.classList.remove(
              'ng-touched',
            );
          }
        }
        this.formFieldErrors.touched = this.formControl.touched;
        this.formFieldErrors.cdr.markForCheck();
      });
  }

  ngAfterContentInit(): void {
    if (this.formFieldInput) {
      const injector = (this.formFieldInput as any).injector;
      if (injector) {
        const formControl = injector.get(NgControl, null);
        if (formControl) {
          setTimeout(() => {
            this.formControl = formControl;
          });
          concat(of(formControl.status), formControl.statusChanges)
            .pipe(takeUntil(this.terminator))
            .subscribe((_) => {
              if (this.formFieldErrors) {
                this.formFieldErrors.errors = formControl.errors;
                this.formFieldErrors.touched = formControl.touched;
                this.formFieldErrors.cdr.markForCheck();
              }
            });
        }
      }
    }
  }
}
