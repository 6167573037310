import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {RouterStateUrl} from '@tsm/framework/root';
import {formatterDocumentTemplateAdapter} from '../reducers/formatter-document-template.reducer';

const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getFormatterDocumentTemplateState = createSelector(
  getDmsState,
  (x) => x.formatterDocumentTemplate,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  formatterDocumentTemplateAdapter.getSelectors(
    getFormatterDocumentTemplateState,
  );

export const selectFormatterDocumentTemplateEntities = createSelector(
  getFormatterDocumentTemplateState,
  selectEntities,
);

export const selectFormatterDocumentTemplateByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectFormatterDocumentTemplateById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);
