import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  LoadMnemonicSequence,
  LoadMnemonicSequenceError,
  LoadMnemonicSequenceSuccess,
  UpsertMnemonicSequence,
  UpsertMnemonicSequenceError,
  UpsertMnemonicSequenceSuccess,
} from '../actions';
import {MnemonicSequence} from '../models';
import {addDays} from 'date-fns';

export type MnemonicSequenceState = EntityState<
  IdentifiedPack<MnemonicSequence>
>;

export const mnemonicSequenceAdapter: EntityAdapter<
  IdentifiedPack<MnemonicSequence>
> = createEntityAdapter<IdentifiedPack<MnemonicSequence>>({});
export const mnemonicSequenceInitialState: MnemonicSequenceState =
  mnemonicSequenceAdapter.getInitialState({});

export const mnemonicSequenceReducer = createReducer(
  mnemonicSequenceInitialState,

  on(LoadMnemonicSequence, UpsertMnemonicSequence, (state, {}) => ({
    ...state,
    loading: true,
  })),

  on(LoadMnemonicSequenceSuccess, (state, {entities}) => ({
    ...mnemonicSequenceAdapter.upsertMany(
      entities.map((mnemonicSequence) => ({
        id: mnemonicSequence.sequenceName,
        data: mnemonicSequence,
        loading: false,
        error: null,
        validUntil: addDays(new Date(), 1),
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(UpsertMnemonicSequenceSuccess, (state, {mnemonicSequence}) =>
    mnemonicSequenceAdapter.upsertOne(
      {
        id: mnemonicSequence.sequenceName,
        data: mnemonicSequence,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(
    LoadMnemonicSequenceError,
    UpsertMnemonicSequenceError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),
);
