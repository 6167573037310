import {Injector, Pipe, PipeTransform, Type} from '@angular/core';
import {isObservable, Observable, of, switchMap} from 'rxjs';
import {filter} from 'rxjs/operators';

@Pipe({
  name: 'pipeInPipe',
  pure: true,
})

/**
 * Pipe, ktera prevede pole objektu do stringu,
 * ve kterem ty objekty budou oddelene pozadovanym separatorem.
 * Take umi prevadet objekty na string dle nekolika parametru tzn.,
 * treba object ma prop1, prop2, a podproperty prop2.prop21, tato funkce umi
 * udelat vupis tvaru prop1
 */
export class PipeInPipePipe implements PipeTransform {
  constructor(private injector: Injector) {}

  /**
   * Defaultnim separatorem je mezera
   * @param id
   * @param elementPipeType pajpa na jednotlivé elementy
   * @param elementPipeArgs parametry pro pajpu
   * @param elementSecondPipeType pajpa na jednotlivé elementy
   * @param elementSecondPipeArgs parametry pro pajpu
   * @return string, ktery se sklada ze string hodnot, ktere, se nachazi na ceste/ach, ktera/e byly predane jako vstupni parameter
   * teto funce
   */
  transform(
    id: string,
    elementPipeType: Type<PipeTransform>,
    elementPipeArgs: any,
    elementSecondPipeType: Type<PipeTransform> = null,
    elementSecondPipeArgs: any = null,
  ): Observable<any> {
    if (!id) {
      return of(null);
    }
    if (elementPipeType != null) {
      const pipe = this.injector.get<PipeTransform>(elementPipeType);
      const args = !Array.isArray(elementPipeArgs)
        ? [elementPipeArgs]
        : elementPipeArgs;
      let observable: Observable<any>;
      const r = pipe.transform(id, ...args);
      if (isObservable(r)) {
        observable = r;
      } else {
        observable = of(r);
      }
      if (elementSecondPipeType != null) {
        return observable.pipe(
          // pokud vstupni data stale jsou stejny, tak nikdy nevolej dalsi pipe
          filter((x) => x !== id),
          switchMap((item: any) => {
            const secondaryPipe = this.injector.get<PipeTransform>(
              elementSecondPipeType,
            );
            const secondaryArgs = !Array.isArray(elementSecondPipeArgs)
              ? [elementSecondPipeArgs]
              : elementSecondPipeArgs;
            const r1 = secondaryPipe.transform(item, ...secondaryArgs);
            if (isObservable(r1)) {
              return r1;
            } else {
              return of(r1);
            }
          }),
        );
      } else {
        return observable;
      }
    }
    return of(id);
  }
}
