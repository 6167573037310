<div class="p-accordion-tab" [ngClass]="{'p-accordion-tab-active': selected}">
  <div
    class="p-accordion-header"
    [ngClass]="{'p-highlight': selected, 'p-disabled': disabled}"
  >
    <a
      role="tab"
      class="p-accordion-header-link"
      (click)="toggle($event)"
      (keydown)="onKeydown($event)"
      [attr.tabindex]="disabled ? null : 0"
      [attr.id]="id"
      [attr.aria-controls]="id + '-content'"
      [attr.aria-expanded]="selected"
    >
      <span
        class="p-accordion-toggle-icon"
        [ngClass]="selected ? accordion.collapseIcon : accordion.expandIcon"
      ></span>
      <span class="p-accordion-header-text" *ngIf="!hasHeaderFacet">
        {{ header }}
      </span>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <ng-content select="p-header" *ngIf="hasHeaderFacet"></ng-content>
    </a>
  </div>
  <div
    [attr.id]="id + '-content'"
    class="p-toggleable-content"
    [@tabContent]="
      selected && contentViewChild?.nativeElement?.children.length > 0
        ? {value: 'visible', params: {transitionParams: transitionOptions}}
        : {value: 'hidden', params: {transitionParams: transitionOptions}}
    "
    role="region"
    [attr.aria-hidden]="!selected"
    [attr.aria-labelledby]="id"
  >
    <div class="p-accordion-content" #content>
      <ng-content></ng-content>
      <ng-container *ngIf="contentTemplate && (cache ? loaded : selected)">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
</div>
