import {Component, HostBinding, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'tsm-readonly-input',
  template: ` <div
    [class]="isChangeLayoutStyle() ? 'md-inputfield' : 'dtl-form-field-flex'"
  >
    <div
      [class]="
        isChangeLayoutStyle()
          ? 'p-inputtext p-state-filled p-state-readonly'
          : label
            ? 'dtl-form-field-input flex-order-2 p-value'
            : ''
      "
    >
      <span #content><ng-content></ng-content></span>
      <span *ngIf="isContent()" [ngClass]="{'empty-text': value === 'none'}">{{
        value
      }}</span>
    </div>
    <label
      *ngIf="label"
      [class]="
        isChangeLayoutStyle()
          ? ''
          : 'xl:col-3 lg:col-4 md:col-5 sm:col-12 flex-order-1 p-label'
      "
      style="padding: 2px 0"
    >
      {{ label }}
    </label>
  </div>`,
  styleUrls: ['./readonly-input.component.scss'],
})
export class ReadonlyInputComponent {
  @HostBinding('class')
  get styleClass(): string {
    if (this.cols) {
      if (this.isChangeLayoutStyle() && this.offset) {
        return (
          'layout-changed col-12 md:col-' +
          this.cols +
          ' md:col-offset-' +
          this.offset +
          ' ' +
          this.class
        );
      }
      if (this.offset) {
        return (
          'col-12 md:col-' +
          this.cols +
          ' md:col-offset-' +
          this.offset +
          ' ' +
          this.class
        );
      }
      if (this.isChangeLayoutStyle()) {
        return 'layout-changed col-12 md:col-' + this.cols + ' ' + this.class;
      }
      return 'layout-default col-12 md:col-' + this.cols + ' ' + this.class;
    } else {
      if (this.isChangeLayoutStyle()) {
        return 'layout-changed col-12 md:col-12' + ' ' + this.class;
      }
      return 'layout-default col-12 md:col-12' + ' ' + this.class;
    }
  }

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, jakou část layoutu má prvek zabírat
   */
  @Input()
  cols: number;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, o jakou část layoutu má být prvek odsunut (zleva)
   */
  @Input()
  offset: number;

  /**
   * Atribut pro připojení rozšiřující CSS třídy
   */
  @Input()
  class = '';

  private _value: any;

  @ViewChild('content', {static: true})
  content;

  @Input()
  label: string;

  /**
   * Přepíná styl a pozici zobrazení labelu a inputu
   * Tento input nepotřebuje žádnou hodnotu
   */
  @Input()
  changeLayoutStyle: any;

  @Input()
  get value(): any {
    return this._value ? this._value : 'none';
  }

  set value(value: any) {
    this._value = value;
  }

  isContent(): boolean {
    return this.content.nativeElement.childNodes.length === 0;
  }

  isChangeLayoutStyle(): boolean {
    return this.changeLayoutStyle === '' || this.changeLayoutStyle;
  }
}
