import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {Role} from '../model';

export const LoadRoles = createAction('[Role] Load Roles');
export const LoadRolesSuccess = createAction(
  '[Role] Load Roles Success',
  props<{entities: Role[]}>(),
);
export const LoadRolesError = createAction(
  '[Role] Load Roles Error',
  props<{error: any}>(),
);

export const LoadRoleById = createAction(
  '[Role] Load LoadRoleById',
  props<{id: string; forcedReload?: boolean}>(),
);
export const LoadRoleByIdSuccess = createAction(
  '[Role] Load LoadRoleById Success',
  props<{role: Role}>(),
);
export const LoadRoleByIdError = createAction(
  '[Role] Load LoadRoleById Error',
  props<{id: string; error: any}>(),
);

export const LoadRoleByCode = createAction(
  '[Role] Load LoadRoleByCode',
  props<{code: string}>(),
);
export const LoadRoleByCodeSuccess = createAction(
  '[Role] Load LoadRoleByCode Success',
  props<Role>(),
);
export const LoadRoleByCodeError = createAction(
  '[Role] Load LoadRoleByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertRole = createAction(
  '[Role] Upsert Role',
  props<{
    role: Role;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertRoleSuccess = createAction(
  '[Role] Upsert Role Success',
  props<{
    role: Role;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertRoleError = createAction(
  '[Role] Upsert Role Error',
  props<TsmError>(),
);

export const DeleteRole = createAction(
  '[Role] Delete Role',
  props<{id: string; listingId: string}>(),
);
export const DeleteRoleSuccess = createAction(
  '[Role] Delete Role Success',
  props<{id: string; listingId: string}>(),
);
export const DeleteRoleError = createAction(
  '[Role] Delete Role Error',
  props<TsmError>(),
);

export const DiffRole = createAction(
  '[Role] Diff Role',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffRoleSuccess = createAction(
  '[Role] Diff Role Success',
  props<{roles: Role[]; listingId: string}>(),
);
export const DiffRoleError = createAction(
  '[Role] Diff Role Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
