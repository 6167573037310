export const en = {
  wfmPlannerPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmTaskCurrentStateFilter: 'tSM Task Current State Filter',
      tsmTaskCurrentStateFilterDescription:
        'Implements a multi-selection filter for task states. ' +
        'Displays available task states using a dropdown with translations. ' +
        'Allows users to select multiple states and dynamically update the filter value. ' +
        'Retrieves predefined task states and maps them to selectable options. ' +
        'Formats the selected states for display using translations before applying the filter.',
      tsmTaskHardRuleFilter: 'tSM Task Hard Rule Filter',
      tsmTaskHardRuleFilterDescription:
        'Implements a multi-selection filter for task constraints. ' +
        'Displays available hard rule constraints using a dropdown with translations. ' +
        'Allows users to select multiple constraints and dynamically update the filter value. ' +
        'Retrieves predefined task constraints and maps them to selectable options. ' +
        'Formats the selected constraints for display using translations before applying the filter.',
      tsmTaskPartitionFilter: 'tSM Task Partition Filter',
      tsmTaskPartitionFilterDescription:
        'Implements a multi-selection filter for task partitions. ' +
        'Displays available partition options using a dropdown list. ' +
        'Allows users to select multiple partitions and dynamically update the filter value. ' +
        'Retrieves predefined task partitions and maps them to selectable options. ' +
        'Formats selected partitions as a comma-separated string before applying the filter.',
    },
    pipes: {
      formatTimeType: 'Format Time Type',
      formatTimeTypeDescription:
        'Formats time intervals based on the specified time type, such as day, week, workweek, month, ' +
        'or custom, using localized date formats. It ensures correct formatting according to the ' +
        'current language settings and the appropriate date range.',
    },
  },
};
