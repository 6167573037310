import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ApplicationRef, Injectable, Optional} from '@angular/core';

import {Store} from '@ngrx/store';
import {
  concatMap,
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {ROUTER_NAVIGATED, RouterNavigatedAction} from '@ngrx/router-store';
import {SecurityDataService} from '@tsm/framework/security';
import {
  getRuntimeInfo,
  LoadRuntimeInfoAction,
  RuntimeInfo,
  RuntimeInfoLoadSuccessAction,
  RuntimeService,
} from '@tsm/runtime-info';
import {
  LoadUserParametersByUserIdSuccess,
  UpsertUserParameterByUserIdAndNameSuccess,
} from '@tsm/user-parameters';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {
  HideUpdateOverlay,
  InitUpdate,
  RouterStateUrl,
  SetUpdateOverlayStepInstalled,
  ShowUpdateMessage,
  ShowUpdateOverlay,
  TriggerUpdateMessageChecking,
  UpdateService,
  withLatestCached,
} from '@tsm/framework/root';
import {first, merge, mergeMap, of} from 'rxjs';
import {CoreService} from '../services';
import {Logout, LogoutClearState, Navigate, ShowMessage} from '../actions';
import {Router, UrlTree} from '@angular/router';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';

@Injectable()
export class CoreEffects {
  initUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitUpdate),
      switchMap(() => this.appRef.isStable.pipe(first((isStable) => isStable))),
      // app should be stable
      filter((isStable) => isStable),
      // if no sw then don´t do anything
      filter(() => this.updateService.swEnabled),
      // check if there are updates available
      switchMap(() => {
        if (navigator.serviceWorker.controller == null) {
          console.log('navigator.serviceWorker.controller is null ');
          return of(false);
        } else {
          return merge(
            this.updateService
              .checkUpdateOnce$()
              .pipe(
                tap((isUpdate) => console.log('Merge check update', isUpdate)),
              ),
            this.updateService.versionDetected$.pipe(
              map(() => true),
              tap(() => console.log('Merge version ready', true)),
            ),
          ).pipe(take(1));
        }
      }),
      tap((isUpdate) => console.log('Update: ', isUpdate)),
      map((isUpdate) =>
        !isUpdate ? TriggerUpdateMessageChecking() : ShowUpdateOverlay(),
      ),
    ),
  );

  showUpdateOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowUpdateOverlay),
      switchMap(() => {
        return merge(
          this.updateService.versionReady$.pipe(
            tap(() => console.log('SwitchMap versionReady')),
          ),
          this.updateService.versionInstallationFailed$.pipe(
            tap(() => console.log('SwitchMap installation failed ')),
          ),
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(TriggerUpdateMessageChecking))),
        );
      }),
      mergeMap((x: {type: string}) => {
        console.log('Ready', x);
        if (x.type === 'VERSION_READY') {
          return [SetUpdateOverlayStepInstalled()];
        } else {
          return [TriggerUpdateMessageChecking(), HideUpdateOverlay()];
        }
      }),
    ),
  );

  setUpdateOverlayStepInstalled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SetUpdateOverlayStepInstalled),
        delay(3000),
        tap(() => {
          console.log('Reloading...');
          window.location.reload();
        }),
      ),
    {dispatch: false},
  );

  triggerUpdateCheckRepeatedly$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TriggerUpdateMessageChecking),
        switchMap(() => this.updateService.checkUpdateRepeatedly$()),
      ),
    {dispatch: false},
  );

  triggerUpdateDialogChecking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TriggerUpdateMessageChecking),
      switchMap(() => this.updateService.versionReady$),
      concatMap(() => this.updateService.activateUpdate()),
      map(() => ShowUpdateMessage()),
    ),
  );

  loadRuntimeInfoWhenNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRuntimeInfoAction),
      withLatestCached((_) => this.store$.select(getRuntimeInfo)),
      switchMap(([{refresh}, ri]) => {
        return !refresh && ri.id
          ? of(RuntimeInfoLoadSuccessAction({runtimeInfo: ri}))
          : this.coreService.getUserSettings().pipe(
              filter((res) => res.success),
              mergeMap((res) => [
                RuntimeInfoLoadSuccessAction({
                  runtimeInfo: res.data?.user as RuntimeInfo,
                }),
                LoadUserParametersByUserIdSuccess({
                  userId: res.data?.user.id,
                  entities: res.data?.userParameters,
                }),
              ]),
            );
      }),
    ),
  );

  autoloadDetailById$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction<RouterStateUrl>>(ROUTER_NAVIGATED),
      filter(
        (x) =>
          x.payload.routerState.data &&
          x.payload.routerState.data.autoload &&
          x.payload.routerState.params['id'] !== 'new',
      ),
      map((x) => {
        const {params, data} = x.payload.routerState;
        const {id, key, module, executionId, activityId} = params;

        const fireActionWithAllParams = data.autoload.fireActionWithAllParams;
        if (fireActionWithAllParams) {
          return data.autoload.action({...params});
        }

        // TODO
        // Podle meho bude do budoucna outdated a vystacime si s fireActionWithAllParams: true
        // Jednoduse by se ridilo temi parametry a jejich spravnym pojmenovanim
        const finalId = data.autoload.param || id;
        if (executionId && activityId) {
          return data.autoload.action({executionId, activityId, module});
        }

        return data.autoload.action({
          ...(key ? {key} : {id: finalId}),
          module,
        });
      }),
    ),
  );

  upsertUserParameterByUserIdAndNameSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserParameterByUserIdAndNameSuccess),
      map(() => {
        return RefreshDataAndClearSelected({id: 'userParameterList'});
      }),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Logout),
      tap((_) => {
        this.runtimeService.signOut();
      }),
      map((_) => LogoutClearState()),
    ),
  );

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Navigate),
        filter(({url}) => url != null),
        tap(({url, extras, newTab}) => {
          const finalUrl =
            typeof url === 'string' ? url : (url as UrlTree).toString();
          if (newTab) {
            window.open(finalUrl, '_blank', 'noopener noreferrer');
          } else {
            finalUrl.startsWith('http')
              ? (window.location.href = finalUrl)
              : this.router.navigateByUrl(finalUrl, extras);
          }
        }),
      ),
    {dispatch: false},
  );

  showMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowMessage),
        filter(({text}) => text != null),
        tap(({text, severity, title}) => {
          this.toastService.showToast(
            text,
            severity ?? ToastSeverity.SUCCESS,
            null,
            null,
            title,
          );
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private runtimeService: RuntimeService,
    private actions$: Actions,
    private store$: Store,
    @Optional()
    private securityDataService: SecurityDataService,
    private coreService: CoreService,
    private appRef: ApplicationRef,
    private toastService: ToastService,
    private router: Router,
    private updateService: UpdateService,
  ) {}
}
