import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter} from '../reducers/check-online.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');

export const getCheckOnlineState = createSelector(
  getUserManagementState,
  (userManagementState) => userManagementState.checkOnline,
);

const {selectEntities} = adapter.getSelectors(getCheckOnlineState);

export const selectCheckOnlineById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);
