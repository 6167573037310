import {Pipe, PipeTransform} from '@angular/core';
import DOMPurify from 'dompurify';
import {DomSanitizer} from '@angular/platform-browser';

DOMPurify.addHook('uponSanitizeAttribute', (node, data) => {
  if (data.attrName === 'style') {
    const allowedStyles = [
      'color',
      'font-size',
      'background-color',
      'box-sizing',
      'font-family',
      'font-weight',
      'font-size',
      'text-align',
      'width',
      'height',
      'padding',
      'margin',
    ]; // Define safe CSS properties
    const sanitizedStyles = data.attrValue
      .split(';') // Split into individual style rules
      .map((style) => style.trim())
      .filter((style) => {
        const [property] = style.split(':').map((s) => s.trim());
        return allowedStyles.includes(property); // Only allow whitelisted properties
      })
      .join('; ');

    data.attrValue = sanitizedStyles; // Overwrite the style attribute with sanitized content
  }
});

@Pipe({
  name: 'domPurify',
})
export class DomPurifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  // umoznije konfigurovat sanitizaci narozdil od SafeHtml
  transform(html: string, config: DOMPurify.Config) {
    const res = DOMPurify.sanitize(html, config);
    return this.sanitizer.bypassSecurityTrustHtml(res);
  }
}
