import {
  Directive,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  TsmFormArray,
  TsmFormBuilder,
  TsmFormGroup,
  TypedTsmFormGroup,
} from '@tsm/framework/forms';
import {merge, Subject, Subscription} from 'rxjs';
import {TranslocoService} from '@tsm/framework/translate';
import * as objectPath from 'object-path';
import {JsonPointer} from 'tsm-json-schema-form-functions';
import {filter} from 'rxjs/operators';

export interface OnAfterFormPatch {
  afterFormPatch: (value) => void;
}

export interface OnBeforeFormPatch {
  beforeFormPatch: (value) => void;
}

export interface ValueTransform {
  valueTransform: (value: any) => any;
}

export interface UnrollValue {
  unrollValue: (value: any) => any;
}

export interface AddsTrigger {
  trigger: Subject<any>;
}

export interface IsEditorComponent {
  editorChanges: EventEmitter<any>;
  config: any;
}

@Directive()
export abstract class WidgetEditorComponent
  implements IsEditorComponent, OnDestroy
{
  @Output() editorChanges: EventEmitter<any> = new EventEmitter<any>();
  subscription: Subscription;
  private subs: Subscription[] = [];

  showTitleLocalizations = false;
  showTooltipLocalizations = false;
  showPlaceholderLocalizations = false;
  showAddButtonLocalizations = false;

  widgetForm: TsmFormGroup | TypedTsmFormGroup;

  _config;

  @Input() set config(value) {
    this.widgetForm.resetWithDefaults({emitEvent: false});
    const newValue = (this as any).valueTransform
      ? (this as any).valueTransform(value)
      : value;

    this._config = newValue;

    this.subscription.unsubscribe();

    if ((this as any).beforeFormPatch) {
      (this as any).beforeFormPatch(value);
    }

    // remove undefined values for all TsmControls
    JsonPointer.forEachDeep(newValue, (v, p) => {
      if (p !== '' && v === undefined) {
        JsonPointer.remove(newValue, p);
      }
    });
    this.widgetForm.patchValue(newValue, {emitEvent: false});

    if (this.checkLocNotEmptyForKey('title')) {
      this.showTitleLocalizations = true;
    }
    if (this.checkLocNotEmptyForKey('widget.tooltip')) {
      this.showTooltipLocalizations = true;
    }

    if (this.checkLocNotEmptyForKey('widget.placeholder')) {
      this.showPlaceholderLocalizations = true;
    }

    if (this.checkLocNotEmptyForKey('widget.buttons.addText')) {
      this.showAddButtonLocalizations = true;
    }

    if ((this as any).afterFormPatch) {
      (this as any).afterFormPatch(value);
    }

    // custom validations
    if (
      Array.isArray(newValue.widget?.customValidations) &&
      newValue.widget?.customValidations.length > 0
    ) {
      this.customValidations.clear();
      newValue.widget?.customValidations.forEach((x) =>
        this.customValidations.push(this.fb.tsmControl(x)),
      );
    }
    if (
      Array.isArray(newValue.widget?.customValidationsValidating) &&
      newValue.widget?.customValidationsValidating.length > 0
    ) {
      this.customValidationsValidating.clear();
      newValue.widget?.customValidationsValidating.forEach((x) =>
        this.customValidationsValidating.push(this.fb.tsmControl(x)),
      );
    }
    if (
      Array.isArray(newValue.widget?.validationMessages?.customValidations) &&
      newValue.widget?.validationMessages?.customValidations.length > 0
    ) {
      this.validationMessagesCustomValidations.clear();
      newValue.widget?.validationMessages?.customValidations.forEach((x) =>
        this.validationMessagesCustomValidations.push(this.fb.tsmControl(x)),
      );
    }

    const valueChangers = (this as any).trigger
      ? [this.widgetForm.valueChanges, (this as any).trigger]
      : [this.widgetForm.valueChanges];
    this.subscription = merge(...valueChangers).subscribe((x) => {
      try {
        const returnValue = (this as any).unrollValue
          ? (this as any).unrollValue(x)
          : x;
        this.editorChanges.next(
          this._eliminateDefaults
            ? this.eliminateDefaults(returnValue)
            : returnValue,
        );
      } catch (ex) {
        console.warn('Error during unrolling value!');
      }
    });
  }

  get config() {
    return this._config;
  }

  get customValidations(): TsmFormArray<any> {
    return this.widgetForm.get(
      'widget.customValidations',
    ) as unknown as TsmFormArray<any>;
  }

  get customValidationsValidating() {
    return this.widgetForm.get(
      'widget.customValidationsValidating',
    ) as unknown as TsmFormArray<any>;
  }

  get validationMessagesCustomValidations() {
    return this.widgetForm.get(
      'widget.validationMessages.customValidations',
    ) as unknown as TsmFormArray<any>;
  }

  setEliminateDefaults(eliminateDefaults: boolean) {
    this._eliminateDefaults = eliminateDefaults;
  }

  private _eliminateDefaults;

  protected translocoService = inject(TranslocoService);
  protected fb = inject(TsmFormBuilder);
  protected langs = (
    this.translocoService.getAvailableLangs() as string[]
  ).filter((x) => x !== this.translocoService.getDefaultLang());

  constructor(widgetForm: TsmFormGroup | TypedTsmFormGroup) {
    this._eliminateDefaults = true;
    this.widgetForm = widgetForm;
    this.setLocalizationDataFor([
      'title',
      'widget.tooltip',
      'widget.placeholder',
      'widget.buttons.addText',
    ]);

    if (
      this.widgetForm.get('widget.buttons.add') != null &&
      this.widgetForm.get('widget.buttons.addText') != null
    ) {
      this.subs.push(
        this.widgetForm
          .get('widget.buttons.add')
          .valueChanges.pipe(filter((x) => x === false))
          .subscribe(() => {
            this.widgetForm.get('widget.buttons.addText').reset();
            this.langs.forEach((lang) => {
              this.widgetForm
                .get('localizationData')
                .get(lang)
                .get('widget.buttons.addText')
                .reset();
            });
          }),
      );
    }

    this.subscription = this.widgetForm.valueChanges.subscribe((x) => {
      const returnValue = (this as any).unrollValue
        ? (this as any).unrollValue(x)
        : x;
      this.editorChanges.next(
        this._eliminateDefaults
          ? this.eliminateDefaults(returnValue)
          : returnValue,
      );
    });
  }

  checkLocNotEmptyForKey(key: string) {
    const locDataGroup = this.widgetForm.get(
      'localizationData',
    ) as unknown as TsmFormGroup;
    return Object.keys(locDataGroup.controls).some((langKey) => {
      const langGroup = locDataGroup.get(langKey) as unknown as TsmFormGroup;
      return (
        langGroup.get(key)?.value != null && langGroup.get(key).value !== ''
      );
    });
  }

  protected setLocalizationDataFor(fields: string | string[]) {
    if (!Array.isArray(fields)) {
      fields = [fields];
    }
    const defaultOne = this.translocoService.getDefaultLang();
    const langs = (
      this.translocoService.getAvailableLangs() as string[]
    ).filter((x) => x !== defaultOne);

    const groups = {};
    langs.forEach((lang) => {
      groups[lang] = this.fb.typedTsmGroup({});

      (fields as string[]).forEach((field) => {
        if (this.widgetForm.get(field) == null) {
          return;
        }
        let fieldTemp = '';
        const fieldPath = field.split('.');
        fieldPath.forEach((x, i) => {
          const isLast = i === fieldPath.length - 1;

          if (isLast) {
            if (fieldTemp !== '') {
              groups[lang]
                .get(fieldTemp)
                .setControl(x, this.fb.typedTsmControl(''));
            } else {
              groups[lang].setControl(x, this.fb.typedTsmControl(''));
            }
          } else if (groups[lang] != null && groups[lang].controls[x] == null) {
            (fieldTemp !== ''
              ? groups[lang].get(fieldTemp)
              : groups[lang]
            ).setControl(x, this.fb.typedTsmGroup({}));
          }
          fieldTemp += fieldTemp !== '' ? '.' + x : x;
        });
      });
    });
    const locDataGroup = this.fb.typedTsmGroup({});
    Object.keys(groups).forEach((key) => {
      const langGroup = groups[key];

      if (this.widgetForm.get('localizationData') != null) {
        // zmergit
        Object.keys(langGroup.controls).forEach((k) => {
          const ctrl = langGroup.controls[k];
          (
            this.widgetForm.get('localizationData').get(key) as TsmFormGroup
          )?.setControl(k, ctrl);
        });
      } else {
        // setni  novy
        locDataGroup.setControl(key, langGroup);
        this.widgetForm.setControl('localizationData', locDataGroup);
      }
    });
  }

  private eliminateDefaults(value) {
    const defaults = this.widgetForm.getDefaults();
    const diff = this.diff(defaults, value);
    return diff;
  }

  private arraysMatch(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  private compare(item1, item2, key, diffs) {
    const type1 = Object.prototype.toString.call(item1);
    const type2 = Object.prototype.toString.call(item2);
    if (type2 === '[object Undefined]') {
      diffs[key] = undefined;
      return;
    }
    if (type1 !== type2) {
      if (item2 === '${}') {
        diffs[key] = undefined;
      } else {
        diffs[key] = item2;
      }
      return;
    }
    if (type1 === '[object Object]') {
      const objDiff = this.diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }
    if (type1 === '[object Array]') {
      if (!this.arraysMatch(item1, item2)) {
        diffs[key] = Array.isArray(item2)
          ? item2.map((x) => (x === '${}' ? '' : x))
          : item2;
      } else {
        diffs[key] = key !== 'items' ? undefined : [];
      }
      return;
    }
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2 && item2 !== '${}') {
        diffs[key] = item2;
      } else {
        // pokud se rovnají, nastavíme undefined tak aby zmiznula value ze schematu
        diffs[key] = undefined;
      }
    }
  }

  private diff(obj1, obj2) {
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
      return obj1;
    }

    const diffs = {};
    let key;
    for (key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        this.compare(obj1[key], obj2[key], key, diffs);
      }
    }

    for (key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (!obj1[key] && obj1[key] !== obj2[key] && obj2[key] !== '${}') {
          diffs[key] = obj2[key];
        }
      }
    }

    // zbyle
    JsonPointer.forEachDeep(diffs, (v, p) => {
      if (v === '${}') {
        JsonPointer.set(diffs, p, undefined);
      }
    });
    return diffs;
  }

  deleteItemByConfigArray(positionIndex: number, path: string) {
    const itemsToDelete: string[] = [];
    const resultIndicesToDelete: number[] = [];
    const arr = objectPath.get(this.widgetForm.value, path);
    const indicesToDelete = arr[positionIndex].content;
    indicesToDelete.forEach((index) => {
      const otherAccordions = arr.filter((_, i) => i !== positionIndex);
      // pokud ostatni accordiony neobsahuji take ten index, tak muzeme smazat item
      if (
        !otherAccordions
          .map((x) => x.content)
          .reduce((a, b) => a.concat(b), [])
          .includes(index)
      ) {
        itemsToDelete.push(this.widgetForm.value.items[index]);
        resultIndicesToDelete.push(index);
      }
    });
    this.widgetForm
      .get('items')
      .setValue(
        this.widgetForm.value.items.filter((x) => !itemsToDelete.includes(x)),
      );
    resultIndicesToDelete.sort();

    resultIndicesToDelete.forEach((indexToDelete) => {
      this.widgetForm.get(path).value.forEach((tab, it) => {
        if (Array.isArray(tab.content)) {
          const content = [...tab.content];
          tab.content.forEach((index, i) => {
            if (index === indexToDelete) {
              content.splice(i, 1);
            } else if (index > indexToDelete) {
              content[i] = index - 1;
            }
          });
          (this.widgetForm.get(path) as unknown as TsmFormArray<any>)
            .at(it)
            .get('content')
            .patchValue(content);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((x) => x.unsubscribe());
    this.subs = [];
  }
}
