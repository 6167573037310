import {
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {of} from 'rxjs';
import {DesignerCategoryEnum} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/characteristics/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const characteristicsPlugin: Plugin = {
  menus: [],
  routes: [],
  filters: [
    {
      definitions: [
        {
          selector: 'tsm-schema-location-filter',
          name: translationPlugin.characteristicsPlugins.filters
            .tsmSchemaLocationFilter,
          description:
            translationPlugin.characteristicsPlugins.filters
              .tsmSchemaLocationFilterDescription,
          loadComponent: () =>
            import(
              '@tsm/characteristics/widgets/filters/schema-location-filter'
            ).then((x) => x.SchemaLocationFilterComponent),
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/characteristics/widgets').then(
          (x) => x.CharacteristicsWidgetsModule,
        ),
      definitions: [
        {
          selector: 'characteristicsCode',
          name: translationPlugin.characteristicsPlugins.pipes
            .characteristicsCode,
          description:
            translationPlugin.characteristicsPlugins.pipes
              .characteristicsCodeDescription,
        },
        {
          selector: 'characteristicsByCodes',
          name: translationPlugin.characteristicsPlugins.pipes
            .characteristicsByCodes,
          description:
            translationPlugin.characteristicsPlugins.pipes
              .characteristicsByCodesDescription,
        },
        {
          selector: 'characteristics',
          name: translationPlugin.characteristicsPlugins.pipes.characteristics,
          description:
            translationPlugin.characteristicsPlugins.pipes
              .characteristicsDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      definitions: [
        {
          selector: 'tsm-characteristics-card',
          loadComponent: () =>
            import('@tsm/characteristics/widgets/characteristics-card').then(
              (x) => x.CharacteristicsCardComponent,
            ),
        },
        {
          selector: 'tsm-characteristics-position-lov',
          loadComponent: () =>
            import(
              '@tsm/characteristics/widgets/characteristics-position-lov'
            ).then((x) => x.CharacteristicsPositionLovComponent),
        },
        {
          selector: 'tsm-characteristics-lov',
          loadComponent: () =>
            import(
              '@tsm/characteristics/widgets/characteristics-lov/characteristics-lov'
            ).then((x) => x.CharacteristicsLovComponent),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-characteristics-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/characteristics/widgets/characteristics-lov/characteristics-lov-editor'
                ).then((x) => x.CharacteristicsLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'characteristicsLov',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets.characteristicSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.characteristicSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.characteristicSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-characteristics-lov',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'Characteristics',
      url: (entityId) => {
        return of('/config/characteristics/' + entityId);
      },
    },
  ],
};
