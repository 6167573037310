import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteEntitySpecification,
  DeleteEntitySpecificationError,
  DeleteEntitySpecificationSuccess,
  DiffEntitySpecification,
  DiffEntitySpecificationError,
  DiffEntitySpecificationSuccess,
  LoadEntitySpecificationByCode,
  LoadEntitySpecificationByCodeError,
  LoadEntitySpecificationByCodeSuccess,
  LoadEntitySpecificationById,
  LoadEntitySpecificationError,
  LoadEntitySpecificationsByCharacteristicCode,
  LoadEntitySpecificationsError,
  LoadEntitySpecificationsSuccess,
  LoadEntitySpecificationSuccess,
  UpsertEntitySpecification,
  UpsertEntitySpecificationError,
  UpsertEntitySpecificationSuccess,
} from '../actions';
import {exhaustBy, withLatestCached} from '@tsm/framework/root';
import {concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {
  selectEntitySpecificationByCode,
  selectEntitySpecificationById,
} from './../selectors';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {CommonApiService} from '../services';
import {EntitySpecification} from '../models';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {of} from 'rxjs';
import {LoadCharacteristicsByCodes} from '@tsm/characteristics/service';
import {isAfter} from 'date-fns';

@Injectable()
export class EntitySpecificationsEffects {
  translation = translation;
  translationShared = translationShared;

  private readonly ENTITY_SPEC_API_PATH = 'entity-specifications';

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEntitySpecificationById),
      withLatestCached((action) =>
        this.store.select(selectEntitySpecificationById(action.id)),
      ),
      exhaustBy(
        ([x]) => x.id,
        ([{id}, cache]) => {
          if (!id || id === 'null') {
            return of(LoadEntitySpecificationError({error: 'ID is null'}));
          }
          const someInvalid =
            cache == null ||
            cache.validUntil == null ||
            isAfter(new Date(), cache.validUntil);
          if (someInvalid) {
            return this.commonApiService
              .getEntityPublic(this.ENTITY_SPEC_API_PATH, id, [
                'CHARACTERISTICS',
              ])
              .pipe(
                map((env) =>
                  env.success
                    ? LoadEntitySpecificationSuccess({
                        entitySpecification: env.data,
                        invalidCached: true,
                      })
                    : LoadEntitySpecificationError({error: env.error}),
                ),
              );
          } else {
            return of(
              LoadEntitySpecificationSuccess({
                entitySpecification: cache.data,
                invalidCached: false,
              }),
            );
          }
        },
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEntitySpecificationByCode),
      withLatestCached((action) =>
        this.store.select(selectEntitySpecificationByCode(action.code)),
      ),
      exhaustBy(
        ([x]) => x.code,
        ([{code}, cache]) => {
          const someInvalid =
            cache == null ||
            cache.validUntil == null ||
            isAfter(new Date(), cache.validUntil);
          if (someInvalid) {
            return this.commonApiService
              .getEntityByCodePublic(this.ENTITY_SPEC_API_PATH, code, [
                'CHARACTERISTICS',
              ])
              .pipe(
                mergeMap((env) => {
                  if (env.success) {
                    return [
                      LoadEntitySpecificationByCodeSuccess({
                        entitySpecification: env.data,
                        invalidCached: true,
                      }),
                      LoadEntitySpecificationSuccess({
                        entitySpecification: env.data,
                        invalidCached: true,
                      }),
                    ];
                  } else {
                    return [
                      LoadEntitySpecificationByCodeError({
                        code: code,
                        error: env.error,
                      }),
                    ];
                  }
                }),
              );
          } else {
            return of(
              LoadEntitySpecificationByCodeSuccess({
                entitySpecification: cache.data,
                invalidCached: false,
              }),
            );
          }
        },
      ),
    ),
  );

  // optimalizace - soucasne s entitySpecification se ze serveru vraci i vsechny jeji charakteristiky
  // tedy je mozne rovnou naplnit s nimi store. v entitySpecification se pak udrzuji pouze ID
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEntitySpecificationSuccess),
      map(({entitySpecification}) =>
        LoadCharacteristicsByCodes({
          codes: entitySpecification.characteristics,
        }),
      ),
    ),
  );

  loadByCharacteristicId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEntitySpecificationsByCharacteristicCode),
      exhaustBy(
        (x) => x.characteristicCode,
        ({characteristicCode}) =>
          this.commonApiService
            .getAllFilterablePublic(
              this.ENTITY_SPEC_API_PATH,
              'characteristicsSpecification.characteristics.code__eq=' +
                characteristicCode,
            )
            .pipe(
              map((env) =>
                env.success
                  ? LoadEntitySpecificationsSuccess({entities: env.data})
                  : LoadEntitySpecificationsError(env.error),
              ),
            ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertEntitySpecification),
      concatMap(({specification, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(
            'v1/' + this.ENTITY_SPEC_API_PATH,
            specification,
            specification.id,
          )
          .pipe(
            mergeMap((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configFormService.effects
                    .entitySpecificationSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return [
                  UpsertEntitySpecificationSuccess({
                    specification: env.data,
                    listingId: listingId,
                    redirectToDetail: redirectToDetail,
                  }),
                  LoadEntitySpecificationSuccess({
                    entitySpecification: env.data,
                    invalidCached: true,
                  }), // kvuli refreshi charakteristik na detailu
                ];
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects
                    .entitySpecificationSaveFailure,
                );
                return [
                  UpsertEntitySpecificationError({
                    id: specification.id,
                    error: env.error,
                  }),
                ];
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteEntitySpecification),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService
          .deleteEntity('v1/' + this.ENTITY_SPEC_API_PATH, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configFormService.effects
                    .entitySpecificationDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                if (shouldRedirectToListing) {
                  this.router.navigate([`/config/entity-specifications`]);
                }
                return DeleteEntitySpecificationSuccess({
                  id: id,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects
                    .entitySpecificationDeleteFailure,
                );
                return DeleteEntitySpecificationError({
                  id: id,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffEntitySpecification),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(
            'v1/' + this.ENTITY_SPEC_API_PATH,
            diffEntities,
            compareField,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffEntitySpecificationSuccess({
                  entitySpecifications: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffEntitySpecificationError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  reloadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteEntitySpecificationSuccess, DiffEntitySpecificationSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertEntitySpecificationSuccess),
      tap(({specification, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            `/config/entity-specifications/${specification.id}`,
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      EntitySpecification,
      EntitySpecification
    >,
    private router: Router,
    private store: Store,
    private toastService: ToastService,
  ) {}
}
