<ng-container>
  <!-- hlavni rodic -->
  <ng-container *ngIf="root && item.visible !== false">
    <div *tsmHasAnyPriv="item.privilege" class="layout-menuitem-root-text">
      <span>{{ item.translateLabel }}</span>
      <i class="layout-menuitem-root-icon pi pi-fw pi-ellipsis-h"></i>
    </div>
  </ng-container>
  <!-- rodic -->
  <a
    *ngIf="((!item.routerLink && !item.url)|| item.items) && item.visible !== false"
    [attr.href]="item.url"
    (click)="itemClick($event, true)"
    [ngClass]="item.class"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.translateLabel"
    [tooltipDisabled]="!(isSlim && root && !active())"
  >
    <ng-container *ngIf="item.icon">
      <i
        *ngIf="item.icon.startsWith('tsm-')"
        class="layout-menuitem-icon {{ item.icon }}"
      ></i>
      <i
        *ngIf="!item.icon.startsWith('tsm-')"
        class="layout-menuitem-icon p-icon-{{
          item.icon.replace('p-icon-', '')
        }}"
      ></i>
    </ng-container>
    <span class="layout-menuitem-text">{{ item.translateLabel }}</span>
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>
  <!--  konecny prvek (posledni potomek) -->
  <a
    *ngIf="(item.routerLink || item.url) && !item.items && item.visible !== false"
    (click)="itemClick($event, false, item)"
    [class.active-route]="isActiveRouterLink"
    [ngClass]="item.class"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.translateLabel"
    [tooltipDisabled]="!(isSlim && root)"
    [attr.data-cy]="getElementUrl(item.routerLink)"
  >
    <i
      class="layout-menuitem-bookmark-toggler"
      [ngClass]="isMark(item) ? 'pi pi-bookmark-fill' : 'pi pi-bookmark'"
      [tsmTooltip]="
        isMark(item)
          ? (translationShared.shared.unpinTopbar | transloco)
          : (translationShared.shared.pinTopbar | transloco)
      "
      (click)="onToggleBookmark($event, item)"
      style="z-index: 100"
    >
    </i>
    <ng-container *ngIf="item.icon">
      <i
        *ngIf="item.icon.startsWith('tsm-')"
        class="layout-menuitem-icon {{ item.icon }}"
      ></i>
      <i
        *ngIf="!item.icon.startsWith('tsm-')"
        class="layout-menuitem-icon p-icon-{{
          item.icon.replace('p-icon-', '')
        }}"
      ></i>
    </ng-container>
    <span class="layout-menuitem-text">{{ item.translateLabel }}</span>
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>

  <ul
    *ngIf="item.items && item.visible !== false"
    [@children]="submenuAnimation"
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li
        *tsmHasAnyPriv="child.privilege"
        tsm-layout-menu-item
        [item]="child"
        [index]="i"
        [parentKey]="key"
        [class]="child.badgeClass"
        [bookmarkMenuItems]="bookmarkMenuItems"
        [menuPinned]="menuPinned"
        [searchCharCount]="searchCharCount"
        (toggleBookmark)="toggleBookmark.emit($event)"
      ></li>
    </ng-template>
  </ul>
</ng-container>
