import {SelectItem} from 'primeng/api/selectitem';

export enum NotificationTemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export function getAllNotificationTempalteStatuses(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(NotificationTemplateStatus).forEach((val) =>
    items.push({
      label: NotificationTemplateStatus[val],
      value: val,
    }),
  );
  return items;
}
