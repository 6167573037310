export const en = {
  processPlugins: {
    activeFrom: 'Active from',
    activeTo: 'Active to',
    activeTotal: 'Active total',
    taskTerm: 'Task term',
    reminder: 'Reminder',
    console: 'Console',
    scheduler: 'Scheduler',
    selectTaskStatus: 'Select task status',
    endTask: 'End task',
    progressBar: 'Progress bar',
    incident: 'Incident',
    incidentMessage: 'Incident message',
    decisionName: 'Decision name',
    decisionKey: 'Decision key',
    decisionInputs: 'Decision inputs',
    decisionOutputs: 'Decision outputs',
    filters: {
      tsmDeployStateFilter: 'tSM Deploy State Filter',
      tsmDeployStateFilterDescription:
        'Defines a filter component for selecting deployment states. ' +
        'Extends a table filter listing parent component and provides ' +
        'a multi-select interface. Loads deployment state data dynamically ' +
        'from a process service. Implements an overlay panel with options ' +
        'for selection and confirmation.',
      tsmProcessGroupActionFilter: 'tSM Process Group Action Filter',
      tsmProcessGroupActionFilterDescription:
        'Defines a filter component for selecting process group actions. ' +
        'Extends a table filter listing parent component and provides ' +
        'a multi-select interface. Loads process group action data dynamically ' +
        'from a service. Implements an overlay panel with options for ' +
        'selection and confirmation.',
      tsmProcessGroupFilter: 'tSM Process Group Filter',
      tsmProcessGroupFilterDescription:
        'Implements a process group filter component with multi-selection support. ' +
        'Extends a table filter listing parent component and enables filtering ' +
        'and selecting process groups from a dynamically loaded list. Provides ' +
        'options for removal and confirmation, using an overlay panel for selection.',
      tsmProcessStateFilter: 'tSM Process State Filter',
      tsmProcessStateFilterDescription:
        'Implements a process state filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to filter ' +
        'process states from a dynamically retrieved list. Provides options for ' +
        'removal and confirmation, using an overlay panel for selection.',
      tsmProcessTypeFilter: 'tSM Process Type Filter',
      tsmProcessTypeFilterDescription:
        'Implements a process type filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to filter ' +
        'process types retrieved from an API. Provides options for selection, removal, ' +
        'and confirmation, using an overlay panel for interaction.',
      tsmTaskFilter: 'tSM Task Filter',
      tsmTaskFilterDescription:
        'Implements a task filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to ' +
        'filter task definitions retrieved from an API. Provides options ' +
        'for selection, removal, and confirmation, using an overlay panel ' +
        'for interaction.',
      tsmTaskStatusFilter: 'tSM Task Status Filter',
      tsmTaskStatusFilterDescription:
        'Implements a task status filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to ' +
        'filter task statuses retrieved from an API. Provides options for ' +
        'selection, removal, and confirmation, using an overlay panel for ' +
        'interaction.',
      tsmTaskStatusGroupFilter: 'tSM Task Status Group Filter',
      tsmTaskStatusGroupFilterDescription:
        'Implements a task status group filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to filter ' +
        'task status groups retrieved from an API. Provides options for selection, ' +
        'removal, and confirmation, using an overlay panel for interaction.',
      tsmTaskTypeFilter: 'tSM Task Type Filter',
      tsmTaskTypeFilterDescription:
        'Implements a task type filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to filter ' +
        'task types retrieved from an API. Provides options for selection, removal, ' +
        'and confirmation, using an overlay panel for interaction.',
    },
    pipes: {
      dmnDefinitionCode: 'Dmn Definition Code',
      dmnDefinitionCodeDescription:
        'Loads and retrieves DMN definition data by its code and deployment status. If data is available, ' +
        'returns the specified field or complete data; otherwise, returns the provided ID. Initiates ' +
        'dispatch to load the data if it is not already in the store.',
      priorityIcon: 'Priority Icon',
      priorityIconDescription:
        'Generates an HTML representation of a priority icon with its name. Retrieves priority details ' +
        'from a register service based on the provided value and optional argument. If a style is ' +
        'available, it includes an icon; otherwise, it displays the name only.',
      processDefinitionCodeDeployed: 'Process Definition Code Deployed',
      processDefinitionCodeDeployedDescription:
        'Fetches and manages data for a deployed process definition by code. Uses the provided ID to ' +
        'select or load process definition details and optionally extracts a specific field if defined. ' +
        'Ensures that the data is fetched and cached efficiently for reuse.',
      processDefinitionCodeVersion: 'Process Definition Code Version',
      processDefinitionCodeVersionDescription:
        'Handles fetching and caching process definition details based on code and version. Uses the ' +
        'provided code and version to select or load relevant data and optionally extract specific fields. ' +
        'Ensures efficient retrieval and reuse of process definition information.',
      processDefinitionCode: 'Process Definition Code',
      processDefinitionCodeDescription:
        'Handles loading and caching of process definitions by their code. Filters and selects ' +
        'deployed processes or the latest inserted version if none are deployed. Provides access to ' +
        'specific fields based on the input arguments while ensuring efficient state management.',
      processDefinitionId: 'Process Definition Id',
      processDefinitionIdDescription:
        'Retrieves process definition details by ID from the store. Supports accessing specific ' +
        'fields based on input arguments or returning the full data object. Dispatches an action ' +
        'to load the process definition if it is not already available in the store.',
      processGroupCode: 'Process Group Code',
      processGroupCodeDescription:
        'Loads and retrieves process group details by code from the store. Returns a specific field ' +
        'from the process group data if specified, or the entire data object otherwise. Dispatches ' +
        'an action to load the process group if it is not present in the store.',
      processGroupId: 'Process Group Id',
      processGroupIdDescription:
        'Retrieves process group details by ID from the store. Provides a specific field from the ' +
        'process group data if defined, or the full data object otherwise. If the process group is ' +
        'not found in the store, dispatches an action to load it.',
      processTypeCode: 'Process Type Code',
      processTypeCodeDescription:
        'Fetches process type details from the store using a unique code. Allows returning specific ' +
        'fields from the process type data if specified, or the entire data object. Triggers an action ' +
        'to load the process type if it is not found in the store.',
      processTypeId: 'Process Type Id',
      processTypeIdDescription:
        'Retrieves process type details from the store using a unique ID. Returns specific field values ' +
        'or the complete data object, depending on the field argument. Initiates an action to load the ' +
        'process type details if they are not found in the store.',
      taskActionTemplateCode: 'Task Action Template Code',
      taskActionTemplateCodeDescription:
        'Fetches task action template details using a unique code. Retrieves specific field values or the ' +
        'complete data object based on the provided field argument. Dispatches an action to load the ' +
        'task action template if it is not already available in the store.',
      taskActionTemplateId: 'Task Action Template Id',
      taskActionTemplateIdDescription:
        'Fetches task action template details using a unique identifier. Retrieves specific field values ' +
        'or the complete data object based on the provided field argument. Dispatches an action to load ' +
        'the task action template if it is not already available in the store.',
      taskDefinitionCode: 'Task Definition Code',
      taskDefinitionCodeDescription:
        'Retrieves task definition data using a unique code. Returns specific field values or the entire ' +
        'data object based on the provided argument. Automatically dispatches an action to load the task ' +
        'definition if it is not already stored.',
      taskDefinitionId: 'Task Definition Id',
      taskDefinitionIdDescription:
        'Fetches task definition data using a unique identifier. Allows retrieval of specific fields or ' +
        'complete data based on the input argument. Initiates an action to load the task definition if ' +
        'it is not present in the store.',
      taskLink: 'Task Link',
      taskLinkDescription:
        'Generates a hyperlink to a task within a specific process instance. Constructs a URL using the ' +
        'specified module and task reference ID from the provided row data. Displays the provided value as ' +
        'the visible text of the link.',
      taskStatusCodeColor: 'Task Status Code Color',
      taskStatusCodeColorDescription:
        'Generates a stylized status label for tasks based on their status code and data. Retrieves ' +
        'task status details from the store and constructs a colored label with translated text. If no ' +
        'status code is provided but row data indicates active tasks, it shows an "active" status. ' +
        'Otherwise, it defaults to a "finished" status with a gray label.',
      taskStatusCode: 'Task Status Code',
      taskStatusCodeDescription:
        'Retrieves task status information based on a given code and returns specific field data or the ' +
        'entire object. If no data is available, the input code is returned. Uses the store to manage ' +
        'state and triggers data loading when needed.',
      taskStatusGroupCode: 'Task Status Group Code',
      taskStatusGroupCodeDescription:
        'Fetches task status group details based on a given code and provides a specific field or the ' +
        'entire object. If no data exists, the input code is returned. Manages state using the store ' +
        'and initiates data loading as required.',
      taskStatusGroupId: 'Task Status Group Id',
      taskStatusGroupIdDescription:
        'Retrieves task status group details by ID and returns a specific field or the full object. If ' +
        'data is unavailable, the provided ID is returned. Automatically manages state and triggers ' +
        'data loading if necessary.',
      taskStatusIcon: 'Task Status Icon',
      taskStatusIconDescription:
        'Generates a task status icon using a style class and name from the status register. Retrieves ' +
        'data by code and constructs a styled span element with the task status name. Returns an empty ' +
        'string if the value is not provided.',
      taskStatusId: 'Task Status Id',
      taskStatusIdDescription:
        'Fetches task status details by its ID and returns a specific field or all data based on the ' +
        'provided arguments. Uses a selector to retrieve the task status from the store and triggers ' +
        'an action to load the status if not available.',
      taskUnassignedSubtask: 'Task Unassigned Subtask',
      taskUnassignedSubtaskDescription:
        'Checks if the task contains any active subtasks that are unassigned and identified as ' +
        'subtasks for approving persons. Uses BooleanTranslatePipe to convert the boolean result ' +
        'into a localized string.',
      ticketTaskById: 'Ticket Task By Id',
      ticketTaskByIdDescription:
        'Provides task data based on a combined activity and execution ID. Splits the compound task ID ' +
        'to extract activity and execution IDs, then retrieves the corresponding task data from the ' +
        'store. Supports accessing nested properties using dot-separated paths.',
    },
    controls: {
      header: 'Header',
      assigned: 'Assigned',
      location: 'Task address',
      time: 'Task times',
      worker: 'Worker',
      divider: 'Divider line',
      skillAndRegion: 'Skills and region',
      description: 'Task description',
      cost: 'Cost',
      footer: 'Footer',
    },
  },
};
