import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteConfigType,
  DeleteConfigTypeError,
  DeleteConfigTypeSuccess,
  DiffConfigType,
  DiffConfigTypeError,
  DiffConfigTypeSuccess,
  LoadConfigType,
  LoadConfigTypeByCode,
  LoadConfigTypeByCodeError,
  LoadConfigTypeByCodeSuccess,
  LoadConfigTypeById,
  LoadConfigTypeByIdError,
  LoadConfigTypeByIdSuccess,
  LoadConfigTypeError,
  LoadConfigTypeSuccess,
  PatchConfigType,
  PatchConfigTypeError,
  PatchConfigTypeSuccess,
  UpsertConfigType,
  UpsertConfigTypeError,
  UpsertConfigTypeSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {CommonApiService} from '../services';
import {ConfigType} from '../models';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {exhaustBy, withLatestCached} from '@tsm/framework/root';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectConfigTypeByCode} from '../selectors';

@Injectable()
export class ConfigTypeEffects {
  private readonly CONFIG_TYPE_API_PATH = 'v1/config-types';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadConfigType),
      exhaustMap(() =>
        this.commonApiService
          .getAll(`${this.CONFIG_TYPE_API_PATH}/all`)
          .pipe(
            map((env) =>
              env.success
                ? LoadConfigTypeSuccess({configTypes: env.data})
                : LoadConfigTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadConfigTypeById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.CONFIG_TYPE_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadConfigTypeByIdSuccess({entity: env.data})
                : LoadConfigTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadConfigTypeByCode),
      withLatestCached(({code}) =>
        this.store.select(selectConfigTypeByCode(code)),
      ),
      exhaustBy(
        ([{code}, _]) => code,
        ([{code}, type]) =>
          type && type.data
            ? of(LoadConfigTypeByCodeSuccess({entity: type.data}))
            : this.commonApiService
                .getEntity(this.CONFIG_TYPE_API_PATH, code)
                .pipe(
                  map((env) =>
                    env.success
                      ? LoadConfigTypeByCodeSuccess({entity: env.data})
                      : LoadConfigTypeByCodeError({code, error: env.error}),
                  ),
                ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertConfigType),
      concatMap(({configType, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(this.CONFIG_TYPE_API_PATH, configType, configType.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configTypeService.effects
                    .configTypeSaveSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertConfigTypeSuccess({
                  configType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configTypeService.effects
                    .configTypeSaveUnSuccessful,
                );
                return UpsertConfigTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatchConfigType),
      concatMap(({id, configType, listingId, redirect}) =>
        this.commonApiService
          .patchEntity(this.CONFIG_TYPE_API_PATH, id, configType)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configTypeService.effects
                    .messageConfigTypeChangeSuccessful,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return PatchConfigTypeSuccess({
                  id: env.data.id,
                  listingId: listingId,
                  configType: env.data,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configTypeService.effects
                    .messageConfigTypeSaveUnSuccessful,
                );
                return PatchConfigTypeError({id, error: env.error});
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteConfigType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.CONFIG_TYPE_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.configTypeService.effects.configTypeDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/config-type']);
              }
              return DeleteConfigTypeSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.configTypeService.effects.configTypeDeleteError,
              );
              return DeleteConfigTypeError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffConfigType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/config-types', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffConfigTypeSuccess({
                  configTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffConfigTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertConfigTypeSuccess, PatchConfigTypeSuccess),
      tap(({configType, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/config/config-type', configType.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteConfigTypeSuccess, DiffConfigTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private commonApiService: CommonApiService<ConfigType, ConfigType>,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
