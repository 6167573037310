import * as effects from './effects';
import * as services from './services';
import {DmsInjectableRxStompConfig} from './services';
import * as reducers from './reducers';
import * as pipes from './pipes';
import {ConfirmationService} from 'primeng/api';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';
import {RxStompService, rxStompServiceFactory} from '@tsm/framework/websocket';
import {FrameworkPluginModule} from '@tsm/framework/plugin';
import {AttachmentCountDataSource} from './datasources/attachment-count.data-source';
import {FormatterDocumentTemplateDataSource} from './datasources/formatter-document-template.data-source';

export const IMPORTS = [
  StoreModule.forFeature('dms', reducers.reducers),
  EffectsModule.forFeature([
    effects.DlqEffects,
    effects.AttachmentEffects,
    effects.CommentEffects,
    effects.NotificationEffect,
    effects.NotificationMyEffects,
    effects.NotificationCountEffects,
    effects.NotificationTemplateEffect,
    effects.NotificationRuleEffects,
    effects.FormatterTemplateEffect,
    effects.FormatterDocumentTemplateEffect,
    effects.SharingEffects,
    effects.AttachmentTypeEffects,
    effects.CommentTypeEffects,
    effects.KafkaTopicEffects,
    effects.WorklogEffects,
    effects.WorklogTypeEffects,
    effects.CountAttachmentsEffects,
    effects.WorklogTimerEffects,
    effects.WorklogMyEffects,
    effects.DeliveryTimeEffects,
  ]),
  FrameworkPluginModule.registerPlugin({
    menus: [],
    routes: [],
    widgets: [],
    dataSources: [
      {
        dataSource: AttachmentCountDataSource,
        name: 'attachmentCount',
        initialValue: '0',
      },
      {
        dataSource: FormatterDocumentTemplateDataSource,
        name: 'formatterDocumentTemplate',
        initialValue: null,
      },
    ],
  }),
  TranslateModule.forFeature(localizations),
];

// TODO, todle by melo byt forRoot nebo vyreseno jinak, jinak kazdy lazy loaded module dostane vlastni instanci
export const SERVICES = [
  services.AttachmentService,
  services.AttachmentDataService,
  services.CommentDataService,
  services.WorklogDataService,
  services.WorklogTypeDataService,
  services.CommentService,
  services.NotificationService,
  services.NotificationMyService,
  services.NotificationTemplateService,
  services.NotificationRuleService,
  services.FormatterService,
  services.FormatterTemplateService,
  services.FormatterDocumentTemplateService,
  services.SharingService,
  services.SharingTypeService,
  services.KafkaTopicService,
  services.KafkaDlqService,
  ConfirmationService,
  {
    provide: RxStompService,
    useFactory: rxStompServiceFactory,
    deps: [DmsInjectableRxStompConfig],
  },
  services.CommonApiService,
];

export const PIPES = [
  pipes.SafeHtmlPipe,
  pipes.NotificationTemplatePipe,
  pipes.NotificationTemplateByCodePipe,
  pipes.FormatterTemplateLoaderPipe,
  pipes.FormatterDocumentTemplateLoaderPipe,
  pipes.EntityNameLoaderPipe,
  pipes.MinutesToTextPipe,
  pipes.CountAttachmentsPipe,
  pipes.NotificationRelatedEntityByIdPipe,
  pipes.NotificationAddressPipe,
  pipes.NotificationCustomerByIdPipe,
  pipes.NotificationRuleCodePipe,
  pipes.ContainsPipe,
  pipes.NotificationToOwnerTypePipe,
];
