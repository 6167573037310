import {ModuleWithProviders, NgModule} from '@angular/core';
import {
  FrameworkShortcutsConfiguration,
  SHORTCUTS_CONFIGURATION,
} from './di/configuration.model';
import {HotkeyDirectiveModule} from './directives/hotkey.directive';

@NgModule({
  imports: [HotkeyDirectiveModule],
  exports: [HotkeyDirectiveModule],
})
export class FrameworkShortcutsModule {
  static forRoot(
    configuration?: FrameworkShortcutsConfiguration,
  ): ModuleWithProviders<FrameworkShortcutsModule> {
    return configuration
      ? {
          ngModule: FrameworkShortcutsModule,
          providers: [
            {
              provide: SHORTCUTS_CONFIGURATION,
              useValue: configuration,
            },
          ],
        }
      : {
          ngModule: FrameworkShortcutsModule,
          providers: [],
        };
  }
}
