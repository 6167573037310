import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '@tsm/shared-i18n';

@Pipe({
  name: 'booleanTranslate',
})
export class BooleanTranslatePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  /**
   * @param value převáděná hodnota
   * @param nullable pokud je true, nebude hodnota NULL považována za "FALSE", ale za prázdnou hodnotu
   */
  transform(value: any, nullable?: any): any {
    if (Array.isArray(value)) {
      if (value?.length == 0 || !value.some((x) => !!x)) {
        if (
          (nullable == true || nullable == 'true') &&
          !value.some((x) => !!x)
        ) {
          return '';
        }
        return this.translocoService.translate(translation.shared.no);
      }
      return this.translocoService.translate(translation.shared.yes);
    }

    if (!value) {
      if ((nullable == true || nullable == 'true') && value == null) {
        return '';
      }
      return this.translocoService.translate(translation.shared.no);
    }
    return this.translocoService.translate(translation.shared.yes);
  }
}
