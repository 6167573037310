export const en = {
  userManagementPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmPrivTypeFilter: 'tSM Priv Type Filter',
      tsmPrivTypeFilterDescription:
        'Provides a filter component for selecting privilege types. ' +
        'Displays a multi-selection data view with filtering and sorting capabilities. ' +
        'Dynamically loads privilege types from the backend and maps them to selectable ' +
        'options. Uses a structured table layout with configurable columns and view ' +
        'settings. Applies and emits selected values upon confirmation.',
      tsmRoleFilter: 'tSM Role Filter',
      tsmRoleFilterDescription:
        'Implements a role selection filter component with a structured data view. ' +
        'Supports multi-selection and dynamic filtering of user roles. ' +
        'Fetches role data from the backend and maps it to selectable options. ' +
        'Allows configuration of the identifier field for filtering. ' +
        'Emits selected roles and their display values on confirmation.',
      tsmUserFilter: 'tSM User Filter',
      tsmUserFilterDescription:
        'Implements a user selection filter with support for filtering by current and ' +
        'undefined users. Displays a structured data view with multiple selection options. ' +
        'Allows users to search and select specific user entries. ' +
        'Provides checkboxes for selecting the current user or undefined users. ' +
        'Automatically updates selected values based on user interactions. ' +
        'Emits selected user data and display values on confirmation.',
      tsmUserGroupFilter: 'tSM User Group Filter',
      tsmUserGroupFilterDescription:
        'Implements a user group selection filter with multiple selection options. ' +
        'Allows filtering by current user groups and undefined user groups. ' +
        'Displays a structured data view with search and selection capabilities. ' +
        'Automatically updates the selected values based on form interactions. ' +
        'Supports filtering by specific user group properties if a context is provided. ' +
        'Emits the selected user groups and display values when confirmed.',
      tsmUserGroupTypeFilter: 'tSM User Group Type Filter',
      tsmUserGroupTypeFilterDescription:
        'Implements a user group type selection filter with multi-selection support. ' +
        'Displays a structured data view with search and filtering capabilities. ' +
        'Allows filtering by user group type codes and names with full-text search. ' +
        'Sorts results in ascending order by code. ' +
        'Emits the selected user group types and display values when confirmed.',
      tsmUserStatusFilter: 'tSM User Status Filter',
      tsmUserStatusFilterDescription:
        'Implements a user status selection filter with multi-selection support. ' +
        'Displays a structured data view with selectable user statuses. ' +
        'Fetches status options dynamically and translates status names. ' +
        'Allows selecting all statuses or individual ones with checkboxes. ' +
        'Emits selected statuses and their display values when confirmed.',
      tsmUserTypeEnumFilter: 'tSM User Type Enum Filter',
      tsmUserTypeEnumFilterDescription:
        'Implements a multi-selection filter for user type enumeration. ' +
        'Displays selectable user types in a structured data view. ' +
        'Dynamically fetches user type options and translates their names. ' +
        'Supports selecting all or individual user types using checkboxes. ' +
        'Emits selected user types and their display values when confirmed.',
      tsmUserTypeFilter: 'tSM User Type Filter',
      tsmUserTypeFilterDescription:
        'Implements a multi-selection filter for user types. ' +
        'Displays available user types in a structured data view. Allows ' +
        'filtering by code or name with full-text search support. Enables users ' +
        'to select multiple user types and manage selections dynamically. Retrieves ' +
        'user type data from the API and updates the filter state on selection.',
    },
    pipes: {
      checkOnline: 'Check Online',
      checkOnlineDescription:
        'Checks if a user or system is online based on the last update timestamp. ' +
        'Fetches online status and determines if the last update was within five minutes.',
      privLoaderByCode: 'Priv Loader By Code',
      privLoaderByCodeDescription:
        'Loads and retrieves privilege details based on a given code. ' +
        'Fetches the privilege from the store and provides specific field data.',
      privLoader: 'Priv Loader',
      privLoaderDescription:
        'Retrieves privilege details using a given ID. ' +
        'Loads the privilege from the store and provides specific field data.',
      roleCode: 'Role Code',
      roleCodeDescription:
        'Retrieves role details using a given code. ' +
        'Loads the role from the store and provides specific field data.',
      role: 'Role',
      roleDescription:
        'Retrieves role details using a given ID. ' +
        'Loads the role from the store and provides specific field data.',
      userByCode: 'User By Code',
      userByCodeDescription:
        'Retrieves user details based on a provided code. ' +
        'Loads the user from the store and returns specified field data.',
      userGroupInfoCode: 'User Group Info Code',
      userGroupInfoCodeDescription:
        'Retrieves user group information based on a given code. ' +
        'Loads the group details from the store and returns specified field data.',
      userGroupInfo: 'User Group Info',
      userGroupInfoDescription:
        'Retrieves user group details using a given ID. ' +
        'Loads group data from the store and returns a specific field value.',
      userGroupTypeCode: 'User Group Type Code',
      userGroupTypeCodeDescription:
        'Retrieves user group type details using a given code. ' +
        'Loads type data from the store and returns a specific field value.',
      userGroupType: 'User Group Type',
      userGroupTypeDescription:
        'Retrieves user group type details using a given ID. ' +
        'Loads type data from the store and returns a specific field value.',
      userGroupsInfoCode: 'User Groups Info Code',
      userGroupsInfoCodeDescription:
        'Retrieves details of multiple user groups using an array of codes. ' +
        'Loads user group data from the store and returns selected field values.',
      userGroupsInfo: 'User Groups Info',
      userGroupsInfoDescription:
        'Fetches information about multiple user groups using an array of IDs. ' +
        'Loads user group data from the store and returns specified field values.',
      userImageComponent: 'User Image Component',
      userImageComponentDescription:
        'Filters unique values from the input array and returns distinct user image ' +
        'components. Ensures that duplicate entries are removed from the result data.',
      userImage: 'User Image',
      userImageDescription:
        "Loads and displays a user's profile image from the store. Supports " +
        'loading multiple user images and displaying them as avatars. If no image ' +
        'is available, it generates wildcards based on user initials.',
      userParameter: 'User Parameter',
      userParameterDescription:
        'Resolves a user parameter key to its translated label. Retrieves ' +
        'the localized value using TranslocoService based on the provided key.',
      userTypeIcon: 'User Type Icon',
      userTypeIconDescription:
        'Retrieves and displays a user type with an associated icon. If an icon ' +
        'is available, it is shown alongside the user type name.',
      userType: 'User Type',
      userTypeDescription:
        'Retrieves and displays user type information from the store. Returns ' +
        'a specific field or the full data object if requested.',
      userUserGroupRole: 'User User Group Role',
      userUserGroupRoleDescription:
        'Transforms an array of user group roles into translated labels. Uses ' +
        'translations from the shared i18n module and user-specific dictionary.',
      userUserGroup: 'User User Group',
      userUserGroupDescription:
        'Retrieves and displays the name of a user or user group. Determines ' +
        'the type dynamically and selects the appropriate data from the store.',
      user: 'User',
      userDescription:
        'Fetches and displays user details based on the given ID. Uses ' +
        'store to load data and return the specified field value.',
    },
  },
};
