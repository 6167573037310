import {
  Directive,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {FormControlDirective, NgControl} from '@angular/forms';
import {TsmFormControl} from '../controls';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';

@Directive({
  selector: '[tsmFormControl]',
})
export class TsmFormControlDirective
  extends FormControlDirective
  implements OnChanges, OnDestroy
{
  @Input('tsmFormControl') form: TsmFormControl;
  @Input() useFormControl = false;

  ngOnChanges(changes: SimpleChanges) {
    if (this.form == null) {
      return;
    }
    // use old behavior
    if (this.useFormControl) {
      super.ngOnChanges(changes);
      return;
    }
    // custom logic
    if (this.isControlChanged(changes)) {
      this.setup(this.form, this);
    }
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    // use old behavior
    if (this.useFormControl) {
      super.ngOnDestroy();
      return;
    }
    // custom logic
    if (this.form) {
      this.cleanup(this.form);
    }
    super.ngOnDestroy();
  }

  private setup(control: TsmFormControl, dir: NgControl) {
    if ((dir.valueAccessor as AbstractControlValueAccessor)!.setReadonlyState) {
      const onReadonlyChange = (readonly: boolean) => {
        (dir.valueAccessor as AbstractControlValueAccessor)!.setReadonlyState!(
          readonly,
        );
      };
      control.registerOnReadonly(onReadonlyChange);
    }
  }

  private cleanup(control: TsmFormControl) {
    control._unregisterOnReadonly();
  }

  private isControlChanged(changes: {[key: string]: any}): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return changes.hasOwnProperty('form');
  }
}

@NgModule({
  declarations: [TsmFormControlDirective],
  exports: [TsmFormControlDirective],
})
export class TsmFormControlDirectiveModule {}
