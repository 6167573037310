import {createAction, props} from '@ngrx/store';
import {FormatterTemplate} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadFormatterTemplateById = createAction(
  '[FormatterTemplate] Load data',
  props<{id: string}>(),
);
export const LoadFormatterTemplateByIdSuccess = createAction(
  '[FormatterTemplate] Load data Success',
  props<{
    formatterTemplate: FormatterTemplate;
  }>(),
);
export const LoadFormatterTemplateByIdError = createAction(
  '[FormatterTemplate] Load data ERROR',
  props<{
    id: string;
    error: any;
  }>(),
);

export const UpsertFormatterTemplate = createAction(
  '[FormatterTemplate] Upsert data',
  props<{
    formatterTemplate: FormatterTemplate;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertFormatterTemplateSuccess = createAction(
  '[FormatterTemplate] Upsert data Success',
  props<{
    formatterTemplate: FormatterTemplate;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertFormatterTemplateError = createAction(
  '[FormatterTemplate] Upsert data Error',
  props<{
    error: any;
  }>(),
);

export const DeleteFormatterTemplate = createAction(
  '[FormatterTemplate] Delete data',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteFormatterTemplateSuccess = createAction(
  '[FormatterTemplate] Delete data Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteFormatterTemplateError = createAction(
  '[FormatterTemplate] Delete data Error',
  props<TsmError>(),
);

export const DiffFormatterTemplate = createAction(
  '[FormatterTemplate] Diff FormatterTemplate',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffFormatterTemplateSuccess = createAction(
  '[FormatterTemplate] Diff FormatterTemplate Success',
  props<{
    formatterTemplates: FormatterTemplate[];
    listingId: string;
  }>(),
);
export const DiffFormatterTemplateError = createAction(
  '[FormatterTemplate] Diff FormatterTemplate Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
