// manual imports
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '../i18n';

// IDE imports
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {
  DeleteNotificationRule,
  DeleteNotificationRuleError,
  DeleteNotificationRuleSuccess,
  DiffNotificationRule,
  DiffNotificationRuleError,
  DiffNotificationRuleSuccess,
  LoadNotificationRuleByCode,
  LoadNotificationRuleByCodeError,
  LoadNotificationRuleByCodeSuccess,
  LoadNotificationRuleById,
  LoadNotificationRuleByIdError,
  LoadNotificationRuleByIdSuccess,
  UpsertNotificationRule,
  UpsertNotificationRuleError,
  UpsertNotificationRuleSuccess,
} from '../actions';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {exhaustBy} from '@tsm/framework/root';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {CommonApiService, NotificationRuleService} from '../services';
import {Router} from '@angular/router';
import {NotificationRule} from '../models';

@Injectable()
export class NotificationRuleEffects {
  translation = translation;
  translationShared = translationShared;

  private readonly ENTITY_PATH = 'notification-rules';

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotificationRuleById),
      mergeMap(({id}) =>
        this.notificationRuleService
          .getNotificationRuleById(id)
          .pipe(
            map((env) =>
              env.success
                ? LoadNotificationRuleByIdSuccess({notificationRule: env.data})
                : LoadNotificationRuleByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotificationRuleByCode),
      concatMap(({code}) =>
        this.commonApiService.getItemsById('code', code, this.ENTITY_PATH).pipe(
          map((env) =>
            env.success && env.data
              ? LoadNotificationRuleByCodeSuccess({
                  notificationRule: env.data,
                })
              : LoadNotificationRuleByCodeError({code, error: env.error}),
          ),
        ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertNotificationRule),
      exhaustBy(
        (x) => x.notificationRule,
        ({notificationRule, listingId, redirectToDetail}) => {
          return this.notificationRuleService
            .saveNotificationRule(notificationRule)
            .pipe(
              tap((env) => {
                if (env.error) {
                  this.ts.showError(
                    env.error,
                    translation.dmsService.unSuccNotifRuleSave,
                  );
                } else if (env.success) {
                  this.ts.showToast(
                    translation.dmsService.succNotifRuleSave,
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                }
              }),
              map((env) =>
                env.success
                  ? UpsertNotificationRuleSuccess({
                      notificationRule: env.data,
                      listingId: listingId,
                      redirectToDetail: redirectToDetail,
                    })
                  : UpsertNotificationRuleError(env.error),
              ),
            );
        },
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteNotificationRule),
      exhaustBy(
        (x) => x.id,
        ({id, listingId, shouldRedirectToListing}) => {
          return this.notificationRuleService
            .deleteNotificationRuleById(id)
            .pipe(
              tap((env) => {
                if (env.success) {
                  this.ts.showToast(
                    translation.dmsService.succNotifRuleDelete,
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                  if (shouldRedirectToListing) {
                    this.router.navigate(['/dms/notification-rule']);
                  }
                } else {
                  this.ts.showError(
                    env.error,
                    translation.dmsService.unSuccNotifRuleDelete,
                  );
                }
              }),
              map((env) =>
                env.success
                  ? DeleteNotificationRuleSuccess({
                      id: id,
                      listingId: listingId,
                    })
                  : DeleteNotificationRuleError(env.error),
              ),
            );
        },
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffNotificationRule),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/notification-rules', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffNotificationRuleSuccess({
                  notificationRules: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffNotificationRuleError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertNotificationRuleSuccess),
      tap(({notificationRule, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate(['/dms/notification-rule', notificationRule.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteNotificationRuleSuccess, DiffNotificationRuleSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationRuleService: NotificationRuleService,
    private store$: Store,
    private ts: ToastService,
    private router: Router,
    private commonApiService: CommonApiService<
      NotificationRule,
      NotificationRule
    >,
  ) {}
}
