import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {UserUserGroup} from '../model';

export const LoadUserUserGroups = createAction(
  '[UserUserGroup] Load UserUserGroups',
);
export const LoadUserUserGroupsSuccess = createAction(
  '[UserUserGroup] Load UserUserGroups Success',
  props<{
    entities: UserUserGroup[];
  }>(),
);
export const LoadUserUserGroupsError = createAction(
  '[UserUserGroup] Load UserUserGroups Error',
  props<{
    error: any;
  }>(),
);

export const LoadUserUserGroupsByUserId = createAction(
  '[UserUserGroup] Load UserUserGroups ByUserId',
  props<{
    userId: string;
  }>(),
);
export const LoadUserUserGroupsByUserIdSuccess = createAction(
  '[UserUserGroup] Load UserUserGroups ByUserId Success',
  props<{
    entities: UserUserGroup[];
  }>(),
);
export const LoadUserUserGroupsByUserIdError = createAction(
  '[UserUserGroup] Load UserUserGroups ByUserId Error',
  props<{
    error: any;
  }>(),
);

export const LoadUserUserGroupsByGroupId = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupId',
  props<{
    groupId: string;
  }>(),
);
export const LoadUserUserGroupsByGroupIds = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupIds',
  props<{
    groupIds: string[];
  }>(),
);
export const LoadUserUserGroupsByGroupIdSuccess = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupId Success',
  props<{
    entities: UserUserGroup[];
  }>(),
);
export const LoadUserUserGroupsByGroupIdError = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupId Error',
  props<{
    error: any;
  }>(),
);

export const LoadUserUserGroupById = createAction(
  '[UserUserGroup] Load UserUserGroups ById',
  props<{id: string}>(),
);
export const LoadUserUserGroupByIdSuccess = createAction(
  '[UserUserGroup] Load UserUserGroups ById Success',
  props<{
    userUserGroup: UserUserGroup;
  }>(),
);
export const LoadUserUserGroupByIdError = createAction(
  '[UserUserGroup] Load UserUserGroups ById Error',
  props<{
    error: any;
  }>(),
);

export const LoadUserUserGroupsByGroupCode = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupCode',
  props<{
    code: string;
  }>(),
);
export const LoadUserUserGroupsByGroupCodeSuccess = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupCode Success',
  props<{
    entities: UserUserGroup[];
  }>(),
);
export const LoadUserUserGroupsByGroupCodeError = createAction(
  '[UserUserGroup] Load UserUserGroups ByGroupCode Error',
  props<{
    error: any;
  }>(),
);

export const UpsertUserUserGroup = createAction(
  '[UserUserGroup] Upsert UserUserGroup',
  props<{
    userUserGroup: UserUserGroup;
    listingId?: string;
  }>(),
);
export const UpsertUserUserGroupSuccess = createAction(
  '[UserUserGroup] Upsert UserUserGroup Success',
  props<{
    userUserGroup: UserUserGroup;
    listingId?: string;
  }>(),
);
export const UpsertUserUserGroupError = createAction(
  '[UserUserGroup] Upsert UserUserGroup Error',
  props<TsmError>(),
);

export const SaveAllUserUserGroups = createAction(
  '[UserUserGroup] Save All UserUserGroups',
  props<{
    entities: UserUserGroup[];
    listingId?: string;
  }>(),
);
export const SaveAllUserUserGroupsSuccess = createAction(
  '[UserUserGroup] Save All UserUserGroups Success',
  props<{
    entities: UserUserGroup[];
    listingId?: string;
  }>(),
);
export const SaveAllUserUserGroupsError = createAction(
  '[UserUserGroup] Save All UserUserGroups Error',
  props<{
    error: any;
  }>(),
);

export const DeleteUserUserGroup = createAction(
  '[UserUserGroup] Delete UserUserGroup',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteUserUserGroupSuccess = createAction(
  '[UserUserGroup] Delete UserUserGroup Success',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteUserUserGroupError = createAction(
  '[UserUserGroup] Delete UserUserGroup Error',
  props<TsmError>(),
);

export const DeleteAllUserUserGroups = createAction(
  '[UserUserGroup] Delete All UserUserGroup',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllUserUserGroupsSuccess = createAction(
  '[UserUserGroup] Delete All UserUserGroups Success',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllUserUserGroupsError = createAction(
  '[UserUserGroup] Delete All UserUserGroups Error',
  props<{
    error: any;
  }>(),
);
