import 'reflect-metadata';

export interface ActionReducer {
  type: string;
  payload?: any;
}

interface Methods<State> {
  [key: string]: (state: State, payload: any) => State;
}

export function createReducer<State>(
  Reducer: {new (): any},
  initialState: State,
) {
  const instance = Object.create(Reducer.prototype);
  const reducers = getReducerMethods(Reducer)(instance);

  return (prevState: State = initialState, action: ActionReducer): State => {
    const fn = reducers[action.type];
    const exists = fn && typeof fn === 'function';
    const reducer = exists
      ? (...args: any[]): State => fn.apply(instance, args)
      : undefined;
    const nextState = reducer ? reducer(prevState, action) : prevState;
    return nextState;
  };
}

function getReducerMethods<State>(Reducer: {new (): any}) {
  const prototype = Reducer.prototype;
  const methods = Object.getOwnPropertyNames(prototype);

  const getMetadata = (method: string) => {
    const meta = Reflect.getMetadata('design:paramtypes', prototype, method);
    const action = meta && meta[1];

    return action ? new action().type : undefined;
  };

  return (instance: any): Methods<State> => {
    return methods
      .filter(getMetadata)
      .map((method: string) => ({[getMetadata(method)]: instance[method]}))
      .filter((item) => item)
      .reduce((acc: object, current: object) => ({...acc, ...current}), {});
  };
}

export function Action<C, P>(
  instance: C,
  method: string,
  descriptor: PropertyDescriptor,
) {}

const typeCache: {[label: string]: boolean} = {};

export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(
      `Action "${label}" is not unique! Check your actions, please."`,
    );
  }

  typeCache[<string>label] = true;

  return <T>label;
}
