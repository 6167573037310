import {Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, from, interval, Observable} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  get versionDetected$(): Observable<VersionReadyEvent> {
    return this.swUpdate.versionUpdates.pipe(
      filter(
        (evt): evt is VersionReadyEvent => evt.type === 'VERSION_DETECTED',
      ),
    );
  }

  get versionInstallationFailed$(): Observable<VersionReadyEvent> {
    return this.swUpdate.versionUpdates.pipe(
      filter(
        (evt): evt is VersionReadyEvent =>
          evt.type === 'VERSION_INSTALLATION_FAILED',
      ),
    );
  }

  get versionReady$(): Observable<VersionReadyEvent> {
    return this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    );
  }

  get swEnabled() {
    return this.swUpdate.isEnabled;
  }

  constructor(public swUpdate: SwUpdate) {
    if (swUpdate.isEnabled) {
      console.log('swUpdate is enabled');
      swUpdate.unrecoverable.subscribe((x) => {
        console.log('unrecoverable: ', x);
        console.log(new Date().getTime());
      });
    }
  }

  activateUpdate() {
    return from(this.swUpdate.activateUpdate());
  }

  checkUpdateRepeatedly$(): Observable<boolean> {
    const timeInterval = interval(8 * 60 * 60 * 100).pipe(startWith(0));
    return timeInterval.pipe(
      switchMap(() => from(this.swUpdate.checkForUpdate())),
    );
  }

  checkUpdateOnce$(): Observable<boolean> {
    return from(this.swUpdate.checkForUpdate());
  }
}
