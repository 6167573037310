import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/user-role.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserRoleState = createSelector(
  getUserManagementState,
  (userRoleState) => userRoleState.userRole,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserRoleState);

export const selectUserRoleByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectUserRolesByUserId = (userId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.user.id === userId),
  );

export const selectUserRoleById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllUserRoles = selectAll;

export const selectUserRolesByRoleId = (roleId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.role.id === roleId),
  );
