import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SecurityState} from '../reducers';
import {adapter} from '../reducers/security-data.reducer';

const getSecurityState = createFeatureSelector<SecurityState>('security');
const pathToEntities = createSelector(getSecurityState, (x) => x.security);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(pathToEntities);

export const getSecurityRecords = selectAll;
