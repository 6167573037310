import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Notification} from '../models';
import {
  DeleteNotification,
  DeleteNotificationSuccess,
  LoadNotification,
  LoadNotificationError,
  LoadNotificationSuccess,
  UpsertNotification,
  UpsertNotificationSuccess,
} from '../actions';
import {TsmError} from '@tsm/framework/http';

export interface NotificationState {
  id: string;
  userNotifyItems: Notification[];
  error: TsmError;
  loading: boolean;
}

export const notificationAdapter: EntityAdapter<NotificationState> =
  createEntityAdapter<NotificationState>({});
export const initialState: EntityState<NotificationState> =
  notificationAdapter.getInitialState({});

export const notificationReducer = createReducer(
  initialState,
  on(LoadNotification, (state, {userId}) =>
    notificationAdapter.upsertOne(
      {
        id: userId,
        // aby neproblikavali notifikace pri update
        userNotifyItems: state.entities[userId]?.userNotifyItems || [],
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationSuccess, (state, {userId, notification}) =>
    notificationAdapter.upsertOne(
      {
        id: userId,
        userNotifyItems: notification,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationError, (state, {userId, error}) =>
    notificationAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(UpsertNotification, (state, {notification}) =>
    notificationAdapter.updateOne(
      {
        id: notification.userId,
        changes: {
          ...state.entities[notification.userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertNotificationSuccess, (state, {notification}) =>
    notificationAdapter.upsertOne(
      {
        id: notification.userId,
        userNotifyItems: state.entities[
          notification.userId
        ]?.userNotifyItems?.filter((i) => i.id !== notification.id),
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteNotification, (state, {id, userId}) =>
    notificationAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteNotificationSuccess, (state, {id, userId}) =>
    notificationAdapter.upsertOne(
      {
        id: userId,
        userNotifyItems: state.entities[userId].userNotifyItems.filter(
          (i) => i.id !== id,
        ),
        loading: false,
        error: null,
      },
      state,
    ),
  ),
);
