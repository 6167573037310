import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  DeleteAllMyNotifications,
  DeleteAllMyNotificationsSuccess,
  DeleteMyNotification,
  DeleteMyNotificationSuccess,
  DisplayedAllMyNotifications,
  DisplayedAllMyNotificationsSuccess,
  LoadAllMyNotification,
  LoadAllMyNotificationError,
  LoadAllMyNotificationSuccess,
  LoadMyNotificationByFilter,
  LoadMyNotificationByFilterError,
  LoadMyNotificationByFilterSuccess,
  UpsertMyNotification,
  UpsertMyNotificationSuccess,
  ClearMyNotificationByFilter,
} from '../actions';
import {MyNotification} from '../models/notification-my.model';
import {NotificationStatus} from '../models/enums/notification-status';

export interface NotificationMyState {
  id: string;
  notifications: MyNotification[];
  error: any;
  loading: boolean;
}

export const notificationMyAdapter: EntityAdapter<NotificationMyState> =
  createEntityAdapter<NotificationMyState>({});
export const initialState: EntityState<NotificationMyState> =
  notificationMyAdapter.getInitialState({});

export const notificationMyReducer = createReducer(
  initialState,
  on(LoadAllMyNotification, LoadMyNotificationByFilter, (state, {userId}) =>
    notificationMyAdapter.upsertOne(
      {
        id: userId,
        // aby neproblikavali notifikace pri update
        notifications: state.entities[userId]?.notifications || [],
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadAllMyNotificationSuccess, (state, {userId, notifications}) =>
    notificationMyAdapter.upsertOne(
      {
        id: userId,
        notifications: notifications,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadAllMyNotificationError, (state, {userId, error}) =>
    notificationMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(LoadMyNotificationByFilterSuccess, (state, {userId, notifications}) => {
    const currentNotifications = state.entities[userId]?.notifications;
    return notificationMyAdapter.upsertOne(
      {
        id: userId,
        notifications: [
          ...currentNotifications,
          ...notifications.filter(
            (x) => !currentNotifications.map((y) => y.id).includes(x.id),
          ),
        ],
        loading: false,
        error: null,
      },
      state,
    );
  }),

  on(ClearMyNotificationByFilter, (state, {userId}) => {
    return notificationMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          notifications: [],
          loading: false,
          error: null,
        },
      },
      state,
    );
  }),

  on(LoadMyNotificationByFilterError, (state, {userId, error}) =>
    notificationMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(UpsertMyNotification, (state, {notification, userId}) =>
    notificationMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertMyNotificationSuccess, (state, {notification, userId}) =>
    notificationMyAdapter.upsertOne(
      {
        id: userId,
        notifications: [
          ...(state.entities[userId]?.notifications?.filter(
            (i) => i.id !== notification.id,
          ) ?? []),
          notification,
        ],
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteMyNotification, (state, {id, userId}) =>
    notificationMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteMyNotificationSuccess, (state, {id, userId}) =>
    notificationMyAdapter.upsertOne(
      {
        id: userId,
        notifications: state.entities[userId].notifications.filter(
          (i) => i.id !== id,
        ),
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteAllMyNotifications, DisplayedAllMyNotifications, (state, {userId}) =>
    notificationMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(
    DisplayedAllMyNotificationsSuccess,
    (state, {userId, notificationType}) => {
      const currentNotifications = state.entities[userId]?.notifications.map(
        (x) => {
          if (
            x.deliveryType === notificationType &&
            !x.notificationToDisplayed
          ) {
            return {...x, notificationToDisplayed: new Date().toISOString()};
          } else {
            return {...x};
          }
        },
      );
      return notificationMyAdapter.upsertOne(
        {
          id: userId,
          notifications: currentNotifications,
          loading: false,
          error: null,
        },
        state,
      );
    },
  ),

  on(
    DeleteAllMyNotificationsSuccess,
    (state, {userId, notificationType, status}) => {
      const currentNotifications = state.entities[userId]?.notifications.map(
        (x) => {
          if (x.deliveryType === notificationType && !x.notificationToDeleted) {
            if (status === NotificationStatus.PLAN) {
              if (!x.notificationToDisplayed) {
                return {...x, notificationToDeleted: new Date().toISOString()};
              } else {
                return {...x};
              }
            } else if (status === NotificationStatus.SENT) {
              if (!!x.notificationToDisplayed) {
                return {...x, notificationToDeleted: new Date().toISOString()};
              } else {
                return {...x};
              }
            } else {
              return {...x, notificationToDeleted: new Date().toISOString()};
            }
          } else {
            return {...x};
          }
        },
      );
      return notificationMyAdapter.upsertOne(
        {
          id: userId,
          notifications: currentNotifications,
          loading: false,
          error: null,
        },
        state,
      );
    },
  ),
);
