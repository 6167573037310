import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectRoleById} from '../selectors';
import {LoadRoleById} from '../actions';

@Pipe({
  name: 'role',
  pure: true,
})
export class RolePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(id: any, field: string = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectRoleById(this._id)),
      map((it) => {
        if (!it || !it.data) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectRoleById(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() => this.store.dispatch(LoadRoleById({id: this._id})));
        }
      });
  }
}
