import {Injectable} from '@angular/core';
import {
  IdentifiedPack,
  loadAndWait,
  SharedRequestValidUntil,
} from '@tsm/framework/root';
import {BehaviorSubject, Observable} from 'rxjs';
import {TsmModule} from '../models';
import {filter, switchMap} from 'rxjs/operators';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {Store} from '@ngrx/store';
import {LoadTsmModuleById} from '../actions';
import {selectTsmModuleById} from '../selectors';
import {translation} from '@tsm/shared-i18n';

@Docs({
  detail: 'tsmModule(id: string): IdentifiedPack<TsmModule>',
  description: {
    about: translation.shared.tsmModule,
    params: [{name: translation.shared.id, about: translation.shared.id}],
    example: "tsmModule('9d1548e7-26df-4ec2-8931-b86581b21672')",
  },
})
@Injectable()
export class TsmModuleDataSource
  implements DtlDataSource<IdentifiedPack<TsmModule>>
{
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private store: Store) {}

  @SharedRequestValidUntil()
  getRemoteData(id: string): Observable<IdentifiedPack<TsmModule>> {
    return this.store.pipe(
      loadAndWait(LoadTsmModuleById({id}), selectTsmModuleById(id)),
    );
  }

  pushParams(params: any[]) {
    this.value.next(params);
  }

  create(): Observable<IdentifiedPack<TsmModule>> {
    return this.value.asObservable().pipe(
      filter((x: string[]) => x[0] != null),
      switchMap((x: string[]) => {
        return this.getRemoteData(x[0]);
      }),
    );
  }
}
