import {APP_INITIALIZER, enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {ConfigService} from '@tsm/framework/config';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

function initializeApp(configService: ConfigService) {
  return () => configService.load().then(() => {});
}

ConfigService.prebootstrapLoad({
  serviceWorker: true,
}).then((_) => {
  return platformBrowserDynamic([
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
  ])
    .bootstrapModule(AppModule)
    .then((ref) => {
      const injector = ref.injector;
      const configService = injector.get(ConfigService);

      // Ensure Angular destroys itself on hot reloads.
      if (window['ngRef']) {
        window['ngRef'].destroy();
      }
      window['ngRef'] = ref;
      const sentryConfig = configService.value.sentryConfig;
      if (sentryConfig) {
        Sentry.init({
          dsn: configService.value.sentryConfig?.dsn,
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
          ],
          tracesSampleRate: configService.value.sentryConfig?.tracesSampleRate,
          tracePropagationTargets:
            configService.value.sentryConfig?.tracePropagationTargets,
          replaysSessionSampleRate:
            configService.value.sentryConfig?.replaysSessionSampleRate,
          replaysOnErrorSampleRate:
            configService.value.sentryConfig?.replaysOnErrorSampleRate,
        });
      }
    })
    .catch((err) => console.error(err));
});

window.app = process.env;
