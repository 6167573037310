export class TsmIconsBundle {
  getAll(): string[] {
    return [
      '002-tools',
      '3rd-party-modules',
      'access',
      'access-control',
      'access-control-1',
      'activity',
      'add',
      'address',
      'address-management',
      'advice-outline',
      'advice-solid',
      'align',
      'analysis',
      'alter-switch',
      'api',
      'approved',
      'asterisk',
      'autonomous-car',
      'backward',
      'backup',
      'backup-file',
      'backup-cloud',
      'bad-review',
      'balance',
      'barcode-scanner',
      'bar-chart',
      'bar-chart-1',
      'bar-chart-2',
      'bookmark',
      'bookmarked',
      'braces',
      'bracket',
      'briefcase',
      'budget',
      'bug',
      'bulk-edit',
      'bulk-edit-1',
      'business-man',
      'button',
      'button-1',
      'cabriolet',
      'calendar',
      'calendar-2',
      'call',
      'call-1',
      'card-payment',
      'catalog',
      'catalog-1',
      'catalog-management',
      'caution',
      'clean',
      'client',
      'clipboard',
      'clipboard-1',
      'clipboard-copy',
      'clipboard-done',
      'clipboard-paste',
      'clock',
      'clock-streamline-time',
      'close',
      'close-solid',
      'cloud-storage',
      'code',
      'code-1',
      'coding',
      'cog-wheels',
      'collaboration',
      'columns',
      'command-line',
      'comment-1',
      'compare',
      'component',
      'component-1',
      'config-form',
      'configuration',
      'contract',
      'contract-1',
      'control-panel',
      'console',
      'conversation',
      'couch',
      'crm',
      'crm-1',
      'crm-4',
      'crosshair-2',
      'crosshair-4',
      'crosshair-5',
      'crosshair-7',
      'custom-modules',
      'customer-review',
      'customer-support',
      'customer-time',
      'customer-type',
      'cycle',
      'cycle-1',
      'data-analysis',
      'data-recovery',
      'data-recovery-cloud',
      'data-recovery-folder',
      'data-transfer',
      'default-checked',
      'design',
      'design-1',
      'design-2',
      'development',
      'diagram',
      'direction-in',
      'direction-in-1',
      'direction-out',
      'direction-out-1',
      'discount',
      'diskette',
      'download',
      'download-arrow',
      'draft-1',
      'draft-label',
      'drafts',
      'drag',
      'duration',
      'economic',
      'economic-1',
      'economic-2',
      'economic-3',
      'economic-4',
      'edit-columns',
      'edit-columns-1',
      'edit-time',
      'email',
      'empty',
      'entity-specifications',
      'envelope',
      'erase',
      'event',
      'evidence',
      'excel',
      'excel-file',
      'exchange',
      'exchange-1',
      'event-1',
      'expense',
      'expense-1',
      'file',
      'file-doc',
      'file-html',
      'file-pdf',
      'file-transfer',
      'file-xls',
      'filter',
      'filter-1',
      'filter-2',
      'filter-3',
      'filter-4',
      'filter-5',
      'filter-6',
      'find-user',
      'flag',
      'flowchart',
      'folder-2',
      'folder-3',
      'form',
      'form-1',
      'form-2',
      'form-position',
      'forward',
      'funnel',
      'future',
      'gears',
      'gift',
      'git-branch',
      'government',
      'government-1',
      'graph',
      'group',
      'growing',
      'growth',
      'growth-1',
      'growth-2',
      'handshake',
      'hashtag',
      'hierarchical-structure',
      'hierarchical-structure-1',
      'hierarchical-structure-2',
      'history',
      'home',
      'home-house',
      'hourglass',
      'change',
      'change-management',
      'change-request',
      'characteristics',
      'chat',
      'check-mark',
      'checklist',
      'chip',
      'id-card',
      'incoming-call',
      'increase',
      'infinity',
      'innovation',
      'input',
      'input-1',
      'input-text',
      'inventory',
      'inventory-search',
      'keyboard',
      'keyboard-1',
      'keyboard-2',
      'keyboard-alt',
      'keyboard-arrows',
      'keyboard-arrows-1',
      'keyboard-button',
      'keyboard-button-1',
      'keyboard-button-2',
      'keyboard-function',
      'keyboard-shortcuts',
      'layout',
      'layout-1',
      'layout-master-detail',
      'layout-table',
      'layout-table-detail',
      'layout-tree',
      'lead',
      'lead-rating',
      'lead-source',
      'legal-form',
      'leisure',
      'leisure-1',
      'line-chart',
      'link',
      'link-external',
      'link-external-1',
      'link-external-2',
      'list',
      'list-outline',
      'location',
      'location-1',
      'locator',
      'lock',
      'lock-1',
      'lock-2',
      'lock-3',
      'lock-4',
      'locked',
      'logging',
      'maintenance-plan',
      'manage-codebooks',
      'management',
      'management-1',
      'management-3',
      'management-5',
      'map',
      'map-1',
      'map-pin-1',
      'materials',
      'materials-1',
      'materials-2',
      'meeting',
      'megaphone',
      'menu-item',
      'merge',
      'message',
      'message-add',
      'message-attachment',
      'message-blocked',
      'message-download',
      'message-favourite',
      'message-like',
      'message-read',
      'message-remove',
      'message-spam',
      'message-time',
      'message-unread',
      'message-upload',
      'message-warning',
      'messages',
      'mobility',
      'module',
      'money',
      'money-bag-1',
      'move',
      'my-tasks',
      'network-inventory',
      'new-ticket',
      'next',
      'next-1',
      'notification',
      'notification-1',
      'notify-user',
      'notify-user-group',
      'notify-user-group-lock',
      'no-signal',
      'no-stopwatch',
      'no-timer',
      'no-wifi',
      'one-dot',
      'open-hand-outline',
      'oil-tank',
      'open-hand-solid',
      'ordering',
      'outgoing-call',
      'overlapping',
      'overlay',
      'paint',
      'paint-brush',
      'paint-brushes',
      'paint-palette',
      'paint-palette-1',
      'paint-roller',
      'paint-spray',
      'palette',
      'panel',
      'park',
      'password',
      'password-1',
      'password-2',
      'person',
      'person-wait-outline',
      'person-wait-solid',
      'pie-chart',
      'pie-chart-1',
      'pin',
      'placeholder',
      'placeholder-1',
      'planner',
      'priority-1',
      'priority-2',
      'priority-3',
      'privilege-escalation',
      'project',
      'project-management',
      'project-management-1',
      'product',
      'product-1',
      'procedure',
      'process',
      'process-1',
      'profiles',
      'profits',
      'promotion',
      'provisioning',
      'pushpin',
      'pushpin-1',
      'pushpin-2',
      'pushpin-3',
      'pushpin-4',
      'pushpin-5',
      'pushpin-6',
      'pushpin-7',
      'puzzle-piece',
      'puzzle-piece-1',
      'qr-code-scanner',
      'question',
      'recession',
      'redo',
      'redo-1',
      'redo-2',
      'reflect',
      'refresh',
      'refresh-data',
      'regions',
      'register',
      'registers',
      'registers-1',
      'reload',
      'reload-1',
      'reminder',
      'repeat',
      'repeat-1',
      'repeat-2',
      'repeat-3',
      'replace',
      'replace-1',
      'replace-2',
      'replace-3',
      'report',
      'reset-settings',
      'reserve',
      'reserve-1',
      'resource-1',
      'return',
      'risk-management',
      'risk-management-1',
      'risks',
      'robot',
      'robot-1',
      'rocket',
      'role',
      'roles',
      'router',
      'ruler',
      'save',
      'save-3',
      'scan',
      'scan-1',
      'scan-2',
      'script',
      'script-1',
      'script-2',
      'script-3',
      'script-list-dialog',
      'script-settings',
      'search',
      'search-location',
      'sedan',
      'segment',
      'segment-1',
      'sending',
      'server',
      'service-management',
      'set',
      'set-1',
      'setting-cloud',
      'setting-folder',
      'settings',
      'shape',
      'shipping-and-deliver',
      'shopping-cart',
      'shopping-cart-add',
      'shopping-cart-remove',
      'sick',
      'sick-1',
      'signature',
      'signature-1',
      'sketch',
      'sketch-1',
      'solutions',
      'splitter-horizontal',
      'splitter-vertical',
      'spreadsheet',
      'stats',
      'start-time',
      'statistics',
      'statistics-2',
      'steps',
      'stock',
      'stock-1',
      'stock-item',
      'stock-movement',
      'stock-movement-creation',
      'stopwatch',
      'stopwatch-1',
      'svg',
      'svg-file',
      'supply',
      'swing',
      'switch',
      'table',
      'table-1',
      'tabpanel',
      'tag',
      'tags',
      'tag-outline',
      'tag-solid',
      'tank',
      'tank-1',
      'target-user',
      'targeting',
      'teamwork',
      'termed',
      'terminal',
      'thermometer',
      'thumb-down',
      'ticket',
      'ticketing',
      'time',
      'time-management',
      'time-management-1',
      'to-console',
      'tools',
      'tools-1',
      'transaction-history',
      'transfer-left',
      'transfer-right',
      'transform',
      'translation',
      'trash',
      'trash-1',
      'tree',
      'tree-1',
      'tree-2',
      'tree-3',
      'turn-left',
      'turn-right',
      'undo',
      'undo-1',
      'undo-2',
      'unlocked',
      'upload',
      'user',
      'user-1',
      'user-2',
      'user-3',
      'user-advice',
      'user-advice-1',
      'user-group',
      'user-group-1',
      'user-group-2',
      'user-group-3',
      'user-management',
      'user-privileges',
      'user-privileges-1',
      'users-skills',
      'vacation',
      'vacation-1',
      'vacation-2',
      'vector',
      'vector-1',
      'vector-2',
      'wait-hand',
      'waiting',
      'waiting-1',
      'warehouse-4',
      'warehouse-5',
      'warehouse-6',
      'work',
      'work-force',
      'work-time',
      'work-time-2',
      'work-time-3',
      'worker',
      'workspace',
    ];
  }
}
