import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {attachmentTypeAdapter} from '../reducers/attachment-type.reducer';
import {RouterStateUrl} from '@tsm/framework/root';

const getDmsState = createFeatureSelector<DmsState>('dms');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
const pathToEntities = createSelector(getDmsState, (x) => x.attachmentType);

const {selectAll, selectEntities} =
  attachmentTypeAdapter.getSelectors(pathToEntities);

export const selectAttachmentTypeById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectAttachmentTypeByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data.code === code),
  );

export const selectAllAttachmentType = createSelector(selectAll, (entities) =>
  entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);

export const selectAttachmentTypeByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);
