<!--<div-->
<!--  _ngcontent-ofe-c0=""-->
<!--  style="-->
<!--    color: blue;-->
<!--    z-index: 1000000;-->
<!--    background-color: red;-->
<!--    position: absolute;-->
<!--"-->
<!--&gt;-->
<!--  {{ translation.root.title | transloco }}-->
<!--  {{ 'shared.section.detail.label' | transloco }}-->

<!--</div>-->
<!--
<ng-container *ngIf="showUpdate$ | async">
-->
<ng-container *ngIf="showUpdateMessage$ | async">
  <div class="update-wrapper">
    {{ translation.swUpdate.message | transloco }}
    <button
      pButton
      label="Update"
      (click)="updateClicked()"
      class="p-button-warning"
      icon="tsm-icon-reload-1"
    ></button>
    ({{ translation.swUpdate.startIn1 | transloco }})
  </div>
</ng-container>

<div
  *ngIf="showUpdateOverlay$ | async"
  [@updateAnimation]
  class="app-init-loading-wrapper"
  style="position: fixed"
>
  <div class="loading-circle-wrap">
    <div class="loading-circle">
      <div class="loading-circle-dot"></div>
      <div class="loading-circle-dot"></div>
      <div class="loading-circle-dot"></div>
      <div class="loading-circle-dot"></div>
      <div class="loading-circle-dot"></div>
      <div class="loading-circle-dot"></div>
    </div>
  </div>
  <div
    *ngIf="(updateOverlayStep$ | async) === updateOverlayStepEnum.DOWNLOADING"
    class="loading-message"
  >
    {{ translation.swUpdate.loading1 | transloco }}
  </div>
  <div
    *ngIf="(updateOverlayStep$ | async) === updateOverlayStepEnum.INSTALLED"
    class="loading-message"
  >
    {{ translation.swUpdate.loading2 | transloco }}
  </div>
</div>

<ng-container [dtlAllowedHotkeys]="['ctrl+s']">
  <div class="animation app-loader"></div>
  <router-outlet></router-outlet>
  <p-toast
    *ngIf="toastService.onToast$ | async"
    [key]="toastService.MAIN_TOAST"
    position="top-right"
    [baseZIndex]="5000"
  >
    <ng-template let-message pTemplate="message">
      <a
        class="p-element p-toast-icon-close p-link p-ripple absolute"
        (click)="toastService.closeToast()"
      >
        <span class="pi pi-times"></span>
      </a>
      <span
        class="p-toast-message-icon pi"
        [ngClass]="{
          'pi-info-circle': message.severity === 'info',
          'pi-exclamation-triangle': message.severity === 'warn',
          'pi-times-circle': message.severity === 'error',
          'pi-check': message.severity === 'success',
        }"
      ></span>
      <div class="p-toast-message-text">
        <div class="p-toast-summary">
          @if (message.summary != null && message.summary !== '') {
            {{ message.summary | transloco }}
          } @else {
            @switch (message.severity) {
              @case ('info') {
                {{ translation.toast.info | transloco }}
              }
              @case ('warn') {
                {{ translation.toast.warn | transloco }}
              }
              @case ('error') {
                {{ translation.toast.error | transloco }}
              }
              @case ('success') {
                {{ translation.toast.success | transloco }}
              }
              @default {
                {{ translation.toast.info | transloco }}
              }
            }
          }
        </div>

        <div class="p-toast-detail">

          {{ (message.data?.message ?? message.detail) | transloco }}

        </div>

        <ng-container *ngIf="message.data != null">
          <span
            class="p-toast-more-btn"
            (click)="showMoreContent = !showMoreContent"
          >{{
              !showMoreContent
                ? (translation.toast.showMore | transloco)
                : (translation.toast.showLess | transloco)
            }}</span
          >
          <div class="p-toast-more-content" [class.show-more]="showMoreContent">
            <div *ngIf="message.data.traceId != null" class="my-1">
              {{ translation.toast.traceIdLabel | transloco }}:
              {{ message.data.traceId }}
            </div>
            <div *ngIf="message.data.traceId == null" class="my-1">
              {{ translation.toast.traceIdNotAvailable | transloco }}
            </div>
            <div *ngIf="message.data.status != null" class="my-1">
              {{ translation.toast.statusLabel | transloco }}:
              {{ message.data.status }}
            </div>
            <div *ngIf="message.data.status == null" class="my-1">
              {{ translation.toast.statusNotAvailable | transloco }}
            </div>
            <div *ngIf="message.data.timestamp != null" class="my-1">
              {{ translation.toast.timestampLabel | transloco }}
              : {{ message.data.timestamp | date: 'dd.MM.yy HH:mm:ss' }}
            </div>
            <div *ngIf="message.data.timestamp == null">
              {{ translation.toast.timestampNotAvailable | transloco }}
            </div>
            <ng-container
              *ngIf="
                message.data.errors != null && message.data.errors.length > 0
              "
            >
              <div class="divider"></div>
              <code [style.word-break]="'break-word'">
                <ng-container *ngFor="let error of message.data.errors">
                  {{ error }}
                </ng-container>
              </code>
            </ng-container>
            <ng-container
              *ngIf="
                message.data.stackTrace != null &&
                message.data.stackTrace.length > 0
              "
            >
              <div class="divider"></div>
              <code [style.word-break]="'break-word'">
                <ng-container
                  *ngFor="let stacktrace of message.data.stackTrace"
                >
                  {{ stacktrace }}
                </ng-container>
              </code>
            </ng-container>
            <div class="divider"></div>
            <div>
              {{ translation.toast.downloadLogDescription | transloco }}
            </div>
            <div class="mt-2">
              <button
                pButton
                pRipple
                [label]="translation.toast.downloadLog | transloco"
                class="p-button-info"
                icon="p-icon-file-download"
                (click)="downloadLog(message.data)"
              ></button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </p-toast>

  <tsm-dialog
    *ngIf="
      (hotkeysVisible$ | async) &&
      !!hotkeysService.getRegistrations() &&
      hotkeysService.getRegistrations().length > 0
    "
    styleClass="shortcut-map-dialog"
    (close)="disableHotkeys()"
  >
    <p-header>
      <i class="tsm-icon-keyboard text-2xl"></i>
      {{ translation.shortcuts.keyboardShortcuts | transloco }}
    </p-header>
    <div class="smc-content">
      <ng-container
        *ngFor="let group of hotkeysService.getGroupedRegistrations()"
      >
        <h3 class="mx-2 my-3 text-xl inline-block">{{ group.group }}</h3>
        <dtl-grid class="grid-nogutter">
          <ng-container *ngFor="let shortcut of group.hotkeys">
            <dtl-col [cols]="6">
              {{ shortcut.text }}
            </dtl-col>
            <dtl-col [cols]="6">
              <ng-container *ngIf="shortcut.customHotkeyText">
                <span [outerHTML]="shortcut.customHotkeyText"></span>
              </ng-container>
              <ng-container *ngIf="!shortcut.customHotkeyText">
                <ng-container
                  *ngFor="let hotkey of shortcut.hotkey; let i = index"
                >
                  <span *ngIf="i > 0" class="thin" style="color: #676767">
                    {{ i > 0 ? ' &nbsp;nebo&nbsp; ' : '' }}
                  </span>
                  <span class="medium">{{ hotkey }}</span>
                </ng-container>
              </ng-container>
            </dtl-col>
            <dtl-col [cols]="12">
              <div class="row-separator my-2"></div>
            </dtl-col>
          </ng-container>
        </dtl-grid>
      </ng-container>
    </div>
  </tsm-dialog>
</ng-container>

<dtl-iframe-kiosk></dtl-iframe-kiosk>
