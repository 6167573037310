import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  MassUpdateDlq,
  MassUpdateDlqError,
  MassUpdateDlqSuccess,
} from '../actions';
import {concatMap} from 'rxjs';
import {DlqService} from '../services';
import {map} from 'rxjs/operators';
import {ToastService} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {Injectable} from '@angular/core';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';

@Injectable()
export class DlqEffects {
  massUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MassUpdateDlq),
      concatMap(({request, listingId}) =>
        this.dlqService.massUpdateDlq(request).pipe(
          map((envelope) => {
            if (envelope.success) {
              this.toastService.showToast(
                translation.dlqService.massUpdateSuccess,
              );
              return MassUpdateDlqSuccess({listingId: listingId});
            } else {
              this.toastService.showError(
                envelope.error,
                translation.dlqService.massUpdateError,
              );
              return MassUpdateDlqError({error: envelope.error});
            }
          }),
        ),
      ),
    ),
  );

  refreshList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MassUpdateDlqSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private dlqService: DlqService,
  ) {}
}
