import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ConfigFormState, configTypeAdapter} from '../reducers';
import {RouterStateUrl} from '@tsm/framework/root';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
export const pathToEntities = createSelector(
  getConfigFormState,
  (x) => x.configTypes,
);

const {selectAll, selectEntities} =
  configTypeAdapter.getSelectors(pathToEntities);

export const allConfigTypes = selectAll;

export const configTypeEntities = selectEntities;

export const selectConfigTypeById = (id: string) =>
  createSelector(configTypeEntities, (entities) => entities[id]);
export const selectConfigTypeByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectConfigTypeByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x?.data?.code === code),
  );

export const selectConfigTypeByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectConfigTypesByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter(
        (x) => x != null && x.loading == false && codes.includes(x?.data?.code),
      )
      .map((x) => x.data),
  );

export const selectConfigTypesByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter(
        (x) => x != null && x.loading == false && ids.includes(x?.data?.id),
      )
      .map((x) => x.data),
  );
