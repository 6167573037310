import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tsmLovboxMultiReadonlySplit',
  pure: true,
})
export class TsmLovboxMultiReadonlySplitPipe implements PipeTransform {
  transform(
    values: string | string[],
  ): string[] | {id: string; [k: string]: any}[] {
    if (typeof values === 'string') {
      return (values as string)?.split(',');
    }
    return values;
  }
}
