import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ConfigFormState} from '../reducers';
import {tsmModuleAdapter} from '../reducers/tsm-module.reducer';
import {RouterStateUrl} from '@tsm/framework/root';

const getTsmModuleState = createFeatureSelector<ConfigFormState>('configForm');
const pathToEntities = createSelector(getTsmModuleState, (x) => x.tsmModule);
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const {selectAll, selectEntities} =
  tsmModuleAdapter.getSelectors(pathToEntities);

export const selectTsmModuleById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectTsmModuleByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x?.data?.code === code),
  );

export const selectTsmModuleByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x?.data?.code)),
  );

export const selectAllTsmModule = createSelector(selectAll, (entities) =>
  entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);

export const selectValidTsmModules = createSelector(selectAll, (entities) =>
  entities
    .filter((entity) => entity && entity.data && entity.data.valid != 'false')
    .map((pack) => pack.data),
);

export const selectTsmModulesByType = (type: string) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter(
        (entity) => entity && entity.data && entity.data.moduleType === type,
      )
      .map((pack) => pack.data),
  );

export const selectTsmModulesByPrimaryMicroserviceCode = (
  primaryMicroserviceCode: string,
) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter(
        (entity) =>
          entity &&
          entity.data &&
          entity.data.primaryMicroservice &&
          entity.data.primaryMicroservice.code === primaryMicroserviceCode,
      )
      .map((pack) => pack.data),
  );

export const selectTsmModuleByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);
