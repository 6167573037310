import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Attachment} from '../models';
import {from, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import FileSaver from 'file-saver';
import {RuntimeService} from '@tsm/runtime-info';
import {SharedRequest, SharedRequestValidUntil} from '@tsm/framework/root';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AttachmentDataService {
  private readonly ATTACHMENT = `attachment`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private runtimeService: RuntimeService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  copy(
    attachmentIds: string[],
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<Attachment[]>> {
    const url = `${this.BASE_URL}/v1/attachments/${attachmentIds.join(',')}/copy/ownerType/${ownerType}/ownerId/${ownerId}`;
    return this.apiService.post(url, attachmentIds);
  }

  getAttachmentsByIds(ids: string[]): Observable<Envelope<Attachment[]>> {
    return this.apiService.get(
      `${this.BASE_URL}/v1/attachments/list/${ids.join(',')}`,
    );
  }

  postAttachment(attachment: Attachment): Observable<Envelope<Attachment>> {
    return this.apiService.post(`${this.BASE_URL}/v1/attachments`, attachment);
  }

  countAttachments(
    ownerIds: string[] | string,
    ownerType: string,
  ): Observable<
    Envelope<{
      [ownerId: string]: number;
    }>
  > {
    return this.apiService.get<
      {[ownerId: string]: number},
      {
        [ownerId: string]: number;
      }
    >(
      `${this.BASE_URL}/${this.ATTACHMENT}/counts/${ownerType}/${
        Array.isArray(ownerIds) ? ownerIds.join(',') : ownerIds
      }`,
    );
  }

  @SharedRequestValidUntil()
  countAttachment(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<number>> {
    return this.apiService.get<
      {
        [ownerId: string]: number;
      },
      number
    >(`${this.BASE_URL}/${this.ATTACHMENT}/count/${ownerType}/${ownerId}`);
  }

  uploadFiles(
    ownerId: string,
    ownerType: string,
    attachments: Attachment[],
  ): Observable<Envelope<Attachment[]>> {
    if (attachments.length === 1 && attachments[0].id) {
      // myslim si ze se jedna o update
      return this.apiService.patch<Attachment, Attachment[]>(
        `${this.BASE_URL}/${this.ATTACHMENT}/${attachments[0].id}`,
        attachments[0],
        (response) => [response],
      );
    }
    return this.apiService.post<Attachment, Attachment[]>(
      `${this.BASE_URL}/${this.ATTACHMENT}/${ownerType}/${ownerId}`,
      attachments,
    );
  }

  uploadAndDeleteAll(
    ownerId: string,
    ownerType: string,
    attachments: Attachment[],
  ): Observable<Envelope<Attachment[]>> {
    return this.apiService.post<Attachment, Attachment[]>(
      `${this.BASE_URL}/${this.ATTACHMENT}/uploadAndDeleteAll/${ownerType}/${ownerId}`,
      attachments,
    );
  }

  deleteFile(id: string): Observable<Envelope<Attachment>> {
    return this.apiService.delete<Attachment, Attachment>(
      `${this.BASE_URL}/${this.ATTACHMENT}/${id}`,
    );
  }

  deleteAllFile(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<Attachment>> {
    return this.apiService.delete<Attachment, Attachment>(
      `${this.BASE_URL}/${this.ATTACHMENT}/ownerId/${ownerId}/ownerType/${ownerType}`,
    );
  }

  getAttachmentByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
    onlyWithThumbnails = false,
  ): Observable<Envelope<Attachment[]>> {
    const url = `${this.BASE_URL}/${this.ATTACHMENT}/${ownerType}/${ownerId}${
      onlyWithThumbnails ? '?onlyWithThumbnail=true' : ''
    }`;
    return this.apiService.get<Attachment[], Attachment[]>(url);
  }

  @SharedRequestValidUntil()
  getAttachmentByOwnerIdAndOwnerTypeCached(
    ownerId: string,
    ownerType: string,
    onlyWithThumbnails = false,
  ): Observable<Envelope<Attachment[]>> {
    return this.getAttachmentByOwnerIdAndOwnerType(
      ownerId,
      ownerType,
      onlyWithThumbnails,
    );
  }

  getV1AttachmentByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
    onlyWithThumbnails = false,
    queryParams?: string,
  ): Observable<Envelope<Attachment[]>> {
    const url = `${this.BASE_URL}/v1/${
      this.ATTACHMENT
    }s/${ownerType}/${ownerId}${
      onlyWithThumbnails ? '?onlyWithThumbnail=true' : ''
    }
    ${
      queryParams
        ? onlyWithThumbnails
          ? '&' + queryParams
          : '?' + queryParams
        : ''
    }`;
    return this.apiService.get<Attachment[], Attachment[]>(url);
  }

  @SharedRequest
  getGalleryAttachment(url: string): Observable<any> {
    return this.apiService.getRaw<Attachment, Attachment>(url, null, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  downloadData(
    ownerId: string,
    ownerType: string,
    attachment: Attachment,
    showData: boolean,
  ): Observable<any> {
    return this.apiService
      .getRaw(
        `${this.BASE_URL}/v1/${this.ATTACHMENT}s/${attachment.id}/content`,
        null,
        {observe: 'response', responseType: 'blob' as 'json'},
      )
      .pipe(
        tap((data) => {
          if (data.status === 200 && (data as any).body.size > 0) {
            const blob = new Blob([(data as any).body], {
              type: data.headers.get('Content-Type'),
            });
            if (showData) {
              // zobrazeni prilohy v novem okne
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            } else {
              // stazeni prilohy
              FileSaver.saveAs(blob, attachment.name);
            }
          }
        }),
        switchMap((x: HttpResponse<unknown> | HttpErrorResponse) => {
          if (x.status === 200 && (x as any).body.size > 0) {
            return of({
              success: true,
              resultCode: 200,
              error: null,
            });
          } else {
            const error = x as HttpErrorResponse;
            if (error.error instanceof Blob) {
              return from(error.error.text()).pipe(
                map((text: string) => {
                  let parsed: any;
                  try {
                    parsed = JSON.parse(text);
                  } catch {
                    parsed = text;
                  }
                  return {
                    success: false,
                    data: null,
                    resultCode: error.status?.toString(),
                    error: parsed,
                  } as Envelope;
                }),
              );
            }

            return of({
              success: false,
              error,
              resultCode: error.status?.toString(),
            });
          }
        }),
      );
  }

  getBlobFile(
    ownerId: string,
    ownerType: string,
    attachment: Attachment,
  ): Observable<
    Envelope<{
      blob: any;
      url: string;
      charset?: string;
    }>
  > {
    return this.apiService
      .getRaw(
        `${this.BASE_URL}/v1/${this.ATTACHMENT}s/${attachment.id}/content`,
        null,
        {observe: 'response', responseType: 'blob' as 'json'},
      )
      .pipe(
        map((x) => {
          if (x.status === 200 && (x as any).body.size > 0) {
            const contentType = x.headers.get('Content-Type');
            const blob = new Blob([(x as any).body], {type: contentType});
            return {
              success: true,
              resultCode: '200',
              error: null,
              data: {
                blob: blob,
                url: window.URL.createObjectURL(blob),
                charset: contentType.replace('text/plain; charset=', ''),
              },
            };
          } else {
            return {
              success: false,
              data: null,
              resultCode: x.status + '',
              error: {
                status: '400',
                message: '',
                timestamp: new Date().toISOString(),
              },
            };
          }
        }),
      );
  }

  updateFile(ownerId: string, ownerType: string, attachment: Attachment) {
    return this.apiService.put<Attachment, Attachment>(
      `${this.BASE_URL}/${this.ATTACHMENT}/${ownerType}/${ownerId}`,
      attachment,
    );
  }
}
