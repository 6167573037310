import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {FormatterDocumentTemplate} from '../models/formatter-document-template.model';

@Injectable({
  providedIn: 'root',
})
export class FormatterDocumentTemplateService {
  private readonly TEMPLATE = `formatterDocumentTemplate`;
  private readonly TEMPLATE_BY_CODE = `templateByCode`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  saveTemplate(
    formatterDocumentTemplate: FormatterDocumentTemplate,
  ): Observable<Envelope<FormatterDocumentTemplate>> {
    if (formatterDocumentTemplate.id) {
      return this.apiService.put<
        FormatterDocumentTemplate,
        FormatterDocumentTemplate
      >(
        `${this.BASE_URL}/${this.TEMPLATE}/${formatterDocumentTemplate.id}`,
        formatterDocumentTemplate,
      );
    } else {
      return this.apiService.post<
        FormatterDocumentTemplate,
        FormatterDocumentTemplate
      >(`${this.BASE_URL}/${this.TEMPLATE}`, formatterDocumentTemplate);
    }
  }

  getTemplateByCode(
    code: string,
  ): Observable<Envelope<FormatterDocumentTemplate>> {
    return this.apiService.get<
      FormatterDocumentTemplate,
      FormatterDocumentTemplate
    >(`${this.BASE_URL}/${this.TEMPLATE}/${this.TEMPLATE_BY_CODE}/${code}`);
  }

  getTemplateById(id: string): Observable<Envelope<FormatterDocumentTemplate>> {
    return this.apiService.get<
      FormatterDocumentTemplate,
      FormatterDocumentTemplate
    >(`${this.BASE_URL}/${this.TEMPLATE}/${id}`);
  }

  deleteTemplateById(
    id: string,
  ): Observable<Envelope<FormatterDocumentTemplate>> {
    return this.apiService.delete<
      FormatterDocumentTemplate,
      FormatterDocumentTemplate
    >(`${this.BASE_URL}/${this.TEMPLATE}/${id}`);
  }
}
