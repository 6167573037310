import {UserType} from '../model';
import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';

export const LoadUserType = createAction('[UserType] Load UserType');
export const LoadUserTypeSuccess = createAction(
  '[UserType] Load UserType Success',
  props<{userTypes: UserType[]}>(),
);
export const LoadUserTypeError = createAction(
  '[UserType] Load UserType Error',
  props<TsmError>(),
);

export const LoadUserTypeById = createAction(
  '[UserType] Load LoadUserTypeById',
  props<{id: string}>(),
);
export const LoadUserTypeByIdSuccess = createAction(
  '[UserType] Load LoadUserTypeById Success',
  props<{
    userType: UserType;
  }>(),
);
export const LoadUserTypeByIdError = createAction(
  '[UserType] Load LoadUserTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);
export const LoadUserTypeByCode = createAction(
  '[UserType] Load LoadUserTypeByCode',
  props<{code: string}>(),
);
export const LoadUserTypeByCodeSuccess = createAction(
  '[UserType] Load LoadUserTypeByCode Success',
  props<{
    userType: UserType;
  }>(),
);
export const LoadUserTypeByCodeError = createAction(
  '[UserType] Load LoadUserTypeByCode Error',
  props<{
    code: string;
    error: TsmError;
  }>(),
);

export const UpsertUserType = createAction(
  '[UserType] Upsert UserType',
  props<{
    userType: UserType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertUserTypeSuccess = createAction(
  '[UserType] Upsert UserType Success',
  props<{
    userType: UserType;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertUserTypeError = createAction(
  '[UserType] Upsert UserType Error',
  props<TsmError>(),
);

export const DeleteUserType = createAction(
  '[UserType] Delete UserType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteUserTypeSuccess = createAction(
  '[UserType] Delete UserType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteUserTypeError = createAction(
  '[UserType] Delete UserType Error',
  props<TsmError>(),
);

export const DiffUserType = createAction(
  '[UserType] Diff UserType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffUserTypeSuccess = createAction(
  '[UserType] Diff UserType Success',
  props<{
    userTypes: UserType[];
    listingId: string;
  }>(),
);
export const DiffUserTypeError = createAction(
  '[UserType] Diff UserType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
