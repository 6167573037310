import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IframeKioskComponent} from './components';
import {SharedModule} from '@tsm/shared';
import {IframeKioskHideDirective} from './directives';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [IframeKioskComponent, IframeKioskHideDirective],
  exports: [IframeKioskComponent, IframeKioskHideDirective],
})
export class FrameworkIframeKioskModule {}
