import {Injectable} from '@angular/core';
import {IdentifiedPack, SharedRequestValidUntil} from '@tsm/framework/root';
import {CommonApiService} from '../services';
import {BehaviorSubject, Observable} from 'rxjs';
import {RegisterValue} from '../models';
import {FilterOperator, PageSortFilterService} from '@tsm/listing-lib/service';
import {filter, map, switchMap} from 'rxjs/operators';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {translation} from '@tsm/shared-i18n';

@Docs({
  detail:
    'registerValue(registerCode: string, code: string): IdentifiedPack<RegisterValue>',
  description: {
    about: translation.shared.userEntityOne,
    params: [
      {
        name: translation.shared.registerCode,
        about: translation.shared.registerCodeCam,
      },
      {
        name: translation.shared.code,
        about: translation.shared.codeRegisterValue,
      },
    ],
    example:
      "registerValue('9d1548e7-26df-4ec2-8931-b86581b21672', '9d1548e7-26df-4ec2-8931-b86581b21672')",
  },
})
@Injectable()
export class RegisterValueDataSource
  implements DtlDataSource<IdentifiedPack<RegisterValue>>
{
  private readonly REG_VALS_API_PATH = 'registerValues';

  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private commonApiService: CommonApiService<any, any>,
    private pageSortFilterService: PageSortFilterService,
  ) {}

  @SharedRequestValidUntil()
  getRemoteData(
    registerCode: string,
    code: string,
  ): Observable<IdentifiedPack<RegisterValue>> {
    return this.commonApiService
      .getAllFilterable(
        this.REG_VALS_API_PATH,
        this.pageSortFilterService.getUrlFilterFromFilterModels([
          {
            field: 'code',
            value: code,
            operator: FilterOperator.eq,
            readonly: true,
            visible: false,
          },
          {
            field: 'register.code',
            value: registerCode,
            operator: FilterOperator.eq,
            readonly: true,
            visible: false,
          },
        ]),
      )
      .pipe(
        map((val) => ({
          data: val.success ? (val.data[0] as any) : null,
          id: (val.data as any)?.id,
          loading: false,
          error: false,
        })),
      );
  }

  pushParams(params: any[]) {
    this.value.next(params);
  }

  create(): Observable<IdentifiedPack<RegisterValue>> {
    return this.value.asObservable().pipe(
      filter((x: string[]) => x[0] != null && x[1] != null),
      switchMap((x: string[]) => {
        return this.getRemoteData(x[0], x[1]);
      }),
    );
  }
}
