import {SelectItem} from 'primeng/api';

export enum IndexMapping {
  NONE = 'NONE',
  BASIC = 'BASIC',
  TYPED = 'TYPED',
}

export function getAllIndexMappings(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(IndexMapping).forEach((val) =>
    items.push({label: 'shared.indexMapping.' + val, value: val}),
  );
  return items;
}
