import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getUiConfig} from '@tsm/ui-config';
import {Config, ConfigService} from '@tsm/framework/config';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CrossLinkService {
  constructor(
    private store$: Store,
    private config: ConfigService<Config>,
  ) {}

  getCrossLink(): Observable<string> {
    return this.store$.pipe(
      select(getUiConfig),
      map((uiConfig) => {
        return (
          uiConfig?.data?.url?.crossLink ??
          this.config.value.externalUrls.crossLink
        );
      }),
    );
  }
}
