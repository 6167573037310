import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {
  DeleteMnemonicConfig,
  DeleteMnemonicConfigError,
  DeleteMnemonicConfigSuccess,
  DiffMnemonicConfig,
  DiffMnemonicConfigError,
  DiffMnemonicConfigSuccess,
  LoadMnemonicConfig,
  LoadMnemonicConfigByCode,
  LoadMnemonicConfigByCodeError,
  LoadMnemonicConfigByCodeSuccess,
  LoadMnemonicConfigById,
  LoadMnemonicConfigByIdError,
  LoadMnemonicConfigByIdSuccess,
  LoadMnemonicConfigError,
  LoadMnemonicConfigSuccess,
  UpsertMnemonicConfig,
  UpsertMnemonicConfigError,
  UpsertMnemonicConfigSuccess,
} from '../actions';
import {CommonApiService} from '../services';
import {MnemonicConfig} from '../models';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class MnemonicConfigEffects {
  translation = translation;
  translationShared = translationShared;

  private readonly MNEMONIC_API_PATH = 'v1/mnemonic-configs';

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMnemonicConfig),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.MNEMONIC_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadMnemonicConfigSuccess({entities: env.data})
                : LoadMnemonicConfigError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMnemonicConfigById),
      concatMap(({id}) => {
        return this.commonApiService
          .getEntity(this.MNEMONIC_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadMnemonicConfigByIdSuccess({entity: env.data})
                : LoadMnemonicConfigByIdError({error: env.error}),
            ),
          );
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMnemonicConfigByCode),
      concatMap(({code}) => {
        return this.commonApiService
          .getEntity(this.MNEMONIC_API_PATH, code)
          .pipe(
            map((env) =>
              env.success
                ? LoadMnemonicConfigByCodeSuccess({entity: env.data})
                : LoadMnemonicConfigByCodeError({error: env.error}),
            ),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMnemonicConfig),
      concatMap(({mnemonicConfig, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.MNEMONIC_API_PATH,
            mnemonicConfig,
            mnemonicConfig.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.mnemonicService.effects.mnemonicSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertMnemonicConfigSuccess({
                  mnemonicConfig: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.mnemonicService.effects.mnemonicSaveError,
                );
                return UpsertMnemonicConfigError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMnemonicConfig),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.MNEMONIC_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.mnemonicService.effects.mnemonicDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/mnemonic-config']);
              }
              return DeleteMnemonicConfigSuccess({
                id: id,
                listingId: listingId,
              });
            } else {
              this.toastService.showError(
                env.error,
                translation.mnemonicService.effects.mnemonicDeleteError,
              );
              return DeleteMnemonicConfigError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffMnemonicConfig),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.MNEMONIC_API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffMnemonicConfigSuccess({
                  mnemonicConfigs: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffMnemonicConfigError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMnemonicConfigSuccess),
      tap(({mnemonicConfig, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/config/mnemonic-config', mnemonicConfig.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMnemonicConfigSuccess, DiffMnemonicConfigSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<MnemonicConfig, MnemonicConfig>,
    private router: Router,
    private toastService: ToastService,
  ) {}
}
