// manual imports
import {translation as translationShared} from '@tsm/shared-i18n';
import {DateFormatPipe} from '@tsm/shared';
import {translation} from '../i18n';

// IDE imports
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {map, mergeMap} from 'rxjs/operators';
import {
  LoadNotificationCount,
  LoadNotificationCountSuccess,
  LoadNotificationCountError,
} from '../actions';
import {ToastService} from '@tsm/framework/toast';
import {NotificationMyService} from '../services';

@Injectable()
export class NotificationCountEffects {
  translation = translation;
  translationShared = translationShared;

  loadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotificationCount),
      mergeMap((action) => {
        return this.notificationMyService
          .getNotificationMyCountByUserId(action.userId, action.deliveryType)
          .pipe(
            map((userNotif) =>
              userNotif.success
                ? LoadNotificationCountSuccess({
                    userId: action.userId,
                    deliveryType: action.deliveryType,
                    count: userNotif.data,
                  })
                : LoadNotificationCountError({
                    userId: action.userId,
                    deliveryType: action.deliveryType,
                    error: userNotif.error,
                  }),
            ),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationMyService: NotificationMyService,
    private store$: Store,
    private ts: ToastService,
  ) {}
}
