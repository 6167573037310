import {GroupPosition, Plugin} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';

export const expressionEditorPlugin: Plugin = {
  menus: [],
  routes: [],
  filters: [],
  pipes: [],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/framework/expression-editor').then(
          (x) => x.FrameworkExpressionEditorModule,
        ),
      definitions: [
        {
          selector: 'dtl-expression-editor',
          designers: [
            {
              editorSelector: 'dtl-expression-editor-editor',
              widgetOrLayout: 'widget',
              formField: 'expressionEditor',
              categoryId: 25, // === DesignerCategoryEnum.Advanced  (prepsano kvuli cyklicke zavislosti)
              description:
                translationShared.shared.formTooltip.basicComponents
                  .expressionEditor,
              icon: 'p-icon-font-download',
              name: translationShared.shared.expressionEditor,
              group: GroupPosition.Secondary,
              order: 6,
              value: {
                type: 'string',
                title: 'Editor výrazů',
                widget: {
                  type: 'dtl-expression-editor',
                },
                config: {
                  isExpression: true,
                  hideChangeButton: true,
                  defaultType: 'spel',
                  widgetHeight: 140,
                  editorOptions: JSON.stringify({
                    fontSize: 12,
                    minimap: {enabled: false},
                    automaticLayout: true,
                    autoClosingBrackets: 'always',
                    matchBrackets: 'always',
                    renderWhitespace: 'all',
                    scrollBeyondLastLine: false,
                  }),
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
