<ng-template #template>
  @if (!readonly) {
    <input
      #inputElement
      [style]="style"
      type="text"
      [(ngModel)]="val"
      [tabindex]="tabindex"
      [placeholder]="placeholder ? (placeholder | transloco) : ''"
      class="p-inputtext p-component"
      [attr.aria-labelledby]="inputId"
      [attr.id]="inputId ? inputId : null"
      data-cy="dtl-form-input-text"
    />
  } @else {
    <span
      [attr.id]="inputId ? inputId : null"
      [ngClass]="val != null ? 'readonly-text' : 'readonly-text empty'"
      [class.p-state-filled]="true"
      [style.color]="
        color != null && color !== '' ? color + '!important' : null
      "
      [style.word-break]="!overflowValueHidden ? 'break-word' : null"
      [style.white-space]="overflowValueHidden ? 'nowrap' : null"
      [class.overflow-hidden]="overflowValueHidden"
      [class.text-overflow-ellipsis]="overflowValueHidden"
      [tsmAutoSizeTooltip]="val"
      [useTargetElement]="true"
      tooltipPosition="top"
    >
      {{ val != null ? getVal(val) : noneText }}
    </span>
  }
</ng-template>

@if (!rightIcon && rightIcon !== '' && !leftIcon && leftIcon !== '') {
  <ng-container *ngTemplateOutlet="template"></ng-container>
}
@if (leftIcon || rightIcon) {
  <p-inputGroup [styleClass]="readonly ? 'read-only' : null">
    @if (leftIcon && leftIcon !== '') {
      <p-inputGroupAddon>
        <i [class]="leftIcon"></i>
      </p-inputGroupAddon>
    }
    <ng-container *ngTemplateOutlet="template"></ng-container>
    @if (rightIcon && rightIcon !== '') {
      <p-inputGroupAddon>
        <i [ngClass]="rightIcon"></i>
      </p-inputGroupAddon>
    }
  </p-inputGroup>
}
