import {signalStore} from '@ngrx/signals';
import {withFluentForms} from './fluent-forms.feature';
import {withFluentDebugger} from './fluent-debugger.feature';

export const FluentFormsStore = signalStore(
  {providedIn: 'root'},
  withFluentForms(),
  withFluentDebugger(),
);

// pro DI
export type FluentFormsStore = InstanceType<typeof FluentFormsStore>;
