import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {countAttachmentsAdapter} from '../reducers/count-attachments.reducer';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getCountAttachmentsState = createSelector(
  getDmsState,
  (x) => x.countAttachments,
);
const {selectEntities} = countAttachmentsAdapter.getSelectors(
  getCountAttachmentsState,
);

export const getCountAttachmentByOwnerIdAndOwnerType = (
  ownerId: string,
  ownerType: string,
) => createSelector(selectEntities, (entities) => entities[ownerId]);
