import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '@tsm/shared-i18n';

@Pipe({
  name: 'emptyOrNotEmptyTranslate',
})
export class EmptyOrNotEmptyTranslatePipe implements PipeTransform {
  translation = translation;

  constructor(private translocoService: TranslocoService) {}

  transform(value: any, nullable?: any): any {
    if (Array.isArray(value)) {
      if (value?.length == 0 || !value.some((x) => !!x)) {
        if (
          (nullable == true || nullable == 'true') &&
          !value.some((x) => !!x)
        ) {
          return '';
        }
        return this.translocoService.translate(
          translation.shared.filterOperator.empty,
        );
      }
      return this.translocoService.translate(
        translation.shared.filterOperator.notempty,
      );
    }

    if (!value) {
      if ((nullable == true || nullable == 'true') && value == null) {
        return '';
      }
      return this.translocoService.translate(
        translation.shared.filterOperator.empty,
      );
    }
    return this.translocoService.translate(
      translation.shared.filterOperator.notempty,
    );
  }
}
