import {APP_INITIALIZER, ErrorHandler, LOCALE_ID} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {DecimalPipe, JsonPipe} from '@angular/common';

import {META_REDUCERS, Store, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {
  CustomSerializer,
  getReducerCatcher,
  hmrMetaReducer,
  TsmStore,
} from '@tsm/framework/root';
import {ClickOutsideModule} from '@tsm/framework/root/directives';
import {Config, ConfigService} from '@tsm/framework/config';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {TranslateModule} from '@tsm/framework/translate';

import {SharedModule, shareReducer} from '@tsm/shared';
import * as components from './components';
import * as effects from './effects';
import * as guards from './guards';
import * as pipes from './pipes';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {localizations} from './i18n';

import {HistoryModule} from '@tsm/framework/history';
import {HotkeyDirectiveModule} from '@tsm/framework/shortcuts';
import {FrameworkLoggerModule} from '@tsm/framework/logger';

import {AppErrorHandler} from './utils/app-error-handler';
import {LogoutClearState} from './actions';
import {NotificationModule} from '@tsm/framework/notification';
import {FrameworkIframeKioskModule} from '@tsm/framework/iframe-kiosk';

declare const ngDevMode: boolean;

export function initConfig(config: ConfigService<Config>): () => Promise<void> {
  return () => config.load();
}

export function getMetaReducer() {
  return getReducerCatcher(); // Same as default
  //return getReducerCatcher('default');
  //return getReducerCatcher('noop');
  //return getReducerCatcher(customFunction);
}

export function logoutClearStateMetaReducer(reducer) {
  return function (state, action) {
    if (action.type === LogoutClearState.type) {
      // router state cannot generate default value
      return reducer(
        {
          uiConfig: {
            ...state.uiConfig,
          },
          router: {
            state: {
              ...(state as any).router.state,
            },
            navigationId: 1,
          },
        },
        action,
      );
    }
    return reducer(state, action);
  };
}

export const IMPORTS = [
  HistoryModule.forRoot(),
  TranslateModule.forFeature(localizations),
  StoreModule.forRoot(shareReducer, {
    metaReducers: [...hmrMetaReducer, logoutClearStateMetaReducer],
    runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false,
      strictActionTypeUniqueness: true,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    serializer: CustomSerializer,
  }),
  ...(ngDevMode
    ? [
        StoreDevtoolsModule.instrument({
          maxAge: 100,
          name: 'tsmStore',
          serialize: {},
        }),
      ]
    : []),
  EffectsModule.forRoot([effects.CoreEffects]),
  HttpClientModule,
  ToastModule,
  SharedModule.forRoot(),
  FrameworkIframeKioskModule,
  HotkeyDirectiveModule,
  FrameworkGridModule,
  ClickOutsideModule,
  FrameworkLoggerModule,
  NotificationModule.forRoot({
    requireInteraction: true,
    icon: 'assets/icons/tsm-logo-notif-icon.png',
  }),
];

export const GUARDS = [guards.UserIsLoggedInGuard, guards.TeamsGuard];

export const PIPES = [pipes.FormatMinutesPipe];

export const RESOLVERS = [guards.RuntimeInfoResolver];

export const EXPORTS = [];

export const COMPONENTS = [components.AppComponent];

export const PROVIDERS = [
  JsonPipe,
  DecimalPipe,
  TsmStore,
  {
    provide: Store,
    useExisting: TsmStore,
  },
  {
    provide: META_REDUCERS,
    useFactory: getMetaReducer,
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    deps: [ConfigService],
    multi: true,
  },
  MessageService,
  {provide: ErrorHandler, useClass: AppErrorHandler},
  {
    provide: LOCALE_ID,
    useValue: navigator.language,
  },
];
