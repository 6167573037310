import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const geKafkaTopicState = createSelector(
  getDmsState,
  (x) => x.kafkaTopic,
);

export const getAllKafkaTopics = () =>
  createSelector(geKafkaTopicState, (entities) => entities);

export const getSelectedKafkaTopic = () =>
  createSelector(geKafkaTopicState, (entities) =>
    entities.kafkaTopics.find((x) => x.selected === true),
  );
