import {computed, effect, Injectable, Signal, untracked} from '@angular/core';
import {MemoizedSelector, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {asSignal} from './as-signal';

@Injectable()
export class TsmStore<T = object> extends Store<T> {
  /**
   * Watches `param` (P | Signal<P> | Observable<P>),
   * selects from the store via `selectorFn(param)`,
   * optionally transforms the result via `mapFn`,
   * returning a `Signal<M>`.
   */
  selectByParam<P, R, M = R>(
    param: P | Signal<P> | Observable<P>,
    selectorFn: (param: P) => MemoizedSelector<any, R>,
    mapFn?: (value: R) => M,
    config?: {initialValue?: P}, // used if param is an Observable
  ): Signal<M> {
    const paramSignal = asSignal<P>(param, config?.initialValue);

    return computed<M>(() => {
      const p = paramSignal(); // read the current param
      // Use the built-in 'selectSignal' from ngrx/store v15+ if available.
      // Fallback: you'd have to implement your own toSignal + store.select
      const storeValue = this.selectSignal(selectorFn(p))();
      return mapFn ? mapFn(storeValue) : (storeValue as unknown as M);
    });
  }

  /**
   * Watches `param` (which can be P, Signal<P>, or Observable<P>),
   * dispatches `loadFn(param)` whenever the param changes,
   * then selects from the store via `selectorFn(param)`,
   * optionally transforms the result via `mapFn`,
   * returning a Signal of the transformed store result.
   */
  loadAndSelect<P, R, M = R>(
    param: P | Signal<P> | Observable<P>,
    loadFn: (param: P) => void,
    selectorFn: (param: P) => MemoizedSelector<any, R>,
    mapFn?: (value: R) => M,
    config?: {initialValue?: P},
  ): Signal<M> {
    const paramSignal = asSignal<P>(param, config?.initialValue);

    effect(() => {
      const p = paramSignal();
      untracked(() => loadFn(p));
    });

    return this.selectByParam(paramSignal, selectorFn, mapFn, config);
  }
}
