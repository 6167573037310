import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewChild,
  OnInit,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  parseISO,
  isValid,
  isSameMinute,
  addDays,
  subSeconds,
} from 'date-fns';
import {Terminator} from '@tsm/framework/terminator';
import {DtlCalendar} from '@tsm/framework/override-primeng/calendar';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {NgStyle, NgClass, NgIf, DatePipe} from '@angular/common';
import {DtlCalendarModule} from '@tsm/framework/override-primeng/calendar';
import {TranslocoPipe} from '@jsverse/transloco';
import {LocalizationDataTranslatePipe} from '@tsm/framework/root';

// eslint-disable-next-line no-restricted-imports

@Component({
  selector: 'dtl-form-input-date',
  templateUrl: './form-input-date.component.html',
  styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputDateComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputDateComponent),
      multi: true,
    },
    Terminator,
    ...useParentWidgetProvidersFor(FormInputDateComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    DtlCalendarModule,
    FormsModule,
    NgClass,
    NgIf,
    DatePipe,
    TranslocoPipe,
    LocalizationDataTranslatePipe,
  ],
})
export class FormInputDateComponent
  extends ParentWidgetAccessorComponent
  implements OnInit, OnChanges
{
  @ViewChild(DtlCalendar, {static: true}) calendar: DtlCalendar;

  @Input() showSeconds = false;

  @Input() showIcon = true;

  @Input() showCloseIcon = true;

  @Input() chipClass = null;

  @Input() inputId: string;

  @Input() showTime = false;

  @Input() showMilliseconds = false;

  /**
   * Slouzi pro zaokrouhledni datumu u polozek "do" (pouze, kdyz se vybira je datum)
   * napr.: 1.1.2020 23:00:00 -> 2.1.2020 22:59:59 (zimni casove pasmo)
   */
  @Input() roundEndDate = false;

  @Input() timeOnly = false;

  @Input() monthYear = false;

  @Input() yearRange: string = '1900:' + (new Date().getFullYear() + 30);

  @Input() showPickDateTimeButton = true;

  @Input() pickDateTimeButtonLabel = 'shared.calendar.pick';

  @Input() countdownTimeFrom: string;

  @Input() appendTo = 'body';

  defaultDateFormat = 'dd.mm.yy';

  dateValue: Date;

  skipChange = false;

  diffTime: string;

  private _minDate: Date;

  private _maxDate: Date;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  get minDate(): Date {
    return this._minDate;
  }

  @Input()
  set minDate(value: Date) {
    if (
      !!value &&
      (typeof value === 'string'
        ? (value as unknown as string).length > 8 &&
          isValid(parseISO(value + ''))
        : true)
    ) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date' || date.getFullYear() > 9999) {
        this._minDate = null;
      } else {
        this._minDate = date;
      }
    } else {
      this._minDate = null;
    }
  }

  get maxDate(): Date {
    return this._maxDate;
  }

  @Input()
  set maxDate(value: Date) {
    if (
      !!value &&
      (typeof value === 'string'
        ? (value as unknown as string).length > 8 &&
          isValid(parseISO(value + ''))
        : true)
    ) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date' || date.getFullYear() > 9999) {
        this._maxDate = null;
      } else {
        this._maxDate = date;
      }
    } else {
      this._maxDate = null;
    }
  }

  ngOnInit() {
    if (this.showTime && !this.timeOnly) {
      this.defaultDateFormat = 'dd.mm.yy';
    }
    if (!this.showTime && !this.timeOnly && !this.monthYear) {
      this.defaultDateFormat = 'dd.mm.yy';
    }
    if (this.timeOnly) {
      this.defaultDateFormat = 'dd.mm.yy';
    }
    if (this.monthYear) {
      this.defaultDateFormat = 'mm.yy';
    }
    if (this.countdownTimeFrom && this.val) {
      this.diffTime = this.differenceTwoDates(
        this.val,
        this.countdownTimeFrom,
        true,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const showIcon = changes.showIcon;
    const readonly = changes.readonly;
    if (showIcon && showIcon.currentValue != false) {
      this.showIcon = true;
    }
    if (readonly && readonly.currentValue == true) {
      this.skipChange = false;
    }
  }

  writeValue(value) {
    let normalizedValue = value;
    if (typeof value === 'string') {
      normalizedValue = parseISO(value);
    }

    if (
      (value &&
        (this.dateValue == null ||
          !isSameMinute(this.dateValue, normalizedValue))) ||
      value == null
    ) {
      this.dateValue = normalizedValue;
    }

    super.writeValue(value);
  }

  updateValue(val) {
    if (this.skipChange) {
      this.skipChange = false;
      return;
    }
    if (!this.readonly) {
      if (val) {
        try {
          let newDate = val;
          if (!this.showTime && this.roundEndDate) {
            newDate = subSeconds(addDays(new Date(newDate), 1), 1);
          }
          this.val = newDate.toISOString();
        } catch (e) {
          this.val = null;
        }
      } else {
        this.val = null;
        this.dateValue = null;
      }
    }
  }

  private differenceTwoDates(
    to: string | Date,
    from: string | Date,
    ignoreZeros?: boolean,
  ): string {
    if (!to || !from) {
      return '';
    }
    let tmpTo = to;
    let tmpFrom = from;
    if (!(to instanceof Date)) {
      tmpTo = to ? new Date(to) : '';
    }
    if (!(from instanceof Date)) {
      tmpFrom = from ? new Date(from) : '';
    }

    const tmpFromDate = new Date(tmpFrom);
    const tmpToDate = new Date(tmpTo);

    const years = Math.abs(differenceInYears(tmpToDate, tmpFromDate));
    const months = Math.abs(differenceInMonths(tmpToDate, tmpFromDate) % 12);
    const days = Math.abs(differenceInDays(tmpToDate, tmpFromDate) % 30);
    const hours = Math.abs(differenceInHours(tmpToDate, tmpFromDate) % 24);
    const minutes = Math.abs(differenceInMinutes(tmpToDate, tmpFromDate) % 60);

    if (ignoreZeros) {
      return (
        (tmpFrom > tmpTo ? '- ' : '') +
        (years > 0 ? years + 'y ' : '') +
        (months > 0 ? months + 'm ' : '') +
        (days > 0 ? days + 'd ' : '') +
        (hours > 0 ? hours + 'h ' : '') +
        (minutes > 0 ? minutes + 'min' : '')
      );
    } else {
      return (
        (tmpFrom > tmpTo ? '- ' : '') +
        (years > 0 ? years + 'y ' : '') +
        (months > 0 ? months + 'm ' : '') +
        (days > 0 ? days + 'd ' : '0d ') +
        (hours > 0 ? hours + 'h ' : '0h ') +
        (minutes > 0 ? minutes + 'min' : 0 + 'min')
      );
    }
  }
}
