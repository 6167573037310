import {createAction, props} from '@ngrx/store';
import {Worklog} from '../models';

export const LoadDataWorklog = createAction(
  '[Worklog] Load data',
  props<{ownerId: string; ownerType: string}>(),
);

export const LoadDataWorklogSuccess = createAction(
  '[Worklog] Load data Success',
  props<{ownerId: string; ownerType: string; worklogs: Worklog[]}>(),
);

export const LoadDataWorklogError = createAction(
  '[Worklog] Load data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const SaveDataWorklog = createAction(
  '[Worklog] Save data',
  props<{
    ownerId: string;
    ownerType: string;
    worklog: Worklog;
    noSuccessToast?: boolean;
    listingId?: string;
  }>(),
);

export const SaveDataWorklogSuccess = createAction(
  '[Worklog] Save data Success',
  props<{
    ownerId: string;
    ownerType: string;
    worklog: Worklog;
    listingId?: string;
  }>(),
);

export const SaveDataWorklogError = createAction(
  '[Worklog] Save data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const DeleteDataWorklog = createAction(
  '[Worklog] Delete data',
  props<{
    ownerId: string;
    ownerType: string;
    worklog: Worklog;
    listingId?: string;
  }>(),
);

export const DeleteDataWorklogSuccess = createAction(
  '[Worklog] Delete data Success',
  props<{
    ownerId: string;
    ownerType: string;
    worklogId: string;
    listingId?: string;
  }>(),
);

export const DeleteDataWorklogError = createAction(
  '[Worklog] Delete data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const EditDataWorklog = createAction(
  '[Worklog] Edit data',
  props<{
    ownerId: string;
    ownerType: string;
    worklog: Worklog;
    openEdit: boolean;
  }>(),
);

export const EditDataWorklogText = createAction(
  '[Worklog] Edit worklog text',
  props<{
    ownerId: string;
    ownerType: string;
    worklog: Worklog;
    worklogText: string;
  }>(),
);

export const CollapsedWorklog = createAction(
  '[Worklog] Collapsed worklog',
  props<{ownerId: string; ownerType: string; worklog: Worklog}>(),
);

export const SetFilterWorklog = createAction(
  '[Worklog] Set filter worklog',
  props<{ownerId: string; ownerType: string; value: string}>(),
);
