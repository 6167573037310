import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {SECURITY_CONFIGURATION, SECURITY_MODULE_OPTIONS} from './di';
import {
  PolicyAttributeDirective,
  PolicyStructuralDirective,
} from './directives';
import {SecurityDataService, SecurityService} from './services';
import {SecurityConfiguration, SecurityModuleOptions} from './models';
import {EffectsModule} from '@ngrx/effects';
import * as effects from './effects';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';

export function defaultTokenGetter() {
  return localStorage.getItem('ACCESS_TOKEN');
}

@NgModule({
  imports: [
    StoreModule.forFeature('security', reducers),
    EffectsModule.forFeature([effects.SecurityDataEffects]),
  ],
  declarations: [PolicyStructuralDirective, PolicyAttributeDirective],
  exports: [PolicyStructuralDirective, PolicyAttributeDirective],
})
export class FrameworkSecurityModule {
  constructor(
    securityService: SecurityService,
    @Optional()
    @Inject(SECURITY_MODULE_OPTIONS)
    moduleOptions?: SecurityModuleOptions,
    @Optional()
    @Inject(SECURITY_CONFIGURATION)
    configuration?: SecurityConfiguration,
  ) {
    if (configuration) {
      securityService.addConfigurations(configuration);
    }
  }

  static forRoot(
    configuration?: SecurityConfiguration,
    options: SecurityModuleOptions = {
      policyConflictResolution: 'ignore',
      whenDuplicit: 'warning',
      tokenGetter: defaultTokenGetter,
      overridePrivileges: [],
    },
  ): ModuleWithProviders<FrameworkSecurityModule> {
    return {
      ngModule: FrameworkSecurityModule,
      providers: [
        SecurityService,
        SecurityDataService,
        {
          provide: SECURITY_CONFIGURATION,
          useValue: configuration,
        },
        {
          provide: SECURITY_MODULE_OPTIONS,
          useValue: options,
        },
      ],
    };
  }

  static forFeature(
    configuration?: SecurityConfiguration,
  ): ModuleWithProviders<FrameworkSecurityModule> {
    return {
      ngModule: FrameworkSecurityModule,
      providers: [
        {
          provide: SECURITY_CONFIGURATION,
          useValue: configuration,
        },
      ],
    };
  }
}
