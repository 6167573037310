import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {OnlineModel} from '../model';

export const LoadCheckOnline = createAction(
  '[CheckOnline] Load Check Online',
  props<{id: string}>(),
);
export const LoadCheckOnlineSuccess = createAction(
  '[CheckOnline] Load Check Online Success',
  props<{
    id: string;
    online: OnlineModel;
  }>(),
);
export const LoadCheckOnlineError = createAction(
  '[CheckOnline] Load Check Online Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);
