export const cs = {
  catalogPlugins: {
    headerDescription: 'Header dokumentace',
    instantiable: 'Povolit vytváření instancí',
    priceDetail: 'Slevové položky',
    catalogRelations: 'Vazby katalogu',
    catalogPrivs: 'Oprávnění katalogu',
    orderLine: {
      entitySpecificationInstance: 'Konfigurační profil řádku objednávky',
    },
    filters: {
      tsmCatalogCategoryFilter: 'tSM Catalog Category Filter',
      tsmCatalogCategoryFilterDescription:
        'Poskytuje rozhraní pro filtrování kategorií katalogu s dynamickými možnostmi. ' +
        'Podporuje filtrování podle názvu, kódu a typu entity, umožňuje vícenásobný výběr.',
      tsmCatalogCodeFilter: 'tSM Catalog Code Filter',
      tsmCatalogCodeFilterDescription:
        'Poskytuje rozhraní pro filtrování katalogů podle kódu a názvu dynamicky. ' +
        'Podporuje vícenásobný výběr a umožňuje filtrování podle typu katalogu.',
      tsmCatalogEntityTypeFilter: 'tSM Catalog Entity Type Filter',
      tsmCatalogEntityTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů entit katalogu dynamicky. ' +
        'Získává dostupné typy a podporuje vícenásobný výběr pro větší flexibilitu.',
      tsmCatalogSpecificationFilter: 'tSM Catalog Specification Filter',
      tsmCatalogSpecificationFilterDescription:
        'Poskytuje rozhraní pro filtrování specifikací katalogu dynamicky. ' +
        'Podporuje filtrování podle názvu, kódu a typu entity, umožňuje vícenásobný výběr.',
      tsmCatalogSpecificationStatusFilter:
        'tSM Catalog Specification Status Filter',
      tsmCatalogSpecificationStatusFilterDescription:
        'Poskytuje rozhraní pro filtrování stavů specifikací katalogu dynamicky. ' +
        'Podporuje filtrování podle kódu a názvu, umožňuje vícenásobný výběr.',
      tsmCatalogSpecificationTypeFilter:
        'tSM Catalog Specification Type Filter',
      tsmCatalogSpecificationTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů specifikací katalogu dynamicky. ' +
        'Podporuje filtrování podle názvu a kódu, umožňuje vícenásobný výběr.',
      tsmCatalogStatusFilter: 'tSM Catalog Status Filter',
      tsmCatalogStatusFilterDescription:
        'Umožňuje filtrování stavů katalogu pomocí vícenásobného výběru. ' +
        'Dynamicky filtruje položky podle kódu a názvu.',
      tsmCatalogTypeFilter: 'tSM Catalog Type Filter',
      tsmCatalogTypeFilterDescription:
        'Umožňuje vícenásobný výběr filtru pro typy katalogu. ' +
        'Načítá dostupné typy katalogu a aplikuje filtrování ' +
        'na základě uživatelského výběru.',
    },
    pipes: {
      catalogCategoryCode: 'Catalog Category Code',
      catalogCategoryCodeDescription:
        'Načítá data kategorie katalogu podle jejího kódu a souvisejícího kódu katalogu z úložiště, získává ' +
        'specifická pole, jako je název nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      catalogCategory: 'Catalog Category',
      catalogCategoryDescription:
        'Načítá data kategorie katalogu podle jejího ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      catalogCode: 'Catalog Code',
      catalogCodeDescription:
        'Načítá data katalogu podle jeho kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      catalogSpecificationLivecycle: 'Catalog Specification Livecycle',
      catalogSpecificationLivecycleDescription:
        'Formátuje data specifikace katalogu na základě jejího životního cyklu a aplikuje vizuální styl ' +
        'pro označení, zda je stav "ACTIVE", "INACTIVE" nebo jiný stav.',
      catalogSpecificationStatusCode: 'Catalog Specification Status Code',
      catalogSpecificationStatusCodeDescription:
        'Načítá stav specifikace katalogu podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      catalogSpecificationStatus: 'Catalog Specification Status',
      catalogSpecificationStatusDescription:
        'Načítá stav specifikace katalogu podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      catalogStatus: 'Catalog Status',
      catalogStatusDescription:
        'Načítá stav katalogu podle ID z úložiště, získává specifická pole, jako je název, nebo celý objekt ' +
        'a zajišťuje načtení dat, pokud již nejsou dostupná.',
      catalog: 'Catalog',
      catalogDescription:
        'Načítá data katalogu podle ID z úložiště, získává specifická pole, jako je název, nebo celý objekt ' +
        'a zajišťuje načtení dat, pokud již nejsou dostupná.',
      entityCatalogSpecCode: 'Entity Catalog Spec Code',
      entityCatalogSpecCodeDescription:
        'Načítá data specifikace katalogu podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      entityCatalogSpec: 'Entity Catalog Spec',
      entityCatalogSpecDescription:
        'Získává data specifikace katalogu podle ID z úložiště, načítá specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      entityCatalogSpecTypeCode: 'Entity Catalog Spec Type Code',
      entityCatalogSpecTypeCodeDescription:
        'Získává data typu specifikace katalogu podle kódu z úložiště, načítá specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      entityCatalogSpecType: 'Entity Catalog Spec Type',
      entityCatalogSpecTypeDescription:
        'Získává data typu specifikace katalogu podle ID z úložiště, načítá specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      priceBookItem: 'Price Book Item',
      priceBookItemDescription:
        'Zpracovává seznam položek ceníku, filtruje je podle konkrétního ID ceníku a formátuje výsledek ' +
        's přeloženými popisky pro opakované ceny a období. Vrací prázdný řetězec, pokud jsou hodnota ' +
        'nebo argumenty neplatné.',
      relationshipArrow: 'Relationship Arrow',
      relationshipArrowDescription:
        'Generuje HTML reprezentaci šipky podle směru vztahu v poskytnutých řádkových datech. Přidá ' +
        'šipku vpřed, pokud je vztah odchozí, šipku vzad, pokud je příchozí, a žádnou šipku, pokud ' +
        'vztah není přítomen.',
      relationshipCategorySpecificationIcon:
        'Relationship Category Specification Icon',
      relationshipCategorySpecificationIconDescription:
        'Zobrazuje HTML reprezentaci ikony vztahu. Pokud je cílová entita kategorizovaná, používá se ' +
        'modré pozadí. Jinak se použije zelené pozadí, které označuje nekategorizovanou entitu.',
      RelationshipCodeLink: 'Relationship Code Link',
      RelationshipCodeLinkDescription:
        'Generuje HTML odkaz pro kód vztahu. Pokud není cílová entita kategorizovaná, vytvoří odkaz ' +
        'na specifikaci katalogu. Pokud je cílová entita kategorizovaná, vytvoří odkaz na kategorii ' +
        'katalogu. Pokud cílová entita nesplňuje žádnou z těchto podmínek, vrátí pouze ID.',
      RelationshipSpecificationState: 'Relationship Specification State',
      RelationshipSpecificationStateDescription:
        'Načítá stav životního cyklu specifikace katalogu podle ID. Pokud je stav aktivní, zobrazí název stavu ' +
        'se zeleným pozadím. Pokud je neaktivní, použije červené pozadí. Pro ostatní stavy použije šedé pozadí. ' +
        'Vrátí prázdný řetězec, pokud nejsou dostupná žádná data.',
    },
  },
};
