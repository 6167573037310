import {Pipe, PipeTransform} from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({
  name: 'filter',
  pure: true,
})
export class FilterPipe implements PipeTransform {
  constructor() {}

  transform(arr: any[], field: string, value: any): any[] {
    return arr.filter((x) => objectPath.get(x, field) === value);
  }
}
