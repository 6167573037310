import {Component, EventEmitter, Input, Output} from '@angular/core';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  TsmFormBuilder,
  TypedTsmFormArray,
  TypedTsmFormControl,
  TypedTsmFormGroup,
} from '@tsm/framework/forms';
import {DtlMenuItem, DtlMenuItemType} from '@tsm/framework/plugin';
import {Validators} from '@angular/forms';
import {TranslocoService} from '@tsm/framework/translate';

@Component({
  selector: 'tsm-layout-menu-item-editor',
  templateUrl: 'layout-menu-item-editor.component.html',
})
export class LayoutMenuItemEditorComponent {
  translation = translation;
  translationShared = translationShared;

  form: TypedTsmFormGroup;
  registerValueControl: TypedTsmFormControl = new TypedTsmFormControl();

  private _menuItem: DtlMenuItem;
  get menuItem(): DtlMenuItem {
    return this._menuItem;
  }

  @Input()
  set menuItem(value: DtlMenuItem) {
    this._menuItem = value;
    if (value != null) {
      this.form.patchValue(value);
      if (this.langs.some((x) => !!value?.localizationData?.[x]?.label)) {
        this.showLabelLang = true;
      }
      value.privilege.forEach((p) => {
        this.addPrivilege(p);
      });
    }
  }

  @Input()
  isBookmark = false;

  @Output() save: EventEmitter<any> = new EventEmitter();

  @Output() cancel: EventEmitter<void> = new EventEmitter();

  showLabelLang = false;

  protected langs = (
    this.translocoSevice.getAvailableLangs() as string[]
  ).filter((x) => x !== this.translocoSevice.getDefaultLang());

  constructor(
    private fb: TsmFormBuilder,
    private translocoSevice: TranslocoService,
  ) {
    //   /** slouzi pro sparovani s profilama (abych vedel, pro ktery polozky v menu vyvorit podrizene polozky z profilu)*/
    //   listingType?: string;
    //   config?: DtlMenuItemConfigApi;
    this.form = new TypedTsmFormGroup<any>({
      id: this.fb.typedTsmControl(null),
      key: this.fb.typedTsmControl(null, Validators.required),
      listingType: this.fb.typedTsmControl(null),
      label: this.fb.typedTsmControl(null, Validators.required),
      icon: this.fb.typedTsmControl(null),
      url: this.fb.typedTsmControl(null),
      routerLink: this.fb.typedTsmControl(null),
      queryParams: this.fb.typedTsmControl({}),
      priority: this.fb.typedTsmControl(0),
      privilege: this.fb.typedTsmArray([]),
      type: this.fb.typedTsmControl(DtlMenuItemType.MENU),
      visible: this.fb.typedTsmControl(false),
      userId: this.fb.typedTsmControl(null),
      localizationData: fb.typedTsmGroup({}),
    });

    this.registerValueControl.valueChanges.subscribe((x) => {
      this.form.patchValue({
        key: 'REGISTER_' + x.code,
        label: x.name,
        routerLink: '/config/registers/' + x.id + '/values',
      });
    });

    this.langs.forEach((lang) => {
      const group = this.fb.typedTsmGroup({
        label: this.fb.typedTsmControl(null),
      });
      (
        this.form.get('localizationData') as unknown as TypedTsmFormGroup
      ).setControl(lang, group);
    });
  }

  onSave() {
    if (this.form.valid) {
      let resultMenuItem = {
        ...this.form.value,
        privilege: this.form.value?.privilege?.filter((x) => !!x),
        translateLabel: this.translocoSevice.translate(this.form.value.label),
        routerLink: !!this.form.value?.routerLink
          ? Array.isArray(this.form.value?.routerLink)
            ? this.form.value?.routerLink
            : [this.form.value?.routerLink]
          : null,
        url: !!this.form.value?.url ? this.form.value?.url : null,
        items: this.menuItem?.items?.length > 0 ? this.menuItem?.items : [],
      };
      this.save.emit(resultMenuItem);
    } else {
      this.form.markAsTouchedRecursively();
    }
  }

  addPrivilege(privilege: string) {
    let priv;
    if (privilege) {
      priv = this.fb.typedTsmControl(privilege);
    } else {
      priv = this.fb.typedTsmControl(null);
    }
    (this.form.get('privilege') as unknown as TypedTsmFormArray).push(priv);
  }

  deletePrivilege(index: number) {
    (this.form.get('privilege') as unknown as TypedTsmFormArray).removeAt(
      index,
    );
  }

  onSelectedIcon(value: string) {
    this.form.get('icon').patchValue(value);
  }

  toggleLabelLang() {
    this.showLabelLang = !this.showLabelLang;
  }
}
