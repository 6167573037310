import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/catalog/plugins-i18n';
import {type Config} from '@tsm/framework/config';

export const controlsCatalogCategoryNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-catalog-category-new',
    name: 'CatalogCategory new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'EntityCatalogCategory',
    tsmControls: {
      categoryName: {
        name: translationShared.shared.title,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      categoryCode: {
        name: translationShared.shared.code,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.code,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      entityCatalog: {
        name: translationShared.shared.catalog,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.catalog,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-lov',
          inputs: {
            returnObject: true,
          },
        },
      },
      parent: {
        name: translationShared.shared.superordinateCategory,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.superordinateCategory,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-category-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      entitySpecificationSpecId: {
        name: translationShared.shared.entitySpecification,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.entitySpecification,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-specification-lov',
          inputs: {
            defaultFilters: [
              {
                field: 'entityType.code',
                value: 'EntityCatalogSpecification',
                operator: 'in',
              },
            ],
          },
        },
      },
      icon: {
        name: translationShared.shared.icon,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'icon-picker',
            label: translationShared.shared.icon,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon-picker',
        },
      },
      sortOrder: {
        name: translationShared.shared.itemOrdering,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label: translationShared.shared.itemOrdering,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-number',
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsCatalogSpecificationNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-catalog-specification-new',
    name: 'CatalogSpecification new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'EntityCatalogSpecification',
    tsmControls: {
      specificationName: {
        name: translationShared.shared.title,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      specificationCode: {
        name: translationShared.shared.code,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.code,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      catalogCategory: {
        name: translationShared.shared.category,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.category,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-category-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      entityInstanceSpecId: {
        name: translationShared.shared.entitySpecificationInfo,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.entitySpecificationInfo,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-specification-lov',
          inputs: {
            defaultFilters: [
              {
                field: 'entityType.code',
                value: 'EntityInstanceConfiguration',
                operator: 'in',
              },
            ],
          },
        },
      },
      orderLineSpecId: {
        name: translation.catalogPlugins.orderLine.entitySpecificationInstance,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translation.catalogPlugins.orderLine.entitySpecificationInstance,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-specification-lov',
          inputs: {
            defaultFilters: [
              {
                field: 'entityType.code',
                value: 'OrderLine',
                operator: 'in',
              },
            ],
          },
        },
      },
      entityInstanceLocation1Required: {
        name: translationShared.shared.entityInstanceLocation1RequiredHeader,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translationShared.shared.entityInstanceLocation1RequiredHeader,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-instance-location-required-lov',
        },
      },
      icon: {
        name: translationShared.shared.icon,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.icon,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon-picker',
        },
      },
      sortOrder: {
        name: translationShared.shared.itemOrdering,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label: translationShared.shared.itemOrdering,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-number',
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      instantiable: {
        name: translation.catalogPlugins.instantiable,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'checkbox',
            label: translation.catalogPlugins.instantiable,
            labelPosition: 'right',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-checkbox',
          inputs: {
            labelPosition: 'right',
          },
        },
      },
      lifecycleStatus: {
        name: translationShared.shared.lifecycleStatusCode,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.lifecycleStatusCode,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-specification-status-lov',
        },
      },
    },
  };

export const controlsCatalogCategoryDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-catalog-category-detail',
    name: 'CatalogCategory detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityCatalogCategory',
    tsmControls: {
      'CatalogCategory.Header': {
        name: translationShared.shared.header,
        description: translation.catalogPlugins.headerDescription,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'CatalogCategory.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'CatalogCategory.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'CatalogCategory.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) =>
              config.apiUrls.catalog + '/category/dataTags',
            parentPriv: 'Cat.Registers.Category',
          },
        },
      },
      'CatalogCategory.Details.EntityCatalog': {
        name: translationShared.shared.catalog,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.catalog,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-lov',
          inputs: {
            returnObject: true,
          },
        },
      },
      'CatalogCategory.Details.SuperordinateCategory': {
        name: translationShared.shared.superordinateCategory,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.superordinateCategory,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-category-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'CatalogCategory.Details.EntitySpecificationSpecId': {
        name: translationShared.shared.entitySpecification,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.entitySpecification,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-specification-lov',
          inputs: {
            defaultFilters: [
              {
                field: 'entityType.code',
                value: 'EntityCatalogCategory',
                operator: 'in',
              },
            ],
          },
        },
      },
      'CatalogCategory.Details.Icon': {
        name: translationShared.shared.icon,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.icon,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon',
        },
      },
      'CatalogCategory.Details.SortOrder': {
        name: translationShared.shared.itemOrdering,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.itemOrdering,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-key-filter',
        },
      },
      'CatalogCategory.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'CatalogCategory.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogCategory.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogCategory.Dates.ValidFrom': {
        name: translationShared.shared.validFrom,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validFrom,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogCategory.Dates.ValidTo': {
        name: translationShared.shared.validTo,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validTo,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogCategory.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'CatalogCategory.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
    },
  };

export const controlsCatalogSpecificationDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-catalog-specification-detail',
    name: 'CatalogSpecification detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityCatalogSpecification',
    tsmControls: {
      'CatalogSpecification.Header': {
        name: translationShared.shared.header,
        description: translation.catalogPlugins.headerDescription,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'CatalogSpecification.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'CatalogSpecification.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'CatalogSpecification.Details.Labels': {
        name: translationShared.shared.labels,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.labels,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-data-tags',
          inputs: {
            url: (config: Config) =>
              config.apiUrls.catalog + '/category/dataTags',
            parentPriv: 'Cat.Registers.Category',
          },
        },
      },
      'CatalogSpecification.Details.Category': {
        name: translationShared.shared.category,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.category,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-category-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      'CatalogSpecification.Details.LifecycleStatus': {
        name: translationShared.shared.lifecycleStatusCode,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.lifecycleStatusCode,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-specification-status-lov',
        },
      },
      'CatalogSpecification.Details.EntitySpecificationType': {
        name: translationShared.shared.catalogSpecificationTypeLabel,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.catalogSpecificationTypeLabel,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-catalog-specification-type-lov',
          inputs: {
            returnObject: 'true',
            showClear: 'true',
          },
        },
      },
      'CatalogSpecification.Details.EntityInstanceLocation1Required': {
        name: translationShared.shared.entityInstanceLocation1RequiredHeader,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label:
              translationShared.shared.entityInstanceLocation1RequiredHeader,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-instance-location-required-lov',
        },
      },
      'CatalogSpecification.Details.EntityInstanceLocation2Required': {
        name: translationShared.shared.entityInstanceLocation2RequiredHeader,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label:
              translationShared.shared.entityInstanceLocation2RequiredHeader,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-instance-location-required-lov',
        },
      },
      'CatalogSpecification.Details.Instantiable': {
        name: translation.catalogPlugins.instantiable,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translation.catalogPlugins.instantiable,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-checkbox',
          inputs: {
            labelPosition: 'right',
          },
        },
      },
      'CatalogSpecification.Details.Icon': {
        name: translationShared.shared.icon,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.icon,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon-picker',
        },
      },
      'CatalogSpecification.Details.SortOrder': {
        name: translationShared.shared.itemOrdering,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.itemOrdering,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon-picker',
        },
      },
      'CatalogSpecification.Details.EntitySpecificationId': {
        name: translationShared.shared.entitySpecification,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.entitySpecification,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-specification-lov',
          inputs: {
            defaultFilters: [
              {
                field: 'entityType.code',
                value: 'EntityCatalogSpecification',
                operator: 'in',
              },
            ],
          },
        },
      },
      'CatalogSpecification.Details.OrderLineSpecId': {
        name: translation.catalogPlugins.orderLine.entitySpecificationInstance,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label:
              translation.catalogPlugins.orderLine.entitySpecificationInstance,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-specification-lov',
          inputs: {
            defaultFilters: [
              {
                field: 'entityType.code',
                value: 'OrderLine',
                operator: 'in',
              },
            ],
          },
        },
      },
      'CatalogSpecification.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'tsm-form-input-icon-tiptap',
        },
      },
      'CatalogSpecification.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogSpecification.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'date',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      'CatalogSpecification.Dates.ValidFrom': {
        name: translationShared.shared.validFrom,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validFrom,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogSpecification.Dates.ValidTo': {
        name: translationShared.shared.validTo,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.validTo,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'CatalogSpecification.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'CatalogSpecification.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
    },
  };
