<tsm-monaco-editor
  [(ngModel)]="val"
  [defaultHeight]="widgetHeight ? widgetHeight : inline ? '1.5em' : null"
  [options]="editorOptions"
  [inline]="inline"
  [resizable]="resizable"
  language="java"
  [updateIfDirty]="updateIfDirty"
  (touchedChange)="onTouched(true)"
  (init)="onEditorInitialized($event)"
  data-cy="dtl-form-input-java"
></tsm-monaco-editor>
