import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';
import {buildTitleMap, isEmpty} from 'tsm-json-schema-form-functions';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationComponent} from '@tsm/config-form/components-i18n';
import {translation as translationPlugin} from '@tsm/config-form/plugins-i18n';
import {of} from 'rxjs';
import {
  controlSpecificationCharacteristicsConfiguration,
  controlsRegisterValueDetailConfiguration,
  controlsRegisterValueNewConfiguration,
} from './tsm-controls-configuration';

export const configFormPlugin: Plugin = {
  menus: [
    {
      key: 'configForm',
      priority: 800,
      label: 'menu.configForm.configForm',
      icon: 'tsm-icon-config-form',
      privilege: [
        'Config.EntitySpecifications.ViewList',
        'Config.Characteristics.ViewList',
        'Config.Forms.ViewList',
        'Config.UserRegisters.ViewList',
      ],
      items: [
        {
          key: 'entitySpecifications',
          label: 'menu.configForm.entitySpecifications',
          icon: 'tsm-icon-entity-specifications',
          routerLink: ['/config/entity-specifications'],
          listingType: 'cnf-entity-specification',
          privilege: ['Config.EntitySpecifications.ViewList'],
        },
        {
          key: 'characteristics',
          label: 'menu.configForm.characteristics',
          icon: 'tsm-icon-characteristics',
          routerLink: ['/config/characteristics'],
          listingType: 'cnf-characteristic',
          privilege: ['Config.Characteristics.ViewList'],
        },
        {
          key: 'form-management',
          label: 'menu.configForm.formManagement',
          icon: 'tsm-icon-form',
          routerLink: ['/config/forms'],
          listingType: 'cnf-form',
          privilege: ['Config.Forms.ViewList'],
        },
        {
          key: 'registers',
          label: 'menu.configForm.registers',
          icon: 'tsm-icon-management-1',
          routerLink: ['/config/user-register'],
          listingType: 'cnf-register',
          privilege: ['Config.UserRegisters.ViewList'],
        },
        // {
        //   key: 'fluentFormMigrations',
        //   label: 'menu.configForm.fluentFormMigrations',
        //   icon: 'tsm-icon-management-1',
        //   routerLink: ['/config/fluent-form-migrations'],
        //   listingType: 'cnf-register',
        //   privilege: ['true']
        // },
        // {
        //   key: 'fluentFormDesigner',
        //   label: 'menu.configForm.fluentFormDesigner',
        //   icon: 'tsm-icon-management-1',
        //   routerLink: ['/config/fluent-form-designer'],
        //   listingType: 'cnf-register',
        //   privilege: ['true']
        // },
        // {
        //   key: 'fluentForm',
        //   label: 'menu.configForm.fluentForm',
        //   icon: 'tsm-icon-management-1',
        //   routerLink: ['/config/fluent-form'],
        //   listingType: 'cnf-register',
        //   privilege: ['true']
        // },
        // {
        //   key: 'fluentFormMigrations',
        //   label: 'menu.configForm.fluentFormMigrations',
        //   icon: 'tsm-icon-management-1',
        //   routerLink: ['/config/fluent-form-migrations'],
        //   listingType: 'cnf-register',
        //   privilege: ['true']
        // },
        // {
        //   key: 'treeView',
        //   label: 'menu.configForm.treeView',
        //   icon: 'tsm-icon-management-1',
        //   routerLink: ['/config/tree-view'],
        //   listingType: 'cnf-register',
        //   privilege: ['true']
        // }
      ],
    },
  ],
  routes: [
    {
      path: 'config',
      loadChildren: () =>
        import('@tsm/config-form/components').then(
          (x) => x.ConfigFormComponentsModule,
        ),
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/config-form/widgets').then(
          (x) => x.ConfigFormWidgetsModule,
        ),
      definitions: [
        {
          selector: 'arrayToString',
          name: translationPlugin.configFormPlugins.pipes.arrayToString,
          description:
            translationPlugin.configFormPlugins.pipes.arrayToStringDescription,
        },
        {
          selector: 'tsmCharsFormTypePipe',
          name: translationPlugin.configFormPlugins.pipes.tsmCharsFormTypePipe,
          description:
            translationPlugin.configFormPlugins.pipes
              .tsmCharsFormTypePipeDescription,
        },
        {
          selector: 'tsmColorExpressionPipe',
          name: translationPlugin.configFormPlugins.pipes
            .tsmColorExpressionPipe,
          description:
            translationPlugin.configFormPlugins.pipes
              .tsmColorExpressionPipeDescription,
        },
        {
          selector: 'configTypeCode',
          name: translationPlugin.configFormPlugins.pipes.configTypeCode,
          description:
            translationPlugin.configFormPlugins.pipes.configTypeCodeDescription,
        },
        {
          selector: 'configType',
          name: translationPlugin.configFormPlugins.pipes.configType,
          description:
            translationPlugin.configFormPlugins.pipes.configTypeDescription,
        },
        {
          selector: 'entitySpecificationCode',
          name: translationPlugin.configFormPlugins.pipes
            .entitySpecificationCode,
          description:
            translationPlugin.configFormPlugins.pipes
              .entitySpecificationCodeDescription,
        },
        {
          selector: 'entitySpecificationType',
          name: translationPlugin.configFormPlugins.pipes
            .entitySpecificationType,
          description:
            translationPlugin.configFormPlugins.pipes
              .entitySpecificationTypeDescription,
        },
        {
          selector: 'entitySpecification',
          name: translationPlugin.configFormPlugins.pipes.entitySpecification,
          description:
            translationPlugin.configFormPlugins.pipes
              .entitySpecificationDescription,
        },
        {
          selector: 'formatText',
          name: translationPlugin.configFormPlugins.pipes.formatText,
          description:
            translationPlugin.configFormPlugins.pipes.formatTextDescription,
        },
        {
          selector: 'tsmFormsCode',
          name: translationPlugin.configFormPlugins.pipes.tsmFormsCode,
          description:
            translationPlugin.configFormPlugins.pipes.tsmFormsCodeDescription,
        },
        {
          selector: 'tsmForms',
          name: translationPlugin.configFormPlugins.pipes.tsmForms,
          description:
            translationPlugin.configFormPlugins.pipes.tsmFormsDescription,
        },
        {
          selector: 'microserviceCode',
          name: translationPlugin.configFormPlugins.pipes.microserviceCode,
          description:
            translationPlugin.configFormPlugins.pipes
              .microserviceCodeDescription,
        },
        {
          selector: 'microservice',
          name: translationPlugin.configFormPlugins.pipes.microservice,
          description:
            translationPlugin.configFormPlugins.pipes.microserviceDescription,
        },
        {
          selector: 'mnemonicConfigCode',
          name: translationPlugin.configFormPlugins.pipes.mnemonicConfigCode,
          description:
            translationPlugin.configFormPlugins.pipes
              .mnemonicConfigCodeDescription,
        },
        {
          selector: 'mnemonicConfig',
          name: translationPlugin.configFormPlugins.pipes.mnemonicConfig,
          description:
            translationPlugin.configFormPlugins.pipes.mnemonicConfigDescription,
        },
        {
          selector: 'pipeInPipe',
          name: translationPlugin.configFormPlugins.pipes.pipeInPipe,
          description:
            translationPlugin.configFormPlugins.pipes.pipeInPipeDescription,
        },
        {
          selector: 'registerByCode',
          name: translationPlugin.configFormPlugins.pipes.registerByCode,
          description:
            translationPlugin.configFormPlugins.pipes.registerByCodeDescription,
        },
        {
          selector: 'registerById',
          name: translationPlugin.configFormPlugins.pipes.registerById,
          description:
            translationPlugin.configFormPlugins.pipes.registerByIdDescription,
        },
        {
          selector: 'registerValueArrayById',
          name: translationPlugin.configFormPlugins.pipes
            .registerValueArrayById,
          description:
            translationPlugin.configFormPlugins.pipes
              .registerValueArrayByIdDescription,
        },
        {
          selector: 'registerValueById',
          name: translationPlugin.configFormPlugins.pipes.registerValueById,
          description:
            translationPlugin.configFormPlugins.pipes
              .registerValueByIdDescription,
        },
        {
          selector: 'registerValueIcon',
          name: translationPlugin.configFormPlugins.pipes.registerValueIcon,
          description:
            translationPlugin.configFormPlugins.pipes
              .registerValueIconDescription,
        },
        {
          selector: 'registerValueName',
          name: translationPlugin.configFormPlugins.pipes.registerValueName,
          description:
            translationPlugin.configFormPlugins.pipes
              .registerValueNameDescription,
        },
        {
          selector: 'registerValue',
          name: translationPlugin.configFormPlugins.pipes.registerValue,
          description:
            translationPlugin.configFormPlugins.pipes.registerValueDescription,
        },
        {
          selector: 'tsmModulesCode',
          name: translationPlugin.configFormPlugins.pipes.tsmModulesCode,
          description:
            translationPlugin.configFormPlugins.pipes.tsmModulesCodeDescription,
        },
        {
          selector: 'tsmModulesId',
          name: translationPlugin.configFormPlugins.pipes.tsmModulesId,
          description:
            translationPlugin.configFormPlugins.pipes.tsmModulesIdDescription,
        },
      ],
    },
  ],
  directives: [
    {
      loadChildren: () =>
        import('@tsm/config-form/widgets').then(
          (x) => x.ConfigFormWidgetsModule,
        ),
      definitions: [
        {
          selector: '[viewDefer]',
          name: translationPlugin.configFormPlugins.directives.viewDefer,
          description:
            translationPlugin.configFormPlugins.directives.viewDeferDescription,
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/config-form/widgets').then(
          (x) => x.ConfigFormWidgetsModule,
        ),
      definitions: [
        {
          selector: 'tsm-config-type-filter',
          name: translationPlugin.configFormPlugins.filters.tsmConfigTypeFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmConfigTypeFilterDescription,
        },
        {
          selector: 'tsm-entity-specification-id-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmEntitySpecificationIdFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmEntitySpecificationIdFilterDescription,
        },
        {
          selector: 'tsm-entity-specification-type-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmEntitySpecificationTypeFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmEntitySpecificationTypeFilterDescription,
        },
        {
          selector: 'tsm-form-filter',
          name: translationPlugin.configFormPlugins.filters.tsmFormFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmFormFilterDescription,
        },
        {
          selector: 'tsm-form-id-filter',
          name: translationPlugin.configFormPlugins.filters.tsmFormIdFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmFormIdFilterDescription,
        },
        {
          selector: 'tsm-form-type-filter',
          name: translationPlugin.configFormPlugins.filters.tsmFormTypeFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmFormTypeFilterDescription,
        },
        {
          selector: 'tsm-index-mapping-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmIndexMappingFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmIndexMappingFilterDescription,
        },
        {
          selector: 'tsm-microservice-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmMicroserviceFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmMicroserviceFilterDescription,
        },
        {
          selector: 'tsm-register-value-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmRegisterValueFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmRegisterValueFilterDescription,
        },
        {
          selector: 'tsm-schema-location-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmSchemaLocationFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmSchemaLocationFilterDescription,
        },
        {
          selector: 'tsm-sync-method-type-filter',
          name: translationPlugin.configFormPlugins.filters
            .tsmSyncMethodTypeFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmSyncMethodTypeFilterDescription,
        },
        {
          selector: 'tsm-module-filter',
          name: translationPlugin.configFormPlugins.filters.tsmTsmModuleFilter,
          description:
            translationPlugin.configFormPlugins.filters
              .tsmTsmModuleFilterDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/config-form/widgets').then(
          (x) => x.ConfigFormWidgetsModule,
        ),
      definitions: [
        {
          selector: 'tsm-register-value-new-container',
        },
        {
          selector: 'tsm-register-value-list-widget',
          loadComponent: () =>
            import('@tsm/config-form/widgets/register-value-list-widget').then(
              (x) => x.RegisterValueListWidgetComponent,
            ),
        },
        {
          selector: 'tsm-form-button-inputs',
        },
        {
          selector: 'dtl-basic-editor',
        },
        {
          selector: 'tsm-listing-basic-editor',
        },
        {
          selector: 'dtl-basic-lovbox-editor',
        },
        {
          selector: 'dtl-basic-validation-editor',
        },
        {
          selector: 'tsm-listing-profile-card',
        },
        {
          selector: 'tsm-register-value-card',
        },
        {
          selector: 'tsm-register-card',
        },
        {
          selector: 'tsm-entity-specification-lov',
        },
        {
          selector: 'tsm-user-defined-list-widget',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            showClear: fluentSchema.required,
          }),
          designers: [
            {
              editorSelector: 'tsm-user-defined-list-widget-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.listingComponents.userRegisterListing,
              icon: 'tsm-icon-list-outline',
              formField: 'register',
              name: translationComponent.configFormComponents.components.form
                .userRegisterListing,
              group: GroupPosition.Primary,
              order: 3,
              value: {
                type: 'array',
                title:
                  translationComponent.configFormComponents.components.form
                    .userRegisterListing,
                widget: {
                  type: 'tsm-user-defined-list-widget',
                  readonly: true,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-register-value-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-register-value-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.listingComponents.userRegister,
              icon: 'tsm-icon-registers',
              formField: 'register',
              name: translationComponent.configFormComponents.components.form
                .userRegister,
              group: GroupPosition.Primary,
              order: 2,
              value: {
                type: 'string',
                title: translationShared.config.codeList,
                widget: {
                  type: 'tsm-register-value-lov',
                },
              },
            },
          ],
        },
        // FLUENT EDITGRID
        {
          selector: 'dtl-fluent-editgrid',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            readonly: fluentSchema.widget?.readonly,
            hidden: fluentSchema.widget?.hidden,
            buttons: fluentSchema.widget?.buttons,
          }),
          designers: [
            {
              // aliasSelector: 'array',
              editorSelector: 'dtl-fluent-editgrid-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description: translationShared.shared.editTableDescription,
              icon: 'tsm-icon-table',
              name: translationShared.shared.editTable,
              formField: 'editgrid',
              group: GroupPosition.Primary,
              order: 1,
              value: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    col0: {
                      type: 'string',
                      widget: {
                        type: 'text',
                        validationMessages: {},
                        notitle: true,
                      },
                    },
                    col1: {
                      type: 'string',
                      widget: {
                        type: 'text',
                        validationMessages: {},
                        notitle: true,
                      },
                    },
                  },
                },
                config: {
                  numberOfRows: {
                    value: 0,
                    onlyForEmpty: true,
                  },
                  headers: [
                    [
                      {
                        title: 'Column0',
                      },
                      {
                        title: 'Column1',
                      },
                    ],
                  ],
                },
                widget: {
                  type: 'dtl-fluent-editgrid',
                  notitle: true,
                },
              },
            },
          ],
        },
        // Fluent
        {
          selector: 'dtl-fluent-inner-form',
          widgetMapper: (schema: FluentSchema) => {
            return {
              hidden: schema.widget?.hidden,
            };
          },
          designers: [
            {
              aliasSelector: 'inner-form',
              editorSelector: 'dtl-fluent-inner-form-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              formField: 'form',
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.listingComponents.innerForm,
              icon: 'p-icon-code',
              name: translationComponent.configFormComponents.components.form
                .innerForm,
              group: GroupPosition.Tertiary,
              order: 2,
              value: {
                type: 'object',
                title: 'Form',
                widget: {
                  type: 'dtl-fluent-inner-form',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-entity-specifications-base-info',
          designers: [
            {
              editorSelector: 'tsm-entity-specifications-base-info-editor',
              widgetOrLayout: 'layout',
              aliasSelector: 'test-alias-selector',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets
                .configurationProfilePanelSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.configurationProfilePanelSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.panel,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets
                    .configurationProfilePanelSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-entity-specifications-base-info',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-characteristic-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
        },
        {
          selector: 'dtl-listing-profile-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'dtl-listing-profile-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'listingProfile',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets.listingProfileSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.listingProfileSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.listingProfileSelect
                    .inWidgetTitle,
                widget: {
                  type: 'dtl-listing-profile-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-listing-column-field-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'dtl-listing-column-field-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'listingColumn',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets.listingColumnSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.listingColumnSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.listingColumnSelect
                    .inWidgetTitle,
                widget: {
                  type: 'dtl-listing-column-field-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-config-form-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-config-form-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'form',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets.formSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.formSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.formSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-config-form-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-form-field',
          designers: [
            {
              editorSelector: 'tsm-form-field-editor',
              widgetOrLayout: 'widget',
              // zbytecny zobrazovat
              // categoryId: DesignerCategoryEnum.Listing,
              description: translationShared.menu.configForm.formField,
              icon: 'tsm-icon-table',
              formField: 'form',
              name: translationShared.menu.configForm.formField,
              value: {
                type: 'object',
                title: translationShared.menu.configForm.formField,
                widget: {
                  type: 'tsm-form-field',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-text',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
              leftIcon: fluentSchema.widget?.leftIcon,
              rightIcon: fluentSchema.widget?.rightIcon,
            };
          },
          designers: [
            {
              aliasSelector: 'text',
              editorSelector: 'dtl-input-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.textField,
              icon: 'tsm-icon-input',
              name: translationComponent.configFormComponents.components.form
                .textField,
              formField: 'text',
              group: GroupPosition.Primary,
              order: 1,
              value: {
                type: 'string',
                //translationShared.shared.text
                title: translationShared.config.text,
                widget: {
                  type: 'text',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-form-input-icon-picker',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-form-input-icon-picker-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.inputIconPicker,
              icon: 'tsm-icon-input',
              name: translationComponent.configFormComponents.components.form
                .inputIconPicker,
              formField: 'iconPicker',
              group: GroupPosition.Tertiary,
              order: 5,
              value: {
                type: 'string',
                title: translationShared.config.icon,
                widget: {
                  type: 'tsm-form-input-icon-picker',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-display-text',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              fieldHtmlClass: fluentSchema.widget?.fieldHtmlClass,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-display-text-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.advancedComponents.displayText,
              icon: 'tsm-icon-input',
              name: translationComponent.configFormComponents.components.form
                .displayTextField,
              backgroundColorOverride: DesignerDataColorOverride.blue,
              group: GroupPosition.Primary,
              order: 0,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-display-text',
                },
                config: {
                  text: 'Text',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-integer',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
        },
        {
          selector: 'dtl-form-input-number',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'number',
              editorSelector: 'dtl-input-number-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.numberField,
              icon: 'p-icon-filter-1',
              name: translationComponent.configFormComponents.components.form
                .numberField,
              formField: 'number',
              group: GroupPosition.Primary,
              order: 4,
              value: {
                type: 'number',
                //translationShared.shared.number
                title: translationShared.config.number,
                widget: {
                  type: 'number',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-textarea',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
              widgetHeight: fluentSchema?.widget.widgetHeight,
            };
          },
          designers: [
            {
              aliasSelector: 'textarea',
              editorSelector: 'dtl-textarea-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.textArea,
              icon: 'p-icon-sort',
              name: translationComponent.configFormComponents.components.form
                .longText,
              formField: 'textarea',
              group: GroupPosition.Primary,
              order: 2,
              value: {
                type: 'string',
                // translationComponent.configFormComponents.components.form.textArea
                title: translationShared.config.textarea,
                widget: {
                  type: 'textarea',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-tiptap',
          designers: [
            {
              aliasSelector: 'richtext',
              editorSelector: 'dtl-richtext-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.richTextEditor,
              icon: 'p-icon-font-download',
              name: translationShared.shared.editor,
              formField: 'richtext',
              group: GroupPosition.Primary,
              order: 3,
              value: {
                type: 'string',
                title: translationShared.config.textarea,
                widget: {
                  type: 'tiptap',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-checkbox',
          designers: [
            {
              aliasSelector: 'checkbox',
              editorSelector: 'dtl-checkbox-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.checkbox,
              icon: 'p-icon-check-box',
              name: translationComponent.configFormComponents.components.form
                .checkboxLabel,
              formField: 'checkbox',
              group: GroupPosition.Secondary,
              order: 1,
              value: {
                type: 'boolean',
                title: translationShared.config.checkbox,
                widget: {
                  type: 'checkbox',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-tri-state-checkbox',
          designers: [
            {
              aliasSelector: 'triStateCheckbox',
              editorSelector: 'dtl-tri-state-checkbox-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.triStateCheckbox,
              icon: 'p-icon-check-box',
              name: translationComponent.configFormComponents.components.form
                .triStateCheckboxLabel,
              formField: 'triStateCheckbox',
              group: GroupPosition.Secondary,
              order: 2,
              value: {
                type: 'boolean',
                title: translationShared.config.triStateCheckbox,
                widget: {
                  type: 'triStateCheckbox',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-switch',
          designers: [
            {
              aliasSelector: 'switch',
              editorSelector: 'dtl-switch-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.switch,
              icon: 'tsm-icon-switch',
              name: translationComponent.configFormComponents.components.form
                .switchLabel,
              formField: 'switch',
              group: GroupPosition.Secondary,
              order: 3,
              value: {
                type: 'boolean',
                title:
                  translationComponent.configFormComponents.components.form
                    .switchLabel,
                widget: {
                  type: 'switch',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-date',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'date',
              editorSelector: 'dtl-datepicker-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.dateTime,
              icon: 'p-icon-date-range',
              name: translationComponent.configFormComponents.components.form
                .dateTimeLabel,
              formField: 'date',
              group: GroupPosition.Primary,
              order: 5,
              value: {
                type: 'string',
                title: translationShared.config.date,
                widget: {
                  type: 'date',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-rating',
          designers: [
            {
              aliasSelector: 'rating',
              editorSelector: 'dtl-rating-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.rating,
              icon: 'p-icon-star-half',
              name: translationShared.shared.rating,
              formField: 'rating',
              group: GroupPosition.Tertiary,
              order: 4,
              value: {
                type: 'number',
                title: translationShared.config.rating,
                widget: {
                  type: 'rating',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-link',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'link',
              editorSelector: 'dtl-link-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.link,
              icon: 'tsm-icon-link',
              name: translationComponent.configFormComponents.components
                .buttonTypes.link,
              formField: 'link',
              group: GroupPosition.Tertiary,
              order: 1,
              value: {
                type: 'string',
                title: translationShared.config.link,
                widget: {
                  type: 'link',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-select',
          widgetMapper: (
            fluentSchema: FluentSchema,
            parentFluentSchema: FluentSchema,
          ) => {
            // TODO ??
            //if (its) return {items: its};
            const items = [];
            const selectList: any[] = buildTitleMap(fluentSchema.oneOf, null);

            if (!isEmpty(selectList)) {
              if (
                typeof selectList[0].title === 'string' &&
                selectList[0].title.indexOf('<em>None</em>') !== -1
              ) {
                selectList.shift();
              }
              selectList.forEach((item) =>
                items.push({label: item.title, value: item.const}),
              );
            }

            return {
              items: items,
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'select',
              editorSelector: 'dtl-select-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.selectField,
              icon: 'p-icon-list',
              name: translationComponent.configFormComponents.components.form
                .select,
              formField: 'select',
              group: GroupPosition.Secondary,
              order: 4,
              value: {
                type: 'string',
                title: translationShared.config.select,
                oneOf: [{title: null, const: null}],
                widget: {
                  type: 'select',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-autocomplete',
          widgetMapper: (
            fluentSchema: FluentSchema,
            parentFluentSchema: FluentSchema,
          ) => {
            // TODO ??
            //if (its) return {items: its};
            const items = [];
            const selectList: any[] = buildTitleMap(fluentSchema.oneOf, null);

            if (!isEmpty(selectList)) {
              if (
                typeof selectList[0].title === 'string' &&
                selectList[0].title.indexOf('<em>None</em>') !== -1
              ) {
                selectList.shift();
              }
              selectList.forEach((item) =>
                items.push({label: item.title, value: item.const}),
              );
            }

            return {
              items: items,
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'autocomplete',
              editorSelector: 'tsm-autocomplete-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.autocompleteField,
              icon: 'p-icon-list',
              name: translationShared.config.autocomplete,
              formField: 'autocomplete',
              group: GroupPosition.Secondary,
              order: 4,
              value: {
                type: 'string',
                title: translationShared.config.autocomplete,
                oneOf: [{title: null, const: null}],
                widget: {
                  type: 'tsm-autocomplete',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-password',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'password',
              editorSelector: 'dtl-input-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.password,
              icon: 'tsm-icon-password',
              name: translationShared.shared.password,
              formField: 'password',
              group: GroupPosition.Tertiary,
              order: 3,
              value: {
                type: 'string',
                title: translationShared.config.password,
                widget: {
                  type: 'password',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-mask',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.config?.maskPlaceholder,
            };
          },
          designers: [
            {
              aliasSelector: 'mask',
              editorSelector: 'dtl-mask-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.BasicComponents,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.maskField,
              icon: 'p-icon-text-fields',
              name: translationComponent.configFormComponents.components.form
                .maskField,
              formField: 'mask',
              group: GroupPosition.Tertiary,
              order: 2,
              value: {
                type: 'string',
                title: translationShared.config.mask,
                widget: {
                  type: 'mask',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-phone',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              aliasSelector: 'phone',
              editorSelector: 'dtl-phone-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description:
                translationComponent.configFormComponents.components.form
                  .phoneField,
              icon: 'p-icon-text-fields',
              name: translationComponent.configFormComponents.components.form
                .phoneField,
              formField: 'mask',
              value: {
                type: 'string',
                title: translationShared.config.phone,
                widget: {
                  type: 'phone',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-variable',
          designers: [
            {
              aliasSelector: 'variable',
              editorSelector: 'dtl-variable-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.variable,
              icon: 'tsm-icon-input',
              name: translationComponent.configFormComponents.components.form
                .variable,
              formField: 'variable',
              group: GroupPosition.Tertiary,
              order: 3,
              value: {
                type: 'string',
                title: translationShared.config.variable,
                widget: {
                  type: 'variable',
                  hidden: true,
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-section',
          designers: [
            {
              aliasSelector: 'object',
              editorSelector: 'dtl-fluent-object-editor',
              widgetOrLayout: 'widget',
              formField: 'obj',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.object,
              icon: 'p-icon-crop-landscape',
              name: translationShared.shared.objectAsObject,
              group: GroupPosition.Primary,
              order: 4,
              value: {
                type: 'object',
                properties: {},
                widget: {
                  type: 'dtl-fluent-section',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-fieldset',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              buttons: fluentSchema.widget?.buttons,
            };
          },
          designers: [
            {
              aliasSelector: 'object',
              editorSelector: 'dtl-fluent-fieldset-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              formField: 'arr',
              name: translationComponent.configFormComponents.components.form
                .array,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.array,
              icon: 'p-icon-crop-landscape',
              group: GroupPosition.Primary,
              order: 5,
              value: {
                type: 'array',
                widget: {
                  type: 'dtl-fluent-fieldset',
                },
                items: {},
                config: {
                  numberOfRows: {
                    value: 0,
                    onlyForEmpty: true,
                  },
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-accordion-array',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              buttons: fluentSchema.widget?.buttons,
            };
          },
          designers: [
            {
              aliasSelector: 'object',
              editorSelector: 'dtl-fluent-accordion-array-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              formField: 'accordionArr',
              name: translationComponent.configFormComponents.components.form
                .accordionArray,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.accordionArray,
              icon: 'p-icon-crop-landscape',
              group: GroupPosition.Primary,
              order: 6,
              value: {
                type: 'array',
                widget: {
                  type: 'dtl-fluent-accordion-array',
                },
                items: {},
                config: {},
              },
            },
          ],
        },
        // {
        //   selector: 'dtl-fluent-modal-array',
        //   designers: [{
        //     aliasSelector: 'object',
        //     editorSelector: 'dtl-fluent-modal-array-editor',
        //     widgetOrLayout: 'widget',
        //     categoryId: DesignerCategoryEnum.TechnicalPreview,
        //     formField: 'accordionArr',
        //     name: translationComponent.configFormComponents.components.form.accordionArray,
        //     description: translationComponent.configFormComponents.components.form.tooltip.basicComponents.accordionArray,
        //     icon: 'p-icon-crop-landscape',
        //     group: GroupPosition.Primary,
        //     order: 5,
        //     value: {
        //       type: 'array',
        //       widget: {
        //         type: 'dtl-fluent-modal-array'
        //       },
        //       items: {},
        //       config: {
        //       }
        //     }
        //   }]
        // },
        {
          selector: 'dtl-fluent-inplace',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
              //disableAutofocus: fluentSchema.widget?.disableAutofocus,
            };
          },
          designers: [
            {
              aliasSelector: 'inplace',
              editorSelector: 'dtl-fluent-inplace-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.inplace,
              icon: 'p-icon-web-asset',
              name: translationShared.shared.configForms.inplace,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-inplace',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'tsm-module-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
        },
        {
          selector: 'dtl-fluent-columns',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              nodeClass: fluentSchema.widget?.nodeClass,
              enableStickyHeader: fluentSchema.widget?.enableStickyHeader,
            };
          },
          designers: [
            {
              aliasSelector: 'column-layout',
              editorSelector: 'dtl-fluent-columns-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.layout,
              icon: 'p-icon-border-all',
              name: translationComponent.configFormComponents.components.form
                .layout,
              value: {
                type: 'layout',
                items: [],
                config: {
                  columns: [{width: 6}, {width: 6}],
                },
                widget: {
                  type: 'dtl-fluent-columns',
                },
              },
            },
          ],
        },
        //TODO: Take icon from fluent-panel-splitter
        {
          selector: 'dtl-fluent-panel-splitter',
          designers: [
            {
              aliasSelector: 'splitter',
              editorSelector: 'dtl-fluent-panel-splitter-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.splitter,
              icon: 'tsm-icon-splitter-horizontal',
              name: translationShared.shared.splitter,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-panel-splitter',
                },
                items: [],
                config: {
                  first: [],
                  second: [],
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-card',
          designers: [
            {
              aliasSelector: 'card',
              editorSelector: 'dtl-fluent-card-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.card,
              icon: 'p-icon-web-asset',
              name: translationShared.shared.card,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-card',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-panel',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              hidden: fluentSchema.widget?.hidden,
            };
          },
          designers: [
            {
              aliasSelector: 'panel',
              editorSelector: 'dtl-fluent-panel-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.panel,
              icon: 'tsm-icon-panel',
              name: translationShared.shared.panel,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-panel',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-section',
          designers: [
            {
              aliasSelector: 'section',
              editorSelector: 'dtl-fluent-section-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.section,
              icon: 'p-icon-crop-landscape',
              name: translationShared.shared.section,
              value: {
                type: 'layout',
                items: [],
                widget: {
                  type: 'dtl-fluent-section',
                },
                config: {
                  legend: 'Legend',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-fieldset',
          designers: [
            {
              aliasSelector: 'dtl-fluent-fieldset',
              editorSelector: 'dtl-fluent-fieldset-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.fieldset,
              icon: 'p-icon-border-style',
              name: translationShared.shared.fieldset,
              value: {
                type: 'layout',
                items: [],
                widget: {
                  type: 'dtl-fluent-fieldset',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-accordion',
          designers: [
            {
              aliasSelector: 'tab',
              editorSelector: 'dtl-fluent-accordion-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.accordion,
              icon: 'tsm-icon-tabpanel',
              name: translationShared.shared.accordion,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-accordion',
                },
                items: [],
                config: {
                  accordions: [
                    {
                      header: 'Accordion',
                      content: [],
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-anchor',
          designers: [
            {
              editorSelector: 'dtl-fluent-anchor-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.anchor,
              icon: 'tsm-icon-tabpanel',
              name: translationShared.shared.anchor,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-anchor',
                },
                items: [],
                config: {},
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-tab',
          designers: [
            {
              aliasSelector: 'tab',
              editorSelector: 'dtl-fluent-tab-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.tabs,
              icon: 'tsm-icon-tabpanel',
              name: translationShared.shared.tabs,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-tab',
                },
                items: [],
                config: {
                  tabs: [
                    {
                      header: 'Tab 1',
                      content: [],
                    },
                    {
                      header: 'Tab 2',
                      content: [],
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-steps',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            readonly: fluentSchema.widget?.readonly,
          }),
          designers: [
            {
              aliasSelector: 'tab',
              editorSelector: 'dtl-fluent-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.steps,
              icon: 'tsm-icon-steps-menu-5',
              name: translationShared.shared.steps,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-steps',
                },
                items: [],
                config: {
                  steps: [
                    {
                      header: 'Step 1',
                      content: [],
                    },
                    {
                      header: 'Step 2',
                      content: [],
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-button',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              customIconCssClass: fluentSchema.widget?.customIconCssClass,
              customCssClass: fluentSchema.widget?.customCssClass,
              disabled: fluentSchema.widget?.disabled,
              hidden: fluentSchema.widget?.hidden,
              tooltip: fluentSchema.widget?.tooltip,
              title: fluentSchema.title,
            };
          },
          designers: [
            {
              aliasSelector: 'button',
              editorSelector: 'dtl-button-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.button,
              icon: 'tsm-icon-button-1',
              name: translationShared.shared.button,
              backgroundColorOverride: DesignerDataColorOverride.blue,
              group: GroupPosition.Secondary,
              order: 1,
              value: {
                type: 'layout',
                title: translationShared.config.button,
                widget: {
                  type: 'dtl-button',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-clipboard-button',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              customIconCssClass: fluentSchema.widget?.customIconCssClass,
              customCssClass: fluentSchema.widget?.customCssClass,
              disabled: fluentSchema.widget?.disabled,
              hidden: fluentSchema.widget?.hidden,
              tooltip: fluentSchema.widget?.tooltip,
              title: fluentSchema.title,
            };
          },
          designers: [
            {
              aliasSelector: 'dtl-clipboard-button',
              editorSelector: 'dtl-clipboard-button-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.clipboardButton,
              icon: 'tsm-icon-button-1',
              name: translationShared.shared.clipboardButton,
              backgroundColorOverride: DesignerDataColorOverride.blue,
              group: GroupPosition.Secondary,
              order: 1,
              value: {
                type: 'layout',
                title: null,
                widget: {
                  type: 'dtl-clipboard-button',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-submit-button',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              customIconCssClass: fluentSchema.widget?.customIconCssClass,
              customCssClass: fluentSchema.widget?.customCssClass,
              disabled: fluentSchema.widget?.disabled,
              hidden: fluentSchema.widget?.hidden,
              tooltip: fluentSchema.widget?.tooltip,
              title: fluentSchema.title,
            };
          },
          designers: [
            {
              aliasSelector: 'dtl-submit-button',
              editorSelector: 'dtl-submit-button-editor',
              widgetOrLayout: 'widget',
              formField: 'submit',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.submitButton,
              icon: 'tsm-icon-button-1',
              name: translationComponent.configFormComponents.components.form
                .submitButton,
              group: GroupPosition.Secondary,
              order: 2,
              value: {
                type: 'object',
                title: translationShared.config.submitButton,
                widget: {
                  type: 'dtl-submit-button',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-submit-request-button',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              customIconCssClass: fluentSchema.widget?.customIconCssClass,
              customCssClass: fluentSchema.widget?.customCssClass,
              disabled: fluentSchema.widget?.disabled,
              hidden: fluentSchema.widget?.hidden,
              tooltip: fluentSchema.widget?.tooltip,
              title: fluentSchema.title,
            };
          },
          designers: [
            {
              aliasSelector: 'dtl-submit-request-button',
              editorSelector: 'dtl-submit-request-button-editor',
              widgetOrLayout: 'widget',
              formField: 'submitRequest',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.submitRequestButton,
              icon: 'tsm-icon-button-1',
              name: translationComponent.configFormComponents.components.form
                .submitRequestButton,
              group: GroupPosition.Secondary,
              order: 3,
              value: {
                type: 'object',
                title: translationShared.config.submitRequestButton,
                widget: {
                  type: 'dtl-submit-request-button',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-monaco',
          designers: [
            {
              aliasSelector: 'monaco-editor',
              editorSelector: 'dtl-monaco-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.monacoEditor,
              icon: 'p-icon-font-download',
              name: translationShared.shared.monacoEditor,
              formField: 'text',
              group: GroupPosition.Secondary,
              order: 4,
              value: {
                type: 'string',
                //translationShared.shared.text
                title: translationShared.config.javaExe,
                widget: {
                  type: 'monaco-editor',
                },
                config: {
                  type: 'string',
                  language: 'java',
                  widgetHeight: '200px',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-form-input-monaco-diff',
          designers: [
            {
              aliasSelector: 'monaco-diff-editor',
              editorSelector: 'dtl-monaco-diff-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.basicComponents.monacoDiffEditor,
              icon: 'p-icon-font-download',
              name: translationShared.shared.monacoDiffEditor,
              formField: 'diff',
              group: GroupPosition.Secondary,
              order: 5,
              value: {
                type: 'string',
                //translationShared.shared.text
                title: translationShared.shared.monacoDiffEditor,
                widget: {
                  type: 'monaco-diff-editor',
                },
                config: {
                  type: 'string',
                  language: 'json',
                  widgetHeight: '200px',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-bar',
          designers: [
            {
              aliasSelector: 'bar',
              editorSelector: 'dtl-fluent-bar-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              name: translationComponent.configFormComponents.components.form
                .bar,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.buttonBar,
              icon: 'pi-chevron-down',
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-bar',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'tsm-button-back',
          designers: [
            {
              aliasSelector: 'object',
              editorSelector: 'tsm-button-back-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              name: translationShared.shared.backButton,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.backButton,
              icon: 'tsm-icon-backward',
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-button-back',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-chart',
          designers: [
            {
              aliasSelector: 'chart',
              editorSelector: 'dtl-fluent-chart-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              name: translationComponent.configFormComponents.components.form
                .chart,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.charts,
              icon: 'p-icon-pie-chart',
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-chart',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-svg',
          designers: [
            {
              aliasSelector: 'svg',
              editorSelector: 'dtl-fluent-svg-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              name: translationComponent.configFormComponents.components.form
                .svg,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.svg,
              icon: 'tsm-icon-vector',
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-svg',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'tsm-text-hint',
          designers: [
            {
              editorSelector: 'tsm-text-hint-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.textHint,
              icon: 'p-icon-help',
              name: translationComponent.configFormComponents.components.form
                .textHint,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-text-hint',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-html',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            hidden: fluentSchema.widget?.hidden,
          }),
          designers: [
            {
              aliasSelector: 'html',
              editorSelector: 'dtl-fluent-html-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.html,
              icon: 'p-icon-code',
              name: translationComponent.configFormComponents.components.form
                .html,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-html',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-iframe',
          designers: [
            {
              aliasSelector: 'iframe',
              editorSelector: 'dtl-fluent-iframe-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.externalContent,
              icon: 'p-icon-system-update-alt',
              name: translationComponent.configFormComponents.components.form
                .iframe,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-iframe',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-clone',
          designers: [
            {
              aliasSelector: 'clone',
              editorSelector: 'dtl-fluent-clone-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.clone,
              icon: 'p-icon-web-asset',
              name: translationShared.shared.clone,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-clone',
                },
                items: [],
              },
            },
          ],
        },
        {
          selector: 'tsm-control',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            hidden: fluentSchema.widget?.hidden,
          }),
          designers: [
            {
              editorSelector: 'tsm-control-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tsmControlTooltip,
              icon: 'p-icon-web',
              name: translationComponent.configFormComponents.components.form
                .tsmControl,
              value: {
                type: 'tsm-control',
                id: '',
                widget: {
                  type: 'tsm-control',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-content',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            labelHtmlClass: fluentSchema.widget?.labelHtmlClass,
          }),
          designers: [
            {
              aliasSelector: 'content',
              editorSelector: 'dtl-fluent-content-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              description:
                translationComponent.configFormComponents.components.form
                  .tooltip.layoutComponents.content,
              icon: 'p-icon-content-paste',
              name: translationComponent.configFormComponents.components.form
                .content,
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-content',
                },
                config: {
                  html: '',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-section',
          designers: [
            {
              aliasSelector: 'object',
              editorSelector: 'dtl-fluent-object-editor',
              widgetOrLayout: 'layout',
              name: translationShared.shared.object,
              description: translationShared.shared.objectLayout,
              icon: '',
              value: {
                type: 'object',
                widget: {
                  type: 'dtl-fluent-section',
                },
              },
            },
          ],
        },
        {
          selector: 'dtl-fluent-toolbar',
          designers: [
            {
              aliasSelector: 'button-menu',
              editorSelector: 'dtl-fluent-toolbar-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Layout,
              name: translationComponent.configFormComponents.components.form
                .toolbar,
              description:
                translationComponent.configFormComponents.components.form
                  .toolbar,
              icon: 'pi-chevron-down',
              value: {
                type: 'layout',
                widget: {
                  type: 'dtl-fluent-toolbar',
                },
                items: [],
              },
            },
          ],
        },
        {selector: 'tsm-microservice-actuator'},
        {selector: 'tsm-entity-specifications-card'},
        {selector: 'tsm-form-card'},
        {
          selector: 'tsm-register-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
        },
        // {
        //   selector: 'dtl-column-layout',
        //   designers: [
        //     {
        //       aliasSelector: 'column-layout',
        //       editorSelector: 'dtl-layout-columns-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationComponent.configFormComponents.components.form.layout,
        //       icon: 'p-icon-border-all',
        //       name: translationComponent.configFormComponents.components.form.layout,
        //       value: {
        //         type: 'column-layout',
        //         items: [],
        //         columns: [{ width: 6 }, { width: 6 }],
        //         widget: {
        //           type: 'column-layout'
        //         }
        //       }
        //     }
        //   ]
        // },
        // Fluent columns
        // {
        //   selector: 'dtl-section-layout',
        //   designers: [
        //     {
        //       aliasSelector: 'section',
        //       editorSelector: 'dtl-layout-section-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationShared.shared.section,
        //       icon: 'ui-icon-crop-landscape',
        //       name: translationShared.shared.section,
        //       value: {
        //         type: 'section',
        //         items: [],
        //         widget: {
        //           type: 'section'
        //         },
        //         config: {
        //           legend: 'Legend'
        //         }
        //       }
        //     }
        //   ]
        // },
        // {
        //   selector: 'dtl-panel',
        //   designers: [
        //     {
        //       aliasSelector: 'panel',
        //       editorSelector: 'dtl-panel-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationShared.shared.panel,
        //       icon: 'p-icon-web-asset',
        //       name: translationShared.shared.panel,
        //       value: {
        //         type: 'panel',
        //         items: [],
        //         widget: {
        //           type: 'panel'
        //         }
        //       }
        //     }
        //   ]
        // },
        // {
        //   selector: 'dtl-card',
        //   designers: [
        //     {
        //       aliasSelector: 'card',
        //       editorSelector: 'dtl-layout-card-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationShared.shared.card,
        //       icon: 'p-icon-filter-none',
        //       name: translationShared.shared.card,
        //       value: {
        //         type: 'card',
        //         items: [],
        //         widget: {
        //           type: 'card'
        //         },
        //         config: {
        //           header: 'Card'
        //         }
        //       }
        //     }
        //   ]
        // },
        // FLUENT EDIT TABLE - NEZOBRAZOVAT SE, POUžÍVÁ SE EDITGRID
        {
          selector: 'dtl-fluent-edit-table',
          designers: [
            {
              // aliasSelector: 'edit-table',
              editorSelector: 'dtl-fluent-edit-table-editor',
              widgetOrLayout: 'widget',
              // categoryId: DesignerCategoryEnum.Listing,
              description: translationShared.shared.editTable,
              icon: 'tsm-icon-table',
              name: translationShared.shared.editTable,
              formField: 'array',
              value: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    col0: {
                      type: 'string',
                      widget: {
                        type: 'text',
                      },
                    },
                    col1: {
                      type: 'string',
                      widget: {
                        type: 'text',
                      },
                    },
                  },
                },
                widget: {
                  type: 'dtl-fluent-edit-table',
                  notitle: true,
                  buttons: {
                    add: false,
                  },
                },
              },
            },
          ],
        },
        // {
        //   selector: 'dtl-editgrid',
        //   widgetMapper: (fluentSchema: FluentSchema) => ({
        //     readonly: fluentSchema.widget?.readonly,
        //     hidden: fluentSchema.widget?.hidden
        //   }),
        //   designers: [
        //     {
        //       // aliasSelector: 'array',
        //       editorSelector: 'dtl-editgrid-editor',
        //       widgetOrLayout: 'widget',
        //       categoryId: DesignerCategoryEnum.Listing,
        //       description: translationShared.shared.editgrid,
        //       icon: 'tsm-icon-table',
        //       name: translationShared.shared.editgrid,
        //       formField: 'editgrit',
        //       value: {
        //         type: "object",
        //         properties: {
        //           array0: {
        //             type: "array",
        //             items: {
        //               type: "object",
        //               properties: {
        //                 col0: {
        //                   type: "string",
        //                   widget: {
        //                     type: "text"
        //                   }
        //                 },
        //                 col1: {
        //                   type: "string",
        //                   widget: {
        //                     type: "text"
        //                   }
        //                 }
        //               }
        //             }
        //           }
        //         },
        //         layout: [
        //           {
        //             type: "array",
        //             items: [
        //               "array0[].col0",
        //               "array0[].col1"
        //             ]
        //           }
        //         ],
        //         widget: {
        //           type: "dtl-editgrid",
        //           notitle: true
        //         }
        //       }
        //     }
        //   ]
        // },
        // {
        //   selector: 'dtl-tabs',
        //   designers: [
        //     {
        //       aliasSelector: 'tabs',
        //       editorSelector: 'dtl-tab-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationShared.shared.tabs,
        //       icon: 'p-icon-tab',
        //       name: translationShared.shared.tabs,
        //       value: {
        //         type: 'tabs',
        //         headers: [{ title: 'Tab1' }],
        //         items: [],
        //         widget: {
        //           type: 'tabs'
        //         }
        //       }
        //     }
        //   ]
        // },
        // {
        //   selector: 'dtl-html',
        //   widgetMapper: (fluentSchema: FluentSchema) => ({
        //     hidden: fluentSchema.widget?.hidden
        //   }),
        //   designers: [
        //     {
        //       aliasSelector: 'html',
        //       editorSelector: 'dtl-html-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationComponent.configFormComponents.components.form.htmlTooltip,
        //       icon: 'p-icon-code',
        //       name: translationComponent.configFormComponents.components.form.html,
        //       value: {
        //         type: 'html',
        //         widget: {
        //           type: 'html'
        //         }
        //       }
        //     }
        //   ]
        // },
        // {
        //   selector: 'dtl-iframe',
        //   designers: [
        //     {
        //       aliasSelector: 'iframe',
        //       editorSelector: 'dtl-iframe-editor',
        //       widgetOrLayout: 'layout',
        //       categoryId: DesignerCategoryEnum.Layout,
        //       description: translationComponent.configFormComponents.components.form.iframeTooltip,
        //       icon: 'p-icon-system-update-alt',
        //       name: translationComponent.configFormComponents.components.form.iframe,
        //       value: {
        //         type: 'iframe',
        //         widget: {
        //           type: 'iframe'
        //         }
        //       }
        //     }
        //   ]
        // },
        // {
        //   selector: 'tsm-chart',
        //   designers: [{
        //     editorSelector: 'tsm-chart-editor',
        //     widgetOrLayout: 'layout',
        //     name: translationComponent.configFormComponents.components.form.chart,
        //     categoryId: DesignerCategoryEnum.Layout,
        //     description: translationComponent.configFormComponents.components.form.chart,
        //     icon: 'p-icon-pie-chart',
        //     value: {
        //       type: 'tsm-chart',
        //       widget: {
        //         type: 'tsm-chart'
        //       }
        //     }
        //   }]
        // },
        {
          selector: 'tsm-characteristic',
          widgetMapper: (schema: FluentSchema) => {
            return {
              hidden: schema.widget?.hidden,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-characteristic-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description:
                translationComponent.configFormComponents.components.form
                  .tsmCharacteristicTooltip,
              icon: 'p-icon-web',
              name: translationComponent.configFormComponents.components.form
                .tsmCharacteristic,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-characteristic',
                },
                config: {
                  context: '${$context}',
                },
              },
            },
          ],
        },
        /*  {
            selector: 'tsm-characteristic',
            bannedInputs: ['readonly'],
            designers: [
              {
                editorSelector: 'tsm-characteristic-editor',
                widgetOrLayout: 'layout',
                categoryId: DesignerCategoryEnum.Layout,
                description: translationComponent.configFormComponents.components.form.tsmCharacteristicTooltip,
                icon: 'p-icon-web',
                name: translationComponent.configFormComponents.components.form.tsmCharacteristic,
                value: {
                  type: 'layout',
                  config: {
                    code: ''
                  },
                  widget: {
                    type: 'tsm-characteristic'
                  }
                }
              }
            ]
          },*/
        {
          selector: 'tsm-microservice-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
        },
        {
          selector: 'tsm-checklist',
          designers: [
            {
              editorSelector: 'tsm-checklist-editor',
              widgetOrLayout: 'widget',
              formField: 'checklist',
              categoryId: DesignerCategoryEnum.Advanced,
              description:
                translationComponent.configFormComponents.components.checklist
                  .tooltip,
              icon: 'tsm-icon-list',
              name: translationComponent.configFormComponents.components
                .checklist.title,
              group: GroupPosition.Tertiary,
              order: 10,
              value: {
                type: 'object',
                widget: {
                  type: 'tsm-checklist',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'EntitySpecification',
      url: (entityId) => {
        return of('/config/entity-specifications/' + entityId);
      },
    },
    {
      entityType: 'Form',
      url: (entityId) => {
        return of('/config/forms/' + entityId);
      },
    },
    {
      entityType: 'Register',
      url: (entityId) => {
        return of('/config/user-register/' + entityId);
      },
    },
    {
      entityType: 'RegisterValue',
      url: (entityId) => {
        return of('/config/user-register-value/' + entityId);
      },
    },
    {
      entityType: 'ConfigType',
      url: (entityId) => {
        return of('/config/config-type/' + entityId);
      },
    },
    {
      entityType: 'EntityType',
      url: (entityId) => {
        return of('/entity-type/entity-type/' + entityId);
      },
    },
    {
      entityType: 'Microservice',
      url: (entityId) => {
        return of('/config/microservice/' + entityId);
      },
    },
    {
      entityType: 'TsmModule',
      url: (entityId) => {
        return of('/config/tsmmodule/' + entityId);
      },
    },
    {
      entityType: 'MnemonicConfig',
      url: (entityId) => {
        return of('/config/mnemonic-config/' + entityId);
      },
    },
    {
      entityType: 'TableColumnConfig',
      url: (entityId) => {
        return of('/config/listing-column/' + entityId);
      },
    },
    {
      entityType: 'ListingType',
      url: (entityId) => {
        return of('/config/listing-type/' + entityId);
      },
    },
    {
      entityType: 'ListingProfile',
      url: (entityId) => {
        return of('/config/listing-profile/' + entityId);
      },
    },
    {
      entityType: 'BackupSet',
      url: (entityId) => {
        return of('/config/backup-set/' + entityId);
      },
    },
    {
      entityType: 'BackupSetImport',
      url: (entityId) => {
        return of('/config/backup-set-import' + entityId);
      },
    },
  ],
  layoutControls: [
    controlsRegisterValueNewConfiguration,
    controlsRegisterValueDetailConfiguration,
    controlSpecificationCharacteristicsConfiguration,
  ],
};
