import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {EntityIdentif, UserGroupNotificationConfig} from '../model';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  private readonly BASE_URL: string;
  private readonly PARAMETER_URL: string;
  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.userManagement;
    this.PARAMETER_URL = EntityIdentif.USER_GROUP;
  }

  saveNotificationConfiguration(
    userGroupId: string,
    config: UserGroupNotificationConfig,
  ) {
    return this.apiService.post(
      this.BASE_URL + `/${this.PARAMETER_URL}/save-notif-config/${userGroupId}`,
      config,
    );
  }
}
