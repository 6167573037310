import {TsmFormBuilder} from '@tsm/framework/forms';
import type {ListingBasicEditorDefaults} from '../models';

export const listingBasicEditorDefaults: ListingBasicEditorDefaults = {
  pageSize: null,
  scrollHeight: null,
  dataViewMode: 'TABLE',
  hidden: null,
  externalData: null,
  listing: null,
  profileId: null,
  profileCategory: null,
  showExport: true,
  showFilters: true,
  showRefresh: true,
  showPaginator: true,
  showManagerColumns: true,
  showProfiles: true,
  showManagerSort: true,
  showManagerColor: true,
  showListingConfig: true,
  hiddenDataViewMode: false,
  hideButtonGroup: false,
  scrollable: true,
  extendTqlWhere: '',
  externalTqlSort: '',
  externalTqlSortForNested: {},
  filters: null,
  customStyleClass: '',
};

/**
 * Creates a configurable form group for the Listing Basic Editor.
 * This method sets default values for various listing configuration parameters
 * while allowing them to be overridden as needed.
 *
 * @param {TsmFormBuilder} fb - The form builder used to create form controls and groups.
 * @param {Partial<ListingBasicEditorDefaults>} defaults - An optional set of values
 * that override the default listing editor configuration.
 * @returns {TsmFormGroup} - A form group containing the listing editor configuration
 * with the specified or default parameters.
 */
export function getListingBasicEditorDefaultsForm(
  fb: TsmFormBuilder,
  defaults: Partial<ListingBasicEditorDefaults> = {},
) {
  const defaultValues = {
    ...listingBasicEditorDefaults,
    ...defaults,
  };

  return fb.tsmGroup(
    Object.keys(defaultValues).reduce(
      (controls, key) => {
        controls[key] = fb.tsmControl(
          defaultValues[key as keyof ListingBasicEditorDefaults],
        );
        return controls;
      },
      {} as Record<string, any>,
    ),
  );
}
