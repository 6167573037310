import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {withLatestCached} from '@tsm/framework/root';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  DeleteWorklogType,
  DeleteWorklogTypeError,
  DeleteWorklogTypeSuccess,
  DiffWorklogType,
  DiffWorklogTypeError,
  DiffWorklogTypeSuccess,
  LoadWorklogType,
  LoadWorklogTypeByCode,
  LoadWorklogTypeByCodeError,
  LoadWorklogTypeByCodeSuccess,
  LoadWorklogTypeById,
  LoadWorklogTypeByIdError,
  LoadWorklogTypeByIdSuccess,
  LoadWorklogTypeError,
  LoadWorklogTypeSuccess,
  UpsertWorklogType,
  UpsertWorklogTypeError,
  UpsertWorklogTypeSuccess,
} from '../actions';
import {selectWorklogTypeByCode} from '../selectors';
import {CommonApiService, WorklogTypeDataService} from '../services';
import {WorklogType} from '../models';

@Injectable()
export class WorklogTypeEffects {
  private readonly WORKLOG_TYPE_API_PATH = 'v1/worklog-types';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadWorklogType),
      exhaustMap(() =>
        this.worklogTypeDataService
          .getAll()
          .pipe(
            map((env) =>
              env.success
                ? LoadWorklogTypeSuccess({worklogTypes: env.data})
                : LoadWorklogTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadWorklogTypeById),
      concatMap(({id}) =>
        this.worklogTypeDataService
          .getEntity(id)
          .pipe(
            map((env) =>
              env.success
                ? LoadWorklogTypeByIdSuccess({worklogType: env.data})
                : LoadWorklogTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadWorklogTypeByCode),
      withLatestCached(({code}) =>
        this.store.select(selectWorklogTypeByCode(code)),
      ),
      concatMap(([{code}, status]) =>
        status && status.data
          ? of(LoadWorklogTypeByCodeSuccess({worklogType: status.data}))
          : this.worklogTypeDataService.getEntityByCode(code).pipe(
              map((env) =>
                env.success
                  ? LoadWorklogTypeByCodeSuccess({worklogType: env.data})
                  : LoadWorklogTypeByCodeError({
                      code: code,
                      error: env.error,
                    }),
              ),
            ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertWorklogType),
      concatMap(({worklogType, listingId, redirect}) =>
        this.worklogTypeDataService
          .upsertEntity(worklogType, worklogType.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.worklogType.effects.worklogTypeSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertWorklogTypeSuccess({
                  worklogType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.worklogType.effects.worklogTypeSaveError,
                );
                return UpsertWorklogTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteWorklogType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.worklogTypeDataService.deleteEntity(id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.worklogType.effects.worklogTypeDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/dms/worklog-types']);
              }
              return DeleteWorklogTypeSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.worklogType.effects.worklogTypeDeleteError,
              );
              return DeleteWorklogTypeError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffWorklogType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.WORKLOG_TYPE_API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffWorklogTypeSuccess({
                  worklogTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffWorklogTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertWorklogTypeSuccess),
      tap(({worklogType, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/dms/worklog-types', worklogType.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteWorklogTypeSuccess, DiffWorklogTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private worklogTypeDataService: WorklogTypeDataService,
    private toastService: ToastService,
    private router: Router,
    private commonApiService: CommonApiService<WorklogType, WorklogType>,
  ) {}
}
