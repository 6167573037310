import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {DeliveryTime} from '../models';

export const DiffDeliveryTime = createAction(
  '[DeliveryTime] Diff DeliveryTime',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffDeliveryTimeSuccess = createAction(
  '[DeliveryTime] Diff DeliveryTime Success',
  props<{
    deliveryTimes: DeliveryTime[];
    listingId: string;
  }>(),
);
export const DiffDeliveryTimeError = createAction(
  '[DeliveryTime] Diff DeliveryTime Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
