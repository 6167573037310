export const cs = {
  listingLib: {
    components: {
      idFilterLabel: 'Seznam ID',
      columnManager: 'Správa sloupců',
      sortManager: 'Správa řazení',
      visibleColumns: 'Viditelné sloupce',
      hidenColumns: 'Skryté sloupce',
      searchColumnName: 'Vyhledat název sloupce',
      fulltextNotSupported: 'Fulltextové vyhledávání zde není podporované.',
      itemsOfFulltextSearch:
        'Fulltextové vyhledávání je možné dle následujících položek:',
      profileManager: 'Přehled profilů',
      profileSettings: 'Nastavení profilu',
      savedProfilesNotExists: 'Nenalezeny žádné uložené profily.',
      filters: 'Filtry',
      columnSettings: 'Nastavení sloupců listingu',
      saveProfileChanges: 'Uložit změny profilu',
      exportSettings: 'Nastavení exportu',
      maxCountForExport: 'Maximální počet záznamů pro export',
      repeating: 'Opakování',
      nextStart: 'Další spuštění',
      sendEmpty: 'Zasílat i prázdný',
      sendToOwner: 'Odeslat vlastníkovi profilu',
      sendToShared: 'Odeslat sdíleným uživatelům',
      xDayOfSelection: 'X-tý den ve zvoleném období',
      dontHaveAnyProfiles: 'Nemáte žádné profily',
      chooseProfile: 'Vyberte profil zobrazení',
      showAll: 'Vše',
      saveProfile: 'Uložit profil',
      saveProfileAs: 'Uložit profil jako',
      saveSettings: 'Uložit nastavení',
      exportConfigForm: 'Konfigurační formulář pro nastavení exportů',
      emailRecipsNote:
        'Pozn.: Jednotlivé emaily musí být oddělené středníkem. Emaily lze také překopírovat z Outlooku ve formátu - Jan Novák (jan.novak@gmail.com), Josef Novotný (josef.novotny@seznam.cz)',
      exportNote:
        'Pozn.: V případě, že měsíc nemá 31 dní a odeslání emailu je nastaveno na 31. den v měsíci, bude automaticky nastaven vždy poslední den v měsíci.',
      expressionForColorEval: 'Výraz pro vyhodnocení barvy',
      resetTableSettings: 'Reset nastavení tabulky',
      refreshTableSettings: 'Obnovení dat tabulky',
      editColumnConfiguration: 'Upravit konfiguraci sloupce',
      createColumnConfiguration: 'Vytvořit konfiguraci sloupce',
      listingType: 'Typ listingu',
      profileCategory: 'Kategorie profilu',
      main: 'Základní',
      field: 'Pole',
      visible: 'Viditelný',
      position: 'Pozice',
      dataType: 'Data typ',
      scriptField: 'Elasticsearch skript pole',
      sortType: 'Typ řazení',
      design: 'Vzhled',
      displayedField: 'Zobrazované pole',
      cssClass: 'CSS třída',
      width: 'Šířka v pixelech',
      widget: 'Widget',
      context: 'Kontext',
      fulltext: 'Fulltext',
      forbid: 'Zakázat',
      converter: 'Konverter',
      columnConfiguration: 'Konfigurace sloupců',
      listingCode: 'Kód listingu',
      header: 'Hlavička',
      tooltip: 'Popisek',
      periodPlaceholder: 'Např. 1d 12h 30m 5s, nebo -7d',
      periodBeforePlaceholder: 'Od',
      periodAfterPlaceholder: 'Do',
      fromRelative: 'Od (relativně)',
      toRelative: 'Do (relativně)',
      actualTimeplusInterval: 'Aktuální čas plus zadaný interval',
      addFilter: 'Přidat filtr',
      search: 'Hledat',
      filterByAttributeOrKeyword: 'Filtrovat dle atributu nebo klíč. slova',
      listing: 'Listing',
      default: 'Výchozí',
      rowsCount: 'Počet řádků',
      clearProfile: 'Zrušit profil',
      deleteProfile: 'Smazat profil',
      deleteProfileConfirm: 'Opravdu chcete smazat profil?',
      repeatedExportSetting: 'Nastavení opakovaného exportu',
      setColors: 'Nastavit barvy',
      settings: 'Nastavení',
      emptyMessage: 'Nenalezeny žádné záznamy',
      metrics: 'Metrics aggregation',
      metricsTooltip:
        'Specify aggregation method according to Elasticsearch metrics aggregation - e.g. sum, count, max, avg or including field name: sum(field), ...',
      buckets: 'Bucket aggregation',
      bucketsTooltip:
        'Specify aggregation method according to Elasticsearch bucket aggregation. Default is term (if not specified and Metrics aggregation is not specified).',
      addUrlParam: 'Doplňující filtry',
      shareProfile: 'Sdílet profil',
      removeAsDefault: 'Odstranit jako defaultní',
      setAsDefault: 'Nastavit jako defaultní',
      defaultProfileTooltip: 'Základní profil pro všechny uživatele',
      to: 'až',
      of: 'z',
      paginatorSettings: 'Nastavení stránkování',
      pageSettings: 'Nastavení stránky',
      pageSize: 'Poč. záznamů na stránku',
      minPage: 'Min. stránka je',
      maxPage: 'Max. stránka je',
      moreValueFilterTooltip:
        'Zadejte seznam hodnot oddělených čárkou nebo novým řádkem. \n Tip: hodnoty lze např. přenést copy & paste ze seznamu v Excelu.',
      selectAll: 'Označit všechny záznamy',
      deselectAll: 'Odoznačit všechny záznamy',
      selectDeselectAll: 'Označit / Odoznačit všechny záznamy',
      selectDeselect: 'Označit / Odoznačit',
      backupRestore: {
        NEW: 'Vytvářený záznam',
        CHANGE: 'Změněný záznam',
        REMOVE: 'Mazaný záznam',
      },
    },
    effects: {
      columnSaveSuccesMsg: 'Uložení sloupce úspěšné',
      columnSaveErrorMsg: 'Během ukládání sloupce došlo k chybě.',
      profileLoadError: 'Nepovedlo se načíst profily',
      profileSaveError: 'Nepovedlo se uložit profily',
      profileDeleteError: 'Nepovedlo se odstranit profil',
      loadDataError: 'Chyba při načítání dat',
      unSetAsDefaultProfileSuccessMsg:
        'Profil byl odstraněn z pozice defaultního',
      unSetAsDefaultProfileErrorMsg:
        'Profil se nepodařilo odstranit z pozice defaultního',
      removeAsDefaultProfileSuccessMsg: 'Profil byl nastaven jako defaultní',
      removeAsDefaultProfileErrorMsg:
        'Profil se nepodařilo nastavit jako defaultní',
      profileSaveSuccess: 'Profil byl úspěšně uložen',
      profileDeleteSuccess: 'Profil byl úspěšně smazán',
      badContentMsg: "Odpověď ze serveru neobsahuje atribut 'content'",
      unknownErrorMsg: 'Neznámá chyba',
      treeCircleErrorMsg:
        'Pří rozbalování položky [{{item}}] došlo k zacyklení. Data se nemusejí zobrazovat správně.',
      profileAddDefaultSaveSuccess:
        'Profil byl úspěšně nastaven jako defaultní',
      profileRemoveDefaultSaveSuccess:
        'Profil byl úspěšně zrušen jako defaultní',
      profileAddDefaultSaveError: 'Nepovedlo se nastavit profil jako defaultní',
      profileRemoveDefaultSaveError:
        'Nepovedlo se zrušit nastavení defaultního profilu',
    },
    dateRound: {
      title: 'Zaokrouhlení',
      HOUR: 'hodina',
      DAY: 'den',
      WEEK: 'týden',
      MONTH: 'měsíc',
    },
    form: {
      titlePosition: 'Pozice názvu',
      optionalInputClass: 'Volitelná CSS třída inputu',
      optionalDescriptionClass: 'Volitelná CSS třída popisu',
      hideTitle: 'Skrýt název',
      hidden: 'Skrytý (hidden)',
    },
    tagFilterWidget: {
      undefinedTag: 'Bez štítku',
    },
    sharing: {
      save: {
        success: 'Ukládání vazby sdílení proběhlo úspěšně',
        error: 'Během ukládání vazby sdílení došlo k chybě',
      },
      delete: {
        success: 'Vazba sdílení smazána',
        error: 'Během mazání vazby sdílení došlo k chybě',
      },
    },
    exportStatus: {
      ACTIVE: 'Aktivní',
      INACTIVE: 'Neaktivní',
    },
    dataType: {
      VARCHAR: 'Varchar',
      INTEGER: 'Integer',
      BIGINT: 'Bigint',
      DOUBLE: 'Double',
      BOOLEAN: 'Boolean',
      TIMESTAMP: 'Datum',
    },
    dataViewMode: {
      TABLE_FULLSCREEN: 'Tabulka na celou obrazovku',
      TABLE_DETAIL: 'Tabulka s detailem',
      TABLE: 'Tabulka s rolováním',
      TREE: 'Stromová tabulka s rolováním',
      TREE_FULLSCREEN: 'Stromová tabulka na celou obrazovku',
      KANBAN: 'Kanban',
    },
  },
  listingTypeService: {
    effects: {
      listingTypeDeleteSuccess: 'Mazání listing typu proběhlo úspěšně',
      listingTypeDeleteError: 'Během mazání listing typu došlo k chybě.',
      messageListingTypeChangeSuccessful: 'Listing type byl úspěšně uložen.',
      messageListingTypeSaveUnSuccessful:
        'Během ukládání listing typu došlo k chybě.',
      listingTypeDiffSuccess: 'Ukládání rozdílů listing typu skončilo úspěšně.',
      listingTypeDiffError:
        'Během ukládání rozdílů listing typu došlo k chybě.',
    },
  },
  listingProfileService: {
    effects: {
      listingProfileDeleteSuccess: 'Mazání listing profilu proběhlo úspěšně',
      listingProfileDeleteError: 'Během mazání listing profilu došlo k chybě.',
      listingProfileDiffSuccess:
        'Ukládání rozdílů listing profilu skončilo úspěšně.',
      listingProfileDiffError:
        'Při ukládání rozdílů listing profilu došlo k chybě.',
      messageListingProfileChangeSuccessful:
        'Listing profile byl úspěšně uložen.',
      messageListingProfileSaveUnSuccessful:
        'Během ukládání listing profilu došlo k chybě.',
    },
  },
  valueConverterConfigService: {
    effects: {
      valueConverterConfigDeleteSuccess:
        'Mazání konfigurace konvertoru hodnot proběhlo úspěšně',
      valueConverterConfigDeleteError:
        'Během mazání konfigurace konvertoru hodnot došlo k chybě.',
      valueConverterConfigDiffSuccess:
        'Ukládání rozdílů konfigurací konvertorů hodnot skončilo úspěšně.',
      valueConverterConfigDiffError:
        'Při ukládání rozdílů konfigurací konvertorů hodnot došlo k chybě.',
      messageValueConverterConfigChangeSuccessful:
        'Konfigurace konvertoru hodnot byla úspěšně uložena.',
      messageValueConverterConfigSaveUnSuccessful:
        'Během ukládání konfigurace konvertoru hodnot došlo k chybě.',
    },
  },
};
