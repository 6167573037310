import {Injectable} from '@angular/core';

import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Store} from '@ngrx/store';
import {LoadUiConfigAction} from '../actions';
import {Observable} from 'rxjs';
import {TsmUiConfigModel, TsmUiConfigStateModel} from '../models';

@Injectable({
  providedIn: 'root',
})
export class UiConfigService {
  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
    private store: Store<TsmUiConfigStateModel>,
  ) {}

  loadUiConfig() {
    this.store.dispatch(LoadUiConfigAction());
  }

  /**
   * Gets current ui config
   * env - dev|test|prod
   * custom object
   */
  getUiConfig(): Observable<Envelope<TsmUiConfigModel>> {
    return this.apiService.get<TsmUiConfigModel, TsmUiConfigModel>(
      this.config.value.apiUrls.base + 'api/ui-configuration',
    );
  }
}
