import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, mergeMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {
  DeleteRegister,
  DeleteRegisterError,
  DeleteRegisterSuccess,
  DiffRegister,
  DiffRegisterError,
  DiffRegisterSuccess,
  LoadRegister,
  LoadRegisterByCode,
  LoadRegisterByCodeError,
  LoadRegisterByCodeSuccess,
  LoadRegisterById,
  LoadRegisterByIdError,
  LoadRegisterByIdSuccess,
  LoadRegisterError,
  LoadRegisterSuccess,
  UpsertRegister,
  UpsertRegisterError,
  UpsertRegisterSuccess,
} from '../actions';
import {CommonApiService} from '../services';
import {Register} from '../models';
import {translation} from '../i18n';
import {exhaustBy} from '@tsm/framework/root';
import {
  DeleteMenuItemsByIdInUrl,
  UpdateMenuItemsByIdInUrl,
} from '@tsm/layout/service';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class RegisterEffects {
  translation = translation;
  translationShared = translationShared;

  private REGISTER_API_PATH = 'v1/registers';

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegister),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.REGISTER_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadRegisterSuccess({entities: env.data})
                : LoadRegisterError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegisterById),
      concatMap(({id}) => {
        return this.commonApiService
          .getEntity(this.REGISTER_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadRegisterByIdSuccess({entity: env.data})
                : LoadRegisterByIdError({error: env.error}),
            ),
          );
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegisterByCode),
      exhaustBy(
        (x) => x.code,
        ({code}) => {
          return this.commonApiService
            .getEntityByCode(this.REGISTER_API_PATH, code)
            .pipe(
              map((env) =>
                env.success
                  ? LoadRegisterByCodeSuccess({entity: env.data})
                  : LoadRegisterByCodeError({error: env.error}),
              ),
            );
        },
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRegister),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.REGISTER_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/user-register']);
              }
              return DeleteRegisterSuccess({id: id, listingId: listingId});
            } else {
              return DeleteRegisterError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRegisterSuccess),
      mergeMap(({id, listingId}) => [
        RefreshDataAndClearSelected({id: listingId}),
        DeleteMenuItemsByIdInUrl({id: id}),
      ]),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRegisterSuccess),
      tap(({redirectToDetail, register}) => {
        if (redirectToDetail === true) {
          this.router.navigate(['/config/user-register', register.id]);
        }
      }),
      mergeMap(({register, listingId}) => [
        RefreshDataAndClearSelected({id: listingId}),
        UpdateMenuItemsByIdInUrl({id: register.id}),
      ]),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRegister),
      concatMap(({register, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(this.REGISTER_API_PATH, register, register.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configFormService.effects.registerSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertRegisterSuccess({
                  register: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects.registerSaveFailure,
                );
                return UpsertRegisterError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffRegister),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('this.REGISTER_API_PATH', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffRegisterSuccess({
                  registers: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffRegisterError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffRegisterSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<Register, Register>,
    private router: Router,
    private toastService: ToastService,
  ) {}
}
