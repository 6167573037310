export function extractExpressionContent(expr: string) {
  const start = '${';
  const end = '}';

  if (expr?.startsWith(start) && expr?.endsWith(end)) {
    let resultExpr = expr.replace('${', '');
    resultExpr = resultExpr.substring(0, resultExpr.length - 1);
    return resultExpr;
  }
  return null;
}

export function isExpression(value: string) {
  return typeof value === 'string' && value.includes('${');
}

export function isValidExpression(value: string) {
  return (
    typeof value === 'string' && value.startsWith('${') && value.endsWith('}')
  );
}
