import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  private readonly EXCELS = `excels`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getExcel(
    template: string,
    format: string,
    params: any,
    ownerType: string,
    ownerId: string,
  ): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL +
        `/${this.EXCELS}/${template}/${format}/${ownerType}/${ownerId}`,
      params,
      null,
      {observe: 'response', responseType: 'blob' as 'json'},
    );
  }

  serviceForCez(file: string): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL + `/${this.EXCELS}/cez-service/${file}`,
      null,
      null,
      {observe: 'response', responseType: 'blob' as 'json'},
    );
  }
}
