import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/ordering/plugins-i18n';
import {type Config} from '@tsm/framework/config';

export const controlsOrderingNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-order-new',
    name: 'Order new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'Order',
    tsmControls: {
      key: {
        name: translationShared.shared.code,
        imageUrl: '',
      },
      customerId: {
        name: translationShared.shared.customer,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.customer,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-lov',
        },
      },
      accountId: {
        name: translationShared.shared.acc,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.acc,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-account-lov',
        },
      },
      entityInstanceConfiguration: {
        name: translationShared.shared.cancelService,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.cancelService,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-instance-configuration-lov',
          inputs: {
            deleteValueOnCustomerAccountIdChanged: true,
            status: 'ACTIVE',
            actives: ['CURRENT'],
            showEntityInstanceKey: true,
            displayField: ['name', 'entityInstance.key'],
          },
        },
      },
      deliveryDateRequested: {
        name: translationShared.shared.requiredDeliveryDate,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label: translationShared.shared.requiredDeliveryDate,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      subject: {
        name: translationShared.shared.subject,
        required: true,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.subject,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-order-detail',
  name: 'Order detail',
  description: '',
  useType: 'FORM_DETAIL',
  entityType: 'Order',
  tsmControls: {
    'Order.Header': {
      name: translationShared.shared.header,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.header,
          labelPosition: 'left',
          type: 'previewHeader',
        },
      },
    },
    'Order.Toolbar.Settings': {
      name: translationShared.shared.settingsButton,
      description: null,
      icon: 'pi pi-ellipsis-v',
    },
    'Order.Toolbar.Refresh': {
      name: translationShared.shared.refreshButton,
      description: null,
      icon: 'pi pi-sync',
    },
    'Order.Details.Subject': {
      name: translationShared.shared.subject,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.subject,
          labelPosition: 'left',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Order.Details.Key': {
      name: translationShared.shared.key,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'text',
          label: translationShared.shared.key,
          labelPosition: 'left',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Order.Details.Type': {
      name: translationShared.shared.type,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.type,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-order-type-lov',
      },
    },
    'Order.Details.Account': {
      name: translationShared.shared.account,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.account,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-account-lov',
      },
    },
    'Order.Details.Quote': {
      name: translationShared.shared.quote,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.quote,
          labelPosition: 'left',
          type: 'previewDropdown',
        },
      },
      defaultComponent: {
        selector: 'tsm-quote-lov',
        inputs: {
          returnObject: true,
        },
      },
    },
    'Order.Details.Labels': {
      name: translationShared.shared.labels,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.labels,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'tsm-data-tags',
        inputs: {
          parentPriv: 'Ord.Order',
          url: (config: Config) => config.apiUrls.ordering + '/orders/dataTags',
          urlRedirect: '/tsm-ordering/api/ordering/order',
        },
      },
    },
    'Order.Details.Description': {
      name: translationShared.shared.description,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.description,
          labelPosition: 'left',
          type: 'tiptap',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-tiptap',
      },
    },
    'Order.Dates.WhenInserted': {
      name: translationShared.shared.whenInserted,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whenInserted,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Order.Dates.WhenEdited': {
      name: translationShared.shared.whenEdited,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whenEdited,
          labelPosition: 'left',
          type: 'datetime',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Order.Dates.OrderDate': {
      name: translationShared.shared.orderDate,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.orderDate,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    'Order.Dates.DeliveryDateRequested': {
      name: translationShared.shared.deliveryDateRequested,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.deliveryDateRequested,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
        inputs: {
          showTime: true,
        },
      },
    },
    'Order.Dates.DeliveryDate': {
      name: translationShared.shared.deliveredDate,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.deliveredDate,
          labelPosition: 'left',
          type: 'date',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-date',
      },
    },
    'Order.People.Tasks': {
      name: translationShared.shared.tasks,
      description: null,
      imageUrl: 'assets/preview/prew_task-card.png',
    },
    'Order.People.WhoInserted': {
      name: translationShared.shared.whoInserted,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whoInserted,
          labelPosition: 'left',
          type: 'text',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
    'Order.People.WhoEdited': {
      name: translationShared.shared.whoEdited,
      description: null,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          label: translationShared.shared.whoEdited,
          labelPosition: 'left',
          type: 'text',
        },
      },
    },
    'Order.Tabs.Process': {
      name: translationShared.shared.process,
      description: null,
      imageUrl: 'assets/preview/prew_process.png',
    },
  },
};

export const controlsTypeDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-order-type-detail',
  name: 'Order type detail',
  description: '',
  useType: 'CONFIG',
  entityType: 'OrderType',
  tsmControls: {},
};

export const controlsOrderLineDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-order-line-detail',
    name: 'Order line detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'OrderLine',
    tsmControls: {
      'OrderLine.Header': {
        name: translationShared.shared.header,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.header,
            labelPosition: 'left',
            type: 'previewHeader',
          },
        },
      },
      'OrderLine.Toolbar.Settings': {
        name: translationShared.shared.settingsButton,
        description: null,
        icon: 'pi pi-ellipsis-v',
      },
      'OrderLine.Toolbar.Refresh': {
        name: translationShared.shared.refreshButton,
        description: null,
        icon: 'pi pi-sync',
      },
      'OrderLine.Details.RecurringChargePrice': {
        name: translation.orderingPlugins.orderLine.regularPriceValue,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.regularPriceValue,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.RecurringChargeListPrice': {
        name: translation.orderingPlugins.orderLine.regularPriceValueWithoutTax,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translation.orderingPlugins.orderLine.regularPriceValueWithoutTax,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.RecurringChargePriceAmount': {
        name: translation.orderingPlugins.orderLine.totalPriceForRegularPayment,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translation.orderingPlugins.orderLine.totalPriceForRegularPayment,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.UsageChargePrice': {
        name: translation.orderingPlugins.orderLine.prieForUnit,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.prieForUnit,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.UsageChargeListPrice': {
        name: translation.orderingPlugins.orderLine.priceForUnitWithoutTax,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.priceForUnitWithoutTax,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.UsageChargePriceAmount': {
        name: translation.orderingPlugins.orderLine.totalPriceForUsing,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.totalPriceForUsing,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.Operation': {
        name: translation.orderingPlugins.orderLine.operation,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.operation,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.Quantity': {
        name: translation.orderingPlugins.orderLine.count,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label: translation.orderingPlugins.orderLine.count,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.RecurringChargePeriod': {
        name: translation.orderingPlugins.orderLine.paymentPeriod,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.paymentPeriod,
            labelPosition: 'left',
          },
        },
      },
      'OrderLine.Details.UsageTo': {
        name: translation.orderingPlugins.orderLine.endOfProviding,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translation.orderingPlugins.orderLine.endOfProviding,
            labelPosition: 'left',
          },
        },
      },
      // 'OrderLine.Details.Labels': {
      //   name: translationShared.shared.labels,
      //   description: null,
      //   previewComponent: {
      //     selector: 'tsm-form-field',
      //     inputs: {
      //       label: translationShared.shared.labels,
      //       labelPosition: 'left',
      //       type: 'text',
      //     },
      //   },
      //   defaultComponent: {
      //     selector: 'tsm-data-tags',
      //     inputs: {
      //       parentPriv: 'Ord.Order',
      //       url: (config: Config) =>
      //         config.apiUrls.ordering + '/orders/dataTags',
      //       urlRedirect: '/tsm-ordering/api/ordering/order',
      //     },
      //   },
      // },
      'OrderLine.Details.Description': {
        name: translationShared.shared.description,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.description,
            labelPosition: 'left',
            type: 'tiptap',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
      'OrderLine.Dates.WhenInserted': {
        name: translationShared.shared.whenInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenInserted,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'OrderLine.Dates.WhenEdited': {
        name: translationShared.shared.whenEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whenEdited,
            labelPosition: 'left',
            type: 'datetime',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
          inputs: {
            showTime: true,
          },
        },
      },
      'OrderLine.People.WhoInserted': {
        name: translationShared.shared.whoInserted,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoInserted,
            labelPosition: 'left',
            type: 'text',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      'OrderLine.People.WhoEdited': {
        name: translationShared.shared.whoEdited,
        description: null,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            label: translationShared.shared.whoEdited,
            labelPosition: 'left',
            type: 'text',
          },
        },
      },
    },
  };
