import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteAllUserRoles,
  DeleteAllUserRolesError,
  DeleteAllUserRolesSuccess,
  DeleteUserRoleSuccess,
  LoadUserRolesByRoleId,
  LoadUserRolesByRoleIdError,
  LoadUserRolesByRoleIdSuccess,
  LoadUserRolesByUserId,
  LoadUserRolesByUserIdError,
  LoadUserRolesByUserIdSuccess,
  SaveAllUserRoles,
  SaveAllUserRolesError,
  SaveAllUserRolesSuccess,
  UpsertUserRoleSuccess,
} from '../actions';
import {UserRole} from '../model';

export type UserRoleState = EntityState<IdentifiedPack<UserRole>>;

export const adapter: EntityAdapter<IdentifiedPack<UserRole>> =
  createEntityAdapter<IdentifiedPack<UserRole>>({});

export const initialState: UserRoleState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(
    LoadUserRolesByUserIdSuccess,
    LoadUserRolesByRoleIdSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((userRole) => ({
          id: userRole.id,
          data: userRole,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadUserRolesByUserId,
    LoadUserRolesByRoleId,
    DeleteAllUserRoles,
    SaveAllUserRoles,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadUserRolesByUserIdError,
    LoadUserRolesByRoleIdError,
    DeleteAllUserRolesError,
    SaveAllUserRolesError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertUserRoleSuccess, (state, {userRole}) =>
    adapter.upsertOne(
      {
        id: userRole.id,
        data: userRole,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteUserRoleSuccess, (state, userRole) =>
    adapter.removeOne(userRole.id, state),
  ),

  on(SaveAllUserRolesSuccess, (state, {entities}) => ({
    ...adapter.upsertMany(
      entities.map((entity) => ({
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(DeleteAllUserRolesSuccess, (state, {ids}) => ({
    ...adapter.removeMany(ids, state),
    error: null,
    loading: false,
  })),
);
