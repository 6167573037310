import {Pipe, PipeTransform} from '@angular/core';
import {JsonPipe} from '@angular/common';

@Pipe({
  name: 'tsmColorExpressionPipe',
  pure: true,
})
export class TsmColorExpressionPipe implements PipeTransform {
  constructor(private jsonPipe: JsonPipe) {}

  transform(value: any, form: string): any {
    return value === null ? '' : this.jsonPipe.transform(value);
  }
}
