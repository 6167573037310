import {SelectItem} from 'primeng/api';

export enum SchemaLocation {
  details = 'details', // Sekce detailu entity, na kterej se charakteristika
  cards = 'cards', // Sekce cards, ktera slouzi pro zobrazeni karticek (napriklad produktu), je umistena hned pod detailem
  taskDetail = 'taskDetail', // Sekce detailu, ktera se zobrazuje pouze pri pouziti komponenty na detailu tasku
  completedPage = 'completedPage', // Sekce charakteristik, ktere slouzi pro ukonceni tasku
  info = 'info', // Prida novou sekci do praveho palenu od detailu
  people = 'people', // Sekce v pravem panelu od detailu
  dates = 'dates', // Sekce v pravem panelu od detailu
  section = 'section', // Sekce, ktera se zobrazi pod detailem entity
  sectionDefaultOpened = 'sectionDefaultOpened', // Sekce, ktera se zobrazi pod detailem entity
  tabs = 'tabs', // Rozsireni tabu entity
  comments = 'comments', // Sablony formularu pro komentare
  attachments = 'attachments', // Sablony formularu pro prilohy
  callScript = 'callScript', // Specialni umisteni, pouze pro sjednavani terminu (tzv. call script)
  topbar = 'topbar',
  topbarMenu = 'topbarMenu', // souhrn formularu pro rozbalovaci tlacitko
  taskPlanner = 'taskPlanner', // Specialni umisteni, pouze pro nahled tasku v planovaci (popup-offer/popup-task/wfm-task)
  taskProtocol = 'taskProtocol', // Specialni umisteni, pouze pro protokoly na nahledu tasku (wfm-task-detail)
  resolution = 'resolution', // Specialni umisteni, pouze pro detail PEW a ticketu sekce Řešení
  problem = 'problem', // Specialni umisteni, pouze pro detail ticketu sekce Problem
  floatingSection = 'floatingSection',
}

export function getAllSchemaLocations(): SelectItem[] {
  const locations: SelectItem[] = [];
  Object.keys(SchemaLocation).forEach((key) =>
    locations.push({label: SchemaLocation[key], value: key}),
  );
  return locations;
}
