import {Component, Input} from '@angular/core';
import {ValidationBadgeStatus} from '../../models/enums';

@Component({
  template: ` <ng-container>
    <i
      class="pi pi-check-circle p-color-green text-sm"
      *ngIf="status === statusValues.Ok"
    ></i>
    <i
      class="pi pi-exclamation-triangle p-color-orange text-sm"
      *ngIf="status === statusValues.Warning"
    ></i>
    <i
      class="pi pi-exclamation-triangle p-color-red text-sm"
      *ngIf="status === statusValues.StrongWarning"
    ></i>
    <i
      class="pi pi-ban p-color-red text-sm"
      *ngIf="status === statusValues.Stop"
    ></i>
    <i
      class="p-color-grey text-sm"
      *ngIf="status === statusValues.NotAvailable"
    ></i>
  </ng-container>`,
  selector: 'tsm-validation-icon',
  styleUrls: ['./validation-icon.component.scss'],
})
export class ValidationIconComponent {
  public statusValues = ValidationBadgeStatus;

  @Input() status: ValidationBadgeStatus = ValidationBadgeStatus.Ok;
}
