export const cs = {
  entityTypePlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmEntityTypeFilter: 'tSM Entity Type Filter',
      tsmEntityTypeFilterDescription:
        'Filtruje typy entit pomocí tabulky s multi-výběrem. ' +
        'Načítá typy entit z API a umožňuje výběr podle ID nebo kódu. ' +
        'Podporuje vyhledávání, řazení podle kódu a odebrání výběrů. ' +
        'Používá překryvný panel s potvrzením a zrušením výběru. ' +
        'Aplikuje vybrané hodnoty jako filtrační kritéria s operátorem "in".',
    },
    pipes: {
      entityTypeCode: 'Entity Type Code',
      entityTypeCodeDescription:
        'Poskytuje funkce pro načtení a správu dat typu entity pomocí zadaného kódu. Pokud data nejsou k dispozici, ' +
        'spustí akci pro jejich načtení. Po získání dat extrahuje požadované pole nebo vrátí kompletní data na ' +
        'základě zadaných parametrů.',
      entityType: 'Entity Type',
      entityTypeDescription:
        'Načítá a spravuje data typu entity podle ID. Automaticky spustí akci pro načtení dat, pokud nejsou již ' +
        'k dispozici. Extrahuje zadané pole nebo vrátí kompletní data podle poskytnutého parametru.',
    },
  },
};
