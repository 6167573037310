<p-dialog
  #tsmDialog
  id="tsm-dialog"
  [header]="header"
  [modal]="modal"
  [visible]="visible"
  [contentStyle]="contentStyle"
  [resizable]="resizable"
  [position]="position"
  [closeIcon]="closeIcon"
  [minimizeIcon]="minimizeIcon"
  [maximizeIcon]="maximizeIcon"
  [maximizable]="maximizable"
  [maskStyleClass]="maskStyleClass"
  [style]="isMobile ? {width: '96vw'} : style"
  [baseZIndex]="baseZIndex"
  (visibleChange)="onClose()"
  (onMaximize)="onMaximize.emit($event)"
  [minY]="minY"
  [blockScroll]="true"
  [focusOnShow]="focusOnShow"
  [styleClass]="styleClass"
  [showHeader]="showHeader"
  [closeOnEscape]="closeOnEscape"
  appendTo="body"
>
  <ng-template *ngIf="!header" pTemplate="header">
    <ng-content select="p-header"></ng-content>
  </ng-template>

  <ng-content></ng-content>

  <ng-template pTemplate="footer">
    <ng-content select="p-footer"></ng-content>
  </ng-template>
</p-dialog>
