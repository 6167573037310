import {StoreModule} from '@ngrx/store';
import * as reducers from './reducers';
import {EffectsModule} from '@ngrx/effects';
import * as Effects from './effects';
import {NgModule} from '@angular/core';

export const IMPORTS = [
  StoreModule.forFeature('runtimeInfo', reducers.reducers),
  EffectsModule.forFeature([Effects.RuntimeInfoEffects]),
];

@NgModule({
  imports: [...IMPORTS],
})
export class RuntimeInfoModule {}
