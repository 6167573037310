import {Injectable} from '@angular/core';
import {
  LumberjackLevel,
  LumberjackLogLevel,
  LumberjackLogPayload,
  LumberjackService,
  LumberjackTimeService,
} from '@ngworker/lumberjack';
import {ToastService} from '@tsm/framework/toast';
import {LoggerBuilder} from '../models';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class AppLoggerService<
  TPayload extends LumberjackLogPayload | void = void,
> {
  constructor(
    protected lumberjack: LumberjackService<TPayload>,
    protected time: LumberjackTimeService,
    protected toastService: ToastService,
  ) {}

  createLog(level: LumberjackLogLevel, message: string) {
    return new LoggerBuilder<TPayload>(
      this.lumberjack,
      this.time,
      level,
      message,
      this.toastService,
    );
  }

  logSentry(err: any, extra: any) {
    Sentry.captureException(err, extra);
  }

  createErrorLog(message: string) {
    Sentry.captureException(message);
    return new LoggerBuilder<TPayload>(
      this.lumberjack,
      this.time,
      LumberjackLevel.Error,
      message,
      this.toastService,
    );
  }

  createInfoLog(message: string) {
    return new LoggerBuilder<TPayload>(
      this.lumberjack,
      this.time,
      LumberjackLevel.Info,
      message,
      this.toastService,
    );
  }

  createDebugLog(message: string) {
    return new LoggerBuilder<TPayload>(
      this.lumberjack,
      this.time,
      LumberjackLevel.Debug,
      message,
      this.toastService,
    );
  }

  createTraceLog(message: string) {
    return new LoggerBuilder<TPayload>(
      this.lumberjack,
      this.time,
      LumberjackLevel.Trace,
      message,
      this.toastService,
    );
  }

  createWarnLog(message: string) {
    return new LoggerBuilder<TPayload>(
      this.lumberjack,
      this.time,
      LumberjackLevel.Warning,
      message,
      this.toastService,
    );
  }
}
