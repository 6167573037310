export const en = {
  entityTypePlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmEntityTypeFilter: 'tSM Entity Type Filter',
      tsmEntityTypeFilterDescription:
        'Filters entity types with a multi-select table. ' +
        'Fetches entity types from an API and allows selection by ID or code. ' +
        'Supports search filtering, sorting by code, and removal of selections. ' +
        'Uses an overlay panel with confirmation and cancellation options. ' +
        'Applies selected values as filter criteria using an "in" operator.',
    },
    pipes: {
      entityTypeCode: 'Entity Type Code',
      entityTypeCodeDescription:
        'Provides functionality to retrieve and manage entity type data using the specified code. If data is ' +
        'unavailable, it triggers an action to load it. Once available, extracts the required field or ' +
        'returns full data based on the provided parameters.',
      entityType: 'Entity Type',
      entityTypeDescription:
        'Retrieves and manages entity type data by ID. Automatically triggers an action to load the data if it ' +
        'is not already available. Extracts the specified field or returns full data based on the given parameter.',
    },
  },
};
