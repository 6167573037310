<div
  #container
  [ngClass]="{'p-menu p-component': true, 'p-menu-overlay': popup}"
  [class]="styleClass"
  [ngStyle]="style"
  (click)="preventDocumentDefault = true"
  *ngIf="!popup || visible"
  [@overlayAnimation]="{
    value: 'visible',
    params: {
      showTransitionParams: showTransitionOptions,
      hideTransitionParams: hideTransitionOptions,
    },
  }"
  [@.disabled]="popup !== true"
  (@overlayAnimation.start)="onOverlayAnimationStart($event)"
>
  @if (!!header) {
    <div class="pl-3 pr-3">{{ header }}</div>
  }
  <ul class="p-menu-list p-reset" role="menu">
    <ng-template ngFor let-submenu [ngForOf]="model" *ngIf="hasSubMenu()">
      <li
        class="p-menu-separator"
        *ngIf="submenu.separator"
        [ngClass]="{'p-hidden': submenu.visible === false}"
        role="separator"
      ></li>
      <li
        class="p-submenu-header"
        [attr.data-automationid]="submenu.automationId"
        *ngIf="!submenu.separator"
        [class.p-hidden]="!submenu.visible"
        pTooltip
        [tooltipOptions]="submenu.tooltipOptions"
        role="none"
      >
        <span>{{ submenu.label | transloco }}</span>
      </li>
      <ng-template ngFor let-item [ngForOf]="submenu.items">
        <li
          class="p-menu-separator"
          *ngIf="item.separator"
          [ngClass]="{
            'p-hidden': item.visible === false || submenu.visible === false,
          }"
          role="separator"
        ></li>
        <li
          class="p-menuitem"
          *ngIf="!item.separator"
          [pMenuItemContent]="item"
          [ngClass]="{
            'p-hidden': item.visible === false || submenu.visible === false,
          }"
          [ngStyle]="item.style"
          [class]="item.styleClass"
          pTooltip
          [tooltipOptions]="item.tooltipOptions"
          role="none"
        ></li>
      </ng-template>
    </ng-template>
    <ng-template ngFor let-item [ngForOf]="model" *ngIf="!hasSubMenu()">
      <li
        class="p-menu-separator"
        *ngIf="item.separator"
        [ngClass]="{'p-hidden': item.visible === false}"
        role="separator"
      ></li>
      <li
        class="p-menuitem"
        *ngIf="!item.separator"
        [pMenuItemContent]="item"
        [ngClass]="{'p-hidden': item.visible === false}"
        [ngStyle]="item.style"
        [class]="item.styleClass"
        pTooltip
        [tooltipOptions]="item.tooltipOptions"
        role="none"
      ></li>
    </ng-template>
  </ul>
</div>
<p-confirmPopup key="{{ confirmPopupKey }}"></p-confirmPopup>
