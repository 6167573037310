import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as Services from './services';
import {SharedModule} from '@tsm/shared';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';
import {TranslocoModule} from '@jsverse/transloco';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToastModule} from 'primeng/toast';
import {layoutReducer, metaReducers} from './reducers';
import * as effects from './effects';
import {UiConfigModule} from '@tsm/ui-config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    TranslocoModule,
    ToastModule,
    InputSwitchModule,
    UiConfigModule,
    StoreModule.forFeature('layout', layoutReducer, {
      metaReducers: metaReducers,
    }),
    EffectsModule.forFeature([effects.LayoutTsmEffects]),
    TranslateModule.forFeature(localizations),
  ],
  providers: [Services.LayoutTsmService],
  exports: [],
})
export class LayoutServiceModule {}
