import {Component, Input} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'dtl-form-group',
    templateUrl: './form-group.component.html',
    styleUrls: ['./form-group.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class FormGroupComponent {
  @Input() class = '';
}
