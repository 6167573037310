import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {Microservice} from '@tsm/characteristics/service';
import {
  DeleteMicroserviceSuccess,
  DiffMicroservice,
  DiffMicroserviceError,
  DiffMicroserviceSuccess,
  LoadMicroserviceByCode,
  LoadMicroserviceByCodeError,
  LoadMicroserviceByCodeSuccess,
  LoadMicroserviceById,
  LoadMicroserviceByIdError,
  LoadMicroserviceByIdSuccess,
  LoadMicroserviceSuccess,
  UpsertMicroservice,
  UpsertMicroserviceSuccess,
} from '../actions';

export type MicroserviceState = EntityState<IdentifiedPack<Microservice>>;

export const microserviceAdapter: EntityAdapter<IdentifiedPack<Microservice>> =
  createEntityAdapter<IdentifiedPack<Microservice>>({});

const initialState: MicroserviceState = microserviceAdapter.getInitialState({});

export const microserviceReducer = createReducer(
  initialState,

  on(LoadMicroserviceSuccess, (state, {microservices}) => ({
    ...microserviceAdapter.addMany(
      microservices.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadMicroserviceById, (state, {id}) =>
    microserviceAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadMicroserviceByIdSuccess, (state, {microservice}) =>
    microserviceAdapter.upsertOne(
      {
        id: microservice.id,
        data: microservice,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadMicroserviceByIdError, (state, {id, error}) =>
    microserviceAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadMicroserviceByCode, (state, {code}) =>
    microserviceAdapter.upsertOne(
      {
        id: code,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadMicroserviceByCodeSuccess, (state, {microservice}) => {
    const newState = microserviceAdapter.removeOne(microservice.code, state);
    return microserviceAdapter.upsertOne(
      {
        id: microservice.id,
        data: microservice,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadMicroserviceByCodeError, (state, {code, error}) =>
    microserviceAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertMicroservice, (state, {microservice}) =>
    microserviceAdapter.updateOne(
      {
        id: microservice.id,
        changes: {
          ...state.entities[microservice.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertMicroserviceSuccess, (state, {microservice}) =>
    microserviceAdapter.upsertOne(
      {
        id: microservice.id,
        data: microservice,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteMicroserviceSuccess, (state, microservice) =>
    microserviceAdapter.removeOne(microservice.id, state),
  ),

  on(DiffMicroservice, (state, {diffEntities}) =>
    microserviceAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffMicroserviceSuccess, (state, {microservices}) =>
    microserviceAdapter.upsertMany(
      microservices.map((microservice) => {
        return {
          id: microservice.id,
          data: microservice,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffMicroserviceError, (state, {diffEntities, error}) =>
    microserviceAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
