import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/skill-assignment.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getSkillAssignmentState = createSelector(
  getUserManagementState,
  (skillAssignmentState) => skillAssignmentState.skillAssignment,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getSkillAssignmentState);

export const selectSkillAssignmentByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectSkillAssignmentsByUserId = (userId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.user.id === userId),
  );

export const selectSkillAssignmentsByUserIds = (userIds: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => userIds.includes(r.data.user.id)),
  );

export const selectSkillAssignmentsBySkillIds = (skillIds: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) =>
      skillIds.includes(r.data.entityCatalogSpecificationId),
    ),
  );

export const selectSkillAssignmentById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllSkillAssignments = selectAll;
