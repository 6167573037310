import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as Reducers from './reducers';
import * as Effects from './effects';
import * as Pipes from './pipes';
import * as Directives from './directives';
import * as Services from './services';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';
import {FrameworkPluginModule} from '@tsm/framework/plugin';
import {RegisterValueDataSource} from './datasources/register-value.data-source';
import {RegisterDataSource} from './datasources/register.data-source';
import {RegisterValidValueDataSource} from './datasources/register-valid-value.data-source';
import {CharacteristicsServiceModule} from '@tsm/characteristics/service';
import {TsmModuleDataSource} from './datasources/tsm-module.data-source';
import {ScriptingServiceModule} from '@tsm/scripting/service';
import {AccessRulesServiceModule} from '@tsm/access-rules/service';

export const PIPES = [
  Pipes.ArrayToStringPipe,
  Pipes.TsmCharsFormTypePipe,
  Pipes.EntitySpecificationPipe,
  Pipes.EntitySpecificationCodePipe,
  Pipes.TsmFormsPipe,
  Pipes.TsmFormsCodePipe,
  Pipes.MicroservicePipe,
  Pipes.MicroserviceCodePipe,
  Pipes.TsmModulesCodePipe,
  Pipes.TsmModulesIdPipe,
  Pipes.TsmColorExpressionPipe,
  Pipes.RegisterValuePipe,
  Pipes.RegisterValueNamePipe,
  Pipes.RegisterValueIconPipe,
  Pipes.MnemonicConfigPipe,
  Pipes.MnemonicConfigCodePipe,
  Pipes.RegisterValueByIdPipe,
  Pipes.RegisterValueArrayByIdPipe,
  Pipes.RegisterByIdPipe,
  Pipes.RegisterByCodePipe,
  Pipes.EntitySpecificationTypePipe,
  Pipes.ConfigTypePipe,
  Pipes.ConfigTypeCodePipe,
  Pipes.FormatTextPipe,
  Pipes.PipeInPipePipe,
  Pipes.FieldsDescriptionPipe,
];

export const DIRECTIVES = [Directives.ViewDeferDirective];

export const IMPORTS = [
  StoreModule.forFeature('configForm', Reducers.reducers),
  EffectsModule.forFeature([
    Effects.EntitySpecificationsEffects,
    Effects.FormEffects,
    Effects.RegisterEffects,
    Effects.RegisterValuesEffects,
    Effects.ConfigTypeEffects,
    Effects.MicroserviceEffects,
    Effects.TsmModuleEffects,
    Effects.MnemonicConfigEffects,
    Effects.MnemonicSequenceEffects,
    Effects.BackupSetEffects,
    Effects.BackupSetImportEffects,
  ]),
  TranslateModule.forFeature(localizations),
  FrameworkPluginModule.registerPlugin({
    menus: [],
    routes: [],
    widgets: [],
    dataSources: [
      {
        dataSource: RegisterDataSource,
        name: 'register',
        initialValue: null,
      },
      {
        dataSource: RegisterValueDataSource,
        name: 'registerValue',
        initialValue: null,
      },
      {
        dataSource: RegisterValidValueDataSource,
        name: 'registerValidValue',
        initialValue: null,
      },
      {
        dataSource: TsmModuleDataSource,
        name: 'tsmModule',
        initialValue: null,
      },
    ],
  }),
  CharacteristicsServiceModule,
  AccessRulesServiceModule,

  // TODO kvuli datasourcum scripting - mozna by datasourcy mely byt singletony pritomne vsude?
  ScriptingServiceModule,
];
export const PROVIDERS = [Services.CommonApiService, Services.FieldsDescriptionService];
