import {Plugin} from '@tsm/framework/plugin';

export const accessRulesPlugin: Plugin = {
  menus: [],
  routes: [],
  pipes: [],
  filters: [],
  widgets: [],
  entityTypes: [],
  dataSources: [],
};
