import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  DeleteAccessRuleSuccess,
  DiffAccessRule,
  DiffAccessRuleError,
  DiffAccessRuleSuccess,
  LoadAccessRuleById,
  LoadAccessRuleByIdError,
  LoadAccessRuleByIdSuccess,
  LoadAccessRules,
  LoadAccessRulesError,
  LoadAccessRulesSuccess,
  UpsertAccessRuleSuccess,
} from '../actions';
import {AccessRule} from '../model';

export type AccessRuleState = EntityState<IdentifiedPack<AccessRule>>;

export const adapter: EntityAdapter<IdentifiedPack<AccessRule>> =
  createEntityAdapter<IdentifiedPack<AccessRule>>({});

export const initialState: AccessRuleState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadAccessRulesSuccess, (state, {entities}) => ({
    ...adapter.addMany(
      entities.map((accessRuleOnly) => ({
        id: accessRuleOnly.id,
        data: accessRuleOnly,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadAccessRuleById, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadAccessRuleByIdSuccess, (state, {accessRule}) =>
    adapter.upsertOne(
      {
        id: accessRule.id,
        data: accessRule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadAccessRuleByIdError, (state, {id, error}) =>
    adapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadAccessRules, (state, {}) => ({...state, loading: true})),

  on(LoadAccessRulesError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(UpsertAccessRuleSuccess, (state, {accessRule}) =>
    adapter.upsertOne(
      {
        id: accessRule.id,
        data: accessRule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteAccessRuleSuccess, (state, accessRule) =>
    adapter.removeOne(accessRule.id, state),
  ),

  on(DiffAccessRule, (state, {diffEntities}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffAccessRuleSuccess, (state, {accessRules}) =>
    adapter.upsertMany(
      accessRules.map((accessRule) => {
        return {
          id: accessRule.id,
          data: accessRule,
          loading: false,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffAccessRuleError, (state, {diffEntities, error}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
