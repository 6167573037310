import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ListingContainerState} from '../reducers';
import {listingProfileAdapter} from '../reducers/listing-profile.reducer';
import {RouterStateUrl} from '@tsm/framework/root';

const getListingContainerState =
  createFeatureSelector<ListingContainerState>('listing');

export const getListingProfileState = createSelector(
  getListingContainerState,
  (state) => state.listingProfile,
);
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const {selectIds, selectEntities, selectAll, selectTotal} =
  listingProfileAdapter.getSelectors(getListingProfileState);

export const selectListingProfilesByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x.data.code)).map((x) => x.data),
  );

export const selectListingProfileByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectListingProfileById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectListingProfileByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x && x?.data?.code === code),
  );

export const selectListingProfileByCodeAndListingCode = (
  code: string,
  listingCode: string,
) =>
  createSelector(selectAll, (entities) =>
    entities.find(
      (x) => x.data?.code === code && x.data?.codeListing === listingCode,
    ),
  );

export const selectAllListingProfile = createSelector(selectAll, (entities) =>
  entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);
