import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DtlTablePaginatorComponent} from './components';
import {FormModule} from '@tsm/framework/form-inputs';
import {OverlayPanelModule} from '@tsm/framework/override-primeng/overlay-panel';
import {localizations} from './i18n';
import {TranslateModule} from '@tsm/framework/translate';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
    OverlayPanelModule,
    FormModule,
    TranslateModule.forFeature(localizations),
    RippleModule,
    ReactiveFormsModule,
  ],
  declarations: [DtlTablePaginatorComponent],
  exports: [DtlTablePaginatorComponent],
})
export class FrameworkTablePaginatorModule {}
