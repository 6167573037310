import {Pipe, PipeTransform} from '@angular/core';
import {translation} from '../i18n';
import {TranslocoService} from '@tsm/framework/translate';
import {translation as translationShared} from '@tsm/shared-i18n';
import {RoleInGroupEnum} from '../model';

@Pipe({
  name: 'userUserGroupRole',
  pure: true,
})
export class UserUserGroupRolePipe implements PipeTransform {
  translation = translation;
  translationShared = translationShared;

  constructor(private translocoService: TranslocoService) {}

  transform(roles: RoleInGroupEnum[]) {
    const result = [];
    if (roles && roles.length > 0) {
      Object.keys(roles).forEach((key) => {
        result.push(
          this.translocoService.translate(
            'shared.roleInGroupEnum.' + roles[key],
          ),
        );
      });
    }
    return result;
  }
}
