import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {TsmModule} from '../models';

export const LoadTsmModule = createAction('[TsmModule] Load TsmModule');
export const LoadTsmModuleSuccess = createAction(
  '[TsmModule] Load TsmModule Success',
  props<{
    tsmModules: TsmModule[];
  }>(),
);
export const LoadTsmModuleError = createAction(
  '[TsmModule] Load TsmModule Error',
  props<TsmError>(),
);

export const LoadTsmModuleById = createAction(
  '[TsmModule] Load LoadTsmModuleById',
  props<{id: string}>(),
);
export const LoadTsmModuleByIdSuccess = createAction(
  '[TsmModule] Load LoadTsmModuleById Success',
  props<{
    tsmModule: TsmModule;
  }>(),
);
export const LoadTsmModuleByIdError = createAction(
  '[TsmModule] Load LoadTsmModuleById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadTsmModuleByCode = createAction(
  '[TsmModule] Load TsmModuleByCode',
  props<{code: string}>(),
);
export const LoadTsmModuleByCodeSuccess = createAction(
  '[TsmModule] Load TsmModuleByCode Success',
  props<{
    tsmModule: TsmModule;
  }>(),
);
export const LoadTsmModuleByCodeError = createAction(
  '[TsmModule] Load TsmModuleByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertTsmModule = createAction(
  '[TsmModule] Upsert TsmModule',
  props<{
    tsmModule: TsmModule;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertTsmModuleSuccess = createAction(
  '[TsmModule] Upsert TsmModule Success',
  props<{
    tsmModule: TsmModule;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertTsmModuleError = createAction(
  '[TsmModule] Upsert TsmModule Error',
  props<TsmError>(),
);

export const DeleteTsmModule = createAction(
  '[TsmModule] Delete TsmModule',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteTsmModuleSuccess = createAction(
  '[TsmModule] Delete TsmModule Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteTsmModuleError = createAction(
  '[TsmModule] Delete TsmModule Error',
  props<TsmError>(),
);

export const LoadTsmModulesByType = createAction(
  '[TsmModule] Load TsmModulesByType',
  props<{moduleType: string}>(),
);
export const LoadTsmModulesByTypeSuccess = createAction(
  '[TsmModule] Load TsmModulesByType Success',
  props<{
    tsmModules: TsmModule[];
  }>(),
);
export const LoadTsmModulesByTypeError = createAction(
  '[TsmModule] Load TsmModulesByType Error',
  props<{
    moduleType: string;
    error: any;
  }>(),
);

export const DiffTsmModule = createAction(
  '[TsmModule] Diff TsmModule',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffTsmModuleSuccess = createAction(
  '[TsmModule] Diff TsmModule Success',
  props<{
    tsmModules: TsmModule[];
    listingId: string;
  }>(),
);
export const DiffTsmModuleError = createAction(
  '[TsmModule] Diff TsmModule Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
