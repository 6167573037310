import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iconImg',
  pure: true,
})
export class IconImgPipe implements PipeTransform {
  public requiresRowData = true;
  private baseUrl = '';

  constructor() {
    this.baseUrl = window.location.origin + '/';
  }

  transform(value: string, field: string, rowData: any): string {
    let icon = this.findIconField(rowData);

    if (icon) {
      if (icon.startsWith('assets')) {
        icon = this.baseUrl + icon;
      }

      if (icon.startsWith('/') || icon.startsWith('http')) {
        return `<img class="h-2rem" src="${icon}" alt="${icon}"/>`;
      } else {
        return `<i class="${icon}"></i>`;
      }
    }
    return value;
  }

  private findIconField(object: any): string {
    const keys = Object.keys(object);
    let result = null;
    let index = 0;
    let currentKey = keys[index];

    while (!result && currentKey) {
      if (currentKey === 'icon') {
        result = object[currentKey];
      }

      if (object[currentKey] instanceof Object) {
        result = this.findIconField(object[currentKey]);
      }

      currentKey = keys[++index];
    }
    return result;
  }
}
