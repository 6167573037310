import * as fromSpecificationsReducer from './entity-specifications.reducer';
import * as fromFormReducer from './form.reducer';
import * as fromRegisterReducer from './register.reducer';
import * as fromRegisterValuesReducer from './register-value.reducer';
import * as fromConfigTypeReducer from './config-type.reducer';
import * as fromMicroserviceReducer from './microservice.reducer';
import * as fromTsmModuleReducer from './tsm-module.reducer';
import * as fromMnemonicConfigReducer from './mnemonic-config.reducer';
import * as fromMnemonicSequenceReducer from './mnemonic-sequence.reducer';
import * as fromBackupSetReducer from './backup-set.reducer';
import * as fromBackupSetImportReducer from './backup-set-import.reducer';
import {Action, combineReducers} from '@ngrx/store';

export * from './entity-specifications.reducer';
export * from './form.reducer';
export * from './register.reducer';
export * from './register-value.reducer';
export * from './config-type.reducer';
export * from './microservice.reducer';
export * from './mnemonic-config.reducer';
export * from './mnemonic-sequence.reducer';
export * from './backup-set.reducer';
export * from './backup-set-import.reducer';

export interface ConfigFormState {
  entitySpecifications: fromSpecificationsReducer.EntitySpecificationsState;
  forms: fromFormReducer.FormState;
  registers: fromRegisterReducer.RegisterState;
  registerValues: fromRegisterValuesReducer.RegisterValuesState;
  configTypes: fromConfigTypeReducer.ConfigTypeState;
  microservice: fromMicroserviceReducer.MicroserviceState;
  tsmModule: fromTsmModuleReducer.TsmModuleState;
  mnemonicConfig: fromMnemonicConfigReducer.MnemonicConfigState;
  mnemonicSequence: fromMnemonicSequenceReducer.MnemonicSequenceState;
  backupSet: fromBackupSetReducer.BackupSetState;
  backupSetImport: fromBackupSetImportReducer.BackupSetImportState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: ConfigFormState | undefined, action: Action) {
  return combineReducers({
    entitySpecifications: fromSpecificationsReducer.entitySpecificationsReducer,
    forms: fromFormReducer.formReducer,
    registers: fromRegisterReducer.registerReducer,
    registerValues: fromRegisterValuesReducer.registerValuesReducer,
    configTypes: fromConfigTypeReducer.configTypeReducer,
    microservice: fromMicroserviceReducer.microserviceReducer,
    tsmModule: fromTsmModuleReducer.tsmModuleReducer,
    mnemonicConfig: fromMnemonicConfigReducer.mnemonicConfigReducer,
    mnemonicSequence: fromMnemonicSequenceReducer.mnemonicSequenceReducer,
    backupSet: fromBackupSetReducer.backupSetReducer,
    backupSetImport: fromBackupSetImportReducer.backupSetImportReducer,
  })(state, action);
}
