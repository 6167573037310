import {Plugin} from '@tsm/framework/plugin';

export const wfmPlugin: Plugin = {
  menus: [
    // presunuto do Orderingu
    // {
    //   key: 'wfm',
    //   priority: 2100,
    //   label: 'Moje úkoly',
    //   icon: 'tsm-icon-user-management',
    //   privilege: [
    //     'true'
    //   ]
    // }
  ],
  routes: [
    {
      path: 'wfm',
      loadChildren: () =>
        import('@tsm/wfm/components').then((x) => x.WfmComponentsModule),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/wfm/widgets').then((x) => x.WfmWidgetsModule),
      definitions: [],
    },
  ],
};
