import {Component, EventEmitter, Input, Output} from '@angular/core';
import {translation} from '@tsm/shared-i18n';
import {PickerBundle, PickerBundleList} from '../resources';

@Component({
  selector: 'tsm-icon-picker',
  templateUrl: 'tsm-icon-picker.component.html',
  styleUrls: ['./tsm-icon-picker.component.scss'],
})
export class TsmIconPickerComponent {
  @Input() label: string;

  @Input() class: string;

  @Input() isMenuReadonly: boolean;

  @Input() allowedCategory: 'icon' | 'image' = null;

  @Input() showSuccessDialog: boolean;

  @Output() onIconClick = new EventEmitter<string>();

  translation = translation;

  icons: string[];
  images: string[];

  prefix: string = null;
  imagePathPrefix: string = null;

  pickerBundles: PickerBundle[] = PickerBundleList.getList();

  selectedIconBundle = 'all';
  selectedIcon: string;

  filteredIcons: string[];
  filteredImages: string[];

  displayDialog = false;

  isIconSelection = true;

  closeDialogAfterSelection = true;

  showDialog() {
    this.displayDialog = true;
  }

  hideDialog(topbar?: boolean) {
    if (this.closeDialogAfterSelection || topbar) {
      this.displayDialog = false;
    }
  }
}

