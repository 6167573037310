import {Pipe, untracked} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectRegisterValueByRegisterCodeAndName} from '../selectors';
import {LoadBatchRegisterValueByRegisterCodeAndName} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'registerValueName',
  pure: true,
})
export class RegisterValueNamePipe extends AbstractCachedLoaderPipe {
  private _cache = new Map<string, any>();

  constructor(protected store: Store) {
    super(store);
  }

  // rowData - nechutny hack, pres ktery si predavam 3. parametr, nejedna se o cely radek, ale o kod registru
  setObservable(id: string, field = 'name', rowData: any) {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectRegisterValueByRegisterCodeAndName(rowData, id)),
      filter((item) => {
        return item && !!item.data;
      }),
      map((it) => {
        if (!it || !it.data) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded(rowData?: any) {
    this._checkSubscription = this.store
      .select(selectRegisterValueByRegisterCodeAndName(rowData, this._id))
      .subscribe((x) => {
        if (!x && !this._cache.has(this._id)) {
          untracked(() =>
            this.store.dispatch(
              LoadBatchRegisterValueByRegisterCodeAndName({
                registerCode: rowData,
                name: this._id,
                // registerFilters: [
                //   {
                //     field: 'name',
                //     value: this._id,
                //     operator: FilterOperator.eq
                //   },
                //   {
                //     field: 'register.code',
                //     value: rowData,
                //     operator: FilterOperator.eq
                //   },
                // ]
              }),
            ),
          );
          // resi problem cyklickym volam za predpokladu, ze backend nevrati data (vyprsela platnost, smazana hodnota, atd.)
          // muze to zpusobovat mozna problem s refreshem u spravnych hodnot ve storu (spravne by se to melo cele prepsat ve storu)
          this._cache.set(this._id, x);
        }
      });
  }
}
