import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {Store} from '@ngrx/store';
import {filter, map, take, tap} from 'rxjs/operators';
import {Observable, of, switchMap} from 'rxjs';

import {loadAndWait, PwaService} from '@tsm/framework/root';
import {
  getRuntimeInfo,
  LoadRuntimeInfoAction,
  RuntimeInfo,
  RuntimeService,
} from '@tsm/runtime-info';

@Injectable()
export class UserIsLoggedInGuard implements CanActivate {
  constructor(
    private router: Router,
    private runtimeService: RuntimeService,
    private pwaService: PwaService,
    private store$: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    return this.store$.select(getRuntimeInfo).pipe(
      switchMap((x) => {
        return x.id != null
          ? of(x)
          : this.store$.pipe(
              loadAndWait(
                LoadRuntimeInfoAction({refresh: false}),
                getRuntimeInfo,
              ),
            );
      }),
      filter((x) => x && !!x.id),
      tap((x) => this.pwaService.ask(x.id)),
      map((x) => true),
    );
  }
}

@Injectable()
export class RuntimeInfoResolver implements Resolve<RuntimeInfo> {
  constructor(private store$: Store) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RuntimeInfo> {
    return this.store$.select(getRuntimeInfo).pipe(
      filter((x) => x && !!x.id),
      take(1),
      map((x) => x),
    );
  }
}
