import {SelectItem} from 'primeng/api';

export enum ExportStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export function getAllExportStatuses(): SelectItem[] {
  const exportStatus: SelectItem[] = [];
  Object.keys(ExportStatus).forEach((key) =>
    exportStatus.push({label: ExportStatus[key], value: key}),
  );
  return exportStatus;
}
