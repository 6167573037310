import {NotificationRule} from '../models';
import {createReducer, on} from '@ngrx/store';
import {
  DiffNotificationRule,
  DiffNotificationRuleError,
  DiffNotificationRuleSuccess,
  LoadNotificationRuleByCode,
  LoadNotificationRuleByCodeError,
  LoadNotificationRuleByCodeSuccess,
  LoadNotificationRuleById,
  LoadNotificationRuleByIdError,
  LoadNotificationRuleByIdSuccess,
  UpsertNotificationRule,
  UpsertNotificationRuleSuccess,
} from '../actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';

export type NotificationRuleState = EntityState<
  IdentifiedPack<NotificationRule>
>;

export const notificationRuleAdapter: EntityAdapter<
  IdentifiedPack<NotificationRule>
> = createEntityAdapter<IdentifiedPack<NotificationRule>>({});
export const initialState: NotificationRuleState =
  notificationRuleAdapter.getInitialState({});

export const notificationRuleReducer = createReducer(
  initialState,

  on(UpsertNotificationRule, (state, {notificationRule}) =>
    notificationRuleAdapter.updateOne(
      {
        id: notificationRule.id,
        changes: {
          ...state.entities[notificationRule.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertNotificationRuleSuccess, (state, {notificationRule}) =>
    notificationRuleAdapter.upsertOne(
      {
        id: notificationRule.id,
        data: notificationRule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationRuleById, (state, {id}) =>
    notificationRuleAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationRuleByIdSuccess, (state, {notificationRule}) =>
    notificationRuleAdapter.upsertOne(
      {
        id: notificationRule.id,
        data: notificationRule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationRuleByIdError, (state, {id, error}) =>
    notificationRuleAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadNotificationRuleByCode, (state, {code}) =>
    notificationRuleAdapter.upsertOne(
      {
        id: code,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationRuleByCodeSuccess, (state, {notificationRule}) => {
    const newState = notificationRuleAdapter.removeOne(
      notificationRule.code,
      state,
    );
    return notificationRuleAdapter.upsertOne(
      {
        id: notificationRule.id,
        data: notificationRule,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadNotificationRuleByCodeError, (state, {code, error}) =>
    notificationRuleAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DiffNotificationRule, (state, {diffEntities}) =>
    notificationRuleAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffNotificationRuleSuccess, (state, {notificationRules}) =>
    notificationRuleAdapter.upsertMany(
      notificationRules.map((notificationRule) => {
        return {
          id: notificationRule.id,
          changes: {
            id: notificationRule.id,
            data: notificationRule,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffNotificationRuleError, (state, {diffEntities, error}) =>
    notificationRuleAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
