import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {withLatestCached} from '@tsm/framework/root';
import {concatMap, exhaustMap, filter, map, mergeMap} from 'rxjs/operators';
import {
  DeleteCharacteristicsValue,
  DeleteCharacteristicsValueError,
  DeleteCharacteristicsValueSuccess,
  LoadSpecCharacteristicsValues,
  LoadSpecCharacteristicsValuesByCharId,
  LoadSpecCharacteristicsValuesByCharIdError,
  LoadSpecCharacteristicsValuesByCharIdSuccess,
  LoadSpecCharacteristicsValuesError,
  LoadSpecCharacteristicsValuesSuccess,
  UpsertCharacteristicsValue,
  UpsertCharacteristicsValueError,
  UpsertCharacteristicsValueSuccess,
} from '../actions';
import {selectCharacteristicsValues} from '../selectors';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {CommonApiService} from '../services';
import {CharacteristicsValue} from '../models';
import {translation} from '../i18n';

@Injectable()
export class CharacteristicsValuesEffects {
  private readonly CHAR_VALS_API_PATH = 'characteristicsValues';

  translation = translation;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSpecCharacteristicsValues),
      withLatestCached(() => this.store.select(selectCharacteristicsValues)),
      filter(([, entities]) => entities.length === 0),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.CHAR_VALS_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadSpecCharacteristicsValuesSuccess({entities: env.data})
                : LoadSpecCharacteristicsValuesError(env.error),
            ),
          ),
      ),
    ),
  );

  loadByCharId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSpecCharacteristicsValuesByCharId),
      concatMap((action) => {
        return this.commonApiService
          .getItemsById('values', action.charId, this.CHAR_VALS_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadSpecCharacteristicsValuesByCharIdSuccess({
                    entities: env.data,
                    charId: action.charId,
                  })
                : LoadSpecCharacteristicsValuesByCharIdError(env.error),
            ),
          );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCharacteristicsValue),
      concatMap(({id}) =>
        this.commonApiService
          .deleteEntity(this.CHAR_VALS_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? DeleteCharacteristicsValueSuccess({id: id})
                : DeleteCharacteristicsValueError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertCharacteristicsValue),
      concatMap(({characteristicsValue}) =>
        this.commonApiService
          .upsertEntity(
            this.CHAR_VALS_API_PATH,
            characteristicsValue,
            characteristicsValue.id,
          )
          .pipe(
            mergeMap((env) => {
              const actions = [];
              if (env.success) {
                this.toastService.showToast(
                  translation.characteristicsService.effects
                    .characteristicValueSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                actions.push(
                  UpsertCharacteristicsValueSuccess({
                    characteristicsValue: env.data,
                  }),
                );
                actions.push(
                  LoadSpecCharacteristicsValuesByCharId({
                    charId: characteristicsValue.characteristics.id,
                  }),
                );
                return actions;
              } else {
                this.toastService.showError(
                  env.error,
                  translation.characteristicsService.effects
                    .characteristicValueSaveError,
                );
                return [UpsertCharacteristicsValueError(env.error)];
              }
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      CharacteristicsValue,
      CharacteristicsValue
    >,
    private router: Router,
    private store: Store<any>,
    private toastService: ToastService,
  ) {}
}
