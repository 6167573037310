import {createAction, props} from '@ngrx/store';
import {CharacteristicsValue} from '../models';

export const LoadSpecCharacteristicsValues = createAction(
  '[CharacteristicsValues] Load CharacteristicsValues',
);
export const LoadSpecCharacteristicsValuesSuccess = createAction(
  '[CharacteristicsValues] Load CharacteristicsValues Success',
  props<{
    entities: CharacteristicsValue[];
  }>(),
);
export const LoadSpecCharacteristicsValuesError = createAction(
  '[CharacteristicsValues] Load CharacteristicsValues Error',
  props<{
    error: any;
  }>(),
);

export const UpsertCharacteristicsValue = createAction(
  '[CharacteristicsValue] Upsert CharacteristicsValue',
  props<{
    characteristicsValue: CharacteristicsValue;
  }>(),
);
export const UpsertCharacteristicsValueSuccess = createAction(
  '[CharacteristicsValue] Upsert CharacteristicsValue Success',
  props<{
    characteristicsValue: CharacteristicsValue;
  }>(),
);
export const UpsertCharacteristicsValueError = createAction(
  '[CharacteristicsValue] Upsert CharacteristicsValue Error',
  props<{
    error: any;
  }>(),
);

export const DeleteCharacteristicsValue = createAction(
  '[CharacteristicsValue] Delete CharacteristicsValue',
  props<{
    id: string;
  }>(),
);
export const DeleteCharacteristicsValueSuccess = createAction(
  '[CharacteristicsValue] Delete CharacteristicsValue Success',
  props<{
    id: string;
  }>(),
);
export const DeleteCharacteristicsValueError = createAction(
  '[CharacteristicsValue] Delete CharacteristicsValue Error',
  props<{
    error: any;
  }>(),
);

export const LoadSpecCharacteristicsValuesByCharId = createAction(
  '[CharacteristicsValues] Load CharacteristicsValuesByCharId',
  props<{
    charId: string;
  }>(),
);
export const LoadSpecCharacteristicsValuesByCharIdSuccess = createAction(
  '[CharacteristicsValues] Load CharacteristicsValuesByCharId Success',
  props<{
    entities: CharacteristicsValue[];
    charId: string;
  }>(),
);
export const LoadSpecCharacteristicsValuesByCharIdError = createAction(
  '[CharacteristicsValues] Load CharacteristicsValuesByCharId Error',
  props<{
    error: any;
  }>(),
);
