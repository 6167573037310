<span
  [ngClass]="{
    'p-inputnumber p-component': true,
    'p-inputnumber-buttons-stacked':
      this.showButtons && this.buttonLayout === 'stacked',
    'p-inputnumber-buttons-horizontal':
      this.showButtons && this.buttonLayout === 'horizontal',
    'p-inputnumber-buttons-vertical':
      this.showButtons && this.buttonLayout === 'vertical',
  }"
  [ngStyle]="style"
  [class]="styleClass"
>
  <input
    #input
    [ngClass]="'p-inputnumber-input'"
    [ngStyle]="inputStyle"
    [class]="inputStyleClass"
    pInputText
    [value]="formattedValue()"
    [attr.placeholder]="placeholder"
    [attr.title]="title"
    [attr.id]="inputId"
    [attr.size]="size"
    [attr.name]="name"
    [attr.autocomplete]="autocomplete"
    [attr.maxlength]="maxlength"
    [attr.tabindex]="tabindex"
    [attr.aria-label]="ariaLabel"
    [attr.aria-required]="ariaRequired"
    [disabled]="disabled"
    [attr.required]="required"
    [attr.min]="min"
    [attr.max]="max"
    [readonly]="readonly"
    inputmode="decimal"
    (input)="onUserInput($event)"
    (keydown)="onInputKeyDown($event)"
    (keypress)="onInputKeyPress($event)"
    (paste)="onPaste($event)"
    (click)="onInputClick()"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)"
  />
  <ng-container *ngIf="buttonLayout != 'vertical' && showClear && value">
    <TimesIcon
      *ngIf="!clearIconTemplate"
      [ngClass]="'p-inputnumber-clear-icon'"
      (click)="clear()"
    />
    <span
      *ngIf="clearIconTemplate"
      (click)="clear()"
      class="p-inputnumber-clear-icon"
    >
      <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
    </span>
  </ng-container>

  <span
    class="p-inputnumber-button-group"
    *ngIf="showButtons && buttonLayout === 'stacked'"
  >
    <button
      type="button"
      pButton
      [ngClass]="{'p-inputnumber-button p-inputnumber-button-up': true}"
      class="p-button-icon-only"
      [class]="incrementButtonClass"
      [disabled]="disabled"
      (mousedown)="onUpButtonMouseDown($event)"
      (mouseup)="onUpButtonMouseUp()"
      (mouseleave)="onUpButtonMouseLeave()"
      (keydown)="onUpButtonKeyDown($event)"
      (keyup)="onUpButtonKeyUp()"
      tabindex="-1"
    >
      <span *ngIf="incrementButtonIcon" [ngClass]="incrementButtonIcon"></span>
      <ng-container *ngIf="!incrementButtonIcon">
        <AngleUpIcon *ngIf="!incrementButtonIconTemplate" />
        <ng-template
          *ngTemplateOutlet="incrementButtonIconTemplate"
        ></ng-template>
      </ng-container>
    </button>
    <button
      type="button"
      pButton
      [ngClass]="{'p-inputnumber-button p-inputnumber-button-down': true}"
      class="p-button-icon-only"
      [class]="decrementButtonClass"
      [disabled]="disabled"
      (mousedown)="onDownButtonMouseDown($event)"
      (mouseup)="onDownButtonMouseUp()"
      (mouseleave)="onDownButtonMouseLeave()"
      (keydown)="onDownButtonKeyDown($event)"
      (keyup)="onDownButtonKeyUp()"
      tabindex="-1"
    >
      <span *ngIf="decrementButtonIcon" [ngClass]="decrementButtonIcon"></span>
      <ng-container *ngIf="!decrementButtonIcon">
        <AngleDownIcon *ngIf="!decrementButtonIconTemplate" />
        <ng-template
          *ngTemplateOutlet="decrementButtonIconTemplate"
        ></ng-template>
      </ng-container>
    </button>
  </span>
  <button
    type="button"
    pButton
    [ngClass]="{'p-inputnumber-button p-inputnumber-button-up': true}"
    [class]="incrementButtonClass"
    class="p-button-icon-only"
    *ngIf="showButtons && buttonLayout !== 'stacked'"
    [disabled]="disabled"
    (mousedown)="onUpButtonMouseDown($event)"
    (mouseup)="onUpButtonMouseUp()"
    (mouseleave)="onUpButtonMouseLeave()"
    (keydown)="onUpButtonKeyDown($event)"
    (keyup)="onUpButtonKeyUp()"
    tabindex="-1"
  >
    <span *ngIf="incrementButtonIcon" [ngClass]="incrementButtonIcon"></span>
    <ng-container *ngIf="!incrementButtonIcon">
      <AngleUpIcon *ngIf="!incrementButtonIconTemplate" />
      <ng-template
        *ngTemplateOutlet="incrementButtonIconTemplate"
      ></ng-template>
    </ng-container>
  </button>
  <button
    type="button"
    pButton
    [ngClass]="{'p-inputnumber-button p-inputnumber-button-down': true}"
    class="p-button-icon-only"
    [class]="decrementButtonClass"
    *ngIf="showButtons && buttonLayout !== 'stacked'"
    [disabled]="disabled"
    (mousedown)="onDownButtonMouseDown($event)"
    (mouseup)="onDownButtonMouseUp()"
    (mouseleave)="onDownButtonMouseLeave()"
    (keydown)="onDownButtonKeyDown($event)"
    (keyup)="onDownButtonKeyUp()"
    tabindex="-1"
  >
    <span *ngIf="decrementButtonIcon" [ngClass]="decrementButtonIcon"></span>
    <ng-container *ngIf="!decrementButtonIcon">
      <AngleDownIcon *ngIf="!decrementButtonIconTemplate" />
      <ng-template
        *ngTemplateOutlet="decrementButtonIconTemplate"
      ></ng-template>
    </ng-container>
  </button>
</span>
