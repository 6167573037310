import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IframeKioskService {
  private showIframeKiosk$ = new BehaviorSubject({show: false, url: null});

  constructor() {}

  get isShowIframeKiosk() {
    return sessionStorage.getItem('kiosk') === 'true';
  }

  showIframeKiosk(url: string) {
    sessionStorage.setItem('kiosk', 'true');
    this.showIframeKiosk$.next({show: true, url: url});
  }

  hideIframeKiosk() {
    sessionStorage.setItem('kiosk', 'false');
    this.showIframeKiosk$.next({show: false, url: null});
  }

  getShowIframeKiosk() {
    return this.showIframeKiosk$.asObservable();
  }
}
