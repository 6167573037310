import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {
  DeleteRole,
  DeleteRoleError,
  DeleteRoleSuccess,
  DiffRole,
  DiffRoleError,
  DiffRoleSuccess,
  LoadRoleByCode,
  LoadRoleByCodeError,
  LoadRoleByCodeSuccess,
  LoadRoleById,
  LoadRoleByIdError,
  LoadRoleByIdSuccess,
  LoadRoles,
  LoadRolesError,
  LoadRolesSuccess,
  UpsertRole,
  UpsertRoleError,
  UpsertRoleSuccess,
} from '../actions';
import {EntityIdentif, Role} from '../model';
import {selectRoleById} from '../selectors';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {withLatestCached} from '@tsm/framework/root';

@Injectable()
export class RoleEffects {
  translation = translation;
  translationShared = translationShared;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<Role, Role>,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoleById),
      withLatestCached((action) =>
        this.store.select(selectRoleById(action.id)),
      ),
      concatMap(([action, role]) => {
        if (action?.forcedReload || !role.data) {
          return this.commonApiService
            .getEntity(EntityIdentif.ROLE, action.id)
            .pipe(
              map((env) =>
                env.success
                  ? LoadRoleByIdSuccess({role: env.data})
                  : LoadRoleByIdError({id: action.id, error: env.error}),
              ),
            );
        } else {
          return of(LoadRoleByIdSuccess({role: role.data}));
        }
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoleByCode),
      concatMap(({code}) =>
        this.commonApiService
          .getItemsById('code', code, EntityIdentif.ROLE)
          .pipe(
            map((env) =>
              env.success && env.data
                ? LoadRoleByCodeSuccess(env.data)
                : LoadRoleByCodeError({code, error: env.error}),
            ),
          ),
      ),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRoles),
      exhaustMap(() =>
        this.commonApiService
          .getAll(EntityIdentif.ROLE)
          .pipe(
            map((env) =>
              env.success
                ? LoadRolesSuccess({entities: env.data})
                : LoadRolesError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRole),
      concatMap(({role, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(EntityIdentif.ROLE, role, role.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.roleSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertRoleSuccess({
                  role: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.roleSaveFailure,
                );
                return UpsertRoleError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  redirectToDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRoleSuccess),
      tap(({role, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate(['/user-management/role/'.concat(role.id)]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRole),
      concatMap(({id, listingId}) =>
        this.commonApiService.deleteEntity(EntityIdentif.ROLE, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.roleDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteRoleSuccess({id: id, listingId: listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects.roleDeleteFailure,
              );
              return DeleteRoleError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffRole),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/roles', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffRoleSuccess({roles: env.data, listingId: listingId});
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffRoleError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRoleSuccess, DeleteRoleSuccess, DiffRoleSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
