import {Pipe, untracked} from '@angular/core';
import {createSelector, select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe, IdentifiedPack} from '@tsm/framework/root';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {LoadUserById, LoadUserGroupById} from '../actions';
import {selectUserById, selectUserGroupById} from '../selectors';
import {User, UserGroup} from '../model';
import {of} from 'rxjs';

@Pipe({
  name: 'userUserGroup',
  pure: true,
})
export class UserUserGroupPipe extends AbstractCachedLoaderPipe {
  _field = 'name';
  _ownerType = null;

  constructor(protected store: Store) {
    super(store);
  }

  // @ts-ignore
  setObservable(
    item: any,
    field = 'name',
    ownerType: 'User' | 'UserGroup' = null,
  ) {
    this.destroy();
    this._id = this._id?.ownerId || item;
    this._field = field || this._id.field;
    this._ownerType = ownerType || this._id.ownerType;

    if (this._id == null || this._ownerType == null) {
      return of(null);
    }
    this._obs$ = this.store.pipe(
      filter((_) => this._id && this._ownerType),
      select(
        this._id && this._ownerType ? this.getSelect(this._id) : () => null,
      ),
      map((it) => {
        if (!it || !it.data) {
          return null;
        } else {
          return this._field !== 'all' ? it.data[this._field] : it.data;
        }
      }),
    );
  }

  getSelect(
    id: string,
  ): MemoizedSelector<
    Object,
    IdentifiedPack<UserGroup> | IdentifiedPack<User>
  > {
    if (id && this._ownerType) {
      switch (this._ownerType) {
        case 'User':
          return selectUserById(id);
        case 'UserGroup':
          return selectUserGroupById(id);
      }
    }
    return createSelector(
      () => null,
      (x) => x,
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(
        filter((_) => this._id && this._ownerType),
        select(
          this._id && this._ownerType ? this.getSelect(this._id) : () => null,
        ),
      )
      .subscribe((x) => {
        if (!x) {
          switch (this._ownerType) {
            case 'User':
              untracked(() =>
                this.store.dispatch(LoadUserById({id: this._id})),
              );
              break;
            case 'UserGroup':
              untracked(() =>
                this.store.dispatch(LoadUserGroupById({id: this._id})),
              );
          }
        }
      });
  }
}
