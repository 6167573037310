import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'memoizedDiscriminator',
})
export class MemoizedDiscriminatorPipe implements PipeTransform {
  _prevValue: any = null;
  _prevResult: any = null;

  public transform(
    value: any,
    functor: (value: any) => any,
    discriminator: (oldVal, newVal) => boolean,
  ): any {
    if (this._prevValue == null) {
      this._prevValue = value;
      this._prevResult = functor(value);
      return this._prevResult;
    }

    const unchanged = discriminator(this._prevValue, value);
    if (unchanged) {
      return this._prevResult;
    }
    this._prevValue = value;
    this._prevResult = functor(value);
    return this._prevResult;
  }
}
