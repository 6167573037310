import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '@tsm/inventory/plugins-i18n';
import type {Config} from '@tsm/framework/config';

const commonNew = {
  name: {
    name: translationShared.shared.name,
    required: true,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'text',
        label: translationShared.shared.name,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-text',
    },
  },
  key: {
    name: translationShared.shared.key,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'previewDropdown',
        label: translationShared.shared.key,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-text',
    },
  },
  customerId: {
    name: translationShared.shared.customer,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'previewDropdown',
        label: translationShared.shared.customer,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'tsm-customer-lov',
    },
  },
  accountId: {
    name: translationShared.shared.account,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'previewDropdown',
        label: translationShared.shared.account,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'tsm-account-lov',
    },
  },
  location1: {
    name: translationShared.shared.locationOne,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'text',
        label: translationShared.shared.locationOne,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'tsm-address-input',
    },
  },
  location2: {
    name: translationShared.shared.locationTwo,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'text',
        label: translationShared.shared.locationTwo,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'tsm-address-input',
    },
  },
  validFrom: {
    name: translationShared.shared.validFrom,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'date',
        label: translationShared.shared.validFrom,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-date',
    },
  },
  validTo: {
    name: translationShared.shared.validTo,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'date',
        label: translationShared.shared.validTo,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-date',
    },
  },
  description: {
    name: translationShared.shared.description,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'tiptap',
        label: translationShared.shared.description,
        labelPosition: 'top',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-tiptap',
    },
  },
};

export const controlsProductNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-inventory-product-new',
  name: 'Inventory Product new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'EntityInstanceConfiguration',
  tsmControls: {
    ...commonNew,
  },
};

export const controlsServiceNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-inventory-service-new',
  name: 'Inventory Service new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'EntityInstanceConfiguration',
  tsmControls: {
    ...commonNew,
  },
};

export const controlsResourceNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-inventory-resource-new',
    name: 'Inventory Resource new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {
      ...commonNew,
    },
  };
export const controlsSkillNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-inventory-skill-new',
  name: 'Inventory Skill new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'EntityInstanceConfiguration',
  tsmControls: {
    ...commonNew,
  },
};

export const controlsWorkNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-inventory-work-new',
  name: 'Inventory Work new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'EntityInstanceConfiguration',
  tsmControls: {
    ...commonNew,
  },
};

export const controlsOthersNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-inventory-others-new',
  name: 'Inventory Others new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'EntityInstanceConfiguration',
  tsmControls: {
    ...commonNew,
  },
};

const common = {
  'EntityInstanceConfiguration.Header': {
    name: translationShared.shared.header,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'text',
        label: translationShared.shared.header,
        labelPosition: 'left',
      },
    },
  },
  'EntityInstanceConfiguration.Toolbar.Settings': {
    name: translationShared.shared.settings,
    imageUrl: 'assets/preview/prew_settings.png',
  },
  'EntityInstanceConfiguration.Toolbar.InventoryChange': {
    name: translation.inventoryPlugins.inventoryChange,
    imageUrl: 'assets/preview/prew_settings.png',
  },
  'EntityInstanceConfiguration.Details.Name': {
    name: translationShared.shared.name,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'text',
        label: translationShared.shared.name,
        labelPosition: 'left',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-text',
    },
  },
  'EntityInstanceConfiguration.Details.Key': {
    name: translationShared.shared.key,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        type: 'text',
        label: translationShared.shared.key,
        labelPosition: 'left',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-text',
    },
  },
  'EntityInstanceConfiguration.Toolbar.Refresh': {
    name: translationShared.shared.refreshButton,
    description: null,
    icon: 'pi pi-ellipsis-v',
  },
  'EntityInstanceConfiguration.Details.CustomerId': {
    name: translationShared.shared.customer,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.customer,
        labelPosition: 'left',
        type: 'previewDropdown',
      },
    },
    defaultComponent: {
      selector: 'tsm-customer-lov',
    },
  },
  'EntityInstanceConfiguration.Details.AccountId': {
    name: translationShared.shared.account,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.account,
        labelPosition: 'left',
        type: 'previewDropdown',
      },
    },
    defaultComponent: {
      selector: 'tsm-account-lov',
    },
  },
  'EntityInstanceConfiguration.Details.EntityCatalogSpecificationId': {
    name: translationShared.shared.serviceDefinition,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.serviceDefinition,
        labelPosition: 'left',
        type: 'previewDropdown',
      },
    },
    defaultComponent: {
      selector: 'tsm-catalog-specification-lov',
    },
  },
  'EntityInstanceConfiguration.Details.Location1': {
    name: translationShared.shared.locationOne,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.locationOne,
        labelPosition: 'left',
        type: 'text',
      },
    },
    defaultComponent: {
      selector: 'tsm-address-input',
    },
  },
  'EntityInstanceConfiguration.Details.Location2': {
    name: translationShared.shared.locationTwo,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.locationTwo,
        labelPosition: 'left',
        type: 'text',
      },
    },
    defaultComponent: {
      selector: 'tsm-address-input',
    },
  },
  'EntityInstanceConfiguration.Details.Labels': {
    name: translationShared.shared.labels,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.labels,
        labelPosition: 'left',
        type: 'text',
      },
    },
    defaultComponent: {
      selector: 'tsm-data-tags',
      inputs: {
        url: (config: Config) =>
          config.apiUrls.inventory + '/entity-instance-configurations/dataTags',
      },
    },
  },
  'EntityInstance.Details.Labels': {
    name: translation.inventoryPlugins.entityInstanceLabels,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translation.inventoryPlugins.entityInstanceLabels,
        labelPosition: 'left',
        type: 'text',
      },
    },
    defaultComponent: {
      selector: 'tsm-data-tags',
      inputs: {
        url: (config: Config) =>
          config.apiUrls.inventory + '/entity-instances/dataTags',
      },
    },
  },
  'EntityInstanceConfiguration.Details.Description': {
    name: translationShared.shared.description,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.description,
        labelPosition: 'left',
        type: 'tiptap',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-tiptap',
    },
  },
  'EntityInstanceConfiguration.Dates.WhenInserted': {
    name: translationShared.shared.whenInserted,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.whenInserted,
        labelPosition: 'left',
        type: 'datetime',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-date',
      inputs: {
        showTime: true,
      },
    },
  },
  'EntityInstanceConfiguration.Dates.WhenEdited': {
    name: translationShared.shared.whenEdited,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.whenEdited,
        labelPosition: 'left',
        type: 'datetime',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-date',
      inputs: {
        showTime: true,
      },
    },
  },
  'EntityInstanceConfiguration.People.WhoInserted': {
    name: translationShared.shared.whoInserted,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.whoInserted,
        labelPosition: 'left',
        type: 'text',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-text',
    },
  },
  'EntityInstanceConfiguration.People.WhoEdited': {
    name: translationShared.shared.whoEdited,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.whoEdited,
        labelPosition: 'left',
        type: 'text',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-text',
    },
  },
  'EntityInstanceConfiguration.Dates.ValidTo': {
    name: translationShared.shared.validTo,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.validTo,
        labelPosition: 'left',
        type: 'datetime',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-date',
      inputs: {
        showTime: true,
      },
    },
  },
  'EntityInstanceConfiguration.Dates.ValidFrom': {
    name: translationShared.shared.validFrom,
    description: null,
    previewComponent: {
      selector: 'tsm-form-field',
      inputs: {
        label: translationShared.shared.validFrom,
        labelPosition: 'left',
        type: 'datetime',
      },
    },
    defaultComponent: {
      selector: 'dtl-form-input-date',
      inputs: {
        showTime: true,
      },
    },
  },
};

export const controlsProductDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-inventory-product-detail',
    name: 'Inventory Product detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {
      ...common,
    },
  };

export const controlsServiceDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-inventory-service-detail',
    name: 'Inventory Service detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {
      ...common,
      'EntityInstanceConfiguration.Details.CreateTicket': {
        name: translation.inventoryPlugins.createTicket,
        imageUrl: 'assets/preview/prew_settings.png',
      },
    },
  };

export const controlsResourceDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-inventory-resource-detail',
    name: 'Inventory Resource detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {
      ...common,
    },
  };

export const controlsSkillDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-inventory-skill-detail',
    name: 'Inventory Skill detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {
      ...common,
    },
  };

export const controlsWorkDetailConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-inventory-work-detail',
  name: 'Inventory Work detail',
  description: '',
  useType: 'FORM_DETAIL',
  entityType: 'EntityInstanceConfiguration',
  tsmControls: {
    ...common,
    'EntityInstanceConfiguration.Details.CreateTicket': {
      name: translation.inventoryPlugins.createTicket,
      imageUrl: 'assets/preview/prew_settings.png',
    },
  },
};

export const controlsOthersDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-inventory-others-detail',
    name: 'Inventory Others detail',
    description: '',
    useType: 'FORM_DETAIL',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {
      ...common,
    },
  };

export const controlsEntityInstanceConfigurationTopbarConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-entity-instance-configuration.topbar',
    name: 'Entity instance configuration topbar',
    description: '',
    useType: 'CONFIG',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {},
  };

export const controlsEntityInstanceConfigurationBulkTopbarConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-entity-instance-configuration.bulkTopbar',
    name: 'Entity instance configuration bulk topbar',
    description: '',
    useType: 'CONFIG',
    entityType: 'EntityInstanceConfiguration',
    tsmControls: {},
  };
