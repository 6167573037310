import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {worklogTypeAdapter} from '../reducers/worklog-type.reducer';
import {RouterStateUrl} from '@tsm/framework/root';

const getDmsState = createFeatureSelector<DmsState>('dms');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
const pathToEntities = createSelector(getDmsState, (x) => x.worklogType);

const {selectAll, selectEntities} =
  worklogTypeAdapter.getSelectors(pathToEntities);

export const selectWorklogTypeById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectWorklogTypesByIds = (ids: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.data.id)),
  );

export const selectWorklogTypeByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data.code === code),
  );

export const selectWorklogTypesByCodes = (codes: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x.data.code)),
  );

export const selectAllWorklogType = createSelector(selectAll, (entities) =>
  entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);

export const selectWorklogTypeByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);
