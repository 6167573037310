import {createAction, props} from '@ngrx/store';
import {
  ColorModel,
  DataViewModeEnum,
  FilterModel,
  GridDataHttpModel,
  ListingProfile,
  NodeData,
  SortModel,
  TableColumn,
  TableDefault,
  TableKanban,
  TableKeyType,
  TableShowConfig,
  TableTqlExtend,
  TableType,
  TreeTable,
} from '../models';

// Data events
export const LoadProfiles = createAction(
  '[Listing] Load profiles',
  props<{
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const LoadProfilesSuccess = createAction(
  '[Listing] Load profiles SUCCESS',
  props<{
    id: string;
    profiles: ListingProfile[];
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const LoadProfilesError = createAction(
  '[Listing] Load profiles ERROR',
  props<{id: string; error: any}>(),
);

export const RefreshProfiles = createAction(
  '[Listing] Refresh profiles',
  props<{id: string; listingType: TableType; reloadData: boolean}>(),
);

export const ResetTableStateToDefaultByCurrentProfile = createAction(
  '[Listing] Reset table state by current profile',
  props<{id: string}>(),
);

export const RefreshProfilesSuccess = createAction(
  '[Listing] Refresh profiles SUCCESS',
  props<{id: string; profiles: ListingProfile[]}>(),
);

export const RefreshProfilesError = createAction(
  '[Listing] Refresh profiles ERROR',
  props<{id: string; error: any}>(),
);

// Specialni pripad pro komponentu table-filter-widget
export const RemoveProfileWithoutDataReload = createAction(
  '[Listing] Remove Profile without data reload',
  props<{id: string}>(),
);

// Specialni pripad pro komponentu table-filter-widget
export const RefreshProfilesSuccessWithoutDataReload = createAction(
  '[Listing] Refresh profiles without data reload SUCCESS',
  props<{id: string; profiles: ListingProfile[]}>(),
);

export const LoadTable = createAction(
  '[Listing] Load table',
  props<{
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const LoadTableSuccess = createAction(
  '[Listing] Load table SUCCESS',
  props<{id: string}>(),
);

export const LoadTableError = createAction(
  '[Listing] Load table ERROR',
  props<{id: string; error: any}>(),
);

export const ResetData = createAction(
  '[Listing] Reset data',
  props<{id: string}>(),
);

export const ResetTableStateWithoutDataReload = createAction(
  '[Listing] Reset Table state without data reload',
  props<{id: string}>(),
);

export const RefreshDataAndClearSelected = createAction(
  '[Listing] Refresh data and clear selected',
  props<{id: string}>(),
);

export const RefreshSwitchMapDataAndClearSelected = createAction(
  '[Listing] Refresh Switch Map data and clear selected',
  props<{id: string}>(),
);

export const RefreshData = createAction(
  '[Listing] Refresh data',
  props<{id: string}>(),
);

export const RefreshSwitchMapData = createAction(
  '[Listing] Refresh Switch Map data',
  props<{id: string}>(),
);

export const RefreshProfilesFor = createAction(
  '[Listing] Refresh Profiles for listing',
  props<{id: string; reloadData: boolean}>(),
);

export const RefreshProfilesForSuccess = createAction(
  '[Listing] Refresh Profiles for listing success',
  props<{id: string}>(),
);

export const LoadData = createAction(
  '[Listing] Load data',
  props<{
    id: string;
    isSubTree?: boolean;
    parentValue?: string;
    expandAll?: boolean;
    selectedRowId?: string;
    errorMsgDurationSeconds?: number;
  }>(),
);

export const LoadDataSuccess = createAction(
  '[Listing] Load data SUCCESS',
  props<{
    id: string;
    data: GridDataHttpModel;
    isSubTree?: boolean;
    expandAll?: boolean;
    selectedRowId?: string;
    withoutLoading?: boolean;
  }>(),
);

export const LoadDataError = createAction(
  '[Listing] Load data ERROR',
  props<{id: string; error: any; errorMsgDurationSeconds?: number}>(),
);

export const LoadTotalData = createAction(
  '[Listing] Load total data',
  props<{
    id: string;
  }>(),
);

export const LoadTotalDataSuccess = createAction(
  '[Listing] Load total data SUCCESS',
  props<{
    id: string;
    data: {[k: string]: number};
  }>(),
);

export const LoadTotalDataError = createAction(
  '[Listing] Load total data ERROR',
  props<{id: string; error: any}>(),
);

// List events
export const InitColumnsChanged = createAction(
  '[ListingModel] Init Columns changed',
  props<{id: string; columns: TableColumn[]}>(),
);

// List events
export const ColumnsChanged = createAction(
  '[ListingModel] Columns changed',
  props<{id: string; columns: TableColumn[]}>(),
);

export const AddColumns = createAction(
  '[ListingModel] Add Columns',
  props<{id: string; columns: TableColumn[]}>(),
);

export const ChangeColumn = createAction(
  '[ListingModel] Change Column',
  props<{id: string; column: TableColumn}>(),
);

export const ColumnsResized = createAction(
  '[ListingModel] Columns Resized',
  props<{id: string; columns: TableColumn[]}>(),
);

export const RemoveColumns = createAction(
  '[ListingModel] Remove Columns',
  props<{id: string; columns: TableColumn[]}>(),
);

export const PageChanged = createAction(
  '[ListingModel] Page changed',
  props<{id: string; page: number}>(),
);

export const PageSizeChanged = createAction(
  '[ListingModel] Page size changed',
  props<{id: string; pageSize: number}>(),
);

export const PageAndPageSizeChanged = createAction(
  '[ListingModel] Page and Page size changed',
  props<{id: string; pageAndPageSize: {page: number; pageSize: number}}>(),
);
export const PageSizeChangedWithoutRefresh = createAction(
  '[ListingModel] Page size changed without refresh',
  props<{id: string; pageSize: number}>(),
);

export const FiltersChanged = createAction(
  '[ListingModel] Filters changed',
  props<{id: string; filters: FilterModel[]}>(),
);

export const FiltersChangedWithoutRefresh = createAction(
  '[ListingModel] Filters changed without refresh',
  props<{id: string; filters: FilterModel[]}>(),
);

export const ShowConfigSet = createAction(
  '[ListingModel] Show Config set',
  props<{id: string; showConfig: TableShowConfig}>(),
);

export const ShowConfigChanged = createAction(
  '[ListingModel] Show Config changed',
  props<{id: string; showConfig: TableShowConfig}>(),
);

export const SetLoading = createAction(
  '[ListingModel] Set loading',
  props<{id: string; loading: boolean}>(),
);

export const CustomChangeSettingTable = createAction(
  '[ListingModel] Custom change setting table',
  props<{id: string; change: boolean}>(),
);

export const FiltersChangedMerge = createAction(
  '[ListingModel] Filters changed merge',
  props<{id: string; filters: FilterModel[]}>(),
);

export const FiltersChangedMergeWithoutRefresh = createAction(
  '[ListingModel] Filters changed merge without refresh',
  props<{id: string; filters: FilterModel[]}>(),
);

export const FiltersChangedIfNew = createAction(
  '[ListingModel] Filters changed if new',
  props<{id: string; filters: FilterModel[]}>(),
);

export const FilterChanged = createAction(
  '[ListingModel] Filter changed',
  props<{id: string; filter: FilterModel}>(),
);

export const SortsChanged = createAction(
  '[ListingModel] Sorts changed',
  props<{id: string; sorts: SortModel[]}>(),
);

export const ColorsChanged = createAction(
  '[ListingModel] Colors changed',
  props<{id: string; colors: ColorModel}>(),
);

export const SetOptions = createAction(
  '[ListingModel] Set Options',
  props<{id: string; options: string}>(),
);

export const TqlExtendChanged = createAction(
  '[ListingModel] TQL extend changed',
  props<{id: string; tqlExtend: TableTqlExtend}>(),
);

export const TqlQueryParamsChanged = createAction(
  '[ListingModel] TQL query params changed',
  props<{id: string; params: {[k: string]: any}}>(),
);

export const SetEmptyData = createAction(
  '[ListingModel] Set Empty data',
  props<{id: string}>(),
);

export const ChangeSelectedProfile = createAction(
  '[ListingModel] Change Selected profile',
  props<{
    id: string;
    selectedProfileId: string;
    mergeProfileFilters: boolean;
  }>(),
);

export const SelectedProfileChangedSuccess = createAction(
  '[ListingModel] Selected profile changed success',
  props<{
    id: string;
    selectedProfileId: string;
    mergeProfileFilters: boolean;
  }>(),
);

export const ChangeSelectedProfileDuringSetup = createAction(
  '[ListingModel] Change Selected Profile During Setup',
  props<{
    id: string;
    selectedProfileId: string;
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const SelectedProfileDuringSetupChangedSuccess = createAction(
  '[ListingModel] Selected profile during setup changed success',
  props<{
    id: string;
    selectedProfileId: string;
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const SaveUserDefaultProfile = createAction(
  '[ListingModel] Save user default profile',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const SaveAsDefaultAllProfile = createAction(
  '[ListingModel] Save as default all profile',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const SaveAsDefaultAllProfileSuccess = createAction(
  '[ListingModel] Save as default all profile success',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const SaveProfile = createAction(
  '[ListingModel] Save profile',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const SaveProfileSuccess = createAction(
  '[ListingModel] Save profile success',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const SaveProfileError = createAction(
  '[ListingModel] Save profile error',
  props<{id: string; error: any}>(),
);

export const DeleteProfile = createAction(
  '[ListingModel] Delete profile',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const DeleteProfileSuccess = createAction(
  '[ListingModel] Delete profile success',
  props<{
    payload: {
      id: string;
      profile: ListingProfile;
      type: TableType;
      reloadData: boolean;
    };
  }>(),
);

export const DeleteProfileError = createAction(
  '[ListingModel] Delete profile error',
  props<{id: string; error: any}>(),
);

export const ClearProfile = createAction(
  '[ListingModel] Clear profile',
  props<{id: string}>(),
);

export const ClearProfileSuccess = createAction(
  '[ListingModel] Clear profile success',
  props<{id: string}>(),
);

export const ClearProfileError = createAction(
  '[ListingModel] Clear profile error',
  props<{id: string}>(),
);

export const RowIdFieldChanged = createAction(
  '[ListingModel] Selected row id field changed',
  props<{id: string; rowIdField: string}>(),
);

export const SelectedRowIdsChanged = createAction(
  '[ListingModel] Selected row ids changed',
  props<{id: string; selectedRowIds: TableKeyType[]}>(),
);

export const SelectedRowIdChanged = createAction(
  '[ListingModel] Selected row id changed',
  props<{id: string; selectedRowId: TableKeyType}>(),
);

export const AllCheckboxChanged = createAction(
  '[ListingModel] All checkbox changed',
  props<{id: string; checked: boolean}>(),
);

export const ShowBulkEditChanged = createAction(
  '[ListingModel] Show bulk edit changed',
  props<{id: string; checked: boolean}>(),
);

export const HasBulkButtonsTemplateChanged = createAction(
  '[ListingModel] Has bulk buttons template changed',
  props<{id: string; checked: boolean}>(),
);

export const ScrollHeight = createAction(
  '[ListingModel] Change scroll height',
  props<{id: string; height: string}>(),
);

export const ScrollWidth = createAction(
  '[ListingModel] Change scroll width',
  props<{id: string; width: string}>(),
);

export const ExportingData = createAction(
  '[ListingModel] Exporting data',
  props<{id: string; value: boolean}>(),
);

export const UpdateRowData = createAction(
  '[ListingModel] Update row data',
  props<{id: string; item: {rowData: any; rowIndex: number}}>(),
);

export const InitTable = createAction(
  '[ListingModel] Init Table',
  props<{
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const SetupProfileUrlFromExistingMemory = createAction(
  '[ListingModel] Setup Profile Url From Existing Memory',
  props<{
    payload: {
      id: string;
      type: TableType;
      defaults?: TableDefault;
      showConfig?: TableShowConfig;
    };
  }>(),
);

export const SetUpTableSettings = createAction(
  '[ListingModel] Set Up table settings',
  props<{id: string; defaults?: TableDefault}>(),
);

export const ClearTableStateByListingType = createAction(
  '[Listing] Clear table state By Listing Type',
  props<{listingType: string}>(),
);

/**
 * SEKCE PRO TREE
 */

export const SetTree = createAction(
  '[ListingModel] Set Tree',
  props<{id: string; tree: TreeTable}>(),
);

export const TreeNodeExpandAll = createAction(
  '[ListingModel] TreeNode expand all',
  props<{id: string; selectedRowId?: string}>(),
);

export const TreeNodeExpandAllFinished = createAction(
  '[ListingModel] TreeNode expand all finished',
  props<{id: string}>(),
);

export const TreeNodeExpandAllBackend = createAction(
  '[ListingModel] TreeNode expand all backend',
  props<{id: string; nodeData: NodeData}>(),
);

export const TreeNodeExpandCollapse = createAction(
  '[ListingModel] TreeNode expand or collapse',
  props<{
    id: string;
    nodeData: NodeData;
    expandAll?: boolean;
    selectedRowId?: string;
  }>(),
);

export const TreeNodeExpandCollapseFinished = createAction(
  '[ListingModel] TreeNode expand or collapse finished',
  props<{id: string; expandAll?: boolean; selectedRowId?: string}>(),
);

export const DataViewChangeMode = createAction(
  '[ListingModel] Dataview change mode',
  props<{id: string; changeMode: DataViewModeEnum}>(),
);

export const DataViewChangeModeWithoutRefresh = createAction(
  '[ListingModel] Dataview change mode without refresh',
  props<{id: string; changeMode: DataViewModeEnum}>(),
);

export const RemoveListing = createAction(
  '[ListingModel] Remove listing',
  props<{id: string}>(),
);

/**
 * KANBAN
 */
export const AddKanbanList = createAction(
  '[ListingModel] Add kanban list',
  props<{id: string; kanban: TableKanban}>(),
);

export const ChangeKanbanList = createAction(
  '[ListingModel] Change kanban list',
  props<{id: string; kanban: TableKanban}>(),
);

export const RemoveKanbanList = createAction(
  '[ListingModel] Remove kanban list',
  props<{id: string; listId: string}>(),
);

export const ChangeFiltersKanbanList = createAction(
  '[ListingModel] Change filters kanban list',
  props<{id: string; kanban: TableKanban}>(),
);

export const LoadDataKanbanList = createAction(
  '[ListingModel] Load data kanban list',
  props<{id: string; kanban: TableKanban}>(),
);

export const ChangeOrderKanbanLists = createAction(
  '[ListingModel] Change order kanban lists',
  props<{id: string; kanbans: TableKanban[]}>(),
);

export const UrlChanged = createAction(
  '[ListingModel] Url Changed',
  props<{id: string; url: string}>(),
);
