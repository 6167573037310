import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LoadKafkaTopic,
  LoadKafkaTopicError,
  LoadKafkaTopicSuccess,
} from '../actions';
import {map, switchMap} from 'rxjs/operators';
import {KafkaTopicService} from '../services';
import {withLatestCached} from '@tsm/framework/root';
import {Store} from '@ngrx/store';
import {getAllKafkaTopics} from '../selectors';
import {of} from 'rxjs';

@Injectable()
export class KafkaTopicEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadKafkaTopic),
      withLatestCached(() => this.store.select(getAllKafkaTopics())),
      switchMap(([action, cache]) => {
        return cache?.kafkaTopics.length > 0
          ? of(LoadKafkaTopicSuccess({topics: cache?.kafkaTopics}))
          : this.kafkaTopicService.getAllKafkaTopic().pipe(
              map((att) =>
                att.success
                  ? LoadKafkaTopicSuccess({
                      topics: att.data.map((t) => ({
                        key: t,
                        selected: false,
                      })),
                    })
                  : LoadKafkaTopicError({error: att.error}),
              ),
            );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private kafkaTopicService: KafkaTopicService,
  ) {}
}
