export const cs = {
  changeManagementPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmChangeManagementFilter: 'tSM Change Management Filter',
      tsmChangeManagementFilterDescription:
        'Umožňuje filtrování změnového řízení. ' +
        'Podporuje vícenásobný výběr a načítá odpovídající ' +
        'záznamy změnového řízení na základě vstupu uživatele.',
      tsmImpactOnCustomerFilter: 'tSM Impact On Customer Filter',
      tsmImpactOnCustomerFilterDescription:
        'Filtruje hodnoty dopadu na zákazníka. ' +
        'Používá výběrový seznam pro uživatelský vstup ' +
        'a aktualizuje filtr podle vybrané hodnoty.',
      tsmOperationImpactFilter: 'tSM Operation Impact Filter',
      tsmOperationImpactFilterDescription:
        'Filtruje hodnoty dopadu na provoz. ' +
        'Načítá data z externího zdroje a ' +
        'umožňuje vícenásobný výběr pro filtrování.',
      tsmTechnologyFilter: 'tSM Technology Filter',
      tsmTechnologyFilterDescription:
        'Filtruje technologické hodnoty pro řízení změn. ' +
        'Načítá data z externího zdroje a ' +
        'umožňuje vícenásobný výběr pro filtrování.',
      tsmWorklogLocalityUserGroupsFilter:
        'tSM Worklog Locality User Groups Filter',
      tsmWorklogLocalityUserGroupsFilterDescription:
        'Filtruje uživatelské skupiny pro výběr lokality pracovního záznamu. ' +
        'Umožňuje vícenásobný výběr a zahrnuje možnosti ' +
        'přiřazených a nepřiřazených skupin.',
    },
    pipes: {
      changeRequestLink: 'Change Request Link',
      changeRequestLinkDescription:
        'Generuje hypertextový odkaz směřující na stránku změnové žádosti. Vytváří navigační odkaz s klíčem ' +
        'tiketu jako viditelným textem a URL adresou změnové žádosti na základě ID tiketu. Vrací prosté ID ' +
        'tiketu, pokud nejsou dostupná požadovaná data.',
      changeRequestLoader: 'Change Request Loader',
      changeRequestLoaderDescription:
        'Načítá data změnové žádosti podle jejího ID. Umožňuje získat vnořené vlastnosti pomocí polí oddělených ' +
        'tečkami. Volitelně aplikuje formátování datumu, pokud je poskytnut dateTransform. Pokud požadovaná data ' +
        'nejsou dostupná, vrací zadané ID.',
      changeRequestOperationImpactByCode:
        'Change Request Operation Impact By Code',
      changeRequestOperationImpactByCodeDescription:
        'Načítá podrobnosti o provozním dopadu pro změnovou žádost pomocí kódu. Umožňuje získat konkrétní ' +
        'atributy nebo celý datový soubor. Využívá aktivní jazykové nastavení v Transloco k aplikaci lokalizace ' +
        'pro pole. Pokud data nejsou dostupná, vrací zadaný kód jako výchozí hodnotu.',
      changeRequestOperationImpact: 'Change Request Operation Impact',
      changeRequestOperationImpactDescription:
        'Načítá podrobnosti o provozním dopadu pro změnovou žádost na základě ID. Umožňuje získat konkrétní ' +
        'atributy nebo celý datový soubor. Využívá aktivní jazykové nastavení z Transloco pro lokalizaci polí. ' +
        'Pokud požadovaná data nejsou dostupná, vrací zadané ID jako výchozí hodnotu.',
      changeRequestSchedule: 'Change Request Schedule',
      changeRequestScheduleDescription:
        'Vytváří odkaz na stránku harmonogramu konkrétní změnové žádosti pomocí jejího ID. Získává podrobnosti ' +
        'o ticketu z úložiště pro sestavení odkazu. Pokud nejsou data ticketu dostupná, vrací zadané ID jako ' +
        'výchozí hodnotu.',
      changeRequestTechnologyCode: 'Change Request Technology Code',
      changeRequestTechnologyCodeDescription:
        'Načítá a zobrazuje podrobnosti o technologii spojené s konkrétním kódem. Získává data z úložiště, ' +
        'umožňuje uživateli vybrat konkrétní pole (např. "name"). Pokud požadovaná data nejsou dostupná, ' +
        'vysílá akci pro jejich načtení a použije zadaný kód jako výchozí hodnotu.',
      changeRequestTechnology: 'Change Request Technology',
      changeRequestTechnologyDescription:
        'Načítá a zobrazuje podrobnosti o technologii pomocí konkrétního ID. Přistupuje k datům z úložiště a ' +
        'nabízí možnost vybrat konkrétní pole, například "name". Pokud požadovaná data nejsou dostupná, ' +
        'vysílá akci pro jejich načtení a použije zadané ID jako výchozí hodnotu.',
      relatedEntitiesToPlaces: 'Related Entities To Places',
      relatedEntitiesToPlacesDescription:
        'Převádí související entity na seznam míst s jejich adresami. Filtruje a zpracovává jedinečné ID míst ' +
        'z přidružených entit, načítá podrobné údaje o adresách pomocí volání API a mapuje výsledky do strukturovaného ' +
        'seznamu obsahujícího ID míst, reference a adresy.',
      scheduleTypeLoader: 'Schedule Type Loader',
      scheduleTypeLoaderDescription:
        'Načte podrobnosti o typu plánu podle ID z úložiště s podporou extrakce vnořených polí a volitelného ' +
        'formátování datumu. Získá a naformátuje specifické vlastnosti typu plánu, přičemž výchozím polem je název. ' +
        'Pokud nejsou data k dispozici, vrátí poskytnuté ID.',
      scheduleTypeCodeLoader: 'Schedule Type Loader Code',
      scheduleTypeLoaderCodeDescription:
        'Načte podrobnosti o typu plánu podle kódu z úložiště s podporou extrakce vnořených polí a volitelného ' +
        'formátování datumu. Získá a naformátuje specifické vlastnosti typu plánu, přičemž výchozím polem je název. ' +
        'Pokud nejsou data k dispozici, vrátí poskytnutý kód.',
      worklogLocalityUserGroups: 'Worklog Locality User Groups',
      worklogLocalityUserGroupsDescription:
        'Načítá a zpracovává uživatelské skupiny spojené s konkrétním uživatelským ID. Odesílá akce pro načtení ' +
        'uživatelských skupin a zpracovává jejich data k získání specifických polí nebo všech dat. Extrahuje ' +
        'a formátuje názvy uživatelských skupin do zřetězeného textu, přičemž se zajišťuje správné zacházení ' +
        's chybami a načítáním.',
    },
  },
};
