import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';
import {FrameworkPluginModule} from '@tsm/framework/plugin';
import {AccessRulesDataSource} from './datasources';

export const PIPES = [];
export const IMPORTS = [
  TranslateModule.forFeature(localizations),
  FrameworkPluginModule.registerPlugin({
    menus: [],
    routes: [],
    pipes: [],
    filters: [],
    widgets: [],
    entityTypes: [],
    dataSources: [
      {
        dataSource: AccessRulesDataSource,
        name: 'accessRules',
        initialValue: null,
      },
    ],
  }),
];
export const PROVIDERS = [];
