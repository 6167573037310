import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {Action, ActionReducer, MetaReducer} from '@ngrx/store';
import {addSeconds, isBefore} from 'date-fns';

export const hmrMetaReducer: MetaReducer<any, Action>[] = [stateSetter];

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      console.log(action.payload);
      return action.payload;
    }
    return reducer(state, action);
  };
}

/**
 * Funknce sdili obserable na zaklade vstupnich parametru funkce (hodnoty cache se smazou az po F5).
 * Pripadne lze pouzit funkci SharedRequestValidUntil
 */
export function SharedRequest(
  target: Object,
  name: string,
  desc: PropertyDescriptor,
) {
  const cacheMap = new Map<string, any>();
  const origos = desc.value;
  desc.value = function (this: any, ...args: any[]): any {
    let key = origos.name + '(' + args.join('::') + ')';
    if (args.length > 1) {
      key =
        origos.name +
        '(' +
        args[0] +
        '::' +
        args.map((x) => JSON.stringify(x)).join('||') +
        ')';
    }

    const foundValue = cacheMap.get(key);
    if (foundValue !== undefined) {
      return foundValue;
    }

    let originalReturn = origos.apply(this, args);
    if (originalReturn instanceof Observable) {
      originalReturn = originalReturn.pipe(shareReplay());
    }
    cacheMap.set(key, originalReturn);
    return originalReturn;
  };
  return desc;
}

/**
 * Funknce sdili obserable na zaklade vstupnich parametru funkce
 * @param seconds - default cache je 5 sekund
 */
export function SharedRequestValidUntil(seconds = 5) {
  return function sharedRequestValidUntil(
    target: Object,
    name: string,
    desc: PropertyDescriptor,
  ) {
    const cacheMap = new Map<string, {validUntil: Date; value: any}>();
    const origos = desc.value;
    desc.value = function (this: any, ...args: any[]): any {
      let key = origos.name + '(' + args.join('::') + ')';
      if (args.length > 1) {
        key =
          origos.name +
          '(' +
          args[0] +
          '::' +
          args.map((x) => JSON.stringify(x)).join('||') +
          ')';
      }

      const foundValue = cacheMap.get(key);
      if (
        foundValue !== undefined &&
        isBefore(new Date(), new Date(foundValue.validUntil))
      ) {
        return foundValue.value;
      }

      let originalReturn = origos.apply(this, args);
      if (originalReturn instanceof Observable) {
        originalReturn = originalReturn.pipe(shareReplay());
      }
      cacheMap.set(key, {
        validUntil: addSeconds(new Date(), seconds),
        value: originalReturn,
      });
      return originalReturn;
    };
    return desc;
  };
}

export function banValid(accountNumber: string): boolean {
  if (!accountNumber || accountNumber.length === 0) {
    return true;
  }
  const tmpAccountNumbers = accountNumber.split('-');

  if (
    tmpAccountNumbers[1]
      .split('')
      .map(Number)
      .every((n) => n === 0)
  ) {
    return false;
  }
  const tmpAccountNumber: any[] = [
    ...tmpAccountNumbers[0].split(''),
    ...tmpAccountNumbers[1].split(''),
  ];
  let result = 0;
  let valueIndex = 0;
  for (let i = tmpAccountNumber.length - 1; i >= 0; i--) {
    switch (valueIndex) {
      case 0:
        result = tmpAccountNumber[i] * 1;
        break;
      case 1:
        result = result + tmpAccountNumber[i] * 2;
        break;
      case 2:
        result = result + tmpAccountNumber[i] * 4;
        break;
      case 3:
        result = result + tmpAccountNumber[i] * 8;
        break;
      case 4:
        result = result + tmpAccountNumber[i] * 5;
        break;
      case 5:
        result = result + tmpAccountNumber[i] * 10;
        break;
      case 6:
        result = result + tmpAccountNumber[i] * 9;
        break;
      case 7:
        result = result + tmpAccountNumber[i] * 7;
        break;
      case 8:
        result = result + tmpAccountNumber[i] * 3;
        break;
      case 9:
        result = result + tmpAccountNumber[i] * 6;
        break;
      case 10:
        result = result + tmpAccountNumber[i] * 1;
        break;
      case 11:
        result = result + tmpAccountNumber[i] * 2;
        break;
      case 12:
        result = result + tmpAccountNumber[i] * 4;
        break;
      case 13:
        result = result + tmpAccountNumber[i] * 8;
        break;
      case 14:
        result = result + tmpAccountNumber[i] * 5;
        break;
      case 15:
        result = result + tmpAccountNumber[i] * 10;
        break;
    }
    valueIndex = valueIndex + 1;
  }
  return result % 11 === 0;
}
