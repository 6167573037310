import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {TsmUiConfigModel} from '../models';

export const LoadUiConfigAction = createAction('[Layout] Load ui config');

export const LoadUiConfigActionSuccess = createAction(
  '[Layout] Load ui config Success',
  props<{uiConfig: TsmUiConfigModel}>(),
);

export const LoadUiConfigActionError = createAction(
  '[Layout] Load ui config Action',
  props<{error: TsmError}>(),
);
