import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {TableColumnConfig} from '../models';
import {
    ClearTableStateByListingType,
    DeleteTableColumnConfig,
    DeleteTableColumnConfigError,
    DeleteTableColumnConfigSuccess,
    DiffTableColumnConfig,
    DiffTableColumnConfigError,
    DiffTableColumnConfigSuccess,
    LoadTableColumnConfig,
    LoadTableColumnConfigById,
    LoadTableColumnConfigByIdError,
    LoadTableColumnConfigByIdSuccess,
    LoadTableColumnConfigError,
    LoadTableColumnConfigsByListingId,
    LoadTableColumnConfigsByListingIdError,
    LoadTableColumnConfigsByListingIdSuccess,
    LoadTableColumnConfigsByListingType,
    LoadTableColumnConfigsByListingTypeError,
    LoadTableColumnConfigsByListingTypes,
    LoadTableColumnConfigsByListingTypesError,
    LoadTableColumnConfigsByListingTypesSuccess,
    LoadTableColumnConfigsByListingTypeSuccess,
    LoadTableColumnConfigSuccess,
    PatchTableColumnConfig,
    PatchTableColumnConfigError,
    PatchTableColumnConfigSuccess,
    RefreshDataAndClearSelected,
    UpsertTableColumnConfig,
    UpsertTableColumnConfigError,
    UpsertTableColumnConfigSuccess,
} from '../actions';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {CommonConfigFormApiService} from '../services';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {SharedRequestValidUntil} from '@tsm/framework/root';

@Injectable()
export class TableColumnConfigEffects {
    translationShared = translationShared;

    private readonly API_PATH = 'listing-column';

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfigById),
      concatMap(({id}) =>
        this.commonApiService.getEntity(this.API_PATH, id).pipe(
          map((env) =>
            env.success
              ? LoadTableColumnConfigByIdSuccess({
                  tableColumnConfig: env.data,
                })
              : LoadTableColumnConfigByIdError({id: id, error: env.error}),
          ),
        ),
      ),
    ),
  );

  loadByListingId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfigsByListingId),
      concatMap(({loadType, payload}) =>
        this.getColumnConfigsByListingId(payload.type.type).pipe(
          map((env) =>
            env.success
              ? LoadTableColumnConfigsByListingIdSuccess({
                  tableColumnConfigs: env.data,
                  payload: payload,
                  loadType: loadType,
                })
              : LoadTableColumnConfigsByListingIdError({
                  error: env.error,
                  loadType: loadType,
                  payload: payload,
                }),
          ),
        ),
      ),
    ),
  );

  loadByListingTypeCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfigsByListingType),
      concatMap(({listingTypeCode}) =>
        this.getColumnConfigsByListingId(listingTypeCode).pipe(
          map((env) =>
            env.success
              ? LoadTableColumnConfigsByListingTypeSuccess({
                  tableColumnConfigs: env.data,
                })
              : LoadTableColumnConfigsByListingTypeError({error: env.error}),
          ),
        ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertTableColumnConfig),
      concatMap(({tableColumnConfig, listingId}) =>
        this.commonApiService
          .upsertEntity(
            this.API_PATH,
            tableColumnConfig,
            tableColumnConfig.id,
          )
          .pipe(
            tap((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.listingLib.effects.columnSaveSuccesMsg,
                  ToastSeverity.SUCCESS,
                  3000,
                );
              } else {
                this.toastService.showError(
                  env.error,
                  translation.listingLib.effects.columnSaveErrorMsg,
                );
              }
            }),
            map((env) =>
              env.success
                ? UpsertTableColumnConfigSuccess({
                    tableColumnConfig: env.data,
                    listingId: listingId,
                  })
                : UpsertTableColumnConfigError({
                    id: tableColumnConfig.id,
                    error: env.error,
                  }),
            ),
          ),
      ),
    ),
  );


    patch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PatchTableColumnConfig),
            concatMap(({
                           tableColumnConfig,
                           listingId
                       }) => this.commonApiService.patchEntity(this.API_PATH, tableColumnConfig.id, tableColumnConfig, true).pipe(
                tap(env => {
                    if (env.success) {
                        this.toastService.showToast(translation.listingLib.effects.columnSaveSuccesMsg, ToastSeverity.SUCCESS, 3000);
                    } else {
                        this.toastService.showError(env.error, translation.listingLib.effects.columnSaveErrorMsg);
                    }
                }),
                map(env => env.success
                    ? PatchTableColumnConfigSuccess({tableColumnConfig: env.data, listingId: listingId})
                    : PatchTableColumnConfigError({id: tableColumnConfig.id, error: env.error})
                )
            ))
        ));  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTableColumnConfig),
      concatMap(({id, listingId, tableColumnListingId}) =>
        this.commonApiService.deleteEntity(this.API_PATH, id).pipe(
          map((env) =>
            env.success
              ? DeleteTableColumnConfigSuccess({
                  id: id,
                  listingId: listingId,
                  tableColumnListingId: tableColumnListingId,
                })
              : DeleteTableColumnConfigError(env.error),
          ),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffTableColumnConfig),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(`v1/${this.API_PATH}s`, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffTableColumnConfigSuccess({
                  tableColumnConfigs: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffTableColumnConfigError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

    refreshAfterDiff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiffTableColumnConfigSuccess),
            map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

    refreshDataAfterDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteTableColumnConfigSuccess),
            concatMap(({listingId, tableColumnListingId}) => [
                RefreshDataAndClearSelected({id: listingId}),
                ClearTableStateByListingType({listingType: tableColumnListingId}),
            ]),
        ),
    );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertTableColumnConfigSuccess),
      concatMap(({listingId, tableColumnConfig}) => [
        RefreshDataAndClearSelected({id: listingId}),
        ClearTableStateByListingType({
          listingType: tableColumnConfig.codeListing,
        }),
      ]),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfig),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadTableColumnConfigSuccess({tableColumnConfigs: env.data})
                : LoadTableColumnConfigError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfigsByListingTypes),
      concatMap(({listingTypeCodes}) =>
        this.commonApiService
          .getEntitiesByCodesSharedRequest(
            this.API_PATH + '/listingCode',
            listingTypeCodes,
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadTableColumnConfigsByListingTypesSuccess({
                    tableColumnConfigs: env.data,
                  })
                : LoadTableColumnConfigsByListingTypesError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonConfigFormApiService<
      TableColumnConfig,
      TableColumnConfig
    >,
    private toastService: ToastService,
    ) {}

    @SharedRequestValidUntil(300)
    private getColumnConfigsByListingId(type: string) {
        return this.commonApiService.getItemsById(
            'listingCode',
      type,
      this.API_PATH,
        );
    }
}
