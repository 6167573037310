import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ConfigFormState, microserviceAdapter} from '../reducers';
import {RouterStateUrl} from '@tsm/framework/root';

const getMicroserviceState =
  createFeatureSelector<ConfigFormState>('configForm');
const pathToEntities = createSelector(
  getMicroserviceState,
  (x) => x.microservice,
);
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const {selectAll, selectEntities} =
  microserviceAdapter.getSelectors(pathToEntities);

export const selectMicroserviceById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectMicroserviceByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectMicroserviceByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectMicroserviceByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data && x.data.code === code),
  );

export const selectMicroserviceByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x?.data?.code)),
  );

export const selectAllMicroservice = createSelector(selectAll, (entities) =>
  entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);

export const selectValidMicroservices = createSelector(selectAll, (entities) =>
  entities
    .filter((entity) => entity && entity.data && entity.data.valid != 'false')
    .map((pack) => pack.data),
);
