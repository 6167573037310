import {Inject, Injectable} from '@angular/core';
import {
  defaultPolicy,
  Policy,
  SecurityConfiguration,
  SecurityModuleOptions,
} from '../models';
import {JwtService} from './jwt.service';
import {SECURITY_MODULE_OPTIONS} from '../di';
import {distinctArrays} from '@tsm/framework/functions';
import {filter, map, Observable, of, take} from 'rxjs';
import {Store} from '@ngrx/store';
import {getSecurityRecords} from '../selectors';

@Injectable()
export class SecurityService {
  private configuration: SecurityConfiguration = {};
  private CLAIMS_KEY = 'CLAIMS';

  constructor(
    @Inject(SECURITY_MODULE_OPTIONS)
    private moduleOptions: SecurityModuleOptions,
    private jwtService: JwtService,
    private store$: Store,
  ) {}

  addConfigurations(configuration: SecurityConfiguration) {
    Object.entries(configuration).forEach(([key, value]) => {
      const foundPolicy = this.configuration[key];

      if (foundPolicy !== undefined) {
        if (this.moduleOptions.whenDuplicit === 'warning') {
          console.warn('Your security policy ' + key + 'is duplicit!');
        }

        if (this.moduleOptions.whenDuplicit === 'error') {
          throw new Error('Your security policy ' + key + 'is duplicit!');
        }
      }

      if (
        foundPolicy === undefined ||
        this.moduleOptions.policyConflictResolution === 'replace'
      ) {
        this.configuration = {...configuration, [key]: value};
      }
    });
  }

  getPolicyByName(policyName: string): Policy {
    return this.configuration[policyName];
  }

  hasAccess$(
    policyName: string | string[],
    params: any[],
    and: boolean = null,
    or: boolean = null,
  ): Observable<boolean> {
    const tokenString = this.moduleOptions.tokenGetter();
    if (tokenString == null) {
      return of(false);
    }
    const claims = this.getClaims();
    const newAuthorities = distinctArrays(
      null,
      claims,
      this.moduleOptions.overridePrivileges,
    );
    const policy = !Array.isArray(policyName)
      ? this.getPolicyByName(policyName)
      : null;
    const securityData$ = this.store$.select(getSecurityRecords);

    if (policy == null) {
      // FIXME domyslet s Bubakem proc zrovna tento parametr je privilege, muze byt cokoli
      const desiredAuthorities = Array.isArray(policyName)
        ? policyName
        : [policyName];

      const result =
        and != null
          ? defaultPolicy(newAuthorities, desiredAuthorities) && and
          : or != null
            ? defaultPolicy(newAuthorities, desiredAuthorities) || or
            : defaultPolicy(newAuthorities, desiredAuthorities);
      return of(result);
    }

    return securityData$.pipe(
      filter((x) => x != null),
      map((x) => {
        return and != null
          ? policy(newAuthorities, params, x) && and
          : or != null
            ? policy(newAuthorities, params, x) || or
            : policy(newAuthorities, params, x);
      }),
    );
  }

  hasAccess(
    policyName: string | string[],
    params: any[],
    and: boolean = null,
    or: boolean = null,
  ): boolean {
    const tokenString = this.moduleOptions.tokenGetter();
    if (tokenString == null) {
      return false;
    }
    const claims = this.getClaims();
    const newAuthorities = distinctArrays(
      null,
      claims,
      this.moduleOptions.overridePrivileges,
    );
    const policy = !Array.isArray(policyName)
      ? this.getPolicyByName(policyName)
      : null;
    let securityData = {};
    this.store$
      .select(getSecurityRecords)
      .pipe(take(1))
      .subscribe((securityDataRes) => (securityData = securityDataRes));

    if (policy == null) {
      // FIXME domyslet s Bubakem proc zrovna tento parametr je privilege, muze byt cokoli
      const desiredAuthorities = Array.isArray(policyName)
        ? policyName
        : [policyName];

      const result =
        and != null
          ? defaultPolicy(newAuthorities, desiredAuthorities) && and
          : or != null
            ? defaultPolicy(newAuthorities, desiredAuthorities) || or
            : defaultPolicy(newAuthorities, desiredAuthorities);
      return result;
    }

    return and != null
      ? policy(newAuthorities, params, securityData) && and
      : or != null
        ? policy(newAuthorities, params, securityData) || or
        : policy(newAuthorities, params, securityData);
  }

  getClaimValue<T>(claimName: string): T {
    const tokenString = this.moduleOptions.tokenGetter();
    if (tokenString == null) {
      return null;
    }
    const claims = this.getClaims();
    const found = claims.find((x) => x[claimName] != null);
    if (found == null) {
      return null;
    }
    return found[claimName] as unknown as T;
  }

  setClaims(claims: string[]) {
    localStorage.setItem(this.CLAIMS_KEY, JSON.stringify(claims));
  }

  removeClaims() {
    localStorage.removeItem(this.CLAIMS_KEY);
  }

  getClaims() {
    return JSON.parse(localStorage.getItem(this.CLAIMS_KEY));
  }

  getPolicies() {
    return this.configuration;
  }
}
