import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'dtl-grid',
  template: ` <ng-content></ng-content>`,
})
export class GridComponent {
  @HostBinding('class')
  get styleClass(): string {
    return 'grid' + ' ' + this.class;
  }

  @Input() class = '';
}
