export const cs = {
  stockPlugins: {
    material: 'Materiál',
    stockItemViews: 'Položky',
    stockSharedResponsibilities: 'Sdílené zodpovědnosti',
    filters: {
      tsmStockFilter: 'tSM Stock Filter',
      tsmStockFilterDescription:
        'Implementuje filtr zásob s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru seznamu tabulek, umožňuje uživatelům ' +
        'filtrovat zásoby načtené z API. Poskytuje možnosti výběru, odstranění ' +
        'a potvrzení, s využitím překryvného panelu pro interakci.',
      tsmStockItemFilter: 'tSM Stock Item Filter',
      tsmStockItemFilterDescription:
        'Implementuje filtr položek skladu s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat skladové položky načtené z API. Poskytuje možnosti výběru, ' +
        'odstranění a potvrzení, s využitím překryvného panelu pro interakci. ' +
        'Zahrnuje filtrování na základě specifikací katalogu entit.',
      tsmStockItemStatusFilter: 'tSM Stock Item Status Filter',
      tsmStockItemStatusFilterDescription:
        'Implementuje filtr stavu skladových položek s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat stavy skladových položek načtené z API. Poskytuje možnosti výběru, ' +
        'odstranění a potvrzení, s využitím překryvného panelu pro interakci.',
      tsmStockItemTypeFilter: 'tSM Stock Item Type Filter',
      tsmStockItemTypeFilterDescription:
        'Implementuje filtr typu skladové položky s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat typy skladových položek načtené z API. Poskytuje možnosti výběru, ' +
        'odstranění a potvrzení, s využitím překryvného panelu pro interakci.',
      tsmStockMovementStatusFilter: 'tSM Stock Movement Status Filter',
      tsmStockMovementStatusFilterDescription:
        'Implementuje filtr stavu pohybu zásob s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat stavy pohybu zásob načtené z předdefinovaného seznamu. ' +
        'Poskytuje možnosti výběru, odstranění a potvrzení prostřednictvím ' +
        'překryvného panelu.',
      tsmStockMovementTypeFilter: 'tSM Stock Movement Type Filter',
      tsmStockMovementTypeFilterDescription:
        'Implementuje filtr typu pohybu zásob s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat typy pohybu zásob načtené ze stránkovaného seznamu. ' +
        'Poskytuje možnosti vyhledávání, výběru, odstranění a potvrzení ' +
        'prostřednictvím překryvného panelu.',
      tsmStockStatusFilter: 'tSM Stock Status Filter',
      tsmStockStatusFilterDescription:
        'Implementuje filtr stavu zásob s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat stavy zásob načítané dynamicky. Zobrazuje dostupné stavy ' +
        'v tabulce s možností vyhledávání a výběru. Poskytuje možnosti ' +
        'potvrzení nebo zrušení výběru prostřednictvím překryvného panelu.',
      tsmStockTypeFilter: 'tSM Stock Type Filter',
      tsmStockTypeFilterDescription:
        'Implementuje filtr typu zásob s podporou vícenásobného výběru. ' +
        'Rozšiřuje nadřazenou komponentu filtru tabulky, umožňuje uživatelům ' +
        'filtrovat typy zásob dynamicky načítané z API. Zobrazuje dostupné ' +
        'typy v tabulce s funkcí vyhledávání. Poskytuje možnosti potvrzení ' +
        'nebo zrušení výběru prostřednictvím překryvného panelu.',
    },
    pipes: {
      stockItemKey: 'Stock Item Key',
      stockItemKeyDescription:
        'Získává podrobnosti o skladové položce pomocí unikátního klíče a vrací konkrétní atribut ' +
        'nebo všechna data. Pokud položka není nalezena ve store, odesílá požadavek k jejímu načtení.',
      stockItemMovementPipe: 'Stock Item Movement Pipe',
      stockItemMovementPipeDescription:
        'Určuje směr pohybu skladové položky výběrem zdroje nebo cíle. ' +
        'Pokud žádný z nich není přítomen, vrací původní hodnotu.',
      stockItemSn: 'Stock Item Sn',
      stockItemSnDescription:
        'Načítá detaily skladové položky podle sériového čísla. ' +
        'Pokud jsou dostupné, vrací zadané pole; jinak vrací ID.',
      stockItemTypeCode: 'Stock Item Type Code',
      stockItemTypeCodeDescription:
        'Získává detaily typu skladové položky podle kódu. ' +
        'Vrací zadané pole nebo všechna data, pokud je zadáno "all".',
      stockItemType: 'Stock Item Type',
      stockItemTypeDescription:
        'Získává detaily typu skladové položky podle ID. ' +
        'Vrací zadané pole nebo všechna data, pokud je zadáno "all".',
      stockItem: 'Stock Item',
      stockItemDescription:
        'Načítá detaily skladové položky podle ID. ' +
        'Vrací zadané pole nebo všechna data, pokud je zadáno "all".',
      stockKey: 'Stock Key',
      stockKeyDescription:
        'Načítá detaily skladu podle klíče. ' +
        'Vrací zadané pole nebo všechna data, pokud je zadáno "all".',
      stockMovementStatus: 'Stock Movement Status',
      stockMovementStatusDescription:
        'Formátuje stav pohybu skladu jako stylovaný štítek. ' +
        'Překládá stav na základě zadané hodnoty.',
      stockMovementTypeCode: 'Stock Movement Type Code',
      stockMovementTypeCodeDescription:
        'Načítá typ pohybu skladu podle kódu a vrací požadované pole. ' +
        'Ve výchozím nastavení vrací název, pokud není určeno jinak.',
      stockMovementType: 'Stock Movement Type',
      stockMovementTypeDescription:
        'Načítá typ pohybu skladu podle ID a vrací požadované pole. ' +
        'Ve výchozím nastavení vrací název, pokud není určeno jinak.',
      stockMovementPipe: 'Stock Movement Pipe',
      stockMovementPipeDescription:
        'Načítá podrobnosti pohybu skladu podle ID a vrací zadané pole. ' +
        'Ve výchozím nastavení vrací název, pokud není určeno jinak.',
      stockTaskLoader: 'Stock Task Loader',
      stockTaskLoaderDescription:
        'Načítá podrobnosti úlohy skladu podle ID úlohy a vrací zadané pole. ' +
        'Ve výchozím nastavení vrací klíč definice úlohy, pokud není určeno jinak.',
      stockTypeCode: 'Stock Type Code',
      stockTypeCodeDescription:
        'Načítá podrobnosti typu skladu pomocí kódu typu skladu. Vrací zadané ' +
        'pole nebo celý objekt dat, pokud není pole určeno.',
      stockType: 'Stock Type',
      stockTypeDescription:
        'Načítá podrobnosti typu skladu podle ID. Vrací zadané pole nebo celý ' +
        'objekt dat, pokud není pole určeno.',
      stock: 'Stock',
      stockDescription:
        'Načítá podrobnosti o skladu podle ID. Vrací zadané pole nebo název skladu, ' +
        'pokud není pole určeno.',
    },
  },
};
