<div class="color-picker-container">
  @if (readonly) {
    <div class="item-cover">
      <span [style.background-color]="val"> </span>
    </div>
  } @else {
    @for (item of colors; track item.color) {
      <div
        class="item-cover cursor-pointer"
        [class.selected]="val === item.color"
      >
        <span
          [style.background-color]="item.color"
          (click)="colorSelected(item, $event)"
        >
        </span>
      </div>
    }

    <span *ngIf="showColorLabel" class="italic make-block"
      >{{ translationShared.shared.selectedColor | transloco }}
      <span style="font-weight: bold">{{ val }}</span>
    </span>
  }
</div>
