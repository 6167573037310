import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {Sharing} from '../models';

@Injectable({
  providedIn: 'root',
})
export class SharingService {
  private readonly SHARING = `sharing`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getSharingByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<Sharing[]>> {
    return this.apiService.get<Sharing[], Sharing[]>(
      `${this.BASE_URL}/${this.SHARING}/${ownerType}/${ownerId}`,
    );
  }

  getSharingByOwnerIdsAndOwnerType(
    ownerIds: string[],
    ownerType: string,
  ): Observable<Envelope<Map<string, Sharing[]>>> {
    return this.apiService.post<Map<string, Sharing[]>, Map<string, Sharing[]>>(
      `${this.BASE_URL}/${this.SHARING}/${ownerType}/all`,
      ownerIds,
    );
  }

  deleteSharing(id: string): Observable<Envelope<Sharing>> {
    return this.apiService.delete<Sharing, Sharing>(
      `${this.BASE_URL}/${this.SHARING}/${id}`,
    );
  }

  userHasRights(
    ownerId: string,
    ownerType: string,
    refId: string,
  ): Observable<Envelope<boolean>> {
    return this.apiService.get<boolean, boolean>(
      `${this.BASE_URL}/${this.SHARING}/${ownerType}/${ownerId}/${refId}`,
    );
  }

  groupHasRights(
    ownerId: string,
    ownerType: string,
    refId: string,
  ): Observable<Envelope<boolean>> {
    return this.apiService.get<boolean, boolean>(
      `${this.BASE_URL}/${this.SHARING}/${ownerType}/${ownerId}/group/${refId}`,
    );
  }

  upsertSharing(share: Sharing): Observable<Envelope<Sharing>> {
    if (share.id) {
      return this.apiService.put<Sharing, Sharing>(
        `${this.BASE_URL}/${this.SHARING}/${share.id}`,
        share,
      );
    }
    return this.apiService.post<Sharing, Sharing>(
      `${this.BASE_URL}/${this.SHARING}`,
      share,
    );
  }
}
