import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {LoadCountAttachmentsSuccess} from '../actions';
import {CountAttachmentsModel} from '../models';

export type CountAttachmentsState = EntityState<CountAttachmentsModel>;

export const countAttachmentsAdapter: EntityAdapter<CountAttachmentsModel> =
  createEntityAdapter<CountAttachmentsModel>({});

const initialState: CountAttachmentsState =
  countAttachmentsAdapter.getInitialState({});

export const countAttachmentsReducer = createReducer(
  initialState,

  on(LoadCountAttachmentsSuccess, (state, {countAttachments}) => {
    const stateIds = Object.keys(state);
    const newIds = countAttachments.map((x) => x.id);

    const result = [];
    newIds
      .filter((x) => !stateIds.includes(x))
      .forEach((x) => {
        const found = countAttachments.find((y) => y.id === x);
        result.push({
          id: found.id,
          count: found.count,
        });
      });

    return {
      ...countAttachmentsAdapter.addMany(result, state),
    };
  }),
);
