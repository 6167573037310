import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Optional,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import { DisplayVariablePipe } from '@tsm/framework/root';

@Component({
    selector: 'dtl-variable',
    template: ` {{ val | displayVariable }} `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => VariableComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(VariableComponent),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DisplayVariablePipe],
})
export class VariableComponent extends ParentWidgetAccessorComponent {
  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }
}
