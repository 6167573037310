import {Component, HostBinding, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'tsm-form',
  template: `
    <form
      [formGroup]="formGroup"
      data-cy="form"
      [autocomplete]="autocomplete"
      [class]="styleClass"
    >
      <ng-content></ng-content>
    </form>
  `,
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  @HostBinding('class')
  get hostClass(): string {
    return this.class;
  }

  @Input() formGroup: FormGroup;

  @Input() class = '';

  @Input() styleClass = '';

  /**
   * Hromadne přepíná stylu a pozice zobrazení labelu
   */
  @Input() labelPosition: 'left' | 'top' | 'right' = 'top';

  @Input() autocomplete: string = 'on';
}
