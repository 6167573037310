import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {UserRole} from '../model';

export const LoadUserRolesByUserId = createAction(
  '[UserRole] Load UserRoles ByUserId',
  props<{userId: string}>(),
);
export const LoadUserRolesByUserIdSuccess = createAction(
  '[UserRole] Load UserRoles ByUserId Success',
  props<{
    entities: UserRole[];
  }>(),
);
export const LoadUserRolesByUserIdError = createAction(
  '[UserRole] Load UserRoles ByUserId Error',
  props<{
    error: any;
  }>(),
);

export const UpsertUserRole = createAction(
  '[UserRole] Upsert UserRole',
  props<{
    userRole: UserRole;
    listingId?: string;
  }>(),
);
export const UpsertUserRoleSuccess = createAction(
  '[UserRole] Upsert UserRole Success',
  props<{
    userRole: UserRole;
    listingId?: string;
  }>(),
);
export const UpsertUserRoleError = createAction(
  '[UserRole] Upsert UserRole Error',
  props<TsmError>(),
);

export const SaveAllUserRoles = createAction(
  '[UserRole] Save All UserRoles',
  props<{
    entities: UserRole[];
    listingId?: string;
  }>(),
);
export const SaveAllUserRolesSuccess = createAction(
  '[UserRole] Save All UserRoles Success',
  props<{
    entities: UserRole[];
    listingId?: string;
  }>(),
);
export const SaveAllUserRolesError = createAction(
  '[UserRole] Save All UserRoles Error',
  props<{error: any}>(),
);

export const DeleteUserRole = createAction(
  '[UserRole] Delete UserRole',
  props<{id: string; listingId?: string}>(),
);
export const DeleteUserRoleSuccess = createAction(
  '[UserRole] Delete UserRole Success',
  props<{
    id: string;
    listingId?: string;
  }>(),
);
export const DeleteUserRoleError = createAction(
  '[UserRole] Delete UserRole Error',
  props<TsmError>(),
);

export const DeleteAllUserRoles = createAction(
  '[UserRole] Delete All UserRoles',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllUserRolesSuccess = createAction(
  '[UserRole] Delete All UserRoles Success',
  props<{
    ids: string[];
    listingId?: string;
  }>(),
);
export const DeleteAllUserRolesError = createAction(
  '[UserRole] Delete All UserRoles Error',
  props<{error: any}>(),
);

export const LoadUserRolesByRoleId = createAction(
  '[UserRole] Load UserRoles ByRoleId',
  props<{roleId: string}>(),
);
export const LoadUserRolesByRoleIdSuccess = createAction(
  '[UserRole] Load UserRoles ByRoleId Success',
  props<{
    entities: UserRole[];
  }>(),
);
export const LoadUserRolesByRoleIdError = createAction(
  '[UserRole] Load UserRoles ByRoleId Error',
  props<{
    error: any;
  }>(),
);
