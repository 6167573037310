import {Injectable} from '@angular/core';
import {RxStomp, RxStompConfig} from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {
  constructor() {
    super();
  }
}

@Injectable()
export class InjectableRxStompConfig extends RxStompConfig {}

export function rxStompServiceFactory(
  rxStompConfig: InjectableRxStompConfig,
): RxStompService {
  const rxStompService = new RxStompService();

  rxStompService.configure(rxStompConfig);
  rxStompService.activate();

  return rxStompService;
}
