import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {Microservice} from '@tsm/characteristics/service';

export const LoadMicroservice = createAction(
  '[Microservice] Load Microservice',
);
export const LoadMicroserviceSuccess = createAction(
  '[Microservice] Load Microservice Success',
  props<{
    microservices: Microservice[];
  }>(),
);
export const LoadMicroserviceError = createAction(
  '[Microservice] Load Microservice Error',
  props<TsmError>(),
);

export const LoadMicroserviceById = createAction(
  '[Microservice] Load LoadMicroserviceById',
  props<{id: string}>(),
);
export const LoadMicroserviceByIdSuccess = createAction(
  '[Microservice] Load LoadMicroserviceById Success',
  props<{
    microservice: Microservice;
  }>(),
);
export const LoadMicroserviceByIdError = createAction(
  '[Microservice] Load LoadMicroserviceById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadMicroserviceByCode = createAction(
  '[Microservice] Load MicroserviceByCode',
  props<{code: string}>(),
);
export const LoadMicroserviceByCodeSuccess = createAction(
  '[Microservice] Load MicroserviceByCode Success',
  props<{
    microservice: Microservice;
  }>(),
);
export const LoadMicroserviceByCodeError = createAction(
  '[Microservice] Load MicroserviceByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertMicroservice = createAction(
  '[Microservice] Upsert Microservice',
  props<{
    microservice: Microservice;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertMicroserviceSuccess = createAction(
  '[Microservice] Upsert Microservice Success',
  props<{
    microservice: Microservice;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertMicroserviceError = createAction(
  '[Microservice] Upsert Microservice Error',
  props<TsmError>(),
);

export const DeleteMicroservice = createAction(
  '[Microservice] Delete Microservice',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteMicroserviceSuccess = createAction(
  '[Microservice] Delete Microservice Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteMicroserviceError = createAction(
  '[Microservice] Delete Microservice Error',
  props<TsmError>(),
);

export const DiffMicroservice = createAction(
  '[Microservice] Diff Microservice',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffMicroserviceSuccess = createAction(
  '[Microservice] Diff Microservice Success',
  props<{
    microservices: Microservice[];
    listingId: string;
  }>(),
);
export const DiffMicroserviceError = createAction(
  '[Microservice] Diff Microservice Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
