export const en = {
  ticketsPlugins: {
    ticketsSubmitHelp: `
        Inputs configuration:

        {
            "disableValidations": true, // disables form validations
            "scriptCode": "codeSpelScript", // SPEL script code
            "ngrxAction": "[Core] Navigate", // action that executes after submit
            "ngrxActionData": {} // optional data for specified action
        }

        All parameters are optional.
        If the script is filled, the NGRX action is executed after the script is finished (it has the data returned by the script available), if the script is not filled, it is executed immediately after the button is pressed.

        *[Core] Navigate - this action expects url key from SPEL script to navigate elsewhere
      `,
    filters: {
      tsmTicketCategoryFilter: 'tSM Ticket Category Filter',
      tsmTicketCategoryFilterDescription:
        'Implements a ticket category filter with multi-selection support. ' +
        'Extends a table filter component, enabling users to filter ' +
        'ticket categories dynamically retrieved from an API. ' +
        'Displays available categories in a table with search functionality. ' +
        'Allows selection removal and confirmation through an overlay panel.',
      tsmTicketChannelFilter: 'tSM Ticket Channel Filter',
      tsmTicketChannelFilterDescription:
        'Implements a ticket channel filter with multi-selection support. ' +
        'Extends a table filter component, allowing users to filter ' +
        'ticket channels dynamically retrieved from an API. ' +
        'Displays available channels in a table with search functionality. ' +
        'Allows selection removal and confirmation through an overlay panel.',
      tsmTicketLinkTypeFilter: 'tSM Ticket Link Type Filter',
      tsmTicketLinkTypeFilterDescription:
        'Implements a ticket link type filter supporting multi-selection. ' +
        'Retrieves link types from the store and filters out invalid entries. ' +
        'Displays available link types in a table format with a selection mode. ' +
        'Applies a prefix filter operator to match selected values. ' +
        'Ensures uniqueness of link types by removing duplicates.',
      tsmTicketPriorityFilter: 'tSM Ticket Priority Filter',
      tsmTicketPriorityFilterDescription:
        'Implements a ticket priority filter with multi-selection capability. ' +
        'Retrieves priorities from the store and applies predefined filters. ' +
        'Displays available priorities in a table format with a selection mode. ' +
        'Supports external filter conditions using the "context" input. ' +
        'Ensures automatic filtering and selection updates based on context values.',
      tsmTicketProblemTreeFilter: 'tSM Ticket Problem Tree Filter',
      tsmTicketProblemTreeFilterDescription:
        'Implements a hierarchical problem tree filter for tickets. ' +
        'Displays a tree structure where users can select multiple problems. ' +
        'Fetches problem data from the backend and builds a tree hierarchy. ' +
        'Filters selectable options based on context conditions. ' +
        'Ensures selected items are applied and emitted upon confirmation.',
      tsmTicketRelatedPartyRoleFilter: 'tSM Ticket Related Party Role Filter',
      tsmTicketRelatedPartyRoleFilterDescription:
        'Implements a filter for selecting related party roles in tickets. ' +
        'Displays a multi-selection data table for filtering roles. ' +
        'Fetches role data from the backend and applies predefined filters. ' +
        'Uses async pipes to resolve role names dynamically. ' +
        'Ensures selected roles are applied and emitted upon confirmation.',
      tsmTicketRelatedPartyTypeFilter: 'tSM Ticket Related Party Type Filter',
      tsmTicketRelatedPartyTypeFilterDescription:
        'Implements a filter for selecting related party types in tickets. ' +
        'Displays a multi-selection data table with filtering options. ' +
        'Fetches related party type data from the backend. ' +
        'Dynamically resolves party type names using async pipes. ' +
        'Applies and emits selected values upon confirmation.',
      tsmTicketSeverityFilter: 'tSM Ticket Severity Filter',
      tsmTicketSeverityFilterDescription:
        'Implements a filter for selecting ticket severity levels. ' +
        'Displays a multi-selection data view with filtering options. ' +
        'Fetches severity data from the backend and applies filters dynamically. ' +
        'Supports context-based filtering using predefined field values. ' +
        'Applies and emits selected values upon confirmation.',
      tsmTicketStatusFilter: 'tSM Ticket Status Filter',
      tsmTicketStatusFilterDescription:
        'Implements a filter for selecting ticket statuses. ' +
        'Displays a multi-selection data view with filtering options. ' +
        'Supports both simple and complex filtering contexts using predefined field definitions. ' +
        'Fetches ticket status data from the backend and dynamically applies filters. ' +
        'Uses an optimized approach to handle filtering operators and field mappings. ' +
        'Applies and emits selected values upon confirmation.',
      tsmTicketTypeCodeFilter: 'tSM Ticket Type Code Filter',
      tsmTicketTypeCodeFilterDescription:
        'Provides a filter component for selecting ticket types by code. ' +
        'Displays a multi-selection data view with filtering and sorting capabilities. ' +
        'Dynamically applies filtering based on the provided module context. ' +
        'Fetches ticket type data from the backend and applies predefined filters. ' +
        'Uses structured table configurations, including column definitions and sorting. ' +
        'Applies and emits selected values upon confirmation.',
      tsmTicketTypeFilter: 'tSM Ticket Type Filter',
      tsmTicketTypeFilterDescription:
        'Provides a filter component for selecting ticket types by ID. ' +
        'Displays a multi-selection data view with filtering and sorting capabilities. ' +
        'Dynamically applies filtering based on the provided module context. ' +
        'Retrieves ticket type data from the backend and applies predefined filters. ' +
        'Uses structured table configurations, including column definitions and sorting. ' +
        'Applies and emits selected values upon confirmation.',
    },
    pipes: {
      adviceIcon: 'Advice Icon',
      adviceIconDescription:
        'Displays an advice icon with a translated tooltip based on the input value. ' +
        'Uses Transloco for localization.',
      channelByCode: 'Channel By Code',
      channelByCodeDescription:
        'Retrieves a ticket channel by its code and returns the specified field value. ' +
        'If the channel is not available, initiates a request to load it. ' +
        'Supports nested field access using dot notation.',
      channelById: 'Channel By Id',
      channelByIdDescription:
        'Loads and retrieves a ticket channel by its ID, returning the specified field value. ' +
        'If the requested channel is unavailable, a fetch request is triggered. ' +
        'Supports accessing nested properties using dot notation.',
      jsonActiveTasks: 'Json Active Tasks',
      jsonActiveTasksDescription:
        'Parses and extracts active task details from a JSON string, ' +
        'converting date fields to a localized format. ' +
        'Returns distinct values as a comma-separated string.',
      localization: 'Localization',
      localizationDescription:
        'Dynamically translates a localized object based on the active language, ' +
        'returning the appropriate string for the selected language.',
      priorityByCode: 'Priority By Code',
      priorityByCodeDescription:
        'Loads and retrieves a priority by its unique code, allowing access ' +
        'to specific fields or the entire priority object. ' +
        'Automatically dispatches an action if the priority is not found in the store.',
      priorityById: 'Priority By Id',
      priorityByIdDescription:
        'Retrieves a ticket priority by its unique identifier, allowing ' +
        'access to a specific field or the entire priority object. ' +
        'Automatically dispatches an action if the priority is not found in the store.',
      problemTypeByCode: 'Problem Type By Code',
      problemTypeByCodeDescription:
        'Fetches a ticket problem type based on its unique code, enabling ' +
        'access to a specific field or the full problem type object. ' +
        'Triggers a store dispatch if the problem type is not already loaded.',
      problemTypeLoader: 'Problem Type Loader',
      problemTypeLoaderDescription:
        'Loads a ticket problem type using its unique ID, allowing access ' +
        'to a specific field or the entire problem type object. ' +
        'Dispatches an action to retrieve the problem type if it is not found in the store.',
      relatedEntityByIdNameIcon: 'Related Entity By Id Name Icon',
      relatedEntityByIdNameIconDescription:
        'Loads a related entity using its unique ID and displays its name ' +
        'with an optional icon if available. If the entity has an active ' +
        'process (decomposition), a loading spinner is appended to indicate ' +
        'an ongoing operation. Dispatches an action to retrieve the entity ' +
        'if it is not found in the store.',
      relatedEntityById: 'Related Entity By Id',
      relatedEntityByIdDescription:
        'Fetches a related entity by its unique ID using an API service and ' +
        'returns an observable containing the entity data. If the provided ID ' +
        'is null, an empty observable is returned to prevent unnecessary requests.',
      relatedEntityNameIcon: 'Related Entity Name Icon',
      relatedEntityNameIconDescription:
        'Transforms a related entity into a formatted name with an icon. Retrieves entity ' +
        'details from the store and displays them as an HTML element with an icon.',
      relatedEntityOfTicket: 'Related Entity Of Ticket',
      relatedEntityOfTicketDescription:
        'Loads related entity details for a specific ticket. Retrieves the entity ' +
        'from the store and extracts the requested field or the entire entity.',
      relatedEntityParentName: 'Related Entity Parent Name',
      relatedEntityParentNameDescription:
        'Retrieves the parent entity name from a list of related entities. Finds ' +
        'the first valid entity and loads its details from the store.',
      relatedEntityShortName: 'Related Entity Short Name',
      relatedEntityShortNameDescription:
        'Retrieves a short name for a related entity by filtering based on ' +
        'a ticket and place ID. Loads data from the store and applies ' +
        'predefined filters for entity retrieval.',
      relatedEntityTypeByCode: 'Related Entity Type By Code',
      relatedEntityTypeByCodeDescription:
        'Retrieves related entity type details by code and loads missing ' +
        'data when necessary. Extracts a specific field or returns ' +
        'the full entity type object.',
      relatedEntityTypeIcon: 'Related Entity Type Icon',
      relatedEntityTypeIconDescription:
        'Retrieves the icon and name for a related entity type by its code. ' +
        'Displays an icon if available, otherwise returns only the name.',
      relatedPartyOwner: 'Related Party Owner',
      relatedPartyOwnerDescription:
        'Retrieves and formats the name of a related party owner. ' +
        'Filters by owner type and fetches details from the store if missing.',
      relatedPartyParticipants: 'Related Party Participants',
      relatedPartyParticipantsDescription:
        'Processes a list of related party participants and formats them into a ' +
        'concatenated string. Supports filtering by specific fields or ' +
        'aggregating all participant data into a structured format.',
      relatedPartyRef: 'Related Party Ref',
      relatedPartyRefDescription:
        'Resolves a related party reference and fetches its associated user ' +
        'or user group details. Uses caching and lazy loading to retrieve ' +
        'entity information efficiently based on its type and ID.',
      relatedPartyRoleByCodePipe: 'Related Party Role By Code Pipe',
      relatedPartyRoleByCodePipeDescription:
        'Fetches details of a related party role based on its code ' +
        'and retrieves specific fields or full role data. Utilizes ' +
        'lazy loading and caching to optimize store lookups.',
      relatedPartyRoleByIdPipe: 'Related Party Role By Id Pipe',
      relatedPartyRoleByIdPipeDescription:
        'Retrieves details of a related party role based on its ID ' +
        'and provides either specific field data or the entire role object. ' +
        'Implements lazy loading and caching for efficient data retrieval.',
      relatedPartyTypeCode: 'Related Party Type Code',
      relatedPartyTypeCodeDescription:
        'Retrieves details of a related party type based on its code ' +
        'and returns either a specific field or the full type data. ' +
        'Ensures lazy loading and caching for optimized data retrieval.',
      relatedPartyTypeId: 'Related Party Type Id',
      relatedPartyTypeIdDescription:
        'Fetches details of a related party type using its unique ID, ' +
        'returning either a specific field or the complete object. ' +
        'Implements lazy loading and caching for efficient data access.',
      relatedPartyWithRef: 'Related Party With Ref',
      relatedPartyWithRefDescription:
        'Loads details of a related party and resolves references to linked entities. ' +
        'Ensures that referenced data is retrieved and transformed properly.',
      relatedParty: 'Related Party',
      relatedPartyDescription:
        'Retrieves details of a related party by its ID and provides specific ' +
        'fields or the entire entity. Ensures that missing data is fetched when needed.',
      relationshipDirection: 'Relationship Direction',
      relationshipDirectionDescription:
        'Determines the relationship direction between related tickets by matching ' +
        'link types and their corresponding names. Uses store data to fetch ' +
        'link type details and format the output accordingly.',
      resolutionTypeByCode: 'Resolution Type By Code',
      resolutionTypeByCodeDescription:
        'Retrieves ticket resolution type details using the provided resolution code. ' +
        'Loads the resolution data from the store and extracts a specified field ' +
        'or returns the full resolution data if no field is specified.',
      resolutionTypeLoader: 'Resolution Type Loader',
      resolutionTypeLoaderDescription:
        'Fetches ticket resolution details using the given resolution ID. ' +
        'Loads the resolution data from the store and extracts a specific field ' +
        'or returns the entire resolution data when no field is specified.',
      ropAddress: 'Rop Address',
      ropAddressDescription:
        'Retrieves the formatted address or object details based on the given ID. ' +
        'Determines the type of entity (locality, object, workplace, or space) ' +
        'and fetches data from the corresponding API endpoint.',
      severityByCode: 'Severity By Code',
      severityByCodeDescription:
        'Fetches severity details based on the provided severity code. ' +
        'Loads the severity data from the store and returns the specified field value.',
      severityIcon: 'Severity Icon',
      severityIconDescription:
        'Retrieves the severity icon and details based on the provided severity ID. ' +
        'Loads severity data from the store and returns either the full data ' +
        'or a specific field with an icon if available.',
      statusByCode: 'Status By Code',
      statusByCodeDescription:
        'Retrieves the ticket status based on the provided status code. ' +
        'Loads status data from the store and returns either the full data ' +
        'or a specific field if specified.',
      statusIconByCode: 'Status Icon By Code',
      statusIconByCodeDescription:
        'Displays a ticket status as a formatted label with a background color. ' +
        'Loads the status from the store and applies localization based on ' +
        'the active language setting.',
      statusIcon: 'Status Icon',
      statusIconDescription:
        'Displays a ticket status as a formatted label with an icon and ' +
        'background color. Loads the status from the store and applies ' +
        'localization based on the active language setting.',
      tabs: 'Tabs',
      tabsDescription:
        'Transforms an array of enum values into a formatted string. ' +
        'Uses the EnumToValuePipe to map each value, optionally ' +
        'adding a prefix before joining them into a single output.',
      ticketCategoryCode: 'Ticket Category Code',
      ticketCategoryCodeDescription:
        'Retrieves a ticket category by its code and returns the specified field. ' +
        'Fetches data from the store and automatically dispatches an action ' +
        'if the category is not already loaded.',
      ticketCategory: 'Ticket Category',
      ticketCategoryDescription:
        'Retrieves a ticket category by its ID and returns the specified field. ' +
        'Loads data from the store and dispatches an action if the category ' +
        'is not yet available.',
      ticketId: 'Ticket Id',
      ticketIdDescription:
        'Fetches a ticket by its ID and extracts the specified field. ' +
        'If the ticket is missing, dispatches an action to load it ' +
        'from the store.',
      ticketKey: 'Ticket Key',
      ticketKeyDescription:
        'Fetches a ticket by its Key and extracts the specified field. ' +
        'If the ticket is missing, dispatches an action to load it ' +
        'from the store.',
      ticketLink: 'Ticket Link',
      ticketLinkDescription:
        'Checks if a ticket link exists with the given type. ' +
        'Returns a check icon if found, otherwise returns an empty string.',
      ticketRefIdPipe: 'Ticket Ref Id Pipe',
      ticketRefIdPipeDescription:
        'Loads related ticket references by reference ID, filtering out completed ones. ' +
        'Returns ticket details or a specific field value.',
      ticketStatusId: 'Ticket Status Id',
      ticketStatusIdDescription:
        'Retrieves ticket status by ID from the store. Dispatches loading action ' +
        'if status data is not available.',
      ticketTaskLink: 'Ticket Task Link',
      ticketTaskLinkDescription:
        'Generates a hyperlink for a task associated with a ticket. ' +
        'Uses process instance details to construct the link.',
      ticketTypeByCode: 'Ticket Type By Code',
      ticketTypeByCodeDescription:
        'Retrieves ticket type details based on a given code. ' +
        'Loads data from the store and updates dynamically.',
      ticketTypeById: 'Ticket Type By Id',
      ticketTypeByIdDescription:
        'Retrieves ticket type details based on a given ID. ' +
        'Dynamically loads and updates data from the store.',
      ticketTypeSla: 'Ticket Type Sla',
      ticketTypeSlaDescription:
        'Determines whether a ticket type has SLA enabled. ' +
        'Loads ticket type details and applies Boolean translation.',
      typeIcon: 'Type Icon',
      typeIconDescription:
        'Transforms a ticket type identifier into an HTML-formatted name with an icon. ' +
        'Loads type details from the register and formats them as an icon with text.',
      userUserGroupRef: 'User User Group Ref',
      userUserGroupRefDescription:
        'Resolves a reference to a user or user group and retrieves its details. ' +
        'Loads data based on the reference type and ID, providing formatted output.',
    },
  },
};
