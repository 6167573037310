import {
  LumberjackLogPayload,
  LumberjackService,
  LumberjackTimeService,
  LumberjackLogLevel,
} from '@ngworker/lumberjack';
import {
  ToastParamsContextModel,
  ToastParamsModel,
  ToastService,
  ToastSeverity,
} from '@tsm/framework/toast';

export class LoggerBuilder<
  TPayload extends LumberjackLogPayload | void = void,
> {
  private scope?: string;
  private payload?: TPayload;
  private toastParams?: ToastParamsModel;

  constructor(
    private readonly lumberjack: LumberjackService<TPayload>,
    private readonly time: LumberjackTimeService,
    private readonly level: LumberjackLogLevel,
    private readonly message: string,
    private readonly toastService: ToastService,
  ) {}

  /**
   * Executes logging
   */
  execute(): void {
    this.lumberjack.log({
      level: this.level,
      message: this.message,
      scope: this.scope,
      createdAt: this.time.getUnixEpochTicks(),
      payload: this.payload,
    });

    if (this.toastParams != null) {
      this.toastService.showToast(
        this.toastParams.message,
        this.toastParams.severity,
        this.toastParams.duration,
        this.toastParams.data,
      );
    }
  }

  /**
   * Adds toast params to the `LumberjackLog`
   */
  withToast(toastParams: ToastParamsModel) {
    this.toastParams = toastParams;

    return this;
  }

  /**
   * Adds error toast params to the `LumberjackLog`
   */
  withErrorToast(toastParams: ToastParamsContextModel) {
    return this.withToast({
      ...toastParams,
      severity: ToastSeverity.ERROR,
    });
  }

  /**
   * Adds warn toast params to the `LumberjackLog`
   */
  withWarnToast(toastParams: ToastParamsContextModel) {
    return this.withToast({
      ...toastParams,
      severity: ToastSeverity.WARN,
    });
  }

  /**
   * Adds success toast params to the `LumberjackLog`
   */
  withSuccessToast(toastParams: ToastParamsContextModel) {
    return this.withToast({
      ...toastParams,
      severity: ToastSeverity.SUCCESS,
    });
  }

  /**
   * Adds info toast params to the `LumberjackLog`
   */
  withInfoToast(toastParams: ToastParamsContextModel) {
    return this.withToast({
      ...toastParams,
      severity: ToastSeverity.INFO,
    });
  }

  /**
   * Adds a scope to the `LumberjackLog`
   */
  withScope(scope: string): LoggerBuilder<TPayload> {
    this.scope = scope;

    return this;
  }

  /**
   * Adds payload with custom data to the `LumberjackLog`
   */
  withPayload(
    ...payloadArg: TPayload extends void ? [never?] : [TPayload]
  ): LoggerBuilder {
    this.payload = payloadArg[0] as TPayload;

    return this as unknown as LoggerBuilder;
  }
}
