import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {RouterStateUrl} from '@tsm/framework/root';
import {formatterTemplateAdapter} from '../reducers/formatter-template.reducer';

const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getFormatterTemplateState = createSelector(
  getDmsState,
  (x) => x.formatterTemplate,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  formatterTemplateAdapter.getSelectors(getFormatterTemplateState);

export const selectFormatterTemplateEntities = createSelector(
  getFormatterTemplateState,
  selectEntities,
);

export const selectFormatterTemplateByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectFormatterTemplateById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);
