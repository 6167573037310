import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Config} from '@tsm/framework/config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KafkaDlqService {
  readonly BASE_URL;

  readonly KAFKA_DLQ_URL = 'kafka-dlq/elastic';

  constructor(
    private api: ApiService,
    private config: ConfigService<Config>,
  ) {
    this.BASE_URL = this.config.value.apiUrls.dms;
  }

  reprocessMessages(ids: string[]): Observable<Envelope<any>> {
    return this.api.post(
      `${this.BASE_URL}/${this.KAFKA_DLQ_URL}/reprocess/${ids}`,
      {},
    );
  }

  getRecordById(id: string): Observable<Envelope<any>> {
    return this.api.get(`${this.BASE_URL}/${this.KAFKA_DLQ_URL}/${id}`);
  }
}
