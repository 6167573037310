import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Config} from '@tsm/framework/config';
import {User} from '../model';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private readonly BASE_URL: string;
  private USER_SETTINGS: string;
  private PASSWORD: string;
  private USER: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.userManagement;
    this.USER_SETTINGS = 'user-settings';
    this.PASSWORD = 'password';
    this.USER = 'user';
  }

  updatePw(userId: string, pwInfo: any): Observable<Envelope<User>> {
    return this.apiService.patch<User, User>(
      `${this.BASE_URL}/${this.USER_SETTINGS}/${this.PASSWORD}/${this.USER}/${userId}`,
      pwInfo,
    );
  }
}
