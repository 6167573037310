import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {TableColumnConfig} from '../models';
import {
    DeleteTableColumnConfigSuccess,
    DiffTableColumnConfig,
    DiffTableColumnConfigError,
    DiffTableColumnConfigSuccess,
    LoadTableColumnConfigById,
    LoadTableColumnConfigByIdError,
    LoadTableColumnConfigByIdSuccess,
    LoadTableColumnConfigsByListingIdSuccess,
    LoadTableColumnConfigsByListingTypesSuccess,
    LoadTableColumnConfigsByListingTypeSuccess,
    LoadTableColumnConfigSuccess,
    PatchTableColumnConfig,
    PatchTableColumnConfigSuccess,
    UpsertTableColumnConfig,
    UpsertTableColumnConfigSuccess,
} from '../actions';

export type TableColumnConfigState = EntityState<
  IdentifiedPack<TableColumnConfig>
>;

export const tableColumnConfigAdapter: EntityAdapter<
  IdentifiedPack<TableColumnConfig>
> = createEntityAdapter<IdentifiedPack<TableColumnConfig>>({});

export const tableConfigInitialState: TableColumnConfigState =
  tableColumnConfigAdapter.getInitialState({});

export const reducer = createReducer(
    tableConfigInitialState,

    on(LoadTableColumnConfigById, (state, {id}) =>
        tableColumnConfigAdapter.upsertOne(
            {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

    on(LoadTableColumnConfigByIdSuccess, (state, {tableColumnConfig}) =>
        tableColumnConfigAdapter.upsertOne(
            {
        id: tableColumnConfig.id,
        data: tableColumnConfig,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

    on(LoadTableColumnConfigByIdError, (state, {id, error}) =>
        tableColumnConfigAdapter.upsertOne(
            {
        id: id,
        data: null,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

    on(UpsertTableColumnConfig,
        PatchTableColumnConfig,
        (state, {tableColumnConfig}) =>
            tableColumnConfigAdapter.updateOne(
                {
                    id: tableColumnConfig.id,
                    changes: {
                        ...state.entities[tableColumnConfig.id],
                        loading: true,
                    },
                },
                state,
            ),
    ),

    on(UpsertTableColumnConfigSuccess,
        PatchTableColumnConfigSuccess,
        (state, {tableColumnConfig}) =>
            tableColumnConfigAdapter.upsertOne(
                {
        id: tableColumnConfig.id,
        data: tableColumnConfig,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

    on(DeleteTableColumnConfigSuccess, (state, tableColumnConfig) =>
        tableColumnConfigAdapter.removeOne(tableColumnConfig.id, state),
    ),

  on(
    LoadTableColumnConfigSuccess,
    LoadTableColumnConfigsByListingIdSuccess,
    LoadTableColumnConfigsByListingTypeSuccess,
    LoadTableColumnConfigsByListingTypesSuccess,
    (state, {tableColumnConfigs}) => ({
      ...tableColumnConfigAdapter.addMany(
        tableColumnConfigs.map((data) => ({
          id: data.id,
          data: data,
          loading: false,
          error: null,
        })),
        state,
      ),
    }),
  ),

    on(DiffTableColumnConfig, (state, {diffEntities}) =>
        tableColumnConfigAdapter.upsertMany(
            diffEntities.map((diffEntity) => {
                return {
                    id: diffEntity.id,
                    loading: true,
                    error: null,
                };
            }),
      state,
    ),
  ),

    on(DiffTableColumnConfigSuccess, (state, {tableColumnConfigs}) =>
        tableColumnConfigAdapter.upsertMany(
            tableColumnConfigs.map((tableColumnConfig) => {
                return {
                    id: tableColumnConfig.id,
                    data: tableColumnConfig,
                    error: null,
                    loading: false,
                };
            }),
      state,
    ),
  ),

    on(DiffTableColumnConfigError, (state, {diffEntities, error}) =>
        tableColumnConfigAdapter.upsertMany(
            diffEntities.map((diffEntity) => {
                return {
                    id: diffEntity.id,
                    data: state.entities[diffEntity.id].data,
                    loading: false,
                    error: error,
                };
            }),
      state,
    ),
  ),
);
