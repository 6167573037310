import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {animate, state, style, transition, trigger} from '@angular/animations';
import {DtlMenuItem} from '@tsm/framework/plugin';
import {Observable, startWith} from 'rxjs';
import {
  ChangeLanguageMenuItemsAction,
  LayoutTsmService,
} from '@tsm/layout/service';
import {TsmFormControl} from '@tsm/framework/forms';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {Terminator} from '@tsm/framework/terminator';
import {PrivHelperService} from '@tsm/framework/security';
import {removeDiacritics} from '@tsm/framework/functions';
import {TranslocoService} from '@tsm/framework/translate';
import {Store} from '@ngrx/store';
import {UserParameterName} from '@tsm/user-parameters';

@Component({
  selector: 'tsm-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss'],
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        }),
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        }),
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Terminator],
})
export class LayoutMenuComponent implements OnInit {
  translationShared = translationShared;

  @Input() menuPinned: boolean;

  loading$: Observable<boolean>;

  private layoutMenuState$: Observable<DtlMenuItem[]>;
  bookmarkMenuState$: Observable<DtlMenuItem[]>;
  filteredMenuItems$: Observable<DtlMenuItem[]>;
  searchControl = new TsmFormControl();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private terminator$: Terminator,
    private store: Store,
    private privHelperService: PrivHelperService,
    public layoutTsmService: LayoutTsmService,
  ) {
    this.layoutMenuState$ = layoutTsmService.getLayoutMenu();
    this.bookmarkMenuState$ = layoutTsmService.getLayoutBookmarkMenu();
    this.loading$ = this.layoutTsmService.loadingLayoutMenu();

    this.translocoService.langChanges$
      .pipe(takeUntil(this.terminator$))
      .subscribe((lang) => {
        if (localStorage.getItem(UserParameterName.LANGUAGE) !== lang) {
          localStorage.setItem(UserParameterName.LANGUAGE, lang);
          this.store.dispatch(ChangeLanguageMenuItemsAction({lang}));
        }
      });
  }

  ngOnInit() {
    this.layoutTsmService.initLayoutMenu();

    this.filteredMenuItems$ = this.searchControl.valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      debounceTime(300),
      map((x) => x?.toLowerCase()),
      switchMap((searchVal) => {
        if (!!searchVal) {
          return this.layoutMenuState$.pipe(
            map((x) => {
              const cleanMenuItems = this.filterMenuItemsByPrivilege(x);
              return this.filterTree(
                cleanMenuItems,
                removeDiacritics(searchVal),
              );
            }),
          );
        }
        return this.layoutMenuState$;
      }),
      takeUntil(this.terminator$),
    );
  }

  onToggleBookmark(item: DtlMenuItem) {
    this.layoutTsmService.toggleBookmarkMenuItem(item);
  }

  private filterTree(items: DtlMenuItem[], searchVal: string): DtlMenuItem[] {
    const getNodes = (result, object) => {
      if (
        removeDiacritics(object.translateLabel.toLowerCase()).includes(
          searchVal,
        ) ||
        object.key.toLowerCase().includes(searchVal)
      ) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.items)) {
        const items = object.items.reduce(getNodes, []);
        if (items.length) result.push({...object, items});
      }
      return result;
    };
    return items.reduce(getNodes, []);
  }

  /**
   * Smaze polozky menu na zaklade privilegia
   */
  private filterMenuItemsByPrivilege(items: DtlMenuItem[]): DtlMenuItem[] {
    const copy = items ? [...items.map((item) => ({...item}))] : [];
    copy.forEach((item) => {
      if (item.items) {
        item.items = this.filterMenuItemsByPrivilege(item.items);
      }
      if (
        item.privilege &&
        !this.privHelperService.isCurrentUserHasAnyOfRoles(item.privilege)
      ) {
        const index = copy.indexOf(item);
        copy.splice(index, 1);
      }
    });
    return copy;
  }

  // clickMenuItem(routerLink: string[], queryParams: Params, url: string, config: DtlMenuItemConfigApi, event) {
  //   if (url != null) {
  //     window.open(url, '_blank', 'noopener noreferrer');
  //     return;
  //   }
  //   const toSameUrl = this.router.isActive(this.router.createUrlTree(routerLink), false);
  //   let navigationExtras: NavigationExtras = toSameUrl ?
  //     this.activatedRoute.snapshot.queryParams.profile ?
  //       {queryParams: {profile: this.activatedRoute.snapshot.queryParams.profile}} :
  //       null :
  //     {queryParams};
  //
  //   if (config?.disabledSameProfile) { // HACK pro tlacitka v nemu ktera maji rovnou nastaveny profil
  //     navigationExtras = {queryParams, state: {clear: true}};
  //   }
  //   if (event?.ctrlKey || event?.metaKey) {
  //     const url = this.router.createUrlTree(routerLink, navigationExtras).toString();
  //     window.open(url, '_blank', 'noopener noreferrer');
  //   } else {
  //     this.router.navigate(routerLink, navigationExtras == null ? {queryParams: null} : navigationExtras);
  //     if (this.pinned) {
  //       this.layoutTsmService.closeMenu();
  //     }
  //     this.itemClicked.emit(true);
  //   }
  // }
}
