import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteAllUserRoles,
  DeleteAllUserRolesError,
  DeleteAllUserRolesSuccess,
  DeleteUserRole,
  DeleteUserRoleError,
  DeleteUserRoleSuccess,
  LoadUserRolesByRoleId,
  LoadUserRolesByRoleIdError,
  LoadUserRolesByRoleIdSuccess,
  LoadUserRolesByUserId,
  LoadUserRolesByUserIdError,
  LoadUserRolesByUserIdSuccess,
  SaveAllUserRoles,
  SaveAllUserRolesError,
  SaveAllUserRolesSuccess,
  UpsertUserRole,
  UpsertUserRoleError,
  UpsertUserRoleSuccess,
} from '../actions';
import {EntityIdentif, UserRole} from '../model';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {translation} from '../i18n';

@Injectable()
export class UserRoleEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<UserRole, UserRole>,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
  ) {}

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserRolesByUserId),
      exhaustMap(({userId}) => {
        return this.commonApiService
          .getAllFilterable(EntityIdentif.USER_ROLE, 'user.id__eq=' + userId)
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserRolesByUserIdSuccess({entities: env.data})
                : LoadUserRolesByUserIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByRoleId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserRolesByRoleId),
      exhaustMap(({roleId}) => {
        return this.commonApiService
          .getAllFilterable(EntityIdentif.USER_ROLE, 'role.id__eq=' + roleId)
          .pipe(
            map((env) => {
              return env.success
                ? LoadUserRolesByRoleIdSuccess({entities: env.data})
                : LoadUserRolesByRoleIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserRole),
      concatMap(({userRole}) =>
        this.commonApiService
          .upsertEntity(EntityIdentif.USER_ROLE, userRole, userRole.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.userRoleSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserRoleSuccess({userRole: env.data});
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects.userRoleSaveFailure,
                );
                return UpsertUserRoleError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAllUserRoles),
      concatMap(({entities, listingId}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.USER_ROLE, entities)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .userRoleAssignSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return SaveAllUserRolesSuccess({
                  entities: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .userRoleAssignFailure,
                );
                return SaveAllUserRolesError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserRole),
      concatMap(({id, listingId}) =>
        this.commonApiService.deleteEntity(EntityIdentif.USER_ROLE, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.userRoleDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteUserRoleSuccess({id: id, listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects.userRoleDeleteFailure,
              );
              return DeleteUserRoleError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllUserRoles),
      concatMap(({ids, listingId}) =>
        this.commonApiService
          .deleteAllEntities(EntityIdentif.USER_ROLE, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .userRoleRemoveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteAllUserRolesSuccess({
                  ids: ids,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .userRoleRemoveFailure,
                );
                return DeleteAllUserRolesError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeleteUserRoleSuccess,
        UpsertUserRoleSuccess,
        SaveAllUserRolesSuccess,
        DeleteAllUserRolesSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
