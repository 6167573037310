import {Inject, Injectable} from '@angular/core';

import {Observable, take} from 'rxjs';
import {
  DtlMenuItem,
  DtlMenuItemApi,
  DtlMenuItemType,
  MENU,
} from '@tsm/framework/plugin';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {LayoutState} from '../models/client';
import {Store} from '@ngrx/store';
import {
  loadingLayoutMenuState,
  selectLayoutBookmarkMenuState,
  selectLayoutMenuState,
} from '../selectors';
import {
  AddMenuItemAction,
  DeleteMenuItemAction,
  LoadMenuItemsAction,
  ReorderBookmarkMenuItemsAction,
  SetLayoutMenuAction,
  ToggleBookmarkMenuItemAction,
  UpdateMenuItemAction,
  UpsertMenuItemsAction,
} from '../actions';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {LayoutTsmMenuService} from './layout-tsm-menu.service';
import {Router, UrlSerializer} from '@angular/router';
import {getUserId} from '@tsm/framework/functions';
import {SharedRequestValidUntil} from '@tsm/framework/root';

@Injectable({
  providedIn: 'root',
})
export class LayoutTsmService {
  selfcare = (window as any)?.app?.params?.selfcare ?? false; // FIXME nevim jestli to tu uz neni zbytecny

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
    private store: Store<LayoutState>,
    private layoutTsmMenuService: LayoutTsmMenuService,
    private urlSerializer: UrlSerializer,
    private router: Router,
    @Inject(MENU) private tsmMenuItems: readonly DtlMenuItem[][],
  ) {}

  @SharedRequestValidUntil()
  loadTsmModuleTypeByTicketId(ticketId: string): Observable<string> {
    return this.apiService
      .get<any, any>(this.config.value.apiUrls.ticket + `/ticket/${ticketId}`)
      .pipe(
        switchMap((x) =>
          this.apiService.get<any, any>(
            `${this.config.value.apiUrls.tsmFormConfig}/tsm-module/${x.data?.type?.tsmModuleId}`,
          ),
        ),
        map((x) => x.data?.moduleType),
      );
  }

  /**
   * Vytvoreni menu a ulozeni do storu
   * @param menuItems - staticky polozky menu (ty co jsou zadratovany v kodu)
   */
  loadLayoutMenu(menuItems: DtlMenuItem[]) {
    this.store.dispatch(SetLayoutMenuAction({layoutMenu: menuItems}));
  }

  /**
   * Selektor na menu ve storu
   */
  getLayoutMenu(): Observable<DtlMenuItem[]> {
    return this.store.select(selectLayoutMenuState);
  }

  /**
   * Selektor na menu ve storu
   */
  getLayoutBookmarkMenu(): Observable<DtlMenuItem[]> {
    return this.store.select(selectLayoutBookmarkMenuState);
  }

  loadingLayoutMenu(): Observable<boolean> {
    return this.store.select(loadingLayoutMenuState);
  }

  /**
   * Sestavo breadcrumb na zaklade labelu uvedeny v definici menu
   * @param url
   */
  getBreadcrumbByLayoutMenu(
    url: string,
  ): Observable<{labels: string[]; found: boolean}> {
    return this.getLayoutMenu().pipe(
      filter((x) => x.length > 0),
      distinctUntilChanged(),
      take(1),
      map((x) => {
        let result: {labels: string[]; found: boolean} = {
          labels: [],
          found: false,
        };
        x.forEach((item) => {
          if (result.found === false) {
            result = {labels: [], found: false};
            this.findLabels(url, item, result);
          }
        });
        return result;
      }),
    );
  }

  findMenuItemByUrl(url: string): Observable<DtlMenuItem> {
    return this.getLayoutMenu().pipe(
      filter((x) => x.length > 0),
      distinctUntilChanged(),
      take(1),
      map((x) => {
        return this.findNodeInForestByUrl(x, url);
      }),
    );
  }

  closeMenu() {
    this.layoutTsmMenuService.closeMenu();
  }

  initLayoutMenu() {
    const tempArr: DtlMenuItem[] = [];
    this.tsmMenuItems.forEach((topLevel) => {
      topLevel.forEach((secondLevel) => {
        if (secondLevel.parent === 'root' || !secondLevel.parent) {
          tempArr.push(secondLevel);
        }
        this.moveToParentItem(secondLevel, tempArr);
      });
    });
    const menuItems = tempArr.sort(
      (a, b) => (b.priority || 0) - (a.priority || 0),
    );
    this.loadLayoutMenu(menuItems);
    this.store.dispatch(LoadMenuItemsAction());
  }

  addLayoutMenuItem(item: DtlMenuItem) {
    this.store.dispatch(AddMenuItemAction({menuItem: item}));
  }

  updateLayoutMenuItem(item: DtlMenuItem) {
    this.store.dispatch(UpdateMenuItemAction({menuItem: item}));
  }

  deleteLayoutMenuItem(item: DtlMenuItem) {
    this.store.dispatch(DeleteMenuItemAction({menuItem: item}));
  }

  saveAllMenuItems(menuItems: DtlMenuItemApi[]) {
    this.store.dispatch(UpsertMenuItemsAction({menuItems}));
  }

  toggleBookmarkMenuItem(item: DtlMenuItem) {
    this.store.dispatch(ToggleBookmarkMenuItemAction({menuItem: item}));
  }

  reorderBookmarkMenuItems(items: DtlMenuItem[]) {
    this.store.dispatch(ReorderBookmarkMenuItemsAction({menuItems: items}));
  }

  getMenuItemApi(): Observable<Envelope<DtlMenuItem[]>> {
    return this.apiService.get<DtlMenuItemApi[], DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig +
        '/menu-item/' +
        (this.selfcare ? 'selfcare/' : '') +
        'all',
      (menuItems) => {
        return this.generateDtlMenuItem(menuItems);
      },
    );
  }

  saveAllMenuItemsApi(menuItems: DtlMenuItemApi[]) {
    return this.apiService.post<DtlMenuItemApi[], DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig +
        '/menu-item/' +
        (this.selfcare ? 'selfcare/' : '') +
        'save/all',
      menuItems,
      (menuItems) => this.generateDtlMenuItem(menuItems),
    );
  }

  addBookmarkMenuItemApi(
    menuItem: DtlMenuItem,
  ): Observable<Envelope<DtlMenuItem[]>> {
    return this.apiService.post<DtlMenuItemApi, DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig + '/menu-item',
      this.generateDtlMenuItemApi(
        menuItem,
        getUserId(),
        DtlMenuItemType.BOOKMARK,
      ),
      (menuItem) => this.generateDtlMenuItem([menuItem]),
    );
  }

  reorderBookmarkMenuItemsApi(
    menuItems: DtlMenuItem[],
  ): Observable<Envelope<DtlMenuItem[]>> {
    return this.apiService.post<DtlMenuItemApi[], DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig +
        '/menu-item/' +
        (this.selfcare ? 'selfcare/' : '') +
        'reorder/all',
      menuItems.map((menuItem, index) =>
        this.generateDtlMenuItemApi(
          {
            ...menuItem,
            priority: index + 10,
          },
          getUserId(),
          DtlMenuItemType.BOOKMARK,
          true,
        ),
      ),
      (menuItems) => this.generateDtlMenuItem(menuItems),
    );
  }

  addMenuItemApi(menuItem: DtlMenuItem): Observable<Envelope<DtlMenuItem[]>> {
    return this.apiService.post<DtlMenuItemApi[], DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig +
        '/menu-item/' +
        (this.selfcare ? 'selfcare/' : '') +
        'all',
      this.generateDtlMenuItemApi(menuItem, getUserId(), menuItem.type),
      (menuItems) => this.generateDtlMenuItem(menuItems),
    );
  }

  updateMenuItemApi(
    menuItem: DtlMenuItem,
  ): Observable<Envelope<DtlMenuItem[]>> {
    return this.apiService.put<DtlMenuItemApi[], DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig +
        '/menu-item/' +
        (this.selfcare ? 'selfcare/' : '') +
        'all/' +
        menuItem.id,
      this.generateDtlMenuItemApi(menuItem, getUserId(), menuItem.type, true),
      (menuItems) => this.generateDtlMenuItem(menuItems),
    );
  }

  deleteMenuItemApi(
    menuItem: DtlMenuItem,
  ): Observable<Envelope<DtlMenuItem[]>> {
    return this.apiService.delete<DtlMenuItemApi[], DtlMenuItem[]>(
      this.config.value.apiUrls.tsmFormConfig +
        '/menu-item/' +
        (this.selfcare ? 'selfcare/' : '') +
        'all/' +
        menuItem.id,
      (menuItems) => this.generateDtlMenuItem(menuItems),
    );
  }

  /**
   * Place menuItem under correct parent(s) in menu structure.
   * @param menuItem
   * @param menuStructure
   * @private
   */
  private moveToParentItem(
    menuItem: DtlMenuItem,
    menuStructure: DtlMenuItem[],
  ) {
    const parents = Array.isArray(menuItem.parent)
      ? menuItem.parent
      : [menuItem.parent];
    let foundParent;
    for (let i = 0; i < parents.length; i++) {
      const foundTmpParent = menuStructure.find((x) => x.key === parents[i]);
      foundParent = foundTmpParent ? {...foundTmpParent} : null;
      if (!foundParent || !foundParent.items) {
        break;
      }
      menuStructure = foundParent.items;
    }
    if (foundParent && !foundParent.items.some((x) => x.key === menuItem.key)) {
      foundParent.items.push(menuItem);
    }
  }

  getMenuItemChainByKey(key: string): Observable<DtlMenuItem[]> {
    return this.getLayoutMenu().pipe(
      filter((pack) => !!pack),
      map((data) => this.findMenuItemChainByKey(key, data)),
    );
  }

  private findMenuItemChainByKey(
    key: string,
    items: DtlMenuItem[],
    parrentItems = [],
  ) {
    if (items && items.length > 0) {
      const foundItem = items.find((item) => item.key === key);
      return foundItem
        ? [...parrentItems, foundItem]
        : items
            .map((item) =>
              this.findMenuItemChainByKey(key, item.items, [
                ...parrentItems,
                item,
              ]),
            )
            .find((itemList) => !!itemList && itemList.length > 0);
    } else {
      return null;
    }
  }

  private findLabels(
    url: string,
    item: DtlMenuItem,
    result: {labels: string[]; found: boolean},
  ) {
    if (item?.routerLink?.length > 0 && item.routerLink.includes(url)) {
      result.labels.push(item.label);
      result.found = true;
    } else if (item?.routerLink == null && item?.items?.length > 0) {
      if (result.found === false) {
        result.labels.push(item.label);
        item?.items.forEach((childItem) => {
          this.findLabels(url, childItem, result);
        });
      }
    }
  }

  private generateDtlMenuItem(data: DtlMenuItemApi[]): DtlMenuItem[] {
    let tmpRoot = data
      ?.filter((x: any) => x.parent == null)
      .map((mi) => {
        const urlTree = mi.routerLink
          ? this.urlSerializer.parse(mi.routerLink)
          : mi.routerLink;
        const queryParams = (urlTree as any)?.queryParams;
        return {
          id: mi.id,
          label: mi.name,
          key: mi.code,
          privilege: mi?.privilege?.split(','),
          priority: mi.sortOrder,
          icon: mi.icon,
          useDefaultSetting:
            mi.useDefaultSetting == null ? true : mi.useDefaultSetting,
          parentId: null,
          items: null,
          visible: mi?.visible,
          url: !!mi?.url ? mi.url : null,
          routerLink: !!mi?.routerLink ? [mi.routerLink.split('?')[0]] : null,
          listingType: mi?.config?.listingType,
          queryParams: queryParams,
          config: mi?.config,
          userId: mi?.userId,
          type: mi?.type,
          localizationData: mi?.localizationData,
        };
      })
      .sort((a, b) => (b.priority || 0) - (a.priority || 0));
    tmpRoot?.forEach((root) =>
      this.setChildren(
        root,
        data
          .filter((x: any) => x.parent != null)
          .sort((a, b) => (b.sortOrder || 0) - (a.sortOrder || 0)),
      ),
    );
    return tmpRoot;
  }

  private setChildren(root: DtlMenuItem, children: DtlMenuItemApi[]) {
    const rootId = root.id;
    const tmpChildren = children
      .filter((x) => x.parent.id === rootId)
      .map((mi) => {
        const urlTree = mi.routerLink
          ? this.urlSerializer.parse(mi.routerLink)
          : mi.routerLink;
        const queryParams = (urlTree as any)?.queryParams;
        return {
          id: mi.id,
          label: mi.name,
          key: mi.code,
          privilege: mi?.privilege?.split(','),
          priority: mi.sortOrder,
          icon: mi.icon,
          useDefaultSetting:
            mi.useDefaultSetting == null ? true : mi.useDefaultSetting,
          parentId: mi.parent.id,
          items: null,
          visible: mi?.visible,
          url: !!mi?.url ? mi.url : null,
          routerLink: !!mi?.routerLink ? [mi.routerLink.split('?')[0]] : null,
          listingType: mi?.config?.listingType,
          queryParams: queryParams,
          config: mi?.config,
          userId: mi?.userId,
          type: mi?.type,
          localizationData: mi?.localizationData,
        };
      });
    if (tmpChildren && tmpChildren.length > 0) {
      if (root.items == null) {
        root.items = [];
      }
      root.items.push(...tmpChildren);
      root.items.forEach((x) => {
        const cyclicChildren = [];
        const nonCyclicChildren = [];
        children.forEach((ch) =>
          ch.id !== rootId && ch.id !== x.id
            ? nonCyclicChildren.push(ch)
            : cyclicChildren.push(ch),
        );
        this.setChildren(x, nonCyclicChildren);
      });
    }
  }

  private generateDtlMenuItemApi(
    data: DtlMenuItem,
    userId: string,
    type: DtlMenuItemType,
    update = false,
  ): DtlMenuItemApi {
    const routerLink =
      data?.routerLink?.length > 0
        ? this.router
            .createUrlTree(data.routerLink, {
              queryParams: data.queryParams || {},
            })
            .toString()
        : null;
    return {
      id: update ? data.id : null,
      code: data.key,
      description: null,
      icon: data.icon,
      name: data.label,
      parent: data.parent
        ? {
            id: (data.parent as DtlMenuItem)?.id,
            code: (data.parent as DtlMenuItem)?.key,
            name: (data.parent as DtlMenuItem)?.label,
            icon: (data.parent as DtlMenuItem)?.icon,
            url: null,
            routerLink: null,
            type: (data.parent as DtlMenuItem)?.type,
          }
        : null,
      privilege: data.privilege.join(','),
      sortOrder: data.priority,
      url: data.url,
      routerLink: routerLink,
      useDefaultSetting: false,
      visible: data.visible,
      config: {
        // listingType: data.listingType
      },
      userId: userId,
      type: type,
      localizationData: data?.localizationData,
    };
  }

  /**
   * Najdni uzel v lese podle url
   */
  private findNodeInForestByUrl(
    forest: DtlMenuItem[],
    url: string,
  ): DtlMenuItem | null {
    function includeUrl(target: string, prefix: string): boolean {
      // Odebrání části za otazníkem
      const normalizedTarget = target.split('?')[0];

      // Zkontroluj, zda je target přesně roven prefixu nebo obsahuje další části za prefixem
      return (
        normalizedTarget === prefix ||
        (normalizedTarget.startsWith(prefix) &&
          normalizedTarget.split('/').length > prefix.split('/').length)
      );
    }

    function findNodeInTree(
      tree: DtlMenuItem,
      url: string,
    ): DtlMenuItem | null {
      if (
        tree?.url === url ||
        (tree?.routerLink?.length > 0 && includeUrl(url, tree?.routerLink[0]))
      ) {
        return tree;
      }

      if (tree?.items == undefined) {
        return null;
      }

      for (const child of tree?.items) {
        const result = findNodeInTree(child, url);
        if (result !== null) {
          return result;
        }
      }
      return null;
    }

    for (const tree of forest) {
      const result = findNodeInTree(tree, url);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }
}
