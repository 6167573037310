import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {DynamicComponentService} from '@tsm/framework/dynamic-component';
import {FrameworkPluginService} from './framework-plugin.service';
import {
  DTL_DATASOURCES,
  DtlFilters,
  DtlLayoutControlsConfiguration,
  DtlPipes,
  DtlWidgets,
  ENTITY_TYPE,
  FILTER,
  LAYOUT_CONTROLS,
  MENU,
  PIPE,
  Plugin,
  TsmEntityTypes,
  WIDGET,
} from './models';
import {DtlDataSource} from '@tsm/framework/datasource';

@NgModule({})
export class FrameworkPluginModule {
  constructor(
    @Inject(DTL_DATASOURCES)
    @Optional()
    dtss: {name: string; dataSource: DtlDataSource; initialValue: any}[][],
    @Inject(WIDGET) @Optional() widgets: DtlWidgets[][],
    @Inject(FILTER) @Optional() filters: DtlFilters[][],
    @Inject(PIPE) @Optional() pipes: DtlPipes[][],
    @Inject(LAYOUT_CONTROLS)
    @Optional()
    layoutControls: DtlLayoutControlsConfiguration[][],
    @Inject(ENTITY_TYPE) @Optional() tsmEntityTypes: TsmEntityTypes[][],
    frameworkPluginService: FrameworkPluginService,
    dynamicComponentService: DynamicComponentService,
  ) {
    dtss
      .filter((x) => !!x)
      .forEach((dataSources) =>
        frameworkPluginService.addDatasources(dataSources),
      );

    widgets.forEach((widgsArray) => {
      widgsArray.forEach((widgs) => {
        widgs.definitions.forEach((def) => {
          dynamicComponentService.addConfigurationNext({
            [def.selector]: {
              loadComponent: def.loadComponent,
              loadChildren: widgs.loadChildren,
              widgetMapper: def.widgetMapper,
              bannedInputs: def.bannedInputs,
            },
          });
          // cela takhle sekce je jenom pro fluent forms
          if (def.designers) {
            def.designers.forEach((designer) => {
              dynamicComponentService.addConfigurationNext({
                [designer.editorSelector]: {
                  loadComponent: designer.loadComponent,
                  loadChildren: widgs.loadChildren,
                  widgetMapper: null,
                  bannedInputs: null,
                },
              });

              // to add widgets plni _widgetsLibrary, coz je duplicita k _configMapNext
              frameworkPluginService.addWidgets([
                {
                  privilege: def.privilege,
                  aliasSelector: designer.aliasSelector,
                  editorSelector: designer.editorSelector,
                  group: designer.group,
                  order: designer.order,
                  selector: def.selector,
                  name: designer.name,
                  description: designer.description,
                  categoryId: designer.categoryId,
                  icon: designer.icon,
                  formField: (designer as any).formField,
                  value: designer.value,
                  widgetOrLayout: designer.widgetOrLayout,
                  backgroundColorOverride: designer.backgroundColorOverride,
                },
              ]);
            });
          }
        });
      });
    });

    filters
      .filter((x) => x)
      .forEach((filtersArray) => {
        filtersArray.forEach((filter) => {
          filter.definitions.forEach((def) => {
            frameworkPluginService.addFilter(def);
            dynamicComponentService.addConfigurationNext({
              [def.selector]: {
                loadComponent: def.loadComponent,
                loadChildren: filter.loadChildren,
              },
            });
          });
        });
      });

    pipes
      .filter((x) => x)
      .forEach((pipesArray) => {
        pipesArray.forEach((pipe) => {
          pipe.definitions.forEach((def) => {
            frameworkPluginService.addPipe(def);
            dynamicComponentService.addConfigurationNext({
              [def.selector]: {
                loadComponent: def.loadComponent,
                loadChildren: pipe.loadChildren,
              },
            });
          });
        });
      });

    tsmEntityTypes
      .filter((x) => x != null)
      .forEach((entityTypes) => {
        entityTypes.forEach((conf) =>
          frameworkPluginService.addEntityType(entityTypes),
        );
      });

    layoutControls
      .filter((x) => x != null)
      .forEach((layoutControlsArr) => {
        layoutControlsArr.forEach((conf) =>
          frameworkPluginService.addLayoutControlConfiguration(conf),
        );
      });
  }

  static registerPlugins(
    plugins: Plugin[],
  ): ModuleWithProviders<FrameworkPluginModule>[] {
    return plugins.map((plugin) =>
      FrameworkPluginModule.registerPlugin(plugin),
    );
  }

  static registerPlugin(
    plugin: Plugin,
  ): ModuleWithProviders<FrameworkPluginModule> {
    return {
      ngModule: FrameworkPluginModule,
      providers: [
        {
          provide: MENU,
          multi: true,
          useValue: plugin.menus,
        },
        {
          provide: WIDGET,
          multi: true,
          useValue: plugin.widgets,
        },
        {
          provide: FILTER,
          multi: true,
          useValue: plugin.filters,
        },
        {
          provide: PIPE,
          multi: true,
          useValue: plugin.pipes,
        },
        {
          provide: ENTITY_TYPE,
          multi: true,
          useValue: plugin.entityTypes,
        },
        {
          provide: DTL_DATASOURCES,
          multi: true,
          useValue: plugin.dataSources,
        },
        {
          provide: LAYOUT_CONTROLS,
          multi: true,
          useValue: plugin.layoutControls,
        },
      ],
    };
  }
}
