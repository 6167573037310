import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectListingTypeByCode} from '../selectors';
import {filter, map} from 'rxjs/operators';
import {LoadListingTypeByCode} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

/**
 * Get customer by id, use dot separated string for nested props.
 */
@Pipe({
  name: 'listingTypeCodeLoader',
  pure: true,
})
export class ListingTypeCodeLoaderPipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  setObservable(id: string, field: string = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectListingTypeByCode(id)),
      filter((x) => x?.data != null),
      map((it) => {
        if (!it || !it.data) {
          return id;
        }
        if (field === 'all') {
          return it.data;
        }

        let value = it.data;
        const fieldProps = field.split('.');
        for (const nestedField of fieldProps) {
          value = value[nestedField];
        }
        return value;
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectListingTypeByCode(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadListingTypeByCode({code: this._id})),
          );
        }
      });
  }
}
