import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import * as Effects from './effects';
import * as Services from './services';
import * as Pipes from './pipes';

import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';

import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '@tsm/shared';

import {localizations} from './i18n';
import {TranslateModule} from '@tsm/framework/translate';
import {DynamicComponentModule} from '@tsm/framework/dynamic-component';

export const IMPORTS = [
  StoreModule.forFeature('listing', reducers),
  EffectsModule.forFeature([
    Effects.ListingEffects,
    Effects.ListingProfileEffects,
    Effects.TableColumnConfigEffects,
    Effects.ListingRefreshEffects,
    Effects.ListingTypeEffects,
    Effects.ValueConverterConfigEffects,
  ]),
  HttpClientModule,
  CommonModule,
  ReactiveFormsModule.withConfig({
    callSetDisabledState: 'whenDisabledForLegacyCode',
  }),
  SharedModule,
  TranslateModule.forFeature(localizations),
  DynamicComponentModule,
];

// TODO, todle by melo byt forRoot nebo vyreseno jinak, jinak kazdy lazy loaded module dostane vlastni instanci
export const SERVICES = [
  Services.PageSortFilterService,
  Services.ListingProfileService,
];

export const PIPES = [
  Pipes.BackupRestoreIconPipe,
  Pipes.CustomColumnValuePipe,
  Pipes.DataForViewPipe,
  Pipes.DiscriminatorPipe,
  Pipes.TsmFiltersToStringPipe,
  Pipes.LinkHrefComponentPipe,
  Pipes.ListingProfileCodePipe,
  Pipes.ListingProfilePipe,
  Pipes.ListingTypeCodeLoaderPipe,
  Pipes.ListingTypeLoaderPipe,
  Pipes.TsmLovboxMultiReadonlySplitPipe,
  Pipes.MemoizedDiscriminatorPipe,
  Pipes.MemoizedPipe,
  Pipes.TsmSelectedColumnsToStringPipe,
];
