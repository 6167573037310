import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BackupSetService, CommonApiService} from '../services';
import {concatMap, of} from 'rxjs';
import {
  BackupSetImport,
  RefreshDataAndClearSelected,
} from '@tsm/listing-lib/service';
import {
  DeleteBackupSetImport,
  DeleteBackupSetImportError,
  DeleteBackupSetImportSuccess,
  DiffBackupSetImport,
  DiffBackupSetImportError,
  DiffBackupSetImportSuccess,
  LoadBackupSetImportById,
  LoadBackupSetImportByIdError,
  LoadBackupSetImportByIdSuccess,
  UpsertBackupSetImport,
  UpsertBackupSetImportError,
  UpsertBackupSetImportSuccess,
} from '../actions';
import {map, tap} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {Router} from '@angular/router';
import {withLatestCached} from '@tsm/framework/root';
import {Store} from '@ngrx/store';
import {
  selectBackupSetImportById,
  selectBackupSetImportProfileDiffTables,
} from '../selectors';

@Injectable()
export class BackupSetImportEffects {
  private readonly API_PATH = 'v1/backup-set-import';
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      BackupSetImport,
      BackupSetImport
    >,
    private toastService: ToastService,
    private router: Router,
    private backupSetService: BackupSetService,
    private store: Store<any>,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBackupSetImportById),
      withLatestCached((action) =>
        this.store.select(selectBackupSetImportById(action.id)),
      ),
      concatMap(([action, backupSetImport]) => {
        return !action?.forcedReload && backupSetImport
          ? of(
              LoadBackupSetImportByIdSuccess({
                backupSetImport: backupSetImport,
              }),
            )
          : this.commonApiService.getEntity(this.API_PATH, action.id).pipe(
              map((env) =>
                env.success
                  ? LoadBackupSetImportByIdSuccess({
                      backupSetImport: env.data,
                    })
                  : LoadBackupSetImportByIdError({
                      id: action.id,
                      error: env.error,
                    }),
              ),
            );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertBackupSetImport),
      concatMap(({backupSetImport, listingId, navigateAfterRefresh}) =>
        this.commonApiService
          .upsertEntityPatch(this.API_PATH, backupSetImport, backupSetImport.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.backupSet.effects.backupSetUpsertSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertBackupSetImportSuccess({
                  backupSetImport: env.data,
                  listingId,
                  navigateAfterRefresh,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.backupSet.effects.backupSetUpsertError,
                );
                return UpsertBackupSetImportError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteBackupSetImport),
      concatMap(({id, listingId, redirect}) =>
        this.commonApiService.deleteEntity(this.API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.backupSet.effects.backupSetDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (redirect) {
                this.router.navigate(['/config/backup-set/import']);
              }
              return DeleteBackupSetImportSuccess({id, listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.backupSet.effects.backupSetDeleteError,
              );
              return DeleteBackupSetImportError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diffBackupSetImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffBackupSetImport),
      withLatestCached((action) =>
        this.store.select(
          selectBackupSetImportProfileDiffTables(action.backupSetImportId),
        ),
      ),
      concatMap(([action, backupSetImportDiffTables]) => {
        const hasEmptyDiff = !!backupSetImportDiffTables
          ? Object.keys(backupSetImportDiffTables).some(
              (key) =>
                backupSetImportDiffTables[key]?.data?.diffs?.length === 0,
            )
          : null;
        if (
          !backupSetImportDiffTables ||
          (action?.options &&
            !action.options?.summaryOnly &&
            ((!action?.options?.profileCode && hasEmptyDiff) ||
              (action?.options?.profileCode &&
                (!(action.options.profileCode in backupSetImportDiffTables) ||
                  backupSetImportDiffTables[action.options.profileCode]?.data
                    ?.diffs?.length === 0)) ||
              action.options?.saveImmediately))
        ) {
          return this.backupSetService
            .diffBackupSetImport(action.backupSetImportId, action?.options)
            .pipe(
              map((env) =>
                env.success
                  ? DiffBackupSetImportSuccess({
                      listingId: action.listingId,
                      backupSetDiffs: env?.data,
                      backupSetImportId: action.backupSetImportId,
                    })
                  : DiffBackupSetImportError(env.error),
              ),
            );
        } else {
          return of(
            DiffBackupSetImportSuccess({
              listingId: action.listingId,
              backupSetDiffs: Object.keys(backupSetImportDiffTables).map(
                (key) => backupSetImportDiffTables[key],
              ),
              backupSetImportId: action.backupSetImportId,
            }),
          );
        }
        return this.backupSetService
          .diffBackupSetImport(action.backupSetImportId, {summaryOnly: true})
          .pipe(
            map((env) =>
              env.success
                ? DiffBackupSetImportSuccess({
                    listingId: action.listingId,
                    backupSetDiffs: env?.data,
                    backupSetImportId: action.backupSetImportId,
                  })
                : DiffBackupSetImportError(env.error),
            ),
          );
      }),
    ),
  );

  refreshAfretUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertBackupSetImportSuccess),
      tap(({backupSetImport, navigateAfterRefresh}) => {
        if (navigateAfterRefresh) {
          this.router.navigate([
            'config/backup-set/import',
            backupSetImport.id,
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteBackupSetImportSuccess, DiffBackupSetImportSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
