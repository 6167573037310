import {Action, combineReducers} from '@ngrx/store';
import * as securityDataReducer from './security-data.reducer';

export interface SecurityState {
  security: securityDataReducer.SecurityDataState;
}

export function reducers(state: SecurityState | undefined, action: Action) {
  return combineReducers({
    security: securityDataReducer.reducer,
  })(state, action);
}
