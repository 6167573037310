import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import {LayoutTsmService, ScaleModeEnum, TopBarVM} from '@tsm/layout/service';
import {Router} from '@angular/router';
import {RuntimeInfo, RuntimeService} from '@tsm/runtime-info';
import {
  DeliveryItemType,
  DmsWebsocketService,
  LoadNotificationCount,
  MyNotification,
  NotificationStatus,
  selectNotificationCountUnreadByDeliveryType,
  UpsertMyNotification,
  UpsertMyNotificationSuccess,
} from '@tsm/dms/service';
import {Store} from '@ngrx/store';
import {
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {translation as translationShared} from '@tsm/shared-i18n';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '../i18n';
import {ShowHotkeysOverlay, withLatestCached} from '@tsm/framework/root';
import {getUserId, isMobile} from '@tsm/framework/functions';
import {Terminator} from '@tsm/framework/terminator';
import {
  selectUserParameterByCurrentUserAndName,
  selectUserParameterByUserIdAndName,
  UpsertUserParameterByUserIdAndName,
  UserParameter,
  UserParameterName,
} from '@tsm/user-parameters';
import {FormControl} from '@angular/forms';
import {LocalStateService} from '@tsm/framework/local-state';
import {DOCUMENT} from '@angular/common';
import {NotificationApiService} from '@tsm/framework/notification';

import {DtlMenuItem} from '@tsm/framework/plugin';
import {FluentFormsStore} from '@tsm/framework/fluent-debugger-service';

@Component({
  selector: 'tsm-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Terminator],
})
export class TopbarComponent implements OnDestroy {
  scaleModeEnum = ScaleModeEnum;
  scale$ = this.store
    .select(
      selectUserParameterByUserIdAndName(getUserId(), UserParameterName.SCALE),
    )
    .pipe(
      map((x) =>
        x?.data?.parameterValue == null
          ? ScaleModeEnum.DEFAULT
          : (x.data.parameterValue as ScaleModeEnum),
      ),
      distinctUntilChanged(),
    );

  langs = this.translocoService.getAvailableLangs();

  translation = translation;

  translationShared = translationShared;

  feVersion = (window as any)?.app?.version;

  build: any = (window as any)?.app?.build;

  isDarkTheme$: Observable<boolean>;

  themeControl = new FormControl(null);

  languageControl = new FormControl(
    this.translocoService.getActiveLang() === 'en',
  );

  debugControl = new FormControl(false);

  changePwFlag = false;

  isMobile = isMobile();

  customLogoPath = (window as any)?.app?.params?.customLogoPath;

  customLogoDarkPath = (window as any)?.app?.params?.customLogoDarkPath;

  // jen kvuli poctu neprectenych notif
  notificationDisplayedItemsCount$: Observable<number>;

  systemNotificationDisplayedItemsCount$: Observable<number>;

  destroyed$: Subject<void> = new Subject();

  currentUser: RuntimeInfo;

  dragMenuItem: DtlMenuItem;

  lastScrollTopHidden: number;

  lastScrollTop: number;

  topbarTempFixed$: Observable<boolean>;

  private deferredPrompt;

  bookmarkMenuState$: Observable<DtlMenuItem[]>;

  showEditMenuItem = false;

  selectedBookmark: DtlMenuItem = null;

  /**
   * APP Configuration parameter
   * Show notification badge with/without count of unread notification
   */
  badgeCountStyle =
    (window as any)?.app?.params?.showNotificationCount ?? false;

  @Input() data: TopBarVM = {
    name: '',
    activeTopBarItem: null,
    pinned: false,
    opened: false,
    staticMenuMobileActive: false,
    staticMenuDesktopInactive: false,
    env: null,
  };
  @Input() worklogStatus = false;
  @Input() menuMode: boolean;
  @Input() panelMode: boolean;
  // @Output() drops: EventEmitter<any> = new EventEmitter<any>();
  @Output() shouldOpenMenuChanges: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() topbarClose: EventEmitter<void> = new EventEmitter<void>();

  // @Output() editChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() buttonRemoves: EventEmitter<any> = new EventEmitter<any>();

  @Output() topbarOpens = new EventEmitter<
    'notifications' | 'profile' | 'messages' | 'worklogs' | null
  >();

  appInstalled$ = of(false).pipe(
    switchMap(() => fromEvent(window, 'appinstalled').pipe(map(() => true))),
  );

  readNotifs() {
    this.store.dispatch(
      LoadNotificationCount({
        userId: this.currentUser.id,
        deliveryType: DeliveryItemType.USER_COMMENT,
      }),
    );
    this.store.dispatch(
      LoadNotificationCount({
        userId: this.currentUser.id,
        deliveryType: DeliveryItemType.APPLICATION,
      }),
    );
    this.notificationDisplayedItemsCount$ = this.store.select(
      selectNotificationCountUnreadByDeliveryType(
        DeliveryItemType.USER_COMMENT,
      ),
    );
    this.systemNotificationDisplayedItemsCount$ = this.store.select(
      selectNotificationCountUnreadByDeliveryType(DeliveryItemType.APPLICATION),
    );
  }

  constructor(
    private router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public runtimeService: RuntimeService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
    private translocoService: TranslocoService,
    private localStateService: LocalStateService,
    private notificationApiService: NotificationApiService,
    private dmsWebsocketService: DmsWebsocketService,
    private fluentFormsStore: FluentFormsStore,
    private terminator: Terminator,
    private renderer: Renderer2,
    public layoutTsmService: LayoutTsmService,
  ) {
    this.bookmarkMenuState$ = layoutTsmService.getLayoutBookmarkMenu();

    this.changeLanguage(
      localStorage.getItem(UserParameterName.LANGUAGE) ||
        this.translocoService.getActiveLang(),
    );

    fromEvent(window, 'beforeinstallprompt')
      .pipe(takeUntil(this.terminator))
      .subscribe((e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.cdr.markForCheck();
      });

    // jen pro mobil
    if (this.isMobile) {
      this.topbarTempFixed$ = fromEvent(window, 'scroll').pipe(
        map(() => {
          const scrollTop = document.documentElement.scrollTop;
          return (
            (scrollTop < this.lastScrollTop && scrollTop !== 0) ||
            (this?.data.opened && scrollTop !== 0)
          );
        }),
        tap((displayClass) => {
          if (displayClass) {
            this.renderer.addClass(document.body, 'temporary-fixed-headers');
          } else {
            this.renderer.removeClass(document.body, 'temporary-fixed-headers');
          }
          this.lastScrollTop = document.documentElement.scrollTop;
        }),
      );
      fromEvent(window, 'scroll')
        .pipe(
          map(() => {
            const scrollTop = document.documentElement.scrollTop;
            return scrollTop > 0;
          }),
          tap((displayClass) => {
            if (displayClass) {
              this.renderer.addClass(document.body, 'hidden-by-scroll');
            } else {
              this.renderer.removeClass(document.body, 'hidden-by-scroll');
            }
            this.lastScrollTopHidden = document.documentElement.scrollTop;
          }),
          takeUntil(this.terminator),
        )
        .subscribe();
    }

    this.zone.runOutsideAngular(() => {
      this.document.addEventListener('click', this.clickout.bind(this));
    });

    this.dmsWebsocketService
      .onStompErrors()
      .pipe(
        filter((x) => x.command === 'ERROR' && x.body.includes('JWT expired')),
        switchMap(() => this.runtimeService.tryRefreshToken()),
        filter((x) => x == null),
        takeUntil(this.terminator),
      )
      .subscribe(() => {
        this.runtimeService.signOut(true);
      });

    this.runtimeService
      .getCurrentUserObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ui) => {
        this.currentUser = ui;
        this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.LANGUAGE,
            ),
          )
          .pipe(
            filter((x) => x != null && x.loading === false),
            map((x) => x.data.parameterValue),
            takeUntil(this.destroyed$),
          )
          .subscribe((lang) => {
            if (this.translocoService.getActiveLang() !== lang) {
              this.changeLanguage(
                localStorage.getItem(UserParameterName.LANGUAGE),
                true,
              );
            }
          });

        this.isDarkTheme$ = this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.DARK_THEME,
            ),
          )
          .pipe(
            filter((x) => x != null && x.loading === false),
            map((x) => x.data.parameterValue),
            map((darkTheme) => darkTheme === 'true'),
            tap((isDarkTheme) => {
              this.themeControl.patchValue(isDarkTheme, {emitEvent: false});
              localStateService.setState(
                UserParameterName.DARK_THEME,
                isDarkTheme,
              );
              localStorage.setItem(
                UserParameterName.DARK_THEME,
                isDarkTheme.toString(),
              );
            }),
            distinctUntilChanged(),
          );

        combineLatest([this.isDarkTheme$, this.scale$])
          .pipe(takeUntil(this.destroyed$))
          .subscribe(([isDarkTheme, scale]) => {
            // pokud existuje v DB neco jineho nez v localstorage, stahni nove css
            this.setThemeLinkAttr(isDarkTheme, scale);
          });

        this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.DEBUG_MODE,
            ),
          )
          .pipe(
            filter((x) => x != null),
            filter((x) => x.loading === false),
            map((x) => x.data.parameterValue),
            map((debugMode) => debugMode === 'true'),
            takeUntil(this.destroyed$),
          )
          .subscribe((x) =>
            this.debugControl.patchValue(x, {emitEvent: false}),
          );

        this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.REMEMBER_TAB_VIEW_STATE,
            ),
          )
          .pipe(
            filter((x) => x != null),
            filter((x) => x.loading === false),
            takeUntil(this.destroyed$),
          )
          .subscribe((x) =>
            localStateService.setState(
              UserParameterName.REMEMBER_TAB_VIEW_STATE,
              x.data == null ? 'true' : x.data.parameterValue,
            ),
          );

        this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.CONFIRM_TASK_STATUS_CHANGE,
            ),
          )
          .pipe(
            filter((x) => x != null),
            filter((x) => x.loading === false),
            takeUntil(this.destroyed$),
          )
          .subscribe((x) =>
            localStateService.setState(
              UserParameterName.CONFIRM_TASK_STATUS_CHANGE,
              x.data == null ? 'false' : x.data.parameterValue,
            ),
          );

        this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.CONFIRM_CLOSE_BUTTON,
            ),
          )
          .pipe(
            filter((x) => x != null),
            filter((x) => x.loading === false),
            takeUntil(this.destroyed$),
          )
          .subscribe((x) =>
            localStateService.setState(
              UserParameterName.CONFIRM_CLOSE_BUTTON,
              x.data == null ? 'false' : x.data.parameterValue,
            ),
          );

        this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.CONFIRM_CLOSE_WINDOW,
            ),
          )
          .pipe(
            filter((x) => x != null),
            filter((x) => x.loading === false),
            takeUntil(this.destroyed$),
          )
          .subscribe((x) => {
            const result = x.data == null ? 'false' : x.data.parameterValue;
            localStateService.setState(
              UserParameterName.CONFIRM_CLOSE_WINDOW,
              result,
            );
            if (result === 'true') {
              window.addEventListener(
                'beforeunload',
                (e) => {
                  e.preventDefault();
                  const dialogText =
                    'Are you sure you want to close the Window?';
                  (e as any).returnValue = dialogText;
                  return dialogText;
                },
                {capture: true},
              );
            }
          });

        // zatim se nepouziva, nejspis ale bude NEMAZAT!
        // this.store.select(selectUserParameterByUserIdAndName(ui.id, UserParameterName.COMMENT_VIEW_TYPE)).pipe(
        //   filter(x => x != null),
        //   filter(x => x.loading === false),
        //   takeUntil(this.destroyed$)
        // ).subscribe(x => localStateService.setState(UserParameterName.COMMENT_VIEW_TYPE, x.data == null ? 'classic' : x.data.parameterValue));

        this.readNotifs();

        this.dmsWebsocketService
          .onWatch(
            {
              topic: 'tsm-notification',
              key: this.currentUser.id,
            },
            'AppNotificationSent',
          )
          .pipe(
            withLatestCached(() =>
              this.store.select(
                selectUserParameterByCurrentUserAndName(
                  UserParameterName.ALLOW_NOTIFICATION_API,
                ),
              ),
            ),
            takeUntil(this.terminator),
          )
          .subscribe(
            ([notification, allowNotificationApiParam]: [
              MyNotification,
              UserParameter,
            ]) => {
              const allowNotificationApi =
                allowNotificationApiParam?.parameterValue === 'true';
              const params = {};
              let result = null;
              if (
                notification.notificationToDisplayed == null &&
                notification.notificationToDeleted == null
              ) {
                if (allowNotificationApi) {
                  if (notification.contents != null) {
                    const div = document.createElement('div');
                    div.innerHTML = notification.contents.replace(
                      /<(style|script)>(.|\s)*?<\/(style|script)>/g,
                      '',
                    );
                    params['body'] = div.textContent || div.innerText || '';
                  }
                  result = this.notificationApiService.notify(
                    notification.subject,
                    {
                      ...params,
                      tag: notification.id,
                    },
                  );
                }
                this.store.dispatch(
                  UpsertMyNotificationSuccess({
                    notification: notification,
                    userId: this.currentUser.id,
                  }),
                );
              }
              // display on click
              if (result && allowNotificationApi) {
                const that = this;
                result.onclick = () => {
                  if (
                    notification.ownerType === 'Ticket' &&
                    notification.ownerId
                  ) {
                    that.layoutTsmService
                      .loadTsmModuleTypeByTicketId(notification.ownerId)
                      .pipe(take(1))
                      .subscribe((moduleType) => {
                        let url = '';
                        if (moduleType === 'Ticketing') {
                          url = that.router
                            .createUrlTree([
                              '/tickets',
                              'ticket',
                              notification.ownerId,
                            ])
                            .toString();
                        } else {
                          url = that.router
                            .createUrlTree([
                              '/change-management',
                              'change-request',
                              notification.ownerId,
                            ])
                            .toString();
                        }
                        window.open(url, '_blank', 'noopener noreferrer');
                      });
                  }
                  if (notification.notificationToDisplayed == null) {
                    that.store.dispatch(
                      UpsertMyNotification({
                        notification: {
                          ...notification,
                          notificationToDisplayed: new Date().toISOString(),
                        },
                        userId: that.currentUser.id,
                      }),
                    );
                  }
                };
              }
            },
          );

        this.dmsWebsocketService
          .onWatch(
            {
              topic: 'tsm-notification',
              key: this.currentUser.id,
            },
            'AppNotificationUpdated',
          )
          .pipe(takeUntil(this.terminator))
          .subscribe((notification: MyNotification) => {
            this.store.dispatch(
              UpsertMyNotificationSuccess({
                notification: notification,
                userId: this.currentUser.id,
              }),
            );
          });

        this.cdr.markForCheck();
      });

    this.scale$.pipe(takeUntil(this.destroyed$)).subscribe((scaleMode) => {
      this.localStateService.setState(UserParameterName.SCALE, scaleMode);
      localStorage.setItem(UserParameterName.SCALE, scaleMode);
    });

    this.themeControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this.setDarkTheme(value));

    this.languageControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) =>
        this.changeLanguage(value === true ? 'en' : 'cs', true),
      );

    this.debugControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this.setDebugMode(value));
  }

  onEditBookmark(button: DtlMenuItem) {
    this.showEditMenuItem = true;
    this.selectedBookmark = button;
  }

  onSaveEditMenuItem(item: DtlMenuItem) {
    this.layoutTsmService.updateLayoutMenuItem(item);
    this.showEditMenuItem = false;
    this.selectedBookmark = null;
  }

  onCloseEditMenuItem() {
    this.showEditMenuItem = false;
    this.selectedBookmark = null;
  }

  onRemoveBookmark(item: DtlMenuItem) {
    this.layoutTsmService.toggleBookmarkMenuItem(item);
  }

  get profileImage(): SafeHtml {
    this.currentUser = this.runtimeService.getCurrent();
    if (this.currentUser && this.currentUser.profileImage) {
      return this.sanitizer.sanitize(
        SecurityContext.URL,
        'data:' +
          this.currentUser.mimeType +
          ';base64,' +
          this.currentUser.profileImage,
      );
    }
    return 'assets/images/avatar_user-default.png';
  }

  private getNotificationStatus(
    notificationType: 'messages' | 'notifications',
  ) {
    return this.localStateService.getState(notificationType).pipe(
      map((x) => {
        let notificationStatus;
        if (x == null) {
          this.localStateService.setState(
            notificationType,
            NotificationStatus.PLAN,
          );
          notificationStatus = NotificationStatus.PLAN;
        } else {
          notificationStatus = x;
        }
        return notificationStatus;
      }),
    );
  }

  private changeLanguage(lang: string, setUserParams = false) {
    this.translocoService.setActiveLang(lang);
    this.document.documentElement.lang = lang;
    this.languageControl.patchValue(lang === 'en', {emitEvent: false});
    if (setUserParams) {
      this.store.dispatch(
        UpsertUserParameterByUserIdAndName({
          name: UserParameterName.LANGUAGE,
          userId: this.currentUser.id,
          value: lang,
        }),
      );
    }
  }

  closeChangePwFlag = () => {
    this.changePwFlag = false;
    this.cdr.markForCheck();
  };

  menuButtonClick() {
    this.data.opened
      ? this.shouldOpenMenuChanges.emit(false)
      : this.shouldOpenMenuChanges.emit(true);
  }

  openTopBar(
    type: 'notifications' | 'profile' | 'messages' | 'worklogs' | null,
  ) {
    this.topbarOpens.emit(type);
    this.fluentFormsStore.destroyDebugger();
  }

  clickout(event) {
    // hlida klik na prihlaseneho uzivatele (image) nebo klik v kontextu otevrene notifikace/message/atd.
    let shouldContinue =
      event &&
      event.path &&
      !event.path.find(
        (el) =>
          [
            'topbar-menu-button',
            'history-panel',
            'notification-status',
          ].includes(el.id) ||
          (el.className &&
            el.className.indexOf &&
            el.className.indexOf('topbar-items') > -1),
      );
    if (
      event?.path?.some((el: HTMLElement) =>
        el.classList?.contains('paginator-overlay-panel'),
      )
    ) {
      shouldContinue = false;
    }

    if (shouldContinue) {
      if (this.data.activeTopBarItem != null) {
        this.topbarOpens.emit(null);
      }
      if (
        event &&
        event.path &&
        !event.path.find(
          (el) =>
            [
              'topbar-menu-button',
              'history-panel',
              'menu-button',
              'user-custom-menu-pin',
              'user-custom-menu-button',
              'submenu-icon-row1',
              'submenu-icon-row2',
              'notification-status',
            ].includes(el.id) ||
            (el.className &&
              el.className.indexOf &&
              el.className.indexOf('topbar-items') > -1),
        ) &&
        this.data.pinned
      ) {
        this.shouldOpenMenuChanges.emit(false);
      }
    }

    // pokud mam otevreny widget z topbaru, neni pinnuty a nemam otevreny modál(napr. pri editaci worklogu) a kliknu mimo komponentu, tak zavrit
    if (
      this.data.activeTopBarItem &&
      !this.panelMode &&
      !(this.document.getElementsByClassName('p-dialog').length > 0)
    ) {
      const sideBar = this.document.getElementById(
        this.data.activeTopBarItem + '-panel',
      );
      if (sideBar) {
        // popup na typ zpráv(nepřečtené, přečtené...) je mimo sidebar a při kliku do něj nesmim zavřít
        const notifDropdownPanel = this.document
          .getElementsByClassName('ntf-dropdown-panel')
          .item(0);
        if (notifDropdownPanel && notifDropdownPanel.contains(event.target)) {
          return;
        }
        const topbarIcon = this.document.getElementById(
          this.data.activeTopBarItem + '-topbar-icon',
        );
        // pokud kliknu na ikonu nesmim nic delat, schovani/zobrazeni ridi jina funkce
        if (event.target != topbarIcon && !topbarIcon.contains(event.target)) {
          const clickedInside = sideBar.contains(event.target);
          if (!clickedInside) {
            this.topbarClose.emit(null);
          }
        }
      }
    }
  }

  logout() {
    // HACK - nez se predelaji pipe (asi 1.6 verze) tak se stava, ze po odhlasesni se zavolaji diky selectorum v pipes akce,
    // ktere ulozit nesmyslne data do storu
    window.location.replace('/account/login');
    // this.router.navigate(['/account/login']);
  }

  toUserDetail() {
    return this.router.navigate([
      `/user-management/user-detail/${getUserId()}`,
    ]);
  }

  showHotkeys() {
    this.store.dispatch(ShowHotkeysOverlay());
  }

  setDarkTheme(isDarkTheme: boolean) {
    this.scale$.pipe(take(1)).subscribe((scaleMode) => {
      this.setThemeLinkAttr(isDarkTheme, scaleMode as ScaleModeEnum);

      this.store.dispatch(
        UpsertUserParameterByUserIdAndName({
          name: UserParameterName.DARK_THEME,
          userId: this.currentUser.id,
          value: isDarkTheme,
        }),
      );
    });
  }

  setScaleMode(scaleMode: ScaleModeEnum) {
    const isDarkTheme = this.themeControl.value;
    this.setThemeLinkAttr(isDarkTheme, scaleMode);

    this.store.dispatch(
      UpsertUserParameterByUserIdAndName({
        name: UserParameterName.SCALE,
        userId: this.currentUser.id,
        value: scaleMode,
      }),
    );
  }

  setDebugMode(debugMode: boolean) {
    this.store.dispatch(
      UpsertUserParameterByUserIdAndName({
        name: UserParameterName.DEBUG_MODE,
        userId: this.currentUser.id,
        value: debugMode,
      }),
    );
  }

  private setThemeLinkAttr(isDarkTheme: boolean, scaleMode: ScaleModeEnum) {
    const newHref = this.getThemeLinkAttr(isDarkTheme, scaleMode);
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const oldHref = themeLink.getAttribute('href');
    if (oldHref !== newHref) {
      themeLink.setAttribute('href', newHref);
    }
  }

  private getThemeLinkAttr(isDarkTheme: boolean, scaleMode: ScaleModeEnum) {
    let scaleModelStr = '';

    if (scaleMode === 'COMPACT_1') {
      scaleModelStr = '-cmp1';
    } else if (scaleMode === 'COMPACT_2') {
      scaleModelStr = '-cmp2';
    }
    return `theme-${isDarkTheme ? 'dark' : 'light'}${scaleModelStr}.css`;
  }

  dropBookmark(
    event,
    index,
    dropAfter: HTMLElement,
    bookmarkList: DtlMenuItem[],
  ) {
    dropAfter.classList.remove('p-placeholder-focus');

    const fromIndex = bookmarkList.findIndex(
      (x) => x.key === this.dragMenuItem.key,
    );
    let toIndex = index === 0 ? 0 : index; // pokud taham item z prava do leva
    if (fromIndex < index) {
      // pokud taham item z leva do prava
      toIndex = index === 0 ? 0 : index - 1;
    }
    let newBookmark = this.moveElementInArray(bookmarkList, fromIndex, toIndex);
    if (!this.checkOrder(bookmarkList, newBookmark)) {
      this.layoutTsmService.reorderBookmarkMenuItems(newBookmark);
    }
  }

  dragStart(menuItem: DtlMenuItem) {
    this.dragMenuItem = menuItem;
  }

  dragEnd() {
    this.dragMenuItem = null;
  }

  private moveElementInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    const newArr = [...arr.slice(0, fromIndex), ...arr.slice(fromIndex + 1)];
    newArr.splice(toIndex, 0, element);
    return newArr;
  }

  private checkOrder(array1: DtlMenuItem[], array2: DtlMenuItem[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i]['key'] !== array2[i]['key']) {
        return false;
      }
    }
    return true;
  }

  closeFluentDebugger() {
    this.fluentFormsStore.destroyDebugger();
  }

  ngOnDestroy(): void {
    this.document.removeEventListener('click', this.clickout);
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }
}
