export const cs = {
  loggingPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmLogTypeFilter: 'tSM Log Type Filter',
      tsmLogTypeFilterDescription:
        'Definuje komponentu filtru typu logu, která rozšiřuje nadřazenou komponentu pro ' +
        'filtrování seznamů. Poskytuje strukturované UI pro výběr více typů logů pomocí ' +
        'datového pohledu. Podporuje filtrování, řazení a zobrazení možností typu logu ' +
        'získaných z externího API. Implementuje chování překryvného panelu s podporou ' +
        'pro mobilní zařízení a přizpůsobitelné cílové prvky. Po výběru aktualizuje hodnoty ' +
        'filtru a emituje změny.',
    },
  },
};
