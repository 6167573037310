import {AbstractCodingTablePublic} from '@tsm/framework/root';
import {DiffRecord, EntityDiff} from './table.model';

export enum BackupSetImportStatus {
  NOT_PROCESSED = 'NOT_PROCESSED',
  OK = 'OK',
  FAILED = 'FAILED',
}

export interface BackupSet extends AbstractCodingTablePublic<string> {
  requests?: BackupSetListingProfile[];
}

export interface BackupSetListingProfile {
  backupName: string;
  entityType: string;
  listingProfile: string;
  compareField: 'id' | 'code';
}

export interface BackupSetImportLog {
  [key: string]: EntityDiff;
}

export interface BackupSetImportError {
  [key: string]: any;
}

export interface BackupSetImportSummary {
  backupSetId?: string;
  backupSetCode?: string;
  fileMap?: any;
  whenExported?: string;
  exportedBy?: string;
  name?: string;
}

export interface BackupSetImport {
  id?: string; //for async API calls
  userId?: string;
  description?: string;
  attachmentId?: string;
  backupFileName?: string;
  importDate?: string;
  readonlyAccess?: boolean;
  whenInserted?: string;
  importLog?: BackupSetImportLog;
  errorLog?: BackupSetImportError;
  importStatus?: BackupSetImportStatus;
  importLogKeys?: string[];
  importSummary?: BackupSetImportSummary;
  version?: number;
}

export interface BackupSetDiffOptions {
  summaryOnly?: boolean;
  saveImmediately?: boolean;
  backupName?: string;
  profileCode?: string;
}

export interface BackupSetDiffTable {
  table?: any;
  data?: EntityDiff;
}

export interface DiffList {
  diffName: string;
  entityType?: string;
  backupName?: string;
  listingType?: string;
  diffs?: DiffRecord[];
}
