import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {FormatterTemplate} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FormatterTemplateService {
  private readonly TEMPLATE = `formatterTemplate`;
  private readonly TEMPLATE_BY_CODE = `templateByCode`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  saveTemplate(
    formatterTemplate: FormatterTemplate,
  ): Observable<Envelope<FormatterTemplate>> {
    if (formatterTemplate.id) {
      return this.apiService.put<FormatterTemplate, FormatterTemplate>(
        `${this.BASE_URL}/${this.TEMPLATE}/${formatterTemplate.id}`,
        formatterTemplate,
      );
    } else {
      return this.apiService.post<FormatterTemplate, FormatterTemplate>(
        `${this.BASE_URL}/${this.TEMPLATE}`,
        formatterTemplate,
      );
    }
  }

  getTemplateByCode(code: string): Observable<Envelope<FormatterTemplate>> {
    return this.apiService.get<FormatterTemplate, FormatterTemplate>(
      `${this.BASE_URL}/${this.TEMPLATE}/${this.TEMPLATE_BY_CODE}/${code}`,
    );
  }

  getTemplateById(id: string): Observable<Envelope<FormatterTemplate>> {
    return this.apiService.get<FormatterTemplate, FormatterTemplate>(
      `${this.BASE_URL}/${this.TEMPLATE}/${id}`,
    );
  }

  deleteTemplateById(id: string): Observable<Envelope<FormatterTemplate>> {
    return this.apiService.delete<FormatterTemplate, FormatterTemplate>(
      `${this.BASE_URL}/${this.TEMPLATE}/${id}`,
    );
  }

  /**
   * Náhled grafické šablony po aplikaci dat.
   */
  getContentPreview(content: string, data: any): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL + `/${this.TEMPLATE}/contentPreview`,
      {
        content: content,
        data: data,
      },
      null,
      {observe: 'response', responseType: 'text' as 'json'},
    );
  }

  getContentPreviewPdf(
    formatterTemplateId: string,
    format: string,
    testData: any,
  ): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL +
        `/${this.TEMPLATE}/contentPreview/${formatterTemplateId}/${format}`,
      testData,
      null,
      {
        observe: 'response',
        responseType: (format == 'html' ? 'text' : 'blob') as 'json',
      },
    );
  }

  sentTestEmail(
    formatterTemplateId: string,
    emailTo: string,
    testData: any,
  ): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL +
        `/${this.TEMPLATE}/contentPreviewEmail/${formatterTemplateId}`,
      {
        to: emailTo,
        testData: testData,
      },
      null,
      {observe: 'response', responseType: 'text' as 'json'},
    );
  }
}
