import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/user.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserState = createSelector(
  getUserManagementState,
  (userState) => userState.user,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserState);

export const selectUserByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectUserById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectUserByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectUserByCode = (code: string) =>
  createSelector(selectAll, (entities) => {
    return entities.find((user) => user.data?.code === code);
  });

export const getAllUsers = selectAll;

export const getAllUserData = createSelector(getAllUsers, (users) =>
  users.map((user) => user.data),
);

export const selectWorkResourceAvatar = (ids: string[]) =>
  createSelector(selectEntities, (entities) => {
    const map: Map<string, string> = new Map();
    ids.forEach((id) => {
      const item = entities[id];
      if (item && item.data && entities[id].data.mimeType) {
        map.set(
          id,
          'data:' +
            entities[id].data.mimeType +
            ';base64,' +
            entities[id].data.profileImage,
        );
      }
    });
    return map;
  });

export const selectUsersByIds = (userIds: string[]) =>
  createSelector(getAllUsers, (users) =>
    users.filter((u) => userIds.includes(u.id)).map((user) => user.data),
  );
