export class FileImagesSvgBundle {
  getAll(): string[] {
    return [
      'ai',
      'avi',
      'css',
      'csv',
      'doc',
      'docx',
      'eps',
      'exe',
      'file',
      'html',
      'jpeg',
      'jpg',
      'm4v',
      'mkv',
      'mov',
      'mp4',
      'mpeg',
      'mpg',
      'otf',
      'pdf',
      'php',
      'png',
      'ppt',
      'pptx',
      'psd',
      'rar',
      'svg',
      'tif',
      'tiff',
      'ttf',
      'txt',
      'wav',
      'xls',
      'xlsm',
      'xlsx',
      'zip',
    ];
  }
}
