import {Component, HostBinding, Input} from '@angular/core';
import {FrameworkTooltipModule} from '@tsm/framework/tooltip';
import {NgIf} from '@angular/common';

@Component({
  selector: 'dtl-form-field-label',
  templateUrl: './form-field-label.component.html',
  standalone: true,
  imports: [FrameworkTooltipModule, NgIf],
})
export class FormFieldLabelComponent {
  @HostBinding('class')
  styleClass = 'dtl-form-field-label xl:col-3 lg:col-4 md:col-5 sm:col-12';

  @Input() labelForId: string;

  @Input() helpMessage: string;

  @Input() helpMessageStatic: string;

  @Input() maxWidthRemTooltip = 28;

  @Input() extensionClass: string;
}
