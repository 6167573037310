import {createAction, props} from '@ngrx/store';
import {TableColumnConfig} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadTableColumnConfigById = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigById',
  props<{
    id: string;
  }>(),
);
export const LoadTableColumnConfigByIdSuccess = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigById Success',
  props<{
    tableColumnConfig: TableColumnConfig;
  }>(),
);
export const LoadTableColumnConfigByIdError = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadTableColumnConfigsByListingId = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingId',
  props<{
    payload: any;
    loadType: 'TABLE' | 'PROFILES';
  }>(),
);
export const LoadTableColumnConfigsByListingIdSuccess = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingId Success',
  props<{
    tableColumnConfigs: TableColumnConfig[];
    payload: any;
    loadType: 'TABLE' | 'PROFILES';
  }>(),
);
export const LoadTableColumnConfigsByListingIdError = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingId Error',
  props<{
    payload: any;
    loadType: 'TABLE' | 'PROFILES';
    error: TsmError;
  }>(),
);

export const LoadTableColumnConfigsByListingType = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingType',
  props<{
    listingTypeCode: string;
  }>(),
);
export const LoadTableColumnConfigsByListingTypeSuccess = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingType Success',
  props<{
    tableColumnConfigs: TableColumnConfig[];
  }>(),
);
export const LoadTableColumnConfigsByListingTypeError = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingType Error',
  props<{
    error: TsmError;
  }>(),
);

export const LoadTableColumnConfigsByListingTypes = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingTypes',
  props<{
    listingTypeCodes: string[];
  }>(),
);
export const LoadTableColumnConfigsByListingTypesSuccess = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingTypes Success',
  props<{
    tableColumnConfigs: TableColumnConfig[];
  }>(),
);
export const LoadTableColumnConfigsByListingTypesError = createAction(
  '[TableColumnConfig] Load LoadTableColumnConfigsByListingTypes Error',
  props<{
    error: TsmError;
  }>(),
);

export const UpsertTableColumnConfig = createAction(
  '[TableColumnConfig] Upsert TableColumnConfig',
  props<{
    tableColumnConfig: TableColumnConfig;
    listingId: string;
  }>(),
);
export const UpsertTableColumnConfigSuccess = createAction(
  '[TableColumnConfig] Upsert TableColumnConfig Success',
  props<{
    tableColumnConfig: TableColumnConfig;
    listingId: string;
  }>(),
);
export const UpsertTableColumnConfigError = createAction(
  '[TableColumnConfig] Upsert TableColumnConfig Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const PatchTableColumnConfig = createAction('[TableColumnConfig] Patch TableColumnConfig', props<{ tableColumnConfig: TableColumnConfig, listingId: string }>());
export const PatchTableColumnConfigSuccess = createAction('[TableColumnConfig] Patch TableColumnConfig Success', props<{ tableColumnConfig: TableColumnConfig, listingId: string }>());
export const PatchTableColumnConfigError = createAction('[TableColumnConfig] Patch TableColumnConfig Error', props<{ id: string; error: TsmError }>());

export const DeleteTableColumnConfig = createAction(
  '[TableColumnConfig] Delete TableColumnConfig',
  props<{
    id: string;
    listingId: string;
    tableColumnListingId: string;
  }>(),
);
export const DeleteTableColumnConfigSuccess = createAction(
  '[TableColumnConfig] Delete TableColumnConfig Success',
  props<{
    id: string;
    listingId: string;
    tableColumnListingId: string;
  }>(),
);
export const DeleteTableColumnConfigError = createAction(
  '[TableColumnConfig] Delete TableColumnConfig Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadTableColumnConfig = createAction(
  '[TableColumnConfig] Load TableColumnConfig',
);
export const LoadTableColumnConfigSuccess = createAction(
  '[TableColumnConfig] Load TableColumnConfig Success',
  props<{
    tableColumnConfigs: TableColumnConfig[];
  }>(),
);
export const LoadTableColumnConfigError = createAction(
  '[TableColumnConfig] Load TableColumnConfig Error',
  props<{
    error: TsmError;
  }>(),
);

export const DiffTableColumnConfig = createAction(
  '[TableColumnConfig] Diff TableColumnConfig',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffTableColumnConfigSuccess = createAction(
  '[TableColumnConfig] Diff TableColumnConfig Success',
  props<{
    tableColumnConfigs: TableColumnConfig[];
    listingId: string;
  }>(),
);
export const DiffTableColumnConfigError = createAction(
  '[TableColumnConfig] Diff TableColumnConfig Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
