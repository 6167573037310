import {createReducer, on} from '@ngrx/store';
import {KafkaTopic} from '../models';
import {
  LoadKafkaTopic,
  LoadKafkaTopicError,
  LoadKafkaTopicSuccess,
  SelectKafkaTopic,
} from '../actions';

export interface KafkaTopicState {
  kafkaTopics: KafkaTopic[];
  error: any;
  loading: boolean;
}

export const initialState: KafkaTopicState = {
  kafkaTopics: [],
  error: null,
  loading: false,
};

export const kafkaTopicReducer = createReducer(
  initialState,
  on(LoadKafkaTopic, (state, {}) => ({
    ...state,
    loading: true,
  })),

  on(LoadKafkaTopicSuccess, (state, {topics}) => ({
    ...state,
    kafkaTopics: topics,
    loading: false,
    error: null,
  })),

  on(LoadKafkaTopicError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(SelectKafkaTopic, (state, {key}) => ({
    ...state,
    kafkaTopics: state.kafkaTopics.map((t) => {
      if (t.key === key) {
        return {key: t.key, selected: true};
      }
      return {key: t.key, selected: false};
    }),
  })),
);
