import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {translation} from '../i18n';
import {
  LoadCountAttachments,
  LoadCountAttachmentsError,
  LoadCountAttachmentsSuccess,
} from '../actions';
import {AttachmentDataService} from '../services';

@Injectable()
export class CountAttachmentsEffects {
  private readonly ATTACHMENT_TYPE_API_PATH = 'v1/attachment-types';

  translation = translation;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCountAttachments),
      concatMap(({ownerType, ownerIds}) =>
        this.attachmentDataService.countAttachments(ownerIds, ownerType).pipe(
          map((env) => {
            return env.success
              ? LoadCountAttachmentsSuccess({
                  countAttachments: (Array.isArray(ownerIds)
                    ? ownerIds
                    : [ownerIds]
                  ).map((x) => ({
                    id: x,
                    count: env.data[x] || 0,
                    dateInserted: new Date(),
                  })),
                })
              : LoadCountAttachmentsError(env.error);
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private attachmentDataService: AttachmentDataService,
  ) {}
}
