import {Injectable} from '@angular/core';
import {IdentifiedPack, SharedRequestValidUntil} from '@tsm/framework/root';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {EntityIdentif, User, UserGroup, UserUserGroup} from '../model';
import {CommonApiService} from '../service';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {translation} from '@tsm/shared-i18n';

@Docs({
  detail:
    'userUserGroupByUserId(userId: string): IdentifiedPack<UserUserGroup[]>',
  description: {
    about: translation.shared.userGroupEntityById,
    params: [
      {name: translation.shared.userId, about: translation.shared.idOfUserThe},
    ],
    example: "userUserGroupByUserId('9d1548e7-26df-4ec2-8931-b86581b21672')",
  },
})
@Injectable()
export class UserUserGroupDataSource
  implements DtlDataSource<IdentifiedPack<UserUserGroup[]>>
{
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private commonApiService: CommonApiService<UserUserGroup, UserUserGroup>,
  ) {}

  pushParams(params) {
    this.value.next(params);
  }

  @SharedRequestValidUntil()
  getRemoteData(userId: string): Observable<IdentifiedPack<UserUserGroup[]>> {
    return this.commonApiService
      .getAllFilterable(EntityIdentif.USER_USER_GROUP, 'user.id__eq=' + userId)
      .pipe(
        map((val) => {
          return {
            data: val.success ? val.data : null,
            id: userId,
            loading: false,
            error: false,
          };
        }),
      );
  }

  create(): Observable<IdentifiedPack<UserUserGroup[]>> {
    return this.value.asObservable().pipe(
      filter((x: string[]) => x[0] != null),
      switchMap((x: string[]) => this.getRemoteData(x[0])),
    );
  }
}
