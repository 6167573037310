/* Identifikace entit pro REST API,
   @see Effects */
export enum EntityIdentif {
  ROLE = 'role',
  ROLE_PRIV = 'role-priv',
  PRIV = 'priv',
  USER_GROUP = 'user-group',
  USER_INFO = 'user',
  USER_ROLE = 'user-role',
  USER_USER_GROUP = 'user-user-group',
  USER_SKILL = 'skill-assignment',
  USER_GROUP_SKILL = 'user-group-skills',
  REGION_ASSIGNMENT = 'region-assignment',
  USER_GROUP_REGION = 'user-group-region',
  ACCESS_RULES = 'access-rules',
  ROLE_ACCESS_RULE = 'role-access-rules',
  REFRESH_TOKENS = 'refresh-tokens',
}
