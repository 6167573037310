import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  DeleteUserGroupSuccess,
  DiffUserGroup,
  DiffUserGroupError,
  DiffUserGroupSuccess,
  LoadBatchUserGroupByCode,
  LoadBatchUserGroupByCodeError,
  LoadBatchUserGroupByCodeSuccess,
  LoadUserGroupByCodeError,
  LoadUserGroupByCodeSuccess,
  LoadUserGroupById,
  LoadUserGroupByIdError,
  LoadUserGroupByIdsError,
  LoadUserGroupByIdsSuccess,
  LoadUserGroupByIdSuccess,
  LoadUserGroups,
  LoadUserGroupsError,
  LoadUserGroupsSuccess,
  SaveUserGroupNotificationConfigError,
  SaveUserGroupNotificationConfigSuccess,
  UpsertUserGroupSuccess,
} from '../actions';
import {UserGroup} from '../model';

export type UserGroupState = EntityState<IdentifiedPack<UserGroup>>;

export const adapter: EntityAdapter<IdentifiedPack<UserGroup>> =
  createEntityAdapter<IdentifiedPack<UserGroup>>({});

export const initialState: UserGroupState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadUserGroupsSuccess, (state, {entities}) => ({
    ...adapter.addMany(
      entities.map((userGroupOnly) => ({
        id: userGroupOnly.id,
        data: userGroupOnly,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadUserGroupById, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadUserGroupByIdSuccess,
    LoadUserGroupByCodeSuccess,
    (state, {userGroup}) =>
      adapter.upsertOne(
        {
          id: userGroup.id,
          data: userGroup,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(LoadBatchUserGroupByCode, (state, {code}) =>
    adapter.upsertOne(
      {
        id: code,
        data: null,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadBatchUserGroupByCodeSuccess, (state, {userGroups}) => {
    const entArray = Object.keys(state.entities).map(
      (it) => state.entities[it],
    );
    const relatedValIds = entArray
      .filter(
        (ent) => ent.data == null && userGroups.some((x) => x.code === ent.id),
      )
      .map((ent) => ent.id);
    const newState = adapter.removeMany(relatedValIds, state);
    return adapter.upsertMany(
      userGroups.map((item) => ({
        id: item.id,
        data: item,
        loading: false,
        error: null,
      })),
      newState,
    );
  }),

  on(LoadBatchUserGroupByCodeError, (state, {codes, error}) => {
    return adapter.upsertMany(
      codes.map((code) => ({
        id: code,
        loading: false,
        error: error,
      })),
      state,
    );
  }),

  on(LoadUserGroupByIdError, (state, {id, error}) =>
    adapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadUserGroupByCodeError, (state, {code, error}) =>
    adapter.upsertOne(
      {
        id: code,
        data: {id: code, name: code, code: code},
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadUserGroups, (state, {}) => ({...state, loading: true})),

  on(LoadUserGroupsError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(UpsertUserGroupSuccess, (state, {userGroup}) =>
    adapter.upsertOne(
      {
        id: userGroup.id,
        data: userGroup,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserGroupByIdsSuccess, (state, {userGroups}) => ({
    ...adapter.upsertMany(
      userGroups.map((ug) => ({
        id: ug.id,
        error: null,
        loading: false,
        data: ug,
      })),
      state,
    ),
  })),

  on(LoadUserGroupByIdsError, (state, {ids, error}) => ({
    ...adapter.upsertMany(
      ids.map((id) => ({
        id: id,
        error: error,
        loading: false,
      })),
      state,
    ),
  })),

  on(DeleteUserGroupSuccess, (state, userGroup) =>
    adapter.removeOne(userGroup.id, state),
  ),

  on(DiffUserGroup, (state, {diffEntities}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffUserGroupSuccess, (state, {userGroups}) =>
    adapter.upsertMany(
      userGroups.map((userGroup) => {
        return {
          id: userGroup.id,
          data: {
            ...state.entities[userGroup.id].data,
            ...userGroup,
          },
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffUserGroupError, (state, {diffEntities, error}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),

  on(SaveUserGroupNotificationConfigSuccess, (state, {id, config}) =>
    adapter.updateOne(
      {
        id: id,
        changes: {
          data: {
            ...state.entities[id].data,
            notificationConfig: config,
          },
        },
      },
      {...state, error: null, loading: false},
    ),
  ),

  on(SaveUserGroupNotificationConfigError, (state, {id, error}) =>
    adapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          error: error,
        },
      },
      state,
    ),
  ),
);
