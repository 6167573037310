import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {ConfigType} from '../models';
import {
  DeleteConfigTypeSuccess,
  DiffConfigType,
  DiffConfigTypeError,
  DiffConfigTypeSuccess,
  LoadConfigTypeByCodeSuccess,
  LoadConfigTypeById,
  LoadConfigTypeByIdError,
  LoadConfigTypeByIdSuccess,
  LoadConfigTypeSuccess,
  PatchConfigType,
  PatchConfigTypeError,
  PatchConfigTypeSuccess,
  UpsertConfigType,
  UpsertConfigTypeSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';

export type ConfigTypeState = EntityState<IdentifiedPack<ConfigType>>;

export const configTypeAdapter: EntityAdapter<IdentifiedPack<ConfigType>> =
  createEntityAdapter<IdentifiedPack<ConfigType>>({});

const initialState: ConfigTypeState = configTypeAdapter.getInitialState({});

export const configTypeReducer = createReducer(
  initialState,

  on(LoadConfigTypeSuccess, (state, {configTypes}) => ({
    ...configTypeAdapter.addMany(
      configTypes.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadConfigTypeById, (state, {id}) =>
    configTypeAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadConfigTypeByIdSuccess,
    LoadConfigTypeByCodeSuccess,
    (state, {entity}) =>
      configTypeAdapter.upsertOne(
        {
          id: entity.id,
          data: entity,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(LoadConfigTypeByIdError, (state, {id, error}) =>
    configTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertConfigType, (state, {configType}) =>
    configTypeAdapter.updateOne(
      {
        id: configType.id,
        changes: {
          ...state.entities[configType.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertConfigTypeSuccess, (state, {configType}) =>
    configTypeAdapter.upsertOne(
      {
        id: configType.id,
        data: configType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteConfigTypeSuccess, (state, configType) =>
    configTypeAdapter.removeOne(configType.id, state),
  ),

  on(PatchConfigType, (state, {configType}) =>
    configTypeAdapter.updateOne(
      {
        id: configType.id,
        changes: {
          ...state.entities[configType.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(PatchConfigTypeSuccess, (state, {id, configType}) =>
    configTypeAdapter.upsertOne(
      {
        id: id,
        data: configType,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(PatchConfigTypeError, (state, {id, error}) =>
    configTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DiffConfigType, (state, {diffEntities}) =>
    configTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffConfigTypeSuccess, (state, {configTypes}) =>
    configTypeAdapter.upsertMany(
      configTypes.map((configType) => {
        return {
          id: configType.id,
          data: configType,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffConfigTypeError, (state, {diffEntities, error}) =>
    configTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
