import {Inject, Injectable, Optional} from '@angular/core';
import {NOTIFICATION_API_CONFIG} from '../di/token';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(
    @Optional()
    @Inject(NOTIFICATION_API_CONFIG)
    private notificationConfig: NotificationOptions,
  ) {}

  // @ts-ignore
  notify(title: string, optionsParams: NotificationOptions = {}) {
    // Let's check if the browser supports notifications
    const options: NotificationOptions =
      this.notificationConfig != null
        ? {...this.notificationConfig, ...optionsParams}
        : optionsParams;

    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
      return null;
    }
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      return new Notification(title, options);
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          return new Notification(title, options);
        }
        return null;
      });
    } else {
      return null;
    }
  }
}
