import {ModuleWithProviders, NgModule} from '@angular/core';

@NgModule({
  imports: [],
})
export class HistoryModule {
  // keep historyService constructor
  constructor() {}

  static forRoot(): ModuleWithProviders<HistoryModule> {
    return {
      ngModule: HistoryModule,
      providers: [],
    };
  }
}
