@if (!readonly) {
  <p-inputMask
    [inputId]="inputId"
    tabindex="{{ tabindex?.toString() }}"
    [mask]="mask || ''"
    [placeholder]="
      localizationData
        | localizationDataTranslate: 'config.maskPlaceholder' : placeholder
    "
    [ngModel]="val"
    (ngModelChange)="setValue($event)"
    (onFocus)="maskFocused()"
    (onBlur)="maskBlured()"
    data-cy="dtl-form-input-mask"
  >
  </p-inputMask>
} @else {
  <span
    [ngStyle]="{display: 'inline-block'}"
    [ngClass]="val ? 'readonly-text' : 'readonly-text empty'"
  >
    {{ val ? val : 'none' }}
  </span>
}
