import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';

import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {translation as translationShared} from '@tsm/shared-i18n';
import {Color, colors} from '../../models';

@Component({
  selector: 'tsm-simple-color-picker',
  templateUrl: './tsm-simple-color-picker.component.html',
  styleUrls: ['./tsm-simple-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleColorPickerComponent),
      multi: true,
    },
  ],
})
export class SimpleColorPickerComponent extends AbstractControlValueAccessor {
  translationShared = translationShared;

  colors: Color[];

  @Input()
  canBeEmpty = false;

  @Input()
  showColorLabel = false;

  private _theme: 'all' | 'dark' | 'light' = 'light';

  get theme(): 'all' | 'dark' | 'light' {
    return this._theme;
  }

  @Input()
  set theme(value: 'all' | 'dark' | 'light') {
    this._theme = value;
    if (value !== 'all') {
      this.colors = colors.filter((x) => x.name.toLowerCase().includes(value));
    } else {
      this.colors = colors;
    }
  }

  /**
   * Zapne možnost zrušit vybranou barvu
   * Při zrušení výběru barvy bude elementu nastavena bíla barva
   */
  @Input()
  emptyIsWhite = false;

  /**
   * Změní defaultní bílou na základní barvu textu (ze šablony)
   * Použít v případě, že se obarvuje text nebo ikona nikoli pozadí
   */
  @Input()
  textColorDefault = false;

  /**
   * Custom colors
   * Example [{name: 'BLUE', flag: 'background', color: '#b9dcf5'},}]
   * attribute flag is not mandatory
   */
  @Input()
  set customColorOptions(customColors: Color[]) {
    this.colors = customColors.map((customColor) => {
      return {
        name: customColor.name.toUpperCase(),
        flag: customColor.flag || 'background',
        color: customColor.color,
      };
    });
  }

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
    if (this.theme === 'light') {
      this.colors = colors.filter(
        (x) => !x.name.toLowerCase().includes('dark'),
      );
    }
  }

  colorSelected(option: Color, $event) {
    $event.stopPropagation();
    if (this.val === option.color && this.emptyIsWhite) {
      this.val = this.textColorDefault ? null : '#ffffff';
    } else {
      this.val = option.color;
    }
  }
}
