import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {CommentTypeModel} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommentTypeDataService {
  private readonly COMMENT = `commentTypes`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getEntity(id: string): Observable<Envelope<CommentTypeModel>> {
    return this.apiService.get<CommentTypeModel, CommentTypeModel>(
      this.BASE_URL + `/${this.COMMENT}/${id}`,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  getEntityByCode(code: string): Observable<Envelope<CommentTypeModel>> {
    return this.apiService.get<CommentTypeModel, CommentTypeModel>(
      this.BASE_URL + `/${this.COMMENT}/code/${code}`,
    );
  }

  deleteEntity(id: string): Observable<Envelope<CommentTypeModel>> {
    return this.apiService.delete<CommentTypeModel, CommentTypeModel>(
      this.BASE_URL + `/${this.COMMENT}/${id}`,
    );
  }

  postEntity(entity: CommentTypeModel): Observable<Envelope<CommentTypeModel>> {
    return this.apiService.post<CommentTypeModel, CommentTypeModel>(
      this.BASE_URL + `/${this.COMMENT}`,
      entity,
    );
  }

  putEntity(id: string, entity: CommentTypeModel): Observable<any> {
    return this.apiService.put<CommentTypeModel, CommentTypeModel>(
      this.BASE_URL + `/${this.COMMENT}/${id}`,
      entity,
    );
  }

  upsertEntity(entity: CommentTypeModel, id: string) {
    if (id) {
      return this.putEntity(id, entity);
    } else {
      return this.postEntity(entity);
    }
  }

  getAll(): Observable<any> {
    return this.apiService.get<CommentTypeModel[], CommentTypeModel[]>(
      this.BASE_URL + `/${this.COMMENT}`,
    );
  }
}
