import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/ordering/plugins-i18n';
import {of} from 'rxjs';

import {
  controlsDetailConfiguration,
  controlsOrderingNewConfiguration,
  controlsOrderLineDetailConfiguration,
  controlsTypeDetailConfiguration,
} from './tsm-controls-configuration';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const orderingPlugin: Plugin = {
  menus: [
    {
      key: 'ordering',
      priority: 1700,
      label: 'menu.ordering.ordering',
      icon: 'tsm-icon-ordering',
      privilege: [
        'Ord.Order.ViewList',
        'Ord.Quote.ViewList',
        'Ord.Registers.OrderType.View',
      ],
      items: [
        {
          key: 'order',
          label: 'menu.ordering.order',
          icon: 'tsm-icon-add',
          routerLink: ['/ordering/order'],
          privilege: ['Ord.Order.ViewList'],
        },
        {
          key: 'quote',
          label: 'menu.ordering.quote',
          icon: 'tsm-icon-contract-1',
          routerLink: ['/ordering/quote'],
          privilege: ['Ord.Quote.ViewList'],
        },
        {
          key: 'ORDERING_REGISTERS',
          label: 'menu.ordering.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Ord.Registers.OrderType.ViewList',
            'Ord.Registers.OrderStatus.ViewList',
          ],
          items: [
            {
              key: 'order-type',
              label: 'menu.ordering.orderType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/ordering/order-type'],
              privilege: ['Ord.Registers.OrderType.ViewList'],
            },
            {
              key: 'order-status',
              label: 'menu.ordering.orderStatus',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/ordering/order-status'],
              privilege: ['Ord.Registers.OrderStatus.ViewList'],
            },
          ],
        },
      ],
    },
    {
      key: 'provisioning',
      priority: 1700,
      label: 'menu.provisioning.provisioning',
      icon: 'tsm-icon-provisioning',
      privilege: ['Prov.Order.ViewList'],
      items: [
        {
          key: 'provisioning-order',
          label: 'menu.provisioning.order',
          icon: 'tsm-icon-add',
          routerLink: ['/ordering/order'],
          privilege: ['Prov.Order.ViewList'],
        },
      ],
    },
    {
      key: 'WFM',
      priority: 1600,
      label: 'menu.wfm.wfm',
      icon: 'tsm-icon-time-management',
      privilege: [
        // 'Wfm.Order.ViewList',
        'Wfm.Planner',
        'Wfm.MyTasks',
        'Wfm.Registers',
      ],
      items: [
        // {
        //   key: 'WFM-ORDER',
        //   label: 'menu.wfm.workOrders',
        //   icon: 'tsm-icon-add',
        //   routerLink: ['/ordering/order'],
        //   privilege: ['Wfm.Order.ViewList'],
        //   priority: 1000
        // },
        {
          key: 'WFM-DISP',
          label: 'menu.wfm.dispatcher',
          icon: 'tsm-icon-time',
          routerLink: ['/ordering/disp'], // TODO napojit dispecer od Kuby
          privilege: ['false'],
          priority: 900,
        },
        {
          key: 'wfm-planner',
          priority: 800,
          label: 'menu.wfm.scheduler',
          icon: 'tsm-icon-work-time',
          routerLink: ['/wfm-planner/planner'],
          privilege: ['Wfm.Planner'],
        },
        /*{
          key: 'wfm-map',
          priority: 2101,
          label: 'menu.wfm.routeMap',
          icon: 'tsm-icon-planner',
          routerLink: ['/wfm-planner/map'],
          privilege: ['true']
        },*/
        {
          key: 'wfm-my-task',
          priority: 700,
          label: 'menu.wfm.myTasks',
          icon: 'tsm-icon-my-tasks',
          routerLink: ['/wfm/my-task'],
          privilege: ['Wfm.MyTasks'],
        },
        {
          key: 'WFM_REGISTERS',
          label: 'menu.wfm.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Wfm.Registers.WorkCatalog.ViewList',
            'Wfm.Registers.MaterialCatalog.ViewList',
          ],
          priority: 600,
          items: [],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'ordering',
      loadChildren: () =>
        import('@tsm/ordering/components').then((x) => x.OrderingModule),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/ordering/widgets').then((x) => x.OrderingWidgetsModule),
      definitions: [
        {
          selector: 'tsm-order-status-filter',
          name: translationPlugin.orderingPlugins.filters.tsmOrderStatusFilter,
          description:
            translationPlugin.orderingPlugins.filters
              .tsmOrderStatusFilterDescription,
        },
        {
          selector: 'tsm-order-type-filter',
          name: translationPlugin.orderingPlugins.filters.tsmOrderTypeFilter,
          description:
            translationPlugin.orderingPlugins.filters
              .tsmOrderTypeFilterDescription,
        },
        {
          selector: 'tsm-quote-status-filter',
          name: translationPlugin.orderingPlugins.filters.tsmQuoteStatusFilter,
          description:
            translationPlugin.orderingPlugins.filters
              .tsmQuoteStatusFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/ordering/widgets').then((x) => x.OrderingWidgetsModule),
      definitions: [
        {
          selector: 'orderLoader',
          name: translationPlugin.orderingPlugins.pipes.orderLoader,
          description:
            translationPlugin.orderingPlugins.pipes.orderLoaderDescription,
        },
        {
          selector: 'orderStatusByCode',
          name: translationPlugin.orderingPlugins.pipes.orderStatusByCode,
          description:
            translationPlugin.orderingPlugins.pipes
              .orderStatusByCodeDescription,
        },
        {
          selector: 'orderLoaderKey',
          name: translationPlugin.orderingPlugins.pipes.orderLoaderKey,
          description:
            translationPlugin.orderingPlugins.pipes.orderLoaderKeyDescription,
        },
        {
          selector: 'orderStatusById',
          name: translationPlugin.orderingPlugins.pipes.orderStatusById,
          description:
            translationPlugin.orderingPlugins.pipes.orderStatusByIdDescription,
        },
        {
          selector: 'orderTypeByCode',
          name: translationPlugin.orderingPlugins.pipes.orderTypeByCode,
          description:
            translationPlugin.orderingPlugins.pipes.orderTypeByCodeDescription,
        },
        {
          selector: 'orderTypeById',
          name: translationPlugin.orderingPlugins.pipes.orderTypeById,
          description:
            translationPlugin.orderingPlugins.pipes.orderTypeByIdDescription,
        },
        {
          selector: 'productOrderLines',
          name: translationPlugin.orderingPlugins.pipes.productOrderLines,
          description:
            translationPlugin.orderingPlugins.pipes
              .productOrderLinesDescription,
        },
        {
          selector: 'quoteLoader',
          name: translationPlugin.orderingPlugins.pipes.quoteLoader,
          description:
            translationPlugin.orderingPlugins.pipes.quoteLoaderDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/ordering/widgets').then((x) => x.OrderingWidgetsModule),
      definitions: [
        {
          selector: 'tsm-order-line-bookmark',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-line-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.orderLinesBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderLinesBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-order-line-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-quotes-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-quotes-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.quotesBookmark.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.quotesBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-quotes-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-orders-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-orders-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.ordersBookmark.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.ordersBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-orders-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-order-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Ordering,
              formField: 'order',
              name: translationShared.shared.widgets.orderSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.orderSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-order-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-type-lov',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Ordering,
              group: GroupPosition.Secondary,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderTypeSelect
                  .widgetDescription,
              icon: DesignerIconsEnum.select,
              formField: 'orderType',
              name: translationShared.shared.widgets.orderTypeSelect.widgetName,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.orderTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-order-type-lov',
                  readonly: false,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-status-lov',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-status-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Ordering,
              group: GroupPosition.Secondary,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderStatusSelect
                  .widgetDescription,
              icon: DesignerIconsEnum.select,
              formField: 'orderStatus',
              name: translationShared.shared.widgets.orderStatusSelect
                .widgetName,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.orderStatusSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-order-status-lov',
                  readonly: false,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-order-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.orderStatusBar.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderStatusBar
                  .widgetDescription,
              group: GroupPosition.Primary,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-order-steps',
                },
              },
            },
          ],
        },
        {selector: 'tsm-ordering-task-detail'},
        {selector: 'tsm-order-card'},
        {
          selector: 'tsm-order-task-list',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-task-list-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.orderTaskList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderTaskList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.orderTaskList.inWidgetTitle,
                widget: {
                  type: 'tsm-order-task-list',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {selector: 'tsm-order-item-card'},
        {selector: 'tsm-quote-card'},
        {selector: 'tsm-order-new-container'},
        {
          selector: 'tsm-order-line-hardware',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-line-hardware-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.menu.ordering.orderHardwareLine,
              icon: 'p-icon-shopping-cart',
              name: translationShared.menu.ordering.orderHardwareLine,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-order-line-hardware',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.orderList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderList.widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                title: translationShared.shared.widgets.orderList.inWidgetTitle,
                widget: {
                  type: 'tsm-order-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-line-widget',
          widgetMapper: (schema: FluentSchema) => {
            return {
              title: schema.title,
            };
          },
          loadComponent: () =>
            import(
              '@tsm/ordering/widgets/order-line-widget/order-line-widget'
            ).then((x) => x.OrderLineWidgetComponent),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-line-widget-editor',
              loadComponent: () =>
                import(
                  '@tsm/ordering/widgets/order-line-widget/order-line-widget-editor'
                ).then((x) => x.OrderLineWidgetEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.orderLine.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.orderLine.widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                title: translationShared.shared.widgets.orderLine.inWidgetTitle,
                widget: {
                  type: 'tsm-order-line-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-order-list-related-entity-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-order-list-related-entity-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Ordering,
              name: translationShared.shared.widgets.relatedEntityOrderList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.relatedEntityOrderList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.relatedEntityOrderList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-order-list-related-entity-widget',
                },
                type: 'layout',
                config: {},
              },
            },
          ],
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'Order',
      url: (entityId) => {
        return of('/ordering/order/' + entityId);
      },
    },
    {
      entityType: 'OrderType',
      url: (entityId) => {
        return of('/ordering/order-type/' + entityId);
      },
    },
    {
      entityType: 'OrderStatus',
      url: (entityId) => {
        return of('/ordering/order-status/' + entityId);
      },
    },
    {
      entityType: 'OrderLine',
      url: (entityId) => {
        return of('/ordering/order-line/' + entityId);
      },
    },
  ],
  layoutControls: [
    controlsOrderingNewConfiguration,
    controlsDetailConfiguration,
    controlsTypeDetailConfiguration,
    controlsOrderLineDetailConfiguration,
  ],
};
