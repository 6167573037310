import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {
  LocalizationVersionData,
  TranslocoService,
} from '@tsm/framework/translate';
import * as objectPath from 'object-path';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Pipe({
    name: 'localizationDataTranslate',
    pure: false,
    standalone: true,
})
export class LocalizationDataTranslatePipe implements PipeTransform, OnDestroy {
  private value?: string;
  private oldLocData: LocalizationVersionData;
  private oldKey: string;
  private oldLocDefault: string;
  private sub: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {}

  transform(
    locData: LocalizationVersionData,
    key: string,
    locDefault = '',
    sync = false,
  ): string {
    if (locData == null) {
      return this.translocoService.translate(locDefault);
    }

    // sync volani z TS
    if (sync) {
      const lang = this.translocoService.getActiveLang();
      const langObj = locData[lang];
      return this.translocoService.translate(
        locData[lang] != null
          ? objectPath.get(langObj, key) || locDefault
          : locDefault,
      );
    }

    // volani z sablony
    if (
      !this.equalsLocData(this.oldLocData, locData) ||
      this.oldKey !== key ||
      this.oldLocDefault !== locDefault
    ) {
      this.oldLocData = locData;
      this.oldKey = key;
      this.oldLocDefault = locDefault;

      const currentLocDefault = locDefault;
      const currentKey = key;
      this.sub?.unsubscribe();
      this.sub = this.translocoService.langChanges$
        .pipe(startWith(this.translocoService.getActiveLang()))
        .subscribe((lng) => {
          const langObj = locData[lng];
          this.value = this.translocoService.translate(
            locData[lng] != null
              ? objectPath.get(langObj, currentKey) || currentLocDefault
              : currentLocDefault,
          );
          this.cdr.markForCheck();
        });
    }

    return this.value;
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  private equalsLocData(
    oldData: LocalizationVersionData,
    newData: LocalizationVersionData,
  ) {
    if (oldData != newData) {
      return false;
    }
    if (oldData != null && newData != null) {
      if (Object.keys(oldData).length !== Object.keys(newData).length) {
        return false;
      }
      for (const k of Object.keys(oldData)) {
        if (oldData[k] !== newData[k]) {
          return false;
        }
      }
    }

    return true;
  }
}
