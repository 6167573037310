import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {notificationTemplateAdapter} from '../reducers/notification-template.reducer';
import {RouterStateUrl} from '@tsm/framework/root';
import {NotificationTemplate} from '../models';

const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getNotificationTemplateState = createSelector(
  getDmsState,
  (x) => x.notificationTemplate,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  notificationTemplateAdapter.getSelectors(getNotificationTemplateState);

export const selectNotificationTemplateEntities = createSelector(
  getNotificationTemplateState,
  selectEntities,
);

export const selectNotificationTemplateByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectNotificationTemplateById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectNotificationTemplatesByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((e) => codes.includes(e.data.code)),
  );

export const selectNotificationTemplateByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((e) => e.data?.code === code),
  );

export const selectNotificationTemplatesByFilter = (
  filter: (notificationTemplate: NotificationTemplate) => boolean,
) =>
  createSelector(selectAll, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter((entity) => filter(entity))
      .sort((i, j) => i.name.localeCompare(j.name));
  });
