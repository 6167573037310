import {Injectable, ErrorHandler} from '@angular/core';
import {AppLoggerService} from '@tsm/framework/logger';
import {from, of, retry, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private terminator$ = new Subject<void>(); // Subject to clean up retries
  private retryAttempts = 2;
  constructor(private appLogger: AppLoggerService) {}

  handleError(error: any) {
    // Attempt to reload the failing chunk
    if (error.name === 'ChunkLoadError') {
      from(import(error.request))
        .pipe(
          retry(this.retryAttempts), // Retry the import operation
          catchError((err) => {
            console.error('Retry attempts failed:', err);
            return of(null);
          }),
          takeUntil(this.terminator$), // Clean up retries if component destroyed
        )
        .subscribe();
    }
    console.error(error);

    this.appLogger
      .createErrorLog(error)
      .withScope(AppErrorHandler.name)
      .execute();
  }
}
