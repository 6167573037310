<tsm-monaco-editor
  [(ngModel)]="val"
  [defaultHeight]="widgetHeight ? widgetHeight : inline ? '1.5em' : null"
  [options]="editorOptions"
  [inline]="inline"
  [resizable]="resizable"
  language="json"
  [updateIfDirty]="updateIfDirty"
  (init)="onEditorInitialized($event)"
  (touchedChange)="onTouched(true)"
  (markersChange)="onMarkersChanged($event)"
  data-cy="dtl-form-input-json"
></tsm-monaco-editor>
