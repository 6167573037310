export const en = {
  tablePaginator: {
    paginatorSettings: 'Paginator settings',
    to: 'To',
    of: 'of',
    pageSize: 'Page size',
    pageSettings: 'Page settings',
    minPage: 'Min page is',
    maxPage: 'Max page is',
  },
};
