import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter} from '../reducers/user-image.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');

export const getUserImageState = createSelector(
  getUserManagementState,
  (userImageState) => userImageState.userImage,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserImageState);

export const selectUserImageById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectUserImageByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

// export const selectWorkResourceAvatar = (ids: string[]) => createSelector(
//   selectEntities,
//   entities => {
//     const map: Map<string, string> = new Map();
//     ids.forEach(id => {
//       const item = entities[id];
//       if (item && item.data && entities[id].data.mimeType) {
//         map.set(id, 'data:' + entities[id].data.mimeType + ';base64,' + entities[id].data.profileImage);
//       }
//     });
//     return map;
//   }
// );
