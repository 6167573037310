import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  DeleteAllRolePrivs,
  DeleteAllRolePrivsError,
  DeleteAllRolePrivsSuccess,
  DeleteRolePriv,
  DeleteRolePrivError,
  DeleteRolePrivSuccess,
  LoadRolePrivsByRoleId,
  LoadRolePrivsByRoleIdError,
  LoadRolePrivsByRoleIdSuccess,
  SaveAllRolePrivs,
  SaveAllRolePrivsError,
  SaveAllRolePrivsSuccess,
  UpsertRolePriv,
  UpsertRolePrivError,
  UpsertRolePrivSuccess,
} from '../actions';
import {EntityIdentif, RolePriv} from '../model';
import {CommonApiService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';

@Injectable()
export class RolePrivEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<RolePriv, RolePriv>,
    private store: Store<any>,
    private ts: ToastService,
  ) {}

  loadByRoleId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRolePrivsByRoleId),
      exhaustMap(({roleId}) => {
        return this.commonApiService
          .getAllFilterable(EntityIdentif.ROLE_PRIV, 'role.id__eq=' + roleId)
          .pipe(
            map((env) => {
              return env.success
                ? LoadRolePrivsByRoleIdSuccess({entities: env.data})
                : LoadRolePrivsByRoleIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRolePriv),
      concatMap(({rolePriv, listingId}) =>
        this.commonApiService
          .upsertEntity(EntityIdentif.ROLE_PRIV, rolePriv, rolePriv.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .rolePrivilegeSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertRolePrivSuccess({
                  rolePriv: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .rolePrivilegeSaveFailure,
                );
                return UpsertRolePrivError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAllRolePrivs),
      concatMap(({rolePrivs, listingId}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.ROLE_PRIV, rolePrivs)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .rolePrivilegeSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return SaveAllRolePrivsSuccess({
                  rolePrivs: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .rolePrivilegeSaveFailure,
                );
                return SaveAllRolePrivsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRolePriv),
      concatMap(({id, listingId}) =>
        this.commonApiService.deleteEntity(EntityIdentif.ROLE_PRIV, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects
                  .rolePrivilegeDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteRolePrivSuccess({id: id, listingId: listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects
                  .rolePrivilegeDeleteFailure,
              );
              return DeleteRolePrivError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllRolePrivs),
      concatMap(({ids, listingId}) =>
        this.commonApiService
          .deleteAllEntities(EntityIdentif.ROLE_PRIV, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .rolePrivilegeDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteAllRolePrivsSuccess({
                  ids: ids,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .rolePrivilegeDeleteFailure,
                );
                return DeleteAllRolePrivsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SaveAllRolePrivsSuccess,
        DeleteAllRolePrivsSuccess,
        DeleteRolePrivSuccess,
        UpsertRolePrivSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
