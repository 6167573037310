import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ConfigFormState, formAdapter} from '../reducers';
import {RouterStateUrl} from '@tsm/framework/root';
import {selectEntitySpecificationById} from './entity-specifications.selectors';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getFormState = createSelector(
  getConfigFormState,
  (formState) => formState.forms,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  formAdapter.getSelectors(getFormState);

export const selectFormById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectFormByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)).filter((x) => !x.loading),
  );

export const selectFormByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectFormByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find(
      (entity) => entity && entity.data && entity.data.code === code,
    ),
  );

export const selectFormByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter((x) => codes.includes(x?.data?.code))
      .filter((x) => !x.loading),
  );

export const selectFormByUserAndType = (userId: string, configTypeId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter(
      (entity) =>
        entity &&
        entity.data.whoInserted === userId &&
        entity.data.configType.id === configTypeId,
    ),
  );

export const selectAllFormsSorted = (asc: boolean) =>
  createSelector(selectAll, (entities) => {
    return entities
      .filter((ent) => ent.data)
      .sort((i1, i2) => {
        if (i1.data.name > i2.data.name) {
          return asc ? 1 : -1;
        } else {
          return asc ? -1 : 1;
        }
      });
  });

export const selectCharacteristicsAndFormSpecification = (
  entitySpecId: string,
  selector: string,
) =>
  createSelector(
    selectAll,
    selectEntitySpecificationById(entitySpecId),
    (entities, entitySpecification) => {
      const tmpCode = entitySpecification?.data?.formSpecification[selector];
      if (tmpCode) {
        const form = entities.find((x) => x.data?.code === tmpCode)?.data;
        const expandedCharacteristics =
          entitySpecification.data?.expandedCharacteristics ?? [];

        if (form != null) {
          return {
            form,
            characteristics: expandedCharacteristics,
          };
        }
        return {
          form: null,
          characteristics: expandedCharacteristics,
        };
      }
      return {
        form: null,
        characteristics: [],
      };
    },
  );
