import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/priv.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getPriv = createSelector(
  getUserManagementState,
  (privState) => privState.privReducer,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getPriv);

export const selectPrivByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectPrivById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectPrivByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data.code === code),
  );

export const selectAllPrivs = selectAll;
