import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  LoadBatchUserImageById,
  LoadBatchUserImageByIdError,
  LoadBatchUserImageByIdSuccess,
  LoadUserImageById,
  LoadUserImageByIdError,
  LoadUserImageByIds,
  LoadUserImageByIdsError,
  LoadUserImageByIdsSuccess,
  LoadUserImageByIdSuccess,
} from '../actions';
import {UserImage} from '../model';

export interface UserImageState
  extends EntityState<IdentifiedPack<UserImage>> {}

export const adapter: EntityAdapter<IdentifiedPack<UserImage>> =
  createEntityAdapter<IdentifiedPack<UserImage>>({});

export const initialState: UserImageState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadUserImageById, LoadBatchUserImageById, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserImageByIds, (state, {ids}) => ({
    ...adapter.upsertMany(
      ids.map((id) => ({
        id: id,
        loading: true,
        error: null,
      })),
      state,
    ),
  })),

  on(
    LoadBatchUserImageByIdSuccess,
    LoadUserImageByIdsSuccess,
    (state, {entities}) => ({
      ...adapter.upsertMany(
        entities.map((userOnly) => ({
          id: userOnly.id,
          data: userOnly,
          loading: false,
          error: null,
        })),
        state,
      ),
    }),
  ),

  on(LoadUserImageByIdSuccess, (state, {user}) =>
    adapter.upsertOne(
      {
        id: user.id,
        data: user,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserImageByIdError, (state, {id, error}) =>
    adapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(
    LoadBatchUserImageByIdError,
    LoadUserImageByIdsError,
    (state, {ids, error}) => ({
      ...adapter.upsertMany(
        ids.map((id) => ({
          id: id,
          loading: false,
          error: error,
        })),
        state,
      ),
    }),
  ),
);
