import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  Output,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import {
  logInvalidControls,
  TsmFormArray,
  TsmFormGroup,
} from '@tsm/framework/forms';
import {translation} from '@tsm/shared-i18n';
import {createClickableBackdropElement} from '../../../utils';
import {DomHandler} from 'primeng/dom';
import {fromEvent, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';

@Component({
  selector: 'tsm-form-inplace',
  template: `
    <div
      [class.p-state-editmode]="!context.readonly"
      [class.p-state-readonly]="readonly"
      [class.always-show-borders]="alwaysShowBorders"
    >
      <div *ngIf="isDraftValue" class="saved-as-draft-icon">
        <i
          class="pi pi-save p-color-blue"
          tsmTooltip="{{ translationShared.shared.savedDraft | transloco }}"
        ></i>
      </div>
      <div
        class="tsm-form-inplace-edit-icon"
        data-cy="crm-item-detail-details-panel"
      >
        <button
          *ngIf="context.readonly && !readonly"
          pButton
          pRipple
          class="p-button-info p-button-rounded"
          icon="pi pi-pencil"
          tsmTooltip="{{
            translationShared.shared.sharedComp.editBlock | transloco
          }}"
          (click)="onEdit()"
          data-cy="crm-detail-button-edit-details-on-selected-item"
        ></button>
      </div>
      <form [formGroup]="formGroup">
        <ng-container
          *ngTemplateOutlet="template; context: context"
        ></ng-container>
      </form>
      <div *ngIf="!context.readonly" class="tsm-form-inplace-action-buttons">
        <button
          pButton
          pRipple
          class="p-button-success p-button-text p-button-lg p-button-rounded"
          icon="pi pi-check"
          tsmTooltip="{{ translationShared.shared.save | transloco }}"
          (click)="onSave()"
        ></button>
        <ng-container *ngIf="showSaveDraft">
          <button
            pButton
            pRipple
            class="p-button-info p-button-text p-button-lg p-button-rounded"
            icon="pi pi-save"
            tsmTooltip="{{ translationShared.shared.saveDraft | transloco }}"
            (click)="onSaveDraft()"
          ></button>
        </ng-container>
        <button
          pButton
          pRipple
          class="p-button-danger p-button-text p-button-lg p-button-rounded"
          icon="pi pi-times"
          tsmTooltip="{{ translationShared.shared.cancel | transloco }}"
          (click)="onCancel()"
        ></button>
      </div>
    </div>
  `,
  styleUrls: ['./form-inplace.component.scss'],
})
export class FormInplaceComponent implements OnDestroy {
  translationShared = translation;

  @Input() alwaysShowBorders = false;

  @Input() isDraftValue = false;

  @Input() showSaveDraft = false;

  @HostBinding('class')
  get styleClass(): string {
    if (this.context.readonly) {
      return 'p-state-readonly' + ' ' + this.class;
    } else {
      return 'p-state-editmode' + ' ' + this.class;
    }
  }

  @ContentChild(TemplateRef, {read: TemplateRef}) contentedTemplate;

  @Input()
  formGroup: TsmFormGroup;

  @Input()
  readonly = false;

  /**
   * Hromadne přepíná stylu a pozice zobrazení labelu
   */
  @Input()
  labelPosition: 'left' | 'top' | 'right' = 'left';

  @Input()
  customValid = true;

  @Input()
  ignoreValidation = false;

  @Input()
  customValidFunction = null;

  @Input()
  class = '';

  @Output()
  save: EventEmitter<any> = new EventEmitter();

  @Output()
  saveDraft: EventEmitter<any> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  context = {
    $implicit: null,
    readonly: true,
  };

  externalForms = new TsmFormArray([]);
  _backdrop?: HTMLElement;
  _headerBackdrop?: HTMLElement;
  _originalIndex?: string;
  _originalPosition?: string;
  editableSubscriptions: Subscription[] = [];

  get template(): TemplateRef<any> {
    return this.contentedTemplate;
  }

  isEditing = false;
  private ctrlEnterList = [
    'dtl-tiptap-editor',
    'dtl-form-input-textarea',
    'tsm-monaco-editor',
  ];
  private isSingleChild = false;
  private isValid = () =>
    this.formGroup.valid &&
    this.customValid &&
    (this.customValidFunction ? this.customValidFunction() : true);

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {}

  onEdit() {
    this.isEditing = true;
    this.focusNearestInput();
    this.showBackdrop();
    this.subscribeEditableEvents();
    this.context = {...this.context, readonly: false};
  }

  onSave() {
    if (this.ignoreValidation ? true : this.isValid()) {
      this.context = {...this.context, readonly: true};
      this.hideBackdrop();
      this.unsubscribeEditableEvents();
      this.save.emit(this.formGroup.value);
    } else {
      this.formGroup.markAsTouchedRecursively();
      logInvalidControls(this.formGroup);
    }
  }

  onSaveDraft() {
    this.saveDraft.emit(this.formGroup.value);
  }

  onCancel() {
    this.isEditing = false;
    this.hideBackdrop();
    this.unsubscribeEditableEvents();
    this.context = {...this.context, readonly: true};
    this.formGroup.markAsUntouchedRecursively();
    this.cancel.emit();
  }

  private showBackdrop() {
    const htmlElement = this.el.nativeElement as HTMLElement;
    const parent = this.el.nativeElement.parentElement as HTMLElement;

    this._originalIndex = htmlElement.style.zIndex;
    this._originalPosition = htmlElement.style.position;
    htmlElement.style.zIndex = '998';
    htmlElement.style.position = 'relative';
    this._backdrop = createClickableBackdropElement(
      () => {
        this.onCancel();
        this.cdr.markForCheck();
      },
      {
        stopPropagation: false,
      },
    );
    parent.appendChild(this._backdrop);

    // pokud je input ve sticky divu tak to nefunguje pro topbar
    this._headerBackdrop = createClickableBackdropElement(
      () => {
        this.onCancel();
        this.cdr.markForCheck();
      },
      {
        height: '3.5rem',
        stopPropagation: false,
        zIndex: 1000,
      },
    );
    document.body.appendChild(this._headerBackdrop);
  }

  private hideBackdrop() {
    if (this._backdrop) {
      const parent = this.el.nativeElement.parentElement as HTMLElement;
      const childInplaceCell = parent.firstElementChild as HTMLElement;
      parent.removeChild(this._backdrop);
      childInplaceCell.style.zIndex = this._originalIndex;
      childInplaceCell.style.position = this._originalPosition;
    }
    if (this._headerBackdrop) {
      document.body.removeChild(this._headerBackdrop);
    }
  }

  private focusNearestInput() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        // this.inplaceCellComponent.setInputs();
        const focusCellSelector = 'input, textarea, select';
        const focusableElement = DomHandler.findSingle(
          this.el.nativeElement,
          focusCellSelector,
        );

        if (focusableElement) {
          focusableElement.focus();
        }
      }, 50);
    });
  }

  private subscribeEditableEvents() {
    const addEnterKey = () => {
      fromEvent(document, 'keydown')
        .pipe(filter((e: KeyboardEvent) => !e.ctrlKey && e.key === 'Enter'))
        .subscribe((event) => {
          if (this.isEditing) {
            if (this.isValid()) {
              this.onSave();
            }

            event.preventDefault();
          }
        });
    };
    const addCtrlEnterKey = () => {
      fromEvent(document, 'keydown')
        .pipe(filter((e: KeyboardEvent) => e.ctrlKey && e.key === 'Enter'))
        .subscribe((event) => {
          if (this.isEditing) {
            if (this.isValid()) {
              this.onSave();
            }

            event.preventDefault();
          }
        });
    };
    const addEscKey = () => {
      this.editableSubscriptions.push(
        fromEvent(document, 'keydown')
          .pipe(filter((e: KeyboardEvent) => e.key === 'Escape'))
          .subscribe((event) => {
            if (this.isEditing) {
              this.onCancel();

              event.preventDefault();
            }
          }),
      );
    };

    if (this.isSingleChild) {
      if (
        this.ctrlEnterList.some(
          (x) => DomHandler.findSingle(this.el.nativeElement, x) != null,
        )
      ) {
        addCtrlEnterKey();
      } else {
        addEnterKey();
      }
    } else {
      // for multiple always ctrl+enter for saving
      addCtrlEnterKey();
    }

    // esc
    addEscKey();
  }

  private unsubscribeEditableEvents() {
    this.editableSubscriptions.forEach((subscription) =>
      subscription.unsubscribe(),
    );
    this.editableSubscriptions = [];
  }

  ngOnDestroy() {
    this.unsubscribeEditableEvents();
  }
}
