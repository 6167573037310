import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgZone,
  Optional,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {NgStyle, NgClass} from '@angular/common';
import {AutoSizeTooltipDirectiveModule} from '@tsm/framework/root/directives';
import {TranslocoPipe} from '@jsverse/transloco';
import {FormatPhonePipe} from '@tsm/framework/root';

@Component({
  selector: 'dtl-form-input-phone',
  templateUrl: './form-input-phone.component.html',
  styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
  styles: [
    `
      :host > *:first-child {
        display: flex;
      }
    `,
  ],
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputPhoneComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputPhoneComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputPhoneComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgStyle,
    NgClass,
    AutoSizeTooltipDirectiveModule,
    TranslocoPipe,
    FormatPhonePipe,
  ],
})
export class FormInputPhoneComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: ElementRef;

  @Input() noneText = 'none';

  @Input() color = '';

  @Input() style: any;

  @Input() labelForId: string;

  @Input() overflowValueHidden = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    private ngZone: NgZone,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  @Input()
  set setAutofocus(value: boolean) {
    if (value) {
      this.ngZone.runOutsideAngular(() => {
        this.inputElement.nativeElement.focus();
        this.focused = true;
      });
    }
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.addEventListener('focus', () => {
      this.onFocus(true);
    });

    this.inputElement.nativeElement.addEventListener('blur', () => {
      this.onFocus(false);
    });
  }
}
