import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/listing-lib/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const listingLibPlugin: Plugin = {
  menus: [],
  routes: [],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/listing-lib/widgets').then((x) => x.ListingLibModule),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/dtl-listing-type-lov/dtl-listing-type-lov'
            ).then((x) => x.DtlListingTypeLovComponent),
          selector: 'dtl-listing-type-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/listing-lib/widgets/dtl-listing-type-lov/dtl-listing-type-lov-editor'
                ).then((x) => x.DtlListingTypeLovEditorComponent),
              editorSelector: 'dtl-listing-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'listingCode',
              categoryId: DesignerCategoryEnum.Listing,
              name: translationShared.shared.widgets.listingTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.listingTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'object',
                title:
                  translationShared.shared.widgets.listingTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'dtl-listing-type-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-table-filter-widget/dtl-table-filter-widget'
            ).then((x) => x.DtlTableFilterWidgetComponent),
          selector: 'dtl-table-filter-widget',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/listing-lib/widgets/filters/dtl-table-filter-widget/dtl-table-filter-widget-editor'
                ).then((x) => x.DtlTableFilterWidgetEditorComponent),
              editorSelector: 'dtl-table-filter-widget-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Reporting,
              description: translationShared.shared.filterWidget,
              icon: 'tsm-icon-registers',
              formField: 'tableFilter',
              name: translationShared.shared.filterWidget,
              value: {
                type: 'object',
                title: translationShared.shared.filterWidget,
                widget: {
                  type: 'dtl-table-filter-widget',
                  notitle: true,
                  persistent: 'Never',
                },
                config: {
                  listingId: '',
                  listingTypeCode: '',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/dtl-link-href').then(
              (x) => x.DtlLinkHrefComponent,
            ),
          selector: 'dtl-link-href',
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/dtl-table-button').then(
              (x) => x.DtlTableButtonComponent,
            ),
          selector: 'dtl-table-button',
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/dtl-table-restore').then(
              (x) => x.DtlTableRestoreComponent,
            ),
          selector: 'dtl-table-restore',
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/dtl-table-preview').then(
              (x) => x.DtlTablePreviewComponent,
            ),
          selector: 'dtl-table-preview',
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/dtl-data-view/dtl-data-view-widget/dtl-data-view-widget'
            ).then((x) => x.DataViewWidgetComponent),
          selector: 'dtl-data-view-widget',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/listing-lib/widgets/dtl-data-view/dtl-data-view-widget/dtl-data-view-widget-editor'
                ).then((x) => x.DataViewWidgetEditorComponent),
              editorSelector: 'dtl-data-view-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Listing,
              description: translationShared.shared.viewComp,
              icon: 'tsm-icon-table',
              name: translationShared.shared.viewComp,
              value: {
                type: 'layout',
                title: translationShared.shared.viewComp,
                widget: {
                  type: 'dtl-data-view-widget',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/listing-lib/widgets').then((x) => x.ListingLibModule),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-data-type-filter'
            ).then((x) => x.DtlDataTypeFilterComponent),
          selector: 'dtl-data-type-filter',
          name: translationPlugin.listingLibPlugins.filters.dtlDataTypeFilter,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlDataTypeFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-boolean').then(
              (x) => x.DtlFilterBooleanComponent,
            ),
          selector: 'dtl-filter-boolean',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterBoolean,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterBooleanDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-filter-calendar-range'
            ).then((x) => x.DtlFilterCalendarRangeComponent),
          selector: 'dtl-filter-calendar-range',
          name: translationPlugin.listingLibPlugins.filters
            .dtlFilterCalendarRange,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterCalendarRangeDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-custom').then(
              (x) => x.DtlFilterCustomComponent,
            ),
          selector: 'dtl-filter-custom',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterCustom,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterCustomDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-filter-data-tags'
            ).then((x) => x.DtlFilterDataTagsComponent),
          selector: 'dtl-filter-data-tags',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterDataTags,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterDataTagsDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-filter-date-to-boolean'
            ).then((x) => x.DtlFilterDateToBooleanComponent),
          selector: 'dtl-filter-date-to-boolean',
          name: translationPlugin.listingLibPlugins.filters
            .dtlFilterDateToBoolean,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterDateToBooleanDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-edit').then(
              (x) => x.DtlFilterEditComponent,
            ),
          selector: 'dtl-filter-edit',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterEdit,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterEditDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-filter-export-status'
            ).then((x) => x.DtlFilterExportStatusComponent),
          selector: 'dtl-filter-export-status',
          name: translationPlugin.listingLibPlugins.filters
            .dtlFilterExportStatus,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterExportStatusDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-filter-in-boolean'
            ).then((x) => x.DtlFilterInBooleanComponent),
          selector: 'dtl-filter-in-boolean',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterInBoolean,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterInBooleanDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-input').then(
              (x) => x.DtlFilterInputComponent,
            ),
          selector: 'dtl-filter-input',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterInput,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterInputDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-item').then(
              (x) => x.DtlFilterItemComponent,
            ),
          selector: 'dtl-filter-item',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterItem,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterItemDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-list').then(
              (x) => x.DtlFilterListComponent,
            ),
          selector: 'dtl-filter-list',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterList,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterListDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-number').then(
              (x) => x.DtlFilterNumberComponent,
            ),
          selector: 'dtl-filter-number',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterNumber,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterNumberDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-phone').then(
              (x) => x.DtlFilterPhoneComponent,
            ),
          selector: 'dtl-filter-phone',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterPhone,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlFilterPhoneDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-filter-tql').then(
              (x) => x.DtlFilterTqlComponent,
            ),
          selector: 'dtl-filter-tql',
          name: translationPlugin.listingLibPlugins.filters.dtlFilterTql,
          description:
            translationPlugin.listingLibPlugins.filters.dtlFilterTqlDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/listing-lib/widgets/filters/dtl-table-filter').then(
              (x) => x.DtlTableFilterComponent,
            ),
          selector: 'dtl-table-filter',
          name: translationPlugin.listingLibPlugins.filters.dtlTableFilter,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-table-filter-column-config-code'
            ).then((x) => x.DtlTableFilterColumnConfigCodeComponent),
          selector: 'dtl-table-filter-column-config-code',
          name: translationPlugin.listingLibPlugins.filters
            .dtlTableFilterColumnConfigCode,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterColumnConfigCodeDescription,
        },
        {
          selector: 'dtl-table-filter-listing-parent',
          name: translationPlugin.listingLibPlugins.filters
            .dtlTableFilterListingParent,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterListingParentDescription,
        },
        {
          selector: 'dtl-table-filter-parent',
          name: translationPlugin.listingLibPlugins.filters
            .dtlTableFilterParent,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterParentDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-table-filter-selected-items'
            ).then((x) => x.DtlTableFilterSelectedItemsComponent),
          selector: 'dtl-table-filter-selected-items',
          name: translationPlugin.listingLibPlugins.filters
            .dtlTableFilterSelectedItems,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterSelectedItemsDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-table-filter-widget/dtl-table-filter-widget-editor'
            ).then((x) => x.DtlTableFilterWidgetEditorComponent),
          selector: 'dtl-table-filter-widget-editor',
          name: translationPlugin.listingLibPlugins.filters
            .dtlTableFilterWidgetEditor,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterWidgetEditorDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/listing-lib/widgets/filters/dtl-table-filter-widget/dtl-table-filter-widget'
            ).then((x) => x.DtlTableFilterWidgetComponent),
          selector: 'dtl-table-filter-widget',
          name: translationPlugin.listingLibPlugins.filters
            .dtlTableFilterWidget,
          description:
            translationPlugin.listingLibPlugins.filters
              .dtlTableFilterWidgetDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/listing-lib/widgets').then((x) => x.ListingLibModule),
      definitions: [
        {
          selector: 'backupRestoreIcon',
          name: translationPlugin.listingLibPlugins.pipes.backupRestoreIcon,
          description:
            translationPlugin.listingLibPlugins.pipes
              .backupRestoreIconDescription,
        },
        {
          selector: 'customColumnValue',
          name: translationPlugin.listingLibPlugins.pipes.customColumnValue,
          description:
            translationPlugin.listingLibPlugins.pipes
              .customColumnValueDescription,
        },
        {
          selector: 'dataForView',
          name: translationPlugin.listingLibPlugins.pipes.dataForView,
          description:
            translationPlugin.listingLibPlugins.pipes.dataForViewDescription,
        },
        {
          selector: 'discriminator',
          name: translationPlugin.listingLibPlugins.pipes.discriminator,
          description:
            translationPlugin.listingLibPlugins.pipes.discriminatorDescription,
        },
        {
          selector: 'tsmFiltersToStringPipe',
          name: translationPlugin.listingLibPlugins.pipes
            .tsmFiltersToStringPipe,
          description:
            translationPlugin.listingLibPlugins.pipes
              .tsmFiltersToStringPipeDescription,
        },
        {
          selector: 'linkHrefComponent',
          name: translationPlugin.listingLibPlugins.pipes.linkHrefComponent,
          description:
            translationPlugin.listingLibPlugins.pipes
              .linkHrefComponentDescription,
        },
        {
          selector: 'listingProfileCode',
          name: translationPlugin.listingLibPlugins.pipes.listingProfileCode,
          description:
            translationPlugin.listingLibPlugins.pipes
              .listingProfileCodeDescription,
        },
        {
          selector: 'listingProfile',
          name: translationPlugin.listingLibPlugins.pipes.listingProfile,
          description:
            translationPlugin.listingLibPlugins.pipes.listingProfileDescription,
        },
        {
          selector: 'listingTypeCodeLoader',
          name: translationPlugin.listingLibPlugins.pipes.listingTypeCodeLoader,
          description:
            translationPlugin.listingLibPlugins.pipes
              .listingTypeCodeLoaderDescription,
        },
        {
          selector: 'listingTypeLoader',
          name: translationPlugin.listingLibPlugins.pipes.listingTypeLoader,
          description:
            translationPlugin.listingLibPlugins.pipes
              .listingTypeLoaderDescription,
        },
        {
          selector: 'tsmLovboxMultiReadonlySplit',
          name: translationPlugin.listingLibPlugins.pipes
            .tsmLovboxMultiReadonlySplit,
          description:
            translationPlugin.listingLibPlugins.pipes
              .tsmLovboxMultiReadonlySplitDescription,
        },
        {
          selector: 'memoizedDiscriminator',
          name: translationPlugin.listingLibPlugins.pipes.memoizedDiscriminator,
          description:
            translationPlugin.listingLibPlugins.pipes
              .memoizedDiscriminatorDescription,
        },
        {
          selector: 'memoized',
          name: translationPlugin.listingLibPlugins.pipes.memoized,
          description:
            translationPlugin.listingLibPlugins.pipes.memoizedDescription,
        },
        {
          selector: 'tsmSelectedColumnsToString',
          name: translationPlugin.listingLibPlugins.pipes
            .tsmSelectedColumnsToString,
          description:
            translationPlugin.listingLibPlugins.pipes
              .tsmSelectedColumnsToStringDescription,
        },
      ],
    },
  ],
};
