import {Pipe, PipeTransform, untracked} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoadCheckOnline} from '../actions';
import {selectCheckOnlineById} from '../selectors';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {addMinutes} from 'date-fns';

@Pipe({
  name: 'checkOnline',
  pure: true,
})
export class CheckOnlinePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(id: string): Observable<{isOnline: boolean; lastUpdatedAt: Date}> {
    untracked(() => this.store.dispatch(LoadCheckOnline({id})));
    return this.store.select(selectCheckOnlineById(id)).pipe(
      map((x) => {
        if (x.data == null) {
          return {
            isOnline: false,
            lastUpdatedAt: null,
          };
        }

        const now = addMinutes(new Date(), -5);
        const last = new Date(x.data.lastUpdatedAt);

        return {
          isOnline: last > now,
          lastUpdatedAt: x.data.lastUpdatedAt,
        };
      }),
    );
  }
}
