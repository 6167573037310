import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {PrivHelperService} from '@tsm/framework/security';
import {RuntimeService} from '@tsm/runtime-info';

@Directive({
  selector: '[tsmHasAnyPriv]',
})
export class HasAnyPrivDirective implements OnChanges {
  @Input()
  tsmHasAnyPriv: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private runtimeService: RuntimeService,
    private privHelperService: PrivHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const privs = changes['tsmHasAnyPriv'];
    if (
      !this.arrayIsSame(privs.previousValue, privs.currentValue) ||
      (privs.previousValue == null && privs.currentValue == null)
    ) {
      this.checkPrivs();
    }
  }

  private checkPrivs() {
    /**
     * Returns true, if any authority is a prefix of required claim.
     */
    let isOk = false;
    if (this.tsmHasAnyPriv) {
      this.tsmHasAnyPriv.forEach((tsmHasPriv) => {
        if (
          this.privHelperService.hasPrivilege(
            tsmHasPriv,
            this.privHelperService.getPrivileges(),
          )
        ) {
          isOk = true;
        }
      });
    } else {
      // bez nastaveni prav
      isOk = true;
    }
    if (isOk) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  private arrayIsSame(arr1: string[], arr2: string[]): boolean {
    const nArr1 = arr1 ? arr1 : [];
    const nArr2 = arr2 ? arr2 : [];
    return (
      nArr1.length === nArr2.length && nArr1.every((a1, i) => a1 === nArr2[i])
    );
  }
}
