import ResizeObserver from 'resize-observer-polyfill';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

const entriesMap = new WeakMap();

const ro = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if (entriesMap.has(entry.target)) {
      const comp = entriesMap.get(entry.target);
      comp.onResize(entry);
    }
  }
});

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tsmResizeObserver]',
  exportAs: 'tsmResizeObserver',
})
export class ResizeObserverDirective implements OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() resize = new EventEmitter<ResizeObserverEntry>();

  @Input() tsmResizeClass: {lowerEquals: number; cssClass: string};

  constructor(private el: ElementRef) {
    entriesMap.set(this.el.nativeElement, this);
    ro.observe(this.el.nativeElement);
  }

  onResize(entry: ResizeObserverEntry) {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.tsmResizeClass) {
      if (entry.contentRect.width <= this.tsmResizeClass.lowerEquals) {
        this.el.nativeElement.classList.add(this.tsmResizeClass.cssClass);
      } else {
        this.el.nativeElement.classList.remove(this.tsmResizeClass.cssClass);
      }
    }
    this.resize.emit(entry);
  }

  ngOnDestroy() {
    ro.unobserve(this.el.nativeElement);
    entriesMap.delete(this.el.nativeElement);
  }
}
