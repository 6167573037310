import {ModuleWithProviders, NgModule} from '@angular/core';
import {NOTIFICATION_API_CONFIG} from './di/token';

@NgModule({
  imports: [],
  declarations: [],
  providers: [],
  exports: [],
})
export class NotificationModule {
  public static forRoot(
    options: NotificationOptions,
  ): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        {
          provide: NOTIFICATION_API_CONFIG,
          useValue: options,
        },
      ],
    };
  }
}
