import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/scripting/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const scriptingPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'scripting',
      loadChildren: () =>
        import('@tsm/scripting/components').then((x) => x.ScriptingModule),
    },
  ],
  filters: [],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/scripting/widgets').then((x) => x.ScriptingWidgetModule),
      definitions: [
        {
          selector: 'scriptCodeLoader',
          name: translationPlugin.scriptingPlugins.pipes.scriptCodeLoader,
          description:
            translationPlugin.scriptingPlugins.pipes
              .scriptCodeLoaderDescription,
        },
        {
          selector: 'scriptLoader',
          name: translationPlugin.scriptingPlugins.pipes.scriptLoader,
          description:
            translationPlugin.scriptingPlugins.pipes.scriptLoaderDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/scripting/widgets').then((x) => x.ScriptingWidgetModule),
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/scripting/widgets/open-console-button/open-console-button'
            ).then((x) => x.OpenConsoleButtonComponent),
          selector: 'tsm-open-console-button',
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/scripting/widgets/open-console-button/open-console-button-editor'
                ).then((x) => x.OpenConsoleButtonEditorComponent),
              editorSelector: 'tsm-open-console-button-editor',
              widgetOrLayout: 'layout',
              name: translationShared.shared.openDetailType,
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.shared.openDetailType,
              icon: 'p-icon-document',
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-open-console-button',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/scripting/widgets/script-lov/script-lov').then(
              (x) => x.ScriptLovComponent,
            ),
          selector: 'tsm-script-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/scripting/widgets/script-lov/script-lov-editor'
                ).then((x) => x.ScriptLovEditorComponent),
              editorSelector: 'tsm-script-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'script',
              categoryId: DesignerCategoryEnum.Scripting,
              name: translationShared.shared.widgets.scriptSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.scriptSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.scriptSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-script-lov',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
