import {DesignerCategoryEnum} from '@tsm/framework/plugin';

import {Plugin} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';

export const frameworkAwarenessPlugin: Plugin = {
  menus: [],
  routes: [],
  filters: [],
  pipes: [],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/framework/awareness/widgets').then(
          (x) => x.FrameworkAwarenessModule,
        ),
      definitions: [
        {
          selector: 'dtl-awareness-users',
          designers: [
            {
              editorSelector: 'dtl-awareness-users-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Dms,
              description: translationShared.shared.awareness,
              icon: 'p-icon-attachment',
              name: translationShared.shared.awareness,
              value: {
                type: 'layout',
                title: 'Awareness',
                widget: {
                  type: 'dtl-awareness-users',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
