import {Pipe, PipeTransform} from '@angular/core';
import {PrivHelperService} from '@tsm/framework/security';
import {Characteristics} from '@tsm/characteristics/service';

@Pipe({
  name: 'tsmCharsFormTypePipe',
  pure: true,
})
export class TsmCharsFormTypePipe implements PipeTransform {
  constructor(private privService: PrivHelperService) {}

  transform(items: Characteristics[], form: string | string[]): any {
    if (!items || !form) {
      return items;
    }
    return items
      .filter(
        (item) =>
          (!Array.isArray(form)
            ? item.schemaLocation === form
            : form.includes(item.schemaLocation)) &&
          (!item.readPrivilege ||
            this.privService.isCurrentUserHasRole(item.readPrivilege)),
      )
      .sort((a, b) => a.defaultOrder - b.defaultOrder);
  }
}
