<!--button for opening the subdialog-->
<button
  *ngIf="!this.isMenuReadonly"
  pButton
  pRipple
  [label]="label === 'empty' ? '' : (translation.shared.iconPicker | transloco)"
  icon="p-icon-search"
  [class]="class"
  type="button"
  (click)="showDialog()"
></button>

<!--dialog representing the tsm icon picker-->
<!-- TODO dodelat lazy loading ikonek, takhle chvili trva nez se zobrazi dialog -->
<p-dialog
  *ngIf="displayDialog"
  (visibleChange)="hideDialog(true)"
  [visible]="true"
  [maximizable]="true"
  [appendTo]="'body'"
  styleClass="menu-icons-dialog"
  [style]="{width: '80%'}"
>
  <p-header>
    {{ translation.shared.iconPicker | transloco }}
  </p-header>

  <tsm-icon-picker-content
    [label]="label"
    [class]="class"
    [isMenuReadonly]="isMenuReadonly"
    [allowedCategory]="allowedCategory"
    [showSuccessDialog]="showSuccessDialog"
    (onIconClick)="onIconClick.emit($event)"
    (hideDialog)="hideDialog()"
  ></tsm-icon-picker-content>

  <!--  canvas is here, for a copy-to-clipboard functionality-->
  <canvas style="visibility: hidden; height: 20px" id="myCanvas"></canvas>

  <!--  buttons at the end of the dialog aka the tsm icon picker-->
  <p-footer>
    <dtl-grid class="align-items-center relative inline-flex my-1 mt-3">
      <dtl-col fixed
        ><small class="text-color-secondary font-semibold uppercase"
          >{{
            translation.shared.closeDialogAfterSelection | transloco
          }}
          :</small
        ></dtl-col
      >
      <dtl-col fixed
        ><label>{{ translation.shared.no | transloco }}</label></dtl-col
      >
      <dtl-col fixed>
        <p-inputSwitch [(ngModel)]="closeDialogAfterSelection"></p-inputSwitch>
      </dtl-col>
      <dtl-col fixed
        ><label>{{ translation.shared.yes | transloco }}</label></dtl-col
      >
    </dtl-grid>
  </p-footer>
</p-dialog>
