export const en = {
  dataSource: {
    evalScriptById: 'Evaluate a script by id',
    evalScriptByCode: 'Evaluate a script by code',
    scriptIdName: 'Script ID',
    scriptDataName: 'Script data',
    scriptDisableCache: 'Disable eval script cache',
    scriptIdDescription: 'ID of a script',
    scriptDataDescription: 'Data of a script',
    scriptDisableCacheDescription: 'Disable cache of script evaluation',
    scriptCodeName: 'Script Code',
    scriptCodeDescription: 'Code of a script',
    scriptDisableToast: 'Disable error',
    scriptDisableToastDescription: 'Disable message in case of error',
  },
  console: {
    run: 'RUN',
    stop: 'STOP',
    dryRun: '--dry-run', //'DRY RUN',
    dryRunTooltip:
      'Run in readonly transaction. Please note, that not all commands support transactions (especially REST calls).',
    translate: 'TRANSLATE',
    initInsertionQuery: '/* Please insert your query here */',
    commandHistory: 'Command history',
    languageSelection: 'Language selection',
    closeDialogAfterSelection: 'Close dialog right after selecting an item',
    commandCopied: 'Command copied',
    command: 'Command',
    copyCurrentContextIntoConsole: 'Copy current context into console',
  },
  script: {
    closeScriptOverview: 'Close script overview',
    scriptOverview: 'Script overview',
    scriptType: 'Script type',
    scriptTypeTql: 'Script of type TQL',
    scriptTypeAdmin: 'Script of type Admin',
    scriptLanguage: 'Script language',
    createScript: 'Create a new script',
    editScript: 'Edit selected script',
    deleteScript: 'Delete selected script',
    upsertSuccess: 'Script was saved successfully',
    deleteSuccess: 'The selected script was deleted successfully',
    patchSuccess: 'The selected script was edited successfully',
    currentConsoleDataCopy: 'Copy current data from console',
    currentConsoleDataCopySuccess: 'Current console data copied successfully',
    currentConsoleDataCopyError: 'Console is empty',
    selectedScriptQuery: 'Query of the selected script',
    copySelectedScriptToClipboard: 'Copy selected script to clipboard',
    pasteSelectedScriptToConsole: 'Paste selected script to console',
    diffSuccess: 'Saving script diff finished with success.',
    diffError: 'Error occurred during saving script diff.',
  },
};
