import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {DeliveryTime} from '../models';
import {createReducer, on} from '@ngrx/store';
import {
  DiffDeliveryTime,
  DiffDeliveryTimeError,
  DiffDeliveryTimeSuccess,
} from '../actions';

export interface DeliveryTimeState
  extends EntityState<IdentifiedPack<DeliveryTime>> {}

export const deliveryTimeAdapter: EntityAdapter<IdentifiedPack<DeliveryTime>> =
  createEntityAdapter<IdentifiedPack<DeliveryTime>>({});

export const initialState: DeliveryTimeState =
  deliveryTimeAdapter.getInitialState();

export const deliveryTimeReducer = createReducer(
  initialState,

  on(DiffDeliveryTime, (state, {diffEntities}) =>
    deliveryTimeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffDeliveryTimeSuccess, (state, {deliveryTimes}) =>
    deliveryTimeAdapter.upsertMany(
      deliveryTimes.map((deliveryTime) => {
        return {
          id: deliveryTime.id,
          data: deliveryTime,
          loading: false,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffDeliveryTimeError, (state, {diffEntities, error}) =>
    deliveryTimeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
