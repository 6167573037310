import {SelectItem} from 'primeng/api/selectitem';

export enum KafkaDlqLogStatusEnum {
  IGNORED = 'IGNORED',
  OK = 'OK',
  RESENT = 'RESENT',
  NEW = 'NEW',
}

export function getAllKafkaDlqLogStatues(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(KafkaDlqLogStatusEnum).forEach((val) =>
    items.push({label: KafkaDlqLogStatusEnum[val], value: val}),
  );
  return items;
}
