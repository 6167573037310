import {SelectItem} from 'primeng/api/selectitem';

export enum PrivTypeEnum {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export function getAllPrivTypes(): SelectItem[] {
  const accessRules: SelectItem[] = [];
  Object.keys(PrivTypeEnum).forEach((key) =>
    accessRules.push({label: PrivTypeEnum[key], value: key}),
  );
  return accessRules;
}
