import {mergeArraysToArray} from './merge-arrays-to-array.function';
import {distinctArrays} from './distinct-arrays.function';

const groupBy = (
  collection: any[],
  property: string,
  initArray?: {key: string; value: any[]}[],
): any[] => {
  if (!collection) {
    return null;
  }

  const initObject = {};
  if (initArray) {
    initArray.forEach((a) => {
      initObject[a.key] = a.value;
    });
  }

  const groupedCollection = collection.reduce((previous, current) => {
    if (!previous[current[property]]) {
      previous[current[property]] = [current];
    } else {
      previous[current[property]].push(current);
    }

    return previous;
  }, initObject);

  return Object.keys(groupedCollection).map((key) => ({
    key,
    value: groupedCollection[key],
  }));
};

export let cacheArray: {key: string; array: string[]};

// @ts-ignore
export function letterIcon<T = any>(
  field: string,
  value: string,
): {letter: string; class: string; origin: string} {
  if (!cacheArray) {
    cacheArray = {key: field, array: distinctArrays(null, [value])};
  } else {
    if (cacheArray.key === field) {
      cacheArray = {
        key: field,
        array: distinctArrays(null, cacheArray.array, [value]),
      };
    } else {
      cacheArray = {key: field, array: distinctArrays(null, [value])};
    }
  }
  if (cacheArray.array.length > 0) {
    const uniqueTexts = cacheArray.array
      .filter((x) => x !== '')
      .map((x) => ({
        key: x.split('')[0],
        origin: x,
      }));
    let howLong = 0;
    const groupedByKey = groupBy(uniqueTexts, 'key');
    groupedByKey
      .filter((x) => x.value.length > 1)
      .forEach((x) => {
        const topIndex = Math.max.apply(
          null,
          x.value.map((z) => z.origin.length),
        );
        for (let i = 1; i < topIndex; i++) {
          const origins = x.value.map((z) => ({tmp: z.origin.substr(0, i)}));
          const grouped = groupBy(origins, 'tmp');
          if (grouped.length === origins.length) {
            howLong = i;
            break;
          }
        }

        x.value.forEach(
          (v, index) =>
            (v.key = index > 0 ? v.origin.substr(0, howLong) : v.key),
        );
      });
    for (let i = 0; i < groupedByKey.length; i++) {
      groupedByKey[i] = {
        ...groupedByKey[i],
        value: groupedByKey[i].value.map((a, index) => {
          const tmpChar = a.key.toLowerCase().substr(0, 1);
          return {
            ...a,
            class: 'char-' + tmpChar + ' ' + 'char-' + tmpChar + index,
            origin: a.origin,
          };
        }),
      };
    }
    const keyMap = mergeArraysToArray(groupedByKey.map((z) => z.value));
    const x = keyMap.find((y) => y.origin === value);
    return {letter: x.key.toUpperCase(), class: x.class, origin: x.origin};
  }
  return null;
}

// @ts-ignore
export function letterIconItem<T = any>(item: {
  field: string;
  value: string;
}): {
  letter: string;
  class: string;
  origin: string;
} {
  if (!cacheArray) {
    cacheArray = {key: item.field, array: distinctArrays(null, [item.value])};
  } else {
    if (cacheArray.key === item.field) {
      cacheArray = {
        key: item.field,
        array: distinctArrays(null, cacheArray.array, [item.value]),
      };
    } else {
      cacheArray = {key: item.field, array: distinctArrays(null, [item.value])};
    }
  }
  if (cacheArray.array.length > 0) {
    const uniqueTexts = cacheArray.array
      .filter((x) => x !== '')
      .map((x) => ({
        key: x.split('')[0],
        origin: x,
      }));
    let howLong = 0;
    const groupedByKey = groupBy(uniqueTexts, 'key');
    groupedByKey
      .filter((x) => x.value.length > 1)
      .forEach((x) => {
        const topIndex = Math.max.apply(
          null,
          x.value.map((z) => z.origin.length),
        );
        for (let i = 1; i < topIndex; i++) {
          const origins = x.value.map((z) => ({tmp: z.origin.substr(0, i)}));
          const grouped = groupBy(origins, 'tmp');
          if (grouped.length === origins.length) {
            howLong = i;
            break;
          }
        }

        x.value.forEach(
          (v, index) =>
            (v.key = index > 0 ? v.origin.substr(0, howLong) : v.key),
        );
      });
    for (let i = 0; i < groupedByKey.length; i++) {
      groupedByKey[i] = {
        ...groupedByKey[i],
        value: groupedByKey[i].value.map((a, index) => {
          const tmpChar = a.key.toLowerCase().substr(0, 1);
          return {
            ...a,
            class: 'char-' + tmpChar + ' ' + 'char-' + tmpChar + index,
            origin: a.origin,
          };
        }),
      };
    }
    const keyMap = mergeArraysToArray(groupedByKey.map((z) => z.value));
    const x = keyMap.find((y) => y.origin === item.value);
    return {letter: x.key.toUpperCase(), class: x.class, origin: x.origin};
  }
  return null;
}
