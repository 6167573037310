import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {ConfigFormState, mnemonicConfigAdapter} from '../reducers';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getMnemonicConfigState = createSelector(
  getConfigFormState,
  (configFormState) => configFormState.mnemonicConfig,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  mnemonicConfigAdapter.getSelectors(getMnemonicConfigState);

export const selectMnemonicConfig = selectAll;

export const selectMnemonicConfigById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectMnemonicConfigByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data && x.data.code === code),
  );

export const selectMnemonicConfigByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);
