import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {translation} from '@tsm/shared-i18n';
import {TsmFormBuilder, TsmFormGroup} from '@tsm/framework/forms';
import {Terminator} from '@tsm/framework/terminator';
import {DialogNextsteps, DialogNextstepsDefault} from '../../models';
import {SelectButton} from 'primeng/selectbutton';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'tsm-dialog-nextsteps',
  template: `
    <tsm-form [formGroup]="formGroup" data-cy="dialog-nextsteps-tsm-form">
      <div class="nextsteps-wrapper" data-cy="dialog-nextsteps-div-wrapper">
        <i
          *ngIf="buttonTypeOptions.length > 0"
          class="pi pi-question-circle cursor-help text-500 p-1 hover:surface-100 hover:text-800 mr-1"
          [tsmTooltip]="changeSaveTooltip"
          data-cy="dialog-nextsteps-icon-settings"
        ></i>
        <ng-template #changeSaveTooltip>
          <!-- Headline -->
          <div
            class="border-blue-600 font-medium text-2xl px-3 py-2 mb-2 text-900 sticky top-0 surface-overlay tsm-tooltip-header"
            style="border-left: 8px solid"
          >
            Změna chování tlačítka Uložit
          </div>

          <!-- Text Body -->
          <div class="line-height-3 text-700">
            <h5 class="mb-1"><i class="pi pi-check mr-1"></i> Výchozí stav</h5>
            <p>
              Ve výchozím stavu se po uložení dialog zavře a nedochází k žádné
              další akci.
            </p>

            <h5 class="mb-1">
              <i class="pi pi-plus mr-1"></i> Uložit a další (volitelné)
            </h5>
            <p>
              Při výběru této volby, bude po uložení otevřen nový (stejný)
              dialog s možnostní ihned zadat nový záznam.
            </p>

            <h5 class="mb-1">
              <i class="pi pi-arrow-right mr-1"></i> Uložit a přejít na detail
              (volitelné)
            </h5>
            <p>
              Při výběru této volby budete po uložení přesměrován/a na detail
              nově založeného záznamu.
            </p>

            <div class="bg-yellow-50 p-2 font-italic font-medium">
              <i class="pi pi-info-circle mr-1"></i> Volbu provedete kliknutím
              na ikonu
            </div>
          </div>
        </ng-template>
        <p-selectButton
          [options]="buttonTypeOptions"
          formControlName="value"
          optionLabel="icon"
          data-cy="dialog-nextsteps-p-selectButton"
        >
          <ng-template let-item>
            <i
              [class]="item.icon"
              [pTooltip]="item.tooltip | transloco"
              tooltipPosition="top"
              [showDelay]="300"
            ></i>
          </ng-template>
        </p-selectButton>
      </div>
    </tsm-form>
  `,
  styleUrls: ['./dialog-nextsteps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Terminator],
})
export class DialogNextstepsComponent {
  translationShared = translation;

  buttonTypeOptions: any[] = [];
  dialogNextstepDefault: DialogNextstepsDefault;

  @ViewChild(SelectButton, {static: true})
  selectButton: SelectButton;

  private _config: DialogNextsteps;

  get config(): DialogNextsteps {
    return this._config;
  }

  @Input()
  set config(value: DialogNextsteps) {
    this._config = value;
    this.buttonTypeOptions = [];
    if (value) {
      // nastaveni jaky buttony maj byt videt
      if (value.createNewAllowed == true) {
        this.buttonTypeOptions.push({
          icon: 'p-icon-add',
          tooltip: translation.shared.saveOther,
          value: DialogNextstepsDefault.CREATE_NEW,
        });
      }
      if (value.entityDetailAllowed == true) {
        this.buttonTypeOptions.push({
          icon: 'p-icon-arrow-forward',
          tooltip: translation.shared.saveDetail,
          value: DialogNextstepsDefault.ENTITY_DETAIL,
        });
      }

      if (value.default === DialogNextstepsDefault.CLOSE) {
        this.formGroup.get('value').setValue(DialogNextstepsDefault.CLOSE);
      } else if (value.default === DialogNextstepsDefault.CREATE_NEW) {
        this.formGroup.get('value').setValue(DialogNextstepsDefault.CREATE_NEW);
      } else {
        this.formGroup
          .get('value')
          .setValue(DialogNextstepsDefault.ENTITY_DETAIL);
      }
    } else {
      this.formGroup
        .get('value')
        .setValue(DialogNextstepsDefault.ENTITY_DETAIL);
    }

    this.dialogNextstepDefault = this.formGroup.get('value').value;
  }

  @Output()
  changeConfig = new EventEmitter<{
    value: DialogNextstepsDefault;
    icon: string;
  }>();

  formGroup: TsmFormGroup<DialogNextsteps>;

  constructor(
    private fb: TsmFormBuilder,
    private terminator: Terminator,
  ) {
    this.formGroup = fb.tsmGroup({
      value: fb.tsmControl(),
    });
    this.formGroup.valueChanges
      .pipe(takeUntil(terminator))
      .subscribe((x: {value: DialogNextstepsDefault}) => {
        let icon = 'pi pi-check';
        if (x.value === DialogNextstepsDefault.CREATE_NEW) {
          icon = 'pi pi-plus';
        } else if (x.value === DialogNextstepsDefault.ENTITY_DETAIL) {
          icon = 'pi pi-arrow-right';
        }
        this.changeConfig.emit({value: x.value, icon: icon});
      });
  }

  ngAfterViewInit(): void {
    this.selectButton.onOptionSelect = (event, option: any, index: number) => {
      if (option.disabled) {
        return;
      }

      if (this.selectButton.value === option.value) {
        this.selectButton.value = this.dialogNextstepDefault;
      } else {
        this.selectButton.value = option.value;
      }

      this.selectButton.onOptionClick.emit({
        originalEvent: event,
        option: option,
        index: index,
      });

      this.selectButton.onModelChange(this.selectButton.value);

      this.selectButton.onChange.emit({
        originalEvent: event,
        value: this.selectButton.value,
      });
    };

    this.selectButton.isSelected = (option: any) => {
      return option.value == this.selectButton.value;
    };
  }
}
