import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  Input,
  LOCALE_ID,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {Terminator} from '@tsm/framework/terminator';
import {TranslocoService} from '@tsm/framework/translate';
import {InputNumber} from '@tsm/framework/override-primeng/input-number';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {InputNumberModule} from '@tsm/framework/override-primeng/input-number';
import {NgStyle, NgClass, NgIf, DecimalPipe} from '@angular/common';
import {LocalizationDataTranslatePipe} from '@tsm/framework/root';

@Component({
  selector: 'dtl-form-input-number',
  templateUrl: './form-input-number.component.html',
  styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputNumberComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputNumberComponent),
      multi: true,
    },
    Terminator,
    ...useParentWidgetProvidersFor(FormInputNumberComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InputNumberModule,
    NgStyle,
    FormsModule,
    NgClass,
    NgIf,
    DecimalPipe,
    LocalizationDataTranslatePipe,
  ],
})
export class FormInputNumberComponent
  extends ParentWidgetAccessorComponent
  implements OnChanges, AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: InputNumber;

  @Input() inputId: string;

  @Input() step = 1;

  @Input() fractionDigits = 0;

  @Input() mode: 'decimal' | 'currency' = 'decimal';

  @Input() currency = 'CZK';

  @Input() placeholder: string;

  @Input() min: number;

  @Input() max: number;

  @Input() disableKeyDown = false;

  @Input() localeFormatting: boolean;

  @Input() showButtons = true;

  // to support horizontal and vertical layouts, amend stylesheet accordingly first
  buttonLayout: 'stacked' /*| "horizontal" | "vertical"*/ = 'stacked';

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    protected ts: TranslocoService,
    @Inject(LOCALE_ID) public locale: string,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  get digitsInfo(): string {
    if (this.fractionDigits == null) {
      return '';
    }
    return `1.${this.fractionDigits}-${this.fractionDigits}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fractionDigits = changes.fractionDigits;
    const step = changes.step;
    const showButtons = changes.showButtons;

    if (showButtons && showButtons.currentValue != false) {
      this.showButtons = true;
    }

    if (
      step &&
      this.isNumber(step.currentValue) &&
      step.currentValue != step.previousValue
    ) {
      this.step = this.computeStep(step.currentValue, this.fractionDigits);
    }

    if (
      fractionDigits &&
      fractionDigits.currentValue !== fractionDigits.previousValue &&
      !fractionDigits.firstChange
    ) {
      if (
        this.isNumber(fractionDigits.currentValue) &&
        fractionDigits.currentValue > 0
      ) {
        const revertedStep = isNaN(fractionDigits.previousValue)
          ? this.step
          : this.step * Math.pow(10, fractionDigits.previousValue);

        this.step = this.computeStep(revertedStep, this.fractionDigits);
      } else {
        this.step = 1;
      }
    }

    if (this.step == null || this.fractionDigits == null) {
      this.step = 1;
    }
    if (this.fractionDigits == null) {
      this.fractionDigits = 0;
    }
  }

  isNumber(value: any) {
    return !isNaN(value);
  }

  ngAfterViewInit(): void {
    if (this.disableKeyDown === true) {
      const oldSpin = this.inputElement.spin;
      this.inputElement.spin = (event: Event, dir: number) => {
        if ((event as KeyboardEvent)?.code === undefined) {
          oldSpin.call(this.inputElement, event, dir);
        }
      };
    }
  }

  handleOnInput(event: {originalEvent: any; value: number}) {
    // correct floating point arithmetic error introduced by spinner buttons
    this.setValCorrectedForPrecision(event.value);
    this.onTouched(true);
  }

  onInputBlur(event) {
    // Pokud to tu je, nedochazi k nastaveni defualtnich hodnot pri ukonceni formulare...
    // zavola to AbstractControlValueAccessor.registerOnChange v FormFieldContainerComponent
    // this.setValCorrectedForPrecision(this.inputElement.value);
    this.onFocus(false);
  }

  /**
   * Set value with precision correction using toFixed()
   *
   * @param newVal
   */
  setValCorrectedForPrecision(newVal: number) {
    if (typeof newVal === 'string') {
      return null;
    }
    this.val = this.getValCorrectedForPrecision(newVal);
  }

  private computeStep(val: number, fractionDigits: number) {
    return val / Math.pow(10, fractionDigits);
  }

  private whatDecimalSeparator(): string {
    const n = 1.1;
    return n.toLocaleString().substring(1, 2);
  }

  private getValCorrectedForPrecision(newVal: number) {
    const decimalOperator = this.whatDecimalSeparator();
    if (
      typeof newVal === 'number' &&
      newVal?.toString().includes(decimalOperator)
    ) {
      return +newVal.toFixed(this.fractionDigits);
    } else {
      return newVal;
    }
  }
}
