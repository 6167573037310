import {RefreshModel, TableKeyType} from '../models';
import {createAction, props} from '@ngrx/store';

/**
 * SEKCE PRO REFRESH
 */

export const SetRefresh = createAction(
  '[Listing] Set Refresh',
  props<{
    id: string;
    profileId: TableKeyType;
    refresh: RefreshModel;
    listingType?: string;
    skipStart?: boolean;
  }>(),
);

export const EnableRefresh = createAction(
  '[Listing] Enable Refresh',
  props<{id: string; profileId: TableKeyType}>(),
);

export const DisableRefresh = createAction(
  '[Listing] Disable Refresh',
  props<{id: string; profileId: TableKeyType}>(),
);

export const DisableRefreshForAllProfiles = createAction(
  '[Listing] Disable Refresh For All Profiles',
  props<{id: string}>(),
);

export const StartRefresh = createAction(
  '[Listing] Start Refresh',
  props<{id: string; profileId: TableKeyType}>(),
);
