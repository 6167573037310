import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Comment} from '../models';
import {
  CollapsedAllComments,
  CollapsedComment,
  DeleteDataComment,
  DeleteDataCommentError,
  DeleteDataCommentSuccess,
  EditDataComment,
  EditDataCommentText,
  LoadDataComment,
  LoadDataCommentError,
  LoadDataCommentSuccess,
  SaveDataComment,
  SaveDataCommentError,
  SaveDataCommentSuccess,
} from '../actions';
import {isBefore, parseISO} from 'date-fns';

export interface CommentState {
  id: string;
  ownerId: string;
  ownerType: string;
  comments: Comment[];
  login?: string;
  allCollapsed?: boolean;
  error: any;
  loading: boolean;
}

export const commentAdapter: EntityAdapter<CommentState> =
  createEntityAdapter<CommentState>();
export const initialState: EntityState<CommentState> =
  commentAdapter.getInitialState({});

export const commentsReducer = createReducer(
  initialState,

  on(LoadDataComment, (state, {ownerId, ownerType}) =>
    commentAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ownerId: ownerId,
        ownerType: ownerType,
        comments: state.entities[ownerId + ownerType]
          ? state.entities[ownerId + ownerType]?.comments
          : [],
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadDataCommentSuccess, (state, {ownerId, ownerType, comments}) => {
    const stateComments = state.entities[ownerId + ownerType].comments;
    const newComments = comments
      .slice()
      .sort((y, z) =>
        isBefore(new Date(y.whenInserted), new Date(z.whenInserted)) ? 1 : -1,
      )
      .map((x, index) => ({
        ...x,
        peditorVisibility: false,
        collapsed: index === 0,
      }));
    const resultComments = newComments.map((nc) => {
      const stateComment = stateComments.find((sc) => sc.id === nc.id);
      if (stateComment) {
        return {...nc, collapsed: stateComment.collapsed};
      }
      return nc;
    });
    return commentAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ownerId: ownerId,
        ownerType: ownerType,
        comments: resultComments,
        loading: false,
        error: null,
      },
      state,
    );
  }),

  on(LoadDataCommentError, (state, {ownerId, ownerType, error}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(SaveDataComment, (state, {ownerId, ownerType}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(SaveDataCommentSuccess, (state, {ownerId, ownerType, comment}) => {
    const newComments = [
      ...(state.entities[ownerId + ownerType]
        ? state.entities[ownerId + ownerType].comments.filter(
            (c) => c.id !== comment.id,
          )
        : []),
      {
        ...comment,
        peditorVisibility: false,
        collapsed:
          state.entities[ownerId + ownerType].allCollapsed !== null
            ? state.entities[ownerId + ownerType].allCollapsed
            : true,
      },
    ].sort((y, z) =>
      isBefore(parseISO(y.whenInserted), parseISO(z.whenInserted)) ? 1 : -1,
    );
    return commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          comments: newComments,
          loading: false,
        },
      },
      state,
    );
  }),

  on(SaveDataCommentError, (state, {ownerId, ownerType, error}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(DeleteDataComment, (state, {ownerId, ownerType}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteDataCommentSuccess, (state, {ownerId, ownerType, commentId}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          comments: state.entities[ownerId + ownerType].comments.filter(
            (c) => c.id !== commentId,
          ),
          loading: false,
        },
      },
      state,
    ),
  ),

  on(DeleteDataCommentError, (state, {ownerId, ownerType, error}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(EditDataComment, (state, {ownerId, ownerType, comment, openEdit}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          comments: state.entities[ownerId + ownerType].comments.map((c) =>
            c.id === comment.id
              ? {
                  ...c,
                  peditorVisibility: openEdit,
                  collapsed: comment.collapsed,
                }
              : c,
          ),
        },
      },
      state,
    ),
  ),

  on(EditDataCommentText, (state, {ownerId, ownerType, comment, commentText}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          comments: state.entities[ownerId + ownerType].comments.map((c) =>
            c.id === comment.id ? {...c, comment: commentText} : c,
          ),
        },
      },
      state,
    ),
  ),

  on(CollapsedComment, (state, {ownerId, ownerType, comment}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          comments: state.entities[ownerId + ownerType].comments.map((c) =>
            c.id === comment.id ? {...c, collapsed: comment.collapsed} : c,
          ),
        },
      },
      state,
    ),
  ),

  on(CollapsedAllComments, (state, {ownerId, ownerType, collapsed}) =>
    commentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          allCollapsed: collapsed,
          comments:
            collapsed !== null
              ? state.entities[ownerId + ownerType].comments.map((c) => ({
                  ...c,
                  collapsed: collapsed,
                }))
              : state.entities[ownerId + ownerType].comments,
        },
      },
      state,
    ),
  ),
);
