<div
  [ngStyle]="{display: readonly ? 'none' : 'block'}"
  data-cy="dtl-form-input-datetime"
>
  <dtl-calendar
    [style]="{width: '100%'}"
    [(ngModel)]="dateValue"
    [inputId]="inputId"
    [showButtonBar]="true"
    [placeholder]="
      localizationData
        | localizationDataTranslate: 'widget.placeholder' : placeholder
    "
    [showClear]="showCloseIcon === true || showCloseIcon === null"
    [dateFormat]="defaultDateFormat"
    [showOnFocus]="!readonly"
    [showTime]="showTime"
    [timeOnly]="timeOnly"
    [showIcon]="!!showIcon"
    [monthNavigator]="true"
    [showSeconds]="showSeconds"
    [view]="monthYear ? 'month' : 'date'"
    [yearNavigator]="true"
    [yearRange]="yearRange"
    [appendTo]="appendTo"
    (onFocus)="onFocus(true)"
    (onBlur)="onFocus(false)"
    (onSelect)="skipChange = false; updateValue($event)"
    (onInput)="skipChange = false; updateValue(dateValue)"
    (onClearClick)="updateValue($event); skipChange = true"
    (onClear)="updateValue($event); skipChange = true"
    [hideOnDateTimeSelect]="!showTime"
    [timeMessage]="
      'shared.calendar.message'
        | transloco: {minDate: (minDate | date: 'dd.MM.yyyy HH:mm')}
    "
    [minDate]="minDate"
    [maxDate]="maxDate"
    [showPickDateTimeButton]="showPickDateTimeButton"
    [pickDateTimeButtonLabel]="pickDateTimeButtonLabel | transloco"
    [ariaLabelledBy]="inputId"
  >
  </dtl-calendar>
</div>

<span
  [ngStyle]="{display: !readonly ? 'none' : 'inline-flex'}"
  [ngClass]="val ? 'readonly-text' : 'readonly-text empty'"
>
  <!-- Time-Only Cases -->
  <ng-container *ngIf="timeOnly">
    <ng-container *ngIf="showSeconds">
      <ng-container *ngIf="showMilliseconds">
        {{ val | date: 'HH:mm:ss.SSS' }}
      </ng-container>
      <ng-container *ngIf="!showMilliseconds">
        {{ val | date: 'HH:mm:ss' }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!showSeconds">
      {{ val | date: 'HH:mm' }}
    </ng-container>
  </ng-container>

  <!-- Month/Year Case -->
  <ng-container *ngIf="!timeOnly && monthYear">
    {{ val | date: 'MM.yyyy' }}
  </ng-container>

  <!-- Full Date With Time -->
  <ng-container *ngIf="!timeOnly && !monthYear && showTime">
    <ng-container *ngIf="showSeconds">
      <ng-container *ngIf="showMilliseconds">
        {{ val | date: 'dd.MM.yyyy HH:mm:ss.SSS' }}
      </ng-container>
      <ng-container *ngIf="!showMilliseconds">
        {{ val | date: 'dd.MM.yyyy HH:mm:ss' }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!showSeconds">
      {{ val | date: 'dd.MM.yyyy HH:mm' }}
    </ng-container>
  </ng-container>

  <!-- Full Date Without Time -->
  <ng-container *ngIf="!timeOnly && !monthYear && !showTime">
    {{ val | date: 'dd.MM.yyyy' }}
  </ng-container>

  <span
    *ngIf="diffTime !== null"
    [class.p-chip]="chipClass != null"
    [ngClass]="chipClass"
  >
    {{ diffTime }}
  </span>
  <ng-container *ngIf="!val"> none </ng-container>
</span>
