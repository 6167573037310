import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {CommentTypeModel} from '../models';
import {
  DeleteCommentTypeSuccess,
  DiffCommentType,
  DiffCommentTypeError,
  DiffCommentTypeSuccess,
  LoadCommentTypeByCodeSuccess,
  LoadCommentTypeById,
  LoadCommentTypeByIdError,
  LoadCommentTypeByIdSuccess,
  LoadCommentTypeSuccess,
  UpsertCommentType,
  UpsertCommentTypeSuccess,
} from '../actions';

export type CommentTypeState = EntityState<IdentifiedPack<CommentTypeModel>>;

export const commentTypeAdapter: EntityAdapter<
  IdentifiedPack<CommentTypeModel>
> = createEntityAdapter<IdentifiedPack<CommentTypeModel>>({});

const initialState: CommentTypeState = commentTypeAdapter.getInitialState({});

export const commentTypeReducer = createReducer(
  initialState,

  on(LoadCommentTypeSuccess, (state, {commentTypes}) => ({
    ...commentTypeAdapter.addMany(
      commentTypes.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadCommentTypeById, (state, {id}) =>
    commentTypeAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadCommentTypeByIdSuccess,
    LoadCommentTypeByCodeSuccess,
    (state, {commentType}) =>
      commentTypeAdapter.upsertOne(
        {
          id: commentType.id,
          data: commentType,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(LoadCommentTypeByIdError, (state, {id, error}) =>
    commentTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertCommentType, (state, {commentType}) =>
    commentTypeAdapter.updateOne(
      {
        id: commentType.id,
        changes: {
          ...state.entities[commentType.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertCommentTypeSuccess, (state, {commentType}) =>
    commentTypeAdapter.upsertOne(
      {
        id: commentType.id,
        data: commentType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteCommentTypeSuccess, (state, commentType) =>
    commentTypeAdapter.removeOne(commentType.id, state),
  ),

  on(DiffCommentType, (state, {diffEntities}) =>
    commentTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffCommentTypeSuccess, (state, {commentTypes}) =>
    commentTypeAdapter.upsertMany(
      commentTypes.map((commentType) => {
        return {
          id: commentType.id,
          data: commentType,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffCommentTypeError, (state, {diffEntities, error}) =>
    commentTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
