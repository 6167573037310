import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {Register} from '../models';
import {ConfigFormState, registerAdapter} from '../reducers';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getRegisterState = createSelector(
  getConfigFormState,
  (configFormState) => configFormState.registers,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  registerAdapter.getSelectors(getRegisterState);

export const selectRegisterById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectRegisterByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectRegisterByCode = (code: string) =>
  createSelector(selectAll, (entities) => {
    return entities.find((pack) => pack.data.code === code);
  });

export const selectRegisterByFilter = (filter: (reg: Register) => boolean) =>
  createSelector(selectAll, (entities) => {
    return entities.map((pack) => pack.data).filter((entity) => filter(entity));
  });

export const selectRegisterCountByFilter = (
  filter: (reg: Register) => boolean,
) =>
  createSelector(selectRegisterByFilter(filter), (data) => {
    return data.length;
  });

export const selectRegisterCountWithoutFilter = () =>
  createSelector(selectAll, (data) => {
    return data.length;
  });
