import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteUserGroupType,
  DeleteUserGroupTypeError,
  DeleteUserGroupTypeSuccess,
  DiffUserGroupType,
  DiffUserGroupTypeError,
  DiffUserGroupTypeSuccess,
  LoadUserGroupType,
  LoadUserGroupTypeByCode,
  LoadUserGroupTypeByCodeError,
  LoadUserGroupTypeByCodeSuccess,
  LoadUserGroupTypeById,
  LoadUserGroupTypeByIdError,
  LoadUserGroupTypeByIdSuccess,
  LoadUserGroupTypeError,
  LoadUserGroupTypeSuccess,
  UpsertUserGroupType,
  UpsertUserGroupTypeError,
  UpsertUserGroupTypeSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {CommonApiService} from '../service';
import {UserGroupType} from '../model';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';

@Injectable()
export class UserGroupTypeEffects {
  translationShared = translationShared;
  private readonly USER_GROUP_TYPE_API_PATH = 'codetables/user-group-types';

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupType),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.USER_GROUP_TYPE_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserGroupTypeSuccess({userGroupTypes: env.data})
                : LoadUserGroupTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupTypeById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.USER_GROUP_TYPE_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserGroupTypeByIdSuccess({userGroupType: env.data})
                : LoadUserGroupTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserGroupTypeByCode),
      concatMap(({code}) =>
        this.commonApiService
          .getEntityByCode(this.USER_GROUP_TYPE_API_PATH, code)
          .pipe(
            map((env) =>
              env.success && env.data
                ? LoadUserGroupTypeByCodeSuccess({userGroupType: env.data})
                : LoadUserGroupTypeByCodeError({code, error: env.error}),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroupType),
      concatMap(({userGroupType, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.USER_GROUP_TYPE_API_PATH,
            userGroupType,
            userGroupType.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .saveUserGroupTypeSuccesMsg,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserGroupTypeSuccess({
                  userGroupType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .saveUserGroupTypeErrorMsg,
                );
                return UpsertUserGroupTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserGroupType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService
          .deleteEntity(this.USER_GROUP_TYPE_API_PATH, id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .deleteUserGroupTypeSuccessMsg,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                if (shouldRedirectToListing) {
                  this.router.navigate(['/user-management/user-group-type']);
                }
                return DeleteUserGroupTypeSuccess({
                  id: id,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .deleteUserGroupTypeErrorMsg,
                );
                return DeleteUserGroupTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffUserGroupType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/user-group-types', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffUserGroupTypeSuccess({
                  userGroupTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffUserGroupTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserGroupTypeSuccess),
      tap(({userGroupType, redirect}) => {
        if (redirect === true) {
          this.router.navigate([
            '/user-management/user-group-type',
            userGroupType.id,
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserGroupTypeSuccess, DiffUserGroupTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<UserGroupType, UserGroupType>,
    private router: Router,
    private ts: ToastService,
  ) {}
}
