import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/role-access-rule.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getRoleAccessRuleState = createSelector(
  getUserManagementState,
  (roleAccessRuleState) => roleAccessRuleState.roleAccessRule,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getRoleAccessRuleState);

export const selectRoleAccessRuleByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectRoleAccessRulesByRoleId = (roleId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((p) => p.data.role.id === roleId),
  );

export const selectRoleAccessRulesByAccessRuleId = (accessRuleId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((p) => p.data.accessRule.id === accessRuleId),
  );

export const selectRoleAccessRuleById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllRoleAccessRules = selectAll;
