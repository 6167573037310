import {Injectable} from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  ControlConfig,
  FormArray,
  FormBuilder,
  FormControl,
  FormControlOptions,
  FormControlState,
  FormGroup,
  FormRecord,
  ValidatorFn,
} from '@angular/forms';
import {TsmFormGroup} from '../controls/tsm-form-group';
import {TsmFormArray} from '../controls/tsm-form-array';
import {TsmFormControl} from '../controls/tsm-form-control';
import {
  TypedTsmAbstractControl,
  TypedTsmFormArray,
  TypedTsmFormControl,
  TypedTsmFormGroup,
} from '../controls';

/**
 * The union of all validator types that can be accepted by a ControlConfig.
 */
type ValidatorConfig =
  | ValidatorFn
  | AsyncValidatorFn
  | ValidatorFn[]
  | AsyncValidatorFn[];

/**
 * The compiler may not always be able to prove that the elements of the control config are a tuple
 * (i.e. occur in a fixed order). This slightly looser type is used for inference, to catch cases
 * where the compiler cannot prove order and position.
 *
 * For example, consider the simple case `fb.group({foo: ['bar', Validators.required]})`. The
 * compiler will infer this as an array, not as a tuple.
 */
type PermissiveControlConfig<T> = Array<
  T | FormControlState<T> | ValidatorConfig
>;

/**
 * Helper type to allow the compiler to accept [XXXX, { updateOn: string }] as a valid shorthand
 * argument for .group()
 */
interface PermissiveAbstractControlOptions
  extends Omit<AbstractControlOptions, 'updateOn'> {
  updateOn?: string;
}

// pozor, porovnej s originalem
export declare type ɵElement<T, N extends null> = [T] extends [
  TypedTsmFormControl<infer U>,
]
  ? TypedTsmFormControl<U>
  : [T] extends [TypedTsmFormControl<infer U> | undefined]
    ? TypedTsmFormControl<U>
    : [T] extends [TypedTsmFormGroup<infer U>]
      ? TypedTsmFormGroup<U>
      : [T] extends [TypedTsmFormGroup<infer U> | undefined]
        ? TypedTsmFormGroup<U>
        : [T] extends [TypedTsmFormArray<infer U>]
          ? TypedTsmFormArray<U>
          : [T] extends [TypedTsmFormArray<infer U> | undefined]
            ? TypedTsmFormArray<U>
            : [T] extends [TypedTsmAbstractControl<infer U>]
              ? TypedTsmAbstractControl<U>
              : [T] extends [TypedTsmAbstractControl<infer U> | undefined]
                ? TypedTsmAbstractControl<U>
                : [T] extends [FormControlState<infer U>]
                  ? TypedTsmFormControl<U | N>
                  : [T] extends [PermissiveControlConfig<infer U>]
                    ? TypedTsmFormControl<
                        | Exclude<
                            U,
                            ValidatorConfig | PermissiveAbstractControlOptions
                          >
                        | N
                      >
                    : TypedTsmFormControl<T | N>;

// export declare type ɵElement<T, N extends null> = [
//   T
// ] extends [TypedTsmFormControl<infer U>] ? TypedTsmFormControl<U> : [
//   T
// ] extends [TypedTsmFormControl<infer U> | undefined] ? TypedTsmFormControl<U> : [
//   T
// ] extends [TypedTsmFormGroup<infer U>] ? TypedTsmFormGroup<U> : [
//   T
// ] extends [TypedTsmFormGroup<infer U> | undefined] ? TypedTsmFormGroup<U> : [
//   T
// ] extends [TypedTsmFormArray<infer U>] ? TypedTsmFormArray<U> : [
//   T
// ] extends [TypedTsmFormArray<infer U> | undefined] ? TypedTsmFormArray<U>  : [
//   T
// ] extends [FormControlState<infer U>] ? TypedTsmFormControl<U | N> : [
//   T
// ] extends [PermissiveControlConfig<infer U>] ? TypedTsmFormControl<Exclude<U, ValidatorConfig | PermissiveAbstractControlOptions> | N> : [
//   T
// ] extends [TypedTsmAbstractControl<infer U>] ? TypedTsmAbstractControl<U> : [
//   T
// ] extends [TypedTsmAbstractControl<infer U> | undefined] ? TypedTsmAbstractControl<U> : TypedTsmFormControl<T | N>;

function isAbstractControlOptions(
  options: AbstractControlOptions | {[key: string]: any} | null | undefined,
): options is AbstractControlOptions {
  return (
    !!options &&
    ((options as AbstractControlOptions).asyncValidators !== undefined ||
      (options as AbstractControlOptions).validators !== undefined ||
      (options as AbstractControlOptions).updateOn !== undefined)
  );
}

@Injectable({
  providedIn: 'root',
})
export class TsmFormBuilder extends FormBuilder {
  /**
   * @deprecated
   * @description
   * Construct a new `FormGroup` instance.
   *
   * @param controlsConfig A collection of child controls. The key for each child is the name
   * under which it is registered.
   *
   * @param extra An object of configuration options for the `FormGroup`.
   * * `validator`: A synchronous validator function, or an array of validator functions
   * * `asyncValidator`: A single async validator or array of async validator functions
   *
   */
  tsmGroup<T>(
    controlsConfig: {[key: string]: any},
    extra: {[key: string]: any} | null = null,
  ): TsmFormGroup<T> {
    const controls = this._reduceControls(controlsConfig);
    const validator: ValidatorFn = extra != null ? extra['validator'] : null;
    const asyncValidator: AsyncValidatorFn =
      extra != null ? extra['asyncValidator'] : null;
    return new TsmFormGroup<T>(controls, validator, asyncValidator);
  }

  // @ts-ignore
  typedTsmGroup<T extends {}>(
    controls: T,
    options?: AbstractControlOptions | null,
  ): TypedTsmFormGroup<{[K in keyof T]: ɵElement<T[K], null>}>;

  typedTsmGroup(
    controls: {[key: string]: any},
    options:
      | AbstractControlOptions
      | {
          [key: string]: any;
        }
      | null = null,
  ): TypedTsmFormGroup {
    const reducedControls = this._reduceTypedControls(controls);
    let newOptions: FormControlOptions = {};
    if (isAbstractControlOptions(options)) {
      // `options` are `AbstractControlOptions`
      newOptions = options;
    } else if (options !== null) {
      // `options` are legacy form group options
      newOptions.validators = (options as any).validator;
      newOptions.asyncValidators = (options as any).asyncValidator;
    }
    return new TypedTsmFormGroup(reducedControls, newOptions);
  }

  /**
   * @deprecated
   * @description
   * Construct a new `FormControl` instance.
   *
   * @param formState Initializes the control with an initial value,
   * or an object that defines the initial value and disabled state.
   *
   * @param validator A synchronous validator function, or an array of synchronous validator
   * functions.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   *
   * @usageNotes
   *
   * ### Initialize a control as disabled
   *
   * The following example returns a control with an initial value in a disabled state.
   *
   * <code-example path="forms/ts/formBuilder/form_builder_example.ts"
   *   linenums="false" region="disabled-control">
   * </code-example>
   *
   */
  tsmControl<T>(
    formState?: any,
    validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    options: {autoparsing: boolean; [key: string]: any} = {autoparsing: true},
  ): TsmFormControl<T> {
    return new TsmFormControl(formState, validator, asyncValidator, options);
  }

  typedTsmControl<T>(
    formState: T | FormControlState<T>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): TypedTsmFormControl<T | null>;

  /**
   * @description
   * Construct a new `FormControl` instance.
   * @param formState
   * @param validatorOrOpts
   * @param asyncValidator
   */
  typedTsmControl<T>(
    formState: T | FormControlState<T>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): TypedTsmFormControl {
    let newOptions: FormControlOptions = {};
    // if (!this.useNonNullable) {
    //   return new TypedTsmFormControl(formState, validatorOrOpts, asyncValidator);
    // }
    if (isAbstractControlOptions(validatorOrOpts)) {
      // If the second argument is options, then they are copied.
      newOptions = validatorOrOpts;
    } else {
      // If the other arguments are validators, they are copied into an options object.
      newOptions.validators = validatorOrOpts;
      newOptions.asyncValidators = asyncValidator;
    }
    return new TypedTsmFormControl<T>(formState, {
      ...newOptions,
      nonNullable: true,
    });
  }

  /**
   * @deprecated
   * @description
   * Construct a new `FormArray` instance.
   *
   * @param controlsConfig An array of child controls. The key for each child control is its index
   * in the array.
   *
   * @param validator A synchronous validator function, or an array of synchronous validator
   * functions.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   */
  tsmArray<T>(
    controlsConfig: any[],
    validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): TsmFormArray<T> {
    const controls = controlsConfig.map((c) => this._createControl(c));
    return new TsmFormArray(controls, validator, asyncValidator);
  }

  typedTsmArray<T>(
    controls: Array<T>,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): TypedTsmFormArray<ɵElement<T, never>>;

  typedTsmArray<T>(
    controls: Array<T>,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): TypedTsmFormArray {
    const createdControls = controls.map((c) => this._createTypedControl(c));
    // Cast to `any` because the inferred types are not as specific as Element.
    return new TypedTsmFormArray(
      createdControls,
      validatorOrOpts,
      asyncValidator,
    );
  }

  /** @internal */
  private _reduceControls(controlsConfig: {[k: string]: any}): {
    [key: string]: AbstractControl;
  } {
    const controls: {[key: string]: AbstractControl} = {};
    Object.keys(controlsConfig).forEach((controlName) => {
      controls[controlName] = this._createControl(controlsConfig[controlName]);
    });
    return controls;
  }

  /** @internal */
  _reduceTypedControls<T>(controls: {
    [k: string]:
      | T
      | ControlConfig<T>
      | FormControlState<T>
      | TypedTsmAbstractControl<T>;
  }): {[key: string]: TypedTsmAbstractControl} {
    const createdControls: {[key: string]: TypedTsmAbstractControl} = {};
    Object.keys(controls).forEach((controlName) => {
      createdControls[controlName] = this._createTypedControl(
        controls[controlName],
      );
    });
    return createdControls;
  }

  /** @internal */
  private _createControl(controlConfig: any): AbstractControl {
    if (
      controlConfig instanceof TsmFormControl ||
      controlConfig instanceof TsmFormGroup ||
      controlConfig instanceof TsmFormArray
    ) {
      return controlConfig;
    } else if (Array.isArray(controlConfig)) {
      const value = controlConfig[0];
      const validator: ValidatorFn =
        controlConfig.length > 1 ? controlConfig[1] : null;
      const asyncValidator: AsyncValidatorFn =
        controlConfig.length > 2 ? controlConfig[2] : null;
      return this.tsmControl(value, validator, asyncValidator);
    } else {
      return this.tsmControl(controlConfig);
    }
  }

  /** @internal */
  _createControl2<T>(
    controls:
      | T
      | FormControlState<T>
      | ControlConfig<T>
      | FormControl<T>
      | AbstractControl<T>,
  ): FormControl<T> | FormControl<T | null> | AbstractControl<T> {
    if (controls instanceof FormControl) {
      return controls as FormControl<T>;
    } else if (controls instanceof AbstractControl) {
      // A control; just return it
      return controls;
    } else if (Array.isArray(controls)) {
      // ControlConfig Tuple
      const value: T | FormControlState<T> = controls[0];
      const validator: ValidatorFn | ValidatorFn[] | null =
        controls.length > 1 ? controls[1]! : null;
      const asyncValidator: AsyncValidatorFn | AsyncValidatorFn[] | null =
        controls.length > 2 ? controls[2]! : null;
      return this.control<T>(value, validator, asyncValidator);
    } else {
      // T or FormControlState<T>
      return this.control<T>(controls);
    }
  }

  /** @internal */
  _createTypedControl<T>(
    controls:
      | T
      | FormControlState<T>
      | ControlConfig<T>
      | TypedTsmFormControl<T>
      | AbstractControl<T>,
  ):
    | TypedTsmFormControl<T>
    | TypedTsmFormControl<T | null>
    | TypedTsmAbstractControl<T> {
    if (controls instanceof TypedTsmFormControl) {
      return controls as TypedTsmFormControl<T>;
    } else if (controls instanceof TypedTsmAbstractControl) {
      // A control; just return it
      return controls;
    } else if (Array.isArray(controls)) {
      // ControlConfig Tuple
      const value: T | FormControlState<T> = controls[0];
      const validator: ValidatorFn | ValidatorFn[] | null =
        controls.length > 1 ? controls[1]! : null;
      const asyncValidator: AsyncValidatorFn | AsyncValidatorFn[] | null =
        controls.length > 2 ? controls[2]! : null;
      return this.typedTsmControl<T>(value, validator, asyncValidator);
    } else {
      // T or FormControlState<T>
      return this.typedTsmControl<T>(controls);
    }
  }
}
