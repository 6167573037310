import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {UserGroupSkill, User} from '../model';

export const LoadUserGroupSkillsByUserId = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserId',
  props<{
    userId: string;
  }>(),
);
export const LoadUserGroupSkillsByUserIds = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserIds',
  props<{
    userIds: string[];
  }>(),
);
export const LoadUserGroupSkillsByUserIdSuccess = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserId Success',
  props<{
    entities: UserGroupSkill[];
  }>(),
);
export const LoadUserGroupSkillsByUserIdError = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserId Error',
  props<{
    error: any;
  }>(),
);

export const LoadUserGroupSkillsByUserGroupId = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserGroupId',
  props<{
    userId: string;
  }>(),
);
export const LoadUserGroupSkillsByUserGroupIds = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserGroupIds',
  props<{
    userIds: string[];
  }>(),
);
export const LoadUserGroupSkillsByUserGroupIdSuccess = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserGroupId Success',
  props<{
    entities: UserGroupSkill[];
  }>(),
);
export const LoadUserGroupSkillsByUserGroupIdError = createAction(
  '[UserGroupSkill] Load UserGroupSkills ByUserGroupId Error',
  props<{
    error: any;
  }>(),
);

export const UpsertUserGroupSkill = createAction(
  '[UserGroupSkill] Upsert UserGroupSkill',
  props<{
    userGroupSkill: UserGroupSkill;
  }>(),
);
export const UpsertUserGroupSkillSuccess = createAction(
  '[UserGroupSkill] Upsert UserGroupSkill Success',
  props<{
    userGroupSkill: UserGroupSkill;
  }>(),
);
export const UpsertUserGroupSkillError = createAction(
  '[UserGroupSkill] Upsert UserGroupSkill Error',
  props<{
    error: any;
  }>(),
);

export const UpsertUserGroupSkills = createAction(
  '[UserGroupSkill] Upsert UserGroupSkills',
  props<{
    entities: UserGroupSkill[];
  }>(),
);
export const UpsertUserGroupSkillsSuccess = createAction(
  '[UserGroupSkill] Upsert UserGroupSkills Success',
  props<{
    entities: UserGroupSkill[];
  }>(),
);
export const UpsertUserGroupSkillsError = createAction(
  '[UserGroupSkill] Upsert UserGroupSkills Error',
  props<{
    error: any;
  }>(),
);

export const UpsertMoreUserGroupSkill = createAction(
  '[UserGroupSkill] Upsert More UserGroupSkill',
  props<{
    rows: {user: User & {[key: string]: number}[]}[];
  }>(),
);
export const UpsertMoreUserGroupSkillSuccess = createAction(
  '[UserGroupSkill] Upsert More UserGroupSkill Success',
  props<{
    entities: UserGroupSkill[];
  }>(),
);
export const UpsertMoreUserGroupSkillError = createAction(
  '[UserGroupSkill] Upsert More UserGroupSkill Error',
  props<{
    error: any;
  }>(),
);

export const DeleteUserGroupSkill = createAction(
  '[UserGroupSkill] Delete UserGroupSkill',
  props<{id: string}>(),
);
export const DeleteUserGroupSkillSuccess = createAction(
  '[UserGroupSkill] Delete UserGroupSkill Success',
  props<{
    id: string;
  }>(),
);
export const DeleteUserGroupSkillError = createAction(
  '[UserGroupSkill] Delete UserGroupSkill Error',
  props<TsmError>(),
);

export const LoadUserGroupSkillsBySkillId = createAction(
  '[UserGroupSkill] Load UserGroupSkills BySkillId',
  props<{
    skillId: string;
  }>(),
);
export const LoadUserGroupSkillsBySkillIds = createAction(
  '[UserGroupSkill] Load UserGroupSkills BySkillIds',
  props<{
    skillIds: string[];
  }>(),
);
export const LoadUserGroupSkillsBySkillIdSuccess = createAction(
  '[UserGroupSkill] Load UserGroupSkills BySkillId Success',
  props<{
    entities: UserGroupSkill[];
  }>(),
);
export const LoadUserGroupSkillsBySkillIdError = createAction(
  '[UserGroupSkill] Load UserGroupSkills BySkillId Error',
  props<{
    error: any;
  }>(),
);
