import {NgModuleFactory, Type} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@tsm/framework/http';
import {ConfigService} from '@tsm/framework/config';

export interface HasWidgets {
  typeMap: {[selector: string]: Type<any>};
}

export enum DesignerDataColorOverride {
  purple = 'purple',
  blue = 'blue',
  grey = 'grey',
}

export enum GroupPosition {
  'Primary' = 1,
  'Secondary' = 2,
  'Tertiary' = 3,
}

export enum DesignerCategoryEnum {
  FavoriteComponents,
  BasicComponents,
  Layout,
  Page,
  Crm,
  Billing,
  Ordering,
  Wfm,
  Ticketing,
  ChangeManagement,
  Inventory,
  Stock,
  UserManagement,
  Catalogs,
  ProcessManagement,
  Dms,
  Audit,
  Reports,
  Reporting,
  Scripting,
  AddressManagement,
  Calendar,
  Listing,
  Logging,
  PreventiveMaintenance,
  Advanced,
  TechnicalPreview,
}

export interface BaseDesignerData {
  name: string;
  icon: string;
  description: string;
  // Pro zobrazeni v menu
  aliasSelector?: string;
  loadComponent?: () => Promise<Type<any>>;
  categoryId?: DesignerCategoryEnum;
  backgroundColorOverride?: DesignerDataColorOverride;
  // určí skupinu pro řazení
  group?: GroupPosition;
  // určí pořadí ve skupině definované v group
  order?: number;
  value: {
    type: string;
    widget: {type: string} & {[key: string]: any};
  } & {
    [key: string]: any;
  };
}

export type DesignerData =
  | (BaseDesignerData & {
      // Pro zobrazeni v menu
      editorSelector?: string;
      // urceni typu
      widgetOrLayout: 'widget';
      // Form field
      formField: string;
      loadComponent?: () => Promise<Type<any>>;
    })
  | (BaseDesignerData & {
      // Pro zobrazeni v menu
      editorSelector: string;
      // urceni typu
      widgetOrLayout: 'layout';
      loadComponent?: () => Promise<Type<any>>;
    });

export interface DtlWidgets {
  loadChildren?: () => Promise<NgModuleFactory<any> | Type<any>>;
  definitions: {
    selector: string;
    config?: any;
    bannedInputs?: string[];
    privilege?: string[];
    widgetMapper?: (input: any, items?: any) => any;
    designers?: DesignerData[];
    loadComponent?: () => Promise<Type<any>>;
  }[];
}

export interface DtlFilters {
  loadChildren?: () => Promise<NgModuleFactory<any> | Type<any>>;
  definitions: {
    selector: string;
    name?: string;
    description?: string;
    loadComponent?: () => Promise<Type<any>>;
  }[];
}

export interface DtlPipes {
  loadChildren?: () => Promise<NgModuleFactory<any> | Type<any>>;
  definitions: {
    selector: string;
    name?: string;
    description?: string;
    loadComponent?: () => Promise<Type<any>>;
  }[];
}

export interface DtlDirectives {
  loadChildren?: () => Promise<NgModuleFactory<any> | Type<any>>;
  definitions: {
    selector: string;
    name?: string;
    description?: string;
    loadComponent?: () => Promise<Type<any>>;
  }[];
}

export interface TsmEntityTypes {
  entityType: string;
  url: (
    entityId: string,
    apiService: ApiService,
    configService: ConfigService,
  ) => Observable<string>;
  priority?: number; // ktera se vyhodnocuje 1.
}

export enum DesignerIconsEnum {
  list = 'ui-icon-table-list text-red-700',
  select = 'ui-icon-dropdown text-purple-700', // tsm-icons-ui-dropdown-bar
  bookmark = 'ui-icon-book-bookmark text-blue-700',
  statusbar = 'ui-icon-bars-progress-1 text-indigo-700',
  history = 'ui-icon-audit text-cyan-700',
  button = 'ui-icon-subscription-alt text-pink-700',
  card = 'ui-icon-cards-blank text-teal-700',
  search = 'ui-icon-search text-blue-700',
  table = 'ui-icon-grid-alt text-red-700',
  chart = 'ui-icon-chart-pie-alt text-blue-700',
  dialog = 'ui-icon-window-restore text-orange-600',
  input = 'ui-icon-input-numeric text-bluegray-700',
  panel = 'ui-icon-window-maximize text-green-700',
}
