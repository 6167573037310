import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {
  controlsDetailConfiguration,
  controlsWfmDetailConfiguration,
  controlsWfmPopupDetailConfiguration,
} from './tsm-controls-configuration';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/process/plugins-i18n';

export const processInstancePlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'process-instance',
      loadChildren: () =>
        import('@tsm/process/instance').then((x) => x.ProcessInstanceModule),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/process/widgets').then((x) => x.ProcessWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-list').then(
              (x) => x.TaskListComponent,
            ),
          selector: 'tsm-task-list',
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-status-split-button').then(
              (x) => x.TaskStatusSplitButtonComponent,
            ),
          selector: 'tsm-task-status-split-button',
        },
        {
          selector: 'tsm-process-instance-diagrams',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-process-instance-diagrams-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.shared.tasks,
              icon: 'p-icon-person',
              name: translationShared.shared.tasks,
              value: {
                type: 'string',
                title: translationShared.shared.tasks,
                widget: {
                  type: 'tsm-process-instance-diagrams',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/process-instance-tasks/process-instance-tasks'
            ).then((x) => x.ProcessInstanceTasksComponent),
          selector: 'tsm-process-instance-tasks',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/process-instance-tasks/process-instance-tasks-editor'
                ).then((x) => x.ProcessInstanceTasksEditorComponent),
              editorSelector: 'tsm-process-instance-tasks-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets
                .processInstanceTasksBookmark.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.processInstanceTasksBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-process-instance-tasks',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-history/task-history').then(
              (x) => x.TaskHistoryComponent,
            ),
          selector: 'tsm-task-history',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-history/task-history-editor'
                ).then((x) => x.TaskHistoryEditorComponent),
              editorSelector: 'tsm-task-history-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskHistoryBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskHistoryBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-task-history',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-logs/task-logs').then(
              (x) => x.TaskLogsComponent,
            ),
          selector: 'tsm-task-logs',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import('@tsm/process/widgets/task-logs/task-logs-editor').then(
                  (x) => x.TaskLogsEditorComponent,
                ),
              editorSelector: 'tsm-task-logs-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskLogsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskLogsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-task-logs',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-variables/task-variables').then(
              (x) => x.TaskVariablesComponent,
            ),
          selector: 'tsm-task-variables',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-variables/task-variables-editor'
                ).then((x) => x.TaskVariablesEditorComponent),
              editorSelector: 'tsm-task-variables-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskVariablesBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskVariablesBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-task-variables',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-details/task-details').then(
              (x) => x.TaskDetailsComponent,
            ),
          selector: 'tsm-task-details',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-details/task-details-editor'
                ).then((x) => x.TaskDetailsEditorComponent),
              editorSelector: 'tsm-task-details-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskDetailsCard.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskDetailsCard
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.card,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-task-details',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/process/widgets').then((x) => x.ProcessWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/deploy-state-filter').then(
              (x) => x.DeployStateFilterComponent,
            ),
          selector: 'tsm-deploy-state-filter',
          name: translationPlugin.processPlugins.filters.tsmDeployStateFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmDeployStateFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/filters/process-group-action-filter'
            ).then((x) => x.ProcessGroupActionFilterComponent),
          selector: 'tsm-process-group-action-filter',
          name: translationPlugin.processPlugins.filters
            .tsmProcessGroupActionFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessGroupActionFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/process-group-filter').then(
              (x) => x.ProcessGroupFilterComponent,
            ),
          selector: 'tsm-process-group-filter',
          name: translationPlugin.processPlugins.filters.tsmProcessGroupFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessGroupFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/process-state-filter').then(
              (x) => x.ProcessStateFilterComponent,
            ),
          selector: 'tsm-process-state-filter',
          name: translationPlugin.processPlugins.filters.tsmProcessStateFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessStateFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/process-type-filter').then(
              (x) => x.ProcessTypeFilterComponent,
            ),
          selector: 'tsm-process-type-filter',
          name: translationPlugin.processPlugins.filters.tsmProcessTypeFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessTypeFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/task-filter').then(
              (x) => x.TaskFilterComponent,
            ),
          selector: 'tsm-task-filter',
          name: translationPlugin.processPlugins.filters.tsmTaskFilter,
          description:
            translationPlugin.processPlugins.filters.tsmTaskFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/task-status-filter').then(
              (x) => x.TaskStatusFilterComponent,
            ),
          selector: 'tsm-task-status-filter',
          name: translationPlugin.processPlugins.filters.tsmTaskStatusFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmTaskStatusFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/filters/task-status-group-filter'
            ).then((x) => x.TaskStatusGroupFilterComponent),
          selector: 'tsm-task-status-group-filter',
          name: translationPlugin.processPlugins.filters
            .tsmTaskStatusGroupFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmTaskStatusGroupFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/task-type-filter').then(
              (x) => x.TaskTypeFilterComponent,
            ),
          selector: 'tsm-task-type-filter',
          name: translationPlugin.processPlugins.filters.tsmTaskTypeFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmTaskTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/process/widgets').then((x) => x.ProcessWidgetsModule),
      definitions: [{selector: 'taskStatusCode'}],
    },
  ],
  layoutControls: [
    controlsDetailConfiguration,
    controlsWfmPopupDetailConfiguration,
    controlsWfmDetailConfiguration,
  ],
};
