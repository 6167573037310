import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Attachment} from '../models';
import {
  DeleteAllDataAttachment,
  DeleteAllDataAttachmentError,
  DeleteAllDataAttachmentSuccess,
  DeleteDataAttachment,
  DeleteDataAttachmentError,
  DeleteDataAttachmentSuccess,
  DownloadDataAttachment,
  DownloadDataAttachmentError,
  DownloadDataAttachmentSuccess,
  LoadDataAttachment,
  LoadDataAttachmentError,
  LoadDataAttachmentSuccess,
  UploadDataAndDeleteAllAttachment,
  UploadDataAndDeleteAllAttachmentError,
  UploadDataAndDeleteAllAttachmentSuccess,
  UploadDataAttachment,
  UploadDataAttachmentError,
  UploadDataAttachmentSuccess,
} from '../actions';

export interface AttachmentState {
  id: string;
  ownerId: string;
  ownerType: string;
  readonly: boolean;
  attachments: Attachment[];
  error: any;
  loading: boolean;
}

export const attachmentAdapter: EntityAdapter<AttachmentState> =
  createEntityAdapter<AttachmentState>();
export const initialState: EntityState<AttachmentState> =
  attachmentAdapter.getInitialState({});

export const attachmentsReducer = createReducer(
  initialState,

  on(LoadDataAttachment, (state, {ownerId, ownerType, readonly}) =>
    attachmentAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ownerId: ownerId,
        ownerType: ownerType,
        attachments: [],
        loading: true,
        readonly: readonly,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadDataAttachmentSuccess,
    (state, {ownerId, ownerType, attachments, readonly}) =>
      attachmentAdapter.upsertOne(
        {
          id: ownerId + ownerType,
          ownerId: ownerId,
          ownerType: ownerType,
          attachments: attachments,
          loading: false,
          readonly: readonly,
          error: null,
        },
        state,
      ),
  ),

  on(LoadDataAttachmentError, (state, {ownerId, ownerType, error}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(UploadDataAttachment, (state, {ownerId, ownerType}) =>
    attachmentAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ...state.entities[ownerId + ownerType],
        loading: true,
      },
      state,
    ),
  ),

  on(
    UploadDataAttachmentSuccess,
    (state, {ownerId, ownerType, attachments}) => {
      const tmpAttachments = [...attachments];
      state.entities[ownerId + ownerType]?.attachments
        ?.filter((att1) => !attachments.some((att2) => att1.id === att2.id))
        .forEach((x) => tmpAttachments.push(x));
      return attachmentAdapter.upsertOne(
        {
          id: ownerId + ownerType,
          ...state.entities[ownerId + ownerType],
          attachments: tmpAttachments,
          loading: false,
        },
        state,
      );
    },
  ),

  on(UploadDataAttachmentError, (state, {ownerId, ownerType, error}) =>
    attachmentAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ...state.entities[ownerId + ownerType],
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DownloadDataAttachment, (state, {ownerId, ownerType}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DownloadDataAttachmentSuccess, (state, {ownerId, ownerType}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
        },
      },
      state,
    ),
  ),

  on(DownloadDataAttachmentError, (state, {ownerId, ownerType, error}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(
    DeleteDataAttachment,
    DeleteAllDataAttachment,
    (state, {ownerId, ownerType}) =>
      attachmentAdapter.updateOne(
        {
          id: ownerId + ownerType,
          changes: {
            ...state.entities[ownerId + ownerType],
            loading: true,
          },
        },
        state,
      ),
  ),

  on(DeleteDataAttachmentSuccess, (state, {ownerId, ownerType, attachmentId}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          attachments: state.entities[ownerId + ownerType]?.attachments?.filter(
            (att) => att.id !== attachmentId,
          ),
          loading: false,
        },
      },
      state,
    ),
  ),

  on(DeleteAllDataAttachmentSuccess, (state, {ownerId, ownerType}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          attachments: [],
          loading: false,
        },
      },
      state,
    ),
  ),

  on(
    DeleteDataAttachmentError,
    DeleteAllDataAttachmentError,
    (state, {ownerId, ownerType, error}) =>
      attachmentAdapter.updateOne(
        {
          id: ownerId + ownerType,
          changes: {
            ...state.entities[ownerId + ownerType],
            loading: false,
            error: error,
          },
        },
        state,
      ),
  ),

  on(UploadDataAndDeleteAllAttachment, (state, {ownerId, ownerType}) =>
    attachmentAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(
    UploadDataAndDeleteAllAttachmentSuccess,
    (state, {ownerId, ownerType, attachments}) =>
      attachmentAdapter.updateOne(
        {
          id: ownerId + ownerType,
          changes: {
            ...state.entities[ownerId + ownerType],
            attachments: [
              ...attachments,
              ...state.entities[ownerId + ownerType]?.attachments?.filter(
                (att1) => !attachments.some((att2) => att1.id === att2.id),
              ),
            ],
            loading: false,
          },
        },
        state,
      ),
  ),

  on(
    UploadDataAndDeleteAllAttachmentError,
    (state, {ownerId, ownerType, error}) =>
      attachmentAdapter.updateOne(
        {
          id: ownerId + ownerType,
          changes: {
            ...state.entities[ownerId + ownerType],
            loading: false,
            error: error,
          },
        },
        state,
      ),
  ),
);
