import {
  CommentImagesSvgBundle,
  FileImagesSvgBundle,
  NotificationImagesSvgBundle,
  PIconsBundle,
  TsmIconsBundle,
  UiIconsBundle,
} from './bundles';

export class PickerBundleList {
  static getList(): PickerBundle[] {
    return [
      {name: 'ALL', code: 'all', type: null, prefix: null, content: null},
      {
        name: 'TSM-ICONS',
        code: 'tsm',
        type: 'icon',
        prefix: 'tsm-icon-',
        content: new TsmIconsBundle().getAll(),
      },
      {
        name: 'P-ICONS',
        code: 'p',
        type: 'icon',
        prefix: 'p-icon-',
        content: new PIconsBundle().getAll(),
      },
      {
        name: 'UI-ICONS',
        code: 'ui',
        type: 'icon',
        prefix: 'ui-icon-',
        content: new UiIconsBundle().getAll(),
      },
      {
        name: 'FILE-IMAGES',
        code: 'files',
        type: 'image',
        prefix: 'file-types/',
        content: new FileImagesSvgBundle().getAll(),
      },
      {
        name: 'COMMENT-IMAGES',
        code: 'comments',
        type: 'image',
        prefix: 'comments/',
        content: new CommentImagesSvgBundle().getAll(),
      },
      {
        name: 'NOTIFICATION-IMAGES',
        code: 'notifications',
        type: 'image',
        prefix: 'notification/',
        content: new NotificationImagesSvgBundle().getAll(),
      },
    ];
  }
}

export interface PickerBundle {
  name: string;
  code: string;
  type: 'icon' | 'image';
  prefix: string;
  content: string[];
}
