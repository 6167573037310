import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {RolePriv} from '../model';

export const LoadRolePrivsByRoleId = createAction(
  '[RolePriv] Load RolePrivs ByRoleId',
  props<{roleId: string}>(),
);
export const LoadRolePrivsByRoleIdSuccess = createAction(
  '[RolePriv] Load RolePrivs ByRoleId Success',
  props<{
    entities: RolePriv[];
  }>(),
);
export const LoadRolePrivsByRoleIdError = createAction(
  '[RolePriv] Load RolePrivs ByRoleId Error',
  props<{
    error: TsmError;
  }>(),
);

export const UpsertRolePriv = createAction(
  '[RolePriv] Upsert RolePriv',
  props<{
    rolePriv: RolePriv;
    listingId: string;
  }>(),
);
export const UpsertRolePrivSuccess = createAction(
  '[RolePriv] Upsert RolePriv Success',
  props<{
    rolePriv: RolePriv;
    listingId: string;
  }>(),
);
export const UpsertRolePrivError = createAction(
  '[RolePriv] Upsert RolePriv Error',
  props<{error: TsmError}>(),
);

export const SaveAllRolePrivs = createAction(
  '[RolePriv] Save RolePrivs',
  props<{
    rolePrivs: RolePriv[];
    listingId: string;
  }>(),
);
export const SaveAllRolePrivsSuccess = createAction(
  '[RolePriv] Save All RolePrivs Success',
  props<{
    rolePrivs: RolePriv[];
    listingId: string;
  }>(),
);
export const SaveAllRolePrivsError = createAction(
  '[RolePriv] Save All RolePrivs Error',
  props<{error: TsmError}>(),
);

export const DeleteRolePriv = createAction(
  '[RolePriv] Delete RolePriv',
  props<{id: string; listingId: string}>(),
);
export const DeleteRolePrivSuccess = createAction(
  '[RolePriv] Delete RolePriv Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteRolePrivError = createAction(
  '[RolePriv] Delete RolePriv Error',
  props<{error: TsmError}>(),
);

export const DeleteAllRolePrivs = createAction(
  '[RolePriv] Delete All RolePrivs',
  props<{
    ids: string[];
    listingId: string;
  }>(),
);
export const DeleteAllRolePrivsSuccess = createAction(
  '[RolePriv] Delete All RolePrivs Success',
  props<{
    ids: string[];
    listingId: string;
  }>(),
);
export const DeleteAllRolePrivsError = createAction(
  '[RolePriv] Delete All RolePrivs Error',
  props<{
    error: TsmError;
  }>(),
);
