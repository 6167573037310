export const en = {
  configFormService: {
    effects: {
      entitySpecificationSaveSuccess: 'Saving the specification was successful',
      entitySpecificationSaveFailure:
        'An error occurred while saving the specification',
      entitySpecificationDeleteSuccess:
        'Deleting the specification was successful',
      entitySpecificationDeleteFailure:
        'An error occurred while deleting the specification',
      diffEntitySpecificationSaveSuccess:
        'Saving Entity specifications diff finished with success.',
      diffEntitySpecificationSaveFailure:
        'Error occurred during saving Entity specifications diff.',
      formSaveSuccess: 'Saving form was successful',
      formSaveFailure: 'An error occurred while saving the form',
      registerSaveSuccess: 'Saving the register was successful',
      registerSaveFailure: 'An error occurred while saving the register',
      registerValueSaveSuccess: 'Saving register value was successful',
      registerValueSaveFailure:
        'An error occurred while saving the register value',
      registerValueDeleteSuccess: 'Deleting register value was successful',
      registerValueDeleteFailure:
        'An error occurred while deleting the register value',
      sequenceSaveSuccess: 'Saving sequence value was successful',
      formDeleteSuccess: 'Deleting region finished with success',
      formDeleteError:
        'Error occurred during deleting form, make sure that form is not used by characteristic or register.',
      entityTypeDeleteSuccess: 'Deleting Entity Type was successful.',
      entityTypeDeleteError:
        "An error occurred while saving record. Make sure it's not referenced from another part of the application.",
      formDiffSuccess: 'Saving forms diff finished with success',
      formDiffError: 'Error occurred during saving forms diff.',
      diffRegisterSuccess: 'Error occurred during saving forms diff.',
      diffRegisterError: 'Error occurred during saving forms diff.',
    },
  },
  microserviceService: {
    effects: {
      microserviceSaveSuccess: 'Saving microservice was successful.',
      microserviceSaveError: 'An error occurred while saving microservice',
      microserviceDeleteSuccess: 'Deleting microservice finished with success',
      microserviceDeleteError:
        'Error occurred while deleting microservice, make sure that microservice is not referenced from different part of the application.',
      microserviceDiffSuccess: 'Saving microservice diff finished with success',
      microserviceDiffError: 'Error occurred during saving microservice diff.',
    },
  },
  tsmModuleService: {
    effects: {
      tsmModuleSaveSuccess: 'Saving TSM module was successful.',
      tsmModuleSaveError: 'An error occurred while saving TSM module',
      tsmModuleDeleteSuccess: 'Deleting TSM module finished with success',
      tsmModuleDeleteError:
        'Error occurred during deleting TSM module, make sure that TSM module is not being referenced in the application.',
      tsmModuleDiffSuccess: 'Saving diff TSM modules finished with success',
      tsmModuleDiffError: 'Error occurred during saving diff TSM modules.',
    },
  },
  configTypeService: {
    effects: {
      configTypeDeleteSuccess: 'Deleting config type finished with success',
      configTypeDeleteError:
        'Error occurred during deleting config type, make sure that config type is not used by characteristic, register, specification or in forms.',
      configTypeSaveSuccessful: 'Saving config type finished successfully',
      configTypeSaveUnSuccessful: 'Error occurred during saving config type',
      messageConfigTypeChangeSuccessful:
        'Changing config type finished successfully',
      messageConfigTypeSaveUnSuccessful:
        'Error occurred during saving config type',
      configTypeDiffSuccess: 'Saving config type diff finished with success',
      configTypeDiffError: 'Error occurred during saving config type diff.',
    },
  },
  mnemonicService: {
    effects: {
      mnemonicSaveSuccess: 'Saving mnemonic was successful.',
      mnemonicSaveError: 'An error occurred while saving mnemonic',
      mnemonicDeleteSuccess: 'Deleting mnemonic finished with success',
      mnemonicDeleteError:
        'Error occurred while deleting mnemonic, make sure that mnemonic is not referenced from different part of the application.',
      diffMnemonicSuccess: 'Saving Mnemonics diff finished with success.',
      diffMnemonicFailure: 'Error occurred during saving Mnemonics diff.',
    },
  },
  registerValue: {
    effects: {
      diffRegisterValueSuccess:
        'Saving register value diff finished with success.',
      diffRegisterValueError:
        'Error occurred during saving register value diff.',
    },
  },
  backupSet: {
    effects: {
      backupSetUpsertSuccess: 'Saving backup set finished with success.',
      backupSetUpsertError: 'An error occurred while saving backup set.',
      backupSetDeleteSuccess: 'Deleting backup set finished with success.',
      backupSetDeleteError: 'An error occurred while deleting backup set.',
      backupSetExportError: 'An error occurred while exporting backup set.',
    },
  },
};
