import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarModule} from 'primeng/sidebar';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {Ripple} from 'primeng/ripple';
import {RouterModule} from '@angular/router';
import {ContainerWrapperFieldsetComponent} from './container-wrapper-fieldset';
import {ContainerWrapperCardComponent} from './container-wrapper-card';
import {ContainerWrapperComponent} from './container-wrapper/container-wrapper.component';
import {ContainerComponent} from './container/container.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FrameworkGridModule,
    SidebarModule,
    TooltipModule,
    RouterModule,
    Ripple,
  ],
  declarations: [
    ContainerComponent,
    ContainerWrapperComponent,
    ContainerWrapperCardComponent,
    ContainerWrapperFieldsetComponent,
  ],
  exports: [
    ContainerComponent,
    ContainerWrapperComponent,
    ContainerWrapperCardComponent,
    ContainerWrapperFieldsetComponent,
  ],
})
export class ContainerWrapperModule {}
