<input
  [ngStyle]="{display: readonly ? 'none' : ''}"
  [style]="style"
  type="text"
  #inputElement
  [(ngModel)]="val"
  [tabindex]="tabindex"
  [placeholder]="placeholder ? (placeholder | transloco) : ''"
  class="p-inputtext p-corner-all p-state-default p-component"
  [attr.aria-labelledby]="labelForId"
  data-cy="dtl-form-input-text"
/>
<span
  [id]="labelForId"
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
  [ngClass]="val != null ? 'readonly-text' : 'readonly-text empty'"
  [style.color]="color != null && color !== '' ? color + '!important' : null"
  [style.white-space]="overflowValueHidden ? 'nowrap' : null"
  [tsmAutoSizeTooltip]="val"
  tooltipPosition="top"
  [useTargetElement]="true"
>
  {{ val != null ? (val | formatPhone) : noneText }}
</span>
