// manual imports
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '../i18n';

// IDE imports
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {
  DeleteAllMyNotifications,
  DeleteAllMyNotificationsError,
  DeleteAllMyNotificationsSuccess,
  DeleteMyNotification,
  DeleteMyNotificationError,
  DeleteMyNotificationSuccess,
  DisplayedAllMyNotifications,
  DisplayedAllMyNotificationsError,
  DisplayedAllMyNotificationsSuccess,
  LoadAllMyNotification,
  LoadAllMyNotificationError,
  LoadAllMyNotificationSuccess,
  LoadMyNotificationByFilter,
  LoadMyNotificationByFilterError,
  LoadMyNotificationByFilterSuccess,
  LoadNotificationCount,
  UpsertMyNotification,
  UpsertMyNotificationError,
  UpsertMyNotificationSuccess,
} from '../actions';
import {NotificationMyService} from '../services';
import {DeliveryItemType} from '../models';

@Injectable()
export class NotificationMyEffects {
  translation = translation;
  translationShared = translationShared;

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAllMyNotification),
      mergeMap((action) => {
        return this.notificationMyService
          .getNotificationMyByUserId(action.userId)
          .pipe(
            map((userNotif) =>
              userNotif.success
                ? LoadAllMyNotificationSuccess({
                    userId: action.userId,
                    notifications: userNotif.data,
                  })
                : LoadAllMyNotificationError({
                    userId: action.userId,
                    error: userNotif.error,
                  }),
            ),
          );
      }),
    ),
  );

  loadDataByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMyNotificationByFilter),
      mergeMap((action) => {
        return this.notificationMyService
          .getNotificationMyByUserIdByFilters(
            action.userId,
            action.page,
            action.size,
            action.filters,
          )
          .pipe(
            map((userNotif) =>
              userNotif.success
                ? LoadMyNotificationByFilterSuccess({
                    userId: action.userId,
                    notifications: userNotif.data.content,
                  })
                : LoadMyNotificationByFilterError({
                    userId: action.userId,
                    error: userNotif.error,
                  }),
            ),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMyNotification),
      concatMap(({notification, userId}) =>
        this.notificationMyService.upsertMyNotification(notification).pipe(
          map((env) =>
            env.success
              ? UpsertMyNotificationSuccess({
                  notification: env.data,
                  userId: userId,
                })
              : UpsertMyNotificationError({error: env.error}),
          ),
        ),
      ),
    ),
  );

  displayedAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayedAllMyNotifications),
      concatMap(({userId, notificationType}) =>
        this.notificationMyService
          .displayedAllMyNotifications(userId, notificationType)
          .pipe(
            map((env) =>
              env.success
                ? DisplayedAllMyNotificationsSuccess({userId, notificationType})
                : DisplayedAllMyNotificationsError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  displayedAllSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayedAllMyNotificationsSuccess),
      map(({userId, notificationType}) =>
        LoadNotificationCount({userId, deliveryType: notificationType}),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMyNotification),
      concatMap(({id, userId}) =>
        this.notificationMyService
          .deleteMyNotificationById(id)
          .pipe(
            map((env) =>
              env.success
                ? DeleteMyNotificationSuccess({id: id, userId})
                : DeleteMyNotificationError({id: id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllMyNotifications),
      concatMap(({userId, notificationType, status}) =>
        this.notificationMyService
          .deleteAllMyNotifications(userId, notificationType, status)
          .pipe(
            map((env) =>
              env.success
                ? DeleteAllMyNotificationsSuccess({
                    userId,
                    notificationType,
                    status,
                  })
                : DeleteAllMyNotificationsError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  deleteAllSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAllMyNotificationsSuccess),
      map(({userId, notificationType}) =>
        LoadNotificationCount({userId, deliveryType: notificationType}),
      ),
    ),
  );

  upsertSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMyNotificationSuccess),
      debounceTime(300),
      filter(
        (action) =>
          action.notification.deliveryType === DeliveryItemType.APPLICATION ||
          action.notification.deliveryType === DeliveryItemType.USER_COMMENT,
      ),
      map((action) =>
        LoadNotificationCount({
          userId: action.userId,
          deliveryType: action.notification.deliveryType,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationMyService: NotificationMyService,
  ) {}
}
