import {TsmFormBuilder} from '@tsm/framework/forms';

/**
 *
 * @returns {TsmFormGroup} - A form group containing the basic editor configuration with the specified or default parameters.
 */
export function getBasicEditorDefaultConfigForm(
  fb: TsmFormBuilder,
  defaults?: {
    keyboardOnMobile?: boolean;
    showClear?: boolean;
    showNoneText?: boolean;
    selectFirstValue?: boolean;
    selectProperty?: 'id' | 'code' | 'key' | 'all' | string;
    multiselect?: boolean;
    selectionMode?: 'single' | 'multiple';
    returnType?: 'string' | 'array';
    displayField?: any;
  },
) {
  defaults = {
    keyboardOnMobile: true,
    showClear: true,
    showNoneText: true,
    selectFirstValue: false,
    selectProperty: 'id',
    selectionMode: 'multiple',
    multiselect: false,
    returnType: 'array',
    displayField: 'name',
    ...defaults,
  };
  return fb.tsmGroup({
    keyboardOnMobile: fb.tsmControl(defaults.keyboardOnMobile),
    showClear: fb.tsmControl(defaults.showClear),
    showNoneText: fb.tsmControl(defaults.showNoneText),
    selectFirstValue: fb.tsmControl(defaults.selectFirstValue),
    selectProperty: fb.tsmControl(defaults.selectProperty),
    multiselect: fb.tsmControl(defaults.multiselect),
    selectionMode: fb.tsmControl(defaults.selectionMode),
    returnType: fb.tsmControl(defaults.returnType),
    defaultFilters: fb.tsmControl(),
    displayField: fb.tsmControl(defaults.displayField),
  });
}
