import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {WorklogDataService, WorklogService} from '../services';
import {exhaustMap, map} from 'rxjs/operators';
import {
  DeleteDataWorklog,
  DeleteDataWorklogError,
  DeleteDataWorklogSuccess,
  SaveDataWorklog,
  SaveDataWorklogError,
  SaveDataWorklogSuccess,
} from '../actions';
import {translation} from '../i18n';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {ListingService, RefreshData} from '@tsm/listing-lib/service';
import {Config, ConfigService} from '@tsm/framework/config';
import {Store} from '@ngrx/store';

@Injectable()
export class WorklogEffects {
  saveData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveDataWorklog),
      exhaustMap(({ownerId, ownerType, worklog, noSuccessToast, listingId}) =>
        this.worklogDataService.saveWorklog(ownerId, ownerType, worklog).pipe(
          map((env) => {
            if (env.success) {
              if (!noSuccessToast) {
                this.toast.showToast(
                  translation.worklogs.save.success,
                  ToastSeverity.SUCCESS,
                  3000,
                );
              }
              return SaveDataWorklogSuccess({
                ownerId: ownerId,
                ownerType: ownerType,
                worklog: env.data,
                listingId: listingId,
              });
            } else {
              this.toast.showError(env.error, translation.worklogs.save.error);
              return SaveDataWorklogError({
                ownerId: ownerId,
                ownerType: ownerType,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  deleteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteDataWorklog),
      exhaustMap(({ownerId, ownerType, worklog, listingId}) =>
        this.worklogDataService.deleteWorklogById(worklog.id).pipe(
          map((env) => {
            if (env.success) {
              this.toast.showToast(
                translation.worklogs.delete.success,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteDataWorklogSuccess({
                ownerId: ownerId,
                ownerType: ownerType,
                worklogId: worklog.id,
                listingId: listingId,
              });
            } else {
              this.toast.showError(
                env.error,
                translation.worklogs.delete.error,
              );
              return DeleteDataWorklogError({
                ownerId: ownerId,
                ownerType: ownerType,
                error: env.error,
              });
            }
          }),
        ),
      ),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveDataWorklogSuccess),
      map(({listingId}) => RefreshData({id: listingId})),
    ),
  );

  refreshAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteDataWorklogSuccess),
      map(({listingId}) => RefreshData({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private worklogService: WorklogService,
    private listingService: ListingService,
    private worklogDataService: WorklogDataService,
    private config: ConfigService<Config>,
    private store: Store,
    private toast: ToastService,
  ) {}
}
