import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  DeleteRoleSuccess,
  DiffRole,
  DiffRoleError,
  DiffRoleSuccess,
  LoadRoleByCode,
  LoadRoleByCodeError,
  LoadRoleByCodeSuccess,
  LoadRoleById,
  LoadRoleByIdError,
  LoadRoleByIdSuccess,
  LoadRoles,
  LoadRolesError,
  LoadRolesSuccess,
  UpsertRoleSuccess,
} from '../actions';
import {Role} from '../model';

export type RoleState = EntityState<IdentifiedPack<Role>>;

export const adapter: EntityAdapter<IdentifiedPack<Role>> = createEntityAdapter<
  IdentifiedPack<Role>
>({});

export const initialState: RoleState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadRolesSuccess, (state, {entities}) => ({
    ...adapter.addMany(
      entities.map((roleOnly) => ({
        id: roleOnly.id,
        data: roleOnly,
        loading: false,
        error: null,
      })),
      state,
    ),
  })),

  on(LoadRoleById, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadRoleByIdSuccess, (state, {role}) =>
    adapter.upsertOne(
      {
        id: role.id,
        data: role,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadRoleByIdError, (state, {id, error}) =>
    adapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadRoleByCode, (state, {code}) =>
    adapter.upsertOne(
      {
        id: code,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadRoleByCodeSuccess, (state, customer) => {
    const newState = adapter.removeOne(customer.code, state);
    return adapter.upsertOne(
      {
        id: customer.id,
        data: customer,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadRoleByCodeError, (state, {code, error}) =>
    adapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadRoles, (state, {}) => ({...state, loading: true})),

  on(LoadRolesError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(UpsertRoleSuccess, (state, {role}) =>
    adapter.upsertOne(
      {
        id: role.id,
        data: role,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteRoleSuccess, (state, role) => adapter.removeOne(role.id, state)),

  on(DiffRole, (state, {diffEntities}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffRoleSuccess, (state, {roles}) =>
    adapter.upsertMany(
      roles.map((role) => {
        return {
          id: role.id,
          data: {
            ...state.entities[role.id].data,
            ...role,
          },
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffRoleError, (state, {diffEntities, error}) =>
    adapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
