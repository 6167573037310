import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'externalLink',
  pure: true,
})
export class ExternalLinkPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    return !!value
      ? '<a href="' + value + '" rel="noopener noreferrer">' + value + '</a>'
      : '';
  }
}
