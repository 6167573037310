<ng-container *ngLet="suggestions$ | async as suggestions">
  <span
    [ngClass]="{
      'p-autocomplete p-component': true,
      'p-autocomplete-dd': dropdown,
      'p-autocomplete-multiple': true,
      'p-disabled': disabled,
      'p-focus': focus,
    }"
    [ngStyle]="style"
    [class]="styleClass"
  >
    <ul
      #multiContainer
      class="p-autocomplete-multiple-container p-component p-inputtext p-state-default p-corner-all"
      (click)="multiIn.focus()"
    >
      <li
        #token
        *ngFor="let val of valOptions"
        class="p-autocomplete-token p-highlight p-corner-all"
      >
        <span
          *ngIf="!selectedItemTemplate"
          class="p-autocomplete-token-label"
          [class.cursor-pointer]="canShowFilteredPage"
          (click)="showFilteredPage(val.value)"
          >{{ val.label }}</span
        >
        <ng-container
          *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: val}"
        ></ng-container>
        <span
          class="p-autocomplete-token-icon pi pi-times-circle"
          (click)="removeItem(token)"
          *ngIf="!disabled"
        ></span>
      </li>
      <!--   hack pro nase readonly zobrazeni -->
      <li *ngIf="disabled && value.length === 0" class="readonly-text empty">
        none
      </li>
      <li class="p-autocomplete-input-token">
        <input
          #multiIn
          [attr.type]="type"
          [attr.id]="inputId"
          [disabled]="disabled"
          [attr.placeholder]="value && value.length ? null : placeholder"
          [attr.tabindex]="tabindex"
          [attr.maxlength]="maxlength"
          (input)="onInput($event)"
          (click)="onInputClick($event)"
          (keydown)="onKeydown($event, suggestions)"
          [readonly]="readonly"
          (keyup)="onKeyup($event)"
          [attr.autofocus]="autofocus"
          (focus)="onInputFocus($event)"
          (blur)="onInputBlur($event)"
          (change)="onInputChange($event, suggestions)"
          (paste)="onInputPaste($event, suggestions)"
          [autocomplete]="autocomplete"
          [ngStyle]="inputStyle"
          [class]="inputStyleClass"
          [attr.aria-label]="ariaLabel"
          [attr.aria-labelledby]="ariaLabelledBy"
          [attr.aria-required]="required"
          aria-autocomplete="list"
          role="searchbox"
          [attr.aria-expanded]="overlayVisible"
          aria-haspopup="true"
          [attr.aria-activedescendant]="'p-highlighted-option'"
        />
      </li>
    </ul>
    <i *ngIf="loading" class="p-autocomplete-loader pi pi-spinner pi-spin"></i>
    @if (!hideDropdownButton) {
      <button
        #ddBtn
        type="button"
        pButton
        pRipple
        [icon]="dropdownIcon"
        class="p-autocomplete-dropdown"
        [disabled]="disabled"
        (click)="handleDropdownClick($event)"
        *ngIf="dropdown"
        [attr.tabindex]="tabindex"
        [attr.aria-label]="getAriaLabel()"
      ></button>
    }
    <p-overlay
      #overlay
      [(visible)]="overlayVisible"
      [options]="virtualScrollOptions"
      [target]="'@parent'"
      [appendTo]="appendTo"
      [showTransitionOptions]="showTransitionOptions"
      [hideTransitionOptions]="hideTransitionOptions"
      (onAnimationStart)="onOverlayAnimationStart($event)"
      (onAnimationDone)="onOverlayAnimationDone($event)"
      (onHide)="hide()"
    >
      <div
        [ngClass]="['p-autocomplete-panel p-component']"
        [style.max-height]="virtualScroll ? 'auto' : scrollHeight"
        [ngStyle]="panelStyle"
        [class]="panelStyleClass"
      >
        <ng-container *ngIf="!virtualScroll">
          <ng-container
            *ngTemplateOutlet="
              buildInItems;
              context: {$implicit: suggestions, options: {}}
            "
          ></ng-container>
        </ng-container>
        <ng-template #buildInItems let-items let-scrollerOptions="options">
          <ul
            #items
            role="listbox"
            class="p-autocomplete-items"
            [ngClass]="scrollerOptions.contentStyleClass"
            [style]="scrollerOptions.contentStyle"
          >
            <ng-container *ngIf="!group">
              <ng-container
                *ngTemplateOutlet="itemslist; context: {$implicit: items}"
              ></ng-container>
            </ng-container>
            <ng-template #itemslist let-suggestionsToDisplay>
              <li
                role="option"
                *ngFor="let option of suggestions; let idx = index"
                [ngClass]="{
                  'p-element p-autocomplete-item': true,
                  'p-highlight': highlightOption == option,
                }"
                (mouseenter)="highlightOption = option"
                (mouseleave)="highlightOption = null"
                [id]="highlightOption == option ? 'p-highlighted-option' : ''"
                (click)="selectItem(option)"
              >
                <span *ngIf="!itemTemplate">{{ option.label }}</span>
                <ng-container
                  *ngTemplateOutlet="
                    itemTemplate;
                    context: {$implicit: option, index: idx}
                  "
                >
                </ng-container>
              </li>
            </ng-template>
            <li
              *ngIf="noResults && emptyMessage"
              class="p-autocomplete-empty-message"
              [ngStyle]="{height: scrollerOptions.itemSize + 'px'}"
            >
              {{ emptyMessage | transloco }}
            </li>
          </ul>
        </ng-template>
      </div>
    </p-overlay>
  </span>
</ng-container>
