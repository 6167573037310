import {createAction, props} from '@ngrx/store';

import {RuntimeInfo} from '../models';

export const LoadRuntimeInfoAction = createAction(
  '[RuntimeInfo] Load',
  props<{refresh: boolean}>(),
);

export const RuntimeInfoLoadSuccessAction = createAction(
  '[RuntimeInfo] Load Success',
  props<{runtimeInfo: RuntimeInfo}>(),
);

export const RuntimeInfoLoadFailedAction = createAction(
  '[RuntimeInfo] Load Failed',
  props<{error: string}>(),
);

export const DeleteRuntimeInfoAction = createAction('[RuntimeInfo] Delete');
