import {FilterModel} from '../models/filter.model';

function compareFilterValue(oldValue: any, newValue: any) {
  if (typeof oldValue === 'object' && typeof newValue === 'object') {
    return JSON.stringify(oldValue) === JSON.stringify(newValue);
  }
  return oldValue === newValue;
}

export function compareFilter(oldFilter: FilterModel, newFilter: FilterModel) {
  return (
    newFilter.field === oldFilter.field &&
    newFilter.operator === oldFilter.operator &&
    compareFilterValue(oldFilter.value, newFilter.value)
  );
}

export function compareFilterNoVal(
  oldFilter: FilterModel,
  newFilter: FilterModel,
) {
  return (
    newFilter.field === oldFilter.field &&
    newFilter.operator === oldFilter.operator
  );
}

/**
 * Compares two arrays of filters
 * @param oldFilters
 * @param newFilters
 * @return true if same, false if not
 */
export function compareFilters(
  oldFilters: FilterModel[],
  newFilters: FilterModel[],
) {
  if (oldFilters.length !== newFilters.length) {
    return false;
  }

  for (let i = 0; i < oldFilters.length; i++) {
    const oldFilter = oldFilters[i];

    const foundSame = newFilters.find((x) => compareFilter(oldFilter, x));
    if (foundSame == null) {
      return false;
    }
  }
  return true;
}
