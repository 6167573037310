import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {concatMap, filter, map, mergeMap, tap} from 'rxjs/operators';
import {animationBuffer, withLatestCached} from '@tsm/framework/root';
import {getUserId, mergeArraysToArray} from '@tsm/framework/functions';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {
  LoadRuntimeInfoAction,
  RuntimeInfoLoadSuccessAction,
  RuntimeService,
} from '@tsm/runtime-info';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {
  LoadBatchUserById,
  LoadBatchUserByIdError,
  LoadBatchUserByIds,
  LoadBatchUserByIdsError,
  LoadBatchUserByIdsSuccess,
  LoadBatchUserByIdSuccess,
  LoadUserByCode,
  LoadUserByCodeError,
  LoadUserByCodeSuccess,
  LoadUserById,
  LoadUserByIdError,
  LoadUserByIds,
  LoadUserByIdsError,
  LoadUserByIdsSuccess,
  LoadUserByIdSuccess,
  LoadUsers,
  LoadUsersError,
  LoadUsersSuccess,
  PatchUser,
  PatchUserError,
  PatchUserSuccess,
  SaveUserNotificationConfig,
  SaveUserNotificationConfigError,
  SaveUserNotificationConfigSuccess,
  UpsertDialogPopup,
  UpsertUser,
  UpsertUserError,
  UpsertUserSuccess,
} from '../actions';
import {EntityIdentif, Status, User} from '../model';
import {getAllUsers, selectUserByCode, selectUserById} from '../selectors';
import {CommonApiService, UserService, UserSettingsService} from '../service';
import {Router} from '@angular/router';
import {translation} from '../i18n';
import {LoginFinishedSuccess, Logout} from '@tsm/core';
import {
  UpsertUserParameterByUserIdAndName,
  UserParameterName,
} from '@tsm/user-parameters';
import {isAfter} from 'date-fns';

@Injectable()
export class UserEffects {
  translation = translation;

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserById),
      withLatestCached((action) =>
        this.store.select(selectUserById(action.id)),
      ),
      concatMap(([action, user]) => {
        return !action?.forcedReload && user.data
          ? of(LoadUserByIdSuccess({user: user.data}))
          : this.commonApiService
              .getEntity(EntityIdentif.USER_INFO, action.id)
              .pipe(
                map((env) =>
                  env.success
                    ? LoadUserByIdSuccess({user: env.data})
                    : LoadUserByIdError({id: action.id, error: env.error}),
                ),
              );
      }),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserByCode),
      withLatestCached((action) =>
        this.store.select(selectUserByCode(action.code)),
      ),
      concatMap(([action, user]) => {
        return user?.data
          ? of(LoadUserByCodeSuccess({user: user.data}))
          : this.userService.getUserByCode(action.code).pipe(
              map((env) =>
                env.success
                  ? LoadUserByCodeSuccess({user: env.data})
                  : LoadUserByCodeError({
                      code: action.code,
                      error: env.error,
                    }),
              ),
            );
      }),
    ),
  );

  loadByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserByIds),
      concatMap(({ids}) => {
        return this.commonApiService
          .getEntitiesByIdsSharedRequest(EntityIdentif.USER_INFO, ids)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserByIdsSuccess({entities: env.data})
                : LoadUserByIdsError({ids: ids, error: env.error}),
            ),
          );
      }),
    ),
  );

  loadBatchById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadBatchUserById),
      animationBuffer(
        this.actions$,
        LoadBatchUserById,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((ids) => ids.map((x) => x.id)),
      map((ids) => Array.from(new Set(ids))),
      concatMap((ids) => {
        return this.commonApiService
          .getEntitiesByIdsSharedRequest(EntityIdentif.USER_INFO, ids)
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchUserByIdSuccess({entities: response.data})
                  : LoadBatchUserByIdError({ids: ids, error: response.error});
              return temp;
            }),
          );
      }),
    );
  });

  loadBatchByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBatchUserByIds),
      animationBuffer(
        this.actions$,
        LoadBatchUserByIds,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((ids) => mergeArraysToArray(ids.map((x) => x.ids))),
      map((ids) => Array.from(new Set(ids))),
      concatMap((ids) => {
        return this.commonApiService
          .getEntitiesByIdsSharedRequest(EntityIdentif.USER_INFO, ids)
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchUserByIdsSuccess({entities: response.data})
                  : LoadBatchUserByIdsError({ids: ids, error: response.error});
              return temp;
            }),
          );
      }),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUsers),
      withLatestCached((_) => this.store.select(getAllUsers)),
      concatMap(([_, users]) => {
        const someInvalid = !!users.find(
          (u) => !u.validUntil || isAfter(new Date(), new Date(u.validUntil)),
        );
        return someInvalid
          ? this.commonApiService
              .getAll(EntityIdentif.USER_INFO)
              .pipe(
                map((env) =>
                  env.success
                    ? LoadUsersSuccess({entities: env.data})
                    : LoadUsersError(env.error),
                ),
              )
          : of(LoadUsersSuccess({entities: users.map((x) => x.data)}));
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUser),
      concatMap(({user, listingId, redirectToDetail}) =>
        this.userService.upsertUser(user.id, user).pipe(
          mergeMap((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.userSaveSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              const actions: Action[] = [
                UpsertUserSuccess({
                  user: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                }),
                UpsertDialogPopup({
                  parentComponentName: 'user',
                  saving: false,
                  successSaving: true,
                  error: env.error,
                }),
              ];
              if (user.version == null) {
                actions.push(
                  UpsertUserParameterByUserIdAndName({
                    name: UserParameterName.LANGUAGE,
                    userId: env.data.id,
                    value: user.language.toLocaleLowerCase(),
                  }),
                );
              }
              return actions;
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects.userSaveFailure,
              );
              return [
                UpsertUserError(env.error),
                UpsertDialogPopup({
                  parentComponentName: 'user',
                  saving: false,
                  successSaving: false,
                  error: env.error,
                }),
              ];
            }
          }),
        ),
      ),
    ),
  );

  redirectToDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserSuccess),
      tap(({user, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            '/user-management/user-detail/'.concat(user.id),
          ]);
        }
      }),
      mergeMap((action) => {
        if (getUserId() === action.user.id) {
          return [
            RefreshDataAndClearSelected({id: action.listingId}),
            LoadRuntimeInfoAction({refresh: true}),
          ];
        }
        return [RefreshDataAndClearSelected({id: action.listingId})];
      }),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatchUserSuccess, UpsertUserSuccess),
      mergeMap((action) => {
        if (getUserId() === action.user.id) {
          return [
            ...(Array.isArray(action.listingId)
              ? action.listingId.map((id) => RefreshDataAndClearSelected({id}))
              : [RefreshDataAndClearSelected({id: action.listingId})]),
            RuntimeInfoLoadSuccessAction({runtimeInfo: action.user}),
          ];
        }
        return Array.isArray(action.listingId)
          ? action.listingId.map((id) => RefreshDataAndClearSelected({id}))
          : [RefreshDataAndClearSelected({id: action.listingId})];
      }),
    ),
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatchUser),
      concatMap(({id, user, listingId, redirect}) =>
        this.commonApiService
          .patchEntity(EntityIdentif.USER_INFO, id, user)
          .pipe(
            map((env) => {
              if (env.success) {
                if (env.data.status === Status.DISABLE) {
                  this.ts.showToast(
                    translation.userManagementService.effects
                      .userDeactivationSuccess,
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                } else {
                  this.ts.showToast(
                    translation.userManagementService.effects
                      .userActivationSuccess,
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                }
                return PatchUserSuccess({
                  id: env.data.id,
                  listingId: listingId,
                  user: env.data,
                  redirect: redirect,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .userDeactivationSuccess,
                );
                return PatchUserError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  saveNotifConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveUserNotificationConfig),
      // groupBy(action => action.id),
      // mergeMap(group =>
      //   group.pipe(debounceTime(500), switchMap(latestValue => of(latestValue)))
      // ),
      concatMap((action) =>
        this.userService
          .updateNotificationConfig(action.id, action.config)
          .pipe(
            map((envelope) => {
              if (envelope.success) {
                return SaveUserNotificationConfigSuccess({
                  id: action.id,
                  config: action.config,
                });
              } else {
                this.ts.showError(
                  envelope.error,
                  translation.userManagementService.effects
                    .saveUserNotificationConfigError,
                );
                return SaveUserNotificationConfigError({
                  id: action.id,
                  error: envelope.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<User, User>,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private ts: ToastService,
    private runtimeService: RuntimeService,
    private userSettingsService: UserSettingsService,
  ) {}
}
