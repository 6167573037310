import * as objectPath from 'object-path';

export function groupBy<T = any>(array: Array<T>, property: string): any {
  const hash = {};
  for (let i = 0; i < array.length; i++) {
    const a = objectPath.get(array[i], property);
    if (!hash[a]) {
      hash[a] = [];
    }
    hash[a].push(array[i]);
  }
  return hash;
}

export function groupByFun<T = any>(
  array: Array<T>,
  fun: (item: T) => string,
): any {
  const hash = {};
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const property = fun(item);
    if (!hash[property]) {
      hash[property] = [];
    }
    hash[property].push(item);
  }
  return hash;
}
