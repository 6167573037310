import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';

@Injectable({
  providedIn: 'root',
})
export class FormatterService {
  private readonly FORMATTER = `formatter`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  generate(
    documentTemplateCode: string,
    format: string,
    data: any,
  ): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL + `/${this.FORMATTER}/${documentTemplateCode}/${format}`,
      data,
      null,
      {
        observe: 'response',
        responseType: (format == 'html' ? 'text' : 'blob') as 'json',
      },
    );
  }

  getContentPreview(
    formatterTemplateId: string,
    format: string,
    templateData: any,
    testData: any,
  ): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL +
        `/${this.FORMATTER}/contentPreview/${formatterTemplateId}/${format}`,
      {
        templateData: templateData,
        testData: testData,
      },
      null,
      {
        observe: 'response',
        responseType: (format == 'html' ? 'text' : 'blob') as 'json',
      },
    );
  }

  markdownToHtml(content: string): Observable<any> {
    return this.apiService.postRaw(
      this.BASE_URL + `/${this.FORMATTER}/markdown-html`,
      {content: content},
      null,
      {observe: 'response', responseType: 'text' as 'json'},
    );
  }
}
