<div
  class="tsm-tooltip-dialog"
  [style.width.rem]="maxWidthRem > 45 ? maxWidthRem : 45"
>
  <div
    class="tsm-tooltip-dialog-header"
    cdkDrag
    cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
  >
    <span class="tsm-tooltip-dialog-title">
      {{ translation.tooltip.dragMe | transloco }}
    </span>
    <div class="tsm-tooltip-dialog-header-icons">
      <button
        pButton
        pRipple
        class="p-button-text p-button-plain p-button-rounded"
        icon="pi pi-times"
        (click)="closeDialog()"
      ></button>
    </div>
  </div>
  <div class="tsm-tooltip-dialog-content h-full w-full">
    <ng-container *ngIf="asString as tooltip">
      {{ tooltip }}
    </ng-container>

    <ng-container *ngIf="asTemplate as tooltipTemplate">
      <ng-template [ngTemplateOutlet]="tooltipTemplate"></ng-template>
    </ng-container>
  </div>
</div>
