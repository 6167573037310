<ng-container *ngIf="state">
  <div class="layout-wrapper" [ngClass]="{'mobile-device': isMobile}">
    <ng-container *ngLet="darkTheme$ | async as darkTheme">
      <div
        class="layout-container menu-layout-static"
        [ngClass]="{'layout-container-dark': darkTheme}"
        [class.layout-menu-static-active]="state.topbar.staticMenuMobileActive"
        [class.layout-menu-static-inactive]="
          state.topbar.staticMenuDesktopInactive
        "
      >
        <div class="layout-main teams-layout">
          <p-blockUI [target]="layoutContent" [blocked]="false"></p-blockUI>
          <tsm-blockable-div #layoutContent class="layout-content">
            <router-outlet></router-outlet>
          </tsm-blockable-div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
