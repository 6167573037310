import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import {ConnectedOverlayScrollHandler, DomHandler} from 'primeng/dom';
import {
  OverlayService,
  PrimeNGConfig,
  PrimeTemplate,
} from 'primeng/api';
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {ZIndexUtils} from 'primeng/utils';
import {Subscription} from 'rxjs';
import {isMobile} from '@tsm/framework/functions';
import { NgIf, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Ripple } from 'primeng/ripple';

@Component({
    selector: 'dtl-overlayPanel',
    templateUrl: './overlay-panel.component.html',
    styleUrls: ['./overlay-panel.component.scss'],
    animations: [
        trigger('animation', [
            state('void', style({
                transform: 'scaleY(0.8)',
                opacity: 0,
            })),
            state('close', style({
                opacity: 0,
            })),
            state('open', style({
                transform: 'translateY(0)',
                opacity: 1,
            })),
            transition('void => open', animate('{{showTransitionParams}}')),
            transition('open => close', animate('{{hideTransitionParams}}')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'p-element',
    },
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        Ripple,
    ],
})
export class OverlayPanel implements AfterContentInit, OnDestroy {
  @Input() closePrevious = true;

  @Input() dismissable = true;

  @Input() showCloseIcon: boolean;

  @Input() style: any;

  @Input() styleClass: string;

  @Input() appendTo: any;

  @Input() autoZIndex = true;

  @Input() ariaCloseLabel: string;

  @Input() baseZIndex = 0;

  @Input() focusOnShow = true;

  @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';

  @Input() hideTransitionOptions = '.1s linear';

  @Input() disableScrollHide = true;

  @Input() target: any;

  @Input() forceOutsideTarget = false;

  @Output() onShow: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  @Output() animationStarted: EventEmitter<void> = new EventEmitter();

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  container: HTMLDivElement;

  overlayVisible = false;

  render = false;

  selfClick = false;

  documentClickListener: any;

  willHide: boolean;

  scrollHandler: any;

  documentResizeListener: any;

  contentTemplate: TemplateRef<any>;

  destroyCallback: Function;

  overlayEventListener;

  overlaySubscription: Subscription;

  isMobile = isMobile;

  // Add a static property to track open panels
  private static openPanels: OverlayPanel[] = [];

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    private zone: NgZone,
    public config: PrimeNGConfig,
    public overlayService: OverlayService,
  ) {
    // Add this instance to the component class
    OverlayPanel.openPanels.push(this);
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;

        default:
          this.contentTemplate = item.template;
          break;
      }

      this.cd.markForCheck();
    });
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener && this.dismissable) {
      this.zone.runOutsideAngular(() => {
        const documentEvent = DomHandler.isIOS() ? 'touchstart' : 'click';
        const documentTarget: any = this.el
          ? this.el.nativeElement.ownerDocument
          : 'document';

        this.documentClickListener = this.renderer.listen(
          documentTarget,
          documentEvent,
          (event) => {
            if (
              !this.container.contains(event.target) &&
              this.target !== event.target &&
              !this.target.contains(event.target) &&
              !this.selfClick &&
              !(
                this.hasDisabledOverlayPanel(event?.target?.parentElement) ||
                this.hasDisabledPathOverlayPanel(event?.path)
              )
            ) {
              this.zone.run(() => {
                this.hide();
              });
            }

            this.selfClick = false;
            this.cd.markForCheck();
          },
        );
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
      this.selfClick = false;
    }
  }

  toggle(event, target?) {
    // Close all other open panels first
    if (this.closePrevious) {
      OverlayPanel.openPanels.forEach((panel) => {
        if (panel !== this && panel.overlayVisible) {
          panel.hide();
        }
      });
    }

    if (this.overlayVisible) {
      if (this.hasTargetChanged(event, target)) {
        this.destroyCallback = () => {
          this.show(null, target || event.currentTarget || event.target);
        };
      }
      this.hide();
    } else {
      this.show(event, target);
    }
  }

  show(event, target?) {
    this.target = this.forceOutsideTarget
      ? this.target
      : target || event?.currentTarget || event?.target;
    this.overlayVisible = true;
    this.render = true;
    this.cd.markForCheck();
  }

  onOverlayClick(event) {
    this.overlayService.add({
      originalEvent: event,
      target: this.el.nativeElement,
    });

    this.selfClick = true;
  }

  onContentClick() {
    this.selfClick = true;
  }

  hasTargetChanged(event, target) {
    return (
      this.target != null &&
      this.target !== (target || event.currentTarget || event.target)
    );
  }

  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.container);
      } else {
        DomHandler.appendChild(this.container, this.appendTo);
      }
    }
  }

  restoreAppend() {
    if (this.container && this.appendTo) {
      this.el.nativeElement.appendChild(this.container);
    }
  }

  align() {
    if (this.autoZIndex) {
      ZIndexUtils.set(
        'overlay',
        this.container,
        this.baseZIndex + this.config.zIndex.overlay,
      );
    }

    DomHandler.absolutePosition(this.container, this.target);

    const containerOffset = DomHandler.getOffset(this.container);
    const targetOffset = DomHandler.getOffset(this.target);
    let arrowLeft = 0;

    if (containerOffset.left < targetOffset.left) {
      arrowLeft = targetOffset.left - containerOffset.left;
    }
    this.container.style.setProperty('--overlayArrowLeft', `${arrowLeft}px`);

    if (containerOffset.top < targetOffset.top) {
      DomHandler.addClass(this.container, 'p-overlaypanel-flipped');
    }
  }

  onAnimationStart(event: AnimationEvent) {
    if (event.toState === 'open') {
      this.container = event.element;
      this.onShow.emit(null);
      this.appendContainer();
      this.align();
      this.bindDocumentClickListener();
      this.bindDocumentResizeListener();
      this.bindScrollListener();

      if (this.focusOnShow) {
        this.focus();
      }

      this.overlayEventListener = (e) => {
        if (this.container && this.container.contains(e.target)) {
          this.selfClick = true;
        }
      };

      this.overlaySubscription = this.overlayService.clickObservable.subscribe(
        this.overlayEventListener,
      );
    }

    this.animationStarted.emit();
  }

  onAnimationEnd(event: AnimationEvent) {
    switch (event.toState) {
      case 'void':
        if (this.destroyCallback) {
          this.destroyCallback();
          this.destroyCallback = null;
        }

        if (this.overlaySubscription) {
          this.overlaySubscription.unsubscribe();
        }
        break;

      case 'close':
        if (this.autoZIndex) {
          ZIndexUtils.clear(this.container);
        }

        if (this.overlaySubscription) {
          this.overlaySubscription.unsubscribe();
        }

        this.onContainerDestroy();
        this.onHide.emit({});
        this.render = false;
        break;
    }
  }

  focus() {
    const focusable = DomHandler.findSingle(this.container, '[autofocus]');
    if (focusable) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => focusable.focus(), 5);
      });
    }
  }

  hide() {
    this.overlayVisible = false;
    this.cd.markForCheck();
  }

  onCloseClick(event) {
    this.hide();
    event.preventDefault();
  }

  onWindowResize() {
    if (!this.isMobile) {
      this.hide();
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(
        this.target,
        () => {
          if (this.overlayVisible && !this.disableScrollHide) {
            this.hide();
          }
        },
      );
    }

    this.scrollHandler.bindScrollListener();
  }

  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }

  onContainerDestroy() {
    if (!(this.cd as ViewRef).destroyed) {
      this.target = null;
    }

    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
  }

  ngOnDestroy() {
    if (this.closePrevious) {
      const index = OverlayPanel.openPanels.indexOf(this);
      if (index > -1) {
        OverlayPanel.openPanels.splice(index, 1);
      }
    }

    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }

    if (this.container && this.autoZIndex) {
      ZIndexUtils.clear(this.container);
    }

    if (!(this.cd as ViewRef).destroyed) {
      this.target = null;
    }

    this.destroyCallback = null;
    if (this.container) {
      this.restoreAppend();
      this.onContainerDestroy();
    }

    if (this.overlaySubscription) {
      this.overlaySubscription.unsubscribe();
    }
  }

  private hasDisabledOverlayPanel(parentElement: any): boolean {
    let disabled = parentElement?.className.includes('disabled-overlay-panel');
    let disabledParentElement = parentElement;
    while (disabled == false && disabledParentElement.parentElement !== null) {
      disabledParentElement = disabledParentElement.parentElement;
      disabled = disabledParentElement?.className.includes(
        'disabled-overlay-panel',
      );
    }
    return disabled;
  }

  private hasDisabledPathOverlayPanel(path: any[]): boolean {
    return path?.some((x) => x?.className?.includes('disabled-overlay-panel'));
  }
}


