import {Injector, Pipe, PipeTransform, Type} from '@angular/core';
import {combineLatest, isObservable, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {distinctArrays, mergeArraysToArray} from '@tsm/framework/functions';
import * as objectPath from 'object-path';

@Pipe({
  name: 'arrayToString',
  pure: true,
})

/**
 * Pipe, ktera prevede pole objektu do stringu,
 * ve kterem ty objekty budou oddelene pozadovanym separatorem.
 * Take umi prevadet objekty na string dle nekolika parametru tzn.,
 * treba object ma prop1, prop2, a podproperty prop2.prop21, tato funkce umi
 * udelat vupis tvaru prop1
 */
export class ArrayToStringPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  /**
   * Defaultnim separatorem je mezera
   * @param array pole objektu
   * @param inListing pokud je true a je vstupní seznam prázdný, pak se nevypíše nic. Jinak při prázdném seznamu 'none'
   * @param fieldsToDisplay cesta k atributu, ktery chceme zobrazit ve vyslednem stringu, pokud
   * atributu je vice, nez jeden, cesty k zajimajicim hodnotam MUSEJI byt oddelene carkou, za
   * carkou NE sleduje zadna mezera
   * @param separator oddelovac, kterym budo jednotlive polozky pole oddelene mezi sebou
   * @param unique pokud je true, zobrazi jen mnozinu unikatnich hodnot
   * @param elementPipeType pajpa na jednotlivé elementy
   * @param elementPipeArgs parametry pro pajpu
   * @return string, ktery se sklada ze string hodnot, ktere, se nachazi na ceste/ach, ktera/e byly predane jako vstupni parameter
   * teto funce
   */
  transform(
    array: any[],
    inListing: boolean,
    fieldsToDisplay?: string,
    separator = ' ',
    unique = false,
    elementPipeType: Type<PipeTransform> = null,
    elementPipeArgs: any = null,
  ): Observable<string> {
    let resultArray = [];
    if (array == null || array.length === 0) {
      return of(inListing ? '' : 'none');
    }

    // HACK - Elasticsearch nerozlišuje mezi polem a jednoduchou hodnotou.
    // Vše co má uložené je pole. Tím pádem když se zeptáš na výsledek,
    // tak bych normálně vše vracel jako pole. Jenže to ve většině případů nedává smysl,
    // proto když je to jen jedna hodnota, vracím to jako string.
    if (typeof array === 'string' || typeof array === 'number') {
      if (elementPipeType != null) {
        const pipe = this.injector.get<PipeTransform>(elementPipeType);
        const args = !Array.isArray(elementPipeArgs)
          ? [elementPipeArgs]
          : elementPipeArgs;
        const r = pipe.transform(array, ...args);
        if (isObservable(r)) {
          return r as Observable<string>;
        } else {
          return of(r);
        }
      } else {
        return of(array);
      }
    }

    if (fieldsToDisplay != null && fieldsToDisplay.length > 0) {
      array.forEach((val) =>
        fieldsToDisplay
          .split(',')
          .forEach((field) => resultArray.push(objectPath.get(val, field))),
      );
    } else {
      resultArray = array.filter((x) => !!x);
    }

    if (unique) {
      resultArray = distinctArrays(
        null,
        mergeArraysToArray(resultArray).filter((x) => x != null),
      );
    }
    if (resultArray.length === 0) {
      return of(null);
    }
    if (elementPipeType != null) {
      const pipe = this.injector.get<PipeTransform>(elementPipeType);
      const args = !Array.isArray(elementPipeArgs)
        ? [elementPipeArgs]
        : elementPipeArgs;
      const observables = resultArray.map((el) => {
        const r = pipe.transform(el, ...args);
        if (isObservable(r)) {
          return r;
        } else {
          return of(r);
        }
      });

      return combineLatest(observables)
        .pipe(
          map(x => {
              const notNull = x.filter( x => !!x)
              return (unique ? notNull.filter(this.uniqueFn) : notNull).join(separator)
            }
          ),
        );
    }
    const notNull = resultArray.filter( x => !!x)
    return of((unique ? notNull.filter(this.uniqueFn) : notNull).join(separator));
  }

  uniqueFn(value: any, index: number, array: any[]): boolean {
    return array.indexOf(value) === index;
  }
}
