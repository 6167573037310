import {CreateSignalOptions, effect, signal} from '@angular/core';
import {toSignal, ToSignalOptions} from '@angular/core/rxjs-interop';
import {concat, Observable, of} from 'rxjs';

/**
 * toWritableSignal
 *
 * Angular's toSignal returns a readonly signal and, although that is
 * probably the right thing, sometimes it isn't what you want. This is
 * a function that converts observables to writable signals, so that
 * you can update their value.
 *
 * Please note that the value of the signal will change every time the
 * observable emits a value.
 */
export function toWritableSignal<T>(
  source: Observable<T>,
  {initialValue, equal, ...options}: ToWritableSignalOptions<T>,
) {
  const writableSignal = signal<T>(initialValue as T, {equal});

  const readonlySignal = toSignal<T>(concat(of(initialValue), source), {
    ...options,
    requireSync: true,
  });

  effect(
    () => {
      writableSignal.set(readonlySignal());
    },
    {allowSignalWrites: true},
  );

  return writableSignal;
}

export type ToWritableSignalOptions<T> = Omit<
  RequireKeys<ToSignalOptions<T>, 'initialValue'> &
    Pick<CreateSignalOptions<T>, 'equal'>,
  'requireSync'
>;

type RequireKeys<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>;
