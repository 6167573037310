import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgZone,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import { NgStyle, NgClass } from '@angular/common';
import { KeyFilterModule } from 'primeng/keyfilter';

@Component({
    selector: 'dtl-form-input-key-filter',
    templateUrl: './form-input-key-filter.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Style from different component
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputKeyFilterComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FormInputKeyFilterComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputKeyFilterComponent),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        NgStyle,
        KeyFilterModule,
        NgClass,
    ],
})
export class FormInputKeyFilterComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: ElementRef;

  @Input() style: any;

  @Input() keyFilter:
    | 'pint'
    | 'int'
    | 'pnum'
    | 'money'
    | 'num'
    | 'hex'
    | 'email'
    | 'alpha'
    | 'alphanum' = 'pint';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    private ngZone: NgZone,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  @Input()
  set setAutofocus(value: boolean) {
    if (value) {
      this.ngZone.runOutsideAngular(() => {
        this.inputElement.nativeElement.focus();
        this.focused = true;
      });
    }
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.addEventListener('focus', () => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    this.inputElement.nativeElement.addEventListener('blur', () => {
      this.focused = false;
      this.onTouched(true);
      this.cdr.markForCheck();
    });
  }
}
