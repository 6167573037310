export const memoizeFn = (prefix: string, fn: (...args: any[]) => any) => {
  const cache = new Map<string, any>();

  return (...args: any[]) => {
    // Combine the provided prefix with the stringified arguments.
    const cacheKey = `${prefix}-${JSON.stringify(args)}`;

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    } else {
      const placeholder = Symbol('placeholder');
      cache.set(cacheKey, placeholder);

      const result = fn(...args);
      cache.set(cacheKey, result);
      return result;
    }
  };
};

export function memoize(target, name, descriptor) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args) {
    const cacheKey = JSON.stringify(args);
    this._cache = this._cache || {};
    if (this._cache[cacheKey]) {
      return this._cache[cacheKey];
    }

    const result = originalMethod.apply(this, args);
    this._cache[cacheKey] = result;
    return result;
  };

  return descriptor;
}
