import {Pipe, PipeTransform} from '@angular/core';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
} from 'date-fns';

@Pipe({
  name: 'datesDiff',
  pure: true,
})
export class DatesDiffPipe implements PipeTransform {
  transform(
    date: Date,
    diff: string,
    fraction = false,
    negative = false,
    floor = false,
    ceil = false,
  ) {
    if (!date) {
      return '';
    }
    const now = new Date();
    const thatDate = new Date(date);

    let result;
    switch (diff) {
      case 'years':
        result = differenceInYears(thatDate, now);
        break;
      case 'months':
        result = differenceInDays(thatDate, now);
        break;
      case 'days':
        result = differenceInDays(thatDate, now);
        break;
      case 'hours':
        result = differenceInHours(thatDate, now);
        break;
      case 'minutes':
        result = differenceInMinutes(thatDate, now);
        break;
      case 'seconds':
        result = differenceInSeconds(thatDate, now);
        break;
      // Add more cases as needed for different units of time
    }
    if (!negative && (result < 0 || isNaN(result))) {
      return 0;
    }
    if (floor) {
      return Math.floor(result);
    }
    if (ceil) {
      return Math.ceil(result);
    }
    return result;
  }
}
