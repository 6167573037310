import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectConfigTypeById} from '../selectors';
import {LoadConfigTypeById} from '../actions';

@Pipe({
  name: 'entitySpecificationType',
  pure: true,
})
export class EntitySpecificationTypePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(id: any, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectConfigTypeById(this._id)),
      map((it) => {
        if (!it || !it.data) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectConfigTypeById(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadConfigTypeById({id: this._id})),
          );
        }
      });
  }
}
