import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  Renderer2,
} from '@angular/core';

import {of, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  getLayoutVM,
  LayoutTsmMenuService,
  LayoutTsmService,
  LayoutVM,
} from '@tsm/layout/service';
import {RuntimeInfo, RuntimeService} from '@tsm/runtime-info';
import {isMobile} from '@tsm/framework/functions';
import {
  selectUserParameterByUserIdAndName,
  UserParameterName,
} from '@tsm/user-parameters';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {ToastService} from '@tsm/framework/toast';
import {DOCUMENT} from '@angular/common';
import {UiConfigService} from '@tsm/ui-config';

@Component({
  templateUrl: './teams-layout.component.html',
  styleUrls: ['./teams-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsLayoutComponent implements OnDestroy {
  state: LayoutVM;

  isMobile = isMobile();

  isDarkTheme = false;

  darkTheme$ = of(false);
  destroyed$: Subject<void> = new Subject();

  currentUser: RuntimeInfo;

  constructor(
    private zone: NgZone,
    public store: Store<any>,
    private layoutTsmService: LayoutTsmService,
    public toastService: ToastService,
    public runtimeService: RuntimeService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private layoutTsmMenuService: LayoutTsmMenuService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.currentUser = this.runtimeService.getCurrent();

    store
      .select(getLayoutVM())
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => {
        zone.run(() => {
          this.state = x;
        });
      });
    if (this.isMobile) {
      this.renderer.addClass(document.body, 'mobile-device');
    }

    this.runtimeService
      .getCurrentUserObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ui) => {
        this.darkTheme$ = this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.DARK_THEME,
            ),
          )
          .pipe(
            filter((x) => x != null && x.loading === false),
            map((x) => x.data.parameterValue === 'true'),
            tap((darkTheme) => {
              if (darkTheme) {
                this.renderer.setAttribute(
                  document.querySelector('html'),
                  'data-theme',
                  'theme-dark',
                );
                this.renderer.addClass(document.body, 'dark-theme');
              } else {
                this.renderer.setAttribute(
                  document.querySelector('html'),
                  'data-theme',
                  'theme-light',
                );
                this.renderer.removeClass(document.body, 'dark-theme');
              }
              this.cdr.markForCheck();
            }),
          );
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }
}
