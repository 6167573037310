import {TableType} from './table-type.model';
import {FilterModel} from './filter.model';
import {SortModel} from './sort.model';
import {TableColumn} from './table-column.model';
import {TableData} from './table-data.model';
import {ListingProfile} from './listing-profile.model';
import {TableExportSettings} from './table-export-settings.model';
import {TableShowConfig} from './table-show-config.model';
import {ColorModel} from './color.model';
import {TreeTableParams} from './tree-table.model';
import {TableDefault} from './table-default.model';
import {DataViewModeEnum} from './data-view-mode.enum';
import {TableTqlExtend} from './table-tql-extend.model';
import {TableKanban} from './table-kanban.model';
import {BackupSetImportStatus} from './backup-set.model';

export type TableKeyType = string | number;

export interface Table<TDataType = any, TDataTypeKey = TableKeyType> {
  id?: string;
  data: TableData<TDataType>;
  dataTotals: {[key: string]: number};
  profilesLoading: boolean;
  dataLoading: boolean;
  error?: any;
  profiles: ListingProfile[];
  selectedProfileId?: string;
  profileCategory?: string;
  columns: TableColumn[];
  page: number;
  filters: FilterModel[];
  sorts: SortModel[];
  colors: ColorModel;
  pageSize: number;
  selectedRowId?: TDataTypeKey;
  selectedRowIds: TDataTypeKey[];
  selectedAllRows: boolean;
  // zda je jsou videt bulk buttons
  showBulkEdit: boolean;
  // zda existuje template s bulk buttons
  hasBulkButtonsTemplate: boolean;
  exportHeaders: any;
  exportSettings: TableExportSettings;
  type: TableType;
  showConfig: TableShowConfig;
  defaults: TableDefault;
  dataViewMode: DataViewModeEnum;
  customChangeSettingTable?: boolean;
  // slouzi pro definici slupce, podle ktere se sestavuje TreeTable
  tree?: TreeTableParams;
  options?: string;
  isTreeExpanding: boolean;
  exportingData: boolean;
  tqlExtend?: TableTqlExtend;
  setUpTableSettings?: boolean;
  kanban?: TableKanban[];
}

export interface DiffRecord {
  id?: string;
  changes?: any[];
  entityType?: string;
  listingType?: string;
  ignoredFields?: string[];
  diffType?: DiffType;
  left?: any;
  right?: any;
}

export interface DiffSet {
  items: any[];
  totalRecords: number;
}

export interface EntityDiffTable {
  table?: Partial<Table>;
  data?: EntityDiff;
}

export interface RestoreItem {
  table?: Table;
  data?: EntityDiff;
}

export interface EntityDiff {
  entityType?: string;
  diffName?: string;
  listingType?: string;
  importStatus?: BackupSetImportStatus;
  ignoredFields?: string[];
  report?: string;
  diffs?: DiffRecord[];
  summary?: BackupSummary;
}

export interface BackupSummary {
  compared?: number;
  total?: number;
  edited?: number;
  created?: number;
  deleted?: number;
  filterMismatch?: number;
}

export enum DiffType {
  CHANGE = 'DIFF_TYPE_CHANGE',
  NEW = 'DIFF_TYPE_NEW',
  REMOVE = 'DIFF_TYPE_REMOVE',
  FILTER_MISMATCH = 'DIFF_TYPE_CHANGE_FILTER_MISMATCH',
}
