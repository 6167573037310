import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteMnemonicConfig,
  DeleteMnemonicConfigError,
  DeleteMnemonicConfigSuccess,
  DiffMnemonicConfig,
  DiffMnemonicConfigError,
  DiffMnemonicConfigSuccess,
  LoadMnemonicConfig,
  LoadMnemonicConfigByCodeSuccess,
  LoadMnemonicConfigById,
  LoadMnemonicConfigByIdSuccess,
  LoadMnemonicConfigError,
  LoadMnemonicConfigSuccess,
  UpsertMnemonicConfig,
  UpsertMnemonicConfigError,
  UpsertMnemonicConfigSuccess,
} from '../actions';
import {MnemonicConfig} from '../models';
import {addDays} from 'date-fns';

export type MnemonicConfigState = EntityState<IdentifiedPack<MnemonicConfig>>;

export const mnemonicConfigAdapter: EntityAdapter<
  IdentifiedPack<MnemonicConfig>
> = createEntityAdapter<IdentifiedPack<MnemonicConfig>>({});
export const mnemonicConfigInitialState: MnemonicConfigState =
  mnemonicConfigAdapter.getInitialState({});

export const mnemonicConfigReducer = createReducer(
  mnemonicConfigInitialState,

  on(
    LoadMnemonicConfig,
    UpsertMnemonicConfig,
    DeleteMnemonicConfig,
    (state, {}) => ({...state, loading: true}),
  ),

  on(LoadMnemonicConfigSuccess, (state, {entities}) => ({
    ...mnemonicConfigAdapter.upsertMany(
      entities.map((statusOnly) => ({
        id: statusOnly.id,
        data: statusOnly,
        loading: false,
        error: null,
        validUntil: addDays(new Date(), 1),
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(LoadMnemonicConfigById, (state, {id}) =>
    mnemonicConfigAdapter.upsertOne(
      {
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadMnemonicConfigByIdSuccess,
    LoadMnemonicConfigByCodeSuccess,
    (state, {entity}) =>
      mnemonicConfigAdapter.upsertOne(
        {
          id: entity.id,
          data: entity,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(DeleteMnemonicConfigSuccess, (state, {id}) =>
    mnemonicConfigAdapter.removeOne(id, state),
  ),

  on(UpsertMnemonicConfigSuccess, (state, {mnemonicConfig}) =>
    mnemonicConfigAdapter.upsertOne(
      {
        id: mnemonicConfig.id,
        data: mnemonicConfig,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(
    LoadMnemonicConfigError,
    UpsertMnemonicConfigError,
    DeleteMnemonicConfigError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(DiffMnemonicConfig, (state, {diffEntities}) =>
    mnemonicConfigAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffMnemonicConfigSuccess, (state, {mnemonicConfigs}) =>
    mnemonicConfigAdapter.upsertMany(
      mnemonicConfigs.map((characteristic) => {
        return {
          id: characteristic.id,
          data: characteristic,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffMnemonicConfigError, (state, {diffEntities, error}) =>
    mnemonicConfigAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
