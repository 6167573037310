import {SelectItem} from 'primeng/api';

export enum FormType {
  DASHBOARD = 'DASHBOARD',
  DIALOG = 'DIALOG',
  FORM = 'FORM',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  PAGE = 'PAGE',
}

export function getAllFormTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(FormType).forEach((val) =>
    items.push({label: 'shared.formType.' + val, value: val}),
  );
  return items;
}
