import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {of} from 'rxjs';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/user-management/plugins-i18n';
import {controlsUserGroupConfiguration} from './tsm-controls-configuration';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const userManagementPlugin: Plugin = {
  menus: [
    {
      key: 'user-management',
      priority: 1100,
      label: 'menu.userManagement.userManagement',
      icon: 'tsm-icon-user-management',
      privilege: [
        'UM.User.ViewList',
        'UM.UserGroup.ViewList',
        'UM.Role.ViewList',
        'Um.Registers.EventType.ViewList',
        'Um.Registers.UserGroupType.ViewList',
        'Um.Registers.UserType.ViewList',
      ],
      items: [
        {
          key: 'tsm-user-list',
          label: 'menu.userManagement.users',
          icon: 'tsm-icon-user-3',
          routerLink: ['/user-management/user/users'],
          listingType: 'tsm-user',
          privilege: ['UM.User.ViewList'],
        },
        {
          key: 'tsm-user-group-list',
          label: 'menu.userManagement.userGroups',
          icon: 'tsm-icon-user-group-3',
          routerLink: ['/user-management/user-group'],
          listingType: 'tsm-user-group',
          privilege: ['UM.UserGroup.ViewList'],
        },
        {
          key: 'tsm-role-list',
          label: 'menu.userManagement.roles',
          icon: 'tsm-icon-roles',
          routerLink: ['/user-management/role'],
          listingType: 'tsm-role',
          privilege: ['UM.Role.ViewList'],
        },
        {
          key: 'tsm-priv-list',
          label: 'menu.userManagement.privs',
          icon: 'tsm-icon-privilege-escalation',
          routerLink: ['/user-management/priv'],
          listingType: 'tsm-priv',
          privilege: ['Um.Privilege.ViewList'],
        },
        {
          key: 'tsm-access-rule-list',
          label: 'menu.userManagement.accessRules',
          icon: 'tsm-icon-access-control-1',
          routerLink: ['/user-management/access-rule'],
          listingType: 'tsm-access-rule',
          privilege: ['Um.AccessRule.ViewList'],
        },
        {
          key: 'UM_REGISTERS',
          label: 'menu.userManagement.registers',
          icon: 'tsm-icon-registers-1',
          privilege: ['UM.Registers.UserGroupType.ViewList'],
          items: [
            {
              key: 'USER_GROUP_TYPE',
              label: 'menu.userManagement.userGroupType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/user-management/user-group-type'],
              privilege: ['UM.Registers.UserGroupType.ViewList'],
            },
            {
              key: 'USER_TYPE',
              label: 'menu.userManagement.userType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/user-management/user-type'],
              privilege: ['Um.Registers.UserType.ViewList'],
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'user-management',
      loadChildren: () =>
        import('@tsm/user-management/components').then(
          (x) => x.UserManagementComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/user-management/widgets').then(
          (x) => x.UserManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'tsm-favorite-button-user-params',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/favorite-button-user-params'
            ).then((x) => x.FavoriteButtonUserParamsComponent),
        },
        {
          selector: 'tsm-user-image',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-image').then(
              (x) => x.UserImageComponent,
            ),
        },
        {
          selector: 'tsm-user-privilege-lov',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-privilege-lov/user-privilege-lov'
            ).then((x) => x.UserPrivilegeLovComponent),
        },
        {
          selector: 'tsm-user-user-group-lov',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-user-group-lov').then(
              (x) => x.UserUserGroupLovComponent,
            ),
        },
        {
          selector: 'tsm-user-pw-change',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-pw-change').then(
              (x) => x.UserPwChangeComponent,
            ),
        },
        {
          selector: 'tsm-user-lov',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-lov/user-lov').then(
              (x) => x.UserLovComponent,
            ),
          privilege: ['Um.Widget.tsm-user-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-user-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-lov/user-lov-editor'
                ).then((x) => x.UserLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'user',
              categoryId: DesignerCategoryEnum.UserManagement,
              name: translationShared.shared.widgets.userSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.userSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.userSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-user-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-user-role-lov',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-role-lov/user-role-lov'
            ).then((x) => x.UserRoleLovComponent),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-user-role-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-role-lov/user-role-lov-editor'
                ).then((x) => x.UserRoleLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'role',
              categoryId: DesignerCategoryEnum.UserManagement,
              name: translationShared.shared.widgets.userRoleSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.userRoleSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.userRoleSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-user-role-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-user-group-lov',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-group-lov/user-group-lov'
            ).then((x) => x.UserGroupLovComponent),
          privilege: ['Um.Widget.tsm-user-group-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-user-group-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-group-lov/user-group-lov-editor'
                ).then((x) => x.UserGroupLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'userGroup',
              categoryId: DesignerCategoryEnum.UserManagement,
              name: translationShared.shared.widgets.userGroupSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.userGroupSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.userGroupSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-user-group-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-user-group-type-lov',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-group-type-lov/user-group-type-lov'
            ).then((x) => x.UserGroupTypeLovComponent),
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-user-group-type-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-group-type-lov/user-group-type-lov-editor'
                ).then((x) => x.UserGroupTypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'userGroupType',
              categoryId: DesignerCategoryEnum.UserManagement,
              name: translationShared.shared.widgets.userGroupTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.userGroupTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.userGroupTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-user-group-type-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-user-privilege-lov',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-privilege-lov/user-privilege-lov'
            ).then((x) => x.UserPrivilegeLovComponent),
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-user-privilege-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-privilege-lov/user-privilege-lov-editor'
                ).then((x) => x.UserPrivilegeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'userPrivilege',
              categoryId: DesignerCategoryEnum.UserManagement,
              name: translationShared.shared.widgets.userPrivilegeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.userPrivilegeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.userPrivilegeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-user-privilege-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-user-type-lov',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-type-lov/user-type-lov'
            ).then((x) => x.UserTypeLovComponent),
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-user-type-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-type-lov/user-type-lov-editor'
                ).then((x) => x.UserTypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'userType',
              categoryId: DesignerCategoryEnum.UserManagement,
              name: translationShared.shared.widgets.userTypeSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.userTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.userTypeSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-user-type-lov',
                },
                type: 'string',
              },
            },
          ],
        },
        {
          selector: 'tsm-user-group-list-widget',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/user-group-list-widget/user-group-list-widget'
            ).then((x) => x.UserGroupListWidgetComponent),
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-user-group-list-widget-editor',
              loadComponent: () =>
                import(
                  '@tsm/user-management/widgets/user-group-list-widget/user-group-list-widget-editor'
                ).then((x) => x.UserGroupListWidgetEditorComponent),
              widgetOrLayout: 'layout',
              name: translationShared.shared.userGroupList,
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              description: translationShared.shared.userGroupList,
              icon: 'p-icon-assignment',
              value: {
                title: translationShared.shared.userGroupList,
                widget: {
                  type: 'tsm-user-group-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-user-mentions',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-mentions').then(
              (x) => x.UserMentionsComponent,
            ),
        },
        {
          selector: 'tsm-access-rule-card',
          loadComponent: () =>
            import('@tsm/user-management/widgets/access-rule-card').then(
              (x) => x.AccessRuleCardComponent,
            ),
        },
        {
          selector: 'tsm-priv-card',
          loadComponent: () =>
            import('@tsm/user-management/widgets/priv-card').then(
              (x) => x.PrivCardComponent,
            ),
        },
        {
          selector: 'tsm-role-card',
          loadComponent: () =>
            import('@tsm/user-management/widgets/role-card').then(
              (x) => x.RoleCardComponent,
            ),
        },
        {
          selector: 'tsm-user-group-card',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-group-card').then(
              (x) => x.UserGroupCardComponent,
            ),
        },
        {
          selector: 'tsm-user-group-type-card',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-group-type-card').then(
              (x) => x.UserGroupTypeCardComponent,
            ),
        },
        {
          selector: 'tsm-user-card',
          loadComponent: () =>
            import('@tsm/user-management/widgets/user-list-card').then(
              (x) => x.UserListCardComponent,
            ),
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/user-management/widgets').then(
          (x) => x.UserManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'tsm-priv-type-filter',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/filters/priv-type-filter'
            ).then((x) => x.PrivTypeFilterComponent),
          name: translationPlugin.userManagementPlugins.filters
            .tsmPrivTypeFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmPrivTypeFilterDescription,
        },
        {
          selector: 'tsm-role-filter',
          loadComponent: () =>
            import('@tsm/user-management/widgets/filters/role-filter').then(
              (x) => x.RoleFilterComponent,
            ),
          name: translationPlugin.userManagementPlugins.filters.tsmRoleFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmRoleFilterDescription,
        },
        {
          selector: 'tsm-user-filter',
          loadComponent: () =>
            import('@tsm/user-management/widgets/filters/user-filter').then(
              (x) => x.UserFilterComponent,
            ),
          name: translationPlugin.userManagementPlugins.filters.tsmUserFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmUserFilterDescription,
        },
        {
          selector: 'tsm-user-group-filter',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/filters/user-group-filter'
            ).then((x) => x.UserGroupFilterComponent),
          name: translationPlugin.userManagementPlugins.filters
            .tsmUserGroupFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmUserGroupFilterDescription,
        },
        {
          selector: 'tsm-user-group-type-filter',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/filters/user-group-type-filter'
            ).then((x) => x.UserGroupTypeFilterComponent),
          name: translationPlugin.userManagementPlugins.filters
            .tsmUserGroupTypeFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmUserGroupTypeFilterDescription,
        },
        {
          selector: 'tsm-user-status-filter',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/filters/user-status-filter'
            ).then((x) => x.UserStatusFilterComponent),
          name: translationPlugin.userManagementPlugins.filters
            .tsmUserStatusFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmUserStatusFilterDescription,
        },
        {
          selector: 'tsm-user-type-enum-filter',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/filters/user-type-enum-filter'
            ).then((x) => x.UserTypeEnumFilterComponent),
          name: translationPlugin.userManagementPlugins.filters
            .tsmUserTypeEnumFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmUserTypeEnumFilterDescription,
        },
        {
          selector: 'tsm-user-type-filter',
          loadComponent: () =>
            import(
              '@tsm/user-management/widgets/filters/user-type-filter'
            ).then((x) => x.UserTypeFilterComponent),
          name: translationPlugin.userManagementPlugins.filters
            .tsmUserTypeFilter,
          description:
            translationPlugin.userManagementPlugins.filters
              .tsmUserTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/user-management/widgets').then(
          (x) => x.UserManagementWidgetsModule,
        ),
      definitions: [
        {
          selector: 'checkOnline',
          name: translationPlugin.userManagementPlugins.pipes.checkOnline,
          description:
            translationPlugin.userManagementPlugins.pipes
              .checkOnlineDescription,
        },
        {
          selector: 'privLoaderByCode',
          name: translationPlugin.userManagementPlugins.pipes.privLoaderByCode,
          description:
            translationPlugin.userManagementPlugins.pipes
              .privLoaderByCodeDescription,
        },
        {
          selector: 'privLoader',
          name: translationPlugin.userManagementPlugins.pipes.privLoader,
          description:
            translationPlugin.userManagementPlugins.pipes.privLoaderDescription,
        },
        {
          selector: 'roleCode',
          name: translationPlugin.userManagementPlugins.pipes.roleCode,
          description:
            translationPlugin.userManagementPlugins.pipes.roleCodeDescription,
        },
        {
          selector: 'role',
          name: translationPlugin.userManagementPlugins.pipes.role,
          description:
            translationPlugin.userManagementPlugins.pipes.roleDescription,
        },
        {
          selector: 'userByCode',
          name: translationPlugin.userManagementPlugins.pipes.userByCode,
          description:
            translationPlugin.userManagementPlugins.pipes.userByCodeDescription,
        },
        {
          selector: 'userGroupInfoCode',
          name: translationPlugin.userManagementPlugins.pipes.userGroupInfoCode,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userGroupInfoCodeDescription,
        },
        {
          selector: 'userGroupInfo',
          name: translationPlugin.userManagementPlugins.pipes.userGroupInfo,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userGroupInfoDescription,
        },
        {
          selector: 'userGroupTypeCode',
          name: translationPlugin.userManagementPlugins.pipes.userGroupTypeCode,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userGroupTypeCodeDescription,
        },
        {
          selector: 'userGroupType',
          name: translationPlugin.userManagementPlugins.pipes.userGroupType,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userGroupTypeDescription,
        },
        {
          selector: 'userGroupsInfoCode',
          name: translationPlugin.userManagementPlugins.pipes
            .userGroupsInfoCode,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userGroupsInfoCodeDescription,
        },
        {
          selector: 'userGroupsInfo',
          name: translationPlugin.userManagementPlugins.pipes.userGroupsInfo,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userGroupsInfoDescription,
        },
        {
          selector: 'userImageComponent',
          name: translationPlugin.userManagementPlugins.pipes
            .userImageComponent,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userImageComponentDescription,
        },
        {
          selector: 'userImage',
          name: translationPlugin.userManagementPlugins.pipes.userImage,
          description:
            translationPlugin.userManagementPlugins.pipes.userImageDescription,
        },
        {
          selector: 'userParameter',
          name: translationPlugin.userManagementPlugins.pipes.userParameter,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userParameterDescription,
        },
        {
          selector: 'userTypeIcon',
          name: translationPlugin.userManagementPlugins.pipes.userTypeIcon,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userTypeIconDescription,
        },
        {
          selector: 'userType',
          name: translationPlugin.userManagementPlugins.pipes.userType,
          description:
            translationPlugin.userManagementPlugins.pipes.userTypeDescription,
        },
        {
          selector: 'userUserGroupRole',
          name: translationPlugin.userManagementPlugins.pipes.userUserGroupRole,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userUserGroupRoleDescription,
        },
        {
          selector: 'userUserGroup',
          name: translationPlugin.userManagementPlugins.pipes.userUserGroup,
          description:
            translationPlugin.userManagementPlugins.pipes
              .userUserGroupDescription,
        },
        {
          selector: 'user',
          name: translationPlugin.userManagementPlugins.pipes.user,
          description:
            translationPlugin.userManagementPlugins.pipes.userDescription,
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'User',
      url: (entityId) => {
        return of('/user-management/user-detail/' + entityId);
      },
    },
    {
      entityType: 'UserType',
      url: (entityId) => {
        return of('/user-management/user-type/' + entityId);
      },
    },
    {
      entityType: 'UserGroup',
      url: (entityId) => {
        return of('/user-management/user-group/' + entityId);
      },
    },
    {
      entityType: 'UserGroupType',
      url: (entityId) => {
        return of('/user-management/user-group-type/' + entityId);
      },
    },
    {
      entityType: 'Role',
      url: (entityId) => {
        return of('/user-management/role/' + entityId);
      },
    },
    {
      entityType: 'Priv',
      url: (entityId) => {
        return of('/user-management/priv');
      },
    },
    {
      entityType: 'AccessRule',
      url: (entityId) => {
        return of('/user-management/access-rule');
      },
    },
  ],
  layoutControls: [controlsUserGroupConfiguration],
};
