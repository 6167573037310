<tsm-dialog
  [header]="
    menuItem
      ? (translation.menuDesigner.editMenuItem | transloco)
      : (translation.menuDesigner.addMenuItem | transloco)
  "
  (close)="cancel.emit()"
>
  <tsm-form [formGroup]="form">
    <dtl-form-group>
      <tsm-form-field
        *ngIf="!isBookmark"
        label="{{ translation.menuDesigner.newUserRegister | transloco }}"
        [cols]="12"
      >
        <ng-template>
          <dtl-dynamic-form-field
            [formControl]="registerValueControl"
            [selector]="'tsm-register-lov'"
            [inputs]="{selectProperty: 'all'}"
          >
          </dtl-dynamic-form-field>
        </ng-template>
      </tsm-form-field>

      <tsm-form-field
        *ngIf="!isBookmark"
        label="{{ translationShared.shared.code | transloco }}"
        formControlName="key"
        [cols]="6"
        [required]="true"
      >
      </tsm-form-field>

      <div class="col-6 md:col-6 field relative flex flex-wrap">
        <div class="p-0 flex col-10">
          <tsm-form-field
            label="{{ translationShared.shared.title | transloco }} {{
              menuItem?.translateLabel
                ? '(' + menuItem?.translateLabel + ')'
                : ''
            }}"
            formControlName="label"
            [required]="true"
            [cols]="12"
          >
          </tsm-form-field>
        </div>
        <div class="p-0 flex col-2 flex-column justify-content-evenly">
          <button
            pButton
            pRipple
            class="p-button-text p-button-rounded ml-1"
            icon="pi pi-language"
            (click)="toggleLabelLang()"
          ></button>
        </div>
        <div
          *ngIf="showLabelLang"
          class="flex flex-column w-full col-12 shadow-1 border-1 border-300 border-round relative z-1 mx-2 mt-3 mb-1 p-3 arrow arrow--top"
        >
          <ng-container *ngFor="let lang of langs">
            <tsm-form-field
              [formControl]="
                form.get('localizationData').get(lang).get('label')
              "
              label="{{ translationShared.shared.title | transloco }} [{{
                lang
              }}]"
              helpMessage="{{ translationShared.shared.title | transloco }}"
              type="text"
            >
            </tsm-form-field>
          </ng-container>
        </div>
      </div>

      <div class="col-6 md:col-6 field relative">
        <tsm-form-field
          label="{{ translationShared.shared.icon | transloco }}"
          formControlName="icon"
        >
        </tsm-form-field>
        <div
          class="absolute"
          style="right: 0.75rem; bottom: 50%; transform: translateY(50%)"
        >
          <tsm-icon-picker
            (onIconClick)="onSelectedIcon($event)"
            label="empty"
            class="p-button-plain p-button-rounded p-button-text"
          ></tsm-icon-picker>
        </div>
      </div>

      <!--      <tsm-form-field *ngIf="!isBookmark" label="{{translation.menuDesigner.listingType | transloco}}"-->
      <!--                      formControlName="listingType" [cols]="6">-->
      <!--      </tsm-form-field>-->

      <tsm-form-field
        *ngIf="!isBookmark"
        label="{{ translation.menuDesigner.routerLink | transloco }}"
        formControlName="routerLink"
        [cols]="6"
        [readonly]="menuItem?.items?.length > 0"
      >
      </tsm-form-field>

      <tsm-form-field
        *ngIf="!isBookmark"
        label="{{ translation.menuDesigner.url | transloco }}"
        formControlName="url"
        [cols]="6"
        [readonly]="menuItem?.items?.length > 0"
      >
      </tsm-form-field>

      <tsm-form-field
        *ngIf="!isBookmark"
        label="{{ translation.menuDesigner.queryParams | transloco }}"
        formControlName="queryParams"
        [cols]="12"
        type="json-object"
        [readonly]="menuItem?.items?.length > 0"
      >
      </tsm-form-field>
    </dtl-form-group>

    <dtl-grid *ngIf="!isBookmark">
      <dtl-col [cols]="12">
        <div class="flex gap-2 align-items-center pt-3 pl-2">
          <span class="font-bold text-xl">{{
            translationShared.shared.privilege | transloco
          }}</span>
          <button
            pButton
            pRipple
            type="button"
            tsmTooltip="{{ translationShared.shared.create | transloco }}"
            (click)="addPrivilege(null)"
            class="p-button-success p-button-outlined p-button-rounded"
            icon="pi pi-plus"
          ></button>
        </div>
      </dtl-col>
      <dtl-col [cols]="12">
        <div
          *ngFor="
            let control of $any(form.get('privilege'))?.controls;
            let index = index
          "
          class="flex gap-2 align-items-center my-2 pl-1"
        >
          <dtl-dynamic-form-field
            [formControl]="control"
            style="width: 100%"
            [selector]="'tsm-user-privilege-lov'"
          >
          </dtl-dynamic-form-field>
          <div class="flex-0 ml-auto">
            <button
              pButton
              pRipple
              class="p-button-danger p-button-text p-button-rounded p-button-sm"
              icon="pi pi-times"
              (click)="deletePrivilege(index)"
            ></button>
          </div>
        </div>
      </dtl-col>
    </dtl-grid>
  </tsm-form>

  <p-footer>
    <tsm-button-success (click)="onSave()"></tsm-button-success>
    <tsm-button-close (click)="cancel.emit()"></tsm-button-close>
  </p-footer>
</tsm-dialog>
