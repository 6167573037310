import {SelectItem} from 'primeng/api';

export enum NotificationTemplateCategory {
  CAT_IMPORTANT = 'CAT_IMPORTANT',
  CAT_LOW_PRIORITY = 'CAT_LOW_PRIORITY',
}

export function getAllNotificationTemplateCategories(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(NotificationTemplateCategory).forEach((val) =>
    items.push({
      label: NotificationTemplateCategory[val],
      value: val,
    }),
  );
  return items;
}
