export const en = {
  stockPlugins: {
    material: 'Material',
    stockItemViews: 'Items',
    stockSharedResponsibilities: 'Shared responsibilities',
    filters: {
      tsmStockFilter: 'tSM Stock Filter',
      tsmStockFilterDescription:
        'Implements a stock filter component with multi-selection support. ' +
        'Extends a table filter listing parent component, allowing users to filter ' +
        'stocks retrieved from an API. Provides options for selection, removal, ' +
        'and confirmation, using an overlay panel for interaction.',
      tsmStockItemFilter: 'tSM Stock Item Filter',
      tsmStockItemFilterDescription:
        'Implements a stock item filter component with multi-selection support. ' +
        'Extends a table filter parent component, allowing users to filter ' +
        'stock items retrieved from an API. Provides options for selection, ' +
        'removal, and confirmation, using an overlay panel for interaction. ' +
        'Includes filtering based on entity catalog specifications.',
      tsmStockItemStatusFilter: 'tSM Stock Item Status Filter',
      tsmStockItemStatusFilterDescription:
        'Implements a stock item status filter component with multi-selection support. ' +
        'Extends a table filter parent component, allowing users to filter ' +
        'stock item statuses retrieved from an API. Provides options for selection, ' +
        'removal, and confirmation, using an overlay panel for interaction.',
      tsmStockItemTypeFilter: 'tSM Stock Item Type Filter',
      tsmStockItemTypeFilterDescription:
        'Implements a stock item type filter component with multi-selection support. ' +
        'Extends a table filter parent component, allowing users to filter ' +
        'stock item types retrieved from an API. Provides options for selection, ' +
        'removal, and confirmation, using an overlay panel for interaction.',
      tsmStockMovementStatusFilter: 'tSM Stock Movement Status Filter',
      tsmStockMovementStatusFilterDescription:
        'Implements a stock movement status filter component with multi-selection. ' +
        'Extends a table filter parent component, allowing users to filter stock ' +
        'movement statuses retrieved from a predefined list. Provides selection, ' +
        'removal, and confirmation options via an overlay panel.',
      tsmStockMovementTypeFilter: 'tSM Stock Movement Type Filter',
      tsmStockMovementTypeFilterDescription:
        'Implements a stock movement type filter with multi-selection support. ' +
        'Extends a table filter parent component, allowing users to filter ' +
        'stock movement types retrieved from a paginated list. Provides ' +
        'search capabilities, selection, removal, and confirmation options ' +
        'via an overlay panel.',
      tsmStockStatusFilter: 'tSM Stock Status Filter',
      tsmStockStatusFilterDescription:
        'Implements a stock status filter with multi-selection support. ' +
        'Extends a table filter parent component, allowing users to filter ' +
        'stock statuses retrieved dynamically. Displays available statuses ' +
        'in a table with search and selection capabilities. Provides options ' +
        'to confirm or cancel selections via an overlay panel.',
      tsmStockTypeFilter: 'tSM Stock Type Filter',
      tsmStockTypeFilterDescription:
        'Implements a stock type filter with multi-selection support. ' +
        'Extends a table filter parent component, allowing users to filter ' +
        'stock types dynamically retrieved from an API. Displays available ' +
        'types in a table with search functionality. Provides options to ' +
        'confirm or cancel selections via an overlay panel.',
    },
    pipes: {
      stockItemKey: 'Stock Item Key',
      stockItemKeyDescription:
        'Retrieves stock item details using a unique key and returns a specific field or full data. ' +
        'If the item is not found in the store, dispatches a request to load it.',
      stockItemMovementPipe: 'Stock Item Movement Pipe',
      stockItemMovementPipeDescription:
        'Determines the stock item movement direction by selecting either the source or destination. ' +
        'If neither is present, returns the original value.',
      stockItemSn: 'Stock Item Sn',
      stockItemSnDescription:
        'Fetches stock item details based on serial number. ' +
        'If available, returns the specified field; otherwise, returns the ID.',
      stockItemTypeCode: 'Stock Item Type Code',
      stockItemTypeCodeDescription:
        'Retrieves stock item type details by code. ' +
        'Returns the specified field or full data if "all" is provided.',
      stockItemType: 'Stock Item Type',
      stockItemTypeDescription:
        'Retrieves stock item type details by ID. ' +
        'Returns the specified field or full data if "all" is provided.',
      stockItem: 'Stock Item',
      stockItemDescription:
        'Fetches stock item details by ID. ' +
        'Returns the specified field or full data if "all" is provided.',
      stockKey: 'Stock Key',
      stockKeyDescription:
        'Retrieves stock details by key. ' +
        'Returns the specified field or full data if "all" is provided.',
      stockMovementStatus: 'Stock Movement Status',
      stockMovementStatusDescription:
        'Formats stock movement status as a styled label. ' +
        'Translates the status based on the provided value.',
      stockMovementTypeCode: 'Stock Movement Type Code',
      stockMovementTypeCodeDescription:
        'Loads stock movement type by code and returns the requested field. ' +
        'Defaults to returning the name unless specified otherwise.',
      stockMovementType: 'Stock Movement Type',
      stockMovementTypeDescription:
        'Loads stock movement type by ID and returns the requested field. ' +
        'Defaults to returning the name unless specified otherwise.',
      stockMovementPipe: 'Stock Movement Pipe',
      stockMovementPipeDescription:
        'Retrieves stock movement details by ID and returns the specified field. ' +
        'Defaults to returning the name unless another field is provided.',
      stockTaskLoader: 'Stock Task Loader',
      stockTaskLoaderDescription:
        'Loads stock task details by task ID and returns the specified field. ' +
        'Defaults to returning the task definition key if no field is provided.',
      stockTypeCode: 'Stock Type Code',
      stockTypeCodeDescription:
        'Fetches stock type details using a stock type code. Returns a specified ' +
        'field or the full data object when no field is provided.',
      stockType: 'Stock Type',
      stockTypeDescription:
        'Retrieves stock type details by ID. Returns a specified field or the full ' +
        'data object when no field is specified.',
      stock: 'Stock',
      stockDescription:
        'Fetches stock details using an ID. Returns a specific field or the stock ' +
        'name if no field is specified.',
    },
  },
};
