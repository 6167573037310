import {NgModule} from '@angular/core';
import {IMPORTS, PIPES, PROVIDERS} from './index';

@NgModule({
  imports: [...IMPORTS],
  declarations: [...PIPES],
  exports: [...PIPES],
  providers: [...PROVIDERS, ...PIPES],
})
export class AccessRulesServiceModule {}
