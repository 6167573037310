import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteUserGroupSkillSuccess,
  LoadUserGroupSkillsBySkillId,
  LoadUserGroupSkillsBySkillIdError,
  LoadUserGroupSkillsBySkillIdSuccess,
  LoadUserGroupSkillsByUserGroupId,
  LoadUserGroupSkillsByUserGroupIdError,
  LoadUserGroupSkillsByUserGroupIdSuccess,
  LoadUserGroupSkillsByUserId,
  LoadUserGroupSkillsByUserIdError,
  LoadUserGroupSkillsByUserIdSuccess,
  UpsertMoreUserGroupSkill,
  UpsertMoreUserGroupSkillError,
  UpsertMoreUserGroupSkillSuccess,
  UpsertUserGroupSkill,
  UpsertUserGroupSkillError,
  UpsertUserGroupSkills,
  UpsertUserGroupSkillsError,
  UpsertUserGroupSkillsSuccess,
  UpsertUserGroupSkillSuccess,
} from '../actions';
import {UserGroupSkill} from '../model';

export type UserGroupSkillState = EntityState<IdentifiedPack<UserGroupSkill>>;

export const adapter: EntityAdapter<IdentifiedPack<UserGroupSkill>> =
  createEntityAdapter<IdentifiedPack<UserGroupSkill>>({});

export const initialState: UserGroupSkillState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(
    LoadUserGroupSkillsByUserIdSuccess,
    LoadUserGroupSkillsByUserGroupIdSuccess,
    LoadUserGroupSkillsBySkillIdSuccess,
    UpsertMoreUserGroupSkillSuccess,
    UpsertUserGroupSkillsSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((skillAssignment) => ({
          id: skillAssignment.id,
          data: skillAssignment,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadUserGroupSkillsByUserId,
    LoadUserGroupSkillsByUserGroupId,
    LoadUserGroupSkillsBySkillId,
    UpsertUserGroupSkill,
    UpsertUserGroupSkills,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadUserGroupSkillsByUserIdError,
    LoadUserGroupSkillsByUserGroupIdError,
    LoadUserGroupSkillsBySkillIdError,
    UpsertMoreUserGroupSkillError,
    UpsertUserGroupSkillError,
    UpsertUserGroupSkillsError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertUserGroupSkillSuccess, (state, {userGroupSkill}) =>
    adapter.upsertOne(
      {
        id: userGroupSkill.id,
        data: userGroupSkill,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteUserGroupSkillSuccess, (state, skillAssignment) =>
    adapter.removeOne(skillAssignment.id, state),
  ),

  on(UpsertMoreUserGroupSkill, (state) => adapter.removeAll(state)),
);
