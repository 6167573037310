// manual imports
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation} from '../i18n';

// IDE imports
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, mergeMap} from 'rxjs/operators';
import {
  DeleteMyWorklog,
  DeleteMyWorklogError,
  DeleteMyWorklogSuccess,
  LoadMyWorklogByFilter,
  LoadMyWorklogByFilterError,
  LoadMyWorklogByFilterSuccess,
  UpsertMyWorklog,
  UpsertMyWorklogError,
  UpsertMyWorklogSuccess,
} from '../actions';
import {WorklogDataService} from '../services';
import {Worklog} from '../models';

@Injectable()
export class WorklogMyEffects {
  translation = translation;
  translationShared = translationShared;

  // loadData$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoadAllMyWorklog),
  //     mergeMap((action) => {
  //       return this.worklogDataService.getWorklogMyByUserId(action.userId)
  //         .pipe(
  //           map(userWorklogs =>
  //             userWorklogs.success ? LoadAllMyWorklogSuccess({
  //               userId: action.userId,
  //               worklogs: userWorklogs.data
  //             }) : LoadAllMyWorklogError({
  //               userId: action.userId,
  //               error: userWorklogs.error
  //             })
  //           )
  //         );
  //     })
  //   )
  // );

  loadDataByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMyWorklogByFilter),
      mergeMap((action) => {
        return this.worklogDataService
          .getWorklogByFilters(
            action.userId,
            action.page,
            action.size,
            action.filters,
          )
          .pipe(
            map((userWorklogs) =>
              userWorklogs.success
                ? LoadMyWorklogByFilterSuccess({
                    userId: action.userId,
                    worklogs: userWorklogs.data.content,
                  })
                : LoadMyWorklogByFilterError({
                    userId: action.userId,
                    error: userWorklogs.error,
                  }),
            ),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMyWorklog),
      concatMap(({worklog, userId}) =>
        this.worklogDataService
          .saveWorklog(worklog.ownerId, worklog.ownerType, worklog)
          .pipe(
            map((env) =>
              env.success
                ? UpsertMyWorklogSuccess({worklog: env.data, userId: userId})
                : UpsertMyWorklogError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMyWorklog),
      concatMap(({id, userId}) =>
        this.worklogDataService
          .deleteWorklogById(id)
          .pipe(
            map((env) =>
              env.success
                ? DeleteMyWorklogSuccess({id: id, userId})
                : DeleteMyWorklogError({id: id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  // upsertSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       UpsertMyWorklogSuccess,
  //     ),
  //     debounceTime(300),
  //     map((action) =>
  //       LoadWorklogCount({
  //       userId: action.userId,
  //       deliveryType: action.Worklog.deliveryType,
  //       status: WorklogStatus.PLAN
  //     }))
  //   ));

  constructor(
    private actions$: Actions,
    private worklogDataService: WorklogDataService,
  ) {}
}
