export const en = {
  loggingPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmLogTypeFilter: 'tSM Log Type Filter',
      tsmLogTypeFilterDescription:
        'Defines a log type filter component that extends a parent filter listing component. ' +
        'Provides a structured UI for selecting multiple log types using a data view. ' +
        'Supports filtering, sorting, and displaying log type options retrieved from ' +
        'an external API. Implements overlay panel behavior with responsive support for ' +
        'mobile devices and customizable target elements. On selection, updates the filter ' +
        'values and emits the changes.',
    },
  },
};
