import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import {translation, translation as translationShared} from '@tsm/shared-i18n';

@Component({
  selector: 'tsm-automatic-create-field',
  templateUrl: './tsm-automatic-create-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomaticCreateFieldContainerComponent {
  translation = translation;
  translationShared = translationShared;

  @Input()
  showField = false;

  @Input()
  fieldName: string;

  @Input()
  fieldNameLink: string;

  @Input()
  fieldDescription: string;

  @Input()
  isPrefix = false;

  @Output()
  toggleFieldChange = new EventEmitter<boolean>();

  @Input()
  requiredMark = false;

  constructor() {}

  toggleField() {
    this.showField = !this.showField;
    this.toggleFieldChange.emit(this.showField);
  }
}
