import {SelectItem} from 'primeng/api/selectitem';

export enum FormatterOutputType {
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
}

export function getAllFormatterOutputTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(FormatterOutputType).forEach((val) =>
    items.push({label: FormatterOutputType[val], value: val}),
  );
  return items;
}
