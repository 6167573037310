<div
  #container
  tsmResizeObserver
  (resize)="onObserverResized($event)"
  class="editor-container"
  mwlResizable
  (resizing)="onMwlResizing($event)"
>
  <div class="wrapper">
    <div
      #editor
      class="monaco-editor"
      [style.width.px]="container.offsetWidth"
      [style.height.px]="container.offsetHeight"
      style="min-width: 0"
    ></div>
  </div>
  <div
    *ngIf="resizable"
    class="resize-handle"
    [style.height.px]="resizeHandleHeightPx"
    mwlResizeHandle
    [resizeEdges]="{bottom: true}"
  >
    <i class="pi pi-ellipsis-h"></i>
  </div>
</div>
