import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Optional,
  Output,
  Type,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {ConfigService} from '@tsm/framework/config';
import {Terminator} from '@tsm/framework/terminator';
import {DomSanitizer} from '@angular/platform-browser';

import {defer, Observable} from 'rxjs';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {DynamicComponentModule} from '@tsm/framework/dynamic-component';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'dtl-form-input-tiptap',
  templateUrl: './form-input-tiptap.component.html',
  styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
  styles: [
    `
      :host ::ng-deep img {
        width: auto !important;
        max-height: 100% !important;
      }
    `,
  ],
  host: {
    '[class.p-state-filled-fix]': '!isEmpty || !readonly',
    '[class.is-empty]': 'isEmpty',
  },
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputTiptapComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputTiptapComponent),
      multi: true,
    },
    {provide: Terminator},
    ...useParentWidgetProvidersFor(FormInputTiptapComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DynamicComponentModule, AsyncPipe],
})
export class FormInputTiptapComponent extends ParentWidgetAccessorComponent {
  @Input() disableTableStyle = false;

  @Input() hashtagOption: 'tickets' | 'script' = 'tickets';

  @Input() suggestionScriptCode: string;

  @Input() suggestionScriptData: any;

  @Input() clickScriptCode: string;

  @Input() clickScriptData: any;

  @Input() moreSelector: string;

  @Input() moreInputs: any = {};

  @Input() hideMenuItemIframe = false;

  @Input() hideMenuItemDiagram = false;

  @Input() hideMenuItemImage = false;

  @Input() hideMenuItemAttachments = false;

  @Input() hideMenuItemInsertCode = false;

  @Input() hideMenuItemSourceCode = false;

  @Input() pastePlainImages = false;

  @Input() ownerId: string;

  @Input() ownerType: string;

  @Input() menuType: 'large' | 'light' | 'small' = 'small';

  @Input() customButtonText: string;

  @Input() customButtonIcon = 'tsm-icon-management-1';

  @Output() hashtagAttachmentIds = new EventEmitter<string[]>();

  @Output() hashtagSelectionChanged = new EventEmitter<{
    value: any;
    index: number;
  }>();

  tipTapType$: Observable<Type<unknown>> = defer(() =>
    import('@tsm/framework/tiptap-editor').then((x) => x.TiptapEditorComponent),
  );

  constructor(
    public configServices: ConfigService,
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public sanitized: DomSanitizer,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  get isEmpty(): boolean {
    return this.val == null || this.val == '' || this.val == '<p></p>';
  }

  setValue(value: string) {
    this.val = value;
  }

  hashtagAttachmentIdsChanges(attachmentIds: string[]) {
    this.hashtagAttachmentIds.emit(attachmentIds);
  }

  hashtagSelectionChangedFce(event) {
    this.hashtagSelectionChanged.emit(event);
  }
}
