export const cs = {
  dataSource: {
    evalScriptById: 'Vyhodnotit skript podle ID',
    evalScriptByCode: 'Vyhodnotit skript podle kódu',
    scriptIdName: 'ID skriptu',
    scriptDataName: 'Data skriptu',
    scriptDisableCache: 'Vypnout cache vykonání skriptu',
    scriptIdDescription: 'ID skriptu',
    scriptDataDescription: 'Data skriptu',
    scriptDisableCacheDescription: 'Vypnout cache vykonání skriptu',
    scriptCodeName: 'Kód skriptu',
    scriptCodeDescription: 'Kód skriptu',
    scriptDisableToast: 'Vypnout chybovou hlášku',
    scriptDisableToastDescription: 'Vypnout chybovou hlášku v případě erroru',
  },
  console: {
    run: 'SPUSTIT',
    stop: 'ZASTAVIT',
    dryRun: '--dry-run', //'SPUSTIT NA SUCHO',
    dryRunTooltip:
      'Zkouška nanečisto. Berte prosím v potaz, že ne všechny příkazy jsou podporovány (zejména REST volání).',
    translate: 'PŘELOŽIT',
    initInsertionQuery: '/* Prosím, sem vložte váš dotaz */',
    commandHistory: 'Historie příkazů',
    languageSelection: 'Výběr jazyka,',
    closeDialogAfterSelection: 'Zavřít dialog ihned po výběru položky',
    commandCopied: 'Příkaz zkopírování',
    command: 'Příkaz',
    copyCurrentContextIntoConsole: 'Kopírovat aktuální kontext do konzole',
  },
  script: {
    closeScriptOverview: 'Zavřít přehled skriptů',
    scriptOverview: 'Přehled skriptů',
    scriptType: 'Typ skriptu',
    scriptTypeTql: 'Skript typu TQL',
    scriptTypeAdmin: 'Skript typu Admin',
    scriptLanguage: 'Jazyk skriptu',
    createScript: 'Vytvořit nový skript',
    editScript: 'Upravit vybraný skript',
    deleteScript: 'Vymazat vybraný skript',
    upsertSuccess: 'Skript byl úspěšně uložen',
    deleteSuccess: 'Skript byl úspěšně smazán',
    patchSuccess: 'Skript byl úspěšně upraven',
    currentConsoleDataCopy: 'Zkopírovat aktuální data z konzole', // Zkopírovat obsah konzole
    currentConsoleDataCopySuccess:
      'Aktuální data konzole byla úspěšně zkopírována',
    currentConsoleDataCopyError: 'Konzole je prázdná',
    selectedScriptQuery: 'Kód vybraného skriptu',
    copySelectedScriptToClipboard: 'Kopírovat vybraný skript do schránky',
    pasteSelectedScriptToConsole: 'Vložit vybraný skript do konzole',
    diffSuccess: 'Ukládání rozdílů skriptu skončilo úspěšně.',
    diffError: 'Při ukládání rozdílů skriptu došlo k chybě.',
  },
};
