import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {backupSetAdapter, ConfigFormState} from '../reducers';

const getBackupSetState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
const pathToEntities = createSelector(getBackupSetState, (x) => x.backupSet);

const {selectAll, selectEntities} =
  backupSetAdapter.getSelectors(pathToEntities);

export const allBackupSets = selectAll;
export const backupSetEntities = selectEntities;

export const selectBackupSetById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);
export const selectBackupSetByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);
