import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteValueConverterConfigSuccess,
  DiffValueConverterConfig,
  DiffValueConverterConfigError,
  DiffValueConverterConfigSuccess,
  InsertValueConverterConfig,
  InsertValueConverterConfigError,
  InsertValueConverterConfigSuccess,
  LoadValueConverterConfigById,
  LoadValueConverterConfigByIdError,
  LoadValueConverterConfigByIdSuccess,
  LoadValueConverterConfigSuccess,
  UpdateValueConverterConfig,
  UpdateValueConverterConfigError,
  UpdateValueConverterConfigSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';
import {ValueConverterConfig} from '../models';

export type ValueConverterConfigState = EntityState<
  IdentifiedPack<ValueConverterConfig>
>;

export const valueConverterConfigAdapter: EntityAdapter<
  IdentifiedPack<ValueConverterConfig>
> = createEntityAdapter<IdentifiedPack<ValueConverterConfig>>({});

const initialState: ValueConverterConfigState =
  valueConverterConfigAdapter.getInitialState({});

export const valueConverterConfigReducer = createReducer(
  initialState,

  on(LoadValueConverterConfigSuccess, (state, {valueConverterConfigs}) => ({
    ...valueConverterConfigAdapter.upsertMany(
      valueConverterConfigs.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadValueConverterConfigById, (state, {id}) =>
    valueConverterConfigAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadValueConverterConfigByIdSuccess, (state, {valueConverterConfig}) =>
    valueConverterConfigAdapter.upsertOne(
      {
        id: valueConverterConfig.id,
        data: valueConverterConfig,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(
    UpdateValueConverterConfig,
    InsertValueConverterConfig,
    (state, {valueConverterConfig}) =>
      valueConverterConfigAdapter.updateOne(
        {
          id: valueConverterConfig.id,
          changes: {
            ...state.entities[valueConverterConfig.id],
            loading: false,
          },
        },
        state,
      ),
  ),

  on(
    UpdateValueConverterConfigSuccess,
    InsertValueConverterConfigSuccess,
    (state, {valueConverterConfig}) =>
      valueConverterConfigAdapter.upsertOne(
        {
          id: valueConverterConfig.id,
          data: valueConverterConfig,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(
    LoadValueConverterConfigByIdError,
    UpdateValueConverterConfigError,
    InsertValueConverterConfigError,
    (state, {id, error}) =>
      valueConverterConfigAdapter.upsertOne(
        {
          id: id,
          loading: false,
          error: error,
        },
        state,
      ),
  ),

  on(DeleteValueConverterConfigSuccess, (state, listingType) =>
    valueConverterConfigAdapter.removeOne(listingType.id, state),
  ),

  on(DiffValueConverterConfig, (state, {diffEntities}) =>
    valueConverterConfigAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffValueConverterConfigSuccess, (state, {valueConverterConfigs}) =>
    valueConverterConfigAdapter.upsertMany(
      valueConverterConfigs.map((valueConverterConfig) => {
        return {
          id: valueConverterConfig.id,
          data: valueConverterConfig,
          loading: false,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffValueConverterConfigError, (state, {diffEntities, error}) =>
    valueConverterConfigAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
