import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BlockUIModule} from 'primeng/blockui';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DragDropModule} from '@tsm/framework/drag-drop';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MessageModule} from 'primeng/message';
import {PasswordModule} from 'primeng/password';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SharedModule} from 'primeng/api';
import {
  AutoSizeTooltipDirectiveModule,
  IframeResizeModule,
  LetDirectiveModule,
  PageTitleDirectiveModule,
} from '@tsm/framework/root/directives';
import {FormModule} from '@tsm/framework/form-inputs';
import {IconPickerModule} from '@tsm/framework/root/icon-picker';
import {ContainerWrapperModule} from '@tsm/framework/root/container-wrapper';
import {AutocompleteTreeModule} from '@tsm/framework/root/autocomplete-tree';
import {AccordionModule} from '@tsm/framework/override-primeng/accordion';
import {AutoCompleteModule} from '@tsm/framework/override-primeng/autocomplete';
import {OverlayPanelModule} from '@tsm/framework/override-primeng/overlay-panel';
import {TooltipModule} from 'primeng/tooltip';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ToastModule} from 'primeng/toast';

import * as Pipes from './pipes';
import {
  AutomaticCreateFieldContainerComponent,
  ButtonCloseComponent,
  ButtonComponent,
  ButtonSuccessComponent,
  CodeEditorDialogComponent,
  DataTagsComponent,
  DialogComponent,
  DialogNextstepsComponent,
  FormComponent,
  FormFieldComponent,
  FormFieldEditorComponent,
  FormInplaceComponent,
  FormInplaceFieldComponent,
  HideOverflowComponent,
  KibanaLogLinkComponent,
  LocalizedFormFieldComponent,
  PreviewDropdownComponent,
  PreviewDropdownEditorComponent,
  ReadonlyInputComponent,
  SimpleColorPickerComponent,
  TsmButtonConfirmPopup,
  TsmDropdownModule,
  ValidationBadgeComponent,
  ValidationIconComponent,
} from './components';
import {
  DisableKeyboardDirective,
  DragAndScrollDirective,
  InputTextDirective,
} from './directives';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {FrameworkResizeObserverModule} from '@tsm/framework/resize-observer';
import {localizations} from '@tsm/shared-i18n';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TranslateModule} from '@tsm/framework/translate';
import {RatingModule} from 'primeng/rating';
import {HotkeyDirectiveModule} from '@tsm/framework/shortcuts';
import {DtlMonacoEditorModule} from '@tsm/framework/monaco-editor';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DividerModule} from 'primeng/divider';
import {UserParametersModule} from '@tsm/user-parameters';
import {RuntimeInfoModule} from '@tsm/runtime-info';
import {RippleModule} from 'primeng/ripple';
import {TsmFormControlDirectiveModule} from '@tsm/framework/forms';
import {DynamicComponentModule} from '@tsm/framework/dynamic-component';
import {InputGroupAddonModule} from 'primeng/inputgroupaddon';
import {InputGroupModule} from 'primeng/inputgroup';
import {PreviewButtonComponent} from './components/form/preview-button/preview-button.component';
import {RxLet} from '@rx-angular/template/let';
import {RxFor} from '@rx-angular/template/for';
import {BadgeModule} from 'primeng/badge';

export const PIPES = [
  Pipes.BooleanIconPipe,
  Pipes.BooleanTranslatePipe,
  Pipes.ClipboardPipe,
  Pipes.ColorPipe,
  Pipes.ConstructCharsForNewPipe,
  Pipes.DataTagsArrayPipe,
  Pipes.DateFormatPipe,
  Pipes.DatesDiffPipe,
  Pipes.DecimalNumberFormatPipe,
  Pipes.DescriptionPipe,
  Pipes.DomPurifyPipe,
  Pipes.EmptyOrNotEmptyTranslatePipe,
  Pipes.EnumToValuePipe,
  Pipes.ExternalLinkPipe,
  Pipes.FilterPipe,
  Pipes.FindPipe,
  Pipes.FlattenExceptKeysPipe,
  Pipes.IconImgPipe,
  Pipes.JoinPipe,
  Pipes.MapPipe,
  Pipes.ModuleIconPipe,
  Pipes.NumberToFixedPipe,
  Pipes.ObjectPathGetPipe,
  Pipes.RemoveHtmlElementsPipe,
  Pipes.SafeEvalPipe,
  Pipes.SafeHtmlPipe,
  Pipes.SafePipe,
  Pipes.SanitizeNullPipe,
  Pipes.ShallowMergePipe,
  Pipes.TranslatePipe,
  Pipes.TrimTextPipe,
];

export const COMPONENTS = [
  ButtonSuccessComponent,
  ButtonCloseComponent,
  ButtonComponent,
  FormComponent,
  FormInplaceComponent,
  FormInplaceFieldComponent,
  FormFieldComponent,
  FormFieldEditorComponent,
  PreviewDropdownComponent,
  PreviewButtonComponent,
  PreviewDropdownEditorComponent,
  DragAndScrollDirective,
  DisableKeyboardDirective,
  DialogComponent,
  ReadonlyInputComponent,
  InputTextDirective,
  CodeEditorDialogComponent,
  SimpleColorPickerComponent,
  KibanaLogLinkComponent,
  DataTagsComponent,
  ValidationBadgeComponent,
  ValidationIconComponent,
  HideOverflowComponent,
  TsmButtonConfirmPopup,
  DialogNextstepsComponent,
  AutomaticCreateFieldContainerComponent,
  LocalizedFormFieldComponent,
];
export const IMPORTS = [
  TranslateModule.forFeature(localizations),
  DragDropModule,
  OverlayPanelModule,
  ContainerWrapperModule,
  SelectButtonModule,
  TooltipModule,
  MessageModule,
  ScrollPanelModule,
  InputTextModule,
  PasswordModule,
  InputTextareaModule,
  BlockUIModule,
  ButtonModule,
  SharedModule,
  FormsModule,
  ReactiveFormsModule.withConfig({
    callSetDisabledState: 'whenDisabledForLegacyCode',
  }),
  CommonModule,
  RouterModule,
  CardModule,
  CalendarModule,
  CheckboxModule,
  DialogModule,
  TsmDropdownModule,
  FormModule,
  KeyFilterModule,
  LetDirectiveModule,
  FrameworkGridModule,
  RatingModule,
  HotkeyDirectiveModule,
  DtlMonacoEditorModule,
  FrameworkResizeObserverModule,
  ConfirmPopupModule,
  ToastModule,
  AutocompleteTreeModule,
  IframeResizeModule,
  ClipboardModule,
  DropdownModule,
  AutoCompleteModule,
  InputSwitchModule,
  DividerModule,
  UserParametersModule,
  RuntimeInfoModule,
  RippleModule,
  AutoSizeTooltipDirectiveModule,
  // tsm form control module
  TsmFormControlDirectiveModule,
  AccordionModule,
  DynamicComponentModule,
  PageTitleDirectiveModule,
  InputGroupAddonModule,
  InputGroupModule,
  IconPickerModule,
  RxLet,
  RxFor,
  BadgeModule,
];

export const EXPORTS = [
  TooltipModule,
  MessageModule,
  CommonModule,
  InputTextModule,
  InputTextareaModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ScrollPanelModule,
  SharedModule,
  BlockUIModule,
  TsmDropdownModule,
  AutoSizeTooltipDirectiveModule,
  // tsm form control module
  TsmFormControlDirectiveModule,
  ButtonModule,
  RippleModule,
  IconPickerModule,
  RxLet,
  RxFor,
];
