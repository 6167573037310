<tsm-monaco-editor
  [(ngModel)]="val"
  [ngStyle]="{display: readonly ? 'none' : ''}"
  [defaultHeight]="widgetHeight ? widgetHeight : inline ? '1.5em' : null"
  [options]="editorOptions"
  [inline]="inline"
  [resizable]="resizable"
  language="html"
  [updateIfDirty]="updateIfDirty"
  (touchedChange)="onTouched(true)"
  (init)="onEditorInitialized($event)"
  data-cy="dtl-form-input-html"
></tsm-monaco-editor>
<span
  [style.overflow]="!readonly ? 'hidden' : 'auto'"
  [ngStyle]="{display: !readonly ? 'none' : 'block'}"
  [ngClass]="val ? 'readonly-text' : 'readonly-text empty'"
>
  <div *ngIf="val">{{ val }}</div>
  <ng-container *ngIf="!val">none</ng-container>
</span>
