import {Pipe, PipeTransform} from '@angular/core';

/// Nepouzivej me, pokud opravdu nevis co delas
@Pipe({
  name: 'memoized',
})
export class MemoizedPipe implements PipeTransform {
  public transform(value: any, functor: (value: any) => any): any {
    return functor(value);
  }
}
