import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteAllUserGroupRegions,
  DeleteAllUserGroupRegionsError,
  DeleteAllUserGroupRegionsSuccess,
  DeleteUserGroupRegionSuccess,
  LoadUserGroupRegionsByUserGroupId,
  LoadUserGroupRegionsByUserGroupIdError,
  LoadUserGroupRegionsByUserGroupIdSuccess,
  LoadUserGroupRegionsByUserGroupRegionId,
  LoadUserGroupRegionsByUserGroupRegionIdError,
  LoadUserGroupRegionsByUserGroupRegionIdSuccess,
  SaveAllUserGroupRegions,
  SaveAllUserGroupRegionsError,
  SaveAllUserGroupRegionsSuccess,
  UpsertUserGroupRegionSuccess,
} from '../actions';
import {UserGroupRegion} from '../model';

export type UserGroupRegionState = EntityState<IdentifiedPack<UserGroupRegion>>;

export const adapter: EntityAdapter<IdentifiedPack<UserGroupRegion>> =
  createEntityAdapter<IdentifiedPack<UserGroupRegion>>({});

export const initialState: UserGroupRegionState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(
    LoadUserGroupRegionsByUserGroupIdSuccess,
    LoadUserGroupRegionsByUserGroupRegionIdSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((userGroupRegion) => ({
          id: userGroupRegion.id,
          data: userGroupRegion,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadUserGroupRegionsByUserGroupId,
    LoadUserGroupRegionsByUserGroupRegionId,
    DeleteAllUserGroupRegions,
    SaveAllUserGroupRegions,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadUserGroupRegionsByUserGroupIdError,
    LoadUserGroupRegionsByUserGroupRegionIdError,
    DeleteAllUserGroupRegionsError,
    SaveAllUserGroupRegionsError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertUserGroupRegionSuccess, (state, {userGroupRegion}) =>
    adapter.upsertOne(
      {
        id: userGroupRegion.id,
        data: userGroupRegion,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteUserGroupRegionSuccess, (state, userGroupRegion) =>
    adapter.removeOne(userGroupRegion.id, state),
  ),

  on(SaveAllUserGroupRegionsSuccess, (state, {entities}) => ({
    ...adapter.upsertMany(
      entities.map((entity) => ({
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(DeleteAllUserGroupRegionsSuccess, (state, {ids}) => ({
    ...adapter.removeMany(ids, state),
    error: null,
    loading: false,
  })),
);
