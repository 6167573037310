import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteAllRoleAccessRules,
  DeleteAllRoleAccessRulesError,
  DeleteAllRoleAccessRulesSuccess,
  DeleteRoleAccessRuleSuccess,
  LoadRoleAccessRulesByAccessRuleId,
  LoadRoleAccessRulesByAccessRuleIdError,
  LoadRoleAccessRulesByAccessRuleIdSuccess,
  LoadRoleAccessRulesByRoleId,
  LoadRoleAccessRulesByRoleIdError,
  LoadRoleAccessRulesByRoleIdSuccess,
  SaveAllRoleAccessRules,
  SaveAllRoleAccessRulesError,
  SaveAllRoleAccessRulesSuccess,
  UpsertRoleAccessRuleSuccess,
} from '../actions';
import {RoleAccessRule} from '../model';

export type RoleAccessRuleState = EntityState<IdentifiedPack<RoleAccessRule>>;

export const adapter: EntityAdapter<IdentifiedPack<RoleAccessRule>> =
  createEntityAdapter<IdentifiedPack<RoleAccessRule>>({});

export const initialState: RoleAccessRuleState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(
    LoadRoleAccessRulesByRoleIdSuccess,
    LoadRoleAccessRulesByAccessRuleIdSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((roleAccessRule) => ({
          id: roleAccessRule.id,
          data: roleAccessRule,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadRoleAccessRulesByRoleId,
    LoadRoleAccessRulesByAccessRuleId,
    SaveAllRoleAccessRules,
    DeleteAllRoleAccessRules,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadRoleAccessRulesByRoleIdError,
    LoadRoleAccessRulesByAccessRuleIdError,
    SaveAllRoleAccessRulesError,
    DeleteAllRoleAccessRulesError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertRoleAccessRuleSuccess, (state, {roleAccessRule}) =>
    adapter.upsertOne(
      {
        id: roleAccessRule.id,
        data: roleAccessRule,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteAllRoleAccessRulesSuccess, (state, {ids}) => ({
    ...adapter.removeMany(ids, state),
    error: null,
    loading: false,
  })),
  on(SaveAllRoleAccessRulesSuccess, (state, {roleAccessRules}) => ({
    ...adapter.upsertMany(
      roleAccessRules.map((rp) => ({
        id: rp.id,
        data: rp,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(DeleteRoleAccessRuleSuccess, (state, roleAccessRule) =>
    adapter.removeOne(roleAccessRule.id, state),
  ),
);
