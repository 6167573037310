import {createReducer, on} from '@ngrx/store';
import {TsmUiConfigModel} from '../models';
import {
  LoadUiConfigAction,
  LoadUiConfigActionError,
  LoadUiConfigActionSuccess,
} from '../actions';
import {Pack} from '@tsm/framework/root';

export const initialState: Pack<TsmUiConfigModel> = {
  data: undefined,
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  // ENVIRONMENT
  on(LoadUiConfigAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(LoadUiConfigActionSuccess, (state, {uiConfig}) => ({
    ...state,
    data: {
      ...state.data,
      ...uiConfig,
    },
    loading: false,
    error: false,
  })),
  on(LoadUiConfigActionError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),
);
