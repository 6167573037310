import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {translation as translationShared} from '@tsm/shared-i18n';

@Component({
  selector: 'tsm-preview-button',
  template: `
    <button
      pButton
      pRipple
      type="button"
      class="p-button-success"
      [label]="
        title
          ? (title | transloco)
          : (translationShared.shared.save | transloco)
      "
      data-cy="button-success"
    ></button>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PreviewButtonComponent),
      multi: true,
    },
  ],
})
export class PreviewButtonComponent extends AbstractControlValueAccessor {
  translationShared = translationShared;

  @Input() title: string;

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
