import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {sharingAdapter} from '../reducers/sharing.reducer';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getSharingState = createSelector(getDmsState, (x) => x.sharing);

const {selectAll, selectEntities, selectIds, selectTotal} =
  sharingAdapter.getSelectors(getSharingState);

export const selectSharingById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectSharingByOwnerIdOwnerType = (
  ownerId: string,
  ownerType: string,
) =>
  createSelector(selectAll, (entities) => {
    return entities.filter(
      (e) => e.data.ownerId === ownerId && e.data.ownerType === ownerType,
    );
  });
