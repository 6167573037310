import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {withLatestCached} from '@tsm/framework/root';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  DeleteCommentType,
  DeleteCommentTypeError,
  DeleteCommentTypeSuccess,
  DiffCommentType,
  DiffCommentTypeError,
  DiffCommentTypeSuccess,
  LoadCommentType,
  LoadCommentTypeByCode,
  LoadCommentTypeByCodeError,
  LoadCommentTypeByCodeSuccess,
  LoadCommentTypeById,
  LoadCommentTypeByIdError,
  LoadCommentTypeByIdSuccess,
  LoadCommentTypeError,
  LoadCommentTypeSuccess,
  UpsertCommentType,
  UpsertCommentTypeError,
  UpsertCommentTypeSuccess,
} from '../actions';
import {selectCommentTypeByCode} from '../selectors';
import {CommentTypeDataService, CommonApiService} from '../services';
import {CommentTypeModel} from '../models';

@Injectable()
export class CommentTypeEffects {
  translation = translation;
  translationShared = translationShared;

  private readonly COMMENT_TYPE_API_PATH = 'v1/comment-types';

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCommentType),
      exhaustMap(() =>
        this.commentTypeDataService
          .getAll()
          .pipe(
            map((env) =>
              env.success
                ? LoadCommentTypeSuccess({commentTypes: env.data})
                : LoadCommentTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCommentTypeById),
      concatMap(({id}) =>
        this.commentTypeDataService
          .getEntity(id)
          .pipe(
            map((env) =>
              env.success
                ? LoadCommentTypeByIdSuccess({commentType: env.data})
                : LoadCommentTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCommentTypeByCode),
      withLatestCached(({code}) =>
        this.store.select(selectCommentTypeByCode(code)),
      ),
      concatMap(([{code}, status]) =>
        status && status.data
          ? of(LoadCommentTypeByCodeSuccess({commentType: status.data}))
          : this.commentTypeDataService.getEntityByCode(code).pipe(
              map((env) =>
                env.success
                  ? LoadCommentTypeByCodeSuccess({commentType: env.data})
                  : LoadCommentTypeByCodeError({
                      code: code,
                      error: env.error,
                    }),
              ),
            ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertCommentType),
      concatMap(({commentType, listingId, redirect}) =>
        this.commentTypeDataService
          .upsertEntity(commentType, commentType.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.commentType.effects.commentTypeSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertCommentTypeSuccess({
                  commentType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.commentType.effects.commentTypeSaveError,
                );
                return UpsertCommentTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCommentType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commentTypeDataService.deleteEntity(id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.commentType.effects.commentTypeDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/dms/comment-types']);
              }
              return DeleteCommentTypeSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.commentType.effects.commentTypeDeleteError,
              );
              return DeleteCommentTypeError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffCommentType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.COMMENT_TYPE_API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffCommentTypeSuccess({
                  commentTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffCommentTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertCommentTypeSuccess),
      tap(({commentType, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/dms/comment-types', commentType.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCommentTypeSuccess, DiffCommentTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private commentTypeDataService: CommentTypeDataService,
    private toastService: ToastService,
    private router: Router,
    private commonApiService: CommonApiService<
      CommentTypeModel,
      CommentTypeModel
    >,
  ) {}
}
