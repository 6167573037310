import {NgModule} from '@angular/core';
import {IMPORTS, PIPES, SERVICES} from './index';

@NgModule({
  declarations: [...PIPES],
  imports: [...IMPORTS],
  providers: [...SERVICES, ...PIPES],
  exports: [...PIPES],
})
export class DmsServiceModule {}
