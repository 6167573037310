import {FilterModel} from '@tsm/listing-lib/service';
import {createAction, props} from '@ngrx/store';
import {MyNotification} from '../models/notification-my.model';
import {DeliveryItemType, NotificationStatus} from '../models';

export const LoadAllMyNotification = createAction(
  '[MyNotification] Load data',
  props<{userId: string}>(),
);
export const LoadAllMyNotificationSuccess = createAction(
  '[MyNotification] Load data Success',
  props<{
    userId: string;
    notifications: MyNotification[];
  }>(),
);
export const LoadAllMyNotificationError = createAction(
  '[MyNotification] Load data ERROR',
  props<{
    userId: string;
    error: any;
  }>(),
);

export const LoadMyNotificationByFilter = createAction(
  '[MyNotification] Load data By Filter',
  props<{
    userId: string;
    page: number;
    size: number;
    filters?: FilterModel[];
  }>(),
);
export const LoadMyNotificationByFilterSuccess = createAction(
  '[MyNotification] Load data By Filter Success',
  props<{
    userId: string;
    notifications: MyNotification[];
  }>(),
);
export const LoadMyNotificationByFilterError = createAction(
  '[MyNotification] Load data By Filter ERROR',
  props<{
    userId: string;
    error: any;
  }>(),
);
export const ClearMyNotificationByFilter = createAction(
  '[MyNotification] Clear data By Filter',
  props<{
    userId: string;
  }>(),
);

export const UpsertMyNotification = createAction(
  '[NotificationMy] Upsert data',
  props<{
    notification: MyNotification;
    userId: string;
  }>(),
);
export const UpsertMyNotificationSuccess = createAction(
  '[NotificationMy] Upsert data Success',
  props<{
    notification: MyNotification;
    userId: string;
  }>(),
);
export const UpsertMyNotificationError = createAction(
  '[NotificationMy] Upsert data Error',
  props<{error: any}>(),
);

export const DisplayedAllMyNotifications = createAction(
  '[NotificationMy] Displayed all data',
  props<{
    userId: string;
    notificationType: DeliveryItemType;
  }>(),
);
export const DisplayedAllMyNotificationsSuccess = createAction(
  '[NotificationMy] Displayed all data Success',
  props<{
    userId: string;
    notificationType: DeliveryItemType;
  }>(),
);
export const DisplayedAllMyNotificationsError = createAction(
  '[NotificationMy] Displayed all data Error',
  props<{
    error: any;
  }>(),
);

export const DeleteMyNotification = createAction(
  '[NotificationMy] Delete data',
  props<{
    id: string;
    userId: string;
  }>(),
);
export const DeleteMyNotificationSuccess = createAction(
  '[NotificationMy] Delete data Success',
  props<{
    id: string;
    userId: string;
  }>(),
);
export const DeleteMyNotificationError = createAction(
  '[NotificationMy] Delete data Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const DeleteAllMyNotifications = createAction(
  '[NotificationMy] Delete all data',
  props<{
    userId: string;
    notificationType: DeliveryItemType;
    status: NotificationStatus;
  }>(),
);
export const DeleteAllMyNotificationsSuccess = createAction(
  '[NotificationMy] Delete all data Success',
  props<{
    userId: string;
    notificationType: DeliveryItemType;
    status: NotificationStatus;
  }>(),
);
export const DeleteAllMyNotificationsError = createAction(
  '[NotificationMy] Delete all data Error',
  props<{
    error: any;
  }>(),
);
