import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {Script} from '../models';
import {CommonApiService} from './common-api.service';
import {ApiService, Envelope} from '@tsm/framework/http';
import {SharedRequestValidUntil} from '@tsm/framework/root';
import {Observable} from 'rxjs';
import {concatMap, map, switchMap, tap} from 'rxjs/operators';
import {Microservice} from '@tsm/characteristics/service';
import {translation as translationShared} from '@tsm/shared-i18n';
import {ToastService} from '@tsm/framework/toast';

@Injectable({
  providedIn: 'root',
})
export class ScriptDataService {
  private readonly BASE_URL: string;
  private readonly ENTITY_PATH = 'v1/scripts';

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
    private microserviceService: CommonApiService<Microservice, Microservice>,
    private commonApiService: CommonApiService<Script, Script>,
    private toastService: ToastService,
  ) {
    this.BASE_URL = config.value.apiUrls.tsmFormConfig;
  }

  evalScriptById(scriptId: string, scriptData = {}, disableCache = false, disableToast = false) {
    return this.getScriptById(scriptId).pipe(
      switchMap((script) =>
        this.microserviceService
          .getEntityByCodeSharedRequest(
            'microservice',
            script.data.microservice,
          )
          .pipe(
            map((microservice) => ({
              script: script.data,
              microservice: microservice.data,
            })),
          ),
      ),
      switchMap(({script, microservice}) =>
        disableCache
          ? this.evalScript(microservice?.backendUrl, script.code, {}, disableToast)
          : this.evalScriptSharedRequest(
              microservice?.backendUrl,
              script.code,
              scriptData,
              disableToast
            ),
      ),
      map((val) => ({
        data: val.data,
        id: scriptId,
        loading: false,
        error: val.error,
      })),
    );
  }

  evalScriptByCode(scriptCode: string, scriptData = {}, disableCache = false, disableToast = false) {
    return this.getScriptByCode(scriptCode).pipe(
      concatMap((script) =>
        this.microserviceService
          .getEntityByCodeSharedRequest(
            'microservice',
            script.data.microservice,
          )
          .pipe(
            map((microservice) => ({
              script: script.data,
              microservice: microservice.data,
            })),
          ),
      ),
      concatMap(({script, microservice}) =>
        disableCache
          ? this.evalScript(microservice?.backendUrl, script.code, scriptData, disableToast)
          : this.evalScriptSharedRequest(
              microservice?.backendUrl,
              script.code,
              scriptData,
              disableToast
            ),
      ),
      map((val) => {
        return {
          data: val.data,
          id: scriptCode,
          loading: false,
          error: val.error,
        };
      }),
    );
  }

  @SharedRequestValidUntil(120)
  getScriptByCode(type: string): Observable<Envelope<Script>> {
    return this.commonApiService.getItemsById('', type, this.ENTITY_PATH);
  }

  @SharedRequestValidUntil(120)
  getScriptById(id: string): Observable<Envelope<Script>> {
    return this.commonApiService.getEntity(this.ENTITY_PATH, id);
  }

  get(id: string) {
    return this.commonApiService.getEntity(this.ENTITY_PATH, id);
  }

  upsert(script: Script) {
    return this.commonApiService.upsertEntity(
      this.ENTITY_PATH,
      script,
      script.id,
    );
  }

  patch(script: Partial<Script>) {
    return this.commonApiService.patchEntity(
      this.ENTITY_PATH,
      script.id,
      script,
    );
  }

  getByCode(code: string) {
    return this.apiService.get<Script, Script>(
      `${this.BASE_URL}/${this.ENTITY_PATH}/${code}`,
    );
  }

  upsertByCode(script: Script) {
    if (script.code) {
      return this.patchByCode(script);
    } else {
      return this.postByCode(script);
    }
  }

  patchByCode(script: Script) {
    return this.apiService.patch(
      `${this.BASE_URL}/${this.ENTITY_PATH}/${script.code}`,
      script,
    );
  }

  postByCode(script: Script) {
    return this.apiService.patch(
      `${this.BASE_URL}/${this.ENTITY_PATH}/${script.code}`,
      script,
    );
  }

  //titap editor services doplnit spel.api.service.ts
  evalScript(microserviceApi: string, scriptCode: string, data = {}, disableToast = false) {
    return this.getEvalScriptResponseData(microserviceApi, scriptCode, data, disableToast);
  }

  @SharedRequestValidUntil(120)
  evalScriptSharedRequest(
    microserviceApi: string,
    scriptCode: string,
    data = {},
    disableToast = false
  ) {
    return this.getEvalScriptResponseData(microserviceApi, scriptCode, data, disableToast);
  }

  delete(id: string) {
    return this.apiService.delete(`${this.BASE_URL}/${this.ENTITY_PATH}/${id}`);
  }

  private getEvalScriptResponseData(
    microserviceApi: string,
    scriptCode: string,
    data = {},
    disableToast = false
  ) {
    return this.apiService
      .post(
        `${this.config.value.apiUrls.base}${microserviceApi}/v1/script/${scriptCode}/eval`,
        data ?? {},
        null,
        {
          observe: 'body',
          responseType: 'text' as 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.success) {
            try {
              return {
                data: JSON.parse(res.data as string),
                error: null,
                success: res.success,
              };
            } catch (e) {
              return {data: res.data, error: null, success: res.success};
            }
          }
          try {
            return {
              data: res?.data,
              error: JSON.parse(res.error),
              success: res.success,
            };
          } catch (e) {
            return {data: res?.data, error: res?.error, success: res.success};
          }
        }),
        tap((res) => {
          if (!res.success && !disableToast) {
            this.toastService.showError(
              res.error,
              translationShared.shared.spelParserError,
              6000,
            );
          }
        }),
      );
  }
}
