import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  ChangeDetectorRef,
  forwardRef,
  Injector,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import { RatingModule } from 'primeng/rating';

@Component({
    selector: 'dtl-form-input-rating',
    templateUrl: './form-input-rating.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputRatingComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FormInputRatingComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputRatingComponent),
    ],
    host: {
        class: 'readonly-no-border',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RatingModule, FormsModule],
})
export class FormInputRatingComponent extends ParentWidgetAccessorComponent {
  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }
}
