import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter} from '../reducers/popup.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');

export const getPopup = createSelector(
  getUserManagementState,
  (popup) => popup.popupReducer,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getPopup);

export const selectPopupByParentComponentName = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectAllPopups = selectAll;
