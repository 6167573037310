import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';

/**
 * Tlačítko s odkazem na Kibanu, předvyplní filtr dle vstupů.
 * TODO: možnost nastavit časový rozsah (např. začátek dle času vytvoření sledované entity)
 */
@Component({
  selector: 'tsm-kibana-log-link',
  template: `
    <button
      pButton
      pRipple
      *ngIf="baseUrl != null"
      type="button"
      [label]="label"
      [pTooltip]="!label ? 'Kibana' : null"
      (click)="onLink()"
      class="p-button-plain p-button-text"
      icon="p-icon-bug-report"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KibanaLogLinkComponent {
  /**
   * Pole dle kterého má filtrovat
   */
  @Input()
  queryField: string;

  /**
   * Hodnota filtru
   */
  @Input()
  queryValue: string;

  /**
   * Label není povinný - pokud není zadán, button se vykreslí jako ikona s tooltipem "Kibana"
   */
  @Input()
  label: string;

  /**
   * URL na kibanu. Obsahuje placelholdery `${query.field}`, `${query.value}`
   */
  baseUrl: string;

  constructor(private config: ConfigService<Config>) {
    this.baseUrl = config.value.kibana?.url;
  }

  onLink() {
    if (!this.baseUrl) {
      return;
    }
    const url = this.baseUrl
      .replace(new RegExp('\\${query.field}', 'g'), this.queryField)
      // neescapuje url!
      .replace(new RegExp('\\${query.value}', 'g'), this.queryValue);
    window.open(url, '_blank', 'noopener noreferrer');
  }
}
