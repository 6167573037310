import * as objectPath from 'object-path';

export class FilterUtils {
  public static objectContainsValueByFilter(
    source: any,
    filter: any,
    key: string,
  ): boolean {
    if (source === undefined || source === null) {
      return false;
    }
    const tmpSource = objectPath.get(source, key);
    const tmpFilter = objectPath.get(filter, key);
    return tmpSource === tmpFilter;
  }

  /**
   *
   * @param Object that should contain subobject
   * @param filter object to find
   * @return true, if values of properties exist in source object. It is not necessary to contain all properties for true return value.
   */
  public static objectContainsSubobject(source: any, filter: any): boolean {
    if (source == null) {
      return false;
    }

    let result = true;

    if (source == null) {
      return false;
    }

    for (const key in filter) {
      const requiredPropertyValue = filter[key];
      const givenPropertyValue = source[key];

      if (
        !Array.isArray(requiredPropertyValue) &&
        requiredPropertyValue instanceof Object
      ) {
        result =
          result &&
          this.objectContainsSubobject(
            givenPropertyValue,
            requiredPropertyValue,
          );
      } else if (requiredPropertyValue instanceof Array) {
        if (givenPropertyValue instanceof Array) {
          result =
            result &&
            this.isAnyObjectInArrayContainsRequiredProperty(
              givenPropertyValue,
              requiredPropertyValue,
            );
          //Nevzdycky funguje jak ma, kvuli tomu, ze funkce Array.includes(something) porovna reference, nikoliv hodnoty za vyjimkou mame-li primitivu
          // for(const item in requiredPropertyValue){
          //   result = result && givenPropertyValue.includes(item);
          // }
        } else {
          return false;
        }
      } else {
        result = result && requiredPropertyValue === givenPropertyValue;
      }

      if (!result) {
        return false;
      }
    }

    return result;
  }

  /**
   * Tato funkce overi existuje-li prvek v poli, ktery obsahuje pozadovane atributy
   * @param givenPropertyValue Pole prvku ve kterych se bude vyhledavat pozadovany/e atributy
   * @param requiredPropertyValue  pozadovane atributy
   */
  private static isAnyObjectInArrayContainsRequiredProperty(
    givenPropertyValue,
    requiredPropertyValue,
  ): boolean {
    for (let i = 0; i < givenPropertyValue.length; i++) {
      if (typeof requiredPropertyValue[0] === 'object') {
        for (const key in requiredPropertyValue[0]) {
          if (givenPropertyValue[i][key]) {
            if (givenPropertyValue[i][key] === requiredPropertyValue[0][key]) {
              return true;
            }
          }
        }
      } else {
        if (givenPropertyValue[i]) {
          if (givenPropertyValue[i] === requiredPropertyValue[0]) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
