import {LayoutState} from '../models/client';
import {Store} from '@ngrx/store';
import {CloseMenuAction, OpenMenuAction} from '../actions';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface MenuChangeEvent {
  key: string;
  routeEvent?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutTsmMenuService {
  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  constructor(private store: Store<LayoutState>) {}

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }

  reset() {
    this.resetSource.next(true);
  }

  openMenu() {
    this.store.dispatch(OpenMenuAction());
  }

  closeMenu() {
    this.store.dispatch(CloseMenuAction());
  }

  toggleMenu(open: boolean) {
    open ? this.openMenu() : this.closeMenu();
  }
}
