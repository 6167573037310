import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  NgModule,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {Terminator} from '@tsm/framework/terminator';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[clickOutside]',
  providers: [Terminator],
})
export class ClickOutsideDirective implements AfterViewInit {
  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  constructor(
    private _elementRef: ElementRef,
    private terminator$: Terminator,
    private zone: NgZone,
  ) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        fromEvent(document, 'click')
          .pipe(takeUntil(this.terminator$))
          .subscribe((event: MouseEvent) => {
            console.log(this._elementRef);
            const targetElement = event.target;
            if (!targetElement) {
              return;
            }

            const clickedInside =
              this._elementRef.nativeElement.contains(targetElement);
            if (!clickedInside) {
              this.zone.run(() => {
                this.clickOutside.emit(event);
              });
            }
          });
      }, 1);
    });
  }
}

@NgModule({
  declarations: [ClickOutsideDirective],
  exports: [ClickOutsideDirective],
})
export class ClickOutsideModule {}
