import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {
  BackupSetDiffOptions,
  BackupSetDiffTable,
  BackupSetImport,
  DiffList,
} from '@tsm/listing-lib/service';

export const LoadBackupSetImportById = createAction(
  '[BackupSetImport] Load BackupSetImport by ID',
  props<{
    id: string;
    forcedReload?: boolean;
  }>(),
);
export const LoadBackupSetImportByIdSuccess = createAction(
  '[BackupSetImport] Load BackupSetImport by ID Success',
  props<{backupSetImport: BackupSetImport}>(),
);
export const LoadBackupSetImportByIdError = createAction(
  '[BackupSetImport] Load BackupSetImport by ID Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const UpsertBackupSetImport = createAction(
  '[BackupSetImport] Upsert BackupSetImport]',
  props<{
    backupSetImport: BackupSetImport;
    listingId: string;
    navigateAfterRefresh?: boolean;
  }>(),
);
export const UpsertBackupSetImportSuccess = createAction(
  '[BackupSetImport] Upsert BackupSetImport Success]',
  props<{
    backupSetImport: BackupSetImport;
    listingId: string;
    navigateAfterRefresh: boolean;
  }>(),
);
export const UpsertBackupSetImportError = createAction(
  '[BackupSetImport] Upsert BackupSetImport Error]',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const DeleteBackupSetImport = createAction(
  '[BackupSetImport] Delete BackupSetImport',
  props<{
    id: string;
    listingId: string;
    redirect?: boolean;
  }>(),
);
export const DeleteBackupSetImportSuccess = createAction(
  '[BackupSetImport] Delete BackupSetImport Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteBackupSetImportError = createAction(
  '[BackupSetImport] Delete BackupSetImport Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const UploadBackupSetImport = createAction(
  '[BackupSetImport] Upload BackupSetImport',
  props<{
    file: any;
  }>(),
);

export const UploadBackupSetImportSuccess = createAction(
  '[BackupSetImport] Upload BackupSetImportSuccess',
  props<{
    backupSetImport: BackupSetImport;
  }>(),
);

export const UploaBackupSetImportError = createAction(
  '[BackupSetImport] Upload BackupSetImportError',
  props<{
    error: TsmError;
  }>(),
);

export const DiffBackupSetImport = createAction(
  '[BackupSetImport] Diff BackupSetImport',
  props<{
    listingId: string;
    backupSetImportId: string;
    options?: BackupSetDiffOptions;
  }>(),
);

export const DiffBackupSetImportSuccess = createAction(
  '[BackupSetImport] Diff BackupSetImport Success',
  props<{
    listingId: string;
    backupSetDiffs: BackupSetDiffTable[];
    backupSetImportId: string;
    options?: BackupSetDiffOptions;
  }>(),
);

export const DiffBackupSetImportError = createAction(
  '[BackupSetImport] Diff BackupSetImport Error',
  props<{
    id: string;
    backupSetImportId: string;
    error?: any;
  }>(),
);

export const SaveBackupSetImportDiff = createAction(
  '[BackupSetImport] Save BackupSetImportDiff',
  props<{
    backupSetImportId: string;
    diffList: DiffList[];
  }>(),
);

export const SaveBackupSetImportDiffSuccess = createAction(
  '[BackupSetImport] Save BackupSetImportDiff Success',
  props<{
    backupSetImportId: string;
    [p: string]: any;
  }>(),
);

export const SaveBackupSetImportDiffError = createAction(
  '[BackupSetImport] Save BackupSetImportDiff Error',
  props<{
    backupSetImportId: string;
    error?: any;
  }>(),
);
