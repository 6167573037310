export const cs = {
  comment: 'Komentář',
  worklog: 'Log práce',
  dmsService: {
    succNotifTmplSave: 'Ukládání šablony proběhlo úspěšně',
    unSuccNotifTmplSave: 'Během ukládání šablony došlo k chybě',
    succNotifTmplDelete: 'Šablona úspěšně smazána',
    unSuccNotifTmplDelete: 'Během mazání šablony došlo k chybě',
    succNotifTmpDiff: 'Ukládání rozdílů šablony skončilo úspěšně.',
    unsuccNotifTmpDiff: 'Při ukládání rozdílů šablony došlo k chybě.',
    succNotifRuleSave: 'Ukládání typu události proběhlo úspěšně',
    unSuccNotifRuleSave: 'Během ukládání typu události došlo k chybě',
    succNotifRuleDelete: 'Typ události úspěšně smazán',
    unSuccNotifRuleDelete: 'Během mazání typu události došlo k chybě',
    succNotifRuleDiff: 'Ukládání rozdílů typu události skončilo úspěšně.',
    unsuccNotifRuleDiff: 'Při ukládání rozdílů typu události došlo k chybě.',
  },
  attachments: {
    type: 'Typ',
    fileName: 'Název souboru',
    fileUploadDate: 'Datum nahrání souboru',
    fileUploaderName: 'Nahrál',
    save: {
      success: 'Příloha byla úspěšně uložena.',
      error: 'Při ukládání příloh(y) došlo k chybě.',
    },
    delete: {
      success: 'Příloha byla úspěšné smazána.',
      error: 'Při mazání přílohy došlo k chybě.',
    },
    download: {
      error: 'Při stahování přílohy došlo k chybě.',
    },
    saveAndDeleteAll: 'Soubor byl úspěšně uložen.',
  },
  comments: {
    save: {
      success: 'Komentář byl úspěšně uložen.',
      error: 'Při ukládání komentáře došlo k chybě.',
    },
    delete: {
      success: 'Komentář byl úspěšné smazán.',
      error: 'Při mazání komentáře došlo k chybě.',
    },
  },
  worklogs: {
    started: 'Zahájena',
    ended: 'Ukončena',
    save: {
      success: 'Log práce byl úspěšně uložen.',
      error: 'Při ukládání logu práce došlo k chybě.',
    },
    delete: {
      success: 'Log práce byl úspěšné smazán.',
      error: 'Při mazání logu práce došlo k chybě.',
    },
  },
  worklogTimer: {
    effects: {
      worklogTimerStartSuccess: 'Log práce byl úspěšně zahájen',
      worklogTimerEndSuccess: 'Log práce byl úspěšně uložen.',
      worklogTimerEndError: 'Během ukládání Logu práce došlo k chybě',
    },
  },
  attachmentType: {
    effects: {
      attachmentTypeSaveSuccess: 'Typ přílohy byl úspěšně uložen.',
      attachmentTypeSaveError: 'Během ukládání typu přílohy došlo k chybě',
      attachmentTypeDeleteSuccess: 'Mazání Typu přílohy proběhlo úspěšně',
      attachmentTypeDeleteError: 'Při mazání záznamu došlo k chybě.',
      attachmentTypeDiffSuccess:
        'Ukládání rozdílů typu přílohy skončilo úspěšně.',
      attachmentTypeDiffError:
        'Při ukládání rozdílů typu přílohy došlo k chybě.',
    },
  },
  commentType: {
    title: 'Typ komentáře',
    effects: {
      commentTypeSaveSuccess: 'Typ komentáře byl úspěšně uložen.',
      commentTypeSaveError: 'Během ukládání typu komentáře došlo k chybě',
      commentTypeDeleteSuccess: 'Mazání Typu komentáře proběhlo úspěšně',
      commentTypeDeleteError: 'Při mazání záznamu došlo k chybě.',
      commentTypeDiffSuccess:
        'Ukládání rozdílů typu komentáře skončilo úspěšně.',
      commentTypeDiffError:
        'Při ukládání rozdílů typu komentáře došlo k chybě.',
    },
  },
  worklogType: {
    effects: {
      worklogTypeSaveSuccess: 'Typ záznamu práce byl úspěšně uložen.',
      worklogTypeSaveError: 'Během ukládání typu logu práce došlo k chybě',
      worklogTypeDeleteSuccess: 'Mazání Typu logu práce proběhlo úspěšně',
      worklogTypeDeleteError: 'Při mazání záznamu došlo k chybě.',
      worklogTypeDiffSuccess:
        'Ukládání rozdílů typu logu práce skončilo úspěšně.',
      worklogTypeDiffError:
        'Při ukládání rozdílů typu logu práce došlo k chybě.',
    },
  },
  formatterTemplate: {
    save: {
      success: 'Ukládání rozložení proběhlo úspěšně',
      error: 'Během ukládání rozložení došlo k chybě',
    },
    delete: {
      success: 'Rozložení úspěšně smazáno',
      error: 'Během mazání rozložení došlo k chybě',
    },
    diff: {
      success: 'Ukládání rozdílů layoutu skončilo úspěšně.',
      error: 'Při ukládání rozdílů layoutu došlo k chybě.',
    },
  },
  formatterDocumentTemplate: {
    diff: {
      success: 'Ukládání rozdílů šablon skončilo úspěšně.',
      error: 'Při ukládání rozdílů šablon došlo k chybě.',
    },
  },
  sharing: {
    save: {
      success: 'Ukládání vazby sdílení proběhlo úspěšně',
      error: 'Během ukládání vazby sdílení došlo k chybě',
    },
    delete: {
      success: 'Vazba sdílení smazána',
      error: 'Během mazání vazby sdílení došlo k chybě',
    },
  },
  deliveryTime: {
    diff: {
      success: 'Ukládání rozdílů času doručení proběhlo úspěšně.',
      error: 'Při ukládání rozdílů času doručení došlo k chybě.',
    },
  },
};
