<ng-container *ngIf="!excelStyleErrors && convertErrors.length > 0 && touched">
  <span style="color: red" *ngFor="let error of convertErrors">
    {{ error.messageKey | transloco: error.value }}
  </span>
</ng-container>

<ng-container *ngIf="excelStyleErrors">
  <span
    *ngIf="convertErrors.length > 0 && touched && !readonly"
    class="error-carret"
    (click)="carretClicked = true"
    [tsmTooltip]="errorsHtml"
    tooltipStyleClass="excel-errors-tooltip"
  >
  </span>
</ng-container>
