export const cs = {
  billingPlugins: {
    headerDescription: 'Header dokumentace',
    billingDocument: {
      invoiceNumberMnemonic: 'Mnemonika pro číslo faktury',
      invoiceNumberMnemonicTooltip:
        ' Mnemonika pro generování čísla faktury. V případě, že je hodnota ponechána prázdná, použije se kód billing dokumentu jako výchozí číslo faktury.',
      dueDate: 'Datum splatnosti',
      invoiceDate: 'Datum vystavení faktury',
      invoiceText: 'Text na fakture',
      priceWithVat: 'Cena s DPH',
      invoiceNumber: 'Dokument billingu',
      billingCurrency: 'Měna',
      account: 'Account',
      catalogLines: 'Katalogové položky',
      products: 'Produkty',
      invoicedProductsAndServices: 'Fakturované položky a služby',
      generateInvoice: 'Generovat fakturu',
      billingCycle: 'Účtovací období',
      variableSymbol: 'Variabilní symbol',
      dateOfRealization: 'Datum zdaň. plnění',
      priceWithoutVat: 'Cena bez DPH',
      billingDocuments: 'Billing dokumenty',
      generate: 'Vygenerovat fakturační podklad',
      approve: 'Schválit',
      storno: 'Storno',
      downloadCSV: 'Stáhnout CSV',
      showDocument: 'Zobrazit doklad',
      regenerateDocument: 'Vygenerovat PDF',
      resendDocument: 'Znovu zaslat fakturu',
      invoiceDocumentNotFound: 'Nepodařilo se najít požadovaný dokument',
    },
    billingDocumentLine: {
      quantity: 'Počet',
      entityInstanceConfiguration: 'Produkt',
      entityInstanceConfigurationName: 'Název produktu',
      entityInstanceConfigurationCode: 'Kód produktu',
      entityInstanceConfigurationValidFor: {
        from: 'Platnost produktu od',
        to: 'Platnost produktu do',
      },
      deleteConfirmation: 'Opravdu si přejete položku odstranit?',
      billingDocumentLines: 'Účetní položky',
      new: 'Vytvořit položku',
      edit: 'Upravit položku',
      goToProductDetail: 'Otevřít detail produktu/služby',
      entityCatalogSpecification: 'Katalogová položka',
      vat: 'DPH',
      sortOrder: 'Pořadí',
      priceWithoutVat: 'Cena bez DPH',
      addNewConfiguration: 'Přidat produkt z katalogu',
      addExistingConfiguration: 'Přidat existující produkt',
      amount: 'Množství',
      vatRate: 'DPH (%)',
      parentDocumentLine: 'Nadřízená položka dokumentu',
      addChild: 'Přidat podřízenou položku',
      priceType: 'Typ poplatku',
      unitPrice: 'Jednotková cena',
      regionName: 'Název oblasti',
      regionCode: 'Kód oblasti',
      SapConsumerNumber: 'Číslo odběratele v SAP',
      costCenter: 'Nákladové středisko',
      dueDateType: 'Splatnost',
      materialNumber: 'Číslo materiálu',
      address: 'Adresa',
      plugId: 'Číslo zásuvky',
      crossId: 'CROSS ID OZ',
      contractNumberOtherParty: 'Číslo smlouvy protistrany',
      productName: 'Název produktu',
      billingDocument: 'Dokument billingu',
    },
    billingCycle: {
      billingCycle: 'Účtovací období',
      billingStart: 'Začátek',
      status: 'Stav',
      billingEnd: 'Konec',
      billingDate: 'Den fakturace',
      billingPeriod: 'Perioda účtování',
      dataTags: 'Štítky',
      type: 'Typ',
      description: 'Popis',
      edit: 'Upravit účtovací období',
      new: 'Vytvořit účtovací období',
      periodFrom: 'Začátek účtovacího období',
      periodTo: 'Konec účtovacího období',
      cycleType: 'Typ účtovacího období',
      periodError: '"Období od" musí být před "Období po"',
      billingDocuments: 'Dokumenty billingu',
      billing: 'Billing',
      toAccount: 'Zaúčtovat',
    },
    plugin: {
      billingCycleTypeLOV: 'Typ účtovacího období',
      loveboxSAPStatus: 'Stav fakturačního SAP dokumentu',
      billingSAPDocumentStatus:
        'Billing - Výběr typu fakturačního SAP dokumentu',
      billingCycleLov: 'Účtovací období',
      billingDocumentLov: 'Typ billing dokumentu',
      billingDocumentWidget: 'Seznam dokumentů billingu',
    },
    filters: {
      tsmBillingCycleFilter: 'tSM Billing Cycle Filter',
      tsmBillingCycleFilterDescription:
        'Poskytuje rozhraní pro filtrování fakturačních cyklů s přizpůsobitelnými možnostmi. ' +
        'Podporuje filtrování podle typu cyklu, období platnosti a kódu. ' +
        'Integruje se se službami pro dynamické získávání a správu vybraných cyklů.',
      tsmBillingCycleStatusFilter: 'tSM Billing Cycle Status Filter',
      tsmBillingCycleStatusFilterDescription:
        'Poskytuje rozhraní pro filtrování stavů fakturačních cyklů podle kódu a názvu. ' +
        'Integruje se se službami pro dynamické získávání stavů a správu výběrů.',
      tsmBillingCycleTypeFilter: 'tSM Billing Cycle Type Filter',
      tsmBillingCycleTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů fakturačních cyklů podle kódu a názvu. ' +
        'Dynamicky získává data a podporuje vícenásobný výběr pro větší flexibilitu.',
      tsmBillingCycleTypeFrequencyFilter:
        'tSM Billing Cycle Type Frequency Filter',
      tsmBillingCycleTypeFrequencyFilterDescription:
        'Poskytuje rozhraní pro filtrování frekvencí fakturace pomocí tabulky. ' +
        'Dynamicky získává dostupné frekvence a podporuje vícenásobný výběr.',
      tsmBillingDocumentStatusFilter: 'tSM Billing Document Status Filter',
      tsmBillingDocumentStatusFilterDescription:
        'Poskytuje rozhraní pro filtrování stavů fakturačních dokumentů podle kódu a názvu. ' +
        'Dynamicky získává a spravuje stavy, podporuje vícenásobný výběr.',
      tsmBillingDocumentTypeFilter: 'tSM Billing Document Type Filter',
      tsmBillingDocumentTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů fakturačních dokumentů podle kódu a názvu. ' +
        'Dynamicky získává typy a podporuje vícenásobný výběr pro větší flexibilitu.',
      tsmBillingSapDocumentStatusFilter:
        'tSM Billing Sap Document Status Filter',
      tsmBillingSapDocumentStatusFilterDescription:
        'Poskytuje rozhraní pro filtrování stavů SAP fakturačních dokumentů podle kódu a názvu. ' +
        'Dynamicky získává stavy a podporuje vícenásobný výběr pro větší flexibilitu.',
    },
    pipes: {
      billingCurrencyLoader: 'Načítání fakturační měny',
      billingCurrencyLoaderDescription:
        'Načítá údaje o měně fakturace podle ID z úložiště, získává specifická pole, jako je kód ' +
        'nebo vnořené vlastnosti pomocí řetězců oddělených tečkami a zajišťuje načtení dat, ' +
        'pokud již nejsou dostupná.',
      billingCurrencyLoaderCode: 'Načítání fakturační měny podle kódu',
      billingCurrencyLoaderCodeDescription:
        'Načítá údaje o měně fakturace podle kódu z úložiště, získává specifická pole, jako je kód ' +
        'nebo vnořené vlastnosti pomocí řetězců oddělených tečkami a zajišťuje načtení dat, ' +
        'pokud již nejsou dostupná.',
      billingCycleLoader: 'Načítání fakturačního cyklu',
      billingCycleLoaderDescription:
        'Načítá data fakturačního cyklu podle ID z úložiště, získává specifická pole, jako je kód ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingCycleLoaderCode: 'Načítání fakturačního cyklu podle kódu',
      billingCycleLoaderCodeDescription:
        'Načítá data fakturačního cyklu podle Code z úložiště, získává specifická pole, jako je kód ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingCycleStatusLoader: 'Načítání stavu fakturačního cyklu',
      billingCycleStatusLoaderDescription:
        'Načítá stav fakturačního cyklu podle ID z úložiště, získává specifická pole, jako je kód ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingCycleStatusLoaderCode:
        'Načítání stavu fakturačního cyklu podle kódu',
      billingCycleStatusLoaderCodeDescription:
        'Načítá stav fakturačního cyklu podle kódu z úložiště, získává specifická pole, jako je kód ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingCycleTypeLoader: 'Načítání typu fakturačního cyklu',
      billingCycleTypeLoaderDescription:
        'Načítá typ fakturačního cyklu podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingDocumentLineLoader: 'Načítání řádku fakturačního dokumentu',
      billingDocumentLineLoaderDescription:
        'Načítá data řádku fakturačního dokumentu podle ID z úložiště, získává specifická pole, jako je ' +
        'název nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingDocumentLoaderKey: 'Načítání fakturačního dokumentu podle klíče',
      billingDocumentLoaderKeyDescription:
        'Načítá data fakturačního dokumentu podle klíče z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingDocumentLoader: 'Načítání fakturačního dokumentu',
      billingDocumentLoaderDescription:
        'Načítá data fakturačního dokumentu podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingDocumentStatusLoader: 'Načítání stavu fakturačního dokumentu',
      billingDocumentStatusLoaderDescription:
        'Načítá stav fakturačního dokumentu podle ID z úložiště, získává specifická pole, jako je kód ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingDocumentStatusLoaderCode:
        'Načítání stavu fakturačního dokumentu podle kódu',
      billingDocumentStatusLoaderCodeDescription:
        'Načítá stav fakturačního dokumentu podle kódu z úložiště, získává specifická pole, jako je kód ' +
        'nebo celý objekt, s podporou pro vnořené vlastnosti pomocí řetězců oddělených tečkami. ' +
        'Zajišťuje načtení dat, pokud již nejsou dostupná.',
      billingDocumentTypeLoader: 'Načítání typu fakturačního dokumentu',
      billingDocumentTypeLoaderDescription:
        'Načítá typ fakturačního dokumentu podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt. Podporuje flexibilní načítání dat s výběrem polí a zajišťuje načtení dat, ' +
        'pokud již nejsou dostupná.',
      billingPriceTypeLoader: 'Typ fakturační ceny',
      billingPriceTypeLoaderDescription:
        'Převádí kódy typu fakturační ceny na odpovídající popisky. Převádí "mesicni" nebo "MESICNI" na ' +
        '"Měsíční" a "jednorazovy" nebo "JEDNORAZOVY" na "Jednorázový". Pro nerozpoznané hodnoty vrací ' +
        'prázdný řetězec.',
      billingSapDocumentStatusLoader: 'Načítání stavu SAP dokumentu',
      billingSapDocumentStatusLoaderDescription:
        'Načte stav SAP dokumentu podle jeho ID z úložiště, získá specifická pole, jako je název, nebo ' +
        'celý objekt, a zajistí načtení dat, pokud již nejsou dostupná.',
      billingVatRateLoader: 'Načítání fakturační sazby DPH',
      billingVatRateLoaderDescription:
        'Načte sazbu DPH pro fakturaci podle jejího ID z úložiště, získá specifická pole, jako je kód ' +
        'nebo celý objekt, a zajistí načtení dat, pokud již nejsou dostupná.',
    },
  },
};
