import {Observable} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

export function switchMapWith<TSource, TSecond>(
  second: (input: TSource) => Observable<TSecond>,
  startWithValue: string,
) {
  return function efficiencyAtItsFinest(source: Observable<TSource>) {
    return Observable.create((subscriber) => {
      const subscription = source
        .pipe(switchMap(second), startWith(startWithValue))
        .subscribe(
          (value) => {
            try {
              subscriber.next(value);
            } catch (err) {
              subscriber.error(err);
            }
          },
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        );
      return subscription;
    }) as Observable<TSecond>;
  };
}
