<ng-container *ngIf="state">
  <!--  <div class="layout-wrapper" [ngClass]="{'mobile-device': isMobile}">-->
  <ng-container *ngLet="darkTheme$ | async as darkTheme">
    <!-- p-input-filled -->
    <!--    layout-static-inactive  => menu je zavrene -->
    <!--    layout-overlay-active  => menu je otevrene -->
    <!--    layout-mobile-active  => nastavi se maska, kdyz jsem na mobilu nebo v malem rozliseni -->
    <div
      *ngLet="isMobileMenu$ | async as isMobileMenu"
      class="layout-container"
      [ngClass]="{
        'layout-static': state.topbar.pinned,
        'layout-static-inactive': state.topbar.staticMenuDesktopInactive,
        'layout-static-locked': state.topbar.opened,
        'layout-overlay': !state.topbar.pinned,
        'layout-overlay-active': isMobileMenu ? false : state.topbar.opened,
        'layout-mobile-active': isMobileMenu ? state.topbar.opened : false,
        'layout-topbar-dark': darkTheme,
        'layout-topbar-white': !darkTheme,
        'layout-menu-dark': darkTheme,
        'layout-menu-light': !darkTheme,
      }"
    >
      <tsm-topbar
        [data]="state.topbar"
        [worklogStatus]="worklogStatus"
        [menuMode]="state.topbar.pinned"
        [panelMode]="panelMode"
        (shouldOpenMenuChanges)="
          shouldOpenMenuChanges($event, state.topbar.pinned)
        "
        (topbarOpens)="topbarOpens($event)"
        (topbarClose)="closeTopBar()"
      >
      </tsm-topbar>

      <div class="layout-sidebar">
        <div class="layout-menu-container">
          <tsm-layout-menu [menuPinned]="state.topbar.pinned">
          </tsm-layout-menu>
          <div *ngIf="!(isMobileMenu$ | async)" class="config-bar -mr-1">
            <div class="flex flex-nowrap gap-2">
              <div class="flex align-items-center justify-content-center">
                <i
                  class="menu-mode-icon tsm-icon-pushpin-3 cursor-pointer"
                  [class.p-state-disabled]="state.topbar.opened"
                  [class.p-state-active]="state.topbar.pinned"
                  (click)="pushPinClick(state.topbar.pinned)"
                  [tsmTooltip]="menuOverlappingTooltip"
                  data-cy="layout-tsm-overlapping-menu-button"
                ></i>
                <ng-template #menuOverlappingTooltip>
                  <!-- Headline -->
                  <div
                    class="border-blue-600 font-medium text-2xl px-3 py-2 mb-2 text-900 sticky top-0 surface-overlay tsm-tooltip-header"
                    style="border-left: 8px solid"
                  >
                    {{ translation.menuPinned.header | transloco }}
                  </div>
                  <!-- Text Body -->
                  <div class="line-height-3 text-700 mb-3">
                    <h4>
                      {{ translation.menuPinned.overlayMode | transloco }}
                    </h4>
                    <p>
                      {{
                        translation.menuPinned.overlayModeDescription
                          | transloco
                      }}
                    </p>

                    <h4>{{ translation.menuPinned.staticMode | transloco }}</h4>
                    <p>
                      {{
                        translation.menuPinned.staticModeDescription | transloco
                      }}
                    </p>
                  </div>
                </ng-template>
              </div>
              <div
                *tsmHasPriv="'!Special.MenuConfig'"
                class="flex align-items-center justify-content-center"
              >
                <i
                  [routerLink]="'layout-menu-designer'"
                  class="menu-mode-icon pi pi-cog cursor-pointer"
                  tsmTooltip="Upravit menu"
                ></i>
              </div>
            </div>
          </div>
        </div>

        <!-- TODO omezit -->
        <dtl-dynamic-component
          selector="dtl-new-comment-windows"
        ></dtl-dynamic-component>
      </div>

      <div
        class="layout-content-wrapper"
        [class.right-panel-pined]="panelMode"
        [ngStyle]="{
          'padding-right':
            !!state.topbar.activeTopBarItem && panelMode ? '350px' : null,
        }"
      >
        <p-blockUI [target]="layoutContent" [blocked]="false"></p-blockUI>
        <tsm-blockable-div #layoutContent [class]="'layout-content'">
          <router-outlet></router-outlet>
        </tsm-blockable-div>
      </div>

      <div class="layout-mask" (click)="onLayoutMaskClick()"></div>

      <div
        id="notifications-panel"
        class="history-panel"
        [class.opened]="state.topbar.activeTopBarItem === 'notifications'"
      >
        <div class="content">
          <dtl-dynamic-component
            *ngIf="state.topbar.activeTopBarItem === 'notifications'"
            [type]="notificationSideBarType$ | async"
            [inputs]="{
              notificationType: state.topbar.activeTopBarItem,
            }"
            [outputs]="{
              close: closeTopBar.bind(this),
            }"
          ></dtl-dynamic-component>
          <tsm-panel-pin
            [panelMode]="panelMode"
            (panelPushPinClick)="panelPushPinClick()"
          >
          </tsm-panel-pin>
        </div>
      </div>

      <div
        id="worklogs-panel"
        class="history-panel"
        [class.opened]="state.topbar.activeTopBarItem === 'worklogs'"
      >
        <div class="content">
          <dtl-dynamic-component
            *ngIf="state.topbar.activeTopBarItem === 'worklogs'"
            [type]="worklogSideBarType$ | async"
            [outputs]="{
              runningWorklogStatus: runningWorklogStatus.bind(this),
              close: closeTopBar.bind(this),
            }"
          ></dtl-dynamic-component>
          <tsm-panel-pin
            [panelMode]="panelMode"
            (panelPushPinClick)="panelPushPinClick()"
          >
          </tsm-panel-pin>
        </div>
      </div>

      <div
        id="messages-panel"
        class="history-panel"
        [class.opened]="state.topbar.activeTopBarItem === 'messages'"
        [ngClass]="panelMode ? 'layout-static' : 'layout-overlay'"
      >
        <div class="content relative">
          <dtl-dynamic-component
            *ngIf="state.topbar.activeTopBarItem === 'messages'"
            [type]="notificationSideBarType$ | async"
            [inputs]="{
              notificationType: state.topbar.activeTopBarItem,
            }"
            [outputs]="{
              close: closeTopBar.bind(this),
            }"
          ></dtl-dynamic-component>
          <tsm-panel-pin
            [panelMode]="panelMode"
            (panelPushPinClick)="panelPushPinClick()"
          >
          </tsm-panel-pin>
        </div>
      </div>

      <div
        *ngLet="isFluentDebuggerOpened() as isFluentDebuggerOpened"
        id="debug-panel"
        class="history-panel"
        style="z-index: 10000"
        [style.transition]="'none'"
        [class.opened]="isFluentDebuggerOpened"
        [ngClass]="'layout-static'"
        tsmResizeObserver
        (resizeEnd)="resizeEnd($event)"
        [style.width.px]="isFluentDebuggerOpened ? rightPanelWidth : null"
        mwlResizable
        [mouseMoveThrottleMS]="1"
        [enableGhostResize]="true"
      >
        <div
          class="resize-handle-left"
          mwlResizeHandle
          [resizeEdges]="{left: true}"
        ></div>

        <div class="content relative">
          <dtl-dynamic-component
            *ngIf="isFluentDebuggerOpened"
            [type]="fluentDebuggerType$ | async"
          ></dtl-dynamic-component>
        </div>
      </div>
    </div>
  </ng-container>
  <!--  </div>-->
</ng-container>
