export const cs = {
  configFormComponents: {
    components: {
      remainingReqTsmControls:
        'Formulář nebyl uložen. Tyto základní atributy jsou povinné: {{data}}',
      invalidTsmControl:
        'Formulář nebyl uložen. Tyto základní atributy jsou špatně: {{data}}',
      checklist: {
        // presunout do plugins-i18n
        title: 'Seznam úkolů',
        tooltip: 'Seznam úkolů s možností nahrání úkolů z číselníku',
      },
      inCharacteristics: 'V charakteristikách',
      selectMicroservice: 'Vybrat microservice',
      placeholders: 'Základní atributy',
      dragToReorder: 'Přetažením změňte pořadí',
      editFavoriteName: 'Přejmenovat',
      addToFavorite: 'Přidat do oblíbených',
      deleteFavorite: 'Smazat z oblíbených',
      noFavoriteItemYet: 'Nemáte žádné oblíbené položky',
      dropToAddFavorite: 'Přetáhněte widget do oblíbených',
      favoriteAdded: 'Widget byl přidán do oblíbených',
      favoriteAlreadyExists: 'Widget již existuje v oblíbených',
      favoriteAddedDetail: "Widget '{{name}}' byl přidán do oblíbených",
      entityType: {
        title: 'Typ entity',
      },
      swagger: 'Swagger',
      swaggerRefreshToken:
        'Došlo k obnově přístupového tokenu. Provolejte prosím požadovaný dotaz ještě jednou.',
      tsmPublicApi: 'tSM Public API',
      defaultProfileTooltip: 'Základní profil pro všechny uživatele',
      uniqueIdentifier: 'Unikátní identifikace v rámci typu konfigurace.',
      characteristicsSorting: 'Logické členění charakteristik do skupin.',
      configTypeLabel: 'Typ konfigurace',
      mnemonicConfigLabel: 'Konfigurace mnemoniky',
      sequenceName: 'Název sekvence',
      createSequence: 'Vytvořit sekvenci',
      startValue: 'Počáteční hodnota',
      increment: 'Inkrement',
      sequence: 'Sekvence',
      dbbridge: 'dbBridge - reload',
      config: 'Konfigurace',
      configAdvanced: 'Rozšířená konfigurace',
      configAdvancedInfo:
        'Další konfigurace uložená v JSON formátu. Lze ji použít pro customizace, např. uložení konfiguračních atributů, ' +
        'podle kterých se bude řídit zpracování v microservice vytvořené na míru mimo jádro tSM.',
      formSelectHint:
        'Použitý formulář pro vykreslení. Pokud není zadán, provede se vykreslení s automatickým layoutem na základě aktuálních dat.',
      baseForm: 'Extenze základního formuláře',
      schemaLocationHint: `Ve které sekci cílového formuláře se má charakteristika zobrazit:
* Details - mezi hlavními položkami úvodu formuláře. Použijte pouze formuláře typu 'custom fields'
* Hlavní sekce - mezi hlavními rozbalovacími sekcemi v levé části formuláře
* Záložka - mezi záložkami
* Lidé, Datumy, ... - v příslušné sekci (pokud ji cílový modul obsahuje)
* Komentáře - slouží jako jedné z možných formulářů pro zadání komentáře
* Vytvoření záznamu - umístění na obrazovce pro vytvoření`,
      characteristics: {
        subtitleHint:
          'Titulek se zobrazuje v cílovém modulu jako název položky (custom field) nebo název sekce/tabu.',
        readPermission: 'Oprávnění pro čtení',
        editPermission: 'Oprávnění pro editaci',
        attributeNameHint:
          'Název atributu, který bude obsahovat informace o charakteristice v JSON formátu.',
        displayOrder: 'Pořadí zobrazení',
        positionHint:
          'Relativní pozice vůči ostatním charakteristikám stejného typu',
        isUnique: 'Je unikátní',
        configurable: 'Konfigurovatelný',
        required: 'Povinný',
        readonly: 'Pouze pro čtení',
        saveDraft: 'Tlačítko pro uložení jako náhled',
        extensible: 'Rozšiřitelný',
        usedInCharacteristics: 'Použito v konfiguračních profilech',
        characteristicsAdministration: 'Správa charakteristik',
        newCharacteristic: 'Nová charakteristika',
        attributeName: 'Název atributu v JSON',
        characteristicsList: 'Seznam charakteristik',
        indexMapping: 'ES typ mapování',
        indexMappingHint:
          'Bez indexu - charakteristika nebude zaindexována v elasticu\n' +
          'Základní - charakteristika bude v elasticu k dispozici v sekci "chars"\n' +
          'Včetně datových typů - charakteristika bude v elasticu k dispozici v sekci "charsTyped" , dle definice charakteristiky se v indexu vytvoří odpovídající mapování umožňující vyhledávání, řazení atd.',
      },
      characteristicsValue: {
        valueAdministration: 'Správa hodnoty charakteristiky',
        valueName: 'Název hodnoty',
        unit: 'Jednotka',
        range: 'Rozsah',
        isDefault: 'Je výchozí',
        characteristicValues: 'Charakteristické hodnoty',
      },
      configType: {
        editConfigType: 'Upravit typ konfigurace',
        createConfigType: 'Vytvořit typ konfigurace',
        sortingByModule: 'Logické členění dle modulů TSM.',
        configTypeList: 'Seznam typů konfigurací',
        syncMethod: 'Metoda synchronizace mezi prostředími',
      },
      microservice: {
        title: 'Microservice',
        editMicroservice: 'Upravit microservice',
        createMicroservice: 'Vytvořit microservice',
        backendUrl: 'URL microservice',
        microserviceList: 'Seznam mikroservis',
        deleteMicroserviceConfirmation: 'Opravdu chcete smazat vybraný záznam?',
        tooltip:
          'Vyberte mikroservice, která je zodpovědná za spouštění dotazů a transformaci dat v tomto listingu',
        environmentalVariables: 'Proměnné prostředí',
        flyway: 'Flyway',
        threadDump: 'Thread dump',
        scheduledTasks: 'Naplánované úlohy',
        installedBy: 'Instaloval',
        installedOn: 'Instalováno',
        installedRank: 'Pořadí instalace',
        executionTime: 'Čas spuštění',
        checksum: 'Kontrolní součet',
      },
      listingProfile: {
        editValueConverterConfig: 'Editovat konvertor hodnot',
        createValueConverterConfig: 'Vytvořit konvertor hodnot',
        title: 'Listing Profile',
        editListingProfile: 'Upravit listing profile',
        createListingProfile: 'Vytvořit listing profile',
        listingProfileList: 'Seznam profilů listingu',
        customError: 'Pořadí řazení musí nabývat hodnot -1 nebo 0 nebo 1',
        config: 'Konfig',
        tqlName: 'TQL název',
        tqlNameTooltip:
          'TQL název tohoto listingu, je používán k identifikaci listingu v jazyku TQL',
        exportStatus: 'Status exportu',
        nextStart: 'Další spuštění',
        profileCategory: 'Kategorie profilu',
      },
      myListingProfiles: {
        title: 'Moje profily listingů',
      },
      backupSet: {
        title: 'Soubory záloh',
        editBackupSet: 'Upravit soubor zálohy',
        createBackupSet: 'Vytvořit soubor zálohy',
        saveDiffSuccess: 'Záloha úspěšně obnovena.',
        saveDiffError: 'Obnovení souboru záloh selhalo.',
        diffResult: 'Výsledek porovnání souboru záloh',
        backupSetName: 'Název konfigurace souboru záloh',
        backupSetCode: 'Kód konfigurace souboru záloh',
        exportedBy: 'Exportoval',
        whenExported: 'Exportováno',
      },
      listingType: {
        title: 'Listing Type',
        editListingType: 'Upravit listing type',
        createListingType: 'Vytvořit listing type',
        listingTypeList: 'Seznam typů listingu',
        configType: 'Typ konfigurace',
        elasticSearchIndex: 'Název Elasticsearch indexu',
        elasticSearchIndexTooltip:
          'Existující Elasticsearch index nebo alias. Každý listing dotaz je přeložen na Elasticsearch DSL Query pomocí tohoto názvu indexu',
        backendUrl: 'Backend URL',
        backendUrlTooltip: 'URL backendu tohoto listingu (REST zdroj dat).',
        frontendUrl: 'Frontend URL',
        frontendUrlTooltip:
          'URL adresa pro zobrazení listingu na UI. Dále např. slouží pro sestavení adresy na zobrazení dat z reportingu.',
        tqlName: 'TQL název',
        tqlNameTooltip:
          'TQL název tohoto listingu, je používán k identifikaci listingu v jazyku TQL',
        customTitle: 'Název listingu',
        customTitleTooltip: 'Název listingu, který se zobrazí v jeho záhlaví',
        defaultFilter: 'Defaultní podmínka',
        defaultFilterTooltip:
          'Možnost zadat defaultní podmínku, která se aplikuje na všechny záznamy (stejně jako access rules).',
        parentPrivilege: 'Oprávnění pro editaci input widget ',
        parentPrivilegeTooltip:
          'Jedná se o oprávnění, které se používá pro přímou editaci v buňce tabulky (např.: Crm.Customer.Edit => Crm.Customer.Edit#name)',
        massEditing: 'Povolit hromadnou editaci',
        massEditingTooltip:
          'Jedná se o povolení hromadné editace více vybraných záznamů.',
        massEditingConfiguration: 'Konfigurace hromadné editace',
        massEditingConfigurationInfo:
          'Pokročilé nastavení hromadné editace, při kterém lze nastavit vzhled tlačítek, SPEL skript a formulář, který se následně aplikuje na vybrané záznamy.',
        defaultListingType: 'Defaultní typ listingu',
        defaultListingTypeTooltip:
          'Právě jeden Typ listingu musí být nastaven jako defaultní v rámci Typu entity. Pokud není nastaven, nejsou k dispozici některé speciální funkce pro zobrazení detailu záznamu (např. zobrazení změn v backup/restore).',
      },
      valueConverterConfig: {
        title: 'Konfigurace konvertoru hodnot',
        edit: 'Upravit konfiguraci konvertoru hodnot',
        create: 'Vytvořit konfiguraci konvertoru hodnot',
        backendBeanName: 'Background bean name',
        paramsFormCode: 'Params form code',
        cacheType: 'Typ cache',
      },
      tsmmodule: {
        title: 'Tsm Module',
        editTsmmodule: 'Upravit tsm module',
        createTsmmodule: 'Vytvořit tsm module',
        tsmModuleList: 'Seznam TSM modulů',
        deleteTsmModuleDialog:
          'Opravdu chcete smazat vybraný záznam? Může na něj být odkazováno z jiné části aplikace a tyto odkazy přestanou po smazání fungovat.',
        jsonParserError:
          'Nepodařilo se rozdělit soubor typu JSON. Prosím zkontrolujte, že zadaná hodnota je správný objekt typu JSON',
      },
      entitySpecifications: {
        newCharacteristicsGroup: 'Nový konfigurační profil',
        specificationsList: 'Seznam specifikací',
        allowedRelationships: 'Povolené vazby',
        allowedRelationshipsInfo:
          'Tato konfigurace umožňuje omezit jaké vazby lze na tento typ vytvářet. Pokud není vyplněno, lze záznam vytvořit bez typ ' +
          'komentáře / přílohy / worklogu / notifikací. Můžete vynutit výběr typu nebo nastavit defaultní typ, lze i přímo vyjmenovat povolené typy. ' +
          'Pro zobrazení komentářů / přílohy / worklogy / notifikace na detailním formuláři použijte příslušné widgety v tSM Form Designeru.',
      },
      mnemonicConfig: {
        editMnemonicConfig: 'Editace mnemoniky',
        createMnemonicConfig: 'Vytvoření mnemoniky',
        mnemonicConfigList: 'Seznam mnemonik',
        deleteMnemonicConfigConfirmation:
          'Opravdu chcete smazat vybraný záznam? Může na něj být odkazováno z jiné části aplikace a tyto odkazy přestanou po smazání fungovat.',
      },
      listingColumn: {
        listingColumnList: 'Seznam sloupců listingu',
      },
      editgrid: {
        footer: 'Patička',
        header: 'Hlavička',
        addHeaderRow: 'Přidat řádek hlavičky',
        addHeaderRowTooltip:
          'Kliknutím na tlačítko "Přidat řádek hlavičky" přidáte řádek',
        addFooterRow: 'Přidat řádek patičky',
        addFooterRowTooltip:
          'Kliknutím na tlačítko "Přidat řádek patičky" přidáte řádek',
        addCell: 'Přidat buňku',
        colspan: 'colspan',
        rowspan: 'rowspan',
        width: 'šířka',
        fractionDigits: 'desetinná místa',
      },
      entitySpecificationCharacteristic: {
        transformation: 'Transformace',
        settingTransformation: 'Nastavení transformace',
        regexForValidation: 'Regulární výraz pro validaci',
      },
      form: {
        applicableUnits: 'Použitelné jednotky',
        key: 'Klíč',
        variable: 'Proměnná',
        toolbar: 'Toolbar',
        pageHeader: 'Hlavička stránky',
        header: 'Header',
        back: 'Přesměrovat zpátky',
        undo: 'Zpět',
        undoTooltip2: 'Posunout změny zpět',
        redo: 'Vpřed',
        reset: 'Smazat obsah formuláře',
        redoTooltip2: 'Posunout změny vpřed',
        resetTooltip:
          'Tlačítko přepíše aktuální schéma a vloží do něj implicitní hodnotu',
        formDesigner: 'Form designer', // nechat anglicky
        formPreview: 'Náhled formuláře',
        savedData: 'Uložená data (JSON)',
        formType: 'Typ formuláře',
        newForm: 'Nový formulář',
        sourceCodeJson: 'Zdrojový kód dat (json)',
        schema: 'Schéma',
        schemaGenerator: 'Generátor schéma',
        fullscreenInTooltip: 'Zobrazit formulář přes celou obrazovku',
        fullscreenOutTooltip:
          'Ukončit režim zobrazení přes celou obrazovku (Esc)',
        undoTooltip: 'Vrátit zpět poslední akci',
        undoShortcut: 'Ctrl+z',
        formSorting: 'Logické členění formulářů do skupin.',
        listing: 'Zobrazit pole v listingu',
        innerForm: 'Vložený formulář',
        formCode: 'Form code',
        formCategoryHint: `Určení formuláře:
* Form - standardní zobrazení jako hlavní sekce nebo tab.
* Dialog - zobrazení v modálním okně a labely se zobrazují nad textem.
* Custom field - typicky zobrazení jedné položky bez labelu pro vložení do detailní sekce (titulek se bere z charakteristiky)
* Dashboard - formulář je určen k zobrazení jako dashboard`,
        favoriteComponents: 'Oblíbené widgety',
        addToFavorites: 'Přidat do oblíbených',
        pinItem:
          'Udržuje kategorii otevřenou i v případě, že se rozbalují jiné kategorie.',
        removeFromFavorites: 'Odebrat z oblíbených',
        basicComponents: 'Základní komponenty',
        basicInfo: 'Základní informace',
        customClass: 'Vlastní css třída',
        textField: 'Textová položka',
        displayTextField: 'Text k zobrazení',
        maskField: 'Maska',
        mask: 'Maska',
        phoneField: 'Telefon',
        placeholder: 'Popis',
        numberField: 'Číselné pole',
        inputIconPicker: 'Výběr ikony',
        textArea: 'Text Area', // nechat anglicky
        longText: 'Dlouhý text',
        checkbox: 'Check Box', // nechat anglicky
        checkboxLabel: 'Checkbox',
        triStateCheckboxLabel: 'Třístavový checkbox',
        switchLabel: 'Switch',
        leftLabel: 'Levý popisek',
        rightLabel: 'Pravý popisek',
        dateLabel: 'Datum',
        dateTimeLabel: 'Datum a čas',
        minDate: 'Min datum',
        maxDate: 'Max datum',
        select: 'Výběr',
        submitButton: 'Tlačítko potvrzení',
        submitRequestButton: 'Tlačítko potvrzení s požadavkem',
        mergeRemoteAndLocalData: 'Sloučit ručně zadané data s dotaženými daty',
        remoteData: 'Výraz pro dotažení dat',
        remoteFieldTitle: 'Název atributu pro sestavení názvu hodnoty',
        remoteFieldConst: 'Název atributu pro sestavení hodnoty',
        dataComponents: 'Datové komponenty',
        register: 'Číselník',
        userRegister: 'Uživatelský číselník',
        userRegisterListing:
          'Pokročilé - Seznam hodnot uživatelského číselníku',
        userChoice: 'Výběr uživatele',
        personChoice: 'Výběr osoby',
        userLink: 'Odkaz na zákazníka',
        contractLink: 'Odkaz na smlouvu',
        contract: 'Account',
        orderLink: 'Odkaz na objednávku',
        leadLink: 'Odkaz na lead',
        leadsByOrder: 'Leady dle objednávky',
        assignPreventiveActions: 'Přiřazení preventivních akcí k typu zařízení',
        dataView: 'Data view komponenta',
        tsmComponents: 'Komponenty TSM',
        tasks: 'Úkoly',
        taskList: 'Seznam úkolů',
        ticketList: 'Seznam tiketů',
        ticketsBySeverityGraphs: 'Tickety dle severity (grafy)',
        ticketsBySeverityCards: 'Tickety dle severity (karty)',
        wfmByWorkResourceGraphs: 'WFM dle work resource (charts)',
        wfmByTaskGraphs: 'WFM dle task (charts)',
        solvedTickets: 'Tikety přijaté/vyřešené',
        layout: 'Rozložení',
        page: 'Stránka',
        toColumns: 'Do sloupců',
        htmlContent: 'HTML Obsah',
        html: 'Html',
        help: 'Help',
        textHint: 'Textová nápověda',
        content: 'Obsah',
        sourceCode: 'Zdrojový kód',
        iframe: 'Externí obsah',
        iframeTooltip: 'Externí obsah - jedná se o IFRAME.',
        saveForm: 'Uložit formulář',
        deleteFormData: 'Smazání dat náhledu formuláře',
        componentDeletion: 'Smazání komponenty rozložení',
        favoriteWidgetDeletion: 'Smazat oblíbený widget?',
        favoriteWidgetDeletionMessage: 'Tato akce je nevratná.',
        duplicateVariable: 'Zadaný název proměnné už ve formuláři existuje.',
        deleteLayoutWidget:
          'Komponenta rozložení bude odstraněna včetně komponent, které obsahuje. Chcete pokračovat?',
        requiredValue: 'Tato hodnota je povinná',
        valueMinLength: 'Hodnota nemá minimální požadovanou délku',
        valueMaxLength: 'Hodnota překračuje maximální povolenou délku',
        hideTitle: 'Skrýt název',
        titlePosition: 'Pozice názvu',
        inputType: 'Typ inputu',
        viewTime: 'Zobrazit čas',
        monthYear: 'Měsíc a rok',
        isoFormat: 'ISO formát',
        htmlCode: 'Html kód',
        shownAttribute: 'Zobrazený atribut (key, name, subject, ...)',
        frame: 'Rámeček',
        background: 'Podbarvení',
        columnWidth: 'Šířka sloupce',
        offset: 'Offset', // nechat anglicky
        addColumn: 'Přidat další sloupec',
        tabName: 'Název záložky',
        deleteTab: 'Smazat záložku',
        addTab: 'Přidat další záložku',
        optionalInputClass: 'Volitelná CSS třída inputu',
        optionalDescriptionClass: 'Volitelná CSS třída popisu',
        moreValues: 'Více hodnot',
        readOnly: 'Pouze pro čtení (readonly)',
        persistent: 'Persistent',
        disabled: 'Zakázat (disabled)',
        addButtons: 'Přidat tlačítko',
        contextButton: 'Kontextové tlačítko',
        localeFormatting: 'Formátování podle locale',
        localeFormattingEnabled: 'Zapnuto',
        localeFormattingDisabled: 'Vypnuto',
        hidden: 'Skrytý (hidden)',
        listingSettings: 'Nastavení listingu',
        defaultRowsCount: 'Výchozí počet řádků',
        tableProfile: 'Profil tabulky',
        viewExport: 'Zobrazit export',
        viewFilter: 'Zobrazit filtr',
        showColumnManager: 'Zobrazit správce sloupců',
        showProfileSelection: 'Zobrazit výběr profilu',
        showResetButton: 'Zobrazit reset tlačítko',
        defaultValue: 'Výchozí hodnota',
        selectionItems: 'Položky výběru',
        const: 'Const',
        addItem: 'Přidat další položku',
        ownerId: 'Id vlastníka',
        ownerType: 'Typ vlastníka',
        ticketId: 'Id tiketu',
        selectedUsers: 'Vybraní uživatelé',
        remove: 'Odebrat',
        selectedGroups: 'Vybrané skupiny',
        validation: 'Validace',
        errorMessageRequired: 'Vlastní chybová zpráva (povinné pole)',
        requiredValidating: 'Výraz pro vypnutí/zapnutí validace',
        errorMessage: 'Vlastní chybová zpráva při porušení patternu',
        patternValidating: 'Výraz pro vypnutí/zapnutí validace patternu',
        customValidation: 'Výraz pro vlastní validaci',
        customValidations: 'Vlastní validace',
        customValidationValidating:
          'Výraz pro vypnutí/zapnutí vlastní validace',
        customValidationMessage: 'Vlastní chybová zpráva (vlastní validace)',
        minValue: 'Minimální hodnota (vč.)',
        maxValue: 'Maximální hodnota (vč.)',
        fractionDigits: 'Počet desetinných míst',
        minLength: 'Min. délka',
        minLengthValidating:
          'Výraz pro vypnutí/zapnutí validace minimální hodnoty',
        minLengthErrorMessage:
          'Min. délka musí být menší nebo rovna max. délce.',
        minLengthError: 'Vlastní chybová zpráva (min. délka)',
        maxLength: 'Max. délka',
        maxLengthValidating: 'Výraz pro vypnutí/zapnutí validace max. hodnoty',
        maxLengthErrorMessage:
          'Max. délka musí být větší nebo rovna min. délce.',
        maxLengthError: 'Vlastní chybová zpráva (max. délka)',
        svg: 'SVG',
        bar: 'Tlačítková lišta',
        buttonMenu: 'Tlačítkové menu',
        diagramCreation: 'Vytvoření diagramu',
        drawIo: 'Draw IO',
        heightPx: 'Výška (px)',
        widthPx: 'Šířka (px)',
        popupHeight: 'Výška popup okna (px)',
        popupWidth: 'Šířka popup okna (px)',
        src: 'Zdrojová URL',
        listingType: 'Typ listingu',
        topEdge: 'Horní okraj',
        rightEdge: 'Pravý okraj',
        bottomEdge: 'Dolní okraj',
        leftEdge: 'Levý okraj',
        incorrectFormat:
          'Chybný formát, zadejte validní css atribut (např. 10px, .5em, 5%)',
        fieldEdit: 'Editace pole',
        variableName: 'Název proměnné',
        customLinkTitle: 'Vlastní název odkazu',
        buttonType: 'Typ buttonu',
        customCssClass: 'Volitelná CSS třída stylu',
        customCssClassHelpMessage: 'CSS třída pro změnu stylu komponenty.',
        customIconCssClass: 'Volitelná CSS třída ikony',
        customIconCssClassHelpMessage:
          "CSS třída pro zobrazení ikony. Příklad: 'p-icon-check'",
        linkTarget: 'Cíl odkazu',
        queryParams: 'Query parametry',
        linkUrl: 'Adresa odkazu',
        postUrl: 'Post url',
        getActionDownloadFile: 'Get request pro stažení souboru',
        getUrl: 'Get url',
        postAction: 'Post request',
        postActionDownloadFile: 'Post request pro stažení souboru',
        getAction: 'Get request',
        ngrxAction: 'Ngrx akce',
        ngrxActionAfterPost: 'Ngrx akce s daty z response',
        closeDialog: 'Zavřít dialog po odeslání',
        formTitle: 'Titulek formuláře',
        formContext: 'Kontext formuláře',
        messageType: 'Typ zprávy',
        messageText: 'Text zprávy',
        successMessage: 'Zpráva po úspěšném provedení requestu',
        materialMovements: 'Pohyb materiálu',
        multimediaDsl: 'Multimédia DSL',
        internetDsl: 'Internet DSL',
        access: 'Přístup',
        activationDetail: 'Detail aktivace',
        kzList: 'Seznam KZ',
        formSettings: 'Nastavení formuláře',
        formWidth: 'Šířka formuláře (px)',
        formHeight: 'Výška formuláře (px)',
        advancedComponents: 'Rozšířené komponenty',
        tsmControl: 'Tsm Control',
        tsmControlTooltip:
          'Tsm Control - slouží pro pozicování statických komponent form-field.',
        tsmCharacteristic: 'Tsm Characteristic',
        tsmCharacteristicTooltip:
          'Tsm Characteristic - slouží pro vložení charakteristiky.',
        chart: 'Rozložení - Graf',
        array: 'Pole',
        accordionArray: 'Pole akordeonů',
        palette: 'Paleta',
        visibleDropAreas: 'Viditelné oblasti přesouvání',
        tooltip: {
          listingComponents: {
            userRegisterListing:
              'Zobrazí seznam hodnot vybraného uživatelského číselníku',
            basicInfo:
              'Komponenta umožňuje definovat dodatečné atributy, vlastní layout formuláře, povolené typy komentářů apod. ' +
              'Konfigurační profily mohou být pro zjednodušení sdílené mezi více typy.',
            listingProfileLov: `Komponenta umožňuje výběr typu profilu seznamu, je možné filtrovat pomocí inputu pro pohodlnější výběr žádané hodnoty.`,
            listingColumns: `Komponenta umožňuje výběr typu sloupce, je možné filtrovat pomocí inputu pro pohodlnější výběr žádané hodnoty.`,
            configFormType:
              'Komponenta umožňuje výběr typu konfiguračního formuláře, je možné filtrovat pomocí inputu pro pohodlnější výběr žádané hodnoty.',
            userRegister:
              'Komponenta umožňuje výběru z číselníku, je možné filtrovat pomocí inputu pro pohodlnější výběr žádané hodnoty.',
            innerForm:
              'Komponenta umožňující vložit jiný formulář, který se stane součástí obsahu tohoto formuláře.',
          },
          basicComponents: {
            monacoDiffEditor:
              'Monaco Diff Editor je obdobou Monaco Editoru, ale nabízí možnost porovnání obsahu dvou editorů, které se zobrazí vedle sebe.',
            favoriteComponents: `<p>Oblíbené widgety umožňují rychlý přístup k často používaným komponentám, včetně zanořených prvků. Díky tomu si usnadníte práci a urychlíte návrh formulářů.</p><h5 class="m-0 mt-3 mb-2">Jak přidat položku do oblíbených?</h5><ul><li><strong>Přetažením</strong> – jednoduše přetáhněte komponentu z plochy designeru do sekce oblíbených.</li><li><strong>Akční ikonou</strong> – klikněte na ikonu vedle názvu komponenty v paletě komponent.</li></ul><h5 class="m-0 mt-3 mb-2">Jak spravovat oblíbené položky?</h5><p>Uložené widgety můžete kdykoli přeuspořádat podle svých potřeb. Stačí je v seznamu oblíbených přetáhnout na požadovanou pozici.</p><ul><li><strong>Přejmenování</strong> – kliknutím na ikonu tužky vedle názvu můžete libovolnou položku přejmenovat.</li><li><strong>Odstranění</strong> – kliknutím na ikonu křížku položku odstraníte z oblíbených.</li></ul>`,
            basicComponents:
              'Základní komponenty jsou sbírkou běžně používaných komponent, které umožňují shromažďování a zobrazování dat. ' +
              'Uživatel přímo interaguje s komponentami a výsledná data se ukládají do databáze. ' +
              'Vlastnosti komponent lze nastavit pomocí editačního widgetu, jako například maximální délka vstupu do textového pole. ' +
              'Zahrnují komponenty, jako je číselné pole, checkbox nebo button.',
            textField:
              'Základní komponenta pro vkládání obecného textu. Volitelně můžete nakonfigurovat ověřování, masky a vizuální chování. Validace zajišťuje, že zadaná data splňují určitá kritéria, jako je určitá délka nebo specifický formát. Maska se používá k formátování textu při jeho zadávání, jako je přidání pomlček k telefonnímu číslu nebo pomlček k číslu kreditní karty. To pomáhá zlepšit uživatelskou zkušenost tím, že poskytuje jasná očekávání ohledně typu dat, která by měla být zadána, a zajišťuje, že data jsou zadávána v konzistentním formátu.',
            variable:
              'Proměnná je komponenta, která umožňuje vložit proměnnou do formuláře. Proměnná je hodnota, která je uložena v objektu $value a lze ji použít ve formuláři.',
            numberField:
              'Číselné pole je vstupní pole ve formuláři, který se používá ke sběru číselného vstupu od uživatele. Pole je obvykle formátováno tak, aby přijímalo pouze čísla, a lze jej dále upravit tak, aby omezovalo typ přijatých čísel. Pole může být například nastaveno tak, aby přijímalo pouze kladná čísla, celá čísla nebo čísla v určitém rozsahu. Ověření lze také použít na číselné pole, aby se zajistilo, že zadaná data splňují určitá kritéria, jako je například určitý rozsah nebo určitý počet desetinných míst. Použití číselného pole pomáhá zajistit, že shromážděná data jsou přesná a konzistentní, a také usnadňuje zpracování dat v následných aplikacích.',
            inputIconPicker:
              'Výběr ikony je vstupní pole ve formuláři, který se používá ke výběru ikony zobrazenou vedle vstupního pole formuláře. Ikona bude umístěna po levé nebo pravé straně pole formuláře.',
            textArea:
              'Textová oblast je víceřádkové textové vstupní pole ve formuláři, které se používá ke shromažďování velkého množství textových dat od uživatele. Na rozdíl od běžného textového pole může textová oblast pojmout více řádků textu, takže je vhodná pro shromažďování delších vstupních dat, jako jsou popisy, komentáře nebo zpětná vazba. Použití textové oblasti pomáhá uživatelům usnadnit zadávání a čtení velkého množství textových dat a zároveň poskytuje způsob, jak zajistit, aby shromážděná data byla přesná a konzistentní. Pokud chcete, aby uživatel zadal Rich text (jako je nadpis, barvy, velikost písma, tloušťka písma atd., použijte Rich text',
            richTextEditor:
              'Pole formátovaného textu(také nazýváno Rich text), známé také jako editor WYSIWYG (What You See Is What You Get), je typ pole pro zadávání textu ve formuláři, který uživatelům umožňuje zadávat a formátovat text různými styly, jako je tučné písmo, kurzíva a zdůraznit. Tento typ textového pole je vhodný pro shromažďování informací, které vyžadují vizuální formátování, jako jsou popisy nebo komentáře. Pole formátovaného textu mohou také zahrnovat ověření, aby se zajistilo, že zadaná data splňují určitá kritéria, jako je minimální nebo maximální délka. Použití pole s formátovaným textem pomáhá zlepšit uživatelskou zkušenost tím, že uživatelům umožňuje vytvářet vizuálně přitažlivý a dobře formátovaný text a zároveň poskytuje způsob, jak zajistit, aby shromážděná data byla přesná a konzistentní.',
            checkbox:
              'Zaškrtávací pole je typem vstupního pole, které umožňuje uživatelům vybrat pouze zaškrtnuté nebo nezaškrtnuté hodnoty. Zaškrtávací pole je zobrazeno jako malý čtverec, který lze zaškrtnout nebo odškrtnout kliknutím na něj. Zaškrtávací pole je spojeno s popiskem, který popisuje možnost, kterou představuje. Použití zaškrtávacích políček pomáhá zjednodušit proces sběru informací tím, že uživateli předkládá jasný seznam možností k výběru, zatímco zároveň umožňuje flexibilní a vícestranný sběr dat.',
            triStateCheckbox:
              'Tri-state checkbox je prvek GUI se třemi možnými stavy: zaškrtnutý, nezaškrtnutý a neurčitý. Neurčitý stav se používá, když stav zaškrtávacího políčka závisí na podřízených zaškrtávacích políčkách nebo specifických kritériích. Běžně se používá v hierarchických datech nebo skupinách souvisejících zaškrtávacích políček. Chování třístavového zaškrtávacího políčka mohou vývojáři upravit tak, aby vyhovovalo potřebám jejich aplikace.',
            switch:
              'Switch je typ vstupního pole ve formuláři, který umožňuje uživatelům přepínat mezi dvěma stavy, jako je zapnuto/vypnuto, ano/ne nebo pravda/nepravda. Switch je reprezentován jako malý obdélníkový přepínač, který lze posunout doleva nebo doprava pro změnu jeho stavu. Často je používán jako kompaktnější alternativa k zaškrtávacím políčkům pro binární rozhodování a lze jej také použít místo radio tlačítek pro výběr s jednou volbou. Použití Switchů pomáhá zjednodušit uživatelské rozhraní tím, že poskytuje jasný a intuitivní způsob provádění binárních výběrů a zároveň umožňuje kompaktnější a esteticky příjemnější formuláře.',
            dateTime:
              'Pole datum a čas je vstupní pole ve formuláři, které se používá ke shromažďování informací o datu a čase od uživatele. Pomocí konfigurace vyberte, zda má uživatel zadávat části Datum a/nebo Čas. Pole nabízí uživateli rozhraní ve stylu kalendáře, což usnadňuje výběr konkrétního data a času. Pole data mohou zahrnovat ověření, aby se zajistilo, že zadaná data splňují specifická kritéria, jako je například to, že jsou v určitém rozsahu nebo jsou ve formátu platného data.',
            rating:
              'Pole hodnocení je vstupní pole ve formuláři, které uživatelům umožňuje hodnotit nebo ohodnotit něco na číselné stupnici. Pole reprezentované řadou hvězdiček, z nichž na každou lze kliknout nebo vybrat pro označení konkrétního hodnocení. Hodnotící pole lze použít ke shromažďování zpětné vazby od zákazníků, recenzí produktů nebo jiných forem hodnocení. Mohou být také použity ke shromažďování informací o osobních preferencích, jako je hodnocení filmů nebo recenze knih. Použití pole hodnocení pomáhá zjednodušit proces shromažďování číselných dat tím, že poskytuje jasný a intuitivní způsob výběru a zároveň umožňuje flexibilní a mnohostranný sběr dat.',
            link: 'Odkazové pole je vstupní pole ve formuláři, které umožňuje uživatelům zadávat odkazy, jako jsou URL adresy nebo e-mailové adresy. Pole je zobrazeno jako textové pole, do kterého uživatel může psát nebo vložit odkaz. Použití odkazového pole je zejména užitečné pro sběr informací o online zdrojích, jako jsou webové stránky, online profily nebo články.',
            selectField:
              'Výběrové pole, také známé jako rozbalovací nabídka, je vstupní pole ve formuláři, které umožňuje uživatelům vybrat volbu z předdefinovaného seznamu možností. Pole je obvykle zobrazeno jako rozbalovací nabídka, ze které může uživatel vybrat jednu možnost. Použití výběrového pole pomáhá zjednodušit proces sběru dat, poskytnutím jasného a stručného způsobu výběru uživatelem. Pole také pomáhá zajistit přesnost a konzistenci shromážděných dat omezením dostupných možností na předem definovanou sadu hodnot. Výběrová pole lze použít ke sběru široké škály informací, jako jsou geografická umístění, názvy pracovních míst, kategorie produktů nebo jakékoliv jiné informace, které lze reprezentovat souborem předem definovaných možností. Pokud potřebujete vybírat z dat založených na TSM, použijte vhodnou komponentu, jako jsou "Uživatelský číselník", "Segment zákazníka" nebo "Účet" (Register value, Customer segment, Account).',
            autocompleteField:
              'Pole automatického doplňování, také známé jako funkce "autocomplete", je interaktivní vstupní prvek ve formuláři, který poskytuje uživatelům okamžité návrhy pro dokončení jejich zadání založené na předchozích vstupech nebo předdefinované sadě možností. Jakmile uživatel začne psát do pole, systém dynamicky generuje a zobrazuje seznam relevantních návrhů, z nichž uživatel může vybrat, což mu umožňuje rychle a efektivně dokončit své zadání bez nutnosti psát celý text.',
            autocomplete:
              'HTML autocomplete input je formulářový prvek, který předpovídá a zobrazuje seznam návrhů, jak uživatel píše, což usnadňuje rychlejší a přesnější zadávání.',
            userRegister:
              'Vyberte hodnotu z číselníku nakonfigurovaného v tSM modulu Konfigurace & formuláře. Na rozdíl od "Výběrového pole" jsou k dispozici hodnoty nakonfigurované v opakovaně použitelném vlastním číselníku (někdy nazývaném také jako vyhledávací tabulka). Pole je zobrazeno jako seznam hodnot, ze kterého uživatel může vybrat požadovanou hodnotu. Hodnoty lze vyhledávat a vybranou hodnotu lze zobrazit nebo upravit v novém okně.',
            codeEditor:
              'Pole editoru kódu je vstupní pole ve formuláři, které se používá ke shromažďování a zobrazování kódu, obvykle pro účely zobrazení aplikačních logů, rozšířené konfigurace nebo nastavení systémové komunikace třetí strany. Pole je reprezentováno pokročilým editorem kódu, který poskytuje další funkce, jako je zvýrazňování syntaxe, dokončování kódu a skládání.',
            password:
              'Pole hesla je vstupní pole ve formuláři, které se používá ke shromažďování citlivých informací, jako je heslo nebo passphrase. Pole je reprezentováno textovým polem, ale znaky zadané do pole jsou skryty a nahrazeny hvězdičkami nebo jinými symboly, aby byla chráněna důvěrnost informací. Pole hesla také obsahuje další bezpečnostní funkce, jako je měřič síly hesla.',
            maskField:
              'Pole maska je vstupní pole, které používá předdefinovaný formát nebo vzor k vedení uživatelů při zadávání dat. Formát může obsahovat symboly nebo zástupné znaky označující požadovaný formát, jako je datum, telefonní číslo nebo číslo kreditní karty. Pole automaticky použije formátování, když uživatelé zadávají data, což usnadňuje zadávání přesných a konzistentních dat. To pomáhá omezit chyby a zlepšit kvalitu dat shromážděných ve formuláři.',
            expressionEditor:
              'Editor výrazů je vstupní pole, které se používá k zadání výrazu SPEL nebo JEXL a jeho přidání do formuláře.',
            object:
              'Objekt je datová struktura, která obsahuje sadu pojmenovaných vlastností, z nichž každá má svou hodnotu. Typ pole objektu se obvykle používá ve formulářích ke shromažďování dat, která jsou organizována do sady souvisejících vlastností. Pole může být reprezentováno kolekcí vstupních polí, jako jsou textová pole, zaškrtávací pole nebo rozevírací nabídky, z nichž každé se používá ke shromažďování konkrétní vlastnosti objektu. Použití typu pole objektu pomáhá zjednodušit proces zadávání dat tím, že poskytuje jasný a organizovaný způsob shromažďování souvisejících datových prvků. Pole lze také použít k vynucení datových omezení nebo k ověření dat zadaných do formuláře pomocí vestavěných ověřovacích pravidel nebo vlastní ověřovací logiky. tSM Forms Designer vytváří obojí – vizuální uživatelské rozhraní pro uživatele a základní datovou strukturu. Možná budete muset použít typ objektu k vytvoření složitého schématu JSON pro komunikaci se systémy třetích stran. Další podrobnosti najdete na ' +
              '<a href="https://json-schema.org/understanding-json-schema/reference/object.html#object" target="_blank" rel="noreferrer">https://json-schema.org/understanding-json-schema/reference/object.html#object</a>',
            array:
              'Pole je typ vstupního pole používaného ke shromažďování a ukládání dat ve formě pole. Pole je datová struktura, která obsahuje uspořádanou kolekci prvků, kde každý prvek může být libovolného datového typu. Pole může být reprezentováno kolekcí vstupních polí, jako jsou textová pole, zaškrtávací pole nebo rozevírací nabídky, z nichž každé se používá ke shromáždění jednoho prvku v poli. Prvek pole může být také komplexní objekt navržený pomocí typu pole Objekt. Možná budete muset použít typ objektu k vytvoření složitého schématu JSON pro komunikaci se systémy třetích stran. Další podrobnosti najdete na ' +
              '<a href="https://json-schema.org/understanding-json-schema/reference/array.html" target="_blank" rel="noreferrer">https://json-schema.org/understanding-json-schema/reference/array.html</a>',
            accordionArray:
              'Pole je typ vstupního pole používaného ke shromažďování a ukládání dat ve formě pole. Pole je datová struktura, která obsahuje uspořádanou kolekci prvků, kde každý prvek může být libovolného datového typu. Pole může být reprezentováno kolekcí vstupních polí, jako jsou textová pole, zaškrtávací pole nebo rozevírací nabídky, z nichž každé se používá ke shromáždění jednoho prvku v poli. Prvek pole může být také komplexní objekt navržený pomocí typu pole Objekt. Možná budete muset použít typ objektu k vytvoření složitého schématu JSON pro komunikaci se systémy třetích stran. Každý prvek pole je obalen akordeonem s možností rozkliknutí. Další podrobnosti najdete na ' +
              '<a href="https://json-schema.org/understanding-json-schema/reference/array.html" target="_blank" rel="noreferrer">https://json-schema.org/understanding-json-schema/reference/array.html</a>',
            monacoEditor:
              'Monaco Editor je výkonný editor kódu, který nabízí pokročilé editační funkce, včetně zvýrazňování syntaxe, dokončování kódu a skládání kódu, které uživatelům pomáhají psát kód efektivněji. Editor také poskytuje podporu pro více programovacích jazyků.',
            button:
              'Prvek tlačítko je komponenta interaktivního uživatelského rozhraní, která se používá ke spuštění akce, jako je odeslání formuláře nebo zahájení konkrétní úlohy. V tSM najdete několik typů tlačítek s širokou možností konfigurace. Mohou být také stylizovány a označeny.',
            submitButton:
              'Tlačítko pro odeslání je interaktivní komponenta uživatelského rozhraní, která se používá ke spuštění události odeslání formuláře. Tlačítko obsahuje hodnotu formuláře, ke které mohou přistupovat další komponenty. Po stisknutí tlačítka se uloží do stavu tlačítka specifikovaná hodnota, přes kterou lze přistupovat přes expression ($value.requestButton).',
            submitRequestButton:
              'Tlačítko pro odeslání je interaktivní komponenta uživatelského rozhraní, která se používá ke spuštění události odeslání formuláře. Tlačítko obsahuje hodnotu formuláře, ke které mohou přistupovat další komponenty. Tlačítko vždy posílá request (Akce - GET/POST + Url). Odpověď, kterou request vrátí se uloží do stavu tlačítka, ke kterému lze přistupovat přes expression (např. $value.requestButton).',
            content:
              'Komponenta content se používá k vložení textu vytvořeného komponentou Editor (viz popis Editoru)',
            clipboardButton:
              'Tlačítko pro zkopírování vybrané hodnoty do schránky.',
          },
          advancedComponents: {
            advancedComponents:
              'Pokročilé komponenty obsahují komponenty které poskytují více funkcí než základní komponenty.',
            displayText: 'Jakýkoli text k zobrazení',
          },
          layoutComponents: {
            anchor:
              'Widget vloží HTML <div> se zadaným ID do DOM a automaticky se do něj přesune, když se stránka načte. To je užitečné pro přesměrování uživatelů na určitou část webové stránky při načítání.',
            layoutComponents: `Komponenty rozložení slouží k definování rozložení a struktury formuláře. Narozdíl od datových komponent do aplikace neukládají žádná data.
              Ale i komponenty rozložení mohou přistupovat k datům formuláře a kontextu pomocí skriptování (například schovat sekci na základě hodnoty pole).
              Tyto komponenty hrají nezbytnou roli v rozhodování o tom, jak je formulář prezentován pro uživatele a jak jsou různé prvky aplikace organizovány na obrazovce.
              Mezi běžné typy komponent rozložení patří:
              <ul>
              <li>Kontejnery: Tyto komponenty definují sekce uživatelského rozhraní a jsou používány pro seskupení různých komponent.
              Například 12 sloupcový layout, Inplace a panely. Zvláštní typ rozložení je komponenta inplace, která umožňuje úpravy dat na místě ve formuláři.</li>
              <li>Karty: Tyto komponenty prezentují informace kompaktním a organizovaným způsobem a
              jsou často používány pro zobrazení informací jako jsou detaily produktu nebo profily.</li>
              <li>Navigační prvky: Tyto komponenty pomáhají uživatelům s procházením přes obrazovku, například akordeony a taby.</li>
              <li>Tlačítka a odkazy: Slouží pro spuštění akce nebo přesměrování na jinou obrazovku.</li>
              <li>Obsah: Slouží pro doplnění dodatečného obsahu zobrazenému koncovému uživateli, jako help, statický text nebo komplexní HTML UI.</li>
              </ul>`,
            splitter:
              'Prvek splitter se používá k vizuálnímu oddělení a organizaci polí formuláře do různých sekcí nebo sloupců. Je reprezentován vodorovnou nebo svislou čárou, kterou lze zmenšit či zvětšit přetažením posouvátka a upravit množství prostoru přiděleného jednotlivým sekcím. Obvykle se používá ve formách s více sloupci nebo sekcemi, aby uživatelům poskytoval jasné vizuální oddělení mezi různými částmi formuláře, což zlepší jeho čitelnost a použitelnost. Může být také přizpůsoben různými styly a barvami pomocí tříd CSS, aby odpovídal celkovému designu formuláře.',
            clone:
              'Speciální komponenta, která umožňuje duplikování sekce formuláře na jiném místě. Použijte atribut „Clone Key“ objektu k označení objektu pomocí vlastního klíče (například „ProductDetail“) a poté použijte klíč v této konfiguraci komponenty. To je určeno k umístění částí formuláře v různých sekcích na základě použití formuláře. Obvykle je to případ detailů v seznamech nebo na samostatné stránce.',
            card: 'Komponenta karta představuje informace kompaktním, organizovaným způsobem a běžně se používá k zobrazení informací, jako jsou detaily produktu nebo profily.',
            tsmControl:
              'Speciální komponenta pro umístění základních atributů tSM, které nejsou konfigurovatelné ve formuláři. Obvykle nemusíte tuto komponentu používat přímo, ale nejprve vyberte komponentu tSM, ke které tento formulář patří (např. "Detail zákazníka") a poté vyberte dostupné ovládací prvky z vlastní nabídky (např. "Název", "Popis", " Segment" atd.).',
            backButton:
              'Komponenta tlačítko zpět slouží k návratu k předchozí stránce po kliknutí',
            inplace:
              'Komponenta Inplace je prvek uživatelského rozhraní, který umožňuje úpravy dat na místě ve formuláři. Data jsou nejprve v režimu pouze pro čtení určeném k prohlížení, ale do režimu editace lze vstoupit kliknutím na ikonu editace. Poté lze provést změny v datech a změny uložit nebo zahodit pomocí tlačítek.',
            panel:
              'Panel komponenta je kontejnerová komponenta, která slouží k seskupování a organizaci dalších komponent v rámci sekce, která má svou hlavičku. Může být manuálně nebo programátorsky sbalena a její vlastnosti, jako například hlavička, mohou být změněny.',
            layout:
              'Rozložení s 12 sloupci je oblíbený designový styl, který se používá k rozdělení uživatelského rozhraní na 12 sloupců stejné šířky. Rozložení s 12 sloupci je flexibilní designový styl, který lze použít k vytvoření široké škály rozložení, od jednoduchých jednosloupcových designů až po složitější rozložení s více sloupci. Poskytuje jednoduchý a efektivní způsob, jak rozdělit uživatelské rozhraní do spravovatelných sekcí, a pomáhá zajistit, aby rozložení bylo konzistentní a škálovatelné pro různé velikosti obrazovek a zařízení. Číslo 12 je nejsnáze dělitelné mezi přiměřeně malými čísly; je možné mít 12, 6, 4, 3, 2 nebo 1 rovnoměrně rozmístěné sloupce. To dává návrhářům obrovskou flexibilitu při rozložení.',
            externalContent:
              'Externí obsah (iframe) slouží k vložení externího obsahu do aktuální stránky. To umožňuje prohlížet obsah z jiného zdroje, aniž byste opustili aktuální stránku. Iframe lze například použít k zobrazení mapy nebo videa z jiného webu. S obsahem lze interagovat v rámci iframu, jako by byl prohlížen na vlastní stránce.',
            accordion:
              'Accordion je komponenta uživatelského rozhraní, která se používá k zobrazení a uspořádání obsahu sbalitelným způsobem, což uživatelům umožňuje rozbalit nebo sbalit části obsahu podle potřeby. Skládá se z několika sekcí nebo panelů, přičemž název každé sekce a související obsah se zobrazí v těle panelu. Když uživatel klikne na název panelu, obsah tohoto panelu se rozbalí, po dalším kliknutí se sbalí.',
            steps:
              'Komponenta wizard slouží jako univerzální průvodce, který uživatele provází vícekrokovým procesem. Organizuje jednotlivé kroky pomocí jasných popisků, což umožňuje uživatelům snadno se v procesu orientovat. S intuitivním rozhraním mohou uživatelé sledovat svůj postup, přecházet dopředu nebo zpět a efektivně plnit složité úkoly. Komponenta se dynamicky přizpůsobuje různému počtu kroků, což zajišťuje plynulý uživatelský zážitek.',
            tabs: 'Komponenta záložky umožňuje organizovat obsah do samostatných záložek. Každá záložka má obvykle štítek nebo název, a když na ní uživatel klikne, zobrazí se obsah s ní spojený. Komponenta záložka je užitečná pro rozdělení velkého množství obsahu na menší, lépe ovladatelné části a poskytuje uživatelům způsob, jak snadno procházet mezi různými částmi obsahu.',
            charts:
              'Komponenta Graf poskytuje sadu přizpůsobitelných možností vytváření grafů, které lze použít k zobrazení dat vizuálně přitažlivým způsobem. Podporuje různé typy grafů, jako jsou spojnicové, pruhové, koláčové, prstencové, radarové, polární a bodové grafy.',
            svg: 'Komponenta SVG se používá pro zobrazení škálovatelné vektorové grafiky. Umožňuje vytvářet vektorovou grafiku, kterou lze zvětšovat nebo zmenšovat bez ztráty kvality nebo jasnosti. Komponenta SVG je užitečná zejména pro vytváření interaktivních a dynamických vizualizací, jako jsou tabulky a grafy, které lze přizpůsobit a animovat na základě interakce uživatele nebo změn dat.',
            buttonBar:
              'Komponenta tlačítková lišta vytváří sekci, kterou můžete otevřít kliknutím. Do sekce můžete vložit další obsah, jako je textové pole, pole hesla nebo zaškrtávací políčko. Tyto položky jsou viditelné pouze při otevření sekce. Do štítku na tlačítkové liště můžete přidat vlastní text – například „Více“ nebo „Kliknutím zobrazíte“. Existují další přizpůsobitelné možnosti, jako je nápověda nebo třída CSS.',
            section:
              'Komponenta sekce je podobná komponentě panel (viz její popis), hlavní rozdíl je v tom, že nemá záhlaví.',
            fieldset:
              'Komponenta Fieldset se používá k seskupení souvisejících ovládacích prvků formuláře nebo obsahu do jediné vizuálně identifikovatelné jednotky. Lze jej použít k poskytnutí kontextu a zlepšení celkové organizace formuláře.',
            html: 'Komponenta Html se používá k vložení čistého html do sekce, která je následně vykreslena.',
            content:
              'Komponent obsah se používá pro vložení textu vytvořeného komponentou Editor(více viz. dokumentace pro Editor) do formuláře.',
            help: 'Komponenta nápověda se používá pro vložení textu dovnitř formuláře, který slouží jako nápověda nebo dokumentace pro uživatele. Může být programátorsky zobrazena či schována.',
            textHint:
              'Komponenta Textová nápověda se používá pro vložení textu do formuláře pomocí editoru který slouží jako nápověda nebo dokumentace pro uživatele. Je možné před text umístit ikonu znázorňující typ nápovědy - př. informační nebo varovací.',

            // nezařazené
            containers:
              'These are components that define a section of the user interface and are used to group other components together. Examples include 12-column layout, In-Place and panels. Special type is an Inplace layout component for in place editing.',
            navigationalElements:
              'These are components that help users navigate through the screen, such as accordions and tabs.',
          },
          technicalPreview: {
            technicalPreview:
              'Komponenty v této sekci jsou experimentální a mohou být v příštích verzích změněny nebo kompletně odstraněny. I když děláme maximum pro odstranění chyb, experimentální komponenty nejsou podporovány ve stejné míře jako obecně dostupné komponenty.',
          },
          scheme: {
            missingInLayout:
              'Chyba: tento prvek nebyl nalezen ve zdrojovém kódu [layout/item]',
          },
        },
        unsavedFormHeading: 'Neuložené změny',
        unsavedFormMessage:
          'Chystáte se opustit tento formulář bez uložení. Všechny změny budou ztraceny. Opravdu chcete odejít bez uložení?',
      },
      register: {
        attributeSpec:
          'Specifikace dalších atributů k číselníkové hodnotě. Tento formulář se zobrazí pro editaci hodnoty.',
        registerSorting: 'Logické členění číselníků do skupin',
        values: 'Hodnoty',
        portrayalInMenu: 'Zobrazení v menu',
        addRegister: 'Přidat tento číselník jako novou položku do menu.',
        locationInMenu: 'Umístění v menu',
        menuKey:
          'Klíč menu (celá cesta), kam se má položka umístit (např. tickets + registers)',
        menuItemTitle: 'Název položky v menu',
        itemIcon: "Ikona položky (viz. manuál) - např. 'tsm-icon-form'",
        newRegister: 'Nový číselník',
        editRecord: 'Edit entry',
        addValue: 'Přidat hodnotu',
        registerValues: 'Hodnoty číselníku',
        registerList: 'Seznam číselníků',
        deleteConfirmation: 'Opravdu si přejete odstranit označené položky?',
        order: 'Pořadí',
      },
      buttonTypes: {
        button_download_file: 'Tlačítko pro stažení souboru',
        button_dialog: 'Tlačítko s dialogem',
        message_button: 'Zobrazení zprávy',
        link: 'Odkaz',
        internal_link: 'Interní odkaz v aplikaci',
        component_selector: 'Tlačítko s komponentou',
      },
      linkTargets: {
        _blank: 'Nové okno',
        _top: 'Aktuální okno',
        popup: 'Popup okno',
      },
      buttonMessageTypes: {
        success: 'Úspěch',
        error: 'Chyba',
      },
      tableColumn: {
        visibleColumns: 'Viditelné sloupce',
        hidenColumns: 'Skryté sloupce',
        filters: 'Filtry',
        editColumnConfiguration: 'Upravit konfiguraci sloupce',
        createColumnConfiguration: 'Vytvořit konfiguraci sloupce',
        listingType: 'Typ listingu',
        main: 'Základní',
        field: 'Pole',
        visible: 'Viditelný',
        visibleTable: 'Viditelný v tabulce',
        visibleCard: 'Viditelný v kartičce',
        displayAllowed: 'Zobrazení sloupce ve správci sloupců',
        position: 'Pozice',
        favoritesOrder: 'Pořadí v nabídce filtrů',
        nested: 'Nested',
        alwaysInQuery: 'Vždy přidat tento sloupec do dotazu',
        applyDefaultValueIfFilterMissing:
          'Použít výchozí hodnotu, pokud není filter zadaný',
        defaultValue: 'Výchozí hodnota',
        dataType: 'Data typ',
        scriptField: 'Elasticsearch skript pole',
        sortType: 'Typ řazení',
        design: 'Vzhled',
        editWidget: 'Editační widget',
        inputWidget: 'Vstupní widget',
        inputWidgetConfig: 'Vstupní konfigurace widgetu',
        inputWidgetPrivilege: 'Oprávnění na editaci buňky',
        inputWidgetSpelCode: 'Spel skript',
        inputWidgetField: 'Editační pole',
        displayedField: 'Zobrazované pole',
        cssClass: 'CSS třída',
        pageSize: 'Počet řádků na stránku',
        rowsPerPageOptions: 'Možnosti počtu řádků na stránku',
        width: 'Šířka v pixelech',
        widget: 'Widget',
        context: 'Kontext',
        fulltext: 'Fulltext',
        customTqlExpression:
          'Atribut pro vlastní definici sestavení filtru ["$field $operator treeParent(\'$value\')]"',
        forbid: 'Zakázat',
        converter: 'Konverter',
        convertOnBackend: 'Konvertovat hodnotu na serveru',
        convertOnBackendTooltip:
          'Pokud je zaškrtnuto, hodnota se převede na serveru, jinak to udělá GUI',
        columnConfiguration: 'Konfigurace sloupců',
        listingCode: 'Kód listingu',
        header: 'Hlavička',
        tooltip: 'Popisek',
        listing: 'Listing',
        metrics: 'Metrics aggregation',
        metricsTooltip:
          'Specify aggregation method according to Elasticsearch metrics aggregation - e.g. sum, count, max, avg or including field name: sum(field), ...',
        buckets: 'Bucket aggregation',
        bucketsTooltip:
          'Specify aggregation method according to Elasticsearch bucket aggregation. Default is term (if not specified and Metrics aggregation is not specified).',
        addUrlParam: 'Doplňující filtry',
        dynamicComponent: 'Dynamická komponenta',
        localization: 'Lokalizace',
      },
    },
  },
};
