import {Characteristics} from '../models';

export function sortChars(a: Characteristics, b: Characteristics) {
  const sortA = a.defaultOrder ? a.defaultOrder : 50;
  const sortB = b.defaultOrder ? b.defaultOrder : 50;
  if (sortA === sortB) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  } else {
    return sortA - sortB;
  }
}
