import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {
  LocalizationVersionData,
  TranslocoService,
} from '@tsm/framework/translate';
import {NgStyle, NgIf} from '@angular/common';
import {AutoSizeTooltipDirectiveModule} from '@tsm/framework/root/directives';
import {LocalizationDataTranslatePipe} from '@tsm/framework/root';

@Component({
  selector: 'dtl-form-input-link',
  templateUrl: './form-input-link.component.html',
  styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputLinkComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputLinkComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputLinkComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgStyle,
    NgIf,
    AutoSizeTooltipDirectiveModule,
    LocalizationDataTranslatePipe,
  ],
})
export class FormInputLinkComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: ElementRef;

  @Input() inputId: string;

  @Input() noneText = 'none';

  @Input() style: any;

  @Input() customTitle: string;

  @Input() noHref = false;

  @Input() target = false;

  @Input() leftIcon: string;

  @Input() rightIcon: string;

  @Input() localizationData: LocalizationVersionData;

  lang = this.translocoService.getActiveLang();

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
    private translocoService: TranslocoService,
  ) {
    super(cdr, layoutIdDirective);
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.addEventListener('focus', () => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    this.inputElement.nativeElement.addEventListener('blur', () => {
      this.focused = false;
      this.onTouched(true);
      this.cdr.markForCheck();
    });
  }
}
