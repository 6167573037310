export const cs = {
  calendarPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmCalendarEventTypeFilter: 'tSM Calendar Event Type Filter',
      tsmCalendarEventTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů kalendářních událostí s dynamickými daty. ' +
        'Získává typy událostí z úložiště a podporuje vícenásobný výběr.',
      tsmCalendarTypeFilter: 'tSM Calendar Type Filter',
      tsmCalendarTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů kalendářů pomocí tabulky. ' +
        'Dynamicky získává dostupné typy a podporuje vícenásobný výběr.',
      tsmFullCalendarEventTypeFilter: 'tSM Full Calendar Event Type Filter',
      tsmFullCalendarEventTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů událostí v kalendáři dynamicky. ' +
        'Podporuje filtrování podle kódů nebo ID a umožňuje vícenásobný výběr.',
      tsmFullCalendarFilter: 'tSM Full Calendar Filter',
      tsmFullCalendarFilterDescription:
        'Poskytuje rozhraní pro filtrování úplného zobrazení kalendáře s konfigurovatelnými možnostmi. ' +
        'Podporuje dynamické filtrování typů událostí a efektivně spravuje stav tabulky.',
      tsmFullCalendarUserGroupFilter: 'tSM Full Calendar User Group Filter',
      tsmFullCalendarUserGroupFilterDescription:
        'Poskytuje rozhraní pro filtrování uživatelských kalendářů podle podtypů. ' +
        'Dynamicky získává dostupné podtypy a aktualizuje konfiguraci kalendáře.',
    },
    pipes: {
      calendarEventTypeByCode: 'Calendar Event Type By Code',
      calendarEventTypeByCodeDescription:
        'Načte typ události kalendáře podle jeho kódu z úložiště, získá specifická pole, jako je název ' +
        'nebo celý objekt a zajistí načtení dat, pokud již nejsou dostupná.',
      calendarEventTypeById: 'Calendar Event Type By Id',
      calendarEventTypeByIdDescription:
        'Načte typ události kalendáře podle jeho ID z úložiště, získá specifická pole, jako je název ' +
        'nebo celý objekt a zajistí načtení dat, pokud již nejsou dostupná.',
      calendarTemplateByCode: 'Calendar Template By Code',
      calendarTemplateByCodeDescription:
        'Načte data šablony kalendáře podle jejího kódu z úložiště, získá specifická pole, jako je název ' +
        'nebo celý objekt a zajistí načtení dat, pokud již nejsou dostupná.',
      calendarTemplateById: 'Calendar Template By Id',
      calendarTemplateByIdDescription:
        'Načte data šablony kalendáře podle jejího ID z úložiště, získá specifická pole, jako je název ' +
        'nebo celý objekt a zajistí načtení dat, pokud již nejsou dostupná.',
      hasCollidingPew: 'Has Colliding Pew',
      hasCollidingPewDescription:
        'Ověřuje data kolidujícího PEW podle jeho ID z úložiště, získává vlastnost "hasCollidingPew", ' +
        'pokud je dostupná a zajišťuje načtení dat, pokud ještě nejsou v úložišti.',
    },
  },
};
