import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Action} from '@ngrx/store';
import {concatMap, filter, map, switchMap} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {
  DeleteRegisterValue,
  DeleteRegisterValueError,
  DeleteRegisterValues,
  DeleteRegisterValuesError,
  DeleteRegisterValuesSuccess,
  DeleteRegisterValueSuccess,
  DiffRegisterValue,
  DiffRegisterValueError,
  DiffRegisterValueSuccess,
  LoadBatchRegisterValueByIds,
  LoadBatchRegisterValueByIdsError,
  LoadBatchRegisterValueByIdsSuccess,
  LoadBatchRegisterValueByRegisterCodeAndCode,
  LoadBatchRegisterValueByRegisterCodeAndCodeError,
  LoadBatchRegisterValueByRegisterCodeAndCodeSuccess,
  LoadBatchRegisterValueByRegisterCodeAndName,
  LoadBatchRegisterValueByRegisterCodeAndNameError,
  LoadBatchRegisterValueByRegisterCodeAndNameSuccess,
  LoadRegisterValueById,
  LoadRegisterValueByIdError,
  LoadRegisterValueByIdSuccess,
  LoadRegisterValuesByFilter,
  LoadRegisterValuesByFilterError,
  LoadRegisterValuesByFilterSuccess,
  LoadRegisterValuesByRegisterId,
  LoadRegisterValuesByRegisterIdError,
  LoadRegisterValuesByRegisterIdSuccess,
  UpsertRegisterValue,
  UpsertRegisterValueError,
  UpsertRegisterValueSuccess,
} from '../actions';
import {CommonApiService, FormActionsService} from '../services';
import {RegisterValue} from '../models';
import {
  PageSortFilterService,
  RefreshDataAndClearSelected,
} from '@tsm/listing-lib/service';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {animationBuffer} from '@tsm/framework/root';
import {LoginFinishedSuccess, Logout} from '@tsm/core';

@Injectable()
export class RegisterValuesEffects {
  private readonly REG_VALS_API_PATH = 'registerValues';

  translation = translation;
  translationShared = translationShared;

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegisterValueById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.REG_VALS_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadRegisterValueByIdSuccess({registerValue: env.data as any})
                : LoadRegisterValueByIdError({id: id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByRegisterId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegisterValuesByRegisterId),
      concatMap(({registerId}) =>
        this.commonApiService
          .getItemsById('register', registerId, this.REG_VALS_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadRegisterValuesByRegisterIdSuccess({
                    registerId: registerId,
                    entities: env.data,
                  })
                : LoadRegisterValuesByRegisterIdError(env.error),
            ),
          ),
      ),
    ),
  );

  loadBatchByCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBatchRegisterValueByRegisterCodeAndCode),
      animationBuffer(
        this.actions$,
        LoadBatchRegisterValueByRegisterCodeAndCode,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((entities) =>
        entities.map((x) => ({value: x.code, registerCode: x.registerCode})),
      ),
      // map(codes => Array.from(new Set(codes))),
      concatMap((entities) => {
        return this.commonApiService
          .getEntitiesByCodes(this.REG_VALS_API_PATH, entities as any)
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchRegisterValueByRegisterCodeAndCodeSuccess({
                      entities: response.data,
                    })
                  : LoadBatchRegisterValueByRegisterCodeAndCodeError({
                      entities: entities,
                      error: response.error,
                    });
              return temp;
            }),
          );
      }),
    ),
  );

  loadBatchByNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBatchRegisterValueByRegisterCodeAndName),
      animationBuffer(
        this.actions$,
        LoadBatchRegisterValueByRegisterCodeAndName,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((entities) =>
        entities.map((x) => ({value: x.name, registerCode: x.registerCode})),
      ),
      // map(codes => Array.from(new Set(codes))),
      concatMap((entities) => {
        return this.commonApiService
          .getEntitiesByNames(this.REG_VALS_API_PATH, entities as any)
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchRegisterValueByRegisterCodeAndNameSuccess({
                      entities: response.data,
                    })
                  : LoadBatchRegisterValueByRegisterCodeAndNameError({
                      entities: entities,
                      error: response.error,
                    });
              return temp;
            }),
          );
      }),
    ),
  );

  loadBatchByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBatchRegisterValueByIds),
      filter((x) => x.ids.length > 0),
      concatMap(({ids}) => {
        return this.commonApiService
          .getEntitiesByIds(this.REG_VALS_API_PATH, ids)
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchRegisterValueByIdsSuccess({
                      entities: response.data,
                    })
                  : LoadBatchRegisterValueByIdsError({
                      ids: ids,
                      error: response.error,
                    });
              return temp;
            }),
          );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRegisterValue),
      concatMap(({id, listingId}) =>
        this.commonApiService
          .deleteEntity(this.REG_VALS_API_PATH, id, true)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configFormService.effects
                    .registerValueDeleteSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DeleteRegisterValueSuccess({id, listingId});
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects
                    .registerValueDeleteFailure,
                );
                return DeleteRegisterValueError({id: id, error: env.error});
              }
            }),
          ),
      ),
    ),
  );

  deleteBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRegisterValues),
      concatMap(({ids, listingId, registerId, shouldRedirectToListing}) =>
        this.commonApiService
          .deleteAllEntities(this.REG_VALS_API_PATH, ids)
          .pipe(
            map((env) => {
              if (env.success) {
                if (shouldRedirectToListing) {
                  this.router.navigate([
                    '/config/registers/',
                    registerId,
                    'values',
                  ]);
                }
                return DeleteRegisterValuesSuccess({
                  ids: ids,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects
                    .registerValueDeleteFailure,
                );
                return DeleteRegisterValuesError({ids: ids, error: env.error});
              }
            }),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRegisterValue),
      concatMap(({registerValue, listingId, scriptData}) =>
        this.commonApiService
          .upsertEntityPatch(
            this.REG_VALS_API_PATH,
            registerValue,
            registerValue.id,
            true,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configFormService.effects
                    .registerValueSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertRegisterValueSuccess({
                  registerValue: env.data,
                  listingId: listingId,
                  scriptData,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects
                    .registerValueSaveFailure,
                );
                return UpsertRegisterValueError({
                  id: registerValue.id,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  upsertWithScriptSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpsertRegisterValueSuccess),
        filter(
          ({scriptData}) =>
            scriptData != null &&
            scriptData.scriptCode != null &&
            scriptData.scriptCode !== '',
        ),
        switchMap(({scriptData}) => this.formActionService.script(scriptData)),
      ),
    {dispatch: false},
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffRegisterValue),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/register-values', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffRegisterValueSuccess({
                  registerValues: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffRegisterValueError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UpsertRegisterValueSuccess,
        DeleteRegisterValueSuccess,
        DeleteRegisterValuesSuccess,
        DiffRegisterValueSuccess,
      ),
      filter(({listingId}) => !!listingId),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  loadByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRegisterValuesByFilter),
      concatMap(({registerFilters}) =>
        this.commonApiService
          .getAllFilterable(
            this.REG_VALS_API_PATH,
            this.pageSortFilterService.getUrlFilterFromFilterModels(
              registerFilters,
            ),
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadRegisterValuesByFilterSuccess({entities: env.data as any})
                : LoadRegisterValuesByFilterError(env.error),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<RegisterValue, RegisterValue>,
    private router: Router,
    private toastService: ToastService,
    private pageSortFilterService: PageSortFilterService,
    private formActionService: FormActionsService,
  ) {}
}
