import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'displayVariable',
    pure: true,
    standalone: true,
})
export class DisplayVariablePipe implements PipeTransform {
  transform(value: string | object): any {
    return typeof value === 'string' ? value : JSON.stringify(value, null, 2);
  }
}
