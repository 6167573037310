import {DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';

export const controlsCommentNewConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-comment-new',
  name: 'Comment new',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'Comment',
  tsmControls: {
    comment: {
      name: translationShared.shared.comment,
      required: false,
      previewComponent: {
        selector: 'tsm-form-field',
        inputs: {
          type: 'tiptap',
          label: translationShared.shared.comment,
          labelPosition: 'top',
        },
      },
      defaultComponent: {
        selector: 'dtl-form-input-text',
      },
    },
  },
};
