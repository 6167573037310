import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'tsm-panel-pin',
  templateUrl: './panel-pin.component.html',
  styleUrls: ['./panel-pin.component.scss'],
})
export class PanelPinComponent {
  @Input()
  panelMode = false;

  @Output()
  panelPushPinClick = new EventEmitter<void>();

  constructor() {}

  onPanelPushPinClick() {
    this.panelPushPinClick.emit();
  }
}
