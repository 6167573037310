import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';

@Pipe({
  name: 'enumToValue',
  pure: false,
})
export class EnumToValuePipe implements PipeTransform {
  transform(enumerated: any, prefix: string): any {
    return enumerated == null || enumerated === ''
      ? ''
      : this.translocoService.translate(
          prefix[enumerated] !== undefined
            ? prefix[enumerated]
            : prefix + enumerated,
        );
  }

  constructor(private translocoService: TranslocoService) {}
}
