import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from 'primeng/api';
import {AutoComplete} from './autocomplete/autocomplete.component';
import {AutoFocusModule} from 'primeng/autofocus';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {OverlayModule} from 'primeng/overlay';
import {RippleModule} from 'primeng/ripple';
import {ScrollerModule} from 'primeng/scroller';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        InputTextModule,
        ButtonModule,
        SharedModule,
        RippleModule,
        ScrollerModule,
        AutoFocusModule,
        AutoComplete,
    ],
    exports: [AutoComplete],
})
export class AutoCompleteModule {}
