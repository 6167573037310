import {translation as translationShared} from '@tsm/shared-i18n';
import {Component} from '@angular/core';
import {WidgetEditorComponent} from '@tsm/framework/root';
import {TsmFormBuilder} from '@tsm/framework/forms';

@Component({
  selector: 'tsm-form-field-editor',
  template: ` <tsm-form [formGroup]="widgetForm"> </tsm-form> `,
})
export class FormFieldEditorComponent extends WidgetEditorComponent {
  translationShared = translationShared;

  constructor(fb: TsmFormBuilder) {
    super(
      fb.tsmGroup<any>({
        title: fb.tsmControl(''),
        default: fb.tsmControl(''),
        widget: fb.tsmGroup({
          listing: fb.tsmControl(false),
          readonly: fb.tsmControl(false),
          excelStyleErrors: fb.tsmControl(false),
          persistent: fb.tsmControl('ReadonlyBased'),
          hidden: fb.tsmControl(false),
          disabled: fb.tsmControl(false),
          notitle: fb.tsmControl(false),
          tooltip: fb.tsmControl(''),
          labelPosition: fb.tsmControl('left'),
          fieldHtmlClass: fb.tsmControl(''),
          labelHtmlClass: fb.tsmControl(''),
          requiredInput: fb.tsmControl(false),
          requiredValidating: fb.tsmControl(''),
          customValidations: fb.tsmArray([]),
          customValidationsValidating: fb.tsmArray([]),
          validationMessages: fb.tsmGroup({
            required: fb.tsmControl(''),
            customValidations: fb.tsmArray([]),
          }),
        }),
        config: fb.tsmGroup({}),
      }),
    );
  }
}
