import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  ParentWidgetAccessorComponent,
  useParentWidgetProvidersFor,
} from '@tsm/framework/parent-widget';
import { PasswordModule } from 'primeng/password';
import { NgStyle, NgClass } from '@angular/common';
import { LocalizationDataTranslatePipe } from '@tsm/framework/root';

@Component({
    selector: 'dtl-form-input-password',
    templateUrl: './form-input-password.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputPasswordComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FormInputPasswordComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputPasswordComponent),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        PasswordModule,
        NgStyle,
        NgClass,
        LocalizationDataTranslatePipe,
    ],
})
export class FormInputPasswordComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: ElementRef;

  @Input() inputId: string;

  public isShowPassword = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.addEventListener('focus', () => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    this.inputElement.nativeElement.addEventListener('blur', () => {
      this.focused = false;
      this.onTouched(true);
      this.cdr.markForCheck();
    });
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }
}
