import {Injectable} from '@angular/core';
import {SharedRequestValidUntil} from '@tsm/framework/root';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {concatMap, filter, map, switchMap} from 'rxjs/operators';
import {DtlDataSource} from '@tsm/framework/datasource';
import {AttachmentDataService} from '../services';
import {Store} from '@ngrx/store';
import {countAttachments} from '../selectors';

@Injectable()
export class AttachmentCountDataSource implements DtlDataSource<string> {
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private attachmentDataService: AttachmentDataService,
    private store: Store,
  ) {}

  pushParams(params) {
    this.value.next(params);
  }

  @SharedRequestValidUntil()
  getRemoteData(ownerId: string, ownerType: string): Observable<string> {
    return this.store
      .select(countAttachments(ownerId, ownerType))
      .pipe(
        concatMap((x) =>
          x == null
            ? this.attachmentDataService
                .countAttachment(ownerId, ownerType)
                .pipe(map((x) => (x.success ? (x.data || 0) + '' : 'N/A')))
            : of(x + ''),
        ),
      );
  }

  create(): Observable<string> {
    return this.value.asObservable().pipe(
      filter((x: string[]) => x[0] != null && x[1] != null),
      switchMap((x: string[]) => this.getRemoteData(x[0], x[1])),
    );
  }
}
