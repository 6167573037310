import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/role.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getRoleState = createSelector(
  getUserManagementState,
  (userManagementState) => userManagementState.role,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getRoleState);

export const selectRoleByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectRoleById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectRolesByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectRoleByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.map((e) => e.data).find((e) => e?.code === code),
  );

export const selectRolesByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x?.data?.code)),
  );

export const selectAllRoles = selectAll;
