import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {ConfigFormState, registerValuesAdapter} from '../reducers';
import {get} from 'lodash-es';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getRegisterValuesState = createSelector(
  getConfigFormState,
  (configState) => configState.registerValues,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  registerValuesAdapter.getSelectors(getRegisterValuesState);

export const selectAllRegisterValues = selectAll;

export const selectRegisterValueById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllRegisterValues = selectAll;

export const selectRegisterValueByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectRegisterValuesByRegisterId = (registerId: string) =>
  createSelector(getAllRegisterValues, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter(
        (entity) =>
          entity && entity.register && entity.register.id === registerId,
      )
      .sort((o1, o2) => o1.order - o2.order);
  });

export const selectRegisterValuesByRegisterCode = (registerCode: string) =>
  createSelector(getAllRegisterValues, (entities) => {
    return entities
      .map((pack) => pack.data)
      .filter(
        (entity) =>
          entity && entity.register && entity.register.code === registerCode,
      );
  });

export const selectRegisterValueByRegisterCodeAndCode = (
  registerCode: string,
  code: string,
) =>
  createSelector(getAllRegisterValues, (entities) => {
    return entities.find(
      (i) =>
        i.error == null &&
        i?.data?.code === code &&
        i?.data?.register?.code === registerCode,
    );
  });

export const selectRegisterValuesByIds = (ids: string[]) =>
  createSelector(getAllRegisterValues, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectRegisterValueByRegisterCodeAndName = (
  registerCode: string,
  name: string,
) =>
  createSelector(getAllRegisterValues, (entities) => {
    return entities.find(
      (i) =>
        i.error == null &&
        i.data.name === name &&
        i.data.register.code === registerCode,
    );
  });

export const selectRegisterValuesByRegisterCodeAndCodes = (
  registerCode: string,
  codes: string[],
) =>
  createSelector(selectAll, (entities) =>
    entities
      .filter(
        (x) =>
          x.error == null &&
          x?.data?.register?.code === registerCode &&
          codes.includes(x.data.code),
      )
      .filter((x) => !x.loading),
  );

export const selectRegisterValueCountByRegisterId = (registerId: string) =>
  createSelector(selectRegisterValuesByRegisterId(registerId), (data) => {
    return data ? data.length : 0;
  });

export const selectRegisterValuesByRegisterIdAndJsonKeyValue = (
  registerId: string,
  key: string,
  value: any,
) =>
  createSelector(getAllRegisterValues, (entities) => {
    if (Array.isArray(value)) {
      if (value == null) {
        return entities.filter((i) => i.data.register.id === registerId);
      }
      return value
        .reduce(
          (acc, val) =>
            acc.concat(
              entities.filter((i) => {
                const jsonVal = get(i.data.chars, key);
                return (
                  i.data.register.id === registerId &&
                  (Array.isArray(jsonVal)
                    ? jsonVal.includes(val)
                    : jsonVal === val)
                );
              }),
            ),
          [],
        )
        .filter((v, i, a) => a.indexOf(v) === i);
    } else {
      return entities
        .filter((i) => {
          if (value == null) {
            return i.data.register.id === registerId;
          }
          const jsonVal = get(i.data.chars, key);
          return (
            i.data.register.id === registerId &&
            (Array.isArray(jsonVal)
              ? jsonVal.includes(value)
              : jsonVal === value)
          );
        })
        .filter((v, i, a) => a.indexOf(v) === i);
    }
  });
