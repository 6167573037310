import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {AttachmentTypeModel} from '../models';

export const LoadAttachmentType = createAction(
  '[AttachmentType] Load AttachmentType',
);
export const LoadAttachmentTypeSuccess = createAction(
  '[AttachmentType] Load AttachmentType Success',
  props<{
    attachmentTypes: AttachmentTypeModel[];
  }>(),
);
export const LoadAttachmentTypeError = createAction(
  '[AttachmentType] Load AttachmentType Error',
  props<TsmError>(),
);

export const LoadAttachmentTypeById = createAction(
  '[AttachmentType] Load LoadAttachmentTypeById',
  props<{
    id: string;
  }>(),
);
export const LoadAttachmentTypeByIdSuccess = createAction(
  '[AttachmentType] Load LoadAttachmentTypeById Success',
  props<{
    attachmentType: AttachmentTypeModel;
  }>(),
);
export const LoadAttachmentTypeByIdError = createAction(
  '[AttachmentType] Load LoadAttachmentTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadAttachmentTypeByCode = createAction(
  '[AttachmentType] Load AttachmentTypeByCode',
  props<{
    code: string;
  }>(),
);
export const LoadAttachmentTypeByCodeSuccess = createAction(
  '[AttachmentType] Load AttachmentTypeByCode Success',
  props<{
    attachmentType: AttachmentTypeModel;
  }>(),
);
export const LoadAttachmentTypeByCodeError = createAction(
  '[AttachmentType] Load AttachmentTypeByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertAttachmentType = createAction(
  '[AttachmentType] Upsert AttachmentType',
  props<{
    attachmentType: AttachmentTypeModel;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertAttachmentTypeSuccess = createAction(
  '[AttachmentType] Upsert AttachmentType Success',
  props<{
    attachmentType: AttachmentTypeModel;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertAttachmentTypeError = createAction(
  '[AttachmentType] Upsert AttachmentType Error',
  props<TsmError>(),
);

export const DeleteAttachmentType = createAction(
  '[AttachmentType] Delete AttachmentType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteAttachmentTypeSuccess = createAction(
  '[AttachmentType] Delete AttachmentType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteAttachmentTypeError = createAction(
  '[AttachmentType] Delete AttachmentType Error',
  props<TsmError>(),
);

export const DiffAttachmentType = createAction(
  '[AttachmentType] Diff AttachmentType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffAttachmentTypeSuccess = createAction(
  '[AttachmentType] Diff AttachmentType Success',
  props<{
    attachmentTypes: AttachmentTypeModel[];
    listingId: string;
  }>(),
);
export const DiffAttachmentTypeError = createAction(
  '[AttachmentType] Diff AttachmentType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
