import {
  AsyncValidatorFn,
  FormControl,
  FormControlOptions,
  FormControlState,
  ValidatorFn,
  ɵTypedOrUntyped,
  ɵValue,
} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {parseISO, isValid} from 'date-fns';
import {
  fluentFilterControl,
  SKIP_CONSTANT,
} from '../utils/fluent-filter-control.utils';

export class TypedTsmFormControl<T = any> extends FormControl<T> {
  public forceReadonly = false;
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;

  private _options: {autoparsing: boolean; [key: string]: any};
  // @ts-ignore
  public defaults: ɵTypedOrUntyped<T, Array<ɵValue<T>>>;
  public default = false;
  public readonly = false;
  public filterMap: {[dataPointer: string]: any} = null;
  public readonly value: T;

  /** @internal */
  _onReadonly: Function[] = [];

  constructor(
    formState: FormControlState<T> | T = null as unknown as T,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    options: {autoparsing: boolean; [key: string]: any} = {autoparsing: true},
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this._options = options;
    this.defaults = this.value;
    this.touchedChanges = this._touchedChanges.asObservable();
  }

  setValue(
    value: T,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }

    if (this._options.autoparsing === false) {
      super.setValue(value, options);
      return;
    }

    if (
      !!value &&
      (value as unknown as string).length > 4 &&
      isValid(parseISO(value + ''))
    ) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date' || date.getFullYear() > 9999) {
        super.setValue(value, options);
      } else {
        // TODO: tadle prasarna musi pryc asap
        super.setValue(date as any, options);
      }
    } else {
      super.setValue(value, options);
    }
  }

  /**
   * @param options - newDefault -> nasetuje value do defaults -> muzu pak zavolat resetWithDefaults a da mu to tam puvodni hodnoty
   */
  patchValue(
    value: T,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }

    // autoparsing == false zajisti to, ze se nesnazi hodnotu preves na objekt typu Date
    if (this._options.autoparsing === false) {
      super.setValue(value, options);
      return;
    }

    if (
      !!value &&
      (value as unknown as string).length > 4 &&
      isValid(parseISO(value + ''))
    ) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date' || date.getFullYear() > 9999) {
        super.patchValue(value, options);
      } else {
        // TODO: tadle prasarna musi pryc asap
        super.patchValue(date as any, options);
      }
    } else {
      super.patchValue(value, options);
    }
  }

  markAsPristineRecursively() {
    this.markAsPristine({onlySelf: true});
  }

  markAsDirtyRecursively() {
    this.markAsDirty({onlySelf: true});
  }

  markAsTouchedRecursively(markAsDirty = false) {
    this.markAsTouched({onlySelf: true});
    if (markAsDirty) {
      this.markAsDirty();
    }
  }

  markAsUntouchedRecursively(markAsPristine = false) {
    this.markAsUntouched();
    if (markAsPristine) {
      this.markAsDirty();
    }
    this.markAsPristine();
  }

  markAsTouched(opts: {onlySelf?: boolean} = {}): void {
    super.markAsTouched(opts);
    this._touchedChanges.next(true);
  }

  markAsUntouched(opts: {onlySelf?: boolean} = {}): void {
    super.markAsUntouched(opts);
    this._touchedChanges.next(false);
  }

  markAsDefaultRecursively(): void {
    this.default = true;
  }

  markAsNonDefaultRecursively(): void {
    this.default = false;
  }

  /**
   * Udela reset a nastavi defaultni hodnotu (ignoruje se atribut "default")
   * @param value
   * @param options
   */
  resetWithDefaults(options?: {onlySelf?: boolean; emitEvent?: boolean}) {
    this.reset(this.defaults, options);
  }

  /**
   * Udela reset a nastavi defaultni hodnotu, pokud je atribut "default" nastaven na TRUE
   * @param value
   * @param options
   */
  resetNonDefaultValues(
    value?: T,
    options?: {onlySelf?: boolean; emitEvent?: boolean},
  ) {
    if (!this.default) {
      this.reset(value, options);
    }
  }

  setDefaultValueRecursively(value: T) {
    this.defaults = value;
  }

  setFilterMapRecursively(filterMap) {
    this.filterMap = filterMap;
  }

  getFilteredValue(): T {
    return this.filterMap != null && this._options.dataPointer != null
      ? fluentFilterControl(
          this.value,
          this._options.dataPointer,
          this.filterMap[this._options.dataPointer],
        )
      : this.value;
  }

  getChangedValue(): T | typeof SKIP_CONSTANT {
    if (!(this.dirty || this.default)) {
      return SKIP_CONSTANT;
    }
    return this.filterMap != null && this._options.dataPointer != null
      ? fluentFilterControl(
          this.value,
          this._options.dataPointer,
          this.filterMap[this._options.dataPointer],
        )
      : this.value;
  }

  setReadonly(readonly: boolean) {
    this.readonly = readonly;
    if (this._onReadonly.length) {
      this._onReadonly.forEach((changeFn) => changeFn(this.readonly));
    }
  }

  registerOnReadonly(fn: (readonly?: any) => void): void {
    this._onReadonly.push(fn);
  }

  /** @internal */
  _unregisterOnReadonly(): void {
    // kdyby dementi nedelali pole s jednou polozku kterou muzu odstranit jen tim, ze mam stejnou instanci tak by mi usnadnili zivot omg
    // removeListItem(this._onReadonly, fn);
    this._onReadonly = [];
  }

  setForceReadonly(forceReadonly) {
    this.forceReadonly = forceReadonly;
  }
}
