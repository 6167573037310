import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  Renderer2,
  Signal,
  Type,
  ViewChild,
} from '@angular/core';

import {
  catchError,
  defer,
  distinctUntilChanged,
  fromEvent,
  fromEventPattern,
  Observable,
  of,
  startWith,
  Subject,
  throwError,
} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  getLayoutVM,
  LayoutTsmMenuService,
  LayoutTsmService,
  LayoutVM,
  SetTopbarAction,
} from '@tsm/layout/service';
import {RuntimeInfo, RuntimeService} from '@tsm/runtime-info';
import {isMobile} from '@tsm/framework/functions';
import {
  selectUserParameterByUserIdAndName,
  UpsertUserParameterByUserIdAndName,
  UserParameterName,
} from '@tsm/user-parameters';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  timeout,
} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {FluentFormsStore} from '@tsm/framework/fluent-debugger-service';
import {Terminator} from '@tsm/framework/terminator';
import {ActivatedRoute} from '@angular/router';
import {SCROLL_IDS} from '@tsm/config-form/service';
import {ObjectUtils} from 'primeng/utils';
import {translation} from '../i18n';

@Component({
  templateUrl: './layout-tsm.component.html',
  styleUrls: ['./layout-tsm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutTsmComponent implements AfterViewInit, OnDestroy {
  translation = translation;
  notificationSideBarType$: Observable<Type<unknown>> = defer(() =>
    import('@tsm/dms/notification-side-bar').then(
      (x) => x.NotificationSideBarComponent,
    ),
  );
  fluentDebuggerType$: Observable<Type<unknown>> = defer(() =>
    import('@tsm/framework/fluent-debugger').then(
      (x) => x.FluentDebuggerContainerComponent,
    ),
  );
  worklogSideBarType$: Observable<Type<unknown>> = defer(() =>
    import('@tsm/dms/worklog-side-bar').then((x) => x.WorklogSideBarComponent),
  );
  @ViewChild('divLayoutMenu') divLayoutMenu!: ElementRef;

  state: LayoutVM;

  // Panel Modes: static(false) or overlay(true)
  panelMode =
    localStorage.getItem(UserParameterName.PANEL_MODE) === 'true' || false;

  isMobileMenu$ = fromEvent(window, 'resize').pipe(
    startWith(isMobile() || window.innerWidth < 991),
    map(() => isMobile() || window.innerWidth < 991),
  );

  isDarkTheme = false;

  darkTheme$ = of(false);

  destroyed$: Subject<void> = new Subject();

  currentUser: RuntimeInfo;

  // indikator, zda nejaky stopny pro worklog bezi
  worklogStatus = false;
  isFluentDebuggerOpened: Signal<boolean> =
    this.fluentFormsStore.isDebuggerOpened;

  rightPanelWidth = 350;

  constructor(
    private zone: NgZone,
    public store: Store,
    private layoutTsmService: LayoutTsmService,
    public runtimeService: RuntimeService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private terminator: Terminator,
    private fluentFormsStore: FluentFormsStore,
    private cdr: ChangeDetectorRef,
    private layoutTsmMenuService: LayoutTsmMenuService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.currentUser = this.runtimeService.getCurrent();

    store
      .select(getLayoutVM())
      .pipe(
        distinctUntilChanged((a, b) => ObjectUtils.deepEquals(a, b)),
        takeUntil(this.destroyed$),
      )
      .subscribe((x) => {
        zone.run(() => {
          this.state = x;
          this.cdr.markForCheck();
        });
      });

    if (isMobile()) {
      this.renderer.addClass(document.body, 'mobile-device');
    }

    this.runtimeService
      .getCurrentUserObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ui) => {
        this.darkTheme$ = this.store
          .select(
            selectUserParameterByUserIdAndName(
              ui.id,
              UserParameterName.DARK_THEME,
            ),
          )
          .pipe(
            filter((x) => x != null && x.loading === false),
            map((x) => x.data.parameterValue === 'true'),
            tap((darkTheme) => {
              if (darkTheme) {
                this.renderer.setAttribute(
                  document.querySelector('html'),
                  'data-theme',
                  'theme-dark',
                );
                this.renderer.addClass(document.body, 'dark-theme');
              } else {
                this.renderer.setAttribute(
                  document.querySelector('html'),
                  'data-theme',
                  'theme-light',
                );
                this.renderer.removeClass(document.body, 'dark-theme');
              }
              this.cdr.markForCheck();
            }),
          );
      });
  }

  resizeEnd(element) {
    this.rightPanelWidth = element.rectangle.width;
  }

  shouldOpenMenuChanges(shouldOpen: boolean, isPinned: boolean) {
    this.layoutTsmMenuService.toggleMenu(shouldOpen);
    if (isPinned) {
      this.setMenuLocked(shouldOpen);
    }
    // Slouzi pro h-flex  directivu
    window.dispatchEvent(new Event('changeMenuStateAnimationStart'));
    setTimeout(
      () => window.dispatchEvent(new Event('changeMenuStateAnimationEnd')),
      500,
    );
  }

  topbarOpens(
    type: 'notifications' | 'profile' | 'messages' | 'worklogs' | null,
  ) {
    this.store.dispatch(SetTopbarAction({topbar: type}));
  }

  onLayoutMaskClick() {
    this.layoutTsmService.closeMenu();
  }

  pushPinClick(value) {
    this.setMenuPinned(value);
  }

  panelPushPinClick() {
    this.panelMode = !this.panelMode;
    localStorage.setItem(
      UserParameterName.PANEL_MODE,
      this.panelMode.toString(),
    );
  }

  runningWorklogStatus(status: boolean) {
    this.worklogStatus = status;
  }

  private setMenuLocked(value) {
    localStorage.setItem(
      UserParameterName.REMEMBER_LOCKED_MENU,
      value.toString(),
    );
    this.store.dispatch(
      UpsertUserParameterByUserIdAndName({
        name: UserParameterName.REMEMBER_LOCKED_MENU,
        userId: this.currentUser.id,
        value: value,
      }),
    );
  }

  private setMenuPinned(value) {
    localStorage.setItem(
      UserParameterName.REMEMBER_PINNED_MENU,
      (!value).toString(),
    );
    this.store.dispatch(
      UpsertUserParameterByUserIdAndName({
        name: UserParameterName.REMEMBER_PINNED_MENU,
        userId: this.currentUser.id,
        value: !value,
      }),
    );
  }

  closeTopBar() {
    //nezavirat topbar, pokud je staticky
    if (this.panelMode === false) {
      this.store.dispatch(SetTopbarAction({topbar: null}));
    }
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      let previousHeight;
      this.activatedRoute.fragment
        .pipe(
          filter((x) => SCROLL_IDS.includes(x)),
          switchMap(() =>
            fromEventPattern(
              (handler) => {
                const observer = new ResizeObserver((entries) => {
                  for (let entry of entries) {
                    if (entry.target === document.body) {
                      const height = entry.contentRect.height;
                      if (height !== previousHeight) {
                        previousHeight = height;
                        handler();
                      }
                    }
                  }
                });
                observer.observe(document.body);
                return observer;
              },
              (_, observer) => observer.disconnect(),
            ),
          ),
          debounceTime(300),
          tap(() => {
            document
              .getElementById(this.activatedRoute.snapshot.fragment)
              ?.scrollIntoView();
          }),
          timeout(6000), // unsub after 6s when nothing emits
          catchError((err) => {
            if (err.name === 'TimeoutError') {
              return of(null); // Timeout occurred, but continue
            } else {
              return throwError(err);
            }
          }),
        )
        .subscribe();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }
}
