import {Injectable} from '@angular/core';

// @dynamic
@Injectable({
  providedIn: 'root',
})
export class ConfigService<ConfigType = any> {
  private static _config: any = null;

  get value(): ConfigType {
    if (!ConfigService._config) {
      throw Error(
        'Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?',
      );
    }
    return ConfigService._config;
  }

  static getQueryStringParams(locationSearch) {
    const a = locationSearch.substr(1).split('&');
    if (a === '') {
      return {};
    }
    const b = {};
    for (let i = 0; i < a.length; ++i) {
      const p = a[i].split('=', 2);
      if (p.length === 1) {
        b[p[0]] = '';
      } else {
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
      }
    }
    return b;
  }

  // const queryStringParams = ((a: any) => {
  //   if (a === "") return {};
  //   const b = {};
  //   for (let i = 0; i < a.length; ++i) {
  //     const p = a[i].split('=', 2);
  //     if (p.length === 1)
  //       b[p[0]] = "";
  //     else
  //       b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  //   }
  //   return b;
  // })(window.location.search.substr(1).split('&'));

  //   if(queryStringParams['env']) {
  //   sessionStorage.setItem('environmentOverride', queryStringParams['env']);
  // }

  /**
   * ...
   * @returns Promise to wait for
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  static prebootstrapLoad(
    options: {
      serviceWorker: boolean;
    } = null,
  ): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const queryStringParams = ConfigService.getQueryStringParams(
        window.location.search,
      );
      if (queryStringParams['env']) {
        sessionStorage.setItem('environmentOverride', queryStringParams['env']);
      }

      const configFileBasePath = sessionStorage.getItem('environmentOverride')
        ? '/configs/' +
          sessionStorage.getItem('environmentOverride') +
          '/config.json'
        : localStorage.getItem('environmentOverride')
          ? '/configs/' +
            sessionStorage.getItem('environmentOverride') +
            '/config.json'
          : 'config.json';

      const request = new XMLHttpRequest();
      request.addEventListener('load', (_) => {
        if (request.status === 200) {
          try {
            ConfigService._config = JSON.parse(request.responseText);
          } catch (e) {
            reject(e);
          }
          resolve();
        } else {
          reject(request.statusText);
        }
      });
      const url =
        options && options.serviceWorker
          ? configFileBasePath
          : configFileBasePath + '?hash=' + new Date().toISOString();

      request.open('GET', url);
      request.send();
    });
  }

  load(): Promise<void> {
    return new Promise((res, rej) => {
      res();
    });
  }
}
