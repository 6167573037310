import {createAction, props} from '@ngrx/store';
import {Form} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadAllForms = createAction('[Form] Load All Forms');
export const LoadAllFormsSuccess = createAction(
  '[Form] Load All Forms Success',
  props<{entities: Form[]}>(),
);
export const LoadAllFormsError = createAction(
  '[Form] Load All Forms Error',
  props<{error: any}>(),
);

export const UpsertForm = createAction(
  '[Form] Upsert Form',
  props<{
    entity: Form;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertFormSuccess = createAction(
  '[Form] Upsert Form Success',
  props<{
    entity: Form;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertFormError = createAction(
  '[Form] Upsert Form Error',
  props<{error: any}>(),
);

export const LoadFormById = createAction(
  '[Form] Load Form by id',
  props<{id: string}>(),
);
export const LoadFormByIdForceReload = createAction(
  '[Form] Load Form by id force reload',
  props<{id: string}>(),
);
export const LoadFormByIdSuccess = createAction(
  '[Form] Load Form by id Success',
  props<{entity: Form}>(),
);
export const LoadFormByIdError = createAction(
  '[Form] Load Form by id Error',
  props<{id: string; error: any}>(),
);

export const LoadFormByCode = createAction(
  '[Form] Load Form by code',
  props<{code: string}>(),
);
export const LoadFormByCodeSuccess = createAction(
  '[Form] Load Form by code Success',
  props<{entity: Form}>(),
);
export const LoadFormByCodeError = createAction(
  '[Form] Load Form by code Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const DeleteForm = createAction(
  '[Form] Delete Form',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteFormSuccess = createAction(
  '[Form] Delete Form Success',
  props<{id: string; listingId: string}>(),
);
export const DeleteFormError = createAction(
  '[Form] Delete Form Error',
  props<{error: any}>(),
);

export const DiffForm = createAction(
  '[Form] Diff Form',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffFormSuccess = createAction(
  '[Form] Diff Form Success',
  props<{forms: Form[]; listingId: string}>(),
);
export const DiffFormError = createAction(
  '[Form] Diff Form Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);

export const LoadFormByEntitySpecificationSelector = createAction(
  '[Form] Load Form by EntitySpecification selector',
  props<{entitySpecId: string; selector: string}>(),
);
