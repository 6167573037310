import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {SkillAssignment, User} from '../model';

export const LoadSkillAssignmentsByUserId = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserId',
  props<{
    userId: string;
  }>(),
);
export const LoadSkillAssignmentsByUserIds = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserIds',
  props<{
    userIds: string[];
  }>(),
);
export const LoadSkillAssignmentsByUserIdSuccess = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserId Success',
  props<{
    entities: SkillAssignment[];
  }>(),
);
export const LoadSkillAssignmentsByUserIdError = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserId Error',
  props<{
    error: any;
  }>(),
);

export const LoadSkillAssignmentsByUserGroupId = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserGroupId',
  props<{
    userId: string;
  }>(),
);
export const LoadSkillAssignmentsByUserGroupIds = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserGroupIds',
  props<{
    userIds: string[];
  }>(),
);
export const LoadSkillAssignmentsByUserGroupIdSuccess = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserGroupId Success',
  props<{
    entities: SkillAssignment[];
  }>(),
);
export const LoadSkillAssignmentsByUserGroupIdError = createAction(
  '[SkillAssignment] Load SkillAssignments ByUserGroupId Error',
  props<{
    error: any;
  }>(),
);

export const UpsertSkillAssignment = createAction(
  '[SkillAssignment] Upsert SkillAssignment',
  props<{
    skillAssignment: SkillAssignment;
  }>(),
);
export const UpsertSkillAssignmentSuccess = createAction(
  '[SkillAssignment] Upsert SkillAssignment Success',
  props<{
    skillAssignment: SkillAssignment;
  }>(),
);
export const UpsertSkillAssignmentError = createAction(
  '[SkillAssignment] Upsert SkillAssignment Error',
  props<{
    error: any;
  }>(),
);

export const UpsertSkillAssignments = createAction(
  '[SkillAssignment] Upsert SkillAssignments',
  props<{
    entities: SkillAssignment[];
  }>(),
);
export const UpsertSkillAssignmentsSuccess = createAction(
  '[SkillAssignment] Upsert SkillAssignments Success',
  props<{
    entities: SkillAssignment[];
  }>(),
);
export const UpsertSkillAssignmentsError = createAction(
  '[SkillAssignment] Upsert SkillAssignments Error',
  props<{
    error: any;
  }>(),
);

export const UpsertMoreSkillAssignment = createAction(
  '[SkillAssignment] Upsert More SkillAssignment',
  props<{
    rows: {user: User & {[key: string]: number}[]}[];
  }>(),
);
export const UpsertMoreSkillAssignmentSuccess = createAction(
  '[SkillAssignment] Upsert More SkillAssignment Success',
  props<{
    entities: SkillAssignment[];
  }>(),
);
export const UpsertMoreSkillAssignmentError = createAction(
  '[SkillAssignment] Upsert More SkillAssignment Error',
  props<{
    error: any;
  }>(),
);

export const DeleteSkillAssignment = createAction(
  '[SkillAssignment] Delete SkillAssignment',
  props<{id: string}>(),
);
export const DeleteSkillAssignmentSuccess = createAction(
  '[SkillAssignment] Delete SkillAssignment Success',
  props<{
    id: string;
  }>(),
);
export const DeleteSkillAssignmentError = createAction(
  '[SkillAssignment] Delete SkillAssignment Error',
  props<TsmError>(),
);

export const LoadSkillAssignmentsBySkillId = createAction(
  '[SkillAssignment] Load SkillAssignments BySkillId',
  props<{
    skillId: string;
  }>(),
);
export const LoadSkillAssignmentsBySkillIds = createAction(
  '[SkillAssignment] Load SkillAssignments BySkillIds',
  props<{
    skillIds: string[];
  }>(),
);
export const LoadSkillAssignmentsBySkillIdSuccess = createAction(
  '[SkillAssignment] Load SkillAssignments BySkillId Success',
  props<{
    entities: SkillAssignment[];
  }>(),
);
export const LoadSkillAssignmentsBySkillIdError = createAction(
  '[SkillAssignment] Load SkillAssignments BySkillId Error',
  props<{
    error: any;
  }>(),
);
