import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {UserGroup, UserGroupNotificationConfig} from '../model';

export const LoadUserGroups = createAction('[UserGroup] Load UserGroups');
export const LoadUserGroupsSuccess = createAction(
  '[UserGroup] Load UserGroups Success',
  props<{
    entities: UserGroup[];
  }>(),
);
export const LoadUserGroupsError = createAction(
  '[UserGroup] Load UserGroups Error',
  props<{error: any}>(),
);

export const LoadUserGroupById = createAction(
  '[UserGroup] Load LoadUserGroupById',
  props<{
    id: string;
    forcedReload?: boolean;
  }>(),
);
export const LoadUserGroupByIdSuccess = createAction(
  '[UserGroup] Load LoadUserGroupById Success',
  props<{
    userGroup: UserGroup;
  }>(),
);
export const LoadUserGroupByIdError = createAction(
  '[UserGroup] Load LoadUserGroupById Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const LoadUserGroupByIds = createAction(
  '[UserGroup] Load LoadUserGroupByIds',
  props<{ids: string[]}>(),
);
export const LoadUserGroupByIdsSuccess = createAction(
  '[UserGroup] Load LoadUserGroupByIds Success',
  props<{
    userGroups: UserGroup[];
  }>(),
);
export const LoadUserGroupByIdsError = createAction(
  '[UserGroup] Load LoadUserGroupByIds Error',
  props<{
    ids: string[];
    error: any;
  }>(),
);

export const LoadUserGroupByCode = createAction(
  '[UserGroup] Load LoadUserGroupByCode',
  props<{code: string}>(),
);
export const LoadUserGroupByCodeSuccess = createAction(
  '[UserGroup] Load LoadUserGroupByCode Success',
  props<{
    userGroup: UserGroup;
  }>(),
);
export const LoadUserGroupByCodeError = createAction(
  '[UserGroup] Load LoadUserGroupByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const LoadUserGroupByCodes = createAction(
  '[UserGroup] Load LoadUserGroupByCodes',
  props<{codes: string[]}>(),
);
export const LoadUserGroupByCodesSuccess = createAction(
  '[UserGroup] Load LoadUserGroupByCodes Success',
  props<{
    userGroups: UserGroup[];
  }>(),
);
export const LoadUserGroupByCodesError = createAction(
  '[UserGroup] Load LoadUserGroupByCodes Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const LoadBatchUserGroupByCode = createAction(
  '[UserGroup] Load LoadBatchUserGroupByCode',
  props<{
    code: string;
  }>(),
);
export const LoadBatchUserGroupByCodeSuccess = createAction(
  '[UserGroup] Load LoadBatchUserGroupByCode Success',
  props<{
    userGroups: UserGroup[];
  }>(),
);
export const LoadBatchUserGroupByCodeError = createAction(
  '[UserGroup] Load LoadBatchUserGroupByCode Error',
  props<{
    codes: string[];
    error: any;
  }>(),
);

export const UpsertUserGroup = createAction(
  '[UserGroup] Upsert UserGroup',
  props<{
    userGroup: UserGroup;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertUserGroupSuccess = createAction(
  '[UserGroup] Upsert UserGroup Success',
  props<{
    userGroup: UserGroup;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertUserGroupError = createAction(
  '[UserGroup] Upsert UserGroup Error',
  props<TsmError>(),
);

export const DeleteUserGroup = createAction(
  '[UserGroup] Delete UserGroup',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteUserGroupSuccess = createAction(
  '[UserGroup] Delete UserGroup Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteUserGroupError = createAction(
  '[UserGroup] Delete UserGroup Error',
  props<TsmError>(),
);

export const DiffUserGroup = createAction(
  '[UserGroup] Diff UserGroup',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffUserGroupSuccess = createAction(
  '[UserGroup] Diff UserGroup Success',
  props<{
    userGroups: UserGroup[];
    listingId: string;
  }>(),
);
export const DiffUserGroupError = createAction(
  '[UserGroup] Diff UserGroup Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);

export const SaveUserGroupNotificationConfig = createAction(
  '[UserGroup] Save UserGroup notification config',
  props<{id: string; config: UserGroupNotificationConfig}>(),
);
export const SaveUserGroupNotificationConfigSuccess = createAction(
  '[UserGroup] Save UserGroup notification config success',
  props<{id: string; config: UserGroupNotificationConfig}>(),
);
export const SaveUserGroupNotificationConfigError = createAction(
  '[UserGroup] Save UserGroup notification config error',
  props<{id: string; error: TsmError}>(),
);
