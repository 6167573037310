import {Injectable, Injector, PipeTransform, Type} from '@angular/core';
import {TableColumn, TableColumnConfig, TableType} from '../models';
import {DynamicComponentService} from '@tsm/framework/dynamic-component';
import {forkJoin, from, isObservable, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TableColumnConfigService {
  private _cache: {
    filters: Map<string, Observable<Type<any>>>;
    converters: Map<string, Observable<Type<any>>>;
  } = {
    filters: new Map<string, Observable<Type<any>>>(),
    converters: new Map<string, Observable<Type<any>>>(),
  };

  constructor(
    private dynamicComponentService: DynamicComponentService,
    private injector: Injector,
  ) {}

  mapColumnConfigToTableColumn(
    conf: TableColumnConfig,
    tableType?: TableType,
  ): Observable<TableColumn> {
    let filter: Observable<any> = of(null);
    let converter: Observable<any> = of(null);
    if (conf.filterWidget) {
      if (conf.filterWidget instanceof Promise) {
        conf.filterWidget.then((filterWidget) => {
          filter =
            this._cache.filters.get(filterWidget.toString()) ||
            from(
              this.dynamicComponentService
                .resolveComponentFactoryAndModule(filterWidget.toString())
                .then(([factory, module]) => {
                  return factory.componentType;
                }),
            );
          this._cache.filters.set(conf.filterWidget.toString(), filter);
        });
      } else {
        filter =
          this._cache.filters.get(conf.filterWidget.toString()) ||
          from(
            this.dynamicComponentService
              .resolveComponentFactoryAndModule(conf.filterWidget.toString())
              .then(([factory, module]) => {
                return factory.componentType;
              }),
          );
        this._cache.filters.set(conf.filterWidget.toString(), filter);
      }
    }
    if (conf.converter && conf.convertOnBackend != true) {
      if (conf.converter instanceof Promise) {
        conf.converter.then((converterWidget) => {
          converter =
            this._cache.converters.get(converterWidget.toString()) ||
            from(
              this.dynamicComponentService
                .resolvePipeFactoryAndModule(converterWidget.toString())
                .then(([type, module]) => {
                  return type;
                }),
            );
          this._cache.converters.set(conf.converter.toString(), converter);
        });
      } else if (conf.converter.toString() === 'userImageComponent') {
        // HACK - pokud pouziju specialni pipe, tak ji nahrad za dynamic komponent
        conf.dynamicComponent = {
          selector: 'tsm-user-image',
        };
      } else if (conf.converter.toString() === 'linkHrefComponent') {
        conf.dynamicComponent = {
          selector: 'dtl-link-href',
          inputs: {
            ...conf.converterParams[0],
          },
        };
      } else if (conf.converter.toString() === 'cellDescriptionComponent') {
        conf.dynamicComponent = {
          selector: 'dtl-cell-description',
          inputs: {
            ...conf.converterParams[0],
          },
        };
      } else {
        converter =
          this._cache.converters.get(conf.converter.toString()) ||
          from(
            this.dynamicComponentService
              .resolvePipeFactoryAndModule(conf.converter.toString())
              .then(([factory, module]) => {
                return factory;
              }),
          );
        this._cache.converters.set(conf.converter.toString(), converter);
      }
    }

    let newConverterParams = [];
    if (conf.converterParams) {
      newConverterParams = [
        ...conf.converterParams.map((x) => {
          return this.dynamicComponentService.anyWithSelector(x)
            ? from(
                this.dynamicComponentService.resolvePipeFactoryAndModule(x),
              ).pipe(map(([factory, module]) => factory))
            : isObservable(x)
              ? x
              : of(
                  typeof x === 'string'
                    ? x.replace("'", '').replace("'", '')
                    : x,
                );
        }),
      ];
    }
    // bud se vezme jako prvni opravneni primo na sloupecku, nebo se vezme jako parentPrivilege (z listingType) + field
    let inputWidgetPrivilege = null;
    if (!!conf.inputWidgetPrivilege) {
      inputWidgetPrivilege = conf.inputWidgetPrivilege;
    } else if (!!conf?.listing?.parentPrivilege) {
      inputWidgetPrivilege = `${conf.listing.parentPrivilege}#${conf.field}`;
    } else if (!!tableType?.parentPrivilege) {
      inputWidgetPrivilege = `${tableType?.parentPrivilege}#${conf.field}`;
    }
    return forkJoin([filter, converter, ...newConverterParams]).pipe(
      map(
        ([componentType, pipeType, ...converterParams]: any) =>
          ({
            field: conf.field,
            visible: conf.visible,
            visibleCard: conf.visibleCard,
            displayAllowed: conf.displayAllowed,
            dataType: conf.dataType,
            displayField: conf.displayField,
            header: conf.header,
            tooltip: conf.tooltip,
            cssClass: conf.cssClass,
            filterField: conf.filterField,
            sortField: conf.sortField,
            sortDisabled: conf.sortDisabled,
            sortType: conf.sortType,
            exportField: conf.exportField,
            exportDisabled: conf.exportDisabled,
            exportConverter: conf.exportConverter,
            scriptField: conf.scriptField,
            addUrlParam: conf.addUrlParam,
            nested: conf.nested,
            alwaysInQuery: conf.alwaysInQuery,
            defaultValue: conf.defaultValue,
            applyDefaultValueIfFilterMissing:
              conf.applyDefaultValueIfFilterMissing,
            customTqlExpression: conf.customTqlExpression,
            dynamicComponent: conf.dynamicComponent,
            readPrivilege: conf.readPrivilege,
            isExternal: true,
            inputWidget: conf.inputWidget,
            inputWidgetConfig: conf.inputWidgetConfig,
            inputWidgetPrivilege: inputWidgetPrivilege,
            inputWidgetSpelCode: conf.inputWidgetSpelCode,
            inputWidgetField: conf.inputWidgetField,
            width: conf.width ? conf.width.replace('px', '') + 'px' : '120px',
            defaultFieldPosition: conf.fieldPosition,
            favoritesOrder: conf.favoritesOrder,
            filterWidget: conf.filterWidget ? componentType : null,
            filterWidgetContext: conf.filterWidgetContext
              ? conf.filterWidgetContext.item
              : null,
            filterFulltextSearch: conf.filterFulltextSearch,
            converter: conf.converter ? pipeType : null, // na backendu bude jako string, protoze se neda serializovat class objekt
            converterParams:
              converterParams.length === 0 ? null : converterParams,
            convertOnBackend: !!conf.convertOnBackend,
            localizationData: conf.localizationData,
          }) as unknown as TableColumn,
      ),
    );
  }

  mapConvertPipeAndParameters(
    value: any,
    selector: string,
    parameters: any[],
  ): Observable<any> {
    let converter = of(null);
    let converterParameters = [];
    if (selector) {
      converter =
        this._cache.converters.get(selector) ||
        from(
          this.dynamicComponentService
            .resolvePipeFactoryAndModule(selector)
            .then(([factory, module]) => {
              return factory;
            }),
        );
      this._cache.converters.set(selector, converter);
    }

    if (parameters) {
      converterParameters = parameters.map((x) => {
        return this.dynamicComponentService.anyWithSelector(x)
          ? from(
              this.dynamicComponentService.resolvePipeFactoryAndModule(x),
            ).pipe(map(([factory, module]) => factory))
          : of(typeof x === 'string' ? x.replace("'", '').replace("'", '') : x);
      });
    }

    return forkJoin([
      of(value),
      converter,
      ...(converterParameters ?? []),
    ]).pipe(
      map(([val, nevimpipe, ...pipeParams]: any) => {
        if (nevimpipe) {
          const pipe = this.injector.get<PipeTransform>(
            nevimpipe as Type<PipeTransform>,
          );
          const result = pipe.transform(val, ...(pipeParams || []));
          if (isObservable(result)) {
            return result;
          } else {
            return of(result);
          }
        }
        return of(null);
      }),
    );
  }
}
