import {de, enUS, cs, sk} from 'date-fns/locale';

export function dateFnsLocaleByLangFunction(lang: string) {
  switch (lang) {
    case 'cs':
      return cs;
    case 'en':
      return enUS;
    case 'de':
      return de;
    case 'sk':
      return sk;
    default:
      return enUS;
  }
}
