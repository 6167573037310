import {Plugin} from '@tsm/framework/plugin';

export const accessDeniedPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: '',
      loadChildren: () =>
        import('@tsm/access-denied/components').then(
          (x) => x.AccessDeniedModule,
        ),
    },
  ],
  widgets: [],
};
