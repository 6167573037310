import {DesignerCategoryEnum, Plugin} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/audit/plugins-i18n';

export const auditPlugin: Plugin = {
  menus: [],
  routes: [],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/audit/widgets').then((x) => x.AuditWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/audit/widgets/entity-history/entity-history').then(
              (x) => x.EntityHistoryComponent,
            ),
          selector: 'tsm-entity-history',
          // TODO: add privilege check
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/audit/widgets/entity-history/entity-history-editor'
                ).then((x) => x.EntityHistoryEditorComponent),
              editorSelector: 'tsm-entity-history-editor',
              widgetOrLayout: 'layout',
              name: translationPlugin.registry.historyOfTSMEntity,
              // name: translationPlugin.registry.historyOfTSMEntity,
              categoryId: DesignerCategoryEnum.Audit,
              description: translationPlugin.registry.historyOfTSMEntity,
              icon: 'tsm-icon-history',
              value: {
                title: translationShared.shared.history,
                widget: {
                  type: 'tsm-entity-history',
                },
                type: 'layout',
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/audit/widgets/audit/audit').then(
              (x) => x.AuditComponent,
            ),
          selector: 'tsm-audit',
          // TODO: add privilege check
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import('@tsm/audit/widgets/audit/audit-editor').then(
                  (x) => x.AuditEditorComponent,
                ),
              editorSelector: 'tsm-audit-editor',
              widgetOrLayout: 'layout',
              name: translationPlugin.registry.auditOfTSMEntity,
              categoryId: DesignerCategoryEnum.Audit,
              description: translationPlugin.registry.auditOfTSMEntity,
              icon: 'tsm-icon-history',
              value: {
                title: translationShared.shared.audit,
                widget: {
                  type: 'tsm-audit',
                },
                type: 'layout',
              },
            },
          ],
        },
      ],
    },
  ],
};
