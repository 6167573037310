import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {
  DeleteForm,
  DeleteFormError,
  DeleteFormSuccess,
  DiffForm,
  DiffFormError,
  DiffFormSuccess,
  LoadAllForms,
  LoadAllFormsError,
  LoadAllFormsSuccess,
  LoadFormByCode,
  LoadFormByCodeError,
  LoadFormByCodeSuccess,
  LoadFormByEntitySpecificationSelector,
  LoadFormById,
  LoadFormByIdError,
  LoadFormByIdForceReload,
  LoadFormByIdSuccess,
  UpsertForm,
  UpsertFormError,
  UpsertFormSuccess,
} from '../actions';
import {CommonApiService} from '../services';
import {Form} from '../models';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {exhaustBy, withLatestCached} from '@tsm/framework/root';
import {of} from 'rxjs';
import {
  selectAllFormsSorted,
  selectEntitySpecificationById,
  selectFormByCode,
  selectFormById,
} from '../selectors';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  DeleteMenuItemsByIdInUrl,
  UpdateMenuItemsByIdInUrl,
} from '@tsm/layout/service';

@Injectable()
export class FormEffects {
  private readonly FORM_API_PATH = 'v1/forms';
  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAllForms),
      withLatestCached(() => this.store.select(selectAllFormsSorted(true))),
      exhaustMap(() =>
        this.commonApiService.getAll(this.FORM_API_PATH).pipe(
          map((env) =>
            env.success
              ? LoadAllFormsSuccess({
                  entities: env.data.map((form: Form) =>
                    this.appendIdToSchema(form),
                  ),
                })
              : LoadAllFormsError(env.error),
          ),
        ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFormById),
      withLatestCached(({id}) => this.store.select(selectFormById(id))),
      concatMap(([{id}, status]) =>
        status.data
          ? of(
              LoadFormByIdSuccess({entity: this.appendIdToSchema(status.data)}),
            )
          : this.commonApiService.getEntity(this.FORM_API_PATH, id).pipe(
              map((env) =>
                env.success
                  ? LoadFormByIdSuccess({
                      entity: this.appendIdToSchema(env.data),
                    })
                  : LoadFormByIdError({id: id, error: env.error}),
              ),
            ),
      ),
    ),
  );

  loadByEntitySpecificationSelector = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFormByEntitySpecificationSelector),
      switchMap(({selector, entitySpecId}) =>
        this.store
          .select(selectEntitySpecificationById(entitySpecId))
          .pipe(map((x) => ({selector, entitySpec: x?.data}))),
      ),
      filter(({entitySpec}) => entitySpec != null),
      map(({entitySpec, selector}) =>
        LoadFormByCode({code: entitySpec.formSpecification[selector]}),
      ),
    ),
  );

  loadByIdForceReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFormByIdForceReload),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.FORM_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadFormByIdSuccess({entity: this.appendIdToSchema(env.data)})
                : LoadFormByIdError({id: id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFormByCode),
      withLatestCached(({code}) => this.store.select(selectFormByCode(code))),
      exhaustBy(
        ([{code}, status]) => code,
        ([{code}, status]) =>
          status && status.data
            ? of(
                LoadFormByCodeSuccess({
                  entity: this.appendIdToSchema(status.data),
                }),
              )
            : this.commonApiService.getEntity(this.FORM_API_PATH, code).pipe(
                map((env) =>
                  env.success
                    ? LoadFormByCodeSuccess({
                        entity: this.appendIdToSchema(env.data),
                      })
                    : LoadFormByCodeError({code: code, error: env.error}),
                ),
              ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertForm),
      concatMap(({entity, redirect, listingId}) =>
        this.commonApiService
          .upsertEntity(this.FORM_API_PATH, entity, entity.id)
          .pipe(
            concatMap((env) => {
              if (env.success) {
                const actions = [];
                this.toastService.showToast(
                  translation.configFormService.effects.formSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );

                actions.push(
                  UpsertFormSuccess({
                    entity: this.appendIdToSchema(env.data),
                    listingId: listingId,
                    redirect: redirect,
                  }),
                );
                return actions;
              } else {
                this.toastService.showError(
                  env.error,
                  translation.configFormService.effects.formSaveFailure,
                );
                return [UpsertFormError(env.error)];
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteForm),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.FORM_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.configFormService.effects.formDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/forms']);
              }
              return DeleteFormSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.configFormService.effects.formDeleteError,
              );
              return DeleteFormError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertFormSuccess),
      tap(({redirect, entity, listingId}) => {
        if (redirect === true) {
          this.router.navigate(['/config/forms', entity.id]);
        }
      }),
      mergeMap(({entity, listingId}) => [
        RefreshDataAndClearSelected({id: listingId}),
        UpdateMenuItemsByIdInUrl({id: entity.id}),
      ]),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteFormSuccess),
      mergeMap(({id, listingId}) => [
        RefreshDataAndClearSelected({id: listingId}),
        DeleteMenuItemsByIdInUrl({id: id}),
      ]),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffForm),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.FORM_API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffFormSuccess({forms: env.data, listingId: listingId});
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffFormError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterDiffSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffFormSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private router: Router,
    private toastService: ToastService,
    private commonApiService: CommonApiService<Form, Form>,
  ) {}

  private appendIdToSchema(form: Form): Form {
    return {
      ...form,
      schema: {
        ...form.schema,
        id: form.id,
        code: form.code,
      },
    };
  }
}
