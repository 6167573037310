import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Worklog} from '../models';
import {
  CollapsedWorklog,
  DeleteDataWorklog,
  DeleteDataWorklogError,
  DeleteDataWorklogSuccess,
  EditDataWorklog,
  EditDataWorklogText,
  LoadDataWorklog,
  LoadDataWorklogError,
  LoadDataWorklogSuccess,
  SaveDataWorklog,
  SaveDataWorklogError,
  SaveDataWorklogSuccess,
} from '../actions';
import {isBefore} from 'date-fns';

export interface WorklogState {
  id: string;
  ownerId: string;
  ownerType: string;
  worklogs: Worklog[];
  login?: string;
  error: any;
  loading: boolean;
}

export const worklogAdapter: EntityAdapter<WorklogState> =
  createEntityAdapter<WorklogState>();
export const initialState: EntityState<WorklogState> =
  worklogAdapter.getInitialState({});

export const worklogsReducer = createReducer(
  initialState,

  on(LoadDataWorklog, (state, {ownerId, ownerType}) =>
    worklogAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ownerId: ownerId,
        ownerType: ownerType,
        worklogs: state.entities[ownerId + ownerType]
          ? state.entities[ownerId + ownerType]?.worklogs
          : [],
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadDataWorklogSuccess, (state, {ownerId, ownerType, worklogs}) => {
    const stateWorklogs = state.entities[ownerId + ownerType].worklogs;
    const newWorklogs = worklogs
      .sort((y, z) =>
        isBefore(new Date(y.whenInserted), new Date(z.whenInserted)) ? 1 : -1,
      )
      .map((x, index) => ({
        ...x,
        peditorVisibility: false,
        collapsed: index === 0 && !!x.description, // rozbalit prvni worklog jenom za predpokladu, ze ma vyplneny description
      }));
    const resultWorklogs = newWorklogs.map((nc) => {
      const stateWorklog = stateWorklogs.find((sc) => sc.id === nc.id);
      if (stateWorklog) {
        return {...nc, collapsed: stateWorklog.collapsed};
      }
      return nc;
    });
    return worklogAdapter.upsertOne(
      {
        id: ownerId + ownerType,
        ownerId: ownerId,
        ownerType: ownerType,
        worklogs: resultWorklogs,
        loading: false,
        error: null,
      },
      state,
    );
  }),

  on(LoadDataWorklogError, (state, {ownerId, ownerType, error}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(SaveDataWorklog, (state, {ownerId, ownerType}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(SaveDataWorklogSuccess, (state, {ownerId, ownerType, worklog}) => {
    const newWorklogs = [
      ...(state.entities[ownerId + ownerType]
        ? state.entities[ownerId + ownerType].worklogs.filter(
            (c) => c.id !== worklog.id,
          )
        : []),
      {
        ...worklog,
        peditorVisibility: false,
        collapsed: true,
      },
    ].sort((y, z) =>
      isBefore(new Date(y.whenInserted), new Date(z.whenInserted)) ? 1 : -1,
    );
    return worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          worklogs: newWorklogs,
          loading: false,
        },
      },
      state,
    );
  }),

  on(SaveDataWorklogError, (state, {ownerId, ownerType, error}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(DeleteDataWorklog, (state, {ownerId, ownerType}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteDataWorklogSuccess, (state, {ownerId, ownerType, worklogId}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          worklogs: state.entities[ownerId + ownerType]?.worklogs.filter(
            (c) => c.id !== worklogId,
          ),
          loading: false,
        },
      },
      state,
    ),
  ),

  on(DeleteDataWorklogError, (state, {ownerId, ownerType, error}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(EditDataWorklog, (state, {ownerId, ownerType, worklog, openEdit}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          worklogs: state.entities[ownerId + ownerType].worklogs.map((c) =>
            c.id === worklog.id
              ? {
                  ...c,
                  peditorVisibility: openEdit,
                  collapsed: worklog.collapsed,
                }
              : c,
          ),
        },
      },
      state,
    ),
  ),

  on(EditDataWorklogText, (state, {ownerId, ownerType, worklog, worklogText}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          worklogs: state.entities[ownerId + ownerType].worklogs.map((c) =>
            c.id === worklog.id ? {...c, worklog: worklogText} : c,
          ),
        },
      },
      state,
    ),
  ),

  on(CollapsedWorklog, (state, {ownerId, ownerType, worklog}) =>
    worklogAdapter.updateOne(
      {
        id: ownerId + ownerType,
        changes: {
          ...state.entities[ownerId + ownerType],
          worklogs: state.entities[ownerId + ownerType].worklogs.map((c) =>
            c.id === worklog.id ? {...c, collapsed: worklog.collapsed} : c,
          ),
        },
      },
      state,
    ),
  ),
);
