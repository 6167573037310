import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'primeng/api';
import {AutocompleteTreeComponent} from './autocomplete-tree/autocomplete-tree.component';
import {ChipsModule} from 'primeng/chips';
import {AutoCompleteModule} from '@tsm/framework/override-primeng/autocomplete';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChipsModule,
    AutoCompleteModule,
    FormsModule,
  ],
  declarations: [AutocompleteTreeComponent],
  exports: [AutocompleteTreeComponent],
})
export class AutocompleteTreeModule {}
