import {SelectItem} from 'primeng/api';
import {AbstractAuditedEntityPublic} from '@tsm/framework/root';

export interface Script extends AbstractAuditedEntityPublic<string> {
  name: string;
  code: string;
  description: string;
  content: string;
  dataTags: string;
  scriptType: ScriptType;
  scriptLanguage: string;
  pivotConfiguration?: any;
  chartConfiguration?: any;
  microservice?: string; // jedna se o kod microsluzby
  listing?: string; // jedna se o kod listingu
  paramsFormCode?: string;
}

export enum ScriptType {
  admin = 'ADMIN', // obecny skript - spel
  spel = 'SPEL', // spel
  groovy = 'GROOVY', // groovy
  ansible = 'ANSIBLE', // ansible playbook
  netmico = 'NETMICO', // python
  tql = 'TQL', // tql
  reporting = 'REPORTING', // tql
}

export function getAllScriptTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(ScriptType).forEach((val) =>
    items.push({label: 'shared.scriptType.' + ScriptType[val], value: val}),
  );
  return items;
}
