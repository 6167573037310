import {Pipe, PipeTransform} from '@angular/core';
import {FilterModel} from '../models';

@Pipe({
  name: 'tsmFiltersToStringPipe',
  pure: false,
})
export class TsmFiltersToStringPipe implements PipeTransform {
  transform(filters: FilterModel[]): string {
    return filters?.map((x) => x.displayValue).join(', ');
  }
}
