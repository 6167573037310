import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getLocalStateByKey, State} from '../reducers';
import {DeleteLocalState, SetLocalState} from '../actions';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalStateService {
  constructor(private store: Store<State>) {}

  setState(key: string, payload: any) {
    this.store.dispatch(
      SetLocalState({
        key,
        payload,
      }),
    );
  }

  getState<T = any>(key: string): Observable<T> {
    return this.store.pipe(
      select(getLocalStateByKey(key)),
      map((x) => x?.data),
    );
  }

  getStateInstant<T = any>(key: string): T {
    let temp = null;
    this.store
      .pipe(
        select(getLocalStateByKey(key)),
        map((x) => (x ? x.data : x)),
        take(1),
      )
      .subscribe((x) => {
        temp = x;
      });

    return temp;
  }

  removeState<T = any>(key: string) {
    this.store.dispatch(DeleteLocalState({key: key}));
  }
}
