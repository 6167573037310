import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as Effects from './effects';
import * as Reducers from './reducers';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';

export const IMPORTS = [
  StoreModule.forFeature('userParameters', Reducers.reducers),
  EffectsModule.forFeature([Effects.UserParameterEffects]),
  TranslateModule.forFeature(localizations),
];

