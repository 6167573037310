import {FilterModel} from '../models';

export const filtersFromQueryString = (
  filtersString: string,
): FilterModel[] => {
  if (!filtersString) {
    return [];
  }

  const parsed = JSON.parse(filtersString);

  if (Array.isArray(parsed)) {
    return parsed;
  } else {
    console.warn(
      `Parsing of querry string filters failed, returning empty array. Input string: '${filtersString}'`,
    );
    return [];
  }
};

export const filtersToQueryString = (
  filters?: FilterModel[],
): string | null => {
  if (!filters) {
    return null;
  }
  return JSON.stringify(filters);
};
