<dtl-grid
  class="mt-3 mr-3 ml-3"
  [pageTitle]="(translation.menuDesigner.menuSettings | transloco) + ' | tSM'"
>
  <dtl-col [cols]="6">
    <div class="border-1 surface-border border-round">
      <div
        class="border-bottom-1 surface-border px-3 py-2 flex align-items-center justify-content-between"
      >
        <span class="text-900 font-bold">{{
          translation.menuDesigner.resultMenu | transloco
        }}</span>
        <span class="flex gap-2">
          <tsm-button-confirm-popup
            label="{{ translation.menuDesigner.saveAllMenu | transloco }}"
            tsmTooltip="{{ translation.menuDesigner.saveAllMenu | transloco }}"
            class="p-button-success p-button-sm"
            icon="pi pi-check"
            (onAcceptClick)="saveMenu()"
            [progress]="loading$ | async"
          ></tsm-button-confirm-popup>
          <tsm-button-confirm-popup
            label="{{ translationShared.shared.discardAllChanges | transloco }}"
            tsmTooltip="{{
              translationShared.shared.discardAllChanges | transloco
            }}"
            (onAcceptClick)="discardAllChanges()"
            class="p-button-danger p-button-text p-button-sm"
            icon="pi pi-trash"
          ></tsm-button-confirm-popup>
        </span>
      </div>
      <div class="p-2 overflow-auto" style="height: calc(100vh - 140px)">
        <p-tree
          [value]="resultMenuList"
          [draggableNodes]="true"
          [droppableNodes]="true"
          [validateDrop]="true"
          (onNodeDrop)="onDrop($event, 'result')"
        >
          <ng-template pTemplate="empty">
            <div
              class="flex align-items-center justify-content-center gap-2 p-2 border-1 border-dashed surface-border w-full text-center border-round"
            >
              {{ translation.menuDesigner.emptyMenu | transloco }}
            </div>
          </ng-template>
          <ng-template let-node pTemplate="default">
            <div class="flex gap-2 align-items-center">
              <span>{{ node.label }}</span>
              <button
                pButton
                pRipple
                class="p-button-text p-button-info p-button-rounded p-button-sm"
                icon="pi pi-pencil"
                tsmTooltip="{{ translationShared.shared.edit | transloco }}"
                (click)="onEdit(node, true)"
              ></button>
            </div>
          </ng-template>
        </p-tree>
      </div>
    </div>
  </dtl-col>

  <dtl-col [cols]="6">
    <div class="border-1 surface-border border-round">
      <div
        class="border-bottom-1 surface-border px-3 py-2 flex align-items-center justify-content-between"
      >
        <span class="text-900 font-bold">{{
          translation.menuDesigner.tmpMenu | transloco
        }}</span>
        <span class="flex gap-2">
          <button
            pButton
            type="button"
            label="{{ translation.menuDesigner.addMenuItem | transloco }}"
            pTooltip="{{ translationShared.shared.createTooltip | transloco }}"
            (click)="showEditMenuItem = true"
            class="p-button-success p-button-text p-button-outlined p-button-sm"
            icon="pi pi-plus"
          ></button>
        </span>
      </div>
      <div class="p-2 overflow-auto" style="height: calc(100vh - 140px)">
        <p-tree
          [value]="tmpMenuList"
          [draggableNodes]="true"
          [droppableNodes]="true"
          [validateDrop]="true"
          (onNodeDrop)="onDrop($event, 'tmp')"
        >
          <ng-template pTemplate="empty">
            <div
              class="flex align-items-center justify-content-center gap-2 p-2 border-1 border-dashed surface-border w-full text-center border-round"
            >
              {{ translation.menuDesigner.emptyMenu | transloco }}
            </div>
          </ng-template>
          <ng-template let-node pTemplate="default">
            <div class="flex gap-2 align-items-center">
              <span>{{ node.label }}</span>
              <span>
                <button
                  pButton
                  pRipple
                  class="p-button-info p-button-text p-button-rounded p-button-sm"
                  icon="pi pi-pencil"
                  tsmTooltip="{{ translationShared.shared.edit | transloco }}"
                  (click)="onEdit(node, false)"
                ></button>
                <button
                  pButton
                  pRipple
                  class="p-button-danger p-button-text p-button-rounded p-button-sm"
                  icon="pi pi-times"
                  tsmTooltip="{{ translationShared.shared.delete | transloco }}"
                  (click)="onDelete(node)"
                ></button>
              </span>
            </div>
          </ng-template>
        </p-tree>
      </div>
    </div>
  </dtl-col>
</dtl-grid>

<tsm-layout-menu-item-editor
  *ngIf="showEditMenuItem"
  [menuItem]="selectedTreeNode?.data"
  (save)="onSaveEditMenuItem($event)"
  (cancel)="onCloseEditMenuItem()"
>
</tsm-layout-menu-item-editor>
