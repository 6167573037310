import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteAllRolePrivs,
  DeleteAllRolePrivsError,
  DeleteAllRolePrivsSuccess,
  DeleteRolePrivSuccess,
  LoadRolePrivsByRoleId,
  LoadRolePrivsByRoleIdError,
  LoadRolePrivsByRoleIdSuccess,
  SaveAllRolePrivs,
  SaveAllRolePrivsError,
  SaveAllRolePrivsSuccess,
  UpsertRolePrivSuccess,
} from '../actions';
import {RolePriv} from '../model';

export type RolePrivState = EntityState<IdentifiedPack<RolePriv>>;

export const adapter: EntityAdapter<IdentifiedPack<RolePriv>> =
  createEntityAdapter<IdentifiedPack<RolePriv>>({});

export const initialState: RolePrivState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadRolePrivsByRoleIdSuccess, (state, {entities}) => ({
    ...adapter.addMany(
      entities.map((rolePriv) => ({
        id: rolePriv.id,
        data: rolePriv,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(
    LoadRolePrivsByRoleId,
    SaveAllRolePrivs,
    DeleteAllRolePrivs,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadRolePrivsByRoleIdError,
    SaveAllRolePrivsError,
    DeleteAllRolePrivsError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertRolePrivSuccess, (state, {rolePriv}) =>
    adapter.upsertOne(
      {
        id: rolePriv.id,
        data: rolePriv,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteAllRolePrivsSuccess, (state, {ids}) => ({
    ...adapter.removeMany(ids, state),
    error: null,
    loading: false,
  })),
  on(SaveAllRolePrivsSuccess, (state, {rolePrivs}) => ({
    ...adapter.upsertMany(
      rolePrivs.map((rp) => ({
        id: rp.id,
        data: rp,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(DeleteRolePrivSuccess, (state, rolePriv) =>
    adapter.removeOne(rolePriv.id, state),
  ),
);
