import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Input,
  Optional,
} from '@angular/core';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {LocalizationVersionData} from '@tsm/framework/translate';
import {FluentFormsInputOptions} from '@tsm/framework/root/di';
import {
  LayoutedComponent,
  LayoutIdDirective,
} from '@tsm/framework/root/layout-id';
@Directive()
export abstract class ParentWidgetAccessorComponent
  extends AbstractControlValueAccessor
  implements FluentFormsInputOptions
{
  @Input() placeholder?: string;
  @Input() localizationData: LocalizationVersionData;

  protected constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
  ) {
    super(cdr);
  }

  @HostBinding('class.p-state-filled')
  get placeholderStyleClass(): boolean {
    return this.filled || (this.placeholder && this.placeholder !== '');
  }

  setInput(key: string, value: any) {
    this[key] = value;
    this.cdr.markForCheck();
  }
}
