import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  LoadCheckOnline,
  LoadCheckOnlineError,
  LoadCheckOnlineSuccess,
} from '../actions';
import {OnlineModel} from '../model';

export type CheckOnlineState = EntityState<IdentifiedPack<OnlineModel>>;

export const adapter: EntityAdapter<IdentifiedPack<OnlineModel>> =
  createEntityAdapter<IdentifiedPack<OnlineModel>>({});

export const initialState: CheckOnlineState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadCheckOnline, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadCheckOnlineSuccess, (state, {id, online}) =>
    adapter.upsertOne(
      {
        id,
        data: online,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadCheckOnlineError, (state, {id, error}) =>
    adapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),
);
