import {createAction, props} from '@ngrx/store';
import {MnemonicSequence} from '../models';

export const LoadMnemonicSequence = createAction(
  '[MnemonicConfig] Load MnemonicSequence',
);
export const LoadMnemonicSequenceSuccess = createAction(
  '[MnemonicConfig] Load MnemonicSequence Success',
  props<{
    entities: MnemonicSequence[];
  }>(),
);
export const LoadMnemonicSequenceError = createAction(
  '[MnemonicConfig] Load MnemonicSequence Error',
  props<{
    error: any;
  }>(),
);

export const UpsertMnemonicSequence = createAction(
  '[MnemonicConfig] Upsert MnemonicSequence',
  props<{
    mnemonicSequence: MnemonicSequence;
    listingId: string;
  }>(),
);
export const UpsertMnemonicSequenceSuccess = createAction(
  '[MnemonicConfig] Upsert MnemonicSequence Success',
  props<{
    mnemonicSequence: MnemonicSequence;
    listingId: string;
  }>(),
);
export const UpsertMnemonicSequenceError = createAction(
  '[MnemonicConfig] Upsert MnemonicSequence Error',
  props<{
    error: any;
  }>(),
);
