import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import {
  TOOLTIP_DATA,
  TOOLTIP_MAX_WIDTH,
  TOOLTIP_STATIC_POSITION,
  TooltipComponent,
  TooltipData,
} from '../components';
import {Overlay, OverlayRef, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Terminator} from '@tsm/framework/terminator';
import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import {isMobile} from '@tsm/framework/functions';

@Directive({
  selector: '[tsmTooltip]',
  providers: [Terminator],
})
export class TooltipDirective implements OnDestroy {
  @Input() tsmTooltip!: TooltipData;
  @Input() staticPosition: string;
  @Input() maxWidthRemTooltip: number = 28;

  private isMouseOver = false;
  private overlayRef: OverlayRef | null = null;
  private unsubscribe$ = new Subject();
  private showTimeout: any;
  private tooltipComponentVisited = false;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef,
    private terminator: Terminator,
    private zone: NgZone,
  ) {}

  @HostListener('mouseenter', ['$event'])
  @HostListener('focus', ['$event'])
  showTooltip($event: MouseEvent): void {
    this.isMouseOver = true;
    if (
      this.overlayRef?.hasAttached() === true ||
      !this.tsmTooltip ||
      isMobile()
    ) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.showTimeout = fromEvent(this.element.nativeElement, 'mousemove')
        .pipe(
          distinctUntilChanged(),
          debounceTime(300),
          take(1),
          takeUntil(this.terminator),
          // @ts-ignore
        )
        .subscribe((event: MouseEvent) => {
          if (this.isMouseOver) {
            this.runAttach(event);
          }
        });
    });
  }

  @HostListener('mouseleave', ['$event'])
  @HostListener('blur', ['$event'])
  @HostListener('click', ['$event'])
  hideTooltipEvent($event: MouseEvent): void {
    this.isMouseOver = false;
    if ($event.type == 'click' && this.overlayRef?.hasAttached() != true) {
      // mám ikonku info a jako správný klikač na ní najedu myší a kliknu. V tu chvíli se popup nezobazí a nevím co se děje...
      // pokud kliknu a neni jeste popup vykresleny tak nedelej nic
      return;
    }
    if (this.staticPosition == null) {
      this.hideTooltip();
    } else {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (!this.tooltipComponentVisited) {
            this.zone.run(() => {
              this.hideTooltip();
            });
          }
        }, 150);
      });
    }

    if (this.showTimeout) {
      this.showTimeout.unsubscribe();
      this.showTimeout = null;
    }
  }

  private runAttach(event: MouseEvent) {
    this.zone.run(() => {
      const bottom =
        this.element.nativeElement.getBoundingClientRect().y +
        this.element.nativeElement.getBoundingClientRect().height;

      const diffMouseBottom = bottom - event.clientY;
      const yDiff = -diffMouseBottom;

      const yAboveDiff =
        event.clientY - this.element.nativeElement.getBoundingClientRect().y;
      const left = this.element.nativeElement.getBoundingClientRect().x;
      const right =
        this.element.nativeElement.getBoundingClientRect().x +
        this.element.nativeElement.getBoundingClientRect().width;
      const leftX = event.clientX - left;
      const rightX = right - event.clientX;
      this.attachTooltip({
        belowY: yDiff,
        aboveY: yAboveDiff,
        rightX: rightX,
        leftX: leftX,
      });
    });
  }

  private attachTooltip(offsets: {
    leftX: number;
    rightX: number;
    belowY: number;
    aboveY: number;
  }): void {
    this.tooltipComponentVisited = false;
    const positionStrategy = this.getPositionStrategy(offsets);
    if (this.overlayRef === null) {
      this.overlayRef = this.overlay.create({
        positionStrategy: positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
      });

      if (this.staticPosition != null) {
        this.overlayRef
          .outsidePointerEvents()
          .pipe(takeUntil(this.terminator))
          .subscribe((x) => {
            this.hideTooltip();
          });
      }
    } else {
      this.overlayRef.updatePositionStrategy(positionStrategy);
    }

    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: this.tsmTooltip,
        },
        {
          provide: TOOLTIP_STATIC_POSITION,
          useValue: this.staticPosition,
        },
        {
          provide: TOOLTIP_MAX_WIDTH,
          useValue: this.maxWidthRemTooltip,
        },
      ],
    });

    if (this.overlayRef.hasAttached() === false) {
      const component = new ComponentPortal(
        TooltipComponent,
        this.viewContainer,
        injector,
      );
      const componentRef = this.overlayRef.attach(component);
      componentRef.instance.closeTooltip
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((_) => {
          this.hideTooltip();
        });

      componentRef.instance.onEnter
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((_) => {
          this.tooltipComponentVisited = true;
        });

      componentRef.changeDetectorRef.markForCheck();
    }
  }

  private hideTooltip() {
    if (this.overlayRef?.hasAttached() === true) {
      this.overlayRef?.detach();
      this.unsubscribe$.next(true);
    }
  }

  convertRemToPixels(rem) {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }

  private getPositionStrategy(offsets: {
    leftX: number;
    rightX: number;
    belowY: number;
    aboveY: number;
  }): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element.nativeElement)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          panelClass: 'bottom',
          offsetY: offsets.belowY + this.convertRemToPixels(0.5) + 2,
          offsetX: offsets.leftX + this.convertRemToPixels(0.5) + 2,
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          panelClass: 'bottom',
          offsetY: offsets.belowY + this.convertRemToPixels(0.5) + 2,
          offsetX: -offsets.rightX - this.convertRemToPixels(0.5) - 2,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          panelClass: 'bottom',
          offsetY: offsets.aboveY + this.convertRemToPixels(0.5) + 2,
          offsetX: offsets.leftX + this.convertRemToPixels(0.5) + 2,
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          panelClass: 'bottom',
          offsetY: -offsets.belowY - this.convertRemToPixels(0.5) - 2,
          offsetX: -offsets.rightX - this.convertRemToPixels(0.5) - 2,
        },
      ]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.overlayRef?.dispose();
  }
}
