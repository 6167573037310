import {createReducer, on} from '@ngrx/store';
import {ShowHotkeysOverlay, HideHotkeysOverlay} from '@tsm/framework/root';

export const initialState = false;

export const hotkeysReducer = createReducer(
  initialState,
  // message
  on(ShowHotkeysOverlay, () => true),
  on(HideHotkeysOverlay, () => false),
);
