import {Plugin} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';

export const exampleHelloworldPlugin: Plugin = {
  menus: [
    {
      key: 'devel',
      priority: 100,
      label: 'Development',
      icon: 'tsm-icon-chip',
      privilege: ['Dev.View'],
      items: [
        {
          key: 'KAFKA_TOPIC',
          label: 'menu.dms.kafkaTopic',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/dms/kafka-topic'],
          privilege: ['true'],
        },
        {
          key: 'hello',
          label: translationShared.menu.dms.helloWorld,
          icon: 'widgets',
          routerLink: ['/devel/hello'],
          privilege: ['true'],
        },
        {
          key: 'template',
          label: translationShared.menu.dms.ntr,
          icon: 'widgets',
          routerLink: ['/devel/template'],
          privilege: ['true'],
        },
        {
          key: 'vypoved-fraud-nt.component',
          label: translationShared.menu.dms.fraud,
          icon: 'widgets',
          routerLink: ['/devel/vypoved-fraud-nt.component'],
          privilege: ['true'],
        },
        {
          key: 'vzor-ucastnicke-smlouvy-nt-fyzicka-osoba',
          label: translationShared.menu.dms.bill,
          icon: 'widgets',
          routerLink: ['/devel/vzor-ucastnicke-smlouvy-nt-fyzicka-osoba'],
          privilege: ['true'],
        },
        {
          key: 'predavaci-servisni-protokol-fyzicka-osoba',
          label: translationShared.menu.dms.service,
          icon: 'widgets',
          routerLink: ['/devel/predavaci-servisni-protokol-fyzicka-osoba'],
          privilege: ['true'],
        },
        {
          key: 'predavaci-servisni-protokol-podnikatel',
          label: translationShared.menu.dms.serviceB2b,
          icon: 'widgets',
          routerLink: ['/devel/predavaci-servisni-protokol-podnikatel'],
          privilege: ['true'],
        },
        {
          key: 'predavaci-servisni-protokol-pravnicka-osoba',
          label: translationShared.menu.dms.serviceB2business,
          icon: 'widgets',
          routerLink: ['/devel/predavaci-servisni-protokol-pravnicka-osoba'],
          privilege: ['true'],
        },
        {
          key: 'faktura-jednorazova',
          label: translationShared.menu.dms.oneTimeBill,
          icon: 'widgets',
          routerLink: ['/devel/faktura-jednorazova'],
          privilege: ['true'],
        },
        {
          key: 'pew-reports-html',
          label: translationShared.menu.dms.pewReports,
          icon: 'widgets',
          routerLink: ['/devel/pew-reports-html'],
          privilege: ['true'],
        },
        {
          key: 'mail',
          label: translationShared.menu.dms.mail,
          icon: 'widgets',
          routerLink: ['/devel/mail'],
          privilege: ['true'],
        },
        {
          key: 'service-offer',
          label: translationShared.menu.dms.serviceOffer,
          icon: 'widgets',
          routerLink: ['/devel/service-offer'],
          privilege: ['true'],
        },
        {
          key: 'mobile-tree',
          label: translationShared.menu.dms.mobileTree,
          icon: 'widgets',
          routerLink: ['/devel/mobile-tree'],
          privilege: ['true'],
        },
        {
          key: 'custom-widgets',
          label: translationShared.menu.dms.customWidgets,
          icon: 'widgets',
          routerLink: ['/devel/custom-widgets'],
          privilege: ['true'],
        },
        {
          key: 'tsm-icon-picker',
          label: translationShared.menu.dms.iconPicker,
          icon: 'tsm-icon-development',
          routerLink: ['/devel/tsm-icon-picker'],
          privilege: ['true'],
        },
        {
          key: 'tsm-tiptap-editor-testing-environment',
          label: translationShared.menu.dms.tiptapEditor,
          icon: 'tsm-icon-development',
          routerLink: ['/devel/tsm-tiptap-editor-testing-environment'],
          privilege: ['true'],
        },
        {
          key: 'vstupni-formular',
          label: translationShared.menu.dms.form,
          icon: 'widgets',
          routerLink: ['/devel/vstupni-formular'],
          privilege: ['true'],
        },
        {
          key: 'a',
          label: 'a',
          icon: 'widgets',
          privilege: ['true'],
          items: [
            {
              key: 'a1-link',
              label: 'a1-link',
              icon: 'subject',
              routerLink: ['/a1-link'],
              privilege: ['true'],
            },
            {
              key: 'a2',
              label: 'a2',
              icon: 'subject',
              privilege: ['true'],
              items: [
                {
                  key: 'a2-link',
                  label: 'a2-link',
                  icon: 'subject',
                  routerLink: ['/a2-link'],
                  privilege: ['true'],
                },
              ],
            },
          ],
        },
        {
          key: 'b',
          label: 'b',
          icon: 'subject',
          privilege: ['true'],
          items: [
            {
              key: 'b1-link',
              label: 'b1-link',
              icon: 'subject',
              routerLink: ['/b1-link'],
              privilege: ['true'],
            },
            {
              key: 'b2',
              label: 'b2',
              icon: 'subject',
              privilege: ['true'],
              items: [
                {
                  key: 'b2-link',
                  label: 'b2-link',
                  icon: 'subject',
                  routerLink: ['/b2-link'],
                  privilege: ['true'],
                },
              ],
            },
          ],
        },
        {
          key: 'audit-sandbox',
          label: translationShared.menu.dms.audit,
          icon: 'widgets',
          routerLink: ['/devel/audit-sandbox'],
          privilege: ['true'],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'devel',
      loadChildren: () =>
        import('@tsm/example/hello-world').then(
          (x) => x.ExampleHelloWorldModule,
        ),
    },
  ],
  widgets: [],
};
