import {
  AsyncValidatorFn,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {
  fluentFilterControl,
  SKIP_CONSTANT,
} from '../utils/fluent-filter-control.utils';
import {isValid, parseISO} from 'date-fns';
import {JsonPointer} from 'tsm-json-schema-form-functions';

/**
 * @deprecated TsmFormControl is depcreated, please use TypedTsmFormControl
 */
export class TsmFormControl<T = any> extends FormControl<T> {
  public tsmLayoutIdForceReadonly = false;
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;

  private _options: {autoparsing: boolean; [key: string]: any};
  private defaults = null;
  public default = false;
  public readonly = false;
  public filterMap: {[dataPointer: string]: any} = null;
  public readonly value: T;
  private fm: any;
  private computed = false;
  private computedNotSave = false;
  private always = false;
  private isDefault = false;

  /** @internal */
  _onReadonly: Function[] = [];

  constructor(
    formState?: T,
    validator: ValidatorFn | ValidatorFn[] = null,
    asyncValidator: AsyncValidatorFn | AsyncValidatorFn[] = null,
    options: {autoparsing: boolean; [key: string]: any} = {autoparsing: true},
  ) {
    super(formState, validator, asyncValidator);
    this._options = options;
    this.defaults = this.value;
    this.touchedChanges = this._touchedChanges.asObservable();
  }

  setValue(
    value: T,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    // neresim computed protoze tohle se vola z FluentNodeRuntime pri setovani default hodnoty

    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }

    if (this._options.autoparsing === false) {
      super.setValue(value, options);
      return;
    }

    if (
      !!value &&
      (value as unknown as string).length > 8 &&
      isValid(parseISO(value + ''))
    ) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date' || date.getFullYear() > 9999) {
        super.setValue(value, options);
      } else {
        // TODO: tadle prasarna musi pryc asap
        super.setValue(date as any, options);
      }
    } else {
      super.setValue(value, options);
    }
  }

  /**
   * @param options - newDefault -> nasetuje value do defaults -> muzu pak zavolat resetWithDefaults a da mu to tam puvodni hodnoty
   */
  patchValue(
    value: T,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    // pokud je computed, tak me nezajima prichozi hodnota
    if (this.isDefault && (this.computed || this.computedNotSave)) {
      return;
    }

    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }

    // autoparsing == false zajisti to, ze se nesnazi hodnotu preves na objekt typu Date
    if (this._options.autoparsing === false) {
      super.setValue(value, options);
      return;
    }

    if (
      !!value &&
      (value as unknown as string).length > 8 &&
      isValid(parseISO(value + ''))
    ) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date' || date.getFullYear() > 9999) {
        super.patchValue(value, options);
      } else {
        // TODO: tadle prasarna musi pryc asap
        super.patchValue(date as any, options);
      }
    } else {
      super.patchValue(value, options);
    }
  }

  markAsPristineRecursively() {
    this.markAsPristine({onlySelf: true});
  }

  markAsDirtyRecursively() {
    this.markAsDirty({onlySelf: true});
  }

  markAsTouchedRecursively(markAsDirty = false) {
    this.markAsTouched({onlySelf: true});
    if (markAsDirty) {
      this.markAsDirty();
    }
  }

  markAsUntouchedRecursively(markAsPristine = false) {
    this.markAsUntouched();
    if (markAsPristine) {
      this.markAsDirty();
    }
    this.markAsPristine();
  }

  markAsTouched(opts: {onlySelf?: boolean} = {}): void {
    super.markAsTouched(opts);
    this._touchedChanges?.next(true);
  }

  markAsUntouched(opts: {onlySelf?: boolean} = {}): void {
    super.markAsUntouched(opts);
    this._touchedChanges.next(false);
  }

  markAsDefaultRecursively(): void {
    this.default = true;
  }

  markAsNonDefaultRecursively(value: any): void {
    // pokud je computed, tak me nezajima prichozi hodnota a vzdy zustane defaultni pokud defaultni existuje
    if (this.isDefault && (this.computed || this.computedNotSave)) {
      return;
    }
    this.default = false;
  }

  /**
   * Udela reset a nastavi defaultni hodnotu (ignoruje se atribut "default")
   * @param value
   * @param options
   */
  resetWithDefaults(options?: {onlySelf?: boolean; emitEvent?: boolean}) {
    this.reset(this.defaults, options);
  }

  /**
   * Udela reset a nastavi defaultni hodnotu, pokud je atribut "default" nastaven na TRUE
   * @param value
   * @param options
   */
  resetNonDefaultValues(
    value?: T,
    options?: {onlySelf?: boolean; emitEvent?: boolean},
  ) {
    if (!this.default) {
      this.reset(value, options);
    }
  }

  setDefaultValueRecursively(value: T) {
    this.defaults = value;
  }

  setFilterMapRecursively(filterMap) {
    this.filterMap = filterMap;
    this.fm =
      this.filterMap != null && this._options.dataPointer != null
        ? this.filterMap[this._options.dataPointer]
        : null;

    this.computed = this.fm?.persistent === 'Computed';
    this.computedNotSave = this.fm?.persistent === 'ComputedNotSave';
    this.always = this.fm?.persistent === 'Always';
    this.isDefault = this.fm?.isDefault;
  }

  /**
   * Odfiltrovava vsechny hodnoty podle logiky persistent
   */
  getFilteredValue(): T {
    if (!(this.default || this.computed || this.always)) {
      return this.value;
    }
    return this.filterMap != null && this._options.dataPointer != null
      ? fluentFilterControl(
          this.value,
          this._options.dataPointer,
          this.filterMap[this._options.dataPointer],
        )
      : this.value;
  }

  /**
   * Odfiltrovava vsechny hodnoty podle logiky persistent + dirty
   */
  getChangedValue(): T | typeof SKIP_CONSTANT {
    if (!(this.dirty || this.default || this.computed || this.always)) {
      return SKIP_CONSTANT;
    }
    const result =
      this.filterMap != null && this._options.dataPointer != null
        ? fluentFilterControl(this.value, this._options.dataPointer, this.fm)
        : this.value;

    // zustava nekde viset undefined, kdyz uz tam je zkonvertuj na null
    const undefinedPointers = [];
    if (
      result != null &&
      typeof result === 'object' &&
      Object.keys(result).length > 0
    ) {
      JsonPointer.forEachDeep(result, (v, p) => {
        if (v === undefined) {
          undefinedPointers.push(p);
        }
      });
    }
    undefinedPointers.forEach((p) => JsonPointer.set(result, p, null));
    return result;
  }

  setReadonly(readonly: boolean) {
    this.readonly = readonly;
    if (this._onReadonly.length) {
      this._onReadonly.forEach((changeFn) => changeFn(this.readonly));
    }
  }

  setForceReadonly(forceReadonly) {
    this.tsmLayoutIdForceReadonly = forceReadonly;
  }

  registerOnReadonly(fn: (readonly?: any) => void): void {
    this._onReadonly.push(fn);
  }

  /** @internal */
  _unregisterOnReadonly(): void {
    // kdyby dementi nedelali pole s jednou polozku kterou muzu odstranit jen tim, ze mam stejnou instanci tak by mi usnadnili zivot omg
    // removeListItem(this._onReadonly, fn);
    this._onReadonly = [];
  }

  get isRequired(): boolean {
    return this.hasValidator(Validators.required);
  }
}
