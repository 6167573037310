import {
  hasValue,
  inArray,
  isDefined,
  JsonPointer,
  toJavaScriptType,
  toSchemaType,
} from 'tsm-json-schema-form-functions';

export const SKIP_CONSTANT = '__SKIP__';

/**
 * Vola se pro objekt nebo pro pole hodnot
 * @param formValue
 * @returns objekt | pole s hodnotami co nejsou {}, [], null atd.
 * @returns objekt | pole s hodnotami co nejsou {}, [], null atd.
 */
export function fluentFilterSkipValues<T>(formValue: any): T {
  const pointersToRemove = [];
  JsonPointer.forEachDeep(formValue, (value, pointer) => {
    if (value === SKIP_CONSTANT) {
      pointersToRemove.push(pointer);
    }
  });
  pointersToRemove.forEach((x) => JsonPointer.remove(formValue, x));
  return formValue;
}

/**
 * Vola se pro objekt nebo pro pole hodnot
 * @param formValue
 * @returns objekt | pole s hodnotami co nejsou {}, [], null atd.
 * @returns objekt | pole s hodnotami co nejsou {}, [], null atd.
 */
export function fluentChangedSkipValues<T>(formValue: any): T {
  const pointersToRemove = [];
  JsonPointer.forEachDeep(formValue, (value, pointer) => {
    if (
      value === SKIP_CONSTANT ||
      (typeof value === 'object' &&
        !Array.isArray(value) &&
        !(value instanceof Date) &&
        value != null &&
        Object.keys(value).length === 0)
    ) {
      //  (typeof value === 'object' && Array.isArray(value) && value.length === 0)
      //  DUVOD zakomentovani: pokud prislo prazdne pole, tak se hodnota neposlala na backend -> problem ohledne dataTags, pokud se vsechny hodnoty smazaly
      // -> asi je zadouci, kdyz control je dirty tak aby se i prazdne posle poslalo -> odmazou se data v DB
      pointersToRemove.push(pointer);
    }
  });
  if (Object.keys(formValue).length > 0) {
    pointersToRemove.forEach((x) => JsonPointer.remove(formValue, x));
  }
  return formValue;
}

/**
 * Pokud je ReadonlyBased && Readonly anebo Persistent.Always tak vrati prazdnou value,
 * kterou ve formech nasledne vyfiltruje fluentFilterEmptyValues o uroven vys
 * @param formValue
 * @param pointer
 * @param filterMap
 * @param persistent
 * @returns null | {} | [] | formValue
 */
export function fluentFilterControl(
  formValue: any,
  pointer: string,
  filterMap: {[dataPointer: string]: any} = {},
) {
  // nenalezeno ve schematu
  if (filterMap == null) {
    console.error(
      'formatFormData error: ' +
        `Schema type not found for form value at ${pointer}`,
    );
    console.error('filterMap', filterMap);
    console.error('genericPointer', pointer);
    return formValue;
  }

  // nalezeno, res dale readonly a persistent
  const schemaType = filterMap.schemaType;
  // FIXME MZ - problem je v tom, kdyz readonly je jexl vyraz, tak sem prijde cely (hasPolicy('ticketDefaultPolicy', [$context.ticketWithTasks, 'Tick.Ticket.Edit']) == false) a nejako jiz vypocitana hodnota (true/false)
  const isReadonly = filterMap.readonly;
  const persistent = filterMap.persistent;
  if (
    (persistent === 'ReadonlyBased' && !isReadonly) ||
    persistent === 'Always' ||
    persistent === 'Computed'
  ) {
    if (schemaType === 'null') {
      return null;
    } else if (
      hasValue(formValue) &&
      inArray(schemaType, ['string', 'integer', 'number', 'boolean'])
    ) {
      const newValue =
        formValue === null
          ? toSchemaType(formValue, schemaType)
          : toJavaScriptType(formValue, schemaType);
      if (isDefined(newValue)) {
        return newValue;
      }
    } else if (schemaType === 'object') {
      // @ts-ignore
      filterMap.requiredFields.forEach((key) => {
        const filterMapFound = filterMap[`${pointer}/${key}`];
        if (filterMapFound != null) {
          const keySchemaType = filterMapFound.schemaType;
          if (keySchemaType === 'array') {
            return [];
          } else if (keySchemaType === 'object') {
            return {};
          }
        }
      });
    }
  } else {
    return SKIP_CONSTANT;
  }

  return formValue;
}
