export const en = {
  inventoryPlugins: {
    catalogSpecification: 'Catalog specification',
    inventoryChange: 'Change product or service',
    createTicket: 'Create ticket',
    filters: {
      tsmEntityActiveFilter: 'tSM Entity Active Filter',
      tsmEntityActiveFilterDescription:
        'Filters entity instances based on their active state. ' +
        'Displays a multi-select table with selectable active states. ' +
        'Fetches available states dynamically and applies filtering using an "in" operator. ' +
        'Provides selection tracking, all-selection support, and overlay panel interactions. ' +
        'Allows users to confirm or cancel their selection.',
      tsmEntityInstanceConfigurationFilter:
        'tSM Entity Instance Configuration Filter',
      tsmEntityInstanceConfigurationFilterDescription:
        'Filters entity instance configurations with a multi-select table. ' +
        'Fetches configurations from an API and allows selection by ID or key. ' +
        'Supports search filtering, sorting by code, and removal of selections. ' +
        'Uses an overlay panel with confirmation and cancellation options. ' +
        'Applies selected values as filter criteria using an "in" operator.',
      tsmEntityInstanceConfigurationStatusFilter:
        'tSM Entity Instance Configuration Status Filter',
      tsmEntityInstanceConfigurationStatusFilterDescription:
        'Filters entity instance configurations by status. ' +
        'Displays a multi-select table for selecting statuses. ' +
        'Fetches available statuses dynamically from the store. ' +
        'Supports filtering with search input and selection tracking. ' +
        'Applies sorting and pagination for efficient data handling. ' +
        'Allows users to confirm or cancel their selection.',
      tsmIpAddressFilter: 'tSM Ip Address Filter',
      tsmIpAddressFilterDescription:
        'Filters IP addresses with configurable comparison operators. ' +
        'Displays an input field for entering IP addresses. ' +
        'Supports equality and inequality filtering. ' +
        'Validates IP address format before applying the filter. ' +
        'Allows users to confirm or cancel their selection. ' +
        'Handles different operator contexts dynamically.',
    },
    pipes: {
      entityInstanceConfigurationKey: 'Entity Instance Configuration Key',
      entityInstanceConfigurationKeyDescription:
        'Fetches and formats entity instance configuration data based on the provided key and field. ' +
        'Retrieves the specified field or the entire data object using a cached observable. Ensures ' +
        'data is loaded from the store if not already available.',
      entityInstanceConfigurationStatusCode:
        'Entity Instance Configuration Status Code',
      entityInstanceConfigurationStatusCodeDescription:
        'Fetches and formats the status of entity instance configurations by code. Retrieves a specific ' +
        'field or the full configuration status using a cached observable. Automatically dispatches ' +
        'a load action if the status is not found in the store.',
      entityInstanceConfigurationStatus: 'Entity Instance Configuration Status',
      entityInstanceConfigurationStatusDescription:
        'Retrieves and displays the status of an entity instance configuration by ID. Allows selecting a ' +
        'specific field or fetching the complete status. Triggers a load action if the data is not ' +
        'available in the store.',
      entityInstanceConfiguration: 'Entity Instance Configuration',
      entityInstanceConfigurationDescription:
        'Fetches and displays entity instance configuration data by ID. Supports retrieving specific ' +
        'fields or the full configuration object. Automatically dispatches a load action if the data is ' +
        'not present in the store.',
    },
    entityInstanceLabels: 'Labels for Entity Instance',
  },
};
