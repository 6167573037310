import {createFeatureSelector, createSelector} from '@ngrx/store';
import {tableColumnConfigAdapter} from '../reducers/table-column-config.reducer';
import {ListingContainerState} from '../reducers';

const getListingContainerState =
  createFeatureSelector<ListingContainerState>('listing');

export const getTableColumnState = createSelector(
  getListingContainerState,
  (state) => state.columnConfig,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  tableColumnConfigAdapter.getSelectors(getTableColumnState);

export const selectTableColumnConfigById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectTableColumnConfigsByListingCode = (listingCode: string) =>
  createSelector(selectAll, (cols) =>
    cols
      .filter(
        (col) => !!col && !!col.data && col.data.codeListing === listingCode,
      )
      .map((col) => col.data),
  );

export const selectTableColumnConfigsByListingCodes = (
  listingCodes: string[],
) =>
  createSelector(selectAll, (cols) =>
    cols
      .filter(
        (col) =>
          !!col && !!col.data && listingCodes.includes(col.data.codeListing),
      )
      .map((col) => col.data),
  );
