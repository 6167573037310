import {Injectable} from '@angular/core';
import {IdentifiedPack, SharedRequestValidUntil} from '@tsm/framework/root';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {DtlDataSource} from '@tsm/framework/datasource';
import {FormatterDocumentTemplateService} from '../services';
import {FormatterDocumentTemplate} from '../models';

@Injectable()
export class FormatterDocumentTemplateDataSource
  implements DtlDataSource<IdentifiedPack<FormatterDocumentTemplate>>
{
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private formatterDocumentTemplateService: FormatterDocumentTemplateService,
  ) {}

  pushParams(params) {
    this.value.next(params);
  }

  @SharedRequestValidUntil()
  getRemoteData(
    id: string,
  ): Observable<IdentifiedPack<FormatterDocumentTemplate>> {
    return this.formatterDocumentTemplateService.getTemplateById(id).pipe(
      map((val) => {
        return {
          data: val.success ? val.data : null,
          id: val.data?.id,
          loading: false,
          error: false,
        };
      }),
    );
  }

  create(): Observable<IdentifiedPack<FormatterDocumentTemplate>> {
    return this.value.asObservable().pipe(
      filter((x: string[]) => x[0] != null),
      switchMap((x: string[]) => this.getRemoteData(x[0])),
    );
  }
}
