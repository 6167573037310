import {computed, Injectable, Signal, SimpleChange} from '@angular/core';

import {v4 as getUuid} from 'uuid';
import {select, Store} from '@ngrx/store';
import {distinctUntilChanged, Observable, of} from 'rxjs';

import {
  AddColumns,
  AddKanbanList,
  AllCheckboxChanged,
  ChangeColumn,
  ChangeFiltersKanbanList,
  ChangeKanbanList,
  ChangeOrderKanbanLists,
  ChangeSelectedProfile,
  ClearProfile,
  ClearTableStateByListingType,
  ColorsChanged,
  ColumnsChanged,
  ColumnsResized,
  CustomChangeSettingTable,
  DataViewChangeMode,
  DataViewChangeModeWithoutRefresh,
  DeleteProfile,
  DisableRefresh,
  DisableRefreshForAllProfiles,
  EnableRefresh,
  ExportingData,
  FilterChanged,
  FiltersChanged,
  FiltersChangedIfNew,
  FiltersChangedMerge,
  FiltersChangedMergeWithoutRefresh,
  FiltersChangedWithoutRefresh,
  HasBulkButtonsTemplateChanged,
  InitTable,
  PageAndPageSizeChanged,
  PageChanged,
  PageSizeChanged,
  PageSizeChangedWithoutRefresh,
  RefreshData,
  RefreshDataAndClearSelected,
  RefreshSwitchMapData,
  RefreshSwitchMapDataAndClearSelected,
  RemoveColumns,
  RemoveKanbanList,
  RemoveListing,
  ResetData,
  ResetTableStateToDefaultByCurrentProfile,
  RowIdFieldChanged,
  SaveAsDefaultAllProfile,
  SaveProfile,
  SaveUserDefaultProfile,
  ScrollHeight,
  ScrollWidth,
  SelectedRowIdChanged,
  SelectedRowIdsChanged,
  SetEmptyData,
  SetLoading,
  SetOptions,
  SetRefresh,
  SetTree,
  SetupProfileUrlFromExistingMemory,
  ShowBulkEditChanged,
  ShowConfigChanged,
  ShowConfigSet,
  SortsChanged,
  TqlExtendChanged,
  TqlQueryParamsChanged,
  TreeNodeExpandAll,
  TreeNodeExpandAllBackend,
  TreeNodeExpandCollapse,
  UpdateRowData,
  UrlChanged,
} from '../actions';
import {
  ColorModel,
  DataViewModeEnum,
  FilterModel,
  FilterOperator,
  ListingProfile,
  ListingType,
  RefreshModel,
  SortModel,
  Table,
  TableColumn,
  TableDefault,
  TableInplaceRowData,
  TableKanban,
  TableKeyType,
  TableShowConfig,
  TableTqlExtend,
  TableType,
  TreeTable,
} from '../models';
import {
  getDataViewMode,
  getEntitiesByIds,
  getRowEntities,
  getSelectedProfileId,
  getSelectedRowEntity,
  getSelectedRowId,
  getSelectedRowIds,
  isListingStatePresentById,
  selectListingStateById,
  selectListingTypeByCode,
} from '../selectors';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {DtlTableExporterService} from './dtl-table-exporter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RuntimeService} from '@tsm/runtime-info';
import {distinctArrays, getUserId} from '@tsm/framework/functions';
import FileSaver from 'file-saver';
import {ListingDataService} from './listing-data.service';
import * as jexl from 'jexl';
import {TranslocoService} from '@tsm/framework/translate';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable({
  providedIn: 'root',
})
export class ListingService {
  constructor(
    private state: Store,
    private router: Router,
    private runtimeService: RuntimeService,
    private exportService: DtlTableExporterService,
    private listingDataService: ListingDataService,
    private translocoService: TranslocoService,
    private activatedRoute: ActivatedRoute,
  ) {}

  /**
   * Removes listing state from ngrx store
   * @param id
   */
  removeListing(id: string): void {
    this.state.dispatch(RemoveListing({id}));
  }

  isListingStatePresentById(id: string): Observable<boolean> {
    return this.state.select(isListingStatePresentById(id));
  }

  isListingStatePresentByIdSignal(id: string): Signal<boolean> {
    return this.state.selectSignal(isListingStatePresentById(id));
  }

  /**
   * Ceka na stav az bude pritomen
   * Jiz ma v sobe take(1)
   * @param id tabulky
   * @param fce to co je potreba vykonat az dorazi stav
   * @returns observable
   */
  waitForStateToBePresent(
    id: string,
    fce: (table: Table) => void,
    checkSetUpTableSettings = false,
  ): void {
    this.getTableState(id)
      .pipe(
        filter(
          (x) =>
            x != null &&
            (checkSetUpTableSettings === true
              ? x.setUpTableSettings === true
              : true),
        ),
        // z duvodu toho, ze pokud fce modifikuje store, tak se funkce zavolala vickrat, takhle je jistota, ze to projde jenom jednou pokud je splneny filtr
        take(1),
        tap((x) => fce(x)),
      )
      .subscribe();
  }

  /**
   * Ceka na stav a dany profil az bude pritomen
   * Jiz ma v sobe take(1)
   * @param id tabulky
   * @param fce to co je potreba vykonat az dorazi stav
   * @returns observable
   */
  waitForStateAndProfileToBePresent(
    id: string,
    profileId,
    fce: (table: Table) => void,
    checkSetUpTableSettings = false,
  ): void {
    this.getTableState(id)
      .pipe(
        tap((x) => {
          if (
            x != null &&
            x.profiles.find((p) => p.id === profileId) == null &&
            (checkSetUpTableSettings === true
              ? x.setUpTableSettings === true
              : true)
          ) {
            console.warn(
              `Check exist/shared profileId: ${profileId} in listingType: ${x.type.type}`,
            );
          }
        }),
        filter(
          (x) =>
            x != null &&
            x.profiles.find((p) => p.id === profileId) != null &&
            (checkSetUpTableSettings === true
              ? x.setUpTableSettings === true
              : true),
        ),
        // z duvodu toho, ze pokud fce modifikuje store, tak se funkce zavolala vickrat, takhle je jistota, ze to projde jenom jednou pokud je splneny filtr
        take(1),
        tap((x) => fce(x)),
      )
      .subscribe();
  }

  /**
   * Resetne nastaveni tabulky
   */
  resetData(id: string) {
    this.waitForStateToBePresent(id, () => {
      this.state.dispatch(ResetData({id}));
    });
  }

  /**
   * Smaze vybrane zaznamy
   */
  refreshDataAndClearSelected(id: string) {
    this.state.dispatch(RefreshDataAndClearSelected({id}));
  }

  /**
   * Smaze vybrane zaznamy + switchMap u vice requestu -> posle posledni, ostatni se cancellnou
   */
  refreshSwitchMapDataAndClearSelected(id: string) {
    this.state.dispatch(RefreshSwitchMapDataAndClearSelected({id}));
  }

  /**
   * Zachovava vybrane zaznamy
   */
  refreshData(id: string) {
    this.state.dispatch(RefreshData({id}));
  }

  /**
   * Zachovava vybrane zaznamy + switchMap u vice requestu -> posle posledni, ostatni se cancellnou
   */
  refreshSwitchMapData(id: string) {
    this.state.dispatch(RefreshSwitchMapData({id}));
  }

  getTableState(id: string): Observable<Table> {
    return this.state.select(selectListingStateById(id));
  }

  /**
   * Vrati id vybraneho zaznamu tabulky
   * @param id table
   */
  getSelectedRowId(id: TableKeyType): Observable<any> {
    return this.state.select(getSelectedRowId(id));
  }

  /**
   * Vrati ids vybranych zaznamu tabulky
   * @param id table
   */
  getSelectedRowIds(id: TableKeyType): Observable<Array<string>> {
    return this.state.select(getSelectedRowIds(id)) as Observable<
      Array<string>
    >;
  }

  /**
   * Vrati oznaceny zaznam z tabulky
   * @param id table
   */
  getSelectedEntity(id: TableKeyType): Observable<any> {
    return this.state.select(getSelectedRowEntity(id));
  }

  /**
   * Vrati vybrane zaznamy z tabulky
   * @param id table
   * @deprecated - pouzivat misto toho getSelectedRowIds
   */
  getSelectedEntities(id: TableKeyType, rowIdKey?: string): Observable<any> {
    return this.state.select(selectListingStateById(id?.toString())).pipe(
      switchMap((state) => {
        if (!state) {
          return of([]);
        } else {
          // pokud nemam vybrane zaznamy, tak nevezmu zadny, jinak vsechny dle selectedRowIds
          return this.exportService
            .allDataFromTable({
              ...state,
              filters: distinctArrays('field', state.filters, [
                {
                  field: rowIdKey ? rowIdKey : state.defaults.rowIdField,
                  operator: FilterOperator.in,
                  value:
                    state.selectedRowIds?.length != 0
                      ? state.selectedRowIds
                      : ['00000000-0000-0000-0000-000000000000'],
                },
              ]),
            })
            .pipe(take(1))
            .pipe(filter((x) => x));
        }
      }),
    );
  }

  /**
   * Vrati zaznamy z tabulky podle idček
   * @param id table
   */
  getEntitiesByIds(id: TableKeyType, rowIdKeys: string[]): Observable<any[]> {
    return this.state.select(selectListingStateById(id?.toString())).pipe(
      switchMap((state) => {
        if (!state) {
          return of([]);
        } else {
          return this.state.select(getEntitiesByIds(id, rowIdKeys));
        }
      }),
    );
  }

  /**
   * Vrati view mode zobraneze tabulky z tabulky
   * @param id table
   * */
  getDataViewMode(id: string): Observable<DataViewModeEnum> {
    return this.isListingStatePresentById(id).pipe(
      filter((x) => x),
      switchMap((x) => this.state.select(getDataViewMode(id))),
    );
  }

  /**
   * Vrati view mode zobraneze tabulky z tabulky jako signal
   * @param id table
   * */
  getDataViewModeSignal(id: string): Signal<DataViewModeEnum | null> {
    const isStatePresent = this.isListingStatePresentByIdSignal(id);
    return computed(() => {
      if (!isStatePresent()) {
        return null;
      }
      return this.state.selectSignal(getDataViewMode(id))();
    });
  }

  /**
   * Vrati view mode zobraneze tabulky z tabulky
   * @param id table
   * */
  getListingType(id: string): Observable<ListingType> {
    return this.state.select(selectListingStateById(id)).pipe(
      filter((x) => !!x),
      switchMap((x) => this.state.select(selectListingTypeByCode(x.type.type))),
      map((x) => x?.data),
    );
  }

  /**
   * Akce aktualizuje zaznam primo v tabulce (pouze v pameti, nedochazi k odeslani hodnoty na server)
   */
  updateRowsData(id: string, items: TableInplaceRowData[]) {
    items
      .filter((item) => item.rowIndex != null)
      .forEach((item) => {
        this.state.dispatch(
          UpdateRowData({
            id: id,
            item: {rowData: item.rowData, rowIndex: item.rowIndex},
          }),
        );
      });
  }

  /**
   * Vrati idčka záznamů z tabulky (NEJSPISE JE TO DUPLICITNI K "changeRowEntities()")
   * Pokud se pouzije "waitForStateToBePresent()", tak je lepsi pouzit "changeRowEntities()")
   * @param id table
   */
  getRowEntities(id: TableKeyType): Observable<any[]> {
    return this.state.select(selectListingStateById(id?.toString())).pipe(
      switchMap((state) => {
        if (!state) {
          return of([]);
        } else {
          return this.state.select(getRowEntities(id));
        }
      }),
    );
  }

  /**
   * Vrati idčka záznamů z tabulky
   * @param id table
   */
  changeRowEntities(id: TableKeyType): Observable<any[]> {
    return this.state.select(getRowEntities(id?.toString()));
  }

  /**
   * Vrati filtry, ktere jsou aplikovane na tabulce
   * @param id table
   */
  getWhere(id: TableKeyType): Observable<string> {
    return this.state.select(selectListingStateById(id?.toString())).pipe(
      distinctUntilChanged((a, b) => {
        const x =
          a?.filters?.length == b?.filters?.length &&
          !a.filters.some(
            (x) => b.filters.find((y) => y?.field === x?.field) == null,
          );
        return x;
      }),
      map((table) => {
        if (!table) {
          return '';
        } else {
          if (table.type.queryLanguage == 'TQL') {
            return this.listingDataService.getWhereByTql(table);
          } else {
            return this.listingDataService.getWhere(table);
          }
        }
      }),
    );
  }

  // NEPOUZIVAT Z VENKU, POKUD NEVITE CO TO DELA PRESNE (POUZIVEJTE FUNKCI - getTableState())
  getTable(
    id: string,
    type: TableType,
    defaults?: TableDefault,
    showConfig?: TableShowConfig,
  ): Observable<Table> {
    if (defaults.rowIdField == null) {
      defaults.rowIdField = 'id';
    }
    if (!this.router.url.includes('?profile=')) {
      this.state.dispatch(
        SetupProfileUrlFromExistingMemory({
          payload: {id, type, defaults, showConfig},
        }),
      );
    }
    this.state.dispatch(InitTable({payload: {id, type, defaults, showConfig}}));
    return this.isListingStatePresentById(id).pipe(
      filter((x) => x),
      take(1),
      switchMap(() => this.state.select(selectListingStateById(id))),
    );
  }

  setSort(id: string, sorts: SortModel[]) {
    this.state.dispatch(SortsChanged({id: id, sorts: sorts}));
  }

  setUrl(id: string, url: string) {
    this.state.dispatch(UrlChanged({id: id, url}));
  }

  setColors(id: string, colors: ColorModel) {
    this.state.dispatch(ColorsChanged({id: id, colors: colors}));
  }

  setPage(id: string, page: number) {
    this.state.dispatch(PageChanged({id: id, page: page}));
  }

  setPageSize(id: string, pageSize: number, refreshData = true) {
    if (refreshData) {
      this.state.dispatch(PageSizeChanged({id: id, pageSize: pageSize}));
    } else {
      this.state.dispatch(
        PageSizeChangedWithoutRefresh({id: id, pageSize: pageSize}),
      );
    }
  }

  setPageAndPageSize(
    id: string,
    pageAndPageSize: {page: number; pageSize: number},
  ) {
    this.state.dispatch(
      PageAndPageSizeChanged({id: id, pageAndPageSize: pageAndPageSize}),
    );
  }

  /** Prenastavi vsechny filtry */
  setFilters(id: string, filters: FilterModel[]) {
    this.state.dispatch(FiltersChanged({id: id, filters: filters}));
  }

  /** Mergne současné a nové filtry a přenastaví */
  setFiltersMerge(id: string, filters: FilterModel[], refreshData = true) {
    if (refreshData) {
      this.state.dispatch(FiltersChangedMerge({id: id, filters: filters}));
    } else {
      this.state.dispatch(
        FiltersChangedMergeWithoutRefresh({id: id, filters: filters}),
      );
    }
  }

  setFiltersChangedWithoutRefresh(id: string, filters: FilterModel[]) {
    this.state.dispatch(
      FiltersChangedWithoutRefresh({id: id, filters: filters}),
    );
  }

  setShowConfig(id: string, showConfig: TableShowConfig) {
    this.state.dispatch(ShowConfigSet({id: id, showConfig}));
  }

  changeShowConfig(id: string, showConfig: TableShowConfig) {
    this.state.dispatch(ShowConfigChanged({id: id, showConfig}));
  }

  setLoading(id: string, loading = true) {
    this.state.dispatch(SetLoading({id: id, loading}));
  }

  /** Funkce, ktera da priznak, zda uzivatel zmenil nastaveni tabulky */
  customChangeSettingTable(id: string, change: boolean) {
    this.state.dispatch(CustomChangeSettingTable({id: id, change}));
  }

  /**
   * Prednastavi filtry pokud se lisi od tech aktualnich,
   * porovnava field, value, operator
   * @param id
   * @param filters
   */
  setFiltersIfNew(id: string, filters: FilterModel[]) {
    this.state.dispatch(FiltersChangedIfNew({id, filters}));
  }

  /** Upravi pouze jeden filter a zbytek necha */
  changeFilter(id: string, filter: FilterModel) {
    this.state.dispatch(FilterChanged({id: id, filter}));
  }

  setRowIdField(id: string, rowIdField: string) {
    this.state.dispatch(RowIdFieldChanged({id, rowIdField}));
  }

  setSelectedRowId(id: string, selectedRowId: TableKeyType) {
    this.state.dispatch(SelectedRowIdChanged({id, selectedRowId}));
  }

  setSelectedRowIds(id: string, selectedRowIds: TableKeyType[]) {
    this.state.dispatch(SelectedRowIdsChanged({id, selectedRowIds}));
  }

  setColumns(id: string, columns: TableColumn[]) {
    this.state.dispatch(ColumnsChanged({id: id, columns: columns}));
  }

  addColumns(id: string, columns: TableColumn[]) {
    this.state.dispatch(AddColumns({id: id, columns: columns}));
  }

  changeColumn(id: string, column: TableColumn) {
    this.state.dispatch(ChangeColumn({id: id, column: column}));
  }

  resizedColumns(id: string, columns: TableColumn[]) {
    this.state.dispatch(ColumnsResized({id: id, columns: columns}));
  }

  removeColumns(id: string, columns: TableColumn[]) {
    this.state.dispatch(RemoveColumns({id: id, columns: columns}));
  }

  setOptions(id: string, options: string) {
    this.state.dispatch(SetOptions({id: id, options: options}));
  }

  changeTqlExtend(id: string, tqlExtend: TableTqlExtend) {
    this.state.dispatch(TqlExtendChanged({id: id, tqlExtend: tqlExtend}));
  }

  changeTqlQueryParams(id: string, params: {[k: string]: any}) {
    this.state.dispatch(TqlQueryParamsChanged({id: id, params: params}));
  }

  setTree(id: string, tree: TreeTable) {
    this.state.dispatch(SetTree({id: id, tree}));
  }

  callBackup(
    id: string,
    backupRestore: {
      entityType?: string;
      fileName?: string;
      compareField?: 'id' | 'code';
    },
  ) {
    this.onBackup(id, backupRestore.fileName || backupRestore.entityType);
  }

  /**
   * Nastavi tabulku, jako kdyby zadne data nemela
   */
  setEmptyData(id: string) {
    this.waitForStateToBePresent(id, () => {
      this.state.dispatch(SetEmptyData({id}));
    });
  }

  getSelectedProfileId(id: string) {
    return this.state.select(getSelectedProfileId(id));
  }

  /**
   * MOZNE POUZIT MIMO LISTING LIB
   */
  setSelectedProfile(
    id: string,
    selectedProfileId: string,
    showConfig: TableShowConfig,
    setToStore = true,
  ): Promise<boolean> {
    const url = this.router.url;
    if (url.includes('dashboard') || showConfig.disableProfileUrl) {
      this.setSelectedProfileState(id, selectedProfileId, true, false);
      return Promise.resolve(true);
    } else {
      return this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {profile: selectedProfileId},
        queryParamsHandling: 'merge',
        replaceUrl: true,
        state: {
          setToStore,
          mergeProfileFilters: false,
        },
      });
    }
  }

  /**
   * NEVOLAT MIMO LISTING LIB
   */
  setSelectedProfileState(
    id: string,
    selectedProfileId?: string,
    setToStore = true,
    mergeProfileFilters = true,
  ) {
    if (setToStore) {
      selectedProfileId != null
        ? this.waitForStateAndProfileToBePresent(id, selectedProfileId, () => {
            this.state.dispatch(
              ChangeSelectedProfile({
                id,
                selectedProfileId,
                mergeProfileFilters,
              }),
            );
          })
        : this.waitForStateToBePresent(id, () => {
            this.state.dispatch(ClearProfile({id}));
          });
    }
  }

  setAllCheckbox(id: string, checked: boolean) {
    this.state.dispatch(AllCheckboxChanged({id: id, checked: checked}));
  }

  setShowBulkEdit(id: string, checked: boolean) {
    this.state.dispatch(ShowBulkEditChanged({id: id, checked: checked}));
  }

  hasBulkButtonsTemplateChanged(id: string, checked: boolean) {
    this.waitForStateToBePresent(id, () => {
      this.state.dispatch(
        HasBulkButtonsTemplateChanged({id: id, checked: checked}),
      );
    });
  }

  saveAsDefaultAllProfile(
    id: string,
    profile: ListingProfile,
    type: TableType,
    reloadData = true,
  ) {
    this.state.dispatch(
      SaveAsDefaultAllProfile({
        payload: {
          id,
          profile: profile,
          type: type,
          reloadData: reloadData,
        },
      }),
    );
  }

  saveAsDefaultProfile(
    id: string,
    profile: ListingProfile,
    type: TableType,
    reloadData = true,
  ) {
    const entity = {
      ...profile,
    };
    if (!entity.userId) {
      entity.userId = getUserId();
    }
    this.state.dispatch(
      SaveUserDefaultProfile({
        payload: {
          id,
          profile: entity,
          type: type,
          reloadData: reloadData,
        },
      }),
    );
  }

  saveProfile(
    id: string,
    profile: ListingProfile,
    type: TableType,
    reloadData = true,
  ) {
    const entity = {
      ...profile,
    };
    if (!entity.userId) {
      entity.userId = getUserId();
    }
    this.state.dispatch(
      SaveProfile({
        payload: {id, profile: entity, type: type, reloadData: reloadData},
      }),
    );
  }

  deleteProfile(
    id: string,
    profile: ListingProfile,
    type: TableType,
    reloadData = true,
  ) {
    this.state.dispatch(
      DeleteProfile({
        payload: {id, profile, type: type, reloadData: reloadData},
      }),
    );
  }

  clearProfile(id: string, showConfig: TableShowConfig) {
    const url = this.router.url;
    url.includes('dashboard') || showConfig.disableProfileUrl
      ? this.setSelectedProfileState(id, null)
      : this.router.navigate([], {
          relativeTo: this.activatedRoute,
          state: {
            setToStore: true,
            clear: true,
          },
        });
  }

  setScrollHeight(id: string, height: string) {
    this.state.dispatch(ScrollHeight({id: id, height: height}));
  }

  setScrollWidth(id: string, width: string) {
    this.state.dispatch(ScrollWidth({id: id, width: width}));
  }

  exportFromTable(t: Table | string, filename: string) {
    if (typeof t === 'string') {
      this.state.dispatch(ExportingData({id: t as string, value: true}));
      this.state
        .pipe(select(selectListingStateById(t as string)), take(1))
        .subscribe((table) => {
          if (table.type.queryLanguage == 'TQL') {
            this.exportService.exportByTql(table, filename);
          } else {
            this.exportService.exportFromTable(table, filename);
          }
        });
    } else {
      this.state.dispatch(ExportingData({id: t.id, value: true}));
      if (t.type.queryLanguage == 'TQL') {
        this.exportService.exportByTql(t, filename);
      } else {
        this.exportService.exportFromTable(t, filename);
      }
    }
  }

  // vezmu vsechny vybrane zaznamy z tabulky dle selectedRowIds (zajisteno pomoci filtru selectedRowIds) a pokud zadny neni vybrany, tak vezmu vsechny dle filtru
  getAllDataFromTable(t: Table | string, filterField: string = null) {
    if (typeof t === 'string') {
      return this.state
        .pipe(select(selectListingStateById(t as string)), take(1))
        .pipe(
          switchMap((state) => {
            let filterFieldValue;
            if (state.defaults.rowIdField) {
              filterFieldValue = state.columns.find(
                (x) => x.field === state.defaults.rowIdField,
              )?.filterField;
            }
            return this.exportService
              .allDataFromTable({
                ...state,
                filters: distinctArrays('field', state.filters, [
                  {
                    field:
                      filterField ||
                      filterFieldValue ||
                      state.defaults.rowIdField,
                    operator: FilterOperator.in,
                    value: state.selectedRowIds,
                  },
                ]),
              })
              .pipe(take(1));
          }),
        )
        .pipe(filter((x) => x));
    } else {
      return this.exportService
        .allDataFromTable({
          ...t,
          filters: distinctArrays('field', t.filters, [
            {
              field: filterField || t.defaults.rowIdField,
              operator: FilterOperator.in,
              value: t.selectedRowIds,
            },
          ]),
        })
        .pipe(take(1))
        .pipe(filter((x) => x));
    }
  }

  // funkce, ktera vrati aktualne vsechny zaznamy na strance
  getAllDataOnPageFromTable(
    t: Table | string,
    disableSelectedRows = false,
  ): Observable<any[]> {
    if (typeof t === 'string') {
      return this.state.pipe(
        select(selectListingStateById(t as string)),
        map((x) => x.data.items),
        take(1),
      );
    } else {
      return of(t.data.items).pipe(take(1));
    }
  }

  onRestore(
    listingId: string,
    file: any,
    compareField: 'id' | 'code' = 'id',
  ): Observable<any> {
    return this.state.pipe(
      select(selectListingStateById(listingId)),
      switchMap((table) => {
        const backupUrl = table.showConfig.backupRestore.url;
        let url: string;
        if (!!backupUrl) {
          url = backupUrl + '/diff';
        } else {
          url = table.type.url
            .replace('filtering', 'diff')
            .replace('page', 'diff');
        }
        return this.listingDataService.restoreData(url, file, table);
      }),
      filter((x) => !!x),
      take(1),
    );
  }

  onBackup(tableId: string, fileName: string) {
    const data: Observable<any> = this.state.pipe(
      select(selectListingStateById(tableId)),
      switchMap((table) =>
        this.listingDataService.backupData(table).pipe(
          filter((x) => x),
          map((x) => x?.body),
        ),
      ),
    );

    data.pipe(take(1)).subscribe((result) => {
      FileSaver.saveAs(
        result,
        'backup-' + fileName + '-' + new Date().toISOString() + '.zip',
      );
    });
  }

  generateId(prefix?: string): string {
    return `${prefix || ''}-${getUuid()}`;
  }

  /**
   * Resetuje nastaveni tabulky podle aktualniho profilu. Pokud neni zvoleny profil nastavi se na defaultni.
   * Pokud nema listing defaultni profil, pouzije se defaultni konfigurace tabulky
   */
  resetTableStateToDefaultByCurrentProfile(id: string) {
    this.state.dispatch(ResetTableStateToDefaultByCurrentProfile({id: id}));
  }

  clearTableStateByListingType(listingType: string) {
    this.state.dispatch(
      ClearTableStateByListingType({listingType: listingType}),
    );
  }

  /**
   * SEKCE PRO TREE
   */
  setTreeNodeExpandCollapse(
    id: string,
    nodeData: {nodeId: string; expanded: boolean},
  ) {
    this.state.dispatch(TreeNodeExpandCollapse({id: id, nodeData}));
  }

  setTreeNodeExpandedAllBackend(
    id: string,
    nodeData: {nodeId: string; expanded: boolean},
  ) {
    this.state.dispatch(TreeNodeExpandAllBackend({id: id, nodeData}));
  }

  treeNodeExpandAll(id: string, selectedRowId?: string) {
    this.state.dispatch(TreeNodeExpandAll({id: id, selectedRowId}));
  }

  // slouzi pro detekci zmeny mezi tree a table
  changeDataViewMode(
    id: string,
    dataViewMode: DataViewModeEnum,
    refreshData = true,
  ) {
    if (refreshData) {
      this.state.dispatch(
        DataViewChangeMode({id: id, changeMode: dataViewMode}),
      );
    } else {
      this.state.dispatch(
        DataViewChangeModeWithoutRefresh({id: id, changeMode: dataViewMode}),
      );
    }
  }

  /**
   * Zapíše do storu refresh (jen pokud je listing ve storu) a pokusi se odstartovat StartRefresh pokud je enabled
   * @param id
   * @param profileId
   * @param refresh
   */
  setRefreshIfPresent(
    id: string,
    profileId: string,
    refresh: RefreshModel,
    listingType: string = null,
    skipStart: boolean = null,
  ) {
    this.state.dispatch(
      SetRefresh({id, profileId, refresh, listingType, skipStart}),
    );
  }

  /**
   * Zapíše do storu refresh (pocka si az bude listing ve storu) a pokusi se odstartovat StartRefresh pokud je enabled
   * @param id
   * @param profileId
   * @param refresh
   */
  setRefresh(
    id: string,
    profileId: string,
    refresh: RefreshModel,
    listingType: string = null,
    skipStart: boolean = null,
  ) {
    this.waitForStateAndProfileToBePresent(id, profileId, () => {
      this.state.dispatch(
        SetRefresh({id, profileId, refresh, listingType, skipStart}),
      );
    });
  }

  /**
   * Odstartuje StartRefresh, nezapisuje nic do storu
   * @param id
   * @param profileId
   */
  enableRefresh(id: string, profileId: string) {
    this.waitForStateAndProfileToBePresent(id, profileId, () => {
      this.state.dispatch(EnableRefresh({id, profileId}));
    });
  }

  /**
   * Killne refreshování pro daný profil daného listingu, nezapisuje nic do storu
   * @param id
   * @param profileId
   */
  disableRefresh(id: string, profileId: string) {
    this.waitForStateAndProfileToBePresent(id, profileId, () => {
      this.state.dispatch(DisableRefresh({id, profileId}));
    });
  }

  /**
   * Killne refreshování pro všechny profily daného listingu, nezapisuje nic do storu
   * @param id
   * @param profileId
   */
  disableRefreshForAllProfiles(id: string) {
    this.state.dispatch(DisableRefreshForAllProfiles({id}));
  }

  setChangeFilter(
    defaultFilters: FilterModel[],
    change: SimpleChange,
    field: string,
    operator = FilterOperator.eq,
    visible = false,
    readonly = true,
  ): FilterModel[] {
    let tmpDefaultFilters = defaultFilters?.map((x) => ({...x})) || [];
    let isSameValue = !change || change.currentValue === change.previousValue;
    if (
      !!change &&
      Array.isArray(change.currentValue) &&
      Array.isArray(change.previousValue)
    ) {
      isSameValue = this.arraysEqual(change.currentValue, change.previousValue);
    }

    if (!isSameValue) {
      const changeFilter = tmpDefaultFilters.find((x) => x.field === field);
      if (changeFilter) {
        if (change.currentValue === null) {
          tmpDefaultFilters = tmpDefaultFilters.filter(
            (x) => x.field !== field,
          );
        } else {
          changeFilter.value = change.currentValue;
          changeFilter.displayValue = change.currentValue;
          changeFilter.operator = operator;
        }
      } else {
        tmpDefaultFilters.push({
          field: field,
          operator: operator,
          value: change.currentValue,
          visible: visible,
          displayValue: change.currentValue,
          readonly: readonly,
        });
      }
    }
    return tmpDefaultFilters;
  }

  setChangeFilterChars(
    defaultFilters: FilterModel[],
    charsSimpleChange: SimpleChange,
    prefixField: string,
    visible = false,
    readonly = true,
  ): FilterModel[] {
    let tmpDefaultFilters = defaultFilters?.map((x) => ({...x}));
    if (charsSimpleChange) {
      const currentValues = charsSimpleChange.currentValue;
      if (Array.isArray(currentValues)) {
        currentValues.forEach((currentValue) => {
          const changeFilter = tmpDefaultFilters.find(
            (x) => x.field === prefixField + '.' + currentValue.field,
          );
          if (changeFilter) {
            if (currentValue === null) {
              tmpDefaultFilters = tmpDefaultFilters.filter(
                (x) => x.field !== prefixField + '.' + currentValue.field,
              );
            } else {
              changeFilter.value = currentValue.value;
              changeFilter.displayValue = currentValue.value;
            }
          } else {
            tmpDefaultFilters.push({
              field: prefixField + '.' + currentValue.field,
              operator: currentValue.operation ?? FilterOperator.eq,
              value: currentValue.value,
              visible: visible,
              displayValue: currentValue.value,
              readonly: readonly,
            });
          }
        });
      } else {
        const changeFilter = tmpDefaultFilters.find(
          (x) => x.field === prefixField + '.' + currentValues.field,
        );
        if (changeFilter) {
          if (currentValues === null) {
            tmpDefaultFilters = tmpDefaultFilters.filter(
              (x) => x.field !== prefixField + '.' + currentValues.field,
            );
          } else {
            changeFilter.value = currentValues.value;
            changeFilter.displayValue = currentValues.value;
          }
        } else {
          tmpDefaultFilters.push({
            field: prefixField + '.' + currentValues.field,
            operator: currentValues.operation ?? FilterOperator.eq,
            value: currentValues.value,
            visible: visible,
            displayValue: currentValues.value,
            readonly: readonly,
          });
        }
      }
    }
    return tmpDefaultFilters;
  }

  arraysEqual(a, b): boolean {
    return a?.length === b?.length && a.every((v, i) => v === b[i]);
  }

  /**
   * KANBAN !!!
   */
  addKanbanList(id: string, kanban: TableKanban) {
    this.state.dispatch(AddKanbanList({id: id, kanban: kanban}));
  }

  changeKanbanList(id: string, kanban: TableKanban) {
    this.state.dispatch(ChangeKanbanList({id: id, kanban: kanban}));
  }

  changeFiltersKanbanList(id: string, kanban: TableKanban) {
    this.state.dispatch(ChangeFiltersKanbanList({id: id, kanban: kanban}));
  }

  removeKanbanList(id: string, listId: string) {
    this.state.dispatch(RemoveKanbanList({id: id, listId: listId}));
  }

  changeOrderKanbanLists(id: string, kanbans: TableKanban[]) {
    this.state.dispatch(ChangeOrderKanbanLists({id: id, kanbans: kanbans}));
  }

  getInputs(inputs: any, rowData: any, value: Observable<any>): any {
    return inputs
      ? {
          ...Object.keys(inputs).reduce((acc, item) => {
            if (
              (typeof inputs[item] === 'string' ||
                inputs[item] instanceof String) &&
              inputs[item].startsWith('${')
            ) {
              const rx = /\${(.*)\}/g;
              const exec = rx.exec(inputs[item] as string);
              if (exec) {
                const parsed = exec[1];
                const resultExpression = this.resultExpression(parsed, rowData);
                return {...acc, [item]: resultExpression};
              }
            }
            return {...acc, [item]: inputs[item]};
          }, {}),
          row: rowData,
          value: value,
        }
      : {row: rowData, value: value};
  }

  /**
   * Pokud je vyplneny selectItemFilters, tak se kontroluje, zda vybrany zaznam odpovida filtrum
   */
  checkSelectedItemByFilters(
    obj: any,
    filters: FilterModel[],
    type: TableType,
  ): boolean {
    return this.listingDataService.checkObjectAgainstFilters(
      obj,
      filters,
      type,
    );
  }

  private resultExpression(expression: string, value: any) {
    let tmpExpression = expression;
    let copyValue = {
      currentUserId: getUserId(),
    };
    const convertKeys = expression
      .match(/#([\w.]+)/g)
      ?.filter((x) => !!x)
      .map((x) => x.replace('#row.', ''));
    Object.keys(value).forEach((k) => {
      if (convertKeys?.includes(k)) {
        const newKey = k.split('.').join('__');
        tmpExpression = tmpExpression.split('#row.' + k).join(newKey);
        copyValue = {
          ...copyValue,
          [newKey]: value[k],
        };
      } else {
        copyValue = {
          ...copyValue,
          [k]: value[k],
        };
      }
    });
    try {
      tmpExpression = tmpExpression.split('row.').join('');
      return jexl.evalSync(tmpExpression, copyValue);
    } catch (ex) {
      console.error('Error parsing getInputs in dtl-data-view:' + ex);
      return expression;
    }
  }

  getFilterOperatorsTranslated(): Observable<{value: string; label: string}[]> {
    return this.translocoService.langChanges$.pipe(
      map(() => {
        return Object.keys(FilterOperator)
          .filter((x) => x !== 'dontTouch')
          .map((key) => FilterOperator[key])
          .map((value) => ({
            label: this.translocoService.translate(
              translationShared.shared.filterOperator[value],
            ),
            value: value,
          }));
      }),
    );
  }
}
