export enum SpelRequestEventType {
  START = 'START',
  VARIABLES = 'VARIABLES',
  STEP_OVER = 'STEP_OVER',
  CONTINUE = 'CONTINUE',
  STOP = 'STOP',
  ADD_BREAKPOINT = 'ADD_BREAKPOINT',
  REMOVE_BREAKPOINT = 'REMOVE_BREAKPOINT',
}

export enum SpelResponseEventType {
  RUNNING = 'RUNNING',
  SUSPENDED = 'SUSPENDED',
  STEP_OVER = 'STEP_OVER',
  CONTINUE = 'CONTINUE',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export interface ISpelRequest {
  type?:
    | SpelRequestEventType.START
    | SpelRequestEventType.VARIABLES
    | SpelRequestEventType.STEP_OVER
    | SpelRequestEventType.CONTINUE
    | SpelRequestEventType.STOP
    | SpelRequestEventType.ADD_BREAKPOINT
    | SpelRequestEventType.REMOVE_BREAKPOINT;
  expression?: string;
  position?: number;
  context?: string;
  breakpoints?: number[];
}

export interface ISpelResponse {
  status:
    | SpelResponseEventType.RUNNING
    | SpelResponseEventType.SUSPENDED
    | SpelResponseEventType.STEP_OVER
    | SpelResponseEventType.CONTINUE
    | SpelResponseEventType.FINISHED
    | SpelResponseEventType.ERROR;
  variables: ISpelVariablesResponse[];
  data: string;
  message: string;
  position: number;
}

export interface ISpelVariablesResponse {
  id?: number;
  name: string;
  preview: string;
  type: string;
  value: string;
}

export interface ISignalHighlightPayload {
  line: number;
  message: string;
}

export interface IBreakpointEvent {
  line: number;
  event:
    | SpelRequestEventType.ADD_BREAKPOINT
    | SpelRequestEventType.REMOVE_BREAKPOINT;
}
