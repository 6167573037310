import {TableData} from '../models';
import * as objectPath from 'object-path';

function hasParent(item: any, parentId: string, parentField: string) {
  const parent = item[parentField];
  return Array.isArray(parent)
    ? parent.includes(parentId)
    : parent === parentId;
}

export function getChildItems(
  data: TableData,
  rowIdField: string,
  parentField: string,
  parentItems?: any[],
) {
  const parentIds =
    parentItems != null
      ? parentItems.map((x) => x[rowIdField])
      : data.items.map((x) => x[rowIdField]);
  const subItems = data.items
    .filter((item) => !parentIds.some((id) => hasParent(item, id, parentField)))
    .filter((item) =>
      parentItems != null ? !parentIds.includes(item[rowIdField]) : true,
    );
  return subItems;
}

export function getParentValue(
  data: any[],
  parentValue: string,
  query: string,
): string {
  const rx = /getParentValue\(\'.*\'\)/g;
  const exec = rx.exec(query as string);
  if (exec[0] == null) {
    return parentValue;
  }
  const key = exec[0].replace("getParentValue('", '').replace("')", '');
  const item = data.find((x) => x.id === parentValue);
  const value = objectPath.get(item, [key]) || parentValue;
  return exec.input.replace(exec[0], value);
}

export function getValueFromGetParentValue(item: any, query: string): string {
  const rx = /getParentValue\(\'.*\'\)/g;
  const exec = rx.exec(query as string);
  if (exec[0] == null) {
    console.error('getParentValue not found in query');
  }
  const key = exec[0].replace("getParentValue('", '').replace("')", '');
  return objectPath.get(item, [key]);
}
