import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'flattenExceptKeys',
  pure: true,
})
export class FlattenExceptKeysPipe implements PipeTransform {
  transform(value: unknown, keys: string[]): any[] {
    return Object.keys(value)
      .filter((key) => !keys.includes(key))
      .map((key) => value[key]);
  }
}
