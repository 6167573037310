<!--  <p-divider align="center">-->
<!--    <span class="font-semibold border-1 border-500 px-4 py-2 surface-ground border-round-sm">Icons</span>-->
<!--  </p-divider>-->

<dtl-grid class="icons-grid gap-3 m-0 px-3 xl:px-0">
  <dtl-col *ngFor="let icon of filteredIcons">
    <ng-container *ngIf="!mixed">
      <div (click)="onIconSelected.emit(prefix.concat(icon))">
        <i class="{{ prefix.concat(icon) }}"></i>
        <span>{{ icon }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="mixed">
      <div (click)="onIconSelected.emit(icon)">
        <i class="{{ icon }}"></i>
        <span>{{ icon }}</span>
      </div>
    </ng-container>
  </dtl-col>
</dtl-grid>
