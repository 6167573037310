import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {}

  getMyDashboards(): Observable<any> {
    return this.apiService.get(
      `${this.config.value.apiUrls.tsmFormConfig}/forms/dashboard`,
    );
  }
}
