import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IframeKioskService} from '../services';
import {Observable} from 'rxjs';

@Component({
  selector: 'dtl-iframe-kiosk',
  template: `
    <ng-container *ngIf="showIframeKiosk$ | async as showIframeKiosk">
      <tsm-dialog
        *ngIf="showIframeKiosk.show"
        (close)="hiddenIframe()"
        header="Záznam"
        [maximizable]="true"
        [defaultMaximizable]="true"
      >
        <iframe
          *ngIf="showIframeKiosk.url"
          [src]="showIframeKiosk.url + '?kiosk=true' | safe: 'resourceUrl'"
          frameborder="0"
          width="100%"
          height="100%"
        >
        </iframe>
      </tsm-dialog>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeKioskComponent {
  showIframeKiosk$: Observable<{show: boolean; url: string}>;

  constructor(private iframeKioskService: IframeKioskService) {
    this.showIframeKiosk$ = this.iframeKioskService.getShowIframeKiosk();
  }

  hiddenIframe() {
    this.iframeKioskService.hideIframeKiosk();
  }
}
