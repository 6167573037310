import {
  Component,
  ElementRef,
  AfterContentInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  ChangeDetectorRef,
  Inject,
  forwardRef,
  TemplateRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
  OnInit,
} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Header, PrimeTemplate, BlockableUI} from 'primeng/api';
import {merge, Subject, Subscription} from 'rxjs';
import {LocalStateService} from '@tsm/framework/local-state';
import {map, takeUntil} from 'rxjs/operators';
import { NgClass, NgStyle, NgIf, NgTemplateOutlet } from '@angular/common';

let idx = 0;

@Component({
    selector: 'tsm-accordionTab',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    animations: [
        trigger('tabContent', [
            state('hidden', style({
                height: '0',
            })),
            state('visible', style({
                height: '*',
            })),
            transition('visible <=> hidden', [animate('{{transitionParams}}')]),
            transition('void => *', animate(0)),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'p-element',
    },
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgTemplateOutlet,
    ],
})
export class AccordionTab implements AfterContentInit, OnDestroy, OnInit {
  @ViewChild('content', {static: true}) contentViewChild: ElementRef;

  @Input() header: string;

  @Input() disabled: boolean;

  @Input() cache = true;

  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  @Input() transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';

  @ContentChildren(Header) headerFacet: QueryList<Header>;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  contentTemplate: TemplateRef<any>;

  headerTemplate: TemplateRef<any>;

  id = `tsm-accordiontab-${idx++}`;

  loaded: boolean;

  accordion: Accordion;

  observer: MutationObserver;

  private _selected: boolean;

  constructor(
    @Inject(forwardRef(() => Accordion)) accordion,
    public changeDetector: ChangeDetectorRef,
  ) {
    this.accordion = accordion as Accordion;
  }

  @Input() get selected(): any {
    return this._selected;
  }

  set selected(val: any) {
    this._selected = val;

    if (!this.loaded) {
      if (this._selected && this.cache) {
        this.loaded = true;
      }

      this.changeDetector.detectChanges();
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;

        case 'header':
          this.headerTemplate = item.template;
          break;

        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }

  ngOnInit() {
    // kvuli lazy loadovani obsahu, vsechno je OnPush, takze kdyz se nacte obsah pozdeji, nevyhodnoti se spravne vyska panelu
    this.observer = new MutationObserver(() => {
      this.changeDetector.detectChanges();
    });
    this.observer.observe(this.contentViewChild.nativeElement, {
      attributes: false,
      childList: true,
      subtree: false,
    });
  }

  // @ts-ignore
  toggle(event) {
    if (this.disabled) {
      return false;
    }

    const index = this.findTabIndex();

    if (this.selected) {
      this.selected = false;
      this.accordion.onClose.emit({originalEvent: event, index: index});
    } else {
      if (!this.accordion.multiple) {
        for (let i = 0; i < this.accordion.tabs.length; i++) {
          if (this.accordion.tabs[i].selected) {
            this.accordion.tabs[i].selected = false;
            this.accordion.tabs[i].selectedChange.emit(false);
            this.accordion.tabs[i].changeDetector.markForCheck();
          }
        }
      }

      this.selected = true;
      this.loaded = true;
      this.accordion.onOpen.emit({originalEvent: event, index: index});
    }

    this.selectedChange.emit(this.selected);
    this.accordion.updateActiveIndex();
    this.changeDetector.markForCheck();

    event.preventDefault();
  }

  findTabIndex() {
    let index = -1;
    for (let i = 0; i < this.accordion.tabs.length; i++) {
      if (this.accordion.tabs[i] == this) {
        index = i;
        break;
      }
    }
    return index;
  }

  get hasHeaderFacet(): boolean {
    return this.headerFacet && this.headerFacet.length > 0;
  }

  onKeydown(event: KeyboardEvent) {
    if (event.which === 32 || event.which === 13) {
      this.toggle(event);
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.accordion.tabs.splice(this.findTabIndex(), 1);
    this.observer.disconnect();
  }
}

@Component({
    selector: 'tsm-accordion',
    template: `
    <div
      [ngClass]="'p-accordion p-component'"
      [ngStyle]="style"
      [class]="styleClass"
      role="tablist"
    >
      <ng-content></ng-content>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'p-element',
    },
    standalone: true,
    imports: [NgClass, NgStyle],
})
export class Accordion implements BlockableUI, AfterContentInit, OnDestroy {
  @Input() saveAccordionState: string;
  // --> custom code

  @Input() multiple: boolean;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Output() onOpen: EventEmitter<any> = new EventEmitter();

  @Input() style: any;

  @Input() styleClass: string;

  @Input() expandIcon = 'pi pi-fw pi-chevron-right';

  @Input() collapseIcon = 'pi pi-fw pi-chevron-down';

  @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();

  @ContentChildren(AccordionTab) tabList: QueryList<AccordionTab>;

  // <!-- custom code
  protected subj: Subject<void> = new Subject();
  state = {};

  tabListSubscription: Subscription;

  private _activeIndex: any;

  preventActiveIndexPropagation: boolean;

  public tabs: AccordionTab[] = [];

  constructor(
    public el: ElementRef,
    public changeDetector: ChangeDetectorRef,
    private localStateService: LocalStateService,
  ) {
    // <!-- custom code
    const onClose$ = this.onClose.pipe(
      map((x) => ({type: false, index: x.index})),
    );
    const onOpen$ = this.onOpen.pipe(
      map((x) => ({type: true, index: x.index})),
    );

    merge(onClose$, onOpen$)
      .pipe(takeUntil(this.subj))
      .subscribe((x) => {
        this.tabs.forEach((tab, index) => {
          this.state = {...this.state, [index]: tab.selected};
        });

        if (this.saveAccordionState != null) {
          this.localStateService.setState(
            'accordionState::' + this.saveAccordionState,
            this.state,
          );
        }
      });
    // --> custom code
  }

  ngAfterContentInit() {
    this.initTabs();

    this.tabListSubscription = this.tabList.changes.subscribe((_) => {
      this.initTabs();
    });
  }

  initTabs(): any {
    this.tabs = this.tabList.toArray();
    this.updateSelectionState();
    this.changeDetector.markForCheck();

    const foundState = this.localStateService.getStateInstant(
      'accordionState::' + this.saveAccordionState,
    );
    this.tabs = this.tabList.toArray();
    if (foundState) {
      this.tabs.forEach((tab, index) => {
        if (foundState[index] === true) {
          tab.selected = true;
          // Here i lost my hope in humankind - load serves as "open and show" in primeng tabAccordion
          tab.loaded = true;
        }
        if (foundState[index] === false) tab.selected = false;
      });
    }
    this.updateSelectionState();
  }

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }

  @Input() get activeIndex(): any {
    return this._activeIndex;
  }

  set activeIndex(val: any) {
    this._activeIndex = val;
    if (this.preventActiveIndexPropagation) {
      this.preventActiveIndexPropagation = false;
      return;
    }

    this.updateSelectionState();
  }

  updateSelectionState() {
    if (this.tabs && this.tabs.length && this._activeIndex != null) {
      for (let i = 0; i < this.tabs.length; i++) {
        const selected = this.multiple
          ? this._activeIndex.includes(i)
          : i === this._activeIndex;
        const changed = selected !== this.tabs[i].selected;

        if (changed) {
          this.tabs[i].selected = selected;
          this.tabs[i].selectedChange.emit(selected);
          this.tabs[i].changeDetector.markForCheck();
        }
      }
    }
  }

  updateActiveIndex() {
    let index: any = this.multiple ? [] : null;
    this.tabs.forEach((tab, i) => {
      if (tab.selected) {
        if (this.multiple) {
          index.push(i);
        } else {
          index = i;
          return;
        }
      }
    });

    this.preventActiveIndexPropagation = true;
    this.activeIndexChange.emit(index);
  }

  ngOnDestroy() {
    if (this.tabListSubscription) {
      this.tabListSubscription.unsubscribe();
    }
    this.subj.next();
    this.subj.complete();
    this.subj = null;
  }
}
