import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DlqService {
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  massUpdateDlq(request: any): Observable<Envelope<any>> {
    return this.apiService.post(
      `${this.BASE_URL}/kafka-dlq/elastic/update-rows`,
      request,
    );
  }
}
