import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {
  Attachment,
  Notification,
  NotificationRelatedEntity,
  NotificationTemplate,
  NotificationThreadEmail,
  NotificationThreadEmailPublic,
  NotificationTo,
} from '../models';
import {RuntimeService} from '@tsm/runtime-info';
import {UserStatusType} from '../models/user-status.type';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly NOTIFICATION_RELATED_ENTITIES = `notification-related-entities`;
  private readonly RESEND = `RESEND`;
  private readonly USER_NOTIFICATION = `notifications`;
  private readonly PUBLIC_USER_NOTIFICATION = `v1/notifications`;
  private readonly USER_INFO_BY_ID = `userId`;
  private readonly BY_OWNER = `owner`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
    private runtimeService: RuntimeService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getNotificationById(id: string): Observable<Envelope<Notification>> {
    return this.apiService.get<Notification, Notification>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${id}`,
    );
  }

  getNotificationRelatedEntityByIdAndResend(
    id: string,
  ): Observable<Envelope<NotificationRelatedEntity[]>> {
    return this.apiService.get<
      NotificationRelatedEntity[],
      NotificationRelatedEntity[]
    >(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${id}/${this.NOTIFICATION_RELATED_ENTITIES}/${this.RESEND}`,
    );
  }

  getNotificationRelatedEntityByIdAndRefType(
    id: string,
    refType: string,
  ): Observable<Envelope<NotificationRelatedEntity[]>> {
    return this.apiService.get<
      NotificationRelatedEntity[],
      NotificationRelatedEntity[]
    >(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${id}/${this.NOTIFICATION_RELATED_ENTITIES}/ref-type/${refType}`,
    );
  }

  getCustomerByNotificationId(id: string): Observable<Envelope<any>> {
    return this.apiService.get<any, any>(
      `${this.config.value.apiUrls.tsmCustomer}/customers/notification/${id}`,
    );
  }

  getNotificationByUserId(
    userId: string,
  ): Observable<Envelope<Notification[]>> {
    return this.apiService.get<Notification[], Notification[]>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${this.USER_INFO_BY_ID}/${userId}`,
    );
  }

  getNotificationByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<Notification[]>> {
    return this.apiService.get<Notification[], Notification[]>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${this.BY_OWNER}/${ownerId}/${ownerType}`,
    );
  }

  saveNotification(
    notification: Notification,
  ): Observable<Envelope<Notification>> {
    return this.apiService.put<Notification, Notification>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${notification.id}`,
      notification,
    );
  }

  getNotificationThreadEmail(
    ownerId: string,
    ownerType: string,
  ): Observable<Envelope<NotificationThreadEmail[]>> {
    return this.apiService.get<
      NotificationThreadEmail[],
      NotificationThreadEmail[]
    >(
      `${this.BASE_URL}/${this.PUBLIC_USER_NOTIFICATION}/threads/${ownerType}/${ownerId}/emails`,
    );
  }

  saveNotificationThreadEmail(
    notification: NotificationThreadEmailPublic,
  ): Observable<Envelope<NotificationThreadEmailPublic>> {
    return this.apiService.post<
      NotificationThreadEmailPublic,
      NotificationThreadEmailPublic
    >(
      `${this.BASE_URL}/${this.PUBLIC_USER_NOTIFICATION}/threads`,
      notification,
    );
  }

  saveNotificationThreadEmailStatus(
    notificationId: string,
    userStatus: UserStatusType,
  ): Observable<Envelope<any>> {
    return this.apiService.post<any, any>(
      `${this.BASE_URL}/${this.PUBLIC_USER_NOTIFICATION}/${notificationId}/user-status/${userStatus}`,
      {},
    );
  }

  deleteNotificationById(id: string): Observable<Envelope<Notification>> {
    return this.apiService.delete<Notification, Notification>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${id}`,
    );
  }

  deleteNotificationWithAttachmentsById(
    id: string,
  ): Observable<Envelope<Notification>> {
    return this.apiService.delete<Notification, Notification>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/${id}/remove-attachments`,
    );
  }

  send(
    url: string,
    notificationTemplate: NotificationTemplate,
    ownerId: string,
    ownerType: string,
    from: string,
    deliveryType: string,
    relatedEntity: NotificationRelatedEntity[],
    notificationTo: NotificationTo[],
    contents?: string,
    attachments?: Attachment[],
    data?: any,
    subject?: string,
  ): Observable<Envelope<Notification>> {
    return this.apiService.post(url, {
      templateCode: notificationTemplate.code,
      ownerType: ownerType,
      ownerId: ownerId,
      sender: this.runtimeService.getCurrent().name,
      senderType: 'MANUAL',
      notificationTo: notificationTo,
      relatedEntities: relatedEntity,
      formatterDocumentTemplateDataOverride: {
        content: contents,
      },
      data: data,
      attachments: attachments,
      from: from,
      subject: subject,
    });
  }

  sendList(
    data: {
      notificationTemplate: string;
      ownerId: string;
      ownerType: string;
      from: string;
      deliveryType: string;
      relatedEntity: NotificationRelatedEntity[];
      notificationTo: NotificationTo[];
      contents?: string;
      attachments?: Attachment[];
      data?: any;
      subject?: string;
    }[],
  ): Observable<Envelope<Notification>> {
    const values = data.map((x) => ({
      templateCode: x.notificationTemplate,
      ownerType: x.ownerType,
      ownerId: x.ownerId,
      sender: this.runtimeService.getCurrent().name,
      senderType: 'MANUAL',
      notificationTo: x.notificationTo,
      relatedEntities: x.relatedEntity,
      formatterDocumentTemplateDataOverride: {
        content: x.contents,
      },
      data: x.data,
      attachments: x.attachments,
      from: x.from,
      subject: x.subject,
    }));
    return this.apiService.post(
      this.config.value.apiUrls.ticket + '/notifications/send/list',
      values,
    );
  }

  resend(
    url: string,
    notificationId: string,
    notificationTo: NotificationTo[],
  ): Observable<Envelope<Notification>> {
    return this.apiService.post(url, {
      notificationId: notificationId,
      notificationTo: notificationTo,
    });
  }
}
