// @dynamic
export class SharedUtils {
  // TODO: add en locale

  public static readonly locale_cs = {
    firstDayOfWeek: 1,
    dayNames: [
      'Neděle',
      'Pondělí',
      'Úterý',
      'Středa',
      'Čtvrtek',
      'Pátek',
      'Sobota',
    ],
    dayNamesShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pa', 'So'],
    dayNamesMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pa', 'So'],
    monthNames: [
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec',
    ],
    monthNamesShort: [
      'Led',
      'Úno',
      'Bře',
      'Dub',
      'Kvě',
      'Čvn',
      'Čvc',
      'Srp',
      'Zář',
      'Říj',
      'Lis',
      'Pro',
    ],
    today: 'Dnes',
    clear: 'Smazat',
  };

  public static readonly NONE = 'none';
}
