import {SelectItem} from 'primeng/api/selectitem';

export enum FormatterDocumentType {
  PDF = 'PDF',
  EMAIL = 'EMAIL',
  SOURCE = 'SOURCE',
  EXCEL = 'EXCEL',
}

export function getAllFormatterDocumentTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(FormatterDocumentType).forEach((val) =>
    items.push({label: FormatterDocumentType[val], value: val}),
  );
  return items;
}
