import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * Service for managing clipboard state across multiple browser tabs.
 * Uses BroadcastChannel API to synchronize clipboard state between different tabs
 * of the same application.
 * 
 * @example
 * ```typescript
 * export class MyComponent {
 *   constructor(private clipboardService: ClipboardService) {
 *     // Subscribe to clipboard state changes
 *     this.clipboardService.hasCopiedData$.subscribe(hasData => {
 *       // Handle clipboard state change
 *     });
 *   }
 * 
 *   onCopy() {
 *     // Set clipboard state to true when data is copied
 *     this.clipboardService.setCopiedData(true);
 *   }
 * 
 *   onPaste() {
 *     // Set clipboard state to false after pasting
 *     this.clipboardService.setCopiedData(false);
 *   }
 * }
 */
@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  // Channel name for cross-tab communication 
  private readonly CHANNEL_NAME = 'fluent-forms-clipboard';
  
  // BroadcastChannel instance for cross-tab communication
  private broadcastChannel: BroadcastChannel;
  
  // BehaviorSubject to track clipboard state 
  private hasCopiedData = new BehaviorSubject<boolean>(false);

  // Observable of clipboard state that components can subscribe to */
  hasCopiedData$ = this.hasCopiedData.asObservable();

  constructor() {
    this.broadcastChannel = new BroadcastChannel(this.CHANNEL_NAME);
    this.setupChannelListener();
  }

  /**
   * Sets up the listener for clipboard state changes from other tabs
   * @private
   */
  private setupChannelListener() {
    this.broadcastChannel.onmessage = (event) => {
      if (event.data.type === 'CLIPBOARD_UPDATE') {
        this.hasCopiedData.next(event.data.hasData);
      }
    };
  }

  /**
   * Updates the clipboard state and broadcasts the change to other tabs
   * @param hasData - Boolean indicating whether there is data in the clipboard
   */
  setCopiedData(hasData: boolean) {
    this.hasCopiedData.next(hasData);
    this.broadcastChannel.postMessage({
      type: 'CLIPBOARD_UPDATE',
      hasData
    });
  }
} 