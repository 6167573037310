import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import {from, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Envelope, ServerHttpOptions} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public get<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.get(url, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping),
      ),
      catchError((error) => of(error)),
    );
  }

  public getRaw<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions
      ? {...requestOptions, observe: 'response'}
      : {observe: 'response'};
    return this.http.get(url, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body) : resource.body,
        };
      }),
      catchError((error) => of(error)),
    );
  }

  public post<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.post(url, body, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping),
      ),
      catchError((error) => this.projectError<ToClientType>(error)),
    );
  }

  public postRaw<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions
      ? {...requestOptions, observe: 'response'}
      : {observe: 'response'};
    return this.http.post(url, body, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body) : resource.body,
        };
      }),
      catchError((error) => of(error)),
    );
  }

  public put<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.put(url, body, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping),
      ),
      catchError((error) => this.projectError<ToClientType>(error)),
    );
  }

  public putRaw<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions
      ? {...requestOptions, observe: 'response'}
      : {observe: 'response'};
    return this.http.put(url, body, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body) : resource.body,
        };
      }),
      catchError((error) => of(error)),
    );
  }

  public delete<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.delete(url, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping),
      ),
      catchError((error) => this.projectError<ToClientType>(error)),
    );
  }

  public deleteRaw<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions
      ? {...requestOptions, observe: 'response'}
      : {observe: 'response'};
    return this.http.delete(url, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body) : resource.body,
        };
      }),
      catchError((error) => of(error)),
    );
  }

  public patch<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.patch(url, body, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping),
      ),
      catchError((error) => this.projectError<ToClientType>(error)),
    );
  }

  public patchRaw<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions,
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions
      ? {...requestOptions, observe: 'response'}
      : {observe: 'response'};
    return this.http.patch(url, body, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body) : resource.body,
        };
      }),
      catchError((error) => of(error)),
    );
  }

  private buildResponse<ServerType, ClientType>(
    response: Envelope<ServerType> | ServerType,
    mapping?: (rawResponse: ServerType) => ClientType,
  ): Envelope<ClientType> {
    return {
      data: mapping != null ? mapping(response as ServerType) : response,
      success: true,
      resultCode: 'OK',
    } as Envelope<ClientType>;
  }

  private projectError<ClientType>(
    error: HttpErrorResponse,
  ): Observable<Envelope<ClientType>> {
    let er: any = error;
    if (error.error?.status) {
      er = error.error;
    } else if (error.error instanceof Blob) {
      return from(error.error.text()).pipe(
        map((text) => {
          let parsed: any;
          try {
            parsed = JSON.parse(text);
          } catch {
            parsed = text;
          }
          return {
            success: false,
            data: null,
            resultCode: error.status?.toString(),
            error: parsed,
          } as Envelope<ClientType>;
        }),
      );
    }
    return of({
      success: false,
      data: null,
      resultCode: er.status,
      error: er,
    } as Envelope<ClientType>);
  }
}
