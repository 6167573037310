export enum DialogNextstepsDefault {
  CLOSE = 'CLOSE',
  CREATE_NEW = 'CREATE_NEW',
  ENTITY_DETAIL = 'ENTITY_DETAIL',
}

export interface DialogNextsteps {
  default: DialogNextstepsDefault;
  createNewAllowed: boolean;
  entityDetailAllowed: boolean;
}
