import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {
  DeleteNotificationTemplate,
  DeleteNotificationTemplateError,
  DeleteNotificationTemplateSuccess,
  DiffNotificationTemplate,
  DiffNotificationTemplateError,
  DiffNotificationTemplateSuccess,
  LoadNotificationTemplateByCode,
  LoadNotificationTemplateByCodeError,
  LoadNotificationTemplateByCodeSuccess,
  LoadNotificationTemplateById,
  LoadNotificationTemplateByIdError,
  LoadNotificationTemplateByIdSuccess,
  LoadNotificationTemplatesByFilter,
  LoadNotificationTemplatesByFilterError,
  LoadNotificationTemplatesByFilterSuccess,
  UpsertNotificationTemplate,
  UpsertNotificationTemplateError,
  UpsertNotificationTemplateSuccess,
} from '../actions';
import {CommonApiService, NotificationTemplateService} from '../services';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {exhaustBy} from '@tsm/framework/root';
import {Router} from '@angular/router';
import {NotificationTemplate} from '../models';

@Injectable()
export class NotificationTemplateEffect {
  translation = translation;
  translationShared = translationShared;

  constructor(
    private actions$: Actions,
    private notificationTemplateService: NotificationTemplateService,
    private ts: ToastService,
    private router: Router,
    private commonApiService: CommonApiService<
      NotificationTemplate,
      NotificationTemplate
    >,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotificationTemplateById),
      mergeMap(({id}) =>
        this.notificationTemplateService.getNotificationTemplateById(id).pipe(
          map((env) =>
            env.success
              ? LoadNotificationTemplateByIdSuccess({
                  notificationTemplate: env.data,
                })
              : LoadNotificationTemplateByIdError({id, error: env.error}),
          ),
        ),
      ),
    ),
  );

  loadByFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotificationTemplatesByFilter),
      concatMap(({filters}) =>
        this.notificationTemplateService
          .getNotificationTemplatesByFilter(filters)
          .pipe(
            map((env) =>
              env.success
                ? LoadNotificationTemplatesByFilterSuccess({entities: env.data})
                : LoadNotificationTemplatesByFilterError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotificationTemplateByCode),
      mergeMap(({code}) =>
        this.notificationTemplateService
          .getNotificationTemplateByCode(code)
          .pipe(
            map((env) =>
              env.success
                ? LoadNotificationTemplateByCodeSuccess({
                    notificationTemplate: env.data,
                  })
                : LoadNotificationTemplateByCodeError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertNotificationTemplate),
      exhaustBy(
        (x) => x.notificationTemplate,
        ({notificationTemplate, listingId, redirectToDetail}) => {
          return this.notificationTemplateService
            .saveNotificationTemplate(notificationTemplate)
            .pipe(
              tap((env) => {
                if (env.success) {
                  this.ts.showToast(
                    translation.dmsService.succNotifTmplSave,
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                } else if (env.error) {
                  this.ts.showError(
                    env.error,
                    translation.dmsService.unSuccNotifTmplSave,
                  );
                }
              }),
              map((env) =>
                env.success
                  ? UpsertNotificationTemplateSuccess({
                      notificationTemplate: env.data,
                      listingId: listingId,
                      redirectToDetail: redirectToDetail,
                    })
                  : UpsertNotificationTemplateError(env.error),
              ),
            );
        },
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteNotificationTemplate),
      exhaustBy(
        (x) => x.id,
        ({id, listingId, shouldRedirectToListing}) => {
          return this.notificationTemplateService
            .deleteNotificationTemplateById(id)
            .pipe(
              tap((env) => {
                if (env.success) {
                  this.ts.showToast(
                    translation.dmsService.succNotifTmplDelete,
                    ToastSeverity.SUCCESS,
                    3000,
                  );
                  if (shouldRedirectToListing) {
                    this.router.navigate(['/dms/notification-template']);
                  }
                } else {
                  this.ts.showError(
                    env.error,
                    translation.dmsService.unSuccNotifTmplDelete,
                  );
                }
              }),
              map((env) =>
                env.success
                  ? DeleteNotificationTemplateSuccess({
                      id: id,
                      listingId: listingId,
                    })
                  : DeleteNotificationTemplateError(env.error),
              ),
            );
        },
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffNotificationTemplate),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/notification-templates', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffNotificationTemplateSuccess({
                  notificationTemplates: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffNotificationTemplateError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UpsertNotificationTemplateSuccess,
        DeleteNotificationTemplateSuccess,
        DiffNotificationTemplateSuccess,
      ),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  redirectToDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertNotificationTemplateSuccess),
      tap(({notificationTemplate, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            '/dms/notification-template/'.concat(notificationTemplate.id),
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
