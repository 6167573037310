import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';

import * as fromUserParameter from './user-parameter.reducer';

export interface UserParametersState {
  userParameters: fromUserParameter.UserParameterState;
}

export function reducers(
  state: UserParametersState | undefined,
  action: Action,
) {
  return combineReducers({
    userParameters: fromUserParameter.reducer,
  })(state, action);
}

export const metaReducers: MetaReducer<UserParametersState>[] = [];

const getUserParametersState =
  createFeatureSelector<UserParametersState>('userParameters');
export const getRuntimeInfo = createSelector(
  getUserParametersState,
  (x) => x.userParameters,
);
