import {FilterModel} from '../models';
import {compareFilterNoVal} from './compare-filters';

export function mergeFilters(
  oldFilters: FilterModel[],
  newFilters: FilterModel[],
): FilterModel[] {
  const result: FilterModel[] = [...oldFilters];
  newFilters.forEach((newFilter) => {
    const found = result.find((old) => compareFilterNoVal(old, newFilter));
    if (found) {
      const index = result.indexOf(found);
      if (index > -1) {
        result.splice(index, 1);
      }
    }
    result.push(newFilter);
  });
  return result;
}
