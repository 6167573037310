import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter} from '../reducers/user-parameter.reducer';
import {UserParametersState} from '../reducers';
import {UserParameterName} from '../model';
import {getUserId} from '@tsm/framework/functions';

const getUserParametersState =
  createFeatureSelector<UserParametersState>('userParameters');

export const getUserParameterState = createSelector(
  getUserParametersState,
  (userManagementState) => userManagementState.userParameters,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserParameterState);

export const selectUserParameterByUserIdAndName = (
  userId: string,
  name: UserParameterName,
) =>
  createSelector(selectAll, (entities) =>
    entities.find((param) => param.id === userId + name),
  );

export const selectUserParameterByCurrentUserAndName = (
  name: UserParameterName,
) =>
  createSelector(
    selectAll,
    (entities) =>
      entities.find((param) => param.id === getUserId() + name)?.data,
  );

export const selectUserParameterByUserIdsAndParameterName = (
  userIds: string[],
  name: UserParameterName,
) =>
  createSelector(selectAll, (entities) => {
    const paramIds = userIds.map((uid) => uid.concat(name));
    return entities.filter((ent) => paramIds.includes(ent.id));
  });
