import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteMicroservice,
  DeleteMicroserviceError,
  DeleteMicroserviceSuccess,
  DiffMicroservice,
  DiffMicroserviceError,
  DiffMicroserviceSuccess,
  LoadMicroservice,
  LoadMicroserviceByCode,
  LoadMicroserviceByCodeError,
  LoadMicroserviceByCodeSuccess,
  LoadMicroserviceById,
  LoadMicroserviceByIdError,
  LoadMicroserviceByIdSuccess,
  LoadMicroserviceError,
  LoadMicroserviceSuccess,
  UpsertMicroservice,
  UpsertMicroserviceError,
  UpsertMicroserviceSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {CommonApiService} from '../services';
import {Microservice} from '@tsm/characteristics/service';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {withLatestCached} from '@tsm/framework/root';
import {selectMicroserviceByCode} from '../selectors';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class MicroserviceEffects {
  private readonly MICROSERVICE_API_PATH = 'microservice';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMicroservice),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.MICROSERVICE_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadMicroserviceSuccess({microservices: env.data})
                : LoadMicroserviceError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMicroserviceById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.MICROSERVICE_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadMicroserviceByIdSuccess({microservice: env.data})
                : LoadMicroserviceByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMicroserviceByCode),
      withLatestCached(({code}) =>
        this.store.select(selectMicroserviceByCode(code)),
      ),
      concatMap(([{code}, status]) =>
        status && status.data
          ? of(LoadMicroserviceByCodeSuccess({microservice: status.data}))
          : this.commonApiService
              .getEntity(`v1/${this.MICROSERVICE_API_PATH}s`, code)
              .pipe(
                map((env) =>
                  env.success
                    ? LoadMicroserviceByCodeSuccess({microservice: env.data})
                    : LoadMicroserviceByCodeError({
                        code: code,
                        error: env.error,
                      }),
                ),
              ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMicroservice),
      concatMap(({microservice, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(
            this.MICROSERVICE_API_PATH,
            microservice,
            microservice.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.microserviceService.effects
                    .microserviceSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertMicroserviceSuccess({
                  microservice: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.microserviceService.effects.microserviceSaveError,
                );
                return UpsertMicroserviceError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMicroservice),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.MICROSERVICE_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.microserviceService.effects
                  .microserviceDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/microservice']);
              }
              return DeleteMicroserviceSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.microserviceService.effects.microserviceDeleteError,
              );
              return DeleteMicroserviceError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffMicroservice),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/microservices', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffMicroserviceSuccess({
                  microservices: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffMicroserviceError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMicroserviceSuccess),
      tap(({microservice, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/config/microservice', microservice.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMicroserviceSuccess, DiffMicroserviceSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private commonApiService: CommonApiService<Microservice, Microservice>,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
