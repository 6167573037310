import * as uiConfigReducer from './ui-config.reducer';
import {Action, combineReducers} from '@ngrx/store';
import {TsmUiConfigStateModel} from '../models';

export function reducers(
  state: TsmUiConfigStateModel | undefined,
  action: Action,
) {
  return combineReducers({
    uiConfig: uiConfigReducer.reducer,
  })(state, action);
}
