import {Injectable} from '@angular/core';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {ApiService, Envelope} from '@tsm/framework/http';
import {ConfigService} from '@tsm/framework/config';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {PopupFormOptions} from '../models';
import {ToastService} from '@tsm/framework/toast';
import {translation as translationShared} from '@tsm/shared-i18n';
import {ScriptData, StoreAction} from '@tsm/framework/fluent-forms';

@Injectable({
  providedIn: 'root'
})
export class FormActionsService {
  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private store: Store,
    private toastService: ToastService,
  ) {}

  script(data: ScriptData) {
    return this.runScript(
      data.scriptCode,
      data.scriptData,
      data.hideScriptError,
    ).pipe(
      map((result) => {
        let response: any;
        try {
          response = JSON.parse(result);
        } catch (e) {
          response = result;
        }

        if (typeof response === 'object') {
          if ((response as Envelope).success) {
            this.runActions(data.successActions, response.data);
          } else {
            this.runActions(data.errorActions, response.data);
          }
        } else {
          this.runActions(data.successActions, response);
        }
        return response;
      }),
    );
  }

  private runScript(scriptCode: string, body?: any, hideScriptError = false) {
    return this.apiService
      .get(
        `${this.configService.value.apiUrls.tsmFormConfig}/v1/scripts/${scriptCode}`,
      )
      .pipe(
        switchMap((script: Envelope) =>
          this.apiService.get(
            `${this.configService.value.apiUrls.tsmFormConfig}/microservice/code/${script.data.microservice}`,
          ),
        ),
        switchMap((microservice: Envelope) =>
          this.apiService.post(
            `${this.configService.value.apiUrls.base}${microservice.data.backendUrl}/v1/script/${scriptCode}/eval`,
            body || {},
          ),
        ),
        tap((res) => {
          if (!res.success && !hideScriptError) {
            this.toastService.showError(
              res.error,
              translationShared.shared.spelParserError,
              6000,
            );
          }
        }),
        map((x) => JSON.stringify(x, null, 2)),
        take(1),
      );
  }

  postRequest(postUrl: string, newValue: any) {
    return this.apiService
      .post(postUrl, newValue, null, {observe: 'response'})
      .pipe(take(1));
  }

  downloadFile(options: PopupFormOptions, mapping?: any) {
    if (options.formAction === 'getDownloadFile') {
      const params = Object.keys(mapping)
        .map((x) => mapping[x])
        .join('/');
      return this.apiService
        .get(options.getUrl + '/' + params, null, {
          observe: 'response',
          responseType: 'blob' as 'json',
        })
        .pipe(take(1));
    } else if (options.formAction === 'postDownloadFile') {
      return this.apiService
        .post(options.postUrl, mapping, null, {
          observe: 'response',
          responseType: 'blob' as 'json',
        })
        .pipe(take(1));
    }
    return of(null);
  }

  runActions(actions: StoreAction[], response = {}, forceResponse = false) {
    if (!Array.isArray(actions)) {
      return;
    }
    actions
      .filter((x) => x.action !== null && x.action?.trim() !== '')
      .forEach((action) => {
        let data = {
          ...(action.passScriptDataToAction || forceResponse
            ? {...response}
            : {}),
        };
        if (action.actionData) {
          if (typeof action.actionData === 'object') {
            data = {
              ...data,
              ...action.actionData,
            };
          } else {
            try {
              const parsedData = JSON.parse(action.actionData);
              data = {
                ...data,
                ...parsedData,
              };
            } catch (e) {}
          }
        }
        this.store.dispatch({
          type: action.action,
          payload: {
            ...data,
          },
          ...data,
        });
      });
  }
}
