// pokud dojde k uprave, je nutne upravit i backend (TsmConfigService.convertNameToCode)
export function normalizedNameToCodeOld(
  value: string,
  prefix: string = '',
  suffix: string = '',
): string {
  // napr. ("Ticketing / Moje - číselníky (ahoj, nazdar)")
  if (value == null) {
    return '';
  }
  // odstranit diakritiku ('Ticketing / Moje - ciselniky (ahoj, nazdar)')
  const withoutDiacritics = value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  // rozdleni podle mezer a odstranit vsechny znaky krome alfanumerickych (['Ticketing', 'Moje', 'ciselniky', 'ahoj', 'nazdar'])
  const keepAlphanumeric = withoutDiacritics
    .split(/[\s/-]+/)
    .map((w) => w.replace(/[^a-zA-Z0-9.]/g, ''))
    .filter((w) => !!w);
  // prvni pismeno vzdy udelat velke a spojit dohromady ('Ticketing.Moje.Ciselniky.Ahoj.Nazdar')
  const camelCase = keepAlphanumeric
    .map((w) => w.replace(/^(.)/, (firstLetter) => firstLetter.toUpperCase()))
    .join('.');
  // // nahradí po sobě jdoucí duplicitní znaky jedním znakem
  // const duplicityCharsRemoved = camelCase.replace(/(.)\1+/g, "$1")
  // pokud je posledni znak tecka tak ji nahradit
  const result = camelCase.replace(/\.$/, '');
  return prefix + result + suffix;
}

// Funkce odstraní diakritiku, normalizuje řetězec, převede slova na CamelCase a přidá prefix/příponu, pokud je zadána.
export function normalizedNameToCode(
  title: string,
  prefix?: string[],
  suffix?: string[],
): string {
  if (!title || title.trim() === '') {
    return '';
  }

  // 1. Odstranění diakritiky a oříznutí
  let normalized = title
    .normalize('NFD')
    .replace(/[\u0300-\u036f]+/g, '')
    .trim();

  // 2. Nahrazení všech sekvencí znaků ., -, /, _ jednou tečkou
  normalized = normalized.replace(/[.\-/_]+/g, '.');

  // 3. Odstranění všech znaků kromě písmen, číslic, mezer a teček
  normalized = normalized.replace(/[^a-zA-Z0-9. ]+/g, '');

  // 4. První písmeno každého slova velké
  normalized = normalized
    .split(' ')
    .map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
      return word;
    })
    .join(' ');

  // 5. Rozdělení podle teček a převod do CamelCase
  const camelParts = normalized.split('.').map((part) => {
    return part
      .split(' ')
      .map((word) => {
        if (word.length > 0) {
          // Každé slovo začne velkým písmenem, zbytek zachová původně (není .toLowerCase() aby se respektovalo předchozí zpracování)
          return word[0].toUpperCase() + word.slice(1);
        }
        return word;
      })
      .join('');
  });

  // 6. Spojení do stringu
  let code = camelParts.join('.');

  // 7. Odstranění speciálních znaků na začátku a na konci
  let result = code
    .replace(/^[.\-/_]+/, '')
    .replace(/[.\-/_]+$/, '')
    .trim();

  // Připojení prefixu a přípony, pokud jsou zadány
  const pre = prefix?.map((p) => p.trim()).join('.');
  const suff = suffix?.map((s) => s.trim()).join('.');
  if (pre && pre.trim() !== '') {
    result = `${pre}.${result}`;
  }
  if (suff && suff.trim() !== '') {
    result = `${result}.${suff}`;
  }
  return result;
}

// Funkce pro nalezení prvního speciálního znaku (kromě písmen, číslic a mezer) ve vstupu.
export function findFirstSpecialChar(input: string): string | null {
  if (!input || input.trim() === '') {
    return null;
  }
  for (const ch of input) {
    if (!/[a-zA-Z0-9\s]/.test(ch)) {
      return ch;
    }
  }
  return null;
}

// Funkce vygeneruje název, kde se oddělovač vybírá podle prvního speciálního znaku vstupního textu.
// Pokud nalezený speciální znak není mezi povolenými ("/", "-", "_", "."), použije se "/".
export function generateName(
  title: string,
  prefix?: string[],
  suffix?: string[],
): string {
  const tmpSpecChar = findFirstSpecialChar(title) || '/';
  const allowedSpecChars = ['/', '-', '_', '.'];
  const specChar = allowedSpecChars.includes(tmpSpecChar) ? tmpSpecChar : '/';
  const pre = prefix?.map((p) => p.trim()).join(` ${specChar} `);
  const suff = suffix?.map((s) => s.trim()).join(` ${specChar} `);

  // Odstranění speciálních znaků na začátku a na konci, nahrazení vícenásobných mezer jednou.
  let result = title
    .replace(/^[.\-/_]+/, '')
    .replace(/[.\-/_]+$/, '')
    .replace(/\s{2,}/g, ' ')
    .trim();

  if (pre && pre.trim() !== '') {
    result = `${pre} ${specChar} ${result}`;
  }
  if (suff && suff.trim() !== '') {
    result = `${result} ${specChar} ${suff}`;
  }
  return result;
}

export function convertToSnakeCase(input: string, char: string = '_'): string {
  // 1. Vložíme oddělovač před každé velké písmeno a převedeme ho na malá písmena.
  let result = input.replace(/([A-Z])/g, (match) => char + match.toLowerCase());

  // 2. Nahrazujeme všechny znaky, které nejsou malá písmena nebo číslice, oddělovačem.
  result = result.replace(/[^a-z0-9]/g, char);

  // 3. Odstraníme oddělovač na začátku a na konci řetězce.
  result = result.replace(new RegExp(`^\\${char}+|\\${char}+$`, 'g'), '');

  // 4. Sloučíme vícenásobné výskyty oddělovače do jednoho.
  result = result.replace(new RegExp(`\\${char}+`, 'g'), char);

  return result;
}

export function removeDiacritics(
  value: string,
  prefix: string = '',
  suffix: string = '',
): string {
  if (value == null) {
    return '';
  }
  const withoutDiacritics = value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return prefix + withoutDiacritics + suffix;
}
