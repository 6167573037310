import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput, TsmFormControl} from '@tsm/framework/forms';
import {
  ParentWidgetAccessorComponent,
  useParentWidgetProvidersFor,
} from '@tsm/framework/parent-widget';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {FormInputTextComponent} from '../form-input-text/form-input-text.component';
import {NgIf} from '@angular/common';
import {IconPickerModule} from '@tsm/framework/root/icon-picker';

@Component({
  selector: 'tsm-form-input-icon-picker',
  templateUrl: './form-input-icon-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputIconPickerComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputIconPickerComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputIconPickerComponent),
  ],
  standalone: true,
  imports: [FormInputTextComponent, FormsModule, NgIf, IconPickerModule],
})
export class FormInputIconPickerComponent extends ParentWidgetAccessorComponent {
  @ViewChild('inputElement', {static: true}) inputElement: ElementRef;

  @Input() required = false;

  @Input() inputId: string;

  @Input() setAutofocus: boolean;

  @Output() iconSelected: EventEmitter<any> = new EventEmitter();

  formControl = new TsmFormControl();

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  selectIcon(icon: string) {
    this.val = icon;
    this.iconSelected.emit(icon);
  }
}
