export const cs = {
  root: {
    title: 'Vítejte v této aplikaci!',
  },
  toast: {
    error: 'Chyba',
    success: 'Úspěch',
    info: 'Info',
    warn: 'Varování',
    downloadLog: 'Stáhnout logy',
    downloadLogDescription:
      'Chcete-li chybu reportovat, stáhněte prosím logy (tlačítko níže) a přiložte je k reportu.',
    showMore: 'Zobrazit více',
    showLess: 'Zobrazit méně',
    traceIdLabel: 'Trace ID',
    traceIdNotAvailable: 'Trace ID není dostupné',
    statusLabel: 'Status',
    statusNotAvailable: 'Status není dostupný',
    timestampLabel: 'Čas',
    timestampNotAvailable: 'Čas chyby není dostupný',
    thirdPartySystemsNotAvailable: 'Systémy třetích stran nejsou dostupné',
  },
  shortcuts: {
    keyboardShortcuts: 'Klávesové zkratky',
  },
  swUpdate: {
    message:
      'Aktualizace je téměř hotová. Uložte si prosím rozdělanou práci, aktualizaci dokončíte kliknutím na tlačítko restart.',
    startIn1:
      'Instalace bude zahájena automaticky při přechodu na další stránku.',
    loading1:
      'Byly nalezeny dostupné aktualizace, probíhá stahování. Počkejte prosím...',
    loading2: 'Probíhá instalace aktualizací, chvilku strpení....',
  },
  registry: {
    translate: 'Translates a text',
    json: 'Converts any object into json string',
    jsonParse: 'Parses a string to json object',
    seconds: 'Calculates seconds for a given date',
    nidValid: 'Validates personal identification number',
    currentUserId: 'Returns current user id',
    banValid: 'Validated account number',
    nidToDate: 'Converts personal identification number to date',
    minutes: 'Calculates minutes for a given date',
    hours: 'Calculates hours for a given date',
    addTime: 'Adds time for given type (seconds, minutes, hours...)',
    length: 'Calculates length of a value',
    round: 'Rounds a number',
    sum: 'Sums numbers',
    sumMany: 'Sums numbers for a given array of object and a specified field',
    transformNumber:
      'Transforms decimal into given numbers behind the floating point',
    now: 'Creates iso string of a current date',
    nowWithHours:
      'Creates iso string of a current date. Possible to set hours, minutes and seconds.',
    substring: 'Get a substring for a given string',
    split: 'Splits a string into and array for a given delimiter',
    replaceDoubleQuotesToSingle: 'Replaces double quotes to single ones',
    replace: 'Replaces a substring inside a string',
    mapObject:
      'Maps an array of objects into a new array based on field parameter',
    join: 'Joins an array into a string based on separator',
    addItems: 'Adds an array into another array',
    isNullOrEmpty: 'Checks if value is null or empty',
    firstOrNull: 'Returns first item from array or null',
    hashMap: 'Creates hashmap from a nested array',
    map: 'Maps array of object into array of simple fields',
    mapToObject:
      'Maps array of object into array of objects with a given fields',
    mapToArray: 'Maps array of object into array of arrays with a given fields',
    toFixed:
      'Formats a number to a given length after the decimal point by rounding off to reduce the length or by adding zeros to increase the length',
    percentToValue: 'Converts decimal to percent',
    isNaN: 'Checks if value is not a number',
    dateFormat: 'Formats a date',
    dateFormatFrom: 'Formats a date from a given format',
    dateServerFormat: 'Formats a date to a format that server can accept',
    includes: 'Checks if a string is included in a array of strings',
    like: 'Checks if array of string contains a string that contains given substring',
    isUuid: 'Checks if a string is a valid uuid',
    getFieldByObject: 'Gets a field value from the object',
    createObject: 'Creates an object from given fields and values',
    find: 'Finds an object from array of objects for a one given field and value',
    findAnd:
      'Finds an object from array of objects for a given fields and values, AND operator is used for the values.',
    findOr:
      'Finds an object from array of objects for a given fields and values, OR operator is used for the values.',
    some: 'Checks if array of objects contains an object for one given field and value',
    someAnd:
      'Checks if array of objects contains an object for given fields and values, AND operator is used for the values.',
    someOr:
      'Checks if array of objects contains an object for given fields and values, OR operator is used for the values.',
    filter:
      'Filters array of objects and return objects for one given field and value',
    filterAnd:
      'Filters array of objects and return objects for one given field and value, AND operator is used for the values.',
    filterOr:
      'Filters array of objects and return objects for one given field and value, OR operator is used for the values.',
    dateDiff:
      'Returns a difference for two given dates, the difference is based on unitOfTime.',
    roundDateTo: 'Rounds a date to a given unitOfTime',
    compareDates: 'Compares dates',
    decimalToHoursMinutesSeconds:
      'Converts decimal number to hours, minutes and seconds string',
    replacement:
      'Replaces an object values that contains string "__REPLACEMENT__"',
    safePlus: 'Sums decimals (handles decimal floating point)',
    safeMinus: 'Substracts decimals (handles decimal floating point)',
  },
  keyboard: {
    showKeyShortcuts: 'Zobrazit klávesové zkratky',
    application: 'Aplikace',
  },
};
