import {Versions} from '@stomp/stompjs';
import {Injectable} from '@angular/core';
import {Config} from '@tsm/framework/config';
import {ConfigService} from '@tsm/framework/config';
import {RuntimeService} from '@tsm/runtime-info';
import {
  InjectableRxStompConfig,
  RxStompService,
} from '@tsm/framework/websocket';

@Injectable({
  providedIn: 'root',
})
export class DmsInjectableRxStompConfig extends InjectableRxStompConfig {
  constructor(
    private config: ConfigService<Config>,
    private runtimeService: RuntimeService,
  ) {
    super();
    // Which server?
    this.brokerURL = config.value.apiUrls.dmsWebsocket + '/events';
    if (!this.brokerURL.startsWith('ws')) {
      this.brokerURL = 'wss://' + window.location.hostname + this.brokerURL;
    }
    this.logRawCommunication = true;
    this.stompVersions = new Versions([Versions.V1_2]);
    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 500 (500 milli seconds)
    this.reconnectDelay = 500;
    // Headers

    this.beforeConnect = (stomp: RxStompService) => {
      stomp.stompClient.connectHeaders = {
        Authorization: runtimeService.getAccessToken(),
      };
    };

    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    this.heartbeatIncoming = 10000; // Typical value 0 - disabled
    this.heartbeatOutgoing = 10000; // Typical value 20000 - every 20 seconds

    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    // this.debug = (msg: string): void => {
    //   console.log(new Date(), msg);
    // }
  }
}
