import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

export type SafePipeType =
  | 'html'
  | 'trustHtml'
  | 'style'
  | 'script'
  | 'url'
  | 'resourceUrl';

@Pipe({
  name: 'safe',
  pure: true,
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(
    value: string,
    type: SafePipeType,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        // vzdy to sanitizuju
        return this.sanitizer.sanitize(SecurityContext.HTML, value);
      case 'trustHtml':
        //verim tomu, ze to nekdo jinej sanitizoval
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.sanitize(SecurityContext.STYLE, value);
      case 'script':
        return this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
      case 'url':
        return this.sanitizer.sanitize(SecurityContext.URL, value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          this.sanitizer.sanitize(SecurityContext.URL, value),
        );
      default:
        throw new Error(
          `SafePipe unable to bypass security for invalid type: ${type}`,
        );
    }
  }
}
