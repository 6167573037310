import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import * as Reducers from './reducers';
import * as Services from './service';
import * as Effects from './effects';
import * as Pipes from './pipes';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';
import {FrameworkPluginModule} from '@tsm/framework/plugin';
import {UserDataSource} from './datasources/user.data-source';
import {ChipModule} from 'primeng/chip';
import {UserGroupCodeDataSource} from './datasources/user-group-code.data-source';
import {UserUserGroupDataSource} from './datasources/user-user-group.data-source';
import {UserRoleDataSource} from './datasources/user-role.data-source';
import {RuntimeInfoModule} from '@tsm/runtime-info';

export const IMPORTS = [
  ChipModule,
  FrameworkPluginModule.registerPlugin({
    dataSources: [
      {
        dataSource: UserDataSource,
        name: 'user',
        initialValue: null,
      },
      {
        dataSource: UserGroupCodeDataSource,
        name: 'userGroupCode',
        initialValue: null,
      },
      {
        dataSource: UserUserGroupDataSource,
        name: 'userUserGroupByUserId',
        initialValue: null,
      },
      {
        dataSource: UserRoleDataSource,
        name: 'userRoleByUserId',
        initialValue: null,
      },
    ],
    menus: [],
    routes: [],
    widgets: [],
  }),
  StoreModule.forFeature('userManagement', Reducers.reducers),
  EffectsModule.forFeature([
    Effects.RoleEffects,
    Effects.RolePrivEffects,
    Effects.UserGroupEffects,
    Effects.UserEffects,
    Effects.UserTypeEffects,
    Effects.UserImageEffects,
    Effects.UserRoleEffects,
    Effects.UserUserGroupEffects,
    Effects.RegionAssignmentEffects,
    Effects.SkillAssignmentEffects,
    Effects.UserGroupSkillEffects,
    Effects.PrivEffects,
    Effects.UserGroupRegionEffects,
    Effects.AccessRuleEffects,
    Effects.CheckOnlineEffects,
    Effects.RoleAccessRuleEffects,
    Effects.UserGroupTypeEffects,
  ]),
  TranslateModule.forFeature(localizations),
  RuntimeInfoModule,
];

export const PIPES = [
  Pipes.CheckOnlinePipe,
  Pipes.PrivLoaderByCodePipe,
  Pipes.PrivLoaderPipe,
  Pipes.RoleCodePipe,
  Pipes.RolePipe,
  Pipes.UserByCodePipe,
  Pipes.UserGroupInfoCodePipe,
  Pipes.UserGroupInfoPipe,
  Pipes.UserGroupTypeCodePipe,
  Pipes.UserGroupTypePipe,
  Pipes.UserGroupsInfoCodePipe,
  Pipes.UserGroupsInfoPipe,
  Pipes.UserImageComponentPipe,
  Pipes.UserImagePipe,
  Pipes.UserParameterPipe,
  Pipes.UserTypeIconPipe,
  Pipes.UserTypeCodePipe,
  Pipes.UserTypePipe,
  Pipes.UserUserGroupRolePipe,
  Pipes.UserUserGroupPipe,
  Pipes.UserPipe,
];

export const PROVIDERS = [
  Services.CommonApiService,
  Services.SkillAssignmentService,
  Services.UserGroupSkillService,
  Services.UserGroupService,
  Services.UserSettingsService,
  Services.UserService,
];
