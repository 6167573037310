import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import {v4 as getUuid} from 'uuid';
import {translation as translationShared} from '@tsm/shared-i18n';
import {TranslocoService} from '@tsm/framework/translate';
import {
  LayoutedComponent,
  LayoutIdDirective,
} from '@tsm/framework/root/layout-id';
import {FluentFormsInputOptions} from '@tsm/framework/root/di';
import {useParentWidgetProvidersFor} from '@tsm/framework/parent-widget';
import {PrivHelperService} from '@tsm/framework/security';

@Component({
  selector: 'tsm-button-confirm-popup',
  template: `
    <button
      pButton
      pRipple
      type="button"
      [label]="label"
      [class]="class"
      [icon]="icon"
      [class.spinner]="progress"
      (click)="onClick($event)"
      [disabled]="disabled"
    ></button>
    <p-confirmPopup [key]="confirmDialogKey">
      <ng-template pTemplate="content" let-message>
        <ng-container
          *ngTemplateOutlet="template; context: {$implicit: message?.message}"
        ></ng-container>
      </ng-template>
    </p-confirmPopup>

    <ng-template #defaultContentTemplate let-message>
      <i
        [ngClass]="'p-confirm-popup-icon'"
        [class]="dialogIcon"
        *ngIf="dialogIcon"
      ></i>
      <span class="p-confirm-popup-message">{{ message }}</span>
    </ng-template>
  `,
  providers: [...useParentWidgetProvidersFor(TsmButtonConfirmPopup)],
})
export class TsmButtonConfirmPopup implements OnInit, FluentFormsInputOptions {
  confirmDialogKey = 'deleteConfirm';

  translationShared = translationShared;

  @ContentChild(TemplateRef, {read: TemplateRef}) contentedTemplate;
  @ViewChild('defaultContentTemplate', {static: true}) defaultContentTemplate;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private primengConfig: PrimeNGConfig,
    private translocoService: TranslocoService,
    private privHelperService: PrivHelperService,
    @Optional() public layoutIdDirective: LayoutIdDirective,
  ) {
    this.confirmDialogKey = getUuid();
  }

  @Input()
  label?: string = null;

  @Input()
  class?: string = 'p-button-text p-button-danger';

  @Input()
  icon?: string = 'pi pi-times';

  @Input()
  disabled?: boolean = false;

  @Input()
  dialogAcceptLabel?: string = translationShared.shared.yes;

  @Input()
  dialogRejectLabel?: string = translationShared.shared.no;

  @Input()
  dialogMessage?: string = translationShared.shared.confirmGenericMessage;

  @Input()
  dialogIcon?: string = 'pi pi-exclamation-triangle';

  // podmínka pro přeskočení potvrzovací hlášky po stisknutí tlačítka
  @Input()
  skipValidation = false;

  private _progress = false;
  get progress(): boolean {
    return this._progress;
  }

  @Input()
  set progress(value: boolean) {
    this._progress = value;
  }

  @Output()
  onAcceptClick?: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onRejectClick?: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('style.pointer-events')
  get disableClicking() {
    return this.disabled || this.progress ? 'none' : null;
  }

  onClick(event: Event) {
    if (this.skipValidation) {
      this.onAcceptClick.emit();
      return;
    }
    this.confirmationService.confirm({
      key: this.confirmDialogKey,
      target: event.target,
      icon: this.dialogIcon,
      acceptLabel: this.translocoService.translate(this.dialogAcceptLabel),
      rejectLabel: this.translocoService.translate(this.dialogRejectLabel),
      message: this.translocoService.translate(this.dialogMessage),
      accept: () => {
        this.onAcceptClick.emit();
      },
      reject: () => {
        this.onRejectClick.emit();
      },
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  setInput(key: string, value: any) {
    if (key === 'inputs') {
      if (value?.externalEnablePrivilege) {
        this.disabled = !this.hasPrivilege(value?.externalEnablePrivilege);
        this.cdr.markForCheck();
      }
    }
  }

  hasPrivilege(priv: string) {
    return this.privHelperService.isCurrentUserHasRole(priv);
  }

  get template(): TemplateRef<any> {
    if (this.contentedTemplate) {
      return this.contentedTemplate;
    } else {
      return this.defaultContentTemplate;
    }
  }
}
