import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {notificationRuleAdapter} from '../reducers/notification-rule.reducer';
import {RouterStateUrl} from '@tsm/framework/root';

const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getNotificationRuleState = createSelector(
  getDmsState,
  (ri) => ri.notificationRules,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  notificationRuleAdapter.getSelectors(getNotificationRuleState);

export const selectNotificationRuleByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectNotificationRuleById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectNotificationRuleByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x?.data?.code === code),
  );
