import {AbstractAuditedEntity} from '@tsm/framework/root';

export interface NotificationTo extends AbstractAuditedEntity<string> {
  ownerId?: string;
  ownerType?: string;
  notification?: any;
  email?: string;
  emailType?: EmailType;
  phone?: string;
  address?: any;
  displayed?: string;
  data?: any;
}

export enum EmailType {
  TO = 'TO',
  CC = 'CC',
  BCC = 'BCC',
}
