import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {LoadScriptById} from '../actions';
import {selectScriptById} from '../selectors';

/**
 * Get customer by id, use dot separated string for nested props.
 */
@Pipe({
  name: 'scriptLoader',
  pure: true,
})
export class ScriptLoaderPipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(id: string, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectScriptById(id)),
      map((it) => {
        if (!it.data || it.error != null) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectScriptById(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() => this.store.dispatch(LoadScriptById({id: this._id})));
        }
      });
  }
}
