import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ConfigFormState, specificationsAdapter} from '../reducers';
import {IdentifiedPack, RouterStateUrl} from '@tsm/framework/root';
import {EntitySpecification} from '../models';
import {
  Characteristics,
  selectCharacteristics,
  sortChars,
} from '@tsm/characteristics/service';

const getConfigFormState = createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getEntitySpecificationsState = createSelector(
  getConfigFormState,
  (specificationsState) => specificationsState.entitySpecifications,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  specificationsAdapter.getSelectors(getEntitySpecificationsState);

export const selectEntitySpecificationById = (id: string) =>
  createSelector(selectEntities, (entities) => {
    const entity = entities[id];
    if (
      entity &&
      entity.data &&
      entity.data.expandedCharacteristics &&
      entity.data.expandedCharacteristics.length > 0
    ) {
      return {
        ...entity,
        data: {
          ...entity.data,
          expandedCharacteristics: [
            ...entity.data.expandedCharacteristics,
          ].sort((a, b) => sortChars(a, b)),
        },
      };
    }
    return entity;
  });

export const selectEntitySpecificationByCode = (code: string) =>
  createSelector(selectAll, (specifications) =>
    specifications.find((spec) => spec.data && spec.data.code === code),
  );

export const selectEntitySpecificationByIds = (ids: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.id)),
  );

export const selectEntitySpecificationByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectEntitySpecificationsByFilter = (
  filter: (spec: EntitySpecification) => boolean,
) =>
  createSelector(selectAll, (entities) => {
    return entities.map((pack) => pack.data).filter((entity) => filter(entity));
  });

export const selectEntitySpecificationCountByFilter = (
  filter: (spec: EntitySpecification) => boolean,
) =>
  createSelector(selectEntitySpecificationsByFilter(filter), (data) => {
    return data.length;
  });

export const selectCharacteristicsBySpecificationId = (
  entitySpecificationId: string,
) =>
  createSelector(
    selectEntitySpecificationById(entitySpecificationId),
    selectCharacteristics,
    (entitySpecification, entities) => {
      return [
        ...entities.filter(
          (chars) =>
            chars &&
            chars.data &&
            entitySpecification &&
            entitySpecification &&
            entitySpecification.data &&
            entitySpecification.data.characteristics &&
            entitySpecification.data.characteristics.includes(chars.data?.code),
        ),
      ]
        .sort((a, b) => sortChars(a.data, b.data))
        .map((x) => ({
          ...x,
          data:
            x.data != null
              ? {
                  ...x.data,
                  form: {
                    ...x.data.form,
                    schema: {
                      ...x.data.form?.schema,
                      id: x.data.form?.id,
                      code: x.data.form?.code,
                    },
                  },
                }
              : null,
        }));
    },
  );

export const selectCharacteristicsBySpecificationCode = (
  entitySpecificationCode: string,
) =>
  createSelector(
    selectEntitySpecificationByCode(entitySpecificationCode),
    selectCharacteristics,
    (entitySpecification, entities: IdentifiedPack<Characteristics>[]) => {
      return [
        ...entities.filter(
          (chars) =>
            chars &&
            chars.data &&
            entitySpecification &&
            entitySpecification &&
            entitySpecification.data &&
            entitySpecification.data.characteristics &&
            entitySpecification.data.characteristics.includes(chars.data?.code),
        ),
      ]
        .sort((a, b) => sortChars(a.data, b.data))
        .map((x) => ({
          ...x,
          data:
            x.data != null
              ? {
                  ...x.data,
                  form: {
                    ...x.data.form,
                    schema: {
                      ...x.data.form.schema,
                      id: x.data.form?.id,
                      code: x.data.form?.code,
                    },
                  },
                }
              : null,
        }));
    },
  );
