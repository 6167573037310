import {SelectItem} from 'primeng/api/selectitem';

export enum SharingRefType {
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
}

export function getAllSharingRefTypes(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(SharingRefType).forEach((val) =>
    items.push({label: SharingRefType[val], value: val}),
  );
  return items;
}
