import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {commentTypeAdapter} from '../reducers/comment-type.reducer';
import {RouterStateUrl} from '@tsm/framework/root';

const getDmsState = createFeatureSelector<DmsState>('dms');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
const pathToEntities = createSelector(getDmsState, (x) => x.commentType);

const {selectAll, selectEntities} =
  commentTypeAdapter.getSelectors(pathToEntities);

export const selectCommentTypeById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectCommentTypesByIds = (ids: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => ids.includes(x.data.id)),
  );

export const selectCommentTypeByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data.code === code),
  );

export const selectCommentTypesByCodes = (codes: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x.data.code)),
  );

export const selectAllCommentType = createSelector(selectAll, (entities) =>
  entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);

export const selectCommentTypeByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);
