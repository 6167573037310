import {Pipe, PipeTransform} from '@angular/core';
import {TableColumn} from '../models';

@Pipe({
  name: 'tsmSelectedColumnsToString',
  pure: false,
})
export class TsmSelectedColumnsToStringPipe implements PipeTransform {
  transform(selectedColumns: TableColumn[]): string {
    return selectedColumns?.map((x) => x.field).join(', ');
  }
}
