import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BackupSetService, CommonApiService} from '../services';
import {concatMap} from 'rxjs';
import {BackupSet, RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {
  DeleteBackupSet,
  DeleteBackupSetError,
  DeleteBackupSetSuccess,
  LoadBackupSetById,
  LoadBackupSetByIdError,
  LoadBackupSetByIdSuccess,
  UpsertBackupSet,
  UpsertBackupSetError,
  UpsertBackupSetSuccess,
} from '../actions';
import {map, tap} from 'rxjs/operators';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {Router} from '@angular/router';

@Injectable()
export class BackupSetEffects {
  private readonly API_PATH = 'v1/backup-set';
  translation = translation;

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<BackupSet, BackupSet>,
    private toastService: ToastService,
    private router: Router,
    private backupSetService: BackupSetService,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBackupSetById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadBackupSetByIdSuccess(env.data)
                : LoadBackupSetByIdError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertBackupSet),
      concatMap(({backupSet, listingId, navigateAfterRefresh}) =>
        this.commonApiService
          .upsertEntity(this.API_PATH, backupSet, backupSet.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.backupSet.effects.backupSetUpsertSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertBackupSetSuccess({
                  backupSet: env.data,
                  listingId,
                  navigateAfterRefresh,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.backupSet.effects.backupSetUpsertError,
                );
                return UpsertBackupSetError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteBackupSet),
      concatMap(({id, listingId, redirect}) =>
        this.commonApiService.deleteEntity(this.API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.backupSet.effects.backupSetDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (redirect) {
                this.router.navigate(['/config/backup-set']);
              }
              return DeleteBackupSetSuccess({id, listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.backupSet.effects.backupSetDeleteError,
              );
              return DeleteBackupSetError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  refreshAfretUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertBackupSetSuccess),
      tap(({backupSet, navigateAfterRefresh}) => {
        if (navigateAfterRefresh) {
          this.router.navigate(['config/backup-set', backupSet.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteBackupSetSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );
}
