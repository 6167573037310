<div class="p-3">
  <div class="tsm-tooltip-wrapper relative" [style.max-width.rem]="maxWidthRem">
    <!-- TSM TOOLTIP zpusobi circular dependency -->
    <i
      *ngIf="canOpenDialog"
      (click)="openInDialog()"
      class="tsm-icon-pushpin-1 absolute top-0 right-0 -mt-2 -mr-2 cursor-pointer z-1 p-2 hover:surface-100 border-circle"
      [pTooltip]="translation.tooltip.pinToScreen | transloco"
    ></i>

    @if (isString) {
      <div [innerHTML]="tmpTooltipData"></div>
    } @else {
      <ng-template [ngTemplateOutlet]="tmpTooltipData"></ng-template>
    }
  </div>
</div>
