<label
  [class]="extensionClass"
  [attr.for]="labelForId"
  [tsmTooltip]="helpMessage ? helpMessage : null"
  [maxWidthRemTooltip]="maxWidthRemTooltip"
  data-cy="dtl-form-field-label"
>
  <ng-content></ng-content>
  <i
    *ngIf="helpMessage && !helpMessageStatic"
    [tsmTooltip]="helpMessage ? helpMessage : null"
    [maxWidthRemTooltip]="maxWidthRemTooltip"
    class="label-tooltip-icon pi pi-info-circle text-sm"
  ></i>
  <i
    *ngIf="helpMessage && helpMessageStatic"
    [tsmTooltip]="helpMessage ? helpMessage : null"
    [staticPosition]="helpMessageStatic"
    [maxWidthRemTooltip]="maxWidthRemTooltip"
    class="label-tooltip-icon pi pi-info-circle text-sm"
  ></i>
</label>
