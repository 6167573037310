import {Pipe, PipeTransform} from '@angular/core';
import {JsonPointer} from 'tsm-json-schema-form-functions';

@Pipe({
  name: 'containsKeyValue',
})
export class ContainsPipe implements PipeTransform {
  transform(value: any, tuple: any, a: any): any {
    let contains = false;
    JsonPointer.forEachDeep(value, (v, p) => {
      if (typeof v === 'object' && v != null) {
        const val = v[tuple[0]];
        if (val === tuple[1]) {
          contains = true;
        }
      }
    });
    return contains;
  }
}
