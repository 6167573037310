import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';

import {
  LoadUiConfigAction,
  LoadUiConfigActionError,
  LoadUiConfigActionSuccess,
} from '../actions';
import {UiConfigService} from '../services';
import {TsmUiConfigModel} from '../models';

@Injectable()
export class UiConfigEffects {
  loadUiConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUiConfigAction),
      switchMap((_) =>
        this.uiConfigService.getUiConfig().pipe(
          map((env) =>
            env.success
              ? LoadUiConfigActionSuccess({
                  uiConfig: env.data as TsmUiConfigModel,
                })
              : LoadUiConfigActionError({error: env.error}),
          ),
        ),
      ),
    ),
  );

  constructor(
    private uiConfigService: UiConfigService,
    private actions$: Actions,
  ) {}
}
