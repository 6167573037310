import {
  animate,
  AnimationEvent,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  IterableDiffers,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  OverlayOptions,
  OverlayService,
  PrimeNGConfig,
  PrimeTemplate,
  TranslationKeys,
  OverlayOnShowEvent,
  ScrollerOptions,
} from 'primeng/api';
import {DomHandler} from 'primeng/dom';
import {Overlay, OverlayModule} from 'primeng/overlay';
import {Scroller, ScrollerModule} from 'primeng/scroller';
import {ObjectUtils, UniqueComponentId} from 'primeng/utils';
import {NgClass, NgStyle, NgIf, NgFor, NgTemplateOutlet} from '@angular/common';
import {AutoFocus} from 'primeng/autofocus';
import {ButtonDirective} from 'primeng/button';
import {Ripple} from 'primeng/ripple';

export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutoComplete),
  multi: true,
};

@Component({
  selector: 'dtl-autoComplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scaleY(0.8)',
        }),
        animate('{{showTransitionParams}}'),
      ]),
      transition(':leave', [
        animate('{{hideTransitionParams}}', style({opacity: 0})),
      ]),
    ]),
  ],
  host: {
    class: 'p-element p-inputwrapper',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]':
      '((focus && !disabled) || autofocus) || overlayVisible',
    '[class.p-autocomplete-clearable]': 'showClear && !disabled',
  },
  providers: [AUTOCOMPLETE_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgIf,
    AutoFocus,
    NgFor,
    NgTemplateOutlet,
    ButtonDirective,
    Ripple,
    OverlayModule,
    ScrollerModule,
    PrimeTemplate,
  ],
})
export class AutoComplete
  implements AfterViewChecked, AfterContentInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('container') containerEL: ElementRef;

  @ViewChild('in') inputEL: ElementRef;

  @ViewChild('multiIn') multiInputEL: ElementRef;

  @ViewChild('multiContainer') multiContainerEL: ElementRef;

  @ViewChild('ddBtn') dropdownButton: ElementRef;

  @ViewChild('items') itemsViewChild: ElementRef;

  @ViewChild('scroller') scroller: Scroller;

  @ViewChild('overlay') overlayViewChild: Overlay;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  /**
   * PRIMENG
   */
  @Input() minLength = 1;

  @Input() delay = 300;

  @Input() style: any;

  @Input() panelStyle: any;

  @Input() styleClass: string;

  @Input() panelStyleClass: string;

  @Input() inputStyle: any;

  @Input() inputId: string;

  @Input() inputStyleClass: string;

  @Input() placeholder: string;

  @Input() readonly: boolean;

  @Input() disabled: boolean;

  @Input() scrollHeight = '200px';

  @Input() lazy = false;

  @Input() virtualScroll: boolean;

  @Input() virtualScrollItemSize: number;

  @Input() virtualScrollOptions: ScrollerOptions;

  @Input() maxlength: number;

  @Input() name: string;

  @Input() required: boolean;

  @Input() size: number;

  @Input() appendTo: any;

  @Input() autoHighlight: boolean;

  @Input() forceSelection: boolean;

  @Input() type = 'text';

  @Input() autoZIndex = true;

  @Input() baseZIndex = 0;

  @Input() ariaLabel: string;

  @Input() dropdownAriaLabel: string;

  @Input() ariaLabelledBy: string;

  @Input() dropdownIcon = 'pi pi-chevron-down';

  @Input() unique = true;

  @Input() group: boolean;

  @Input() completeOnFocus = false;

  @Input() showClear = false;

  @Input() field: string;
  @Input() useFieldAsValue: boolean;

  @Input() dropdown: boolean;

  @Input() showEmptyMessage: boolean;

  @Input() dropdownMode = 'blank';

  @Input() multiple: boolean;

  @Input() tabindex: number;

  @Input() dataKey: string;

  @Input() emptyMessage: string;

  @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';

  @Input() hideTransitionOptions = '.1s linear';

  @Input() autofocus: boolean;

  @Input() autocomplete = 'off';

  @Input() optionGroupChildren: string;

  @Input() optionGroupLabel: string;

  @Input() overlayOptions: OverlayOptions;

  @Output() completeMethod: EventEmitter<any> = new EventEmitter();

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @Output() onUnselect: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Output() onDropdownClick: EventEmitter<any> = new EventEmitter();

  @Output() onClear: EventEmitter<any> = new EventEmitter();

  @Output() onKeyUp: EventEmitter<any> = new EventEmitter();

  @Output() onShow: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();

  overlay: HTMLDivElement;

  itemsWrapper: HTMLDivElement;

  itemTemplate: TemplateRef<any>;

  emptyTemplate: TemplateRef<any>;

  headerTemplate: TemplateRef<any>;

  footerTemplate: TemplateRef<any>;

  selectedItemTemplate: TemplateRef<any>;

  groupTemplate: TemplateRef<any>;

  loaderTemplate: TemplateRef<any>;

  value: any;

  _suggestions: any[];

  timeout: any;

  overlayVisible = false;

  documentClickListener: any;

  suggestionsUpdated: boolean;

  highlightOption: any;

  highlightOptionChanged: boolean;

  focus = false;

  filled: boolean;

  inputClick: boolean;

  inputKeyDown: boolean;

  noResults: boolean;

  differ: any;

  inputFieldValue: string = null;

  loading: boolean;

  scrollHandler: any;

  forceSelectionUpdateModelTimeout: any;

  listId: string;

  itemClicked: boolean;

  inputValue: string = null;

  /* @deprecated */
  _itemSize: number;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    public differs: IterableDiffers,
    public config: PrimeNGConfig,
    public overlayService: OverlayService,
    private zone: NgZone,
  ) {
    this.differ = differs.find([]).create(null);
    this.listId = UniqueComponentId() + '_list';
  }

  @Input() get itemSize(): number {
    return this._itemSize;
  }

  set itemSize(val: number) {
    this._itemSize = val;
    console.warn(
      'The itemSize property is deprecated, use virtualScrollItemSize property instead.',
    );
  }

  @Input() get suggestions(): any[] {
    return this._suggestions;
  }

  set suggestions(val: any[]) {
    this._suggestions = val;
    this.handleSuggestionsChange();
  }

  // custom code
  get isCleanButton(): boolean {
    return (
      this.showClear &&
      !!this.inputEL &&
      !!this.inputEL.nativeElement &&
      this.inputEL.nativeElement.value !== ''
    );
  }

  get emptyMessageLabel(): string {
    return (
      this.emptyMessage ||
      this.config.getTranslation(TranslationKeys.EMPTY_MESSAGE)
    );
  }

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  ngAfterViewChecked() {
    //Use timeouts as since Angular 4.2, AfterViewChecked is broken and not called after panel is updated
    if (this.suggestionsUpdated && this.overlayViewChild) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.overlayViewChild) {
            this.overlayViewChild.alignOverlay();
          }
        }, 1);
        this.suggestionsUpdated = false;
      });
    }

    if (this.highlightOptionChanged) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.overlay && this.itemsWrapper) {
            const listItem = DomHandler.findSingle(
              this.overlayViewChild.overlayViewChild.nativeElement,
              'li.p-highlight',
            );

            if (listItem) {
              DomHandler.scrollInView(this.itemsWrapper, listItem);
            }
          }
        }, 1);
        this.highlightOptionChanged = false;
      });
    }
  }

  handleSuggestionsChange() {
    if (this._suggestions != null && this.loading) {
      this.highlightOption = null;
      if (this._suggestions.length) {
        this.noResults = false;
        this.show();
        this.suggestionsUpdated = true;

        if (this.autoHighlight) {
          this.highlightOption = this._suggestions[0];
        }
      } else {
        this.noResults = true;

        if (this.showEmptyMessage) {
          this.show();
          this.suggestionsUpdated = true;
        } else {
          this.hide();
        }
      }

      this.loading = false;
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'group':
          this.groupTemplate = item.template;
          break;

        case 'selectedItem':
          this.selectedItemTemplate = item.template;
          break;

        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'empty':
          this.emptyTemplate = item.template;
          break;

        case 'footer':
          this.footerTemplate = item.template;
          break;

        case 'loader':
          this.loaderTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  writeValue(value: any): void {
    this.value = value;
    this.filled = this.value && this.value != '';
    this.updateInputField();
    this.cd.markForCheck();
  }

  getOptionGroupChildren(optionGroup: any) {
    return this.optionGroupChildren
      ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupChildren)
      : optionGroup.items;
  }

  getOptionGroupLabel(optionGroup: any) {
    return this.optionGroupLabel
      ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel)
      : optionGroup.label != undefined
        ? optionGroup.label
        : optionGroup;
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  onInput(event: Event) {
    // When an input element with a placeholder is clicked, the onInput event is invoked in IE.
    if (!this.inputKeyDown && DomHandler.isIE()) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const value = (<HTMLInputElement>event.target).value;
    this.inputValue = value;
    if (!this.multiple && !this.forceSelection) {
      this.onModelChange(value);
    }

    if (value.length === 0 && !this.multiple) {
      this.value = null;
      this.hide();
      this.onClear.emit(event);
      this.onModelChange(value);
    }

    if (value.length >= this.minLength) {
      this.timeout = setTimeout(() => {
        this.search(event, value);
      }, this.delay);
    } else {
      this.hide();
    }
    this.updateFilledState();
    this.inputKeyDown = false;
  }

  onInputClick(event: MouseEvent) {
    if (this.documentClickListener) {
      this.inputClick = true;
    }
  }

  search(event: any, query: string) {
    //allow empty string but not undefined or null
    if (query === undefined || query === null) {
      return;
    }

    this.loading = true;

    this.completeMethod.emit({
      originalEvent: event,
      query: query,
    });
  }

  selectItem(option: any, focus = true) {
    if (this.forceSelectionUpdateModelTimeout) {
      clearTimeout(this.forceSelectionUpdateModelTimeout);
      this.forceSelectionUpdateModelTimeout = null;
    }

    if (this.multiple) {
      this.multiInputEL.nativeElement.value = '';
      this.value = this.value || [];
      if (!this.isSelected(option) || !this.unique) {
        this.value = [...this.value, option];
        this.onModelChange(this.value);
      }
    } else {
      const val = this.resolveFieldData(option);
      this.inputEL.nativeElement.value = val;
      this.value = this.useFieldAsValue ? val : option;
      this.onModelChange(this.value);
    }

    this.onSelect.emit(option);
    this.updateFilledState();

    if (focus) {
      this.itemClicked = true;
      this.focusInput();
    }

    this.hide();
  }

  show(event?: OverlayOnShowEvent) {
    if (this.multiInputEL || this.inputEL) {
      const hasFocus = this.multiple
        ? this.multiInputEL.nativeElement.ownerDocument.activeElement ==
          this.multiInputEL.nativeElement
        : this.inputEL.nativeElement.ownerDocument.activeElement ==
          this.inputEL.nativeElement;

      if (!this.overlayVisible && hasFocus) {
        this.overlayVisible = true;
      }
    }

    this.onShow.emit(event);
    this.cd.markForCheck();
  }

  clear() {
    if (this.multiple) {
      this.value = null;
    } else {
      this.inputValue = null;
      this.inputEL.nativeElement.value = '';
      this.inputFieldValue = null;
    }

    this.updateFilledState();
    this.onModelChange(this.value);
    this.onClear.emit();
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    if (event.toState === 'visible') {
      this.itemsWrapper = DomHandler.findSingle(
        this.overlayViewChild.overlayViewChild.nativeElement,
        this.virtualScroll ? '.p-scroller' : '.p-autocomplete-panel',
      );
      this.virtualScroll &&
        this.scroller?.setContentEl(this.itemsViewChild.nativeElement);
    }
  }

  resolveFieldData(value) {
    if (typeof value !== 'object' && !Array.isArray(value)) {
      return value;
    }
    const data = this.field
      ? ObjectUtils.resolveFieldData(value, this.field)
      : value;
    return data !== null ? data : '';
  }

  hide(event?: any) {
    this.overlayVisible = false;

    this.onHide.emit(event);
    this.cd.markForCheck();
  }

  handleDropdownClick(event) {
    if (!this.overlayVisible) {
      this.focusInput();
      const queryValue = this.multiple
        ? this.multiInputEL.nativeElement.value
        : this.inputEL.nativeElement.value;

      if (this.dropdownMode === 'blank') {
        this.search(event, '');
      } else if (this.dropdownMode === 'current') {
        this.search(event, queryValue);
      }

      this.onDropdownClick.emit({
        originalEvent: event,
        query: queryValue,
      });
    } else {
      this.hide();
    }
  }

  focusInput() {
    if (this.multiple) {
      this.multiInputEL.nativeElement.focus();
    } else {
      this.inputEL.nativeElement.focus();
    }
  }

  removeItem(item: any) {
    const itemIndex = DomHandler.index(item);
    const removedValue = this.value[itemIndex];
    this.value = this.value.filter((val, i) => i != itemIndex);
    this.onModelChange(this.value);
    this.updateFilledState();
    this.onUnselect.emit(removedValue);
  }

  onKeydown(event) {
    if (this.overlayVisible) {
      switch (event.which) {
        //down
        case 40:
          if (this.group) {
            const highlightItemIndex = this.findOptionGroupIndex(
              this.highlightOption,
              this.suggestions,
            );
            if (highlightItemIndex !== -1) {
              const nextItemIndex = highlightItemIndex.itemIndex + 1;
              if (
                nextItemIndex <
                this.getOptionGroupChildren(
                  this.suggestions[highlightItemIndex.groupIndex],
                ).length
              ) {
                this.highlightOption = this.getOptionGroupChildren(
                  this.suggestions[highlightItemIndex.groupIndex],
                )[nextItemIndex];
                this.highlightOptionChanged = true;
              } else if (this.suggestions[highlightItemIndex.groupIndex + 1]) {
                this.highlightOption = this.getOptionGroupChildren(
                  this.suggestions[highlightItemIndex.groupIndex + 1],
                )[0];
                this.highlightOptionChanged = true;
              }
            } else {
              this.highlightOption = this.getOptionGroupChildren(
                this.suggestions[0],
              )[0];
            }
          } else {
            const highlightItemIndex = this.findOptionIndex(
              this.highlightOption,
              this.suggestions,
            );
            if (highlightItemIndex != -1) {
              const nextItemIndex = highlightItemIndex + 1;
              if (nextItemIndex != this.suggestions.length) {
                this.highlightOption = this.suggestions[nextItemIndex];
                this.highlightOptionChanged = true;
              }
            } else {
              this.highlightOption = this.suggestions[0];
            }
          }

          event.preventDefault();
          break;

        //up
        case 38:
          if (this.group) {
            const highlightItemIndex = this.findOptionGroupIndex(
              this.highlightOption,
              this.suggestions,
            );
            if (highlightItemIndex !== -1) {
              const prevItemIndex = highlightItemIndex.itemIndex - 1;
              if (prevItemIndex >= 0) {
                this.highlightOption = this.getOptionGroupChildren(
                  this.suggestions[highlightItemIndex.groupIndex],
                )[prevItemIndex];
                this.highlightOptionChanged = true;
              } else if (prevItemIndex < 0) {
                const prevGroup =
                  this.suggestions[highlightItemIndex.groupIndex - 1];
                if (prevGroup) {
                  this.highlightOption =
                    this.getOptionGroupChildren(prevGroup)[
                      this.getOptionGroupChildren(prevGroup).length - 1
                    ];
                  this.highlightOptionChanged = true;
                }
              }
            }
          } else {
            const highlightItemIndex = this.findOptionIndex(
              this.highlightOption,
              this.suggestions,
            );

            if (highlightItemIndex > 0) {
              const prevItemIndex = highlightItemIndex - 1;
              this.highlightOption = this.suggestions[prevItemIndex];
              this.highlightOptionChanged = true;
            }
          }

          event.preventDefault();
          break;

        //enter
        case 13:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
            this.hide();
          }
          event.preventDefault();
          break;

        //escape
        case 27:
          this.hide();
          event.preventDefault();
          break;

        //tab
        case 9:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
          }
          this.hide();
          break;
      }
    } else {
      if (event.which === 40 && this.suggestions) {
        this.search(event, event.target.value);
      } else if (event.ctrlKey && event.key === 'z' && !this.multiple) {
        this.inputEL.nativeElement.value = this.resolveFieldData(null);
        this.value = '';
        this.onModelChange(this.value);
      } else if (event.ctrlKey && event.key === 'z' && this.multiple) {
        this.value.pop();
        this.onModelChange(this.value);
        this.updateFilledState();
      }
    }

    if (this.multiple) {
      switch (event.which) {
        //backspace
        case 8:
          if (
            this.value &&
            this.value.length &&
            !this.multiInputEL.nativeElement.value
          ) {
            this.value = [...this.value];
            const removedValue = this.value.pop();
            this.onModelChange(this.value);
            this.updateFilledState();
            this.onUnselect.emit(removedValue);
          }
          break;
      }
    }

    this.inputKeyDown = true;
  }

  onKeyup(event) {
    this.onKeyUp.emit(event);
  }

  onInputFocus(event) {
    if (!this.itemClicked && this.completeOnFocus) {
      const queryValue = this.multiple
        ? this.multiInputEL.nativeElement.value
        : this.inputEL.nativeElement.value;
      this.search(event, queryValue);
    }

    this.focus = true;
    this.onFocus.emit(event);
    this.itemClicked = false;
  }

  onInputBlur(event) {
    this.focus = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onInputChange(event) {
    if (this.forceSelection) {
      let valid = false;
      const inputValue = event.target.value.trim();

      if (this.suggestions) {
        for (const suggestion of this.suggestions) {
          const itemValue = this.field
            ? ObjectUtils.resolveFieldData(suggestion, this.field)
            : suggestion;
          if (itemValue && inputValue === itemValue.trim()) {
            valid = true;
            this.forceSelectionUpdateModelTimeout = setTimeout(() => {
              this.selectItem(suggestion, false);
            }, 250);
            break;
          }
        }
      }

      if (!valid) {
        if (this.multiple) {
          this.multiInputEL.nativeElement.value = '';
        } else {
          this.value = null;
          this.inputEL.nativeElement.value = '';
        }

        this.onClear.emit(event);
        this.onModelChange(this.value);
        this.updateFilledState();
      }
    }
  }

  onInputPaste(event: ClipboardEvent) {
    this.onKeydown(event);
  }

  isSelected(val: any): boolean {
    let selected = false;
    if (this.value && this.value.length) {
      for (let i = 0; i < this.value.length; i++) {
        if (ObjectUtils.equals(this.value[i], val, this.dataKey)) {
          selected = true;
          break;
        }
      }
    }
    return selected;
  }

  findOptionIndex(option, suggestions): number {
    let index = -1;
    if (suggestions) {
      for (let i = 0; i < suggestions.length; i++) {
        if (ObjectUtils.equals(option, suggestions[i])) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  findOptionGroupIndex(val: any, opts: any[]): any {
    let groupIndex, itemIndex;

    if (opts) {
      for (let i = 0; i < opts.length; i++) {
        groupIndex = i;
        itemIndex = this.findOptionIndex(
          val,
          this.getOptionGroupChildren(opts[i]),
        );

        if (itemIndex !== -1) {
          break;
        }
      }
    }

    if (itemIndex !== -1) {
      return {groupIndex: groupIndex, itemIndex: itemIndex};
    } else {
      return -1;
    }
  }

  updateFilledState() {
    if (this.multiple) {
      this.filled =
        (this.value && this.value.length) ||
        (this.multiInputEL &&
          this.multiInputEL.nativeElement &&
          this.multiInputEL.nativeElement.value != '');
    } else {
      this.filled =
        (this.inputFieldValue && this.inputFieldValue != '') ||
        (this.inputEL &&
          this.inputEL.nativeElement &&
          this.inputEL.nativeElement.value != '');
    }
  }

  updateInputField() {
    const formattedValue = this.resolveFieldData(this.value);
    this.inputFieldValue = formattedValue;

    if (this.inputEL && this.inputEL.nativeElement) {
      this.inputEL.nativeElement.value = formattedValue;
    }

    this.updateFilledState();
  }

  ngOnDestroy() {
    if (this.forceSelectionUpdateModelTimeout) {
      clearTimeout(this.forceSelectionUpdateModelTimeout);
      this.forceSelectionUpdateModelTimeout = null;
    }

    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }
  }
}
