import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {
  EntityIdentif,
  OnlineModel,
  User,
  UserNotificationConfig,
} from '../model';
import {SharedRequestValidUntil} from '@tsm/framework/root';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly BASE_URL: string;
  private readonly PARAMETER_URL: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.userManagement;
    this.PARAMETER_URL = EntityIdentif.USER_INFO;
  }

  getUser(id: string): Observable<Envelope<User>> {
    return this.apiService.get<User, User>(
      this.BASE_URL + `/${this.PARAMETER_URL}/${id}`,
    );
  }

  getUserByCode(code: string): Observable<Envelope<User>> {
    return this.apiService.get<User, User>(
      this.BASE_URL + `/${this.PARAMETER_URL}/find-by-code/${code}`,
    );
  }

  putUser(id: string, user: User): Observable<Envelope<User>> {
    return this.apiService.put<User, User>(
      this.BASE_URL + `/${this.PARAMETER_URL}/${id}`,
      user,
    );
  }

  patchUser(user: User) {
    const {id, version, ...newUser} = user;
    return this.apiService.patch<User, User>(
      this.BASE_URL + `/${this.PARAMETER_URL}/${id}`,
      newUser,
    );
  }

  postUser(user: User) {
    return this.apiService.post<User, User>(
      this.BASE_URL + `/${this.PARAMETER_URL}`,
      user,
    );
  }

  upsertUser(id: string, user: User): Observable<Envelope<User>> {
    if (user.version != null) {
      return this.putUser(id, user);
    } else {
      return this.postUser(user);
    }
  }

  @SharedRequestValidUntil(1)
  checkOnline(id: string): Observable<Envelope<OnlineModel>> {
    return this.apiService.get(
      this.BASE_URL + `/${this.PARAMETER_URL}/${id}/online`,
    );
  }

  updateNotificationConfig(userId: string, config: UserNotificationConfig) {
    return this.apiService.post<any, any>(
      this.BASE_URL + `/${this.PARAMETER_URL}/save-notif-config/${userId}`,
      config,
    );
  }
}
