@if (!readonly) {
  <dtl-dynamic-component
    data-cy="dtl-form-input-tiptap"
    [type]="tipTapType$ | async"
    [inputs]="{
      editable: true,
      menuType: menuType,
      mentionsUrl:
        configServices.value.apiUrls.userManagement +
        '/user/filtering-list?status__eq=ENABLE&name__contains=',
      hashtagUrl:
        configServices.value.apiUrls.ticket +
        '/ticket/elastic/filtering?key__contains=',
      scriptUrl: configServices.value.apiUrls.tsmFormConfig + '/v1/scripts',
      value: val,
      ownerId: ownerId,
      ownerType: ownerType,
      hideMenuItemIframe: hideMenuItemIframe,
      hideMenuItemDiagram: hideMenuItemDiagram,
      hideMenuItemImage: hideMenuItemImage,
      hideMenuItemAttachments: hideMenuItemAttachments,
      pastePlainImages: pastePlainImages,
      hideMenuItemInsertCode: hideMenuItemInsertCode,
      hideMenuItemSourceCode: hideMenuItemSourceCode,
      hashtagOption: hashtagOption,
      suggestionScriptCode: suggestionScriptCode,
      suggestionScriptData: suggestionScriptData,
      clickScriptCode: clickScriptCode,
      clickScriptData: clickScriptData,
      moreSelector: moreSelector,
      moreInputs: moreInputs,
      disableTableStyle: disableTableStyle,
      customButtonText: customButtonText,
      customButtonIcon: customButtonIcon,
    }"
    [outputs]="{
      valueChange: setValue.bind(this),
      onTouched: onFocus.bind(this),
      hashtagAttachmentIds: hashtagAttachmentIdsChanges.bind(this),
      hashtagSelectionChanged: hashtagSelectionChangedFce.bind(this),
    }"
  ></dtl-dynamic-component>
} @else {
  <dtl-dynamic-component
    data-cy="dtl-form-input-tiptap"
    [type]="tipTapType$ | async"
    [inputs]="{
      editable: false,
      menuType: menuType,
      mentionsUrl:
        configServices.value.apiUrls.userManagement +
        '/user/filtering-list?status__eq=ENABLE&name__contains=',
      hashtagUrl:
        configServices.value.apiUrls.ticket +
        '/ticket/elastic/filtering?key__contains=',
      scriptUrl: configServices.value.apiUrls.tsmFormConfig + '/v1/scripts',
      value: val,
      ownerId: ownerId,
      ownerType: ownerType,
      hideMenuItemIframe: hideMenuItemIframe,
      hideMenuItemDiagram: hideMenuItemDiagram,
      hideMenuItemImage: hideMenuItemImage,
      hideMenuItemAttachments: hideMenuItemAttachments,
      hideMenuItemInsertCode: hideMenuItemInsertCode,
      hideMenuItemSourceCode: hideMenuItemSourceCode,
      pastePlainImages: pastePlainImages,
      hashtagOption: hashtagOption,
      suggestionScriptCode: suggestionScriptCode,
      suggestionScriptData: suggestionScriptData,
      clickScriptCode: clickScriptCode,
      clickScriptData: clickScriptData,
      moreSelector: moreSelector,
      moreInputs: moreInputs,
      disableTableStyle: disableTableStyle,
      customButtonText: customButtonText,
      customButtonIcon: customButtonIcon,
    }"
    [outputs]="{
      valueChange: setValue.bind(this),
      hashtagAttachmentIds: hashtagAttachmentIdsChanges.bind(this),
      hashtagSelectionChanged: hashtagSelectionChangedFce.bind(this),
    }"
  ></dtl-dynamic-component>
  <!--      <div>AAA{{isEmpty}}</div>-->
}
