import {createFeatureSelector, createSelector} from '@ngrx/store';
import {commentAdapter} from '../reducers/comment.reducer';
import {DmsState} from '../reducers';
import {Location} from '@tsm/wfm/service';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getCommentState = createSelector(getDmsState, (x) => x.comments);

const {selectAll, selectEntities} =
  commentAdapter.getSelectors(getCommentState);

export const selectCommentsById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectCommentsByOwnerIdOwnerType = (
  ownerId: string,
  ownerType: string,
) =>
  createSelector(selectAll, (events) => {
    const comment = events.find(
      (e) => e.ownerId == ownerId && e.ownerType == ownerType,
    );
    return comment ? comment.comments : [];
  });

// https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
// aktualni presnost nastavena na ~ 330 m
export const selectCommentsByOwnerIdAndLocation = (
  ownerId: string,
  loc: Location,
) =>
  createSelector(selectCommentsById(ownerId), (state) => ({
    ...state,
    comments: state.comments.filter(
      (comm) =>
        Math.abs(comm.lat - loc.lat) < 0.003 &&
        Math.abs(comm.lng - loc.lng) < 0.003,
    ),
  }));
