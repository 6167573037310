export const en = {
  changeManagementPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmChangeManagementFilter: 'tSM Change Management Filter',
      tsmChangeManagementFilterDescription:
        'Provides a filter for change management. ' +
        'Allows multi-selection and retrieves relevant ' +
        'change management entries based on user input.',
      tsmImpactOnCustomerFilter: 'tSM Impact On Customer Filter',
      tsmImpactOnCustomerFilterDescription:
        'Filters impact on customer values. ' +
        'Uses a selection list for user input ' +
        'and updates the filter based on the selected value.',
      tsmOperationImpactFilter: 'tSM Operation Impact Filter',
      tsmOperationImpactFilterDescription:
        'Filters operation impact values. ' +
        'Retrieves data from a remote source and ' +
        'allows multiple selections for filtering.',
      tsmTechnologyFilter: 'tSM Technology Filter',
      tsmTechnologyFilterDescription:
        'Filters technology values for change management. ' +
        'Retrieves data from a remote source and ' +
        'allows multiple selections for filtering.',
      tsmWorklogLocalityUserGroupsFilter:
        'tSM Worklog Locality User Groups Filter',
      tsmWorklogLocalityUserGroupsFilterDescription:
        'Filters user groups for worklog locality selection. ' +
        'Allows multiple selections and includes options for ' +
        'assigned and unassigned groups.',
    },
    pipes: {
      changeRequestLink: 'Change Request Link',
      changeRequestLinkDescription:
        'Generates a hyperlink directing to the change request page. Constructs a navigation link with the ' +
        'ticket key as the visible text and the change request URL based on the ticket ID. Returns the plain ' +
        'ticket ID if the required data is not available.',
      changeRequestLoader: 'Change Request Loader',
      changeRequestLoaderDescription:
        'Loads change request data by its ID. Enables the retrieval of nested properties using dot-separated ' +
        'fields. Optionally applies date formatting if a dateTransform is provided. If the required data is ' +
        'unavailable, returns the given ID.',
      changeRequestOperationImpactByCode:
        'Change Request Operation Impact By Code',
      changeRequestOperationImpactByCodeDescription:
        'Retrieves operational impact details for a change request using its code. Provides options to fetch ' +
        'specific attributes or the entire dataset. Utilizes active language settings in Transloco to apply ' +
        'localization for fields. If the data is unavailable, returns the provided code as a fallback.',
      changeRequestOperationImpact: 'Change Request Operation Impact',
      changeRequestOperationImpactDescription:
        'Fetches operational impact details for a change request based on its ID. Provides options to retrieve ' +
        'specific attributes or the entire dataset. Utilizes active language settings from Transloco to localize ' +
        'fields. If the requested data is unavailable, the provided ID is returned as a fallback.',
      changeRequestSchedule: 'Change Request Schedule',
      changeRequestScheduleDescription:
        'Generates a link to the schedule page of a specific change request using its ID. Retrieves the ticket ' +
        'details from the store to construct the link. If the ticket data is unavailable, it returns the provided ' +
        'ID as a fallback.',
      changeRequestTechnologyCode: 'Change Request Technology Code',
      changeRequestTechnologyCodeDescription:
        'Loads and displays details of a technology associated with a specific code. Retrieves the data from ' +
        'the store, allowing the user to select a specific field (e.g., "name"). If the requested data is not ' +
        'available, it dispatches an action to load the data and uses the provided code as a fallback.',
      changeRequestTechnology: 'Change Request Technology',
      changeRequestTechnologyDescription:
        'Retrieves and displays technology details using a specific ID. Accesses data from the store and ' +
        'provides the ability to select a specific field, such as "name". If the requested data is unavailable, ' +
        'dispatches an action to load the data, falling back to the provided ID if no data exists.',
      relatedEntitiesToPlaces: 'Related Entities To Places',
      relatedEntitiesToPlacesDescription:
        'Transforms related entities into a list of places with their respective addresses. Filters and processes ' +
        'unique place IDs from related entities, retrieves detailed address data using API calls, and maps the results ' +
        'into a structured list containing place IDs, references, and addresses.',
      scheduleTypeLoader: 'Schedule Type Loader',
      scheduleTypeLoaderDescription:
        'Loads schedule type details by ID from the store, supporting nested field extraction and optional ' +
        'date formatting. Retrieves and formats specific properties of the schedule type, with a default field ' +
        'being the name. If the data is unavailable, returns the provided ID.',
      scheduleTypeLoaderCode: 'Schedule Type Loader Code',
      scheduleTypeLoaderCodeDescription:
        'Loads schedule type details by Code from the store, supporting nested field extraction and optional ' +
        'date formatting. Retrieves and formats specific properties of the schedule type, with a default field ' +
        'being the name. If the data is unavailable, returns the provided Code.',
      worklogLocalityUserGroups: 'Worklog Locality User Groups',
      worklogLocalityUserGroupsDescription:
        'Fetches and processes user groups associated with a specific user ID. Dispatches actions to load ' +
        'user groups and processes their data to retrieve specific fields or all data. Extracts ' +
        'and formats user group names into a concatenated string, ensuring error and loading states are ' +
        'handled gracefully.',
    },
  },
};
