import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {concatMap, filter, map} from 'rxjs/operators';
import {animationBuffer, withLatestCached} from '@tsm/framework/root';
import {
  LoadBatchUserImageById,
  LoadBatchUserImageByIdError,
  LoadBatchUserImageByIdSuccess,
  LoadUserImageById,
  LoadUserImageByIdError,
  LoadUserImageByIds,
  LoadUserImageByIdsError,
  LoadUserImageByIdsSuccess,
  LoadUserImageByIdSuccess,
} from '../actions';
import {EntityIdentif, User} from '../model';
import {selectUserImageById} from '../selectors';
import {CommonApiService, UserService} from '../service';
import {translation} from '../i18n';
import {LoginFinishedSuccess, Logout} from '@tsm/core';

@Injectable()
export class UserImageEffects {
  translation = translation;

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserImageById),
      withLatestCached((action) =>
        this.store.select(selectUserImageById(action.id)),
      ),
      concatMap(([action, user]) => {
        return user.data
          ? of(LoadUserImageByIdSuccess({user: user.data}))
          : this.commonApiService
              .getEntity(EntityIdentif.USER_INFO + '/image', action.id)
              .pipe(
                map((env) =>
                  env.success
                    ? LoadUserImageByIdSuccess({user: env.data})
                    : LoadUserImageByIdError({id: action.id, error: env.error}),
                ),
              );
      }),
    ),
  );

  loadByIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadUserImageByIds),
      concatMap(({ids}) => {
        return this.commonApiService
          .getEntitiesByIdsSharedRequest(
            EntityIdentif.USER_INFO + '/image',
            ids,
          )
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadUserImageByIdsSuccess({entities: response.data})
                  : LoadUserImageByIdsError({ids: ids, error: response.error});
              return temp;
            }),
          );
      }),
    );
  });

  loadBatchById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadBatchUserImageById),
      animationBuffer(
        this.actions$,
        LoadBatchUserImageById,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((ids) => ids.map((x) => x.id)),
      map((ids) => Array.from(new Set(ids))),
      concatMap((ids) => {
        return this.commonApiService
          .getEntitiesByIdsSharedRequest(
            EntityIdentif.USER_INFO + '/image',
            ids,
          )
          .pipe(
            map((response) => {
              const temp: Action =
                response.success == true
                  ? LoadBatchUserImageByIdSuccess({entities: response.data})
                  : LoadBatchUserImageByIdError({
                      ids: ids,
                      error: response.error,
                    });
              return temp;
            }),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<User, User>,
    private userService: UserService,
    private store: Store,
  ) {}
}
