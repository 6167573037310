export const cs = {
  listingLibPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      dtlDataTypeFilter: 'dtl Data Type Filter',
      dtlDataTypeFilterDescription:
        'Filtruje datové typy v tabulkovém zobrazení s výběrem. ' +
        'Zobrazuje dostupné datové typy se stránkováním a tříděním. ' +
        'Podporuje vícenásobný výběr datových typů. ' +
        'Umožňuje uživatelům potvrdit nebo zrušit výběr. ' +
        'Překládá názvy datových typů pro lokalizaci. ' +
        'Řídí výběr a zrušení výběru všech položek. ' +
        'Dynamicky spravuje hodnotu filtru a zobrazovaný text.',
      dtlFilterBoolean: 'dtl Filter Boolean',
      dtlFilterBooleanDescription:
        'Poskytuje komponentu pro filtr boolean hodnot s předdefinovanými možnostmi. ' +
        'Zobrazuje volby jako "Ano", "Ne", "Prázdné" a "Neprázdné". ' +
        'Podporuje přizpůsobené popisky a výchozí hodnoty přes vstupní kontext. ' +
        'Automaticky nastavuje operátor podle vybrané hodnoty. ' +
        'Aktualizuje a emituje hodnotu filtru při změně výběru. ' +
        'Používá překryvný panel pro výběr z rozbalovacího seznamu. ' +
        'Překládá popisky možností pro podporu lokalizace.',
      dtlFilterCalendarRange: 'dtl Filter Calendar Range',
      dtlFilterCalendarRangeDescription:
        'Poskytuje komponentu pro filtrování rozsahu dat pro tabulky, podporující absolutní i relativní data. ' +
        'Umožňuje výběr rozsahu dat s volitelným výběrem času. ' +
        'Podporuje různé operátory filtru, jako je "větší než", "menší než" nebo "mezi". ' +
        'Formátuje a převádí data pro API požadavky a zajišťuje správnou práci s časovými zónami. ' +
        'Podporuje předdefinované relativní hodnoty dat a překlady pro lepší použitelnost.',
      dtlFilterCustom: 'dtl Filter Custom',
      dtlFilterCustomDescription:
        'Implementuje přizpůsobitelný filtr s předdefinovanými hodnotami a operátory. ' +
        'Umožňuje nastavení filtru pomocí zadaného operátoru a jedné nebo více hodnot. ' +
        'Podporuje dynamické hodnoty zobrazení pro lepší čitelnost. ' +
        'Emituje aktualizovanou hodnotu filtru při inicializaci a změnách. ' +
        'Navrženo jako základ pro pokročilou vlastní logiku filtrů. ' +
        'Používá explicitní příznak k označení vlastního typu filtru.',
      dtlFilterDataTags: 'dtl Filter Data Tags',
      dtlFilterDataTagsDescription:
        'Poskytuje komponentu tabulkového filtru pro výběr datových tagů z konfigurovatelného API. ' +
        'Podporuje filtrování, řazení a výběr více položek, včetně speciálního tagu "NULL". ' +
        'Dynamicky načítá data na základě uživatelského vstupu a aktualizuje výběr. ' +
        'Integruje se s externími API a spravuje stav prostřednictvím formulářových kontrol. ' +
        'Zajišťuje efektivní načítání dat a aktualizaci UI pomocí strategií detekce změn.',
      dtlFilterDateToBoolean: 'dtl Filter Date To Boolean',
      dtlFilterDateToBooleanDescription:
        'Vytváří filtr založený na rozevíracím seznamu, který převádí hodnoty data na boolean. ' +
        'Poskytuje možnosti filtrování dat, kde je datum nastavené nebo prázdné. ' +
        'Ve výchozím nastavení filtruje nenulové hodnoty pomocí operátoru "notempty". ' +
        'Automaticky nastavuje a emituje hodnotu filtru při inicializaci. ' +
        'Aktualizuje zobrazenou hodnotu na základě výběru uživatele. ' +
        'Podporuje dynamické překlady pro zobrazované hodnoty.',
      dtlFilterEdit: 'dtl Filter Edit',
      dtlFilterEditDescription:
        'Spravuje dynamické vytváření komponent filtrů v rámci systému tabulkového filtrování. ' +
        'Dynamicky načítá a inicializuje filtrační widgety na základě zadané konfigurace filtru. ' +
        'Zajišťuje, že filtrační widget obdrží potřebný kontext a aktualizuje svůj stav. ' +
        'Řeší úpravy pozicování pro konzistenci uživatelského rozhraní. ' +
        'Při zničení komponenty uvolňuje dynamicky vytvořené komponenty.',
      dtlFilterExportStatus: 'dtl Filter Export Status',
      dtlFilterExportStatusDescription:
        'Vytváří komponentu filtru pro výběr stavů exportu. ' +
        'Zobrazuje seznam dostupných stavů exportu v tabulkovém formátu s možností vícenásobného ' +
        'výběru. Dynamicky načítá možnosti stavů exportu a překládá je pro lokalizaci. ' +
        'Ukládá vybrané hodnoty a odpovídajícím způsobem aktualizuje stav filtru. ' +
        'Obsahuje zápatí s tlačítky pro potvrzení a zrušení. ' +
        'Podporuje konfiguraci stránkování a řazení pro zobrazení dat.',
      dtlFilterInBoolean: 'dtl Filter In Boolean',
      dtlFilterInBooleanDescription:
        'Vytváří komponentu filtru pro booleovské hodnoty s možností vícenásobného výběru. ' +
        'Umožňuje uživatelům filtrovat data výběrem "Ano," "Ne" nebo obou možností. ' +
        'Podporuje vlastní výchozí hodnoty a popisky pro lokalizaci. ' +
        'Spravuje stav filtru a emituje změny při výběru. ' +
        'Používá rozbalovací seznam pro snadný výběr booleovských hodnot. ' +
        'V zápatí obsahuje tlačítka pro potvrzení a zrušení.',
      dtlFilterInput: 'dtl Filter Input',
      dtlFilterInputDescription:
        'Vytváří komponentu vstupního filtru s více možnostmi filtrování. ' +
        'Podporuje textový vstup, vícenásobný vstup a operátory jako ' +
        '"obsahuje," "rovná se," "nerovná se," "začíná na," "končí na" a další. ' +
        'Zpracovává prázdné i ne-prázdné hodnoty a automaticky formátuje ' +
        'vícenásobné filtry. ' +
        'Používá reaktivní formulář s dynamicky aktualizovaným výběrem operátorů. ' +
        'V zápatí obsahuje tlačítka pro potvrzení a zrušení.',
      dtlFilterItem: 'dtl Filter Item',
      dtlFilterItemDescription:
        'Definuje komponentu položky filtru, která se integruje do systému filtrování tabulky. ' +
        'Dynamicky spravuje uživatelský vstup a transformaci operátorů filtrů. ' +
        'Zajišťuje správné formátování hodnot filtru na základě vybraného operátoru. ' +
        'Automaticky aktualizuje hodnoty a zobrazované hodnoty při změně operátoru. ' +
        'Používá reaktivní formuláře pro efektivní sledování stavu filtru a změn. ' +
        'Podporuje validaci, dynamickou viditelnost a konfiguraci pro čtení/zápis.',
      dtlFilterList: 'dtl Filter List',
      dtlFilterListDescription:
        'Definuje komponentu seznamu filtrů, která umožňuje spravovat více filtračních podmínek. ' +
        'Podporuje jak strukturované modely filtrů, tak filtrování na základě výrazů. ' +
        'Dynamicky aktualizuje formulářové ovládací prvky na základě vstupu uživatele. ' +
        'Validuje objekty filtrů, aby zajistila, že obsahují požadovaná pole. ' +
        'Automaticky synchronizuje hodnoty mezi strukturovaným a JSON formátem. ' +
        'Poskytuje možnost přepínání mezi JSON a filtrováním na základě výrazů.',
      dtlFilterNumber: 'dtl Filter Number',
      dtlFilterNumberDescription:
        'Definuje číselný filtr, který umožňuje uživatelům filtrovat číselné hodnoty ' +
        'pomocí různých operátorů, jako je "rovná se", "větší než", "menší než", "mezi" ' +
        'a "zahrnutí více hodnot". Podporuje číselný vstup, výběr rozsahu a zadávání ' +
        'více hodnot přes textovou oblast. Dynamicky aktualizuje formulář podle ' +
        'vybraného operátoru a zajišťuje číselnou validaci. Poskytuje možnost ' +
        'filtrování prázdných nebo neprázdných hodnot.',
      dtlFilterPhone: 'dtl Filter Phone',
      dtlFilterPhoneDescription:
        'Vytváří komponentu filtru pro telefonní čísla s více možnostmi filtrování. ' +
        'Podporuje "přesnou shodu", "obsahuje", "začíná na", "končí na" a "vícenásobný vstup". ' +
        'Automaticky odstraňuje mezery a zajišťuje správné formátování. ' +
        'Používá reaktivní formulář s dynamicky volitelnými operátory filtrování. ' +
        'V zápatí obsahuje tlačítka pro potvrzení a zrušení.',
      dtlFilterTql: 'dtl Filter Tql',
      dtlFilterTqlDescription:
        'Vytváří komponentu filtru pro výběr předdefinovaných dotazových možností. ' +
        'Používá rozbalovací nabídku pro výběr z hodnot poskytovaných v kontextu. ' +
        'Výchozí hodnotou je první možnost, pokud není vybrána žádná hodnota. ' +
        'Podporuje filtrování na základě rovnosti s vybranou možností. ' +
        'V zápatí obsahuje tlačítka pro potvrzení a zrušení.',
      dtlTableFilter: 'dtl Table Filter',
      dtlTableFilterDescription:
        'Definuje filtr tabulky, který spravuje logiku filtrování dat v tabulce. ' +
        'Podporuje dynamické vytváření filtrů, výběr z dostupných sloupců a použití ' +
        'různých filtrů. Umožňuje filtrování více polí, interaktivní správu filtrů ' +
        'a odstranění použitých filtrů. Integruje překlady pro lokalizované popisky ' +
        'a nápovědu k filtrům. Obsahuje klávesové zkratky pro rychlé filtrování ' +
        'a automatické doplňování pro filtrování podle sloupců.',
      dtlTableFilterColumnConfigCode: 'dtl Table Filter Column Config Code',
      dtlTableFilterColumnConfigCodeDescription:
        'Vytváří komponentu filtru s vícenásobným výběrem pro filtrování sloupců tabulky ' +
        'podle konfiguračních kódů. Používá konektor datového zobrazení ' +
        'pro načtení a zobrazení dostupných kódů. Podporuje vyhledávání a ' +
        'řazení podle kódu a názvu. V zápatí obsahuje potvrzovací a storno tlačítko ' +
        'pro použití nebo zrušení výběru.',
      dtlTableFilterListingParent: 'dtl Table Filter Listing Parent',
      dtlTableFilterListingParentDescription:
        'Definuje abstraktní direktivu, která rozšiřuje základní komponentu ' +
        'tabulkového filtru pro podporu filtrů založených na seznamech. ' +
        'Spravuje vybrané hodnoty a aktualizuje stav seznamu. Integruje se ' +
        'se službou historie pro sledování vybraných entit. Poskytuje metody ' +
        'pro odstranění jednotlivých výběrů nebo vymazání všech výběrů.',
      dtlTableFilterParent: 'dtl Table Filter Parent',
      dtlTableFilterParentDescription:
        'Definuje abstraktní základní třídu pro komponenty tabulkových filtrů. ' +
        'Spravuje vlastnosti filtru, lifecycle hooky a interakce s overlay ' +
        'panelem. Zajišťuje výchozí operátor filtru, pokud není nastaven. ' +
        'Poskytuje metody pro potvrzení a zrušení filtru. Podporuje ' +
        'automatické přepínání panelu a čištění při zničení komponenty.',
      dtlTableFilterSelectedItems: 'dtl Table Filter Selected Items',
      dtlTableFilterSelectedItemsDescription:
        'Zobrazuje panel vybraných položek pro tabulkové filtry. ' +
        'Zobrazuje vybrané hodnoty filtru jako štítky s možností jejich odstranění. ' +
        'Poskytuje tlačítko pro zrušení výběru. Používá responzivní rozložení ' +
        'pro zpracování více vybraných položek. Podporuje dynamický překlad popisků.',
      dtlTableFilterWidgetEditor: 'dtl Table Filter Widget Editor',
      dtlTableFilterWidgetEditorDescription:
        'Definuje editor widgetu tabulkového filtru, který umožňuje konfiguraci vzhledu ' +
        'a chování filtrů tabulky. Podporuje nastavení pozice popisků, správu viditelnosti ' +
        'a použití vlastních stylovacích tříd. Integruje překlady pro lokalizované ' +
        'prvky uživatelského rozhraní. Rozšiřuje základní editor widgetů pro strukturovanou ' +
        'konfiguraci filtrů.',
      dtlTableFilterWidget: 'dtl Table Filter Widget',
      dtlTableFilterWidgetDescription:
        'Definuje widget pro filtrování tabulky, který se integruje se službou pro správu ' +
        'seznamů a umožňuje dynamické řízení filtrů, profilů a nastavení tabulky. Poskytuje ' +
        'strukturované uživatelské rozhraní pro konfiguraci a aplikaci filtrů včetně výběru ' +
        'profilů, ukládání a resetování možností. Podporuje asynchronní správu stavu pomocí ' +
        'observables pro aktualizaci UI při změnách stavu seznamu.',
    },
    pipes: {
      backupRestoreIcon: 'Backup Restore Icon',
      backupRestoreIconDescription:
        'Vykresluje ikonu na základě zadané hodnoty pro indikaci akcí zálohování nebo obnovení. Ikony ' +
        'zahrnují "new" pro přidání, "change" pro úpravu a výchozí ikonu "remove". Ikony zobrazují ' +
        'nástrojové tipy přeložené do aktivního jazyka.',
      customColumnValue: 'Custom Column Value',
      customColumnValueDescription:
        'Provádí vlastní transformační funkci pro vytvoření hodnoty sloupce. Tato funkce přijímá jako ' +
        'vstup data řádku a vytváří přizpůsobený řetězec pro zobrazení sloupce. Určeno k flexibilnímu a ' +
        'dynamickému vykreslování sloupců v tabulkových datech.',
      dataForView: 'Data For View',
      dataForViewDescription:
        'Zpracovává a formátuje data pro zobrazení v tabulce. Mapuje definice sloupců na odpovídající ' +
        'hodnoty řádků s použitím konvertorů a lokalizačních transformací, pokud jsou definovány. ' +
        'Zajišťuje efektivní opětovné použití předchozích výsledků při nezměněných datech pro ' +
        'zlepšení výkonu.',
      discriminator: 'Discriminator',
      discriminatorDescription:
        'Implementuje logiku detekce změn pomocí vlastní funkce diskriminátoru. Porovnává aktuální ' +
        'hodnotu s předchozí hodnotou pro určení, zda došlo k rozdílu. Vrací uložený výsledek, pokud ' +
        'se hodnota nezměnila, což zvyšuje výkon díky eliminaci zbytečných přepočtů.',
      tsmFiltersToStringPipe: 'Tsm Filters To String Pipe',
      tsmFiltersToStringPipeDescription:
        'Převádí seznam filtrů na čitelný řetězec. Z každého filtru extrahuje vlastnost `displayValue` ' +
        'a spojuje je čárkami, aby vytvořil jeden řetězcový výstup.',
      linkHrefComponent: 'Link Href Component',
      linkHrefComponentDescription:
        'Definuje pipe pro zpracování transformací souvisejících s odkazy. Používá vstupní data z řádku ' +
        'a vrací prázdný řetězec jako výstup.',
      listingProfileCode: 'Listing Profile Code',
      listingProfileCodeDescription:
        'Vytváří pipe pro získání dat profilu záznamu podle kódu. Podporuje filtrování podle kódu záznamu ' +
        'nebo načtení všech souvisejících dat. Extrahuje specifická pole nebo celou datovou strukturu. ' +
        'Spouští akce pro načtení dat, pokud nejsou dostupná ve store.',
      listingProfile: 'Listing Profile',
      listingProfileDescription:
        'Poskytuje pipe pro získání dat profilu záznamu podle ID. Umožňuje načítat konkrétní pole nebo ' +
        'všechna data dle potřeby. Automaticky spouští akce k načtení dat, pokud chybí ve store.',
      listingTypeCodeLoader: 'Listing Type Code Loader',
      listingTypeCodeLoaderDescription:
        'Umožňuje načítání dat typu záznamu podle jeho kódu. Podporuje načítání konkrétních polí ' +
        'nebo celých dat. Zajišťuje automatické načtení chybějících dat do store.',
      listingTypeLoader: 'Listing Type Loader',
      listingTypeLoaderDescription:
        'Načítá data typu záznamu podle ID ze store. Umožňuje načítání konkrétních polí nebo všech dat. ' +
        'Automaticky spouští načítání dat do store při jejich nedostupnosti.',
      tsmLovboxMultiReadonlySplit: 'Tsm Lovbox Multi Readonly Split',
      tsmLovboxMultiReadonlySplitDescription:
        'Rozdělí řetězec hodnot na pole řetězců podle čárek. Vrátí původní pole, pokud je vstup již polem.',
      memoizedDiscriminator: 'Memoized Discriminator',
      memoizedDiscriminatorDescription:
        'Optimalizuje opakované výpočty ukládáním výsledků funkce do mezipaměti. Spustí poskytnutou funkci, ' +
        'pokud diskriminátor detekuje změny mezi předchozí a aktuální hodnotou. Vrátí uložený výsledek, pokud ' +
        'nejsou detekovány žádné změny.',
      memoized: 'Memoized',
      memoizedDescription:
        'Spustí zadanou funkci s poskytnutou hodnotou. Nepoužívejte, pokud plně nerozumíte důsledkům, ' +
        'protože funkce bude přímo volána bez ukládání do mezipaměti nebo optimalizace.',
      tsmSelectedColumnsToString: 'Tsm Selected Columns To String',
      tsmSelectedColumnsToStringDescription:
        'Převede pole vybraných sloupců tabulky na řetězec oddělený čárkami. Extrahuje property "field" ' +
        'z každého sloupce v zadaném poli a spojí je do jednoho řetězce.',
    },
  },
};
