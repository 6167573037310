import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'customColumnValue',
  pure: true,
})
export class CustomColumnValuePipe implements PipeTransform {
  public requiresRowData = true;

  transform(
    columnValue: any,
    customTransformFn: (rowData: any) => string,
    rowData: any,
  ): any {
    return customTransformFn(rowData);
  }
}
