import {AbstractAuditedEntity} from '@tsm/framework/root';
import {SelectItem} from 'primeng/api/selectitem';

// TODO ceka na prepis na public objekt => AbstractAuditedEntityPublic
export interface UserType extends AbstractAuditedEntity<string> {
  validityTo?: string;
  code?: string;
  entitySpecId?: string;
  config?: any;
  type: UserTypeEnum;
  name?: string;
  icon?: string;
  validityFrom?: string;
  description?: string;
  registry?: any;
}

export enum UserTypeEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  WORK_GROUP = 'WORK_GROUP',
}

export function getAllUserTypeEnum(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(UserTypeEnum).forEach((val) =>
    items.push({label: UserTypeEnum[val], value: val}),
  );
  return items;
}
