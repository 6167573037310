import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {WorklogDataService, WorklogService} from '../services';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  EndWorklogTimer,
  EndWorklogTimerError,
  EndWorklogTimerSuccess,
  StartWorklogTimer,
  StartWorklogTimerSuccess,
} from '../actions';
import {translation} from '../i18n';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {ListingService} from '@tsm/listing-lib/service';
import {Config, ConfigService} from '@tsm/framework/config';
import {Store} from '@ngrx/store';

@Injectable()
export class WorklogTimerEffects {
  start$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartWorklogTimer),
      exhaustMap(({worklogTimer}) =>
        this.worklogDataService.startWorklogStopwatch(worklogTimer).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.worklogTimer.effects.worklogTimerStartSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return StartWorklogTimerSuccess({worklogTimer: worklogTimer});
            } else {
              this.ts.showError(
                env.error,
                translation.worklogTimer.effects.worklogTimerEndError,
              );
              return EndWorklogTimerError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  stop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndWorklogTimer),
      concatMap(({worklogTimer}) =>
        this.worklogDataService.stopWorklogTimer().pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.worklogTimer.effects.worklogTimerEndSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return EndWorklogTimerSuccess({worklogTimer: env.data});
            } else {
              this.ts.showError(
                env.error,
                translation.worklogTimer.effects.worklogTimerEndError,
              );
              return EndWorklogTimerError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private worklogService: WorklogService,
    private listingService: ListingService,
    private worklogDataService: WorklogDataService,
    private config: ConfigService<Config>,
    private store: Store,
    private toast: ToastService,
    private ts: ToastService,
  ) {}
}
