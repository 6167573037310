import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'notificationAddress',
  pure: true,
})
export class NotificationAddressPipe implements PipeTransform {
  transform(value: any): any {
    // city: "Praha"
    // country: "Česká republika"
    // name: "Jarda Carda"
    // partOfCity: "Holešovice"
    // partOfCityNo: "1272"
    // partOfCityNoType: "č. p."
    // postalCode: "17000"
    // street: "Dělnická"
    // streetNo: "53"
    // zip: "17000"
    return value?.formattedAddress
      ? `${value?.formattedAddress}`
      : `${value?.street} ${value?.streetNo}, ${value?.city} ${value?.postalCode}`;
  }
}
