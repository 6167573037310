import {Component, HostBinding, Input} from '@angular/core';
import {translation} from '@tsm/shared-i18n';

@Component({
  selector: 'tsm-button-success',
  template: `
    <button
      pButton
      pRipple
      type="button"
      [label]="label ? label : (translationShared.shared.save | transloco)"
      [class]="class ? class : 'p-button-success'"
      [class.spinner]="progress"
      [icon]="icon"
      iconPos="{{ iconPos }}"
      dtlHotkey="ctrl+enter"
      dtlHotkeyGroup="Dialog"
      [disabled]="disabled"
      [dtlHotkeyLocal]="false"
      [dtlHotkeyDisabledIn]="[]"
      [style]="style"
      dtlHotkeyText="{{ translationShared.shared.save | transloco }}"
      data-cy="button-success"
    ></button>
  `,
})
export class ButtonSuccessComponent {
  translationShared = translation;

  /**
   * Label pro změnu názvu tlačítka
   */
  @Input() label: string;

  /**
   * Class pro změnu stylu tlačítka
   */
  @Input() class: string;

  @Input() icon = 'pi pi-check';

  @Input() iconPos = 'left';

  @Input() progress = false;

  @Input() disabled = false;

  @Input() style: {};

  @HostBinding('style.pointer-events')
  get disableClicking() {
    return this.disabled || this.progress ? 'none' : null;
  }

  constructor() {}
}
