export class TranslationUtils {
  public static translatedValue(
    obj: Localized,
    language: string,
    attrName: string,
  ): string {
    if (language == null) {
      return null;
    }
    return obj.localizationData != null &&
      obj.localizationData[language] != null
      ? obj.localizationData[language][attrName]
      : null;
  }
}

export interface Localized {
  localizationData?: any;
}
