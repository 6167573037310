<dtl-grid class="grid-nogutter" data-cy="dtl-form-input-switch">
  <dtl-col
    *ngIf="leftLabel != null"
    fixed
    [class.cursor-pointer]="!readonly"
    style="white-space: nowrap"
    (click)="leftLabelClicked()"
  >
    <span id="leftLabel">{{ leftLabel }}</span>
  </dtl-col>
  <dtl-col fixed class="input-switch">
    <p-inputSwitch
      [(ngModel)]="val"
      [inputId]="inputId"
      [disabled]="readonly"
      (onChange)="onTouched(true)"
      ariaLabelledBy="leftLabel rightLabel"
    ></p-inputSwitch>
  </dtl-col>
  <dtl-col
    *ngIf="rightLabel != null"
    fixed
    [class.cursor-pointer]="!readonly"
    (click)="rightLabelClicked()"
  >
    <span id="rightLabel">{{ rightLabel }}</span>
  </dtl-col>
</dtl-grid>
