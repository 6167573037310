import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {SharingType} from '../models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharingTypeService {
  private readonly SHARING_TYPE = `sharing-type`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getAll(): Observable<Envelope<SharingType[]>> {
    return this.apiService.get<SharingType[], SharingType[]>(
      `${this.BASE_URL}/${this.SHARING_TYPE}`,
    );
  }
}
