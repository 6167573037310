import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatText',
  pure: true,
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const formattedText = value
      .replace(/\\r\\n|\\r|\\n/g, '<br>')
      .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      .replace(/\r\n|\r|\n/g, '<br>')
      .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

    return formattedText;
  }
}
