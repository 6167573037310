import {createAction, props} from '@ngrx/store';
import {MnemonicConfig} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadMnemonicConfig = createAction(
  '[MnemonicConfig] Load MnemonicConfig',
);
export const LoadMnemonicConfigSuccess = createAction(
  '[MnemonicConfig] Load MnemonicConfig Success',
  props<{
    entities: MnemonicConfig[];
  }>(),
);
export const LoadMnemonicConfigError = createAction(
  '[MnemonicConfig] Load MnemonicConfig Error',
  props<{
    error: any;
  }>(),
);

export const LoadMnemonicConfigById = createAction(
  '[MnemonicConfig] Load MnemonicConfig by id',
  props<{
    id: string;
  }>(),
);
export const LoadMnemonicConfigByIdSuccess = createAction(
  '[MnemonicConfig] Load MnemonicConfig by id Success',
  props<{
    entity: MnemonicConfig;
  }>(),
);
export const LoadMnemonicConfigByIdError = createAction(
  '[MnemonicConfig] Load MnemonicConfig by id Error',
  props<{
    error: any;
  }>(),
);

export const LoadMnemonicConfigByCode = createAction(
  '[MnemonicConfig] Load MnemonicConfig by code',
  props<{
    code: string;
  }>(),
);
export const LoadMnemonicConfigByCodeSuccess = createAction(
  '[MnemonicConfig] Load MnemonicConfig by code Success',
  props<{
    entity: MnemonicConfig;
  }>(),
);
export const LoadMnemonicConfigByCodeError = createAction(
  '[MnemonicConfig] Load MnemonicConfig by code Error',
  props<{
    error: any;
  }>(),
);

export const UpsertMnemonicConfig = createAction(
  '[MnemonicConfig] Upsert MnemonicConfig',
  props<{
    mnemonicConfig: MnemonicConfig;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertMnemonicConfigSuccess = createAction(
  '[MnemonicConfig] Upsert MnemonicConfig Success',
  props<{
    mnemonicConfig: MnemonicConfig;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertMnemonicConfigError = createAction(
  '[MnemonicConfig] Upsert MnemonicConfig Error',
  props<{
    error: any;
  }>(),
);

export const DeleteMnemonicConfig = createAction(
  '[MnemonicConfig] Delete MnemonicConfig',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing: boolean;
  }>(),
);
export const DeleteMnemonicConfigSuccess = createAction(
  '[MnemonicConfig] Delete MnemonicConfig Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteMnemonicConfigError = createAction(
  '[MnemonicConfig] Delete MnemonicConfig Error',
  props<{
    error: any;
  }>(),
);

export const DiffMnemonicConfig = createAction(
  '[MnemonicConfig] Diff MnemonicConfig',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffMnemonicConfigSuccess = createAction(
  '[MnemonicConfig] Diff MnemonicConfig Success',
  props<{
    mnemonicConfigs: MnemonicConfig[];
    listingId: string;
  }>(),
);
export const DiffMnemonicConfigError = createAction(
  '[MnemonicConfig] Diff MnemonicConfig Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
