import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteEntitySpecificationError,
  DeleteEntitySpecificationSuccess,
  DiffEntitySpecification,
  DiffEntitySpecificationError,
  DiffEntitySpecificationSuccess,
  LoadEntitySpecificationByCode,
  LoadEntitySpecificationByCodeError,
  LoadEntitySpecificationByCodeSuccess,
  LoadEntitySpecificationById,
  LoadEntitySpecificationsError,
  LoadEntitySpecificationsSuccess,
  LoadEntitySpecificationSuccess,
  UpsertEntitySpecification,
  UpsertEntitySpecificationError,
  UpsertEntitySpecificationSuccess,
} from '../actions';
import {EntitySpecification} from '../models';
import {addHours} from 'date-fns';

export type EntitySpecificationsState = EntityState<
  IdentifiedPack<EntitySpecification>
>;

export const specificationsAdapter: EntityAdapter<
  IdentifiedPack<EntitySpecification>
> = createEntityAdapter<IdentifiedPack<EntitySpecification>>({});
export const specificationsInitialState: EntitySpecificationsState =
  specificationsAdapter.getInitialState({});

export const entitySpecificationsReducer = createReducer(
  specificationsInitialState,

  on(LoadEntitySpecificationById, (state, {id}) =>
    specificationsAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadEntitySpecificationSuccess,
    (state, {entitySpecification, invalidCached}) =>
      specificationsAdapter.upsertOne(
        {
          id: entitySpecification.id,
          data: {
            ...entitySpecification,
          },
          loading: false,
          error: null,
          validUntil:
            invalidCached === true
              ? addHours(new Date(), 1)
              : state.entities[entitySpecification.id].validUntil,
        },
        state,
      ),
  ),

  on(LoadEntitySpecificationByCode, (state, {code}) =>
    specificationsAdapter.upsertOne(
      {
        id: code,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadEntitySpecificationByCodeSuccess,
    (state, {entitySpecification, invalidCached}) => {
      const newState = specificationsAdapter.removeOne(
        entitySpecification.code,
        state,
      );
      return specificationsAdapter.upsertOne(
        {
          id: entitySpecification.id,
          data: entitySpecification,
          loading: false,
          error: null,
          validUntil:
            invalidCached === true
              ? addHours(new Date(), 1)
              : state.entities[entitySpecification.id].validUntil,
        },
        newState,
      );
    },
  ),

  on(LoadEntitySpecificationByCodeError, (state, {code, error}) =>
    specificationsAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertEntitySpecification, (state, {specification}) =>
    specificationsAdapter.upsertOne(
      {
        id: specification.id,
        data: null,
        loading: true,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(UpsertEntitySpecificationSuccess, (state, {specification}) =>
    specificationsAdapter.upsertOne(
      {
        id: specification.id,
        data: specification,
        loading: false,
        error: null,
        validUntil: addHours(new Date(), 1),
      },
      state,
    ),
  ),

  on(
    UpsertEntitySpecificationError,
    DeleteEntitySpecificationError,
    (state, {id, error}) =>
      specificationsAdapter.upsertOne(
        {
          id: id,
          loading: false,
          error: error,
          validUntil: null,
        },
        state,
      ),
  ),

  on(DeleteEntitySpecificationSuccess, (state, {id}) =>
    specificationsAdapter.removeOne(id, state),
  ),

  on(LoadEntitySpecificationsSuccess, (state, {entities}) => ({
    ...specificationsAdapter.addMany(
      entities.map((statusOnly) => ({
        id: statusOnly.id,
        data: statusOnly,
        loading: false,
        error: null,
        validUntil: addHours(new Date(), 1),
      })),
      state,
    ),
  })),

  on(DiffEntitySpecification, (state, {diffEntities}) =>
    specificationsAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffEntitySpecificationSuccess, (state, {entitySpecifications}) =>
    specificationsAdapter.upsertMany(
      entitySpecifications.map((specification) => {
        return {
          id: specification.id,
          data: state.entities[specification.id].data,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffEntitySpecificationError, (state, {diffEntities, error}) =>
    specificationsAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
