import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Optional,
  Output,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {
  MonacoEditorConstructionOptions,
  MonacoStandaloneCodeEditor,
} from '@tsm/framework/monaco-editor';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {DtlMonacoEditorModule} from '@tsm/framework/monaco-editor';

@Component({
  selector: 'dtl-form-input-xml',
  templateUrl: './form-input-xml.component.html',
  host: {
    class: 'p-state-filled-fix p-inputtext',
  },
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputXmlComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputXmlComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputXmlComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DtlMonacoEditorModule, FormsModule],
})
export class FormInputXmlComponent extends ParentWidgetAccessorComponent {
  @Input() updateIfDirty = false;

  @Input() widgetHeight: any;

  @Input() inline = false;

  @Input() resizable = true;

  @Output() init: EventEmitter<MonacoStandaloneCodeEditor> = new EventEmitter();

  private _editorOptions: MonacoEditorConstructionOptions;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  get editorOptions(): MonacoEditorConstructionOptions {
    return {
      // manual settings
      fontSize: 11,
      minimap: {
        enabled: false,
      },
      // taken from inputs
      readOnly: this.readonly,
      tabIndex: this.tabindex,
      ...this._editorOptions,
    };
  }

  @Input()
  set editorOptions(value: MonacoEditorConstructionOptions) {
    this._editorOptions = value;
  }

  onEditorInitialized(editor: MonacoStandaloneCodeEditor) {
    editor.onDidFocusEditorWidget(() => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    editor.onDidBlurEditorWidget(() => {
      this.focused = false;
      this.cdr.markForCheck();
    });

    this.init.emit(editor);
  }
}
