import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {FormatterTemplate} from '../models';
import {
  DiffFormatterTemplate,
  DiffFormatterTemplateError,
  DiffFormatterTemplateSuccess,
  LoadFormatterTemplateById,
  LoadFormatterTemplateByIdError,
  LoadFormatterTemplateByIdSuccess,
  UpsertFormatterTemplate,
  UpsertFormatterTemplateSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';

export type FormatterTemplateState = EntityState<
  IdentifiedPack<FormatterTemplate>
>;

export const formatterTemplateAdapter: EntityAdapter<
  IdentifiedPack<FormatterTemplate>
> = createEntityAdapter<IdentifiedPack<FormatterTemplate>>({});
export const initialState: FormatterTemplateState =
  formatterTemplateAdapter.getInitialState({});

export const formatterTemplateReducer = createReducer(
  initialState,

  on(UpsertFormatterTemplate, (state, {formatterTemplate}) =>
    formatterTemplateAdapter.updateOne(
      {
        id: formatterTemplate.id,
        changes: {
          ...state.entities[formatterTemplate.id],
          loading: false,
        },
      },
      state,
    ),
  ),

  on(UpsertFormatterTemplateSuccess, (state, {formatterTemplate}) =>
    formatterTemplateAdapter.upsertOne(
      {
        id: formatterTemplate.id,
        data: formatterTemplate,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(LoadFormatterTemplateById, (state, {id}) =>
    formatterTemplateAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadFormatterTemplateByIdSuccess, (state, {formatterTemplate}) =>
    formatterTemplateAdapter.upsertOne(
      {
        id: formatterTemplate.id,
        data: formatterTemplate,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadFormatterTemplateByIdError, (state, {id, error}) =>
    formatterTemplateAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),
  on(DiffFormatterTemplate, (state, {diffEntities}) =>
    formatterTemplateAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffFormatterTemplateSuccess, (state, {formatterTemplates}) =>
    formatterTemplateAdapter.upsertMany(
      formatterTemplates.map((formatterTemplate) => {
        return {
          id: formatterTemplate.id,
          data: formatterTemplate,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffFormatterTemplateError, (state, {diffEntities, error}) =>
    formatterTemplateAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
