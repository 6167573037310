export interface GridDataHttpModel<Type = any> {
  content: Type[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: Sort;
  numberOfElements: number;
  size: number;
  number: number;
}

export interface Pageable {
  sort: Sort;
  pageSize: number;
  pageNumber: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
}

export const emptyGridDataHttpModel = {
  content: [],
  pageable: {
    sort: {
      unsorted: false,
      sorted: true,
      empty: false,
    },
    offset: 0,
    pageNumber: 0,
    pageSize: (window as any)?.app?.params?.dataViewPageSizeDefault ?? 20,
    paged: true,
    unpaged: false,
  },
  totalPages: 0,
  totalElements: 0,
  first: true,
  last: true,
  sort: {
    unsorted: false,
    sorted: true,
    empty: false,
  },
  size: 20,
  number: 0,
  numberOfElements: 0,
};
