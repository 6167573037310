import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  CollapsedWorklog,
  DeleteDataWorklog,
  EditDataWorklog,
  EditDataWorklogText,
  LoadDataWorklog,
  LoadDataWorklogSuccess,
  SaveDataWorklog,
} from '../actions';
import {Worklog} from '../models';
import {WorklogState} from '../reducers';
import {Config, ConfigService} from '@tsm/framework/config';
import {UserPipe} from '@tsm/user-management/service';
import {RuntimeService} from '@tsm/runtime-info';
import {translation as translationShared} from '@tsm/shared-i18n';
import {DateFormatPipe} from '@tsm/shared';
import {
  FilterModel,
  FilterOperator,
  ListingProfile,
  ListingService,
  Table,
  TableColumn,
  TableDefault,
  TableShowConfig,
  TableType,
} from '@tsm/listing-lib/service';
import {filter, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {translation} from '../i18n';
import {getUserId} from '@tsm/framework/functions';

@Injectable({
  providedIn: 'root',
})
export class WorklogService {
  private readonly BASE_URL: string;
  private USER_PARAMETERS: string;

  translationShared = translationShared;
  translation = translation;

  private defaults: TableDefault = {
    pageSize: 200,
    columns: [
      // {
      //   field: 'id',
      //   header: translationShared.shared.id,
      //   tooltip: translationShared.shared.id,
      //   visible: false,
      //   filterWidget: import(
      //   '@tsm/listing-lib/widgets/filters/dtl-filter-input'
      // ).then((x) => x.DtlFilterInputComponent)
      // },
      {
        field: 'userId',
        header: translationShared.shared.user,
        tooltip: translationShared.shared.user,
        filterWidget: import(
          '@tsm/user-management/widgets/filters/user-filter'
        ).then((x) => x.UserFilterComponent),
        converter: UserPipe,
        width: '100px',
        visible: false,
      },
      {
        field: 'userGroupId',
        header: translationShared.shared.userGroup,
        tooltip: translationShared.shared.userGroup,
        filterWidget: import(
          '@tsm/user-management/widgets/filters/user-group-filter'
        ).then((x) => x.UserGroupFilterComponent),
        filterWidgetContext: {field: 'id', hideUnassigned: true},
        sortDisabled: true,
        exportDisabled: true,
        width: '100px',
        visible: false,
      },
      {
        field: 'whenInserted',
        header: translationShared.shared.createDate,
        tooltip: translationShared.shared.createDate,
        width: '200px',
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-calendar-range'
        ).then((x) => x.DtlFilterCalendarRangeComponent),
        converter: DateFormatPipe,
        converterParams: ['dateTimeS'],
        visible: false,
      },
      {
        field: 'startDate',
        header: translation.worklogs.started,
        tooltip: translation.worklogs.started,
        width: '200px',
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-calendar-range'
        ).then((x) => x.DtlFilterCalendarRangeComponent),
        converter: DateFormatPipe,
        converterParams: ['dateTimeS'],
        visible: false,
      },
      {
        field: 'endDate',
        header: translation.worklogs.ended,
        tooltip: translation.worklogs.ended,
        width: '200px',
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-calendar-range'
        ).then((x) => x.DtlFilterCalendarRangeComponent),
        converter: DateFormatPipe,
        converterParams: ['dateTimeS'],
        visible: false,
      },
      {
        field: 'description',
        header: translation.worklog,
        tooltip: translation.worklog,
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-input'
        ).then((x) => x.DtlFilterInputComponent),
        filterFulltextSearch: true,
        width: '100px',
        visible: false,
      },
      {
        field: 'private',
        header: translationShared.shared.privateComment,
        tooltip: translationShared.shared.privateComment,
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-boolean'
        ).then((x) => x.DtlFilterBooleanComponent),
        visible: false,
        visibleCard: false,
      },
    ],
  };

  constructor(
    private store: Store<WorklogState>,
    private runtimeService: RuntimeService,
    private listingService: ListingService,
    private config: ConfigService<Config>,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
    this.USER_PARAMETERS = 'worklog';
  }

  loadWorklogByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
    listingTypeCode: string,
    profileId: string,
    showConfig: TableShowConfig,
  ): Observable<Table> {
    this.store.dispatch(LoadDataWorklog({ownerId, ownerType}));
    return this.listingService
      .getTable(
        'tsm-worklog-' + ownerId,
        {
          type: listingTypeCode,
          url: this.config.value.apiUrls.dms + '/worklog/filtering',
        },
        {
          ...this.defaults,
          profileId,
          filters: [
            {
              field: 'ownerId',
              operator: FilterOperator.eq,
              value: ownerId,
              visible: false,
              readonly: true,
            },
            {
              field: 'ownerType',
              operator: FilterOperator.eq,
              value: ownerType,
              visible: false,
              readonly: true,
            },
          ],
        },
        showConfig,
      )
      .pipe(
        filter((x) => x.dataLoading === false && x.profilesLoading === false),
        tap((tableState) => {
          this.store.dispatch(
            LoadDataWorklogSuccess({
              ownerType: ownerType,
              ownerId: ownerId,
              worklogs: tableState.data.items as Array<Worklog>,
            }),
          );
          // this.store.dispatch(LoadDataWorklogError({
          //   ownerType: ownerType,
          //   ownerId: ownerId,
          //   error: 'Chyba pri nacteni dat!'
          // }));
        }),
      );
  }

  // funkce na zaklade commentTypeCodes upravi context filtru CommentType za predpokladu, ze je nadefinovany jako dynam. sloupecek
  updateWorklogTypeColumn(ownerId: string, commentTypeCodes: string[]) {
    this.listingService
      .getTableState('tsm-worklog-' + ownerId)
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((table) => {
        const col = table.columns.find(
          (c) =>
            c.field == 'worklogType.name' ||
            c.displayField == 'worklogType.name',
        );
        if (col != null) {
          this.listingService.changeColumn('tsm-worklog-' + ownerId, {
            ...col,
            filterField: 'worklogType.id',
            filterWidgetContext: commentTypeCodes,
          });
        }
      });
  }

  saveWorklog(
    ownerId: string,
    ownerType: string,
    worklog: Worklog,
    noSuccessToast?: boolean,
  ) {
    this.store.dispatch(
      SaveDataWorklog({ownerId: ownerId, ownerType, worklog, noSuccessToast}),
    );
  }

  deleteWorklog(ownerId: string, ownerType: string, worklog: Worklog) {
    this.store.dispatch(
      DeleteDataWorklog({ownerId: ownerId, ownerType, worklog}),
    );
  }

  editWorklog(ownerId: string, ownerType: string, worklog: Worklog) {
    this.store.dispatch(
      EditDataWorklog({
        ownerId: ownerId,
        ownerType: ownerType,
        worklog: worklog,
        openEdit: !worklog.peditorVisibility,
      }),
    );
  }

  editWorklogText(
    ownerId: string,
    ownerType: string,
    event: {worklog: Worklog; worklogText: string},
  ) {
    this.store.dispatch(
      EditDataWorklogText({
        ownerId: ownerId,
        ownerType: ownerType,
        worklog: event.worklog,
        worklogText: event.worklogText,
      }),
    );
  }

  collapsedWorklog(ownerId: string, ownerType: string, worklog: Worklog) {
    this.store.dispatch(
      CollapsedWorklog({
        ownerId: ownerId,
        ownerType: ownerType,
        worklog: worklog,
      }),
    );
  }

  filterValueChanged(ownerId: string, ownerType: string, value: FilterModel[]) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      true,
    );
    this.listingService.setFilters('tsm-worklog-' + ownerId, value);
  }

  selectedProfileIdChanged(
    ownerId: string,
    ownerType: string,
    state: {
      profileId: string;
      showConfig: TableShowConfig;
    },
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      false,
    );
    this.listingService.setSelectedProfile(
      'tsm-worklog-' + ownerId,
      state.profileId,
      state.showConfig,
    );
  }

  saveProfile(
    ownerId: string,
    ownerType: string,
    state: {profile: ListingProfile; type: TableType},
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      false,
    );
    this.listingService.saveProfile(
      'tsm-worklog-' + ownerId,
      state.profile,
      state.type,
    );
  }

  deleteProfile(
    ownerId: string,
    ownerType: string,
    state: {profile: ListingProfile; type: TableType},
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      false,
    );
    this.listingService.deleteProfile(
      'tsm-worklog-' + ownerId,
      state.profile,
      state.type,
    );
  }

  clearProfile(
    ownerId: string,
    ownerType: string,
    showConfig: TableShowConfig,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      false,
    );
    this.listingService.clearProfile('tsm-worklog-' + ownerId, showConfig);
  }

  resetData(ownerId: string, ownerType: string) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      false,
    );
    this.listingService.resetData('tsm-worklog-' + ownerId);
  }

  refreshWorklogs(ownerId: string, ownerType: string) {
    this.listingService.refreshData('tsm-worklog-' + ownerId);
  }

  saveAsDefaultProfile(
    ownerId: string,
    ownerType: string,
    state: {
      profile: ListingProfile;
      type: TableType;
    },
    listingId?: string,
  ) {
    const entity = {
      ...state.profile,
    };
    if (!entity.userId) {
      entity.userId = getUserId();
    }
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + (listingId || ownerId),
      false,
    );
    this.listingService.saveAsDefaultProfile(
      'tsm-worklog-' + (listingId || ownerId),
      entity,
      state.type,
      false,
    );
  }

  saveAsDefaultAllProfile(
    ownerId: string,
    ownerType: string,
    state: {profile: ListingProfile; type: TableType},
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      false,
    );
    this.listingService.saveAsDefaultAllProfile(
      'tsm-worklog-' + ownerId,
      state.profile,
      state.type,
      false,
    );
  }

  pageSize(ownerId: string, ownerType: string, pageSize: number) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      true,
    );
    this.listingService.setPageSize('tsm-worklog-' + ownerId, pageSize);
  }

  setColumns(ownerId: string, ownerType: string, columns: TableColumn[]) {
    this.listingService.customChangeSettingTable(
      'tsm-worklog-' + ownerId,
      true,
    );
    this.listingService.setColumns('tsm-worklog-' + ownerId, columns);
  }

  // zatim se nepouziva, nejspis ale bude NEMAZAT!
  // worklogsViewTypeChanged(ownerId: string, ownerType: string, value: boolean) {
  //   this.store.dispatch(UpsertUserParameterByUserIdAndName({
  //     name: UserParameterName.COMMENT_VIEW_TYPE,
  //     userId: getUserId(),
  //     value: value === true ? 'table' : 'classic'
  //   }));
  // }
}
