import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {FormatterDocumentTemplate} from '../models';
import {
  DiffFormatterDocumentTemplate,
  DiffFormatterDocumentTemplateError,
  DiffFormatterDocumentTemplateSuccess,
  LoadFormatterDocumentTemplateById,
  LoadFormatterDocumentTemplateByIdError,
  LoadFormatterDocumentTemplateByIdSuccess,
  UpsertFormatterDocumentTemplate,
  UpsertFormatterDocumentTemplateSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';

export type FormatterDocumentTemplateState = EntityState<
  IdentifiedPack<FormatterDocumentTemplate>
>;

export const formatterDocumentTemplateAdapter: EntityAdapter<
  IdentifiedPack<FormatterDocumentTemplate>
> = createEntityAdapter<IdentifiedPack<FormatterDocumentTemplate>>({});
export const initialState: FormatterDocumentTemplateState =
  formatterDocumentTemplateAdapter.getInitialState({});

export const formatterDocumentTemplateReducer = createReducer(
  initialState,

  on(UpsertFormatterDocumentTemplate, (state, {formatterDocumentTemplate}) =>
    formatterDocumentTemplateAdapter.updateOne(
      {
        id: formatterDocumentTemplate.id,
        changes: {
          ...state.entities[formatterDocumentTemplate.id],
          loading: false,
        },
      },
      state,
    ),
  ),

  on(
    UpsertFormatterDocumentTemplateSuccess,
    (state, {formatterDocumentTemplate}) =>
      formatterDocumentTemplateAdapter.upsertOne(
        {
          id: formatterDocumentTemplate.id,
          data: formatterDocumentTemplate,
          loading: false,
          error: null,
          validUntil: null,
        },
        state,
      ),
  ),

  on(LoadFormatterDocumentTemplateById, (state, {id}) =>
    formatterDocumentTemplateAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadFormatterDocumentTemplateByIdSuccess,
    (state, {formatterDocumentTemplate}) =>
      formatterDocumentTemplateAdapter.upsertOne(
        {
          id: formatterDocumentTemplate.id,
          data: formatterDocumentTemplate,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(LoadFormatterDocumentTemplateByIdError, (state, {id, error}) =>
    formatterDocumentTemplateAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DiffFormatterDocumentTemplate, (state, {diffEntities}) =>
    formatterDocumentTemplateAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(
    DiffFormatterDocumentTemplateSuccess,
    (state, {formatterDocumentTemplates}) =>
      formatterDocumentTemplateAdapter.upsertMany(
        formatterDocumentTemplates.map((formatterDocumentTemplate) => {
          return {
            id: formatterDocumentTemplate.id,
            data: formatterDocumentTemplate,
            error: null,
            loading: false,
          };
        }),
        state,
      ),
  ),

  on(DiffFormatterDocumentTemplateError, (state, {diffEntities, error}) =>
    formatterDocumentTemplateAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
