import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decimalNumberFormat',
})
export class DecimalNumberFormatPipe implements PipeTransform {
  transform(value: any): number {
    return this.localeString(value);
  }

  localeString(nStr) {
    if (nStr === '') {
      return '';
    }

    let x, x1, x2, y1, y2;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');
    y2 = x1.split(',');
    x = y2[0] + y1;
    x = x1 + x2;

    return x;
  }
}
