import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {BackupSet, BackupSetImport} from '@tsm/listing-lib/service';

export const LoadBackupSetById = createAction(
  '[BackupSet] Load BackupSet by ID',
  props<{id: string}>(),
);
export const LoadBackupSetByIdSuccess = createAction(
  '[BackupSet] Load BackupSet by ID Success',
  props<BackupSet>(),
);
export const LoadBackupSetByIdError = createAction(
  '[BackupSet] Load BackupSet by ID Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const UpsertBackupSet = createAction(
  '[BackupSet] Upsert BackupSet]',
  props<{
    backupSet: BackupSet;
    listingId: string;
    navigateAfterRefresh: boolean;
  }>(),
);
export const UpsertBackupSetSuccess = createAction(
  '[BackupSet] Upsert BackupSet Success]',
  props<{
    backupSet: BackupSet;
    listingId: string;
    navigateAfterRefresh: boolean;
  }>(),
);
export const UpsertBackupSetError = createAction(
  '[BackupSet] Upsert BackupSet Error]',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const DeleteBackupSet = createAction(
  '[BackupSet] Delete BackupSet',
  props<{
    id: string;
    listingId: string;
    redirect?: boolean;
  }>(),
);
export const DeleteBackupSetSuccess = createAction(
  '[BackupSet] Delete BackupSet Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteBackupSetError = createAction(
  '[BackupSet] Delete BackupSet Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const UploadBackupSet = createAction(
  '[BackupSet] Upload BackupSet',
  props<{
    file: any;
  }>(),
);

export const UploadBackupSetSuccess = createAction(
  '[BackupSet] Upload BackupSetSuccess',
  props<{
    backupSetImport: BackupSetImport;
  }>(),
);

export const UploaBackupSetError = createAction(
  '[BackupSet] Upload BackupSetError',
  props<{
    error: TsmError;
  }>(),
);
