import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  ValidatorFn,
  ɵFormArrayRawValue,
  ɵFormArrayValue,
} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {
  fluentChangedSkipValues,
  fluentFilterSkipValues,
  removeListItem,
} from '../utils';
import {TypedTsmAbstractControl} from './typed-tsm-abstract-control';

export class TypedTsmFormArray<
  T extends TypedTsmAbstractControl<any> = any,
> extends FormArray<T> {
  public forceReadonly = false;
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;
  private _beforePatch: Subject<{control: TypedTsmFormArray; value: any[]}> =
    new Subject();
  public defaults: T = null;
  public beforePatch: Observable<{control: TypedTsmFormArray; value: any[]}> =
    null;
  public filterMap: {[dataPointer: string]: any} = null;
  public default = false;
  public readonly = false;

  /** @internal */
  _onReadonly: Function[];

  constructor(
    controls: Array<T>,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    this.touchedChanges = this._touchedChanges.asObservable();
    this.beforePatch = this._beforePatch.asObservable();
  }

  setValue(
    value: ɵFormArrayRawValue<T>,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }
    super.setValue(value, options);
  }

  push(control: T, options: {emitEvent?: boolean} = {}): void {
    this.controls.push(control);
    (this as any)._registerControl(control);
    this.updateValueAndValidity({emitEvent: options.emitEvent});
    (this as any)._onCollectionChange();
  }

  patchValue(
    value: ɵFormArrayValue<T>,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    // :-) ze serveru chodi random nully tam kde ma byt pole a chceme byt "resilientni"
    if (value == null) {
      value = [];
    }
    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }
    // :-( pridani controls podle value
    // if (this.controls.length !== value.length) {
    //   this.patchControls(value);
    // }

    this._beforePatch.next({
      control: this,
      value: value,
    });
    super.patchValue(value, options);
  }

  markAsTouchedRecursively() {
    this.markAsTouched({onlySelf: true});
    this.markAsDirty();
    this.controls.forEach((control) => {
      (control as any).markAsTouchedRecursively();
    });
  }

  markAsDefaultRecursively() {
    this.default = true;
    this.controls.forEach((control) => {
      (control as any).markAsDefaultRecursively();
    });
  }

  markAsNonDefaultRecursively() {
    this.default = false;
    this.controls.forEach((control) => {
      (control as any).markAsNonDefaultRecursively();
    });
  }

  markAsUntouchedRecursively() {
    this.markAsUntouched();
    this.markAsPristine();
    this.controls.forEach((control) => {
      (control as any).markAsUntouchedRecursively();
    });
  }

  markAsPristineRecursively() {
    this.controls.forEach((control) => {
      (control as any).markAsPristineRecursively();
    });
    super.markAsPristine({onlySelf: true});
  }

  markAsDirtyRecursively() {
    this.controls.forEach((control) => {
      (control as any).markAsDirtyRecursively();
    });
    super.markAsDirty({onlySelf: true});
  }

  markAsTouched(opts: {onlySelf?: boolean} = {}): void {
    this._touchedChanges.next(true);
    super.markAsTouched(opts);
  }

  markAsUntouched(opts: {onlySelf?: boolean} = {}): void {
    this._touchedChanges.next(false);
    super.markAsUntouched(opts);
  }

  updateValueAndValidityRecursively(
    opts: {onlySelf?: boolean; emitEvent?: boolean} = {},
  ) {
    this.updateValueAndValidity();

    this.controls.forEach((control: any) => {
      if (control.updateValueAndValidityRecursively) {
        control.updateValueAndValidityRecursively(opts);
      } else {
        control.updateValueAndValidity(opts);
      }
    });
  }

  setDefaultValueRecursively(defaults) {
    this.controls.forEach((control) => {
      (control as any).setDefaultValueRecursively(defaults);
    });
  }

  resetWithDefaults(options?: {onlySelf?: boolean; emitEvent?: boolean}) {
    this.controls.forEach((fg) =>
      (fg as TypedTsmAbstractControl).resetWithDefaults(options),
    );
  }

  resetNonDefaultValues(
    value?: any,
    options?: {onlySelf?: boolean; emitEvent?: boolean},
  ) {
    if (Array.isArray(value)) {
      this.controls.forEach((fg, i) =>
        (fg as TypedTsmAbstractControl).resetNonDefaultValues(value[i], {
          onlySelf: true,
          emitEvent: options?.emitEvent,
        }),
      );
    } else {
      this.controls.forEach((fg) =>
        (fg as TypedTsmAbstractControl).resetNonDefaultValues(value, {
          onlySelf: true,
          emitEvent: options?.emitEvent,
        }),
      );
    }
    this.updateValueAndValidity(options);
  }

  setFilterMapRecursively(filterMap) {
    this.filterMap = filterMap;
    this.controls.forEach((control) => {
      (control as any).setFilterMapRecursively(filterMap);
    });
  }

  getFilteredValue(): T[] {
    const filtered = this.controls.map((control: TypedTsmAbstractControl) => {
      return control.getFilteredValue();
    });
    return fluentFilterSkipValues(filtered);
  }

  getChangedValue(): T[] {
    const filtered = this.controls.map((control: TypedTsmAbstractControl) => {
      return control.getChangedValue();
    });
    return fluentChangedSkipValues(filtered);
  }

  setReadonly(readonly: boolean) {
    this.readonly = readonly;
    this.controls.forEach((control) => {
      (control as any).setReadonly(this.readonly);
    });
    if (this._onReadonly.length) {
      this._onReadonly.forEach((changeFn) => changeFn(this.readonly));
    }
  }

  registerOnReadonly(fn: Function): void {
    this._onReadonly.push(fn);
  }

  /** @internal */
  _unregisterOnReadonly(
    fn: (readonly?: any, emitModelEvent?: boolean) => void,
  ): void {
    removeListItem(this._onReadonly, fn);
  }
}
