import {Action, combineReducers, MetaReducer} from '@ngrx/store';

import * as fromRole from './role.reducer';
import * as fromRolePriv from './role-priv.reducer';
import * as fromUserGroup from './user-group.reducer';
import * as fromUser from './user.reducer';
import * as fromUserType from './user-type.reducer';
import * as fromUserImage from './user-image.reducer';
import * as fromUserRole from './user-role.reducer';
import * as fromUserUserGroup from './user-user-group.reducer';
import * as fromRegionAssignment from './region-assignment.reducer';
import * as fromSkillAssignment from './skill-assignment.reducer';
import * as fromUserGroupSkillAssignment from './user-group-skill.reducer';
import * as fromPrivReducer from './priv.reducer';
import * as fromPopup from './popup.reducer';
import * as fromUserGroupRegion from './user-group-region.reducer';
import * as fromAccessRule from './access-rule.reducer';
import * as fromRoleAccessRule from './role-access-rule.reducer';
import * as fromUserGroupType from './user-group-type.reducer';
import * as fromCheckOnline from './check-online.reducer';

export interface UserManagementState {
  role: fromRole.RoleState;
  rolePriv: fromRolePriv.RolePrivState;
  userGroup: fromUserGroup.UserGroupState;
  user: fromUser.UserState;
  userType: fromUserType.UserTypeState;
  userImage: fromUserImage.UserImageState;
  userRole: fromUserRole.UserRoleState;
  skillAssignment: fromSkillAssignment.SkillAssignmentState;
  userGroupSkill: fromUserGroupSkillAssignment.UserGroupSkillState;
  userUserGroup: fromUserUserGroup.UserUserGroupState;
  regionAssignment: fromRegionAssignment.RegionAssignmentState;
  privReducer: fromPrivReducer.PrivState;
  popupReducer: fromPopup.PopupState;
  userGroupRegion: fromUserGroupRegion.UserGroupRegionState;
  accessRule: fromAccessRule.AccessRuleState;
  roleAccessRule: fromRoleAccessRule.RoleAccessRuleState;
  userGroupType: fromUserGroupType.UserGroupTypeState;
  checkOnline: fromCheckOnline.CheckOnlineState;
}

export function reducers(
  state: UserManagementState | undefined,
  action: Action,
) {
  return combineReducers({
    role: fromRole.reducer,
    rolePriv: fromRolePriv.reducer,
    userGroup: fromUserGroup.reducer,
    user: fromUser.reducer,
    userType: fromUserType.userTypeReducer,
    userImage: fromUserImage.reducer,
    userRole: fromUserRole.reducer,
    skillAssignment: fromSkillAssignment.reducer,
    userGroupSkill: fromUserGroupSkillAssignment.reducer,
    userUserGroup: fromUserUserGroup.reducer,
    regionAssignment: fromRegionAssignment.reducer,
    privReducer: fromPrivReducer.reducer,
    popupReducer: fromPopup.reducer,
    userGroupRegion: fromUserGroupRegion.reducer,
    accessRule: fromAccessRule.reducer,
    roleAccessRule: fromRoleAccessRule.reducer,
    userGroupType: fromUserGroupType.userGroupTypeReducer,
    checkOnline: fromCheckOnline.reducer,
  })(state, action);
}

export const metaReducers: MetaReducer<UserManagementState>[] = [];
