import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  Optional,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
    selector: 'dtl-form-input-radiobutton',
    templateUrl: './form-input-radiobutton.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
    styles: [
        `
      :host > *:first-child {
        display: inline-flex;
      }

      :host p-checkbox {
        vertical-align: bottom;
      }
    `,
    ],
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputRadioButtonComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FormInputRadioButtonComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputRadioButtonComponent),
    ],
    host: {
        class: 'p-state-filled-fix readonly-no-border',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RadioButtonModule, FormsModule],
})
export class FormInputRadioButtonComponent extends ParentWidgetAccessorComponent {
  @Input() labelForId: string;

  @Input() radioButtonValue: string;

  @Input() radioButtonGroup: string;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }
}
