import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {isMobile} from '@tsm/framework/functions';

@Directive({
  selector: '[tsmDisableKeyboard]',
})
export class DisableKeyboardDirective implements AfterViewInit {
  isMobile = isMobile();

  private _readonly: boolean;
  get readonly() {
    return this._readonly;
  }

  get canDisableKeyboard() {
    return this.isMobile && !this.readonly;
  }

  @Input() tsmDisableKeyboard: boolean;

  @Input() set readonly(readonly: boolean) {
    this._readonly = readonly;
    this.handleKeyboard();
  }

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {}

  private handleKeyboard() {
    if (this.tsmDisableKeyboard && (this.readonly || this.canDisableKeyboard)) {
      (this.el.nativeElement as HTMLElement).setAttribute(
        'readonly',
        'readonly',
      );
    } else if (!this.tsmDisableKeyboard && !this.readonly) {
      (this.el.nativeElement as HTMLElement).removeAttribute('readonly');
    }
  }
}
