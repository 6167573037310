import {createAction, props} from '@ngrx/store';
import {WorklogTimer} from '../models';

export const StartWorklogTimer = createAction(
  '[WorklogTimer] Start WorklogTimer',
  props<{worklogTimer: WorklogTimer}>(),
);

export const StartWorklogTimerSuccess = createAction(
  '[WorklogTimer] Start WorklogTimer Success',
  props<{worklogTimer: WorklogTimer}>(),
);

export const EndWorklogTimer = createAction(
  '[WorklogTimer] End WorklogTimer',
  props<{worklogTimer: WorklogTimer}>(),
);

export const EndWorklogTimerSuccess = createAction(
  '[WorklogTimer] End WorklogTimer Success',
  props<{worklogTimer: WorklogTimer}>(),
);

export const EndWorklogTimerError = createAction(
  '[WorklogTimer] End WorklogTimer ERROR',
  props<{error: any}>(),
);
