<div
  class="textarea-resizable-wrapper"
  [style.display]="readonly ? 'none' : 'grid'"
>
  <textarea
    pInputTextarea
    #inputElement
    [(ngModel)]="val"
    style="width: 100%"
    [placeholder]="placeholder ? (placeholder | transloco) : ''"
    [style.display]="readonly ? 'none' : ''"
    [style.min-height.px]="widgetHeight ? widgetHeight : 75"
    [tabindex]="tabindex"
    [attr.id]="inputId ? inputId : null"
    onfocus="this.parentNode.dataset.replicatedValue = this.value"
    oninput="this.parentNode.dataset.replicatedValue = this.value"
    data-cy="dtl-form-input-textarea"
  >
  </textarea>
</div>
<span
  [style.display]="!readonly ? 'none' : 'inline-block'"
  [ngClass]="val ? 'readonly-text' : 'readonly-text empty'"
  [innerHTML]="val?.trim() ? val : noneText?.trim() ? noneText : 'none'"
>
</span>
