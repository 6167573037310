import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {withLatestCached} from '@tsm/framework/root';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  DeleteAttachmentType,
  DeleteAttachmentTypeError,
  DeleteAttachmentTypeSuccess,
  DiffAttachmentType,
  DiffAttachmentTypeError,
  DiffAttachmentTypeSuccess,
  LoadAttachmentType,
  LoadAttachmentTypeByCode,
  LoadAttachmentTypeByCodeError,
  LoadAttachmentTypeByCodeSuccess,
  LoadAttachmentTypeById,
  LoadAttachmentTypeByIdError,
  LoadAttachmentTypeByIdSuccess,
  LoadAttachmentTypeError,
  LoadAttachmentTypeSuccess,
  UpsertAttachmentType,
  UpsertAttachmentTypeError,
  UpsertAttachmentTypeSuccess,
} from '../actions';
import {selectAttachmentTypeByCode} from '../selectors';
import {AttachmentTypeDataService, CommonApiService} from '../services';
import {AttachmentTypeModel} from '../models';

@Injectable()
export class AttachmentTypeEffects {
  private readonly ATTACHMENT_TYPE_API_PATH = 'v1/attachment-types';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAttachmentType),
      exhaustMap(() =>
        this.attachmentTypeDataService
          .getAll()
          .pipe(
            map((env) =>
              env.success
                ? LoadAttachmentTypeSuccess({attachmentTypes: env.data})
                : LoadAttachmentTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAttachmentTypeById),
      concatMap(({id}) =>
        this.attachmentTypeDataService
          .getEntity(id)
          .pipe(
            map((env) =>
              env.success
                ? LoadAttachmentTypeByIdSuccess({attachmentType: env.data})
                : LoadAttachmentTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAttachmentTypeByCode),
      withLatestCached(({code}) =>
        this.store.select(selectAttachmentTypeByCode(code)),
      ),
      concatMap(([{code}, status]) =>
        status && status.data
          ? of(LoadAttachmentTypeByCodeSuccess({attachmentType: status.data}))
          : this.attachmentTypeDataService.getEntityByCode(code).pipe(
              map((env) =>
                env.success
                  ? LoadAttachmentTypeByCodeSuccess({
                      attachmentType: env.data,
                    })
                  : LoadAttachmentTypeByCodeError({
                      code: code,
                      error: env.error,
                    }),
              ),
            ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertAttachmentType),
      concatMap(({attachmentType, listingId, redirect}) =>
        this.attachmentTypeDataService
          .upsertEntity(attachmentType, attachmentType.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.attachmentType.effects.attachmentTypeSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertAttachmentTypeSuccess({
                  attachmentType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.attachmentType.effects.attachmentTypeSaveError,
                );
                return UpsertAttachmentTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAttachmentType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.attachmentTypeDataService.deleteEntity(id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.attachmentType.effects.attachmentTypeDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/dms/attachment-types']);
              }
              return DeleteAttachmentTypeSuccess({
                id: id,
                listingId: listingId,
              });
            } else {
              this.toastService.showError(
                env.error,
                translation.attachmentType.effects.attachmentTypeDeleteError,
              );
              return DeleteAttachmentTypeError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffAttachmentType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(
            this.ATTACHMENT_TYPE_API_PATH,
            diffEntities,
            compareField,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffAttachmentTypeSuccess({
                  attachmentTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffAttachmentTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertAttachmentTypeSuccess),
      tap(({attachmentType, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/dms/attachment-types', attachmentType.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAttachmentTypeSuccess, DiffAttachmentTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private attachmentTypeDataService: AttachmentTypeDataService,
    private toastService: ToastService,
    private router: Router,
    private commonApiService: CommonApiService<
      AttachmentTypeModel,
      AttachmentTypeModel
    >,
  ) {}
}
