import {Plugin} from '@tsm/framework/plugin';

export const accountComponentsPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'account',
      loadChildren: () =>
        import('@tsm/account/components').then(
          (x) => x.AccountComponentsModule,
        ),
    },
  ],
  widgets: [],
};
