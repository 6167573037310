import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {Observable} from 'rxjs';
import {WorklogType} from '../models';

@Injectable({
  providedIn: 'root',
})
export class WorklogTypeDataService {
  private readonly WORKLOG = `worklog-type`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<any>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getEntity(id: string): Observable<Envelope<WorklogType>> {
    return this.apiService.get<WorklogType, WorklogType>(
      this.BASE_URL + `/${this.WORKLOG}/${id}`,
    );
  }

  /** Použití pro entity typu číselník s unikátním kódem */
  getEntityByCode(code: string): Observable<Envelope<WorklogType>> {
    return this.apiService.get<WorklogType, WorklogType>(
      this.BASE_URL + `/${this.WORKLOG}/code/${code}`,
    );
  }

  deleteEntity(id: string): Observable<Envelope<WorklogType>> {
    return this.apiService.delete<WorklogType, WorklogType>(
      this.BASE_URL + `/${this.WORKLOG}/${id}`,
    );
  }

  postEntity(entity: WorklogType): Observable<Envelope<WorklogType>> {
    return this.apiService.post<WorklogType, WorklogType>(
      this.BASE_URL + `/${this.WORKLOG}`,
      entity,
    );
  }

  putEntity(id: string, entity: WorklogType): Observable<any> {
    return this.apiService.put<WorklogType, WorklogType>(
      this.BASE_URL + `/${this.WORKLOG}/${id}`,
      entity,
    );
  }

  upsertEntity(entity: WorklogType, id: string) {
    if (id) {
      return this.putEntity(id, entity);
    } else {
      return this.postEntity(entity);
    }
  }

  getAll(): Observable<any> {
    return this.apiService.get<WorklogType[], WorklogType[]>(
      this.BASE_URL + `/${this.WORKLOG}`,
    );
  }
}
