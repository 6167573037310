import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteUserType,
  DeleteUserTypeError,
  DeleteUserTypeSuccess,
  DiffUserType,
  DiffUserTypeError,
  DiffUserTypeSuccess,
  LoadUserType,
  LoadUserTypeByCode,
  LoadUserTypeByCodeError,
  LoadUserTypeByCodeSuccess,
  LoadUserTypeById,
  LoadUserTypeByIdError,
  LoadUserTypeByIdSuccess,
  LoadUserTypeError,
  LoadUserTypeSuccess,
  UpsertUserType,
  UpsertUserTypeError,
  UpsertUserTypeSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {CommonApiService} from '../service';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {UserType} from '../model';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';

@Injectable()
export class UserTypeEffects {
  translationShared = translationShared;
  private readonly USER_TYPE_API_PATH = 'codetables/user-types';

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserType),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.USER_TYPE_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserTypeSuccess({userTypes: env.data})
                : LoadUserTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserTypeById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntity(this.USER_TYPE_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserTypeByIdSuccess({userType: env.data})
                : LoadUserTypeByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUserTypeByCode),
      concatMap(({code}) =>
        this.commonApiService
          .getEntityByCode(this.USER_TYPE_API_PATH, code)
          .pipe(
            map((env) =>
              env.success
                ? LoadUserTypeByCodeSuccess({userType: env.data})
                : LoadUserTypeByCodeError({code, error: env.error}),
            ),
          ),
      ),
    ),
  );
  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserType),
      concatMap(({userType, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(this.USER_TYPE_API_PATH, userType, userType.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects
                    .saveUserTypeSuccesMsg,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertUserTypeSuccess({
                  userType: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translation.userManagementService.effects
                    .saveUserTypeErrorMsg,
                );
                return UpsertUserTypeError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserType),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.USER_TYPE_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects
                  .deleteUserTypeSuccessMsg,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/user-management/user-type']);
              }
              return DeleteUserTypeSuccess({id: id, listingId: listingId});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects
                  .deleteUserTypeErrorMsg,
              );
              return DeleteUserTypeError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffUserType),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/codetables/user-types', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffUserTypeSuccess({
                  userTypes: env.data,
                  listingId: listingId,
                });
              } else {
                this.ts.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffUserTypeError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertUserTypeSuccess),
      tap(({userType, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/user-management/user-type', userType.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUserTypeSuccess, DiffUserTypeSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<UserType, UserType>,
    private router: Router,
    private ts: ToastService,
  ) {}
}
