import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {TsmFormGroup} from '@tsm/framework/forms';
import {translation as translationShared} from '@tsm/shared-i18n';

import {TranslocoService} from '@tsm/framework/translate';
import {
  LayoutedComponent,
  LayoutIdDirective,
} from '@tsm/framework/root/layout-id';
import {FluentFormsInputOptions} from '@tsm/framework/root/di';
import {useParentWidgetProvidersFor} from '@tsm/framework/parent-widget';

@Component({
  selector: 'tsm-localized-form-field',
  templateUrl: './localized-form-field.component.html',
  styleUrls: ['./localized-form-field.component.scss'],
  providers: [...useParentWidgetProvidersFor(LocalizedFormFieldComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizedFormFieldComponent
  implements FluentFormsInputOptions<LocalizedFormFieldComponent>, OnChanges
{
  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, jakou část layoutu má prvek zabírat
   */
  @Input() cols: number;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, o jakou část layoutu má být prvek odsunut (zleva)
   */
  @Input() offset: number;

  /**
   * Atribut pro připojení rozšiřující CSS třídy
   */
  @Input() class = '';

  @Input() form: TsmFormGroup;

  @Input() field: string;

  @Input() label: string;

  // vypada to jako klasicky formField i v readonly rezimu
  @Input() readonlyShowLabel = false;

  @Input() type:
    | 'text'
    | 'link'
    | 'password'
    | 'date'
    | 'datetime'
    | 'time'
    | 'monthYear'
    | 'textarea'
    | 'checkbox'
    | 'triStateCheckbox'
    | 'radiobutton'
    | 'richtext'
    | 'richtext-inline'
    | 'tiptap'
    | 'tiptap-large'
    | 'tiptap-light'
    | 'number'
    | 'pint'
    | 'num'
    | 'sort'
    | 'rating'
    | 'int'
    | 'mask'
    | 'phone'
    | 'switch'
    | 'xml'
    | 'markdown'
    | 'json'
    | 'json-object'
    | 'java'
    | 'html'
    | 'sql'
    | 'previewDropdown'
    | 'regex'
    | 'icon-picker'
    | 'tags' = 'text';

  @Input() readonly = false;

  showLangs = false;

  translationShared = translationShared;

  langs = [];

  constructor(
    public translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
  ) {
    this.langs = (this.translocoService.getAvailableLangs() as string[]).filter(
      (x) => x !== this.translocoService.getDefaultLang(),
    );
  }

  @HostBinding('class')
  get styleClass(): string {
    let result = 'field col-12' + ' ' + this.class;
    if (this.cols) {
      result = this.offset
        ? 'field col-12 md:col-' +
          this.cols +
          ' md:col-offset-' +
          this.offset +
          ' ' +
          this.class
        : 'field col-12 md:col-' + this.cols + ' ' + this.class;
    }
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const readonly = changes.readonly;
    if (
      readonly &&
      readonly.previousValue === true &&
      (readonly.currentValue === undefined || readonly.currentValue === false)
    ) {
      this.langs.forEach((lang) => {
        if (
          !!this.form.get('localizationData')?.get(lang)?.get(this.field)?.value
        ) {
          this.showLangs = true;
        }
      });
    }
  }

  setInput(key: string, value: any) {
    if (key === 'readonly') {
      this.readonly = value;
      this.cdr.markForCheck();
    }
  }

  getLocalizedField(field: string, lang: string) {
    return this.form.get('localizationData').get(lang)
      ? this.form.get('localizationData').get(lang).get(field)
      : this.form.get(field);
  }
}
