import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {User, UserNotificationConfig} from '../model';

export const LoadUsers = createAction('[User] Load Users');
export const LoadUsersSuccess = createAction(
  '[User] Load Users Success',
  props<{entities: User[]}>(),
);
export const LoadUsersError = createAction(
  '[User] Load Users Error',
  props<{error: any}>(),
);

export const LoadUserById = createAction(
  '[User] Load LoadUserById',
  props<{id: string; forcedReload?: boolean}>(),
);
export const LoadUserByIdSuccess = createAction(
  '[User] Load LoadUserById Success',
  props<{user: User}>(),
);
export const LoadUserByIdError = createAction(
  '[User] Load LoadUserById Error',
  props<{id: string; error: any}>(),
);

export const LoadUserByCode = createAction(
  '[User] Load LoadUserByCode',
  props<{code: string}>(),
);
export const LoadUserByCodeSuccess = createAction(
  '[User] Load LoadUserByCode Success',
  props<{user: User}>(),
);
export const LoadUserByCodeError = createAction(
  '[User] Load LoadUserByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const LoadBatchUserById = createAction(
  '[User] Load LoadBatchUserById',
  props<{id: string}>(),
);
export const LoadBatchUserByIdSuccess = createAction(
  '[User] Load LoadBatchUserById Success',
  props<{
    entities: User[];
  }>(),
);
export const LoadBatchUserByIdError = createAction(
  '[User] Load LoadBatchUserById Error',
  props<{
    ids: string[];
    error: any;
  }>(),
);

export const LoadUserByIds = createAction(
  '[User] Load LoadUserByIds',
  props<{ids: string[]}>(),
);
export const LoadUserByIdsSuccess = createAction(
  '[User] Load LoadUserByIds Success',
  props<{entities: User[]}>(),
);
export const LoadUserByIdsError = createAction(
  '[User] Load LoadUserByIds Error',
  props<{
    ids: string[];
    error: any;
  }>(),
);

export const LoadBatchUserByIds = createAction(
  '[User] Load LoadBatchUserByIds',
  props<{ids: string[]}>(),
);
export const LoadBatchUserByIdsSuccess = createAction(
  '[User] Load LoadBatchUserByIds Success',
  props<{
    entities: User[];
  }>(),
);
export const LoadBatchUserByIdsError = createAction(
  '[User] Load LoadBatchUserByIds Error',
  props<{
    ids: string[];
    error: any;
  }>(),
);

export const UpsertUser = createAction(
  '[User] Upsert User',
  props<{
    user: User;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertUserSuccess = createAction(
  '[User] Upsert User Success',
  props<{
    user: User;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertUserError = createAction(
  '[User] Upsert User Error',
  props<TsmError>(),
);

export const PatchUser = createAction(
  '[User] Patch User',
  props<{
    id: string;
    user: any;
    listingId: string | string[];
    redirect: boolean;
  }>(),
);
export const PatchUserSuccess = createAction(
  '[User] Patch User Success',
  props<{
    id: string;
    user: any;
    listingId: string | string[];
    redirect: boolean;
  }>(),
);
export const PatchUserError = createAction(
  '[User] Patch User Error',
  props<{error: any}>(),
);

export const SaveUserNotificationConfig = createAction(
  '[User] Save notification config',
  props<{id: string; config: UserNotificationConfig}>(),
);
export const SaveUserNotificationConfigSuccess = createAction(
  '[User] Save notification config success',
  props<{id: string; config: UserNotificationConfig}>(),
);
export const SaveUserNotificationConfigError = createAction(
  '[User] Save notification config error',
  props<{id: string; error: any}>(),
);
