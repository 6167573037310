import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {worklogMyAdapter} from '../reducers/worklog-my.reducer';
import {DateHelper} from '@tsm/shared';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getWorklogMyState = createSelector(
  getDmsState,
  (x) => x.worklogMy,
);

const {selectAll, selectEntities, selectIds, selectTotal} =
  worklogMyAdapter.getSelectors(getWorklogMyState);

export const selectWorklogsMyByUserId = (userId: string) =>
  createSelector(selectEntities, (entities) => {
    const result = entities[userId]?.worklogs?.sort((i, j) =>
      DateHelper.compareDates(i.startDate, j.startDate),
    );
    return result;
  });
