import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Renderer2,
} from '@angular/core';
import {ToastParamsDataModel, ToastService} from '@tsm/framework/toast';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '../../i18n';
import {HotkeysService} from '@tsm/framework/shortcuts';
import {from, Observable, of, retry, Subscription, switchMap} from 'rxjs';
import {catchError, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {
  HideHotkeysOverlay,
  InitUpdate,
  PwaService,
  ShowHotkeysOverlay,
  withLatestCached,
} from '@tsm/framework/root';
import * as FileSaver from 'file-saver';
import {JsonPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import {PrimeNGConfig} from 'primeng/api';
import {UiConfigService} from '@tsm/ui-config';
import {Terminator} from '@tsm/framework/terminator';
import {
  getHotkeysOverlay,
  selectUpdateMessage,
  selectUpdateOverlay,
  selectUpdateOverlayStep,
  UpdateOverlayStepEnum,
} from '@tsm/shared';
import {translation as translationShared} from '@tsm/shared-i18n';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {NavigationError, Router} from '@angular/router';

@Component({
  selector: 'tsm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Terminator],
  animations: [
    trigger('updateAnimation', [
      transition(':enter', [
        animate(
          '500ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
          keyframes([
            style({opacity: 0, transform: 'translateY(1000px)', offset: 0}),
            style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
          ]),
        ),
      ]),
    ]),
  ],
})
export class AppComponent {
  subscription: Subscription;
  translation = translation;
  translationShared = translationShared;
  hotkeysVisible$: Observable<boolean>;

  // show more
  showMore = true;
  showMoreContent = false;
  showUpdateMessage$: Observable<boolean>;
  showUpdateOverlay$: Observable<boolean>;
  updateOverlayStep$: Observable<UpdateOverlayStepEnum>;
  updateOverlayStepEnum = UpdateOverlayStepEnum;
  anim = false;

  start = 300;

  constructor(
    public toastService: ToastService,
    private translocoService: TranslocoService,
    public hotkeysService: HotkeysService,
    private cdr: ChangeDetectorRef,
    private store: Store<any>,
    private jsonPipe: JsonPipe,
    private primengConfig: PrimeNGConfig,
    private pwaService: PwaService,
    private terminator$: Terminator,
    private appRef: ApplicationRef,
    private uiConfigService: UiConfigService,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationError),
        map((evt) => evt as NavigationError),
        catchError((err) => {
          if (
            err.error instanceof Error &&
            err.error.name === 'ChunkLoadError'
          ) {
            return from(import(err.url)).pipe(retry(2)); // Retry loading the module
          }
          return of(err);
        }),
        takeUntil(this.terminator$),
      )
      .subscribe();

    this.store.dispatch(InitUpdate());
    this.hotkeysVisible$ = this.store.select(getHotkeysOverlay);

    // promeng config
    this.primengConfig.ripple = true;

    let sub = null;
    this.translocoService.langChanges$
      .pipe(
        tap(() => {
          sub?.unsubscribe();
          sub = this.hotkeysService
            .when(['f1'], {
              text: this.translocoService.translate(
                translation.keyboard.showKeyShortcuts,
              ),
              preventDefault: true,
              stopPropagation: false,
              group: 'Aplikace',
              allowedHotkeys: [],
            })
            .subscribe((_) => {
              this.store.dispatch(ShowHotkeysOverlay());
              this.cdr.markForCheck();
            });
        }),
        // takeUntil(this.terminator$)
      )
      .subscribe();

    // hotkeys configuration
    // this.hotkeysService.when(['f1', 'ctrl+/'], {
    //   text: this.translocoService.translate(translation.keyboard.showKeyShortcuts),
    //   preventDefault: true,
    //   stopPropagation: false,
    //   group: this.translocoService.translate(translation.keyboard.application),
    //   allowedHotkeys: []
    // }).subscribe(_ => {
    //     this.store.dispatch(ShowHotkeysOverlay());
    //     this.cdr.markForCheck();
    //   }
    // );
    this.showUpdateMessage$ = this.store.select(selectUpdateMessage);
    this.showUpdateOverlay$ = this.store.select(selectUpdateOverlay).pipe(
      tap((show) => {
        if (show) {
          this.renderer.setStyle(document.body, 'overflow', 'hidden');
        } else {
          this.renderer.setStyle(document.body, 'overflow', null);
        }
      }),
    );
    this.updateOverlayStep$ = this.store.select(selectUpdateOverlayStep);
    this.uiConfigService.loadUiConfig();
  }

  downloadLog(messageData: ToastParamsDataModel) {
    const data$ = this.toastService.onToast$.pipe(
      map(() => messageData),
      withLatestCached(() =>
        this.store.source.pipe(map((state) => JSON.stringify(state))),
      ),
      take(1),
    );

    data$.subscribe(([messageData, stateString]) => {
      const timestamp = new Date()
        .toISOString()
        .replace(':', '-')
        .replace(':', '-')
        .replace('.', '_');
      import('html2canvas').then((html2canvas) =>
        html2canvas.default(document.body).then(function (canvas) {
          canvas.toBlob(function (blob) {
            FileSaver.saveAs(blob, 'screen_' + timestamp + '.jpeg');
          });
        }),
      );
      const stateBlob: Blob = new Blob([stateString], {
        type: 'application/json',
      });
      FileSaver.saveAs(stateBlob, 'state_' + timestamp + '.json');

      const messageJsonStr = this.jsonPipe.transform(messageData);
      const messageBlob: Blob = new Blob([messageJsonStr], {
        type: 'application/json',
      });
      FileSaver.saveAs(messageBlob, 'message_' + timestamp + '.json');
    });
  }

  sendLogToServer() {}

  updateClicked() {
    window.location.reload();
  }

  disableHotkeys() {
    this.store.dispatch(HideHotkeysOverlay());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }
}
