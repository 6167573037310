import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {UserParameter} from '../model';
import {
  DeleteUserParameterByUserIdAndName,
  DeleteUserParameterByUserIdAndNameError,
  DeleteUserParameterByUserIdAndNameSuccess,
  LoadUserParameterByUserIdAndParameterName,
  LoadUserParameterByUserIdAndParameterNameError,
  LoadUserParameterByUserIdAndParameterNameSuccess,
  LoadUserParameterByUserIdsAndParameterName,
  LoadUserParameterByUserIdsAndParameterNameError,
  LoadUserParameterByUserIdsAndParameterNameSuccess,
  LoadUserParametersByUserIdSuccess,
  UpsertUserParameterByUserIdAndName,
  UpsertUserParameterByUserIdAndNameError,
  UpsertUserParameterByUserIdAndNameSuccess,
} from '../actions';

export interface UserParameterState
  extends EntityState<IdentifiedPack<UserParameter>> {}

export const adapter: EntityAdapter<IdentifiedPack<UserParameter>> =
  createEntityAdapter<IdentifiedPack<UserParameter>>({});

export const initialState: UserParameterState = adapter.getInitialState({
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(
    UpsertUserParameterByUserIdAndName,
    LoadUserParameterByUserIdAndParameterName,
    DeleteUserParameterByUserIdAndName,
    (state, {userId, name}) =>
      adapter.upsertOne(
        {
          ...state.entities[userId + name],
          id: userId + name,
          loading: true,
          error: null,
        },
        state,
      ),
  ),

  on(
    DeleteUserParameterByUserIdAndNameSuccess,
    (state, {entity, userId, name}) => adapter.removeOne(userId + name, state),
  ),

  on(
    UpsertUserParameterByUserIdAndNameSuccess,
    LoadUserParameterByUserIdAndParameterNameSuccess,
    (state, {entity, userId, name}) =>
      adapter.upsertOne(
        {
          id: userId + name,
          data: entity,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(
    UpsertUserParameterByUserIdAndNameError,
    LoadUserParameterByUserIdAndParameterNameError,
    DeleteUserParameterByUserIdAndNameError,
    (state, {userId, name, error}) =>
      adapter.upsertOne(
        {
          id: userId + name,
          data: state.entities[userId + name].data,
          loading: false,
          error: error,
        },
        state,
      ),
  ),

  on(LoadUserParameterByUserIdsAndParameterName, (state, {userIds, name}) =>
    adapter.upsertMany(
      userIds.map((uid) => ({
        ...state.entities[uid + name],
        id: uid + name,
        loading: true,
        error: null,
      })),
      state,
    ),
  ),

  on(LoadUserParametersByUserIdSuccess, (state, {userId, entities}) => {
    return adapter.upsertMany(
      entities.map((data) => ({
        id: userId + data.parameterName,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    );
  }),

  on(
    LoadUserParameterByUserIdsAndParameterNameSuccess,
    (state, {userIds, entities}) => {
      return adapter.upsertMany(
        entities.map((data) => ({
          id: data.userId + data.parameterName,
          data: data,
          loading: false,
          error: null,
        })),
        state,
      );
    },
  ),

  on(
    LoadUserParameterByUserIdsAndParameterNameError,
    (state, {userIds, name, error}) => {
      return adapter.upsertMany(
        userIds.map((id) => ({
          id: id + name,
          loading: false,
          error: error,
        })),
        state,
      );
    },
  ),
);
