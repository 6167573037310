import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {BlockableUI} from 'primeng/api';

@Component({
  selector: 'tsm-blockable-div',
  templateUrl: './blockable-div.component.html',
  styleUrls: ['./blockable-div.component.scss'],
})
export class BlockableDivComponent implements BlockableUI, OnInit {
  @Input() style: any;
  @Input() class: any;

  constructor(private el: ElementRef) {}

  ngOnInit() {}

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
