import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'map',
  pure: true,
})
export class MapPipe implements PipeTransform {
  transform(array: any[], attribute: string): any {
    return array.map((x) => x[attribute]);
  }
}
