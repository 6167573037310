import {
  createAction as originalCreateAction,
  Creator,
  ActionCreator,
  Action,
  FunctionWithParametersType,
  NotAllowedCheck,
  ActionCreatorProps,
} from '@ngrx/store';

export const StoreActionMetadataMap = new Map<
  string,
  {title?: string; props?: any}
>();

/**
 * A drop-in replacement for `createAction(...)` that also captures metadata.
 * You can dispatch the returned action exactly the same way as an NgRx action.
 */
export function createActionWithMetadata<T extends string>(
  type: T,
  metadata?: {title?: string; props?: any},
): ActionCreator<T, () => Action<T>>;

export function createActionWithMetadata<T extends string, P extends object>(
  type: T,
  metadata: {title?: string; props?: any},
  config: ActionCreatorProps<P> & NotAllowedCheck<P>,
): ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & Action<T>>;

export function createActionWithMetadata<
  T extends string,
  P extends any[],
  R extends object,
>(
  type: T,
  metadata: {title?: string; props?: any},
  creator: Creator<P, R & NotAllowedCheck<R>>,
): FunctionWithParametersType<P, R & Action<T>> & Action<T>;

/**
 * This final signature catches the generic scenario
 * (mirroring the final signature in the original createAction).
 */
export function createActionWithMetadata<T extends string, C extends Creator>(
  type: T,
  metadata?: {title?: string; props?: any},
  config?: {_as: 'props'} | C,
): ActionCreator<T> {
  // 1) Call the original createAction
  const actionCreator = originalCreateAction(type, config as any);

  // 2) Record your metadata in the Map
  if (metadata) {
    StoreActionMetadataMap.set(type, {
      title: metadata.title,
      props: metadata.props,
    });
  }

  // 3) Return the original action creator, preserving the type
  return actionCreator;
}
