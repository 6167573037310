import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';
import {of} from 'rxjs';

import {
  controlsAccountDetailConfiguration,
  controlsAccountNewConfiguration,
  controlsCampaignDetailConfiguration,
  controlsCampaignNewConfiguration,
  controlsCampaignStatusDetailConfiguration,
  controlsCampaignTopbarConfiguration,
  controlsCampaignTypeDetailConfiguration,
  controlsCustomerDetailConfiguration,
  controlsCustomerNewConfiguration,
  controlsLeadDetailConfiguration,
  controlsListTopbarConfiguration,
  controlsPartyCampaignStatusDetailConfiguration,
  controlsPersonNewConfiguration,
  controlsPersonDetailConfiguration,
  controlsTopbarConfiguration,
  controlsTypeDetailConfiguration,
} from './tsm-controls-configuration';
import {translation as translationPlugin} from '@tsm/customer/plugins-i18n';

export const customerPlugin: Plugin = {
  menus: [
    {
      key: 'crm',
      priority: 1800,
      label: 'menu.crm.crm',
      icon: 'tsm-icon-crm',
      privilege: [
        'Crm.Customer.ViewList',
        'Crm.Account.ViewList',
        'Crm.Lead.ViewList',
        'Crm.Person.ViewList',
        'Crm.Registers.PersonRoleType.ViewList',
        'Crm.Registers.CustomerLegalForm.ViewList',
        'Crm.Registers.CustomerType.ViewList',
        'Crm.Registers.LeadType.ViewList',
        'Crm.Registers.PersonType.ViewList',
        'Crm.Registers.AccountType.ViewList',
        'Crm.Registers.LeadRating.ViewList',
        'Crm.Registers.LeadSource.ViewList',
        'Crm.Campaign.ViewList',
        'Crm.Registers.CampaignType.ViewList',
        'Crm.Registers.CampaignStatus.ViewList',
        'Crm.Registers.PartyCampaignStatus.ViewList',
        'Crm.CampaignWaveDefinition.ViewList',
        'Crm.CampaignWave.ViewList',
      ],
      items: [
        {
          key: 'customer',
          label: 'menu.crm.customers',
          icon: 'tsm-icon-client',
          routerLink: ['/crm/customer'],
          listingType: 'tsm-customer',
          privilege: ['Crm.Customer.ViewList'],
        },
        {
          key: 'account',
          label: 'menu.crm.contracts',
          icon: 'tsm-icon-contract-1',
          routerLink: ['/crm/account'],
          listingType: 'tsm-account',
          privilege: ['Crm.Account.ViewList'],
        },
        {
          key: 'lead',
          label: 'menu.crm.leads',
          icon: 'tsm-icon-lead',
          routerLink: ['/crm/lead'],
          listingType: 'tsm-lead',
          privilege: ['Crm.Lead.ViewList'],
        },
        {
          key: 'person',
          label: 'menu.crm.persons',
          icon: 'tsm-icon-person',
          routerLink: ['/crm/person'],
          listingType: 'tsm-person',
          privilege: ['Crm.Person.ViewList'],
        },
        {
          key: 'CAMPAIGN_CAMPAIGN',
          label: 'menu.crm.campaign',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/crm/campaign'],
          privilege: ['Crm.Campaign.ViewList'],
        },
        {
          key: 'CAMPAIGN_CAMPAIGN_WAVE_DEFINITION',
          label: 'menu.crm.campaignWaveDefinition',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/crm/campaign-wave-definition'],
          privilege: ['Crm.CampaignWaveDefinition.ViewList'],
        },
        {
          key: 'CAMPAIGN_CAMPAIGN_WAVE',
          label: 'menu.crm.campaignWave',
          icon: 'tsm-icon-menu-item',
          routerLink: ['/crm/campaign-wave'],
          privilege: ['Crm.CampaignWave.ViewList'],
        },
        {
          key: 'CRM_REGISTERS',
          label: 'menu.crm.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Crm.Registers.PersonRoleType.ViewList',
            'Crm.Registers.CustomerLegalForm.ViewList',
            'Crm.Registers.CustomerType.ViewList',
            'Crm.Registers.LeadType.ViewList',
            'Crm.Registers.PersonType.ViewList',
            'Crm.Registers.AccountType.ViewList',
            'Crm.Registers.LeadRating.ViewList',
            'Crm.Registers.LeadSource.ViewList',
            'Crm.Registers.CampaignType.ViewList',
            'Crm.Registers.CampaignStatus.ViewList',
            'Crm.Registers.PartyCampaignStatus.ViewList',
          ],
          items: [
            {
              key: 'CUSTOMER_ROLE_TYPE',
              label: 'menu.crm.personRoles',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/registers/CUSTOMER_ROLE_TYPE'],
              privilege: ['Crm.Registers.PersonRoleType.ViewList'],
            },
            {
              key: 'CUSTOMER_LEGAL_FORM',
              label: 'menu.crm.legalForm',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/registers/CUSTOMER_LEGAL_FORM'],
              privilege: ['Crm.Registers.CustomerLegalForm.ViewList'],
            },
            {
              key: 'CUSTOMER_TYPE',
              label: 'menu.crm.customerType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/customer-type'],
              privilege: ['Crm.Registers.CustomerType.ViewList'],
            },
            {
              key: 'LEAD_TYPE',
              label: 'menu.crm.leadType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/lead-type'],
              privilege: ['Crm.Registers.LeadType.ViewList'],
            },
            {
              key: 'PERSON_TYPE',
              label: 'menu.crm.personType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/person-type'],
              privilege: ['Crm.Registers.PersonType.ViewList'],
            },
            {
              key: 'ACCOUNT_TYPE',
              label: 'menu.crm.accountType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/account-type'],
              privilege: ['Crm.Registers.AccountType.ViewList'],
            },
            {
              key: 'CUSTOMER_SEGMENT',
              label: 'menu.crm.customerSegment',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/customer-segment'],
              privilege: ['Crm.Registers.CustomerSegment.ViewList'],
            },
            {
              key: 'LEAD_RATING',
              label: 'menu.crm.rating',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/registers/LEAD_RATING'],
              privilege: ['Crm.Registers.LeadRating.ViewList'],
            },
            {
              key: 'LEAD_SOURCE',
              label: 'menu.crm.leadSource',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/registers/LEAD_SOURCE'],
              privilege: ['Crm.Registers.LeadSource.ViewList'],
            },
            {
              key: 'CAMPAIGN_TYPE',
              label: 'menu.crm.campaignType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/campaign-type'],
              privilege: ['Crm.Registers.CampaignType.ViewList'],
            },
            {
              key: 'CAMPAIGN_STATUS',
              label: 'menu.crm.campaignStatus',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/campaign-status'],
              privilege: ['Crm.Registers.CampaignStatus.ViewList'],
            },
            {
              key: 'PARTY_CAMPAIGN_STATUS',
              label: 'menu.crm.PartyCampaignStatus',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/crm/party-campaign-status'],
              privilege: ['Crm.Registers.PartyCampaignStatus.ViewList'],
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'crm',
      loadChildren: () =>
        import('@tsm/customer/components').then(
          (x) => x.CustomerComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/customer/widgets').then((x) => x.CustomerWidgetsModule),
      definitions: [
        {selector: 'tsm-account-status-lov'},
        {
          selector: 'tsm-person-type-lov',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-person-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.personTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.personTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 10,
              icon: DesignerIconsEnum.select,
              formField: 'personType',
              value: {
                title:
                  translationShared.shared.widgets.personTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-person-type-lov',
                },
                type: 'widget',
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-account-type-lov',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-account-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.accountTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.accountTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 10,
              icon: DesignerIconsEnum.select,
              formField: 'accountType',
              value: {
                title:
                  translationShared.shared.widgets.accountTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-account-type-lov',
                },
                type: 'widget',
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-lead-type-lov',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-lead-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.leadTypeSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.leadTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 10,
              icon: DesignerIconsEnum.select,
              formField: 'leadType',
              value: {
                title:
                  translationShared.shared.widgets.leadTypeSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-lead-type-lov',
                },
                type: 'widget',
                config: {},
              },
            },
          ],
        },
        {
          selector: 'tsm-lead-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-lead-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.leadList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.leadList.widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.list,
              value: {
                title: translationShared.shared.widgets.leadList.inWidgetTitle,
                widget: {
                  type: 'tsm-lead-list-widget',
                },
                type: 'layout',
                config: {},
              },
            },
          ],
        },
        {selector: 'tsm-customer-card'},
        {selector: 'tsm-account-card'},
        {selector: 'tsm-lead-card'},
        {selector: 'tsm-person-card'},
        {selector: 'tsm-address-card'},
        {selector: 'tsm-contact-card'},
        {
          selector: 'tsm-customer-history',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-customer-history-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.customerAuditList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerAuditList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 10,
              icon: DesignerIconsEnum.history,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-customer-history',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-account-history',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-account-history-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.accountAuditList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.accountAuditList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 11,
              icon: DesignerIconsEnum.history,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-account-history',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-person-history',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-person-history-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.personAuditList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.personAuditList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 13,
              icon: DesignerIconsEnum.history,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-person-history',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-lead-history',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-lead-history-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.leadAuditList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.leadAuditList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 12,
              icon: DesignerIconsEnum.history,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-lead-history',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-contacts-manager',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-contacts-manager-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.contactsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.contactsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-contacts-manager',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-crm-address-roles-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-crm-address-roles-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.addressRolesBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.addressRolesBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-crm-address-roles-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-persons-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-persons-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.personsBookmark.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.personsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-persons-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-responsible-persons-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-responsible-persons-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.responsiblePersonsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.responsiblePersonsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 8,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-responsible-persons-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-crm-addresses-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-crm-addresses-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.addressesBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.addressesBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-crm-addresses-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-accounts-bookmark',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-accounts-bookmark-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.accountsBookmark
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.accountsBookmark
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.bookmark,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-accounts-bookmark',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-person-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-person-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.personStatusBar.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.personStatusBar
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 13,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-person-steps',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-lead-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-lead-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.leadStatusBar.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.leadStatusBar
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 12,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-lead-steps',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/customer/widgets/customer-steps').then(
              (x) => x.CustomerStepsComponent,
            ),
          selector: 'tsm-customer-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import('@tsm/customer/widgets/customer-steps-editor').then(
                  (x) => x.CustomerStepsEditorComponent,
                ),
              editorSelector: 'tsm-customer-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.customerStatusBar
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerStatusBar
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 14,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-customer-steps',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-account-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-account-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.accountStatusBar
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.accountStatusBar
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 11,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-account-steps',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-person-lov',
          privilege: ['!Crm.Widget.tsm-person-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-person-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.personSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.personSelect.widgetDescription,
              group: GroupPosition.Secondary,
              order: 5,
              icon: DesignerIconsEnum.select,
              formField: 'person',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.personSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-person-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-crm-address-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-crm-address-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.addressSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.addressSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 6,
              icon: DesignerIconsEnum.select,
              formField: 'crmAddress',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.addressSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-crm-address-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-crm-address-role-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-crm-address-role-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.addressRoleTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.addressRoleTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 6,
              icon: DesignerIconsEnum.select,
              formField: 'crmAddressRoleType',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.addressRoleTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-crm-address-role-type-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-customer-lov',
          privilege: ['!Crm.Widget.tsm-customer-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-customer-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.customerSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 2,
              icon: DesignerIconsEnum.select,
              formField: 'customer',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.customerSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-customer-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-customer-legal-form-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-customer-legal-form-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.legalFormSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.legalFormSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 7,
              icon: DesignerIconsEnum.select,
              formField: 'legalForm',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.legalFormSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-customer-legal-form-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-customer-report-form',
          widgetMapper: (fluentSchema: FluentSchema) => ({
            readonly:
              fluentSchema.widget?.disabled || fluentSchema.widget?.readonly,
          }),
          designers: [
            {
              editorSelector: 'tsm-customer-report-form-editor',
              widgetOrLayout: 'widget',
              description: translationShared.shared.customerReport,
              icon: 'p-icon-person',
              categoryId: DesignerCategoryEnum.TechnicalPreview,
              formField: 'report',
              name: translationShared.shared.customerReport,
              value: {
                type: 'object',
                title: translationShared.shared.customerReport,
                widget: {
                  type: 'tsm-customer-report-form',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-account-lov',
          privilege: ['!Crm.Widget.tsm-account-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-account-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.accountSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.accountSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 3,
              icon: DesignerIconsEnum.select,
              formField: 'account',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.accountSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-account-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-lead-lov',
          privilege: ['!Crm.Widget.tsm-lead-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-lead-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.leadSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.leadSelect.widgetDescription,
              group: GroupPosition.Secondary,
              order: 4,
              icon: DesignerIconsEnum.select,
              formField: 'lead',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.leadSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-lead-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-customer-segment-lov',
          privilege: ['!Crm.Widget.tsm-customer-segment-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-customer-segment-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.customerSegmentSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerSegmentSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 8,
              icon: DesignerIconsEnum.select,
              formField: 'segment',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.customerSegmentSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-customer-segment-lov',
                },
                config: {
                  selectProperty: 'id',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-customer-type-lov',
          privilege: ['!Crm.Widget.tsm-customer-type-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-customer-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.customerTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 9,
              icon: DesignerIconsEnum.select,
              formField: 'customerType',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.customerTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-customer-type-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-ares-new-customer-container',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-ares-new-customer-container-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.createFromAresButton
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.createFromAresButton
                  .widgetDescription,
              group: GroupPosition.Tertiary,
              order: 3,
              icon: DesignerIconsEnum.button,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-ares-new-customer-container',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-customer-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-customer-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.customerList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.customerList.widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.customerList.inWidgetTitle,
                widget: {
                  type: 'tsm-customer-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-campaign-lov',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-campaign-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.campaignSelect.widgetName,
              description:
                translationShared.shared.widgets.campaignSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 1,
              icon: DesignerIconsEnum.select,
              formField: 'campaign',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.campaignSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-campaign-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-campaign-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-campaign-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.campaignTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.campaignTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 1,
              icon: DesignerIconsEnum.select,
              formField: 'campaignType',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.campaignTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-campaign-type-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-campaign-wave-definition-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-campaign-wave-definition-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.campaignWaveDefinition
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.campaignWaveDefinition
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 10,
              icon: DesignerIconsEnum.select,
              formField: 'campaignWaveDefinition',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.campaignWaveDefinition
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-campaign-wave-definition-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-campaign-status-steps',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              readonly: fluentSchema.widget?.readonly,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-campaign-status-steps-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.campaignStatusBar
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.campaignStatusBar
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 10,
              icon: DesignerIconsEnum.statusbar,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-campaign-status-steps',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-campaign-wave-list-widget',
          privilege: ['true'],
          designers: [
            {
              editorSelector: 'tsm-campaign-wave-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Crm,
              name: translationShared.shared.widgets.campaignWaveList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.campaignWaveList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                title:
                  translationShared.shared.widgets.campaignWaveList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-campaign-wave-list-widget',
                },
                type: 'layout',
                config: {},
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/customer/widgets').then((x) => x.CustomerWidgetsModule),
      definitions: [
        {
          selector: 'tsm-account-filter',
          name: translationPlugin.customerPlugins.filters.tsmAccountFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmAccountFilterDescription,
        },
        {
          selector: 'tsm-account-status-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmAccountStatusFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmAccountStatusFilterDescription,
        },
        {
          selector: 'tsm-account-type-filter',
          name: translationPlugin.customerPlugins.filters.tsmAccountTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmAccountTypeFilterDescription,
        },
        {
          selector: 'tsm-campaign-filter',
          name: translationPlugin.customerPlugins.filters.tsmCampaignFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCampaignFilterDescription,
        },
        {
          selector: 'tsm-campaign-status-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmCampaignStatusFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCampaignStatusFilterDescription,
        },
        {
          selector: 'tsm-campaign-type-filter',
          name: translationPlugin.customerPlugins.filters.tsmCampaignTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCampaignTypeFilterDescription,
        },
        {
          selector: 'tsm-campaign-wave-definition-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmCampaignWaveDefinitionFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCampaignWaveDefinitionFilterDescription,
        },
        {
          selector: 'tsm-crm-address-role-type-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmCrmAddressRoleTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCrmAddressRoleTypeFilterDescription,
        },
        {
          selector: 'tsm-customer-filter',
          name: translationPlugin.customerPlugins.filters.tsmCustomerFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCustomerFilterDescription,
        },
        {
          selector: 'tsm-customer-legal-form-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmCustomerLegalFormFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCustomerLegalFormFilterDescription,
        },
        {
          selector: 'tsm-customer-segment-type-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmCustomerSegmentTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCustomerSegmentTypeFilterDescription,
        },
        {
          selector: 'tsm-customer-status-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmCustomerStatusFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCustomerStatusFilterDescription,
        },
        {
          selector: 'tsm-customer-type-filter',
          name: translationPlugin.customerPlugins.filters.tsmCustomerTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmCustomerTypeFilterDescription,
        },
        {
          selector: 'tsm-lead-filter',
          name: translationPlugin.customerPlugins.filters.tsmLeadFilter,
          description:
            translationPlugin.customerPlugins.filters.tsmLeadFilterDescription,
        },
        {
          selector: 'tsm-lead-priority-filter',
          name: translationPlugin.customerPlugins.filters.tsmLeadPriorityFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmLeadPriorityFilterDescription,
        },
        {
          selector: 'tsm-lead-source-filter',
          name: translationPlugin.customerPlugins.filters.tsmLeadSourceFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmLeadSourceFilterDescription,
        },
        {
          selector: 'tsm-lead-status-filter',
          name: translationPlugin.customerPlugins.filters.tsmLeadStatusFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmLeadStatusFilterDescription,
        },
        {
          selector: 'tsm-lead-type-filter',
          name: translationPlugin.customerPlugins.filters.tsmLeadTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmLeadTypeFilterDescription,
        },
        {
          selector: 'tsm-party-campaign-status-filter',
          name: translationPlugin.customerPlugins.filters
            .tsmPartyCampaignStatusFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmPartyCampaignStatusFilterDescription,
        },
        {
          selector: 'tsm-person-filter',
          name: translationPlugin.customerPlugins.filters.tsmPersonFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmPersonFilterDescription,
        },
        {
          selector: 'tsm-person-status-filter',
          name: translationPlugin.customerPlugins.filters.tsmPersonStatusFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmPersonStatusFilterDescription,
        },
        {
          selector: 'tsm-person-type-filter',
          name: translationPlugin.customerPlugins.filters.tsmPersonTypeFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmPersonTypeFilterDescription,
        },
        {
          selector: 'tsm-role-in-group-filter',
          name: translationPlugin.customerPlugins.filters.tsmRoleInGroupFilter,
          description:
            translationPlugin.customerPlugins.filters
              .tsmRoleInGroupFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/customer/widgets').then((x) => x.CustomerWidgetsModule),
      definitions: [
        {
          selector: 'accountLoaderKey',
          name: translationPlugin.customerPlugins.pipes.accountLoaderKey,
          description:
            translationPlugin.customerPlugins.pipes.accountLoaderKeyDescription,
        },
        {
          selector: 'accountLoader',
          name: translationPlugin.customerPlugins.pipes.accountLoader,
          description:
            translationPlugin.customerPlugins.pipes.accountLoaderDescription,
        },
        {
          selector: 'accountTypeLoaderCode',
          name: translationPlugin.customerPlugins.pipes.accountTypeLoaderCode,
          description:
            translationPlugin.customerPlugins.pipes
              .accountTypeLoaderCodeDescription,
        },
        {
          selector: 'accountTypeLoader',
          name: translationPlugin.customerPlugins.pipes.accountTypeLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .accountTypeLoaderDescription,
        },
        {
          selector: 'campaignCode',
          name: translationPlugin.customerPlugins.pipes.campaignCode,
          description:
            translationPlugin.customerPlugins.pipes.campaignCodeDescription,
        },
        {
          selector: 'campaignId',
          name: translationPlugin.customerPlugins.pipes.campaignId,
          description:
            translationPlugin.customerPlugins.pipes.campaignIdDescription,
        },
        {
          selector: 'campaignStatusCode',
          name: translationPlugin.customerPlugins.pipes.campaignStatusCode,
          description:
            translationPlugin.customerPlugins.pipes
              .campaignStatusCodeDescription,
        },
        {
          selector: 'campaignStatusId',
          name: translationPlugin.customerPlugins.pipes.campaignStatusId,
          description:
            translationPlugin.customerPlugins.pipes.campaignStatusIdDescription,
        },
        {
          selector: 'campaignTypeCode',
          name: translationPlugin.customerPlugins.pipes.campaignTypeCode,
          description:
            translationPlugin.customerPlugins.pipes.campaignTypeCodeDescription,
        },
        {
          selector: 'campaignTypeId',
          name: translationPlugin.customerPlugins.pipes.campaignTypeId,
          description:
            translationPlugin.customerPlugins.pipes.campaignTypeIdDescription,
        },
        {
          selector: 'campaignWaveDefinitionCode',
          name: translationPlugin.customerPlugins.pipes
            .campaignWaveDefinitionCode,
          description:
            translationPlugin.customerPlugins.pipes
              .campaignWaveDefinitionCodeDescription,
        },
        {
          selector: 'campaignWaveDefinitionId',
          name: translationPlugin.customerPlugins.pipes
            .campaignWaveDefinitionId,
          description:
            translationPlugin.customerPlugins.pipes
              .campaignWaveDefinitionIdDescription,
        },
        {
          selector: 'crmAddressObjectLoader',
          name: translationPlugin.customerPlugins.pipes.crmAddressObjectLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .crmAddressObjectLoaderDescription,
        },
        {
          selector: 'crmAddressRoleTypeCodeLoader',
          name: translationPlugin.customerPlugins.pipes
            .crmAddressRoleTypeCodeLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .crmAddressRoleTypeCodeLoaderDescription,
        },
        {
          selector: 'crmAddressRoleTypeLoader',
          name: translationPlugin.customerPlugins.pipes
            .crmAddressRoleTypeLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .crmAddressRoleTypeLoaderDescription,
        },
        {
          selector: 'tsmCrmAddressRoleUsage',
          name: translationPlugin.customerPlugins.pipes.tsmCrmAddressRoleUsage,
          description:
            translationPlugin.customerPlugins.pipes
              .tsmCrmAddressRoleUsageDescription,
        },
        {
          selector: 'crmAddressRoleObjectLoader',
          name: translationPlugin.customerPlugins.pipes
            .crmAddressRoleObjectLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .crmAddressRoleObjectLoaderDescription,
        },
        {
          selector: 'customerJsonActiveTasks',
          name: translationPlugin.customerPlugins.pipes.customerJsonActiveTasks,
          description:
            translationPlugin.customerPlugins.pipes
              .customerJsonActiveTasksDescription,
        },
        {
          selector: 'customerLegalFormCode',
          name: translationPlugin.customerPlugins.pipes.customerLegalFormCode,
          description:
            translationPlugin.customerPlugins.pipes
              .customerLegalFormCodeDescription,
        },
        {
          selector: 'customerLegalFormId',
          name: translationPlugin.customerPlugins.pipes.customerLegalFormId,
          description:
            translationPlugin.customerPlugins.pipes
              .customerLegalFormIdDescription,
        },
        {
          selector: 'customerLoaderKey',
          name: translationPlugin.customerPlugins.pipes.customerLoaderKey,
          description:
            translationPlugin.customerPlugins.pipes
              .customerLoaderKeyDescription,
        },
        {
          selector: 'customerLoader',
          name: translationPlugin.customerPlugins.pipes.customerLoader,
          description:
            translationPlugin.customerPlugins.pipes.customerLoaderDescription,
        },
        {
          selector: 'customerSegmentId',
          name: translationPlugin.customerPlugins.pipes.customerSegmentId,
          description:
            translationPlugin.customerPlugins.pipes
              .customerSegmentIdDescription,
        },
        {
          selector: 'customerTypeLoaderCode',
          name: translationPlugin.customerPlugins.pipes.customerTypeLoaderCode,
          description:
            translationPlugin.customerPlugins.pipes
              .customerTypeLoaderCodeDescription,
        },
        {
          selector: 'customerTypeLoader',
          name: translationPlugin.customerPlugins.pipes.customerTypeLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .customerTypeLoaderDescription,
        },
        {
          selector: 'leadLoaderKey',
          name: translationPlugin.customerPlugins.pipes.leadLoaderKey,
          description:
            translationPlugin.customerPlugins.pipes.leadLoaderKeyDescription,
        },
        {
          selector: 'leadLoader',
          name: translationPlugin.customerPlugins.pipes.leadLoader,
          description:
            translationPlugin.customerPlugins.pipes.leadLoaderDescription,
        },
        {
          selector: 'leadSourceLoader',
          name: translationPlugin.customerPlugins.pipes.leadSourceLoader,
          description:
            translationPlugin.customerPlugins.pipes.leadSourceLoaderDescription,
        },
        {
          selector: 'leadTypeLoaderCode',
          name: translationPlugin.customerPlugins.pipes.leadTypeLoaderCode,
          description:
            translationPlugin.customerPlugins.pipes
              .leadTypeLoaderCodeDescription,
        },
        {
          selector: 'leadTypeLoader',
          name: translationPlugin.customerPlugins.pipes.leadTypeLoader,
          description:
            translationPlugin.customerPlugins.pipes.leadTypeLoaderDescription,
        },
        {
          selector: 'partyCampaignStatusCode',
          name: translationPlugin.customerPlugins.pipes.partyCampaignStatusCode,
          description:
            translationPlugin.customerPlugins.pipes
              .partyCampaignStatusCodeDescription,
        },
        {
          selector: 'partyCampaignStatusId',
          name: translationPlugin.customerPlugins.pipes.partyCampaignStatusId,
          description:
            translationPlugin.customerPlugins.pipes
              .partyCampaignStatusIdDescription,
        },
        {
          selector: 'personObjectKeyLoader',
          name: translationPlugin.customerPlugins.pipes.personObjectKeyLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .personObjectKeyLoaderDescription,
        },
        {
          selector: 'personObjectLoader',
          name: translationPlugin.customerPlugins.pipes.personObjectLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .personObjectLoaderDescription,
        },
        {
          selector: 'personRoleByPersonIdLoader',
          name: translationPlugin.customerPlugins.pipes
            .personRoleByPersonIdLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .personRoleByPersonIdLoaderDescription,
        },
        {
          selector: 'personRoleObjectLoader',
          name: translationPlugin.customerPlugins.pipes.personRoleObjectLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .personRoleObjectLoaderDescription,
        },
        {
          selector: 'personRoleTypeLoader',
          name: translationPlugin.customerPlugins.pipes.personRoleTypeLoader,
          description:
            translationPlugin.customerPlugins.pipes
              .personRoleTypeLoaderDescription,
        },
        {
          selector: 'personTypeLoaderCode',
          name: translationPlugin.customerPlugins.pipes.personTypeLoaderCode,
          description:
            translationPlugin.customerPlugins.pipes
              .personTypeLoaderCodeDescription,
        },
        {
          selector: 'personTypeLoader',
          name: translationPlugin.customerPlugins.pipes.personTypeLoader,
          description:
            translationPlugin.customerPlugins.pipes.personTypeLoaderDescription,
        },
        {
          selector: 'tsmPersonUsage',
          name: translationPlugin.customerPlugins.pipes.tsmPersonUsage,
          description:
            translationPlugin.customerPlugins.pipes.tsmPersonUsageDescription,
        },
        {
          selector: 'primaryContactElastic',
          name: translationPlugin.customerPlugins.pipes.primaryContactElastic,
          description:
            translationPlugin.customerPlugins.pipes
              .primaryContactElasticDescription,
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'Customer',
      url: (entityId) => {
        return of('/crm/customer/' + entityId);
      },
    },
    {
      entityType: 'Lead',
      url: (entityId) => {
        return of('/crm/lead/' + entityId);
      },
    },
    {
      entityType: 'Person',
      url: (entityId) => {
        return of('/crm/person/' + entityId);
      },
    },
    {
      entityType: 'Account',
      url: (entityId) => {
        return of('/crm/account/' + entityId);
      },
    },
    {
      entityType: 'CustomerType',
      url: (entityId) => {
        return of('/crm/customer-type/' + entityId);
      },
    },
    {
      entityType: 'LeadType',
      url: (entityId) => {
        return of('/crm/lead-type/' + entityId);
      },
    },
    {
      entityType: 'PersonType',
      url: (entityId) => {
        return of('/crm/person-type/' + entityId);
      },
    },
    {
      entityType: 'AccountType',
      url: (entityId) => {
        return of('/crm/account-type' + entityId);
      },
    },
    {
      entityType: 'CustomerSegment',
      url: (entityId) => {
        return of('/crm/customer-segment' + entityId);
      },
    },
    {
      entityType: 'Campaign',
      url: (entityId) => {
        return of('/crm/v1/campaigns/' + entityId);
      },
    },
    {
      entityType: 'CampaignWaveDefinition',
      url: (entityId) => {
        return of('/crm/v1/campaign-wave-definitions/' + entityId);
      },
    },
    {
      entityType: 'CampaignWave',
      url: (entityId) => {
        return of('/crm/v1/campaign-waves/' + entityId);
      },
    },
  ],
  layoutControls: [
    controlsCustomerNewConfiguration,
    controlsCustomerDetailConfiguration,
    controlsAccountNewConfiguration,
    controlsAccountDetailConfiguration,
    controlsPersonNewConfiguration,
    controlsPersonDetailConfiguration,
    controlsLeadDetailConfiguration,
    controlsCampaignNewConfiguration,
    controlsCampaignDetailConfiguration,
    controlsTypeDetailConfiguration,
    controlsCampaignTypeDetailConfiguration,
    controlsPartyCampaignStatusDetailConfiguration,
    controlsCampaignStatusDetailConfiguration,
    controlsListTopbarConfiguration,
    controlsTopbarConfiguration,
    controlsCampaignTopbarConfiguration,
  ],
};
