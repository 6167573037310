export const en = {
  customerPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmAccountFilter: 'tSM Account Filter',
      tsmAccountFilterDescription:
        'Filters accounts by key or name, supporting multiple selection. ' +
        'Dynamically retrieves selected accounts and allows filtering ' +
        'through an overlay panel. Includes keyboard shortcuts for quick ' +
        'selection and removal of selected accounts.',
      tsmAccountStatusFilter: 'tSM Account Status Filter',
      tsmAccountStatusFilterDescription:
        'Filters accounts based on their status, supporting multiple selection. ' +
        'Displays available statuses in an overlay panel and enables users ' +
        'to select or deselect them. Uses translations for dynamic label ' +
        'rendering and allows bulk selection and removal of statuses.',
      tsmAccountTypeFilter: 'tSM Account Type Filter',
      tsmAccountTypeFilterDescription:
        'Filters accounts based on their type, supporting multiple selection. ' +
        'Displays account types in an overlay panel, enabling users to ' +
        'select or deselect them. Uses a service to fetch available account ' +
        'types and translations for dynamic label rendering.',
      tsmCampaignFilter: 'tSM Campaign Filter',
      tsmCampaignFilterDescription:
        'Filters campaigns by their unique identifiers, allowing multiple selection. ' +
        'Displays campaigns in an overlay panel, enabling users to ' +
        'choose and filter them efficiently. Uses a service to fetch campaign data ' +
        'and translations for dynamic label rendering.',
      tsmCampaignStatusFilter: 'tSM Campaign Status Filter',
      tsmCampaignStatusFilterDescription:
        'Filters campaign statuses by their unique identifiers or codes. ' +
        'Allows multiple selections and displays campaign statuses ' +
        'in an overlay panel for efficient selection. Uses a service to fetch ' +
        'campaign status data and applies translations dynamically for labels.',
      tsmCampaignTypeFilter: 'tSM Campaign Type Filter',
      tsmCampaignTypeFilterDescription:
        'Filters campaign types by their unique identifiers or codes. ' +
        'Allows multiple selections and displays campaign types ' +
        'in an overlay panel for efficient selection. Uses a service to fetch ' +
        'campaign type data and dynamically applies translations for labels.',
      tsmCampaignWaveDefinitionFilter: 'tSM Campaign Wave Definition Filter',
      tsmCampaignWaveDefinitionFilterDescription:
        'Filters campaign wave definitions by their unique identifiers. ' +
        'Allows multiple selections and presents available options ' +
        'in an overlay panel for efficient selection. Uses a service to ' +
        'fetch campaign wave definition data and dynamically applies translations ' +
        'for labels and display values.',
      tsmCrmAddressRoleTypeFilter: 'tSM Crm Address Role Type Filter',
      tsmCrmAddressRoleTypeFilterDescription:
        'Filters CRM address role types using a multi-select dropdown ' +
        'inside an overlay panel. Dynamically loads available role types ' +
        'from the register service and maps them into selectable options. ' +
        'Displays selected values with their translated names and allows ' +
        'users to confirm or cancel their selection.',
      tsmCustomerFilter: 'tSM Customer Filter',
      tsmCustomerFilterDescription:
        'Filters customers using a selection panel with multi-select support. ' +
        'Dynamically loads customer data and maps it to selectable options. ' +
        'Allows filtering by customer keys or IDs based on the provided context. ' +
        'Retrieves customer data from the store and updates the filter value ' +
        'with the selected items. Provides an option to confirm or cancel selection.',
      tsmCustomerLegalFormFilter: 'tSM Customer Legal Form Filter',
      tsmCustomerLegalFormFilterDescription:
        'Filters customer legal forms using a selection panel with multi-select support. ' +
        'Dynamically loads legal form data and maps it to selectable options. ' +
        'Retrieves legal form data from the store and updates the filter value ' +
        'with the selected items. Provides an option to confirm or cancel selection.',
      tsmCustomerSegmentTypeFilter: 'tSM Customer Segment Type Filter',
      tsmCustomerSegmentTypeFilterDescription:
        'Filters customer segments using a multi-select overlay panel. ' +
        'Loads segment data dynamically and presents it as selectable options. ' +
        'Retrieves customer segments from the store and updates the filter value ' +
        'with the selected items. Allows confirmation or cancellation of the selection.',
      tsmCustomerStatusFilter: 'tSM Customer Status Filter',
      tsmCustomerStatusFilterDescription:
        'Filters customer statuses using a multi-select overlay panel. ' +
        'Dynamically loads status data and displays selectable options. ' +
        'Retrieves customer statuses and updates the filter with selected values. ' +
        'Allows confirmation or cancellation of the selection.',
      tsmCustomerTypeFilter: 'tSM Customer Type Filter',
      tsmCustomerTypeFilterDescription:
        'Filters customer types using a multi-selection overlay panel. ' +
        'Loads customer type data dynamically and allows filtering by code or name. ' +
        'Retrieves selected customer types and updates the filter values. ' +
        'Supports confirming or canceling the selection.',
      tsmLeadFilter: 'tSM Lead Filter',
      tsmLeadFilterDescription:
        'Filters lead data using a multi-selection overlay panel. ' +
        'Loads lead records dynamically and enables filtering by ID or party name. ' +
        'Retrieves selected leads and updates the filter values. ' +
        'Supports confirming or canceling the selection.',
      tsmLeadPriorityFilter: 'tSM Lead Priority Filter',
      tsmLeadPriorityFilterDescription:
        'Filters lead priority data using a multi-selection overlay panel. ' +
        'Loads priority options dynamically and enables filtering by priority name. ' +
        'Retrieves selected priorities and updates the filter values. ' +
        'Supports confirming or canceling the selection.',
      tsmLeadSourceFilter: 'tSM Lead Source Filter',
      tsmLeadSourceFilterDescription:
        'Filters lead sources using a multi-selection overlay panel. ' +
        'Allows users to select lead sources dynamically and updates the filter. ' +
        'Retrieves lead source data and formats selected values for display. ' +
        'Supports sorting, filtering, and confirming or canceling selections.',
      tsmLeadStatusFilter: 'tSM Lead Status Filter',
      tsmLeadStatusFilterDescription:
        'Filters lead statuses using a multi-selection overlay panel. ' +
        'Allows users to select lead statuses dynamically and updates the filter. ' +
        'Retrieves lead status data and formats selected values for display. ' +
        'Supports sorting, filtering, and confirming or canceling selections.',
      tsmLeadTypeFilter: 'tSM Lead Type Filter',
      tsmLeadTypeFilterDescription:
        'Filters lead types using a multi-selection overlay panel. ' +
        'Allows users to dynamically select lead types and updates the filter. ' +
        'Retrieves lead type data and formats selected values for display. ' +
        'Supports sorting, filtering, and confirming or canceling selections.',
      tsmPartyCampaignStatusFilter: 'tSM Party Campaign Status Filter',
      tsmPartyCampaignStatusFilterDescription:
        'Filters party campaign statuses using a multi-selection overlay panel. ' +
        'Allows users to dynamically select campaign statuses and updates the filter. ' +
        'Retrieves status data and formats selected values for display. ' +
        'Supports sorting, filtering, and confirming or canceling selections.',
      tsmPersonFilter: 'tSM Person Filter',
      tsmPersonFilterDescription:
        'Filters persons using a multi-selection overlay panel. ' +
        'Allows users to select individuals dynamically and updates the filter. ' +
        'Retrieves person data and formats selected values for display. ' +
        'Supports sorting, filtering, and confirming or canceling selections.',
      tsmPersonStatusFilter: 'tSM Person Status Filter',
      tsmPersonStatusFilterDescription:
        'Filters persons based on status using a multi-selection overlay panel. ' +
        'Displays available statuses and allows users to select multiple options. ' +
        'Retrieves person status data and formats selected values for display. ' +
        'Supports confirming or canceling selections and updating the filter.',
      tsmPersonTypeFilter: 'tSM Person Type Filter',
      tsmPersonTypeFilterDescription:
        'Filters persons by type using a multi-selection overlay panel. ' +
        'Displays a list of person types and allows multiple selections. ' +
        'Retrieves available types and formats selected values for display. ' +
        'Supports confirming or canceling selections and updates the filter.',
      tsmRoleInGroupFilter: 'tSM Role In Group Filter',
      tsmRoleInGroupFilterDescription:
        'Filters roles within a group using a multi-selection overlay panel. ' +
        'Displays a list of available roles and allows multiple selections. ' +
        'Retrieves role data and formats selected values for display. ' +
        'Supports confirming or canceling selections and updates the filter.',
    },
    pipes: {
      customerLoader: 'Customer convertor',
      customerLoaderDescription:
        'Loads customer data by ID from the store, retrieves specific fields including ' +
        'nested properties using dot notation, or returns the entire object if requested. ' +
        'Ensures the data is fetched if unavailable.',
      accountLoader: 'Account convertor',
      accountLoaderDescription:
        'Loads account data by ID from the store, retrieves specific fields using dot notation, ' +
        'or returns the entire object if requested. Ensures the data is fetched if it is not available.',
      accountTypeLoaderCode: 'Account Type Loader Code',
      accountTypeLoaderCodeDescription:
        'Loads account type data by code from the store, retrieves specific fields like the name, or ' +
        'the complete object, and ensures the data is fetched if it is not already available.',
      campaignId: 'Campaign convertor',
      campaignIdDescription:
        'Loads campaign data by ID from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      campaignStatusId: 'Campaign status convertor',
      campaignStatusIdDescription:
        'Loads campaign status data by ID from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      campaignCode: 'Campaign convertor',
      campaignCodeDescription:
        'Loads campaign data by code from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      campaignStatusCode: 'Campaign status convertor',
      campaignStatusCodeDescription:
        'Loads campaign status data by code from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      campaignTypeId: 'Campaign type convertor',
      campaignTypeIdDescription:
        'Loads campaign type data by ID from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      campaignTypeCode: 'Campaign type convertor',
      campaignTypeCodeDescription:
        'Loads campaign type data by code from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      partyCampaignStatusId: 'Customer campaign status convertor',
      partyCampaignStatusIdDescription:
        'Loads party campaign status data by ID from the store, retrieves specific fields such as' +
        'the name or the entire object, and ensures the data is fetched if it is not already available.',
      partyCampaignStatusCode: 'Customer campaign status convertor',
      partyCampaignStatusCodeDescription:
        'Loads party campaign status data by code from the store, retrieves specific fields ' +
        'such as the name or the entire object, and ensures the data is fetched if not available.',
      campaignWaveDefinitionId: 'Campaign wave definition convertor',
      campaignWaveDefinitionIdDescription:
        'Loads campaign wave definition data by ID from the store, retrieves specific fields ' +
        'such as the name or the entire object, and ensures the data is fetched if not available.',
      campaignWaveDefinitionCode: 'Campaign wave definition convertor',
      campaignWaveDefinitionCodeDescription:
        'Loads campaign wave definition data by code from the store, retrieves specific fields ' +
        'such as the name or the entire object, and ensures the data is fetched if not available.',
      personObjectLoader: 'Person convertor',
      personObjectLoaderDescription:
        'Loads person data by ID from the store, retrieves specific fields such as the full name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      crmAddressRoleTypeCodeLoader: 'CRM address role type convertor',
      crmAddressRoleTypeCodeLoaderDescription:
        'Loads CRM address role type data by code from the store, retrieves the name field, ' +
        'and ensures the data is fetched if it is not already available.',
      crmAddressObjectLoader: 'CRM address convertor',
      crmAddressObjectLoaderDescription:
        'Loads CRM address data by ID from the store, retrieves specific fields or the entire ' +
        'object, and ensures the data is fetched if it is not already available.',
      accountLoaderKey: 'Account Loader Key',
      accountLoaderKeyDescription:
        'Fetches and caches account data by key, retrieving specific fields or all data, ' +
        'and triggers actions if the data isn not already cached.',
      primaryContactElastic: 'Primary Contact Elastic',
      primaryContactElasticDescription:
        'Finds and returns the value of the primary contact from a list, or null if no ' +
        'primary contact is found.',
      tsmPersonUsage: 'tSM Person Usage',
      tsmPersonUsageDescription:
        'Loads and combines account and order data by person role IDs, retrieves ' +
        'specified fields, and triggers actions to fetch missing data if necessary.',
      personTypeLoaderCode: 'Person Type Loader Code',
      personTypeLoaderCodeDescription:
        'Loads person type data by code from the store, retrieves specific fields ' +
        'or all data, and triggers actions to fetch data if not already available.',
      personTypeLoader: 'Person Type Loader',
      personTypeLoaderDescription:
        'Loads person type data by ID from the store, retrieves specific fields or ' +
        'all data, and triggers actions to fetch missing data if not already available.',
      personRoleTypeLoader: 'Person Role Type Loader',
      personRoleTypeLoaderDescription:
        'Loads person role type data by ID from the store, retrieves data such as code or name, ' +
        'and ensures the data is fetched if it is not already available.',
      personRoleObjectLoader: 'Person Role Object Loader Description',
      personRoleObjectLoaderDescription:
        'Loads person role data by ID from the store, retrieves the full object, and triggers ' +
        'actions to fetch the data if it is not already available.',
      personRoleByPersonIdLoader: 'Person Role By Person Id Loader',
      personRoleByPersonIdLoaderDescription:
        'Loads active person roles by person ID from the store, retrieves their data, and triggers ' +
        'actions to fetch the roles if they are not available or missing in the cache.',
      personObjectKeyLoader: 'Person Object Key Loader',
      personObjectKeyLoaderDescription:
        'Loads person data by a unique key from the store, retrieves specific fields or the entire ' +
        'object, and triggers actions to fetch the data if it is not already available.',
      leadTypeLoader: 'Lead Type Loader',
      leadTypeLoaderDescription:
        'Loads lead type data by ID from the store, retrieves specific fields or the entire ' +
        'object, and triggers actions to fetch data if it is not already available.',
      leadSourceLoader: 'Lead Source Loader',
      leadSourceLoaderDescription:
        'Loads lead source data by ID from the store, retrieves specific fields or the entire ' +
        'object, and triggers actions to fetch data if it is not already available.',
      leadTypeLoaderCode: 'Lead Type Loader Code',
      leadTypeLoaderCodeDescription:
        'Loads lead type data by code from the store, retrieves specific fields like the name, or ' +
        'the complete object, and ensures the data is fetched if it is not already available.',
      leadLoader: 'Lead Loader',
      leadLoaderDescription:
        'Loads lead data by ID from the store, retrieves specific fields such as the "party name" ' +
        'or the entire object, and triggers actions to fetch data if it is not already available.',
      customerTypeLoader: 'Customer Type Loader',
      customerTypeLoaderDescription:
        'Loads customer type data by ID from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      leadLoaderKey: 'Lead Loader Key',
      leadLoaderKeyDescription:
        'Loads lead data by key from the store, retrieves specific fields like the party name, or ' +
        'the complete object, and ensures the data is fetched if it is not already available.',
      customerTypeLoaderCode: 'Customer Type Loader Code',
      customerTypeLoaderCodeDescription:
        'Loads customer type data by code from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available',
      customerSegmentId: 'Customer Segment Id',
      customerSegmentIdDescription:
        'Loads customer segment data by ID from the store, retrieves specific fields such as the name ' +
        'or the entire object, and ensures the data is fetched if it is not already available.',
      customerLoaderKey: 'Customer Loader Key',
      customerLoaderKeyDescription:
        'Loads customer data by key from the store, retrieves specific fields including nested ' +
        'properties using dot notation, or returns the entire object if requested. Ensures data ' +
        'is fetched if unavailable.',
      customerLegalFormId: 'Customer Legal Form Id',
      customerLegalFormIdDescription:
        'Loads customer legal form data by ID from the store, retrieves specific fields such as ' +
        'the name or the entire object, and ensures the data is fetched if it is not already available.',
      customerLegalFormCode: 'Customer Legal Form Code',
      customerLegalFormCodeDescription:
        'Loads customer legal form data by code from the store, retrieves specific fields such as ' +
        'the name or the entire object, and ensures the data is fetched if it is not already available.',
      customerJsonActiveTasks: 'Customer Json Active Tasks',
      customerJsonActiveTasksDescription:
        'Processes and formats active task information from a JSON string, converting dates ' +
        'to a readable format and returning distinct values as a CSV string.',
      crmAddressRoleObjectLoader: 'CRM Address Role Object Loader',
      crmAddressRoleObjectLoaderDescription:
        'Fetches CRM address role data by ID from the store, retrieves the full object, ' +
        'and dispatches actions to load the data if it is not already available',
      tsmCrmAddressRoleUsage: 'tSM CRM Address Role Usage',
      tsmCrmAddressRoleUsageDescription:
        'Combines and processes data related to CRM address roles by IDs, including accounts, ' +
        'orders, and additional objects. Retrieves specified fields or all data and dispatches ' +
        'actions to load missing information if necessary.',
      crmAddressRoleTypeLoader: 'CRM Address Role Type Loader',
      crmAddressRoleTypeLoaderDescription:
        'Loads CRM address role type data by ID from the store, retrieves the name field, ' +
        'and dispatches actions to fetch the data if it is not already available.',
      accountTypeLoader: 'Account Type Loader',
      accountTypeLoaderDescription:
        'Loads account type data by ID from the store, retrieves specific fields such as the name ' +
        'or the entire object, and dispatches actions to fetch the data if it is not already available.',
    },
  },
};
