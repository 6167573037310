/**
 * U seznamu poli hodnot zalezi na poradi
 * (hodnoty polozek jsou postupne updatovany podle hodnot v zadanych polich od prvniho do posledniho zleva)
 * @param key
 * @param rest
 */
export function distinctArrays(key: string, ...rest: any[][]) {
  function concatMultiple(arrs: any[][]) {
    let result: any[] = [];
    arrs.forEach((arr) => {
      result = result.concat(arr); //zachova poradi spojenych poli a jejich polozek
    });
    return result;
  }

  const distinced = concatMultiple(rest);
  const final: any[] = [];

  distinced
    .filter((x) => x != null)
    .forEach((con) => {
      const existing = final.find(
        (x) => (key ? x[key] : x) === (key ? con[key] : con),
      );
      if (!existing) {
        final.push(con);
      } else {
        // update existujici polozky, pokud je v distinced vickrat
        final[
          final.findIndex((x) => (key ? x[key] : x) === (key ? con[key] : con))
        ] = key ? {...existing, ...con} : con;
      }
    });

  return final;
}
