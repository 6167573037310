<div
  #container
  tsmResizeObserver
  (resize)="onObserverResized($event)"
  class="editor-container"
  mwlResizable
  [mouseMoveThrottleMS]="10"
  (resizing)="onMwlResizing($event)"
>
  @if (titleOriginal || titleModified) {
    <div class="flex flex-nowrap">
      @if (titleOriginal) {
        <span class="w-6 pl-4">{{ titleOriginal }}</span>
      }
      @if (titleModified) {
        <span class="w-6 pl-4">{{ titleModified }}</span>
      }
    </div>
  }
  <div class="wrapper">
    <div
      #diffEditor
      class="monaco-editor"
      [style.width.px]="container.offsetWidth"
      [style.height.px]="container.offsetHeight"
      style="min-width: 0"
    ></div>
  </div>
  @if (resizable) {
    <div
      class="resize-handle"
      [style.height.px]="resizeHandleHeightPx"
      mwlResizeHandle
      [resizeEdges]="{bottom: true}"
    >
      <img
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MjYuNjY3IDQyNi42NjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQyNi42NjcgNDI2LjY2NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8Zz4KCTxnPgoJCTxjaXJjbGUgY3g9IjQyLjY2NyIgY3k9IjIxMy4zMzMiIHI9IjQyLjY2NyIgZmlsbD0iIzg3ODc4NyIvPgoJPC9nPgo8L2c+CjxnPgoJPGc+CgkJPGNpcmNsZSBjeD0iMjEzLjMzMyIgY3k9IjIxMy4zMzMiIHI9IjQyLjY2NyIgZmlsbD0iIzg3ODc4NyIvPgoJPC9nPgo8L2c+CjxnPgoJPGc+CgkJPGNpcmNsZSBjeD0iMzg0IiBjeT0iMjEzLjMzMyIgcj0iNDIuNjY3IiBmaWxsPSIjODc4Nzg3Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="
        style="min-width: 36px"
      />
    </div>
  }
</div>
