import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';

export const controlsUserGroupConfiguration: DtlLayoutControlsConfiguration = {
  code: 'tsm-user-user-group',
  name: 'User group',
  description: '',
  useType: 'FORM_NEW',
  entityType: 'UserGroup',
  tsmControls: {},
};
