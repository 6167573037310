import {createAction, props} from '@ngrx/store';
import {EntitySpecification} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadEntitySpecificationById = createAction(
  '[EntitySpecification] Load EntitySpecificationById',
  props<{
    id: string;
  }>(),
);
export const LoadEntitySpecificationSuccess = createAction(
  '[EntitySpecification] Load EntitySpecification Success',
  props<{
    entitySpecification: EntitySpecification;
    invalidCached: boolean;
  }>(),
);
export const LoadEntitySpecificationError = createAction(
  '[EntitySpecification] Load EntitySpecification Error',
  props<{
    error: any;
  }>(),
);

export const LoadEntitySpecificationByCode = createAction(
  '[EntitySpecification] Load EntitySpecificationByCode',
  props<{
    code: string;
  }>(),
);
export const LoadEntitySpecificationByCodeSuccess = createAction(
  '[EntitySpecificationType] Load oadEntitySpecificationByCode Success',
  props<{
    entitySpecification: EntitySpecification;
    invalidCached: boolean;
  }>(),
);
export const LoadEntitySpecificationByCodeError = createAction(
  '[EntitySpecificationType] Load oadEntitySpecificationByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const LoadEntitySpecificationsSuccess = createAction(
  '[EntitySpecification] Load EntitySpecifications Success',
  props<{
    entities: EntitySpecification[];
  }>(),
);

export const LoadEntitySpecificationsError = createAction(
  '[EntitySpecification] Load EntitySpecifications Error',
  props<{
    error: any;
  }>(),
);

export const LoadEntitySpecificationsByCharacteristicCode = createAction(
  '[EntitySpecification] Load EntitySpecifications by characteristicCode',
  props<{
    characteristicCode: string;
  }>(),
);

export const UpsertEntitySpecification = createAction(
  '[EntitySpecification] Upsert EntitySpecification',
  props<{
    specification: EntitySpecification;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertEntitySpecificationSuccess = createAction(
  '[EntitySpecification] Upsert EntitySpecification Success',
  props<{
    specification: EntitySpecification;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertEntitySpecificationError = createAction(
  '[EntitySpecification] Upsert EntitySpecification Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const DeleteEntitySpecification = createAction(
  '[EntitySpecification] Delete EntitySpecification',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);

export const DeleteEntitySpecificationSuccess = createAction(
  '[EntitySpecification] Delete EntitySpecification Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);

export const DeleteEntitySpecificationError = createAction(
  '[EntitySpecification] Delete EntitySpecification Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const DiffEntitySpecification = createAction(
  '[EntitySpecification] Diff EntitySpecification',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffEntitySpecificationSuccess = createAction(
  '[EntitySpecification] Diff EntitySpecification Success',
  props<{
    entitySpecifications: EntitySpecification[];
    listingId: string;
  }>(),
);
export const DiffEntitySpecificationError = createAction(
  '[EntitySpecification] Diff EntitySpecification Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
