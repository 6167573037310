export const cs = {
  serviceManagementPlugins: {
    headerDescription: 'Hlavička dokumentace',
    pipes: {
      consumerStatusIcon: 'Consumer Status Icon',
      consumerStatusIconDescription:
        'Vrací ikonu reprezentující stav konzumenta. Každému stavu přiřazuje odpovídající ikonu ' +
        'a volitelnou CSS třídu. Může vracet buď celý HTML prvek nebo pouze třídu.',
      serviceStatusIconPipe: 'Service Status Icon Pipe',
      serviceStatusIconPipeDescription:
        'Přiřazuje stavu služby odpovídající ikonu a popisek. Vrací buď celý HTML prvek ' +
        'nebo pouze třídu, podle zadaného parametru.',
    },
  },
};
