import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  TemplateRef,
} from '@angular/core';
import {LayoutedComponent} from './layouted-component';

@Directive({
  selector: '[tsmLayoutId]',
  standalone: true,
})
export class LayoutIdDirective {
  @Input() tsmLayoutId: [
    key: string,
    propagateCallback: (LayoutedComponent) => any,
  ];

  @Input() tsmLayoutIdForceReadonly = false;

  public layoutedComponent: LayoutedComponent;

  constructor(
    public el: ElementRef,
    public cdr: ChangeDetectorRef,
    public injector: Injector,
    public template: TemplateRef<any>,
  ) {}
}
