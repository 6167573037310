import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {
  LoadUserById,
  LoadUserGroupById,
  selectUserById,
  selectUserGroupById,
} from '@tsm/user-management/service';
import {MemoizedSelector} from '@ngrx/store/src/selector';

@Pipe({
  name: 'entityNameLoader',
  pure: true,
})
export class EntityNameLoaderPipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  setObservable(item: any, field = 'name') {
    this.destroy();
    this._id = item;

    this._obs$ = this.store.pipe(
      select(this.getSelect(this._id)),
      filter((it) => !!it?.data),
      map((it) => {
        return field !== 'all' ? it.data[field] : it.data;
      }),
    );
  }

  getSelect(item: any): MemoizedSelector<Object, any> {
    if (item && item.refId) {
      switch (item.refType) {
        case 'User':
          return selectUserById(item.refId);
          break;
        case 'UserGroup':
          return selectUserGroupById(item.refId);
      }
    }
    return null;
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(this.getSelect(this._id)))
      .subscribe((x) => {
        if (!x) {
          switch (this._id.refType) {
            case 'User':
              untracked(() =>
                this.store.dispatch(LoadUserById({id: this._id.refId})),
              );
              break;
            case 'UserGroup':
              untracked(() =>
                this.store.dispatch(LoadUserGroupById({id: this._id.refId})),
              );
          }
        }
      });
  }
}
