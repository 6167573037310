import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {FilterModel} from '@tsm/listing-lib/service';
import {Characteristics} from '../models';
import {IdentifiedPack} from '@tsm/framework/root';

export const LoadCharacteristics = createAction(
  '[Characteristics] Load Characteristics',
);
export const LoadCharacteristicsSuccess = createAction(
  '[Characteristics] Load Characteristics Success',
  props<{
    entities: Characteristics[];
  }>(),
);
export const LoadCharacteristicsPacksSuccess = createAction(
  '[Characteristics] Load Characteristics Packs Success',
  props<{
    packs: IdentifiedPack<Characteristics>[];
  }>(),
);
export const LoadCharacteristicsError = createAction(
  '[Characteristics] Load Characteristics Error',
  props<{
    error: any;
  }>(),
);

export const UpsertCharacteristics = createAction(
  '[Characteristics] Upsert Characteristics',
  props<{
    characteristics: Characteristics;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertCharacteristicsSuccess = createAction(
  '[Characteristics] Upsert Characteristics Success',
  props<{
    characteristics: Characteristics;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertCharacteristicsError = createAction(
  '[Characteristics] Upsert Characteristics Error',
  props<{
    id: string;
    error: any;
  }>(),
);

export const DeleteCharacteristics = createAction(
  '[Characteristics] Delete Characteristics',
  props<{
    id: string;
    listingId: string;
    redirectToDetail: boolean;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteCharacteristicsSuccess = createAction(
  '[Characteristics] Delete Characteristics Success',
  props<{
    id: string;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const DeleteCharacteristicsError = createAction(
  '[Characteristics] Delete Characteristics Error',
  props<{
    error: any;
  }>(),
);

export const LoadCharacteristicsById = createAction(
  '[Characteristics] Load Characteristics by Id',
  props<{
    id: string;
  }>(),
);
export const LoadCharacteristicsByIdSuccess = createAction(
  '[Characteristics] Load Characteristics by Id Success',
  props<{
    entity: Characteristics;
  }>(),
);
export const LoadCharacteristicsByIdError = createAction(
  '[Characteristics] Load Characteristics by Id Error',
  props<{
    error: any;
  }>(),
);

export const LoadCharacteristicsByFormId = createAction(
  '[Characteristics] Load Characteristics by FormId',
  props<{
    formId: string;
  }>(),
);
export const LoadCharacteristicsByFormIdSuccess = createAction(
  '[Characteristics] Load Characteristics by FormId Success',
  props<{
    entities: Characteristics[];
  }>(),
);
export const LoadCharacteristicsByFormIdError = createAction(
  '[Characteristics] Load Characteristics by FormId Error',
  props<{
    error: TsmError;
  }>(),
);

export const LoadBatchCharacteristicsByCodeFilter = createAction(
  '[Characteristic] Load Batch Characteristics By CodeFilter',
  props<{
    code: string;
  }>(),
);
export const LoadCharacteristicsByCodes = createAction(
  '[Characteristic] Load Characteristics By Codes',
  props<{
    codes: string[];
  }>(),
);
export const LoadCharacteristicsByFilter = createAction(
  '[Characteristic] Load Characteristics By Filter',
  props<{
    filters: FilterModel[];
  }>(),
);
export const LoadCharacteristicsByFilterSuccess = createAction(
  '[Characteristic] Load Characteristics By Filter Success',
  props<{
    entities: Characteristics[];
  }>(),
);
export const LoadCharacteristicsByFilterError = createAction(
  '[Characteristic] Load Characteristics By Filter Error',
  props<{
    error: any;
  }>(),
);

export const LoadCharacteristicsByEntityType = createAction(
  '[Characteristic] Load Characteristics By EntityType',
  props<{
    entityType: string;
  }>(),
);
export const LoadCharacteristicsByEntityTypeSuccess = createAction(
  '[Characteristic] Load Characteristics By EntityType Success',
  props<{
    entities: Characteristics[];
  }>(),
);
export const LoadCharacteristicsByEntityTypeError = createAction(
  '[Characteristic] Load Characteristics By EntityType Error',
  props<{
    error: any;
  }>(),
);

export const DiffCharacteristics = createAction(
  '[Characteristic] Diff Characteristics',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffCharacteristicsSuccess = createAction(
  '[Characteristic] Diff Characteristics Success',
  props<{
    characteristics: Characteristics[];
    listingId: string;
  }>(),
);
export const DiffCharacteristicsError = createAction(
  '[Characteristic] Diff Characteristics Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
