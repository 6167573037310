import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GridComponent} from './components/grid.component';
import {FormGridComponent} from './components/form-grid.component';
import {ColComponent} from './components/col.component';
import {FieldColComponent} from './components/field-col.component';
import {FixedColDirective} from './directives/fixed-col.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    GridComponent,
    ColComponent,
    FormGridComponent,
    FieldColComponent,
    FixedColDirective,
  ],
  exports: [
    GridComponent,
    ColComponent,
    FormGridComponent,
    FieldColComponent,
    FixedColDirective,
  ],
})
export class FrameworkGridModule {}
