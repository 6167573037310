export const cs = {
  orderingPlugins: {
    orderLine: {
      count: 'Počet',
      operation: 'Operace',
      work: 'Práce',
      productId: 'ID produktu',
      productCode: 'Kód produktu',
      serviceId: 'ID služby',
      serviceCode: 'Kód služby',
      workCode: 'Kód práce',
      price: 'Cena',
      workOnService: 'Práce na službě',
      prieForUnit: 'Cena za jednotku',
      endOfProviding: 'Konec poskytování',
      priceForUnitWithoutTax: 'Cena za jednotku (bez DPH)',
      totalPriceForUnit: 'Celková cena za jednotky',
      paymentPeriod: 'Perioda plateb',
      regularPriceValue: 'Cena pravidelné platby',
      regularPriceValueWithoutTax: 'Cena pravidelné platby (bez DPH)',
      totalPriceForUsing: 'Celková cena za použití',
      totalPriceForRegularPayment: 'Celková cena pravidelné platby',
      priceConfig: 'Nastavení cen',
      orderRowChars: 'Konfigurační profil řádku objednávky',
      orderTypeDetail: 'Detail typu objednávky',
      newOrderType: 'Nový typ objednávky',
      orderTypes: 'Typy objednávek',
      goToProductDetail: 'Přejít na detail produktu',
      goToServiceDetail: 'Přejít na detail služby',
      goToDetailLine: 'Přejít na detail řádku',
      addRelationshipOnSpecification: 'Přidat doplňkový produkt',
      addRelationshipOnInventory: 'Přidat podřízený z inventory',
      addServiceRelationshipOnSpecification: 'Přidat doplňkovou službu',
      deleteProduct: 'Odebrat produkt',
      deleteService: 'Odebrat službu',
      deleteConfirmation: 'Opravdu chcete odebrat vybraný produkt?',
      selectProductWarn:
        'Byla vybrána kategorie, vyberte prosím konkrétní produkt.',
    },
    filters: {
      tsmOrderStatusFilter: 'tSM Order Status Filter',
      tsmOrderStatusFilterDescription:
        'Definuje komponentu filtru stavu objednávky, která rozšiřuje nadřazenou komponentu ' +
        'pro filtrování seznamů. Poskytuje uživatelské rozhraní pro výběr více stavů ' +
        'objednávek pomocí datového pohledu. Podporuje filtrování, řazení a zobrazení ' +
        'stavů objednávek získaných z externího API. Implementuje překryvný panel s ' +
        'možnostmi potvrzení nebo zrušení výběru. Po výběru aktualizuje hodnoty filtru ' +
        'a emituje změny.',
      tsmOrderTypeFilter: 'tSM Order Type Filter',
      tsmOrderTypeFilterDescription:
        'Definuje komponentu filtru typu objednávky, která rozšiřuje nadřazenou ' +
        'komponentu pro filtrování seznamů. Poskytuje rozhraní pro výběr více typů ' +
        'objednávek pomocí datového pohledu. Podporuje filtrování, řazení a načítání ' +
        'typů objednávek z externího API. Implementuje překryvný panel s možnostmi ' +
        'potvrzení nebo zrušení výběru. Aktualizuje hodnoty filtru a po výběru ' +
        'emituje změny. Dynamicky upravuje seznam na základě zadaného kontextu.',
      tsmQuoteStatusFilter: 'tSM Quote Status Filter',
      tsmQuoteStatusFilterDescription:
        'Definuje komponentu filtru stavu nabídky, která rozšiřuje nadřazenou ' +
        'komponentu pro filtrování tabulky. Poskytuje rozhraní pro výběr stavů ' +
        'nabídky pomocí datového pohledu. Podporuje vícenásobný výběr, zobrazení ' +
        'stavů v tabulce a aktualizaci hodnot filtru po výběru. Implementuje ' +
        'překryvný panel s možnostmi potvrzení a zrušení. Dynamicky překládá názvy ' +
        'stavů na základě lokalizace.',
    },
    pipes: {
      orderLoader: 'Order Loader',
      orderLoaderDescription:
        'Načítá data objednávky podle ID z úložiště, získává specifická pole, jako je předmět ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      orderLoaderKey: 'Order Loader by Key',
      orderLoaderKeyDescription:
        'Načítá data objednávky podle klíče z úložiště, získává specifická pole, jako je předmět ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      orderStatusByCode: 'Order Status By Code',
      orderStatusByCodeDescription:
        'Načítá data stavu objednávky podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      orderStatusById: 'Order Status By Id',
      orderStatusByIdDescription:
        'Načítá data stavu objednávky podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      orderTypeByCode: 'Order Type By Code',
      orderTypeByCodeDescription:
        'Načítá data typu objednávky podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      orderTypeById: 'Order Type By Id',
      orderTypeByIdDescription:
        'Načítá data typu objednávky podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      productOrderLines: 'Product Order Lines',
      productOrderLinesDescription:
        'Načítá data řádků objednávky spojená s konkrétním ID konfigurace instance entity ' +
        'z úložiště, získává buď všechna pole nebo specifická a provádí akci pro načtení dat, ' +
        'pokud již nejsou dostupná.',
      quoteLoader: 'Quote Loader',
      quoteLoaderDescription:
        'Načítá data nabídky podle ID z úložiště, získává specifická pole, jako je předmět ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
    },
  },
};
