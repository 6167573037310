import {AbstractControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';

export abstract class TypedTsmAbstractControl<
  TValue = any,
  TRawValue extends TValue = TValue,
> extends AbstractControl<TValue, TRawValue> {
  public forceReadonly = false;
  public touchedChanges: Observable<boolean> = null;
  public defaults: TValue;
  public default = false;
  public readonly = false;
  public filterMap: {[dataPointer: string]: any} = null;

  abstract markAsPristineRecursively();

  abstract markAsDirtyRecursively();

  abstract markAsTouchedRecursively();

  abstract markAsUntouchedRecursively();

  abstract setDefaultValueRecursively(defaultValue: TValue);

  abstract markAsDefaultRecursively();

  abstract markAsNonDefaultRecursively();

  abstract resetWithDefaults(options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  });

  abstract setFilterMapRecursively(filterMap);

  abstract setReadonly(readonly: boolean);

  abstract getFilteredValue(): TValue;

  abstract resetNonDefaultValues(
    value?: TValue,
    options?: {onlySelf?: boolean; emitEvent?: boolean},
  );

  abstract getChangedValue(): TValue;
}
