import {Injectable} from '@angular/core';
import {exhaustBy, withLatestCached} from '@tsm/framework/root';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {timer} from 'rxjs';
import {filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {
  ClearProfileSuccess,
  DeleteProfileSuccess,
  DisableRefresh,
  DisableRefreshForAllProfiles,
  EnableRefresh,
  LoadData,
  LoadProfilesSuccess,
  ResetData,
  ResetTableStateWithoutDataReload,
  SetRefresh,
  SetupProfileUrlFromExistingMemory,
  StartRefresh,
} from '../actions';
import {TableKeyType} from '../models';
import {ListingService} from '../services';
import {LISTING_MINIMAL_REFRESH_IN_SECONDS} from '../utils';

@Injectable()
export class ListingRefreshEffects {
  setRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetRefresh),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      filter(
        ([{profileId, skipStart}, state]) =>
          state?.profiles.find((x) => x.id === profileId) != null && !skipStart,
      ),
      map(([action, _]) => action),
      map(({id, profileId}) => StartRefresh({id, profileId})),
    ),
  );

  enableRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnableRefresh),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      filter(
        ([{profileId}, state]) =>
          state?.profiles.find((x) => x.id === profileId)?.config?.refresh !=
          null,
      ),
      map(([{id, profileId}, _]) => StartRefresh({id, profileId})),
    ),
  );

  loadProfilesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProfilesSuccess),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      map(([{id}, state]) =>
        StartRefresh({id, profileId: state.selectedProfileId}),
      ),
    ),
  );

  clearProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearProfileSuccess),
      map(({id}) => DisableRefreshForAllProfiles({id})),
    ),
  );

  deleteProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteProfileSuccess),
      mergeMap(({payload}) => {
        if (payload?.reloadData === false) {
          return [
            DisableRefreshForAllProfiles({id: payload.id}),
            ResetTableStateWithoutDataReload({id: payload.id}),
          ];
        }
        return [
          DisableRefreshForAllProfiles({id: payload.id}),
          ResetData({id: payload.id}),
        ];
      }),
    ),
  );

  setupProfileUrlFromExistingMemory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetupProfileUrlFromExistingMemory),
      withLatestCached(({payload}) =>
        this.listingService.getTableState(payload.id),
      ),
      filter(([_, state]) => state != null),
      map(([{payload}, state]) =>
        StartRefresh({id: payload.id, profileId: state.selectedProfileId}),
      ),
    ),
  );

  startRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartRefresh),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      filter(([{profileId}, state]) => {
        const refresh = state.profiles.find((x) => x.id === profileId)?.config
          ?.refresh;
        return (
          refresh?.seconds >= LISTING_MINIMAL_REFRESH_IN_SECONDS &&
          state.selectedProfileId === profileId &&
          refresh?.enabled
        );
      }),
      exhaustBy(
        ([{id, profileId}, _]) => id + profileId,
        ([{id, profileId}, state]) => {
          const profile = state.profiles.find((x) => x.id === profileId);
          const seconds = profile.config.refresh.seconds;
          const errorMsgDurationSeconds =
            profile.config.refresh.errorMsgDurationSeconds != null
              ? profile.config.refresh.errorMsgDurationSeconds * 1000
              : null;
          const ms = seconds * 1000;
          return timer(ms, ms).pipe(
            map(() => LoadData({id, errorMsgDurationSeconds})),
            takeUntil(
              this.actions$.pipe(
                ofType(DisableRefresh, DisableRefreshForAllProfiles),
                filter(
                  (action: {id: string; profileId?: TableKeyType}) =>
                    action.id === id &&
                    (action.profileId != null
                      ? action.profileId === profileId
                      : true),
                ),
              ),
            ),
          );
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private listingService: ListingService,
  ) {}
}
