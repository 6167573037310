export type ContextParamType = 'entityId' | 'entityType';

export interface HistoryRouteData {
  logHistory: {
    entityId?: string;
    entityType?: string;
    title?: string;
    icon?: string;
    paramsMap?: {[contextParam in ContextParamType]: string};
  };
}

export function isHistoryRouteData(
  obj: HistoryRouteData,
): obj is HistoryRouteData {
  return (obj as HistoryRouteData).logHistory !== undefined;
}
