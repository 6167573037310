import {createAction, props} from '@ngrx/store';
import {Comment} from '../models';

export const LoadDataComment = createAction(
  '[Comment] Load data',
  props<{ownerId: string; ownerType: string}>(),
);

export const LoadDataCommentSuccess = createAction(
  '[Comment] Load data Success',
  props<{ownerId: string; ownerType: string; comments: Comment[]}>(),
);

export const LoadDataCommentError = createAction(
  '[Comment] Load data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const SaveDataComment = createAction(
  '[Comment] Save data',
  props<{
    ownerId: string;
    ownerType: string;
    comment: Comment;
    noSuccessToast?: boolean;
  }>(),
);

export const SaveDataCommentSuccess = createAction(
  '[Comment] Save data Success',
  props<{ownerId: string; ownerType: string; comment: Comment}>(),
);

export const SaveDataCommentError = createAction(
  '[Comment] Save data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const DeleteDataComment = createAction(
  '[Comment] Delete data',
  props<{ownerId: string; ownerType: string; comment: Comment}>(),
);

export const DeleteDataCommentSuccess = createAction(
  '[Comment] Delete data Success',
  props<{ownerId: string; ownerType: string; commentId: string}>(),
);

export const DeleteDataCommentError = createAction(
  '[Comment] Delete data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const EditDataComment = createAction(
  '[Comment] Edit data',
  props<{
    ownerId: string;
    ownerType: string;
    comment: Comment;
    openEdit: boolean;
  }>(),
);

export const EditDataCommentText = createAction(
  '[Comment] Edit comment text',
  props<{
    ownerId: string;
    ownerType: string;
    comment: Comment;
    commentText: string;
  }>(),
);

export const CollapsedComment = createAction(
  '[Comment] Collapsed comment',
  props<{ownerId: string; ownerType: string; comment: Comment}>(),
);

export const CollapsedAllComments = createAction(
  '[Comment] Collapsed all comments',
  props<{ownerId: string; ownerType: string; collapsed: boolean}>(),
);

export const SetFilterComment = createAction(
  '[Comment] Set filter comment',
  props<{ownerId: string; ownerType: string; value: string}>(),
);
