import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteRegister,
  DeleteRegisterError,
  DeleteRegisterSuccess,
  DiffRegister,
  DiffRegisterError,
  DiffRegisterSuccess,
  LoadRegister,
  LoadRegisterByCodeSuccess,
  LoadRegisterByIdSuccess,
  LoadRegisterError,
  LoadRegisterSuccess,
  UpsertRegister,
  UpsertRegisterError,
  UpsertRegisterSuccess,
} from '../actions';
import {Register} from '../models';
import {addDays} from 'date-fns';

export type RegisterState = EntityState<IdentifiedPack<Register>>;

export const registerAdapter: EntityAdapter<IdentifiedPack<Register>> =
  createEntityAdapter<IdentifiedPack<Register>>({});
export const registerInitialState: RegisterState =
  registerAdapter.getInitialState({});

export const registerReducer = createReducer(
  registerInitialState,

  on(LoadRegister, UpsertRegister, DeleteRegister, (state, {}) => ({
    ...state,
    loading: true,
  })),

  on(LoadRegisterSuccess, (state, {entities}) => ({
    ...registerAdapter.upsertMany(
      entities.map((statusOnly) => ({
        id: statusOnly.id,
        data: statusOnly,
        loading: false,
        error: null,
        validUntil: addDays(new Date(), 1),
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(LoadRegisterByIdSuccess, LoadRegisterByCodeSuccess, (state, {entity}) =>
    registerAdapter.upsertOne(
      {
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteRegisterSuccess, (state, {id}) =>
    registerAdapter.removeOne(id, state),
  ),

  on(UpsertRegisterSuccess, (state, {register}) =>
    registerAdapter.upsertOne(
      {
        id: register.id,
        data: register,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(
    LoadRegisterError,
    UpsertRegisterError,
    DeleteRegisterError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(DiffRegister, (state, {diffEntities}) =>
    registerAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffRegisterSuccess, (state, {registers}) =>
    registerAdapter.upsertMany(
      registers.map((register) => {
        return {
          id: register.id,
          data: register,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffRegisterError, (state, {diffEntities, error}) =>
    registerAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
