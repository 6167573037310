import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as Reducers from './reducers';
import * as Effects from './effects';
import * as Pipes from './pipes';
import * as Services from './services';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';

export const PIPES = [
  Pipes.CharacteristicsCodePipe,
  Pipes.CharacteristicsByCodesPipe,
  Pipes.CharacteristicsPipe,
];

export const IMPORTS = [
  StoreModule.forFeature('characteristics', Reducers.reducers),
  EffectsModule.forFeature([
    Effects.CharacteristicsEffects,
    Effects.CharacteristicsValuesEffects,
  ]),
  TranslateModule.forFeature(localizations),
];

export const PROVIDERS = [Services.CommonApiService];
