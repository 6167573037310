import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';

export const controlsRelatedEntityPlaceObjectsDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-ticket-related-entity-place-objects-detail.detail',
    name: 'Ticket related entity place objects detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'TicketRelatedEntityPlaceObjects',
    tsmControls: {},
  };

export const controlsRelatedEntityPlaceLocalitiesDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-ticket-related-entity-place-localities-detail.detail',
    name: 'Ticket related entity place localities detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'TicketRelatedEntityLocalities',
    tsmControls: {},
  };

export const controlsWorklogLocalityTopbarConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-worklog-locality.topbar',
    name: 'Ticket worklog lokality topbar',
    description: '',
    useType: 'CONFIG',
    entityType: 'TicketRelatedEntityLocalities', //??
    tsmControls: {},
  };
