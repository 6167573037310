import {FilterModel} from '@tsm/listing-lib/service';
import {createAction, props} from '@ngrx/store';
import {Worklog} from '../models';

export const LoadAllMyWorklog = createAction(
  '[MyWorklog] Load data',
  props<{userId: string}>(),
);
export const LoadAllMyWorklogSuccess = createAction(
  '[MyWorklog] Load data Success',
  props<{
    userId: string;
    worklogs: Worklog[];
  }>(),
);
export const LoadAllMyWorklogError = createAction(
  '[MyWorklog] Load data ERROR',
  props<{
    userId: string;
    error: any;
  }>(),
);

export const LoadMyWorklogByFilter = createAction(
  '[MyWorklog] Load data By Filter',
  props<{
    userId: string;
    page: number;
    size: number;
    filters?: FilterModel[];
  }>(),
);
export const LoadMyWorklogByFilterSuccess = createAction(
  '[MyWorklog] Load data By Filter Success',
  props<{
    userId: string;
    worklogs: Worklog[];
  }>(),
);
export const LoadMyWorklogByFilterError = createAction(
  '[MyWorklog] Load data By Filter ERROR',
  props<{
    userId: string;
    error: any;
  }>(),
);
export const ClearMyWorklogByFilter = createAction(
  '[MyWorklog] Clear data By Filter',
  props<{userId: string}>(),
);

export const UpsertMyWorklog = createAction(
  '[MyWorklog] Upsert data',
  props<{worklog: Worklog; userId: string}>(),
);
export const UpsertMyWorklogSuccess = createAction(
  '[MyWorklog] Upsert data Success',
  props<{
    worklog: Worklog;
    userId: string;
  }>(),
);
export const UpsertMyWorklogError = createAction(
  '[MyWorklog] Upsert data Error',
  props<{error: any}>(),
);

export const DeleteMyWorklog = createAction(
  '[MyWorklog] Delete data',
  props<{id: string; userId: string}>(),
);
export const DeleteMyWorklogSuccess = createAction(
  '[MyWorklog] Delete data Success',
  props<{
    id: string;
    userId: string;
  }>(),
);
export const DeleteMyWorklogError = createAction(
  '[MyWorklog] Delete data Error',
  props<{id: string; error: any}>(),
);
