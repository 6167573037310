import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  CollapsedAllComments,
  CollapsedComment,
  DeleteDataComment,
  EditDataComment,
  EditDataCommentText,
  LoadDataComment,
  LoadDataCommentSuccess,
  SaveDataComment,
} from '../actions';
import {Comment} from '../models';
import {CommentState} from '../reducers';
import {Config, ConfigService} from '@tsm/framework/config';
import {UserPipe} from '@tsm/user-management/service';
import {RuntimeService} from '@tsm/runtime-info';
import {translation as translationShared} from '@tsm/shared-i18n';
import {DateFormatPipe} from '@tsm/shared';
import {
  FilterModel,
  FilterOperator,
  ListingProfile,
  ListingService,
  Table,
  TableColumn,
  TableDefault,
  TableShowConfig,
  TableType,
} from '@tsm/listing-lib/service';
import {filter, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {translation} from '../i18n';
import {getUserId} from '@tsm/framework/functions';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private readonly BASE_URL: string;

  translationShared = translationShared;
  translation = translation;

  private defaults: TableDefault = {
    pageSize: 50,
    columns: [
      // {
      //   field: 'id',
      //   header: translationShared.shared.id,
      //   tooltip: translationShared.shared.id,
      //   visible: false,
      //   filterWidget: import(
      //   '@tsm/listing-lib/widgets/filters/dtl-filter-input'
      // ).then((x) => x.DtlFilterInputComponent)
      // },
      {
        field: 'userId',
        header: translationShared.shared.user,
        tooltip: translationShared.shared.user,
        filterWidget: import(
          '@tsm/user-management/widgets/filters/user-filter'
        ).then((x) => x.UserFilterComponent),
        converter: UserPipe,
        width: '100px',
        visible: false,
        visibleCard: false,
      },
      {
        field: 'userGroupId',
        header: translationShared.shared.userGroup,
        tooltip: translationShared.shared.userGroup,
        filterWidget: import(
          '@tsm/user-management/widgets/filters/user-group-filter'
        ).then((x) => x.UserGroupFilterComponent),
        filterWidgetContext: {field: 'id', hideUnassigned: true},
        sortDisabled: true,
        exportDisabled: true,
        width: '100px',
        visible: false,
        visibleCard: false,
      },
      {
        field: 'whenInserted',
        header: translationShared.shared.createDate,
        tooltip: translationShared.shared.createDate,
        width: '200px',
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-calendar-range'
        ).then((x) => x.DtlFilterCalendarRangeComponent),
        converter: DateFormatPipe,
        converterParams: ['dateTimeS'],
        visible: false,
        visibleCard: false,
      },
      {
        field: 'comment',
        header: translation.comment,
        tooltip: translation.comment,
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-input'
        ).then((x) => x.DtlFilterInputComponent),
        filterFulltextSearch: true,
        width: '100px',
        visible: false,
        visibleCard: false,
      },
      {
        field: 'private',
        header: translationShared.shared.privateComment,
        tooltip: translationShared.shared.privateComment,
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-boolean'
        ).then((x) => x.DtlFilterBooleanComponent),
        width: '100px',
        visible: false,
        visibleCard: false,
      },
    ],
  };

  constructor(
    private store: Store<CommentState>,
    private runtimeService: RuntimeService,
    private listingService: ListingService,
    private config: ConfigService<Config>,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  loadCommentByOwnerIdAndOwnerType(
    listingId: string,
    ownerId: string,
    ownerType: string,
    listingTypeCode: string,
    profileId: string,
    showConfig: TableShowConfig,
    defaultFilters: FilterModel[],
  ): Observable<Table> {
    this.store.dispatch(LoadDataComment({ownerId, ownerType}));
    return this.listingService
      .getTable(
        'tsm-comment-' + (listingId || ownerId),
        {
          type: listingTypeCode,
          url: this.config.value.apiUrls.dms + '/comment/filtering',
        },
        {
          ...this.defaults,
          profileId,
          filters: [
            {
              field: 'ownerId',
              operator: FilterOperator.eq,
              value: ownerId,
              visible: false,
              readonly: true,
            },
            {
              field: 'ownerType',
              operator: FilterOperator.eq,
              value: ownerType,
              visible: false,
              readonly: true,
            },
            ...defaultFilters,
          ],
        },
        showConfig,
      )
      .pipe(
        filter((x) => x.dataLoading === false && x.profilesLoading === false),
        tap((tableState) => {
          this.store.dispatch(
            LoadDataCommentSuccess({
              ownerType: ownerType,
              ownerId: ownerId,
              comments: tableState.data.items as Array<Comment>,
            }),
          );
          // this.store.dispatch(LoadDataCommentError({
          //   ownerType: ownerType,
          //   ownerId: ownerId,
          //   error: 'Chyba pri nacteni dat!'
          // }));
        }),
      );
  }

  // funkce na zaklade commentTypeCodes upravi context filtru CommentType za predpokladu, ze je nadefinovany jako dynam. sloupecek
  updateCommentTypeColumn(
    ownerId: string,
    commentTypeCodes: string[],
    listingId?: string,
  ) {
    this.listingService
      .getTableState('tsm-comment-' + (listingId || ownerId))
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((table) => {
        const col = table.columns.find(
          (c) =>
            c.field == 'commentType.name' ||
            c.displayField == 'commentType.name',
        );
        if (col != null) {
          this.listingService.changeColumn(
            'tsm-comment-' + (listingId || ownerId),
            {
              ...col,
              filterField: 'commentType.id',
              filterWidgetContext: commentTypeCodes,
            },
          );
        }
      });
  }

  saveComment(
    ownerId: string,
    ownerType: string,
    comment: Comment,
    noSuccessToast?: boolean,
  ) {
    this.store.dispatch(
      SaveDataComment({ownerId: ownerId, ownerType, comment, noSuccessToast}),
    );
  }

  deleteComment(ownerId: string, ownerType: string, comment: Comment) {
    this.store.dispatch(
      DeleteDataComment({ownerId: ownerId, ownerType, comment}),
    );
  }

  editComment(ownerId: string, ownerType: string, comment: Comment) {
    this.store.dispatch(
      EditDataComment({
        ownerId: ownerId,
        ownerType: ownerType,
        comment: comment,
        openEdit: !comment.peditorVisibility,
      }),
    );
  }

  editCommentText(
    ownerId: string,
    ownerType: string,
    event: {comment: Comment; commentText: string},
  ) {
    this.store.dispatch(
      EditDataCommentText({
        ownerId: ownerId,
        ownerType: ownerType,
        comment: event.comment,
        commentText: event.commentText,
      }),
    );
  }

  collapsedComment(ownerId: string, ownerType: string, comment: Comment) {
    this.store.dispatch(
      CollapsedComment({
        ownerId: ownerId,
        ownerType: ownerType,
        comment: comment,
      }),
    );
  }

  collapsedAllComments(ownerId: string, ownerType: string, collapsed: boolean) {
    this.store.dispatch(
      CollapsedAllComments({
        ownerId: ownerId,
        ownerType: ownerType,
        collapsed: collapsed,
      }),
    );
  }

  filterValueChanged(
    ownerId: string,
    ownerType: string,
    value: FilterModel[],
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      true,
    );
    this.listingService.setFilters(
      'tsm-comment-' + (listingId || ownerId),
      value,
    );
  }

  selectedProfileIdChanged(
    ownerId: string,
    ownerType: string,
    state: {
      profileId: string;
      showConfig: TableShowConfig;
    },
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.setSelectedProfile(
      'tsm-comment-' + (listingId || ownerId),
      state.profileId,
      state.showConfig,
    );
  }

  saveProfile(
    ownerId: string,
    ownerType: string,
    state: {
      profile: ListingProfile;
      type: TableType;
    },
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.saveProfile(
      'tsm-comment-' + (listingId || ownerId),
      state.profile,
      state.type,
    );
  }

  deleteProfile(
    ownerId: string,
    ownerType: string,
    state: {
      profile: ListingProfile;
      type: TableType;
    },
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.deleteProfile(
      'tsm-comment-' + (listingId || ownerId),
      state.profile,
      state.type,
    );
  }

  clearProfile(
    ownerId: string,
    ownerType: string,
    showConfig: TableShowConfig,
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.clearProfile(
      'tsm-comment-' + (listingId || ownerId),
      showConfig,
    );
  }

  resetData(
    ownerId: string,
    ownerType: string,
    value?: any,
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.resetData('tsm-comment-' + (listingId || ownerId));
  }

  refreshComments(
    ownerId: string,
    ownerType: string,
    value?: any,
    listingId?: string,
  ) {
    this.listingService.refreshData('tsm-comment-' + (listingId || ownerId));
  }

  saveAsDefaultProfile(
    ownerId: string,
    ownerType: string,
    state: {
      profile: ListingProfile;
      type: TableType;
    },
    listingId?: string,
  ) {
    const entity = {
      ...state.profile,
    };
    if (!entity.userId) {
      entity.userId = getUserId();
    }
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.saveAsDefaultProfile(
      'tsm-comment-' + (listingId || ownerId),
      entity,
      state.type,
      false,
    );
  }

  saveAsDefaultAllProfile(
    ownerId: string,
    ownerType: string,
    state: {
      profile: ListingProfile;
      type: TableType;
    },
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      false,
    );
    this.listingService.saveAsDefaultAllProfile(
      'tsm-comment-' + (listingId || ownerId),
      state.profile,
      state.type,
      false,
    );
  }

  pageSize(
    ownerId: string,
    ownerType: string,
    pageSize: number,
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      true,
    );
    this.listingService.setPageSize(
      'tsm-comment-' + (listingId || ownerId),
      pageSize,
    );
  }

  setColumns(
    ownerId: string,
    ownerType: string,
    columns: TableColumn[],
    listingId?: string,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-comment-' + (listingId || ownerId),
      true,
    );
    this.listingService.setColumns(
      'tsm-comment-' + (listingId || ownerId),
      columns,
    );
  }

  // zatim se nepouziva, nejspis ale bude NEMAZAT!
  // commentsViewTypeChanged(ownerId: string, ownerType: string, value: boolean) {
  //   this.store.dispatch(UpsertUserParameterByUserIdAndName({
  //     name: UserParameterName.COMMENT_VIEW_TYPE,
  //     userId: getUserId()d,
  //     value: value === true ? 'table' : 'classic'
  //   }));
  // }
}
