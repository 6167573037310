import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslocoService} from '@tsm/framework/translate';
import {ConfirmationService} from 'primeng/api';
import {translation} from '@tsm/shared-i18n';
import {LocalStateService} from '@tsm/framework/local-state';
import {UserParameterName} from '@tsm/user-parameters';

@Component({
  selector: 'tsm-button-close',
  template: `
    <button
      pButton
      pRipple
      type="button"
      (click)="onClick($event)"
      [label]="label ? label : [translationShared.shared.close | transloco]"
      [class]="styleClass ? styleClass : 'p-button-link'"
      [disabled]="disabled"
      [icon]="iconClass ? iconClass : ''"
      data-cy="button-close"
    ></button>
    <p-confirmPopup></p-confirmPopup>
  `,
})
export class ButtonCloseComponent {
  translationShared = translation;

  /**
   * Label pro změnu názvu tlačítka
   */
  @Input() label: any;

  /**
   * Css třída pro změnu stylu tlačítka
   */
  @Input() styleClass = '';

  // @Input() icon = 'pi pi-times';

  /**
   * Css třída pro změnu ikony tlačítka
   */
  @Input() iconClass = 'pi pi-times';

  /**
   * Zda klik potvrzovat
   */
  confirmCloseButton = false;

  @Input() disabled = false;

  @Output() click = new EventEmitter<void>();

  constructor(
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
    private localStateService: LocalStateService,
  ) {
    this.confirmCloseButton =
      localStateService.getStateInstant(
        UserParameterName.CONFIRM_CLOSE_BUTTON,
      ) === 'true';
  }

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.confirmCloseButton == false) {
      this.click.emit(e);
    } else {
      this.confirmationService.confirm({
        target: e.target,
        icon: 'p-icon-warning p-color-warning',
        acceptLabel: `${this.translocoService.translate(
          this.translationShared.shared.yes,
        )}`,
        rejectLabel: `${this.translocoService.translate(
          this.translationShared.shared.no,
        )}`,
        message: this.translocoService.translate(
          this.translationShared.shared.confirmCloseWindow,
        ),
        accept: () => {
          this.click.emit(e);
        },
      });
    }
  }
}
