import {Plugin} from '@tsm/framework/plugin';
import {translation as translationPlugin} from '@tsm/wfm-planner/plugins-i18n';

export const wfmPlannerPlugin: Plugin = {
  menus: [
    // presunuto do orderingu
    // {
    //   key: 'wfm',
    //   priority: 2100,
    //   label: 'WFM',
    //   icon: null,
    //   privilege: ['true'],
    //   items: [
    //     {
    //       key: 'wfm-planner',
    //       priority: 2100,
    //       label: 'Plánovač',
    //       icon: null,
    //       routerLink: ['/wfm-planner/planner'],
    //       privilege: ['true']
    //     }, {
    //       key: 'wfm-map',
    //       priority: 2100,
    //       label: 'Mapa tras',
    //       icon: null,
    //       routerLink: ['/wfm-planner/map'],
    //       privilege: ['true']
    //     }
    //   ]
    // }
  ],
  routes: [
    {
      path: 'wfm-planner',
      loadChildren: () =>
        import('@tsm/wfm-planner/components').then(
          (x) => x.WfmPlannerComponentsModule,
        ),
    },
  ],
  widgets: [],
  filters: [
    {
      definitions: [
        {
          loadComponent: () =>
            import(
              '@tsm/wfm-planner/widgets/filters/task-current-state-filter'
            ).then((x) => x.TaskCurrentStateFilterComponent),
          selector: 'tsm-task-current-state-filter',
          name: translationPlugin.wfmPlannerPlugins.filters
            .tsmTaskCurrentStateFilter,
          description:
            translationPlugin.wfmPlannerPlugins.filters
              .tsmTaskCurrentStateFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/wfm-planner/widgets/filters/task-hard-rule-filter'
            ).then((x) => x.TaskHardRuleFilterComponent),
          selector: 'tsm-task-hard-rule-filter',
          name: translationPlugin.wfmPlannerPlugins.filters
            .tsmTaskHardRuleFilter,
          description:
            translationPlugin.wfmPlannerPlugins.filters
              .tsmTaskHardRuleFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/wfm-planner/widgets/filters/task-partition-filter'
            ).then((x) => x.TaskPartitionFilterComponent),
          selector: 'tsm-task-partition-filter',
          name: translationPlugin.wfmPlannerPlugins.filters
            .tsmTaskPartitionFilter,
          description:
            translationPlugin.wfmPlannerPlugins.filters
              .tsmTaskPartitionFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/wfm-planner/widgets').then(
          (x) => x.WfmPlannerWidgetsModule,
        ),
      definitions: [
        {
          selector: 'formatTimeType',
          name: translationPlugin.wfmPlannerPlugins.pipes.formatTimeType,
          description:
            translationPlugin.wfmPlannerPlugins.pipes.formatTimeTypeDescription,
        },
      ],
    },
  ],
};
