import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

type dateFormatPipeType =
  | ''
  | 'dateTime'
  | 'dateTimeS'
  | 'dateTimeMs'
  | 'date'
  | 'time';

@Pipe({
  name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, type?: dateFormatPipeType): any {
    if (value != null) {
      if (type) {
        switch (type) {
          case 'dateTime': {
            return this.datePipe.transform(value, 'dd.MM.yyyy HH:mm');
          }
          case 'dateTimeS': {
            return this.datePipe.transform(value, 'dd.MM.yyyy HH:mm:ss');
          }
          case 'dateTimeMs': {
            return this.datePipe.transform(value, 'dd.MM.yyyy HH:mm:ss.SSS');
          }
          case 'date': {
            return this.datePipe.transform(value, 'dd.MM.yyyy');
          }
          case 'time': {
            return this.datePipe.transform(value, 'HH:mm');
          }
          default: {
            return value;
          }
        }
      }
      return value;
    }
  }
}
