import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Optional,
  Output,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgControl, FormsModule} from '@angular/forms';
import {
  MonacoEditorConstructionOptions,
  MonacoStandaloneCodeEditor,
} from '@tsm/framework/monaco-editor';
import {TsmAbstractControl} from '@tsm/framework/forms';
import {
  ParentWidgetAccessorComponent,
  useParentWidgetProvidersFor,
} from '@tsm/framework/parent-widget';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {DtlMonacoEditorModule} from '@tsm/framework/monaco-editor';

@Component({
  selector: 'dtl-form-input-monaco',
  templateUrl: './form-input-monaco.component.html',
  host: {
    class: 'p-state-filled-fix p-inputtext',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputMonacoComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputMonacoComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DtlMonacoEditorModule, FormsModule],
})
export class FormInputMonacoComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @Input() updateIfDirty = false;

  @Input() jsonObject = false;

  @Input() language: string;

  @Input() widgetHeight: any;

  @Input() inline = false;

  @Input() resizable = true;

  @Output() init: EventEmitter<MonacoStandaloneCodeEditor> = new EventEmitter();

  public ngControl;

  private _editorOptions: MonacoEditorConstructionOptions;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  get editorOptions(): MonacoEditorConstructionOptions {
    return {
      // manual settings
      fontSize: 11,
      minimap: {
        enabled: false,
      },
      // taken from inputs
      readOnly: this.readonly,
      tabIndex: this.tabindex,
      ...this._editorOptions,
    };
  }

  @Input()
  set editorOptions(value: MonacoEditorConstructionOptions) {
    this._editorOptions = value;
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.ngControl = ngControl.control as TsmAbstractControl;
      });
    }
  }

  onEditorInitialized(editor: MonacoStandaloneCodeEditor) {
    editor.onDidFocusEditorWidget(() => {
      this.focused = true;
      this.cdr.markForCheck();
    });

    editor.onDidBlurEditorWidget(() => {
      this.focused = false;
      this.cdr.markForCheck();
    });

    this.init.emit(editor);
  }

  stringifyVal(val: any): string {
    // do not serialize empty input
    if (typeof val === 'string') {
      return val;
    }
    if (val === null || val === undefined) {
      return '';
    }

    try {
      return JSON.stringify(this.val, null, 4);
    } catch (e) {
      if (e instanceof TypeError) {
        // pass
      } else {
        throw e;
      }
      return '';
    }
  }

  destringifyVal(value: string) {
    try {
      // do not attempt to parse empty string
      if (!value || value.trim() === '') {
        return null;
      } else {
        return JSON.parse(value);
      }
      this.clearFormError('$customErrorCode-parser');
    } catch (e) {
      // TODO: Localize ?
      this.setFormError(
        '$customErrorCode-parser',
        'Unable to parse object from given JSON: ' + e.message,
      );
    }
  }

  modelChanges(event) {
    this.val = event;
  }

  private clearFormError(key: string): void {
    if (!this.ngControl) {
      return;
    }

    const errorsCopy = {...this.ngControl.errors};

    if (errorsCopy.hasOwnProperty(key)) {
      delete errorsCopy[key];
    }

    if (Object.keys(errorsCopy).length === 0) {
      this.ngControl.setErrors(null);
    } else {
      this.ngControl.setErrors(errorsCopy);
    }
  }

  private setFormError(key: string, value: string): void {
    if (!this.ngControl) {
      return;
    }

    const errorsCopy = {...this.ngControl.errors};
    errorsCopy[key] = value;
    this.ngControl.setErrors(errorsCopy);
  }
}
