import {createAction, props} from '@ngrx/store';

export const InitUpdate = createAction('[Core] InitUpdate');

export const ShowUpdateOverlay = createAction('[Core] ShowUpdateOverlay');

export const HideUpdateOverlay = createAction('[Core] HideUpdateOverlay');

export const SetUpdateOverlayStepInstalled = createAction(
  '[Core] SetUpdateOverlayStepInstalled',
);

export const TriggerUpdateMessageChecking = createAction(
  '[Core] TriggerUpdateMessageChecking',
);

export const ShowUpdateMessage = createAction('[Core] ShowUpdateMessage');

export const HideUpdateMessage = createAction('[Core] HideUpdateMessage');
