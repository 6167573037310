<input
  #inputElement
  type="url"
  [(ngModel)]="val"
  [tabindex]="tabindex"
  [attr.id]="inputId ? inputId : null"
  class="p-inputtext p-state-default"
  [style]="style"
  [ngStyle]="{display: readonly ? 'none' : null}"
  data-cy="dtl-form-input-link"
  [placeholder]="
    localizationData
      | localizationDataTranslate: 'widget.placeholder' : placeholder
  "
/>
<a
  *ngIf="val && noHref == false"
  #el
  href="{{ val }}"
  class="readonly-text text-overflow-ellipsis"
  rel="noopener noreferrer"
  [attr.target]="target ? '_blank' : null"
  [ngStyle]="{display: !readonly ? 'none' : 'block'}"
>
  <span
    [tsmAutoSizeTooltip]="
      localizationData
        | localizationDataTranslate: 'config.customTitle' : customTitle ?? val
    "
  >
    {{
      localizationData
        | localizationDataTranslate: 'config.customTitle' : customTitle ?? val
    }}</span
  >
</a>
<span
  *ngIf="val && noHref == true"
  class="readonly-text text-overflow-ellipsis"
  [ngStyle]="{display: !readonly ? 'none' : 'block'}"
>
  {{
    localizationData
      | localizationDataTranslate: 'config.customTitle' : customTitle ?? val
  }}
</span>
<span
  *ngIf="!val"
  class="readonly-text empty"
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
>
  {{ noneText }}
</span>
