<tsm-monaco-diff-editor
  [swap]="swap"
  [original]="original"
  [ngModel]="stringifyVal(val)"
  (ngModelChange)="
    !jsonObject
      ? modelChanges(stringifyVal($event))
      : modelChanges(destringifyVal($event))
  "
  [defaultHeight]="widgetHeight ? widgetHeight : inline ? '1.5em' : null"
  [options]="editorOptions"
  [resizable]="resizable"
  (init)="onEditorInitialized($event)"
  data-cy="dtl-form-input-sql"
></tsm-monaco-diff-editor>
