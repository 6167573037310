export const cs = {
  inventoryPlugins: {
    catalogSpecification: 'Položka nákladu',
    inventoryChange: 'Změna produktu nebo služby',
    createTicket: 'Založit tiket',
    filters: {
      tsmEntityActiveFilter: 'tSM Entity Active Filter',
      tsmEntityActiveFilterDescription:
        'Filtruje instance entit podle jejich aktivního stavu. ' +
        'Zobrazuje tabulku s možností výběru aktivních stavů. ' +
        'Dynamicky načítá dostupné stavy a aplikuje filtr pomocí operátoru "in". ' +
        'Podporuje sledování výběru, hromadný výběr a interakce v překryvném panelu. ' +
        'Umožňuje uživatelům potvrdit nebo zrušit výběr.',
      tsmEntityInstanceConfigurationFilter:
        'tSM Entity Instance Configuration Filter',
      tsmEntityInstanceConfigurationFilterDescription:
        'Filtruje konfigurace instancí entit pomocí tabulky s multi-výběrem. ' +
        'Načítá konfigurace z API a umožňuje výběr podle ID nebo klíče. ' +
        'Podporuje vyhledávání, řazení podle kódu a odebrání výběrů. ' +
        'Používá překryvný panel s potvrzením a zrušením výběru. ' +
        'Aplikuje vybrané hodnoty jako filtrační kritéria s operátorem "in".',
      tsmEntityInstanceConfigurationStatusFilter:
        'tSM Entity Instance Configuration Status Filter',
      tsmEntityInstanceConfigurationStatusFilterDescription:
        'Filtruje konfigurace instancí entit podle stavu. ' +
        'Zobrazuje tabulku s možností vícenásobného výběru. ' +
        'Dynamicky načítá dostupné stavy ze store. ' +
        'Podporuje filtrování pomocí vyhledávacího vstupu a sledování výběru. ' +
        'Aplikuje řazení a stránkování pro efektivní správu dat. ' +
        'Umožňuje uživatelům potvrdit nebo zrušit výběr.',
      tsmIpAddressFilter: 'tSM Ip Address Filter',
      tsmIpAddressFilterDescription:
        'Filtruje IP adresy s konfigurovatelnými porovnávacími operátory. ' +
        'Zobrazuje vstupní pole pro zadání IP adres. ' +
        'Podporuje filtrování podle rovnosti a nerovnosti. ' +
        'Ověřuje formát IP adresy před použitím filtru. ' +
        'Umožňuje uživatelům potvrdit nebo zrušit výběr. ' +
        'Dynamicky zpracovává různé kontexty operátorů.',
    },
    pipes: {
      entityInstanceConfigurationKey: 'Entity Instance Configuration Key',
      entityInstanceConfigurationKeyDescription:
        'Načítá a formátuje data konfigurace instance entity na základě zadaného klíče a pole. ' +
        'Získává zadané pole nebo celý datový objekt pomocí kešované observablu. Zajišťuje, ' +
        'že data jsou načtena z úložiště, pokud ještě nejsou dostupná.',
      entityInstanceConfigurationStatusCode:
        'Entity Instance Configuration Status Code',
      entityInstanceConfigurationStatusCodeDescription:
        'Načítá a formátuje stav konfigurací instance entity podle kódu. Získává konkrétní pole nebo ' +
        'celkový stav konfigurace pomocí kešované observablu. Automaticky provádí akci načtení, ' +
        'pokud stav není nalezen v úložišti.',
      entityInstanceConfigurationStatus: 'Entity Instance Configuration Status',
      entityInstanceConfigurationStatusDescription:
        'Získává a zobrazuje stav konfigurace instance entity podle ID. Umožňuje vybrat konkrétní pole ' +
        'nebo načíst celý stav. Spouští akci načtení, pokud nejsou data dostupná v úložišti.',
      entityInstanceConfiguration: 'Entity Instance Configuration',
      entityInstanceConfigurationDescription:
        'Načítá a zobrazuje data konfigurace instance entity podle ID. Podporuje získání konkrétních polí ' +
        'nebo celého objektu konfigurace. Automaticky spustí akci načtení, pokud data nejsou dostupná ' +
        'v úložišti.',
    },
    entityInstanceLabels: 'Štítky pro Entity Instance',
  },
};
