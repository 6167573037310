import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import {TsmFormControl} from '@tsm/framework/forms';
import {translation} from '@tsm/shared-i18n';
import {Terminator} from '@tsm/framework/terminator';
import {createClickableBackdropElement} from '../../../utils';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'tsm-form-inplace-field',
  template: `
    <div
      [class.p-state-editmode]="!context.readonly"
      [class.p-state-readonly]="readonly"
      (click)="onEdit($event)"
    >
      <div
        class="tsm-form-inplace-field-edit-icon"
        data-cy="crm-item-detail-details-panel"
      >
        <i
          *ngIf="context.readonly && !readonly"
          class="pi pi-pencil"
          title="{{
            translationShared.shared.sharedComp.editBlock | transloco
          }}"
          (click)="onEdit($event)"
        ></i>
      </div>
      <ng-container
        *ngTemplateOutlet="template; context: context"
      ></ng-container>
      <dtl-form-field-errors
        [errors]="formControlInplace?.errors"
        [touched]="formControlInplace?.touched"
        style="position: absolute;margin-top: 5px;right: 0;  left: 100%; margin-left: 5px;"
        [excelStyleErrors]="
          excelStyleErrors == null
            ? excelStyleErrorsAppParams
            : excelStyleErrors
        "
      >
      </dtl-form-field-errors>
      <div
        *ngIf="!context.readonly && instantSave != true"
        class="tsm-form-inplace-field-action-buttons text-right"
      >
        <i
          class="p-icon-done"
          pTooltip="{{ translationShared.shared.save | transloco }}"
          tooltipPosition="bottom"
          (click)="onSave($event)"
        ></i>
        <i
          class="p-icon-close"
          pTooltip="{{ translationShared.shared.cancel | transloco }}"
          tooltipPosition="bottom"
          (click)="onCancel($event)"
        ></i>
      </div>
    </div>
  `,
  styleUrls: ['./form-inplace-field.component.scss'],
  providers: [Terminator],
})
export class FormInplaceFieldComponent implements OnInit {
  translationShared = translation;

  @HostBinding('class')
  get styleClass(): string {
    if (this.context.readonly) {
      return 'p-state-readonly' + ' ' + this.class;
    } else {
      return 'p-state-editmode' + ' ' + this.class;
    }
  }

  @ContentChild(TemplateRef, {read: TemplateRef}) contentedTemplate;

  @Input() formControlInplace: TsmFormControl;

  @Input() readonly = false;

  /**
   * Hromadne přepíná stylu a pozice zobrazení labelu
   */
  @Input() labelPosition: 'left' | 'top' | 'right' = 'left';

  @Input() instantSave = false;

  /**
   * Atribut pro připojení rozšiřující CSS třídy
   */
  @Input() class = '';

  @Input() excelStyleErrors = null;
  excelStyleErrorsAppParams = (window as any)?.app?.params?.excelStyleErrors;

  @Output()
  save: EventEmitter<any> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  context = {
    $implicit: null,
    readonly: true,
  };

  defaultValue: any = undefined;

  private canOnEdit = true;

  _backdrop?: HTMLElement;
  _originalIndex?: string;
  _originalPosition?: string;

  get template(): TemplateRef<any> {
    return this.contentedTemplate;
  }

  constructor(
    private terminator: Terminator,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    public renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    if (this.instantSave) {
      this.formControlInplace.valueChanges
        .pipe(distinctUntilChanged())
        .pipe(takeUntil(this.terminator))
        .subscribe((x) => {
          if (this.formControlInplace.touched) {
            this.onSave(null);
          }
        });
    }
  }

  onEdit(event) {
    event.stopImmediatePropagation();
    if (this.readonly == false && this.canOnEdit === true) {
      this.defaultValue = this.formControlInplace.value;
      this.context = {...this.context, readonly: false};
      this.canOnEdit = false;

      const htmlElement = this.elementRef.nativeElement as HTMLElement;
      const parent = this.elementRef.nativeElement.parentElement as HTMLElement;

      this._originalIndex = htmlElement.style.zIndex;
      this._originalPosition = htmlElement.style.position;
      htmlElement.style.zIndex = '998';
      htmlElement.style.position = 'relative';
      this._backdrop = createClickableBackdropElement(
        () => {
          // zavola se jenom pro kliku ven
          if (this.instantSave && this.formControlInplace.touched) {
            this.onSave(null);
          }
        },
        {
          stopPropagation: false,
        },
      );
      parent.appendChild(this._backdrop);
    }
  }

  onSave(event) {
    event?.stopImmediatePropagation();
    if (this.formControlInplace.valid) {
      this.context = {...this.context, readonly: true};
      this.canOnEdit = true;
      if (this.defaultValue !== this.formControlInplace.value) {
        this.save.emit(this.formControlInplace.value);
      }
      this.removeBackdrop();
    } else {
      this.formControlInplace.markAsTouchedRecursively();
    }
  }

  onCancel(event) {
    event?.stopImmediatePropagation();
    if (this.defaultValue !== undefined) {
      this.formControlInplace.reset(this.defaultValue, {emitEvent: false});
    }
    this.context = {...this.context, readonly: true};
    this.formControlInplace.markAsUntouchedRecursively();
    this.cancel.emit();
    this.canOnEdit = true;
    this.removeBackdrop();
  }

  private removeBackdrop() {
    if (this._backdrop) {
      const parent = this.elementRef.nativeElement.parentElement as HTMLElement;
      const childInplaceCell = parent.firstElementChild as HTMLElement;
      parent.removeChild(this._backdrop);
      childInplaceCell.style.zIndex = this._originalIndex;
      childInplaceCell.style.position = this._originalPosition;
    }
  }
}
