import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';

import {
  AddMenuItemAction,
  ChangeLanguageMenuItemsAction,
  CloseMenuAction,
  DeleteMenuItemAction,
  DeleteMenuItemsByIdInUrl,
  LoadMenuItemsAction,
  LoadMenuItemsActionError,
  LoadMenuItemsActionSuccess,
  OpenMenuAction,
  ReorderBookmarkMenuItemsAction,
  SetMenuAction,
  ToggleBookmarkMenuItemAction,
  UpdateMenuItemAction,
  UpdateMenuItemsByIdInUrl,
  UpsertMenuItemsAction,
  UpsertPreviousUrl,
} from '../actions';
import {LayoutTsmService} from '../services';
import {RouterStateUrl, withLatestCached} from '@tsm/framework/root';
import {DtlMenuItem, DtlMenuItemType} from '@tsm/framework/plugin';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {RuntimeService} from '@tsm/runtime-info';
import {ROUTER_REQUEST, RouterNavigatedAction} from '@ngrx/router-store';
import {TranslocoService} from '@tsm/framework/translate';
import {Store} from '@ngrx/store';
import {selectAllLayoutMenuState} from '../selectors';
import {Router} from '@angular/router';

export function fixMenu(
  menuItems: DtlMenuItem[],
  listingType?: string,
): DtlMenuItem[] {
  // pokud je privilegium null, tak se nastavuje defaultne na TRUE
  // const convertMenu = newItems.map(x => ({...x, privilege: x.privilege ?? ['true']}));
  menuItems.forEach((newItem) => {
    if (!newItem.privilege) {
      newItem.privilege = ['true'];
    }
    if (newItem.listingType == null && listingType !== null) {
      newItem.listingType = listingType;
    }
    if (!newItem?.routerLink && !newItem?.url && newItem?.items == null) {
      newItem.items = [];
    }
    if (newItem.items && newItem.items.length > 0) {
      return fixMenu(newItem.items, listingType);
    }
    return null;
  });
  return menuItems;
}

@Injectable()
export class LayoutTsmEffects {
  selfcare = (window as any)?.app?.params?.selfcare ?? false;

  openMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenMenuAction),
      map(() => {
        return SetMenuAction({opened: true});
      }),
    ),
  );

  closeMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CloseMenuAction),
      map(() => {
        return SetMenuAction({opened: false});
      }),
    ),
  );

  loadMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMenuItemsAction, ChangeLanguageMenuItemsAction),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      switchMap(([action, menuItems]) => {
        return this.layoutService.getMenuItemApi().pipe(
          map((env) => {
            if (env.success) {
              const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
              // pokud mam cele menu v DB, tak ho ber pouze z ni
              let mergedMenuItems = fixMenuItems.sort(
                (a, b) => (b.priority || 0) - (a.priority || 0),
              );
              if (
                !env.data.some((x) =>
                  x.type === this.selfcare
                    ? DtlMenuItemType.SELFCARE
                    : DtlMenuItemType.MENU,
                )
              ) {
                // pokud nemam menu v DB, tak vezmi to staticke
                mergedMenuItems = this.mergeWithMenuItemsApi(
                  menuItems,
                  fixMenuItems,
                );
              }
              return LoadMenuItemsActionSuccess({
                menus: this.createTranslateMenuItems(mergedMenuItems),
              });
            } else {
              return LoadMenuItemsActionError({error: env.error});
            }
          }),
        );
      }),
    ),
  );

  toggleBookmarkMenuItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToggleBookmarkMenuItemAction),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      switchMap(([{menuItem}, menuItems]) => {
        const menuItemUrl = this.router
          .createUrlTree(menuItem.routerLink, {
            queryParams: menuItem.queryParams || {},
          })
          .toString();
        const foundItem = menuItems
          .filter((x) => x.type === DtlMenuItemType.BOOKMARK)
          .find((x) => {
            const tmpMenuItemUrl = this.router
              .createUrlTree(x.routerLink, {queryParams: x.queryParams || {}})
              .toString();
            return x.key === menuItem.key && menuItemUrl === tmpMenuItemUrl;
          });
        if (foundItem) {
          return this.layoutService.deleteMenuItemApi(foundItem).pipe(
            map((env) => {
              if (env.success) {
                const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
                // const mergedMenuItems = this.mergeWithMenuItemsApi(menuItems, fixMenuItems);
                return LoadMenuItemsActionSuccess({
                  menus: this.createTranslateMenuItems(fixMenuItems),
                });
              } else {
                return LoadMenuItemsActionError({error: env.error});
              }
            }),
          );
        }
        return this.layoutService.addBookmarkMenuItemApi(menuItem).pipe(
          map((env) => {
            if (env.success) {
              const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
              const mergedMenuItems = this.mergeWithMenuItemsApi(
                menuItems,
                fixMenuItems,
              );
              return LoadMenuItemsActionSuccess({
                menus: this.createTranslateMenuItems(mergedMenuItems),
              });
            } else {
              return LoadMenuItemsActionError({error: env.error});
            }
          }),
        );
      }),
    ),
  );

  reorderBookmarkMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReorderBookmarkMenuItemsAction),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      switchMap(([action, menuItems]) => {
        return this.layoutService
          .reorderBookmarkMenuItemsApi(action.menuItems)
          .pipe(
            map((env) => {
              if (env.success) {
                const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
                const mergedMenuItems = this.mergeWithMenuItemsApi(
                  menuItems,
                  fixMenuItems,
                );
                return LoadMenuItemsActionSuccess({
                  menus: this.createTranslateMenuItems(mergedMenuItems),
                });
              } else {
                return LoadMenuItemsActionError({error: env.error});
              }
            }),
          );
      }),
    ),
  );

  upsertMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMenuItemsAction),
      switchMap(({menuItems}) => {
        return this.layoutService.saveAllMenuItemsApi(menuItems).pipe(
          map((env) => {
            if (env.success) {
              const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
              return LoadMenuItemsActionSuccess({
                menus: this.createTranslateMenuItems(fixMenuItems),
              });
            } else {
              return LoadMenuItemsActionError({error: env.error});
            }
          }),
        );
      }),
    ),
  );

  addMenuItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddMenuItemAction),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      switchMap(([{menuItem}, menuItems]) => {
        return this.layoutService.addMenuItemApi(menuItem).pipe(
          map((env) => {
            if (env.success) {
              const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
              const mergedMenuItems = this.mergeWithMenuItemsApi(
                menuItems,
                fixMenuItems,
              );
              return LoadMenuItemsActionSuccess({
                menus: this.createTranslateMenuItems(mergedMenuItems),
              });
            } else {
              return LoadMenuItemsActionError({error: env.error});
            }
          }),
        );
      }),
    ),
  );

  updateMenuItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMenuItemAction),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      switchMap(([{menuItem}, menuItems]) => {
        return this.layoutService.updateMenuItemApi(menuItem).pipe(
          map((env) => {
            if (env.success) {
              const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
              const mergedMenuItems = this.mergeWithMenuItemsApi(
                menuItems,
                fixMenuItems,
              );
              return LoadMenuItemsActionSuccess({
                menus: this.createTranslateMenuItems(mergedMenuItems),
              });
            } else {
              return LoadMenuItemsActionError({error: env.error});
            }
          }),
        );
      }),
    ),
  );

  deleteMenuItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMenuItemAction),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      switchMap(([{menuItem}, menuItems]) => {
        return this.layoutService.deleteMenuItemApi(menuItem).pipe(
          map((env) => {
            if (env.success) {
              const fixMenuItems = env.data == null ? [] : fixMenu(env.data);
              // const mergedMenuItems = this.mergeWithMenuItemsApi(menuItems, fixMenuItems);
              return LoadMenuItemsActionSuccess({
                menus: this.createTranslateMenuItems(fixMenuItems),
              });
            } else {
              return LoadMenuItemsActionError({error: env.error});
            }
          }),
        );
      }),
    ),
  );

  updateMenuItemByForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMenuItemsByIdInUrl),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      map(([{id}, menuItems]) => {
        if (this.findNodeInForestByIdInUrl(menuItems, id)) {
          return LoadMenuItemsAction();
        }
        return LoadMenuItemsActionSuccess({menus: menuItems});
      }),
    ),
  );

  deleteMenuItemByForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMenuItemsByIdInUrl),
      withLatestCached(() => this.store$.select(selectAllLayoutMenuState)),
      map(([{id}, menuItems]) => {
        if (this.findNodeInForestByIdInUrl(menuItems, id)) {
          return LoadMenuItemsAction();
        }
        return LoadMenuItemsActionSuccess({menus: menuItems});
      }),
    ),
  );

  upsertPreviousUrl = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction<RouterStateUrl>>(ROUTER_REQUEST),
      map(({payload}) =>
        UpsertPreviousUrl({
          url: payload.routerState.url,
          queryParams: payload.routerState.queryParams,
        }),
      ),
    ),
  );

  constructor(
    private layoutService: LayoutTsmService,
    private runtimeService: RuntimeService,
    private apiService: ApiService,
    private config: ConfigService<Config>,
    private actions$: Actions,
    private store$: Store,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  private mergeWithMenuItemsApi(
    staticItems: DtlMenuItem[],
    menuItems: DtlMenuItem[],
    parentIcon?: string,
  ): DtlMenuItem[] {
    const copy = staticItems ? [...staticItems] : [];
    menuItems.forEach((newItem) => {
      const keepExisting = staticItems
        ? staticItems.find((item) => item.key === newItem.key)
        : null;
      if (keepExisting) {
        // nechci aktualizovat menu polozkama, ktere jsou BOOKMARK
        // Ve statickych existuje
        if (newItem.items) {
          const index = staticItems.indexOf(keepExisting);
          // existuje,
          copy[index] = {
            ...copy[index],
            id: newItem.useDefaultSetting == true ? copy[index].id : newItem.id,
            label:
              newItem.useDefaultSetting == true
                ? copy[index].label
                : newItem.label,
            icon:
              newItem.useDefaultSetting == true
                ? copy[index].icon
                : newItem.icon,
            visible:
              newItem.useDefaultSetting == true
                ? copy[index].visible
                : newItem.visible,
            priority:
              newItem.useDefaultSetting == true
                ? copy[index].priority
                : newItem.priority,
            privilege:
              newItem.useDefaultSetting == true
                ? copy[index].privilege
                : newItem.privilege,
            routerLink:
              newItem.useDefaultSetting == true
                ? copy[index].routerLink
                : newItem.routerLink,
            listingType:
              newItem.useDefaultSetting == true
                ? copy[index].listingType
                : newItem.listingType,
            items: this.mergeWithMenuItemsApi(
              copy[index].items,
              newItem.items,
              keepExisting.icon,
            ),
          };
        } else if (copy.some((x) => x.id === newItem.id)) {
          // pokud uz tam jednou je, ten stejny menu item tam znova nedavat, ale aktualizovat
          // pouziva se asi jenom pro update polozky v bookmarku!!
          const index = staticItems.findIndex((x) => x.id === newItem.id);
          copy[index] = {
            ...copy[index],
            // id: newItem.useDefaultSetting == true ? copy[index].id : newItem.id,
            label:
              newItem.useDefaultSetting == true
                ? copy[index].label
                : newItem.label,
            icon:
              newItem.useDefaultSetting == true
                ? copy[index].icon
                : newItem.icon,
            // visible: newItem.useDefaultSetting == true ? copy[index].visible : newItem.visible,
            priority:
              newItem.useDefaultSetting == true
                ? copy[index].priority
                : newItem.priority,
            privilege:
              newItem.useDefaultSetting == true
                ? copy[index].privilege
                : newItem.privilege,
            routerLink:
              newItem.useDefaultSetting == true
                ? copy[index].routerLink
                : newItem.routerLink,
            // listingType: newItem.useDefaultSetting == true ? copy[index].listingType : newItem.listingType,
            // items: this.mergeWithMenuItemsApi(copy[index].items, newItem.items, keepExisting.icon)
          };
        } else {
          // ?? pridavam novy
          copy.push({
            ...keepExisting,
            id:
              newItem.useDefaultSetting == true ? keepExisting.id : newItem.id,
            label:
              newItem.useDefaultSetting == true
                ? keepExisting.label
                : newItem.label,
            icon:
              newItem.useDefaultSetting == true
                ? keepExisting.icon
                : newItem.icon,
            visible:
              newItem.useDefaultSetting == true
                ? keepExisting.visible
                : newItem.visible,
            priority:
              newItem.useDefaultSetting == true
                ? keepExisting.priority
                : newItem.priority,
            privilege:
              newItem.useDefaultSetting == true
                ? keepExisting.privilege
                : newItem.privilege,
            routerLink:
              newItem.useDefaultSetting == true
                ? keepExisting.routerLink
                : newItem.routerLink,
            listingType:
              newItem.useDefaultSetting == true
                ? keepExisting.listingType
                : newItem.listingType,
            items: newItem.items,
          });
        }
      } else {
        // prvek neexistuje ve statickem menu
        copy.push({
          ...newItem,
          icon: newItem.icon === null ? parentIcon : newItem.icon,
        });
      }
    });

    return copy.sort((a, b) => (b.priority || 0) - (a.priority || 0));
  }

  /**
   * Vytvorit preklady pro menu item
   */
  private createTranslateMenuItems(items: DtlMenuItem[]): DtlMenuItem[] {
    this.setTranslateLabel(items, this.translocoService.getActiveLang());
    return items;
  }

  private setTranslateLabel(items: DtlMenuItem[], lang: string) {
    if (!items || items.length === 0) {
      return;
    }
    for (let i = 0; i < items.length; i++) {
      if (!!items[i].localizationData?.[lang]?.label) {
        items[i].translateLabel = this.translocoService.translate(
          items[i].localizationData[lang]?.label,
        );
      } else {
        items[i].translateLabel = this.translocoService.translate(
          items[i].label,
        );
      }
      if (items[i].items?.length > 0) {
        this.setTranslateLabel(items[i].items, lang);
      }
    }
  }

  /**
   * Vrati menu item podle id
   */
  private findNodeInForestByIdInUrl(
    forest: DtlMenuItem[],
    id: string,
  ): DtlMenuItem | null {
    function findNodeInTree(
      tree: DtlMenuItem,
      id: string,
      router,
    ): DtlMenuItem | null {
      if (
        tree?.url?.includes(id) ||
        (tree?.routerLink?.length > 0 &&
          router
            .createUrlTree(tree?.routerLink, {
              queryParams: tree.queryParams || {},
            })
            .toString()
            ?.includes(id))
      ) {
        return tree;
      }

      if (tree?.items == undefined) {
        return null;
      }

      for (const child of tree?.items) {
        const result = findNodeInTree(child, id, router);
        if (result !== null) {
          return result;
        }
      }
      return null;
    }

    for (const tree of forest) {
      const result = findNodeInTree(tree, id, this.router);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }
}
