import {Injectable} from '@angular/core';
import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {CommonApiService as SharedApiService} from '@tsm/shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonConfigFormApiService<
  ServerResponseType,
  ToClientType,
> extends SharedApiService<ServerResponseType, ToClientType> {
  constructor(config: ConfigService<Config>, apiService: ApiService) {
    super('tsm-config-form/api', apiService, config);
  }

  getFormByCode(code: string): Observable<Envelope<any>> {
    return this.apiService.get(`${this.BASE_URL}/v1/forms/${code}`).pipe(
      map((x: Envelope<any>) => {
        if (x.success) {
          return {
            ...x,
            data: {
              ...x.data,
              schema: {
                ...x.data.schema,
                id: x.data.id,
                code: x.data.code
              }
            }
          };
        }
        return x;
      })
    );
  }
}
