import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sanitizeNull',
})
export class SanitizeNullPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value === null || value === undefined ? '' : value;
  }
}
