import {Pipe, untracked} from '@angular/core';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {LoadFormById} from '../actions';
import {selectFormById} from '../selectors';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'tsmForms',
  pure: true,
})
export class TsmFormsPipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  setObservable(id: string, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectFormById(id)),
      map((it) => {
        if (!it || !it.data) {
          return id;
        } else {
          return field !== 'all' ? it.data[field] : it.data;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectFormById(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() => this.store.dispatch(LoadFormById({id: this._id})));
        }
      });
  }
}
