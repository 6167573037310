import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Injector,
  NgZone,
  Optional,
  Output,
  SecurityContext,
  TemplateRef,
} from '@angular/core';
import {Terminator} from '@tsm/framework/terminator';
import {fromEvent} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Dialog} from '@angular/cdk/dialog';
import {TooltipDialogComponent} from '../tooltip-dialog/tsm-tooltip-dialog.component';
import {translation} from '../../i18n';
import {DomSanitizer} from '@angular/platform-browser';

export type TooltipData = string | TemplateRef<void>;
export const TOOLTIP_DATA = new InjectionToken<TooltipData>(
  'Data to display in tooltip',
);
export const TOOLTIP_STATIC_POSITION = new InjectionToken<boolean>(
  'Static position in tooltip',
);
export const TOOLTIP_MAX_WIDTH = new InjectionToken<boolean>(
  'Max width tooltip',
);

@Component({
  selector: 'tsm-tooltip-component',
  templateUrl: './tsm-tooltip.component.html',
  styleUrls: ['./tsm-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Terminator],
})
export class TooltipComponent implements AfterViewInit {
  translation = translation;

  private mouserEnter = false;

  maxWidthRem = 33;

  @Output()
  closeTooltip = new EventEmitter();

  @Output()
  onEnter = new EventEmitter();

  isString = false;
  tmpTooltipData: TooltipData;

  constructor(
    @Inject(TOOLTIP_DATA) public tooltipData: TooltipData,
    @Optional()
    @Inject(TOOLTIP_STATIC_POSITION)
    public tooltipStaticPosition: string,
    @Optional() @Inject(TOOLTIP_MAX_WIDTH) public tooltipMaxWidthRem: number,
    public dialog: Dialog,
    private zone: NgZone,
    private sanitized: DomSanitizer,
    private elementRef: ElementRef,
    private terminator: Terminator,
  ) {
    if (tooltipMaxWidthRem) {
      this.maxWidthRem = tooltipMaxWidthRem;
    }
    this.isString = typeof this.tooltipData === 'string';
    if (this.isString) {
      this.tmpTooltipData = this.sanitized.sanitize(
        SecurityContext.HTML,
        this.tooltipData,
      );
    } else {
      this.tmpTooltipData = tooltipData;
    }
  }

  ngAfterViewInit() {
    if (this.tooltipStaticPosition != null) {
      this.zone.runOutsideAngular(() => {
        fromEvent(this.elementRef.nativeElement, 'mouseenter')
          .pipe(takeUntil(this.terminator))
          .subscribe((event: MouseEvent) => {
            this.onEnter.emit();
            this.runMouseLeave();
          });
      });
    }
  }

  runMouseLeave() {
    if (this.mouserEnter == false) {
      this.mouserEnter = true;
      this.zone.runOutsideAngular(() => {
        fromEvent(this.elementRef.nativeElement, 'mouseleave')
          .pipe(takeUntil(this.terminator))
          .subscribe((event: MouseEvent) => {
            this.zone.run(() => {
              this.closeTooltip.emit(true);
            });
          });
      });
    }
  }

  openInDialog() {
    this.closeTooltip.emit(true);
    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: this.tooltipData,
        },
        {
          provide: TOOLTIP_STATIC_POSITION,
          useValue: 'tsmTooltip-' + this.tooltipStaticPosition,
        },
        {
          provide: TOOLTIP_MAX_WIDTH,
          useValue: this.maxWidthRem,
        },
      ],
    });
    this.dialog.open(TooltipDialogComponent, {
      injector: injector,
      minWidth: '300px',
      hasBackdrop: false,
    });
  }

  get canOpenDialog(): boolean {
    return (
      this.tooltipStaticPosition != null &&
      document.getElementById('tsmTooltip-' + this.tooltipStaticPosition) ==
        null
    );
  }

  protected readonly SecurityContext = SecurityContext;
}
