export const en = {
  listingLibPlugins: {
    headerDescription: 'Header docs',
    filters: {
      dtlDataTypeFilter: 'dtl Data Type Filter',
      dtlDataTypeFilterDescription:
        'Filters data types in a selectable table view. ' +
        'Displays available data types with pagination and sorting. ' +
        'Supports multi-selection of data types. ' +
        'Allows users to confirm or cancel their selection. ' +
        'Translates data type names for localization. ' +
        'Handles selection and deselection of all items. ' +
        'Manages filter value and display text dynamically.',
      dtlFilterBoolean: 'dtl Filter Boolean',
      dtlFilterBooleanDescription:
        'Provides a boolean filter component for selecting predefined boolean values. ' +
        'Displays options such as "Yes", "No", "Empty", and "Not Empty". ' +
        'Supports customizable labels and default values via context input. ' +
        'Automatically sets the operator based on the selected value. ' +
        'Updates and emits the filter value on selection change. ' +
        'Uses an overlay panel for dropdown selection. ' +
        'Translates option labels for localization support.',
      dtlFilterCalendarRange: 'dtl Filter Calendar Range',
      dtlFilterCalendarRangeDescription:
        'Provides a date range filter component for tables, supporting absolute and relative dates. ' +
        'Allows selection of date ranges with optional time selection. ' +
        'Handles different filter operators like "greater than", "less than", or "between". ' +
        'Formats and converts dates for API requests and ensures correct time zone handling. ' +
        'Supports predefined relative date values and translations for better usability.',
      dtlFilterCustom: 'dtl Filter Custom',
      dtlFilterCustomDescription:
        'Implements a customizable filter component with predefined values and operators. ' +
        'Allows setting a filter using a specified operator and a single or multiple values. ' +
        'Supports dynamic display values for better user readability. ' +
        'Emits the updated filter value upon initialization and when changes occur. ' +
        'Designed as a base for advanced custom filter logic. ' +
        'Uses an explicit flag to indicate a custom filter type.',
      dtlFilterDataTags: 'dtl Filter Data Tags',
      dtlFilterDataTagsDescription:
        'Provides a table filter component for selecting data tags from a configurable API endpoint. ' +
        'Supports filtering, sorting, and selecting multiple items, including a special "NULL" tag. ' +
        'Loads data dynamically based on user input and updates selection accordingly. ' +
        'Integrates with external APIs and manages state through form controls. ' +
        'Ensures efficient data fetching and UI updates using change detection strategies.',
      dtlFilterDateToBoolean: 'dtl Filter Date To Boolean',
      dtlFilterDateToBooleanDescription:
        'Creates a dropdown-based filter component that converts date values to boolean. ' +
        'Provides options to filter data where the date field is set or empty. ' +
        'Defaults to filtering for non-empty values using the "notempty" operator. ' +
        'Automatically sets and emits the filter value upon initialization. ' +
        'Updates the displayed value based on user selection. ' +
        'Supports dynamic translations for displayed values.',
      dtlFilterEdit: 'dtl Filter Edit',
      dtlFilterEditDescription:
        'Manages the dynamic creation of filter components within a table filter system. ' +
        'Dynamically loads and initializes filter widgets based on the provided filter configuration. ' +
        'Ensures the filter widget receives necessary context and updates its state. ' +
        'Handles positioning adjustments for UI consistency. ' +
        'Cleans up dynamically created components upon destruction.',
      dtlFilterExportStatus: 'dtl Filter Export Status',
      dtlFilterExportStatusDescription:
        'Creates a filter component for selecting export statuses. ' +
        'Displays a list of available export statuses in a table format with multiple selection. ' +
        'Retrieves export status options dynamically and translates them for localization. ' +
        'Stores the selected values and updates the filter state accordingly. ' +
        'Includes a footer with confirmation and cancellation buttons. ' +
        'Supports pagination and sorting configuration for the data view.',
      dtlFilterInBoolean: 'dtl Filter In Boolean',
      dtlFilterInBooleanDescription:
        'Creates a boolean filter component with multiple selection options. ' +
        'Allows users to filter data by selecting "Yes," "No," or both options. ' +
        'Supports custom default values and labels for localization. ' +
        'Handles filter state updates and emits changes when selection is made. ' +
        'Uses a dropdown component for easy selection of boolean values. ' +
        'Includes confirmation and cancellation buttons in the footer.',
      dtlFilterInput: 'dtl Filter Input',
      dtlFilterInputDescription:
        'Creates an input filter component with multiple filtering operators. ' +
        'Supports text input, multi-value input, and operators like "contains," ' +
        '"equals," "not equals," "starts with," "ends with," and more. ' +
        'Handles empty and non-empty values and automatically formats multi-value ' +
        'filters. ' +
        'Uses a reactive form with a dynamically updated operator selection. ' +
        'Includes confirmation and cancellation buttons in the footer.',
      dtlFilterItem: 'dtl Filter Item',
      dtlFilterItemDescription:
        'Defines a filter item component that integrates with the table filtering system. ' +
        'Manages user input and filter operator transformations dynamically. ' +
        'Ensures filter values are correctly formatted based on the selected operator. ' +
        'Automatically updates values and display values upon operator change. ' +
        'Uses reactive forms to track filter state and changes efficiently. ' +
        'Supports validation, dynamic visibility, and readonly configurations.',
      dtlFilterList: 'dtl Filter List',
      dtlFilterListDescription:
        'Defines a filter list component that allows users to manage multiple filter conditions. ' +
        'Supports both structured filter models and expression-based filtering. ' +
        'Dynamically updates form controls based on user input and filter type. ' +
        'Validates filter objects to ensure they contain the required fields. ' +
        'Automatically synchronizes values between structured and JSON formats. ' +
        'Provides an option to toggle between JSON and expression-based filtering.',
      dtlFilterNumber: 'dtl Filter Number',
      dtlFilterNumberDescription:
        'Defines a number filter component that allows users to filter numeric values ' +
        'using various operators such as "equals", "greater than", "less than", "between", and ' +
        '"includes multiple values". Supports numeric input, range selection, and ' +
        'multi-value input via a textarea. Dynamically updates the form based on ' +
        'selected operator and ensures numeric validation. Provides an option to ' +
        'filter empty or non-empty values.',
      dtlFilterPhone: 'dtl Filter Phone',
      dtlFilterPhoneDescription:
        'Creates a phone number filter component with multiple filtering operators. ' +
        'Supports "exact match", "contains", "starts with", "ends with", and "multi-value input". ' +
        'Removes spaces automatically and ensures proper formatting. ' +
        'Uses a reactive form with dynamically selectable filtering operators. ' +
        'Displays confirmation and cancellation buttons in the footer.',
      dtlFilterTql: 'dtl Filter Tql',
      dtlFilterTqlDescription:
        'Creates a filter component for selecting predefined query options. ' +
        'Uses a dropdown menu to choose from predefined values provided in the context. ' +
        'Defaults to the first option if no value is selected. ' +
        'Supports equality filtering with the selected option. ' +
        'Includes confirmation and cancellation buttons in the footer.',
      dtlTableFilter: 'dtl Table Filter',
      dtlTableFilterDescription:
        'Defines a table filter component that manages filtering logic for a data table. ' +
        'Supports dynamic filter creation, selection from available columns, and applying ' +
        'various filter operators. Allows users to filter across multiple fields, manage ' +
        'filters interactively, and remove applied filters. Integrates with translations ' +
        'to provide localized filter labels and tooltips. Includes keyboard shortcuts for ' +
        'quick filtering and auto-complete suggestions for filterable columns.',
      dtlTableFilterColumnConfigCode: 'dtl Table Filter Column Config Code',
      dtlTableFilterColumnConfigCodeDescription:
        'Creates a multi-select filter component for filtering table columns ' +
        'based on configuration codes. Uses a data view connector to fetch ' +
        'and display available codes. Supports searching and sorting by ' +
        'code and name. Includes confirmation and cancellation buttons in ' +
        'the footer to apply or discard the selection.',
      dtlTableFilterListingParent: 'dtl Table Filter Listing Parent',
      dtlTableFilterListingParentDescription:
        'Defines an abstract directive that extends the base table filter ' +
        'component to support listing-based filters. Manages selected values ' +
        'and updates the listing state accordingly. Integrates with the ' +
        'history service to track selected entities. Provides methods for ' +
        'removing individual selections or clearing all selections.',
      dtlTableFilterParent: 'dtl Table Filter Parent',
      dtlTableFilterParentDescription:
        'Defines an abstract base class for table filter components. ' +
        'Manages filter field properties, lifecycle hooks, and overlay panel ' +
        'interactions. Ensures a default filter operator if not set. Provides ' +
        'methods for handling filter confirmation and cancellation. Supports ' +
        'automatic panel toggling and cleanup on component destruction.',
      dtlTableFilterSelectedItems: 'dtl Table Filter Selected Items',
      dtlTableFilterSelectedItemsDescription:
        'Displays a selected items bar for table filters. ' +
        'Shows selected filter values as chips with an option to remove all. ' +
        'Provides a cancel button to clear selections. Uses a responsive layout ' +
        'for handling multiple selected items. Supports dynamic translation of labels.',
      dtlTableFilterWidgetEditor: 'dtl Table Filter Widget Editor',
      dtlTableFilterWidgetEditorDescription:
        'Defines a table filter widget editor that provides configuration options for ' +
        'customizing the appearance and behavior of table filters. Supports setting ' +
        'label positions, managing visibility, and applying custom styling classes. ' +
        'Integrates with translations for localized UI elements. Extends the base ' +
        'widget editor component to enable structured filter configurations.',
      dtlTableFilterWidget: 'dtl Table Filter Widget',
      dtlTableFilterWidgetDescription:
        'Defines a table filter widget that integrates with a listing service to manage ' +
        'filters, profiles, and table settings dynamically. Provides a structured UI ' +
        'for configuring and applying table filters, including profile selection, saving, ' +
        'and resetting options. Supports asynchronous state management with observables ' +
        'to update the UI in response to changes in the listing state.',
    },
    pipes: {
      backupRestoreIcon: 'Backup Restore Icon',
      backupRestoreIconDescription:
        'Renders an icon based on the provided value to indicate backup or restore actions. Icons include ' +
        '"new" for adding, "change" for editing, and a default "remove" icon. The icons display tooltips ' +
        'translated to the active language.',
      customColumnValue: 'Custom Column Value',
      customColumnValueDescription:
        'Executes a custom transformation function to generate a column value. This function takes the ' +
        'row data as input and produces a customized string for the column display. Designed to enable ' +
        'flexible and dynamic column rendering in tabular data.',
      dataForView: 'Data For View',
      dataForViewDescription:
        'Processes and formats data for view representation in a table. Maps column definitions to ' +
        'corresponding row values, applying converters and localization transforms if specified. ' +
        'Ensures efficient reuse of previous results when data remains unchanged, enhancing performance.',
      discriminator: 'Discriminator',
      discriminatorDescription:
        'Implements change detection logic using a custom discriminator function. Compares the current ' +
        'value with the previous value to determine if they differ. Returns the cached result if the ' +
        'value remains unchanged, improving performance by avoiding unnecessary recomputation.',
      tsmFiltersToStringPipe: 'Tsm Filters To String Pipe',
      tsmFiltersToStringPipeDescription:
        'Converts a list of filters into a readable string. Extracts the `displayValue` property from ' +
        'each filter and joins them with a comma to create a single string representation.',
      linkHrefComponent: 'Link Href Component',
      linkHrefComponentDescription:
        'Defines a pipe to process link-related transformations. It utilizes the row data for input, ' +
        'and returns an empty string as the output.',
      listingProfileCode: 'Listing Profile Code',
      listingProfileCodeDescription:
        'Creates a pipe for retrieving listing profile data by code. Supports filtering by listing code ' +
        'or fetching all associated data. Extracts specific fields or the complete data structure. ' +
        'Dispatches actions to load data if unavailable in the store.',
      listingProfile: 'Listing Profile',
      listingProfileDescription:
        'Provides a pipe to retrieve listing profile data by ID. Allows fetching specific fields or ' +
        'all data when needed. Automatically triggers actions to load the data if it is missing ' +
        'in the store.',
      listingTypeCodeLoader: 'Listing Type Code Loader',
      listingTypeCodeLoaderDescription:
        'Enables fetching listing type data by its code. Supports retrieving specific fields or ' +
        'complete data. Ensures missing data is loaded into the store automatically.',
      listingTypeLoader: 'Listing Type Loader',
      listingTypeLoaderDescription:
        'Fetches listing type data by ID from the store. Allows retrieving specific fields or full data. ' +
        'Automatically triggers data loading into the store when missing.',
      tsmLovboxMultiReadonlySplit: 'Tsm Lovbox Multi Readonly Split',
      tsmLovboxMultiReadonlySplitDescription:
        'Splits a string of values into an array of strings based on commas. Returns the original array ' +
        'if the input is already an array.',
      memoizedDiscriminator: 'Memoized Discriminator',
      memoizedDiscriminatorDescription:
        'Optimizes repeated computations by caching the result of a function. Executes the provided function ' +
        'if the discriminator detects changes between the previous and current values. Returns the cached ' +
        'result if no changes are detected.',
      memoized: 'Memoized',
      memoizedDescription:
        'Executes a provided function with the given value. Avoid usage unless the implications are fully ' +
        'understood, as the function will be directly invoked without caching or optimization.',
      tsmSelectedColumnsToString: 'Tsm Selected Columns To String',
      tsmSelectedColumnsToStringDescription:
        'Converts an array of selected table columns to a comma-separated string. Extracts the "field" ' +
        'property from each column in the input array and joins them into a single string.',
    },
  },
};
