import {CanDeactivateFn, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: (
    nextState: RouterStateSnapshot,
  ) =>
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree;
}

export const unsavedChangesGuard: CanDeactivateFn<CanComponentDeactivate> = (
  component: CanComponentDeactivate,
  _,
  __,
  nextState: RouterStateSnapshot,
) => {
  return component?.canDeactivate ? component.canDeactivate(nextState) : true;
};
