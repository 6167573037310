import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorklogTimer} from '../models';
import {
  EndWorklogTimer,
  EndWorklogTimerSuccess,
  StartWorklogTimer,
  StartWorklogTimerSuccess,
} from '../actions';

export interface WorklogTimerState {
  id: string;
  worklogTimer?: WorklogTimer;
  loading: boolean;
  error: any;
}

export const worklogTimerAdapter: EntityAdapter<WorklogTimerState> =
  createEntityAdapter<WorklogTimerState>();
export const initialState: EntityState<WorklogTimerState> =
  worklogTimerAdapter.getInitialState({});

export const worklogTimerReducer = createReducer(
  initialState,

  on(StartWorklogTimer, (state, {worklogTimer}) =>
    worklogTimerAdapter.updateOne(
      {
        id: worklogTimer.workerId,
        changes: {
          ...state.entities[worklogTimer.workerId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(StartWorklogTimerSuccess, (state, {worklogTimer}) =>
    worklogTimerAdapter.updateOne(
      {
        id: worklogTimer.workerId,
        changes: {
          ...state.entities[worklogTimer.workerId],
          worklogTimer: worklogTimer,
          loading: false,
        },
      },
      state,
    ),
  ),

  on(EndWorklogTimer, (state, {worklogTimer}) =>
    worklogTimerAdapter.updateOne(
      {
        id: worklogTimer.workerId,
        changes: {
          ...state.entities[worklogTimer.workerId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(EndWorklogTimerSuccess, (state, {worklogTimer}) =>
    worklogTimerAdapter.updateOne(
      {
        id: worklogTimer.workerId,
        changes: {
          ...state.entities[worklogTimer.workerId],
          worklogTimer: worklogTimer,
          loading: false,
        },
      },
      state,
    ),
  ),
);
