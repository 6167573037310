import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';

export const LoadDialogPopupByParentComponentName = createAction(
  '[DialogPopup UserManagement] Load DialogPopup',
  props<{
    parentComponentName: string;
  }>(),
);
export const UpsertDialogPopup = createAction(
  '[DialogPopup UserManagement] Upsert DialogPopup',
  props<{
    parentComponentName: string;
    saving: boolean;
    successSaving: boolean;
    error: TsmError;
  }>(),
);
export const UpsertDialogPopupSuccess = createAction(
  '[DialogPopup UserManagement] Upsert DialogPopup Success',
  props<{
    parentComponentName: string;
    saving: boolean;
    successSaving: boolean;
    error: TsmError;
  }>(),
);
export const DeleteDialogPopup = createAction(
  '[DialogPopup UserManagement] Delete DialogPopup Error',
  props<{
    parentComponentName: string;
  }>(),
);
