export const cs = {
  characteristicsService: {
    effects: {
      characteristicSaveSuccess: 'Uložení charakteristiky proběhlo úspěšně',
      characteristicSaveError: 'Během ukládání charakteristiky došlo k chybě',
      characteristicValueSaveSuccess:
        'Uložení hodnoty charakteristiky proběhlo úspěšně',
      characteristicValueSaveError:
        'Během ukládání hodnoty charakteristiky došlo k chybě',
      diffCharacteristicsSuccess:
        'Ukládání rozdílů charakteristik skončilo úspěšně.',
      diffCharacteristicsError:
        'Při ukládání rozdílů charakteristik došlo k chybě.',
    },
  },
};
