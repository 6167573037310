import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {DynamicComponentBundle, DynamicComponentService} from '../../services';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';

@Component({
  selector: 'dtl-dynamic-component',
  templateUrl: './dynamic-component.component.html',
  styleUrls: ['./dynamic-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicComponentComponent
  extends AbstractControlValueAccessor
  implements OnChanges, OnDestroy
{
  @ViewChild('hosting', {static: true, read: ViewContainerRef})
  hosting: ViewContainerRef;
  @Input() selector? = '';
  @Input() type?: Type<unknown>;
  @Input() inputs: {[key: string]: any};
  @Input() filterInputs = false;
  @Input() outputs: {[key: string]: any};
  @Input() root = false;
  @Output() created: EventEmitter<ComponentRef<any>> = new EventEmitter<
    ComponentRef<any>
  >();

  constructor(
    private dynamicService: DynamicComponentService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  dynamicComponentBundle: DynamicComponentBundle = null;

  _sub: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selector']) {
      if (this.root) {
        this._sub = this.dynamicService
          .useRootDynamicComponent$(
            changes['selector'].currentValue,
            changes['inputs']?.currentValue,
            changes['outputs']?.currentValue,
          )
          .subscribe((bundle) => {
            this.dynamicComponentBundle = bundle;
            this.dynamicComponentBundle.componentChangeDetector.detectChanges();
            this.created.emit(this.dynamicComponentBundle.componentRef);
          });
      } else {
        this.dynamicService
          .useDynamicComponent$(
            changes['selector'].currentValue,
            this.hosting,
            changes['inputs']?.currentValue,
            changes['outputs']?.currentValue,
          )
          .subscribe((bundle) => {
            this.dynamicComponentBundle = bundle;
            this.dynamicComponentBundle.componentChangeDetector.detectChanges();
            this.created.emit(this.dynamicComponentBundle.componentRef);
          });
      }
    } else if (changes['type'] && changes['type'].currentValue != null) {
      this.dynamicService
        .useDynamicStandaloneComponent$(
          changes['type'].currentValue,
          this.hosting,
          changes['inputs'] ? changes['inputs'].currentValue : this.inputs,
          changes['outputs']?.currentValue,
        )
        .subscribe((bundle) => {
          this.dynamicComponentBundle = bundle;
          this.dynamicComponentBundle.componentChangeDetector.detectChanges();
          this.created.emit(this.dynamicComponentBundle.componentRef);
        });
    } else {
      if (
        changes['inputs']?.currentValue &&
        this.dynamicComponentBundle != null
      ) {
        this.dynamicComponentBundle.setInputs(changes['inputs'].currentValue);
      }
      if (
        changes['outputs']?.currentValue &&
        this.dynamicComponentBundle != null
      ) {
        this.dynamicComponentBundle.setOutputs(
          changes['outputs'].currentValue,
          changes['outputs'].previousValue,
        );
      }
    }
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
