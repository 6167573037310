import {createFeatureSelector, createSelector} from '@ngrx/store';
import {notificationAdapter} from '../reducers/notification.reducer';
import {DmsState} from '../reducers';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getNotificationState = createSelector(
  getDmsState,
  (x) => x.notification,
);

const {selectAll, selectEntities, selectIds, selectTotal} =
  notificationAdapter.getSelectors();

export const selectNotificationEntities = createSelector(
  getNotificationState,
  selectEntities,
);
