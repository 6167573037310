import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {WorklogType} from '../models';
import {
  DeleteWorklogTypeSuccess,
  DiffWorklogType,
  DiffWorklogTypeError,
  DiffWorklogTypeSuccess,
  LoadWorklogTypeByCodeSuccess,
  LoadWorklogTypeById,
  LoadWorklogTypeByIdError,
  LoadWorklogTypeByIdSuccess,
  LoadWorklogTypeSuccess,
  UpsertWorklogType,
  UpsertWorklogTypeSuccess,
} from '../actions';

export type WorklogTypeState = EntityState<IdentifiedPack<WorklogType>>;

export const worklogTypeAdapter: EntityAdapter<IdentifiedPack<WorklogType>> =
  createEntityAdapter<IdentifiedPack<WorklogType>>({});

const initialState: WorklogTypeState = worklogTypeAdapter.getInitialState({});

export const worklogTypeReducer = createReducer(
  initialState,

  on(LoadWorklogTypeSuccess, (state, {worklogTypes}) => ({
    ...worklogTypeAdapter.addMany(
      worklogTypes.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadWorklogTypeById, (state, {id}) =>
    worklogTypeAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadWorklogTypeByIdSuccess,
    LoadWorklogTypeByCodeSuccess,
    (state, {worklogType}) =>
      worklogTypeAdapter.upsertOne(
        {
          id: worklogType.id,
          data: worklogType,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(LoadWorklogTypeByIdError, (state, {id, error}) =>
    worklogTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertWorklogType, (state, {worklogType}) =>
    worklogTypeAdapter.updateOne(
      {
        id: worklogType.id,
        changes: {
          ...state.entities[worklogType.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertWorklogTypeSuccess, (state, {worklogType}) =>
    worklogTypeAdapter.upsertOne(
      {
        id: worklogType.id,
        data: worklogType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteWorklogTypeSuccess, (state, worklogType) =>
    worklogTypeAdapter.removeOne(worklogType.id, state),
  ),

  on(DiffWorklogType, (state, {diffEntities}) =>
    worklogTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffWorklogTypeSuccess, (state, {worklogTypes}) =>
    worklogTypeAdapter.upsertMany(
      worklogTypes.map((worklogType) => {
        return {
          id: worklogType.id,
          changes: {
            id: worklogType.id,
            data: worklogType,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffWorklogTypeError, (state, {diffEntities, error}) =>
    worklogTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
