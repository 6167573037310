import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  selectCharacteristicsBySpecificationCode,
  selectCharacteristicsBySpecificationId,
  selectEntitySpecificationByCode,
  selectEntitySpecificationById,
  selectFormByCode,
  selectFormById,
  selectTsmModuleByCode,
} from '../selectors';
import {EntitySpecification, Form, TsmModule} from '../models';
import {
  LoadEntitySpecificationByCode,
  LoadEntitySpecificationById,
  LoadFormByCode,
  LoadFormById,
  LoadTsmModuleByCode,
  UpsertEntitySpecification,
} from '../actions';
import {map, switchMap} from 'rxjs/operators';
import {
  IdentifiedPack,
  loadAndWait,
  loadAndWaitForStore,
} from '@tsm/framework/root';
import {FilterOperator} from '@tsm/listing-lib/service';
import {
  Characteristics,
  LoadCharacteristicsByFilter,
  selectCharacteristicsByCode,
} from '@tsm/characteristics/service';

@Injectable({
  providedIn: 'root',
})
export class TsmFormConfigService {
  constructor(private store: Store) {}

  getEntityCharacteristicsBySpecificationId(
    entitySpecificationId: string,
  ): Observable<Characteristics[]> {
    if (!entitySpecificationId) {
      return of();
    }

    // nahraje specifikaci vcetne vsech charakteristik a formularu do store
    this.store.dispatch(
      LoadEntitySpecificationById({id: entitySpecificationId}),
    );

    return this.store
      .select(selectCharacteristicsBySpecificationId(entitySpecificationId))
      .pipe(
        map((chars) =>
          chars.filter((char) => char.data).map((char) => char.data),
        ),
      );
  }

  // FIXME CANCER idelane smazat a opravit kde se to pouziva (par mist)
  getEntityCharacteristicsBySpecificationCode(
    entitySpecificationCode: string,
  ): Observable<Characteristics[]> {
    // nahraje specifikaci vcetne vsech charakteristik a formularu do store
    if (!entitySpecificationCode || entitySpecificationCode === 'null') {
      return of([]);
    }
    this.store.dispatch(
      LoadEntitySpecificationByCode({code: entitySpecificationCode}),
    );
    return this.store
      .select(selectCharacteristicsBySpecificationCode(entitySpecificationCode))
      .pipe(
        map((chars) =>
          chars.filter((char) => char.data).map((char) => char.data),
        ),
      );
  }

  getEntityCharacteristicsByCode(code: string): Observable<Characteristics[]> {
    if (!code) {
      return of();
    }
    this.store.dispatch(
      LoadCharacteristicsByFilter({
        filters: [
          {
            field: 'code',
            operator: FilterOperator.eq,
            value: code,
          },
        ],
      }),
    );
    return this.store
      .select(selectCharacteristicsByCode(code))
      .pipe(map((chars) => chars.filter((char) => char)));
  }

  getEntitySpecificationById(
    id: string,
    infinite: boolean = true,
    untracked = false,
  ) {
    return this.store.pipe(
      loadAndWait(
        LoadEntitySpecificationById({id: id}),
        selectEntitySpecificationById(id),
        infinite,
        untracked,
      ),
    );
  }

  getEntitySpecificationByCode(code: string, infinite: boolean = true) {
    return this.store.pipe(
      loadAndWait(
        LoadEntitySpecificationByCode({code: code}),
        selectEntitySpecificationByCode(code),
        infinite,
      ),
    );
  }

  saveOrUpdateSpecification(
    entitySpecification: EntitySpecification,
    redirectToDetail: boolean,
  ) {
    this.store.dispatch(
      UpsertEntitySpecification({
        specification: entitySpecification,
        listingId: 'entitySpecifications',
        redirectToDetail: redirectToDetail,
      }),
    );
  }

  getFormByCode(
    code: string,
    infinite: boolean = true,
    actionUntracked = false,
  ): Observable<IdentifiedPack<Form>> {
    return this.store.pipe(
      loadAndWait(
        LoadFormByCode({code: code}),
        selectFormByCode(code),
        infinite,
        actionUntracked,
      ),
    );
  }

  getFormById(
    id: string,
    infinite = true,
    actionUntracked = false,
  ): Observable<IdentifiedPack<Form>> {
    return this.store.pipe(
      loadAndWait(
        LoadFormById({id: id}),
        selectFormById(id),
        infinite,
        actionUntracked,
      ),
    );
  }

  /**
   * Funkce vrati charakteristiky na zaklade entitySpecificationId a
   * formular (na zaklade sloupce formSpecification), ktery slouzí pro vykreslení stranky/formulare
   * @param entitySpecId - root entity
   * @param selector - komponenty, pro kterou je formular definovany
   */
  getCharacteristicsAndFormSpecification(
    entitySpecId: string,
    selector: string,
    infinite = false,
    untracked = false,
  ): Observable<{
    characteristics: Characteristics[];
    form: Form;
  }> {
    return entitySpecId
      ? this.store.pipe(
          loadAndWait(
            LoadEntitySpecificationById({id: entitySpecId}),
            selectEntitySpecificationById(entitySpecId),
            infinite,
            untracked,
          ),
          switchMap((x) => {
            const tmpFormSpecification = x.data.formSpecification;
            if (tmpFormSpecification && tmpFormSpecification[selector]) {
              return combineLatest([
                of(x.data.expandedCharacteristics),
                loadAndWaitForStore(
                  this.store,
                  LoadFormByCode({code: tmpFormSpecification[selector]}),
                  selectFormByCode(tmpFormSpecification[selector]),
                ).pipe(map((y) => y.data)),
              ]).pipe(
                map(([chars, form]) => ({
                  characteristics: chars,
                  form: form,
                })),
              );
            }
            return of({
              characteristics: x.data.expandedCharacteristics,
              form: null,
            });
          }),
        )
      : of();
  }

  /**
   * Funkce vrati charakteristiky na zaklade entitySpecificationId a
   * formular (na zaklade sloupce formSpecification), ktery slouzí pro vykreslení stranky/formulare
   * @param entitySpecCode - root entity
   * @param selector - komponenty, pro kterou je formular definovany
   */
  getCharacteristicsAndFormSpecificationByCode(
    entitySpecCode: string,
    selector: string,
    infinite = false,
    untracked = false,
  ): Observable<{
    characteristics: Characteristics[];
    form: Form;
  }> {
    return entitySpecCode
      ? this.store.pipe(
          loadAndWait(
            LoadEntitySpecificationByCode({code: entitySpecCode}),
            selectEntitySpecificationByCode(entitySpecCode),
            infinite,
            untracked,
          ),
          switchMap((x) => {
            const tmpFormSpecification = x.data.formSpecification;
            if (tmpFormSpecification && tmpFormSpecification[selector]) {
              return combineLatest([
                of(x.data.expandedCharacteristics),
                loadAndWaitForStore(
                  this.store,
                  LoadFormByCode({code: tmpFormSpecification[selector]}),
                  selectFormByCode(tmpFormSpecification[selector]),
                ).pipe(map((y) => y.data)),
              ]).pipe(
                map(([chars, form]) => ({
                  characteristics: chars,
                  form: form,
                })),
              );
            }
            return of({
              characteristics: x.data.expandedCharacteristics,
              form: null,
            });
          }),
        )
      : of();
  }

  /**
   * Funkce vrati Tsm modul a formular na zaklade tsmModuleCode a selectoru komponenty
   * @param moduleCode - root entity
   * @param selector - komponenty, pro kterou je formular definovany
   */
  getTsmModuleAndFormSpecification(
    moduleCode: string,
    selector: string,
  ): Observable<{
    module: TsmModule;
    form: Form;
  }> {
    return moduleCode
      ? this.store.pipe(
          loadAndWait(
            LoadTsmModuleByCode({code: moduleCode}),
            selectTsmModuleByCode(moduleCode),
          ),
          switchMap((x) => {
            const tmpFormSpecification = x.data.formSpecification;
            if (tmpFormSpecification && tmpFormSpecification[selector]) {
              return combineLatest([
                of(x.data),
                loadAndWaitForStore(
                  this.store,
                  LoadFormByCode({code: tmpFormSpecification[selector]}),
                  selectFormByCode(tmpFormSpecification[selector]),
                ).pipe(map((y) => y.data)),
              ]).pipe(
                map(([module, form]) => ({
                  module: module,
                  form: form,
                })),
              );
            }
            return of({
              module: x.data,
              form: null,
            });
          }),
        )
      : of();
  }
}
