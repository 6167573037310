import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {CommentTypeModel} from '../models';

export const LoadCommentType = createAction('[CommentType] Load CommentType');
export const LoadCommentTypeSuccess = createAction(
  '[CommentType] Load CommentType Success',
  props<{
    commentTypes: CommentTypeModel[];
  }>(),
);
export const LoadCommentTypeError = createAction(
  '[CommentType] Load CommentType Error',
  props<TsmError>(),
);

export const LoadCommentTypeById = createAction(
  '[CommentType] Load LoadCommentTypeById',
  props<{id: string}>(),
);
export const LoadCommentTypeByIdSuccess = createAction(
  '[CommentType] Load LoadCommentTypeById Success',
  props<{
    commentType: CommentTypeModel;
  }>(),
);
export const LoadCommentTypeByIdError = createAction(
  '[CommentType] Load LoadCommentTypeById Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);

export const LoadCommentTypeByCode = createAction(
  '[CommentType] Load CommentTypeByCode',
  props<{code: string}>(),
);
export const LoadCommentTypeByCodeSuccess = createAction(
  '[CommentType] Load CommentTypeByCode Success',
  props<{
    commentType: CommentTypeModel;
  }>(),
);
export const LoadCommentTypeByCodeError = createAction(
  '[CommentType] Load CommentTypeByCode Error',
  props<{
    code: string;
    error: any;
  }>(),
);

export const UpsertCommentType = createAction(
  '[CommentType] Upsert CommentType',
  props<{
    commentType: CommentTypeModel;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertCommentTypeSuccess = createAction(
  '[CommentType] Upsert CommentType Success',
  props<{
    commentType: CommentTypeModel;
    listingId: string;
    redirect: boolean;
  }>(),
);
export const UpsertCommentTypeError = createAction(
  '[CommentType] Upsert CommentType Error',
  props<TsmError>(),
);

export const DeleteCommentType = createAction(
  '[CommentType] Delete CommentType',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing?: boolean;
  }>(),
);
export const DeleteCommentTypeSuccess = createAction(
  '[CommentType] Delete CommentType Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteCommentTypeError = createAction(
  '[CommentType] Delete CommentType Error',
  props<TsmError>(),
);

export const DiffCommentType = createAction(
  '[CommentType] Diff CommentType',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffCommentTypeSuccess = createAction(
  '[CommentType] Diff CommentType Success',
  props<{
    commentTypes: CommentTypeModel[];
    listingId: string;
  }>(),
);
export const DiffCommentTypeError = createAction(
  '[CommentType] Diff CommentType Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
