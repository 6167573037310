export const cs = {
  addressManagementPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmRegionFilter: 'Tsm Region Filter',
      tsmRegionFilterDescription:
        'Poskytuje uživatelské rozhraní pro filtrování regionů s přizpůsobitelnými konfiguracemi. ' +
        'Integruje se se službami pro dynamickou správu filtrů, získávání vybraných entit ' +
        'a aplikuje kontextově citlivé filtry pro flexibilní použití v různých situacích.',
      tsmRegionLayerFilter: 'Tsm Region Layer Filter',
      tsmRegionLayerFilterDescription:
        'Poskytuje rozhraní pro filtrování vrstev regionů s flexibilními konfiguracemi. ' +
        'Dynamicky spravuje filtry, získává vybrané vrstvy z úložiště ' +
        'a podporuje kontextově citlivé filtrování pro vyšší flexibilitu.',
      tsmRegionTypeFilter: 'Tsm Region Type Filter',
      tsmRegionTypeFilterDescription:
        'Poskytuje rozhraní pro filtrování typů regionů pomocí zobrazení tabulky. ' +
        'Dynamicky získává a překládá typy regionů, spravuje vybrané hodnoty, ' +
        'a podporuje vícenásobný výběr pro větší flexibilitu.',
    },
    pipes: {
      addressLoader: 'Address Loader',
      addressLoaderDescription:
        'Načítá údaje o adrese podle ID z úložiště, získává specifická pole, jako je formátovaná ' +
        'adresa nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      regionCode: 'Region Code',
      regionCodeDescription:
        'Načítá údaje o regionu podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      regionLayer: 'Region Layer',
      regionLayerDescription:
        'Načítá údaje o vrstvě regionu podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      regionLayerCode: 'Region Layer Code',
      regionLayerCodeDescription:
        'Načítá údaje o vrstvě regionu podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud již nejsou dostupná.',
      regionObjectLoader: 'Region Object Loader',
      regionObjectLoaderDescription:
        'Načítá údaje o regionu podle ID z úložiště a získává celý objekt. Zajišťuje načtení dat, ' +
        'pokud již nejsou dostupná.',
    },
  },
};
