import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducer} from './reducers';
import {StateCoalescePipe} from './pipes/state-coalesce.pipe';

@NgModule({
  imports: [StoreModule.forFeature('localState', reducer, {})],
  declarations: [StateCoalescePipe],
  exports: [StateCoalescePipe],
})
export class LocalStateModule {}
