export const cs = {
  scriptingPlugins: {
    headerDescription: 'Hlavička dokumentace',
    pipes: {
      scriptCodeLoader: 'Script Code Loader',
      scriptCodeLoaderDescription:
        'Získává data skriptu pomocí zadaného kódu. Načítá podrobnosti o skriptu z úložiště a ' +
        'umožňuje přístup ke specifickým vlastnostem prostřednictvím parametru pole. ' +
        'Zajišťuje načtení dat, pokud nejsou dostupná v úložišti.',
      scriptLoader: 'Script Loader',
      scriptLoaderDescription:
        'Načítá podrobnosti skriptu podle zadaného ID. Získává data skriptu z úložiště a umožňuje ' +
        'přístup ke specifickým atributům přes parametr pole. Zajišťuje načtení dat, pokud nejsou dostupná.',
    },
  },
};
