import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertMinutesToHoursMinutes',
  pure: true,
  standalone: true,
})
export class ConvertMinutesToHoursMinutesPipe implements PipeTransform {
  transform(value: string | number, ignoreZeros?: boolean): any {
    const minutes = typeof value === 'string' ? parseInt(value) : value;
    const isNegative = minutes < 0;
    const absMinutes = Math.abs(minutes);
    const h = Math.floor(absMinutes / 60);
    const min = absMinutes % 60;

    if (ignoreZeros) {
      // Pokud je záporné, přidáme mínus na začátek, ale samotné hodnoty jsou počítány z absolutní hodnoty.
      if (isNegative) {
        const hStr = h > 0 ? h + 'h ' : '';
        const mStr = min > 0 ? min + 'min' : '';
        const result = hStr || mStr ? (hStr + mStr).trim() : '0min';
        return '-' + result;
      } else {
        const hStr = h > 0 ? h + 'h ' : '';
        const mStr = min > 0 ? min + 'min' : '';
        const result = hStr || mStr ? (hStr + mStr).trim() : '0min';
        return result;
      }
    } else {
      // Bez ignorování nul: vždy zobrazíme i nulové hodnoty.
      if (isNegative) {
        return `-${h}h ${min}min`;
      } else {
        return `${h}h ${min}min`;
      }
    }
  }
}
