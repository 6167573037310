import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  concatMap,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  AddKanbanList,
  ChangeFiltersKanbanList,
  ChangeKanbanList,
  ChangeOrderKanbanLists,
  ChangeSelectedProfile,
  ChangeSelectedProfileDuringSetup,
  ClearProfile,
  ClearProfileError,
  ClearProfileSuccess,
  ColorsChanged,
  ColumnsChanged,
  DataViewChangeMode,
  DeleteProfile,
  DeleteProfileError,
  DeleteProfileSuccess,
  FilterChanged,
  FiltersChanged,
  FiltersChangedIfNew,
  FiltersChangedMerge,
  FiltersChangedMergeWithoutRefresh,
  FiltersChangedWithoutRefresh,
  InitColumnsChanged,
  InitTable,
  LoadData,
  LoadDataError,
  LoadDataKanbanList,
  LoadDataSuccess,
  LoadListingTypeByCode,
  LoadProfiles,
  LoadProfilesError,
  LoadProfilesSuccess,
  LoadTable,
  LoadTableColumnConfigsByListingId,
  LoadTableColumnConfigsByListingIdError,
  LoadTableColumnConfigsByListingIdSuccess,
  LoadTableSuccess,
  LoadTotalData,
  LoadTotalDataError,
  LoadTotalDataSuccess,
  PageAndPageSizeChanged,
  PageChanged,
  PageSizeChanged,
  RefreshData,
  RefreshDataAndClearSelected,
  RefreshProfiles,
  RefreshProfilesError,
  RefreshProfilesFor,
  RefreshProfilesForSuccess,
  RefreshProfilesSuccess,
  RefreshProfilesSuccessWithoutDataReload,
  RefreshSwitchMapData,
  RefreshSwitchMapDataAndClearSelected,
  ResetData,
  ResetTableStateToDefaultByCurrentProfile,
  ResetTableStateWithoutDataReload,
  SaveAsDefaultAllProfile,
  SaveAsDefaultAllProfileSuccess,
  SaveProfile,
  SaveProfileError,
  SaveProfileSuccess,
  SaveUserDefaultProfile,
  SelectedProfileChangedSuccess,
  SelectedProfileDuringSetupChangedSuccess,
  SelectedRowIdChanged,
  SetupProfileUrlFromExistingMemory,
  SetUpTableSettings,
  SortsChanged,
  TreeNodeExpandAll,
  TreeNodeExpandAllBackend,
  TreeNodeExpandAllFinished,
  TreeNodeExpandCollapse,
  TreeNodeExpandCollapseFinished,
  UrlChanged,
} from '../actions';
import {
  ListingDataService,
  ListingProfileService,
  ListingService,
  TableColumnConfigService,
} from '../services';
import {
  concatBy,
  exhaustBy,
  loadAndWaitForStore,
  withLatestCached,
} from '@tsm/framework/root';
import {distinctArrays} from '@tsm/framework/functions';
import {forkJoin, Observable, of} from 'rxjs';
import {translation} from '../i18n';
import {TranslocoService} from '@tsm/framework/translate';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {ActivatedRoute, Router} from '@angular/router';
import {RuntimeService} from '@tsm/runtime-info';
import {
  compareFilters,
  getChildItems,
  getLocalStorageListing,
  mergeFilters,
  setLocalStorageListing,
} from '../utils';
import {
  DataViewModeEnum,
  emptyGridDataHttpModel,
  Table,
  TableColumn,
  TableData,
  TableKanban,
} from '../models';
import {Action, Store} from '@ngrx/store';
import {getListingState, selectListingTypeByCode} from '../selectors';

@Injectable()
export class ListingEffects {
  translation = translation;

  initTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitTable),
      withLatestCached(({payload}) =>
        this.listingService.getTableState(payload.id),
      ),
      mergeMap(([{payload}, state]) => {
        if (state != null) {
          const actions = [];

          // POZOR musi se znovu setnout (nejmene columny) pokud ne bude tam stara instance columnu a vede k neuveritelnym problemum
          // jako napr. tlacitko/callback s menu columnem je jina instance columnu vede k race condition atd.
          // musi se resetnout znovu columny z payloadu, protoze se tam pouziva u tlacitek this - muze vest k tomu ze this ukazuje na jinou instanci pokud se nesetnou znovu

          // TYHLE DVA ŘÁDKY TU BODOU, JEJICH ZAKOMENTOVANI ROZBIJE KONTEXTOVE MENU TABU NA CETINU MINIMALNE, JAKOBY TU JIZ NEBYLO DOST KOMENTARU :-)
          const newColumnsFields = payload.defaults.columns.map((x) => x.field);
          // TODO
          const newColumns = state.columns.map((col) =>
            newColumnsFields.includes(col.field)
              ? {
                  ...payload.defaults.columns
                    .filter((x) => x != null)
                    .find((x) => x.field === col.field),
                  visible: col.visible,
                  visibleCard: col.visibleCard,
                  displayAllowed: col.displayAllowed,
                  converter: col.converter,
                  converterParams: col.converterParams,
                  convertOnBackend: col.convertOnBackend,
                  filterFulltextSearch: col.filterFulltextSearch,
                  filterWidget: col.filterWidget,
                  filterWidgetContext: col.filterWidgetContext,
                  applyDefaultValueIfFilterMissing:
                    col.applyDefaultValueIfFilterMissing,
                  defaultValue: col.defaultValue,
                  customTqlExpression: col.customTqlExpression,
                  width: col.width,
                  inputWidget: col.inputWidget,
                  inputWidgetConfig: col.inputWidgetConfig,
                  inputWidgetPrivilege: col.inputWidgetPrivilege,
                  inputWidgetSpelCode: col.inputWidgetSpelCode,
                  tooltip: col.tooltip,
                  header: col.header,
                  dynamicComponent: col.dynamicComponent,
                  localizationData: col.localizationData,
                  cssClass: col.cssClass,
                }
              : {...col},
          );
          // POUZE TENHLE RADEK TO ROZBIJE
          // const newColumns = state.columns.map(col => ({...col}));
          actions.push(
            InitColumnsChanged({id: payload.id, columns: newColumns}),
          );

          if (payload.defaults.defaultView != null) {
            actions.push(
              DataViewChangeMode({
                id: payload.id,
                changeMode: payload.defaults.defaultView,
              }),
            );
          }

          if (payload?.showConfig?.disabledFirstLoadData === true) {
            actions.push(
              LoadDataSuccess({data: emptyGridDataHttpModel, id: payload.id}),
            );
            return actions;
          }

          actions.push(LoadData({id: payload.id}));

          return actions;
        }
        return !payload.showConfig ||
          payload.showConfig.showProfiles !== false ||
          payload.showConfig.loadSilenceProfiles === true
          ? [LoadTableColumnConfigsByListingId({payload, loadType: 'PROFILES'})]
          : [LoadTableColumnConfigsByListingId({payload, loadType: 'TABLE'})];
      }),
    ),
  );

  setupProfileUrlFromExistingMemory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetupProfileUrlFromExistingMemory),
      withLatestCached(({payload}) =>
        this.listingService.getTableState(payload.id),
      ),
      filter(([_, state]) => state != null && state.selectedProfileId != null),
      map(([{payload}, state]) => {
        const url = this.router.url;
        const currentNavigation = this.router.getCurrentNavigation();
        if (
          !(url.includes('dashboard') || state.showConfig.disableProfileUrl)
        ) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {profile: state.selectedProfileId},
            queryParamsHandling: 'merge',
            replaceUrl: true,
            state: {
              ...currentNavigation?.extras?.state,
              setToStore: false,
            },
          });
        }
        //console.log('SetupProfileUrlFromExistingMemory');
        return SetUpTableSettings({id: payload.id, defaults: payload.defaults});
      }),
    ),
  );

  loadTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTable),
      map(({payload}) =>
        LoadTableSuccess({
          id: payload.id,
        }),
      ),
    ),
  );

  loadProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProfiles),
      mergeMap(({payload}) => {
        //console.log('LoadProfiles');
        return this.listingProfileService.getTableProfiles(payload.type).pipe(
          map((envelop) => {
            if (envelop.success) {
              return LoadProfilesSuccess({
                profiles: envelop.data,
                id: payload.id,
                payload,
              });
            }
            return LoadProfilesError({
              id: payload.id,
              error: this.translocoService.translate(
                translation.listingLib.effects.profileLoadError,
              ),
            });
          }),
        );
      }),
    ),
  );

  loadProfilesError$ = createEffect(
    () =>
      // eslint-disable-next-line rxjs/no-cyclic-action
      this.actions$.pipe(
        ofType(LoadProfilesError),
        tap(() =>
          this.toastService.showToast(
            translation.listingLib.effects.profileLoadError,
            ToastSeverity.ERROR,
          ),
        ),
      ),
    {dispatch: false},
  );

  refreshProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshProfiles),
      mergeMap(({id, listingType, reloadData}) =>
        this.listingProfileService.getTableProfiles(listingType.type).pipe(
          map((envelop) => {
            if (envelop.success) {
              if (reloadData == false) {
                return RefreshProfilesSuccessWithoutDataReload({
                  id: id,
                  profiles: envelop.data,
                });
              }
              return RefreshProfilesSuccess({
                id: id,
                profiles: envelop.data,
              });
            } else {
              return RefreshProfilesError({
                id: id,
                error: this.translocoService.translate(
                  translation.listingLib.effects.profileLoadError,
                ),
              });
            }
          }),
        ),
      ),
    ),
  );

  loadProfilesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProfilesSuccess),
      withLatestCached((action) =>
        this.listingService.getTableState(action.payload.id),
      ),
      map(([{id, payload}, state]) => {
        let profileId = payload.defaults.profileId;
        if (profileId == null || profileId === 'empty') {
          profileId = state.selectedProfileId;
        }
        if (
          (!payload.showConfig || payload.showConfig.showProfiles === true) &&
          profileId !== 'empty' &&
          profileId != null &&
          state.profiles.find((x) => x.id == profileId) != null
        ) {
          const url = this.router.url;
          if (
            url.includes('dashboard') ||
            payload.showConfig.disableProfileUrl
          ) {
            return ChangeSelectedProfileDuringSetup({
              id,
              selectedProfileId: profileId,
              payload,
            });
          } else {
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {profile: profileId},
              queryParamsHandling: 'merge',
              state: {
                duringSetup: true,
              },
            });
          }
        }
        //console.log('LoadProfilesSuccess');
        return SetUpTableSettings({id: id, defaults: payload.defaults});
      }),
    ),
  );

  // eslint-disable-next-line rxjs/finnish
  filtersChangedIfNew = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersChangedIfNew),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      filter(([_, state]) => state != null),
      filter(
        ([action, state]) => !compareFilters(state.filters, action.filters),
      ),
      map(([action, _]) =>
        FiltersChanged({
          id: action.id,
          filters: action.filters,
        }),
      ),
    ),
  );

  filtersChangedMerge = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersChangedMerge),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      filter(([_, state]) => state != null),
      map(([action, state]) => {
        const newFilters = mergeFilters(state.filters, action.filters);
        return FiltersChanged({
          id: action.id,
          filters: newFilters,
        });
      }),
    ),
  );

  filtersChangedMergeWithoutRefresh = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersChangedMergeWithoutRefresh),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      filter(([_, state]) => state != null),
      map(([action, state]) => {
        const newFilters = mergeFilters(state.filters, action.filters);
        return FiltersChangedWithoutRefresh({
          id: action.id,
          filters: newFilters,
        });
      }),
    ),
  );

  selectedProfileDuringSetupChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelectedProfileDuringSetupChangedSuccess),
      map(({id, payload}) =>
        SetUpTableSettings({id, defaults: payload.defaults}),
      ),
    ),
  );

  loadElasticColumnsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfigsByListingIdSuccess),
      exhaustBy(
        ({tableColumnConfigs, payload, loadType}) => payload.id,
        ({tableColumnConfigs, payload, loadType}) => {
          const observers = tableColumnConfigs.map((col) =>
            this.tableColumnService.mapColumnConfigToTableColumn(
              col,
              payload.type,
            ),
          );
          return forkJoin([
            of(payload),
            of(loadType),
            of(tableColumnConfigs),
            ...observers,
          ]);
        },
      ),
      map(
        ([
          tmpPayload,
          loadType,
          tableColumnConfigs,
          ...columnsFromObservers
        ]) => {
          let columns = tmpPayload.defaults.columns;
          let listingType = null;
          if (columnsFromObservers && columnsFromObservers.length > 0) {
            listingType = tableColumnConfigs[0].listing;
            // dojde k sestaveni noveho pole sloupcu ocisteneho o atribut exportConverter (nikdy to ulozeny v DB nebude, musim nechat original)
            const tmpColumnsFromObservers = columnsFromObservers.map((x) => {
              const {exportConverter, ...resultObject} = x;
              return resultObject;
            });
            // udelat merge sloupcu + zachovat poradi dle toho jak jsou nastaveny dynamicke sloupce
            columns = distinctArrays(
              'field',
              tmpPayload.defaults.columns,
              tmpColumnsFromObservers,
            ).sort(
              (a, b) =>
                (a.defaultFieldPosition ?? 99999999) -
                (b.defaultFieldPosition ?? 99999999),
            );
            // pokud ma nejaky sloupec definovanou dynamickou kompoponentu, tak ji nastavit!!!
            tmpPayload.defaults.columns
              .filter((dCol) => (dCol as TableColumn).dynamicComponent != null)
              .forEach((dCol) => {
                columns.find((c) => c.field == dCol.field).dynamicComponent =
                  dCol.dynamicComponent;
              });
            // pokud ma nejaky defaultni (je v kodu ale neni v DB) sloupec definovany input widget a nema definovane presne privilegium tak ho sestav a dopln
            if (tmpPayload.type?.parentPrivilege) {
              tmpPayload.defaults.columns
                .filter(
                  (dCol) =>
                    (dCol as TableColumn).inputWidget != null &&
                    !dCol.inputWidgetPrivilege,
                )
                .forEach((dCol) => {
                  dCol.inputWidgetPrivilege = `${tmpPayload.type.parentPrivilege}#${dCol.field}`;
                });
            }

            // otazka je, zda chci pripadne doplnovat definice sloupecku z kodu, pokud nejsou v DB (nejspis ne)
            // tmpPayload.defaults.columns.filter(dCol => (dCol as TableColumn).inputWidget != null).forEach(dCol => {
            //   const col = columns.find(c => c.field == dCol.field);
            //   if (col.inputWidget == null) {
            //     col.inputWidget = dCol.inputWidget;
            //     col.inputWidgetConfig = dCol.inputWidgetConfig;
            //     col.inputWidgetPrivilege = dCol.inputWidgetPrivilege;
            //   }
            // });
          }
          return loadType === 'PROFILES'
            ? LoadProfiles({
                payload: {
                  ...tmpPayload,
                  defaults: {
                    ...tmpPayload.defaults,
                    columns,
                  },
                  type: {
                    ...tmpPayload.type,
                    config: {
                      ...(tmpPayload.type?.config || {}),
                      ...(listingType?.config || {}),
                    },
                  },
                },
              })
            : LoadTable({
                payload: {
                  ...tmpPayload,
                  defaults: {
                    ...tmpPayload.defaults,
                    columns,
                  },
                  type: {
                    ...tmpPayload.type,
                    config: {
                      ...(tmpPayload.type?.config || {}),
                      ...(listingType?.config || {}),
                    },
                  },
                },
              });
        },
      ),
    ),
  );

  loadElasticColumnsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTableColumnConfigsByListingIdError),
      map(({payload, loadType}) => {
        return loadType === 'PROFILES'
          ? LoadProfiles({payload: payload})
          : LoadTable({payload: payload});
      }),
    ),
  );

  saveAsDefaultAllProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveAsDefaultAllProfile),
      mergeMap((action) => {
        return this.listingProfileService
          .removeDefaultProfile(action.payload.profile, action.payload.type)
          .pipe(
            map((envelop) => {
              if (envelop.success) {
                this.toastService.showToast(
                  translation.listingLib.effects.profileSaveSuccess,
                );
                return SaveAsDefaultAllProfileSuccess({
                  payload: {
                    id: action.payload.id,
                    type: action.payload.type,
                    profile: envelop.data,
                    reloadData: action.payload.reloadData,
                  },
                });
              } else {
                this.toastService.showError(
                  envelop.error,
                  translation.listingLib.effects.profileSaveError,
                );
                return SaveProfileError({
                  id: action.payload.id,
                  error: envelop.error,
                });
              }
            }),
          );
      }),
    ),
  );

  saveUserDefaultProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveUserDefaultProfile),
      withLatestCached((action) =>
        this.listingService.getTableState(action.payload.id),
      ),
      mergeMap(([action, table]) => {
        return this.listingProfileService
          .saveUserDefaultProfile(
            action.payload.profile,
            table,
            action.payload.type,
          )
          .pipe(
            map((envelop) => {
              if (envelop.success) {
                if (envelop.data.isUserDefault) {
                  this.toastService.showToast(
                    translation.listingLib.effects.profileAddDefaultSaveSuccess,
                  );
                } else {
                  this.toastService.showToast(
                    translation.listingLib.effects
                      .profileRemoveDefaultSaveSuccess,
                  );
                }
                return SaveProfileSuccess({
                  payload: {
                    id: action.payload.id,
                    type: action.payload.type,
                    profile: envelop.data,
                    reloadData: action.payload.reloadData,
                  },
                });
              } else {
                if (envelop.data.isUserDefault) {
                  this.toastService.showError(
                    envelop.error,
                    translation.listingLib.effects.profileAddDefaultSaveError,
                  );
                } else {
                  this.toastService.showError(
                    envelop.error,
                    translation.listingLib.effects
                      .profileRemoveDefaultSaveError,
                  );
                }
                return SaveProfileError({
                  id: action.payload.id,
                  error: envelop.error,
                });
              }
            }),
          );
      }),
    ),
  );

  saveProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveProfile),
      withLatestCached((action) =>
        this.listingService.getTableState(action.payload.id),
      ),
      mergeMap(([action, table]) => {
        return this.listingProfileService
          .saveProfile(action.payload.profile, table, action.payload.type)
          .pipe(
            map((envelop) => {
              if (envelop.success) {
                this.toastService.showToast(
                  translation.listingLib.effects.profileSaveSuccess,
                );
                return SaveProfileSuccess({
                  payload: {
                    id: action.payload.id,
                    type: action.payload.type,
                    profile: envelop.data,
                    reloadData: action.payload.reloadData,
                  },
                });
              } else {
                this.toastService.showError(
                  envelop.error,
                  translation.listingLib.effects.profileSaveError,
                );
                return SaveProfileError({
                  id: action.payload.id,
                  error: envelop.error,
                });
              }
            }),
          );
      }),
    ),
  );

  clearProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearProfile),
      withLatestCached(({id}) => this.listingService.getTableState(id)),
      map(([{id}, table]) =>
        table?.selectedProfileId != null
          ? ClearProfileSuccess({id})
          : ClearProfileError({id}),
      ),
    ),
  );

  clearProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearProfileSuccess),
      map(({id}) => ResetData({id})),
    ),
  );

  deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteProfile),
      mergeMap(({payload}) => {
        return this.listingProfileService.deleteProfile(payload.profile).pipe(
          exhaustMap((envelop) => {
            if (envelop.success) {
              this.toastService.showToast(
                translation.listingLib.effects.profileDeleteSuccess,
              );
              return of(
                DeleteProfileSuccess({
                  payload: {
                    id: payload.id,
                    profile: payload.profile,
                    type: payload.type,
                    reloadData: payload.reloadData,
                  },
                }),
              );
            } else {
              this.toastService.showError(
                envelop.error,
                translation.listingLib.effects.profileDeleteError,
              );
              return of(
                DeleteProfileError({
                  id: payload.id,
                  error: envelop.error,
                }),
              );
            }
          }),
        );
      }),
    ),
  );

  setProfileByUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SaveProfileSuccess, DeleteProfileSuccess),
        withLatestCached(({payload}) =>
          this.listingService.getTableState(payload.id),
        ),
        tap(([{payload, type}, table]) => {
          this.listingService.setSelectedProfile(
            payload.id,
            type === DeleteProfileSuccess.type ? null : payload.profile.id,
            table.showConfig,
          );
        }),
      ),
    {dispatch: false},
  );

  reloadDataWhenStateChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UrlChanged,
        PageChanged,
        PageSizeChanged,
        PageAndPageSizeChanged,
        FiltersChanged,
        FilterChanged,
        SortsChanged,
        ColumnsChanged,
        ColorsChanged,
        SelectedProfileChangedSuccess,
        LoadTableSuccess,
        SetUpTableSettings,
      ),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      map(([action, table]) => {
        if (table?.showConfig?.disabledFirstLoadData === true) {
          if (
            action.type === LoadTableSuccess.type ||
            action.type === SetUpTableSettings.type ||
            (action.type === SelectedProfileChangedSuccess.type &&
              this.router.getCurrentNavigation()?.extras?.state?.setToStore ==
                null)
          ) {
            return LoadDataSuccess({
              data: emptyGridDataHttpModel,
              id: action.id,
              withoutLoading: true,
            });
          } else if (table.type.url == null) {
            return LoadDataSuccess({
              data: emptyGridDataHttpModel,
              id: action.id,
            });
          }
        }
        return LoadData({id: action.id});
      }),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ResetData,
        RefreshProfilesSuccess,
        RefreshData,
        RefreshDataAndClearSelected,
        DataViewChangeMode,
      ),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      filter(([_, table]) => {
        return !table?.isTreeExpanding;
      }),
      map(([{id, type}, table]) => {
        return LoadData({id});
      }),
    ),
  );

  refreshSwitchMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshSwitchMapData, RefreshSwitchMapDataAndClearSelected),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      filter(([_, table]) => {
        return !table?.isTreeExpanding;
      }),
      switchMap(([{id, type}, table]) => {
        return of(LoadData({id}));
      }),
    ),
  );

  treeNodeExpandAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TreeNodeExpandAll),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      concatBy(
        ([action, _]) => action.id,
        ([action, state]) => {
          // sestaveny stromecek tree
          let tree = [];
          const createTreeRecursive = (
            data: TableData,
            rowIdField: string,
            parentField: string,
            parentItems?: any[],
          ): void => {
            const items =
              parentItems != null
                ? getChildItems(data, rowIdField, parentField, parentItems)
                : getChildItems(data, rowIdField, parentField);

            if (items.length > 0) {
              tree = [
                ...tree,
                ...items
                  .filter((x) => !tree.includes(x))
                  .map((x) => x[rowIdField]),
              ];
              createTreeRecursive(data, rowIdField, parentField, items);
            }
          };
          createTreeRecursive(
            state.data,
            state.defaults.rowIdField,
            state.tree.parentField,
          );

          const notExpandedTreeSorted = tree.filter(
            (x) => !state.data.expandedItems.includes(x),
          );
          if (notExpandedTreeSorted.length === 0) {
            if (action.selectedRowId) {
              return of(
                SelectedRowIdChanged({
                  id: action.id,
                  selectedRowId: action.selectedRowId,
                }),
              );
            }
            return of(TreeNodeExpandAllFinished({id: action.id}) as Action);
          }
          const foundId = notExpandedTreeSorted[0];

          return of(
            TreeNodeExpandCollapse({
              id: action.id,
              expandAll: true,
              selectedRowId: action.selectedRowId,
              nodeData: {expanded: true, nodeId: foundId},
            }),
          );
        },
      ),
    ),
  );

  loadDataSuccessExpandAllContinue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TreeNodeExpandCollapseFinished),
      filter((action) => action.expandAll),
      map((action) =>
        TreeNodeExpandAll({
          id: action.id,
          selectedRowId: action.selectedRowId,
        }),
      ),
    ),
  );

  treeNodeExpandAllBackend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TreeNodeExpandAllBackend),
      concatBy(
        ({id}) => id,
        (action) => {
          // if (!action.nodeData.expanded) {
          //   return of(TreeNodeExpandCollapseFinished({
          //     id: action.id,
          //     expandAll: action.expandAll,
          //     selectedRowId: action.selectedRowId
          //   }) as Action);
          // }
          return of(
            LoadData({
              id: action.id,
              isSubTree: true,
              expandAll: false,
              parentValue: action.nodeData.nodeId,
            }),
          );
        },
      ),
    ),
  );

  treeNodeExpandItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TreeNodeExpandCollapse),
      concatBy(
        ({id}) => id,
        (action) => {
          if (!action.nodeData.expanded) {
            return of(
              TreeNodeExpandCollapseFinished({
                id: action.id,
                expandAll: action.expandAll,
                selectedRowId: action.selectedRowId,
              }) as Action,
            );
          }
          return of(
            LoadData({
              id: action.id,
              isSubTree: true,
              expandAll: action.expandAll,
              parentValue: action.nodeData.nodeId,
              selectedRowId: action.selectedRowId,
            }),
          );
        },
      ),
    ),
  );

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadData),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id).pipe(
          // pokud mam zobrazeni kanbanu tak nechci delat zakladni reload dat
          filter(
            (table) =>
              table != null && table.dataViewMode !== DataViewModeEnum.KANBAN,
          ),
          switchMap((table) =>
            this.store.select(selectListingTypeByCode(table.type.type)).pipe(
              map((x) => ({
                tqlName: x?.data?.tqlName,
                table: table,
              })),
            ),
          ),
        ),
      ),
      // filter(([action, table]) => table != null),
      // withLatestCached(([action, table]) => this.store.select(selectListingTypeByCode(table.type.type)).pipe(map(x => x?.data?.tqlName))),
      concatBy(
        ([action, {tqlName, table}]) => action.id,
        ([action, {tqlName, table}]) => {
          if (table.type.queryLanguage === 'TQL') {
            if (tqlName == null && !table.type.tqlName) {
              return loadAndWaitForStore(
                this.store,
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
              ).pipe(
                switchMap((x) =>
                  this.fetchTqlRequest(table, action, x?.data?.tqlName),
                ),
              );
            }
            return this.fetchTqlRequest(
              table,
              action,
              tqlName || table.type.tqlName,
            );
          }
          return this.fetchRequest(table, action);
        },
      ),
    ),
  );

  loadDataError$ = createEffect(
    () =>
      // eslint-disable-next-line rxjs/no-cyclic-action
      this.actions$.pipe(
        ofType(LoadDataError),
        tap(({error, errorMsgDurationSeconds}) => {
          this.toastService.showError(
            error,
            translation.listingLib.effects.loadDataError,
            errorMsgDurationSeconds,
          );
        }),
      ),
    {dispatch: false},
  );

  loadDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDataSuccess),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      concatMap(([{id, data, expandAll, selectedRowId}, table]) => {
        const resultActions = table?.data.expandedItems
          // .filter(expandedId => data.content.some(x => x.id === expandedId && !table.data.items.some((y: any) => y.id === expandedId)))
          .filter((expandedId) =>
            data.content.some(
              (x) =>
                (!!table.tree.parentValueField
                  ? x[table.tree.parentValueField]
                  : x.id) === expandedId,
            ),
          )
          .map((expandedId) =>
            TreeNodeExpandCollapse({
              id,
              nodeData: {nodeId: expandedId, expanded: true},
              expandAll,
              selectedRowId,
            }),
          );

        // hlida zda nemam za potomka sveho rodice (A -> B a B -> A)
        const nodeIds = resultActions.map((y) => y.nodeData.nodeId);
        const findCyclic =
          nodeIds?.length > 0
            ? table?.data.items
                .filter(
                  (x: any) =>
                    !nodeIds.includes(
                      !!table.tree.parentValueField
                        ? x[table.tree.parentValueField]
                        : x.id,
                    ),
                ) // najdi vsechny data mimo me
                .filter((x: any) =>
                  (typeof x[table.tree.parentField] === 'string'
                    ? [x[table.tree.parentField]]
                    : x[table.tree.parentField]
                  )?.some((y) => {
                    return nodeIds.includes(y);
                  }),
                )
            : [];
        // najdi meho cyklickeho potomka
        if (findCyclic != null && findCyclic.length > 0) {
          console.warn(findCyclic, '- want to have my parent as a child');
          // this.toastService.showToast(
          //   this.translocoService.translate(translation.listingLib.effects.treeCircleErrorMsg, {item: findCyclic.map(x => (x as any)?.name).join(", ")}),
          //   ToastSeverity.WARN);
          return [
            TreeNodeExpandCollapseFinished({id, expandAll, selectedRowId}),
          ];
        }
        return resultActions.length > 0
          ? resultActions
          : [
              TreeNodeExpandCollapseFinished({
                id,
                expandAll,
                selectedRowId,
              }),
            ];
      }),
    ),
  );

  loadDataTotal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTotalData),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id).pipe(
          // pokud mam zobrazeni kanbanu tak nechci delat zakladni reload dat
          filter(
            (table) =>
              table != null && table.dataViewMode !== DataViewModeEnum.KANBAN,
          ),
          switchMap((table) =>
            this.store.select(selectListingTypeByCode(table.type.type)).pipe(
              map((x) => ({
                tqlName: x?.data?.tqlName,
                table: table,
              })),
            ),
          ),
        ),
      ),
      concatBy(
        ([action, {tqlName, table}]) => action.id,
        ([action, {tqlName, table}]) => {
          if (table.type.queryLanguage === 'TQL') {
            if (tqlName == null && !table.type.tqlName) {
              return loadAndWaitForStore(
                this.store,
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
              ).pipe(
                switchMap((x) =>
                  this.fetchTqlTotalRequest(table, action, x?.data?.tqlName),
                ),
              );
            }
            return this.fetchTqlTotalRequest(
              table,
              action,
              tqlName || table.type.tqlName,
            );
          }
          return of(
            LoadTotalDataSuccess({
              data: {},
              id: action.id,
            }),
          );
        },
      ),
    ),
  );

  refreshProfilesFor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshProfilesFor),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      map(([action, table]) => {
        if (table) {
          return RefreshProfiles({
            id: table.id,
            listingType: table.type,
            reloadData: action.reloadData,
          });
        }
        return RefreshProfilesForSuccess({id: table?.id});
      }),
    ),
  );

  /**
   * Resetuje nastaveni tabulky podle aktualniho profilu. Pokud neni zvoleny profil nastavi se na defaultni.
   */
  resetTableStateToCurrentOrDefaultProfileDefaults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetTableStateToDefaultByCurrentProfile),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      mergeMap(([{id}, table]) => {
        const profileId =
          table.selectedProfileId ||
          table.profiles.find(
            (pf) =>
              (!!table.profileCategory
                ? table.profileCategory === pf.profileCategory
                : !pf.profileCategory) && pf.isUserDefault,
          )?.id ||
          table.profiles.find(
            (pf) =>
              (!!table.profileCategory
                ? table.profileCategory === pf.profileCategory
                : !pf.profileCategory) && pf.isDefault,
          )?.id;

        const url = this.router.url;
        if (profileId) {
          if (
            !(url.includes('dashboard') || table.showConfig.disableProfileUrl)
          ) {
            const currentNavigation = this.router.getCurrentNavigation();
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {profile: profileId},
              queryParamsHandling: 'merge',
              state: {
                ...currentNavigation?.extras?.state,
                setToStore: false,
              },
            });
          }
          return [
            ChangeSelectedProfile({
              id: id,
              selectedProfileId: profileId,
              mergeProfileFilters: false,
            }),
            ResetTableStateWithoutDataReload({id: id}),
          ];
        } else {
          return [ResetData({id: id})];
        }
      }),
    ),
  );

  listingStateLocalStore$ = createEffect(
    () =>
      this.store.select(getListingState).pipe(
        filter((_) => (window as any)?.app?.params?.listingStateLocalStore),
        // dulezite
        debounceTime(2000),
        map((x) => {
          let resultJson = getLocalStorageListing();
          Object.keys(x.entities).forEach((key) => {
            const entity = x.entities[key];
            if (entity.showConfig?.disableProfileUrl !== true) {
              resultJson = {
                ...resultJson,
                [key]: this.serializationState(entity),
              };
            }
          });
          setLocalStorageListing(resultJson);
        }),
      ),
    {dispatch: false},
  );

  /**
   * SEKCE KANBAN
   */
  refreshKanbanList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddKanbanList, ChangeFiltersKanbanList),
      concatMap(({id, kanban}) =>
        of(LoadDataKanbanList({id: id, kanban: kanban})),
      ),
    ),
  );

  refreshKanbanListByTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FiltersChanged,
        RefreshData,
        SortsChanged,
        ColumnsChanged,
        DataViewChangeMode,
        SelectedProfileChangedSuccess,
        ChangeOrderKanbanLists,
      ),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id),
      ),
      filter(
        ([action, table]) => table?.dataViewMode === DataViewModeEnum.KANBAN,
      ),
      mergeMap(([action, table]) =>
        table.kanban.map((x) => LoadDataKanbanList({id: action.id, kanban: x})),
      ),
    ),
  );

  loadDataForKanbanList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDataKanbanList),
      withLatestCached((action) =>
        this.listingService.getTableState(action.id).pipe(
          filter((table) => table != null),
          switchMap((table) =>
            this.store.select(selectListingTypeByCode(table.type.type)).pipe(
              map((x) => ({
                tqlName: x?.data?.tqlName,
                table: table,
              })),
            ),
          ),
        ),
      ),
      concatBy(
        ([action, {tqlName, table}]) => action.id,
        ([{id, kanban}, {tqlName, table}]) => {
          if (table.type.queryLanguage === 'TQL') {
            if (tqlName == null && !table.type.tqlName) {
              return loadAndWaitForStore(
                this.store,
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
              ).pipe(
                switchMap((x) =>
                  this.fetchTqlRequestKanban(
                    table,
                    kanban,
                    {id: table.id},
                    x?.data?.tqlName,
                  ),
                ),
              );
            }
            return this.fetchTqlRequestKanban(
              table,
              kanban,
              {id: table.id},
              tqlName || table.type.tqlName,
            );
          }
          return this.fetchRequestKanban(table, kanban, {id: table.id});
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private listingDataService: ListingDataService,
    private listingService: ListingService,
    private listingProfileService: ListingProfileService,
    private tableColumnService: TableColumnConfigService,
    private translocoService: TranslocoService,
    private toastService: ToastService,
    private router: Router,
    private runtimeService: RuntimeService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {}

  private fetchRequest(table: Table, action): Observable<any> {
    return this.listingDataService
      .fetchData(
        table,
        table.type.url,
        {
          ...table.tree,
          parentValue: action.parentValue,
        },
        table.options,
        true,
      )
      .pipe(
        map((responseData) =>
          responseData && responseData['content'] && !responseData['error']
            ? LoadDataSuccess({
                data: responseData,
                id: action.id,
                isSubTree: action.isSubTree,
                expandAll: action.expandAll,
                selectedRowId: action.selectedRowId,
              })
            : LoadDataError({
                id: action.id,
                error: responseData['error']
                  ? {
                      timestamp: responseData['error'].timestamp,
                      message: responseData['error'].path,
                      errors: [responseData['error'].error],
                      status: responseData['error'].status,
                    }
                  : {
                      timestamp: new Date(),
                      status: responseData['content'] == null ? '422' : '400',
                      message:
                        responseData['content'] == null
                          ? translation.listingLib.effects.badContentMsg
                          : translation.listingLib.effects.unknownErrorMsg,
                      errors: [JSON.stringify(responseData)],
                    },
              }),
        ),
      );
  }

  private fetchTqlRequest(
    table: Table,
    action,
    tqlName: string,
  ): Observable<any> {
    const showTotalsColumns = table.columns.some((x) => x.showTotals);
    return this.listingDataService
      .fetchDataTql(
        table,
        table.type.url,
        tqlName,
        {
          ...table.tree,
          parentValue: action.parentValue,
        },
        table.options,
        true,
      )
      .pipe(
        mergeMap((responseData) => {
          const actions = [];
          if (showTotalsColumns) {
            actions.push(LoadTotalData({id: table.id}));
          }
          if (
            responseData &&
            responseData['content'] &&
            !responseData['error']
          ) {
            actions.push(
              LoadDataSuccess({
                data: responseData,
                id: action.id,
                isSubTree: action.isSubTree,
                expandAll: action.expandAll,
                selectedRowId: action.selectedRowId,
              }),
            );
          } else {
            actions.push(
              LoadDataError({
                id: action.id,
                error: responseData['error']
                  ? {
                      timestamp: responseData['error'].timestamp,
                      message: responseData['error'].path,
                      errors: [responseData['error'].error],
                      status: responseData['error'].status,
                    }
                  : {
                      timestamp: new Date(),
                      status: responseData['content'] == null ? '422' : '400',
                      message:
                        responseData['content'] == null
                          ? translation.listingLib.effects.badContentMsg
                          : translation.listingLib.effects.unknownErrorMsg,
                      errors: [JSON.stringify(responseData)],
                    },
              }),
            );
          }
          return actions;
        }),
      );
  }

  private fetchRequestKanban(
    table: Table,
    kanban: TableKanban,
    action?: any,
  ): Observable<any> {
    return this.listingDataService
      .fetchData(
        {
          ...table,
          filters: distinctArrays('field', table.filters, kanban.filters),
          page: 1,
          pageSize: 100,
        },
        table.type.url,
        {
          ...table.tree,
          parentValue: action.parentValue,
        },
        table.options,
        true,
      )
      .pipe(
        map((responseData) =>
          responseData && responseData['content'] && !responseData['error']
            ? ChangeKanbanList({
                id: action.id,
                kanban: {...kanban, data: responseData.content, loading: false},
              })
            : ChangeKanbanList({
                id: action.id,
                kanban: {
                  ...kanban,
                  loading: false,
                  error: responseData['error']
                    ? {
                        timestamp: responseData['error'].timestamp,
                        message: responseData['error'].path,
                        errors: [responseData['error'].error],
                        status: responseData['error'].status,
                      }
                    : {
                        timestamp: new Date(),
                        status: responseData['content'] == null ? '422' : '400',
                        message:
                          responseData['content'] == null
                            ? translation.listingLib.effects.badContentMsg
                            : translation.listingLib.effects.unknownErrorMsg,
                        errors: [JSON.stringify(responseData)],
                      },
                },
              }),
        ),
      );
  }

  private fetchTqlRequestKanban(
    table: Table,
    kanban: TableKanban,
    action,
    tqlName: string,
  ): Observable<any> {
    return this.listingDataService
      .fetchDataTql(
        {
          ...table,
          filters: distinctArrays('field', table.filters, kanban.filters),
          page: 1,
          pageSize: 100,
        },
        table.type.url,
        tqlName,
        {
          ...table.tree,
          parentValue: action.parentValue,
        },
        table.options,
        true,
      )
      .pipe(
        map((responseData) =>
          responseData && responseData['content'] && !responseData['error']
            ? ChangeKanbanList({
                id: action.id,
                kanban: {...kanban, data: responseData.content, loading: false},
              })
            : ChangeKanbanList({
                id: action.id,
                kanban: {
                  ...kanban,
                  loading: false,
                  error: responseData['error']
                    ? {
                        timestamp: responseData['error'].timestamp,
                        message: responseData['error'].path,
                        errors: [responseData['error'].error],
                        status: responseData['error'].status,
                      }
                    : {
                        timestamp: new Date(),
                        status: responseData['content'] == null ? '422' : '400',
                        message:
                          responseData['content'] == null
                            ? translation.listingLib.effects.badContentMsg
                            : translation.listingLib.effects.unknownErrorMsg,
                        errors: [JSON.stringify(responseData)],
                      },
                },
              }),
        ),
      );
  }

  private fetchTqlTotalRequest(
    table: Table,
    action,
    tqlName: string,
  ): Observable<any> {
    return this.listingDataService
      .fetchDataTotalTql(table, table.type.url, tqlName, false)
      .pipe(
        map((responseData) => {
          if (
            responseData &&
            responseData['content'] &&
            !responseData['error']
          ) {
            return LoadTotalDataSuccess({
              data: responseData['content'][0],
              id: action.id,
            });
          }
          return LoadTotalDataError({
            id: action.id,
            error: responseData['error']
              ? {
                  timestamp: responseData['error'].timestamp,
                  message: responseData['error'].path,
                  errors: [responseData['error'].error],
                  status: responseData['error'].status,
                }
              : {
                  timestamp: new Date(),
                  status: responseData['content'] == null ? '422' : '400',
                  message:
                    responseData['content'] == null
                      ? translation.listingLib.effects.badContentMsg
                      : translation.listingLib.effects.unknownErrorMsg,
                  errors: [JSON.stringify(responseData)],
                },
          });
        }),
      );
  }

  private serializationState(table: Table) {
    return {
      ...table,
      defaults: {},
      filters: table.filters.map((f) => ({
        field: f.field,
        value: f.value,
        displayValue: f.displayValue,
        operator: f.operator,
      })),
      selectedColumns: table.columns.map((c) => ({
        field: c.field,
        width: c.width,
        visible: c.visible != null ? c.visible : true,
        visibleCard: c.visibleCard != null ? c.visibleCard : true,
        displayAllowed: c.displayAllowed != null ? c.displayAllowed : true,
      })),
      columns: table.columns.map((c) => ({
        field: c.field,
        width: c.width,
        visible: c.visible != null ? c.visible : true,
        visibleCard: c.visibleCard != null ? c.visibleCard : true,
        displayAllowed: c.displayAllowed != null ? c.displayAllowed : true,
      })),
    };
  }
}
