import {Observable, Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';

// @ts-ignore
@Injectable()
export class Terminator extends Observable<void> implements OnDestroy {
  private alive$ = new Subject<void>();

  constructor() {
    super((sub: any) => this.alive$.subscribe(sub));
  }

  ngOnDestroy() {
    this.alive$.next();
    this.alive$.complete();
  }
}
