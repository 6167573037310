import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  DeleteAttachmentTypeSuccess,
  DiffAttachmentType,
  DiffAttachmentTypeError,
  DiffAttachmentTypeSuccess,
  LoadAttachmentTypeByCodeSuccess,
  LoadAttachmentTypeById,
  LoadAttachmentTypeByIdError,
  LoadAttachmentTypeByIdSuccess,
  LoadAttachmentTypeSuccess,
  UpsertAttachmentType,
  UpsertAttachmentTypeSuccess,
} from '../actions';
import {AttachmentTypeModel} from '../models';

export type AttachmentTypeState = EntityState<
  IdentifiedPack<AttachmentTypeModel>
>;

export const attachmentTypeAdapter: EntityAdapter<
  IdentifiedPack<AttachmentTypeModel>
> = createEntityAdapter<IdentifiedPack<AttachmentTypeModel>>({});

const initialState: AttachmentTypeState = attachmentTypeAdapter.getInitialState(
  {},
);

export const attachmentTypeReducer = createReducer(
  initialState,

  on(LoadAttachmentTypeSuccess, (state, {attachmentTypes}) => ({
    ...attachmentTypeAdapter.addMany(
      attachmentTypes.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadAttachmentTypeById, (state, {id}) =>
    attachmentTypeAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(
    LoadAttachmentTypeByIdSuccess,
    LoadAttachmentTypeByCodeSuccess,
    (state, {attachmentType}) =>
      attachmentTypeAdapter.upsertOne(
        {
          id: attachmentType.id,
          data: attachmentType,
          loading: false,
          error: null,
        },
        state,
      ),
  ),

  on(LoadAttachmentTypeByIdError, (state, {id, error}) =>
    attachmentTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertAttachmentType, (state, {attachmentType}) =>
    attachmentTypeAdapter.updateOne(
      {
        id: attachmentType.id,
        changes: {
          ...state.entities[attachmentType.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertAttachmentTypeSuccess, (state, {attachmentType}) =>
    attachmentTypeAdapter.upsertOne(
      {
        id: attachmentType.id,
        data: attachmentType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteAttachmentTypeSuccess, (state, attachmentType) =>
    attachmentTypeAdapter.removeOne(attachmentType.id, state),
  ),

  on(DiffAttachmentType, (state, {diffEntities}) =>
    attachmentTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffAttachmentTypeSuccess, (state, {attachmentTypes}) =>
    attachmentTypeAdapter.upsertMany(
      attachmentTypes.map((attachmentType) => {
        return {
          id: attachmentType.id,
          data: attachmentType,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffAttachmentTypeError, (state, {diffEntities, error}) =>
    attachmentTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
