import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import 'mousetrap';
import Mousetrap, {MousetrapInstance} from 'mousetrap';
import {Observable, of, Subscriber} from 'rxjs';
import {FrameworkShortcutsConfiguration, SHORTCUTS_CONFIGURATION} from '../di';
import * as objectPath from 'object-path';

export type DisableSelector = 'input' | 'textarea' | 'select' | string;

export interface HotkeyRegistrationInfo {
  hotkey: string | string[];
  text?: string;
  group?: string;
  customHotkeyText?: string;
}

export interface HotkeyRegistration extends HotkeyRegistrationInfo {
  local?: {
    trap: MousetrapInstance;
    element: Element;
  };
  options: HotkeyRegisterOptions;
  subscriber: Subscriber<any>;
}

export interface HotkeyRegisterOptions {
  element?: Element;
  preventDefault: boolean;
  stopPropagation: boolean;
  disableIn?: DisableSelector[];
  text?: string;
  group?: string;
  customHotkeyText?: string;
  allowedHotkeys: string[];
}

@Injectable({
  providedIn: 'root',
})
export class HotkeysService implements OnDestroy {
  private _mouseTrap: MousetrapInstance;

  private _registered: HotkeyRegistration[] = [];

  constructor(
    @Optional()
    @Inject(SHORTCUTS_CONFIGURATION)
    private options: FrameworkShortcutsConfiguration,
  ) {
    Mousetrap.prototype.stopCallback = (
      event: KeyboardEvent,
      element: HTMLElement,
      combo: string,
      callback: Function,
    ) => {
      // Povoleni pokud element obsahuje mousetrap
      if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
        return false;
      }
      return element.contentEditable && element.contentEditable === 'true';
    };
    this._mouseTrap = new Mousetrap();
  }

  private bind(
    subscriber: Subscriber<any>,
    hotkey: string | string[],
    options: HotkeyRegisterOptions,
    event: Mousetrap.ExtendedKeyboardEvent,
  ): any {
    if (event) {
      const target: HTMLElement = (event.target ||
        event.srcElement) as HTMLElement; // srcElement is IE only
      const nodeName: string = target.nodeName.toUpperCase();

      if (!((' ' + target.className + ' ').indexOf(' mousetrap ') > -1)) {
        if (
          options.disableIn &&
          options.disableIn.findIndex((x) => x.toUpperCase() === nodeName) >
            -1 &&
          !options.allowedHotkeys.some((allowed) =>
            Array.isArray(hotkey)
              ? hotkey.some((h) => h === allowed)
              : hotkey === allowed,
          )
        ) {
          // vypnuti podle tagu
          return;
        } else {
          if (
            options?.disableIn &&
            options?.disableIn.findIndex(
              (x) => (' ' + target.className + ' ').indexOf(' ' + x + ' ') > -1,
            ) > -1 &&
            !options.allowedHotkeys.some((allowed) =>
              Array.isArray(hotkey)
                ? hotkey.some((h) => h === allowed)
                : hotkey === allowed,
            )
          ) {
            // vypnuti pomoci classy
            return;
          }
        }
      }
    }
    subscriber.next(event);
    if (options?.preventDefault === true) {
      event.preventDefault();
    }

    if (options?.stopPropagation === true) {
      event.stopPropagation();
    }
  }

  when(
    hotkey: string | string[],
    options: HotkeyRegisterOptions = {
      preventDefault: true,
      stopPropagation: true,
      element: null,
      allowedHotkeys: [],
    },
  ): Observable<any> {
    if (this.options?.enabled !== true) {
      return of();
    }

    return new Observable((subscriber) => {
      // pridat do registraci
      const trap = options?.element
        ? new Mousetrap(options.element)
        : this._mouseTrap;
      this._registered.push({
        hotkey: Array.isArray(hotkey) ? hotkey : [hotkey],
        subscriber: subscriber,
        text: options?.text,
        group: options?.group ? options?.group : '',
        customHotkeyText: options?.customHotkeyText,
        options: options,
        local: options?.element
          ? {
              element: options?.element ? options.element : undefined,
              trap: trap,
            }
          : undefined,
      });

      trap.unbind(hotkey);
      trap.bind(hotkey, (e) => {
        this.bind(subscriber, hotkey, options, e);
      });

      return () => {
        const subIndex = this._registered.findIndex(
          (x) => x.subscriber === subscriber,
        );
        // pokud to byl lokalni, nic moc nedelame
        if (this._registered[subIndex].local != null) {
          this._registered[subIndex].local.trap.unbind(hotkey);
        } else {
          // u globalniho je to slozitejsi, zkusime najit predelsou registraci a tu pripadne nabindovat
          this._mouseTrap.unbind(hotkey);
          const sameHotkeyArray = this._registered.filter(
            (x, index) => x.hotkey === hotkey && index !== subIndex,
          );
          if (sameHotkeyArray.length > 0) {
            const foundNext = sameHotkeyArray[sameHotkeyArray.length - 1];
            this._mouseTrap.bind(hotkey, (e) => {
              this.bind(foundNext.subscriber, hotkey, foundNext.options, e);
            });
          }
        }
        this._registered[subIndex].subscriber.unsubscribe();
        this._registered.splice(subIndex, 1);
      };
    });
  }

  getRegistrations(): HotkeyRegistrationInfo[] {
    return this._registered;
  }

  getGroupedRegistrations(): {
    group: string;
    hotkeys: HotkeyRegistrationInfo[];
  }[] {
    const obj = this.groupBy(this._registered, 'group');
    return Object.keys(obj).reduce(
      (acc, it) => [...acc, {group: it, hotkeys: obj[it]}],
      [],
    );
  }

  globalReset() {
    this._mouseTrap.reset();
  }

  ngOnDestroy(): void {
    this.globalReset();
  }

  private groupBy<T = any>(array: Array<T>, property: string): any {
    const hash = {};
    for (let i = 0; i < array.length; i++) {
      const a = objectPath.get(array[i], property);
      if (!hash[a]) {
        hash[a] = [];
      }
      hash[a].push(array[i]);
    }
    return hash;
  }
}
