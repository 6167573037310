import {Observable} from 'rxjs';
import * as jexl from 'jexl';

export type OperatorType =
  | '+'
  | '-'
  | '*'
  | '/'
  | '|'
  | '%'
  | '||'
  | '&&'
  | '??'
  | '=='
  | '!='
  | '==='
  | '!=='
  | '<'
  | '>'
  | '<='
  | '>=';

export interface AstNode {
  type: AstNodeType;
}

export enum AstNodeType {
  ExpressionStatemenent = 'ExpressionStatement',
  CallExpression = 'CallExpression',
  Identifier = 'Identifier',
  PrivateIdentifier = 'PrivateIdentifier',
  MemberExpression = 'MemberExpression',
  Literal = 'Literal',
  BinaryExpression = 'BinaryExpression',
  ArrayExpression = 'ArrayExpression',
  ConditionalExpression = 'ConditionalExpression',
  UnaryExpression = 'UnaryExpression',
  LogicalExpression = 'LogicalExpression',
  ChainExpression = 'ChainExpression',
  ArrowFunctionExpression = 'ArrowFunctionExpression',
  ObjectExpression = 'ObjectExpression',
}

export interface ArrowFunctionExpressionAstNode extends AstNode {
  async: boolean;
  body: AstNode;
  expression: AstNode;
  params: any[];
}

export function isArrowFunctionExpressionAstNode(
  arg: AstNode,
): arg is ArrowFunctionExpressionAstNode {
  return arg.type === AstNodeType.ArrowFunctionExpression;
}

export interface ChainExpressionAstNode extends AstNode {
  expression: AstNode;
}

export function isChainExpressionAstNode(
  arg: AstNode,
): arg is ChainExpressionAstNode {
  return arg.type === AstNodeType.ChainExpression;
}

export interface LogicalExpressionAstNode extends AstNode {
  operator: OperatorType;
  left: BinaryExpressionAsNode;
  right: BinaryExpressionAsNode;
}

export function isLogicalExpressionAstNode(
  arg: AstNode,
): arg is LogicalExpressionAstNode {
  return arg.type === AstNodeType.LogicalExpression;
}

export interface UnaryExpressionAstNode extends AstNode {
  argument: MemberExpressionAstNode;
}

export function isUnaryExpressionAstNode(
  arg: AstNode,
): arg is UnaryExpressionAstNode {
  return arg.type === AstNodeType.UnaryExpression;
}

export interface ArrayExpressionAstNode extends AstNode {
  elements: AstNode[];
}

export function isArrayExpressionAstNode(
  arg: AstNode,
): arg is ArrayExpressionAstNode {
  return arg.type === AstNodeType.ArrayExpression;
}

export interface ConditionalExpressionAsNode extends AstNode {
  test: MemberExpressionAstNode;
  alternate: AstNode;
  consequent: AstNode;
}

export function isConditionalExpression(
  arg: AstNode,
): arg is ConditionalExpressionAsNode {
  return arg.type === AstNodeType.ConditionalExpression;
}

export function isArrayExpression(arg: AstNode): arg is ArrayExpressionAstNode {
  return arg.type === AstNodeType.ArrayExpression;
}

export interface BinaryExpressionAsNode extends AstNode {
  operator: OperatorType;
  left: AstNode;
  right: AstNode;
}

export function operate(left, right, operator: OperatorType) {
  switch (operator) {
    case '+':
      return left + right;
    case '-':
      return left - right;
    case '*':
      return left * right;
    case '/':
      return left / right;
    case '||':
      return left || right;
    case '&&':
      return left && right;
    case '??':
      return left ?? right;
    case '|':
      const transform = jexl._grammar.transforms[right];
      if (transform == null) {
        console.error(`Transform ${transform} doesn´t exist.`);
        return undefined;
      }
      const jexled = transform(left);
      return jexled;
    case '%':
      return left % right;
    case '==':
      return left == right;
    case '!=':
      return left != right;
    case '===':
      return left === right;
    case '!==':
      return left !== right;
    case '<':
      return left < right;
    case '>':
      return left > right;
    case '<=':
      return left <= right;
    case '>=':
      return left >= right;
    default:
      console.warn('Undefined operator ' + operator);
      return true;
  }
}

export function ternary(test, consequent, alternate) {
  return test ? consequent : alternate;
}

export function unary(argument) {
  return !argument;
}

export function isLoginalExpressionAsNode(
  arg: AstNode,
): arg is LogicalExpressionAstNode {
  return arg.type === AstNodeType.LogicalExpression;
}

export function isBinaryExpressionAsNode(
  arg: AstNode,
): arg is BinaryExpressionAsNode {
  return arg.type === AstNodeType.BinaryExpression;
}

export interface LiteralAstNode extends AstNode {
  value: any;
}

export function isLiteralAstnode(arg: AstNode): arg is LiteralAstNode {
  return arg.type === AstNodeType.Literal;
}

export interface IdentifierAstNode extends AstNode {
  name: string;
}

export function isIdentifierAstNode(arg: AstNode): arg is IdentifierAstNode {
  return (
    arg.type === AstNodeType.Identifier ||
    arg.type === AstNodeType.PrivateIdentifier
  );
}

export interface ExpresstionStatementAstNode extends AstNode {
  expression: AstNode;
}

export function isExpresstionStatementAstnode(
  arg: AstNode,
): arg is ExpresstionStatementAstNode {
  return arg.type === AstNodeType.ExpressionStatemenent;
}

export interface CallExpressionAstNode extends AstNode {
  expression: AstNode;
  object: AstNode;
  callee: AstNode;
  arguments: AstNode[];
}

export function isCallExpressionAstNode(
  arg: AstNode,
): arg is CallExpressionAstNode {
  return arg.type === AstNodeType.CallExpression;
}

export interface MemberExpressionAstNode extends AstNode {
  name: string;
  object: AstNode;
  computed: boolean;
  property: AstNode;
}

export function isMemberExpressionAstNode(
  arg: AstNode,
): arg is MemberExpressionAstNode {
  return arg.type === AstNodeType.MemberExpression;
}

export interface ObjectExpressionAstNode extends AstNode {
  properties: {key: AstNode; value: AstNode}[];
}

export function isObjectExpressionAstNode(
  arg: AstNode,
): arg is ObjectExpressionAstNode {
  return arg.type === AstNodeType.ObjectExpression;
}

export interface AstObservable {
  key: string;
  observable: Observable<any>;
  dependencies: AstObservable[];
}
