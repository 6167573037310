import {createFeatureSelector, createSelector} from '@ngrx/store';
import {attachmentAdapter} from '../reducers/attachment.reducer';
import {DmsState} from '../reducers';
import {Location} from '@tsm/wfm/service';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getAttachmentState = createSelector(
  getDmsState,
  (x) => x.attachment,
);

const {selectAll, selectEntities, selectIds, selectTotal} =
  attachmentAdapter.getSelectors(getAttachmentState);

export const selectAttachmentsById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

// https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
// aktualni presnost nastavena na ~ 330 m
export const selectAttachmentsByIdAndLocation = (id: string, loc: Location) =>
  createSelector(selectAttachmentsById(id), (state) => ({
    ...state,
    attachments: state.attachments.filter(
      (att) =>
        Math.abs(att.lat - loc.lat) < 0.003 &&
        Math.abs(att.lng - loc.lng) < 0.003,
    ),
  }));

export const countAttachments = (ownerId: string, ownerType: string) =>
  createSelector(selectEntities, (entities) =>
    entities[ownerId + ownerType] != null
      ? entities[ownerId + ownerType].attachments?.length
      : null,
  );
