import {createAction, props} from '@ngrx/store';
import {Notification} from '../models';
import {TsmError} from '@tsm/framework/http';

export const LoadNotification = createAction(
  '[Notification] Load data',
  props<{userId: string}>(),
);
export const LoadNotificationSuccess = createAction(
  '[Notification] Load data Success',
  props<{
    userId: string;
    notification: Notification[];
  }>(),
);
export const LoadNotificationError = createAction(
  '[Notification] Load data ERROR',
  props<{
    userId: string;
    error: TsmError;
  }>(),
);

export const UpsertNotification = createAction(
  '[Notification] Upsert data',
  props<{notification: Notification}>(),
);
export const UpsertNotificationSuccess = createAction(
  '[Notification] Upsert data Success',
  props<{
    notification: Notification;
  }>(),
);
export const UpsertNotificationError = createAction(
  '[Notification] Upsert data Error',
  props<{error: TsmError}>(),
);

export const DeleteNotification = createAction(
  '[Notification] Delete data',
  props<{id: string; userId: string}>(),
);
export const DeleteNotificationSuccess = createAction(
  '[Notification] Delete data Success',
  props<{
    id: string;
    userId: string;
  }>(),
);
export const DeleteNotificationError = createAction(
  '[Notification] Delete data Error',
  props<{
    id: string;
    error: TsmError;
  }>(),
);
