import {createReducer, on} from '@ngrx/store';
import {
  HideUpdateMessage,
  HideUpdateOverlay,
  SetUpdateOverlayStepInstalled,
  ShowUpdateMessage,
  ShowUpdateOverlay,
} from '@tsm/framework/root';
import {
  UpdateModel,
  UpdateOverlayStepEnum,
} from '../models/client/update.model';

export const initialState: UpdateModel = {
  showUpdateMessage: false,
  showUpdateOverlay: false,
  updateOverlayStep: null,
};

export const updateReducer = createReducer(
  initialState,
  // message
  on(ShowUpdateMessage, (state) => ({
    ...state,
    showUpdateMessage: true,
  })),
  on(HideUpdateMessage, (state) => ({
    ...state,
    showUpdateMessage: false,
  })),
  on(ShowUpdateOverlay, (state) => ({
    ...state,
    showUpdateOverlay: true,
    updateOverlayStep: UpdateOverlayStepEnum.DOWNLOADING,
  })),
  on(SetUpdateOverlayStepInstalled, (state) => ({
    ...state,
    showUpdateOverlay: true,
    updateOverlayStep: UpdateOverlayStepEnum.INSTALLED,
  })),
  on(HideUpdateOverlay, (state) => ({
    ...state,
    showUpdateOverlay: false,
  })),
);
