import {Injectable, OnDestroy, PipeTransform} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';

@Injectable()
export abstract class AbstractCachedLoaderPipe
  implements PipeTransform, OnDestroy
{
  // slouzi pro definovani, zda jako posledni argument bude cely objekt radky
  public requiresRowData = true;
  protected _checkSubscription: Subscription;
  protected _obs$: Observable<any>;
  protected _id: any;
  protected _args?: any;
  protected _of = of(null);

  protected constructor(protected store: Store<any>) {}

  ngOnDestroy() {
    this.destroy();
  }

  destroy() {
    this._obs$ = undefined;
    if (this._checkSubscription) {
      this._checkSubscription.unsubscribe();
      this._checkSubscription = undefined;
    }
  }

  transform(
    id: any,
    args?: any,
    rowData?: any,
    rowNode?: any,
  ): Observable<any> {
    if (!id) return this._of;

    // Check if return new Observable and check if group has beeen already loaded
    if (id !== this._id || args !== this._args) {
      this.setObservable(
        id,
        args === 'emptyConverterParams' ? undefined : args,
        rowData,
        rowNode,
      );
      this.checkIfDispatchNeeded(rowData);
    }

    return this._obs$;
  }

  abstract setObservable(_id: any, args?: any, rowData?: any, rowNode?: any);

  abstract checkIfDispatchNeeded(rowData?: any);
}
