import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {of} from 'rxjs';

import {
  controlsStockDetailConfiguration,
  controlsStockItemDetailConfiguration,
  controlsStockItemNewConfiguration,
  controlsStockNewConfiguration,
} from './tsm-controls-configuration';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/stock/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const stockPlugin: Plugin = {
  menus: [
    {
      key: 'stock',
      priority: 1300,
      label: 'menu.stock.stock',
      icon: 'tsm-icon-stock',
      privilege: [
        'Stc.Stock.ViewList',
        'Stc.StockItem.ViewList',
        'Stc.StockMovement.ViewList',
        'Stc.StockMovementCre.ViewList',
        'Stc.Registers.StockType.ViewList',
        'Stc.Registers.StockMovementType.ViewList',
        'Stc.Registers.StockItemType.ViewList',
      ],
      items: [
        {
          key: 'tsm-stock',
          label: 'menu.stock.stocks',
          icon: 'tsm-icon-stock-1',
          routerLink: ['/stock/stock'],
          listingType: 'tsm-stock',
          privilege: ['Stc.Stock.ViewList'],
        },
        {
          key: 'tsm-stock-item',
          label: 'menu.stock.items',
          icon: 'tsm-icon-stock-item',
          routerLink: ['/stock/stock-item'],
          listingType: 'tsm-stock-item',
          privilege: ['Stc.StockItem.ViewList'],
        },
        {
          key: 'tsm-stock-item-list',
          label: 'menu.stock.itemList',
          icon: 'tsm-icon-stock-item',
          routerLink: ['/stock/stock-item-list'],
          listingType: 'tsm-stock-item',
          privilege: ['Stc.StockItem.ViewList'],
        },
        {
          key: 'tsm-stock-movement',
          label: 'menu.stock.moves',
          icon: 'tsm-icon-stock-movement',
          routerLink: ['/stock/stock-movement-list'],
          listingType: 'tsm-stock-movement-list',
          privilege: ['Stc.StockMovement.ViewList'],
        },
        {
          key: 'tsm-stock-movement-creation',
          label: 'menu.stock.movementCreation',
          icon: 'tsm-icon-stock-movement-creation',
          routerLink: ['/stock/stock-movement'],
          listingType: 'tsm-stock-movement',
          privilege: ['Stc.StockMovementCre.ViewList'],
        },
        {
          key: 'STOCK_REGISTERS',
          label: 'menu.stock.registerManagement',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Stc.Registers.StockType.ViewList',
            'Stc.Registers.StockMovementType.ViewList',
          ],
          items: [
            {
              key: 'STOCK_TYPE',
              label: 'menu.stock.stockType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/stock/stock-type'],
              privilege: ['Stc.Registers.StockType.ViewList'],
            },
            {
              key: 'STOCK_MOVEMENT_TYPE',
              label: 'menu.stock.stockMovementType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/stock/stock-movement-type'],
              privilege: ['Stc.Registers.StockMovementType.ViewList'],
            },
            {
              key: 'STOCK_ITEM_TYPE',
              label: 'menu.stock.stockItemType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/stock/stock-item-type'],
              privilege: ['Stc.Registers.StockItemType.ViewList'],
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'stock',
      loadChildren: () =>
        import('@tsm/stock/components').then((x) => x.StockComponentsModule),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/stock/widgets').then((x) => x.StockWidgetsModule),
      definitions: [
        {
          selector: 'tsm-stock-filter',
          loadComponent: () =>
            import('@tsm/stock/widgets/filters/stock-filter').then(
              (x) => x.StockFilterComponent,
            ),
          name: translationPlugin.stockPlugins.filters.tsmStockFilter,
          description:
            translationPlugin.stockPlugins.filters.tsmStockFilterDescription,
        },
        {
          selector: 'tsm-stock-item-filter',
          loadComponent: () =>
            import('@tsm/stock/widgets/filters/stock-item-filter').then(
              (x) => x.StockItemFilterComponent,
            ),
          name: translationPlugin.stockPlugins.filters.tsmStockItemFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockItemFilterDescription,
        },
        {
          selector: 'tsm-stock-item-status-filter',
          loadComponent: () =>
            import('@tsm/stock/widgets/filters/stock-item-status-filter').then(
              (x) => x.StockItemStatusFilterComponent,
            ),
          name: translationPlugin.stockPlugins.filters.tsmStockItemStatusFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockItemStatusFilterDescription,
        },
        {
          selector: 'tsm-stock-item-type-filter',
          loadComponent: () =>
            import('@tsm/stock/widgets/filters/stock-item-type-filter').then(
              (x) => x.StockItemTypeFilterComponent,
            ),
          name: translationPlugin.stockPlugins.filters.tsmStockItemTypeFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockItemTypeFilterDescription,
        },
        {
          selector: 'tsm-stock-movement-status-filter',
          loadComponent: () =>
            import(
              '@tsm/stock/widgets/filters/stock-movement-status-filter'
            ).then((x) => x.StockMovementStatusFilterComponent),
          name: translationPlugin.stockPlugins.filters
            .tsmStockMovementStatusFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockMovementStatusFilterDescription,
        },
        {
          selector: 'tsm-stock-movement-type-filter',
          loadComponent: () =>
            import(
              '@tsm/stock/widgets/filters/stock-movement-type-filter'
            ).then((x) => x.StockMovementTypeFilterComponent),
          name: translationPlugin.stockPlugins.filters
            .tsmStockMovementTypeFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockMovementTypeFilterDescription,
        },
        {
          selector: 'tsm-stock-status-filter',
          loadComponent: () =>
            import('@tsm/stock/widgets/filters/stock-status-filter').then(
              (x) => x.StockStatusFilterComponent,
            ),
          name: translationPlugin.stockPlugins.filters.tsmStockStatusFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockStatusFilterDescription,
        },
        {
          selector: 'tsm-stock-type-filter',
          loadComponent: () =>
            import('@tsm/stock/widgets/filters/stock-type-filter').then(
              (x) => x.StockTypeFilterComponent,
            ),
          name: translationPlugin.stockPlugins.filters.tsmStockTypeFilter,
          description:
            translationPlugin.stockPlugins.filters
              .tsmStockTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/stock/widgets').then((x) => x.StockWidgetsModule),
      definitions: [
        {
          selector: 'stockItemKey',
          name: translationPlugin.stockPlugins.pipes.stockItemKey,
          description:
            translationPlugin.stockPlugins.pipes.stockItemKeyDescription,
        },
        {
          selector: 'stockItemMovementPipe',
          name: translationPlugin.stockPlugins.pipes.stockItemMovementPipe,
          description:
            translationPlugin.stockPlugins.pipes
              .stockItemMovementPipeDescription,
        },
        {
          selector: 'stockItemSn',
          name: translationPlugin.stockPlugins.pipes.stockItemSn,
          description:
            translationPlugin.stockPlugins.pipes.stockItemSnDescription,
        },
        {
          selector: 'stockItemTypeCode',
          name: translationPlugin.stockPlugins.pipes.stockItemTypeCode,
          description:
            translationPlugin.stockPlugins.pipes.stockItemTypeCodeDescription,
        },
        {
          selector: 'stockItemType',
          name: translationPlugin.stockPlugins.pipes.stockItemType,
          description:
            translationPlugin.stockPlugins.pipes.stockItemTypeDescription,
        },
        {
          selector: 'stockItem',
          name: translationPlugin.stockPlugins.pipes.stockItem,
          description:
            translationPlugin.stockPlugins.pipes.stockItemDescription,
        },
        {
          selector: 'stockKey',
          name: translationPlugin.stockPlugins.pipes.stockKey,
          description: translationPlugin.stockPlugins.pipes.stockKeyDescription,
        },
        {
          selector: 'stockMovementStatus',
          name: translationPlugin.stockPlugins.pipes.stockMovementStatus,
          description:
            translationPlugin.stockPlugins.pipes.stockMovementStatusDescription,
        },
        {
          selector: 'stockMovementTypeCode',
          name: translationPlugin.stockPlugins.pipes.stockMovementTypeCode,
          description:
            translationPlugin.stockPlugins.pipes
              .stockMovementTypeCodeDescription,
        },
        {
          selector: 'stockMovementType',
          name: translationPlugin.stockPlugins.pipes.stockMovementType,
          description:
            translationPlugin.stockPlugins.pipes.stockMovementTypeDescription,
        },
        {
          selector: 'stockMovementPipe',
          name: translationPlugin.stockPlugins.pipes.stockMovementPipe,
          description:
            translationPlugin.stockPlugins.pipes.stockMovementPipeDescription,
        },
        {
          selector: 'stockTaskLoader',
          name: translationPlugin.stockPlugins.pipes.stockTaskLoader,
          description:
            translationPlugin.stockPlugins.pipes.stockTaskLoaderDescription,
        },
        {
          selector: 'stockTypeCode',
          name: translationPlugin.stockPlugins.pipes.stockTypeCode,
          description:
            translationPlugin.stockPlugins.pipes.stockTypeCodeDescription,
        },
        {
          selector: 'stockType',
          name: translationPlugin.stockPlugins.pipes.stockType,
          description:
            translationPlugin.stockPlugins.pipes.stockTypeDescription,
        },
        {
          selector: 'stock',
          name: translationPlugin.stockPlugins.pipes.stock,
          description: translationPlugin.stockPlugins.pipes.stockDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/stock/widgets').then((x) => x.StockWidgetsModule),
      definitions: [
        {
          selector: 'tsm-user-stock-items',
          loadComponent: () =>
            import('@tsm/stock/widgets/user-stock-items/user-stock-items').then(
              (x) => x.UserStockItemsComponent,
            ),
          designers: [
            {
              editorSelector: 'tsm-user-stock-items-editor',
              loadComponent: () =>
                import(
                  '@tsm/stock/widgets/user-stock-items/user-stock-items-editor'
                ).then((x) => x.UserStockItemsEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Stock,
              name: translationShared.shared.widgets.stockItemList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.stockItemList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                widget: {
                  type: 'tsm-user-stock-items',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-stock-card',
          loadComponent: () =>
            import('@tsm/stock/widgets/stock-card').then(
              (x) => x.StockCardComponent,
            ),
        },
        {
          selector: 'tsm-stock-item-card',
          loadComponent: () =>
            import('@tsm/stock/widgets/stock-item-card').then(
              (x) => x.StockItemCardComponent,
            ),
        },
        {
          selector: 'tsm-stock-item-sn-lov',
          loadComponent: () =>
            import(
              '@tsm/stock/widgets/stock-item-sn-lov/stock-item-sn-lov'
            ).then((x) => x.StockItemSNLovComponent),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-stock-item-sn-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/stock/widgets/stock-item-sn-lov/stock-item-sn-lov-editor'
                ).then((x) => x.StockItemSnLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'stockItem',
              categoryId: DesignerCategoryEnum.Stock,
              name: translationShared.shared.widgets.stockItemSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.stockItemSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.stockItemSelect
                    .inWidgetTitle,
                type: 'string',
                widget: {
                  type: 'tsm-stock-item-sn-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-stock-item-type-lov',
          loadComponent: () =>
            import(
              '@tsm/stock/widgets/stock-item-type-lov/stock-item-type-lov'
            ).then((x) => x.StockItemTypeLovComponent),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-stock-item-type-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/stock/widgets/stock-item-type-lov/stock-item-type-lov-editor'
                ).then((x) => x.StockItemTypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'stockItemType',
              categoryId: DesignerCategoryEnum.Stock,
              name: translationShared.shared.widgets.stockItemTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.stockItemTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.stockItemTypeSelect
                    .inWidgetTitle,
                type: 'string',
                widget: {
                  type: 'tsm-stock-item-type-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-stock-lov',
          loadComponent: () =>
            import('@tsm/stock/widgets/stock-lov/stock-lov').then(
              (x) => x.StockLovComponent,
            ),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-stock-lov-editor',
              loadComponent: () =>
                import('@tsm/stock/widgets/stock-lov/stock-lov-editor').then(
                  (x) => x.StockLovEditorComponent,
                ),
              widgetOrLayout: 'widget',
              formField: 'stock',
              categoryId: DesignerCategoryEnum.Stock,
              name: translationShared.shared.widgets.stockSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.stockSelect.widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.stockSelect.inWidgetTitle,
                type: 'string',
                widget: {
                  type: 'tsm-stock-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-stock-movement-card',
          loadComponent: () =>
            import('@tsm/stock/widgets/stock-movement-card').then(
              (x) => x.StockMovementCardComponent,
            ),
        },
        {
          selector: 'tsm-stock-movement-type-lov',
          loadComponent: () =>
            import(
              '@tsm/stock/widgets/stock-movement-type-lov/stock-movement-type-lov'
            ).then((x) => x.StockMovementTypeLovComponent),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-stock-movement-type-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/stock/widgets/stock-movement-type-lov/stock-movement-type-lov-editor'
                ).then((x) => x.StockMovementTypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'stockMovementType',
              categoryId: DesignerCategoryEnum.Stock,
              name: translationShared.shared.widgets.stockMovementTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.stockMovementTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.stockMovementTypeSelect
                    .inWidgetTitle,
                type: 'string',
                widget: {
                  type: 'tsm-stock-movement-type-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-stock-type-lov',
          loadComponent: () =>
            import('@tsm/stock/widgets/stock-type-lov/stock-type-lov').then(
              (x) => x.StockTypeLovComponent,
            ),
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-stock-type-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/stock/widgets/stock-movement-type-lov/stock-movement-type-lov-editor'
                ).then((x) => x.StockMovementTypeLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'stockType',
              categoryId: DesignerCategoryEnum.Stock,
              name: translationShared.shared.widgets.stockTypeSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.stockTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.select,
              value: {
                title:
                  translationShared.shared.widgets.stockTypeSelect
                    .inWidgetTitle,
                type: 'string',
                widget: {
                  type: 'tsm-stock-type-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-task-stock-movement',
          loadComponent: () =>
            import('@tsm/stock/widgets/task-stock-movement').then(
              (x) => x.TaskStockMovementComponent,
            ),
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'Stock',
      url: (entityId) => {
        return of('/stock/stock/' + entityId);
      },
    },
    {
      entityType: 'StockItem',
      url: (entityId) => {
        return of('/stock/stock-item/' + entityId);
      },
    },
    {
      entityType: 'StockMovement',
      url: (entityId) => {
        return of('/stock/stock-movement-list');
      },
    },
    {
      entityType: 'StockType',
      url: (entityId) => {
        return of(`/stock/stock-type/${entityId}`);
      },
    },
    {
      entityType: 'StockItemType',
      url: (entityId) => {
        return of(`/stock/stock-item-type/${entityId}`);
      },
    },
    {
      entityType: 'StockMovementType',
      url: (entityId) => {
        return of(`/stock/stock-movement-type/${entityId}`);
      },
    },
  ],
  layoutControls: [
    controlsStockNewConfiguration,
    controlsStockDetailConfiguration,
    controlsStockItemNewConfiguration,
    controlsStockItemDetailConfiguration,
  ],
};
