import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {Router} from '@angular/router';
import {
  DeleteCharacteristics,
  DeleteCharacteristicsError,
  DeleteCharacteristicsSuccess,
  DiffCharacteristics,
  DiffCharacteristicsError,
  DiffCharacteristicsSuccess,
  LoadBatchCharacteristicsByCodeFilter,
  LoadCharacteristics,
  LoadCharacteristicsByCodes,
  LoadCharacteristicsByEntityType,
  LoadCharacteristicsByEntityTypeError,
  LoadCharacteristicsByEntityTypeSuccess,
  LoadCharacteristicsByFilter,
  LoadCharacteristicsByFilterError,
  LoadCharacteristicsByFilterSuccess,
  LoadCharacteristicsByFormId,
  LoadCharacteristicsByFormIdError,
  LoadCharacteristicsByFormIdSuccess,
  LoadCharacteristicsById,
  LoadCharacteristicsByIdError,
  LoadCharacteristicsByIdSuccess,
  LoadCharacteristicsError,
  LoadCharacteristicsSuccess,
  UpsertCharacteristics,
  UpsertCharacteristicsError,
  UpsertCharacteristicsSuccess,
} from '../actions';
import {animationBuffer, withLatestCached} from '@tsm/framework/root';
import {selectCharacteristics} from '../selectors';
import {Action, Store} from '@ngrx/store';
import {
  PageSortFilterService,
  RefreshDataAndClearSelected,
} from '@tsm/listing-lib/service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {CommonApiService} from '../services';
import {Characteristics} from '../models';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';
import {LoginFinishedSuccess, Logout} from '@tsm/core';

@Injectable()
export class CharacteristicsEffects {
  translation = translation;
  translationShared = translationShared;

  private readonly CHARS_API_PATH = 'v1/characteristics';

  loadBySpecId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCharacteristicsById),
      concatMap(({id}) => {
        return this.commonApiService
          .getEntity(this.CHARS_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadCharacteristicsByIdSuccess({entity: env.data})
                : LoadCharacteristicsByIdError({error: env.error}),
            ),
          );
      }),
    ),
  );

  loadCharsCachedByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBatchCharacteristicsByCodeFilter),
      animationBuffer(
        this.actions$,
        LoadBatchCharacteristicsByCodeFilter,
        LoginFinishedSuccess,
        Logout,
      ),
      filter((x) => x.length > 0),
      map((ids) => ids.map((x) => x.code)),
      map((codes) => Array.from(new Set(codes))),
      concatMap((codes) => {
        return this.commonApiService.getCharacteristicsByCodes(codes).pipe(
          map((response) => {
            const temp: Action =
              response.success == true
                ? LoadCharacteristicsByFilterSuccess({entities: response.data})
                : LoadCharacteristicsByFilterError(response.error);
            return temp;
          }),
        );
      }),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCharacteristics),
      // TODO lepe poznat ze uz se nahraly, resit dobu v cache, ...
      withLatestCached(() => this.store.select(selectCharacteristics)),
      filter(([, entities]) => entities.length === 0),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.CHARS_API_PATH + '/all')
          .pipe(
            map((env) =>
              env.success
                ? LoadCharacteristicsSuccess({entities: env.data})
                : LoadCharacteristicsError(env.error),
            ),
          ),
      ),
    ),
  );

  loadByFormId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCharacteristicsByFormId),
      concatMap(({formId}) =>
        this.commonApiService
          .getItemsById('form', formId, this.CHARS_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadCharacteristicsByFormIdSuccess({entities: env.data})
                : LoadCharacteristicsByFormIdError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCharacteristics),
      concatMap(({id, listingId, redirectToDetail, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.CHARS_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              if (shouldRedirectToListing) {
                this.router.navigate([`/config/characteristics`]);
              }
              return DeleteCharacteristicsSuccess({
                id: id,
                listingId: listingId,
                redirectToDetail: redirectToDetail,
              });
            } else {
              return DeleteCharacteristicsError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  refreshData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCharacteristicsSuccess, DiffCharacteristicsSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertCharacteristics),
      concatMap(({characteristics, listingId, redirectToDetail}) =>
        this.commonApiService
          .upsertEntity(
            this.CHARS_API_PATH,
            characteristics,
            characteristics.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.characteristicsService.effects
                    .characteristicSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertCharacteristicsSuccess({
                  characteristics: env.data,
                  listingId: listingId,
                  redirectToDetail: redirectToDetail,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.characteristicsService.effects
                    .characteristicSaveError,
                );
                return UpsertCharacteristicsError({
                  id: characteristics.id,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffCharacteristics),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities(this.CHARS_API_PATH, diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffCharacteristicsSuccess({
                  characteristics: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffCharacteristicsError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertCharacteristicsSuccess),
      tap(({characteristics, redirectToDetail}) => {
        if (redirectToDetail === true) {
          this.router.navigate([
            `/config/characteristics/${characteristics.id}`,
          ]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  loadByEntityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCharacteristicsByEntityType),
      mergeMap(({entityType}) =>
        this.commonApiService
          .getCharacteristicsByEntityType(entityType)
          .pipe(
            map((env) =>
              env.success
                ? LoadCharacteristicsByEntityTypeSuccess({entities: env.data})
                : LoadCharacteristicsByEntityTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  loadByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCharacteristicsByFilter),
      mergeMap(({filters}) =>
        this.commonApiService
          .getAllFilterable(
            this.CHARS_API_PATH,
            this.pageSortFilterService.getUrlFilterFromFilterModels(filters),
          )
          .pipe(
            map((env) =>
              env.success
                ? LoadCharacteristicsByFilterSuccess({entities: env.data})
                : LoadCharacteristicsByFilterError(env.error),
            ),
          ),
      ),
    ),
  );

  loadByFilterCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCharacteristicsByCodes),
      mergeMap(({codes}) =>
        this.commonApiService
          .getCharacteristicsByCodes(codes)
          .pipe(
            map((env) =>
              env.success
                ? LoadCharacteristicsByFilterSuccess({entities: env.data})
                : LoadCharacteristicsByFilterError(env.error),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      Characteristics,
      Characteristics
    >,
    private pageSortFilterService: PageSortFilterService,
    private router: Router,
    private store: Store<any>,
    private toastService: ToastService,
  ) {}
}
