import {createAction, props} from '@ngrx/store';
import {DeliveryItemType} from '../models';
import {NotificationCountState} from '../models';

export const LoadNotificationCount = createAction(
  '[NotificationCount] Load count',
  props<{
    userId: string;
    deliveryType: DeliveryItemType;
  }>(),
);
export const LoadNotificationCountSuccess = createAction(
  '[NotificationCount] Load count Success',
  props<{
    userId: string;
    deliveryType: DeliveryItemType;
    count: NotificationCountState;
  }>(),
);
export const LoadNotificationCountError = createAction(
  '[NotificationCount] Load count ERROR',
  props<{
    userId: string;
    deliveryType: DeliveryItemType;
    error: any;
  }>(),
);
