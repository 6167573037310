import * as lzString from 'lz-string';

export function getLocalStorageListing() {
  const storageItem = localStorage.getItem('WEB_WORKER_LISTING');
  const result =
    storageItem != null ? JSON.parse(lzString.decompress(storageItem)) : null;
  return result;
}

export function setLocalStorageListing(resultJson: any) {
  localStorage.setItem(
    'WEB_WORKER_LISTING',
    lzString.compress(JSON.stringify(resultJson)),
  );
}
