import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {map, Observable} from 'rxjs';
import {DeliveryItemType, MyNotification, NotificationStatus} from '../models';
import {RuntimeService} from '@tsm/runtime-info';
import {
  FilterModel,
  FilterOperator,
  GridDataHttpModel,
  PageSortFilterService,
} from '@tsm/listing-lib/service';
import {NotificationCountState} from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationMyService {
  private readonly USER_NOTIFICATION = `notifications`;
  private readonly USER_INFO_BY_ID = `userId`;
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private runtimeService: RuntimeService,
    private pageSortFilterService: PageSortFilterService,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  getNotificationMyCountByUserId(
    userId: string,
    deliveryType: DeliveryItemType,
  ): Observable<Envelope<NotificationCountState>> {
    const url = `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/${this.USER_INFO_BY_ID}/${userId}/deliveryType/${deliveryType}/counts`;
    return this.apiService
      .get<NotificationCountState, NotificationCountState>(url)
      .pipe(
        map((x) => {
          const result: Envelope<NotificationCountState> = {
            data: x.data,
            error: x.error,
            resultCode: x.resultCode,
            success: x.success,
          };
          return result;
        }),
      );
  }

  getNotificationMyByUserId(
    userId: string,
  ): Observable<Envelope<MyNotification[]>> {
    return this.apiService.get<MyNotification[], MyNotification[]>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/${this.USER_INFO_BY_ID}/${userId}`,
    );
  }

  getNotificationMyByUserIdByFilters(
    userId: string,
    page: number,
    size: number,
    filters?: FilterModel[],
  ): Observable<Envelope<GridDataHttpModel<MyNotification>>> {
    let url = `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/${this.USER_INFO_BY_ID}/${userId}/filtering?page=${page}&size=${size}&sort=whenInserted,desc`;
    if (filters?.length > 0) {
      url += `&${this.pageSortFilterService.getUrlFilterFromFilterModels(
        filters,
      )}`;
    }
    return this.apiService.get(url);
  }

  upsertMyNotification(
    notification: MyNotification,
  ): Observable<Envelope<MyNotification>> {
    return this.apiService.post<MyNotification, MyNotification>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/${notification.id}`,
      notification,
    );
  }

  displayedAllMyNotifications(
    id: string,
    notificationType: DeliveryItemType,
  ): Observable<Envelope<MyNotification>> {
    return this.apiService.post(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/userId/${id}/displayedAll/${notificationType}`,
      {},
    );
  }

  deleteMyNotificationById(id: string): Observable<Envelope<MyNotification>> {
    return this.apiService.delete<MyNotification, MyNotification>(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/${id}`,
    );
  }

  deleteAllMyNotifications(
    id: string,
    notificationType: DeliveryItemType,
    status: NotificationStatus,
  ): Observable<Envelope<MyNotification>> {
    if (
      status === NotificationStatus.SENT ||
      status === NotificationStatus.PLAN
    ) {
      return this.apiService.delete(
        `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/userId/${id}/deleteAll/${notificationType}?status=${status}`,
      );
    }
    return this.apiService.delete(
      `${this.BASE_URL}/${this.USER_NOTIFICATION}/my/userId/${id}/deleteAll/${notificationType}`,
    );
  }

  // send(url: string,
  //      notificationTemplate: NotificationTemplate,
  //      ownerId: string,
  //      ownerType: string,
  //      from: string,
  //      deliveryType: string,
  //      relatedEntity: NotificationRelatedEntity[],
  //      notificationTo: NotificationTo[],
  //      contents?: string,
  //      attachments?: Attachment[],
  //      data?: any): Observable<Envelope<Notification>> {
  //   return this.apiService.post(url,
  //     {
  //       templateCode: notificationTemplate.code,
  //       ownerType: ownerType,
  //       ownerId: ownerId,
  //       sender: this.runtimeService.getCurrent().name,
  //       senderType: 'MANUAL',
  //       notificationTo: notificationTo,
  //       relatedEntities: relatedEntity,
  //       formatterDocumentTemplateDataOverride: {
  //         content: contents
  //       },
  //       data: data,
  //       attachments: attachments,
  //       from: from,
  //     }
  //   );
  // }

  getNotificationFiltersByStatus(status: NotificationStatus) {
    const filters = [];
    if (status === NotificationStatus.ALL) {
      // neprecteny + precteny
      filters.push({
        field: 'deleted',
        operator: FilterOperator.empty,
      });
    } else if (status === NotificationStatus.PLAN) {
      // neprecteny
      filters.push({
        field: 'displayed',
        operator: FilterOperator.empty,
      });
      filters.push({
        field: 'deleted',
        operator: FilterOperator.empty,
      });
    } else if (status === NotificationStatus.SENT) {
      // neprecteny
      filters.push({
        field: 'displayed',
        operator: FilterOperator.notempty,
      });
      filters.push({
        field: 'deleted',
        operator: FilterOperator.empty,
      });
    } else if (status === NotificationStatus.DELETED) {
      // smazane
      filters.push({
        field: 'deleted',
        operator: FilterOperator.notempty,
      });
    }
    return filters;
  }
}
