import {SelectItem} from 'primeng/api';
import {AbstractAuditedEntity} from '@tsm/framework/root';
import {UserType} from './user-type.model';

export interface User extends AbstractAuditedEntity<string> {
  name: string;
  email?: string;
  code?: string;
  phoneNumber?: string;
  locale?: string;
  urlProfile?: string;
  webSite?: string;
  type?: UserType;
  status: Status;
  authentication: Authentication;
  givenName?: string;
  familyName?: string;
  // preferredUsername: string;
  profileImage: string;
  mimeType: string;
  description?: string;
  password?: string;
  supervisorId?: string;
  //Organisation
  addressRefs?: string[];
  //workGroup
  chars?: {};
  modulesIds?: string[];
  usersAssignedIds?: string[];
  language?: string;
  __leaf?: boolean;
  dataTags?: string[];
  personId?: string;
  notificationConfig?: UserNotificationConfig;
}

export interface UserNotificationConfig {
  ignoredTemplateCodes?: string[];
  ignoredByUserGroupRecipientTemplateCodes?: string[];
  ignoredUserGroups?: string[];
  user: UserNotifOverride;
  userGroups: UserUserGroupNotificationOverride[];
}
export interface UserUserGroupNotificationOverride {
  code: string;
  disabled: boolean;
  notifications: SingleNotificationOverride[];
}

export interface SingleNotificationOverride {
  code: string;
  enabled: boolean;
}
export interface UserNotifOverride {
  notifications: SingleNotificationOverride[];
  others?: 'enabled' | 'disabled' | 'userGroups';
}

// export interface UserNotificationConfig {
//   ignoredTemplateCodes?: string[];
//   ignoredByUserGroupRecipientTemplateCodes?: string[];
//   ignoredUserGroups?: string[];
// }

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum Status {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export enum Authentication {
  LDAP = 'LDAP',
  PKI = 'PKI',
  AD = 'AD',
  PASSWORD = 'PASSWORD',
  EMPTY = 'EMPTY',
}

export enum Language {
  CS = 'cs',
  EN = 'en',
}

export function getAllGenders(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(Gender).forEach((val) =>
    items.push({label: Gender[val], value: val}),
  );
  return items;
}

export function getAllLanguages(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(Language).forEach((val) =>
    items.push({label: Language[val], value: val}),
  );
  return items;
}

export function getAllStatuses(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(Status).forEach((val) =>
    items.push({label: Status[val], value: val}),
  );
  return items;
}

export function getAllAuthentications(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(Authentication).forEach((val) =>
    items.push({label: Authentication[val], value: val}),
  );
  return items;
}
