import {Injectable} from '@angular/core';
import {toJsonSchema} from 'tsm-json-schema-form-functions';

@Injectable({
  providedIn: 'root',
})
export class JsonSchemaGeneratorService {
  constructor() {}

  mapJsonToJsonSchema(json: any, options: any = {required: false}): any {
    options = {
      required: false,
      arrays: {mode: 'all'},
      objects: {
        additionalProperties: true,
        ...options,
        postProcessFnc: options?.required
          ? (schema, obj, defaultFnc) => {
              return {
                ...defaultFnc(schema, obj),
                required: Object.getOwnPropertyNames(obj),
              };
            }
          : null,
      },
      ...options,
    };
    return toJsonSchema(json, options);
  }
}
