import {createFeatureSelector, createSelector} from '@ngrx/store';

import {DataViewModeEnum, TableKeyType} from '../models';
import {listingAdapter, ListingContainerState} from '../reducers';

const getListingContainerState =
  createFeatureSelector<ListingContainerState>('listing');

export const getListingState = createSelector(
  getListingContainerState,
  (state) => state.listing,
);

export const {selectAll, selectEntities, selectIds, selectTotal} =
  listingAdapter.getSelectors(getListingState);

export const selectListingStateById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const isListingStatePresentById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id] != null);

export const getSelectedRowId = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) => entities[id].selectedRowId);

export const getSelectedProfileId = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) => entities[id]?.selectedProfileId);

export const getSelectedRowEntity = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) =>
    entities[id]?.data.items.find(
      (item) => (item as any).id === entities[id].selectedRowId,
    ),
  );

export const getSelectedRowIds = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) => entities[id].selectedRowIds);

/**
 * @Deprecated - Tohle vrati pouze vytazenen zaznamy u jedne stranky, vsechny zaznamy jsou v selectedRowIds (pouze jako IDcka)
 */
export const getSelectedEntities = (id: TableKeyType, rowIdKey?: string) =>
  createSelector(selectEntities, (entities) => {
    const rowIdField = entities[id]?.defaults?.rowIdField ?? 'id';
    return entities[id].data.items.filter((item) => {
      return !!entities[id].selectedRowIds.find((id) =>
        rowIdKey
          ? id == (item as any)[rowIdKey]
          : id == (item as any)[rowIdField],
      );
    });
  });

export const getEntitiesByIds = (id: TableKeyType, rowIdKeys: string[]) =>
  createSelector(selectEntities, (entities) => {
    const rowIdField = entities[id]?.defaults?.rowIdField ?? 'id';
    return entities[id].data.items.filter((item) => {
      return rowIdKeys.includes(item[rowIdField]);
    });
  });

export const getRowEntities = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) => {
    return entities[id].data.items;
  });

export const getDataViewMode = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) => {
    return entities[id] == null
      ? DataViewModeEnum.TABLE
      : entities[id].dataViewMode;
  });

export const getFilters = (id: TableKeyType) =>
  createSelector(selectEntities, (entities) => entities[id].filters);
