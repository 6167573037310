import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {
  LoadCheckOnline,
  LoadCheckOnlineError,
  LoadCheckOnlineSuccess,
} from '../actions';
import {UserService} from '../service';
import {translation} from '../i18n';

@Injectable()
export class CheckOnlineEffects {
  translation = translation;

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCheckOnline),
      concatMap(({id}) => {
        return this.userService
          .checkOnline(id)
          .pipe(
            map((env) =>
              env.success
                ? LoadCheckOnlineSuccess({id, online: env.data})
                : LoadCheckOnlineError({id, error: env.error}),
            ),
          );
      }),
    ),
  );
}
