import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  DeleteSharing,
  DeleteSharingSuccess,
  LoadSharing,
  LoadSharingError,
  LoadSharingSuccess,
  UpsertSharing,
  UpsertSharingSuccess,
} from '../actions';
import {Sharing} from '../models';
import {IdentifiedPack} from '@tsm/framework/root';

export type SharingState = EntityState<IdentifiedPack<Sharing>>;

export const sharingAdapter: EntityAdapter<IdentifiedPack<Sharing>> =
  createEntityAdapter<IdentifiedPack<Sharing>>({});
export const initialState: SharingState = sharingAdapter.getInitialState({});

export const sharingReducer = createReducer(
  initialState,

  on(LoadSharing, (state, {}) => ({...state, loading: true})),

  on(LoadSharingSuccess, (state, {sharing}) =>
    sharingAdapter.addMany(
      sharing.map((share) => ({
        id: share.id,
        data: share,
        loading: false,
        error: null,
      })),
      state,
    ),
  ),

  on(LoadSharingError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(DeleteSharing, (state, {id}) =>
    sharingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteSharingSuccess, (state, {id}) =>
    sharingAdapter.removeOne(id, state),
  ),

  on(UpsertSharing, (state, {sharing}) =>
    sharingAdapter.updateOne(
      {
        id: sharing.id,
        changes: {
          ...state.entities[sharing.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertSharingSuccess, (state, {sharing}) =>
    sharingAdapter.upsertOne(
      {
        id: sharing.id,
        data: sharing,
        loading: false,
        error: null,
      },
      state,
    ),
  ),
);
