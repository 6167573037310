import {SelectItem} from 'primeng/api/selectitem';

export enum NotificationStatus {
  ALL = 'ALL', // slouzi jenom na GUI
  PLAN = 'PLAN',
  PROCESSING = 'PROCESSING',
  SENT = 'SENT',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  DELETED = 'DELETED',

  TEMPLATE_INACTIVE = 'TEMPLATE_INACTIVE',
  CAT_LOW_PRIORITY = 'CAT_LOW_PRIORITY',
  DISABLED = 'DISABLED',
  CONFIRMED = 'CONFIRMED',
}

export function getAllNotificationStatuses(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(NotificationStatus).forEach((val) =>
    items.push({label: NotificationStatus[val], value: val}),
  );
  return items;
}
