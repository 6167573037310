import {SelectItem} from 'primeng/api';

export enum DataViewModeEnum {
  TABLE = 'TABLE',
  TABLE_DETAIL = 'TABLE_DETAIL',
  TABLE_FULLSCREEN = 'TABLE_FULLSCREEN',
  TREE = 'TREE',
  TREE_FULLSCREEN = 'TREE_FULLSCREEN',
  KANBAN = 'KANBAN',
}

export function getDataViewModes(...modes: DataViewModeEnum[]): SelectItem[] {
  const items: SelectItem[] = [];
  modes.forEach((val) =>
    items.push({
      label: 'listingLib.dataViewMode.' + DataViewModeEnum[val],
      value: val,
    }),
  );
  return items;
}
