import {Pipe, untracked} from '@angular/core';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {LoadCharacteristicsByFilter} from '../actions';
import {selectCharacteristicsByCodes} from '../selectors';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {FilterOperator} from '@tsm/listing-lib/service';

@Pipe({
  name: 'characteristicsByCodes',
  pure: true,
})
export class CharacteristicsByCodesPipe extends AbstractCachedLoaderPipe {
  constructor(store: Store<any>) {
    super(store);
  }

  setObservable(id: any, field = 'all') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectCharacteristicsByCodes(this._id)),
      map((it) => {
        if (!it) {
          return id;
        } else {
          return field !== 'all'
            ? it.length > 0
              ? it.map((row) => row[field])
              : id
            : it;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    const ids = this._id;
    this._checkSubscription = this.store
      .pipe(select(selectCharacteristicsByCodes(this._id)))
      .subscribe((x) => {
        if (!x || x.length === 0) {
          untracked(() =>
            this.store.dispatch(
              LoadCharacteristicsByFilter({
                filters: [
                  {
                    field: 'code',
                    operator: FilterOperator.in,
                    value: ids,
                  },
                ],
              }),
            ),
          );
        }
      });
  }
}
