import * as Widgets from './widgets-index';
import {SharedModule} from '@tsm/shared';
import {
  LetDirectiveModule,
  PageTitleDirectiveModule,
} from '@tsm/framework/root/directives';
import {FormModule} from '@tsm/framework/form-inputs';
import {AccordionModule} from '@tsm/framework/override-primeng/accordion';
import {MenuModule} from '@tsm/framework/override-primeng/menu';
import {AutoCompleteModule} from '@tsm/framework/override-primeng/autocomplete';
import {OverlayPanelModule} from '@tsm/framework/override-primeng/overlay-panel';
import {PrivilegeModule} from '@tsm/privilege';
import {TranslateModule} from '@tsm/framework/translate';
import {localizations} from './i18n';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {HotkeyDirectiveModule} from '@tsm/framework/shortcuts';
import {LayoutServiceModule} from '@tsm/layout/service';
import {DynamicComponentModule} from '@tsm/framework/dynamic-component';
import {CardModule} from 'primeng/card';
import {StyleClassModule} from 'primeng/styleclass';
import {BadgeModule} from 'primeng/badge';
import {TreeModule} from 'primeng/tree';
import {DmsServiceModule} from '@tsm/dms/service';
import {FrameworkTablePaginatorModule} from '@tsm/framework/table-paginator';
import {LocalStateModule} from '@tsm/framework/local-state';
import {ListingLibServiceModule} from '@tsm/listing-lib/service';
import {FrameworkIframeKioskModule} from '@tsm/framework/iframe-kiosk';
import {ResizableModule} from 'angular-resizable-element';
import {FrameworkResizeObserverModule} from '@tsm/framework/resize-observer';
import {SkeletonModule} from 'primeng/skeleton';
import {DialogModule} from 'primeng/dialog';
import {ChipModule} from 'primeng/chip';
import {TagModule} from 'primeng/tag';

export const COMPONENTS = [
  Widgets.BlockableDivComponent,
  Widgets.LayoutMenuComponent,
  Widgets.LayoutMenuItemComponent,
  Widgets.LayoutMenuItemEditorComponent,
  Widgets.LayoutMenuDesignerComponent,
  Widgets.TopbarComponent,
  Widgets.LayoutTsmComponent,
  Widgets.TeamsLayoutComponent,
  Widgets.PanelPinComponent,
];

export const IMPORTS = [
  SharedModule,
  LayoutServiceModule,
  DmsServiceModule,
  FrameworkTablePaginatorModule,
  FormModule,
  PrivilegeModule,
  TranslateModule.forFeature(localizations),
  HotkeyDirectiveModule,
  OverlayPanelModule,
  FrameworkGridModule,
  AccordionModule,
  LetDirectiveModule,
  DynamicComponentModule,
  MenuModule,
  CardModule,
  StyleClassModule,
  BadgeModule,
  TreeModule,
  LocalStateModule,
  ListingLibServiceModule,
  PageTitleDirectiveModule,
  ResizableModule,
  FrameworkResizeObserverModule,
  SkeletonModule,
  FrameworkIframeKioskModule,
  AutoCompleteModule,
  DialogModule,
  ChipModule,
  TagModule,
];
