import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  DeleteListingTypeSuccess,
  DiffListingType,
  DiffListingTypeError,
  DiffListingTypeSuccess,
  LoadListingTypeByCode,
  LoadListingTypeByCodeError,
  LoadListingTypeByCodeSuccess,
  LoadListingTypeById,
  LoadListingTypeByIdError,
  LoadListingTypeByIdSuccess,
  LoadListingTypeSuccess,
  UpsertListingType,
  UpsertListingTypeSuccess,
} from '../actions';
import {ListingType} from '../models';

export type ListingTypeState = EntityState<IdentifiedPack<ListingType>>;

export const listingTypeAdapter: EntityAdapter<IdentifiedPack<ListingType>> =
  createEntityAdapter<IdentifiedPack<ListingType>>({});

const initialState: ListingTypeState = listingTypeAdapter.getInitialState({});

export const listingTypeReducer = createReducer(
  initialState,

  on(LoadListingTypeSuccess, (state, {listingTypes}) => ({
    ...listingTypeAdapter.upsertMany(
      listingTypes.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
  })),

  on(LoadListingTypeById, (state, {id}) =>
    listingTypeAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadListingTypeByIdSuccess, (state, {listingType}) =>
    listingTypeAdapter.upsertOne(
      {
        id: listingType.id,
        data: listingType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadListingTypeByIdError, (state, {id, error}) =>
    listingTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(LoadListingTypeByCode, (state, {code}) =>
    listingTypeAdapter.upsertOne(
      {
        id: code,
        data: null,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadListingTypeByCodeSuccess, (state, {listingType}) => {
    const entArray = Object.keys(state.entities).map(
      (it) => state.entities[it],
    );
    const relatedValIds = entArray
      .filter((ent) => ent.data == null && listingType.code === ent.id)
      .map((ent) => ent.id);
    const newState = listingTypeAdapter.removeMany(relatedValIds, state);

    return listingTypeAdapter.upsertOne(
      {
        id: listingType.id,
        data: listingType,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadListingTypeByCodeError, (state, {code, error}) => {
    return listingTypeAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    );
  }),

  on(UpsertListingType, (state, {listingType}) => {
    const foundItem = state.entities[listingType.id];
    return listingTypeAdapter.updateOne(
      {
        id: listingType.id,
        changes: {
          ...state.entities[listingType.id],
          loading: foundItem?.data == null,
        },
      },
      state,
    );
  }),

  on(UpsertListingTypeSuccess, (state, {listingType}) =>
    listingTypeAdapter.upsertOne(
      {
        id: listingType.id,
        data: listingType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteListingTypeSuccess, (state, listingType) =>
    listingTypeAdapter.removeOne(listingType.id, state),
  ),

  on(DiffListingType, (state, {diffEntities}) =>
    listingTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),

  on(DiffListingTypeSuccess, (state, {listingTypes}) =>
    listingTypeAdapter.upsertMany(
      listingTypes.map((listingType) => {
        return {
          id: listingType.id,
          data: listingType,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffListingTypeError, (state, {diffEntities, error}) =>
    listingTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
