<div class="field mb-3 px-2 w-12">
  <label class="block">
    <span
      class="block font-bold text-lg relative"
      [ngClass]="requiredMark ? 'required' : ''"
      >{{ fieldName }}
    </span>
    <span class="block text-sm text-600">{{ fieldDescription }}</span>
  </label>
  <div
    *ngIf="!showField"
    class="flex align-items-center my-3 py-2 w-full surface-100 border-round-sm"
  >
    <div class="flex-1 flex align-items-center gap-1 justify-content-center">
      <div class="font-medium inline-flex flex-column text-center">
        <span>
          <i
            class="pi pi-info-circle"
            tsmTooltip="{{
              (isPrefix
                ? translationShared.shared.createAutomaticallyWithPrefixTooltip
                : translationShared.shared.createAutomaticallyTooltip
              ) | transloco
            }}"
          >
          </i>
          {{
            (isPrefix
              ? translationShared.shared.createAutomaticallyWithPrefix
              : translationShared.shared.createAutomatically
            ) | transloco
          }}
        </span>
        <ng-content select="[automaticData]"></ng-content>
      </div>
      <div class="w-2" *ngIf="isPrefix">
        <ng-content select="[prefixField]"></ng-content>
      </div>
    </div>
    <div class="flex-none flex">
      <p-divider layout="vertical">
        <span class="p-2 surface-100 -m-2 font-semibold"
          ><i class="pi pi-angle-double-right"></i
        ></span>
      </p-divider>
    </div>
    <div class="flex-1 flex align-items-center justify-content-center">
      <button
        pButton
        label="{{ translationShared.shared.chooseFromExisting | transloco }}"
        class="p-button-success p-button-outlined p-button-text border-round-sm p-3 mr-3"
        icon="pi pi-plus"
        (click)="toggleField()"
      ></button>
    </div>
  </div>
  <div *ngIf="showField" class="flex align-items-center">
    <div class="w-6">
      <ng-content></ng-content>
    </div>

    <span class="ml-2">
      <button
        pButton
        pRipple
        class="p-button-outlined p-button-info border-round-md"
        icon="pi pi-sparkles"
        (click)="toggleField()"
        [label]="
          (isPrefix
            ? translationShared.shared.createAutomaticallyWithPrefix
            : translationShared.shared.createAutomatically
          ) | transloco
        "
      ></button>
      <!--      <label>-->
      <!--        <input type="checkbox" (click)="toggleField()" />-->
      <!--        {{-->
      <!--          (isPrefix-->
      <!--            ? translationShared.shared.createAutomaticallyWithPrefix-->
      <!--            : translationShared.shared.createAutomatically-->
      <!--          ) | transloco-->
      <!--        }}</label>-->
    </span>
  </div>
</div>
