<!-- INPUT -->
<ng-template #defaultInputTemplate let-readonly="readonly">
  <dtl-form-input-text
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [setAutofocus]="setAutofocus"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="generatedLabelId"
  >
  </dtl-form-input-text>
</ng-template>

<!-- ICON PICKER -->
<ng-template #defaultIconPickerTemplate let-readonly="readonly">
  <tsm-form-input-icon-picker
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [setAutofocus]="setAutofocus"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="generatedLabelId"
    (iconSelected)="iconSelected.emit($event)"
  >
  </tsm-form-input-icon-picker>
</ng-template>

<!-- TEXTAREA -->
<ng-template #defaultTextareaTemplate let-readonly="readonly">
  <dtl-form-input-textarea
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [noneText]="noneText"
    [placeholder]="placeholder"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [setAutofocus]="setAutofocus"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-textarea>
</ng-template>

<!-- DATE -->
<ng-template #defaultDateTemplate let-readonly="readonly">
  <dtl-form-input-date
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [yearRange]="yearRange"
    [pickDateTimeButtonLabel]="pickDateTimeButtonLabel"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [roundEndDate]="roundEndDate"
    [readonly]="readonly"
    [showIcon]="calendarShowIcon"
    [showCloseIcon]="calendarShowCloseIcon"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="generatedLabelId"
  >
  </dtl-form-input-date>
</ng-template>

<!-- DATETIME -->
<ng-template #defaultDatetimeTemplate let-readonly="readonly">
  <dtl-form-input-date
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [yearRange]="yearRange"
    [showSeconds]="showSeconds"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [readonly]="readonly"
    [showPickDateTimeButton]="showPickDateTimeButton"
    [pickDateTimeButtonLabel]="pickDateTimeButtonLabel"
    [showTime]="showTime"
    [showMilliseconds]="showMilliseconds"
    [showIcon]="calendarShowIcon"
    [showCloseIcon]="calendarShowCloseIcon"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="generatedLabelId"
  >
  </dtl-form-input-date>
</ng-template>

<!-- TIME -->
<ng-template #defaultTimeTemplate let-readonly="readonly">
  <dtl-form-input-date
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [yearRange]="yearRange"
    [showSeconds]="showSeconds"
    [pickDateTimeButtonLabel]="pickDateTimeButtonLabel"
    [readonly]="readonly"
    [timeOnly]="true"
    [showIcon]="calendarShowIcon"
    [showCloseIcon]="calendarShowCloseIcon"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="generatedLabelId"
  >
  </dtl-form-input-date>
</ng-template>

<!-- MONTH AND YEAR -->
<ng-template #defaultMonthYearTemplate let-readonly="readonly">
  <dtl-form-input-date
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [yearRange]="yearRange"
    [showSeconds]="showSeconds"
    [pickDateTimeButtonLabel]="pickDateTimeButtonLabel"
    [readonly]="readonly"
    [monthYear]="true"
    [showIcon]="calendarShowIcon"
    [showCloseIcon]="calendarShowCloseIcon"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
    [inputId]="generatedLabelId"
  >
  </dtl-form-input-date>
</ng-template>

<!-- CHECKBOX -->
<ng-template #defaultCheckboxTemplate let-readonly="readonly">
  <dtl-form-input-checkbox
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-checkbox>
</ng-template>

<!-- TRI STATE CHECKBOX -->
<ng-template #defaultTriStateCheckboxTemplate let-readonly="readonly">
  <dtl-form-input-tri-state-checkbox
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-tri-state-checkbox>
</ng-template>

<!-- RADIOBUTTON -->
<ng-template #defaultRadioButtonTemplate let-readonly="readonly">
  <dtl-form-input-radiobutton
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [radioButtonValue]="radioButtonValue"
    [radioButtonGroup]="radioButtonGroup"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-radiobutton>
</ng-template>

<!-- TIPTAP -->
<ng-template #defaultTiptapTemplate let-readonly="readonly">
  <dtl-form-input-tiptap
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [hideMenuItemIframe]="hideMenuItemIframe"
    [hideMenuItemDiagram]="hideMenuItemDiagram"
    [hideMenuItemImage]="hideMenuItemImage"
    [hideMenuItemAttachments]="hideMenuItemAttachments"
    [hideMenuItemInsertCode]="hideMenuItemInsertCode"
    [hideMenuItemSourceCode]="hideMenuItemSourceCode"
    [pastePlainImages]="pastePlainImages"
    [hashtagOption]="hashtagOption"
    [suggestionScriptCode]="suggestionScriptCode"
    [suggestionScriptData]="suggestionScriptData"
    [clickScriptCode]="clickScriptCode"
    [clickScriptData]="clickScriptData"
    [moreSelector]="moreSelector"
    [moreInputs]="moreInputs"
    [disableTableStyle]="disableTableStyle"
    [ownerId]="ownerId"
    [ownerType]="ownerType"
    [customButtonText]="customButtonText"
    [customButtonIcon]="customButtonIcon"
    (changeFocus)="changeFocus.emit($event)"
    (hashtagAttachmentIds)="hashtagAttachmentIds.emit($event)"
    (hashtagSelectionChanged)="hashtagSelectionChanged.emit($event)"
  >
  </dtl-form-input-tiptap>
</ng-template>

<!-- TIPTAP - SMALL -->
<ng-template #defaultTiptapSmallTemplate let-readonly="readonly">
  <dtl-form-input-tiptap
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [menuType]="'small'"
    [hideMenuItemIframe]="hideMenuItemIframe"
    [hideMenuItemDiagram]="hideMenuItemDiagram"
    [hideMenuItemImage]="hideMenuItemImage"
    [hideMenuItemAttachments]="hideMenuItemAttachments"
    [hideMenuItemInsertCode]="hideMenuItemInsertCode"
    [hideMenuItemSourceCode]="hideMenuItemSourceCode"
    [pastePlainImages]="pastePlainImages"
    [hashtagOption]="hashtagOption"
    [suggestionScriptCode]="suggestionScriptCode"
    [suggestionScriptData]="suggestionScriptData"
    [clickScriptCode]="clickScriptCode"
    [clickScriptData]="clickScriptData"
    [moreSelector]="moreSelector"
    [moreInputs]="moreInputs"
    [disableTableStyle]="disableTableStyle"
    [ownerId]="ownerId"
    [ownerType]="ownerType"
    [customButtonText]="customButtonText"
    [customButtonIcon]="customButtonIcon"
    (changeFocus)="changeFocus.emit($event)"
    (hashtagAttachmentIds)="hashtagAttachmentIds.emit($event)"
    (hashtagSelectionChanged)="hashtagSelectionChanged.emit($event)"
  >
  </dtl-form-input-tiptap>
</ng-template>

<!-- TIPTAP - LIGHT -->
<ng-template #defaultTiptapLightTemplate let-readonly="readonly">
  <dtl-form-input-tiptap
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [menuType]="'light'"
    [hideMenuItemIframe]="hideMenuItemIframe"
    [hideMenuItemDiagram]="hideMenuItemDiagram"
    [hideMenuItemImage]="hideMenuItemImage"
    [hideMenuItemAttachments]="hideMenuItemAttachments"
    [hideMenuItemInsertCode]="hideMenuItemInsertCode"
    [hideMenuItemSourceCode]="hideMenuItemSourceCode"
    [pastePlainImages]="pastePlainImages"
    [hashtagOption]="hashtagOption"
    [suggestionScriptCode]="suggestionScriptCode"
    [suggestionScriptData]="suggestionScriptData"
    [clickScriptCode]="clickScriptCode"
    [clickScriptData]="clickScriptData"
    [moreSelector]="moreSelector"
    [moreInputs]="moreInputs"
    [disableTableStyle]="disableTableStyle"
    [ownerId]="ownerId"
    [ownerType]="ownerType"
    [customButtonText]="customButtonText"
    [customButtonIcon]="customButtonIcon"
    (changeFocus)="changeFocus.emit($event)"
    (hashtagAttachmentIds)="hashtagAttachmentIds.emit($event)"
    (hashtagSelectionChanged)="hashtagSelectionChanged.emit($event)"
  >
  </dtl-form-input-tiptap>
</ng-template>

<!-- NUMBER -->
<ng-template #defaultNumberTemplate let-readonly="readonly">
  <dtl-form-input-number
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [min]="min"
    [max]="max"
    [fractionDigits]="fractionDigits"
    [step]="step"
    [showButtons]="stepShowButtons"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-number>
</ng-template>

<!-- SORT -->
<ng-template #defaultNumberSortTemplate let-readonly="readonly">
  <dtl-form-input-number
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [min]="-1"
    [max]="1"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-number>
</ng-template>

<!-- PINT -->
<ng-template #defaultPintTemplate let-readonly="readonly">
  <dtl-form-input-key-filter
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-key-filter>
</ng-template>

<!-- INT -->
<ng-template #defaultIntTemplate let-readonly="readonly">
  <dtl-form-input-integer
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-integer>
</ng-template>

<!-- NUM -->
<ng-template #defaultNumTemplate let-readonly="readonly">
  <dtl-form-input-key-filter
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    keyFilter="num"
    [setAutofocus]="setAutofocus"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-key-filter>
</ng-template>

<!-- regexp -->
<ng-template #defaultRegexTemplate let-readonly="readonly">
  <dtl-form-input-key-filter-regex
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [keyFilter]="regex"
    [setAutofocus]="setAutofocus"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-key-filter-regex>
</ng-template>

<!-- RATING -->
<ng-template #defaultRatingTemplate let-readonly="readonly">
  <dtl-form-input-rating
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-rating>
</ng-template>

<!-- PASSWORD -->
<ng-template #defaultPasswordTemplate let-readonly="readonly">
  <dtl-form-input-password
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-password>
</ng-template>

<!-- XML CODE EDITOR -->
<ng-template #defaultXmlTemplate let-readonly="readonly">
  <dtl-form-input-xml
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-xml>
</ng-template>

<!-- MARKDOWN CODE EDITOR -->
<ng-template #defaultMarkdownTemplate let-readonly="readonly">
  <dtl-form-input-markdown
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    [updateIfDirty]="updateIfDirty"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-markdown>
</ng-template>

<!-- JSON CODE EDITOR -->
<ng-template #defaultJsonTemplate let-readonly="readonly">
  <dtl-form-input-json
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [wrapLines]="wrapLines"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-json>
</ng-template>

<!-- JSON-OBJECT CODE EDITOR -->
<ng-template #defaultJsonObjectTemplate let-readonly="readonly">
  <dtl-form-input-json-object
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [updateIfDirty]="updateIfDirty"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-json-object>
</ng-template>

<!-- JAVA CODE EDITOR -->
<ng-template #defaultJavaTemplate let-readonly="readonly">
  <dtl-form-input-java
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-java>
</ng-template>

<!-- SQL CODE EDITOR -->
<ng-template #defaultSqlTemplate let-readonly="readonly">
  <dtl-form-input-sql
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-sql>
</ng-template>

<!-- HTML CODE EDITOR -->
<ng-template #defaultHtmlTemplate let-readonly="readonly">
  <dtl-form-input-html
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [widgetHeight]="codeEditorHeight"
    [resizable]="codeEditorResizable"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-html>
</ng-template>

<!-- LINK -->
<ng-template #defaultLinkTemplate let-readonly="readonly">
  <dtl-form-input-link
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
  ></dtl-form-input-link>
</ng-template>

<!-- MASK -->
<ng-template #defaultMaskTemplate let-readonly="readonly">
  <dtl-form-input-mask
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [mask]="mask"
    [placeholder]="maskPlaceholder"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-mask>
</ng-template>

<!-- PHONE -->
<ng-template #defaultPhoneTemplate let-readonly="readonly">
  <dtl-form-input-phone
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [placeholder]="placeholder"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-phone>
</ng-template>

<!-- SWITCH -->
<ng-template #defaultSwitchTemplate let-readonly="readonly">
  <dtl-form-input-switch
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    [leftLabel]="leftSwitchLabel"
    [rightLabel]="rightSwitchLabel"
    (changeFocus)="changeFocus.emit($event)"
  >
  </dtl-form-input-switch>
</ng-template>

<!-- PREVIEW DROPDOWN -->
<ng-template #defaultPreviewDropdownTemplate let-readonly="readonly">
  <tsm-preview-dropdown
    [class.ng-required]="required == true"
    [ngClass]="formFieldTemplateExtendClass"
    [tsmFormControl]="control"
    [useFormControl]="useFormControl"
    [tabindex]="tabindex"
    [readonly]="readonly"
    (changeFocus)="changeFocus.emit($event)"
  >
  </tsm-preview-dropdown>
</ng-template>

<ng-template #actionButtonsTemplate let-readonly="readonly">
  <ng-container *ngIf="showRemoveButton">
    <div class="buttons-container-v flex flex-col pl-1 items-center">
      <!-- Remove Button -->
      <button
        *ngIf="showRemoveButton"
        pButton
        pRipple
        icon="pi pi-times"
        class="p-button-text p-button-rounded p-button-sm text-red-500"
        (click)="removeClick.emit()"
      ></button>
    </div>
  </ng-container>
  <ng-container *ngIf="showLangButton">
    <button
      pButton
      pRipple
      class="p-button-text p-button-rounded ml-1"
      icon="pi pi-language"
      (click)="showLangClick.emit()"
    ></button>
  </ng-container>
</ng-template>

<dtl-form-field-container
  [labelPosition]="labelPosition"
  [ngClass]="formFieldContainerExtendClass"
  [ngStyle]="formFieldContainerExtendStyle"
  [class.without-label]="!label"
  [excelStyleErrors]="
    excelStyleErrors == null ? excelStyleErrorsAppParams : excelStyleErrors
  "
  (click)="containerClicked()"
  [tooltip]="tooltip"
  [rightIcon]="rightIcon"
  [leftIcon]="leftIcon"
>
  <dtl-form-field-label
    *ngIf="label"
    [helpMessage]="helpMessage"
    [helpMessageStatic]="helpMessageStatic"
    [maxWidthRemTooltip]="maxWidthRemTooltip"
    [labelForId]="generatedLabelId"
    [ngClass]="formFieldLabelExtendClass"
  >
    {{ label | transloco }}{{ !!label && labelPosition === 'left' ? ':' : '' }}
  </dtl-form-field-label>
  <ng-container *ngTemplateOutlet="template; context: context"></ng-container>
  <!-- touched se vola nespravne az kdyz se zedituje hodnota - neni to idealni -->
  <ng-container
    *ngTemplateOutlet="actionButtonsTemplate; context: {readonly: readonly}"
  ></ng-container>
  <dtl-form-field-errors
    [errors]="control?.errors"
    [touched]="control?.touched"
    [excelStyleErrors]="
      excelStyleErrors == null ? excelStyleErrorsAppParams : excelStyleErrors
    "
  ></dtl-form-field-errors>
</dtl-form-field-container>
