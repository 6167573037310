import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  LoadNotificationCount,
  LoadNotificationCountError,
  LoadNotificationCountSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';
import {NotificationCountState} from '../models';

export const notificationCountAdapter: EntityAdapter<
  IdentifiedPack<NotificationCountState>
> = createEntityAdapter<IdentifiedPack<NotificationCountState>>({});
export const initialState: EntityState<IdentifiedPack<NotificationCountState>> =
  notificationCountAdapter.getInitialState({});

export const notificationCountReducer = createReducer(
  initialState,
  on(LoadNotificationCount, (state, {deliveryType}) =>
    notificationCountAdapter.upsertOne(
      {
        ...state.entities[deliveryType],
        id: deliveryType,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationCountSuccess, (state, {deliveryType, count}) =>
    notificationCountAdapter.upsertOne(
      {
        id: deliveryType,
        data: count,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadNotificationCountError, (state, {deliveryType, error}) =>
    notificationCountAdapter.updateOne(
      {
        id: deliveryType,
        changes: {
          ...state.entities[deliveryType],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),
);
