import {
  Directive,
  ElementRef,
  EventEmitter,
  NgModule,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[mutated]',
})
export class ObserveMutationDirective implements OnDestroy {
  @Output('mutated') mutated: EventEmitter<any> = new EventEmitter<any>();

  private _observer: MutationObserver;

  constructor(private el: ElementRef) {
    this._observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation, index) => {
        this.mutated.emit(mutation.target);
      });
    });

    this._observer.observe(this.el.nativeElement, {
      childList: true,
      attributes: false,
      characterData: false,
    });
  }

  ngOnDestroy() {
    if (this._observer) {
      this._observer.disconnect();
      this._observer = null;
    }
  }
}

@NgModule({
  declarations: [ObserveMutationDirective],
  exports: [ObserveMutationDirective],
})
export class ObserveMutationModule {}
