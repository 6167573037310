import {Plugin} from '@tsm/framework/plugin';

export const dashboardPlugin: Plugin = {
  menus: [
    {
      key: 'dashboard',
      priority: 2000,
      label: 'Dashboard',
      icon: 'tsm-icon-home',
      privilege: ['true'],
      items: [
        {
          key: 'dashboard-manage',
          label: 'menu.dashboard.dashboardManage',
          icon: 'tsm-icon-home',
          routerLink: ['/dashboard/dashboard-manage'],
          privilege: ['true'],
          priority: 0,
        },
      ],
    },
  ],
  routes: [
    {
      path: 'dashboard',
      loadChildren: () =>
        import('@tsm/dashboard/components').then((x) => x.DashboardModule),
    },
  ],
  widgets: [],
};
