import {
  AfterViewChecked,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Dialog} from 'primeng/dialog';
import {Router} from '@angular/router';
import {isMobile} from '@tsm/framework/functions';
import {LocalStateService} from '@tsm/framework/local-state';
import {DomHandler} from 'primeng/dom';
import {ZIndexUtils} from 'primeng/utils';
import {PrimeNGConfig} from 'primeng/api';
import {UserParameterName} from '@tsm/user-parameters';
import {v4 as getUuid} from 'uuid';

@Component({
  selector: 'tsm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  isMobile = isMobile();

  @Input() visible = true;

  @Input() header: string;

  @Input() position:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright';

  @Input() maximizable = false;

  @Input() defaultMaximizable = false;

  @Input() closeIcon = 'pi pi-times';

  @Input() minimizeIcon = 'pi pi-window-minimize';

  @Input() maximizeIcon = 'pi pi-window-maximize';

  @Input() contentStyle = {};

  @Input() appendTo: any;

  @Input() style: any = {width: '900px'};

  @Input() baseZIndex = 0;

  @Input() customTemplate: TemplateRef<any>;

  @Input() minY = 0;

  @Input() focusOnShow = false;

  @Input() styleClass: string;

  @Input() showHeader = true;

  @Input() autoZIndex = true;

  @Input() closeOnEscape = true;

  // ZMENUZIVAM maskStyleClass pro nastaveni unikatniho identifikatoru pro dialog-mask,
  // pouziva se pro spravne zavirani dialogu pomoci klavesy Esc, kdyz jich je otevreno vice nad sebou
  maskStyleClass = getUuid();

  container: HTMLDivElement;

  wrapper: HTMLElement;

  _centered: any;

  get centered(): any {
    return this._centered;
  }

  @Input()
  set centered(event: any) {
    this._centered = event;
    if (this.dialog && this.dialog.container) {
      this.dialog.center();
    }
  }

  @ContentChild(TemplateRef, {read: TemplateRef}) contentedTemplate;

  @ViewChild('tsmDialog', {static: true})
  private dialog: Dialog;

  /**
   * Input na kterou se aplikace presmeruje po zmacknuti praveho hornho krozku nebo klavesy Esc
   */
  @Input() closeUrl: string;

  /**
   * Při stisknutí křížku provede navigaci "back", dává smysl pouze při navigaci na URL popup.
   */
  @Input() closeNavigatesBack = false;

  /**
   * Funkce, ktera se zavola po zmacknuti praveho hornho krozku nebo klavesy Esc.
   * Atribut closeUrl a closeNavigatesBack se zavola soucasne s touto funkci
   * @return confirmCloseButton - uzivatelsky parametr
   */
  @Output() close = new EventEmitter<boolean>();

  @Output() onMaximize = new EventEmitter<any>();

  resizable = false;
  modal = true;
  confirmCloseButton = false;

  context = {$implicit: null};
  private mutationObserver: MutationObserver;

  constructor(
    private router: Router,
    private localStateService: LocalStateService,
    public config: PrimeNGConfig,
  ) {
    // this.mutationObserver = new MutationObserver(() => {
    //   this.dialog.center();
    // });

    this.confirmCloseButton =
      localStateService.getStateInstant(
        UserParameterName.CONFIRM_CLOSE_BUTTON,
      ) === 'true';
  }

  ngOnInit(): void {
    // this.dialog.moveOnTop = () => {
    //   this.dialog.container.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
    //   this.dialog.wrapper.style.zIndex = String(this.baseZIndex + (DomHandler.zindex - 1));
    // };
    this.dialog.onDrag = (event: MouseEvent) => {
      if (this.dialog.dragging) {
        const containerWidth = DomHandler.getOuterWidth(this.dialog.container);
        const containerHeight = DomHandler.getOuterHeight(
          this.dialog.container,
        );
        const deltaX = event.pageX - this.dialog.lastPageX;
        const deltaY = event.pageY - this.dialog.lastPageY;
        const offset = this.getOffset(this.dialog.container);
        const leftPos = offset.left + deltaX;
        const topPos = offset.top + deltaY;
        const viewport = DomHandler.getViewport();

        this.dialog.container.style.position = 'fixed';

        if (this.dialog.keepInViewport) {
          if (
            leftPos >= this.dialog.minX &&
            leftPos + containerWidth < viewport.width
          ) {
            this.dialog._style.left = leftPos + 'px';
            this.dialog.lastPageX = event.pageX;
            this.dialog.container.style.left = leftPos + 'px';
          }

          if (
            topPos >= this.dialog.minY &&
            topPos + containerHeight < viewport.height
          ) {
            this.dialog._style.top = topPos + 'px';
            this.dialog.lastPageY = event.pageY;
            this.dialog.container.style.top = topPos + 'px';
          }
        } else {
          this.dialog.lastPageX = event.pageX;
          this.dialog.container.style.left = leftPos + 'px';
          this.dialog.lastPageY = event.pageY;
          this.dialog.container.style.top = topPos + 'px';
        }
      }
    };

    this.dialog.bindDocumentEscapeListener = () => {
      const documentTarget: any = this.dialog.el
        ? this.dialog.el.nativeElement.ownerDocument
        : 'document';

      this.dialog.documentEscapeListener = this.dialog.renderer.listen(
        documentTarget,
        'keydown',
        (event) => {
          const dialogElements =
            document.getElementsByClassName('p-dialog-mask');
          const lastChild = dialogElements[dialogElements.length - 1];
          if (
            event.which == 27 &&
            lastChild.className.includes(this.maskStyleClass)
          ) {
            this.dialog.close(event);
          }
        },
      );
    };

    if (this.defaultMaximizable) {
      this.dialog.maximize();
    }
  }

  private getOffset(el) {
    const rect = el.getBoundingClientRect();

    return {
      top: rect.top,
      left:
        rect.left +
        (window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0),
    };
  }

  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set(
        'modal',
        this.container,
        this.baseZIndex + this.config.zIndex.modal,
      );
      this.wrapper.style.zIndex = String(
        parseInt(this.container.style.zIndex, 10) - 1,
      );
    }
  }

  ngAfterViewChecked(): void {
    // if (this.dialog && this.dialog.container) {
    //   this.mutationObserver.disconnect();
    //   this.mutationObserver.observe(this.dialog.container, {
    //     attributes: false,
    //     childList: true,
    //     subtree: true
    //   });
    // }
  }

  onClose() {
    this.close.emit(this.confirmCloseButton);
    if (this.closeUrl) {
      this.router.navigate([this.closeUrl]);
    } else if (this.closeNavigatesBack) {
      history.back();
    }
  }

  ngOnDestroy() {
    // if (this.mutationObserver) {
    //   this.mutationObserver.disconnect();
    //   this.mutationObserver = null;
    // }
  }
}
