import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {adapter} from '../reducers/user-user-group.reducer';
import {UserManagementState} from '../reducers';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserUserGroupState = createSelector(
  getUserManagementState,
  (userUserGroupState) => userUserGroupState.userUserGroup,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserUserGroupState);

export const selectUserUserGroupByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectUserUserGroupById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectUserUserGroupsByUserId = (userId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.user.id === userId),
  );

export const selectUserUserGroupsByGroupId = (groupId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.userGroup.id === groupId),
  );

export const selectUserUserGroupsByGroupCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.userGroup.code === code),
  );

export const selectUserUserGroupByGroupIds = (groupIds: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => groupIds.includes(x.data.userGroup.id)),
  );

export const getAllUserUserGroups = selectAll;
