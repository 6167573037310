import {Pipe, PipeTransform} from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({
  name: 'find',
  pure: true,
})
export class FindPipe implements PipeTransform {
  constructor() {}

  transform(arr: any[], field: string, value: any): any[] {
    return arr.find((x) => objectPath.get(x, field) === value);
  }
}
