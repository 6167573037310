import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable, Optional} from '@angular/core';
import {tap} from 'rxjs';

import {RuntimeService} from '../services';
import {SecurityDataService} from '@tsm/framework/security';
import {RuntimeInfoLoadSuccessAction} from '../actions';
import {getUserId} from '@tsm/framework/functions';

@Injectable()
export class RuntimeInfoEffects {
  runtimeInfoLoadSuccess$ = createEffect(
    () =>
      // eslint-disable-next-line rxjs/no-cyclic-action
      this.actions$.pipe(
        ofType(RuntimeInfoLoadSuccessAction),
        tap(({runtimeInfo}) => {
          this.runtimeService.setUserId(runtimeInfo.id);
          this.removeOtherHistoryExceptMe();
          if (this.securityDataService) {
            this.securityDataService.loadSecurityData();
          }
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private runtimeService: RuntimeService,
    private actions$: Actions,
    @Optional()
    private securityDataService: SecurityDataService,
  ) {}

  private removeOtherHistoryExceptMe() {
    const historyKeys = Object.keys(localStorage).filter((x) =>
      x.includes('dtlHistoryLog'),
    );
    const otherUsersToRemove = historyKeys.filter(
      (x) => !x.includes(getUserId()),
    );
    otherUsersToRemove.forEach((x) => localStorage.removeItem(x));
  }
}
