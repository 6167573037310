<tsm-monaco-editor
  [ngModel]="stringifyVal(val)"
  (ngModelChange)="
    !jsonObject
      ? modelChanges(stringifyVal($event))
      : modelChanges(destringifyVal($event))
  "
  [defaultHeight]="widgetHeight ? widgetHeight : inline ? '1.5em' : null"
  [options]="editorOptions"
  [inline]="inline"
  [resizable]="resizable"
  [language]="language"
  [updateIfDirty]="updateIfDirty"
  (touchedChange)="onTouched(true)"
  (init)="onEditorInitialized($event)"
  data-cy="dtl-form-input-sql"
></tsm-monaco-editor>
