import {createAction, props} from '@ngrx/store';

export const LoadSecurityData = createAction('[Security] Load Security Data');

export const LoadSecurityDataSuccess = createAction(
  '[Security] Load Security Data Success',
  props<{data: any}>(),
);

export const LoadSecurityDataError = createAction(
  '[Security] Load Security Data Error',
  props<{error: any; data: any}>(),
);
