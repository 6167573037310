import {Observable} from 'rxjs';
import {groupBy, mergeMap, concatMap} from 'rxjs/operators';

export function concatBy<TSource, TSecond>(
  keySelector: (input: TSource) => any,
  second: (input: TSource) => Observable<TSecond>,
) {
  return function efficiencyAtItsFinest(source: Observable<TSource>) {
    return Observable.create((subscriber) => {
      const subscription = source
        .pipe(
          groupBy(keySelector),
          mergeMap((y) => y.pipe(concatMap(second))),
        )
        .subscribe(
          (value) => {
            try {
              subscriber.next(value);
            } catch (err) {
              subscriber.error(err);
            }
          },
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        );
      return subscription;
    }) as Observable<TSecond>;
  };
}
