import {CommonModule} from '@angular/common';
import {DialogModule} from 'primeng/dialog';
import {TsmIconPickerContentComponent} from './tsm-icon-picker-content/tsm-icon-picker-content.component';
import {TsmIconPickerIconComponent} from './tsm-icon-picker-icon/tsm-icon-picker-icon.component';
import {TsmIconPickerImageComponent} from './tsm-icon-picker-image/tsm-icon-picker-image.component';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {DividerModule} from 'primeng/divider';
import {TooltipModule} from 'primeng/tooltip';
import {AutoCompleteModule} from '@tsm/framework/override-primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@tsm/framework/translate';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {NgModule} from '@angular/core';
import {TsmIconPickerComponent} from './tsm-icon-picker/tsm-icon-picker.component';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    FrameworkGridModule,
    DividerModule,
    TooltipModule,
    AutoCompleteModule,
    DropdownModule,
    FormsModule,
    TranslateModule,
    InputSwitchModule,
    ButtonModule,
    RippleModule,
  ],
  declarations: [
    TsmIconPickerComponent,
    TsmIconPickerContentComponent,
    TsmIconPickerIconComponent,
    TsmIconPickerImageComponent,
  ],
  exports: [
    TsmIconPickerComponent,
    TsmIconPickerContentComponent,
    TsmIconPickerIconComponent,
    TsmIconPickerImageComponent,
  ],
})
export class IconPickerModule {}
