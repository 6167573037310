import {InjectionToken} from '@angular/core';
import {
  LayoutedComponent,
  LayoutIdDirective,
} from '@tsm/framework/root/layout-id';

export interface FluentFormsInputOptions<T = any> {
  layoutIdDirective: LayoutIdDirective;

  setInput(key: string, value: any);
}

export const FLUENT_FORMS_INPUT_COMPONENT: InjectionToken<FluentFormsInputOptions> =
  new InjectionToken<FluentFormsInputOptions>('Fluent forms input component');
