import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Config, ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {UserSettings} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {}

  getUserSettings(): Observable<Envelope<UserSettings>> {
    const url = this.config.value.apiUrls.userManagement + '/user-settings';
    return this.apiService.get<any, UserSettings>(url, (rawResponse) => ({
      ...rawResponse,
      userParameters: rawResponse.userParameters.map((x) => {
        const {user, ...userParam} = x;
        return {...userParam, userId: user.id};
      }),
    }));
  }
}
