export const en = {
  sharedPlugins: {
    headerDescription: 'Header docs',
    pipes: {
      number: 'Number format',
      numberDescription: 'Transforms to number',
      currency: 'Currency',
      currencyDescription: 'Displaying currency',
      formatPhone: 'Format Phone',
      formatPhoneDescription: 'Format Phone',
      booleanIcon: 'Boolean Icon',
      booleanIconDescription:
        'Generates an HTML icon representation based on a boolean or array value. It applies specified ' +
        'CSS classes for true, false, or null values. The pipe supports custom class configurations ' +
        'and ensures appropriate icon rendering for single values or arrays.',
      booleanTranslate: 'Boolean Translate',
      booleanTranslateDescription:
        'Transforms boolean or array values into translated strings. Returns "Yes" or "No" based on the ' +
        'value and applies translation using the TranslocoService. If the "nullable" parameter is true,  ' +
        'null values are treated as empty values.',
      clipboard: 'Clipboard',
      clipboardDescription:
        'Generates a sanitized HTML string containing an icon for copying values to the clipboard. The icon ' +
        'is styled and includes an "onclick" event that triggers a JavaScript function to copy the provided ' +
        'value to the clipboard.',
      colorPipe: 'Color Pipe',
      colorPipeDescription:
        'Transforms a color value into an HTML string that displays a small square div element styled ' +
        'with the specified background color. If the color value is null or empty, it returns an empty string.',
      constructCharsForNew: 'Construct Chars For New',
      constructCharsForNewDescription:
        'Transforms an entity object by extracting its "chars" property and embedding it into a new object. ' +
        'The resulting object includes the original "chars" property as well as a nested "chars" field. ' +
        'If the entity or its "chars" property is null, it defaults to an empty object.',
      dataTagsArray: 'Data Tags Array',
      dataTagsArrayDescription:
        'Transforms an array of values into a string of HTML elements. ' +
        'Each value in the array is wrapped in a span element with the "tsm-data-tag" class. ' +
        'The resulting HTML elements are concatenated with line breaks between them.',
      dateFormatPipe: 'Date Format Pipe',
      dateFormatPipeDescription:
        'Formats a given date value into a string based on the specified format type. ' +
        'The supported formats include: "dateTime" for "dd.MM.yyyy HH:mm", "dateTimeS" for "dd.MM.yyyy HH:mm:ss", ' +
        '"dateTimeMs" for "dd.MM.yyyy HH:mm:ss.SSS", "date" for "dd.MM.yyyy", and "time" for "HH:mm". ' +
        'If no format type is provided, or the value is null, the original value is returned without changes.',
      datesDiff: 'Dates Diff',
      datesDiffDescription:
        'Calculates the difference between a given date and the current date in a specified time unit. ' +
        'Supports time units such as "years", "months", "days", "hours", "minutes", and "seconds". ' +
        'Additional options include: returning only positive values, rounding down (floor), or rounding up (ceil). ' +
        'If the date is null or invalid value, an empty string is returned.',
      decimalNumberFormat: 'Decimal Number Format',
      decimalNumberFormatDescription:
        'Formats a numeric value into a locale-specific string representation. ' +
        'The pipe converts the input number into a string format, ensuring appropriate ' +
        'decimal and thousands separators for better readability. If the input is empty, ' +
        'an empty string is returned.',
      description: 'Description',
      descriptionDescription:
        'It passes the input data unchanged, preserving HTML formatting, which is acceptable on the GUI. ' +
        'However, on the backend, the converter strips out HTML tags to avoid potential problems.',
      domPurify: 'Dom Purify',
      domPurifyDescription:
        'Ensures HTML string safety by sanitizing its content using DOMPurify with a custom configuration. ' +
        'Filters attributes like "style" to allow only a predefined set of CSS properties such as color, ' +
        "font-size, and text-align. After sanitization, the transformed HTML is bypassed for Angular's " +
        'security checks, ensuring safe rendering of the content in the browser.',
      emptyOrNotEmptyTranslate: 'Empty Or Not Empty Translate',
      emptyOrNotEmptyTranslateDescription:
        'Translates array or value into "empty" or "not empty" based on its content, using localization. ' +
        'If the value is an empty array or does not contain any truthy elements, it translates to "empty". ' +
        'If the array contains at least one truthy element, it translates to "not empty". Handles nullable ' +
        'values by returning an empty string if specified as nullable and the value is null.',
      enumToValue: 'Enum To Value',
      enumToValueDescription:
        'Converts an enumerated value to a translated string representation. If the enumerated value exists ' +
        'in the provided prefix mapping, it retrieves and translates the corresponding label. Otherwise, it ' +
        'appends the enumerated value to the prefix and translates the result. If the input is null or empty, ' +
        'it returns an empty string.',
      externalLink: 'External Link',
      externalLinkDescription:
        'Transforms a URL string into an HTML anchor element with the "rel" attribute set to "noopener noreferrer". ' +
        'If the input value is valid, it returns a clickable link. Otherwise, it returns an empty string.',
      filter: 'Filter',
      filterDescription:
        'Filters an array of objects based on a specified field and value. ' +
        'Returns a new array containing only objects where the specified field matches the given value.',
      find: 'Find',
      findDescription:
        'Finds the first object in an array where the specified field matches the given value. ' +
        'Uses the field path to retrieve the value within each object and returns the matching object, ' +
        'or undefined if no match is found.',
      flattenExceptKeys: 'Flatten Except Keys',
      flattenExceptKeysDescription:
        'Flattens the properties of an object into an array, excluding the specified keys. ' +
        'Filters out the provided keys from the object, then maps the remaining properties ' +
        'to an array of their values.',
      iconImg: 'Icon Img',
      iconImgDescription:
        'Generates an HTML representation for an icon or image based on the provided value and row data. ' +
        'If the "icon" field is found in the row data, it determines whether to render an image or ' +
        'an icon class. Images with paths starting with "assets" are prefixed with the base URL. ' +
        'Supports both absolute and relative URLs for images.',
      join: 'Join',
      joinDescription:
        'Concatenates an array of strings into a single string, using the specified delimiter to ' +
        'separate each element. If the array is empty or undefined, it returns an empty string.',
      map: 'Map',
      mapDescription:
        'Transforms an array of objects into an array containing the values of a specified attribute ' +
        'from each object. If the array is empty or undefined, it returns an empty array.',
      moduleIcon: 'Module Icon',
      moduleIconDescription:
        'Converts module identifiers into corresponding icon elements with translated tooltips. ' +
        'For example, "ticket" is transformed into a ticketing icon, and "ordering" into an ordering icon. ' +
        'If the module identifier does not match any predefined icons, it returns the original value. ' +
        'If the input value is null or undefined, it returns an empty string.',
      numberToFixed: 'To Fixed',
      numberToFixedDescription:
        'Formats a number to a fixed number of decimal places with a default of 2 digits. ' +
        'If the input is null or undefined, it returns an empty string. If the input is a string, ' +
        'it converts it to a number before formatting. The decimal point is replaced with a comma ' +
        'for localization.',
      objectPathGet: 'Object Path Get',
      objectPathGetDescription:
        'Retrieves the value at a specified path from an object using the ObjectPath library. ' +
        'The path can be provided as a string or an array of strings. If the path does not exist, ' +
        'it returns undefined.',
      removeHtmlElements: 'Remove Html Elements',
      removeHtmlElementsDescription:
        'Removes HTML tags from a given string. If a length is specified, it truncates the ' +
        'text to the specified length and appends a custom suffix (default is "..."). If no length is ' +
        'provided, it returns the full text without HTML tags.',
      safeEval: 'Safe Eval',
      safeEvalDescription:
        'Evaluates JEXL expressions against provided data, enabling dynamic computations based on the ' +
        'specified expression. Supports transforming object keys with dots into double underscores to ' +
        'ensure compatibility. Returns the evaluated result or the original value in case of an error. ' +
        'Logs an error if the expression is not provided or parsing fails.',
      safeHtml: 'Safe Html',
      safeHtmlDescription:
        'Sanitizes HTML content to ensure it is safe for use in Angular templates, removing potentially ' +
        "dangerous elements or attributes. Uses Angular's DomSanitizer to process the input and returns " +
        'a sanitized version of the provided HTML string.',
      safe: 'Safe',
      safeDescription:
        'Processes input strings based on the specified type to ensure safe usage in Angular applications. ' +
        'Sanitizes or trusts content based on the given context, such as HTML, style, script, URL, or ' +
        'resource URL. Allows bypassing security for pre-sanitized content when explicitly marked as trusted.',
      sanitizeNull: 'Sanitize Null',
      sanitizeNullDescription:
        'Replaces null or undefined values with an empty string, ensuring safe and consistent output ' +
        'for use in Angular applications. Leaves non-null and defined values unchanged.',
      shallowMerge: 'Shallow Merge',
      shallowMergeDescription:
        'Merges two objects into a single object using shallow merging, where properties from the second ' +
        'object overwrite those in the first object if they have the same keys. Useful for combining or ' +
        'updating data structures with minimal complexity.',
      translatePipe: 'Translate Pipe',
      translatePipeDescription:
        'Handles real-time translation of a given localization key by observing language changes. ' +
        'Automatically updates the translation whenever the language is switched, ensuring dynamic ' +
        'and context-sensitive translations.',
      trimTextPipe: 'Trim Text Pipe',
      trimTextPipeDescription:
        'Truncates a given string to a specified character limit, appending "..." to indicate, that ' +
        'the text has been shortened. If the input is not a string, it returns the original value ' +
        'unchanged.',
    },
  },
};
