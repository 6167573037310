import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {ListingContainerState} from '../reducers';
import {valueConverterConfigAdapter} from '../reducers/value-converter-config.reducer';

const getValueConverterConfigState =
  createFeatureSelector<ListingContainerState>('listing');
const pathToEntities = createSelector(
  getValueConverterConfigState,
  (x) => x.valueConverterConfig,
);
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

const {selectAll, selectEntities} =
  valueConverterConfigAdapter.getSelectors(pathToEntities);

export const selectValueConverterConfigByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectValueConverterConfigById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectValueConverterConfigByCode = (code: string) =>
  createSelector(selectAll, (entities) =>
    entities.find((x) => x.data?.code === code),
  );

export const selectAllValueConverterConfig = createSelector(
  selectAll,
  (entities) =>
    entities.map((x) => x.data).sort((a, b) => a.code.localeCompare(b.code)),
);

export const selectValueConverterConfigsByCodes = (codes: string[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((x) => codes.includes(x.data?.code)).map((x) => x.data),
  );
