import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {UserType} from '../model';
import {
  DeleteUserTypeSuccess,
  DiffUserType,
  DiffUserTypeError,
  DiffUserTypeSuccess,
  LoadUserTypeByCode,
  LoadUserTypeByCodeError,
  LoadUserTypeByCodeSuccess,
  LoadUserTypeById,
  LoadUserTypeByIdError,
  LoadUserTypeByIdSuccess,
  LoadUserTypeSuccess,
  UpsertUserType,
  UpsertUserTypeSuccess,
} from '../actions';
import {IdentifiedPack} from '@tsm/framework/root';

export type UserTypeState = EntityState<IdentifiedPack<UserType>>;

export const userTypeAdapter: EntityAdapter<IdentifiedPack<UserType>> =
  createEntityAdapter<IdentifiedPack<UserType>>({});

export const userTypeState: UserTypeState = userTypeAdapter.getInitialState({});

export const userTypeReducer = createReducer(
  userTypeState,

  on(LoadUserTypeSuccess, (state, {userTypes}) => ({
    ...userTypeAdapter.addMany(
      userTypes.map((data) => ({
        id: data.id,
        data: data,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
  })),

  on(LoadUserTypeById, (state, {id}) =>
    userTypeAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserTypeByIdSuccess, (state, {userType}) =>
    userTypeAdapter.upsertOne(
      {
        id: userType.id,
        data: userType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserTypeByIdError, (state, {id, error}) =>
    userTypeAdapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(UpsertUserType, (state, {userType}) =>
    userTypeAdapter.updateOne(
      {
        id: userType.id,
        changes: {
          ...state.entities[userType.id],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertUserTypeSuccess, (state, {userType}) =>
    userTypeAdapter.upsertOne(
      {
        id: userType.id,
        data: userType,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteUserTypeSuccess, (state, userType) =>
    userTypeAdapter.removeOne(userType.id, state),
  ),
  on(LoadUserTypeByCode, (state, {code}) =>
    userTypeAdapter.upsertOne(
      {
        id: code,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserTypeByCodeSuccess, (state, {userType}) => {
    const newState = userTypeAdapter.removeOne(userType.code, state);
    return userTypeAdapter.upsertOne(
      {
        id: userType.id,
        data: userType,
        loading: false,
        error: null,
      },
      newState,
    );
  }),

  on(LoadUserTypeByCodeError, (state, {code, error}) =>
    userTypeAdapter.upsertOne(
      {
        id: code,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DiffUserType, (state, {diffEntities}) =>
    userTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            ...state.entities[diffEntity.id],
            loading: true,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffUserTypeSuccess, (state, {userTypes}) =>
    userTypeAdapter.upsertMany(
      userTypes.map((userType) => {
        return {
          id: userType.id,
          changes: {
            id: userType.id,
            data: userType,
            error: null,
            loading: false,
          },
        };
      }),
      state,
    ),
  ),

  on(DiffUserTypeError, (state, {diffEntities, error}) =>
    userTypeAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          changes: {
            loading: false,
            error: error,
          },
        };
      }),
      state,
    ),
  ),
);
