import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {backupSetImportAdapter, ConfigFormState} from '../reducers';

const getBackupSetImportState =
  createFeatureSelector<ConfigFormState>('configForm');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');
const pathToEntities = createSelector(
  getBackupSetImportState,
  (x) => x.backupSetImport,
);

const {selectAll, selectEntities} =
  backupSetImportAdapter.getSelectors(pathToEntities);

export const allBackupSetImports = selectAll;
export const backupSetImportEntities = selectEntities;

export const selectBackupSetImportById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]?.data);
export const selectBackupSetImportByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectBackupSetImportProfileDiffTable = (
  backupSetImportId: string,
  profileCode: string,
) =>
  createSelector(
    selectEntities,
    (entities) => entities[backupSetImportId]?.data?.diffTables[profileCode],
  );

export const selectBackupSetImportProfileDiffTables = (
  backupSetImportId: string,
) =>
  createSelector(
    selectEntities,
    (entities) => entities[backupSetImportId]?.data?.diffTables,
  );
