import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, map} from 'rxjs/operators';
import {
  LoadMnemonicSequence,
  LoadMnemonicSequenceError,
  LoadMnemonicSequenceSuccess,
  UpsertMnemonicSequence,
  UpsertMnemonicSequenceError,
  UpsertMnemonicSequenceSuccess,
} from '../actions';
import {CommonApiService} from '../services';
import {MnemonicSequence} from '../models';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';

@Injectable()
export class MnemonicSequenceEffects {
  private readonly MNEMONIC_API_PATH = 'mnemonic';

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMnemonicSequence),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.MNEMONIC_API_PATH + '/sequence-list')
          .pipe(
            map((env) =>
              env.success
                ? LoadMnemonicSequenceSuccess({entities: env.data})
                : LoadMnemonicSequenceError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMnemonicSequence),
      concatMap(({mnemonicSequence, listingId}) =>
        this.commonApiService
          .upsertEntity(
            this.MNEMONIC_API_PATH + '/sequence',
            mnemonicSequence,
            null,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.configFormService.effects.sequenceSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertMnemonicSequenceSuccess({
                  mnemonicSequence: env.data,
                  listingId: listingId,
                });
              } else {
                return UpsertMnemonicSequenceError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      MnemonicSequence,
      MnemonicSequence
    >,
    private toastService: ToastService,
  ) {}
}
