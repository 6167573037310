import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {IdentifiedPack} from '@tsm/framework/root';
import {
  LoadBatchUserById,
  LoadBatchUserByIdError,
  LoadBatchUserByIds,
  LoadBatchUserByIdsError,
  LoadBatchUserByIdsSuccess,
  LoadBatchUserByIdSuccess,
  LoadUserByCode,
  LoadUserByCodeSuccess,
  LoadUserById,
  LoadUserByIdError,
  LoadUserByIds,
  LoadUserByIdsError,
  LoadUserByIdsSuccess,
  LoadUserByIdSuccess,
  LoadUsers,
  LoadUsersError,
  LoadUsersSuccess,
  PatchUser,
  PatchUserError,
  PatchUserSuccess,
  SaveUserNotificationConfigError,
  SaveUserNotificationConfigSuccess,
  UpsertUser,
  UpsertUserSuccess,
} from '../actions';
import {User} from '../model';
import {addDays} from 'date-fns';

export interface UserState extends EntityState<IdentifiedPack<User>> {
  error?: any;
  loading?: boolean;
}

export const adapter: EntityAdapter<IdentifiedPack<User>> = createEntityAdapter<
  IdentifiedPack<User>
>({});

export const initialState: UserState = adapter.getInitialState({
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(LoadUserById, LoadBatchUserById, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      {...state, error: null, loading: true},
    ),
  ),

  on(LoadUserByCode, (state, {code}) => {
    const id = Object.keys(state.entities).find(
      (id) => state.entities[id]?.data?.code === code,
    );
    return id != null
      ? adapter.upsertOne(
          {
            ...state.entities[id],
            id: id,
            loading: true,
            error: null,
          },
          {...state, error: null, loading: true},
        )
      : state;
  }),

  on(
    LoadUsersSuccess,
    LoadUserByIdsSuccess,
    LoadBatchUserByIdsSuccess,
    LoadBatchUserByIdSuccess,
    (state, {entities}) => ({
      ...adapter.upsertMany(
        entities.map((userOnly) => ({
          id: userOnly.id,
          data: userOnly,
          loading: false,
          error: null,
          validUntil: addDays(new Date(), 1),
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(LoadUserByIds, LoadBatchUserByIds, (state, {ids}) => ({
    ...adapter.upsertMany(
      ids.map((id) => ({
        id: id,
        loading: true,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(LoadUserByIdSuccess, LoadUserByCodeSuccess, (state, {user}) =>
    adapter.upsertOne(
      {
        id: user.id,
        data: user,
        loading: false,
        error: null,
        validUntil: state.entities[user.id]
          ? state.entities[user.id].validUntil
          : null,
      },
      {...state, error: null, loading: false},
    ),
  ),

  on(LoadUserByIdError, (state, {id, error}) =>
    adapter.upsertOne(
      {
        id: id,
        data: state.entities[id].data,
        loading: false,
        error: error,
      },
      {...state, error: error, loading: false},
    ),
  ),

  on(
    LoadUserByIdsError,
    LoadBatchUserByIdError,
    LoadBatchUserByIdsError,
    (state, {ids, error}) => ({
      ...adapter.upsertMany(
        ids.map((id) => ({
          id: id,
          loading: false,
          error: error,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(LoadUsers, PatchUser, (state, {}) => ({...state, loading: true})),

  on(
    LoadUsersError,
    PatchUserError,
    SaveUserNotificationConfigError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertUser, (state, {user}) =>
    adapter.upsertOne(
      {
        ...state.entities[user.id],
        id: user.id,
        loading: true,
        error: null,
      },
      {...state, error: null, loading: true},
    ),
  ),

  on(UpsertUserSuccess, (state, {user}) =>
    adapter.upsertOne(
      {
        id: user.id,
        data: {
          ...user,
          whoInserted:
            user.whoInserted ?? state.entities[user.id]?.data?.whoInserted,
          whenInserted:
            user.whenInserted ?? state.entities[user.id]?.data?.whenInserted,
        },
        loading: false,
        error: null,
        validUntil: null,
      },
      {...state, error: null, loading: false},
    ),
  ),

  on(PatchUserSuccess, (state, {id, user}) =>
    adapter.upsertOne(
      {
        id: id,
        data: user,
        loading: false,
        error: null,
        validUntil: null,
      },
      {...state, error: null, loading: false},
    ),
  ),

  on(SaveUserNotificationConfigSuccess, (state, {id, config}) =>
    adapter.updateOne(
      {
        id: id,
        changes: {
          data: {
            ...state.entities[id].data,
            notificationConfig: config,
          },
        },
      },
      {...state, error: null, loading: false},
    ),
  ),
);
