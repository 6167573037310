import {Injectable} from '@angular/core';
import {SharedRequestValidUntil} from '@tsm/framework/root';
import {BehaviorSubject, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {translation} from '../i18n';
import {AccessRules} from '../models';
import {AccessRulesGuard} from '../guards';

@Docs({
  detail: 'accessRules(): IdentifiedPack<AccessRules>',
  description: {
    about: translation.accessRulesService.dataSources.accessRules,
    params: [],
    example: 'accessRules()',
  },
})
@Injectable()
export class AccessRulesDataSource implements DtlDataSource<AccessRules> {
  value: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private accessRulesGuardService: AccessRulesGuard) {}

  pushParams(params) {
    this.value.next(params);
  }

  @SharedRequestValidUntil(2)
  getRemoteData(): Observable<AccessRules> {
    return this.accessRulesGuardService.accessRules$;
  }

  create(): Observable<AccessRules> {
    return this.value
      .asObservable()
      .pipe(switchMap(() => this.getRemoteData()));
  }
}
