import {Plugin} from '@tsm/framework/plugin';
import {
  controlsOrderingDetailConfiguration,
  controlsOrderingNewConfiguration,
  controlsTicketDetailConfiguration,
  controlsTicketNewConfiguration,
} from './tsm-controls-configuration';

export const requirementsPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'requirements',
      loadChildren: () =>
        import('@tsm/selfcare/requirements/components').then(
          (x) => x.RequirementsComponentsModule,
        ),
    },
  ],
  widgets: [],
  filters: [],
  pipes: [],
  entityTypes: [],
  layoutControls: [
    controlsTicketNewConfiguration,
    controlsTicketDetailConfiguration,
    controlsOrderingNewConfiguration,
    controlsOrderingDetailConfiguration,
  ],
};
