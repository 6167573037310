import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import {provideLumberjack, LumberjackLevel} from '@ngworker/lumberjack';
import {provideLumberjackConsoleDriver} from '@ngworker/lumberjack/console-driver';
import * as Sentry from '@sentry/angular';
import {Router} from '@angular/router';

@NgModule({
  imports: [],
  exports: [],
  providers: [provideLumberjack(), provideLumberjackConsoleDriver()],
})
export class FrameworkLoggerModule {
  static forRoot(): ModuleWithProviders<FrameworkLoggerModule> {
    return {
      ngModule: FrameworkLoggerModule,
      providers: [
        provideLumberjack({
          levels: [
            LumberjackLevel.Debug,
            LumberjackLevel.Info,
            LumberjackLevel.Warning,
            LumberjackLevel.Error,
          ],
          format: ({level, message}) => `${level}: ${message}`,
        }),
        provideLumberjackConsoleDriver(),
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (traceService: Sentry.TraceService) => () => {
            // Initialization logic if needed
          },
          deps: [Sentry.TraceService],
          multi: true,
        },
      ],
    };
  }
}
