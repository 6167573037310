import {type DtlLayoutControlsConfiguration} from '@tsm/framework/plugin';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationCustom} from '@tsm/billing/plugins-i18n';

export const controlsBillingDocumentNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-billing-document-new',
    name: translationShared.shared.billingDocument.billingNew,
    description: '',
    useType: 'FORM_NEW',
    entityType: 'BillingDocument',
    tsmControls: {
      name: {
        name: translationShared.shared.title,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      invoiceDate: {
        name: translationCustom.billingPlugins.billingDocument.invoiceDate,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label: translationCustom.billingPlugins.billingDocument.invoiceDate,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      dueDate: {
        name: translationCustom.billingPlugins.billingDocument.dueDate,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label: translationCustom.billingPlugins.billingDocument.invoiceDate,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      dateOfRealization: {
        name: translationCustom.billingPlugins.billingDocument
          .dateOfRealization,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'date',
            label:
              translationCustom.billingPlugins.billingDocument
                .dateOfRealization,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-date',
        },
      },
      variableSymbol: {
        name: translationCustom.billingPlugins.billingDocument.variableSymbol,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translationCustom.billingPlugins.billingDocument.variableSymbol,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      customerId: {
        name: translationShared.shared.customer,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.customer,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-customer-lov',
        },
      },
      accountId: {
        name: translationShared.shared.acc,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.acc,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-account-lov',
        },
      },
      // invoiceText: {
      //   name: translationCustom.billingPlugins.billingDocument.invoiceText,
      //   previewComponent: {
      //     selector: 'tsm-form-field',
      //     inputs: {
      //       type: 'text',
      //       label: translationCustom.billingPlugins.billingDocument.invoiceText,
      //       labelPosition: 'top'
      //     }
      //   }
      // },
      // priceWithVat: {
      //   name: translationCustom.billingPlugins.billingDocument.priceWithVat,
      //   previewComponent: {
      //     selector: 'tsm-form-field',
      //     inputs: {
      //       type: 'text',
      //       label: translationCustom.billingPlugins.billingDocument.priceWithVat,
      //       labelPosition: 'top'
      //     }
      //   }
      // },
      invoiceNumber: {
        name: translationCustom.billingPlugins.billingDocument.invoiceNumber,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translationCustom.billingPlugins.billingDocument.invoiceNumber,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      billingCurrency: {
        name: translationCustom.billingPlugins.billingDocument.billingCurrency,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label:
              translationCustom.billingPlugins.billingDocument.billingCurrency,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-billing-currency-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      billingCycle: {
        name: translationCustom.billingPlugins.billingCycle.billingCycle,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationCustom.billingPlugins.billingCycle.billingCycle,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-billing-cycle-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      status: {
        name: translationShared.shared.state,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'previewDropdown',
            label: translationShared.shared.state,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-billing-document-status-lov',
          inputs: {
            selectProperty: 'all',
          },
        },
      },
      description: {
        name: translationShared.shared.description,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'tiptap',
            label: translationShared.shared.description,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-tiptap',
        },
      },
    },
  };

export const controlsBillingDocumentLineNewConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-billing-document-line-new',
    name: 'Billing document line new',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'BillingDocumentLine',
    tsmControls: {
      name: {
        name: translationShared.shared.title,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label: translationShared.shared.title,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-text',
        },
      },
      parentDocumentLineId: {
        name: translationCustom.billingPlugins.billingDocumentLine
          .parentDocumentLine,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translationCustom.billingPlugins.billingDocumentLine
                .parentDocumentLine,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-billing-document-line-lov',
          inputs: {
            selectProperty: 'id',
          },
        },
      },
      billingDocumentId: {
        name: translationCustom.billingPlugins.billingDocumentLine
          .billingDocument,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translationCustom.billingPlugins.billingDocumentLine
                .billingDocument,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-billing-document-lov',
        },
      },
      entityInstanceConfigurationId: {
        name: translationCustom.billingPlugins.billingDocumentLine
          .entityInstanceConfiguration,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'text',
            label:
              translationCustom.billingPlugins.billingDocumentLine
                .entityInstanceConfiguration,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'tsm-entity-instance-configuration-lov',
          inputs: {
            selectProperty: 'id',
          },
        },
      },
      vatRate: {
        name: translationCustom.billingPlugins.billingDocumentLine.vat,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label: translationCustom.billingPlugins.billingDocumentLine.vat,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-number',
        },
      },
      priceWithoutVat: {
        name: translationCustom.billingPlugins.billingDocumentLine
          .priceWithoutVat,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'number',
            label:
              translationCustom.billingPlugins.billingDocumentLine
                .priceWithoutVat,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-number',
        },
      },
      sortOrder: {
        name: translationCustom.billingPlugins.billingDocumentLine.sortOrder,
        previewComponent: {
          selector: 'tsm-form-field',
          inputs: {
            type: 'pint',
            label:
              translationCustom.billingPlugins.billingDocumentLine.sortOrder,
            labelPosition: 'top',
          },
        },
        defaultComponent: {
          selector: 'dtl-form-input-key-filter',
        },
      },
    },
  };

export const controlsBillingDocumentTypeDetailConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-billing-document-type-detail',
    name: 'Billing document type detail',
    description: '',
    useType: 'CONFIG',
    entityType: 'BillingDocumentType',
    tsmControls: {},
  };

export const controlsBillingDocumentLineTopbarConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-billing-document-line.topbar',
    name: 'Billing document line topbar',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'BillingDocumentLine',
    tsmControls: {},
  };

export const controlsBillingTopbarConfiguration: DtlLayoutControlsConfiguration =
  {
    code: 'tsm-billing.topbar',
    name: 'Billing topbar',
    description: '',
    useType: 'FORM_NEW',
    entityType: 'Billing',
    tsmControls: {},
  };
