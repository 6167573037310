import {BehaviorSubject, Observable} from 'rxjs';

export interface Docs {
  detail: string; // title
  deprecated?: boolean;
  description: {
    about: string;
    params?: {name: string; about: string}[];
    example?: string;
  };
}

export function Docs(docs: Docs) {
  return function (constructor: Function) {
    constructor.prototype.docs = docs;
  };
}

export interface DtlDataSource<T = any> {
  value: BehaviorSubject<any>;
  pushParams: (params) => void;
  create?: () => Observable<T>;
}

export interface DataSourceType<T = any> {
  name: string;
  initialValue?: any;
  dataSource: DtlDataSource<T>;
}
