import {Pipe, untracked} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectRegisterValuesByIds} from '../selectors';
import {LoadBatchRegisterValueByIds} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'registerValueArrayById',
  pure: true,
})
export class RegisterValueArrayByIdPipe extends AbstractCachedLoaderPipe {
  private _cache = new Map<string, any>();

  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(ids: string[], field = 'name') {
    this.destroy();
    this._id = ids;

    this._obs$ = this.store.pipe(
      select(selectRegisterValuesByIds(ids)),
      filter((items) => {
        return items != null;
      }),
      map((it) => it.map((it) => it.data)),
      map((it) => {
        if (!it || it.filter((x) => !!x).length == 0) {
          return ids;
        } else {
          return field !== 'all' ? it.map((it) => it[field]).join(',') : it;
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .select(selectRegisterValuesByIds(this._id))
      .subscribe((x) => {
        if ((!x || x?.length == 0) && !this._cache.has(this._id)) {
          untracked(() =>
            this.store.dispatch(LoadBatchRegisterValueByIds({ids: this._id})),
          );
          // resi problem cyklickym volam za predpokladu, ze backend nevrati data (vyprsela platnost, smazana hodnota, atd.)
          // muze to zpusobovat mozna problem s refreshem u spravnych hodnot ve storu (spravne by se to melo cele prepsat ve storu)
          this._cache.set(this._id, x);
        }
      });
  }
}
