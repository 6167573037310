import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DoCheck,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {AbstractControlValueAccessor} from '@tsm/framework/abstract-control-value-accessor';
import {FormFieldInput} from '@tsm/framework/forms';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DynamicComponentComponent} from '../dynamic-component/dynamic-component.component';

@Component({
  selector: 'dtl-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldInput,
      useExisting: DynamicFormFieldComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => DynamicFormFieldComponent),
      multi: true,
    },
  ],
})
export class DynamicFormFieldComponent
  extends AbstractControlValueAccessor
  implements OnInit, DoCheck
{
  @ViewChild(DynamicComponentComponent, {static: true})
  dynamicComponent: DynamicComponentComponent;

  component: ComponentRef<any>;

  @Input() selector = '';
  private _inputs: {[key: string]: any};
  @Input() set inputs(inputs: {[key: string]: any}) {
    this._inputs = inputs;
  }

  get inputs() {
    return {
      ...this._inputs,
      readonly: this.readonly,
      dataKey: this.dataKey,
    };
  }

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    this.dynamicComponent.created.subscribe((x) => {
      this.component = x;
      this.component.instance.writeValue(this.val);
    });
  }

  writeValue(value) {
    super.writeValue(value);
    if (this.component && this.component.instance) {
      this.component.instance.writeValue(value);
    }
  }

  ngDoCheck(): void {
    if (this.component && this.component.instance.focused !== this.focused) {
      this.onFocus(this.component.instance.focused);
    }
  }

  changeValue(event) {
    this.val = event;
  }
}
