import {Action, combineReducers} from '@ngrx/store';
import * as fromScript from './script.reducer';

export interface ScriptingState {
  script: fromScript.ScriptState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: ScriptingState | undefined, action: Action) {
  return combineReducers({
    script: fromScript.reducer,
  })(state, action);
}
