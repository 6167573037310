import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {DateHelper} from '@tsm/shared';
import {DeliveryItemType, NotificationStatus} from '../models';
import {notificationMyAdapter} from '../reducers/notification-my.reducer';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getNotificationMyState = createSelector(
  getDmsState,
  (x) => x.notificationMy,
);

const {selectAll, selectEntities, selectIds, selectTotal} =
  notificationMyAdapter.getSelectors(getNotificationMyState);

export const selectNotificationMyByUserId = (
  userId: string,
  status: NotificationStatus,
  deliveryItemTypes: DeliveryItemType[],
) =>
  createSelector(selectEntities, (entities) => {
    const result = entities[userId]?.notifications
      ?.filter((item) => {
        if (deliveryItemTypes.includes(item.deliveryType)) {
          if (status === NotificationStatus.ALL) {
            // neprecteny + precteny
            return item.notificationToDeleted == null;
          } else if (status === NotificationStatus.PLAN) {
            // neprecteny
            return (
              item.notificationToDisplayed == null &&
              item.notificationToDeleted == null
            );
          } else if (status === NotificationStatus.SENT) {
            // precteny
            return (
              item.notificationToDisplayed != null &&
              item.notificationToDeleted == null
            );
          } else if (status === NotificationStatus.DELETED) {
            // smazane
            return item.notificationToDeleted != null;
          } else {
            return false;
          }
        }
        return false;
      })
      ?.sort((i, j) => DateHelper.compareDates(i.created, j.created));
    return result;
  });

export const selectNotificationMyByUserIdDisplayed = (
  userId: string,
  deliveryItemType: DeliveryItemType,
) =>
  createSelector(selectEntities, (entities) =>
    entities[userId]?.notifications
      ?.filter(
        (item) =>
          item.notificationToDisplayed == null &&
          item.notificationToDeleted == null &&
          item.deliveryType === deliveryItemType,
      )
      .sort((i, j) => DateHelper.compareDates(i.created, j.created)),
  );
