import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  ParentWidgetAccessorComponent,
  useParentWidgetProvidersFor,
} from '@tsm/framework/parent-widget';
import { NgStyle, NgClass } from '@angular/common';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'dtl-form-input-integer',
    templateUrl: './form-input-integer.component.html',
    styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
    providers: [
        {
            provide: FormFieldInput,
            useExisting: FormInputIntegerComponent,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => FormInputIntegerComponent),
            multi: true,
        },
        ...useParentWidgetProvidersFor(FormInputIntegerComponent),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        NgStyle,
        KeyFilterModule,
        NgClass,
        TranslocoPipe,
    ],
})
export class FormInputIntegerComponent
  extends ParentWidgetAccessorComponent
  implements AfterViewInit
{
  @ViewChild('inputElement', {static: true})
  inputElement: ElementRef;

  @Input() minlength = 0;

  @Input() maxlength = 10;

  @Input() placeholder: string;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.addEventListener('focus', () => {
      this.onFocus(true);
    });

    this.inputElement.nativeElement.addEventListener('blur', () => {
      this.onFocus(false);
    });
  }
}
