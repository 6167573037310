import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';
import {selectUserById} from '../selectors';
import {LoadBatchUserById} from '../actions';
import {of} from 'rxjs';
import {isUuid} from '@tsm/framework/functions';

@Pipe({
  name: 'user',
  pure: true,
})
export class UserPipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store) {
    super(store);
  }

  get obser(): any {
    return this._obs$;
  }

  setObservable(id: string, field = 'name') {
    this.destroy();
    this._id = id;

    if (id != null && !isUuid(id)) {
      this._obs$ = of(id);
    } else {
      this._obs$ = this.store.pipe(
        select(selectUserById(id)),
        filter((it) => it != null && it.loading == false),
        map((it) => {
          if (!it.data || it.error != null) {
            return id;
          } else {
            return field !== 'all' ? it.data[field] : it.data;
          }
        }),
      );
    }
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectUserById(this._id)))
      .subscribe((x) => {
        if (!x && isUuid(this._id)) {
          untracked(() =>
            this.store.dispatch(LoadBatchUserById({id: this._id})),
          );
        }
      });
  }
}
