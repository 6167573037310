import {SelectItem} from 'primeng/api';

export enum SyncMethodType {
  MANUAL = 'MANUAL',
  NONE = 'NONE',
  COMPARE = 'COMPARE',
  SYSTEM = 'SYSTEM',
}

export function getAllSyncMethods(): SelectItem[] {
  const items: SelectItem[] = [];
  Object.keys(SyncMethodType).forEach((val) =>
    items.push({label: 'shared.syncMethodType.' + val, value: val}),
  );
  return items;
}
