import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {Notification} from '../models';
import {NotificationService} from '../services';
import {ApiService} from '@tsm/framework/http';
import {Config, ConfigService} from '@tsm/framework/config';

@Pipe({
  name: 'notificationCustomerById',
  pure: true,
})
export class NotificationCustomerByIdPipe implements PipeTransform {
  public requiresRowData = true;

  constructor(
    private notificationService: NotificationService,
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {}

  transform(notification: Notification): Observable<string> {
    if (notification?.id == null) {
      return of('');
    }
    if (notification.ownerType === 'Customer') {
      return this.apiService
        .get<
          any,
          any
        >(`${this.config.value.apiUrls.tsmCustomer}/customers/${notification.ownerId}`)
        .pipe(
          take(1),
          map((x) => {
            if (x.success) {
              return x.data.key;
            }
            return '';
          }),
        );
    }
    if (notification.ownerType === 'Account') {
      return this.apiService
        .get<
          any,
          any
        >(`${this.config.value.apiUrls.tsmCustomer}/accounts/${notification.ownerId}`)
        .pipe(
          take(1),
          map((x) => {
            if (x.success) {
              return x.data?.customer?.key;
            }
            return '';
          }),
        );
    }
    if (notification.ownerType === 'OCS_INVOICE') {
      return of(notification?.params?.customer?.key);
    }
    if (
      notification.ownerType === 'Ticket' ||
      notification.ownerType === 'Order'
    ) {
      if (notification?.notificationTemplate?.code == 'Comment.Mention') {
        return of('');
      }
      return of(notification?.params?.customer?.key).pipe(
        switchMap((x) => {
          if (x) {
            return of(x);
          } else {
            return this.notificationService
              .getCustomerByNotificationId(notification?.id)
              .pipe(
                take(1),
                map((x) => {
                  if (x.success) {
                    return x.data.map((x) => x.key).join(', ');
                  }
                  return '';
                }),
              );
          }
        }),
      );
    }
    return of('');
  }
}
