import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteTsmModule,
  DeleteTsmModuleError,
  DeleteTsmModuleSuccess,
  DiffTsmModule,
  DiffTsmModuleError,
  DiffTsmModuleSuccess,
  LoadTsmModule,
  LoadTsmModuleByCode,
  LoadTsmModuleByCodeError,
  LoadTsmModuleByCodeSuccess,
  LoadTsmModuleById,
  LoadTsmModuleByIdError,
  LoadTsmModuleByIdSuccess,
  LoadTsmModuleError,
  LoadTsmModulesByType,
  LoadTsmModulesByTypeError,
  LoadTsmModulesByTypeSuccess,
  LoadTsmModuleSuccess,
  UpsertTsmModule,
  UpsertTsmModuleError,
  UpsertTsmModuleSuccess,
} from '../actions';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {CommonApiService} from '../services';
import {TsmModule} from '../models';
import {RefreshDataAndClearSelected} from '@tsm/listing-lib/service';
import {Router} from '@angular/router';
import {withLatestCached} from '@tsm/framework/root';
import {selectTsmModuleByCode} from '../selectors';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {translation} from '../i18n';
import {translation as translationShared} from '@tsm/shared-i18n';

@Injectable()
export class TsmModuleEffects {
  private readonly CONFIG_TYPE_API_PATH = 'tsm-module';

  translation = translation;
  translationShared = translationShared;

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTsmModule),
      exhaustMap(() =>
        this.commonApiService
          .getAll(this.CONFIG_TYPE_API_PATH)
          .pipe(
            map((env) =>
              env.success
                ? LoadTsmModuleSuccess({tsmModules: env.data})
                : LoadTsmModuleError(env.error),
            ),
          ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTsmModuleById),
      concatMap(({id}) =>
        this.commonApiService
          .getEntitySharedRequest(this.CONFIG_TYPE_API_PATH, id)
          .pipe(
            map((env) =>
              env.success
                ? LoadTsmModuleByIdSuccess({tsmModule: env.data})
                : LoadTsmModuleByIdError({id, error: env.error}),
            ),
          ),
      ),
    ),
  );

  loadByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTsmModuleByCode),
      withLatestCached(({code}) =>
        this.store.select(selectTsmModuleByCode(code)),
      ),
      concatMap(([{code}, status]) =>
        status && status.data
          ? of(LoadTsmModuleByCodeSuccess({tsmModule: status.data}))
          : this.commonApiService
              .getEntityByCode(this.CONFIG_TYPE_API_PATH, code)
              .pipe(
                map((env) =>
                  env.success
                    ? LoadTsmModuleByCodeSuccess({tsmModule: env.data})
                    : LoadTsmModuleByCodeError({code: code, error: env.error}),
                ),
              ),
      ),
    ),
  );

  loadByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTsmModulesByType),
      exhaustMap(({moduleType}) =>
        this.commonApiService
          .getTsmModuleByType(moduleType)
          .pipe(
            map((env) =>
              env.success
                ? LoadTsmModulesByTypeSuccess({tsmModules: env.data})
                : LoadTsmModulesByTypeError(env.error),
            ),
          ),
      ),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertTsmModule),
      concatMap(({tsmModule, listingId, redirect}) =>
        this.commonApiService
          .upsertEntity(this.CONFIG_TYPE_API_PATH, tsmModule, tsmModule.id)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translation.tsmModuleService.effects.tsmModuleSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertTsmModuleSuccess({
                  tsmModule: env.data,
                  listingId: listingId,
                  redirect: redirect,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translation.tsmModuleService.effects.tsmModuleSaveError,
                );
                return UpsertTsmModuleError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTsmModule),
      concatMap(({id, listingId, shouldRedirectToListing}) =>
        this.commonApiService.deleteEntity(this.CONFIG_TYPE_API_PATH, id).pipe(
          map((env) => {
            if (env.success) {
              this.toastService.showToast(
                translation.tsmModuleService.effects.tsmModuleDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              if (shouldRedirectToListing) {
                this.router.navigate(['/config/tsmmodule']);
              }
              return DeleteTsmModuleSuccess({id: id, listingId: listingId});
            } else {
              this.toastService.showError(
                env.error,
                translation.tsmModuleService.effects.tsmModuleDeleteError,
              );
              return DeleteTsmModuleError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  diff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiffTsmModule),
      concatMap(({diffEntities, listingId, compareField}) =>
        this.commonApiService
          .diffEntities('v1/modules', diffEntities, compareField)
          .pipe(
            map((env) => {
              if (env.success) {
                this.toastService.showToast(
                  translationShared.shared.restoreSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return DiffTsmModuleSuccess({
                  tsmModules: env.data,
                  listingId: listingId,
                });
              } else {
                this.toastService.showError(
                  env.error,
                  translationShared.shared.restoreFailed,
                );
                return DiffTsmModuleError({
                  diffEntities: diffEntities,
                  error: env.error,
                });
              }
            }),
          ),
      ),
    ),
  );

  refreshDataAfterUpsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertTsmModuleSuccess),
      tap(({tsmModule, redirect}) => {
        if (redirect === true) {
          this.router.navigate(['/config/tsmmodule', tsmModule.id]);
        }
      }),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  refreshDataAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTsmModuleSuccess, DiffTsmModuleSuccess),
      map(({listingId}) => RefreshDataAndClearSelected({id: listingId})),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private commonApiService: CommonApiService<TsmModule, TsmModule>,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
