import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberToFixed',
  pure: true,
})
export class NumberToFixedPipe implements PipeTransform {
  transform(number: number, fractionDigits = 2): string {
    if (number == null) {
      return '';
    }
    if (typeof number === 'string') {
      return Number(number).toFixed(fractionDigits).replace('.', ',');
    }
    return number.toFixed(fractionDigits).replace('.', ',');
  }
}
