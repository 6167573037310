export const cs = {
  configFormService: {
    effects: {
      entitySpecificationSaveSuccess: 'Uložení specifikace proběhlo úspěšně',
      entitySpecificationSaveFailure:
        'Během ukládání specifikace došlo k chybě',
      entitySpecificationDeleteSuccess: 'Smazání specifikace proběhlo úspěšně',
      entitySpecificationDeleteFailure:
        'Během mazání specifikace došlo k chybě',
      diffEntitySpecificationSaveSuccess:
        'Ukládání rozdílů Specifikací entit skončilo úspěšně.',
      diffEntitySpecificationSaveFailure:
        'Při ukládání rozdílů Specifikací entit došlo k chybě.',
      formSaveSuccess: 'Uložení formuláře proběhlo úspěšně',
      formSaveFailure: 'Během ukládání formuláře došlo k chybě',
      registerSaveSuccess: 'Uložení číselníku proběhlo úspěšně',
      registerSaveFailure: 'Během ukládání číselníku došlo k chybě',
      registerValueSaveSuccess: 'Uložení hodnoty registru proběhlo úspěšně',
      registerValueSaveFailure: 'Během ukládání hodnoty registru došlo k chybě',
      registerValueDeleteSuccess: 'Smazání hodnoty registru proběhlo úspěšně',
      registerValueDeleteFailure: 'Během mazání hodnoty registru došlo k chybě',
      sequenceSaveSuccess: 'Uložení sekvence proběhlo úspěšně',
      formDeleteSuccess: 'Mazání formuláře proběhlo úspěšně',
      formDeleteError:
        'Během mazání formuláře došlo k chybě, zkontrolujte, zda formulář není použit u charakteristiky nebo uživatelského číselníku.',
      entityTypeDeleteSuccess: 'Mazání typu entity proběhlo úspěšně.',
      entityTypeDeleteError:
        'Při mazání záznamu došlo k chybě. Zkontrolujte, že na mazaný záznam není odkazováno z jiné části aplikace.',
      formDiffSuccess: 'Ukládání rozdílů formulářů skončilo úspěšně.',
      formDiffError: 'Při ukládání rozdílů formulářů došlo k chybě.',
    },
  },
  microserviceService: {
    effects: {
      microserviceSaveSuccess: 'TSM microservice byla úspěšně uložena.',
      microserviceSaveError: 'Během ukládání TSM microservice došlo k chybě',
      microserviceDeleteSuccess: 'Mazání microservice proběhlo úspěšně',
      microserviceDeleteError:
        'Při mazání záznamu došlo k chybě. Zkontrolujte, že na mazaný záznam není odkazováno z jiné části aplikace.',
      microserviceDiffSuccess:
        'Ukládání rozdílů microservice skončilo úspěšně.',
      microserviceDiffError: 'Při ukládání rozdílů microservice došlo k chybě.',
    },
  },
  tsmModuleService: {
    effects: {
      tsmModuleSaveSuccess: 'TSM modul byl úspěšně uložen.',
      tsmModuleSaveError: 'Během ukládání TSM modulu došlo k chybě',
      tsmModuleDeleteSuccess: 'Mazání TSM modulu proběhlo úspěšně',
      tsmModuleDeleteError:
        'Během mazání TSM modulu došlo k chybě, zkontrolujte, že na mazaný záznam není odkazováno z jiné části aplikace.',
      tsmModuleDiffSuccess: 'Ukládání rozdílových modulů TSM skončilo úspěšně.',
      tsmModuleDiffError: 'Při ukládání rozdílových modulů TSM došlo k chybě.',
    },
  },
  configTypeService: {
    effects: {
      configTypeDeleteSuccess: 'Mazání typu konfigurace proběhlo úspěšně',
      configTypeDeleteError:
        'Během mazání typu konfigurace došlo k chybě, zkontrolujte, zda typ konfigurace není použit u charakteristik, v registrech, na specifikaci nebo na formech.',
      configTypeSaveSuccessful: 'Typ konfigurace byl úspěšně uložen.',
      configTypeSaveUnSuccessful:
        'Během ukládání typu konfigurace došlo k chybě',
      messageConfigTypeChangeSuccessful:
        'Změna typu konfigurace proběhla úspěšně',
      messageConfigTypeSaveSuccessful:
        'Ukládání typu konfigurace proběhlo úspěšně',
      configTypeDiffSuccess:
        'Ukládání rozdílů typu konfigurace skončilo úspěšně.',
      configTypeDiffError:
        'Při ukládání rozdílů typu konfigurace došlo k chybě.',
    },
  },
  mnemonicService: {
    effects: {
      mnemonicSaveSuccess: 'mnemonika byla úspěšně uložena.',
      mnemonicSaveError: 'Během ukládání mnemoniky došlo k chybě',
      mnemonicDeleteSuccess: 'Mazání mnemoniky proběhlo úspěšně',
      mnemonicDeleteError:
        'Během mazání mnemoniky došlo k chybě, zkontrolujte, že na mazaný záznam není odkazováno z jiné části aplikace.',
      diffMnemonicSuccess: 'Ukládání rozdílů Mnemonik skončilo úspěšně.',
      diffMnemonicFailure: 'Při ukládání rozdílů Mnemonik došlo k chybě.',
    },
  },
  registerValue: {
    effects: {
      diffRegisterValueSuccess:
        'Ukládání rozdílů hodnot registru skončilo úspěšně.',
      diffRegisterValueError:
        'Při ukládání rozdílů hodnot registru došlo k chybě.',
    },
  },
  backupSet: {
    effects: {
      backupSetUpsertSuccess: 'Ukládání souboru záloh skončilo úspěšně.',
      backupSetUpsertError: 'Při ukládání souboru záloh došlo k chybě.',
      backupSetDeleteSuccess: 'Mazání souboru záloh skončilo úspěšně.',
      backupSetDeleteError: 'Při mazání souboru záloh došlo k chybě.',
      backupSetExportError: 'Při exportování Backupsetu došlo k chybě.',
    },
  },
};
