import {isSignal, signal, Signal} from '@angular/core';
import {isObservable, Observable} from 'rxjs';
import {toSignal} from '@angular/core/rxjs-interop';

/**
 * Helper that returns a Signal from either:
 * - a plain value P,
 * - a Signal<P>,
 * - or an Observable<P>
 */
export function asSignal<P>(
  paramOrSignalOrObs: P | Signal<P> | Observable<P>,
  initialValue?: P,
): Signal<P> {
  if (isSignal(paramOrSignalOrObs)) {
    return paramOrSignalOrObs;
  }

  if (isObservable(paramOrSignalOrObs)) {
    return toSignal(paramOrSignalOrObs, {initialValue});
  }

  return signal(paramOrSignalOrObs as P);
}
