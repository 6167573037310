import {createAction, props} from '@ngrx/store';
import {Attachment} from '../models';

export const LoadDataAttachmentOld = createAction(
  '[Attachment] Load data old',
  props<{
    ownerId: string;
    ownerType: string;
    readonly: boolean;
    onlyWithThumbnail: boolean;
  }>(),
);

export const LoadDataAttachment = createAction(
  '[Attachment] Load data',
  props<{ownerId: string; ownerType: string; readonly: boolean}>(),
);

export const LoadDataAttachmentSuccess = createAction(
  '[Attachment] Load data Success',
  props<{
    ownerId: string;
    ownerType: string;
    attachments: Attachment[];
    readonly: boolean;
  }>(),
);

export const LoadDataAttachmentError = createAction(
  '[Attachment] Load data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const UploadDataAttachment = createAction(
  '[Attachment] Upload data data',
  props<{ownerId: string; ownerType: string; attachments: Attachment[]}>(),
);

export const UploadDataAttachmentSuccess = createAction(
  '[Attachment] Upload data Success',
  props<{ownerId: string; ownerType: string; attachments: Attachment[]}>(),
);

export const UploadDataAttachmentError = createAction(
  '[Attachment] Upload data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const UploadDataAndDeleteAllAttachment = createAction(
  '[Attachment] Upload data and delete data',
  props<{ownerId: string; ownerType: string; attachments: Attachment[]}>(),
);

export const UploadDataAndDeleteAllAttachmentSuccess = createAction(
  '[Attachment] Upload data and delete data Success',
  props<{ownerId: string; ownerType: string; attachments: Attachment[]}>(),
);

export const UploadDataAndDeleteAllAttachmentError = createAction(
  '[Attachment] Upload data and delete data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const DownloadDataAttachment = createAction(
  '[Attachment] Download data data',
  props<{
    ownerId: string;
    ownerType: string;
    attachment: Attachment;
    showData: boolean;
  }>(),
);

export const DownloadDataAttachmentSuccess = createAction(
  '[Attachment] Download data Success',
  props<{ownerId: string; ownerType: string}>(),
);

export const DownloadDataAttachmentError = createAction(
  '[Attachment] Download data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const DeleteDataAttachment = createAction(
  '[Attachment] Delete data',
  props<{ownerId: string; ownerType: string; attachment: Attachment}>(),
);

export const DeleteDataAttachmentSuccess = createAction(
  '[Attachment] Delete data Success',
  props<{ownerId: string; ownerType: string; attachmentId: string}>(),
);

export const DeleteDataAttachmentError = createAction(
  '[Attachment] Delete data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);

export const DeleteAllDataAttachment = createAction(
  '[Attachment] Delete all data',
  props<{ownerId: string; ownerType: string}>(),
);

export const DeleteAllDataAttachmentSuccess = createAction(
  '[Attachment] Delete all data Success',
  props<{ownerId: string; ownerType: string}>(),
);

export const DeleteAllDataAttachmentError = createAction(
  '[Attachment] Delete all data ERROR',
  props<{ownerId: string; ownerType: string; error: any}>(),
);
