import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TsmUiConfigStateModel} from '../models/ui-config-state.model';

const selectUiConfigState =
  createFeatureSelector<TsmUiConfigStateModel>('uiConfig');

export const getUiConfig = createSelector(
  selectUiConfigState,
  (uiConfig) => uiConfig.uiConfig,
);
