import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {DeliveryItemType} from '../models';
import {notificationCountAdapter} from '../reducers/notification-count.reducer';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getNotificationCountState = createSelector(
  getDmsState,
  (x) => x.notificationCount,
);

const {selectAll, selectEntities, selectIds, selectTotal} =
  notificationCountAdapter.getSelectors(getNotificationCountState);

export const selectNotificationCountByDeliveryType = (
  deliveryItemType: DeliveryItemType,
) =>
  createSelector(
    selectEntities,
    (entities) => entities[deliveryItemType]?.data,
  );

export const selectNotificationCountUnreadByDeliveryType = (
  deliveryItemType: DeliveryItemType,
) =>
  createSelector(
    selectNotificationCountByDeliveryType(deliveryItemType),
    (count) => count?.unread,
  );
