import {createFeatureSelector, createSelector} from '@ngrx/store';
import {worklogAdapter} from '../reducers/worklog.reducer';
import {DmsState} from '../reducers';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getWorklogState = createSelector(getDmsState, (x) => x.worklog);

const {selectAll, selectEntities, selectIds, selectTotal} =
  worklogAdapter.getSelectors(getWorklogState);

export const selectWorklogsById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectWorklogsByOwnerIdOwnerType = (
  ownerId: string,
  ownerType: string,
) =>
  createSelector(selectAll, (events) => {
    const worklog = events.find(
      (e) => e.ownerId == ownerId && e.ownerType == ownerType,
    );
    return worklog ? worklog.worklogs : [];
  });
