import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {of} from 'rxjs';

import {
  controlsCatalogCategoryDetailConfiguration,
  controlsCatalogCategoryNewConfiguration,
  controlsCatalogSpecificationDetailConfiguration,
  controlsCatalogSpecificationNewConfiguration,
} from './tsm-controls-configuration';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/catalog/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const catalogPlugin: Plugin = {
  menus: [
    {
      key: 'catalogs',
      priority: 1000,
      label: 'menu.catalogs.catalogManagement',
      icon: 'tsm-icon-catalog-management',
      privilege: ['Cat.ViewList'],
      items: [
        // {
        //   key: 'specifications',
        //   label: 'menu.catalogs.specifications',
        //   icon: 'tsm-icon-catalog',
        //   routerLink: ['/catalogs/catalog-specification'],
        //   listingType: 'tsm-catalog-specification',
        //   privilege: ['true']
        // },
        {
          key: 'CATALOG_REGISTERS',
          label: 'menu.catalogs.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Cat.Registers.Catalog.ViewList',
            'Cat.Registers.Category.ViewList',
            'Cat.Registers.CatStatus.ViewList',
            'Cat.Registers.SpecStatus.ViewList',
            'Cat.Registers.SpecRelType.ViewList',
            'Cat.Registers.SpecType.ViewList',
            'Cat.Registers.VatRange.ViewList',
          ],
          items: [
            {
              key: 'catalog',
              label: 'menu.catalogs.catalogs',
              icon: 'tsm-icon-catalog',
              routerLink: ['/catalogs/catalog'],
              listingType: 'tsm-catalog',
              privilege: ['Cat.Registers.Catalog.ViewList'],
            },
            {
              key: 'categories',
              label: 'menu.catalogs.category',
              icon: 'tsm-icon-catalog',
              routerLink: ['/catalogs/catalog-category'],
              listingType: 'tsm-catalog-category',
              privilege: ['Cat.Registers.Category.ViewList'],
            },
            {
              key: 'CATALOG_LIFECYCLE_STATUS',
              label: 'menu.catalogs.catalogStates',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/catalogs/registers/CATALOG_LIFECYCLE_STATUS'],
              privilege: ['Cat.Registers.CatStatus.ViewList'],
            },
            {
              key: 'CATALOG_SPEC_LIFECYCLE_STATUS',
              label: 'menu.catalogs.specificationStates',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/catalogs/registers/CATALOG_SPEC_LIFECYCLE_STATUS'],
              privilege: ['Cat.Registers.SpecStatus.ViewList'],
            },
            {
              key: 'catalog-spec-rel-type',
              label: 'menu.catalogs.specificationLinkType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/catalogs/registers/catalog-spec-rel-type'],
              privilege: ['Cat.Registers.SpecRelType.ViewList'],
            },
            {
              key: 'catalog-specification-type',
              label: 'menu.catalogs.specificationType',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/catalogs/registers/catalog-specification-type'],
              privilege: ['Cat.Registers.SpecType.ViewList'],
            },
            {
              key: 'vat-range',
              label: 'menu.catalogs.vatRange',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/catalogs/vat-range'],
              privilege: ['Cat.Registers.VatRange.ViewList'],
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'catalogs',
      loadChildren: () =>
        import('@tsm/catalog/components').then(
          (x) => x.CatalogComponentsModule,
        ),
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/catalog/widgets').then((x) => x.CatalogWidgetsModule),
      definitions: [
        {
          selector: 'tsm-catalog-category-filter',
          loadComponent: () =>
            import('@tsm/catalog/widgets/filters/catalog-category-filter').then(
              (x) => x.CatalogCategoryFilterComponent,
            ),
          name: translationPlugin.catalogPlugins.filters
            .tsmCatalogCategoryFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogCategoryFilterDescription,
        },
        {
          selector: 'tsm-catalog-code-filter',
          loadComponent: () =>
            import('@tsm/catalog/widgets/filters/catalog-code-filter').then(
              (x) => x.CatalogCodeFilterComponent,
            ),
          name: translationPlugin.catalogPlugins.filters.tsmCatalogCodeFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogCodeFilterDescription,
        },
        {
          selector: 'tsm-catalog-entity-type-filter',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/filters/catalog-entity-type-filter'
            ).then((x) => x.CatalogEntityTypeFilterComponent),
          name: translationPlugin.catalogPlugins.filters
            .tsmCatalogEntityTypeFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogEntityTypeFilterDescription,
        },
        {
          selector: 'tsm-catalog-specification-filter',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/filters/catalog-specification-filter'
            ).then((x) => x.CatalogSpecificationFilterComponent),
          name: translationPlugin.catalogPlugins.filters
            .tsmCatalogSpecificationFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogSpecificationFilterDescription,
        },
        {
          selector: 'tsm-catalog-specification-status-filter',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/filters/catalog-specification-status-filter'
            ).then((x) => x.CatalogSpecificationStatusFilterComponent),
          name: translationPlugin.catalogPlugins.filters
            .tsmCatalogSpecificationStatusFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogSpecificationStatusFilterDescription,
        },
        {
          selector: 'tsm-catalog-specification-type-filter',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/filters/catalog-specification-type-filter'
            ).then((x) => x.CatalogSpecificationTypeFilterComponent),
          name: translationPlugin.catalogPlugins.filters
            .tsmCatalogSpecificationTypeFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogSpecificationTypeFilterDescription,
        },
        {
          selector: 'tsm-catalog-status-filter',
          loadComponent: () =>
            import('@tsm/catalog/widgets/filters/catalog-status-filter').then(
              (x) => x.CatalogStatusFilterComponent,
            ),
          name: translationPlugin.catalogPlugins.filters.tsmCatalogStatusFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogStatusFilterDescription,
        },
        {
          selector: 'tsm-catalog-type-filter',
          loadComponent: () =>
            import('@tsm/catalog/widgets/filters/catalog-type-filter').then(
              (x) => x.CatalogTypeFilterComponent,
            ),
          name: translationPlugin.catalogPlugins.filters.tsmCatalogTypeFilter,
          description:
            translationPlugin.catalogPlugins.filters
              .tsmCatalogTypeFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/catalog/widgets').then((x) => x.CatalogWidgetsModule),
      definitions: [
        {
          selector: 'catalogCategoryCode',
          name: translationPlugin.catalogPlugins.pipes.catalogCategoryCode,
          description:
            translationPlugin.catalogPlugins.pipes
              .catalogCategoryCodeDescription,
        },
        {
          selector: 'catalogCategory',
          name: translationPlugin.catalogPlugins.pipes.catalogCategory,
          description:
            translationPlugin.catalogPlugins.pipes.catalogCategoryDescription,
        },
        {
          selector: 'catalogCode',
          name: translationPlugin.catalogPlugins.pipes.catalogCode,
          description:
            translationPlugin.catalogPlugins.pipes.catalogCodeDescription,
        },
        {
          selector: 'catalogSpecificationLivecycle',
          name: translationPlugin.catalogPlugins.pipes
            .catalogSpecificationLivecycle,
          description:
            translationPlugin.catalogPlugins.pipes
              .catalogSpecificationLivecycleDescription,
        },
        {
          selector: 'catalogSpecificationStatusCode',
          name: translationPlugin.catalogPlugins.pipes
            .catalogSpecificationStatusCode,
          description:
            translationPlugin.catalogPlugins.pipes
              .catalogSpecificationStatusCodeDescription,
        },
        {
          selector: 'catalogSpecificationStatus',
          name: translationPlugin.catalogPlugins.pipes
            .catalogSpecificationStatus,
          description:
            translationPlugin.catalogPlugins.pipes
              .catalogSpecificationStatusDescription,
        },
        {
          selector: 'catalogStatus',
          name: translationPlugin.catalogPlugins.pipes.catalogStatus,
          description:
            translationPlugin.catalogPlugins.pipes.catalogStatusDescription,
        },
        {
          selector: 'catalog',
          name: translationPlugin.catalogPlugins.pipes.catalog,
          description:
            translationPlugin.catalogPlugins.pipes.catalogDescription,
        },
        {
          selector: 'entityCatalogSpecCode',
          name: translationPlugin.catalogPlugins.pipes.entityCatalogSpecCode,
          description:
            translationPlugin.catalogPlugins.pipes
              .entityCatalogSpecCodeDescription,
        },
        {
          selector: 'entityCatalogSpec',
          name: translationPlugin.catalogPlugins.pipes.entityCatalogSpec,
          description:
            translationPlugin.catalogPlugins.pipes.entityCatalogSpecDescription,
        },
        {
          selector: 'entityCatalogSpecTypeCode',
          name: translationPlugin.catalogPlugins.pipes
            .entityCatalogSpecTypeCode,
          description:
            translationPlugin.catalogPlugins.pipes
              .entityCatalogSpecTypeCodeDescription,
        },
        {
          selector: 'entityCatalogSpecType',
          name: translationPlugin.catalogPlugins.pipes.entityCatalogSpecType,
          description:
            translationPlugin.catalogPlugins.pipes
              .entityCatalogSpecTypeDescription,
        },
        {
          selector: 'priceBookItem',
          name: translationPlugin.catalogPlugins.pipes.priceBookItem,
          description:
            translationPlugin.catalogPlugins.pipes.priceBookItemDescription,
        },
        {
          selector: 'relationshipArrow',
          name: translationPlugin.catalogPlugins.pipes.relationshipArrow,
          description:
            translationPlugin.catalogPlugins.pipes.relationshipArrowDescription,
        },
        {
          selector: 'relationshipCategorySpecificationIcon',
          name: translationPlugin.catalogPlugins.pipes
            .relationshipCategorySpecificationIcon,
          description:
            translationPlugin.catalogPlugins.pipes
              .relationshipCategorySpecificationIconDescription,
        },
        {
          selector: 'RelationshipCodeLink',
          name: translationPlugin.catalogPlugins.pipes.RelationshipCodeLink,
          description:
            translationPlugin.catalogPlugins.pipes
              .RelationshipCodeLinkDescription,
        },
        {
          selector: 'RelationshipSpecificationState',
          name: translationPlugin.catalogPlugins.pipes
            .RelationshipSpecificationState,
          description:
            translationPlugin.catalogPlugins.pipes
              .RelationshipSpecificationStateDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/catalog/widgets').then((x) => x.CatalogWidgetsModule),
      definitions: [
        {
          selector: 'tsm-entity-instance-location-required-lov',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/entity-instance-location-required-lov'
            ).then((x) => x.EntityInstanceLocationRequiredLovComponent),
        },
        {
          selector: 'tsm-catalog-specification-status-lov',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/catalog-specification-status-lov'
            ).then((x) => x.CatalogSpecificationStatusLovComponent),
        },
        {
          selector: 'tsm-catalog-card',
          loadComponent: () =>
            import('@tsm/catalog/widgets/catalog-card').then(
              (x) => x.CatalogCardComponent,
            ),
        },
        {
          selector: 'tsm-catalog-category-card',
          loadComponent: () =>
            import('@tsm/catalog/widgets/catalog-category-card').then(
              (x) => x.CatalogCategoryCardComponent,
            ),
        },
        {
          selector: 'tsm-catalog-specification-card',
          loadComponent: () =>
            import('@tsm/catalog/widgets/catalog-specification-card').then(
              (x) => x.CatalogSpecificationCardComponent,
            ),
        },
        {
          selector: 'tsm-catalog-specification-lov',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/catalog-specification-lov/catalog-specification-lov'
            ).then((x) => x.CatalogSpecificationLovComponent),
          privilege: ['!Cat.Widget.tsm-catalog-specification-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-catalog-specification-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/catalog/widgets/catalog-specification-lov/catalog-specification-lov-editor'
                ).then((x) => x.CatalogSpecificationLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'catalogSpecification',
              categoryId: DesignerCategoryEnum.Catalogs,
              name: translationShared.shared.widgets.catalogSpecificationSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.catalogSpecificationSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.catalogSpecificationSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-catalog-specification-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-catalog-category-lov',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/catalog-category-lov/catalog-category-lov'
            ).then((x) => x.CatalogCategoryLovComponent),
          privilege: ['!Cat.Widget.tsm-catalog-category-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-catalog-category-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/catalog/widgets/catalog-category-lov/catalog-category-lov-editor'
                ).then((x) => x.CatalogCategoryLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'catalogCategory',
              categoryId: DesignerCategoryEnum.Catalogs,
              name: translationShared.shared.widgets.catalogCategorySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.catalogCategorySelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 4,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.catalogCategorySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-catalog-category-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-catalog-lov',
          loadComponent: () =>
            import('@tsm/catalog/widgets/catalog-lov/catalog-lov').then(
              (x) => x.CatalogLovComponent,
            ),
          privilege: ['!Cat.Widget.tsm-catalog-lov'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              editorSelector: 'tsm-catalog-lov-editor',
              loadComponent: () =>
                import(
                  '@tsm/catalog/widgets/catalog-category-lov/catalog-category-lov-editor'
                ).then((x) => x.CatalogCategoryLovEditorComponent),
              widgetOrLayout: 'widget',
              formField: 'catalog',
              categoryId: DesignerCategoryEnum.Catalogs,
              name: translationShared.shared.widgets.catalogSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.catalogSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 3,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.catalogSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-catalog-lov',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-catalog-priv',
          loadComponent: () =>
            import('@tsm/catalog/widgets/catalog-priv/catalog-priv').then(
              (x) => x.CatalogPrivComponent,
            ),
          designers: [
            {
              editorSelector: 'tsm-catalog-priv-editor',
              loadComponent: () =>
                import(
                  '@tsm/catalog/widgets/catalog-priv/catalog-priv-editor'
                ).then((x) => x.CatalogPrivEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Catalogs,
              name: translationShared.shared.widgets.catalogPrivilegeList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.catalogPrivilegeList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 2,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.catalogPrivilegeList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-catalog-priv',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-catalog-specification-relationship-list',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/catalog-spec-relationship-list/catalog-spec-relationship-list'
            ).then((x) => x.CatalogSpecRelationshipListComponent),
          designers: [
            {
              editorSelector:
                'tsm-catalog-specification-relationship-list-editor',
              loadComponent: () =>
                import(
                  '@tsm/catalog/widgets/catalog-spec-relationship-list/catalog-spec-relationship-list-editor'
                ).then((x) => x.CatalogSpecRelationshipListEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Catalogs,
              name: translationShared.shared.widgets.catalogRelationList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.catalogRelationList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.catalogRelationList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-catalog-specification-relationship-list',
                },
              },
            },
          ],
        },
        {
          selector: 'tsm-price-list-detail',
          loadComponent: () =>
            import(
              '@tsm/catalog/widgets/price-list-detail/price-list-detail'
            ).then((x) => x.PriceListDetailComponent),
          designers: [
            {
              editorSelector: 'tsm-price-list-detail-editor',
              loadComponent: () =>
                import(
                  '@tsm/catalog/widgets/price-list-detail/price-list-detail-editor'
                ).then((x) => x.PriceListDetailEditorComponent),
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Catalogs,
              name: translationShared.shared.widgets.priceCard.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.priceCard.widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.card,
              value: {
                type: 'layout',
                title: translationShared.shared.widgets.priceCard.inWidgetTitle,
                widget: {
                  type: 'tsm-price-list-detail',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  dataSources: [],
  entityTypes: [
    {
      entityType: 'EntityCatalog',
      url: (entityId) => {
        return of('/catalogs/catalog/' + entityId);
      },
    },
    {
      entityType: 'EntityCatalogCategory',
      url: (entityId) => {
        return of('/catalogs/catalog-category/' + entityId);
      },
    },
    {
      entityType: 'EntityCatalogSpecification',
      url: (entityId) => {
        return of('/catalogs/catalog-specification/' + entityId);
      },
    },
    {
      entityType: 'CatalogSpecificationRelationshipType',
      url: (entityId) => {
        return of('/catalogs/registers/catalog-spec-rel-type');
      },
    },
    {
      entityType: 'CatalogSpecificationType',
      url: (entityId) => {
        return of('/catalogs/registers/catalog-specification-type');
      },
    },
    {
      entityType: 'EntityCatalogCategory',
      url: (entityId) => {
        return of(`/catalogs/catalog-category/${entityId}`);
      },
    },
    {
      // FIXME - opravit url po prepsani na plnohodnotne entity
      entityType: 'EntityCatalogStatus',
      url: (entityId) => {
        return of('/catalogs/registers/CATALOG_LIFECYCLE_STATUS');
      },
    },
    {
      // FIXME - opravit url po prepsani na plnohodnotne entity
      entityType: 'CatalogStatus',
      url: (entityId) => {
        return of('/catalogs/registers/CATALOG_LIFECYCLE_STATUS');
      },
    },
    {
      entityType: 'CatalogVatRange',
      url: (entityId) => {
        return of('/catalogs/vat-range');
      },
    },
    {
      // FIXME - opravit url po prepsani na plnohodnotne entity
      entityType: 'CatalogItemStatus',
      url: (entityId) => {
        return of('/catalogs/registers/CATALOG_SPEC_LIFECYCLE_STATUS');
      },
    },
  ],
  layoutControls: [
    controlsCatalogCategoryNewConfiguration,
    controlsCatalogCategoryDetailConfiguration,
    controlsCatalogSpecificationNewConfiguration,
    controlsCatalogSpecificationDetailConfiguration,
  ],
};
