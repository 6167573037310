import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';
import {of} from 'rxjs';

import {
  controlsBillingDocumentLineNewConfiguration,
  controlsBillingDocumentLineTopbarConfiguration,
  controlsBillingDocumentNewConfiguration,
  controlsBillingDocumentTypeDetailConfiguration,
  controlsBillingTopbarConfiguration,
} from './tsm-controls-configuration';
import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/billing/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const billingPlugin: Plugin = {
  menus: [
    {
      key: 'billing',
      priority: 1750,
      label: 'menu.billing.billing',
      icon: 'tsm-icon-budget',
      privilege: [
        'Billing.BillingDocument.ViewList',
        'Billing.Registers.BillingDocumentType.ViewList',
      ],
      items: [
        {
          key: 'BILLING_CYCLE',
          label: 'menu.billing.billingCycle',
          icon: 'tsm-icon-message-time',
          routerLink: ['/billing/billing-cycle'],
          privilege: ['Billing.BillingCycle.ViewList'],
        },
        {
          key: 'BILLING_DOCUMENT',
          label: 'menu.billing.billingDocument',
          icon: 'tsm-icon-card-payment',
          routerLink: ['/billing/billing-document'],
          privilege: ['Billing.BillingDocument.ViewList'],
        },
        {
          key: 'BILLING_DOCUMENT_LINE',
          label: 'menu.billing.billingDocumentLine',
          icon: 'tsm-icon-card-payment',
          routerLink: ['/billing/billing-document-line'],
          privilege: ['Billing.BillingDocumentLine.ViewList'],
        },
        {
          key: 'SAP_DOCUMENT',
          label: 'menu.billing.sapDocument',
          icon: 'tsm-icon-card-payment',
          routerLink: ['/billing/sap-document'],
          privilege: ['Billing.SapDocument.ViewList'],
        },
        {
          key: 'BILLING_REGISTERS',
          label: 'menu.billing.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Billing.Registers.BillingDocumentType.ViewList',
            'Billing.Registers.BillingCycleType.ViewList',
            'Billing.Registers.BillingCycleStatus.ViewList',
            'Billing.Registers.BillingDocumentStatus.ViewList',
            'Billing.Registers.BillingVatRate.ViewList',
            'Billing.Registers.SapDocumentStatus.ViewList',
          ],
          items: [
            {
              key: 'BILLING_DOCUMENT_TYPE',
              label: translationShared.menu.billing.billingDocumentType,
              icon: 'tsm-icon-menu-item',
              routerLink: ['/billing/billing-document-type'],
              privilege: ['Billing.Registers.BillingDocumentType.ViewList'],
            },
            {
              key: 'BILLING_DOCUMENT_STATUS',
              label: translationShared.menu.billing.billingDocumentStatus,
              icon: 'tsm-icon-menu-item',
              routerLink: ['/billing/billing-document-status'],
              privilege: ['Billing.Registers.BillingDocumentStatus.ViewList'],
            },
            {
              key: 'BILLING_CYCLE_TYPE',
              label: translationShared.menu.billing.billingCycleType,
              icon: 'tsm-icon-menu-item',
              routerLink: ['/billing/billing-cycle-type'],
              privilege: ['Billing.Registers.BillingCycleType.ViewList'],
            },
            {
              key: 'BILLING_CYCLE_STATUS',
              label: translationShared.menu.billing.billingCycleStatus,
              icon: 'tsm-icon-menu-item',
              routerLink: ['/billing/billing-cycle-status'],
              privilege: ['Billing.Registers.BillingCycleStatus.ViewList'],
            },
            {
              key: 'BILLING_SAP_DOCUMENT_STATUS',
              label: translationShared.menu.billing.sapDocumentStatus,
              icon: 'tsm-icon-menu-item',
              routerLink: ['/billing/sap-document-status'],
              privilege: ['Billing.Registers.SapDocument.ViewList'],
            },
            {
              key: 'BILLING_CURRENCY',
              label: translationShared.menu.billing.billingCurrency,
              icon: 'tsm-icon-menu-item',
              routerLink: ['/billing/billing-currency'],
              privilege: ['Billing.Registers.BillingCurrency.ViewList'],
            },
            // pro prezentaci schovano
            /* {
               key: 'BILLING_VAT_RATE',
               label: 'menu.billing.vatRate',
               icon: 'tsm-icon-menu-item',
               routerLink: ['/billing/billing-vat-rate'],
               privilege: ['Billing.Registers.BillingVatRate.ViewList']
             },*/
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'billing',
      loadChildren: () =>
        import('@tsm/billing/components').then(
          (x) => x.BillingComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/billing/widgets').then((x) => x.BillingWidgetsModule),
      definitions: [
        {selector: 'tsm-billing-document-card'},
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-cycle-type-lov/billing-cycle-type-lov'
            ).then((x) => x.BillingCycleTypeLovComponent),
          selector: 'tsm-billing-cycle-type-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-cycle-type-lov/billing-cycle-type-lov-editor'
                ).then((x) => x.BillingCycleTypeLovEditorComponent),
              editorSelector: 'tsm-billing-cycle-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingCycleTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingCycleTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 2,
              icon: DesignerIconsEnum.select,
              formField: 'billingCycleType',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingCycleTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-cycle-type-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-currency-lov/billing-currency-lov'
            ).then((x) => x.BillingCurrencyLovComponent),
          selector: 'tsm-billing-currency-lov',
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-currency-lov/billing-currency-lov-editor'
                ).then((x) => x.BillingCurrencyLovEditorComponent),
              editorSelector: 'tsm-billing-currency-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingCurrencySelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingCurrencySelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 2,
              icon: DesignerIconsEnum.select,
              formField: 'billingCurrency',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingCurrencySelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-currency-lov',
                },
              },
            },
          ],
        },
        {
          // todo: zjistit proč se nenačítají data
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-sap-document-status-lov/billing-sap-document-status-lov'
            ).then((x) => x.BillingSapDocumentStatusLovComponent),
          selector: 'tsm-billing-sap-document-status-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-sap-document-status-lov/billing-sap-document-status-lov-editor'
                ).then((x) => x.BillingSapDocumentStatusLovEditorComponent),
              editorSelector: 'tsm-billing-sap-document-status-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              description:
                translationShared.shared.widgets.billingSapDocumentStatusSelect
                  .widgetDescription,
              icon: DesignerIconsEnum.select,
              formField: 'sapDocumentStatus',
              name: translationShared.shared.widgets
                .billingSapDocumentStatusSelect.widgetName,
              group: GroupPosition.Secondary,
              order: 4,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets
                    .billingSapDocumentStatusSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-billing-sap-document-status-lov',
                  readonly: false,
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-cycle-lov/billing-cycle-lov'
            ).then((x) => x.BillingCycleLovComponent),
          selector: 'tsm-billing-cycle-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-cycle-lov/billing-cycle-lov-editor'
                ).then((x) => x.BillingCycleLovEditorComponent),
              editorSelector: 'tsm-billing-cycle-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingCycleSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingCycleSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 1,
              icon: DesignerIconsEnum.select,
              formField: 'billingCycle',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingCycleSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-cycle-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-cycle-status-lov/billing-cycle-status-lov'
            ).then((x) => x.BillingCycleStatusLovComponent),
          selector: 'tsm-billing-cycle-status-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-cycle-status-lov/billing-cycle-status-lov-editor'
                ).then((x) => x.BillingCycleStatusLovEditorComponent),
              editorSelector: 'tsm-billing-cycle-status-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingCycleStatusSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingCycleStatusSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 1,
              icon: DesignerIconsEnum.select,
              formField: 'billingCycleStatus',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingCycleStatusSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-cycle-status-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-document-lov/billing-document-lov'
            ).then((x) => x.BillingDocumentLovComponent),
          selector: 'tsm-billing-document-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-document-lov/billing-document-lov-editor'
                ).then((x) => x.BillingDocumentLovEditorComponent),
              editorSelector: 'tsm-billing-document-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingDocumentSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingDocumentSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 3,
              icon: DesignerIconsEnum.select,
              formField: 'billingDocument',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingDocumentSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-document-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-vat-rate-lov/billing-vat-rate-lov'
            ).then((x) => x.BillingVatRateLovComponent),
          selector: 'tsm-billing-vat-rate-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-vat-rate-lov/billing-vat-rate-lov-editor'
                ).then((x) => x.BillingVatRateLovEditorComponent),
              editorSelector: 'tsm-billing-vat-rate-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.vatRateSelect.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.vatRateSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 3,
              icon: DesignerIconsEnum.select,
              formField: 'vatRate',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.vatRateSelect.inWidgetTitle,
                widget: {
                  type: 'tsm-vat-rate-lov-box-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-document-type-lov/billing-document-type-lov'
            ).then((x) => x.BillingDocumentTypeLovComponent),
          selector: 'tsm-billing-document-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-document-type-lov/billing-document-type-lov-editor'
                ).then((x) => x.BillingDocumentTypeLovEditorComponent),
              editorSelector: 'tsm-billing-document-type-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingDocumentTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingDocumentTypeSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 3,
              icon: DesignerIconsEnum.select,
              formField: 'billingDocumentType',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingDocumentTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-document-type-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-document-line-lov/billing-document-line-lov'
            ).then((x) => x.BillingDocumentLineLovComponent),
          selector: 'tsm-billing-document-line-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-document-line-lov/billing-document-line-lov-editor'
                ).then((x) => x.BillingDocumentLineLovEditorComponent),
              editorSelector: 'tsm-billing-document-line-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingDocumentLineSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingDocumentLineSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 3,
              icon: DesignerIconsEnum.select,
              formField: 'billingDocumentLine',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingDocumentLineSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-document-line-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-document-status-lov/billing-document-status-lov'
            ).then((x) => x.BillingDocumentStatusLovComponent),
          selector: 'tsm-billing-document-status-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-document-status-lov/billing-document-status-lov-editor'
                ).then((x) => x.BillingDocumentStatusLovEditorComponent),
              editorSelector: 'tsm-billing-document-status-lov-editor',
              widgetOrLayout: 'widget',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingDocumentStatusSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingDocumentStatusSelect
                  .widgetDescription,
              group: GroupPosition.Secondary,
              order: 3,
              icon: DesignerIconsEnum.select,
              formField: 'billingDocumentStatus',
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.billingDocumentStatusSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-document-status-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/billing-document-widget/billing-document-widget'
            ).then((x) => x.BillingDocumentWidgetComponent),
          selector: 'tsm-billing-document-widget',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/billing/widgets/billing-document-widget/billing-document-widget-editor'
                ).then((x) => x.BillingDocumentWidgetEditorComponent),
              editorSelector: 'tsm-billing-document-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Billing,
              name: translationShared.shared.widgets.billingDocumentList
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.billingDocumentList
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 1,
              icon: DesignerIconsEnum.list,
              value: {
                type: 'layout',
                title:
                  translationShared.shared.widgets.billingDocumentList
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-billing-document-widget',
                },
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/billing/widgets').then((x) => x.BillingWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/billing/widgets/filters/billing-cycle-filter').then(
              (x) => x.BillingCycleFilterComponent,
            ),
          selector: 'tsm-billing-cycle-filter',
          name: translationPlugin.billingPlugins.filters.tsmBillingCycleFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingCycleFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/filters/billing-cycle-status-filter'
            ).then((x) => x.BillingCycleStatusFilterComponent),
          selector: 'tsm-billing-cycle-status-filter',
          name: translationPlugin.billingPlugins.filters
            .tsmBillingCycleStatusFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingCycleStatusFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/filters/billing-cycle-type-filter'
            ).then((x) => x.BillingCycleTypeFilterComponent),
          selector: 'tsm-billing-cycle-type-filter',
          name: translationPlugin.billingPlugins.filters
            .tsmBillingCycleTypeFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingCycleTypeFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/filters/billing-cycle-type-frequency-filter'
            ).then((x) => x.BillingCycleTypeFrequencyFilterComponent),
          selector: 'tsm-billing-cycle-type-frequency-filter',
          name: translationPlugin.billingPlugins.filters
            .tsmBillingCycleTypeFrequencyFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingCycleTypeFrequencyFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/filters/billing-document-status-filter'
            ).then((x) => x.BillingDocumentStatusFilterComponent),
          selector: 'tsm-billing-document-status-filter',
          name: translationPlugin.billingPlugins.filters
            .tsmBillingDocumentStatusFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingDocumentStatusFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/filters/billing-document-type-filter'
            ).then((x) => x.BillingDocumentTypeFilterComponent),
          selector: 'tsm-billing-document-type-filter',
          name: translationPlugin.billingPlugins.filters
            .tsmBillingDocumentTypeFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingDocumentTypeFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/billing/widgets/filters/billing-sap-document-status-filter'
            ).then((x) => x.BillingSapDocumentStatusFilterComponent),
          selector: 'tsm-billing-sap-document-status-filter',
          name: translationPlugin.billingPlugins.filters
            .tsmBillingSapDocumentStatusFilter,
          description:
            translationPlugin.billingPlugins.filters
              .tsmBillingSapDocumentStatusFilterDescription,
        },
      ],
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/billing/widgets').then((x) => x.BillingWidgetsModule),
      definitions: [
        {
          selector: 'billingCurrencyLoader',
          name: translationPlugin.billingPlugins.pipes.billingCurrencyLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingCurrencyLoaderDescription,
        },
        {
          selector: 'billingCurrencyLoaderCode',
          name: translationPlugin.billingPlugins.pipes
            .billingCurrencyLoaderCode,
          description:
            translationPlugin.billingPlugins.pipes
              .billingCurrencyLoaderCodeDescription,
        },
        {
          selector: 'billingCycleLoader',
          name: translationPlugin.billingPlugins.pipes.billingCycleLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingCycleLoaderDescription,
        },
        {
          selector: 'billingCycleLoaderCode',
          name: translationPlugin.billingPlugins.pipes.billingCycleLoaderCode,
          description:
            translationPlugin.billingPlugins.pipes
              .billingCycleLoaderCodeDescription,
        },
        {
          selector: 'billingCycleStatusLoader',
          name: translationPlugin.billingPlugins.pipes.billingCycleStatusLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingCycleStatusLoaderDescription,
        },
        {
          selector: 'billingCycleTypeLoader',
          name: translationPlugin.billingPlugins.pipes.billingCycleTypeLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingCycleTypeLoaderDescription,
        },
        {
          selector: 'billingDocumentLineLoader',
          name: translationPlugin.billingPlugins.pipes
            .billingDocumentLineLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingDocumentLineLoaderDescription,
        },
        {
          selector: 'billingDocumentLoader',
          name: translationPlugin.billingPlugins.pipes.billingDocumentLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingDocumentLoaderDescription,
        },
        {
          selector: 'billingDocumentLoaderKey',
          name: translationPlugin.billingPlugins.pipes.billingDocumentLoaderKey,
          description:
            translationPlugin.billingPlugins.pipes
              .billingDocumentLoaderKeyDescription,
        },
        {
          selector: 'billingDocumentStatusLoader',
          name: translationPlugin.billingPlugins.pipes
            .billingDocumentStatusLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingDocumentStatusLoaderDescription,
        },
        {
          selector: 'billingDocumentStatusLoaderCode',
          name: translationPlugin.billingPlugins.pipes
            .billingSapDocumentStatusLoaderCode,
          description:
            translationPlugin.billingPlugins.pipes
              .billingSapDocumentStatusLoaderCodeDescription,
        },
        {
          selector: 'billingDocumentTypeLoader',
          name: translationPlugin.billingPlugins.pipes
            .billingDocumentTypeLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingDocumentTypeLoaderDescription,
        },
        {
          selector: 'billingPriceTypeLoader',
          name: translationPlugin.billingPlugins.pipes.billingPriceTypeLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingPriceTypeLoaderDescription,
        },
        {
          selector: 'billingSapDocumentStatusLoader',
          name: translationPlugin.billingPlugins.pipes
            .billingSapDocumentStatusLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingSapDocumentStatusLoaderDescription,
        },

        {
          selector: 'billingVatRateLoader',
          name: translationPlugin.billingPlugins.pipes.billingVatRateLoader,
          description:
            translationPlugin.billingPlugins.pipes
              .billingVatRateLoaderDescription,
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'BillingDocument',
      url: (entityId) => {
        return of('/billing/billing-document/' + entityId);
      },
    },
    {
      entityType: 'BillingDocumentType',
      url: (entityId) => {
        return of('/billing/billing-document-type/' + entityId);
      },
    },
    {
      entityType: 'BillingDocumentStatus',
      url: (entityId) => {
        return of('/billing/billing-document-status/' + entityId);
      },
    },
    {
      entityType: 'BillingCycleType',
      url: (entityId) => {
        return of('/billing/billing-cycle-type/' + entityId);
      },
    },
    {
      entityType: 'BillingCycleStatus',
      url: (entityId) => {
        return of('/billing/billing-cycle-status/' + entityId);
      },
    },
    {
      entityType: 'BillingCurrency',
      url: (entityId) => {
        return of('/billing/billing-currency/' + entityId);
      },
    },
    {
      entityType: 'SapDocumentStatus',
      url: (entityId) => {
        return of('/billing/sap-document-status/' + entityId);
      },
    },
  ],
  layoutControls: [
    controlsBillingDocumentNewConfiguration,
    controlsBillingDocumentLineNewConfiguration,
    controlsBillingDocumentTypeDetailConfiguration,
    controlsBillingDocumentLineTopbarConfiguration,
    controlsBillingTopbarConfiguration,
  ],
};
