import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
} from '@angular/core';
import {FixedColDirective} from '../directives';

@Component({
  selector: 'dtl-col',
  template: ` <ng-content></ng-content>`,
})
export class ColComponent {
  @HostBinding('class')
  get styleClass(): string {
    if (this.cols) {
      if (this.offset) {
        return this.fixedColDirective?.fixedClass === true
          ? this.class
          : 'col-12 md:col-' +
              this.cols +
              ' col-offset-' +
              this.offset +
              ' ' +
              this.class;
      }
      return this.fixedColDirective?.fixedClass === true
        ? this.class
        : 'col-12 md:col-' + this.cols + ' ' + this.class;
    } else {
      return 'col' + ' ' + this.class;
    }
  }

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, jakou část layoutu má prvek zabírat
   */
  @Input()
  cols: number;

  /**
   * Layout je rozdělen na 12 částí
   * Zadaním čísla v rozmezí 1 - 12 určíte, o jakou část layoutu má být prvek odsunut (zleva)
   */
  @Input()
  offset: number;

  /**
   * Atribut pro připojení rozšiřující CSS třídy
   */
  @Input()
  class = '';

  constructor(
    public el: ElementRef,
    @Optional() @Self() private fixedColDirective: FixedColDirective,
  ) {}
}
