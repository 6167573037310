import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {InputMaskModule} from 'primeng/inputmask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormGroupComponent} from './form-group/form-group.component';
import {FormFieldContainerComponent} from './form-field-container/form-field-container.component';
import {FormFieldLabelComponent} from './form-field-label/form-field-label.component';
import {FormFieldErrorsComponent} from './form-field-errors/form-field-errors.component';

import {TranslateModule} from '@tsm/framework/translate';
import {FormInputPasswordComponent} from './form-input-password/form-input-password.component';
import {FormInputNumberComponent} from './form-input-number/form-input-number.component';
import {FormInputIntegerComponent} from './form-input-integer/form-input-integer.component';
import {FormInputTextareaComponent} from './form-input-textarea/form-input-textarea.component';
import {FormInputDateComponent} from './form-input-date/form-input-date.component';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FormInputCheckboxComponent} from './form-input-checkbox/form-input-checkbox.component';
import {FormInputTriStateCheckboxComponent} from './form-input-tri-state-checkbox/form-input-tri-state-checkbox.component';
import {FormInputRadioButtonComponent} from './form-input-radiobutton/form-input-radiobutton.component';
import {FormInputRatingComponent} from './form-input-rating/form-input-rating.component';
import {CheckboxModule} from 'primeng/checkbox';
import {KeyFilterModule} from 'primeng/keyfilter';
import {RatingModule} from 'primeng/rating';
import {FormInputKeyFilterComponent} from './form-input-key-filter/form-input-key-filter.component';
import {FormInputKeyFilterRegexComponent} from './form-input-key-filter-regex/form-input-key-filter-regex.component';
import {PasswordModule} from 'primeng/password';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {FormInputSqlComponent} from './form-input-sql/form-input-sql.component';
import {FormInputLinkComponent} from './form-input-link/form-input-link.component';
import {FormInputMaskComponent} from './form-input-mask/form-input-mask.component';
import {FormInputSwitchComponent} from './form-input-switch/form-input-switch.component';
import {
  DtlMonacoEditorModule,
  DtlMonacoDiffEditorModule,
} from '@tsm/framework/monaco-editor';
import {FormInputXmlComponent} from './form-input-xml/form-input-xml.component';
import {FormInputMarkdownComponent} from './form-input-markdown/form-input-markdown.component';
import {FormInputJsonComponent} from './form-input-json/form-input-json.component';
import {FormInputJsonObjectComponent} from './form-input-json-object/form-input-json-object.component';
import {FormInputJavaComponent} from './form-input-java/form-input-java.component';
import {FormInputHtmlComponent} from './form-input-html/form-input-html.component';
import {FormInputMonacoComponent} from './form-input-monaco/form-input-monaco.component';
import {FormInputMonacoDiffComponent} from './form-input-monaco-diff/form-input-monaco-diff.component';
import {FormInputTiptapComponent} from './form-input-tiptap/form-input-tiptap.component';
import {VariableComponent} from './variable/variable.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FrameworkTooltipModule} from '@tsm/framework/tooltip';
import {DynamicComponentModule} from '@tsm/framework/dynamic-component';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {localizations} from './i18n';
import {FormInputPhoneComponent} from './form-input-phone/form-input-phone.component';
import {InputNumberModule} from '@tsm/framework/override-primeng/input-number';
import {DtlCalendarModule} from '@tsm/framework/override-primeng/calendar';
import {InputGroupModule} from 'primeng/inputgroup';
import {InputGroupAddonModule} from 'primeng/inputgroupaddon';
import {FormInputIconPickerComponent} from './form-input-icon-picker/form-input-icon-picker.component';
import {FormInputTextComponent} from './form-input-text/form-input-text.component';
import {
  AutoSizeTooltipDirectiveModule,
  FormFieldInputDirective,
} from '@tsm/framework/root/directives';
import {
  DisplayVariablePipe,
  FormatPhonePipe,
  LocalizationDataTranslatePipe,
} from '@tsm/framework/root';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {IconPickerModule} from '@tsm/framework/root/icon-picker';

@NgModule({
    imports: [
        CommonModule,
        InputTextModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextareaModule,
        ReactiveFormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode',
        }),
        FormsModule,
        TranslateModule,
        CalendarModule,
        RatingModule,
        CheckboxModule,
        KeyFilterModule,
        PasswordModule,
        FrameworkGridModule,
        DtlMonacoEditorModule,
        DtlMonacoDiffEditorModule,
        DtlCalendarModule,
        RadioButtonModule,
        AutoSizeTooltipDirectiveModule,
        FrameworkTooltipModule,
        DynamicComponentModule,
        TriStateCheckboxModule,
        TranslateModule.forFeature(localizations),
        InputNumberModule,
        InputGroupModule,
        InputGroupAddonModule,
        IconPickerModule,
        LayoutIdDirective,
        FormGroupComponent,
        FormFieldContainerComponent,
        FormFieldLabelComponent,
        FormFieldErrorsComponent,
        FormInputTextComponent,
        FormInputMaskComponent,
        FormInputPhoneComponent,
        FormInputSwitchComponent,
        FormInputPasswordComponent,
        FormInputLinkComponent,
        FormInputNumberComponent,
        FormInputIntegerComponent,
        FormInputCheckboxComponent,
        FormInputTriStateCheckboxComponent,
        FormInputRadioButtonComponent,
        FormInputRatingComponent,
        FormInputTiptapComponent,
        FormInputTextareaComponent,
        FormInputDateComponent,
        FormFieldInputDirective,
        FormInputKeyFilterComponent,
        FormInputKeyFilterRegexComponent,
        FormInputXmlComponent,
        VariableComponent,
        FormInputJavaComponent,
        FormInputSqlComponent,
        FormInputMonacoComponent,
        FormInputMonacoDiffComponent,
        FormInputHtmlComponent,
        FormInputMarkdownComponent,
        FormInputJsonComponent,
        FormInputJsonObjectComponent,
        FormatPhonePipe,
        DisplayVariablePipe,
        LocalizationDataTranslatePipe,
        FormInputIconPickerComponent,
    ],
    exports: [
        FormGroupComponent,
        FormFieldContainerComponent,
        FormFieldLabelComponent,
        FormFieldErrorsComponent,
        FormInputMonacoComponent,
        FormInputMonacoDiffComponent,
        FormInputTextComponent,
        FormInputMaskComponent,
        FormInputPhoneComponent,
        FormInputSwitchComponent,
        FormInputPasswordComponent,
        FormInputLinkComponent,
        FormInputNumberComponent,
        FormInputIntegerComponent,
        FormInputCheckboxComponent,
        FormInputTriStateCheckboxComponent,
        FormInputRadioButtonComponent,
        FormInputRatingComponent,
        FormInputTiptapComponent,
        FormInputTextareaComponent,
        FormInputDateComponent,
        FormFieldInputDirective,
        FormInputKeyFilterComponent,
        FormInputKeyFilterRegexComponent,
        FormInputXmlComponent,
        VariableComponent,
        FormInputJavaComponent,
        FormInputSqlComponent,
        FormInputHtmlComponent,
        FormInputMarkdownComponent,
        FormInputJsonComponent,
        FormInputJsonObjectComponent,
        LayoutIdDirective,
        FormatPhonePipe,
        DisplayVariablePipe,
        LocalizationDataTranslatePipe,
        FrameworkTooltipModule,
        FormInputIconPickerComponent,
    ],
    providers: [
        FormatPhonePipe,
        DisplayVariablePipe,
        LocalizationDataTranslatePipe,
    ],
})
export class FormModule {}
