import {Injectable} from '@angular/core';
import {catchError, forkJoin, map, Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {filter, switchMap} from 'rxjs/operators';
import FileSaver from 'file-saver';
import {ApiService, Envelope} from '@tsm/framework/http';
import {ToastService} from '@tsm/framework/toast';
import {
  BackupSetDiffOptions,
  BackupSetDiffTable,
  BackupSetImport,
  CommonConfigFormApiService,
  DiffList,
  EntityDiff,
  TableColumn,
  TableColumnConfig,
  TableColumnConfigService,
} from '@tsm/listing-lib/service';
import {translation} from '../i18n';
import {Config, ConfigService} from '@tsm/framework/config';
import {distinctArrays} from '@tsm/framework/functions';

@Injectable({
  providedIn: 'root',
})
export class BackupSetService {
  translation = translation;

  private readonly BASE_PATH: string;
  private readonly API_URL = 'v1/backup-set';

  constructor(
    private store: Store,
    private toastService: ToastService,
    private apiService: ApiService,
    private configService: ConfigService<Config>,
    private tableColumnConfigService: TableColumnConfigService,
    private commonApiService: CommonConfigFormApiService<
      TableColumnConfig,
      TableColumnConfig
    >,
  ) {
    this.BASE_PATH = this.configService.value.apiUrls.tsmFormConfig;
  }

  restoreBackupSet(
    file: any,
    options?: BackupSetDiffOptions,
  ): Observable<Envelope<BackupSetDiffTable[]>> {
    return this.uploadBackupSet(file).pipe(
      switchMap((backupSetImport: Envelope<BackupSetImport>) => {
        return this.diffBackupSetImport(backupSetImport.data.id, options);
      }),
      catchError((error) => of({error, success: false, resultCode: '500'})),
    );
  }

  diffBackupSetImport(
    backupSetImportId: string,
    options?: BackupSetDiffOptions,
  ) {
    return this.diffBackupSet(backupSetImportId, options).pipe(
      switchMap((env) => {
        const diffs = env.data;
        return this.getBackupSetColumns(diffs).pipe(
          map((cols) => {
            return {
              ...env,
              header: {backupSetImportId},
              data: diffs.map((diff) => {
                return {
                  data: {
                    ...diff,
                  },
                  table: {
                    columns: [...cols],
                  },
                };
              }),
            } as Envelope<BackupSetDiffTable[]>;
          }),
        );
      }),
    );
  }

  exportBackupSet(backupSetId: string) {
    return this.export(backupSetId).pipe(filter((x) => x));
  }

  saveBackupSetFile(file, fileName: string) {
    FileSaver.saveAs(
      file,
      'backup-set-' + fileName + '-' + new Date().toISOString() + '.zip',
    );
  }

  getBackupSetColumns(backupSetDiffs: EntityDiff[]): Observable<TableColumn[]> {
    const listingTypeCodes = distinctArrays(
      null,
      backupSetDiffs.map((x) => x.listingType),
    );
    return this.commonApiService
      .getEntitiesByCodesSharedRequest(
        'listing-column/listingCode',
        listingTypeCodes,
      )
      .pipe(
        switchMap((env) => {
          if (env.success && env?.data?.length > 0) {
            return forkJoin<TableColumnConfig[]>(
              env.data.map((col) =>
                this.tableColumnConfigService.mapColumnConfigToTableColumn(col),
              ),
            );
          } else {
            return of([]);
          }
        }),
      );
  }

  private export(backupSetId: string): Observable<any> {
    return this.apiService.getRaw<any, any>(
      `${this.BASE_PATH}/${this.API_URL}/backup/${backupSetId}`,
      null,
      {observe: 'response', responseType: 'blob' as 'json'},
    );
  }

  /**
   * Bez options vrací seznam všech změn
   * @param backupSetImportId uuid pod kterym je ulozeny BS.zip po uploadu
   * @param options BackupSetDiffOptions
   **/
  private diffBackupSet(
    backupSetImportId: string,
    options?: BackupSetDiffOptions,
  ): Observable<Envelope<EntityDiff[], EntityDiff[]>> {
    const url = `${this.BASE_PATH}/${
      this.API_URL
    }/diff/${backupSetImportId}${this.getDiffOptionsQuerry(options)}`;
    return this.apiService
      .get<EntityDiff[], EntityDiff[]>(url)
      .pipe(catchError((err) => of(err)));
  }

  /**
   *
   * @param backupSetImportId id BackupSet importu
   * @param profileCode uloží jenom zvolený seznam profilů
   * @private
   */
  saveBackupSetImportDiff(backupSetImportId: string, data: DiffList[]) {
    const url = `${this.BASE_PATH}/${this.API_URL}/save-diff/${backupSetImportId}`;
    return this.apiService.post<any, any>(url, data);
  }

  private uploadBackupSet(
    file: any,
  ): Observable<Envelope<BackupSetImport, any>> {
    const url = `${this.BASE_PATH}/${this.API_URL}/upload`;
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService
      .post<BackupSetImport, any>(url, formData)
      .pipe(catchError((err) => of(err)));
  }

  private getDiffOptionsQuerry(options?: BackupSetDiffOptions): string {
    var querry = '?';
    if (options?.summaryOnly) {
      querry += 'summaryOnly=true&';
    }
    if (options?.profileCode) {
      querry += 'backupName=' + options.profileCode + '&';
    }
    if (options?.saveImmediately) {
      querry += 'save=true';
    }
    return querry.substring(0, querry.length - 1);
  }
}
