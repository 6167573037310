import {StoreModule} from '@ngrx/store';
import * as Reducers from './reducers';
import * as Services from './services';
import * as Effects from './effects';
import * as Pipes from './pipes';
import {EffectsModule} from '@ngrx/effects';
import {localizations} from './i18n';
import {TranslateModule} from '@tsm/framework/translate';
import {FrameworkPluginModule} from '@tsm/framework/plugin';
import {
  EvalScriptByIdDataSource,
  EvalScriptByCodeDataSource,
} from './datasources';

export const IMPORTS = [
  StoreModule.forFeature('scripting', Reducers.reducers),
  EffectsModule.forFeature([Effects.ScriptEffects]),
  TranslateModule.forFeature(localizations),
  FrameworkPluginModule.registerPlugin({
    menus: [],
    routes: [],
    widgets: [],
    dataSources: [
      {
        name: 'evalScriptById',
        initialValue: null,
        dataSource: EvalScriptByIdDataSource,
      },
      {
        name: 'evalScriptByCode',
        initialValue: null,
        dataSource: EvalScriptByCodeDataSource,
      },
    ],
  }),
];

export const PIPES = [Pipes.ScriptCodeLoaderPipe, Pipes.ScriptLoaderPipe];

export const PROVIDERS = [
  Services.AnsibleConsoleDataService,
  Services.CommonApiService,
  Services.GroovyConsoleDataService,
  Services.PythonScriptConsoleDataService,
  Services.ScriptDataService,
  Services.TqlConsoleDataService,
];
