import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DmsState} from '../reducers';
import {newCommentAdapter} from '../reducers/new-comment.reducer';

const getDmsState = createFeatureSelector<DmsState>('dms');
export const getNewCommentState = createSelector(
  getDmsState,
  (x) => x.newComment,
);

const {selectEntities} = newCommentAdapter.getSelectors(getNewCommentState);

export const selectNewComments = selectEntities;
