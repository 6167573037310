import {Injectable, Injector, PipeTransform, Type} from '@angular/core';

import * as FileSaver from 'file-saver';
import {FilterOperator, Table, TableColumn, TableType} from '../models';
import {ListingDataService} from './listing-data.service';
import {concatMap, forkJoin, from, isObservable, Observable, of} from 'rxjs';
import {filter, map, switchMap, take, timeoutWith} from 'rxjs/operators';
import {TranslocoService} from '@tsm/framework/translate';
import {Store} from '@ngrx/store';
import * as objectPath from 'object-path';
import {ExportingData, LoadListingTypeByCode} from '../actions';
import {selectListingTypeByCode} from '../selectors';
import {loadAndWaitForStore} from '@tsm/framework/root';
import {distinctArrays} from '@tsm/framework/functions';
import {DynamicComponentService} from '@tsm/framework/dynamic-component';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class DtlTableExporterService {
  private __xlsx = null;
  private readonly widthColumnConst = 3; // + 3 znaky ke kazdy velikosti sloupce

  constructor(
    private listingDataService: ListingDataService,
    private injector: Injector,
    private store: Store,
    private dynamicComponentService: DynamicComponentService,
    private translocoService: TranslocoService,
  ) {}

  getXlsX(): Promise<any> {
    if (this.__xlsx == null) {
      return new Observable((res) => {
        import('xlsx').then((x) => {
          this.__xlsx = x;
          res.next(this.__xlsx);
          res.complete();
        });
      }).toPromise();
    } else {
      return of(this.__xlsx).toPromise();
    }
  }

  exportByTql(table: Table, filename?: string) {
    if (table.type.queryLanguage === 'TQL') {
      this.store
        .select(selectListingTypeByCode(table.type.type))
        .pipe(map((x) => x?.data?.tqlName))
        .pipe(
          concatMap((tqlName) => {
            if (tqlName == null && !table.type.tqlName) {
              return loadAndWaitForStore(
                this.store,
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
              ).pipe(
                switchMap((x) => {
                  return this.listingDataService.exportByTql(
                    table,
                    x?.data?.tqlName,
                  );
                }),
              );
            }
            return this.listingDataService.exportByTql(
              table,
              tqlName || table.type.tqlName,
            );
          }),
          take(1),
        )
        .subscribe((x) => {
          if (x.status === 200) {
            const blob = new Blob([(x as any).body], {
              type: x.headers.get('Content-Type'),
            });
            FileSaver.saveAs(blob, (filename ?? 'export') + '.xlsx');
          }
          this.store.dispatch(ExportingData({id: table.id, value: false}));
        });
    }
  }

  exportFromTable(table: Table, filename: string) {
    this.getXlsX().then((xlsx) => {
      this.listingDataService
        .fetchData(
          {
            ...table,
            filters: distinctArrays('field', table.filters, [
              {
                field: table.defaults.rowIdField,
                operator: FilterOperator.in,
                value:
                  table.selectedRowIds?.length != 0 ? table.selectedRowIds : [],
              },
            ]),
            page: 1,
            pageSize: 50000,
          },
          table.type.url,
          // hack, aby export pro tree se choval stejne jako u tabulky
          {...table.tree, isActive: false},
          table.options,
        )
        .pipe(
          switchMap((x) =>
            this.innerExportFromTable([], {
              ...table,
              data: {
                items: x.content,
                totalRecords: x.content.length,
              },
            }),
          ),
        )
        .pipe(take(1))
        .subscribe((data) =>
          this.convertDataFromTable(data, table, filename, xlsx),
        );
    });
  }

  allDataFromTable(table: Table): Observable<any> {
    if (table.type.queryLanguage === 'TQL') {
      return this.store
        .select(selectListingTypeByCode(table.type.type))
        .pipe(map((x) => x?.data?.tqlName))
        .pipe(
          concatMap((tqlName) => {
            if (tqlName == null && !table.type.tqlName) {
              return loadAndWaitForStore(
                this.store,
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
              ).pipe(
                switchMap((x) => {
                  return this.listingDataService
                    .fetchDataTql(
                      {
                        ...table,
                        page: 1,
                        pageSize: 50000,
                      },
                      table.type.url,
                      // hack, aby export pro tree se choval stejne jako u tabulky
                      x?.data?.tqlName,
                      {...table.tree, isActive: false},
                      table.options,
                    )
                    .pipe(map((x) => x.content));
                }),
              );
            }
            return this.listingDataService
              .fetchDataTql(
                {
                  ...table,
                  page: 1,
                  pageSize: 50000,
                },
                table.type.url,
                // hack, aby export pro tree se choval stejne jako u tabulky
                tqlName || table.type.tqlName,
                {...table.tree, isActive: false},
                table.options,
              )
              .pipe(map((x) => x.content));
          }),
        );
    }
    return this.listingDataService
      .fetchData(
        {
          ...table,
          page: 1,
          pageSize: 50000,
        },
        `${table.type.url}`,
        // hack, aby export pro tree se choval stejne jako u tabulky
        {...table.tree, isActive: false},
        table.options,
      )
      .pipe(map((x) => x.content));
  }

  private chunk(arr: any[], len): any[][] {
    const chunks = [];
    let i = 0;
    const n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  private innerExportFromTable(
    selectedRows: any[],
    table: Table,
  ): Observable<any> {
    return this.getData(
      selectedRows.length > 0 ? selectedRows : table.data.items,
      table.columns,
      table.type,
      table.exportHeaders,
    );
  }

  private innerAllDataFromTable(
    selectedRows: any[],
    table: Table,
  ): Observable<any> {
    return this.getData(
      selectedRows.length > 0 ? selectedRows : table.data.items,
      table.columns,
      table.type,
      table.exportHeaders,
    ).pipe(
      map((data) =>
        this.chunk(
          data,
          table.columns.filter(
            (column) => column.visible === undefined || column.visible,
          ).length,
        ),
      ),
    );
  }

  private convertDataFromTable(
    values: any[],
    table: Table,
    filename: string,
    xlsx,
  ) {
    const vals = this.chunk(
      values,
      table.columns.filter(
        (column) =>
          (column.visible === undefined || column.visible) &&
          (column.exportDisabled === undefined || !column.exportDisabled),
      ).length,
    );
    const worksheet = xlsx.utils.json_to_sheet(vals);
    this.removeRow(worksheet, 0, xlsx);
    const widthTypeColumns = this.getWidthTypeColumns(vals);
    worksheet['!cols'] = widthTypeColumns.width;
    // this.changeTypeRow(worksheet, 1, widthTypeColumns.types, xlsx);
    // xlsx.utils.sheet_add_aoa(worksheet, [
    //   ["new data", 1, 2, 3, 4]
    // ], {origin: -1});

    const workbook = {
      Sheets: {data: worksheet},
      SheetNames: ['data'],
    };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, table.id, filename);
  }

  private saveAsExcelFile(
    buffer: any,
    tableId: string,
    fileName: string,
  ): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    this.store.dispatch(ExportingData({id: tableId, value: false}));
  }

  private getData(
    values: any[],
    columns: TableColumn[],
    tableType: TableType,
    exportHeaders?: any[],
  ): Observable<any[]> {
    const finalData: any[] = [];
    const visibleColumns: TableColumn[] = columns.filter(
      (column) =>
        (column.visible === undefined || column.visible) &&
        column.exportDisabled !== true,
    );

    if (exportHeaders) {
      exportHeaders.forEach((row) =>
        Array.isArray(row) ? finalData.push(row) : finalData.push([row]),
      );
    }
    finalData.push(
      visibleColumns.map((value) =>
        of(this.translocoService.translate(value.header)),
      ),
    );
    const rowData: Observable<any>[] = [];
    values.forEach((row) => {
      for (let i = 0; i < visibleColumns.length; i++) {
        rowData.push(
          this.getValue(
            tableType,
            row,
            // MOZNA BUDE PROBLEM PRO TQL?
            visibleColumns[i].exportField
              ? visibleColumns[i].exportField
              : visibleColumns[i].field,
            visibleColumns[i].converter,
            visibleColumns[i].converterParams,
            visibleColumns[i].exportConverter,
          ),
        );
      }
    });

    return forkJoin([...finalData[0], ...rowData]);
  }

  private getValue(
    tableType: TableType,
    rowData: any,
    field: string,
    converter: Type<PipeTransform> | Promise<Type<PipeTransform>>,
    converterParams: any[],
    exportConverter: (values: any, columns: string) => string,
  ): Observable<any> {
    const data = this.getColumnValue(tableType, field, rowData);
    if (Array.isArray(data) && data.filter((x) => !!x).length === 0) {
      // pokud se mi vrati pole a vsechny jeho hodnoty jsou null
      return of('');
    }
    if (exportConverter) {
      return of(exportConverter(rowData, field));
    } else if (converter) {
      return from(Promise.resolve(converter)).pipe(
        switchMap((converterWidget) => {
          const pipe = this.injector.get<PipeTransform>(converterWidget, null, {
            optional: true,
          });
          if (!pipe) {
            if (!(converterWidget as any)?.ɵpipe?.name) {
              throw new Error('ɵpipe does not exist!!');
            }
            // pokud pipu nedohledáme v injectoru, pokusíme se ji dynamicky načíst
            return from(
              this.dynamicComponentService.resolvePipeFactoryAndModule(
                (converterWidget as any).ɵpipe.name,
              ),
            ).pipe(
              switchMap((x) => {
                const newInjector =
                  x[1] == null
                    ? Injector.create({
                        providers: [x[0]],
                        parent: this.injector,
                      })
                    : x[1].injector;

                const pipe1 = newInjector.get(converterWidget);
                let result;
                if ((pipe1 as any).requiresRowData) {
                  result = pipe1.transform(
                    data,
                    ...(converterParams || ['emptyConverterParams']),
                    rowData,
                  );
                } else {
                  result = pipe1.transform(data, ...(converterParams || []));
                }

                const result$ = isObservable(result) ? result : of(result);
                return result$.pipe(
                  filter((val) => {
                    const regUUid =
                      /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                    return !regUUid.test(val as string);
                  }),
                  take(1),
                  timeoutWith(120000, of('N/A')),
                );
              }),
            );
          } else {
            let result;
            if ((pipe as any).requiresRowData) {
              result = pipe.transform(
                data,
                ...(converterParams || ['emptyConverterParams']),
                rowData,
              );
            } else {
              result = pipe.transform(data, ...(converterParams || []));
            }

            const result$ = isObservable(result) ? result : of(result);
            return result$.pipe(
              filter((val) => {
                const regUUid =
                  /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                return !regUUid.test(val as string);
              }),
              take(1),
              timeoutWith(120000, of('N/A')),
            );
          }
        }),
      );
    }
    return of(data);
  }

  private getColumnValue(tableType: TableType, field: string, rowData) {
    // strip vse za znakem # - pouziva se jako suffix pro unikatni field
    if (field.indexOf('#') !== -1) {
      field = field.substr(0, field.indexOf('#'));
    }

    return field === '*'
      ? rowData
      : tableType?.queryLanguage === 'TQL'
        ? objectPath.get(rowData, [field])
        : objectPath.get(rowData, field);
  }

  /**
   * Change type row
   * {WorkSheet} ws
   * {number} row_index - starts at 0
   * {types} types
   */
  private changeTypeRow(ws: any, row_index: number, types: string[], xlsx) {
    const variable = xlsx.utils.decode_range(ws['!ref']);
    for (let R = row_index; R <= variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        switch (types[C]) {
          case 'string':
            ws[xlsx.utils.encode_cell({r: R, c: C})].t = 's';
            break;
          case 'number':
            ws[xlsx.utils.encode_cell({r: R, c: C})].t = 'n';
            break;
          default:
            ws[xlsx.utils.encode_cell({r: R, c: C})].t = 's';
            return;
        }
      }
    }
    variable.e.r--;
  }

  /**
   * Remove row
   * {WorkSheet} ws
   * {number} row_index - starts at 0
   */
  private removeRow(ws: any, row_index: number, xlsx) {
    const variable = xlsx.utils.decode_range(ws['!ref']);
    for (let R = row_index; R < variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        ws[xlsx.utils.encode_cell({r: R, c: C})] =
          ws[xlsx.utils.encode_cell({r: R + 1, c: C})];
      }
    }
    variable.e.r--;
    ws['!ref'] = xlsx.utils.encode_range(variable.s, variable.e);
  }

  private getWidthTypeColumns(values: any[]): {width: any[]; types: string[]} {
    const widthColumns: {wch}[] = [];
    const widths: number[] = new Array<number>(values[0].length);
    const types: string[] = new Array<string>(values[0].length);
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        types[j] = typeof values[i][j];
        if (!widths[j] || (values[i][j] && widths[j] < values[i][j].length)) {
          widths[j] = values[i][j].length;
        }
      }
    }
    widths.forEach((item) =>
      widthColumns.push({wch: item + this.widthColumnConst}),
    );
    return {width: widthColumns, types: types};
  }
}
