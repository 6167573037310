import {NgModule} from '@angular/core';

import {HasAnyPrivDirective, HasPrivDirective} from './directives';
import {PrivilegeGuard} from './guards';

@NgModule({
  declarations: [HasPrivDirective, HasAnyPrivDirective],
  exports: [HasPrivDirective, HasAnyPrivDirective],
  providers: [PrivilegeGuard],
})
export class PrivilegeModule {}
