export const en = {
  characteristicsPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmSchemaLocationFilter: 'tSM Schema Location Filter',
      tsmSchemaLocationFilterDescription:
        'Filters locations based on schema definitions. ' +
        'Supports multiple selections and provides filtering ' +
        'options for hierarchical location structures.',
    },
    pipes: {
      characteristicsCode: 'Characteristics Code',
      characteristicsCodeDescription:
        'Retrieves and processes characteristics data based on a specific code. Dispatches actions to load ' +
        'characteristics if not already available in the store. Extracts and returns a specific field of ' +
        'the first characteristic or the entire object, depending on the specified field. Handles cases ' +
        'where no data is found gracefully.',
      characteristicsByCodes: 'Characteristics By Codes',
      characteristicsByCodesDescription:
        'Retrieves and processes characteristics data based on multiple codes. Dispatches an action to load ' +
        'characteristics if the data is not already present in the store. Returns specific fields or the ' +
        'complete set of characteristics for the provided codes. Handles cases with missing data gracefully, ' +
        'ensuring seamless integration with the store.',
      characteristics: 'Characteristics',
      characteristicsDescription:
        'Fetches and processes characteristic data by a given ID. Dispatches an action to load ' +
        'characteristics if the data is not already available in the store. Returns specific fields or the ' +
        'entire data object based on the provided field parameter. Ensures smooth integration with the store ' +
        'and handles cases where the data is unavailable.',
    },
  },
};
