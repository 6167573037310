import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormGroup,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import {JsonPointer} from 'tsm-json-schema-form-functions';
import {Observable, Subject} from 'rxjs';
import {
  fluentChangedSkipValues,
  fluentFilterSkipValues,
} from '../utils/fluent-filter-control.utils';
import {TsmAbstractControl} from './tsm-abstract-control';
import {TsmFormArray} from './tsm-form-array';
import {removeListItem} from '../utils';

/**
 * @deprecated TsmFormGroup is depcreated, please use TypedTsmFormGroup instead.
 */
export class TsmFormGroup<T = any> extends UntypedFormGroup {
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;
  private defaults = null;
  public default = false;
  public readonly = false;
  public filterMap: {[dataPointer: string]: any} = null;
  public readonly value: T;

  /** @internal */
  // eslint-disable-next-line @typescript-eslint/ban-types
  _onReadonly: Function[] = [];

  getDefaults() {
    return this.defaults;
  }

  constructor(
    controls: {
      [key: string]: AbstractControl;
    },
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    //     isFocusFirstEnabled = true
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    this.defaults = this.value;
    this.touchedChanges = this._touchedChanges.asObservable();
    //    this.isFocusFirstEnabled = isFocusFirstEnabled;
  }

  get(path: string | (string | number)[]): TsmAbstractControl | null {
    return super.get(path) as TsmAbstractControl | null;
  }

  /**
   * @param options - newDefault -> nasetuje value do defaultValue -> muzu pak zavolat resetWithDefaults a da mu to tam puvodni hodnoty
   */
  patchValue(
    value: Partial<T>,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    if (value) {
      if (options.newDefault) {
        this.setDefaultValueRecursively(value);
      }
      super.patchValue(value, options);
    }
  }

  setValue(
    value: T,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      newDefault?: boolean;
    } = {},
  ): void {
    if (options.newDefault) {
      this.setDefaultValueRecursively(value);
    }
    super.setValue(value, options);
  }

  resetWithDefaults(options?: {onlySelf?: boolean; emitEvent?: boolean}) {
    this.reset(this.defaults, options);
  }

  resetWithDefaultsRecursively(options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.resetWithDefaults(options);
      }
    }
  }

  resetNonDefaultValues(
    value?: any,
    options?: {onlySelf?: boolean; emitEvent?: boolean},
  ) {
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.resetNonDefaultValues(value != null ? value[key] : undefined, {
          onlySelf: true,
          emitEvent: options?.emitEvent,
        });
      }
    }
    this.updateValueAndValidity(options);
  }

  markAsPristineRecursively() {
    super.markAsPristine({onlySelf: true});
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.markAsPristineRecursively();
      }
    }
  }

  markAsDirtyRecursively() {
    super.markAsDirty({onlySelf: true});
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.markAsDirtyRecursively();
      }
    }
  }

  markAsTouchedRecursively(markAsDirty = false) {
    if (markAsDirty) {
      this.markAsDirty();
    }
    this.markAsTouched({onlySelf: true});
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.markAsTouchedRecursively(markAsDirty);
      }
    }
  }

  markAsDefaultRecursively() {
    this.default = true;
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        if (control) {
          control.markAsDefaultRecursively();
        }
      }
    }
  }

  markAsNonDefaultRecursively(value: any) {
    this.default = false;
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        const val = value ? value[key] : undefined;
        if (control && value != null && key in value) {
          control.markAsNonDefaultRecursively(val);
        }
      }
    }
  }

  markAsTouched(opts: {onlySelf?: boolean} = {}): void {
    this._touchedChanges?.next(true);
    super.markAsTouched(opts);
  }

  markAsUntouched(opts: {onlySelf?: boolean} = {}): void {
    this._touchedChanges.next(false);
    super.markAsUntouched(opts);
  }

  markAsUntouchedRecursively(markAsPristine = false) {
    this.markAsUntouched();
    if (markAsPristine) {
      this.markAsPristine();
    }
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;

        control.markAsUntouchedRecursively(markAsPristine);
      }
    }
  }

  updateValueAndValidityRecursively(
    opts: {onlySelf?: boolean; emitEvent?: boolean} = {},
  ) {
    this.updateValueAndValidity();
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;

        if (control.updateValueAndValidityRecursively) {
          control.updateValueAndValidityRecursively(opts);
        } else {
          control.updateValueAndValidity(opts);
        }
      }
    }
  }

  setDefaultValueRecursively(value: any) {
    this.defaults = value;
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key) && this.defaults[key]) {
        const control = this.controls[key] as any;
        control.setDefaultValueRecursively(this.defaults[key]);
      }
    }
  }

  setFilterMapRecursively(filterMap) {
    this.filterMap = filterMap;
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.setFilterMapRecursively(filterMap);
      }
    }
  }

  /** @internal */
  _forEachChild(cb: (v: any, k: string) => void): void {
    Object.keys(this.controls).forEach((key) => {
      // The list of controls can change (for ex. controls might be removed) while the loop
      // is running (as a result of invoking Forms API in `valueChanges` subscription), so we
      // have to null check before invoking the callback.
      const control = this.controls[key];
      control && cb(control, key);
    });
  }

  /** @internal */
  _reduceChildren(initValue: any, fn: Function) {
    let res = initValue;
    this._forEachChild((control: AbstractControl, name: string) => {
      res = fn(res, control, name);
    });
    return res;
  }

  getFilteredValue(): T {
    const filtered = this._reduceChildren(
      {},
      (
        acc: {[k: string]: TsmAbstractControl},
        control: TsmAbstractControl,
        name: string,
      ) => {
        acc[name] = control.getFilteredValue();
        return acc;
      },
    );
    return fluentFilterSkipValues(filtered);
  }

  getChangedValue(): T {
    const filtered = this._reduceChildren(
      {},
      (
        acc: {[k: string]: TsmAbstractControl},
        control: TsmAbstractControl,
        name: string,
      ) => {
        // form array musi vratit vsechno
        if (control instanceof TsmFormArray) {
          const val: any[] = (
            (control as unknown as TsmFormArray<any>).value as any[]
          ).filter((x) =>
            typeof x === 'object' && x != null
              ? Object.keys(x).length > 0
              : true,
          );
          const fil: any[] = (
            (
              control as unknown as TsmFormArray<any>
            ).getFilteredValue() as any[]
          ).filter((x) =>
            typeof x === 'object' && x != null
              ? Object.keys(x).length > 0
              : true,
          );

          let repetition = 1;
          for (let i = 0; i < repetition; i++) {
            JsonPointer.forEachDeep(fil, (v, p) => {
              if (
                p !== '' &&
                ((Array.isArray(v) && v.length === 0) ||
                  (v != null &&
                    typeof v === 'object' &&
                    Object.keys(v).length === 0))
              ) {
                JsonPointer.remove(fil, p);
                repetition += 1;
              }
            });
          }

          if (val.length === 0) {
            acc[name] = val as any;
          } else if (
            val.length > 0 &&
            fil.length > 0 &&
            !fil.includes('__SKIP__')
          ) {
            acc[name] = fil as any;
          }
        } else {
          acc[name] = control.getChangedValue();
        }
        return acc;
      },
    );
    return fluentChangedSkipValues(filtered);
  }

  setReadonly(readonly: boolean) {
    this.readonly = readonly;
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.setReadonly(readonly);
      }
    }
    if (this._onReadonly.length) {
      this._onReadonly.forEach((changeFn) => changeFn(this.readonly));
    }
  }

  registerOnReadonly(fn: Function): void {
    this._onReadonly.push(fn);
  }

  /** @internal */
  _unregisterOnReadonly(
    fn: (readonly?: any, emitModelEvent?: boolean) => void,
  ): void {
    removeListItem(this._onReadonly, fn);
  }
}
