export const cs = {
  ticketsPlugins: {
    ticketsSubmitHelp: `
        Konfigurace:

        {
            "disableValidations": true, // vypne validace formuláře
            "scriptCode": "codeSpelScript", // kód SPEL skriptu
            "ngrxAction": "[Core] Navigate", // NGRX akce vykonaná po odeslání formuláře
            "ngrxActionData": {} // nepovinné parametry pro specifikovanou akci
        }

        Všechny parametry jsou nepovinné.
        Pokud je vyplněn skript, vykoná se NGRX akce po dokončení skriptu (má k dispozici data vrácená skriptem), pokud skript vyplněn není, vykoná se ihned po stisku tlačítka.

        *[Core] Navigate - tato akce očekává url parametr jako odpověď ze SPEL skriptu
      `,
    filters: {
      tsmTicketCategoryFilter: 'tSM Ticket Category Filter',
      tsmTicketCategoryFilterDescription:
        'Implementuje filtr kategorií tiketů s podporou vícenásobného výběru. ' +
        'Rozšiřuje komponentu filtru tabulky a umožňuje uživatelům filtrovat ' +
        'kategorie tiketů dynamicky načítané z API. ' +
        'Zobrazuje dostupné kategorie v tabulce s funkcí vyhledávání. ' +
        'Umožňuje odstranění a potvrzení výběru prostřednictvím překryvného panelu.',
      tsmTicketChannelFilter: 'tSM Ticket Channel Filter',
      tsmTicketChannelFilterDescription:
        'Implementuje filtr kanálů tiketů s podporou vícenásobného výběru. ' +
        'Rozšiřuje komponentu filtru tabulky a umožňuje uživatelům filtrovat ' +
        'kanály tiketů dynamicky načítané z API. ' +
        'Zobrazuje dostupné kanály v tabulce s funkcí vyhledávání. ' +
        'Umožňuje odstranění a potvrzení výběru prostřednictvím překryvného panelu.',
      tsmTicketLinkTypeFilter: 'tSM Ticket Link Type Filter',
      tsmTicketLinkTypeFilterDescription:
        'Implementuje filtr typů odkazů tiketů s podporou vícenásobného výběru. ' +
        'Načítá typy odkazů ze store a filtruje neplatné položky. ' +
        'Zobrazuje dostupné typy odkazů v tabulce s možností výběru. ' +
        'Používá operátor filtru s předponou pro porovnání vybraných hodnot. ' +
        'Zajišťuje unikátnost typů odkazů odstraněním duplicit.',
      tsmTicketPriorityFilter: 'tSM Ticket Priority Filter',
      tsmTicketPriorityFilterDescription:
        'Implementuje filtr priorit tiketů s možností vícenásobného výběru. ' +
        'Načítá priority ze store a aplikuje předdefinované filtry. ' +
        'Zobrazuje dostupné priority v tabulce s režimem výběru. ' +
        'Podporuje externí podmínky filtrování pomocí vstupu "context". ' +
        'Zajišťuje automatické filtrování a aktualizaci výběru podle hodnot contextu.',
      tsmTicketProblemTreeFilter: 'tSM Ticket Problem Tree Filter',
      tsmTicketProblemTreeFilterDescription:
        'Implementuje hierarchický stromový filtr problémů pro tikety. ' +
        'Zobrazuje stromovou strukturu, ve které lze vybrat více problémů. ' +
        'Načítá data problémů ze serveru a vytváří hierarchii stromu. ' +
        'Filtruje volitelné možnosti na základě podmínek kontextu. ' +
        'Zajišťuje, že vybrané položky jsou aplikovány a odeslány při potvrzení.',
      tsmTicketRelatedPartyRoleFilter: 'tSM Ticket Related Party Role Filter',
      tsmTicketRelatedPartyRoleFilterDescription:
        'Implementuje filtr pro výběr rolí souvisejících stran v tiketech. ' +
        'Zobrazuje tabulku s možností vícenásobného výběru rolí. ' +
        'Načítá data rolí ze serveru a aplikuje předdefinované filtry. ' +
        'Používá asynchronní pipes pro dynamické získání názvů rolí. ' +
        'Zajišťuje, že vybrané role jsou aplikovány a odeslány při potvrzení.',
      tsmTicketRelatedPartyTypeFilter: 'tSM Ticket Related Party Type Filter',
      tsmTicketRelatedPartyTypeFilterDescription:
        'Implementuje filtr pro výběr typů souvisejících stran v tiketech. ' +
        'Zobrazuje tabulku s možností vícenásobného výběru a filtrování. ' +
        'Načítá data typů souvisejících stran ze serveru. ' +
        'Dynamicky získává názvy typů pomocí asynchronních pipe. ' +
        'Aplikuje a odesílá vybrané hodnoty při potvrzení.',
      tsmTicketSeverityFilter: 'tSM Ticket Severity Filter',
      tsmTicketSeverityFilterDescription:
        'Implementuje filtr pro výběr úrovní závažnosti tiketů. ' +
        'Zobrazuje pohled s možností vícenásobného výběru a filtrování. ' +
        'Načítá data o závažnosti ze serveru a dynamicky aplikuje filtry. ' +
        'Podporuje filtrování na základě kontextu s předdefinovanými hodnotami. ' +
        'Aplikuje a odesílá vybrané hodnoty při potvrzení.',
      tsmTicketStatusFilter: 'tSM Ticket Status Filter',
      tsmTicketStatusFilterDescription:
        'Implementuje filtr pro výběr stavů tiketů. ' +
        'Zobrazuje pohled s možností vícenásobného výběru a filtrování. ' +
        'Podporuje jednoduché i komplexní kontexty filtrování s předdefinovanými poli. ' +
        'Načítá data o stavech tiketů ze serveru a dynamicky aplikuje filtry. ' +
        'Používá optimalizovaný přístup k práci s filtračními operátory a poli. ' +
        'Aplikuje a odesílá vybrané hodnoty při potvrzení.',
      tsmTicketTypeCodeFilter: 'tSM Ticket Type Code Filter',
      tsmTicketTypeCodeFilterDescription:
        'Poskytuje komponentu filtru pro výběr typů tiketů podle kódu. ' +
        'Zobrazuje pohled s možností vícenásobného výběru, filtrování a řazení. ' +
        'Dynamicky aplikuje filtrování na základě poskytnutého kontextu modulu. ' +
        'Načítá data o typech tiketů ze serveru a aplikuje předdefinované filtry. ' +
        'Používá strukturovanou konfiguraci tabulek, včetně definice sloupců a řazení. ' +
        'Aplikuje a odesílá vybrané hodnoty při potvrzení.',
      tsmTicketTypeFilter: 'tSM Ticket Type Filter',
      tsmTicketTypeFilterDescription:
        'Poskytuje komponentu filtru pro výběr typů tiketů podle ID. ' +
        'Zobrazuje pohled s možností vícenásobného výběru, filtrování a řazení. ' +
        'Dynamicky aplikuje filtrování na základě poskytnutého kontextu modulu. ' +
        'Načítá data o typech tiketů ze serveru a aplikuje předdefinované filtry. ' +
        'Používá strukturovanou konfiguraci tabulek, včetně definice sloupců a řazení. ' +
        'Aplikuje a odesílá vybrané hodnoty při potvrzení.',
    },
    pipes: {
      adviceIcon: 'Advice Icon',
      adviceIconDescription:
        'Zobrazuje ikonu rady s přeloženým tooltipem na základě vstupní hodnoty. ' +
        'Používá Transloco pro lokalizaci.',
      channelByCode: 'Channel By Code',
      channelByCodeDescription:
        'Získává komunikační kanál ticketu podle jeho kódu a vrací požadovanou hodnotu pole. ' +
        'Pokud kanál není dostupný, inicializuje požadavek na jeho načtení. ' +
        'Podporuje přístup k vnořeným polím pomocí tečkové notace.',
      channelById: 'Channel By Id',
      channelByIdDescription:
        'Načítá a získává komunikační kanál ticketu podle jeho ID, vrací požadovanou hodnotu pole. ' +
        'Pokud požadovaný kanál není dostupný, spustí se požadavek na jeho načtení. ' +
        'Podporuje přístup k vnořeným vlastnostem pomocí tečkové notace.',
      jsonActiveTasks: 'Json Active Tasks',
      jsonActiveTasksDescription:
        'Parsuje a extrahuje detaily aktivních úkolů z JSON řetězce, ' +
        'konvertuje datumová pole do lokalizovaného formátu. ' +
        'Vrací unikátní hodnoty jako čárkou oddělený řetězec.',
      localization: 'Localization',
      localizationDescription:
        'Dynamicky překládá lokalizovaný objekt na základě aktivního jazyka, ' +
        'vrací odpovídající řetězec pro zvolený jazyk.',
      priorityByCode: 'Priority By Code',
      priorityByCodeDescription:
        'Načítá a získává prioritu podle jejího jedinečného kódu, ' +
        'umožňuje přístup ke konkrétním polím nebo celému objektu priority. ' +
        'Automaticky odesílá akci, pokud priorita není nalezena ve store.',
      priorityById: 'Priority By Id',
      priorityByIdDescription:
        'Získává prioritu tiketu podle jeho jedinečného identifikátoru, ' +
        'umožňuje přístup ke konkrétnímu poli nebo celému objektu priority. ' +
        'Automaticky odesílá akci, pokud priorita není nalezena ve store.',
      problemTypeByCode: 'Problem Type By Code',
      problemTypeByCodeDescription:
        'Načítá typ problému tiketu podle jeho jedinečného kódu, umožňuje ' +
        'přístup ke konkrétnímu poli nebo celému objektu typu problému. ' +
        'Spouští akci store, pokud typ problému není již načten.',
      problemTypeLoader: 'Problem Type Loader',
      problemTypeLoaderDescription:
        'Načítá typ problému tiketu pomocí jeho jedinečného ID, umožňuje ' +
        'přístup ke konkrétnímu poli nebo celému objektu typu problému. ' +
        'Spouští akci pro načtení typu problému, pokud není nalezen ve store.',
      relatedEntityByIdNameIcon: 'Related Entity By Id Name Icon',
      relatedEntityByIdNameIconDescription:
        'Načítá související entitu pomocí jejího jedinečného ID a zobrazuje její ' +
        'název s volitelnou ikonou, pokud je dostupná. Pokud entita obsahuje ' +
        'aktivní proces (dekompozici), je přidán indikátor načítání pro znázornění ' +
        'probíhající operace. Spouští akci pro načtení entity, pokud není nalezena ve store.',
      relatedEntityById: 'Related Entity By Id',
      relatedEntityByIdDescription:
        'Načte související entitu podle jejího jedinečného ID pomocí API služby ' +
        'a vrátí stream dat obsahující informace o entitě. Pokud je zadané ID ' +
        'null, vrátí prázdný stream, aby se předešlo zbytečným požadavkům.',
      relatedEntityNameIcon: 'Related Entity Name Icon',
      relatedEntityNameIconDescription:
        'Převede související entitu na formátovaný název s ikonou. Načítá detaily ' +
        'entity z úložiště a zobrazí je jako HTML prvek s ikonou.',
      relatedEntityOfTicket: 'Related Entity Of Ticket',
      relatedEntityOfTicketDescription:
        'Načte detaily související entity pro konkrétní tiket. Získává entitu ' +
        'z úložiště a extrahuje požadované pole nebo celou entitu.',
      relatedEntityParentName: 'Related Entity Parent Name',
      relatedEntityParentNameDescription:
        'Získává název nadřazené entity ze seznamu souvisejících entit. Vyhledá ' +
        'první platnou entitu a načte její detaily z úložiště.',
      relatedEntityShortName: 'Related Entity Short Name',
      relatedEntityShortNameDescription:
        'Získává krátký název související entity filtrováním podle ' +
        'ID tiketu a místa. Načítá data z úložiště a používá ' +
        'předdefinované filtry pro získání entity.',
      relatedEntityTypeByCode: 'Related Entity Type By Code',
      relatedEntityTypeByCodeDescription:
        'Získává podrobnosti o typu související entity podle kódu a ' +
        'načítá chybějící data, pokud je to nutné. Extrahuje konkrétní ' +
        'pole nebo vrací celý objekt typu entity.',
      relatedEntityTypeIcon: 'Related Entity Type Icon',
      relatedEntityTypeIconDescription:
        'Získává ikonu a název pro typ související entity podle kódu. ' +
        'Zobrazuje ikonu, pokud je dostupná, jinak vrací pouze název.',
      relatedPartyOwner: 'Related Party Owner',
      relatedPartyOwnerDescription:
        'Získává a formátuje název vlastníka související strany. ' +
        'Filtruje podle typu vlastníka a načítá detaily z úložiště, pokud chybí.',
      relatedPartyParticipants: 'Related Party Participants',
      relatedPartyParticipantsDescription:
        'Zpracovává seznam účastníků související strany a formátuje je ' +
        'do řetězce. Podporuje filtrování podle specifických polí ' +
        'nebo agregaci všech dat účastníků do strukturovaného formátu.',
      relatedPartyRef: 'Related Party Ref',
      relatedPartyRefDescription:
        'Vyhodnocuje odkaz na související stranu a načítá související ' +
        'detaily uživatele nebo uživatelské skupiny. Používá ukládání do ' +
        'mezipaměti a lazy loading pro efektivní načítání informací ' +
        'podle typu a ID entity.',
      relatedPartyRoleByCodePipe: 'Related Party Role By Code Pipe',
      relatedPartyRoleByCodePipeDescription:
        'Načítá podrobnosti o roli související strany na základě jejího kódu ' +
        'a získává specifická pole nebo kompletní data role. Využívá lazy loading ' +
        'a ukládání do mezipaměti pro optimalizaci dotazů do úložiště.',
      relatedPartyRoleByIdPipe: 'Related Party Role By Id Pipe',
      relatedPartyRoleByIdPipeDescription:
        'Načítá podrobnosti o roli související strany podle jejího ID ' +
        'a poskytuje buď specifická pole nebo celý objekt role. ' +
        'Používá lazy loading a ukládání do mezipaměti pro efektivní načítání dat.',
      relatedPartyTypeCode: 'Related Party Type Code',
      relatedPartyTypeCodeDescription:
        'Načítá podrobnosti o typu související strany na základě jeho kódu ' +
        'a vrací buď specifické pole nebo celý objekt typu. ' +
        'Používá lazy loading a ukládání do mezipaměti pro efektivní načítání dat.',
      relatedPartyTypeId: 'Related Party Type Id',
      relatedPartyTypeIdDescription:
        'Získává podrobnosti o typu související strany pomocí jeho unikátního ID, ' +
        'vrací buď specifické pole nebo celý objekt. ' +
        'Používá lazy loading a ukládání do mezipaměti pro efektivní přístup k datům.',
      relatedPartyWithRef: 'Related Party With Ref',
      relatedPartyWithRefDescription:
        'Načítá podrobnosti o související straně a řeší odkazy na propojené entity. ' +
        'Zajišťuje správné načtení a transformaci odkazovaných dat.',
      relatedParty: 'Related Party',
      relatedPartyDescription:
        'Získává podrobnosti o související straně podle jejího ID a poskytuje ' +
        'konkrétní pole nebo celou entitu. Zajišťuje načtení chybějících dat podle potřeby.',
      relationshipDirection: 'Relationship Direction',
      relationshipDirectionDescription:
        'Určuje směr vztahu mezi souvisejícími tikety na základě odpovídajících ' +
        'typů odkazů a jejich názvů. Používá data z úložiště k načtení ' +
        'podrobností o typu odkazu a formátování výstupu.',
      resolutionTypeByCode: 'Resolution Type By Code',
      resolutionTypeByCodeDescription:
        'Získává podrobnosti o typu uzavření tiketu pomocí poskytnutého kódu uzavření. ' +
        'Načítá data o uzavření z úložiště a extrahuje určené pole, ' +
        'nebo vrací všechna data o uzavření, pokud pole není zadáno.',
      resolutionTypeLoader: 'Resolution Type Loader',
      resolutionTypeLoaderDescription:
        'Získává podrobnosti o uzavření tiketu pomocí daného ID uzavření. ' +
        'Načítá data o uzavření z úložiště a extrahuje určené pole, ' +
        'nebo vrací všechna data o uzavření, pokud pole není zadáno.',
      ropAddress: 'Rop Address',
      ropAddressDescription:
        'Získává formátovanou adresu nebo podrobnosti o objektu na základě zadaného ID. ' +
        'Určuje typ entity (lokalita, objekt, pracoviště nebo prostor) ' +
        'a načítá data z odpovídajícího API.',
      severityByCode: 'Severity By Code',
      severityByCodeDescription:
        'Načítá podrobnosti o závažnosti na základě poskytnutého kódu závažnosti. ' +
        'Načítá data o závažnosti z úložiště a vrací požadovanou hodnotu pole.',
      severityIcon: 'Severity Icon',
      severityIconDescription:
        'Získává ikonu závažnosti a podrobnosti na základě poskytnutého ID závažnosti. ' +
        'Načítá data o závažnosti z úložiště a vrací buď všechna data ' +
        'nebo konkrétní pole s ikonou, pokud je k dispozici.',
      statusByCode: 'Status By Code',
      statusByCodeDescription:
        'Získává stav tiketu na základě poskytnutého kódu stavu. ' +
        'Načítá data o stavu z úložiště a vrací buď všechna data ' +
        'nebo konkrétní pole, pokud je určeno.',
      statusIconByCode: 'Status Icon By Code',
      statusIconByCodeDescription:
        'Zobrazuje stav tiketu jako formátovaný štítek s barevným pozadím. ' +
        'Načítá stav z úložiště a aplikuje lokalizaci podle aktuálního ' +
        'jazykového nastavení.',
      statusIcon: 'Status Icon',
      statusIconDescription:
        'Zobrazuje stav tiketu jako formátovaný štítek s ikonou a ' +
        'barevným pozadím. Načítá stav z úložiště a aplikuje lokalizaci ' +
        'podle aktuálního jazykového nastavení.',
      tabs: 'Tabs',
      tabsDescription:
        'Převádí pole hodnot výčtového typu na formátovaný řetězec. ' +
        'Používá EnumToValuePipe pro mapování každé hodnoty, volitelně' +
        'přidává prefix před jejich spojením do jednoho výstupu.',
      ticketCategoryCode: 'Ticket Category Code',
      ticketCategoryCodeDescription:
        'Získává kategorii tiketu podle jejího kódu a vrací požadované pole. ' +
        'Načítá data z úložiště a automaticky odesílá akci, ' +
        'pokud kategorie ještě není načtena.',
      ticketCategory: 'Ticket Category',
      ticketCategoryDescription:
        'Získává kategorii tiketu podle jejího ID a vrací požadované pole. ' +
        'Načítá data z úložiště a odesílá akci, pokud kategorie ' +
        'ještě není dostupná.',
      ticketId: 'Ticket Id',
      ticketIdDescription:
        'Načítá tiket podle jeho ID a extrahuje zadané pole. ' +
        'Pokud tiket chybí, odešle akci pro jeho načtení ' +
        'z úložiště.',
      ticketKey: 'Ticket Key',
      ticketKeyDescription:
        'Načítá tiket podle jeho klíče a extrahuje zadané pole. ' +
        'Pokud tiket chybí, odešle akci pro jeho načtení ' +
        'z úložiště.',
      ticketLink: 'Ticket Link',
      ticketLinkDescription:
        'Ověří, zda existuje odkaz na tiket s daným typem. ' +
        'Pokud je nalezen, vrátí ikonu potvrzení, jinak prázdný řetězec.',
      ticketRefIdPipe: 'Ticket Ref Id Pipe',
      ticketRefIdPipeDescription:
        'Načítá související reference tiketů podle referenčního ID, ' +
        'odfiltruje dokončené. Vrací detaily tiketu nebo hodnotu konkrétního pole.',
      ticketStatusId: 'Ticket Status Id',
      ticketStatusIdDescription:
        'Získává stav tiketu podle ID ze store. Spouští akci načtení, ' +
        'pokud stavová data nejsou dostupná.',
      ticketTaskLink: 'Ticket Task Link',
      ticketTaskLinkDescription:
        'Generuje hypertextový odkaz na úkol spojený s tiketem. ' +
        'Používá detaily instance procesu k vytvoření odkazu.',
      ticketTypeByCode: 'Ticket Type By Code',
      ticketTypeByCodeDescription:
        'Získává detaily typu tiketu na základě daného kódu. ' +
        'Načítá data z úložiště a dynamicky je aktualizuje.',
      ticketTypeById: 'Ticket Type By Id',
      ticketTypeByIdDescription:
        'Získává detaily typu tiketu na základě daného ID. ' +
        'Dynamicky načítá a aktualizuje data z úložiště.',
      ticketTypeSla: 'Ticket Type Sla',
      ticketTypeSlaDescription:
        'Určuje, zda má typ tiketu aktivované SLA. ' +
        'Načítá detaily typu tiketu a aplikuje přepis logických hodnot.',
      typeIcon: 'Type Icon',
      typeIconDescription:
        'Převádí identifikátor typu tiketu na HTML formátovaný název s ikonou. ' +
        'Načítá detaily typu z registru a formátuje je jako ikonu s textem.',
      userUserGroupRef: 'User User Group Ref',
      userUserGroupRefDescription:
        'Rozpoznává odkaz na uživatele nebo skupinu uživatelů a načítá jejich detaily. ' +
        'Načítá data podle typu reference a ID a poskytuje formátovaný výstup.',
    },
  },
};
