import {Injectable} from '@angular/core';
import {ConfigService} from '@tsm/framework/config';
import {ApiService} from '@tsm/framework/http';
import {Config} from '@tsm/framework/config';
import {PythonRequestModel, PythonResultModel} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PythonScriptConsoleDataService {
  private readonly BASE_URL: string;

  constructor(
    private config: ConfigService<Config>,
    private apiService: ApiService,
  ) {
    this.BASE_URL = config.value.apiUrls.networkAutomation;
  }

  runConsole(body: PythonRequestModel) {
    return this.apiService.post<PythonResultModel, PythonResultModel>(
      `${this.BASE_URL}/execute`,
      body,
    );
  }
}
