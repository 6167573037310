import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectMnemonicConfigByCode} from '../selectors';
import {map} from 'rxjs/operators';
import {LoadMnemonicConfigByCode} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'mnemonicConfigCode',
  pure: true,
})
export class MnemonicConfigCodePipe extends AbstractCachedLoaderPipe {
  constructor(protected store: Store<any>) {
    super(store);
  }

  setObservable(id: string, field = 'name') {
    this.destroy();
    this._id = id;

    this._obs$ = this.store.pipe(
      select(selectMnemonicConfigByCode(id)),
      map((it) => {
        if (!it || !it.data) {
          return id;
        } else {
          return field === 'all' ? it.data : it.data[field];
        }
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(select(selectMnemonicConfigByCode(this._id)))
      .subscribe((x) => {
        if (!x) {
          untracked(() =>
            this.store.dispatch(LoadMnemonicConfigByCode({code: this._id})),
          );
        }
      });
  }
}
