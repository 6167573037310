import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  EntityDiff,
  FilterModel,
  FilterOperator,
  GridDataHttpModel,
  Table,
  TableColumn,
  TableRestore,
  TableType,
  TreeTableParams,
} from '../models';
import {concatMap, from, Observable, of} from 'rxjs';
import {PageSortFilterService} from './page-sort-filter.service';
import {catchError, map, take, tap} from 'rxjs/operators';
import {loadAndWait, SharedRequestValidUntil} from '@tsm/framework/root';
import {PageSortFilterTqlService} from './page-sort-filter-tql.service';
import {selectListingTypeByCode} from '../selectors';
import {LoadListingTypeByCode} from '../actions';
import {Store} from '@ngrx/store';
import {TranslocoService} from '@tsm/framework/translate';
import * as objectPath from 'object-path';
import {ApiService} from '@tsm/framework/http';
import {distinctArrays} from '@tsm/framework/functions';

@Injectable({
  providedIn: 'root',
})
export class ListingDataService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private store: Store,
    private translocoService: TranslocoService,
    private pageSortFilterService: PageSortFilterService,
    private pageSortFilterTqlService: PageSortFilterTqlService,
  ) {}

  fetchData<T>(
    tableModel: Table<T>,
    url: string,
    tree?: TreeTableParams,
    options?: string,
    throwError = false,
  ): Observable<GridDataHttpModel<T>> {
    const queryString = this.pageSortFilterService.getPagingSortFilterUrl(
      tableModel,
      tree,
      options,
    );
    return this.http
      .get(url + '?' + queryString)
      .pipe(catchError((err) => of(throwError ? err : null)));
  }

  fetchDataTql<T>(
    tableModel: Table<T>,
    url: string,
    tqlName: string,
    tree?: TreeTableParams,
    options?: string,
    throwError = false,
  ): Observable<GridDataHttpModel<T>> {
    const queryString = this.pageSortFilterTqlService.getPagingSortFilterUrl(
      false,
      tableModel,
      tqlName,
      tree,
      options,
    );
    let params = {
      language: this.translocoService.getActiveLang(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...(tableModel.type.queryParams || {}),
    };
    return this.http
      .post(url, {query: queryString, params: params})
      .pipe(catchError((err) => of(throwError ? err : null)));
  }

  @SharedRequestValidUntil()
  fetchAllDataForHistory<T>(
    url: string,
    ids: string[],
    columns: TableColumn[],
    options?: string,
  ): Observable<GridDataHttpModel<T>> {
    const queryString =
      this.pageSortFilterService.getPagingSortFilterUrlForHistory(
        columns,
        ids,
        options,
      );
    return this.http
      .get(url + '?' + queryString)
      .pipe(catchError((err) => of(err)));
  }

  getValueByFiled(
    value: any,
    field: string,
    table: Table,
    filterFields: string[] = [],
    defaultFilters: FilterModel[] = [],
  ): Observable<any> {
    // z duvodu casove zavisloti... nekdy table nema nastavene filtry na zaklade defaultFilters a pak ten dotaz je spatne
    const mergeFilters = distinctArrays('field', defaultFilters, table.filters);
    const filteredFilters = mergeFilters.filter((x) =>
      filterFields.includes(x.field),
    );
    const filtersString =
      filteredFilters.length > 0
        ? '&' +
          this.pageSortFilterService.getUrlFilterFromFilterModels(
            filteredFilters,
            table.columns,
          )
        : '';
    return this.http
      .get(
        table.type.url +
          '?' +
          (['all', 'custom'].includes(field) ? 'id' : field) +
          '__eq=' +
          (['all', 'custom'].includes(field) ? value?.id : value) +
          filtersString,
      )
      .pipe(
        take(1),
        map((x) => {
          let data = [];
          // filtering-list endpoint
          if (Array.isArray(x) && x.length > 0) {
            data = x;
            // filtering (elastic) endpoint
          } else if (
            x['content'] &&
            Array.isArray(x['content']) &&
            x['content'].length > 0
          ) {
            data = x['content'];
          }
          if (data.length === 1) {
            return data[0];
          }
          if (data.length > 1) {
            console.warn('More than one result for value: ', data);
            return data[0];
          } else {
            return null;
          }
        }),
      );
  }

  getValueByFiledTql(
    value: any,
    field: string,
    table: Table,
    filterFields: string[] = [],
    defaultFilters: FilterModel[] = [],
  ): Observable<any> {
    const url = table.type.url;
    // z duvodu casove zavisloti... nekdy table nema nastavene filtry na zaklade defaultFilters a pak ten dotaz je spatne
    const mergeFilters = distinctArrays('field', defaultFilters, table.filters);
    let nestedFilters = this.pageSortFilterTqlService.nestedFilters(
      table.columns,
      mergeFilters,
    );
    let urlColumns =
      this.pageSortFilterTqlService.getUrlColumns(
        false,
        table.dataViewMode,
        table.columns,
        nestedFilters,
      ) + ',id ';
    let urlWhere = '';
    if (!!value) {
      const nestedColumnBySelectProperty = table.columns.find(
        (x) => x.field == field && !!x.nested,
      );
      if (!!nestedColumnBySelectProperty) {
        urlWhere = `where ${nestedColumnBySelectProperty.nested}[
        ${['all', 'custom'].includes(field) ? 'id' : field} = '${
          ['all', 'custom'].includes(field) ? value?.id : value
        }']`;
      } else {
        urlWhere = `where ${
          ['all', 'custom'].includes(field) ? 'id' : field
        } = '${['all', 'custom'].includes(field) ? value?.id : value}'`;
      }
      const filteredFilters = mergeFilters.filter((x) =>
        filterFields.includes(x.field),
      );
      if (filteredFilters.length > 0) {
        urlWhere +=
          ' and ' +
          this.pageSortFilterTqlService
            .getUrlWhere(filteredFilters, table.columns, nestedFilters)
            .replace('where ', '');
      }
    } else {
      return of(null);
    }
    return this.store
      .select(selectListingTypeByCode(table.type.type))
      .pipe(map((x) => x?.data?.tqlName))
      .pipe(
        concatMap((tqlName) => {
          if (tqlName == null && !table.type.tqlName) {
            return this.store.pipe(
              loadAndWait(
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
                true,
              ),
              map((x) => x?.data?.tqlName),
            );
          }
          return of(tqlName || table.type.tqlName);
        }),
        concatMap((tqlName) => {
          const queryString = `select ${urlColumns}
                                     from ${
                                       tqlName || table.type.tqlName
                                     } ${urlWhere} track total`;
          return this.getDataByValueFiledTql(url, queryString);
        }),
      );
  }

  checkObjectAgainstFilters(obj, filters: FilterModel[], type: TableType) {
    return filters.every((x) => {
      const hasField = objectPath.has(
        obj,
        type.queryLanguage == 'TQL' ? [x.field] : x.field,
      );
      const value = objectPath.get(
        obj,
        type.queryLanguage == 'TQL' ? [x.field] : x.field,
        null,
      );
      if (!hasField) {
        console.warn(
          'checkSelectedItemByFilters: ' + x.field + ' is not on object: ',
          obj,
        );
        return true;
      }
      if (Array.isArray(value)) {
        if (x.operator == FilterOperator.notin) {
          return !value.some((y) => y == x.value);
        } else {
          return value.some((y) => y == x.value);
        }
      } else {
        if (x.operator == FilterOperator.contains) {
          return value == null ? false : value.includes(x.value);
        } else if (x.operator == FilterOperator.notcontains) {
          return value == null ? false : !value.includes(x.value);
        } else if (x.operator == FilterOperator.noteq) {
          return value != x.value;
        } else {
          return value == x.value;
        }
      }
    });
  }

  @SharedRequestValidUntil(60)
  private getDataByValueFiledTql(url, queryString) {
    return this.http
      .post(url, {
        query: queryString,
      })
      .pipe(
        map((x) => {
          let data = [];
          // filtering (elastic) endpoint
          if (
            x['content'] &&
            Array.isArray(x['content']) &&
            x['content'].length > 0
          ) {
            data = x['content'];
          }
          if (data.length === 1) {
            return data[0];
          } else if (data.length > 1) {
            console.warn('More than one result for value: ', data);
            return data[0];
          } else {
            return null;
          }
        }),
      );
  }

  getValuesByFiledArray(
    value: any[],
    field: string,
    table: Table,
    filterFields: string[] = [],
    defaultFilters: FilterModel[] = [],
  ): Observable<any> {
    // z duvodu casove zavisloti... nekdy table nema nastavene filtry na zaklade defaultFilters a pak ten dotaz je spatne
    const mergeFilters = distinctArrays('field', defaultFilters, table.filters);
    const filteredFilters = mergeFilters.filter((x) =>
      filterFields.includes(x.field),
    );
    const filtersString =
      filteredFilters.length > 0
        ? '&' +
          this.pageSortFilterService.getUrlFilterFromFilterModels(
            filteredFilters,
            table.columns,
          )
        : '';
    return this.http
      .get(
        table.type.url +
          '?' +
          (['all', 'custom'].includes(field) ? 'id' : field) +
          '__in=' +
          value
            .map((x) => (['all', 'custom'].includes(field) ? x?.id : x))
            .join(',') +
          filtersString,
      )
      .pipe(
        take(1),
        map((x) => {
          // filtering-list endpoint
          if (Array.isArray(x) && x.length > 0) {
            return x;
            // filtering (elastic) endpoint
          } else if (
            x['content'] &&
            Array.isArray(x['content']) &&
            x['content'].length > 0
          ) {
            return x['content'];
          } else {
            return null;
          }
        }),
      );
  }

  getValuesByFiledTql(
    values: any[],
    field: string,
    table: Table,
    filterFields: string[] = [],
    defaultFilters: FilterModel[] = [],
  ): Observable<any> {
    const url = table.type.url;
    // z duvodu casove zavisloti... nekdy table nema nastavene filtry na zaklade defaultFilters a pak ten dotaz je spatne
    const mergeFilters = distinctArrays('field', defaultFilters, table.filters);
    let nestedFilters = this.pageSortFilterTqlService.nestedFilters(
      table.columns,
      mergeFilters,
    );
    let urlColumns =
      this.pageSortFilterTqlService.getUrlColumns(
        false,
        table.dataViewMode,
        table.columns,
        nestedFilters,
      ) + ',id ';
    let urlWhere = '';
    if (values?.length > 0) {
      const nestedColumnBySelectProperty = table.columns.find(
        (x) => x.field == field && !!x.nested,
      );
      if (!!nestedColumnBySelectProperty) {
        urlWhere =
          'where ' +
          nestedColumnBySelectProperty.nested +
          '[' +
          (['all', 'custom'].includes(field) ? 'id' : field) +
          ' in (' +
          values
            .map(
              (x) =>
                "'" + (['all', 'custom'].includes(field) ? x?.id : x) + "'",
            )
            .join(',') +
          ')]';
      } else {
        urlWhere =
          'where ' +
          (['all', 'custom'].includes(field) ? 'id' : field) +
          ' in (' +
          values
            .map(
              (x) =>
                "'" + (['all', 'custom'].includes(field) ? x?.id : x) + "'",
            )
            .join(',') +
          ')';
      }
      const filteredFilters = mergeFilters.filter((x) =>
        filterFields.includes(x.field),
      );
      if (filteredFilters.length > 0) {
        urlWhere +=
          ' and ' +
          this.pageSortFilterTqlService
            .getUrlWhere(filteredFilters, table.columns, nestedFilters)
            .replace('where ', '');
      }
    } else {
      return of(null);
    }
    return this.store
      .select(selectListingTypeByCode(table.type.type))
      .pipe(map((x) => x?.data?.tqlName))
      .pipe(
        concatMap((tqlName) => {
          if (tqlName == null && !table.type.tqlName) {
            return this.store.pipe(
              loadAndWait(
                LoadListingTypeByCode({code: table.type.type}),
                selectListingTypeByCode(table.type.type),
                true,
              ),
              map((x) => x?.data?.tqlName),
            );
          }
          return of(tqlName || table.type.tqlName);
        }),
        concatMap((tqlName) => {
          const queryString = `select ${urlColumns}
                                     from ${
                                       tqlName || table.type.tqlName
                                     } ${urlWhere} track total`;
          return this.getDataByValuesFiledTql(url, queryString);
        }),
      );
  }

  @SharedRequestValidUntil(60)
  private getDataByValuesFiledTql(url, queryString) {
    return this.http
      .post(url, {
        query: queryString,
      })
      .pipe(
        map((x) => {
          if (
            x['content'] &&
            Array.isArray(x['content']) &&
            x['content'].length > 0
          ) {
            return x['content'];
          } else {
            return null;
          }
        }),
      );
  }

  exportByTql(table: Table, tqlName: string): Observable<any> {
    const url = table.type.url.replace('page', 'export');
    const nestedFilters = this.pageSortFilterTqlService.nestedFilters(
      table.columns,
      table.filters,
    );
    const urlColumns = this.pageSortFilterTqlService.getUrlColumns(
      true,
      table.dataViewMode,
      table.columns,
      nestedFilters,
    );
    let urlWhere = this.pageSortFilterTqlService.getUrlWhere(
      table.filters,
      table.columns,
      nestedFilters,
      table?.tqlExtend?.extendTqlWhere,
    );
    const urlOrderBy = this.pageSortFilterTqlService.getUrlOrderBy(
      table.sorts,
      nestedFilters,
      table?.tqlExtend?.externalTqlSort,
      table?.tqlExtend?.externalTqlSortForNested,
    );

    if (table.selectedRowIds?.length > 0) {
      const whereSelectedIds =
        'id in (' +
        table.selectedRowIds.map((x) => "'" + x + "'").join(',') +
        ')';
      urlWhere = urlWhere.includes('where')
        ? urlWhere + ' and ' + whereSelectedIds
        : 'where ' + whereSelectedIds;
    }
    const queryString = `select converted(useExportFields, autoAlias) ${urlColumns}
                             from ${tqlName} ${urlWhere} ${urlOrderBy} limit 50000 track total`;
    let params = {
      language: this.translocoService.getActiveLang(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (
      table?.exportSettings?.templateId &&
      table.selectedProfileId &&
      table.selectedProfileId !== 'all'
    ) {
      params['listingProfileId'] = table.selectedProfileId;
    }
    return this.http.post(
      url,
      {
        query: queryString,
        params: params,
      },
      {observe: 'response', responseType: 'blob' as 'json'},
    );
  }

  getWhere(table: Table): string {
    const urlWhere = this.pageSortFilterService.getUrlFilterFromFilterModels(
      table.filters,
      table.columns,
    );
    return urlWhere;
  }

  getWhereByTql(table: Table): string {
    const nestedFilters = this.pageSortFilterTqlService.nestedFilters(
      table.columns,
      table.filters,
    );
    const urlWhere = this.pageSortFilterTqlService.getUrlWhere(
      table.filters,
      table.columns,
      nestedFilters,
      table?.tqlExtend?.extendTqlWhere,
    );
    return urlWhere;
  }

  restoreData(
    url: string,
    file: any,
    table: Table,
  ): Observable<{
    error?: any;
    table: Table;
    data: EntityDiff;
    totalImports?: number;
    ignoredFields?: string[];
  }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData).pipe(
      tap((d) => console.log(d)),
      map((x: any) => {
        return {
          table: table,
          data: x,
        };
      }),
      tap((d) => console.log(d)),
      // catchError(err => of(err))
    );
  }

  backupData(table: Table): Observable<any> {
    const backupUrl = table.showConfig.backupRestore.url;
    const urlSuffix = 'backup';
    const tmpUrl = table.type.url;
    let url: string;
    if (!!backupUrl) {
      url = backupUrl + '/' + urlSuffix;
    } else if (tmpUrl.includes('page') || tmpUrl.includes('filtering')) {
      url = tmpUrl.substring(0, tmpUrl.lastIndexOf('/') + 1) + urlSuffix;
    } else {
      url = tmpUrl + '/' + urlSuffix;
    }
    const body = {
      backupName: table.showConfig.backupRestore.fileName,
      entityType: table.showConfig.backupRestore.entityType,
      exportFilter: table.filters,
    };
    url =
      url +
      '?' +
      this.pageSortFilterService.getUrlFilterFromFilterModels(
        table.filters,
        table.columns,
      );
    return this.apiService.postRaw<any, any>(url, body, null, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  // Vezme data z exportu (backup) a vrati je jako objekt
  private getRestoreData(file: any): Observable<TableRestore> {
    return from(
      new Promise<TableRestore>((res) => {
        const reader = new FileReader();
        reader.onload = () => {
          const data = reader.result;
          res(JSON.parse(data as string));
        };
        reader.readAsBinaryString(file);
      }),
    );
  }

  fetchDataTotalTql<T>(
    tableModel: Table<T>,
    url: string,
    tqlName: string,
    throwError = false,
  ): Observable<GridDataHttpModel<T>> {
    const queryString = this.pageSortFilterTqlService.getTqlTotals(
      tableModel,
      tqlName,
    );
    let params = {
      language: this.translocoService.getActiveLang(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...(tableModel.type.queryParams || {}),
    };
    return this.http
      .post(url, {query: queryString, params: params})
      .pipe(catchError((err) => of(throwError ? err : null)));
  }
}
