import {AnimationEvent} from '@angular/animations';
import {CommonModule} from '@angular/common';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  NgZone,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslocoService} from '@tsm/framework/translate';
import {translation} from '@tsm/shared-i18n';
import {
  FilterService,
  OverlayOptions,
  PrimeNGConfig,
  PrimeTemplate,
  SelectItem,
  SharedModule,
  TranslationKeys,
} from 'primeng/api';
import {AutoFocusModule} from 'primeng/autofocus';
import {DomHandler} from 'primeng/dom';
import {Overlay, OverlayModule} from 'primeng/overlay';
import {RippleModule} from 'primeng/ripple';
import {Scroller, ScrollerModule, ScrollerItemOptions} from 'primeng/scroller';
import {TooltipModule} from 'primeng/tooltip';
import {ObjectUtils, UniqueComponentId} from 'primeng/utils';

export const DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TsmDropdown),
  multi: true,
};

export interface DropdownFilterOptions {
  filter?: (value?: any) => void;
  reset?: () => void;
}

@Component({
  selector: 'p-dropdownItem',
  template: `
    <li
      (click)="onOptionClick($event)"
      role="option"
      pRipple
      [attr.aria-label]="label"
      [attr.aria-selected]="selected"
      [ngStyle]="{height: itemSize + 'px'}"
      [id]="selected ? 'p-highlighted-option' : ''"
      [ngClass]="{
        'p-dropdown-item': true,
        'p-highlight': selected,
        'p-disabled': disabled,
      }"
    >
      <span *ngIf="!template">{{ label || 'empty' }}</span>
      <ng-container
        *ngTemplateOutlet="template; context: {$implicit: option}"
      ></ng-container>
    </li>
  `,
  host: {
    class: 'p-element',
  },
})
export class TsmDropdownItem {
  @Input() option: SelectItem;

  @Input() selected: boolean;

  @Input() label: string;

  @Input() disabled: boolean;

  @Input() visible: boolean;

  @Input() itemSize: number;

  @Input() template: TemplateRef<any>;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  onOptionClick(event: Event) {
    this.onClick.emit({
      originalEvent: event,
      option: this.option,
    });
  }
}

// custom code
export type openOptionsType = 'click' | 'focus' | 'hover' | 'none';

@Component({
  selector: 'tsm-dropdown',
  templateUrl: './dropdown.component.html',
  host: {
    class: 'p-element p-inputwrapper',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-state-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focused || overlayVisible',
    '[class.p-state-focus]': 'focused || overlayVisible',
  },
  providers: [DROPDOWN_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dropdown.css'],
})
export class TsmDropdown
  implements
    OnInit,
    AfterViewInit,
    AfterContentInit,
    AfterViewChecked,
    ControlValueAccessor
{
  // custom code
  @Input() iconHref: string;

  @Input() openForInit = false;

  @Input() autoOpen: Array<openOptionsType> | openOptionsType = 'none';

  @Input() scrollHeight: string = '200px';

  @Input() filter: boolean;

  @Input() name: string;

  @Input() style: any;

  @Input() panelStyle: any;

  @Input() styleClass: string;

  @Input() panelStyleClass: string;

  @Input() dropdownItemStyleClass: string;

  @Input() readonly: boolean;

  @Input() required: boolean;

  @Input() editable: boolean;

  @Input() appendTo = 'body';

  @Input() tabindex: number;

  @Input() placeholder: string;

  @Input() filterPlaceholder: string;

  @Input() filterLocale: string;

  @Input() inputId: string;

  @Input() selectId: string;

  @Input() dataKey: string;

  @Input() filterBy = 'label';

  @Input() autofocus: boolean;

  @Input() resetFilterOnHide: boolean = false;

  @Input() dropdownIcon: string = 'pi pi-chevron-down';

  @Input() optionLabel: string;

  @Input() optionValue: string;

  @Input() optionDisabled: string;

  @Input() optionGroupLabel: string;

  @Input() optionGroupChildren: string = 'items';

  @Input() autoDisplayFirst: boolean = true;

  @Input() group: boolean;

  @Input() showClear: boolean;

  @Input() emptyFilterMessage: string = '';

  @Input() emptyMessage: string = this.translocoService.translate(
    translation.shared.noResultsFound,
  );

  @Input() lazy: boolean = false;

  @Input() virtualScroll: boolean;

  @Input() virtualScrollItemSize: number;

  @Input() virtualScrollOptions: ScrollerItemOptions;

  @Input() overlayOptions: OverlayOptions;

  @Input() ariaFilterLabel: string;

  @Input() ariaLabel: string;

  @Input() ariaLabelledBy: string;

  @Input() filterMatchMode: string = 'contains';

  @Input() maxlength: number;

  @Input() tooltip: string = '';

  @Input() tooltipPosition: string = 'right';

  @Input() tooltipPositionStyle: string = 'absolute';

  @Input() tooltipStyleClass: string;

  @Input() autofocusFilter: boolean = true;

  @Input() overlayDirection: string = 'end';

  @Input() ignoreCtrlSKey = true;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onShow: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  @Output() onClear: EventEmitter<any> = new EventEmitter();

  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();

  @ViewChild('container') containerViewChild: ElementRef;

  @ViewChild('filter') filterViewChild: ElementRef;

  @ViewChild('in') accessibleViewChild: ElementRef;

  @ViewChild('editableInput') editableInputViewChild: ElementRef;

  @ViewChild('items') itemsViewChild: ElementRef;

  @ViewChild('scroller') scroller: Scroller;

  @ViewChild('overlay') overlayViewChild: Overlay;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  private _disabled: boolean;

  @Input() get disabled(): boolean {
    return this._disabled;
  }

  set disabled(_disabled: boolean) {
    if (_disabled) {
      this.focused = false;

      if (this.overlayVisible) this.hide();
    }

    this._disabled = _disabled;
    if (!(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  /* @deprecated */
  _itemSize: number;
  @Input() get itemSize(): number {
    return this._itemSize;
  }

  set itemSize(val: number) {
    this._itemSize = val;
    console.warn(
      'The itemSize property is deprecated, use virtualScrollItemSize property instead.',
    );
  }

  /* @deprecated */
  _autoZIndex: boolean;
  @Input() get autoZIndex(): boolean {
    return this._autoZIndex;
  }

  set autoZIndex(val: boolean) {
    this._autoZIndex = val;
    console.warn(
      'The autoZIndex property is deprecated since v14.2.0, use overlayOptions property instead.',
    );
  }

  /* @deprecated */
  _baseZIndex: number;
  @Input() get baseZIndex(): number {
    return this._baseZIndex;
  }

  set baseZIndex(val: number) {
    this._baseZIndex = val;
    console.warn(
      'The baseZIndex property is deprecated since v14.2.0, use overlayOptions property instead.',
    );
  }

  /* @deprecated */
  _showTransitionOptions: string;
  @Input() get showTransitionOptions(): string {
    return this._showTransitionOptions;
  }

  set showTransitionOptions(val: string) {
    this._showTransitionOptions = val;
    console.warn(
      'The showTransitionOptions property is deprecated since v14.2.0, use overlayOptions property instead.',
    );
  }

  /* @deprecated */
  _hideTransitionOptions: string;
  @Input() get hideTransitionOptions(): string {
    return this._hideTransitionOptions;
  }

  set hideTransitionOptions(val: string) {
    this._hideTransitionOptions = val;
    console.warn(
      'The hideTransitionOptions property is deprecated since v14.2.0, use overlayOptions property instead.',
    );
  }

  itemsWrapper: HTMLDivElement;

  itemTemplate: TemplateRef<any>;

  groupTemplate: TemplateRef<any>;

  loaderTemplate: TemplateRef<any>;

  selectedItemTemplate: TemplateRef<any>;

  headerTemplate: TemplateRef<any>;

  filterTemplate: TemplateRef<any>;

  footerTemplate: TemplateRef<any>;

  emptyFilterTemplate: TemplateRef<any>;

  emptyTemplate: TemplateRef<any>;

  filterOptions: DropdownFilterOptions;

  selectedOption: any;

  _options: any[];

  value: any;

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  optionsToDisplay: any[];

  hover: boolean;

  focused: boolean;

  overlayVisible: boolean;

  optionsChanged: boolean;

  panel: HTMLDivElement;

  dimensionsUpdated: boolean;

  hoveredItem: any;

  selectedOptionUpdated: boolean;

  _filterValue: string;

  searchValue: string;

  searchIndex: number;

  searchTimeout: any;

  previousSearchChar: string;

  currentSearchChar: string;

  preventModelTouched: boolean;

  id: string = UniqueComponentId();

  labelId: string;

  listId: string;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    public zone: NgZone,
    public filterService: FilterService,
    public config: PrimeNGConfig,
    public translocoService: TranslocoService,
  ) {
    filterService.filters.contains = (
      value,
      filter,
      filterLocale?,
    ): boolean => {
      if (
        filter === undefined ||
        filter === null ||
        (typeof filter === 'string' && filter.trim() === '')
      ) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value
        .toString()
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          filter
            .toString()
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        );
    };
  }

  // custom code
  containsOpenOptions(options: openOptionsType) {
    return Array.isArray(this.autoOpen)
      ? this.autoOpen.some((x) => x === options)
      : this.autoOpen === options;
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItem':
          this.selectedItemTemplate = item.template;
          break;

        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'filter':
          this.filterTemplate = item.template;
          break;

        case 'footer':
          this.footerTemplate = item.template;
          break;

        case 'emptyfilter':
          this.emptyFilterTemplate = item.template;
          break;

        case 'empty':
          this.emptyTemplate = item.template;
          break;

        case 'group':
          this.groupTemplate = item.template;
          break;

        case 'loader':
          this.loaderTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  ngOnInit() {
    this.optionsToDisplay = this.options;
    this.updateSelectedOption(null);
    this.labelId = this.id + '_label';
    this.listId = this.id + '_list';

    if (this.filterBy) {
      this.filterOptions = {
        filter: (value) => this.onFilterInputChange(value),
        reset: () => this.resetFilter(),
      };
    }
  }

  @Input() get options(): any[] {
    return this._options;
  }

  set options(val: any[]) {
    this._options = val;
    this.optionsToDisplay = this._options;
    this.updateSelectedOption(this.value);

    this.selectedOption = this.findOption(this.value, this.optionsToDisplay);
    if (
      !this.selectedOption &&
      ObjectUtils.isNotEmpty(this.value) &&
      !this.editable
    ) {
      this.value = null;
      this.onModelChange(this.value);
    }

    this.optionsChanged = true;

    if (this._filterValue && this._filterValue.length) {
      this.activateFilter();
    }
  }

  @Input() get filterValue(): string {
    return this._filterValue;
  }

  set filterValue(val: string) {
    this._filterValue = val;
    this.activateFilter();
  }

  ngAfterViewInit() {
    if (this.editable) {
      this.updateEditableLabel();
    }
    // custom code
    if (this.openForInit) {
      this.show();
    }
  }

  get label(): string {
    return this.selectedOption
      ? this.getOptionLabel(this.selectedOption)
      : null;
  }

  get emptyMessageLabel(): string {
    return (
      this.emptyMessage ||
      this.config.getTranslation(TranslationKeys.EMPTY_MESSAGE)
    );
  }

  get emptyFilterMessageLabel(): string {
    return (
      this.emptyFilterMessage ||
      this.config.getTranslation(TranslationKeys.EMPTY_FILTER_MESSAGE)
    );
  }

  get filled(): boolean {
    if (typeof this.value === 'string') return !!this.value;

    return this.value || this.value != null || this.value != undefined;
  }

  get isVisibleClearIcon(): boolean {
    return (
      this.value != null &&
      this.value !== '' &&
      this.showClear &&
      !this.disabled
    );
  }

  updateEditableLabel(): void {
    if (
      this.editableInputViewChild &&
      this.editableInputViewChild.nativeElement
    ) {
      this.editableInputViewChild.nativeElement.value = this.selectedOption
        ? this.getOptionLabel(this.selectedOption)
        : this.value || '';
    }
  }

  getOptionLabel(option: any) {
    return this.optionLabel
      ? ObjectUtils.resolveFieldData(option, this.optionLabel)
      : option && option.label !== undefined
        ? option.label
        : option;
  }

  getOptionValue(option: any) {
    return this.optionValue
      ? ObjectUtils.resolveFieldData(option, this.optionValue)
      : !this.optionLabel && option && option.value !== undefined
        ? option.value
        : option;
  }

  isOptionDisabled(option: any) {
    return this.optionDisabled
      ? ObjectUtils.resolveFieldData(option, this.optionDisabled)
      : option && option.disabled !== undefined
        ? option.disabled
        : false;
  }

  getOptionGroupLabel(optionGroup: any) {
    return this.optionGroupLabel
      ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel)
      : optionGroup && optionGroup.label !== undefined
        ? optionGroup.label
        : optionGroup;
  }

  getOptionGroupChildren(optionGroup: any) {
    return this.optionGroupChildren
      ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupChildren)
      : optionGroup.items;
  }

  onItemClick(event) {
    const option = event.option;

    if (!this.isOptionDisabled(option)) {
      this.selectItem(event.originalEvent, option);
      this.accessibleViewChild.nativeElement.focus({preventScroll: true});
    }

    setTimeout(() => {
      this.hide();
    }, 1);
  }

  selectItem(event, option) {
    if (this.selectedOption != option) {
      this.selectedOption = option;
      this.value = this.getOptionValue(option);

      this.onModelChange(this.value);
      this.updateEditableLabel();
      this.onChange.emit({
        originalEvent: event,
        value: this.value,
      });
    }
  }

  ngAfterViewChecked() {
    if (this.optionsChanged && this.overlayVisible) {
      this.optionsChanged = false;

      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.overlayViewChild) {
            this.overlayViewChild.alignOverlay();
          }
        }, 1);
      });
    }

    if (this.selectedOptionUpdated && this.itemsWrapper) {
      let selectedItem = DomHandler.findSingle(
        this.overlayViewChild.overlayViewChild.nativeElement,
        'li.p-highlight',
      );
      if (selectedItem) {
        DomHandler.scrollInView(this.itemsWrapper, selectedItem);
      }
      this.selectedOptionUpdated = false;
    }
  }

  writeValue(value: any): void {
    if (this.filter) {
      this.resetFilter();
    }

    this.value = value;
    this.updateSelectedOption(value);
    this.updateEditableLabel();
    this.cd.markForCheck();
  }

  resetFilter(): void {
    this._filterValue = null;

    if (this.filterViewChild && this.filterViewChild.nativeElement) {
      this.filterViewChild.nativeElement.value = '';
    }

    this.optionsToDisplay = this.options;
  }

  updateSelectedOption(val: any): void {
    this.selectedOption = this.findOption(val, this.optionsToDisplay);

    if (
      this.autoDisplayFirst &&
      !this.placeholder &&
      !this.selectedOption &&
      this.optionsToDisplay &&
      this.optionsToDisplay.length &&
      !this.editable
    ) {
      if (this.group) {
        this.selectedOption = this.optionsToDisplay[0].items[0];
      } else {
        this.selectedOption = this.optionsToDisplay[0];
      }
      this.value = this.getOptionValue(this.selectedOption);
      // FIXME CHYBA nevolat ve writeValue
      this.onModelChange(this.value);
    }

    this.selectedOptionUpdated = true;
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  onMouseclick(event) {
    // custom code
    if (this.containsOpenOptions('click') && !this.overlayVisible) {
      this.show();
    }

    if (this.disabled || this.readonly || this.isInputClick(event)) {
      return;
    }

    this.onClick.emit(event);

    this.accessibleViewChild.nativeElement.focus({preventScroll: true});

    if (this.overlayVisible) this.hide();
    else this.show();

    this.cd.detectChanges();
  }

  isInputClick(event): boolean {
    return (
      DomHandler.hasClass(event.target, 'p-dropdown-clear-icon') ||
      event.target.isSameNode(this.accessibleViewChild.nativeElement) ||
      (this.editableInputViewChild &&
        event.target.isSameNode(this.editableInputViewChild.nativeElement))
    );
  }

  isEmpty() {
    return (
      !this.optionsToDisplay ||
      (this.optionsToDisplay && this.optionsToDisplay.length === 0)
    );
  }

  onEditableInputFocus(event) {
    this.focused = true;
    this.hide();
    this.onFocus.emit(event);
  }

  onEditableInputChange(event) {
    this.value = event.target.value;
    this.updateSelectedOption(this.value);
    this.onModelChange(this.value);
    this.onChange.emit({
      originalEvent: event,
      value: this.value,
    });
  }

  show() {
    this.overlayVisible = true;
    this.cd.markForCheck();
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    if (event.toState === 'visible') {
      this.itemsWrapper = DomHandler.findSingle(
        this.overlayViewChild.overlayViewChild.nativeElement,
        this.virtualScroll ? '.p-scroller' : '.p-dropdown-items-wrapper',
      );
      this.virtualScroll &&
        this.scroller.setContentEl(this.itemsViewChild.nativeElement);

      if (this.options && this.options.length) {
        if (this.virtualScroll) {
          const selectedIndex = this.selectedOption
            ? this.findOptionIndex(
                this.getOptionValue(this.selectedOption),
                this.optionsToDisplay,
              )
            : -1;
          if (selectedIndex !== -1) {
            this.scroller.scrollToIndex(selectedIndex);
          }
        } else {
          let selectedListItem = DomHandler.findSingle(
            this.itemsWrapper,
            '.p-dropdown-item.p-highlight',
          );

          if (selectedListItem) {
            selectedListItem.scrollIntoView({
              block: 'nearest',
              inline: 'center',
            });
          }
        }
      }

      if (this.filterViewChild && this.filterViewChild.nativeElement) {
        this.preventModelTouched = true;

        if (this.autofocusFilter) {
          this.filterViewChild.nativeElement.focus();
        }
      }

      this.onShow.emit(event);
    }
    if (event.toState === 'void') {
      this.itemsWrapper = null;
      this.onModelTouched();
      this.onHide.emit(event);
    }
  }

  hide() {
    this.overlayVisible = false;

    if (this.filter && this.resetFilterOnHide) {
      this.resetFilter();
    }

    this.cd.markForCheck();
  }

  onInputFocus(event) {
    // custom code
    if (!this.overlayVisible && this.containsOpenOptions('focus')) {
      this.show();
    }
    this.focused = true;
    this.onFocus.emit(event);
  }

  onInputBlur(event) {
    this.focused = false;
    this.onBlur.emit(event);

    if (!this.preventModelTouched) {
      this.onModelTouched();
    }
    this.preventModelTouched = false;
  }

  findPrevEnabledOption(index) {
    let prevEnabledOption;

    if (this.optionsToDisplay && this.optionsToDisplay.length) {
      for (let i = index - 1; 0 <= i; i--) {
        let option = this.optionsToDisplay[i];
        if (this.isOptionDisabled(option)) {
          continue;
        } else {
          prevEnabledOption = option;
          break;
        }
      }

      if (!prevEnabledOption) {
        for (let i = this.optionsToDisplay.length - 1; i >= index; i--) {
          let option = this.optionsToDisplay[i];
          if (this.isOptionDisabled(option)) {
            continue;
          } else {
            prevEnabledOption = option;
            break;
          }
        }
      }
    }

    return prevEnabledOption;
  }

  findNextEnabledOption(index) {
    let nextEnabledOption;

    if (this.optionsToDisplay && this.optionsToDisplay.length) {
      for (let i = index + 1; i < this.optionsToDisplay.length; i++) {
        let option = this.optionsToDisplay[i];
        if (this.isOptionDisabled(option)) {
          continue;
        } else {
          nextEnabledOption = option;
          break;
        }
      }

      if (!nextEnabledOption) {
        for (let i = 0; i < index; i++) {
          let option = this.optionsToDisplay[i];
          if (this.isOptionDisabled(option)) {
            continue;
          } else {
            nextEnabledOption = option;
            break;
          }
        }
      }
    }

    return nextEnabledOption;
  }

  onKeydown(event: KeyboardEvent, search: boolean) {
    if (
      this.readonly ||
      !this.optionsToDisplay ||
      this.optionsToDisplay.length === null
    ) {
      return;
    }

    if (this.ignoreCtrlSKey && event.ctrlKey && event.which === 83) {
      event.preventDefault();
      return;
    }

    switch (event.which) {
      //down
      case 40:
        if (!this.overlayVisible && event.altKey) {
          this.show();
        } else {
          if (this.group) {
            let selectedItemIndex = this.selectedOption
              ? this.findOptionGroupIndex(
                  this.getOptionValue(this.selectedOption),
                  this.optionsToDisplay,
                )
              : -1;

            if (selectedItemIndex !== -1) {
              let nextItemIndex = selectedItemIndex.itemIndex + 1;
              if (
                nextItemIndex <
                this.getOptionGroupChildren(
                  this.optionsToDisplay[selectedItemIndex.groupIndex],
                ).length
              ) {
                this.selectItem(
                  event,
                  this.getOptionGroupChildren(
                    this.optionsToDisplay[selectedItemIndex.groupIndex],
                  )[nextItemIndex],
                );
                this.selectedOptionUpdated = true;
              } else if (
                this.optionsToDisplay[selectedItemIndex.groupIndex + 1]
              ) {
                this.selectItem(
                  event,
                  this.getOptionGroupChildren(
                    this.optionsToDisplay[selectedItemIndex.groupIndex + 1],
                  )[0],
                );
                this.selectedOptionUpdated = true;
              }
            } else {
              if (this.optionsToDisplay && this.optionsToDisplay.length > 0) {
                this.selectItem(
                  event,
                  this.getOptionGroupChildren(this.optionsToDisplay[0])[0],
                );
              }
            }
          } else {
            let selectedItemIndex = this.selectedOption
              ? this.findOptionIndex(
                  this.getOptionValue(this.selectedOption),
                  this.optionsToDisplay,
                )
              : -1;
            let nextEnabledOption =
              this.findNextEnabledOption(selectedItemIndex);
            if (nextEnabledOption) {
              this.selectItem(event, nextEnabledOption);
              this.selectedOptionUpdated = true;
            }
          }
        }

        event.preventDefault();

        break;

      //up
      case 38:
        if (this.group) {
          let selectedItemIndex = this.selectedOption
            ? this.findOptionGroupIndex(
                this.getOptionValue(this.selectedOption),
                this.optionsToDisplay,
              )
            : -1;
          if (selectedItemIndex !== -1) {
            let prevItemIndex = selectedItemIndex.itemIndex - 1;
            if (prevItemIndex >= 0) {
              this.selectItem(
                event,
                this.getOptionGroupChildren(
                  this.optionsToDisplay[selectedItemIndex.groupIndex],
                )[prevItemIndex],
              );
              this.selectedOptionUpdated = true;
            } else if (prevItemIndex < 0) {
              let prevGroup =
                this.optionsToDisplay[selectedItemIndex.groupIndex - 1];
              if (prevGroup) {
                this.selectItem(
                  event,
                  this.getOptionGroupChildren(prevGroup)[
                    this.getOptionGroupChildren(prevGroup).length - 1
                  ],
                );
                this.selectedOptionUpdated = true;
              }
            }
          }
        } else {
          let selectedItemIndex = this.selectedOption
            ? this.findOptionIndex(
                this.getOptionValue(this.selectedOption),
                this.optionsToDisplay,
              )
            : -1;
          let prevEnabledOption = this.findPrevEnabledOption(selectedItemIndex);
          if (prevEnabledOption) {
            this.selectItem(event, prevEnabledOption);
            this.selectedOptionUpdated = true;
          }
        }

        event.preventDefault();
        break;

      //space
      case 32:
        if (search) {
          if (!this.overlayVisible) {
            this.show();
          } else {
            this.hide();
          }

          event.preventDefault();
        }
        break;

      //enter
      case 13:
        if (
          this.overlayVisible &&
          (!this.filter ||
            (this.optionsToDisplay && this.optionsToDisplay.length > 0))
        ) {
          this.hide();
        } else if (!this.overlayVisible) {
          this.show();
        }

        event.preventDefault();
        break;

      //escape and tab
      case 27:
      case 9:
        this.hide();
        event.stopPropagation();
        break;
      //search item based on keyboard input
      default:
        if (search && !event.metaKey && event.which !== 17) {
          this.search(event);
        }
        break;
    }
  }

  search(event: KeyboardEvent) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    const char = event.key;
    this.previousSearchChar = this.currentSearchChar;
    this.currentSearchChar = char;

    if (this.previousSearchChar === this.currentSearchChar)
      this.searchValue = this.currentSearchChar;
    else this.searchValue = this.searchValue ? this.searchValue + char : char;

    let newOption;
    if (this.group) {
      let searchIndex = this.selectedOption
        ? this.findOptionGroupIndex(
            this.getOptionValue(this.selectedOption),
            this.optionsToDisplay,
          )
        : {
            groupIndex: 0,
            itemIndex: 0,
          };
      newOption = this.searchOptionWithinGroup(searchIndex);
    } else {
      let searchIndex = this.selectedOption
        ? this.findOptionIndex(
            this.getOptionValue(this.selectedOption),
            this.optionsToDisplay,
          )
        : -1;
      newOption = this.searchOption(++searchIndex);
    }

    if (newOption && !this.isOptionDisabled(newOption)) {
      this.selectItem(event, newOption);
      this.selectedOptionUpdated = true;
    }

    this.searchTimeout = setTimeout(() => {
      this.searchValue = null;
    }, 250);
  }

  searchOption(index) {
    let option;

    if (this.searchValue) {
      option = this.searchOptionInRange(index, this.optionsToDisplay.length);

      if (!option) {
        option = this.searchOptionInRange(0, index);
      }
    }

    return option;
  }

  searchOptionInRange(start, end) {
    for (let i = start; i < end; i++) {
      let opt = this.optionsToDisplay[i];
      if (
        this.getOptionLabel(opt)
          .toLocaleLowerCase(this.filterLocale)
          .startsWith(
            (this.searchValue as any).toLocaleLowerCase(this.filterLocale),
          ) &&
        !this.isOptionDisabled(opt)
      ) {
        return opt;
      }
    }

    return null;
  }

  searchOptionWithinGroup(index) {
    let option;

    if (this.searchValue) {
      for (let i = index.groupIndex; i < this.optionsToDisplay.length; i++) {
        for (
          let j = index.groupIndex === i ? index.itemIndex + 1 : 0;
          j < this.getOptionGroupChildren(this.optionsToDisplay[i]).length;
          j++
        ) {
          let opt = this.getOptionGroupChildren(this.optionsToDisplay[i])[j];
          if (
            this.getOptionLabel(opt)
              .toLocaleLowerCase(this.filterLocale)
              .startsWith(
                (this.searchValue as any).toLocaleLowerCase(this.filterLocale),
              ) &&
            !this.isOptionDisabled(opt)
          ) {
            return opt;
          }
        }
      }

      if (!option) {
        for (let i = 0; i <= index.groupIndex; i++) {
          for (
            let j = 0;
            j <
            (index.groupIndex === i
              ? index.itemIndex
              : this.getOptionGroupChildren(this.optionsToDisplay[i]).length);
            j++
          ) {
            let opt = this.getOptionGroupChildren(this.optionsToDisplay[i])[j];
            if (
              this.getOptionLabel(opt)
                .toLocaleLowerCase(this.filterLocale)
                .startsWith(
                  (this.searchValue as any).toLocaleLowerCase(
                    this.filterLocale,
                  ),
                ) &&
              !this.isOptionDisabled(opt)
            ) {
              return opt;
            }
          }
        }
      }
    }

    return null;
  }

  // primeng code
  // findOptionIndex(val: any, opts: any[]): number {
  //   let index: number = -1;
  //   if (opts) {
  //     for (let i = 0; i < opts.length; i++) {
  //       if ((val == null && this.getOptionValue(opts[i]) == null) || ObjectUtils.equals(val, this.getOptionValue(opts[i]), this.dataKey)) {
  //         index = i;
  //         break;
  //       }
  //     }
  //   }
  //
  //   return index;
  // }

  // custom code
  findOptionIndex(val: any, opts: any[]): number {
    let index = -1;
    if (opts) {
      for (let i = 0; i < opts.length; i++) {
        if (
          (val == null && this.getOptionValue(opts[i]) == null) ||
          (this.dataKey
            ? val == this.getOptionValue(opts[i])[this.dataKey] ||
              (val &&
                val[this.dataKey] ===
                  this.getOptionValue(opts[i])[this.dataKey])
            : ObjectUtils.equals(
                val,
                this.getOptionValue(opts[i]),
                this.dataKey,
              ))
        ) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  findOptionGroupIndex(val: any, opts: any[]): any {
    let groupIndex, itemIndex;

    if (opts) {
      for (let i = 0; i < opts.length; i++) {
        groupIndex = i;
        itemIndex = this.findOptionIndex(
          val,
          this.getOptionGroupChildren(opts[i]),
        );

        if (itemIndex !== -1) {
          break;
        }
      }
    }

    if (itemIndex !== -1) {
      return {groupIndex: groupIndex, itemIndex: itemIndex};
    } else {
      return -1;
    }
  }

  findOption(val: any, opts: any[], inGroup?: boolean): SelectItem {
    if (this.group && !inGroup) {
      let opt: SelectItem;
      if (opts && opts.length) {
        for (let optgroup of opts) {
          opt = this.findOption(
            val,
            this.getOptionGroupChildren(optgroup),
            true,
          );
          if (opt) {
            break;
          }
        }
      }
      return opt;
    } else {
      let index: number = this.findOptionIndex(val, opts);
      return index != -1 ? opts[index] : null;
    }
  }

  onFilterInputChange(event): void {
    let inputValue = event.target.value;
    if (inputValue && inputValue.length) {
      this._filterValue = inputValue;
      this.activateFilter();
    } else {
      this._filterValue = null;
      this.optionsToDisplay = this.options;
    }

    this.virtualScroll && this.scroller.scrollToIndex(0);

    this.optionsChanged = true;
    this.onFilter.emit({originalEvent: event, filter: this._filterValue});
  }

  activateFilter() {
    let searchFields: string[] = (
      this.filterBy ||
      this.optionLabel ||
      'label'
    ).split(',');

    if (this.options && this.options.length) {
      if (this.group) {
        let filteredGroups = [];
        for (let optgroup of this.options) {
          let filteredSubOptions = this.filterService.filter(
            this.getOptionGroupChildren(optgroup),
            searchFields,
            this.filterValue,
            this.filterMatchMode,
            this.filterLocale,
          );
          if (filteredSubOptions && filteredSubOptions.length) {
            filteredGroups.push({
              ...optgroup,
              ...{[this.optionGroupChildren]: filteredSubOptions},
            });
          }
        }

        this.optionsToDisplay = filteredGroups;
      } else {
        this.optionsToDisplay = this.filterService.filter(
          this.options,
          searchFields,
          this.filterValue,
          this.filterMatchMode,
          this.filterLocale,
        );
      }

      this.optionsChanged = true;
    }
  }

  applyFocus(): void {
    if (this.editable)
      DomHandler.findSingle(
        this.el.nativeElement,
        '.p-dropdown-label.p-inputtext',
      ).focus();
    else
      DomHandler.findSingle(this.el.nativeElement, 'input[readonly]').focus();
  }

  focus(): void {
    this.applyFocus();
  }

  clear(event: Event) {
    this.value = null;
    this.onModelChange(this.value);
    this.onChange.emit({
      originalEvent: event,
      value: this.value,
    });
    this.updateSelectedOption(this.value);
    this.updateEditableLabel();
    this.onClear.emit(event);
  }
}

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    SharedModule,
    TooltipModule,
    RippleModule,
    ScrollerModule,
    AutoFocusModule,
  ],
  exports: [TsmDropdown, OverlayModule, SharedModule, ScrollerModule],
  declarations: [TsmDropdown, TsmDropdownItem],
})
export class TsmDropdownModule {}
