import {Injectable} from '@angular/core';
import {
  DataViewModeEnum,
  FilterModel,
  FilterOperator,
  filterOperatorConverter,
  filterValueAndOperatorConverter,
  SortModel,
  Table,
  TableColumn,
  TreeTableParams,
} from '../models';
import {DtlUtils} from '../utils';
import {DatePipe} from '@angular/common';
import {groupBy, isMobile} from '@tsm/framework/functions';

export interface NestedFilters {
  [key: string]: {filter: string; field: string; onlyDefaultFilter?: boolean};
}

@Injectable({
  providedIn: 'root',
})
export class PageSortFilterTqlService {
  isMobile = isMobile();

  public getTqlTotals<T>(table: Table<T>, tqlName: string): string {
    let nestedFilters = this.nestedFilters(table.columns, table.filters);
    let urlColumns = table.columns
      .filter((x) => x.showTotals)
      .map((x) => `sum(${x.field}) as "${x.field}"`)
      .join(', ');
    let urlWhere = this.getUrlWhere(
      table.filters,
      table.columns,
      nestedFilters,
      table?.tqlExtend?.extendTqlWhere,
    );
    let select = `select ${urlColumns}
                      from ${tqlName} ${urlWhere}`;
    return select;
  }

  public getPagingSortFilterUrl<T>(
    exportMode: boolean,
    table: Table<T>,
    tqlName: string,
    tree?: TreeTableParams,
    options?: string,
  ): string {
    let nestedFilters = this.nestedFilters(table.columns, table.filters);
    let urlGroupBy = this.getUrlGroupBy(table.columns, nestedFilters);
    let urlColumns = this.getUrlColumns(
      exportMode,
      table.dataViewMode,
      table.columns,
      nestedFilters,
      urlGroupBy !== '',
    );
    let urlWhere = this.getUrlWhere(
      table.filters,
      table.columns,
      nestedFilters,
      table?.tqlExtend?.extendTqlWhere,
    );
    let urlOrderBy = this.getUrlOrderBy(
      table.sorts,
      nestedFilters,
      table?.tqlExtend?.externalTqlSort,
      table?.tqlExtend?.externalTqlSortForNested,
    );
    let expandAll = tree?.expandAll === true;
    const offset = table.pageSize * table.page - table.pageSize;
    let select;

    // JEDNA SE O TABULKU
    if (tree.isActive !== true) {
      select = `select converted(useConvertOnBackend) ${urlColumns}
                      from ${tqlName} ${urlWhere} ${urlGroupBy} ${urlOrderBy} limit ${offset}, ${table.pageSize} track total`;
    }
    // JEDNA SE O TREE TABLE (select id, code, name, parentId from catalogtree where parentId is null with subtree name like '%s%'order by name limit 5,10)
    else if (tree.isActive === true && tree.parentValue) {
      // pokud nejsem na prvni strance s rootama a otviram potomky
      const cleanWhere = urlWhere.replace('where ', '');
      let whereCondition = `${tree.parentField} = '${tree.parentValue}'`;
      if (!!tree.whereChildCondition) {
        whereCondition = `${tree.whereChildCondition
          .replace('$parentField', tree.parentField)
          .replace('$parentValue', tree.parentValue)}`;
      }
      if (!!tree.whereStaticCondition) {
        whereCondition = `${whereCondition} and ${tree.whereStaticCondition}`;
      }
      select = `select converted(useConvertOnBackend) __leaf, ${
        tree.parentField
      }, ${urlColumns}
                      from ${tqlName}
                      where (${whereCondition})
                          ${
                            tree.isWhereFilters === true
                              ? (!!cleanWhere ? 'and ' : '') + cleanWhere
                              : (!!cleanWhere ? 'with subtree ' : '') +
                                (expandAll ? 'expanded ' : '') +
                                cleanWhere
                          } ${urlGroupBy} ${urlOrderBy} limit 0
                          , 1000 track total`;
    } else if (tree.isActive === true) {
      const cleanWhere = urlWhere.replace('where ', '');
      let whereCondition = `${tree.parentField} is null or ${tree.parentField} = '00000000-0000-0000-0000-000000000000'`;
      if (!!tree.whereRootCondition) {
        whereCondition = `${tree.whereRootCondition.replace(
          '$parentField',
          tree.parentField,
        )}`;
      }
      if (!!tree.whereStaticCondition) {
        whereCondition = `${whereCondition} and ${tree.whereStaticCondition}`;
      }
      select = `select converted(useConvertOnBackend) __leaf, ${
        tree.parentField
      }, ${urlColumns}
                      from ${tqlName}
                      where (${whereCondition})
                          ${
                            tree.isWhereFilters === true
                              ? (!!cleanWhere ? 'and ' : '') + cleanWhere
                              : (!!cleanWhere ? 'with subtree ' : '') +
                                (expandAll ? 'expanded ' : '') +
                                cleanWhere
                          } ${urlGroupBy} ${urlOrderBy} limit ${offset}
                          , ${table.pageSize} track total`;
    }

    // console.log(select);
    return select;
  }

  // Vytvoreni objektu "nestedFiled" : "filterQuery"
  public nestedFilters(
    columns: TableColumn[],
    filters: FilterModel[],
    externalQuery?: {
      [k: string]: string;
    },
  ): NestedFilters {
    let nestedFilters: NestedFilters;
    const cleanColumns = columns.filter((x) => !!x);
    const nestedGroupBy = groupBy(
      cleanColumns.filter((x) => !!x.nested),
      'nested',
    );
    // potrebuju to seradit tak, aby prvni byly sloupce, ktery jsou jenom nested a az pak za nima nestedInNested
    Object.keys(nestedGroupBy)
      .sort((a, b) => a.split('[].').length - b.split('[].').length)
      .forEach((key) => {
        // sloupecky pro nestedField
        const nestedColumns: Array<TableColumn> = nestedGroupBy[key];
        // split key, tak abych zjistil, zda se jedna jenom o nested nebo nestedInNested
        const nestedInNested = key.split('[].');
        // ocisteni fieldu pokud je nested in nested -> odstranit zavorky (do fieldu pri definici sloupce se uz zavorky nedavaji)
        const cleanField = nestedInNested.join('.') + '.'; // (sample: tasks[].history  - > tasks.history)
        if (nestedInNested.length == 1) {
          // JEDNA SE O NESTED
          const createNestedFilter = this.createNestedFilter(
            filters,
            nestedColumns,
            cleanColumns,
            cleanField,
          );
          let resultNestedFilter = createNestedFilter.nestedQueryFilter;
          // pokud mam nejaky externalQuery tak ho musim doplnit do vysledneho dotazu
          if (!!externalQuery && Object.keys(externalQuery)?.length > 0) {
            const foundExternalQuery = externalQuery[nestedInNested[0]];
            if (foundExternalQuery) {
              if (!!resultNestedFilter) {
                resultNestedFilter = `${resultNestedFilter} and ${foundExternalQuery}`;
              } else {
                resultNestedFilter = foundExternalQuery;
              }
            }
          }
          nestedFilters = {
            ...nestedFilters,
            [key]: {
              filter: resultNestedFilter,
              field: resultNestedFilter
                ? `${key}[${resultNestedFilter}]`
                : `${key}[]`,
              onlyDefaultFilter: createNestedFilter.onlyDefaultFilter,
            },
          };
        } else if (nestedInNested.length > 1) {
          // JEDNA SE O NESTED IN NESTED (sample: tasks[].history)
          const nestedParentField = nestedInNested[0]; //(sample: tasks)
          // pokud nemam nezadne predky nestedFilters, tak si ho sestavim sam, jinak se pouzije nalezeny
          const filterNestedParent =
            nestedFilters == null
              ? {
                  filter: undefined,
                  field: `${nestedParentField}[]`,
                }
              : nestedFilters[nestedParentField]; //(sample: active = true && status in ('DONE'))
          const resultParentFieldWithCondition =
            filterNestedParent?.field || nestedParentField + '[]'; // (sample: tasks[active == true && status in ('DONE')])
          const nestedField = key.replace(nestedParentField + '[]', ''); // (sample: .history)
          const createNestedFilter = this.createNestedFilter(
            filters,
            nestedColumns,
            cleanColumns,
            cleanField,
          );
          let resultNestedFilter = createNestedFilter.nestedQueryFilter;
          // pokud mam nejaky externalQuery tak ho musim doplnit do vysledneho dotazu
          if (!!externalQuery && Object.keys(externalQuery)?.length > 0) {
            const foundExternalQuery =
              externalQuery[nestedParentField + '[]' + nestedField];
            if (foundExternalQuery) {
              const result = foundExternalQuery.replace(
                nestedParentField + nestedField + '.',
                '',
              );
              if (!!resultNestedFilter) {
                resultNestedFilter = `${resultNestedFilter} and ${result}`;
              } else {
                resultNestedFilter = result;
              }
            }
          }
          nestedFilters = {
            ...nestedFilters,
            [key]: {
              filter: resultNestedFilter,
              field:
                resultParentFieldWithCondition +
                (resultNestedFilter
                  ? `${nestedField}[${resultNestedFilter}]`
                  : `${nestedField}[]`),
              onlyDefaultFilter: createNestedFilter.onlyDefaultFilter,
            },
          };
        }
      });
    return nestedFilters;
  }

  public getUrlColumns(
    exportMode: boolean,
    dataViewMode: DataViewModeEnum,
    columns: TableColumn[],
    nestedFilters: NestedFilters,
    isGroupBy = false,
  ): string {
    let urlColumns = '';
    let visibleColumns: TableColumn[] = [];
    if (
      !this.isMobile &&
      dataViewMode !== DataViewModeEnum.TABLE_DETAIL &&
      dataViewMode !== DataViewModeEnum.KANBAN
    ) {
      visibleColumns = columns.filter(
        (x) => x.visible === true || x.visible == null,
      );
    } else {
      visibleColumns = columns.filter(
        (x) => x.visibleCard === true || x.visibleCard == null,
      );
    }
    // nejaka unikatni hodnota tam musi byt vzdy! pomoci ID se treba resi selectedRows v dataView
    if (
      !visibleColumns.some((x) => x.field == 'id') &&
      exportMode != true &&
      !isGroupBy
    ) {
      visibleColumns.push({
        field: 'id',
        header: 'ID',
        visible: false,
      });
    }

    // sloupce, ktery maji atribut "alwaysInQuery"(vždy přidat tento sloupec do dotazu),
    // pokud se nejedna o export, to me pak zajimaji jenom ty, co maj visible/visibleCard === true
    if (exportMode != true) {
      const alwaysInQueryColumns = columns.filter(
        (x) => x.alwaysInQuery === true,
      );
      if (alwaysInQueryColumns.length > 0) {
        alwaysInQueryColumns.forEach((c) => {
          if (!visibleColumns.some((x) => x.field == c.field)) {
            visibleColumns.push(c);
          }
        });
      }
    }

    visibleColumns
      .filter((c) => (exportMode == true ? c.exportDisabled != true : true))
      .forEach((column, index) => {
        if (index != 0) {
          urlColumns = urlColumns + ', ';
        }
        if (!!column.nested) {
          const nestedInNested = column.nested.split('[].');
          if (nestedInNested.length == 1) {
            // jedna se o nested
            const filterNested = nestedFilters[column.nested];
            if (exportMode == true) {
              if (!!column?.exportField) {
                urlColumns =
                  urlColumns +
                  `${filterNested.field}${column.exportField.replace(
                    column.nested,
                    '',
                  )}`;
              } else if (!!column?.displayField) {
                urlColumns =
                  urlColumns +
                  `${filterNested.field}${column.displayField.replace(
                    column.nested,
                    '',
                  )}`;
              } else {
                urlColumns =
                  urlColumns +
                  `${filterNested.field}${column.field.replace(
                    column.nested,
                    '',
                  )}`;
              }
            } else if (!!column?.displayField) {
              urlColumns =
                urlColumns +
                `${filterNested.field}${column.displayField.replace(
                  column.nested,
                  '',
                )} as "${column.field}"`;
            } else {
              urlColumns =
                urlColumns +
                `${filterNested.field}${column.field.replace(
                  column.nested,
                  '',
                )} as "${column.field}"`;
            }
          } else if (nestedInNested.length > 1) {
            // jedna se o nested in nested (sample: tasks[].history)
            const filterNested = nestedFilters[column.nested]; //(sample: duration > 5)
            if (exportMode == true) {
              if (!!column?.exportField) {
                urlColumns =
                  urlColumns +
                  `${filterNested.field}${column.exportField.replace(
                    nestedInNested.join('.'),
                    '',
                  )}`;
              } else if (!!column?.displayField) {
                urlColumns =
                  urlColumns +
                  `${filterNested.field}${column.displayField.replace(
                    nestedInNested.join('.'),
                    '',
                  )}`;
              } else {
                urlColumns =
                  urlColumns +
                  `${filterNested.field}${column.field.replace(
                    nestedInNested.join('.'),
                    '',
                  )}`;
              }
            } else if (
              !!column?.displayField &&
              column.convertOnBackend != true
            ) {
              urlColumns =
                urlColumns +
                `${filterNested.field}${column.displayField.replace(
                  nestedInNested.join('.'),
                  '',
                )} as "${column.field}"`;
            } else {
              urlColumns =
                urlColumns +
                `${filterNested.field}${column.field.replace(
                  nestedInNested.join('.'),
                  '',
                )} as "${column.field}"`;
            }
          }
        } else {
          // normalni sloupec
          if (
            column.displayField &&
            column.convertOnBackend != true &&
            exportMode != true
          ) {
            urlColumns =
              urlColumns + column.displayField + ` as "${column.field}"`;
          } else {
            urlColumns = urlColumns + column.field;
          }
        }
      });
    return urlColumns;
  }

  public getUrlWhere(
    filters: FilterModel[],
    columns: TableColumn[],
    nestedFilters: NestedFilters,
    extendTqlWhere?: string,
  ): string {
    let urlWhere = '';
    if (Array.isArray(filters)) {
      const filteredColumns = filters.filter((filter) => {
        // potrebuju jeste vyhazet ty nested
        if (
          filter.operator &&
          filter.value != null &&
          filter.value !== '' &&
          (!nestedFilters ||
            !Object.keys(nestedFilters).some((k) =>
              filter.field.startsWith(k + '.'),
            ))
        ) {
          if (
            Array.isArray(filter.value) &&
            filter.value.filter((val) => val).length
          ) {
            return true;
          } else if (!Array.isArray(filter.value)) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      });
      if (filteredColumns.length > 0) {
        const createUrlFilters = filteredColumns
          .map((f) => this.getUrlFilter(f, columns))
          .filter((x) => !!x);
        // pokud jsem mel nejaky sloupce k sestaveni filtru (filteredColumns) a nepovedlo se mi zadny filtr sestavit, tak vrat prazdny retezec
        if (createUrlFilters?.length > 0) {
          urlWhere = urlWhere + 'where ' + createUrlFilters.join(' and ');
        }
      }
    }
    // dopln filtry, ktere nejsou nested
    // mam nejaky nestedFilter a zaroven je pridany i nejaky od uzivatele!
    if (nestedFilters) {
      Object.keys(nestedFilters).forEach((key) => {
        // sloupecky pro nestedField
        const nestedFilter = nestedFilters[key];
        // ocisteni fieldu pokud je nested in nested -> odstranit zavorky
        const replaceField = key.split('[].').join('.') + '.';
        if (
          !!nestedFilter &&
          filters.some(
            (f) =>
              f.field.startsWith(replaceField) ||
              (f.field === 'ALL' &&
                !!nestedFilter.filter &&
                nestedFilter.onlyDefaultFilter != true),
          )
        ) {
          if (urlWhere === '') {
            urlWhere = urlWhere + 'where ';
          } else {
            urlWhere = urlWhere + ' and ';
          }
          urlWhere = `${urlWhere} ${nestedFilter?.field || ''}`;
        }
      });
    }

    // doplneni defaultnich filtru ktere nejsou nested
    const filterDefaultColumns = columns.filter(
      (c) =>
        c.applyDefaultValueIfFilterMissing == true &&
        !c.nested &&
        !filters.some((f) => f.field === (c.filterField || c.field)) &&
        c?.defaultValue?.field != null,
    );
    if (filterDefaultColumns?.length > 0) {
      const defaultFilter = filterDefaultColumns
        .map((f) => this.getUrlFilter(f.defaultValue, columns))
        .join(' and ');
      if (urlWhere === '') {
        urlWhere = urlWhere + 'where ';
      } else {
        urlWhere = urlWhere + ' and ';
      }
      urlWhere = `${urlWhere} ${defaultFilter}`;
    }

    // doplneni externi podminky
    if (!!extendTqlWhere) {
      if (urlWhere === '') {
        urlWhere = urlWhere + 'where ' + extendTqlWhere;
      } else {
        urlWhere = urlWhere + ' and ' + extendTqlWhere;
      }
    }
    // odfiltrovat samotne "where " (kdyz neexistuje zadna podminka)
    if (urlWhere.match(/where\s+$/)) {
      return '';
    }
    return urlWhere;
  }

  public getUrlOrderBy(
    sorts: SortModel[],
    nestedFilters: NestedFilters,
    externalTqlSort?: string,
    externalTqlSortForNested?: {
      [key: string]: any;
    },
  ): string {
    let urlSort = 'order by ';
    if (!!externalTqlSort) {
      return `${urlSort} ${externalTqlSort}`;
    }
    if (sorts && sorts.length > 0) {
      urlSort =
        urlSort +
        sorts
          .map((s) => {
            let filteredNestedFilters = nestedFilters
              ? Object.keys(nestedFilters)
                  .filter((key) => {
                    const replaceField = key.split('[].').join('.') + '.';
                    return s.field.startsWith(replaceField);
                  })
                  .sort((a, b) => b.split('[].').length - a.split('[].').length)
              : [];
            if (filteredNestedFilters?.length > 0) {
              const nestedFilter = filteredNestedFilters[0];
              const nestedInNested = nestedFilter.split('[].');
              const filterNested = nestedFilters[nestedFilter];
              if (nestedInNested.length == 1) {
                // jedna se o nested
                if (
                  !!externalTqlSortForNested &&
                  !!externalTqlSortForNested[nestedFilter]
                ) {
                  // definoval jsem si vlastni sort pro nested field, tak pouzi ten, misto sestaveneho na zaklade filtru
                  return `${
                    externalTqlSortForNested[nestedFilter]
                  }${s.field.replace(nestedFilter, '')} ${s.sortType}`;
                }
                return `${filterNested?.field}${s.field.replace(
                  nestedFilter,
                  '',
                )} ${s.sortType}`;
              } else if (nestedInNested.length > 1) {
                // jedna se o nested in nested (sample: tasks[].history)
                if (
                  !!externalTqlSortForNested &&
                  !!externalTqlSortForNested[nestedFilter]
                ) {
                  return `${
                    externalTqlSortForNested[nestedFilter]
                  }${s.field.replace(nestedInNested.join('.'), '')} ${
                    s.sortType
                  }`;
                }
                return `${filterNested?.field}${s.field.replace(
                  nestedInNested.join('.'),
                  '',
                )} ${s.sortType}`;
              }
            }
            return s.field + ' ' + s.sortType;
          })
          .join(', ');
    } else {
      urlSort = urlSort + 'id desc';
    }
    return urlSort;
  }

  private getUrlGroupBy(
    columns: TableColumn[],
    nestedFilters: NestedFilters,
  ): string {
    const targetGroupBy = [];
    columns
      .filter((c) => c.groupBy === true)
      .forEach((c) => {
        let resultField: string;
        if (!!c.nested) {
          const nestedInNested = c.nested.split('[].');
          if (nestedInNested.length == 1) {
            // jedna se o nested
            const filterNested = nestedFilters[c.nested];
            if (!!c?.displayField) {
              resultField = `${filterNested.field}${c.displayField.replace(
                c.nested,
                '',
              )}`;
            } else {
              resultField = `${filterNested.field}${c.field.replace(
                c.nested,
                '',
              )}`;
            }
          } else if (nestedInNested.length > 1) {
            // jedna se o nested in nested (sample: tasks[].history)
            const filterNested = nestedFilters[c.nested]; //(sample: duration > 5)
            if (!!c?.displayField) {
              resultField = `${filterNested.field}${c.displayField.replace(
                nestedInNested.join('.'),
                '',
              )}`;
            } else {
              resultField = `${filterNested.field}${c.field.replace(
                nestedInNested.join('.'),
                '',
              )}`;
            }
          }
        } else {
          // normalni sloupec
          if (c.displayField) {
            resultField = c.displayField;
          } else {
            resultField = c.field;
          }
        }
        targetGroupBy.push(resultField);
      });

    return targetGroupBy?.length > 0
      ? `group by ${targetGroupBy.filter((x) => !!x).join(', ')}`
      : '';
  }

  // Funkce sestavi nested filter pro field
  private createNestedFilter(
    filters: FilterModel[],
    nestedColumns: Array<TableColumn>,
    cleanColumns: TableColumn[],
    cleanField: string,
  ): {
    nestedQueryFilter: string;
    onlyDefaultFilter: boolean;
  } {
    let resultNestedFilter: string;
    // vsechny sloupce, pro ktere se maji defaultne aplikovat filter
    const nestedFilterDefaultColumns = nestedColumns.filter(
      (c) =>
        c.applyDefaultValueIfFilterMissing == true &&
        !filters.some((f) => f.field === (c.filterField || c.field)) &&
        c?.defaultValue?.field != null,
    );

    //sestaveni filtru pro kazdy nestedColumn (filtr musi byt bud jako fulltext a nebo musi byt obsazen mezi nested slupci)
    const nestedFilterColumns = filters.filter(
      (x) =>
        nestedColumns.some((f) => (f.filterField || f.field) === x.field) ||
        (x.field === 'ALL' &&
          nestedColumns.some((f) => f.filterFulltextSearch === true)),
    );
    nestedFilterColumns.forEach((f, i) => {
      if (i !== 0) {
        resultNestedFilter = resultNestedFilter + ' and ';
      }
      resultNestedFilter =
        (resultNestedFilter ?? '') +
        this.replaceAll(
          this.getUrlFilter(f, cleanColumns, true),
          cleanField,
          '',
        );
    });
    // doplneni defaultnich filtru
    nestedFilterDefaultColumns.forEach((c, i) => {
      if (i !== 0 || !!resultNestedFilter) {
        resultNestedFilter = resultNestedFilter + ' and ';
      }
      resultNestedFilter =
        (resultNestedFilter ?? '') +
        this.replaceAll(
          this.getUrlFilter(c.defaultValue, cleanColumns, true),
          cleanField,
          '',
        );
    });
    return {
      nestedQueryFilter: resultNestedFilter,
      onlyDefaultFilter: nestedFilterColumns.length == 0,
    };
  }

  private getUrlFilter(
    filter: FilterModel,
    columns: TableColumn[],
    isNested = false,
  ): string {
    if (filter) {
      const foundColumn = columns?.find(
        (c) => filter.field === c.field || filter.field === c.filterField,
      );
      const convertedFilter = this.convertRelativeOrLeaveBe(
        filter,
        foundColumn,
      );
      if (convertedFilter.field.length !== 0) {
        // specialni pripad vyhledani podle vsech dostupnych sloupcu (bere se i v potaz, zda se sestavuje fulltext filtr pro nested sloupce nebo ne)
        if (columns && convertedFilter.field === 'ALL') {
          const fulltextSearchColumns = columns.filter(
            (col) =>
              col.filterWidget != null &&
              col.filterFulltextSearch &&
              (isNested ? !!col.nested : !col.nested),
          );
          if (fulltextSearchColumns.length === 0) {
            return null;
          }
          return (
            '(' +
            fulltextSearchColumns
              .map((col) =>
                this.getFilerOperatorWithValue(
                  col.filterField || col.field,
                  convertedFilter,
                  col,
                ),
              )
              .join(' or ') +
            ')'
          );
        }

        const filterAddParam = foundColumn?.addUrlParam;
        const splitAddParamByAnd =
          filterAddParam != null ? filterAddParam.split('&') : [];
        const filtersByAddParam = splitAddParamByAnd
          .filter((p) => !!p)
          .map((p) => {
            const splitField = p.split('__');
            const splitOperator = splitField[1].split('=');
            return {
              field: splitField[0],
              operator: splitOperator[0],
              value: splitOperator[1],
            };
          });
        // bezne vyhledavani
        if (filtersByAddParam.length > 0) {
          // pokud sloupec ma definovany addParam
          const addParamQuery = filtersByAddParam
            .map((x) =>
              this.getFilerOperatorWithValue(
                x.field,
                x as FilterModel,
                foundColumn,
              ),
            )
            .join(' and ');
          return `${this.getFilerOperatorWithValue(
            convertedFilter.field,
            convertedFilter,
            foundColumn,
          )} and ${addParamQuery}`;
        }
        return this.getFilerOperatorWithValue(
          convertedFilter.field,
          convertedFilter,
          foundColumn,
        );
      }
    }
    return null;
  }

  private getFilerOperatorWithValue(
    field: string,
    convertedFilter: FilterModel,
    column: TableColumn,
  ): string {
    let result;
    const customTqlExpression = column?.customTqlExpression;
    let operator = convertedFilter.operator
      .toString()
      .includes('FilterOperator.')
      ? convertedFilter.operator.toString().split('.')[1]
      : convertedFilter.operator.toString();
    //HACK pro datumy, EQ posilat jako BTW, protoze TQL to neumi zpracovat
    if ((column?.filterWidget as any)?.isCalendarRangeFilter == true) {
      if (operator == 'eq') {
        operator = 'btw';
      } else if (
        Array.isArray(convertedFilter.value) &&
        column?.filterWidgetContext?.searchAsISOFormat == true &&
        operator != 'empty' &&
        operator != 'notempty'
      ) {
        operator = 'btw';
      }
    }
    if (
      Array.isArray(convertedFilter.value) &&
      convertedFilter.value.includes('NULL')
    ) {
      // jedna se o specialni atribut ve filtru... najdi mi hodnoty, ktere maji prazdnou polozku
      if (convertedFilter.value.includes('NULL')) {
        if (!!customTqlExpression) {
          result = this.createCustomTqlExpression(
            customTqlExpression,
            field,
            operator,
            convertedFilter,
          );
        } else {
          result = `${field} ${filterValueAndOperatorConverter(
            FilterOperator.empty,
            convertedFilter.value,
          )}`;
        }
      }
      if (convertedFilter.value.filter((x) => x != 'NULL')?.length > 0) {
        if (result != '') {
          result = result + ' or ';
        }
        if (!!customTqlExpression) {
          result = this.createCustomTqlExpression(
            customTqlExpression,
            field,
            operator,
            convertedFilter,
          );
        } else {
          result = `(${result} ${field} ${filterValueAndOperatorConverter(
            operator,
            convertedFilter.value.filter((x) => x != 'NULL'),
          )})`;
        }
      }
    } else {
      // bezny filtr
      if (!!customTqlExpression) {
        result = this.createCustomTqlExpression(
          customTqlExpression,
          field,
          operator,
          convertedFilter,
        );
      } else {
        result = `${field} ${filterValueAndOperatorConverter(
          operator,
          convertedFilter.value,
        )}`;
      }
    }
    return result;
  }

  /**
   * Sestavi na zaklade vyrazu vysledny filtr
   */
  private createCustomTqlExpression(
    customTqlExpression: string,
    field: string,
    operator: string,
    convertedFilter: FilterModel,
  ): string {
    const filter = customTqlExpression
      .replace(new RegExp('\\$field', 'g'), field)
      .replace(
        new RegExp('\\$operator', 'g'),
        filterOperatorConverter(operator),
      )
      .replace(new RegExp('\\$value\\[0\\]', 'g'), convertedFilter.value[0])
      .replace(new RegExp('\\$value\\[1\\]', 'g'), convertedFilter.value[1])
      .replace(
        new RegExp('\\$value', 'g'),
        filterValueAndOperatorConverter(
          operator,
          convertedFilter.value,
          true,
          true,
        ),
      );
    return this.replaceAll(filter, "''", "'");
  }

  /**
   * relativni filter prelozi na absolutni podle aktualniho data, ostatni filtery vrati beze zmeny
   * */
  private convertRelativeOrLeaveBe(
    filter: FilterModel,
    column?: TableColumn,
  ): FilterModel {
    return this.isRelative(filter)
      ? this.convertToNonRelative(filter, column)
      : filter;
  }

  private isRelative(filter: FilterModel): boolean {
    return (
      filter.operator === FilterOperator.gtr ||
      filter.operator === FilterOperator.ltr ||
      filter.operator === FilterOperator.btwr
    );
  }

  private convertToNonRelative(
    filter: FilterModel,
    column?: TableColumn,
  ): FilterModel {
    let newFilter = null;
    const datePipe = new DatePipe('cs');

    // hack pro searchAsISOFormatFrom
    if (
      column != null &&
      column?.filterWidgetContext?.searchAsISOFormat == true
    ) {
      const operator =
        filter.operator === FilterOperator.btwr
          ? FilterOperator.btw
          : filter.operator === FilterOperator.gtr
            ? FilterOperator.gte
            : FilterOperator.lte;
      const dateRound = filter.value[2];

      // mene nebo rovno
      if (operator === FilterOperator.lte) {
        const period = filter.value[1];
        const boundaryDate = DtlUtils.getDateFromPeriodAndToday(
          period,
          dateRound,
        );
        newFilter = {
          ...filter,
          operator: operator,
          value: [
            (column?.filterWidget as any).searchAsISOFormatFrom,
            datePipe.transform(boundaryDate, "yyyy-MM-dd'T'HH:mm:59.000"),
          ],
        };
      } // vetsi nebo rovno
      else if (operator === FilterOperator.gte) {
        const period = filter.value[0];
        const boundaryDate = DtlUtils.getDateFromPeriodAndToday(
          period,
          dateRound,
        );
        newFilter = {
          ...filter,
          operator: operator,
          value: [
            datePipe.transform(boundaryDate, "yyyy-MM-dd'T'HH:mm:00.000"),
            (column?.filterWidget as any).searchAsISOFormatTo,
          ],
        };
      } else if (operator === FilterOperator.btw) {
        const period0 = filter.value[0];
        const boundaryDate0 = DtlUtils.getDateFromPeriodAndToday(
          period0,
          dateRound,
        );
        const period1 = filter.value[1];
        const boundaryDate1 = DtlUtils.getDateFromPeriodAndToday(
          period1,
          dateRound,
        );
        newFilter = {
          ...filter,
          operator: operator,
          value: [
            datePipe.transform(boundaryDate0, "yyyy-MM-dd'T'HH:mm:00.000"),
            datePipe.transform(boundaryDate1, "yyyy-MM-dd'T'HH:mm:59.000"),
          ],
        };
      }
    } else {
      if (filter.operator === FilterOperator.btwr) {
        const periodFirst = filter.value[0];
        const periodSecond = filter.value[1];
        const dateRound = filter.value[2];

        const boundaryDateFirst = DtlUtils.getDateFromPeriodAndToday(
          periodFirst,
          dateRound,
        );
        const boundaryDateSecond = DtlUtils.getDateFromPeriodAndToday(
          periodSecond,
          dateRound,
        );
        newFilter = {
          ...filter,
          operator: FilterOperator.btw,
          value: [
            datePipe.transform(boundaryDateFirst, "yyyy-MM-dd'T'HH:mm:ss.SSSZ"),
            datePipe.transform(
              boundaryDateSecond,
              "yyyy-MM-dd'T'HH:mm:ss.SSSZ",
            ),
          ],
        };
      } else {
        const operator =
          filter.operator === FilterOperator.gtr
            ? FilterOperator.gte
            : FilterOperator.lte;
        const period = filter.value[0];
        const dateRound = filter.value[1];

        const boundaryDate = DtlUtils.getDateFromPeriodAndToday(
          period,
          dateRound,
        );

        newFilter = {
          ...filter,
          operator: operator,
          value: datePipe.transform(boundaryDate, "yyyy-MM-dd'T'HH:mm:ss.SSSZ"),
        };
      }
    }

    return newFilter;
  }

  private replaceAll(
    str: string,
    occurence: string,
    replaceWith: string,
  ): string {
    if (str == null) {
      return '';
    }
    return str.split(occurence).join(replaceWith);
  }
}
