import {Injectable} from '@angular/core';
import {SharedRequest} from '@tsm/framework/root';
import {Config, ConfigService} from '@tsm/framework/config';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {Docs, DtlDataSource} from '@tsm/framework/datasource';
import {select, Store} from '@ngrx/store';
import {getUiConfig} from '@tsm/ui-config';
import * as objectPath from 'object-path';
import {translation} from '@tsm/shared-i18n';

@Docs({
  detail: 'configUi(attribute: string): string',
  description: {
    about: translation.shared.urlAbout,
    params: [
      {
        name: translation.shared.urlAttributeName,
        about: translation.shared.urlAttributeThe,
      },
    ],
    example: "configUi('url')",
  },
})
@Injectable()
export class ConfigUrlDataSource implements DtlDataSource<string> {
  value: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private store$: Store,
    private config: ConfigService<Config>,
  ) {}

  pushParams(params) {
    this.value.next(params);
  }

  @SharedRequest
  getRemoteData(attribute: string): Observable<string> {
    return this.store$.pipe(
      select(getUiConfig),
      map(
        (uiConfig) =>
          objectPath.get(uiConfig?.data?.url, attribute) ||
          objectPath.get(this.config.value.externalUrls, attribute),
      ),
    );
  }

  create(): Observable<string> {
    return this.value.asObservable().pipe(
      filter((x: string) => x[0] != null),
      switchMap((x: string) => this.getRemoteData(x[0])),
    );
  }
}
