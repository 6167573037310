import {IdentifiedPack} from '@tsm/framework/root';
import {Form} from '../models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteFormError,
  DeleteFormSuccess,
  DiffForm,
  DiffFormError,
  DiffFormSuccess,
  LoadAllForms,
  LoadAllFormsError,
  LoadAllFormsSuccess,
  LoadFormByCodeSuccess,
  LoadFormById,
  LoadFormByIdError,
  LoadFormByIdSuccess,
  UpsertForm,
  UpsertFormError,
  UpsertFormSuccess,
} from '../actions';
import {addDays} from 'date-fns';

export type FormState = EntityState<IdentifiedPack<Form>>;

export const formAdapter: EntityAdapter<IdentifiedPack<Form>> =
  createEntityAdapter<IdentifiedPack<Form>>({});
export const formInitialState: FormState = formAdapter.getInitialState({});

export const formReducer = createReducer(
  formInitialState,

  on(LoadAllForms, (state, {}) => ({...state, loading: true})),

  on(LoadAllFormsSuccess, (state, {entities}) => ({
    ...formAdapter.addMany(
      entities.map((ent) => ({
        id: ent.id,
        data: ent,
        loading: false,
        error: null,
        validUntil: addDays(new Date(), 1),
      })),
      state,
    ),
    error: null,
  })),

  on(UpsertForm, (state, {entity}) =>
    formAdapter.upsertOne(
      {
        ...state[entity.id],
        id: entity.id,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(UpsertFormSuccess, (state, {entity}) =>
    formAdapter.upsertOne(
      {
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(DeleteFormSuccess, (state, {id}) => ({
    ...formAdapter.removeOne(id, state),
    loading: false,
  })),

  on(LoadAllFormsError, UpsertFormError, DeleteFormError, (state, {error}) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(LoadFormById, (state, {id}) =>
    formAdapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadFormByIdSuccess, LoadFormByCodeSuccess, (state, {entity}) =>
    formAdapter.upsertOne(
      {
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadFormByIdError, (state, {id, error}) =>
    formAdapter.upsertOne(
      {
        id: id,
        data: null,
        loading: false,
        error: error,
      },
      state,
    ),
  ),

  on(DiffForm, (state, {diffEntities}) =>
    formAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          loading: true,
          error: null,
        };
      }),
      state,
    ),
  ),
  on(DiffFormSuccess, (state, {forms}) =>
    formAdapter.upsertMany(
      forms.map((form) => {
        return {
          id: form.id,
          data: form,
          error: null,
          loading: false,
        };
      }),
      state,
    ),
  ),

  on(DiffFormError, (state, {diffEntities, error}) =>
    formAdapter.upsertMany(
      diffEntities.map((diffEntity) => {
        return {
          id: diffEntity.id,
          data: state.entities[diffEntity.id].data,
          loading: false,
          error: error,
        };
      }),
      state,
    ),
  ),
);
