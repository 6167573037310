import {RuntimeInfo} from '../models';
import {
  DeleteRuntimeInfoAction,
  RuntimeInfoLoadSuccessAction,
} from '../actions';
import {createReducer, on} from '@ngrx/store';

export const initialState: RuntimeInfo = {
  id: null,
  name: null,
  code: null,
  status: null,
  period: null,
  uugList: [],
  urList: [],
  upList: [],
  personalId: null,
  givenName: null,
  familyName: null,
  // preferredUsername: null,
  profileImage: null,
  mimeType: null,
  language: null,
  type: null,
  authentication: null,
};

export const runtimeInfoReducer = createReducer(
  initialState,
  on(RuntimeInfoLoadSuccessAction, (state, {runtimeInfo}) => ({
    ...state,
    ...runtimeInfo,
  })),

  on(DeleteRuntimeInfoAction, (state, {}) => ({
    ...state,
    ...initialState,
  })),
);
