import {createReducer, on} from '@ngrx/store';

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  ClearMyWorklogByFilter,
  DeleteMyWorklog,
  DeleteMyWorklogSuccess,
  LoadAllMyWorklog,
  LoadAllMyWorklogError,
  LoadAllMyWorklogSuccess,
  LoadMyWorklogByFilter,
  LoadMyWorklogByFilterError,
  LoadMyWorklogByFilterSuccess,
  UpsertMyWorklog,
  UpsertMyWorklogSuccess,
} from '../actions';
import {Worklog} from '../models';

export interface WorklogMyState {
  id: string;
  worklogs: Worklog[];
  error: any;
  loading: boolean;
}

export const worklogMyAdapter: EntityAdapter<WorklogMyState> =
  createEntityAdapter<WorklogMyState>({});
export const initialState: EntityState<WorklogMyState> =
  worklogMyAdapter.getInitialState({});

export const worklogMyReducer = createReducer(
  initialState,
  on(LoadAllMyWorklog, LoadMyWorklogByFilter, (state, {userId}) =>
    worklogMyAdapter.upsertOne(
      {
        id: userId,
        // aby neproblikavali notifikace pri update
        worklogs: state.entities[userId]?.worklogs || [],
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadAllMyWorklogSuccess, (state, {userId, worklogs}) =>
    worklogMyAdapter.upsertOne(
      {
        id: userId,
        worklogs: worklogs,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(LoadAllMyWorklogError, (state, {userId, error}) =>
    worklogMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(LoadMyWorklogByFilterSuccess, (state, {userId, worklogs}) => {
    const currentWorklogs = state.entities[userId]?.worklogs;
    return worklogMyAdapter.upsertOne(
      {
        id: userId,
        worklogs: [
          ...currentWorklogs,
          ...worklogs.filter(
            (x) => !currentWorklogs.map((y) => y.id).includes(x.id),
          ),
        ],
        loading: false,
        error: null,
      },
      state,
    );
  }),

  on(ClearMyWorklogByFilter, (state, {userId}) => {
    return worklogMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          worklogs: [],
          loading: false,
          error: null,
        },
      },
      state,
    );
  }),

  on(LoadMyWorklogByFilterError, (state, {userId, error}) =>
    worklogMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  on(UpsertMyWorklog, (state, {worklog, userId}) =>
    worklogMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(UpsertMyWorklogSuccess, (state, {worklog, userId}) =>
    worklogMyAdapter.upsertOne(
      {
        id: userId,
        worklogs: [
          ...(state.entities[userId]?.worklogs?.filter(
            (i) => i.id !== worklog.id,
          ) ?? []),
          worklog,
        ],
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteMyWorklog, (state, {id, userId}) =>
    worklogMyAdapter.updateOne(
      {
        id: userId,
        changes: {
          ...state.entities[userId],
          loading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteMyWorklogSuccess, (state, {id, userId}) =>
    worklogMyAdapter.upsertOne(
      {
        id: userId,
        worklogs: state.entities[userId].worklogs.filter((i) => i.id !== id),
        loading: false,
        error: null,
      },
      state,
    ),
  ),
);
