import {createAction, props} from '@ngrx/store';
import {TsmError} from '@tsm/framework/http';
import {CountAttachmentsModel} from '../models';

export const LoadCountAttachments = createAction(
  '[AttachmentType] Load CountAttachments',
  props<{
    ownerType: string;
    ownerIds: string | string[];
  }>(),
);
export const LoadCountAttachmentsSuccess = createAction(
  '[AttachmentType] Load CountAttachments Success',
  props<{
    countAttachments: CountAttachmentsModel[];
  }>(),
);
export const LoadCountAttachmentsError = createAction(
  '[AttachmentType] Load CountAttachments Error',
  props<TsmError>(),
);
