<input
  [ngStyle]="{display: readonly ? 'none' : ''}"
  [style]="style"
  type="text"
  #inputElement
  [(ngModel)]="val"
  [tabindex]="tabindex"
  [pKeyFilter]="keyFilter"
  class="p-inputtext p-corner-all p-state-default p-component"
  data-cy="dtl-form-input-key-filter"
/>
<span
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
  [ngClass]="
    val == null || val === '' ? 'readonly-text empty' : 'readonly-text'
  "
>
  {{ val == null || val === '' ? 'none' : val }}
</span>
