import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteAllUserUserGroups,
  DeleteAllUserUserGroupsError,
  DeleteAllUserUserGroupsSuccess,
  DeleteUserUserGroupSuccess,
  LoadUserUserGroupById,
  LoadUserUserGroupByIdSuccess,
  LoadUserUserGroupsByGroupCodeError,
  LoadUserUserGroupsByGroupCodeSuccess,
  LoadUserUserGroupsByGroupId,
  LoadUserUserGroupsByGroupIdError,
  LoadUserUserGroupsByGroupIdSuccess,
  LoadUserUserGroupsByUserId,
  LoadUserUserGroupsByUserIdError,
  LoadUserUserGroupsByUserIdSuccess,
  LoadUserUserGroupsSuccess,
  SaveAllUserUserGroups,
  SaveAllUserUserGroupsError,
  SaveAllUserUserGroupsSuccess,
  UpsertUserUserGroupSuccess,
} from '../actions';
import {UserUserGroup} from '../model';
import {addDays} from 'date-fns';

export type UserUserGroupState = EntityState<IdentifiedPack<UserUserGroup>>;

export const adapter: EntityAdapter<IdentifiedPack<UserUserGroup>> =
  createEntityAdapter<IdentifiedPack<UserUserGroup>>({});

export const initialState: UserUserGroupState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(LoadUserUserGroupsSuccess, (state, {entities}) => ({
    ...adapter.addMany(
      entities.map((statusOnly) => ({
        id: statusOnly.id,
        data: statusOnly,
        loading: false,
        error: null,
        validUntil: addDays(new Date(), 1),
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(LoadUserUserGroupById, (state, {id}) =>
    adapter.upsertOne(
      {
        ...state.entities[id],
        id: id,
        loading: true,
        error: null,
      },
      state,
    ),
  ),

  on(LoadUserUserGroupByIdSuccess, (state, {userUserGroup}) =>
    adapter.upsertOne(
      {
        id: userUserGroup.id,
        data: userUserGroup,
        loading: false,
        error: null,
        validUntil: null,
      },
      {...state, error: null, loading: false},
    ),
  ),

  on(
    LoadUserUserGroupsByUserIdSuccess,
    LoadUserUserGroupsByGroupIdSuccess,
    LoadUserUserGroupsByGroupCodeSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((userUserGroup) => ({
          id: userUserGroup.id,
          data: userUserGroup,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadUserUserGroupsByUserId,
    LoadUserUserGroupsByGroupId,
    SaveAllUserUserGroups,
    DeleteAllUserUserGroups,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadUserUserGroupsByUserIdError,
    LoadUserUserGroupsByGroupIdError,
    LoadUserUserGroupsByGroupCodeError,
    SaveAllUserUserGroupsError,
    DeleteAllUserUserGroupsError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertUserUserGroupSuccess, (state, {userUserGroup}) =>
    adapter.upsertOne(
      {
        id: userUserGroup.id,
        data: userUserGroup,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteUserUserGroupSuccess, (state, userRole) =>
    adapter.removeOne(userRole.id, state),
  ),

  on(SaveAllUserUserGroupsSuccess, (state, {entities}) => ({
    ...adapter.upsertMany(
      entities.map((entity) => ({
        id: entity.id,
        data: entity,
        loading: false,
        error: null,
      })),
      state,
    ),
    error: null,
    loading: false,
  })),

  on(DeleteAllUserUserGroupsSuccess, (state, {ids}) => ({
    ...adapter.removeMany(ids, state),
    error: null,
    loading: false,
  })),
);
