import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, exhaustMap, map, mergeMap} from 'rxjs/operators';
import {
  DeleteSkillAssignment,
  DeleteSkillAssignmentError,
  DeleteSkillAssignmentSuccess,
  LoadSkillAssignmentsBySkillId,
  LoadSkillAssignmentsBySkillIdError,
  LoadSkillAssignmentsBySkillIds,
  LoadSkillAssignmentsBySkillIdSuccess,
  LoadSkillAssignmentsByUserId,
  LoadSkillAssignmentsByUserIdError,
  LoadSkillAssignmentsByUserIds,
  LoadSkillAssignmentsByUserIdSuccess,
  UpsertMoreSkillAssignment,
  UpsertMoreSkillAssignmentError,
  UpsertMoreSkillAssignmentSuccess,
  UpsertSkillAssignment,
  UpsertSkillAssignmentError,
  UpsertSkillAssignments,
  UpsertSkillAssignmentsError,
  UpsertSkillAssignmentsSuccess,
  UpsertSkillAssignmentSuccess,
} from '../actions';
import {EntityIdentif, SkillAssignment} from '../model';
import {CommonApiService, SkillAssignmentService} from '../service';
import {ToastService, ToastSeverity} from '@tsm/framework/toast';
import {Router} from '@angular/router';
import {translation} from '../i18n';

@Injectable()
export class SkillAssignmentEffects {
  translation = translation;

  loadMultipleByUserIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSkillAssignmentsByUserIds),
      mergeMap((action) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_SKILL,
            'user.id__in=' + action.userIds.join(','),
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadSkillAssignmentsByUserIdSuccess({entities: env.data})
                : LoadSkillAssignmentsByUserIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSkillAssignmentsByUserId),
      exhaustMap(({userId}) => {
        return this.commonApiService
          .getAllFilterable(EntityIdentif.USER_SKILL, 'user.id__eq=' + userId)
          .pipe(
            map((env) => {
              return env.success
                ? LoadSkillAssignmentsByUserIdSuccess({entities: env.data})
                : LoadSkillAssignmentsByUserIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadBySkillId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSkillAssignmentsBySkillId),
      exhaustMap(({skillId}) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_SKILL,
            'entityCatalogSpecificationId__eq=' + skillId,
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadSkillAssignmentsBySkillIdSuccess({entities: env.data})
                : LoadSkillAssignmentsBySkillIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  loadMultipleBySkillIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSkillAssignmentsBySkillIds),
      mergeMap((action) => {
        return this.commonApiService
          .getAllFilterable(
            EntityIdentif.USER_SKILL,
            'entityCatalogSpecificationId.id__in=' + action.skillIds.join(','),
          )
          .pipe(
            map((env) => {
              return env.success
                ? LoadSkillAssignmentsBySkillIdSuccess({entities: env.data})
                : LoadSkillAssignmentsBySkillIdError({error: env.error});
            }),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertSkillAssignment),
      concatMap(({skillAssignment}) =>
        this.commonApiService
          .upsertEntity(
            EntityIdentif.USER_SKILL,
            skillAssignment,
            skillAssignment.id,
          )
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.skillSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertSkillAssignmentSuccess({
                  skillAssignment: env.data,
                });
              } else {
                const message =
                  env.error.serverError === 'PrintableError'
                    ? translation.userManagementService.effects
                        .skillAlreadyAssigned
                    : translation.userManagementService.effects.regionSaveError;
                this.ts.showError(env.error, message);
                return UpsertSkillAssignmentError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  upserts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertSkillAssignments),
      concatMap(({entities}) =>
        this.commonApiService
          .postAllEntities(EntityIdentif.USER_SKILL, entities)
          .pipe(
            map((env) => {
              if (env.success) {
                this.ts.showToast(
                  translation.userManagementService.effects.skillSaveSuccess,
                  ToastSeverity.SUCCESS,
                  3000,
                );
                return UpsertSkillAssignmentsSuccess({entities: env.data});
              } else {
                const message =
                  env.error.serverError === 'PrintableError'
                    ? translation.userManagementService.effects
                        .skillAlreadyAssigned
                    : translation.userManagementService.effects.regionSaveError;
                this.ts.showError(env.error, message);
                return UpsertSkillAssignmentsError(env.error);
              }
            }),
          ),
      ),
    ),
  );

  upsertMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertMoreSkillAssignment),
      concatMap(({rows}) =>
        this.skillAssignmentService.upsertMoreSkillAssignment(rows).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.skillSaveSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return UpsertMoreSkillAssignmentSuccess({entities: env.data});
            } else {
              const message =
                env.error.serverError === 'PrintableError'
                  ? translation.userManagementService.effects
                      .skillAlreadyAssigned
                  : translation.userManagementService.effects.regionSaveError;

              this.ts.showError(env.error, message);
              return UpsertMoreSkillAssignmentError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteSkillAssignment),
      concatMap(({id}) =>
        this.commonApiService.deleteEntity(EntityIdentif.USER_SKILL, id).pipe(
          map((env) => {
            if (env.success) {
              this.ts.showToast(
                translation.userManagementService.effects.skillDeleteSuccess,
                ToastSeverity.SUCCESS,
                3000,
              );
              return DeleteSkillAssignmentSuccess({id: id});
            } else {
              this.ts.showError(
                env.error,
                translation.userManagementService.effects.skillDeleteFailure,
              );
              return DeleteSkillAssignmentError(env.error);
            }
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonApiService: CommonApiService<
      SkillAssignment,
      SkillAssignment
    >,
    private store: Store<any>,
    private router: Router,
    private ts: ToastService,
    private skillAssignmentService: SkillAssignmentService,
  ) {}
}
