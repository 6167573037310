import {Pipe, untracked} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  selectListingProfileByCode,
  selectListingProfileByCodeAndListingCode,
} from '../selectors';
import {map} from 'rxjs/operators';
import {
  LoadListingProfileByCode,
  LoadListingProfileByCodeAndListingCode,
} from '../actions';
import {AbstractCachedLoaderPipe} from '@tsm/framework/root';

@Pipe({
  name: 'listingProfileCode',
  pure: true,
})
export class ListingProfileCodePipe extends AbstractCachedLoaderPipe {
  requiresRowData = false;
  private _listingCode: string;

  constructor(protected store: Store) {
    super(store);
  }

  setObservable(id: string, listingCode = 'all', field: string = 'name') {
    this.destroy();
    this._id = id;
    this._listingCode = listingCode;

    this._obs$ = this.store.pipe(
      select(
        listingCode === 'all'
          ? selectListingProfileByCode(id)
          : selectListingProfileByCodeAndListingCode(id, listingCode),
      ),
      map((it) => {
        if (!it || !it.data) {
          return id;
        }
        if (field === 'all') {
          return it.data;
        }

        let value = it.data;
        const fieldProps = field.split('.');
        for (const nestedField of fieldProps) {
          value = value[nestedField];
        }
        return value;
      }),
    );
  }

  checkIfDispatchNeeded() {
    this._checkSubscription = this.store
      .pipe(
        select(
          this._listingCode === 'all'
            ? selectListingProfileByCode(this._id)
            : selectListingProfileByCodeAndListingCode(
                this._id,
                this._listingCode,
              ),
        ),
      )
      .subscribe((x) => {
        if (!x) {
          untracked(() => {
            if (this._listingCode === 'all') {
              this.store.dispatch(LoadListingProfileByCode({code: this._id}));
            } else {
              this.store.dispatch(
                LoadListingProfileByCodeAndListingCode({
                  code: this._id,
                  listingCode: this._listingCode,
                }),
              );
            }
          });
        }
      });
  }
}
