import {Injectable} from '@angular/core';
import {Attachment, AttachmentTypeModel} from '../models';
import {Store} from '@ngrx/store';
import {
  DeleteAllDataAttachment,
  DeleteDataAttachment,
  DownloadDataAttachment,
  LoadDataAttachment,
  LoadDataAttachmentOld,
  LoadDataAttachmentSuccess,
  UploadDataAndDeleteAllAttachment,
  UploadDataAttachment,
} from '../actions';
import {AttachmentState} from '../reducers';
import {from, Observable} from 'rxjs';
import {
  FilterModel,
  FilterOperator,
  ListingProfile,
  ListingService,
  Table,
  TableDefault,
  TableShowConfig,
  TableType,
} from '@tsm/listing-lib/service';
import {filter, tap} from 'rxjs/operators';
import {RuntimeService} from '@tsm/runtime-info';
import {Config, ConfigService} from '@tsm/framework/config';
import {translation as translationShared} from '@tsm/shared-i18n';
import {DateFormatPipe} from '@tsm/shared';
import {translation} from '../i18n';
import {UserPipe} from '@tsm/user-management/service';
import {getUserId} from '@tsm/framework/functions';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  private readonly BASE_URL: string;
  translationShared = translationShared;
  translation = translation;

  private defaults: TableDefault = {
    pageSize: 200,
    columns: [
      // {
      //   field: 'id',
      //   header: translationShared.shared.id,
      //   tooltip: translationShared.shared.id,
      //   visible: false,
      //   filterWidget: import(
      //   '@tsm/listing-lib/widgets/filters/dtl-filter-input'
      // ).then((x) => x.DtlFilterInputComponent)
      // },
      {
        field: 'name',
        header: translation.attachments.fileName,
        tooltip: translation.attachments.fileName,
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-input'
        ).then((x) => x.DtlFilterInputComponent),

        filterFulltextSearch: true,
        width: '100px',
        visible: false,
      },
      {
        field: 'whenInserted',
        header: translation.attachments.fileUploadDate,
        tooltip: translation.attachments.fileUploadDate,
        width: '200px',
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-calendar-range'
        ).then((x) => x.DtlFilterCalendarRangeComponent),
        converter: DateFormatPipe,
        converterParams: ['dateTimeS'],
        visible: false,
      },
      {
        field: 'attachmentType.name',
        header: translation.attachments.type,
        tooltip: translation.attachments.type,
        filterWidget: import(
          '@tsm/listing-lib/widgets/filters/dtl-filter-input'
        ).then((x) => x.DtlFilterInputComponent),
        filterFulltextSearch: true,
        width: '100px',
        visible: false,
      },
      {
        field: 'whoInserted',
        header: translation.attachments.fileUploaderName,
        tooltip: translation.attachments.fileUploaderName,
        filterWidget: import(
          '@tsm/user-management/widgets/filters/user-filter'
        ).then((x) => x.UserFilterComponent),
        converter: UserPipe,
        sortDisabled: true,
      },
    ],
  };

  constructor(
    private store: Store<AttachmentState>,
    private runtimeService: RuntimeService,
    private listingService: ListingService,
    private config: ConfigService<Config>,
  ) {
    this.BASE_URL = config.value.apiUrls.dms;
  }

  uploadFiles(ownerId: string, ownerType: string, attachments: Attachment[]) {
    this.store.dispatch(
      UploadDataAttachment({ownerId: ownerId, ownerType, attachments}),
    );
  }

  uploadAndDeleteAll(
    ownerId: string,
    ownerType: string,
    attachments: Attachment[],
  ) {
    this.store.dispatch(
      UploadDataAndDeleteAllAttachment({
        ownerId: ownerId,
        ownerType: ownerType,
        attachments: attachments,
      }),
    );
  }

  downloadFile(ownerId: string, ownerType: string, attachment: Attachment) {
    this.store.dispatch(
      DownloadDataAttachment({
        ownerId: ownerId,
        ownerType: ownerType,
        attachment: attachment,
        showData: false,
      }),
    );
  }

  showFile(ownerId: string, ownerType: string, attachment: Attachment) {
    this.store.dispatch(
      DownloadDataAttachment({
        ownerId: ownerId,
        ownerType: ownerType,
        attachment: attachment,
        showData: true,
      }),
    );
  }

  deleteFile(ownerId: string, ownerType: string, attachment: Attachment) {
    this.store.dispatch(
      DeleteDataAttachment({ownerId: ownerId, ownerType, attachment}),
    );
  }

  deleteAllFiles(ownerId: string, ownerType: string) {
    this.store.dispatch(DeleteAllDataAttachment({ownerId: ownerId, ownerType}));
  }

  loadAttachmentByOwnerIdAndOwnerTypeOld(
    ownerId: string,
    ownerType: string,
    readonly = false,
    onlyWithThumbnail = false,
  ) {
    this.store.dispatch(
      LoadDataAttachmentOld({
        ownerId: ownerId,
        ownerType,
        readonly,
        onlyWithThumbnail,
      }),
    );
  }

  loadAttachmentByOwnerIdAndOwnerType(
    ownerId: string,
    ownerType: string,
    readonly: boolean,
    listingTypeCode: string,
    profileId: string,
    showConfig: TableShowConfig,
  ): Observable<Table> {
    this.store.dispatch(LoadDataAttachment({ownerId, ownerType, readonly}));
    return this.listingService
      .getTable(
        'tsm-attachment-' + ownerId,
        {
          type: listingTypeCode,
          url: this.config.value.apiUrls.dms + '/attachment/filtering',
        },
        {
          ...this.defaults,
          profileId,
          filters: [
            {
              field: 'ownerId',
              operator: FilterOperator.eq,
              value: ownerId,
              visible: false,
              readonly: true,
            },
            {
              field: 'ownerType',
              operator: FilterOperator.eq,
              value: ownerType,
              visible: false,
              readonly: true,
            },
            {
              field: 'withRelEntity',
              operator: FilterOperator.eq,
              value: true,
              readonly: true,
              visible: false,
            },
          ],
        },
        showConfig,
      )
      .pipe(
        filter((x) => x.dataLoading === false && x.profilesLoading === false),
        tap((tableState) => {
          this.store.dispatch(
            LoadDataAttachmentSuccess({
              ownerType: ownerType,
              ownerId: ownerId,
              attachments: tableState.data.items as Array<Attachment>,
              readonly: readonly,
            }),
          );
          // this.store.dispatch(LoadDataAttachmentError({
          //   ownerType: ownerType,
          //   ownerId: ownerId,
          //   error: 'Chyba pri nacteni dat!'
          // }));
        }),
      );
  }

  createAttachmentByBlob(
    ownerId: string,
    ownerType: string,
    blob: Blob,
    mimeType: string,
    fileName: string,
    attachmentType: AttachmentTypeModel,
  ): Observable<Attachment> {
    return from(
      new Promise<Attachment>((res) => {
        const reader = new FileReader();
        reader.onload = () => {
          res({
            attachmentType: attachmentType,
            data: (<string>reader.result).split(',')[1],
            date: new Date(),
            formData: null,
            lat: null,
            lng: null,
            mimeType: mimeType,
            name: fileName,
            ownerId: ownerId,
            ownerType: ownerType,
            size: blob.size + '',
            sizeUnit: 'kB',
          });
        };
        reader.readAsDataURL(blob);
      }),
    );
  }

  filterValueChanged(ownerId: string, ownerType: string, value: FilterModel[]) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      true,
    );
    this.listingService.setFilters('tsm-attachment-' + ownerId, value);
  }

  selectedProfileIdChanged(
    ownerId: string,
    ownerType: string,
    state: {
      profileId: string;
      showConfig: TableShowConfig;
    },
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      false,
    );
    this.listingService.setSelectedProfile(
      'tsm-attachment-' + ownerId,
      state.profileId,
      state.showConfig,
    );
  }

  saveProfile(
    ownerId: string,
    ownerType: string,
    state: {profile: ListingProfile; type: TableType},
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      false,
    );
    this.listingService.saveProfile(
      'tsm-attachment-' + ownerId,
      state.profile,
      state.type,
    );
  }

  deleteProfile(
    ownerId: string,
    ownerType: string,
    state: {profile: ListingProfile; type: TableType},
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      false,
    );
    this.listingService.deleteProfile(
      'tsm-attachment-' + ownerId,
      state.profile,
      state.type,
    );
  }

  clearProfile(
    ownerId: string,
    ownerType: string,
    showConfig: TableShowConfig,
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      false,
    );
    this.listingService.clearProfile('tsm-attachment-' + ownerId, showConfig);
  }

  resetData(ownerId: string, ownerType: string) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      false,
    );
    this.listingService.resetData('tsm-attachment-' + ownerId);
  }

  refreshAttachments(ownerId: string, ownerType: string) {
    this.listingService.refreshData('tsm-attachment-' + ownerId);
  }

  saveAsDefaultProfile(
    ownerId: string,
    ownerType: string,
    state: {
      profile: ListingProfile;
      type: TableType;
    },
    listingId?: string,
  ) {
    const entity = {
      ...state.profile,
    };
    if (!entity.userId) {
      entity.userId = getUserId();
    }
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + (listingId || ownerId),
      false,
    );
    this.listingService.saveAsDefaultProfile(
      'tsm-attachment-' + (listingId || ownerId),
      entity,
      state.type,
      false,
    );
  }

  saveAsDefaultAllProfile(
    ownerId: string,
    ownerType: string,
    state: {profile: ListingProfile; type: TableType},
  ) {
    this.listingService.customChangeSettingTable(
      'tsm-attachment-' + ownerId,
      false,
    );
    this.listingService.saveAsDefaultAllProfile(
      'tsm-attachment-' + ownerId,
      state.profile,
      state.type,
      false,
    );
  }
}
