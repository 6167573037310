import {createReducer, on} from '@ngrx/store';

import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';

import {
  AddColumns,
  AddKanbanList,
  AllCheckboxChanged,
  ChangeColumn,
  ChangeFiltersKanbanList,
  ChangeKanbanList,
  ChangeOrderKanbanLists,
  ClearProfileSuccess,
  ClearTableStateByListingType,
  ColorsChanged,
  ColumnsChanged,
  ColumnsResized,
  CustomChangeSettingTable,
  DataViewChangeMode,
  DataViewChangeModeWithoutRefresh,
  DeleteProfile,
  DeleteProfileError,
  DeleteProfileSuccess,
  ExportingData,
  FilterChanged,
  FiltersChanged,
  FiltersChangedWithoutRefresh,
  HasBulkButtonsTemplateChanged,
  InitColumnsChanged,
  LoadData,
  LoadDataError,
  LoadDataKanbanList,
  LoadDataSuccess,
  LoadProfiles,
  LoadProfilesError,
  LoadProfilesSuccess,
  LoadTable,
  LoadTableError,
  LoadTableSuccess,
  LoadTotalData,
  LoadTotalDataError,
  LoadTotalDataSuccess,
  PageAndPageSizeChanged,
  PageChanged,
  PageSizeChanged,
  PageSizeChangedWithoutRefresh,
  RefreshData,
  RefreshDataAndClearSelected,
  RefreshProfiles,
  RefreshProfilesError,
  RefreshProfilesSuccess,
  RefreshProfilesSuccessWithoutDataReload,
  RemoveColumns,
  RemoveKanbanList,
  RemoveListing,
  RemoveProfileWithoutDataReload,
  ResetData,
  ResetTableStateWithoutDataReload,
  RowIdFieldChanged,
  SaveAsDefaultAllProfile,
  SaveAsDefaultAllProfileSuccess,
  SaveProfile,
  SaveProfileError,
  SaveProfileSuccess,
  SaveUserDefaultProfile,
  ScrollHeight,
  ScrollWidth,
  SelectedProfileChangedSuccess,
  SelectedProfileDuringSetupChangedSuccess,
  SelectedRowIdChanged,
  SelectedRowIdsChanged,
  SetEmptyData,
  SetLoading,
  SetOptions,
  SetRefresh,
  SetTree,
  SetUpTableSettings,
  ShowBulkEditChanged,
  ShowConfigChanged,
  ShowConfigSet,
  SortsChanged,
  TqlExtendChanged,
  TqlQueryParamsChanged,
  TreeNodeExpandAllBackend,
  TreeNodeExpandCollapse,
  TreeNodeExpandCollapseFinished,
  UpdateRowData,
  UrlChanged,
} from '../actions';
import {
  ColorModel,
  DataViewModeEnum,
  ExportStatus,
  FilterModel,
  FilterOperator,
  ListingProfile,
  Table,
  TableColumn,
  TableKeyType,
  TableType,
} from '../models';
import {distinctArrays, getUserId} from '@tsm/framework/functions';
import {DtlUtils, getLocalStorageListing, mergeFilters} from '../utils';

const DEFAULT_HEIGHT = '250px';
const DEFAULT_ALL_PROFILE = 'all';

export const listingAdapter: EntityAdapter<Table> =
  createEntityAdapter<Table>();
export const initialState: EntityState<Table> = listingAdapter.getInitialState(
  {},
);

// prevezme definici novych sloupcu (např. z profilu pokud chci profilem prepsat stavajici sloupce, v SetUp
// to chci naopak pokud existuje profil) a nahradi z nich nektere vlastnosti v currentColumns
export function mergeColumns(newColumns, currentColumns, empty = true) {
  if (!newColumns) {
    return currentColumns;
  }
  if (!currentColumns) {
    return newColumns;
  }

  const presetColumns = newColumns
    .map((newCol) => {
      const currentColumn = currentColumns.find(
        (currCol) => currCol.field === newCol.field,
      );
      return currentColumn
        ? {...currentColumn, ...newCol} // merge vlastnosti
        : empty
          ? null
          : {...newCol}; // neexistujici sloupec v profilu (asi z aplikace smazan)
    })
    .filter((col) => !!col); // neexistujici sloupce se ignoruji

  // ve vracenych sloupcich budou nejprve ty z profilu a pote ostatni, ale nejsou jako viditelne
  const resultColumns = [
    ...presetColumns,
    ...currentColumns
      .filter(
        (col) => !presetColumns.find((prCol) => prCol.field === col.field),
      )
      .map((col) => {
        return {...col, visible: false};
      }),
  ];
  return resultColumns;
}

/**
 * Oprava displayValue u filtrů relativních k aktuálnímu času.
 * Je nutné přepočítat vždy při aktivaci profilu. (Jinak by tam zůstala uložená hodnota, nebo hodnota z posledního načtení profilu)
 * @param profile
 */
function fixRelativeFilters(
  filters: FilterModel[],
  columns: TableColumn[],
): FilterModel[] {
  if (filters == null) {
    return [];
  }
  const newfilters = [];
  for (let i = 0; i < filters.length; i++) {
    const f: FilterModel = {...filters[i]};
    // zde budeme ignorovat uloženou displayValue -
    // v profilu je uložena stará hodnota, ale my už máme novou - je nový čas, mnoho vody uteklo
    if (f.value && Array.isArray(f.value)) {
      if (f.operator === FilterOperator.btwr) {
        f.displayValue = DtlUtils.formatRelative(
          f.operator,
          f.value,
          true,
          f.value[2],
        );
      } else if (
        f.operator === FilterOperator.ltr ||
        f.operator === FilterOperator.gtr
      ) {
        if (
          columns.find((c) => c.filterField === f.field || c.field === f.field)
            ?.filterWidgetContext?.searchAsISOFormat == true
        ) {
          f.displayValue = DtlUtils.formatRelative(
            f.operator,
            f.value,
            true,
            f.value[2],
            true,
          );
        } else {
          f.displayValue = DtlUtils.formatRelative(
            f.operator,
            f.value,
            true,
            f.value[1],
          );
        }
      }
    }
    newfilters.push({...f});
  }
  return newfilters;
}

export const reducer = createReducer(
  initialState,

  // Load configuration request
  on(LoadProfiles, (state, {payload}) => {
    const stateFilters = state.entities[payload.id]
      ? state.entities[payload.id].filters
      : [];
    const filters =
      payload.defaults && payload.defaults.filters
        ? distinctArrays('field', payload.defaults.filters, stateFilters)
        : stateFilters;
    const stateSorts = state.entities[payload.id]
      ? state.entities[payload.id].sorts
      : [];
    const sorts =
      payload.defaults && payload.defaults.sorts
        ? distinctArrays('field', payload.defaults.sorts, stateSorts)
        : stateSorts;
    const stateColumns = state.entities[payload.id]
      ? state.entities[payload.id].columns
      : [];
    const columns =
      payload.defaults && payload.defaults.columns
        ? distinctArrays('field', payload.defaults.columns, stateColumns)
        : stateColumns;
    let viewMode: DataViewModeEnum;
    if (payload.defaults.tree && payload.defaults.tree.isActive !== undefined) {
      if (payload.defaults.tree.isActive) {
        if (payload.defaults.defaultView === DataViewModeEnum.TREE_FULLSCREEN) {
          viewMode = DataViewModeEnum.TREE_FULLSCREEN;
        } else {
          viewMode = DataViewModeEnum.TREE;
        }
      } else {
        viewMode = payload.defaults.defaultView ?? DataViewModeEnum.TABLE;
      }
    } else if (payload.defaults.tree && !!payload.defaults.tree.parentField) {
      viewMode = DataViewModeEnum.TREE;
    } else {
      viewMode = payload.defaults.defaultView ?? DataViewModeEnum.TABLE;
    }
    return listingAdapter.upsertOne(
      {
        id: payload.id,
        isTreeExpanding: false,
        exportingData: false,
        data: {
          items: [],
          totalRecords: 0,
          expandedItems: [],
        },
        dataTotals: {},
        type: payload.type,
        profilesLoading: true,
        dataLoading: false,
        profiles: [],
        profileCategory: payload.defaults.profileCategory,
        selectedProfileId:
          payload.defaults && payload.defaults.profileId !== 'empty'
            ? payload.defaults.profileId
            : null,
        columns: columns,
        page: 1,
        filters: filters,
        sorts: sorts,
        colors:
          payload.defaults && payload.defaults.colors
            ? payload.defaults.colors
            : null,
        pageSize:
          payload.defaults && payload.defaults.pageSize
            ? payload.defaults.pageSize
            : 20,
        selectedRowId: null,
        selectedRowIds: [],
        selectedAllRows: false,
        showBulkEdit: false,
        hasBulkButtonsTemplate: false,
        exportHeaders: null,
        exportSettings: null,
        dataViewMode: viewMode,
        options: payload.defaults ? payload.defaults.options : null,
        tqlExtend: payload.defaults ? payload.defaults.tqlExtend : null,
        tree: payload.defaults
          ? {
              ...payload.defaults.tree,
              // isActive: payload.defaults.tree && !!payload.defaults.tree.parentField
              isActive:
                payload.defaults.tree &&
                payload.defaults.tree.isActive !== undefined
                  ? payload.defaults.tree.isActive
                  : payload.defaults.tree &&
                    !!payload.defaults.tree.parentField,
            }
          : {
              parentField: null,
              multipleParents: false,
              parentValueField: null,
              parentIsArrayWithNullValue: false,
              isActive: false,
            },
        customChangeSettingTable: false,
        showConfig: payload.showConfig
          ? {
              showProfiles: payload.showConfig.showProfiles !== false,
              disableDefaultProfilesOption:
                payload.showConfig.disableDefaultProfilesOption === true,
              disableProfileUrl: payload.showConfig.disableProfileUrl === true,
              disabledFirstLoadData:
                payload.showConfig.disabledFirstLoadData === true,
              showManagerColumns:
                payload.showConfig.showManagerColumns !== false,
              showManagerSort: payload.showConfig.showManagerSort !== false,
              showFilters: payload.showConfig.showFilters !== false,
              showExport: payload.showConfig.showExport !== false,
              showReset: payload.showConfig.showReset !== false,
              showRefresh: payload.showConfig.showRefresh !== false,
              showPaginator: payload.showConfig.showPaginator !== false,
              showManagerColor: payload.showConfig.showManagerColor !== false,
              showListingConfig: payload.showConfig.showListingConfig !== false,
              showRepeatedExport:
                payload.showConfig.showRepeatedExport !== false,
              showTreeNodeExpandAll:
                payload.showConfig.showTreeNodeExpandAll === true,
              scrollable: payload.showConfig.scrollable !== false,
              scrollHeight:
                viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                viewMode === DataViewModeEnum.TREE_FULLSCREEN ||
                viewMode === DataViewModeEnum.KANBAN
                  ? 'ANY'
                  : (payload.showConfig?.scrollHeight ?? DEFAULT_HEIGHT),
              scrollWidth: payload.showConfig.scrollWidth || '25%',
              treeTableNowrap: payload.showConfig.treeTableNowrap !== false,
              dataTableNowrap: payload.showConfig.dataTableNowrap !== false,
              viewport: {
                offset: payload.showConfig?.viewport?.offset || 250,
                flexible:
                  payload.showConfig.viewport?.flexible ??
                  (viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                    viewMode === DataViewModeEnum.TREE_FULLSCREEN),
              },
              backupRestore: payload.showConfig.backupRestore || null,
            }
          : {
              showProfiles: true,
              disableDefaultProfilesOption: false,
              disableProfileUrl: false,
              disabledFirstLoadData: false,
              showManagerColumns: true,
              showManagerSort: true,
              showFilters: true,
              showExport: true,
              showReset: true,
              showRefresh: true,
              showPaginator: true,
              showManagerColor: true,
              showListingConfig: true,
              showRepeatedExport: true,
              showTreeNodeExpandAll: false,
              scrollable: true,
              scrollHeight:
                viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                viewMode === DataViewModeEnum.TREE_FULLSCREEN ||
                viewMode === DataViewModeEnum.KANBAN
                  ? 'ANY'
                  : (payload.showConfig?.scrollHeight ?? DEFAULT_HEIGHT),
              scrollWidth: '25%',
              treeTableNowrap: true,
              dataTableNowrap: true,
              viewport: {
                flexible:
                  viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                  viewMode === DataViewModeEnum.TREE_FULLSCREEN,
                offset: 255,
              },
              backupRestore: null,
            },
        defaults: payload.defaults,
        kanban: [],
      },
      state,
    );
  }),

  // When configuration was loaded succesfully
  on(LoadProfilesSuccess, (state, {id, profiles}) =>
    saveListingProfiles(id, state, listingAdapter, profiles, true),
  ),

  on(RefreshProfiles, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          profilesLoading: true,
        },
      },
      state,
    ),
  ),

  on(
    RefreshProfilesSuccess,
    RefreshProfilesSuccessWithoutDataReload,
    (state, {id, profiles}) =>
      saveListingProfiles(id, state, listingAdapter, profiles, false),
  ),

  on(RefreshProfilesError, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          profilesLoading: false,
          // error: error
        },
      },
      state,
    ),
  ),

  on(LoadProfilesError, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          profilesLoading: false,
          // error: error
        },
      },
      state,
    ),
  ),

  // Specialni pripad pro komponentu table-filter-widget
  on(RemoveProfileWithoutDataReload, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          selectedProfileId: null,
          filters: [],
        },
      },
      state,
    ),
  ),

  // Slouzu pro vytvoreni tabulky za predpokladu, ze nebude vyuzivat profily!
  on(LoadTable, (state, {payload}) => {
    // console.log("LoadTable");
    const stateFilters = state.entities[payload.id]
      ? state.entities[payload.id].filters
      : [];
    const stateTqlExtend = state.entities[payload.id]
      ? state.entities[payload.id].tqlExtend
      : {};
    const filters =
      payload.defaults && payload.defaults.filters
        ? distinctArrays('field', payload.defaults.filters, stateFilters)
        : stateFilters;
    const tqlExtend =
      payload.defaults && payload.defaults.tqlExtend
        ? {...payload.defaults.tqlExtend, ...stateTqlExtend}
        : stateTqlExtend;
    const stateSorts = state.entities[payload.id]
      ? state.entities[payload.id].sorts
      : [];
    const sorts =
      payload.defaults && payload.defaults.sorts
        ? distinctArrays('field', payload.defaults.sorts, stateSorts)
        : stateSorts;
    const stateColumns = state.entities[payload.id]
      ? state.entities[payload.id].columns
      : [];
    const columns =
      payload.defaults && payload.defaults.columns
        ? distinctArrays('field', payload.defaults.columns, stateColumns)
        : stateColumns;
    let viewMode: DataViewModeEnum;
    if (payload.defaults.tree && payload.defaults.tree.isActive !== undefined) {
      if (payload.defaults.tree.isActive) {
        if (payload.defaults.defaultView === DataViewModeEnum.TREE_FULLSCREEN) {
          viewMode = DataViewModeEnum.TREE_FULLSCREEN;
        } else {
          viewMode = DataViewModeEnum.TREE;
        }
      } else {
        viewMode = payload.defaults.defaultView ?? DataViewModeEnum.TABLE;
      }
    } else if (payload.defaults.tree && !!payload.defaults.tree.parentField) {
      viewMode = DataViewModeEnum.TREE;
    } else {
      viewMode = payload.defaults.defaultView ?? DataViewModeEnum.TABLE;
    }
    return listingAdapter.upsertOne(
      {
        id: payload.id,
        isTreeExpanding: false,
        exportingData: false,
        data: {
          items: [],
          totalRecords: 0,
          expandedItems: [],
        },
        dataTotals: {},
        type: payload.type,
        profilesLoading: false,
        dataLoading: true,
        profiles: [],
        profileCategory: payload.defaults.profileCategory,
        selectedProfileId:
          payload.defaults && payload.defaults.profileId !== 'empty'
            ? payload.defaults.profileId
            : null,
        columns: columns,
        page: 1,
        filters: filters,
        sorts: sorts,
        colors:
          payload.defaults && payload.defaults.colors
            ? payload.defaults.colors
            : null,
        pageSize:
          payload.defaults && payload.defaults.pageSize
            ? payload.defaults.pageSize
            : 20,
        selectedRowId: null,
        selectedRowIds: [],
        selectedAllRows: false,
        showBulkEdit: false,
        hasBulkButtonsTemplate: false,
        exportHeaders: null,
        exportSettings: null,
        customChangeSettingTable: false,
        showConfig: payload.showConfig
          ? {
              showProfiles: payload.showConfig.showProfiles !== false,
              disableDefaultProfilesOption:
                payload.showConfig.disableDefaultProfilesOption === true,
              disableProfileUrl: payload.showConfig.disableProfileUrl === true,
              disabledFirstLoadData:
                payload.showConfig.disabledFirstLoadData === true,
              showManagerColumns:
                payload.showConfig.showManagerColumns !== false,
              showManagerSort: payload.showConfig.showManagerSort !== false,
              showFilters: payload.showConfig.showFilters !== false,
              showExport: payload.showConfig.showExport !== false,
              showReset: payload.showConfig.showReset !== false,
              showRefresh: payload.showConfig.showRefresh !== false,
              showPaginator: payload.showConfig.showPaginator !== false,
              showManagerColor: payload.showConfig.showManagerColor !== false,
              showListingConfig: payload.showConfig.showListingConfig !== false,
              showRepeatedExport:
                payload.showConfig.showRepeatedExport !== false,
              showTreeNodeExpandAll:
                payload.showConfig.showTreeNodeExpandAll === true,
              scrollable: payload.showConfig.scrollable !== false,
              scrollHeight:
                viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                viewMode === DataViewModeEnum.TREE_FULLSCREEN ||
                viewMode === DataViewModeEnum.KANBAN
                  ? 'ANY'
                  : (payload.showConfig?.scrollHeight ?? DEFAULT_HEIGHT),
              scrollWidth: payload.showConfig.scrollWidth || '25%',
              treeTableNowrap: payload.showConfig.treeTableNowrap !== false,
              dataTableNowrap: payload.showConfig.dataTableNowrap !== false,
              viewport: {
                offset: payload.showConfig?.viewport?.offset || 250,
                flexible:
                  payload.showConfig.viewport?.flexible ??
                  (viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                    viewMode === DataViewModeEnum.TREE_FULLSCREEN),
              },
              backupRestore: payload.showConfig.backupRestore || null,
            }
          : {
              showProfiles: true,
              disableDefaultProfilesOption: false,
              disableProfileUrl: false,
              disabledFirstLoadData: false,
              showManagerColumns: true,
              showManagerSort: true,
              showFilters: true,
              showExport: true,
              showReset: true,
              showRefresh: true,
              showPaginator: true,
              showManagerColor: true,
              showListingConfig: true,
              showRepeatedExport: true,
              showTreeNodeExpandAll: false,
              scrollable: true,
              scrollHeight:
                viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                viewMode === DataViewModeEnum.TREE_FULLSCREEN ||
                viewMode === DataViewModeEnum.KANBAN
                  ? 'ANY'
                  : (payload.showConfig?.scrollHeight ?? DEFAULT_HEIGHT),
              scrollWidth: '25%',
              treeTableNowrap: true,
              dataTableNowrap: true,
              viewport: {
                offset: 255,
                flexible:
                  viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                  viewMode === DataViewModeEnum.TREE_FULLSCREEN,
              },
              backupRestore: null,
            },
        options: payload.defaults ? payload.defaults.options : null,
        tqlExtend: tqlExtend,
        dataViewMode: viewMode,
        tree: payload.defaults
          ? {
              ...payload.defaults.tree,
              // isActive: payload.defaults.tree && !!payload.defaults.tree.parentField
              isActive:
                payload.defaults.tree &&
                payload.defaults.tree.isActive !== undefined
                  ? payload.defaults.tree.isActive
                  : payload.defaults.tree &&
                    !!payload.defaults.tree.parentField,
            }
          : {
              parentField: null,
              multipleParents: false,
              parentValueField: null,
              parentIsArrayWithNullValue: false,
              isActive: false,
            },
        defaults: payload.defaults,
        kanban: [],
      },
      state,
    );
  }),

  on(LoadTableSuccess, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          profilesLoading: false,
          dataLoading: false,
        },
      },
      state,
    ),
  ),

  on(LoadTableError, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          profilesLoading: false,
          dataLoading: false,
          // error: error
        },
      },
      state,
    ),
  ),

  on(SaveAsDefaultAllProfileSuccess, (state, {payload}) =>
    listingAdapter.updateOne(
      {
        id: payload.id,
        changes: {
          ...state.entities[payload.id],
          profilesLoading: false,
          selectedProfileId: DEFAULT_ALL_PROFILE,
          profiles: [
            ...state.entities[payload.id].profiles.filter(
              (prof) => prof.id !== payload.profile.id,
            ),
            payload.profile,
          ],
        },
      },
      state,
    ),
  ),

  on(
    SaveProfile,
    SaveUserDefaultProfile,
    SaveAsDefaultAllProfile,
    (state, {payload}) =>
      listingAdapter.updateOne(
        {
          id: payload.id,
          changes: {
            ...state.entities[payload.id],
            profilesLoading: true,
          },
        },
        state,
      ),
  ),

  on(SaveProfileSuccess, (state, {payload}) =>
    listingAdapter.updateOne(
      {
        id: payload.id,
        changes: {
          ...state.entities[payload.id],
          profilesLoading: false,
          selectedProfileId: payload.profile.id,
          profiles: [
            ...state.entities[payload.id].profiles.filter(
              (prof) => prof.id !== payload.profile.id,
            ),
            payload.profile,
          ],
        },
      },
      state,
    ),
  ),

  on(SaveProfileError, (state, {id, error}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          profilesLoading: false,
          dataLoading: false,
          error: error,
        },
      },
      state,
    ),
  ),

  // to same jako kdybych dal F5 a prisel znova na stranku
  on(ClearProfileSuccess, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id,
        changes: {
          ...state.entities[id],
          selectedProfileId: null,
        },
      },
      state,
    ),
  ),

  on(DeleteProfile, (state, {payload}) =>
    listingAdapter.updateOne(
      {
        id: payload.id,
        changes: {
          ...state.entities[payload.id],
          profilesLoading: true,
        },
      },
      state,
    ),
  ),

  on(DeleteProfileSuccess, (state, {payload}) => {
    const tableState = state.entities[payload.id];
    const profiles = tableState.profiles.filter(
      (prof) => prof.id !== payload.profile.id,
    );
    return listingAdapter.updateOne(
      {
        id: payload.id,
        changes: {
          ...state.entities[payload.id],
          profilesLoading: false,
          selectedProfileId: null,
          profiles: profiles,
        },
      },
      state,
    );
  }),

  on(DeleteProfileError, (state, payload) =>
    listingAdapter.updateOne(
      {
        id: payload.id,
        changes: {
          ...state.entities[payload.id],
          dataLoading: false,
          profilesLoading: false,
          error: payload.error,
        },
      },
      state,
    ),
  ),

  // Reset z menu, to same jako F5 a prijdu poprve
  on(ResetData, (state, {id}) => {
    return resetTable(id, state, listingAdapter, true);
  }),
  on(ResetTableStateWithoutDataReload, (state, {id}) => {
    return resetTable(id, state, listingAdapter, false);
  }),

  // Refreshne data, zachova nastaveni filtru, sortu atd.
  // Vybrane radky se zachovajiy
  on(RefreshData, (state, {id}) => {
    // console.log("RefreshData");
    const entity = state.entities[id] || {};
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...entity,
          filters: fixRelativeFilters(
            (entity as any)?.filters,
            (entity as any)?.columns,
          ),
          dataLoading: true,
        },
      },
      state,
    );
  }),

  // Refreshne data, zachova nastaveni filtru, sortu atd.
  // Vybrane radky se SMAZOU
  on(RefreshDataAndClearSelected, (state, {id}) => {
    // console.log("RefreshDataAndClearSelected");
    const entity = state.entities[id] || {};
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...entity,
          dataLoading: true,
          selectedRowId: null,
          selectedRowIds: [],
        },
      },
      state,
    );
  }),

  // Load data
  on(LoadData, (state, {id}) => {
    // console.log("LoadData");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: true,
        },
      },
      state,
    );
  }),

  // Load data to grid success
  on(LoadDataSuccess, (state, {id, data, isSubTree, withoutLoading}) => {
    const rowIdField = state.entities[id].defaults.rowIdField;
    const tree = state.entities[id].tree;
    let selectedRowIds = [];
    if (tree?.isActive) {
      // z duvodu lazyloadu potomku musim doresit zaskrtavani, pokud je rodic oznacenej
      selectedRowIds = state.entities[id].selectedAllRows
        ? distinctArrays(
            null,
            state.entities[id].selectedRowIds,
            data.content.map((x) => x[rowIdField]),
          ) // pokud allRows = true, tak zaskrtavej i donacteny potomky
        : state.entities[id].selectedRowIds &&
            state.entities[id].selectedRowIds.length > 0
          ? distinctArrays(null, state.entities[id].selectedRowIds)
          : // pokud mam zaskrtnuteho rodice, tak zaskrtni i potomky ktery jsem pravne dotahnul
            //  data.content.filter(x => state.entities[id].selectedRowIds.includes(x[state.entities[id].tree.parentField])).map(x => x.id)
            [];
    } else {
      if (state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true) {
        selectedRowIds = state.entities[id].selectedRowIds;
      } else {
        selectedRowIds = state.entities[id].selectedAllRows
          ? data.content.map((x) => x[rowIdField])
          : state.entities[id].selectedRowIds &&
              state.entities[id].selectedRowIds.length > 0
            ? state.entities[id].selectedRowIds
            : [];
      }
    }
    // pro tree
    let treeItems = [];
    let expandedItems = state.entities[id].data.expandedItems;
    if (tree?.isActive) {
      const oldTreeItems = state.entities[id].data.items;
      let newItemsWithNoParent = 0;

      if (tree.multipleParents) {
        if (tree.parentIsArrayWithNullValue === true) {
          newItemsWithNoParent = data.content.filter((x) =>
            Array.isArray(x[tree.parentField])
              ? // pokud parentField je pole, tak vrat vsechny, ktery jsou jako root prvek (jejich pocet, nemaji rodice)
                x[tree.parentField].some((y) => y == null)
              : // pokud parentField neni pole, tak vrat vsechny, kde je null (jejich pocet, nemaji rodice)
                x[tree.parentField] == null,
          ).length;
        } else {
          newItemsWithNoParent = data.content.filter((x) =>
            Array.isArray(x[tree.parentField])
              ? // pokud parentField je pole, tak ho vrat, kdyz je rodic (vsechny jeho hodnoty se rovnaji '00000000-0000-0000-0000-000000000000')
                !x[tree.parentField].some(
                  (y) => y !== '00000000-0000-0000-0000-000000000000',
                )
              : // pokud parentField neni pole, tak ho vrat, kdyz je parentFiled null (je to rodic)
                x[tree.parentField] == null,
          ).length;
        }
      } else {
        newItemsWithNoParent = data.content.filter(
          (x) => x[tree.parentField] == null,
        ).length;
      }

      if (
        oldTreeItems.length === 0 ||
        (data.content.length > 0 &&
          newItemsWithNoParent === data.content.length)
      ) {
        treeItems = data.content;
      } else if (tree?.expandAll === true) {
        treeItems = DtlUtils.copyItemOrdering(
          'id',
          data.content,
          distinctArrays('id', oldTreeItems, data.content),
        );
        expandedItems = distinctArrays(
          null,
          expandedItems,
          data.content.filter((x) => x.__leaf === true).map((x) => x.id),
        );
      } else {
        const newTreeItemsWhichParentExists = data.content.filter((newItem) =>
          oldTreeItems.some((oldItem: any) => {
            const oldItemValue = !!tree.parentValueField
              ? oldItem[tree.parentValueField]
              : oldItem.id;
            return tree.multipleParents
              ? newItem[tree.parentField].some((x) => x === oldItemValue)
              : newItem[tree.parentField] == oldItemValue;
          }),
        );
        treeItems = DtlUtils.copyItemOrdering(
          'id',
          newTreeItemsWhichParentExists,
          distinctArrays('id', oldTreeItems, newTreeItemsWhichParentExists),
        );
      }
    }

    let items = data.content;
    let totalRecords = data.totalElements;
    if (
      tree?.isActive &&
      state.entities[id].data.totalRecords !== 0 &&
      isSubTree
    ) {
      // pokud se jedna o stromecek a je aktivni a neni prazdny, tak pouzijeme data z treeItems
      items = treeItems;
      // vezmeme puvodni pocet zaznamu, protoze se strankuje pouze pres root prvky
      totalRecords = state.entities[id].data.totalRecords;
    }
    // console.log("LoadDataSuccess");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading:
            withoutLoading === true ? state.entities[id].dataLoading : false,
          // error: null,
          selectedRowIds: selectedRowIds,
          selectedAllRows:
            state.entities[id] != null
              ? isSelectedAllRows(
                  items,
                  rowIdField,
                  selectedRowIds,
                  tree?.isActive,
                  state.entities[id].selectedAllRows,
                )
              : false,
          data: {
            ...state.entities[id].data,
            expandedItems: expandedItems,
            items: items,
            totalRecords: totalRecords,
          },
          tree: tree
            ? {
                ...tree,
                parentValue: null,
                expandAll: false,
                // parentField: tree.parentField,
                // isActive: tree.isActive === undefined || tree.isActive === null ? !!tree.parentField : tree.isActive,
              }
            : {
                parentField: null,
                multipleParents: false,
                parentValueField: null,
                parentIsArrayWithNullValue: false,
                isActive: false,
                expandAll: false,
              },
        },
      },
      state,
    );
  }),

  // Load data to grid error
  on(LoadDataError, (state, {id}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: false,
          // error: error
        },
      },
      state,
    ),
  ),

  on(LoadTotalData, LoadTotalDataError, (state, {id}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataTotals: {},
        },
      },
      state,
    );
  }),

  on(LoadTotalDataSuccess, (state, {id, data}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataTotals: data,
        },
      },
      state,
    );
  }),

  on(UpdateRowData, (state, {id, item}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          data: {
            ...state.entities[id].data,
            items: state.entities[id].data.items.map((x, index) =>
              index === item.rowIndex ? item.rowData : x,
            ),
          },
        },
      },
      state,
    );
  }),

  on(SortsChanged, (state, {id, sorts}) => {
    // console.log("SortsChanged");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: true,
          selectedRowId:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowId
              : null,
          selectedRowIds:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowIds
              : [],
          sorts: sorts,
          page: 1,
          data: {
            ...state.entities[id].data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    );
  }),

  on(ColorsChanged, (state, {id, colors}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          colors: colors,
        },
      },
      state,
    ),
  ),

  on(PageChanged, (state, {id, page}) => {
    // console.log("PageChanged");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          selectedRowId:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowId
              : null,
          selectedRowIds:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowIds
              : [],
          dataLoading: true,
          page: page,
          data: {
            ...state.entities[id].data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    );
  }),

  on(PageSizeChanged, (state, {id, pageSize}) => {
    // console.log("PageSizeChanged");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: true,
          pageSize: pageSize,
          data: {
            ...state.entities[id]?.data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    );
  }),

  on(PageSizeChangedWithoutRefresh, (state, {id, pageSize}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          pageSize: pageSize,
          data: {
            ...state.entities[id]?.data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    ),
  ),

  on(PageAndPageSizeChanged, (state, {id, pageAndPageSize}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: true,
          page: pageAndPageSize.page,
          pageSize: pageAndPageSize.pageSize,
          data: {
            ...state.entities[id]?.data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    );
  }),

  on(UrlChanged, (state, {id, url}) => {
    // console.log("FiltersChanged");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          type: {
            ...state.entities[id].type,
            url,
          },
        },
      },
      state,
    );
  }),

  on(FiltersChanged, (state, {id, filters}) => {
    // console.log("FiltersChanged");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: true,
          selectedRowId:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowId
              : null,
          selectedRowIds:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowIds
              : [],
          filters: filters,
          page: 1,
          data: {
            ...state.entities[id]?.data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    );
  }),

  on(FiltersChangedWithoutRefresh, (state, {id, filters}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          selectedRowId:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowId
              : null,
          selectedRowIds:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowIds
              : [],
          filters: filters,
          page: 1,
          data: {
            ...state.entities[id]?.data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    ),
  ),

  on(FilterChanged, (state, {id, filter}) => {
    // console.log("FilterChanged");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: true,
          selectedRowId:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowId
              : null,
          selectedRowIds:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? state.entities[id].selectedRowIds
              : [],
          filters: [
            ...state.entities[id].filters.filter(
              (x) => x.field !== filter.field,
            ),
            filter,
          ],
          page: 1,
          data: {
            ...state.entities[id].data,
            items: [],
            expandedItems: [],
          },
        },
      },
      state,
    );
  }),

  on(InitColumnsChanged, (state, {id, columns}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          columns: columns,
        },
      },
      state,
    ),
  ),

  on(ShowConfigSet, (state, {id, showConfig}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          showConfig,
        },
      },
      state,
    ),
  ),

  on(ShowConfigChanged, (state, {id, showConfig}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          showConfig: {
            ...state.entities[id].showConfig,
            ...showConfig,
          },
        },
      },
      state,
    ),
  ),

  on(SetLoading, (state, {id, loading}) => {
    // console.log("SetLoading");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataLoading: loading,
        },
      },
      state,
    );
  }),

  on(CustomChangeSettingTable, (state, {id, change}) => {
    // console.log("CustomChangeSettingTable");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          customChangeSettingTable: change,
        },
      },
      state,
    );
  }),

  on(ColumnsChanged, ColumnsResized, (state, {id, columns}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          columns: columns,
        },
      },
      state,
    ),
  ),

  on(AddColumns, (state, {id, columns}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          columns: distinctArrays('field', state.entities[id].columns, columns),
        },
      },
      state,
    ),
  ),

  on(ChangeColumn, (state, {id, column}) => {
    const findColumn = state.entities[id]?.columns.find(
      (x) => x.field === column.field,
    );
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          columns: [
            ...(findColumn
              ? state.entities[id].columns.map((x) => {
                  if (x.field === findColumn.field) {
                    return {...x, ...column};
                  }
                  return x;
                })
              : state.entities[id]?.columns),
          ],
        },
      },
      state,
    );
  }),

  on(RemoveColumns, (state, {id, columns}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          columns: state.entities[id].columns.filter(
            (x) => !columns.find((y) => y.field === x.field),
          ),
        },
      },
      state,
    ),
  ),

  on(SetOptions, (state, {id, options}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          options: options,
        },
      },
      state,
    ),
  ),

  on(TqlExtendChanged, (state, {id, tqlExtend}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          tqlExtend:
            state.entities[id].tqlExtend == null
              ? tqlExtend
              : {
                  ...state.entities[id].tqlExtend,
                  ...tqlExtend,
                },
        },
      },
      state,
    ),
  ),

  on(TqlQueryParamsChanged, (state, {id, params}) => {
    const entity = state.entities[id];
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...entity,
          type: {
            ...entity.type,
            queryParams: {
              ...(entity.type.queryParams || {}),
              ...(params || {}),
            },
          },
        },
      },
      state,
    );
  }),

  on(SetTree, (state, {id, tree}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          tree: tree,
        },
      },
      state,
    ),
  ),

  on(RowIdFieldChanged, (state, {id, rowIdField}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          defaults: {
            ...state.entities[id].defaults,
            rowIdField,
          },
        },
      },
      state,
    ),
  ),

  on(SelectedRowIdChanged, (state, {id, selectedRowId}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          selectedRowId: selectedRowId,
        },
      },
      state,
    ),
  ),

  on(SelectedRowIdsChanged, (state, {id, selectedRowIds}) => {
    const foundState = state.entities[id];
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...foundState,
          selectedRowIds: selectedRowIds,
          selectedAllRows: foundState.selectedAllRows
            ? isSelectedAllRows(
                foundState.data.items,
                foundState.defaults.rowIdField,
                selectedRowIds,
                foundState.tree?.isActive,
                foundState.selectedAllRows,
              )
            : false,
        },
      },
      state,
    );
  }),

  on(AllCheckboxChanged, (state, {id, checked}) => {
    const rowIdField = state.entities[id].defaults.rowIdField;
    const selectedRowIds = state.entities[id].data.items.map(
      (x) => x[rowIdField],
    );
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          selectedRowIds:
            state.entities[id]?.defaults?.disabledRemoveSelectedRowIds == true
              ? checked
                ? distinctArrays(
                    null,
                    state.entities[id].selectedRowIds,
                    selectedRowIds,
                  )
                : state.entities[id].selectedRowIds.filter(
                    (x) => !selectedRowIds.includes(x),
                  )
              : checked
                ? selectedRowIds
                : [],
          selectedAllRows: checked,
        },
      },
      state,
    );
  }),

  on(ShowBulkEditChanged, (state, {id, checked}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          showBulkEdit: checked,
          selectedRowIds: !checked ? [] : state.entities[id].selectedRowIds,
          selectedAllRows: !checked
            ? false
            : state.entities[id].selectedAllRows,
          defaults: {
            ...state.entities[id].defaults,
            disabledRemoveSelectedRowIds: checked,
          },
        },
      },
      state,
    );
  }),

  on(HasBulkButtonsTemplateChanged, (state, {id, checked}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          hasBulkButtonsTemplate: checked,
        },
      },
      state,
    );
  }),

  on(
    SelectedProfileDuringSetupChangedSuccess,
    (state, {id, selectedProfileId}) => {
      // console.log("SelectedProfileDuringSetupChangedSuccess");
      const selectedProfile =
        state.entities[id].profiles &&
        state.entities[id].profiles.find((x) => x.id === selectedProfileId);
      const letSelectedRows =
        state.entities[id].selectedProfileId === selectedProfileId;
      const readonlyFilters = state.entities[id].filters.filter(
        (x) => x.readonly === true,
      );

      if (selectedProfile) {
        const newFilters = fixRelativeFilters(
          selectedProfile.filters,
          state.entities[id].columns,
        );
        return listingAdapter.updateOne(
          {
            id: id,
            changes: {
              ...state.entities[id],
              data: {
                ...state.entities[id].data,
                expandedItems: [],
              },
              selectedProfileId: selectedProfileId,
              exportSettings: selectedProfile.exportSettings,
              filters: [...readonlyFilters, ...newFilters],
              sorts: selectedProfile.sorts,
              colors: selectedProfile.colors,
              pageSize: selectedProfile.pageSize,
              exportHeaders: selectedProfile.exportHeaders,
              columns: mergeColumns(
                selectedProfile.columns,
                state.entities[id].columns,
              ),
              selectedAllRows: letSelectedRows
                ? state.entities[id].selectedAllRows
                : false,
              selectedRowId: letSelectedRows
                ? state.entities[id].selectedRowId
                : null,
              selectedRowIds: letSelectedRows
                ? state.entities[id].selectedRowIds
                : [],
              page: letSelectedRows ? state.entities[id].page : 1,
              dataLoading: true,
            },
          },
          state,
        );
      } else {
        return listingAdapter.updateOne(
          {
            id: id,
            changes: {
              ...state.entities[id],
              selectedProfileId: selectedProfileId,
              dataLoading: true,
            },
          },
          state,
        );
      }
    },
  ),

  on(
    SelectedProfileChangedSuccess,
    (state, {id, selectedProfileId, mergeProfileFilters}) => {
      // TODO otazka zni, zda by se i tady nemelo divat na profileCategory
      //  (tedka profil nastaveny na widgetu se aplikuje vzdy, pokud ho mam nasdileny beu ohledu profileCategory)
      const selectedProfile =
        state.entities[id].profiles &&
        state.entities[id].profiles.find((x) => x.id === selectedProfileId);

      const letSelectedRows =
        state.entities[id].selectedProfileId === selectedProfileId;

      let filters = [];
      const fixFilters = fixRelativeFilters(
        selectedProfile.filters,
        state.entities[id].columns,
      );
      if (mergeProfileFilters) {
        filters = mergeFilters(state.entities[id].filters, fixFilters);
      } else {
        const readonlyFilters = state.entities[id].filters.filter(
          (x) => x.readonly === true,
        );
        filters = distinctArrays('field', readonlyFilters, fixFilters);
      }

      // console.log("SelectedProfileChangedSuccess");
      if (selectedProfile) {
        let columns = mergeColumns(
          selectedProfile?.columns,
          state.entities[id].columns,
        );
        const viewMode =
          selectedProfile.dataViewMode ??
          (state.entities[id]?.tree?.parentField
            ? DataViewModeEnum.TREE
            : (state.entities[id].dataViewMode ?? DataViewModeEnum.TABLE));
        return listingAdapter.updateOne(
          {
            id: id,
            changes: {
              ...state.entities[id],
              data: {
                ...state.entities[id].data,
                expandedItems: [],
              },
              selectedProfileId: selectedProfileId,
              exportSettings: selectedProfile.exportSettings,
              filters: filters,
              sorts: selectedProfile.sorts,
              colors: selectedProfile.colors,
              pageSize: selectedProfile.pageSize,
              exportHeaders: selectedProfile.exportHeaders,
              columns: columns,
              selectedAllRows: letSelectedRows
                ? state.entities[id].selectedAllRows
                : false,
              selectedRowId: letSelectedRows
                ? state.entities[id].selectedRowId
                : null,
              selectedRowIds: letSelectedRows
                ? state.entities[id].selectedRowIds
                : [],
              page: letSelectedRows ? state.entities[id].page : 1,
              dataLoading: true,
              dataViewMode: viewMode,
              showConfig: {
                ...state.entities[id].showConfig,
                scrollHeight:
                  viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                  viewMode === DataViewModeEnum.TREE_FULLSCREEN ||
                  viewMode === DataViewModeEnum.KANBAN
                    ? 'ANY'
                    : (state.entities[id].showConfig.scrollHeight ??
                      DEFAULT_HEIGHT),
                viewport: {
                  ...state.entities[id].showConfig.viewport,
                  flexible:
                    viewMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                    viewMode === DataViewModeEnum.TREE_FULLSCREEN,
                },
              },
              tree: {
                ...state.entities[id].tree,
                isActive:
                  viewMode === DataViewModeEnum.TREE ||
                  viewMode === DataViewModeEnum.TREE_FULLSCREEN,
              },
              kanban: selectedProfile?.config?.kanban || [],
            },
          },
          state,
        );
      } else {
        return listingAdapter.updateOne(
          {
            id: id,
            changes: {
              ...state.entities[id],
              selectedProfileId: selectedProfileId,
              dataLoading: true,
            },
          },
          state,
        );
      }
    },
  ),

  on(ScrollHeight, (state, {id, height}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          showConfig: {
            ...state.entities[id].showConfig,
            scrollHeight: height,
          },
        },
      },
      state,
    ),
  ),

  on(ScrollWidth, (state, {id, width}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          showConfig: {
            ...state.entities[id].showConfig,
            scrollWidth: width,
          },
        },
      },
      state,
    ),
  ),

  on(ExportingData, (state, {id, value}) =>
    listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          exportingData: value,
        },
      },
      state,
    ),
  ),

  on(SetUpTableSettings, (state, {id, defaults}) => {
    if ((window as any)?.app?.params?.listingStateLocalStore) {
      const localStorageState = getLocalStorageListing();
      if (localStorageState != null && localStorageState[id]) {
        const localTableState: Table = localStorageState[id];
        // console.log("SetUpTableSettings localstorage", setLocalStorageTableState);
        return setLocalStorageTableState(
          id,
          state,
          listingAdapter,
          localTableState,
        );
      }
    }

    const selectedProfile = state.entities[id].profiles.find(
      (x) => x.id === state.entities[id].selectedProfileId,
    );

    const letSelectedRows = true;

    const filters = distinctArrays(
      'field',
      state.entities[id] && state.entities[id].filters
        ? state.entities[id].filters
        : [],
      defaults && defaults.filters ? defaults.filters : [],
    );
    const tqlExtend =
      state.entities[id] && state.entities[id].tqlExtend
        ? state.entities[id].tqlExtend
        : defaults.tqlExtend;
    const sorts = state.entities[id].sorts
      ? state.entities[id].sorts
      : selectedProfile && selectedProfile.sorts
        ? selectedProfile.sorts
        : defaults.sorts;
    const colors = state.entities[id].colors
      ? state.entities[id].colors
      : selectedProfile && selectedProfile.colors
        ? selectedProfile.colors
        : defaults.colors;
    const exportSettings =
      selectedProfile && selectedProfile.exportSettings
        ? selectedProfile.exportSettings
        : null;
    const exportHeaders =
      selectedProfile && selectedProfile.exportHeaders
        ? selectedProfile.exportHeaders
        : null;
    const pageSize = state.entities[id].pageSize
      ? state.entities[id].pageSize
      : selectedProfile && selectedProfile.pageSize
        ? selectedProfile.pageSize
        : (defaults.pageSize ?? 20);

    const columns =
      selectedProfile != null
        ? mergeColumns(
            state.entities[id].columns,
            distinctArrays(
              'field',
              state.entities[id].columns.filter((x) => x.isExternal),
              selectedProfile.columns || [],
            ),
            false,
          )
        : state.entities[id].columns;
    // console.log("SetUpTableSettings normal");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          data: {
            items: [],
            totalRecords: 0,
            expandedItems: state.entities[id].data.expandedItems,
          },
          profileCategory: defaults.profileCategory,
          selectedProfileId: selectedProfile ? selectedProfile.id : null,
          exportSettings: exportSettings,
          exportHeaders: exportHeaders,
          columns: columns,
          filters: filters.sort((col1, col2) =>
            col1.readonly === col2.readonly ? -1 : 1,
          ),
          sorts: sorts,
          colors: colors,
          pageSize: pageSize,
          selectedRowId: letSelectedRows
            ? state.entities[id].selectedRowId
            : null,
          selectedRowIds: letSelectedRows
            ? state.entities[id].selectedRowIds
            : [],
          selectedAllRows: letSelectedRows
            ? state.entities[id].selectedAllRows
            : false,
          dataLoading: true,
          page: letSelectedRows ? state.entities[id].page : 1,
          options: defaults ? defaults.options : null,
          tqlExtend: tqlExtend,
          setUpTableSettings: true,
        },
      },
      state,
    );
  }),

  on(ClearTableStateByListingType, (state, {listingType}) => {
    const entArray = Object.keys(state.entities).map(
      (it) => state.entities[it],
    );
    const relatedValIds = entArray
      .filter(
        (ent) => ent.type && ent.type.type && ent.type.type === listingType,
      )
      .map((ent) => ent.id);
    return listingAdapter.removeMany(relatedValIds, state);
  }),

  /**
   * SEKCE PRO TREE
   */

  on(TreeNodeExpandAllBackend, (state, {id, nodeData}) => {
    const expandedItems = nodeData.expanded
      ? [...state.entities[id].data.expandedItems, nodeData.nodeId].reduce(
          (acc, item) => {
            // distinct()
            if (!acc.includes(item)) {
              acc.push(item);
            }
            return acc;
          },
          [],
        )
      : state.entities[id].data.expandedItems.filter(
          (x) => x !== nodeData.nodeId,
        );

    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          data: {
            ...state.entities[id].data,
            expandedItems: expandedItems,
          },
          dataLoading: nodeData.expanded,
          isTreeExpanding: true,
          tree: {
            ...state.entities[id].tree,
            expandAll: true,
          },
        },
      },
      state,
    );
  }),

  on(TreeNodeExpandCollapse, (state, {id, nodeData}) => {
    const expandedItems = nodeData.expanded
      ? [...state.entities[id].data.expandedItems, nodeData.nodeId].reduce(
          (acc, item) => {
            // distinct()
            if (!acc.includes(item)) {
              acc.push(item);
            }
            return acc;
          },
          [],
        )
      : state.entities[id].data.expandedItems.filter(
          (x) => x !== nodeData.nodeId,
        );

    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          data: {
            ...state.entities[id].data,
            expandedItems: expandedItems,
          },
          dataLoading: nodeData.expanded,
          isTreeExpanding: true,
        },
      },
      state,
    );
  }),

  on(TreeNodeExpandCollapseFinished, (state, {id}) => {
    return listingAdapter.updateOne(
      {
        id,
        changes: {
          ...state.entities[id],
          isTreeExpanding: false,
        },
      },
      state,
    );
  }),

  on(DataViewChangeMode, (state, {id, changeMode}) => {
    const columns = state.entities[id].columns;
    // console.log("DataViewChangeMode");
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          data: {
            items: [],
            totalRecords: 0,
            expandedItems: [],
          },
          dataLoading: true,
          selectedAllRows: false,
          dataViewMode: changeMode,
          columns: columns,
          showConfig: {
            ...state.entities[id].showConfig,
            scrollHeight:
              changeMode === DataViewModeEnum.TABLE_FULLSCREEN ||
              changeMode === DataViewModeEnum.TREE_FULLSCREEN ||
              changeMode === DataViewModeEnum.KANBAN
                ? 'ANY'
                : DEFAULT_HEIGHT,
            viewport: {
              ...state.entities[id].showConfig.viewport,
              flexible:
                changeMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                changeMode === DataViewModeEnum.TREE_FULLSCREEN,
            },
          },
          tree: {
            ...state.entities[id].tree,
            isActive:
              changeMode === DataViewModeEnum.TREE ||
              changeMode === DataViewModeEnum.TREE_FULLSCREEN,
            parentValue: null,
            expandAll: false,
          },
        },
      },
      state,
    );
  }),

  on(DataViewChangeModeWithoutRefresh, (state, {id, changeMode}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          dataViewMode: changeMode,
          showConfig: {
            ...state.entities[id].showConfig,
            scrollHeight:
              changeMode === DataViewModeEnum.TABLE_FULLSCREEN ||
              changeMode === DataViewModeEnum.TREE_FULLSCREEN ||
              changeMode === DataViewModeEnum.KANBAN
                ? 'ANY'
                : DEFAULT_HEIGHT,
            viewport: {
              ...state.entities[id].showConfig.viewport,
              flexible:
                changeMode === DataViewModeEnum.TABLE_FULLSCREEN ||
                changeMode === DataViewModeEnum.TREE_FULLSCREEN,
            },
          },
          tree: {
            ...state.entities[id].tree,
            isActive:
              changeMode === DataViewModeEnum.TREE ||
              changeMode === DataViewModeEnum.TREE_FULLSCREEN,
            parentValue: null,
            expandAll: false,
          },
        },
      },
      state,
    );
  }),

  on(RemoveListing, (state, {id}) => {
    return listingAdapter.removeOne(id, state);
  }),

  on(SetEmptyData, (state, {id}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          data: {
            items: [],
            totalRecords: 0,
            expandedItems: [],
          },
          page: 1,
        },
      },
      state,
    );
  }),

  on(SetRefresh, (state, {id, profileId, refresh, listingType}) => {
    if (id == null && listingType != null) {
      const entitiesToUpdate: Update<Table<any, TableKeyType>>[] = [];
      Object.keys(state.entities)
        .filter((x) => state.entities[x].type.type === listingType)
        .forEach((x) => {
          const foundProfile = state.entities[x].profiles.find(
            (x) => x.id === profileId,
          );
          entitiesToUpdate.push({
            id: state.entities[x].id,
            changes: {
              ...state.entities[x],
              profiles: [
                ...state.entities[x].profiles.filter((x) => x.id !== profileId),
                {
                  ...foundProfile,
                  config: {
                    ...foundProfile?.config,
                    refresh,
                  },
                },
              ],
            },
          });
        });
      return listingAdapter.updateMany(entitiesToUpdate, state);
    } else {
      const foundProfile = state.entities[id]?.profiles?.find(
        (x) => x.id === profileId,
      );
      return foundProfile != null
        ? listingAdapter.updateOne(
            {
              id,
              changes: {
                ...state.entities[id],
                profiles: [
                  ...state.entities[id].profiles.filter(
                    (x) => x.id !== profileId,
                  ),
                  {
                    ...foundProfile,
                    config: {
                      ...foundProfile?.config,
                      refresh,
                    },
                  },
                ],
              },
            },
            state,
          )
        : state;
    }
  }),

  /**
   * SEKCE KANBAN
   */
  on(AddKanbanList, (state, {id, kanban}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          kanban: distinctArrays('listId', state.entities[id].kanban, [kanban]),
        },
      },
      state,
    );
  }),
  on(ChangeKanbanList, ChangeFiltersKanbanList, (state, {id, kanban}) => {
    const findKanban = state.entities[id]?.kanban.find(
      (x) => x.listId === kanban.listId,
    );
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          kanban: [
            ...(findKanban
              ? state.entities[id].kanban.map((x) => {
                  if (x.listId === findKanban.listId) {
                    return {...x, ...kanban};
                  }
                  return x;
                })
              : state.entities[id]?.kanban),
          ],
        },
      },
      state,
    );
  }),
  on(LoadDataKanbanList, (state, {id, kanban}) => {
    const findKanban = state.entities[id]?.kanban.find(
      (x) => x.listId === kanban.listId,
    );
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          kanban: [
            ...(findKanban
              ? state.entities[id].kanban.map((x) => {
                  if (x.listId === findKanban.listId) {
                    return {...x, loading: true, error: null};
                  }
                  return x;
                })
              : state.entities[id]?.kanban),
          ],
        },
      },
      state,
    );
  }),
  on(RemoveKanbanList, (state, {id, listId}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          kanban: state.entities[id].kanban.filter((x) => x.listId !== listId),
        },
      },
      state,
    );
  }),
  on(ChangeOrderKanbanLists, (state, {id, kanbans}) => {
    return listingAdapter.updateOne(
      {
        id: id,
        changes: {
          ...state.entities[id],
          kanban: kanbans,
        },
      },
      state,
    );
  }),
);

function setLocalStorageTableState(
  id: string,
  state: any,
  adapter: EntityAdapter<any>,
  localStorageTableState: Table,
) {
  const storeState = state.entities[id];

  const columns = mergeColumns(
    localStorageTableState.columns,
    storeState.columns,
  );

  const filters = distinctArrays(
    'field',
    localStorageTableState.filters,
    storeState.filters ? storeState.filters : [],
  ).sort((col1, col2) => (col1.readonly === col2.readonly ? -1 : 1));
  // console.log("setLocalStorageTableState", state.entities[id]);
  const result = {
    id: id,
    changes: {
      ...state.entities[id],
      profilesLoading: false,
      filters: filters,
      profileCategory: localStorageTableState.profileCategory,
      selectedProfileId: localStorageTableState.selectedProfileId,
      profiles: localStorageTableState.profiles,
      sorts: localStorageTableState.sorts,
      colors: localStorageTableState.colors,
      pageSize: localStorageTableState.pageSize,
      columns: columns,
      page: localStorageTableState.page,
      exportSettings: localStorageTableState.exportSettings,
      exportHeaders: localStorageTableState.exportHeaders,
    },
  };
  result.changes.dataLoading = true;
  return listingAdapter.updateOne(result, state);
}

function saveListingProfiles(
  id: string,
  state: EntityState<Table>,
  adapter: EntityAdapter<any>,
  dbProfiles: ListingProfile[],
  setDataLoadingToTrue = false,
) {
  const tableState = state.entities[id];
  const defaultAllProfile = tableState.profiles?.find(
    (p) => p.code === DEFAULT_ALL_PROFILE,
  );
  const profiles =
    defaultAllProfile != null
      ? [defaultAllProfile, ...dbProfiles] //pokud uz tam ten defaultni profil je, tak ho znova nevytvarim (neprepisuju)
      : [createBasitProfile(tableState, tableState.type), ...dbProfiles];
  // console.log('saveListingProfiles', profiles);
  const profileId = tableState.selectedProfileId
    ? tableState.selectedProfileId
    : getFirstNotDefaultProfile(
        profiles.filter(
          (pro) =>
            (!!tableState.profileCategory
              ? tableState.profileCategory === pro.profileCategory
              : !pro.profileCategory) && pro.isUserDefault,
        ),
      ) ||
      getFirstNotDefaultProfile(
        profiles.filter(
          (pro) =>
            (!!tableState.profileCategory
              ? tableState.profileCategory === pro.profileCategory
              : !pro.profileCategory) && pro.isDefault,
        ),
      );
  const profileIdExists =
    profileId != null
      ? profiles.find((p) => p.id === profileId) != null
      : false;

  if ((window as any)?.app?.params?.listingStateLocalStore) {
    const localStorageState = getLocalStorageListing();
    if (localStorageState?.[id]) {
      const localTableState: Table = localStorageState[id];
      localTableState.profiles = profiles;
      localTableState.selectedProfileId = profileIdExists ? profileId : null;
      return setLocalStorageTableState(
        id,
        state,
        listingAdapter,
        localTableState,
      );
    }
  }

  const profile = profiles.find((p) => p.id === profileId);
  const columns = profileIdExists
    ? mergeColumns(profile.columns, tableState.columns)
    : tableState.columns;
  const filters = distinctArrays(
    'field',
    profile?.filters || [],
    tableState.filters || [],
  ).sort((a, b) => (a.readonly === b.readonly ? -1 : 1));

  const viewMode = profile?.dataViewMode || tableState.dataViewMode;
  // (tableState?.tree?.parentField
  //     ? DataViewModeEnum.TREE
  //     : (state.entities[id].dataViewMode ?? DataViewModeEnum.TABLE))

  const result = {
    id: id,
    changes: {
      ...tableState,
      profilesLoading: false,
      profiles: profiles,
      selectedProfileId: profileIdExists ? profileId : null,
      filters: filters,
      sorts: profile?.sorts || tableState.sorts,
      colors: profile?.colors || tableState.colors,
      pageSize: profile?.pageSize || tableState.pageSize,
      columns: columns,
      page: tableState.page,
      exportSettings: profile?.exportSettings || tableState.exportSettings,
      exportHeaders: profile?.exportHeaders || tableState.exportHeaders,
      dataViewMode: viewMode,
      tree:
        viewMode === DataViewModeEnum.TREE ||
        viewMode === DataViewModeEnum.TREE_FULLSCREEN
          ? {
              ...tableState.tree,
              isActive: true,
            }
          : tableState.tree,
    },
  };

  if (setDataLoadingToTrue) {
    result.changes.dataLoading = true;
  }

  return listingAdapter.updateOne(result, state);
}

function resetTable(
  id: string,
  state: any,
  adapter: EntityAdapter<any>,
  setDataLoadingToTrue: boolean,
) {
  const defaults = state.entities[id].defaults;
  const selectedProfile = state.entities[id].profiles.find(
    (x) => x.id === state.entities[id].selectedProfileId,
  );

  const filters = distinctArrays(
    'field',
    selectedProfile && selectedProfile.filters ? selectedProfile.filters : [],
    defaults && defaults.filters ? defaults.filters : [],
    state.entities[id].filters.filter((x) => x.readonly === true), // chci pridat i ty, ktery jsou readonly (nejsou ulozeny v profilu)
  ).sort((col1, col2) => (col1.readonly === col2.readonly ? -1 : 1));

  const letSelectedRows = false;
  const defaultColumns =
    defaults && defaults.columns
      ? defaults.columns
      : state.entities[id].columns;
  const changeMode =
    selectedProfile?.dataViewMode ??
    (state.entities[id]?.tree?.parentField
      ? DataViewModeEnum.TREE
      : (state.entities[id].dataViewMode ?? DataViewModeEnum.TABLE));

  const result = {
    id: id,
    changes: {
      ...state.entities[id],
      data: {
        ...state.entities[id].data,
        expandedItems: [],
      },
      selectedProfileId: selectedProfile ? selectedProfile.id : null,
      exportSettings:
        selectedProfile && selectedProfile.exportSettings
          ? selectedProfile.exportSettings
          : null,
      exportHeaders:
        selectedProfile && selectedProfile.exportHeaders
          ? selectedProfile.exportHeaders
          : null,
      columns: selectedProfile
        ? mergeColumns(selectedProfile.columns, state.entities[id].columns)
        : defaultColumns,
      filters: filters,
      sorts:
        selectedProfile && selectedProfile.sorts
          ? selectedProfile.sorts
          : defaults && defaults.sorts
            ? defaults.sorts
            : [],
      colors:
        selectedProfile && selectedProfile.colors
          ? selectedProfile.colors
          : ((defaults && defaults.colors
              ? defaults.colors
              : null) as ColorModel),
      pageSize:
        selectedProfile && selectedProfile.pageSize
          ? selectedProfile.pageSize
          : defaults && defaults.pageSize
            ? defaults.pageSize
            : 20,
      selectedRowId: letSelectedRows ? state.entities[id].selectedRowId : null,
      selectedRowIds: letSelectedRows ? state.entities[id].selectedRowIds : [],
      selectedAllRows: letSelectedRows
        ? state.entities[id].selectedAllRows
        : false,
      page: letSelectedRows ? state.entities[id].page : 1,
      options: defaults ? defaults.options : null,
      tqlExtend: defaults ? defaults.tqlExtend : null,
      dataViewMode: changeMode,
      showConfig: {
        ...state.entities[id].showConfig,
        scrollHeight:
          changeMode === DataViewModeEnum.TABLE_FULLSCREEN ||
          changeMode === DataViewModeEnum.TREE_FULLSCREEN
            ? 'ANY'
            : (state.entities[id]?.showConfig?.scrollHeight ?? DEFAULT_HEIGHT),
        viewport: {
          ...state.entities[id].showConfig.viewport,
          flexible:
            changeMode === DataViewModeEnum.TABLE_FULLSCREEN ||
            changeMode === DataViewModeEnum.TREE_FULLSCREEN,
        },
      },
    },
  };

  if (setDataLoadingToTrue) {
    result.changes.dataLoading = true;
  }

  return adapter.updateOne(result, state);
}

function getFirstNotDefaultProfile(arr: ListingProfile[]): string {
  if (arr.length === 0) {
    return null;
  }
  if (arr.length === 1) {
    return arr[0].id;
  }
  return arr.find((x) => x.code !== DEFAULT_ALL_PROFILE)?.id;
}

function createBasitProfile(table: Table, type: TableType): ListingProfile {
  return {
    name: 'listingLib.components.showAll',
    code: DEFAULT_ALL_PROFILE,
    id: DEFAULT_ALL_PROFILE,
    description: null,
    validityFrom: null,
    validityTo: null,
    valid: 'true',
    listing: null, // "cely objekt listingu"
    filters: table.filters, // doplnit defaut
    columns: table.columns,
    userId: getUserId(),
    ownerName: null,
    canModify: false,
    isUserDefault: false,
    exportSettings: null,
    colors: table.colors || {
      rowColorExpr: null,
    },
    dataTags: [],
    config: {
      kanban: [],
    },
    tqlName: type.tqlName,
    profileCategory: table.profileCategory,
    nextSendExec: null,
    exportStatus: ExportStatus.INACTIVE,
    dataViewMode: table.dataViewMode,
    codeListing: type.type,
    isDefault: true,
    sorts: table.sorts,
    pageSize: table.pageSize,
    exportHeaders: table.exportHeaders,
  };
}

/**
 * Pokud aktualne vytazena data z DB pro konktrenit stranku a filtru jsou obsazena mezi vybranejma radkama
 */
function isSelectedAllRows(
  items: any[],
  rowIdField: string,
  selectedRowIds: TableKeyType[],
  isTreeActive: boolean = false,
  selectedAllRows: boolean = false,
): boolean {
  if (items?.length === 0 || (!selectedAllRows && isTreeActive)) {
    return false;
  }
  return !items.some((x) => !selectedRowIds.includes(x[rowIdField]));
}
