import {SelectItem} from 'primeng/api';

export enum AccessRuleEnum {
  READ = 'READ',
  READ_WRITE = 'READ_WRITE',
}

export function getAllAccessRules(): SelectItem[] {
  const accessRules: SelectItem[] = [];
  Object.keys(AccessRuleEnum).forEach((key) =>
    accessRules.push({label: AccessRuleEnum[key], value: key}),
  );
  return accessRules;
}
