export const en = {
  addressManagementPlugins: {
    headerDescription: 'Header docs',
    filters: {
      tsmRegionFilter: 'Tsm Region Filter',
      tsmRegionFilterDescription:
        'Provides a user interface for filtering regions with customizable configurations. ' +
        'It integrates with services to manage filters dynamically, fetch selected entities, ' +
        'and apply context-sensitive filters for flexible usage in different scenarios.',
      tsmRegionLayerFilter: 'Tsm Region Layer Filter',
      tsmRegionLayerFilterDescription:
        'Provides an interface for filtering region layers with flexible configurations. ' +
        'It dynamically manages filters, fetches selected layers from the store, ' +
        'and supports context-sensitive filtering for enhanced flexibility.',
      tsmRegionTypeFilter: 'Tsm Region Type Filter',
      tsmRegionTypeFilterDescription:
        'Provides an interface for filtering region types with a table view. ' +
        'Retrieves and translates region types dynamically, manages selected values, ' +
        'and supports multi-selection for flexibility.',
    },
    pipes: {
      addressLoader: 'Address Loader',
      addressLoaderDescription:
        'Fetches address data by ID from the store, retrieves specific fields such as the formatted ' +
        'address or the complete object, and ensures the data is loaded if not already available.',
      regionCode: 'Region Code',
      regionCodeDescription:
        'Loads region data by code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if not already available.',
      regionLayer: 'Region Layer',
      regionLayerDescription:
        'Loads region layer data by ID from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if not already available.',
      regionLayerCode: 'Region Layer Code',
      regionLayerCodeDescription:
        'Loads region layer data by Code from the store, retrieves specific fields such as the name, ' +
        'or the entire object, and ensures the data is fetched if not already available.',
      regionObjectLoader: 'Region Object Loader',
      regionObjectLoaderDescription:
        'Loads region data by ID from the store and retrieves the entire object. Ensures the data ' +
        'is fetched if not already available.',
    },
  },
};
