import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {AngleDownIcon} from 'primeng/icons/angledown';
import {AngleUpIcon} from 'primeng/icons/angleup';
import {TimesIcon} from 'primeng/icons/times';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumber} from './input-number/input-number.component';

@NgModule({
    imports: [
        CommonModule,
        InputTextModule,
        ButtonModule,
        TimesIcon,
        AngleUpIcon,
        AngleDownIcon,
        InputNumber,
    ],
    exports: [InputNumber, SharedModule],
})
export class InputNumberModule {}
