<tsm-inputNumber
  #inputElement
  [inputId]="inputId"
  [useGrouping]="localeFormatting === false ? false : true"
  [ngStyle]="{display: readonly ? 'none' : ''}"
  [tabindex]="tabindex?.toString()"
  [ngModel]="val"
  [min]="min"
  [placeholder]="
    localizationData
      | localizationDataTranslate: 'widget.placeholder' : placeholder
  "
  [max]="max"
  [mode]="mode"
  [currency]="currency"
  [step]="step"
  [showButtons]="showButtons"
  [buttonLayout]="buttonLayout"
  [minFractionDigits]="fractionDigits"
  [maxFractionDigits]="fractionDigits"
  [locale]="locale"
  (onFocus)="onFocus(true)"
  (onBlur)="onInputBlur($event)"
  (onInput)="handleOnInput($event)"
  data-cy="dtl-form-input-number"
></tsm-inputNumber>
<span
  [ngStyle]="{display: !readonly ? 'none' : 'inline-block'}"
  [ngClass]="
    val !== null && val !== undefined ? 'readonly-text' : 'readonly-text empty'
  "
>
  <ng-container *ngIf="val !== null && val !== undefined; else noneTemplate">
    <ng-container
      *ngIf="
        isNumber(val) && (localeFormatting == true || localeFormatting == null);
        else noNumberTemplate
      "
    >
      <ng-container *ngIf="val === 0">{{ val }}</ng-container>
      <ng-container *ngIf="val !== 0 && mode !== 'currency'">{{
        val | number: digitsInfo
      }}</ng-container>
      <ng-container *ngIf="val !== 0 && mode === 'currency'">
        <tsm-inputNumber
          [ngModel]="val"
          mode="currency"
          [currency]="currency"
          [readonly]="true"
          [inputStyle]="{
            border: 'none',
            background: 'none',
            'box-shadow': 'none',
          }"
          [placeholder]="placeholder"
        >
        </tsm-inputNumber>
      </ng-container>
    </ng-container>
    <ng-template #noNumberTemplate>
      {{ val }}
    </ng-template>
  </ng-container>
  <ng-template #noneTemplate> none </ng-template>
</span>
