import {Directive, HostListener, Input, NgModule} from '@angular/core';

@Directive({
  selector: '[autolostFocus]',
})
export class AutoLostFocusDirective {
  constructor() {}

  @Input() maxTraversalLevel = 2;

  @HostListener('mouseup', ['$event.target'])
  onMouseUpEvent(target) {
    let node = target;
    let level = 1;
    while (level <= this.maxTraversalLevel) {
      if (node === document.activeElement) {
        node.blur();
        break;
      }
      if (!node.parentElement) break;
      node = node.parentElement;
      level++;
    }
  }
}

@NgModule({
  declarations: [AutoLostFocusDirective],
  exports: [AutoLostFocusDirective],
})
export class AutoLostFocusModule {}
