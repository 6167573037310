import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'join',
  pure: true,
})
export class JoinPipe implements PipeTransform {
  constructor() {}

  transform(arr: string[], delimiter: string): string {
    return arr?.join(delimiter);
  }
}
