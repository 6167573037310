import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from 'primeng/api';
import {Accordion, AccordionTab} from './accordion/accordion.component';

@NgModule({
    imports: [CommonModule, Accordion, AccordionTab],
    exports: [Accordion, AccordionTab, SharedModule],
})
export class AccordionModule {}
