import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from '@tsm/framework/root';
import {UserManagementState} from '../reducers';
import {adapter} from '../reducers/user-group-region.reducer';

const getUserManagementState =
  createFeatureSelector<UserManagementState>('userManagement');
const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getUserGroupRegionState = createSelector(
  getUserManagementState,
  (userGroupRegionState) => userGroupRegionState.userGroupRegion,
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  adapter.getSelectors(getUserGroupRegionState);

export const selectUserGroupRegionByRoute = createSelector(
  selectEntities,
  getRouterState,
  (entities, router) => entities[(router as any).state.params['id']],
);

export const selectUserGroupRegionsByUserGroupId = (userGroupId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.userGroup.id === userGroupId),
  );

export const selectUserGroupRegionById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const getAllUserGroupRegions = selectAll;

export const selectUserGroupRegionsByRegionId = (regionId: string) =>
  createSelector(selectAll, (entities) =>
    entities.filter((r) => r.data.regionId === regionId),
  );
