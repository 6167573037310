import {Pipe, PipeTransform, inject} from '@angular/core';
import {FieldsDescriptionService} from '../services/fields.description.service';

@Pipe({
  name: 'fieldsDescription',
  pure: true,
})
export class FieldsDescriptionPipe implements PipeTransform {
  private service = inject(FieldsDescriptionService);

  transform(id: string, type: 'label' | 'helpMessage'): string {
    const sections = this.service.getSections();

    for (const section of sections) {
      for (const field of section.fields) {
        if (field.key === id) {
          return field[type] || '';
        }
      }
    }
    return '';
  }
}
