import {Pipe, PipeTransform} from '@angular/core';
import {map} from 'rxjs/operators';
import {AttachmentDataService} from '../services';
import {Observable, of} from 'rxjs';

@Pipe({
  name: 'countAttachments',
  pure: true,
})
export class CountAttachmentsPipe implements PipeTransform {
  private requiresRowData = true;

  constructor(protected attachmentDataService: AttachmentDataService) {}

  /**
   * Počet příloh podle vlastníka
   *
   * @param id ownerId
   * @param ownerType - ownerType
   * @param row - celý řádek, pokud není definovaný args, tak se bere jako default row.ownerType
   */
  transform(id: string, ownerType: string, row?: any): Observable<number> {
    if (id == null) {
      return of(null);
    }

    return this.attachmentDataService
      .countAttachment(id, ownerType ? ownerType : row.ownerType)
      .pipe(
        map((env) => {
          return env.success ? env.data || 0 : 0;
        }),
      );
  }
}
