import {Pipe, PipeTransform} from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({
  name: 'objectPathGet',
  pure: true,
})
export class ObjectPathGetPipe implements PipeTransform {
  transform(value: any, field: string | string[]): any {
    return objectPath.get(value, field);
  }
}
