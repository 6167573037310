import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/process/plugins-i18n';
import {type FluentSchema} from '@tsm/framework/fluent-debugger-service';

export const processDefinitionPlugin: Plugin = {
  menus: [
    {
      key: 'process-admin',
      priority: 900,
      label: 'menu.process.processManag',
      icon: 'tsm-icon-diagram',
      privilege: [
        'Process.DmnDef.ViewList',
        'Process.ProcessDef.ViewList',
        'Process.TaskDef.ViewList',
        'Process.TaskActionTemplate.ViewList',
        'Process.Registers',
        'Process.Registers.ProcessType.ViewList',
        'Process.Registers.ProcessGroup.ViewList',
        'Process.Registers.Status.ViewList',
        'Process.Registers.StatusGroup.ViewList',
      ],
      items: [
        {
          key: 'process-definition',
          label: 'menu.process.processDesigner',
          icon: 'tsm-icon-flowchart',
          routerLink: ['/process/process-definition'],
          listingType: 'tsm-process-definition',
          privilege: ['Process.ProcessDef.ViewList'],
        },
        {
          key: 'task-definition',
          label: 'menu.process.taskDesigner',
          icon: 'tsm-icon-vector-2',
          routerLink: ['/process/task-definition'],
          listingType: 'tsm-task-definition',
          privilege: ['Process.TaskDef.ViewList'],
        },
        {
          key: 'task-action-template',
          label: 'menu.process.taskActionTemplate',
          icon: 'tsm-icon-vector-2',
          routerLink: ['/process/task-action-template'],
          listingType: 'tsm-task-action-template',
          privilege: ['Process.TaskActionTemplate.ViewList'],
        },
        {
          key: 'dmn-definition',
          label: 'menu.process.dmnDesigner',
          icon: 'tsm-icon-sketch',
          routerLink: ['/process/dmn-definition'],
          listingType: 'tsm-dmn-definition',
          privilege: ['Process.DmnDef.ViewList'],
        },
        {
          key: 'process-tasks',
          label: 'menu.process.processTasks',
          icon: 'tsm-icon-process',
          routerLink: ['/process/process-tasks'],
          listingType: 'tsm-process-type',
          privilege: ['true'],
        },
        {
          key: 'PRM_REGISTERS',
          label: 'menu.process.registers',
          icon: 'tsm-icon-registers-1',
          privilege: [
            'Process.Registers',
            'Process.Registers.ProcessType.ViewList',
            'Process.Registers.ProcessGroup.ViewList',
            'Process.Registers.Status.ViewList',
            'Process.Registers.StatusGroup.ViewList',
          ],
          items: [
            {
              key: 'PROCESS_TYPE',
              label: 'menu.process.processTypesManage',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/process/process-type'],
              privilege: ['Process.Registers.ProcessType.ViewList'],
            },
            {
              key: 'PROCESS_GROUP',
              label: 'menu.process.processGroupManag',
              icon: 'tsm-icon-menu-item',
              routerLink: ['/process/process-group'],
              privilege: ['Process.Registers.ProcessGroup.ViewList'],
            },
            {
              key: 'task-status-admin',
              label: 'menu.process.statusManagement',
              icon: 'tsm-icon-mobility',
              routerLink: ['/process/task-status-admin'],
              listingType: 'tsm-task-status-admin',
              privilege: ['Process.Registers.Status.ViewList'],
            },
            {
              key: 'task-status-group',
              label: 'menu.process.stateMenagGrooups',
              icon: 'tsm-icon-management-3',
              routerLink: ['/process/task-status-group'],
              listingType: 'tsm-task-status-group',
              privilege: ['Process.Registers.StatusGroup.ViewList'],
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: 'process',
      loadChildren: () =>
        import('@tsm/process/definition').then(
          (x) => x.ProcessDefinitionModule,
        ),
    },
  ],
  pipes: [
    {
      loadChildren: () =>
        import('@tsm/process/definition').then(
          (x) => x.ProcessDefinitionModule,
        ),
      definitions: [
        {
          selector: 'dmnDefinitionCode',
          name: translationPlugin.processPlugins.pipes.dmnDefinitionCode,
          description:
            translationPlugin.processPlugins.pipes.dmnDefinitionCodeDescription,
        },
        {
          selector: 'priorityIcon',
          name: translationPlugin.processPlugins.pipes.priorityIcon,
          description:
            translationPlugin.processPlugins.pipes.priorityIconDescription,
        },
        {
          selector: 'processDefinitionCodeDeployed',
          name: translationPlugin.processPlugins.pipes
            .processDefinitionCodeDeployed,
          description:
            translationPlugin.processPlugins.pipes
              .processDefinitionCodeDeployedDescription,
        },
        {
          selector: 'processDefinitionCodeVersion',
          name: translationPlugin.processPlugins.pipes
            .processDefinitionCodeVersion,
          description:
            translationPlugin.processPlugins.pipes
              .processDefinitionCodeVersionDescription,
        },
        {
          selector: 'processDefinitionCode',
          name: translationPlugin.processPlugins.pipes.processDefinitionCode,
          description:
            translationPlugin.processPlugins.pipes
              .processDefinitionCodeDescription,
        },
        {
          selector: 'processDefinitionId',
          name: translationPlugin.processPlugins.pipes.processDefinitionId,
          description:
            translationPlugin.processPlugins.pipes
              .processDefinitionIdDescription,
        },
        {
          selector: 'processGroupCode',
          name: translationPlugin.processPlugins.pipes.processGroupCode,
          description:
            translationPlugin.processPlugins.pipes.processGroupCodeDescription,
        },
        {
          selector: 'processGroupId',
          name: translationPlugin.processPlugins.pipes.processGroupId,
          description:
            translationPlugin.processPlugins.pipes.processGroupIdDescription,
        },
        {
          selector: 'processTypeCode',
          name: translationPlugin.processPlugins.pipes.processTypeCode,
          description:
            translationPlugin.processPlugins.pipes.processTypeCodeDescription,
        },
        {
          selector: 'processTypeId',
          name: translationPlugin.processPlugins.pipes.processTypeId,
          description:
            translationPlugin.processPlugins.pipes.processTypeIdDescription,
        },
        {
          selector: 'taskActionTemplateCode',
          name: translationPlugin.processPlugins.pipes.taskActionTemplateCode,
          description:
            translationPlugin.processPlugins.pipes
              .taskActionTemplateCodeDescription,
        },
        {
          selector: 'taskActionTemplateId',
          name: translationPlugin.processPlugins.pipes.taskActionTemplateId,
          description:
            translationPlugin.processPlugins.pipes
              .taskActionTemplateIdDescription,
        },
        {
          selector: 'taskDefinitionCode',
          name: translationPlugin.processPlugins.pipes.taskDefinitionCode,
          description:
            translationPlugin.processPlugins.pipes
              .taskDefinitionCodeDescription,
        },
        {
          selector: 'taskDefinitionId',
          name: translationPlugin.processPlugins.pipes.taskDefinitionId,
          description:
            translationPlugin.processPlugins.pipes.taskDefinitionIdDescription,
        },
        {
          selector: 'taskLink',
          name: translationPlugin.processPlugins.pipes.taskLink,
          description:
            translationPlugin.processPlugins.pipes.taskLinkDescription,
        },
        {
          selector: 'taskStatusCodeColor',
          name: translationPlugin.processPlugins.pipes.taskStatusCodeColor,
          description:
            translationPlugin.processPlugins.pipes
              .taskStatusCodeColorDescription,
        },
        {
          selector: 'taskStatusCode',
          name: translationPlugin.processPlugins.pipes.taskStatusCode,
          description:
            translationPlugin.processPlugins.pipes.taskStatusCodeDescription,
        },
        {
          selector: 'taskStatusGroupCode',
          name: translationPlugin.processPlugins.pipes.taskStatusGroupCode,
          description:
            translationPlugin.processPlugins.pipes
              .taskStatusGroupCodeDescription,
        },
        {
          selector: 'taskStatusGroupId',
          name: translationPlugin.processPlugins.pipes.taskStatusGroupId,
          description:
            translationPlugin.processPlugins.pipes.taskStatusGroupIdDescription,
        },
        {
          selector: 'taskStatusIcon',
          name: translationPlugin.processPlugins.pipes.taskStatusIcon,
          description:
            translationPlugin.processPlugins.pipes.taskStatusIconDescription,
        },
        {
          selector: 'taskStatusId',
          name: translationPlugin.processPlugins.pipes.taskStatusId,
          description:
            translationPlugin.processPlugins.pipes.taskStatusIdDescription,
        },
        {
          selector: 'taskUnassignedSubtask',
          name: translationPlugin.processPlugins.pipes.taskUnassignedSubtask,
          description:
            translationPlugin.processPlugins.pipes
              .taskUnassignedSubtaskDescription,
        },
        {
          selector: 'ticketTaskById',
          name: translationPlugin.processPlugins.pipes.ticketTaskById,
          description:
            translationPlugin.processPlugins.pipes.ticketTaskByIdDescription,
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/process/definition').then(
          (x) => x.ProcessDefinitionModule,
        ),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/deploy-state-filter').then(
              (x) => x.DeployStateFilterComponent,
            ),
          selector: 'tsm-deploy-state-filter',
          name: translationPlugin.processPlugins.filters.tsmDeployStateFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmDeployStateFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/filters/process-group-action-filter'
            ).then((x) => x.ProcessGroupActionFilterComponent),
          selector: 'tsm-process-group-action-filter',
          name: translationPlugin.processPlugins.filters
            .tsmProcessGroupActionFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessGroupActionFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/process-group-filter').then(
              (x) => x.ProcessGroupFilterComponent,
            ),
          selector: 'tsm-process-group-filter',
          name: translationPlugin.processPlugins.filters.tsmProcessGroupFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessGroupFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/process-state-filter').then(
              (x) => x.ProcessStateFilterComponent,
            ),
          selector: 'tsm-process-state-filter',
          name: translationPlugin.processPlugins.filters.tsmProcessStateFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessStateFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/process-type-filter').then(
              (x) => x.ProcessTypeFilterComponent,
            ),
          selector: 'tsm-process-type-filter',
          name: translationPlugin.processPlugins.filters.tsmProcessTypeFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmProcessTypeFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/task-filter').then(
              (x) => x.TaskFilterComponent,
            ),
          selector: 'tsm-task-filter',
          name: translationPlugin.processPlugins.filters.tsmTaskFilter,
          description:
            translationPlugin.processPlugins.filters.tsmTaskFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/task-status-filter').then(
              (x) => x.TaskStatusFilterComponent,
            ),
          selector: 'tsm-task-status-filter',
          name: translationPlugin.processPlugins.filters.tsmTaskStatusFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmTaskStatusFilterDescription,
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/filters/task-status-group-filter'
            ).then((x) => x.TaskStatusGroupFilterComponent),
          selector: 'tsm-task-status-group-filter',
          name: translationPlugin.processPlugins.filters
            .tsmTaskStatusGroupFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmTaskStatusGroupFilterDescription,
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/filters/task-type-filter').then(
              (x) => x.TaskTypeFilterComponent,
            ),
          selector: 'tsm-task-type-filter',
          name: translationPlugin.processPlugins.filters.tsmTaskTypeFilter,
          description:
            translationPlugin.processPlugins.filters
              .tsmTaskTypeFilterDescription,
        },
      ],
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/process/definition').then(
          (x) => x.ProcessDefinitionModule,
        ),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/process/widgets/process-definition-card').then(
              (x) => x.ProcessDefinitionCardComponent,
            ),
          selector: 'tsm-process-definition-card',
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-definition-card').then(
              (x) => x.TaskDefinitionCardComponent,
            ),
          selector: 'tsm-task-definition-card',
        },
        {selector: 'tsm-svg-parser'},
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/task-definition-lov/task-definition-lov'
            ).then((x) => x.TaskDefinitionLovComponent),
          selector: 'tsm-task-definition-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-definition-lov/task-definition-lov-editor'
                ).then((x) => x.TaskDefinitionLovEditorComponent),
              editorSelector: 'tsm-task-definition-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'taskDefinition',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskDefinitionSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskDefinitionSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.taskDefinitionSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-task-definition-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/process-definition-lov/process-definition-lov'
            ).then((x) => x.ProcessDefinitionLovComponent),
          selector: 'tsm-process-definition-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/process-definition-lov/process-definition-lov-editor'
                ).then((x) => x.ProcessDefinitionLovEditorComponent),
              editorSelector: 'tsm-process-definition-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'processDefinition',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.processDefinitionSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.processDefinitionSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 5,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.processDefinitionSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-process-definition-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import('@tsm/process/widgets/task-status-lov/task-status-lov').then(
              (x) => x.TaskStatusLovComponent,
            ),
          selector: 'tsm-task-status-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-status-lov/task-status-lov-editor'
                ).then((x) => x.TaskStatusLovEditorComponent),
              editorSelector: 'tsm-task-status-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'taskStatus',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskStatusSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskStatusSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 6,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.taskStatusSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-task-status-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/task-action-template-lov/task-action-template-lov'
            ).then((x) => x.TaskActionTemplateLovComponent),
          selector: 'tsm-task-action-template-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-action-template-lov/task-action-template-lov-editor'
                ).then((x) => x.TaskActionTemplateLovEditorComponent),

              editorSelector: 'tsm-task-action-template-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'taskStatus',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskActionTemplateSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskActionTemplateSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 7,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.taskActionTemplateSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-task-action-template-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/process-group-lov/process-group-lov'
            ).then((x) => x.ProcessGroupLovComponent),
          selector: 'tsm-process-group-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/process-group-lov/process-group-lov-editor'
                ).then((x) => x.ProcessGroupLovEditorComponent),
              editorSelector: 'tsm-process-group-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'processGroup',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.processGroupSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.processGroupSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 8,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.processGroupSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-process-group-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/process-type-lov/process-type-lov'
            ).then((x) => x.ProcessTypeLovComponent),
          selector: 'tsm-process-type-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/process-type-lov/process-type-lov-editor'
                ).then((x) => x.ProcessTypeLovEditorComponent),
              editorSelector: 'tsm-process-type-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'processType',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.processTypeSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.processTypeSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 8,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.processTypeSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-process-type-lov',
                },
              },
            },
          ],
        },
        {
          loadComponent: () =>
            import(
              '@tsm/process/widgets/task-status-group-lov/task-status-group-lov'
            ).then((x) => x.TaskStatusGroupLovComponent),
          selector: 'tsm-task-status-group-lov',
          privilege: ['true'],
          widgetMapper: (fluentSchema: FluentSchema) => {
            return {
              placeholder: fluentSchema.widget?.placeholder,
            };
          },
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/process/widgets/task-status-group-lov/task-status-group-lov-editor'
                ).then((x) => x.TaskStatusGroupLovEditorComponent),
              editorSelector: 'tsm-task-status-group-lov-editor',
              widgetOrLayout: 'widget',
              formField: 'taskStatusGroup',
              categoryId: DesignerCategoryEnum.ProcessManagement,
              name: translationShared.shared.widgets.taskStatusGroupSelect
                .widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.taskStatusGroupSelect
                  .widgetDescription,
              group: GroupPosition.Primary,
              order: 9,
              icon: DesignerIconsEnum.select,
              value: {
                type: 'string',
                title:
                  translationShared.shared.widgets.taskStatusGroupSelect
                    .inWidgetTitle,
                widget: {
                  type: 'tsm-task-status-group-lov',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
