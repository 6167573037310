import {Injectable, signal} from '@angular/core';
import {JSONSchema6Type, JSONSchema6TypeName} from 'json-schema';
import {LocalizationVersionData} from '@tsm/framework/translate';
import {JsonPointer} from 'tsm-json-schema-form-functions';
import {FluentSchema} from '@tsm/framework/fluent-debugger-service';
import {memoize} from '@tsm/framework/root';

interface Item {
  type: JSONSchema6TypeName | JSONSchema6Type | 'valueObject';
  objectPointer: string;
  children: Item[];
  title?: string;
  localizationData?: LocalizationVersionData;
}

interface UniqueObjectPointerLayout {
  valuePointer: string[];
  objectPointer: string;
  found: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FormSchemaLayoutService {
  collapsedList = signal<string[]>([]);
  schemaRoot = signal<Item | null>(null);
  missingCount = signal(0);
  private uniqueObjectPointers = [];
  private uniqueObjectPointersLayout: UniqueObjectPointerLayout[] = [];

  constructor() {}

  @memoize
  parse(schema: FluentSchema) {
    this.uniqueObjectPointers = [];
    const schemaRoot =
      schema != null
        ? {
            type: 'object',
            objectPointer: '',
            children: this.parseSchema(schema),
          }
        : null;
    this.schemaRoot.set(schemaRoot);

    this.uniqueObjectPointersLayout = [];
    const uniqueValuePointers = this.uniqueObjectPointers.map((x) => {
      const vp = x
        .split('/properties')
        .map((x) => x.replace('/', '').replace('/items', ''))
        .filter((x) => x !== '')
        .join('.');
      return {
        valuePointer: [...new Set([vp, ...vp.split('.')])],
        objectPointer: x,
        found: false,
      };
    });

    if (Array.isArray(schema.layout)) {
      JsonPointer.forEachDeep(schema.layout, (v, p) => {
        const split = p.split('/');
        const beforeLastItem = split[split.length - 2];
        if (
          typeof v === 'string' &&
          (beforeLastItem === 'items' || beforeLastItem === '') &&
          uniqueValuePointers.some((x) => x.valuePointer.some((y) => y === v))
        ) {
          const filtered = uniqueValuePointers.filter((x, i) =>
            x.valuePointer.some((y) => y === v),
          );
          filtered.forEach((x) => {
            const item = uniqueValuePointers.find(
              (y) => y.objectPointer === x.objectPointer,
            );

            const beforeItemsPath = split
              .filter((_, i) => i < split.length - 2)
              .join('/');
            const beforeItemsObj = JsonPointer.get(
              schema.layout,
              beforeItemsPath,
            );
            const index =
              beforeItemsPath !== ''
                ? beforeItemsObj.items.findIndex((x) => x === v)
                : beforeItemsObj.findIndex((x) => x === v);
            let foundInContent = false;

            if (beforeItemsPath !== '' && beforeItemsObj.config) {
              JsonPointer.forEachDeep(beforeItemsObj.config, (cv, cp) => {
                if (
                  cp.endsWith('content') &&
                  Array.isArray(cv) &&
                  cv.includes(index)
                ) {
                  foundInContent = true;
                }
              });

              if (
                beforeItemsObj.config.content != null &&
                item &&
                foundInContent
              ) {
                item.found = true; // pro rozlozeni, co maji content: [0, 1]
              } else if (beforeItemsObj.config.content == null && item) {
                item.found = true; // sekce nema content, pouze items
              }
            } else {
              if (item) {
                item.found = true;
              }
            }
          });
        }
      });
    } else {
      uniqueValuePointers.forEach((x) => (x.found = true));
    }
    this.uniqueObjectPointersLayout = uniqueValuePointers;
    this.missingCount.set(
      this.uniqueObjectPointersLayout.filter((x) => !x.found).length,
    );
  }

  collapse(objectPointer: string) {
    this.collapsedList.update((list) => [...list, objectPointer]);
  }

  expand(objectPointer: string) {
    this.collapsedList.update((list) => {
      const fi = list.findIndex((x) => x === objectPointer);
      list.splice(fi, 1);
      return list;
    });
  }

  inLayout(objectPointer: string) {
    return (
      this.uniqueObjectPointersLayout.find(
        (x) => x.objectPointer === objectPointer,
      )?.found ?? false
    );
  }

  private parseSchema(schema: FluentSchema, parentObjectPointer = ''): Item[] {
    let result: Item[] = [];
    if (schema == null) {
      return result;
    }
    let objectPointer = parentObjectPointer;
    if (schema.type === 'object' && schema.properties) {
      objectPointer += '/properties';
      result = [
        {
          type: schema.type,
          objectPointer,
          children: Object.keys(schema.properties)
            .map((key) => {
              const val: any = schema.properties[key];
              return this.parseSchema(val, `${objectPointer}/${key}`);
            })
            .reduce((a, b) => a.concat(b), []),
        },
      ];
    } else if (schema.type === 'array' && schema.items) {
      objectPointer += '/items';
      const isEmptyObjItems =
        typeof schema.items === 'object' &&
        Object.keys(schema.items).length === 0;
      result = [
        {
          type: schema.type,
          objectPointer,
          children: !isEmptyObjItems
            ? this.parseSchema(schema.items as any, objectPointer)
            : [],
        },
      ];
    } else {
      // ignore duplicates
      if (!this.uniqueObjectPointers.includes(objectPointer)) {
        result = [
          {
            type:
              schema.type === 'object' && !schema.properties
                ? 'valueObject'
                : schema.type,
            objectPointer,
            children: [],
            title: schema.title,
            localizationData: schema.localizationData,
          },
        ];
        this.uniqueObjectPointers.push(objectPointer);
      }
    }
    return result;
  }
}
