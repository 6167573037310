import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteSkillAssignmentSuccess,
  LoadSkillAssignmentsBySkillId,
  LoadSkillAssignmentsBySkillIdError,
  LoadSkillAssignmentsBySkillIdSuccess,
  LoadSkillAssignmentsByUserGroupId,
  LoadSkillAssignmentsByUserGroupIdError,
  LoadSkillAssignmentsByUserGroupIdSuccess,
  LoadSkillAssignmentsByUserId,
  LoadSkillAssignmentsByUserIdError,
  LoadSkillAssignmentsByUserIdSuccess,
  UpsertMoreSkillAssignment,
  UpsertMoreSkillAssignmentError,
  UpsertMoreSkillAssignmentSuccess,
  UpsertSkillAssignment,
  UpsertSkillAssignmentError,
  UpsertSkillAssignments,
  UpsertSkillAssignmentsError,
  UpsertSkillAssignmentsSuccess,
  UpsertSkillAssignmentSuccess,
} from '../actions';
import {SkillAssignment} from '../model';

export type SkillAssignmentState = EntityState<IdentifiedPack<SkillAssignment>>;

export const adapter: EntityAdapter<IdentifiedPack<SkillAssignment>> =
  createEntityAdapter<IdentifiedPack<SkillAssignment>>({});

export const initialState: SkillAssignmentState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(
    LoadSkillAssignmentsByUserIdSuccess,
    LoadSkillAssignmentsByUserGroupIdSuccess,
    LoadSkillAssignmentsBySkillIdSuccess,
    UpsertMoreSkillAssignmentSuccess,
    UpsertSkillAssignmentsSuccess,
    (state, {entities}) => ({
      ...adapter.addMany(
        entities.map((skillAssignment) => ({
          id: skillAssignment.id,
          data: skillAssignment,
          loading: false,
          error: null,
        })),
        state,
      ),
      error: null,
      loading: false,
    }),
  ),

  on(
    LoadSkillAssignmentsByUserId,
    LoadSkillAssignmentsByUserGroupId,
    LoadSkillAssignmentsBySkillId,
    UpsertSkillAssignment,
    UpsertSkillAssignments,
    (state, {}) => ({...state, loading: true}),
  ),

  on(
    LoadSkillAssignmentsByUserIdError,
    LoadSkillAssignmentsByUserGroupIdError,
    LoadSkillAssignmentsBySkillIdError,
    UpsertMoreSkillAssignmentError,
    UpsertSkillAssignmentError,
    UpsertSkillAssignmentsError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),

  on(UpsertSkillAssignmentSuccess, (state, {skillAssignment}) =>
    adapter.upsertOne(
      {
        id: skillAssignment.id,
        data: skillAssignment,
        loading: false,
        error: null,
      },
      state,
    ),
  ),

  on(DeleteSkillAssignmentSuccess, (state, skillAssignment) =>
    adapter.removeOne(skillAssignment.id, state),
  ),

  on(UpsertMoreSkillAssignment, (state) => adapter.removeAll(state)),
);
