import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  Optional,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';

import {translation} from '../i18n';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {FrameworkTooltipModule} from '@tsm/framework/tooltip';
import {TranslocoPipe} from '@jsverse/transloco';

@Component({
  selector: 'dtl-form-input-tri-state-checkbox',
  templateUrl: './form-input-tri-state-checkbox.component.html',
  styleUrls: ['../form-input-text/form-input-text.component.scss'], // NOTE: Styles from different component
  styles: [
    `
      :host > *:first-child {
        display: inline-flex;
      }

      :host p-checkbox {
        vertical-align: bottom;
      }
    `,
  ],
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputTriStateCheckboxComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputTriStateCheckboxComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputTriStateCheckboxComponent),
  ],
  host: {
    class: 'p-state-filled-fix readonly-no-border',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TriStateCheckboxModule,
    FormsModule,
    FrameworkTooltipModule,
    TranslocoPipe,
  ],
})
export class FormInputTriStateCheckboxComponent extends ParentWidgetAccessorComponent {
  @Input() labelForId: string;

  translation = translation;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
    public injector: Injector,
  ) {
    super(cdr, layoutIdDirective);
  }
}
