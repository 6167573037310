import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  ExportStatus,
  ListingProfile,
  ListingProfileModel,
  SortType,
  Table,
  TableType,
} from '../models';
import {ConfigService} from '@tsm/framework/config';
import {ApiService, Envelope} from '@tsm/framework/http';
import {SharedRequest} from '@tsm/framework/root';

@Injectable({
  providedIn: 'root',
})
export class ListingProfileService {
  constructor(
    private apiService: ApiService,
    private config: ConfigService,
  ) {}

  getTableProfiles(
    type: TableType | string,
  ): Observable<Envelope<ListingProfile[]>> {
    return this.apiService.get<any, ListingProfile[]>(
      `${
        this.config.value.apiUrls.tsmFormConfig
      }/listing-profile/listing?listingCode=${
        type instanceof Object ? type.type : type
      }`,
      (serverType) =>
        serverType.map((profile) => this.convertProfileFromDB(profile)),
    );
  }

  getAllTableProfiles(): Observable<Envelope<ListingProfile[]>> {
    return this.apiService.get<any, ListingProfile[]>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile`,
      (serverType) =>
        serverType.map((profile) => this.convertProfileFromDB(profile)),
    );
  }

  getListingProfilesByEntityTypeCode(code: string) {
    return this.apiService.get<any, ListingProfile[]>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/listing/entity-type?entityTypeCode=${code}`,
      (serverType) =>
        serverType.map((profile) => this.convertProfileFromDB(profile)),
    );
  }

  saveProfile(
    profile: ListingProfile,
    state: Table,
    type: TableType,
  ): Observable<Envelope<ListingProfile>> {
    if (profile && profile.id) {
      return this.apiService.put<any, ListingProfile>(
        `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/${profile.id}`,
        this.convertProfileToDB(profile, state, type),
        (serverType) => this.convertProfileFromDB(serverType),
      );
    } else {
      return this.apiService.post<any, ListingProfile>(
        `${this.config.value.apiUrls.tsmFormConfig}/listing-profile`,
        this.convertProfileToDB(profile, state, type),
        (serverType) => this.convertProfileFromDB(serverType),
      );
    }
  }

  saveUserDefaultProfile(
    profile: ListingProfile,
    state: Table,
    type: TableType,
  ): Observable<Envelope<ListingProfile>> {
    return this.apiService.put<any, ListingProfile>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/default/${profile.id}`,
      this.convertProfileToDB(profile, state, type),
      (serverType) => this.convertProfileFromDB(serverType),
    );
  }

  deleteProfile(profile: ListingProfile): Observable<Envelope<void>> {
    return this.apiService.delete<any, void>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/${profile.id}`,
    );
  }

  removeDefaultProfile(
    profile: ListingProfile,
    type: TableType,
  ): Observable<Envelope<ListingProfile>> {
    return this.apiService.put<any, ListingProfile>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/${profile.id}`,
      {
        ...profile,
        default: false,
      },
      (serverType) => this.convertProfileFromDB(serverType),
    );
  }

  @SharedRequest
  getListingProfilesByCodes(
    codes: string[],
  ): Observable<Envelope<ListingProfile[]>> {
    return this.apiService.post<ListingProfileModel[], ListingProfile[]>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/allByCodes`,
      codes,
      (serverType) => serverType.map((x) => this.convertProfileFromDB(x)),
    );
  }

  @SharedRequest
  getListingProfilesById(id: string): Observable<Envelope<ListingProfile>> {
    return this.apiService.get<ListingProfileModel, ListingProfile>(
      `${this.config.value.apiUrls.tsmFormConfig}/listing-profile/${id}`,
      (serverType) => this.convertProfileFromDB(serverType),
    );
  }

  deleteExportTemplateAttachment(id: string): Observable<Envelope<any>> {
    return this.apiService.delete<any, any>(
      `${this.config.value.apiUrls.dms}/attachment/${id}`,
    );
  }

  private convertProfileFromDB(profile: ListingProfileModel): ListingProfile {
    return {
      ...profile,
      codeListing: profile.codeListing,
      isDefault: profile.default,
      exportSettings: profile.exportSettings,
      filters:
        profile.filters == null
          ? []
          : profile.filters.map((f) => ({
              field: f.field,
              value: f.value,
              displayValue: f.displayValue,
              operator: f.operator,
            })),
      sorts: profile.sortField
        ? [
            {
              field: profile.sortField,
              sortType: profile.sortOrder === -1 ? SortType.desc : SortType.asc,
            },
          ]
        : [],
      colors: profile.colors ? profile.colors : {rowColorExpr: null},
      pageSize: profile.rowsCount,
      exportHeaders: [],
      columns: profile.selectedColumns,
      userId: profile.userId,
      nextSendExec: profile.nextSendExec,
      exportStatus: profile.exportStatus,
      dataViewMode: profile.dataViewMode,
      tqlName: profile.tqlName,
      profileCategory: profile.profileCategory,
    };
  }

  // state - aktulani stav storu
  private convertProfileToDB(
    profile: ListingProfile,
    state: Table,
    type: TableType,
  ): ListingProfileModel {
    const isProfile = profile && profile.id;
    return {
      ...profile,
      id: isProfile ? profile.id : null,
      codeListing: type.type,
      default: profile.isDefault,
      exportSettings: profile.exportSettings,
      filters: state.filters
        .filter((f) => !(f.readonly === true))
        .map((f) => ({
          // TODO co ostatni plozky?
          field: f.field,
          value: f.value,
          displayValue: f.displayValue,
          operator: f.operator,
        })),
      sortField: state.sorts && state.sorts[0] ? state.sorts[0].field : '',
      sortOrder:
        state.sorts && state.sorts[0]
          ? state.sorts[0].sortType === SortType.desc
            ? -1
            : 1
          : null,
      colors: state.colors,
      rowsCount: state.pageSize,
      selectedColumns: state.columns.map((c) => ({
        field: c.field,
        width: c.width,
        visible: c.visible != null ? c.visible : true,
        visibleCard: c.visibleCard != null ? c.visibleCard : true,
        displayAllowed: c.displayAllowed != null ? c.displayAllowed : true,
      })),
      userId: profile.userId,
      nextSendExec: profile.nextSendExec,
      exportStatus:
        profile.exportSettings == null || profile.nextSendExec == null
          ? ExportStatus.INACTIVE
          : ExportStatus.ACTIVE,
      dataViewMode: state.dataViewMode,
      tqlName: profile.tqlName,
      profileCategory: profile.profileCategory,
      config: {
        ...profile.config,
        kanban: state.kanban.map((k) => ({
          listId: k.listId,
          title: k.title,
          filters: k.filters
            .filter((f) => !(f.readonly === true))
            .map((f) => ({
              // TODO co ostatni plozky?
              field: f.field,
              value: f.value,
              displayValue: f.displayValue,
              operator: f.operator,
            })),
        })),
      },
    };
  }
}
