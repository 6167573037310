import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Optional,
} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {FormFieldInput} from '@tsm/framework/forms';
import {LayoutIdDirective} from '@tsm/framework/root/layout-id';
import {
  useParentWidgetProvidersFor,
  ParentWidgetAccessorComponent,
} from '@tsm/framework/parent-widget';
import {FrameworkGridModule} from '@tsm/framework/grid';
import {NgIf} from '@angular/common';
import {InputSwitchModule} from 'primeng/inputswitch';

@Component({
  selector: 'dtl-form-input-switch',
  templateUrl: './form-input-switch.component.html',
  styleUrls: ['./form-input-switch.component.scss'],
  providers: [
    {
      provide: FormFieldInput,
      useExisting: FormInputSwitchComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FormInputSwitchComponent),
      multi: true,
    },
    ...useParentWidgetProvidersFor(FormInputSwitchComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FrameworkGridModule, NgIf, InputSwitchModule, FormsModule],
})
export class FormInputSwitchComponent extends ParentWidgetAccessorComponent {
  @Input() leftLabel: string;

  @Input() rightLabel: string;

  @Input() inputId: string;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Optional() public layoutIdDirective: LayoutIdDirective,
  ) {
    super(cdr, layoutIdDirective);
  }

  rightLabelClicked() {
    if (!this.readonly) {
      this.val =
        this.leftLabel != null && this.rightLabel != null ? true : !this.val;
    }
  }

  leftLabelClicked() {
    if (!this.readonly) {
      this.val =
        this.leftLabel != null && this.rightLabel != null ? false : !this.val;
    }
  }
}
