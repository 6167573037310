export interface TokenPrivilege {
  visible?: boolean;
}

export type TokenPrivilegeType = string | TokenPrivilege;

const checkPrivileges = (checkPriv: string[], priv: string[]): boolean => {
  if (checkPriv.length === priv.length) {
    return checkPriv.every((v, i) => v === priv[i]);
  } else {
    let result = true;
    priv.forEach((x, index) => {
      if (x === checkPriv[index] && result) {
        result = true;
      } else {
        result = false;
      }
    });
    return result;
  }
};

export const hasPriv = (tokenPriv: TokenPrivilegeType, privileges: string[]) => {
  // Always return true if tokenPriv has the 'visible' property
  if (
    tokenPriv &&
    typeof tokenPriv === 'object' &&
    tokenPriv.visible === true
  ) {
    return true;
  }

  if (!tokenPriv || typeof tokenPriv !== 'string') {
    return false;
  }

  if (tokenPriv === 'true') {
    return true;
  }

  // dohledam vsechny pozitivni i negativni privilegia co mam a jsou prefixem meho
  const plus = privileges.filter((p) => {
    const checkPriv = tokenPriv.toUpperCase();
    const priv = p.toUpperCase();
    return checkPrivileges(checkPriv.split('.'), priv.split('.'));
  });
  const minus = privileges.filter((p) => {
    const checkPriv = '-' + tokenPriv.toUpperCase();
    const priv = p.toUpperCase();
    return checkPrivileges(checkPriv.split('.'), priv.split('.'));
  });

  if (!minus) {
    // pokud nejsou zadne negativni a mam alespon jedno pozitivni, tak mam pravo
    return !!plus;
  } else {
    // existuje pozitivni, ktere neni prefixem negativniho
    // Crm.Customer
    // -Crm.Customer.Account
    // Crm.Customer.Account.Add  - pokud tam je, neni prefixem radku nad tim a proto pravo mam. Prvni Crm.Customer je prefixem, proto je zruseno
    return !!plus.find(
      (a) =>
        !minus.find((b) => b.toUpperCase().startsWith('-' + a.toUpperCase())),
    );
  }
};

export const hasPrivStrict = (tokenPriv: string, privileges: string[]) => {
  if (!tokenPriv) {
    return false;
  }

  if (tokenPriv === 'true') {
    return true;
  }

  // dohledam vsechny pozitivni i negativni privilegia co mam a jsou prefixem meho
  const plus = privileges.filter(
    (a) => tokenPriv.toUpperCase() === a.toUpperCase(),
  );
  const minus = privileges.filter(
    (a) => '-' + tokenPriv.toUpperCase() === a.toUpperCase(),
  );

  if (!minus) {
    // pokud nejsou zadne negativni a mam alespon jedno pozitivni, tak mam pravo
    return !!plus;
  } else {
    return !!plus.find(
      (a) => !minus.find((b) => b.toUpperCase() === '-' + a.toUpperCase()),
    );
  }
};

export const defaultPolicy = (
  authorities: string[],
  desiredAuthorities: string[],
) => {
  return desiredAuthorities.every((x) => hasPriv(x, authorities));
};
