export const cs = {
  configFormPlugins: {
    headerDescription: 'Hlavička dokumentace',
    filters: {
      tsmConfigTypeFilter: 'tSM Config Type Filter',
      tsmConfigTypeFilterDescription:
        'Filtruje typy konfigurace na základě předdefinovaných atributů. ' +
        'Podporuje vícenásobný výběr a umožňuje filtrování ' +
        'podle kódu a názvu pro přesné výsledky.',
      tsmEntitySpecificationIdFilter: 'tSM Entity Specification Id Filter',
      tsmEntitySpecificationIdFilterDescription:
        'Filtruje specifikace entit na základě předdefinovaných atributů. ' +
        'Podporuje vícenásobný výběr a umožňuje filtrování ' +
        'podle kódu a názvu pro efektivní správu entit.',
      tsmEntitySpecificationTypeFilter: 'tSM Entity Specification Type Filter',
      tsmEntitySpecificationTypeFilterDescription:
        'Filtruje typy specifikací entit na základě předdefinovaných kritérií. ' +
        'Podporuje vícenásobný výběr a umožňuje filtrování ' +
        'podle kódu a názvu pro efektivní správu typů entit.',
      tsmFormFilter: 'tSM Form Filter',
      tsmFormFilterDescription:
        'Filtruje formuláře podle předdefinovaných kritérií a umožňuje vícenásobný ' +
        'výběr. Podporuje filtrování podle kódu a názvu pro efektivní výběr ' +
        'a správu formulářů.',
      tsmFormIdFilter: 'tSM Form Id Filter',
      tsmFormIdFilterDescription:
        'Filtruje formuláře na základě unikátních identifikátorů a umožňuje vícenásobný ' +
        'výběr. Umožňuje přesné filtrování formulářů podle ID a názvu, což optimalizuje ' +
        'jejich správu a výběr.',
      tsmFormTypeFilter: 'tSM Form Type Filter',
      tsmFormTypeFilterDescription:
        'Filtruje formuláře podle jejich typu a umožňuje vícenásobný výběr. ' +
        'Poskytuje strukturovaný způsob kategorizace a výběru formulářů, ' +
        'což zvyšuje efektivitu jejich správy.',
      tsmIndexMappingFilter: 'tSM Index Mapping Filter',
      tsmIndexMappingFilterDescription:
        'Filtruje mapování indexů s podporou vícenásobného výběru. ' +
        'Zobrazuje dostupná mapování ve strukturovaném seznamu, což umožňuje ' +
        'uživatelům efektivně zpřesnit jejich výběr.',
      tsmMicroserviceFilter: 'tSM Microservice Filter',
      tsmMicroserviceFilterDescription:
        'Filtruje mikroslužby s podporou vícenásobného výběru. ' +
        'Zobrazuje dostupné mikroslužby ve strukturovaném seznamu, ' +
        'umožňující uživatelům efektivně zpřesnit jejich výběr.',
      tsmRegisterValueFilter: 'tSM Register Value Filter',
      tsmRegisterValueFilterDescription:
        'Filtruje hodnoty registru na základě zadaného kontextu. ' +
        'Podporuje výběr podle ID nebo kódu, což umožňuje uživatelům ' +
        'dynamicky zpřesnit kritéria filtru.',
      tsmSchemaLocationFilter: 'tSM Schema Location Filter',
      tsmSchemaLocationFilterDescription:
        'Filtruje umístění schémat na základě dostupných možností. ' +
        'Podporuje vícenásobný výběr a dynamický překlad ' +
        'zobrazených hodnot.',
      tsmSyncMethodTypeFilter: 'tSM Sync Method Type Filter',
      tsmSyncMethodTypeFilterDescription:
        'Filtruje synchronizační metody z dostupných možností. ' +
        'Podporuje vícenásobný výběr a dynamický překlad ' +
        'zobrazených hodnot.',
      tsmTsmModuleFilter: 'tSM Tsm Module Filter',
      tsmTsmModuleFilterDescription:
        'Filtruje moduly TSM podle jejich kódu nebo názvu. ' +
        'Podporuje vícenásobný výběr a dynamicky načítá ' +
        'vybrané hodnoty.',
    },
    pipes: {
      arrayToString: 'Array To String',
      arrayToStringDescription:
        'Transformuje pole objektů na řetězec, kde jsou objekty odděleny daným oddělovačem. ' +
        'Podporuje zobrazení specifických polí, zpracování unikátních hodnot a použití transformační ' +
        'pipe na jednotlivé prvky.',
      tsmCharsFormTypePipe: 'TSM Chars Form Type Pipe',
      tsmCharsFormTypePipeDescription:
        'Filtruje a třídí seznam charakteristik podle typu formuláře, umístění schématu ' +
        'a uživatelských oprávnění. Zajišťuje zobrazení pouze dostupných položek a řadí je ' +
        'podle výchozího pořadí.',
      tsmColorExpressionPipe: 'TSM Color Expression Pipe',
      tsmColorExpressionPipeDescription:
        'Převádí hodnotu na její JSON řetězcovou reprezentaci pomocí JSON pipe. ' +
        'Pokud je hodnota null, vrací prázdný řetězec.',
      configTypeCode: 'Config Type Code',
      configTypeCodeDescription:
        'Načítá data typu konfigurace podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      configType: 'Config Type',
      configTypeDescription:
        'Načítá data typu konfigurace podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      entitySpecificationCode: 'Entity Specificatio nCode',
      entitySpecificationCodeDescription:
        'Načítá data specifikace entity podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      entitySpecificationType: 'Entity Specification Type',
      entitySpecificationTypeDescription:
        'Načítá data typu specifikace entity podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      entitySpecification: 'Entity Specification',
      entitySpecificationDescription:
        'Načítá data specifikace entity podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      formatText: 'Format Text',
      formatTextDescription:
        'Formátuje zadaný textový řetězec nahrazením znaků nového řádku a tabulátoru HTML prvky. ' +
        'Nové řádky jsou převedeny na <br> tagy a tabulátory na pevné mezery.',
      tsmFormsCode: 'TSM Forms Code',
      tsmFormsCodeDescription:
        'Načítá data formuláře podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      tsmForms: 'TSM Forms',
      tsmFormsDescription:
        'Načítá data formuláře podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      microserviceCode: 'Microservice Code',
      microserviceCodeDescription:
        'Načítá data mikroservisy podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      microservice: 'Microservice',
      microserviceDescription:
        'Načítá data mikroservisy podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou dostupná.',
      mnemonicConfigCode: 'Mnemonic Config Code',
      mnemonicConfigCodeDescription:
        'Načítá data mnemonické konfigurace podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      mnemonicConfig: 'Mnemonic Config',
      mnemonicConfigDescription:
        'Načítá data mnemonické konfigurace podle ID z úložiště, získává specifická pole, jako je název, ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      pipeInPipe: 'Pipe In Pipe',
      pipeInPipeDescription:
        'Transformuje vstupní hodnotu aplikací dvou po sobě jdoucích pipe, umožňuje ' +
        'přizpůsobení argumentů a zajišťuje, že data projdou oběma transformacemi.',
      registerByCode: 'Register By Code',
      registerByCodeDescription:
        'Načítá data registru podle kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      registerById: 'Register By Id',
      registerByIdDescription:
        'Načítá data registru podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt a zajišťuje načtení dat, pokud nejsou dostupná.',
      registerValueArrayById: 'Register Value Array By Id',
      registerValueArrayByIdDescription:
        'Načítá pole hodnot registru podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo všechna data a zajišťuje načtení hodnot, pokud nejsou dostupné.',
      registerValueById: 'Register Value By Id',
      registerValueByIdDescription:
        'Načítá hodnotu registru podle ID z úložiště, získává specifická pole, jako je název ' +
        'nebo všechna data a zajišťuje načtení hodnoty, pokud není dostupná.',
      registerValueIcon: 'Register Value Icon',
      registerValueIconDescription:
        'Načítá ikonu hodnoty registru podle kódu registru a kódu z úložiště, získává specifická ' +
        'pole, jako je název, aplikuje transformaci ikony a zajišťuje načtení dat, pokud nejsou ' +
        'dostupná.',
      registerValueName: 'Register Value Name',
      registerValueNameDescription:
        'Načítá hodnotu registru podle kódu registru a názvu z úložiště, získává specifická ' +
        'pole, jako je název nebo celý objekt, a zajišťuje načtení dat, pokud nejsou ' +
        'dostupná.',
      registerValue: 'Register Value',
      registerValueDescription:
        'Načítá hodnotu registru podle kódu registru a kódu z úložiště, získává specifická pole, ' +
        'jako je název nebo celý objekt a zajišťuje načtení dat, pokud nejsou již dostupná.',
      tsmModulesCode: 'TSM Modules Code',
      tsmModulesCodeDescription:
        'Načítá data modulu TSM podle jeho kódu z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou již dostupná.',
      tsmModulesId: 'TSM Modules Id',
      tsmModulesIdDescription:
        'Načítá data modulu TSM podle jeho ID z úložiště, získává specifická pole, jako je název ' +
        'nebo celý objekt, a zajišťuje načtení dat, pokud nejsou již dostupná.',
    },
    directives: {
      viewDefer: 'View Defer',
      viewDeferDescription:
        'Odkládá načítání obsahu, dokud se nestane viditelným v okně prohlížeče při posouvání. ' +
        'Užitečné pro optimalizaci výkonu odložením vykreslení obsahu, který není zpočátku viditelný. ' +
        'Při načtení obsahu vyvolá událost "onLoad". Používá události posouvání a rozměry okna pro ' +
        'určení okamžiku načtení odloženého obsahu.',
    },
  },
};
