import {TsmFormBuilder} from '@tsm/framework/forms';

/**
 * Creates a configurable form group for a List of Values (LOV) component.
 * This method allows setting default values for various LOV configuration parameters
 * while also providing the flexibility to override them.
 *
 * @param {TsmFormBuilder} fb - The form builder used to create form controls and groups.
 * @param {Object} defaults - An optional object containing overrides for default configuration parameters.
 * @param {boolean} [defaults.keyboardOnMobile=true] - Enables or disables keyboard interaction on mobile devices.
 * @param {boolean} [defaults.showClear=true] - Determines whether a clear button is shown in the LOV component.
 * @param {boolean} [defaults.showNoneText=true] - Controls whether a 'None' option is displayed in the LOV component.
 * @param {boolean} [defaults.selectFirstValue=false] - Specifies if the first value should be automatically selected.
 * @param {'id' | 'code' | 'key' | 'all' | string} [defaults.selectProperty='id'] - Defines the property used for selecting items in the LOV component.
 * @param {'single' | 'multiple'} [defaults.selectionMode='multiple'] - Specifies the selection mode, e.g., 'single' or 'multiple'.
 * @param {'string' | 'array'} [defaults.returnType='array'] - Defines the return type of the selection, e.g., 'single' value or an 'array' of values.
 *
 * @returns {TsmFormGroup} - A form group containing the LOV configuration with the specified or default parameters.
 */
export function getLovBoxDefaultConfigForm(
  fb: TsmFormBuilder,
  defaults?: {
    keyboardOnMobile?: boolean;
    showClear?: boolean;
    showNoneText?: boolean;
    selectFirstValue?: boolean;
    selectProperty?: 'id' | 'code' | 'key' | 'all' | string;
    multiselect?: boolean;
    selectionMode?: 'single' | 'multiple';
    returnType?: 'string' | 'array';
    displayField?: any;
  },
) {
  defaults = {
    keyboardOnMobile: true,
    showClear: true,
    showNoneText: true,
    selectFirstValue: false,
    selectProperty: 'id',
    selectionMode: 'multiple',
    multiselect: false,
    returnType: 'array',
    displayField: 'name',
    ...defaults,
  };
  return fb.tsmGroup({
    keyboardOnMobile: fb.tsmControl(defaults.keyboardOnMobile),
    showClear: fb.tsmControl(defaults.showClear),
    showNoneText: fb.tsmControl(defaults.showNoneText),
    selectFirstValue: fb.tsmControl(defaults.selectFirstValue),
    selectProperty: fb.tsmControl(defaults.selectProperty),
    multiselect: fb.tsmControl(defaults.multiselect),
    selectionMode: fb.tsmControl(defaults.selectionMode),
    returnType: fb.tsmControl(defaults.returnType),
    defaultFilters: fb.tsmControl(),
    displayField: fb.tsmControl(defaults.displayField),
  });
}
