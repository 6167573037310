import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, mergeMap, map} from 'rxjs/operators';
import {
  DeleteNotification,
  DeleteNotificationError,
  DeleteNotificationSuccess,
  LoadNotification,
  LoadNotificationError,
  LoadNotificationSuccess,
  UpsertNotification,
  UpsertNotificationError,
  UpsertNotificationSuccess,
} from '../actions';
import {NotificationService} from '../services';

@Injectable()
export class NotificationEffect {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
  ) {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadNotification),
      mergeMap((action) => {
        return this.notificationService
          .getNotificationByUserId(action.userId)
          .pipe(
            map((userNotif) =>
              userNotif.success
                ? LoadNotificationSuccess({
                    userId: action.userId,
                    notification: userNotif.data,
                  })
                : LoadNotificationError({
                    userId: action.userId,
                    error: userNotif.error,
                  }),
            ),
          );
      }),
    ),
  );

  upsert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertNotification),
      concatMap(({notification}) =>
        this.notificationService
          .saveNotification(notification)
          .pipe(
            map((env) =>
              env.success
                ? UpsertNotificationSuccess({notification: env.data})
                : UpsertNotificationError({error: env.error}),
            ),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteNotification),
      concatMap(({id, userId}) =>
        this.notificationService
          .deleteNotificationById(id)
          .pipe(
            map((env) =>
              env.success
                ? DeleteNotificationSuccess({id: id, userId})
                : DeleteNotificationError({id: id, error: env.error}),
            ),
          ),
      ),
    ),
  );
}
