import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IdentifiedPack} from '@tsm/framework/root';
import {createReducer, on} from '@ngrx/store';
import {
  DeleteCharacteristicsValueError,
  DeleteCharacteristicsValueSuccess,
  LoadSpecCharacteristicsValues,
  LoadSpecCharacteristicsValuesByCharIdError,
  LoadSpecCharacteristicsValuesByCharIdSuccess,
  LoadSpecCharacteristicsValuesError,
  LoadSpecCharacteristicsValuesSuccess,
  UpsertCharacteristicsValueError,
  UpsertCharacteristicsValueSuccess,
} from '../actions';
import {CharacteristicsValue} from '../models';
import {addDays} from 'date-fns';

export type CharacteristicsValuesState = EntityState<
  IdentifiedPack<CharacteristicsValue>
>;

export const characteristicsValuesAdapter: EntityAdapter<
  IdentifiedPack<CharacteristicsValue>
> = createEntityAdapter<IdentifiedPack<CharacteristicsValue>>({});
export const characteristicsValuesInitialState: CharacteristicsValuesState =
  characteristicsValuesAdapter.getInitialState({});

export const characteristicsValuesReducer = createReducer(
  characteristicsValuesInitialState,

  on(LoadSpecCharacteristicsValues, (state, {}) => ({...state, loading: true})),

  on(
    LoadSpecCharacteristicsValuesSuccess,
    LoadSpecCharacteristicsValuesByCharIdSuccess,
    (state, {entities}) => ({
      ...characteristicsValuesAdapter.addMany(
        entities.map((statusOnly) => ({
          id: statusOnly.id,
          data: statusOnly,
          loading: false,
          error: null,
          validUntil: addDays(new Date(), 1),
        })),
        state,
      ),
      error: null,
    }),
  ),

  on(DeleteCharacteristicsValueSuccess, (state, {id}) =>
    characteristicsValuesAdapter.removeOne(id, state),
  ),

  on(UpsertCharacteristicsValueSuccess, (state, {characteristicsValue}) =>
    characteristicsValuesAdapter.upsertOne(
      {
        id: characteristicsValue.id,
        data: characteristicsValue,
        loading: false,
        error: null,
        validUntil: null,
      },
      state,
    ),
  ),

  on(
    LoadSpecCharacteristicsValuesError,
    UpsertCharacteristicsValueError,
    DeleteCharacteristicsValueError,
    LoadSpecCharacteristicsValuesByCharIdError,
    (state, {error}) => ({...state, loading: false, error: error}),
  ),
);
