import {Directive, HostBinding} from '@angular/core';
import {Input} from '@angular/core';

@Directive({
  selector: '[fixed]',
})
/**
 * **Fixed Directive**
 *
 * If is necessary to set a fixed width for the `<dtl-col>`, use the `fixed` selector directive to sets up everything automatic.
 *
 * Can be use as classic directive or with a condition.
 *
 * The default value is `true`.
 *
 * **Usage:**
 *
 * ```html
 * <dtl-col fixed>
 * ```
 *
 * **With a condition**
 *
 * ```html
 * <dtl-col [fixed]="true || false">
 * ```
 */
export class FixedColDirective {
  @Input() set fixed(fixed: boolean | string) {
    this.fixedClass = (fixed === '' || fixed == null ? true : fixed) as boolean;
  }

  @HostBinding('class.col-fixed')
  fixedClass = true;

  constructor() {}
}
