import {Injector, Pipe, PipeTransform, Type} from '@angular/core';
import {combineLatest, isObservable, Observable, of} from 'rxjs';
import {distinctArrays} from '@tsm/framework/functions';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'notificationToOwnerType',
  pure: true,
})
export class NotificationToOwnerTypePipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(
    array: any[],
    ownerType: string,
    elementPipeType: Type<PipeTransform> = null,
    elementPipeArgs: any = null,
  ): Observable<string> {
    let resultArray = [];
    if (array == null || array.length === 0) {
      return of('');
    }

    resultArray = array
      .filter((x) => !!x.ownerId && x.ownerType === ownerType)
      .map((x) => x.ownerId);

    resultArray = distinctArrays(null, resultArray);
    if (resultArray.length === 0) {
      return of(null);
    }
    if (!!ownerType) {
      const pipe = this.injector.get<PipeTransform>(elementPipeType);
      const args = !Array.isArray(elementPipeArgs)
        ? [elementPipeArgs]
        : elementPipeArgs;
      const observables = resultArray.map((el) => {
        const r = pipe.transform(el, ...args);
        if (isObservable(r)) {
          return r;
        } else {
          return of(r);
        }
      });
      return combineLatest(observables).pipe(
        map((x) => x.filter((x) => x != null).join(', ')),
      );
    } else {
      return of('');
    }
  }
}
