import {Plugin} from './lib/models';

export const frameworkPlugin: Plugin = {
  menus: [],
  routes: [],
  widgets: [
    {
      definitions: [
        // ZDE MUZOU BYT POUZE STANDALONE WIDGETS KTERE JSOU SOUCASTI FRAMEWORKU
        // {
        //   selector: 'tsm-full-calendar',
        //   loadComponent: () => import('@tsm/calendar/widgets/full-calendar').then(x => x.FullCalendarComponent),
        //   privilege: ['true'],
        //   designers: [{
        //     editorSelector: 'tsm-full-calendar-editor',
        //     loadComponent: () => import('@tsm/calendar/widgets/full-calendar').then(x => x.FullCalendarEditorComponent),
        //     widgetOrLayout: 'layout',
        //     categoryId: DesignerCategoryEnum.Calendar,
        //     name: translationShared.shared.widgets.fullCalendarPanel.widgetName,
        //     backgroundColorOverride: DesignerDataColorOverride.grey,
        //     description: translationShared.shared.widgets.fullCalendarPanel.widgetDescription,
        //     group: GroupPosition.Primary,
        //     order: 6,
        //     icon: DesignerIconsEnum.panel,
        //     value: {
        //       title: translationShared.shared.widgets.fullCalendarPanel.inWidgetTitle,
        //       widget: {
        //         type: 'tsm-full-calendar'
        //       },
        //       type: 'layout',
        //       config: {
        //         filterOnlyByOwner: true,
        //         canAddEvent: true,
        //         canEditEvent: true,
        //         hideLeftPanel: true,
        //       }
        //     }
        //   }]
        // }
      ],
    },
  ],
  filters: [
    {
      definitions: [
        // {
        //   selector: 'tsm-full-calendar-event-type-filter',
        //   loadComponent: () => import('@tsm/calendar/widgets/full-calendar').then(x => x.FullCalendarEventTypeFilterComponent)
        // }
      ],
    },
  ],
  pipes: [
    {
      definitions: [
        {
          selector: 'convertMinutesToHoursMinutes',
          loadComponent: () =>
            import('@tsm/framework/root').then(
              (x) => x.ConvertMinutesToHoursMinutesPipe,
            ),
        },
      ],
    },
  ],
};
