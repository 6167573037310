import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesToText',
  pure: false,
})
export class MinutesToTextPipe implements PipeTransform {
  transform(value: any): any {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return (hours > 0 ? hours + 'h ' : '') + minutes + 'min';
  }
}
