import {
  DesignerCategoryEnum,
  DesignerDataColorOverride,
  DesignerIconsEnum,
  GroupPosition,
  Plugin,
} from '@tsm/framework/plugin';

import {translation as translationShared} from '@tsm/shared-i18n';
import {translation as translationPlugin} from '@tsm/logging/plugins-i18n';
import {of} from 'rxjs';

export const loggingPlugin: Plugin = {
  menus: [],
  routes: [
    {
      path: 'logging',
      loadChildren: () =>
        import('@tsm/logging/components').then(
          (x) => x.LoggingComponentsModule,
        ),
    },
  ],
  widgets: [
    {
      loadChildren: () =>
        import('@tsm/logging/widgets').then((x) => x.LoggingWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/logging/widgets/owner-widget').then(
              (x) => x.OwnerWidgetComponent,
            ),
          selector: 'tsm-owner-widget',
        },
        {
          loadComponent: () =>
            import('@tsm/logging/widgets/log-card').then(
              (x) => x.LogCardComponent,
            ),
          selector: 'tsm-log-card',
        },
        {
          loadComponent: () =>
            import('@tsm/logging/widgets/log-list-widget/log-list-widget').then(
              (x) => x.LogListWidgetComponent,
            ),
          selector: 'tsm-log-list-widget',
          privilege: ['true'],
          designers: [
            {
              loadComponent: () =>
                import(
                  '@tsm/logging/widgets/log-list-widget/log-list-widget-editor'
                ).then((x) => x.LogListWidgetEditorComponent),
              editorSelector: 'tsm-log-list-widget-editor',
              widgetOrLayout: 'layout',
              categoryId: DesignerCategoryEnum.Logging,
              name: translationShared.shared.widgets.logList.widgetName,
              backgroundColorOverride: DesignerDataColorOverride.grey,
              description:
                translationShared.shared.widgets.logList.widgetDescription,
              group: GroupPosition.Primary,
              order: 1, // Order for lists, adjust if necessary
              icon: DesignerIconsEnum.list,
              value: {
                title: translationShared.shared.widgets.logList.inWidgetTitle,
                widget: {
                  type: 'tsm-log-list-widget',
                },
                type: 'layout',
                config: {
                  pageSize: 10,
                },
              },
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      loadChildren: () =>
        import('@tsm/logging/widgets').then((x) => x.LoggingWidgetsModule),
      definitions: [
        {
          loadComponent: () =>
            import('@tsm/logging/widgets/filters/log-type-filter').then(
              (x) => x.LogTypeFilterComponent,
            ),
          selector: 'tsm-log-type-filter',
          name: translationPlugin.loggingPlugins.filters.tsmLogTypeFilter,
          description:
            translationPlugin.loggingPlugins.filters
              .tsmLogTypeFilterDescription,
        },
      ],
    },
  ],
  entityTypes: [
    {
      entityType: 'LogType',
      url: (entityId) => {
        return of('/logging/log-type');
      },
    },
  ],
};
