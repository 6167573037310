import {buffer, repeatWhen, takeUntil} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {coalesceWith} from '@rx-angular/cdk/coalescing';
import {
  animationFrameScheduler,
  interval,
  MonoTypeOperatorFunction,
  Observable,
} from 'rxjs';
import {ActionCreator} from '@ngrx/store';

const animationFrame$ = interval(0, animationFrameScheduler) as Observable<any>;

export function animationBuffer(
  actions$: Actions,
  ofTypeAction: ActionCreator,
  startAction: ActionCreator,
  stopAction: ActionCreator,
): MonoTypeOperatorFunction<any[]> {
  return (source$) =>
    source$.pipe(
      buffer(
        actions$.pipe(
          ofType(ofTypeAction),
          coalesceWith(animationFrame$ as any) as any,
          takeUntil(actions$.pipe(ofType(stopAction))),
          repeatWhen(() => actions$.pipe(ofType(startAction))),
        ),
      ),
    );
}
