import {Directive, ElementRef, NgModule} from '@angular/core';

@Directive({
  selector: 'img[autohide]',
})
export class ImageAutohideDirective {
  constructor(elementRef: ElementRef<HTMLImageElement>) {
    elementRef.nativeElement.onerror = () => {
      elementRef.nativeElement.style.display = 'none';
    };
  }
}

@NgModule({
  declarations: [ImageAutohideDirective],
  exports: [ImageAutohideDirective],
})
export class ImageAutohideDirectiveModule {}
