export const en = {
  listingLib: {
    components: {
      idFilterLabel: 'List of IDs',
      columnManager: 'Manage columns',
      sortManager: 'Manage sort',
      visibleColumns: 'Visible Columns',
      hidenColumns: 'Hidden columns',
      searchColumnName: 'Search column name',
      fulltextNotSupported: 'Fulltext search is not supported here.',
      itemsOfFulltextSearch:
        'Fulltext search is possible according to the following items:',
      profileManager: 'Profile overview',
      profileSettings: 'Profile settings',
      savedProfilesNotExists: 'No saved profiles found.',
      filters: 'Filters',
      columnSettings: 'Column settings',
      saveProfileChanges: 'Save profile changes',
      exportSettings: 'Export settings',
      maxCountForExport: 'Maximum number of records to export',
      repeating: 'Repetition',
      nextStart: 'Next start',
      sendEmpty: 'Also send empty',
      sendToOwner: 'Send to profile owner',
      sendToShared: 'Send to shared users',
      xDayOfSelection: 'Xth day of the selected period',
      dontHaveAnyProfiles: 'You have no profiles',
      chooseProfile: 'Select a display profile',
      showAll: 'All',
      saveProfile: 'Save profile',
      saveProfileAs: 'Save profile as',
      saveSettings: 'Save settings',
      exportConfigForm: 'Configuration form for export settings',
      emailRecipsNote:
        'Note: Emails must be separeted by semicolon. Emails can also be copied from Outlook in next format - Jan Novák (jan.novak@gmail.com), Josef Novotný (josef.novotny@seznam.cz)',
      exportNote:
        'Note: If there are less than 31 days in current month and sending emails is set to the 31th day, then emails will be sent on the last day of the current month',
      expressionForColorEval: 'Expression for color evaluation',
      resetTableSettings: 'Reset table settings',
      refreshTableSettings: 'Refresh data table',
      editColumnConfiguration: 'Edit column configuration',
      createColumnConfiguration: 'Create column configuration',
      listingType: 'Listing type',
      profileCategory: 'Profile category',
      main: 'Main',
      field: 'Field',
      visible: 'Visible',
      position: 'Position',
      dataType: 'Data type',
      scriptField: 'Elasticsearch script field',
      sortType: 'Sort type',
      design: 'Design',
      displayedField: 'Displayed field',
      cssClass: 'CSS class',
      width: 'Width in pixels',
      widget: 'Widget',
      context: 'Context',
      fulltext: 'Fulltext',
      forbid: 'Forbid',
      converter: 'Converter',
      columnConfiguration: 'Column configuration',
      listingCode: 'Listing code',
      header: 'Header',
      tooltip: 'Tooltip',
      periodPlaceholder: 'E.g. 1d 12h 30m 5s, or -7d',
      periodBeforePlaceholder: 'From',
      periodAfterPlaceholder: 'To',
      fromRelative: 'From (relative)',
      toRelative: 'To (relative)',
      actualTimeplusInterval: 'Actual time plus interval',
      addFilter: 'Add filter',
      search: 'Search',
      filterByAttributeOrKeyword: 'Filter by attribute or keyword',
      listing: 'Listing',
      default: 'Default',
      rowsCount: 'Rowcount',
      clearProfile: 'Clear profile',
      deleteProfile: 'Delete profile',
      deleteProfileConfirm: 'Are you sure you want to delete the profile?',
      repeatedExportSetting: 'Repeated export setup',
      setColors: 'Set colors',
      settings: 'Settings',
      emptyMessage: 'No records found',
      metrics: 'Metrics aggregation',
      metricsTooltip:
        'Specify aggregation method according to Elasticsearch metrics aggregation - e.g. sum, count, max, avg or including field name: sum(field), ...',
      buckets: 'Bucket aggregation',
      bucketsTooltip:
        'Specify aggregation method according to Elasticsearch bucket aggregation. Default is term (if not specified and Metrics aggregation is not specified).',
      addUrlParam: 'Additional filter parameters',
      shareProfile: 'Share profile',
      setAsDefault: 'Set as default',
      removeAsDefault: 'Remove as default',
      defaultProfileTooltip: 'Default profile for all users',
      to: 'to',
      of: 'of',
      paginatorSettings: 'Paginator settings',
      pageSettings: 'Page settings',
      pageSize: 'Page size',
      minPage: 'Min page is',
      maxPage: 'Max page is',
      moreValueFilterTooltip:
        'Enter a comma-separated or newline-separated list of values. \n Tip: The values can be transferred by e.g. copy & paste from a list in Excel.',
      selectAll: 'Select all records',
      deselectAll: 'Deselect all records',
      selectDeselectAll: 'Select / Deselect all records',
      selectDeselect: 'Select / Deselect',
      backupRestore: {
        NEW: 'New record',
        CHANGE: 'Changed record',
        REMOVE: 'Deleted record',
      },
    },
    effects: {
      columnSaveSuccesMsg: 'Column saving finished successfully',
      columnSaveErrorMsg: 'Error occurred during saving column',
      profileLoadError: 'Error occurred during loading the profiles',
      profileSaveError: 'Error occurred during saving the profiles',
      profileDeleteError: 'Error occurred during deleting the profile',
      loadDataError: 'Error occurred during data loading',
      unSetAsDefaultProfileSuccessMsg: 'Profile has been removed as default',
      unSetAsDefaultProfileErrorMsg:
        'Error occurred while setting profile as non-default',
      setDefaultProfileSuccessMsg: 'Profile has been set as default',
      setDefaultProfileErrorMsg:
        'Error occurred while setting profile as default',
      profileSaveSuccess: 'Profile has been successfully saved',
      profileDeleteSuccess: 'Profile has been successfully deleted',
      badContentMsg: 'The response does not contain a content attribute',
      unknownErrorMsg: 'Unknown error',
      treeCircleErrorMsg:
        'A loop occurred while expanding [{{item}}]. Data may not be displayed correctly.',
      profileAddDefaultSaveSuccess:
        'The profile was successfully set as default',
      profileRemoveDefaultSaveSuccess:
        'Profile was successfully removed as default',
      profileAddDefaultSaveError: 'Failed to set profile as default',
      profileRemoveDefaultSaveError:
        'Failed to remove default profile settings',
    },
    dateRound: {
      title: 'Round date',
      HOUR: 'hour',
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
    },
    form: {
      titlePosition: 'Title position',
      optionalInputClass: 'Optional CSS class for input',
      optionalDescriptionClass: 'Optional CSS class for description',
      hideTitle: 'Hide title',
      hidden: 'Hidden',
    },
    tagFilterWidget: {
      undefinedTag: 'Without a tag',
    },
    sharing: {
      save: {
        success: 'Saving sharing finished successfully',
        error: 'Error occurred during saving sharing',
      },
      delete: {
        success: 'Delete sharing finished successfully',
        error: 'Error occurred during delete sharing',
      },
    },
    exportStatus: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    },
    dataType: {
      VARCHAR: 'Varchar',
      INTEGER: 'Integer',
      BIGINT: 'Bigint',
      DOUBLE: 'Double',
      BOOLEAN: 'Boolean',
      TIMESTAMP: 'Date',
    },
    dataViewMode: {
      TABLE_FULLSCREEN: 'Fullscreen table',
      TABLE_DETAIL: 'Table with detail',
      TABLE: 'Table with scroll',
      TREE: 'Tree table with scroll',
      TREE_FULLSCREEN: 'Fullscreen tree table',
      KANBAN: 'Kanban',
    },
  },
  listingTypeService: {
    effects: {
      listingTypeDeleteSuccess: 'Deleting listing type finished with success',
      listingTypeDeleteError: 'Error occurred during deleting listing type.',
      messageListingTypeChangeSuccessful:
        'Changing listing type finished successfully',
      messageListingTypeSaveUnSuccessful:
        'Error occurred during saving listing type',
      listingTypeDiffSuccess: 'Saving listing type diff finished with success',
      listingTypeDiffError: 'Error occurred during saving listing type diff.',
    },
  },
  listingProfileService: {
    effects: {
      listingProfileDeleteSuccess:
        'Deleting listing profile finished with success',
      listingProfileDeleteError:
        'Error occurred during deleting listing profile.',
      listingProfileDiffSuccess:
        'Saving listing profile diff finished with success',
      listingProfileDiffError:
        'Error occurred during saving listing profile diff.',
      messageListingProfileChangeSuccessful:
        'Changing listing profile finished successfully',
      messageListingProfileSaveUnSuccessful:
        'Error occurred during saving listing profile',
    },
  },
  valueConverterConfigService: {
    effects: {
      valueConverterConfigDeleteSuccess:
        'Deleting value converter config finished with success',
      valueConverterConfigDeleteError:
        'Error occurred during deleting value converter config.',
      valueConverterConfigDiffSuccess:
        'Saving value converter config diff finished with success',
      valueConverterConfigDiffError:
        'Error occurred during saving value converter config diff.',
      messageValueConverterConfigChangeSuccessful:
        'Changing value converter config finished successfully.',
      messageValueConverterConfigSaveUnSuccessful:
        'Error occurred during saving value converter config.',
    },
  },
};
