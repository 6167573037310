import {createAction, props} from '@ngrx/store';
import {Register} from '../models';
import {TsmError} from '@tsm/framework/http';
import {createActionWithMetadata} from '@tsm/framework/root/actions-metadata';
import {translation as translationShared} from '@tsm/shared-i18n';

export const LoadRegister = createAction('[Register] Load Register');
export const LoadRegisterSuccess = createAction(
  '[Register] Load Register Success',
  props<{entities: Register[]}>(),
);
export const LoadRegisterError = createAction(
  '[Register] Load Register Error',
  props<{error: any}>(),
);

export const LoadRegisterById = createActionWithMetadata(
  '[Register] Load Register by id',
  {
    title: translationShared.shared.actionsMetadata.loadRegisterById,
    props: {
      id: translationShared.shared.id,
    },
  },
  props<{id: string}>(),
);
export const LoadRegisterByIdSuccess = createAction(
  '[Register] Load Register by id Success',
  props<{
    entity: Register;
  }>(),
);
export const LoadRegisterByIdError = createAction(
  '[Register] Load Register by id Error',
  props<{error: any}>(),
);

export const LoadRegisterByCode = createActionWithMetadata(
  '[Register] Load Register by code',
  {
    title: translationShared.shared.actionsMetadata.loadRegisterByCode,
    props: {
      code: translationShared.shared.code,
    },
  },
  props<{code: string}>(),
);
export const LoadRegisterByCodeSuccess = createAction(
  '[Register] Load Register by code Success',
  props<{
    entity: Register;
  }>(),
);
export const LoadRegisterByCodeError = createAction(
  '[Register] Load Register by code Error',
  props<{error: any}>(),
);

export const UpsertRegister = createAction(
  '[Register] Upsert Register',
  props<{
    register: Register;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertRegisterSuccess = createAction(
  '[Register] Upsert Register Success',
  props<{
    register: Register;
    listingId: string;
    redirectToDetail: boolean;
  }>(),
);
export const UpsertRegisterError = createAction(
  '[Register] Upsert Register Error',
  props<{error: any}>(),
);

export const DeleteRegister = createAction(
  '[Register] Delete Register',
  props<{
    id: string;
    listingId: string;
    shouldRedirectToListing: boolean;
  }>(),
);
export const DeleteRegisterSuccess = createAction(
  '[Register] Delete Register Success',
  props<{
    id: string;
    listingId: string;
  }>(),
);
export const DeleteRegisterError = createAction(
  '[Register] Delete Register Error',
  props<{error: TsmError}>(),
);

export const DiffRegister = createAction(
  '[Register] Diff Register',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    listingId: string;
    compareField: 'id' | 'code';
  }>(),
);
export const DiffRegisterSuccess = createAction(
  '[Register] Diff Register Success',
  props<{
    registers: Register[];
    listingId: string;
  }>(),
);
export const DiffRegisterError = createAction(
  '[Register] Diff Register Error',
  props<{
    diffEntities: {id: string; [k: string]: any}[];
    error: TsmError;
  }>(),
);
